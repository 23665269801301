import { h } from 'vue'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import CstToast from '@ui/Toast/CstToast'

export default function useToast() {
  const route = useRoute()

  const alertMessage = (message, type, duration = 5000) => {
    if (route.name === 'analytics_pdf_report') {
      return
    }

    toast(({ closeToast }) =>
      h(CstToast, { closeToastCallback: closeToast, type, message })
    )
  }

  return {
    alertMessage,
  }
}
