<script setup>
import { defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import MiniStatsCard from '@/src/modules/analytics/views/common/MiniStatsCard.vue'

const {
  isReportView,

  getRollup,
} = useFacebookAnalytics('Rollup')

defineProps({
  type: {
    type: String,
    default: '',
    required: true,
  },
})

</script>

<template>
  <AnalyticsCardWrapper :enable-modal="false">
    <template v-slot:card-header>
      <p class="font-weight-500 text-base"> <span class="capitalize">{{ type }}</span> Rollup</p>
    </template>
    <template v-slot:card-body="">
      <div class="w-full flex flex-col gap-4" >
        <div
          class="flex flex-col gap-2 overflow-y-auto"
          :class="{ 'h-[350px]': !isReportView }"
        >
          <MiniStatsCard
            v-for="card in getRollup(type)?.keys"
            :key="card"
            :data="getRollup(type)?.getData(card)"
            :is-report-view="isReportView"
          />
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<style scoped>
</style>
