<template>
  <div class="facebook-analytics-demo">
    <section>
      <template v-if="fans <= 100">
        <div class="analytics-main-errors-overflow">
          <img
            draggable="false"
            src="../../../assets/imgs/no_data_images/no_demographics_for_less_than_100_fans_2x.png"
            alt=""
          />

          <p> No Demographics for less than 100 fans </p>
        </div>
      </template>

      <h2>Instagram Audience Demographics</h2>
      <p>Review your audience demographics during the reporting period.</p>

      <div class="row">
        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience by Age</h3>
            </div>
            <template v-if="age_gender_loader">
              <div class="straight-line-chart">
                <div v-for="i in 7" :key="i" class="straight-line-chart__item">
                  <div class="straight-line-chart__header">
                    <SkeletonBox width="80px" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </div>
                  <SkeletonBox width="100%" height="8px" radius="50rem" />
                </div>
              </div>
            </template>
            <template v-else>
              <StraightLineChart
                :values="fans_age"
                :max_value="max_gender_age.value"
              />
            </template>
          </div>
        </div>

        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header facebook-analytics-demo__gender">
<!--              <i class="far fa-venus-mars"></i>-->
              <h3>Audience by Gender</h3>
            </div>
            <template v-if="age_gender_loader">
              <div class="h-chart flex-center-center" style="height: 90%">
                <SkeletonBox
                  :innerfill="true"
                  :width="'240px'"
                  :height="'240px'"
                  radius="50%"
                />
              </div>
            </template>
            <template v-else>
              <PieInnerChart
                :chart-ref="`gender-overall-analytics-pie`"
                :colors="['#6173fc', '#2fe095', '#ffe202']"
                :data="[
                  ['Male', fans_gender['M']],
                  ['Female', fans_gender['F']],
                  ['Unspecified', fans_gender['U']],
                ]"
                class="flex-center-center"
                style="flex-direction: column"
                :width="270"
                :height="270"
                @mounted="getPieRef"
              />
              <div class="d-flex justify_center analytics-pie-legends">
                <template
                  v-for="(obj, i) in legends['gender-overall-analytics-pie']"
                  :key="i"
                >
                  <div
                    class="analytics-pie-legends__detail-item"
                    @click="legendClick(obj, obj.name !== 'No Data Found')"
                  >
                    <div class="analytics-pie-legends__detail-label v-metrics">
                      <span
                        :style="{ borderColor: obj.color }"
                        class="analytics-pie-legends__detail-label-icon"
                      ></span>
                      {{ capitalizeFirstLetter(obj.name) }}
                    </div>
                    <div
                      v-if="obj.name !== 'No Data Found'"
                      class="analytics-pie-legends__detail-value"
                    >
                      {{ obj.y }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="fans > 100" class="row">
        <div class="col-12">
          <div class="bordered-box bordered-box--margined">
            <div class="bordered-box__header justify_center mb-0">
              <template v-if="age_gender_loader">
                <SkeletonBox width="60%" radius=".2rem" />
              </template>
              <template v-else>
                <h3 class="normal"
                  ><b>{{ gender[max_gender_age.gender] }}</b> between the ages
                  of <b>{{ max_gender_age.age }}</b> appear to be the leading
                  force among your followers.</h3
                >
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience Top Countries</h3>
            </div>

            <div class="analytics-audience">
              <template v-if="country_city_loader">
                <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
                  <SkeletonBox :width="'100%'" height="90px" radius="10px" />
                </div>
              </template>
              <template v-else>
                <div
                  v-if="fans <= 100"
                  class="flex-center-center"
                  style="height: 90%"
                >
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <template
                  v-for="(
                    audience_value, audience_key, index
                  ) in audience_country"
                  v-else
                  :key="index"
                >
                  <StatsCard
                    v-if="index < 10"
                    :title="audience_key"
                    :value="audience_value"
                    variant="country"
                  />
                </template>
              </template>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="bordered-box bordered-box--contained">
            <div class="bordered-box__header">
              <h3>Audience Top Cities</h3>
            </div>
            <div class="analytics-audience">
              <template v-if="country_city_loader">
                <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
                  <SkeletonBox :width="'100%'" height="90px" radius="10px" />
                </div>
              </template>
              <template v-else>
                <div
                  v-if="fans <= 100"
                  class="flex-center-center"
                  style="height: 90%"
                >
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
                <div v-else>
                    <template
                            v-for="(
                      audience_value, audience_key, index
                    ) in audience_city"
                            :key="index"
                    >
                        <StatsCard
                                v-if="index < 10"
                                :title="audience_key"
                                :value="audience_value"
                                variant="city"
                        />
                    </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StraightLineChart from '@src/modules/analytics/views/common/Infographics/StraightLineChart'
import StatsCard from '@src/modules/analytics/views/common/StatsCard'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'

import { mapActions } from 'vuex'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'

export default {
  name: 'DemographicsTab',
  components: {
    StraightLineChart,
    StatsCard,
    SkeletonBox,
    PieInnerChart
  },
  mixins: [analyticsUtilsMixin],
  props: ['account', 'date', 'previous_date', 'getDemographicsLoader'],
  data () {
    return dataValues().instagram.demographics
  },
  computed: {
    getFetchingData () {
      return this.age_gender_loader || this.country_city_loader
    }
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    initialize () {
      this.getFansAgeGender()
      this.getFansCountryCity()
    },
    async getFansAgeGender () {
      if (this.age_gender_loader === true) {
        return
      }
      this.age_gender_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id,
          date: this.date,
          tab: 'demographics',
          section: 'audience_age',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })

          this.fans_age = response.audience_age
          this.fans_gender = response.audience_gender
          this.max_gender_age = response.max_audience_age
          this.fans = response.audience_gender.audience_fans
      }
      this.age_gender_loader = false
    },
    async getFansCountryCity () {
      if (this.country_city_loader === true) {
        return
      }
      this.country_city_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id,
          date: this.date,
          tab: 'demographics',
          section: 'country_city',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.audience_country = response.audience_country
          this.audience_country = this.sortList(this.audience_country)
          this.audience_city = response.audience_city
          this.audience_city = this.sortList(this.audience_city)
      }
      this.country_city_loader = false
    }
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    getFetchingData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDemographicsLoader(newVal)
      }
    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
