import moment from 'moment'
import proxy from '@common/lib/http-common'
import Router from '@src/router'
import axios from 'axios'
import orderBy from 'lodash/orderBy'
import { discoveryBaseUrl } from '@src/config/api-utils'
import { composer } from '../../composer/store/composer-mutation-type'
import {
  getArticlesURL,
  getCuratedTopicByIDURL,
  previewSuggestionURL,
  removeCustomTopicURL,
} from '../config/api-utils'
import { previewTypes, topicTypes } from './mutation-types'

const getDefaultTrendingTopicDate = () => {
  return {
    label: 'Last 7 days',
    value:
      moment().subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss') +
      ' - ' +
      moment().format('YYYY-MM-DDTHH:mm:ss'),
  }
}
const getDefaultTopicDate = () => {
  return {
    label: ' Last 30 days',
    value:
      moment().subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss') +
      ' - ' +
      moment().format('YYYY-MM-DDTHH:mm:ss'),
  }
}

const getDefaultTopicRegion = () => {
  return {
    code: 'world',
    native_language: {
      code: 'en',
      languageName: 'English',
    },
    name: 'Worldwide',
    flag_position: '0 0',
  }
}
const getDefaultTopicLanguage = () => {
  return {
    languageName: 'English',
    code: 'en',
  }
}

const getDefaultTopicSelectionState = () => {
  return {
    match_type: 'title',
    sources: 'topics_and_keywords',
    any_keywords: [],
    must_also_keywords: [],
    must_contains_keywords: [],
    must_not_contains_keywords: [],
    must_exclude_keywords: [],
    include_domains: [],
    exclude_domains: [],
    limit_domains_results: [],
  }
}

const getDefaultTopicFiltersState = () => {
  return {
    topics: {
      topic: null,
      name: null,
      type: 'Any',
      sort: 'Most Shared',
      date: getDefaultTopicDate(),
      region: getDefaultTopicRegion(),
      language: getDefaultTopicLanguage(),
      related_id: null,
      page: 1,
      limit: 20,
      domain: null,
      min_social_engagement: 0,
      post_age: {
        value: 0,
        text: 'Any',
      },
      engagement: {
        type: 'None',
        count: 'Facebook',
      },
      must_include_featured_image: true,
    },
  }
}

export default {
  state: {
    curatedTopics: [],
    myCuratedTopics: [],
    // for activating the tab color.
    activeTopicTab: null,
    layout: 'grid',

    // covered stories and trending news

    coveredStories: false,
    coveredStoriesLoader: false,
    coveredStoriesRunOnce: false,
    curatedQuotes: false,
    trendingStories: false,
    coveredStoriesPosts: [],
    customStoriesPosts: [],

    // topic selection, it is set when the user selects a topic

    topic: null,
    topicText: null,
    topicType: 'curated',
    totalPosts: 0,
    pageLoaded: false,
    topicContentType: 'article',
    topicCreationModal: false,

    // topics fetch status, default is false, once the topics are fetched, the status changes.

    fetchTopicsStatus: false,
    topics: [], // list of topics

    // suggested keywords are the suggestion that will appear when the user enter a term while creating a topic.
    suggestedKeywords: [],
    topicQuotaStatus: false,
    totalAddedTopic: 0,

    // for editing part -> in create new topic scenario.
    topicLabel: {
      name: null,
    },
    selectedCuratedTopic: {},
    // topic selection and content retrieval done based on these rules.
    selection: getDefaultTopicSelectionState(),
    // filters are used for the Topics search as well as curated topics.
    filters: getDefaultTopicFiltersState(),

    // preview suggestion pagination is done here.
    preview: {
      total: 0,
      posts: [],
      limit: 20,
      page: 1,
      scroll: true,
      noMoreResult: false,
      infiniteLoader: false,
      loader: false,
      token: null,
    },
    loaders: {
      topic: false,
      customTopics: true,
      curatedTopics: true,
      paginationLoader: false,
      disabled: false,
      insights: false,
      showCharts: false,
      customSidebar: false,
    },
    scroll: false, // used for the infinite loader.

    // before states for the topic reset part.

    beforeRegion: {},
    beforeSort: '',
    beforeLanguage: {},
    beforeDate: {},
    beforeName: '',

    module: 'content', // 3 choices, content, insights and influencers.

    // customize side bar

    sidebarCustomization: false,
    sidebarFetchStatus: false,
    customSidebar: {
      quickTopics: {
        status: true,
        options: [],
      },

      myTopics: {
        status: true,
        options: [],
      },

      favorites: {
        status: true,
        options: [],
      },
    },
  },
  actions: {
    fetchTopicsAndFavorites({ commit, getters, dispatch }) {
      return proxy.post(
        `${discoveryBaseUrl}v2/topics/retrieve_topics_and_favorites`,
        { workspace_id: getters.getActiveWorkspace._id }
      )
    },

    curatedTopic({ commit, getters, dispatch }, id) {
      return proxy.post(getCuratedTopicByIDURL, { id })
    },

    topicsContent({ commit, getters, dispatch }, $state = null) {
      commit(topicTypes.SET_TOPIC_DISABLED, true)
      commit(topicTypes.SET_POSTS_TOTAL, 0)
      const payload = {
        route: Router.currentRoute.value.name,
        workspace_id: getters.getActiveWorkspace._id,
      }
      switch (getters.getTopicType) {
        case 'custom_topic':
          payload.module_type = 'topics'
          payload.selection = getters.getTopicSelection
          payload.filters = getters.getTopicsFilters
          break
        case 'curated':
          payload.module_type = 'curated'
          payload.filters = getters.getTopicsFilters
          break

        case 'favorite':
          payload.module_type = 'favorite'
          payload.filters = getters.getTopicsFilters
          payload.topic = getters.getTopic
          break
        case 'archived':
          payload.module_type = 'archived'
          payload.filters = getters.getTopicsFilters
          break
        case 'pocket':
          payload.module_type = 'pocket'
          payload.filters = getters.getTopicsFilters
          break
        case 'covered_stories':
          payload.module_type = 'covered_stories'
          break
      }

      console.debug(payload)
      if (getters.getTopicType !== 'covered_stories') {
        if (payload.filters.topics.page === 1) {
          commit('SET_POSTS', [])
          commit('SET_TOPICS_LOADER', true)
        }
      }
      return proxy
        .post(getArticlesURL, payload)
        .catch(() => {})
        .then((resp) => {
          commit(topicTypes.SET_TOPIC_DISABLED, false)
          commit('SET_TOPICS_PAGE_LOADED', true)

          // covered stories

          if (getters.getTopicType === 'covered_stories') {
            console.log(resp.data.covered_stories)
            if (resp && resp.data) {
              commit('SET_CURATED_STORIES_POSTS', resp.data.covered_stories)
            }
            if (resp && resp.data) {
              commit(
                'SET_CUSTOM_STORIES_POSTS',
                resp.data.custom_topics_stories
              )
            }
            commit('setCoveredStoriesLoader', false)
            return
          }

          // other filters such as curated topic, favorites etc.
          // if the page is first one, set the post array to 0.
          if (
            getters.getTopicsPage === 0 ||
            (getters.getTopicsPage === 1 &&
              (!getters.getPosts || getters.getPosts.length === 0))
          ) {
            commit('SET_POSTS', [])
          }
          if (resp && resp.data) commit('SET_POSTS_TOTAL', resp.data.total)

          if (getters.getTopicsPage > 1) {
            commit('SET_POSTS', getters.getPosts.concat(resp.data.posts))
          } else {
            /* if (this.getRelatedStatus) {
                          let parentPost = this.getPosts[0]
                          this.setPosts([parentPost])
                          this.setPosts(this.getPosts.concat(response.data.posts))
                        } else { */
            commit('SET_POSTS', resp.data.posts)
            /* } */
          }
          commit('SET_TOPICS_PAGINATION_LOADER', true)

          if (resp.data.posts && resp.data.posts.length === 20) {
            commit('SET_TOPICS_SCROLL', true)
            if ($state) {
              $state.loaded()
            }
          } else {
            commit('SET_TOPICS_SCROLL', false)
            try {
              if ($state) {
                $state.complete() // if not work remove this -->adding this because loader showing even no more posts found
              }
              /* } */
            } catch (ex) {
              console.log(ex)
            }
          }
          commit('SET_TOPICS_LOADER', false)
          return true
        })
    },

    articlesPreviewSuggestion({ commit, getters, mutations }, payload) {
      // if there are no keywords or domains, don't send a request.
      if (getters.isPreviewSnippetAllowed === false) {
        return
      }

      if (payload && !payload.hasOwnProperty('pagination')) {
        payload.pagination = false
      }

      if (!payload) {
        payload = {
          pagination: false,
        }
      }

      const requestPayload = {
        filters: getters.getTopicsFilters,
        preview: getters.getPreviewSuggestion,
        workspace_id: getters.getActiveWorkspace._id,
        module_type: 'preview',
      }

      // for automation content preview for the topics and other parts.
      const automation = getters.getArticleAutomationDetails.article

      const windowPath = window.location.href
      // automation scenario
      if (windowPath.includes('/automation/')) {
        // post age and engagement

        requestPayload.filters.topics.post_age = automation.post_age
        requestPayload.filters.topics.post_date = automation.post_date
        requestPayload.filters.topics.engagement = {
          type: automation.engagement_type,
          count: automation.engagement_count,
        }
        requestPayload.skip_without_image = automation.skip_without_image

        // for the automation id exclude ids

        if (
          windowPath.includes('/automation/blog/article') ||
          windowPath.includes('/automation/social/article')
        ) {
          requestPayload.automation_id = getters.getArticleAutomationDetails.id
          requestPayload.automation_preview = true
        }

        // topics and selected topics

        if (
          automation.build_type === 'topics' &&
          automation.topics.length > 0
        ) {
          console.debug('Automation case...')
          requestPayload.selected_topics = automation.topics
        } else {
          requestPayload.selection = getters.getTopicSelection
        }
      } else {
        console.debug('QeuryBuilder case...')
        requestPayload.selection = getters.getTopicSelection
      }

      // resetting all the states if the user has entered a new keyword and pagination is set to false.
      commit(previewTypes.SET_PREVIEW_SUGGESTION_INFINITE_LOADER, false)
      commit(previewTypes.SET_PREVIEW_SUGGESTION_LOADER, true)
      if (payload.pagination === false) {
        commit(previewTypes.SET_PREVIEW_SUGGESTION_POSTS, [])
        commit(previewTypes.SET_PREVIEW_SUGGESTION_PAGE, 1)
        commit(previewTypes.SET_PREVIEW_SUGGESTION_LOADER, true)
      }

      // set the default to false.
      commit(previewTypes.SET_PREVIEW_SUGGESTION_SCROLL, false)

      const CancelToken = axios.CancelToken

      // removing all existing axios requests

      for (
        let i = 0;
        i < getters.getAxiosRequests.previewSuggestion.length;
        i++
      ) {
        const remove = getters.getAxiosRequests.previewSuggestion[i]
        remove()
      }

      let cancel, url

      axios
        .post(
          getArticlesURL,
          requestPayload,
          // 'topics': this.getArticleAutomationDetails.article.topics
          { headers: { Authorization: 'Bearer ' + getters.getJWTToken } },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          }
        )
        .then((resp) => {
          if (resp.data.status) {
            // hide the loaders

            commit(previewTypes.SET_PREVIEW_SUGGESTION_LOADER, false)

            // add the posts to the array if they are called through the pagination
            if (payload.pagination) {
              commit(
                previewTypes.SET_PREVIEW_SUGGESTION_POSTS,
                getters.getPreviewSuggestionPosts.concat(resp.data.posts)
              )
            } else {
              commit(previewTypes.SET_PREVIEW_SUGGESTION_POSTS, resp.data.posts)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_TOTAL, resp.data.total)
            }
            // if the posts are 20, we need to set the infinite loading to scrollable behavior
            if (resp.data.posts.length === 20) {
              console.log('Set Preview Suggestion Scroll')
              commit(previewTypes.SET_PREVIEW_SUGGESTION_SCROLL, true)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_INFINITE_LOADER, true)
              console.log('Value set for the infinite loader')

              if (payload.$state) {
                console.log('State loaded for the preview suggestion')
                payload.$state.loaded()
              }
            } else if (
              resp.data.posts.length > 0 &&
              resp.data.posts.length < 20
            ) {
              console.log('Posts length is less, setting all values to false.')
              commit(previewTypes.SET_PREVIEW_SUGGESTION_SCROLL, false)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_NO_MORE_RESULT, true)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_INFINITE_LOADER, false)
              if (payload.$state) {
                payload.$state.complete()
              }
            } else {
              console.log('Posts length is zero...')
              commit(previewTypes.SET_PREVIEW_SUGGESTION_SCROLL, false)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_NO_MORE_RESULT, true)
              commit(previewTypes.SET_PREVIEW_SUGGESTION_INFINITE_LOADER, false)
              if (payload.$state) {
                payload.$state.complete()
              }
            }
          }
        })
        .catch(() => {})
      getters.getAxiosRequests.previewSuggestion.push(cancel)
    },
    /**
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload {object}
     * @param payload.id {string} Topic id to remove.
     * @param payload.index {integer} Topic index
     */
    deleteCustomTopic({ commit, getters, dispatch }, { id, index }) {
      proxy
        .post(removeCustomTopicURL, {
          topic_id: id,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            const topicsList = getters.getTopics
            topicsList.splice(index, 1)
            commit('SET_CUSTOM_TOPICS', topicsList)
            if (getters.getCustomStoriesPosts.length > 0) {
              getters.getCustomStoriesPosts.forEach((item, index) => {
                if (item.topic._id === id) {
                  console.log('Index is', index)
                  getters.getCustomStoriesPosts.splice(index, 1)
                }
              })
            }
          }
        })
        .catch(() => {})
    },

    setCoveredStoriesRunOnce({ commit }, value) {
      commit('setCoveredStoriesRunOnce', value)
    },
    setCoveredStoriesLoader({ commit }, value) {
      commit('setCoveredStoriesLoader', value)
    },

    // user followed curated/quick topics list from the followed section

    addToCuratedTopicsList({ commit }, list) {
      commit('ADD_TO_CURATED_LIST', list)
    },

    // set the topic id

    setTopic({ commit }, topic) {
      commit('setTopic', topic)
    },

    // reset all the states filters i.e create new topic or workspace change.

    stateResetTopicFilters({ commit }) {
      commit('stateResetTopicFilters')
    },

    // topics filter mixin

    // Articles start
    setTopicCreationModal({ commit }, sort) {
      commit('setTopicCreationModal', sort)
    },

    // Articles end

    // preview topics content for the automation as well as for the discovery creation

    setPreviewSuggestionPage({ commit }, page) {
      commit('setPreviewSuggestionPage', page)
    },
    setPreviewSuggestionScroll({ commit }, scroll) {
      commit('setPreviewSuggestionScroll', scroll)
    },
    setPreviewSuggestionPosts({ commit }, posts) {
      commit('setPreviewSuggestionPosts', posts)
    },
    setPreviewSuggestionTotal({ commit }, total) {
      commit('setPreviewSuggestionTotal', total)
    },
    setPreviewSuggestionNoMoreResult({ commit }, data) {
      commit('setPreviewSuggestionNoMoreResult', data)
    },
    setPreviewSuggestionInfiniteLoader({ commit }, loader) {
      commit('setPreviewSuggestionInfiniteLoader', loader)
    },
    setPreviewSuggestionToken({ commit }, token) {
      commit('setPreviewSuggestionToken', token)
    },
    setPreviewSuggestionLoader({ commit }, loader) {
      commit('setPreviewSuggestionLoader', loader)
    },

    // filters coming from automation for preview

    setPreviewSuggestionPostAge({ commit }, postAge) {
      commit('setPreviewSuggestionPostAge', postAge)
    },
    setPreviewSuggestionEngagement({ commit }, engagement) {
      commit('setPreviewSuggestionEngagement', engagement)
    },
  },
  mutations: {
    setCoveredStoriesRunOnce(state, value) {
      state.coveredStoriesRunOnce = value
    },
    setCoveredStoriesLoader(state, value) {
      state.coveredStoriesLoader = value
    },

    // set the topic id and label

    setTopic(state, topic) {
      // @umair: not sure why we are using it. state.filters.category = null
      if (topic) {
        state.topic = topic._id
        state.topicText = topic.label

        // set the default data.

        if (topic.filters) {
          state.filters = JSON.parse(JSON.stringify(topic.filters))
        } else {
          state.filters = getDefaultTopicFiltersState()
        }
        if (topic.selection) {
          state.selection = JSON.parse(JSON.stringify(topic.selection))
        } else {
          state.selection = getDefaultTopicSelectionState()
        }
      } else {
        state.topic = topic
      }
    },

    // set topic text that is shown on the topics section when the user selects it.

    // active blue color added on the left side border for the list menu.

    // reset topic

    stateResetTopicFilters(state) {
      state.filters = getDefaultTopicFiltersState()
    },

    // Articles start

    setTopicCreationModal(state, value) {
      state.topicCreationModal = value
    },

    // preview topics content for the automation as well as for the discovery creation
    setPreviewSuggestionPage(state, page) {
      state.preview.page = page
    },
    setPreviewSuggestionScroll(state, scroll) {
      state.preview.scroll = scroll
    },
    setPreviewSuggestionPosts(state, posts) {
      state.preview.posts = posts
    },
    setPreviewSuggestionTotal(state, total) {
      state.preview.total = total
    },
    setPreviewSuggestionNoMoreResult(state, data) {
      state.preview.noMoreResult = data
    },
    setPreviewSuggestionInfiniteLoader(state, loader) {
      state.preview.infiniteLoader = loader
    },
    setPreviewSuggestionToken(state, token) {
      state.preview.token = token
    },
    setPreviewSuggestionLoader(state, loader) {
      state.preview.loader = loader
    },

    // automation filters for preview

    setPreviewSuggestionPostAge(state, postAge) {
      state.filters.topics.post_age = postAge
    },
    setPreviewSuggestionEngagement(state, engagement) {
      state.filters.topics.engagement = engagement
    },

    // new way for dealing with states

    SET_TOPICS_PAGE_LOADED(state, value) {
      state.pageLoaded = value
    },

    // user followed curated/quick topics list from the followed section
    [topicTypes.SET_CURATED_TOPICS_LIST](state, list) {
      state.myCuratedTopics = list
    },

    [topicTypes.ADD_TO_CURATED_LIST](state, list) {
      state.myCuratedTopics = state.myCuratedTopics.concat(list)
    },
    [topicTypes.REMOVE_FROM_CURATED_LIST](state, index) {
      state.myCuratedTopics = state.myCuratedTopics.splice(index, 1)
    },
    // user custom topics
    [topicTypes.SET_CUSTOM_TOPICS](state, topicsList) {
      const checkRange = {
        'Last 24 Hours': 1,
        'Last 48 Hours': 2,
        'Last 3 Days': 3,
        'Last 7 Days': 7,
        'Last 30 Days': 30,
        'Last 3 Months': 90,
        'Last 90 Days': 90,
        'Last 6 Months': 180,
        'Last 1 Year': 360,
        'Last 2 Years': 720,
      }

      // iterating the topics list for the frontend side for the date range picker option.

      if (topicsList) {
        topicsList.forEach((item) => {
          if (item.filters) {
            console.log('SET_CUSTOM_TOPICS > ', item)
            if (item.filters.topics.date) {
              if (item.filters.topics.date.label) {
                console.log(
                  'SET_CUSTOM_TOPICS > ',
                  item.filters.topics.date.label,
                  item
                )

                if (checkRange.hasOwnProperty(item.filters.topics.date.label)) {
                  console.log(
                    'SET_CUSTOM_TOPICS > has Property available and value updated'
                  )
                  console.log(
                    'SET_CUSTOM_TOPICS > value from the label is ',
                    checkRange[item.filters.topics.date.label]
                  )

                  item.filters.topics.date.value =
                    moment
                      .utc()
                      .subtract(
                        checkRange[item.filters.topics.date.label],
                        'days'
                      )
                      .format('YYYY-MM-DD HH:mm:ss') +
                    ' - ' +
                    moment.utc().format('YYYY-MM-DD HH:mm:ss')
                }
              }
            }
          }
        })
      }
      state.topics = topicsList
    },

    SET_CHANGE_TOPIC_FILTERS(state, payload) {
      console.log('SET_CHANGE_TOPIC_FILTERS', payload)
      // default all checks set to the false.

      state.coveredStories = false
      state.curatedQuotes = false
      state.trendingStories = false
      state.loaders.topic = true
      state.filters.topics.sort = 'Recent'
      state.topicType = payload.type

      // if the topic is available set it fields. topic is null in case of archived, more to add here.
      if (payload.topic) {
        state.activeTopicTab = payload.topic._id
        state.topic = payload.topic._id

        // if the type is of custom topic, then set the name from the user saved topic

        if (payload.type === 'custom_topic') {
          state.topicText = payload.topic.filters.topics.name
        } else {
          state.topicText = payload.topic.name
        }

        // if the topic type is a custom, it will have the selections and filters.

        if (payload.topic.filters) {
          state.filters = JSON.parse(JSON.stringify(payload.topic.filters))
        } else {
          state.filters = getDefaultTopicFiltersState()
        }
        if (payload.topic.selection) {
          state.selection = JSON.parse(JSON.stringify(payload.topic.selection))
        } else {
          state.selection = getDefaultTopicSelectionState()
        }
      }
      if (payload.hasOwnProperty('contentType')) {
        console.log('topicContentType', payload.contentType)
        state.topicContentType = payload.contentType
      } else {
        console.log('topicContentType is article')
        state.topicContentType = 'article'
      }

      state.filters.topics.page = 1
      state.module = payload.module || 'content'

      // set the dynamic values over here.

      switch (payload.type) {
        case 'favorite':
          break
        case 'archived':
          state.topicText = 'Archived'
          state.activeTopicTab = payload.type
          break
        case 'covered_stories':
          state.coveredStories = true
          state.activeTopicTab = null
          state.coveredStoriesPosts = []
          state.customStoriesPosts = []
          state.coveredStoriesLoader = true
          break

        case 'trending':
          state.filters.topic = payload.topic
          state.trendingStories = true
          state.topicType = 'curated'
          state.filters.topics.sort = 'Trending'
          state.selectedCuratedTopic = payload.topic
          state.filters.topics.page = 1
          state.loaders.paginationLoader = false
          state.filters.topics.date = getDefaultTrendingTopicDate()
          break

        case 'curated':
          state.filters.topic = payload.topic
          state.filters.topics.sort = 'Most Shared'
          state.selectedCuratedTopic = payload.topic
          state.filters.topics.page = 1
          state.loaders.paginationLoader = false
          state.filters.topics.date = getDefaultTopicDate()
          break
        case 'custom_topic':
          state.filters.topics.page = 1
          state.loaders.paginationLoader = false
          break
        case 'quotes':
          state.curatedQuotes = true
          state.activeTopicTab = null
          break
      }
    },

    SET_TOPICS_DATE_RANGE(state, date) {
      if (date == null) {
        state.filters.topics.date = {
          label: ' All Time',
          value: '',
        }
      } else {
        state.filters.topics.date = date
      }
    },

    SET_TOPICS_REGION(state, region) {
      state.filters.topics.region = region
    },

    SET_TOPICS_LANGUAGE(state, language) {
      state.filters.topics.language = language || getDefaultTopicLanguage()
    },
    SET_TOPICS_SORT(state, sort) {
      state.filters.topics.sort = sort
    },
    SET_TOPICS_TYPE(state, type) {
      state.filters.topics.type = type
    },

    SET_TOPICS_LAYOUT(state, value) {
      state.layout = value
    },
    SET_TOPICS_SCROLL(state, scroll) {
      state.scroll = scroll
    },
    SET_TOPICS_PAGE(state, page) {
      state.filters.topics.page = page
    },
    SET_TOPICS_PAGINATION_LOADER(state, value) {
      state.loaders.paginationLoader = value
    },
    SET_TOPICS_LOADER(state, value) {
      state.loaders.topic = value
    },

    // update/ add custom topics
    updateCustomTopics(state, topic) {
      const index = state.topics.findIndex(obj => obj._id === topic._id);
      if (index !== -1) {
        // Update the topic in the array if it already exists
        state.topics?.splice(index, 1, topic);
      } else {
        // Add the new topic to the array if it doesn't exist
        state.topics?.push(topic);
      }
    },

    // topic type such as curated, custom_topic or favorite. Filtering on the backend is done based on this.
    SET_TOPIC_TYPE(state, type) {
      state.topicType = type
    },

    SET_TOPIC_CONTENT_TYPE(state, type) {
      state.topicContentType = type
    },

    SET_TOPIC_MODULE(state, module) {
      state.module = module
    },

    /* SET_POSTS_TOTAL (state, value) {
      state.totalPosts = value
    }, */
    [topicTypes.SET_POSTS_TOTAL](state, value) {
      state.totalPosts = value
    },

    [topicTypes.SET_TOPIC_QUOTA_STATUS](state, status) {
      state.topicQuotaStatus = status
    },

    [topicTypes.SET_TOPIC_DISABLED](state, value) {
      state.loaders.disabled = value
    },

    // before date mutations

    [topicTypes.SET_BEFORE_REGION](state, region) {
      state.beforeRegion = region
    },

    [topicTypes.SET_BEFORE_LANGUAGE](state, language) {
      state.beforeLanguage = language
    },

    [topicTypes.SET_BEFORE_DATE](state, range) {
      state.beforeDate = range
    },
    [topicTypes.SET_BEFORE_TOPIC_NAME](state, name) {
      state.beforeName = name
    },
    [topicTypes.SET_TOPIC_NAME](state, name) {
      state.filters.topics.name = name
      state.topicText = name
    },

    SET_BEFORE_SORT(state, sort) {
      state.beforeSort = sort
    },

    // reset all the states selection i.e create new topic or workspace change, automation opening etc.

    SET_TOPIC_SELECTION(state, selection) {
      if (selection) {
        state.selection = selection
      } else {
        // state.topic.filters.name = ''
        state.selection = getDefaultTopicSelectionState()
      }
    },
    // reseting all the states if the user changes his workspace etc.

    RESET_SELECTION_ON_WORKSPACE_CHANGE(state) {
      state.myCuratedTopics = []
      state.topics = []
      state.selectedCuratedTopic = {}
    },

    // cover stories

    SET_COVERED_STORIES_STATUS(state, value) {
      state.coveredStories = value
    },

    SET_CUSTOM_TOPICS_LOADER(state, loader) {
      state.loaders.customTopics = loader
    },

    SET_CUSTOM_STORIES_POSTS(state, value) {
      state.customStoriesPosts = value
    },
    SET_CURATED_STORIES_POSTS(state, value) {
      state.coveredStoriesPosts = value
    },
    // active blue color added on the left side border for the list menu.
    SET_ACTIVE_TOPIC_TAB(state, value) {
      state.activeTopicTab = value
    },

    [topicTypes.RESET_TOPICS_FILTER](state) {
      state.loaders.topic = true
      state.filters.topics.page = 1
    },

    // preview suggestions

    [previewTypes.SET_PREVIEW_SUGGESTION_PAGE](state, value) {
      state.preview.page = value
    },

    [previewTypes.SET_PREVIEW_SUGGESTION_SCROLL](state, value) {
      state.preview.scroll = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_POSTS](state, value) {
      state.preview.posts = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_TOTAL](state, value) {
      state.preview.total = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_NO_MORE_RESULT](state, value) {
      state.preview.noMoreResult = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_INFINITE_LOADER](state, value) {
      state.preview.infiniteLoader = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_TOKEN](state, value) {
      state.preview.token = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_LOADER](state, value) {
      state.preview.loader = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_POST_AGE](state, value) {
      state.filters.topics.post_age = value
    },
    [previewTypes.SET_PREVIEW_SUGGESTION_ENGAGEMENT](state, value) {
      state.filters.topics.engagement = value
    },
  },
  getters: {
    isPreviewSnippetAllowed: (state) => {
      // || (state.selection.article.topics.length > 0
      if (
        state.selection.any_keywords.length > 0 ||
        state.selection.must_also_keywords.length ||
        state.selection.must_contains_keywords.length ||
        state.selection.include_domains.length ||
        state.selection.limit_domains_results.length
      ) {
        return true
      }
    },
    // for billing
    getActiveChannelRoute: (state) => {
      switch (state.topicContentType) {
        case 'article':
          return 'topics_web'
        case 'youtube':
          return 'topics_youtube'
        case 'facebook':
          return 'topics_facebook'
        case 'twitter':
          return 'topics_twitter'
        default:
          return 'topics_web'
      }
    },
    getTopicQuotaStatus: (state) => {
      return state.topicQuotaStatus
    },
    getCoveredStories: (state) => {
      return state.coveredStories
    },
    getCoveredStoriesLoader: (state) => {
      return state.coveredStoriesLoader
    },
    getCoveredStoriesRunOnce: (state) => {
      return state.coveredStoriesRunOnce
    },
    getCoveredStoriesPosts: (state) => {
      return state.coveredStoriesPosts && state.coveredStoriesPosts.length > 0
        ? orderBy(
            state.coveredStoriesPosts,
            [(item) => item.topic.name.toLowerCase()],
            ['asc']
          )
        : state.coveredStoriesPosts
    },
    getCustomStoriesPosts: (state) => {
      return state.customStoriesPosts && state.customStoriesPosts.length > 0
        ? orderBy(
            state.customStoriesPosts,
            [(item) => item.topic.name.toLowerCase()],
            ['asc']
          )
        : state.customStoriesPosts
    },
    getCuratedQuotes: (state) => {
      return state.curatedQuotes
    },

    getTrendingStories: (state) => {
      return state.trendingStories
    },
    getMyCuratedTopicsList: (state) => {
      return state.myCuratedTopics.length > 0
        ? orderBy(
            state.myCuratedTopics,
            [(topic) => topic.name.toLowerCase()],
            ['asc']
          )
        : state.myCuratedTopics
    },
    getTopics: (state) => {
      return state.topics.length > 0
        ? orderBy(
            state.topics,
            [(item) => item.filters.topics.name.toLowerCase()],
            ['asc']
          )
        : state.topics
    },
    getTopic: (state) => {
      return state.topic
    },
    getCuratedTopic: (state) => {
      return state.selectedCuratedTopic
    },
    getTopicText: (state) => {
      return state.topicText
    },
    getTopicType: (state) => {
      return state.topicType
    },
    getActiveTopicTab: (state) => {
      return state.activeTopicTab
    },
    // get the topics related filters
    getTopicCreationModal: (state) => {
      return state.topicCreationModal
    },
    getTopicsFilters: (state) => {
      return state.filters
    },

    getTopicContentType: (state) => {
      return state.topicContentType
    },

    // get topic label

    getTopicLabel: (state) => {
      return state.filters.topics
    },
    // Articles start

    getTopicsType: (state) => {
      return state.filters.topics.type
    },
    getTopicsSort: (state) => {
      return state.filters.topics.sort
    },
    getTopicsPage: (state) => {
      return state.filters.topics.page
    },
    getTopicsRegion: (state) => {
      return state.filters.topics.region
    },
    getTopicsLanguage: (state) => {
      return state.filters.topics.language
    },

    getTopicsScroll: (state) => {
      return state.scroll
    },
    getTopicsPosts: (state) => {
      return state.posts
    },

    getTopicSelection: (state) => {
      return state.selection
    },

    getTopicPosts: (state) => {
      return state.topicPosts
    },

    getPostsTotal: (state) => {
      return state.totalPosts
    },
    getShowPageLoaded: (state) => {
      return state.pageLoaded
    },
    // preview topics content for the automation as well as for the discovery creation

    getPreviewSuggestion: (state) => {
      return state.preview
    },
    getPreviewSuggestionLimit: (state) => {
      return state.preview.limit
    },
    getPreviewSuggestionPage: (state) => {
      return state.preview.page
    },
    getPreviewSuggestionScroll: (state) => {
      return state.preview.scroll
    },
    getPreviewSuggestionPosts: (state) => {
      return state.preview.posts
    },
    getPreviewSuggestionNoMoreResult: (state) => {
      return state.preview.noMoreResult
    },
    getPreviewSuggestionInfiniteLoader: (state) => {
      return state.preview.infiniteLoader
    },
    getPreviewSuggestionLoader: (state) => {
      return state.preview.loader
    },

    // loaders for the topic section

    getTopicLoader: (state) => {
      return state.loaders.topic
    },
    getCustomTopicsLoader: (state) => {
      return state.loaders.customTopics
    },
    getCuratedTopicsLoader: (state) => {
      return state.loaders.curatedTopics
    },
    getTopicDisabled: (state) => {
      return state.loaders.disabled
    },
    getTopicPaginationLoader: (state) => {
      return state.loaders.paginationLoader
    },
    getBeforeRegion: (state) => {
      return state.beforeRegion
    },
    getBeforeLanguage: (state) => {
      return state.beforeLanguage
    },
    getBeforeSort: (state) => {
      return state.beforeSort
    },
    getBeforeDate: (state) => {
      return state.beforeDate
    },
    getBeforeTopicName: (state) => {
      return state.beforeName
    },
    getTopicModule: (state) => {
      return state.module
    },

    getTopicsLayout: (state) => {
      return state.layout
    },
    getTopicList: (state) => {
      return state.myCuratedTopics
    },
  },
}
