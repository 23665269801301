<template>
  <div>
    <router-link
      v-if="!isEmpty"
      :to="{ name: getReportRedirectLink, params: { reportId: reportId } }"
    >
      <div
        v-tooltip="{
          content: `${
            state.enableTileTooltip ? 'Click to view competitor analysis report' : ''
          }`,
          maxWidth: state.enableTileTooltip ? 350 : 0,
          arrow: state.enableTileTooltip,
        }"
        class="
          border
          bg-white
          p-3
          rounded-md
          select-none
          cursor-pointer
          h-[10rem]
          cst-editor
          group
          !border-[#E9EFF6]'
        "
      >
        <div class="flex flex-1 flex-col justify-between h-full w-full">
          <div class="flex justify-between items-center h-4">
            <div class="flex items-center">
              <span
                v-tooltip="{ content: name }"
                class="text-gray-900 text-base font-weight-500 truncate w-44"
                @mouseover="state.enableTileTooltip = false"
                @mouseout="state.enableTileTooltip = true"
              >
                {{ name }}
              </span>
            </div>

            <div class="relative hidden group-hover:inline-flex">
              <i
                v-tooltip.top="{ content: 'Delete' }"
                class="
                  far
                  fa-trash-alt
                  text-red-500
                  rounded-full
                  w-8
                  h-8
                  flex
                  items-center
                  justify-center
                  hover:bg-red-50
                  active:bg-red-100
                "
                aria-hidden="true"
                @mouseover="state.enableTileTooltip = false"
                @mouseout="state.enableTileTooltip = true"
                @click.prevent="deleteCompetitorItem"
              >
              </i>
              <i
                v-tooltip.top="{ content: 'Manage Competitors' }"
                class="
                  fa fa-pencil
                  text-gray-900
                  rounded-full
                  w-8
                  h-8
                  flex
                  items-center
                  justify-center
                  hover:bg-gray-100
                  active:bg-gray-300
                "
                aria-hidden="true"
                @mouseover="state.enableTileTooltip = false"
                @mouseout="state.enableTileTooltip = true"
                @click.prevent="
                  EventBus.$emit('show-manage-competitors-modal', reportData)
                "
              >
              </i>
            </div>
          </div>

          <div class="w-full flex justify-center items-center">
            <template
              v-for="(account, index) in accountsList"
              :key="`accountImage_${index}`"
            >
              <div>
                <img
                  v-if="account.competitor_id"
                  v-tooltip.bottom="{
                    content: account.name ? account.name : account.slug,
                  }"
                  :src="account.image"
                  alt=""
                  class="w-9 h-9 m-1 rounded-full border border-[#E9EFF6]"
                  @mouseover="state.enableTileTooltip = false"
                  @mouseout="state.enableTileTooltip = true"
                  @error="
                    $event.target.src =
                      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
              </div>
            </template>
          </div>

          <p class="flex justify-start items-end text-[#979CA0] text-xs">
            Created: <span class="ml-1"> {{ momentWrapper(createdAt).formatDateTime() }}</span>
          </p>
        </div>
      </div>
    </router-link>
    <div
      v-else
      class="
        bg-white
        p-3
        rounded-md
        select-none
        cursor-pointer
        h-[9.5rem]
        text-gray-700
        !border-2 !border-dashed
        group
        hover:!border-gray-700
        !border-gray-500
      "
      @click="EventBus.$emit('show-manage-competitors-modal')"
    >
      <div
        class="
          group-hover:text-gray-900
          flex flex-col
          items-center
          justify-center
          w-full
          h-full
        "
      >
        <i class="fa fa-plus text-2xl" aria-hidden="true"></i>
        <p class="text-sm">Create New</p>
      </div>
    </div>
  </div>
</template>

<script setup>
// libraries
import { computed, inject, reactive, defineProps } from 'vue'
import { EventBus } from "@common/lib/event-bus";

// constants
import { swalAttributes } from '@common/constants/common-attributes'
import { useStore } from '@state/base'
import useDateFormat from "@common/composables/useDateFormat";

const props = defineProps({
  reportId: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: 'Untitled',
  },
  isEmpty: {
    type: Boolean,
    default: false,
  },
  createdAt: {
    type: String,
    default: '',
  },
  accountsList: {
    type: Array,
    default: () => [],
  },
  reportData: {
    type: Object,
    default: () => {},
  },
})
const root = inject('root')
const { $bvModal, $route } = root
const { getters } = useStore()
const { momentWrapper } = useDateFormat()

const state = reactive({
  showOptions: false,
  enableTileTooltip: true,
})

/**
 * @description method to delete a specific report, requires the report ID for the api call and name to show in case of error message.
 * @returns {Promise<void>}
 */
const deleteCompetitorItem = async () => {
  const res = await $bvModal.msgBoxConfirm(
      'Are you sure you want to delete?',
      {
        title: 'Delete Response',
        ...swalAttributes(),
      }
  )
  if (res) {
    EventBus.$emit('delete-competitors-report', {reportId: props.reportId, reportName: props.name})
  }
}


const getReportRedirectLink = computed(() => {
  if ($route.name === 'instagram_competitor_overview_v3') {
    return 'instagram_competitor_v3'
  } else if ($route.name === 'facebook_competitor_overview_v3') {
    return 'facebook_competitor_v3'
  }
  return ''
})
</script>
