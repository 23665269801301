<template>
  <div>
    <div :class="customClass" class="pb-2">
      <div class="w-full bg-white relative">
        <div class="absolute bg-gray-500 w-[2px] left-8 h-[98%]"></div>
        <div class="flex px-3 py-3 font-bold">
          <img
              class="rounded-full object-cover border border-solid border-black z-10"
              width="40"
              height="40"
              :src="account?.platform_logo"
              alt=""
              @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
          />
          <div>
            <div class="px-2 font-bold">
            {{ account?.platform_name }}
          </div>
            <div
                v-if="getDescription"
                class="items-center px-2 py-1 pb-3"
            >
      <span
          class="whitespace-pre-wrap font-normal leading-4"
          v-html="getDescription"
      ></span>
              <span
                  v-if="getDescription.length > charLimit && loadMore"
                  class="text-blue-300 cursor-pointer"
                  @click="changeLoadMore"
              >
            Load More</span
              >
              <span
                  v-if="getDescription.length > charLimit && !loadMore"
                  class="text-blue-300 cursor-pointer"
                  @click="changeLoadMore"
              >
            Show Less</span
              >
            </div>
          </div>
        </div>
        <ThreadsMultimediaPreview
            v-if="shouldRenderThreadsPreview"
            :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
            :detail="detail"
            :is-reply="true"
            :hide-footer="hideFooter"
            @display-file="displayFile"
        />
        <!-- Link Preview -->
        <ThreadsLinkPreview v-else-if="detail.url && detail.multimedia.length > 0" :detail="detail" />
        <div class="flex items-center ml-12 px-3 py-2 reaction-icons space-x-5 text-xl mt-2">
          <i class="far fa-heart"></i>
          <i class="far fa-comment"></i>
          <i class="far fa-paper-plane"></i>
        </div>

        <div v-if="multiThreadsDetailItem.hasOwnProperty('status')" class="comments-status-box ml-12">
          <p v-if="multiThreadsDetailItem?.link " class="ml-2">
            <span class="status published"></span>
            <span class="text">Published</span>
            <span> | [Thread has been posted successfully.]</span>
          </p>
          <p v-else-if="!multiThreadsDetailItem?.status && !multiThreadsDetailItem?.link" class="ml-2">
            <span class="status failed"></span>
            <span class="text">Failed</span>
            <span> | {{multiThreadsDetailItem?.error}}</span>
          </p>
        </div>

      </div>
    </div>
  </div>
  <VueEasyLightbox
      :visible="visible"
      :imgs="detail.image"
      :index="selectedImageIndex"
      @hide="visible = false"
  />
</template>

<script setup>
import {ref, computed, inject} from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import ThreadsMultimediaPreview from '@src/modules/composer_v2/components/SocialPreviews/ThreadsMultimediaPreview'
import {EventBus} from '@common/lib/event-bus'
import {parseDescriptionHtml} from '@common/lib/helper'
import ThreadsLinkPreview from "@src/modules/composer_v2/components/SocialPreviews/ThreadsLinkPreview.vue";

// Props
const props = defineProps({
  multiThreadsDetailItem: {
    type: Object,
    default: {},
    required: false
  },
  customClass: {
    type: String,
    default: '',
  },
  account: {
    type: [Object, Array],
    default: () => ({}),
    required: true,
  },
  detail: {
    type: Object,
    default: () => {},
    required: false,
  },
  type: {
    type: String,
    default: 'composer',
  },
  whiteBg: {
    type: Boolean,
    default: true,
  },
})

// Reactive data
const loadMore = ref(true)
const charLimit = ref(500)
const visible = ref(false)
const selectedImageIndex = ref(0)
const root = inject('root')
const {$bvModal} = root

// Computed properties
const shouldRenderThreadsPreview = computed(() => {
  return (props.detail?.multimedia?.length || props.detail?.image?.length) && !props.detail.url
})

const getDescription = computed(() => {
  let msg = props.detail.message || ''
  msg = parseDescriptionHtml(msg, 5000)
  if (msg && msg.length > charLimit.value && loadMore.value) {
    msg = msg.substr(0, charLimit.value).concat('...')
    return msg
  }
  return msg
})

// Methods
const changeLoadMore = () => {
  loadMore.value = !loadMore.value
}

const getHostOnly = (url) => {
  const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
  return urlParts[1] ? urlParts[1] : ''
}

const displayFile = (type, media, index) => {
  EventBus.$emit('displayFile', {
    type,
    media,
    index,
  })
  $bvModal.show('display-file-modal')
}
</script>

