<template>
  <div
    ref="sidebar"
    class="inbox_sidebar"
    :class="{ 'inbox_sidebar--expended': expended }"
    @mouseover="expendOnHover"
    @mouseleave="colapsedOnLeave"
  >
    <div class="inbox_header">
      <div class="inbox_header__label">Inbox</div>
      <div
        id="syncBtn"
        v-tooltip.right="{
          content: 'Sync your data across all of your social accounts',
          container: `#syncBtn`,
        }"
        class="inbox_header__icon"
        @click="syncAllDataFromServer"
      >
        <i :class="{ rotating: rotatingActive }" class="icon-Refresh-2"></i>
      </div>

      <transition name="fade">
        <div
          v-if="expended"
          v-tooltip.right="{
            content: 'Minimize Sidebar',
          }"
          class="inbox_header__colapse-btn"
          style="transition-delay: 1.5s"
          @click="expendSideBar"
        >
          <i class="far fa-compress"></i>
        </div>
      </transition>
    </div>

    <div class="inbox_nav-wrapper">
      <nav class="inbox_nav">
        <div
          v-for="option in conversationOptions"
          :id="option.key"
          :key="option.key"
          v-tooltip.right="{
            content: option.name,
            container: `#${option.key}`,
            classes: 'tooltip-hide',
          }"
          class="inbox_nav__item"
          :class="option.isActive !== '' ? 'inbox_nav__item--active' : ''"
          @click="setSelectedOption(option.key)"
        >
          <i class="fal" :class="option.image"></i>
          <span>{{ option.name }}</span>
          <span>{{ conversationCount[option.key] }}</span>
        </div>
      </nav>

      <hr class="inbox_sidebar__divider" />

      <div
        v-if="expended"
        class="inbox_filter"
        :style="expended ? { display: 'block' } : { display: 'none' }"
      >
        <div v-b-toggle.collapse-2 class="inbox_filter__toggler">
          <span>Filters</span>
          <i class="fal fa-angle-down"></i>
        </div>
        <b-collapse id="collapse-2" visible>
          <template v-if="commonMethods.isEmpty(channels)">
            <div class="flex items-center justify-between px-4">
              <span class="uppercase font-medium text-sm text-gray-700">
                By Platform
              </span>
              <div class="field_group">
                <div class="checkbox_container">
                  <span class="text-sm font-medium text-gray-700">All</span>
                  <label>
                    <input
                      id="platform_all"
                      v-model="all"
                      type="checkbox"
                      checked="checked"
                      @change="toggleSelectAllStatus"
                    />
                    <span class="check !left-[25px]"></span>
                  </label>
                </div>
              </div>
            </div>
          </template>

          <div
            v-if="!commonMethods.isEmpty(channels)"
            class="inbox_filter__no-channel"
          >
            <div>
              <span> No Social Accounts Connected </span>
            </div>
            <div @click.prevent="profileRouter('social')">
              <button
                class="btn large_btn gradient_btn btn_loader pl-3"
                style="color: white"
              >
                Connect
              </button>
            </div>
          </div>

          <div class="inbox_filter-channels">
            <div v-for="(platform, index) in channels" :key="index" class="">
              <AccountDropdown
                v-if="platform.length > 0"
                :ref="`${index}_account_dropdown`"
                :select-all="true"
                :channel="index"
                :open="true"
                @selectChannelAccounts="selectAllPlatformChannel"
              >
                <template v-slot:channel>
                  <div class="flex items-center">
                    <img
                      :src="
                        require('@assets/img/integration/' +
                          getSocialIcon(index))
                      "
                      alt=""
                      class="w-5"
                    />
                    <span class="ml-2 text-sm font-medium text-black-300">
                      {{ index.at(0).toUpperCase() + index.substring(1) }}
                    </span>
                  </div>
                </template>
                <template v-slot:accounts>
                  <template v-for="(account, i) in platform" :key="i">
                    <div>
                      <CstAccountCheckbox
                        :size="'small'"
                        :validity="false"
                        :account="account"
                        :channel-id="account.platform_id"
                        :account-image="
                          account.platform_image
                            ? account.platform_image
                            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                        "
                        :account-name="account.platform_name"
                        :account-type="account.type"
                        :account-platform="account.platform_type"
                        :is-checked="account.is_selected"
                        :disabled="hasInboxPermission(account)"
                        @change="
                          (e) => {
                            setChannel(e, index)
                          }
                        "
                      />
                    </div>
                  </template>
                </template>
              </AccountDropdown>
            </div>
          </div>

          <template v-if="tags.length > 0">
            <div class="flex items-center justify-between px-4">
              <span class="uppercase font-medium text-sm text-gray-700">
                By Tag
              </span>
              <div class="field_group">
                <div class="checkbox_container">
                  <span class="text-sm font-medium text-gray-700">All</span>
                  <label>
                    <input
                      id="tag_all"
                      v-model="allTags"
                      type="checkbox"
                      checked="checked"
                      @change="commonMethods.setUnsetAll(temp, 'tags')"
                    />
                    <span class="check !left-[25px]"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="inbox_filter-tags !m-0">
              <div
                v-for="tag in tags"
                :key="tag._id"
                class="cst-account-checkbox cursor-pointer px-3 py-2 pl-6 m-0 flex justify-between items-center hover:bg-cs-foggray opacity-100"
              >
                <div class="inbox_filter-tag__info">
                  <div
                    class="inbox_filter-tag__color"
                    :class="[tag.tag_color]"
                  ></div>
                  <div class="inbox_filter-tag__text">{{ tag.tag_name }}</div>
                </div>
                <div class="inbox_filter-tag__check">
                  <div class="field_group !ml-8 !-mt-2">
                    <div class="checkbox_container">
                      <label :for="tag._id">
                        <input
                          :id="tag._id"
                          v-model="selectedTags"
                          type="checkbox"
                          checked="checked"
                          :value="tag._id"
                          @change="setTags(tag._id)"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-collapse>
      </div>

      <div
        v-else
        id="more-btn"
        v-tooltip.right="{
          content: 'Maximize Sidebar',
          container: `#more-btn`,
          classes: 'tooltip-hide',
        }"
        :style="!expended ? { display: 'block' } : { display: 'none' }"
        class="inbox_nav__more"
        @click="expendSideBar"
      >
        <i class="far fa-ellipsis-h"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
  conversationCounts,
  conversationOptions,
  socialChannelsArray,
} from '@src/modules/inbox/store/inbox-filters.js'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { commonMethods } from '@src/modules/inbox/store/common-methods.js'
import _ from 'underscore'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import AccountDropdown from '@ui/Dropdown/AccountDropdown'
import CstAccountCheckbox from '@ui/CheckBox/CstAccountCheckBox'
import {EventBus} from "@common/lib/event-bus";
export default {
  components: {
    AccountDropdown,
    CstAccountCheckbox,
  },
  mixins: [ComposerHelper],
  data() {
    return {
      rotatingActive: false,
      temp: this,
      conversationOptions,
      commonMethods,
      selectedOption: 'UNASSIGNED',
      conversationCount: conversationCounts,
      tags: [],
      allTags: false,
      channels: {},
      all: true,
      allChannels: {},
      selectedChannels: {},
      selectedTags: [],
      inbox_link_sidebar_channel: '',
      expended: false,
      initialWidth: null,
      isSidebarHover: false,
      hoverTimeout: null,
    }
  },
  computed: {
    ...mapGetters([
      'getPlan',
      'getActiveWorkspace',
      'getActiveWorkspaceAddonsLifetime',
      'getProfile',
      'getInboxFilter'
    ]),
    hasSocialInboxAccess() {
      /* eslint-disable */
      if (
        this.getActiveWorkspaceAddonsLifetime.hasOwnProperty(
          'social_inbox_beta'
        ) &&
        this.getActiveWorkspaceAddonsLifetime.social_inbox_beta
      ) {
        return true
      }

      if (
        this.getActiveWorkspaceAddonsLifetime.hasOwnProperty('social_inbox') &&
        this.getActiveWorkspaceAddonsLifetime.social_inbox
      ) {
        return true
      }
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      if (
        this.getPlan &&
        this.getPlan.subscription.hasOwnProperty('default_add_ons')
      ) {
        return !!this.getPlan.subscription.default_add_ons.social_inbox
      }
      return false
    },
  },
  created() {
    console.log("CREATED::inboxLinksidebar.vue")
    EventBus.$on('fetched-social-accounts', () => {
      this.reFetch()
    })
  },
  async beforeMount() {
    console.log("BEFORE-MOUNT::inboxLinkSidebear.vue")
    this.selectedOption =
      this.$route.params?.filter != null
        ? this.$route.params.filter.toUpperCase()
        : 'UNASSIGNED'
    await this.filteredChannels()
    await commonMethods.setUnsetAll(this, 'channels', false)
    await this.fetchDetails(true)
    if (this.$route.params?.filter) {
      this.setSelectedOption(this.$route.params.filter.toUpperCase())
    }
    this.executeFilter()
    this.redirectToConversation()
  },
  mounted() {
    this.temp = this
    const pusher = pusherAuthSocketInbox(
      this.getProfile._id,
      this.getProfile.full_name,
      this.getProfile.email
    )
    this.inbox_link_sidebar_channel = pusher.subscribe(
      'presence-inbox-link-sidebar-' + this.getActiveWorkspace._id
    )
    this.bindPusherConversations()
  },
  beforeUnmount() {
    EventBus.$off('workspace-changed')
    EventBus.$off('fetched-social-accounts')
    if (this.getActiveWorkspace._id !== null) {
      this.removeSocketListners(this.getActiveWorkspace._id)
      if (this.inbox_link_sidebar_channel) {
        const channelForUpdateSideBar =
          'channel-update-sidebar-' + this.getActiveWorkspace._id
        const channelForUpdateResponse =
          'channel-update-bulk-response-' + this.getActiveWorkspace._id

        this.inbox_link_sidebar_channel.unbind(channelForUpdateSideBar)
        this.inbox_link_sidebar_channel.unbind(channelForUpdateResponse)
        this.inbox_link_sidebar_channel.unsubscribe(
          'presence-inbox-link-sidebar-' + this.getActiveWorkspace._id
        )
      }
    }
  },
  methods: {
    ...mapActions(['fetchSideBarDetails', 'syncAllData']),
    ...mapMutations(['setInboxFilter']),
    colapsedOnLeave() {
      this.isSidebarHover = false
    },
    expendOnHover() {
      if (this.isSidebarHover === false) {
        this.isSidebarHover = true
      }
    },
    expendSideBar() {
      if (this.expended) {
        this.$refs.sidebar.style.width = '58px'
        setTimeout(() => (this.expended = !this.expended), 280)
      } else {
        this.$refs.sidebar.style.width = '250px'
        this.expended = !this.expended
      }
    },
    bindPusherConversations() {
      const tail = this.getActiveWorkspace._id
      const channelForUpdateSideBar = 'channel-update-sidebar-' + tail
      const channelForUpdateResponse = 'channel-update-bulk-response-' + tail

      const temp = this

      this.inbox_link_sidebar_channel.bind(channelForUpdateSideBar, (data) => {
        if (data.update === true) {
          console.log('Updated ...')
          temp.fetchConversationCounts(false, this.getInboxFilter.inbox_types)
        }
      })
      this.inbox_link_sidebar_channel.bind(channelForUpdateResponse, (data) => {
        if (data.update === true) {
          console.log('Updated ...')
          if (data.updated_conversations > 0) {
            temp.fetchDetails(false, this.getInboxFilter.inbox_types)
            const action =
              data.action === 'assigned'
                ? data.status
                  ? data.action
                  : 'unassigned'
                : data.action === 'marked_done'
                ? 'marked as done'
                : data.action
            this.alertMessage(
              '<p>' +
                data.updated_conversations +
                ' conversation(s) ' +
                action +
                '.</p>',
              'info'
            )
          }
        }
      })

      EventBus.$off('update-side-bar')
      EventBus.$on('update-side-bar', ({typeFilters, isDefault = false}) => {
        console.log('update-side-bar')
        this.fetchDetails(isDefault, typeFilters)
      })
    },
    redirectToConversation() {
      console.log('route params .. ', this.$route.params)

      if (!this.hasSocialInboxAccess) {
        return
      }

      if (this.$route.params.conversation) {
        this.$router.push({
          path:
            '/' +
            this.getActiveWorkspace.slug +
            '/inbox/' +
            this.selectedOption?.toLowerCase() +
            '/' +
            this.$route.params.conversation,
        })
      } else {
        this.$router.push({
          path:
            '/' +
            this.getActiveWorkspace.slug +
            '/inbox/' +
            this.selectedOption.toLowerCase() +
            '/conversation',
        })
      }
    },
    setSelectedOption(selectedIndex) {
      console.log(selectedIndex)
      const temp = this
      _.forEach(this.conversationOptions, function (e, k) {
        if (temp.conversationOptions[k].key === selectedIndex) {
          e.isActive = 'active'
        } else {
          e.isActive = ''
        }
      })
      this.selectedOption = selectedIndex
      this.executeFilter()
      this.redirectToConversation()
    },
    profileRouter(value) {
      this.$router.push({
        name: value,
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
      $('#profile_dropdown_id .dropdown_header').dropdown('toggle')
    },
    hasInboxPermission(channel) {
      return (
        channel.platform_type === 'facebook' &&
        channel.hasOwnProperty('inbox_permission') &&
        channel.inbox_permission !== 'granted'
      )
    },
    async filteredChannels() {
      socialChannelsArray.forEach((item) => {
        if(item.name === 'twitter') return
        const getter = this.$store.getters[item.getter]
        const channelItems = getter.hasOwnProperty('items')
          ? getter.items
          : getter
        channelItems.forEach((channel) => {
          const channelObj = {}
          if (item.name === 'facebook') {
            if (channel.type !== 'Page') return
            const inboxPermission = _.findWhere(channel.permissions, {
              permission: 'pages_messaging',
            })
            channelObj.inbox_permission = inboxPermission
              ? inboxPermission.status
              : 'not_granted'
          }
          if (
            item.name === 'instagram' &&
            !(channel.hasOwnProperty('is_inbox') && channel.is_inbox)
          ) {
            return
          }
          if (item.name === 'linkedin') {
            if (channel.type !== 'Page') return
          }
          channelObj.platform_id = channel[item.key]
          channelObj.platform_image = channel.image
          channelObj.platform_type = item.name
          channelObj.type = channel.type ? channel.type : 'Profile'
          channelObj.is_selected = true
          if (item.name === 'gmb') {
            channelObj.platform_name = channel.location_name
            channelObj.full_name = channel.platform_name
            channelObj.platform_image = channel.account_image
              ? channel.account_image
              : 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
          } else {
            channelObj.platform_name = channel.name
          }
          if (!(item.name in this.channels)) {
            this.channels[item.name] = []
          }
          this.channels[item.name].push(channelObj)
        })
      })
    },
    async syncAllDataFromServer() {
      if (!this.rotatingActive) {
        this.rotatingActive = true
        const resp = await this.syncAllData({
          workspace_id: this.getActiveWorkspace._id,
        })
        if (resp.isValid) {
          let toastType = resp.message.includes('cooldown') ? 'warn' : 'success'
          await this.$store.dispatch('toastNotification', {
            message: resp.message,
            type: toastType,
          })
          this.stopRotationAfter()
        } else {
          this.stopRotationAfter()
        }
      }
    },
    stopRotationAfter() {
      setTimeout(() => {
        this.rotatingActive = false
      }, 7000)
    },
    async fetchDetails(
      isDefault,
      typeFilters = ['conversation', 'post', 'review']
    ) {
      const payload = {
        workspace_id: this.getActiveWorkspace._id,
        conversation_counts: this.conversationCount,
        channels: _.flatten(this.selectedChannels),
        all_channels: this.selectedChannels,
        tags: this.selectedTags,
        inbox_types: typeFilters,
        search_term: '',
        default: isDefault,
      }

      const resp = await this.fetchSideBarDetails(payload)
      if (resp.isValid) {
        this.executeFilter()
        this.redirectToConversation()
        this.conversationCount = resp.conversation_counts
        if (isDefault) {
          this.tags = _.sortBy(resp.tags, 'tag_name')
        }

      }
    },
    async fetchConversationCounts(
      isDefault,
      typeFilters = ['conversation', 'post']
    ) {
      const resp = await this.fetchSideBarDetails({
        workspace_id: this.getActiveWorkspace._id,
        conversation_counts: this.conversationCount,
        channels: _.flatten(this.selectedChannels),
        all_channels: this.selectedChannels,
        tags: this.selectedTags,
        inbox_types: typeFilters,
        search_term: '',
        default: isDefault,
      })
      if (resp.isValid) {
        this.conversationCount = resp.conversation_counts
      } else {
      }
    },

    async selectedOnly(platform, platformId) {
      // set all to false
      this.all = false

      // get all the channel keys .. facebook, instagram, twitter
      const channelNames = Object.keys(this.channels)

      // iterate over each channel name
      for (const index in channelNames) {
        // set true and false
        this.allChannels[channelNames[index]] = false

        // set as empty
        this.selectedChannels[channelNames[index]] = []
      }

      // set the selected one
      this.selectedChannels[platform].push(platformId)

      // make the service call
      this.fetchDetails(false, this.getInboxFilter.inbox_types)
      this.executeFilter()
    },

    setChannel(Args, platform) {
      console.log('set channels ->', Args, platform)
      const { channelId, value } = Args
      const currentChannel = this.channels[platform].find(
        (item) => item.platform_id === channelId
      )
      const currentChannelIndex = this.selectedChannels[platform].findIndex(
        (item) => item === currentChannel.platform_id
      )
      if (currentChannelIndex === -1 && value) {
        this.selectedChannels[platform].push(channelId)
      } else {
        this.selectedChannels[platform].splice(currentChannelIndex, 1)
      }
      this.channels[platform].map((item) => {
        if (item.platform_id === channelId) item.is_selected = value
      })

      this.$refs[`${platform}_account_dropdown`][0].checked = this.channels[
        platform
      ].every((account) => account.is_selected)
      this.all = Object.keys(this.channels).every((key) => {
        return this.$refs[`${key}_account_dropdown`][0].checked
      })
      this.fetchDetails(false)
      this.executeFilter()
    },
    selectAllPlatformChannel(platform, value) {
      console.log('select all ->', platform, value)
      let ids = []
      if (value) {
        this.channels[platform].map((item) => {
          ids.push(item.platform_id)
          item.is_selected = value
        })
          this.selectedChannels[platform] = ids
      } else {
        this.channels[platform].map((item) => {
          item.is_selected = value
        })
        this.selectedChannels[platform] = []
      }
      this.fetchDetails(false)
      this.executeFilter()
      this.$refs[`${platform}_account_dropdown`][0].checked = this.channels[
        platform
      ].every((account) => account.is_selected)
      this.all = Object.keys(this.channels).every((key) => {
        return this.$refs[`${key}_account_dropdown`][0].checked
      })
    },
    selectAllPlatformValue(platform) {
      const refName = `${platform}_account_dropdown`
      if (
        this.selectedChannels[platform].length ===
        this.channels[platform].length
      ) {
        this.$refs[refName][0].checked = true
        this.allChannels[platform] = true
      } else {
        this.$refs[refName][0].checked = false
        this.allChannels[platform] = false
      }
    },
    selectAll() {
      for (const [key, value] of Object.entries(this.channels)) {
        if (value.length === this.selectedChannels[key].length) {
          this.all = true
        } else {
          this.all = false
          return
        }
      }
    },

    setTags() {
      this.allTags = this.tags.length === this.selectedTags.length
      this.fetchDetails(false, this.getInboxFilter.inbox_types)
      this.executeFilter()
    },

    executeFilter() {
      this.rotatingActive = true
      const filter = {
        action: this.selectedOption,
        workspace_id: this.getActiveWorkspace._id,
        all_channels: this.selectedChannels,
        tags: this.selectedTags,
        inbox_types: ['conversation', 'post'],
        order_by: 'desc',
      }
      this.setInboxFilter(filter)
      setTimeout(() => {
        this.rotatingActive = false
      }, 2000)
    },
    removeSocketListners(value) {
      const channelForUpdateSideBar = 'channel-update-sidebar-' + value
      this.inbox_link_sidebar_channel.unbind(channelForUpdateSideBar)
    },
    async reFetch() {
      console.debug('REFECTCH')
      this.channels = {}
      this.selectedChannels = {}
      await this.filteredChannels()
      await commonMethods.setUnsetAll(this, 'channels', false)
      await this.fetchDetails(true)
      this.executeFilter()
      this.redirectToConversation()
    },

    /**
     * @description toggles the checked status of all the account drop-downs.
     */
    toggleSelectAllStatus() {
      commonMethods.setUnsetAll(this.temp, 'channels')
      Object.keys(this.channels).forEach((key) => {
        this.$refs[`${key}_account_dropdown`][0].checked = this.all
      })
    },
  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (oldVal !== null) {
          this.removeSocketListners(oldVal)
        }
        this.bindPusherConversations()
        if(this.getInboxFilter?.workspace_id !== newVal){
          this.setInboxFilter(null)
        }
        await this.$store.dispatch('fetchSocialAccounts')
      }
    },
    '$route.params.filter': async function (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== undefined) {
        this.setSelectedOption(newVal?.toUpperCase())
        this.executeFilter()
      }
    },
    '$route.params.conversation': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.$route.query.notification === true) {
          this.setSelectedOption(this.$route.params.filter.toUpperCase())
          this.executeFilter()
        }
      }
    },
    isSidebarHover: function (val) {
      if (window.innerWidth <= 1377) {
        this.expendSideBar()
      }
    },
  },
}
</script>

<style lang="less" scoped>
/*social channel styles*/
.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .social_channels {
  padding: 5px 0;
}

.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .social_channels
  .special_block_inner {
  border-radius: 0;
  box-shadow: none;
}

.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .social_channels
  li {
  padding-left: 25px;
}

.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .social_channels
  li:first-child {
  padding-left: 15px;
}

.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .social_channels
  li
  .checkbox_input_image
  label
  .text_block
  p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

/*filter by tag*/
.inbox_main_view
  .social_profiles_sidebar
  .sidebar_inner
  .collapse_link
  .collapse_body
  .inner-heading {
  padding-top: 6px;
}

.square_check_icon {
  span {
    cursor: pointer;
  }
}
</style>
