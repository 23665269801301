<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      showDropdown: false,
      text: '',
      origin: {}
    }
  },
  computed: {
    ...mapGetters([
      'getPocketContentPreview',
      'getPocketLoaders',
      'getBlogEditorSelector'
    ]),
    isShowArticlePreview () {
      return !this.getPocketLoaders.previewLoader
    }
  },

  methods: {
    ...mapActions(['archive']),
    closeMenu () {
      this.showDropdown = false
    },
    openMenu (e) {
      this.text = ''
      // get selected text
      this.showDropdown = false
      if (window.getSelection) {
        this.text = window.getSelection().toString()
        if (this.text.trim() !== '') {
          this.showDropdown = true
        }
      }
      // set dropdown position
      const parent = $('#article_wrapper')
      this.origin = {
        left: e.pageX - parent.offset().left,
        top: e.pageY - parent.offset().top
      }
    },
    addTextToEditor (type) {
      // this.checkEditorRange()
      // add text by "selected" or "full" content type
      // selected by mouse cursor
      if (type === 'selected') {
        if (this.text.trim() !== '') {
          this.setCKEditorHtml(this.text, true)
        }
        // full by click on "add to editor" button
      } else if (type === 'full') {
        if (this.getPocketContentPreview.description.length) {
          this.setCKEditorHtml(this.getPocketContentPreview.description, true)
          // this.getBlogEditorSelector.insertHtml(this.getPocketContentPreview.description)
        }
      }
    }
  }
}
</script>

<template>
  <b-modal
    id="pocket_preview_modal"
    ref="pocket_preview_modal"
    modal-class="full_height article_preview_modal pocket_preview_modal right side_slide_right w_1150"
    hide-footer
    hide-header
  >
    <!--<div class="modal_head  ">-->
    <!--&lt;!&ndash;<h2>Video Preview</h2>&ndash;&gt;-->
    <!--<button type="button" class="close" data-dismiss="modal" @click="$bvModal.hide('article_preview_modal')">&times;</button>-->
    <!--</div>-->

    <div v-if="isShowArticlePreview" class="modal_body d-flex height">
      <div class="modal_right">
        <div class="modal_head">
          <div class="d-flex align-items-center">
            <div class="left_content">
              <span class="title">Content Preview</span>
            </div>
            <div class="right_content">
              <button
                class="btn border_btn gradient_btn"
                @click="addTextToEditor('full')"
              >
                <span>Add Full Article to Editor</span>
              </button>
              <button
                class="btn_close btn border_btn royal_blue"
                @click="$bvModal.hide('pocket_preview_modal')"
              >
                <span>&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="article_content">
          <div id="article_wrapper" class="article_wrapper">
            <h2 class="title" v-html="getPocketContentPreview.title"></h2>
            <div class="author_detail">
              <p>
                {{ getPocketContentPreview.domain_url }}
              </p>
            </div>

            <template v-if="getPocketContentPreview.description">
              <div
                v-click-away="closeMenu"
                class="article_content_inner"
                @click.left="closeMenu"
                @contextmenu.prevent="openMenu($event)"
                v-html="getPocketContentPreview.description"
              ></div>
            </template>

            <div
              v-if="showDropdown"
              class="right_click_dropdown"
              :style="{ left: origin.left + 'px', top: origin.top + 'px' }"
            >
              <ul>
                <li @click="addTextToEditor('selected')">Add to editor</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="modal_body d-flex height align-items-center justify-content-center"
    >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
  </b-modal>

  <!--<div class="full_height modal fade article_preview_modal pocket_preview_modal right side_slide_right w_1150" id="pocket_preview_modal">-->
  <!--<div class="modal-dialog">-->
  <!--<div class="modal-content">-->

  <!--<div class="modal_body d-flex height" v-if="isShowArticlePreview">-->

  <!--<div class="modal_right">-->

  <!--<div class="modal_head">-->
  <!--<div class="d-flex align-items-center">-->
  <!--<div class="left_content">-->
  <!--<span class="title">Content Preview</span>-->
  <!--</div>-->
  <!--<div class="right_content">-->
  <!--<button @click="addTextToEditor('full')"-->
  <!--class="btn border_btn royal_blue">-->
  <!--<span>Add Full Article to Editor</span>-->
  <!--</button>-->
  <!--<button data-dismiss="modal" class="btn_close btn border_btn royal_blue">-->
  <!--<span>&times;</span>-->
  <!--</button>-->

  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <!--<div class="article_content">-->
  <!--<div class="article_wrapper" id="article_wrapper">-->
  <!--<h2 class="title" v-html="getPocketContentPreview.title"></h2>-->
  <!--<div class="author_detail">-->
  <!--<p>-->
  <!--{{getPocketContentPreview.domain_url}}-->
  <!--</p>-->
  <!--</div>-->

  <!--<template v-if="getPocketContentPreview.description">-->
  <!--<div @click.left="closeMenu" v-click-away="closeMenu"-->
  <!--@contextmenu.prevent="openMenu($event)"-->
  <!--class="article_content_inner"-->
  <!--v-html="getPocketContentPreview.description"></div>-->
  <!--</template>-->

  <!--<div v-if="showDropdown" class="right_click_dropdown"-->
  <!--:style="{ left: origin.left + 'px', top: origin.top + 'px' }">-->
  <!--<ul>-->
  <!--<li @click="addTextToEditor('selected')">Add to editor</li>-->
  <!--</ul>-->
  <!--</div>-->

  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <!--</div>-->
  <!--<div class="modal_body d-flex height align-items-center justify-content-center" v-else>-->
  <!--<beat-loader :color="'#436aff'"></beat-loader>-->
  <!--</div>-->

  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</template>
