<template>
  <div class="threaded-tweets social_content_box">
    <div class="grid-flex">
      <div class="cell-12">
        <div class="threaded-tweets__head">
          <div class="label">
            Add Threaded Tweets
            <div class="toggle-section">
              <div
                v-if="
                  getAccountSelection['twitter'] &&
                  getAccountSelection['twitter'].length > 0
                "
                class="d-flex align_center"
              >
                <label class="switch-radio">
                  <input
                    v-model="getTwitterOptions.has_threaded_tweets"
                    type="checkbox"
                    :disabled="!isThreadedTweetsEnabled"
                  />
                  <div
                    class="slider round"
                    :class="{ 'disabled ': !isThreadedTweetsEnabled }"
                  ></div>
                </label>
              </div>
            </div>
          </div>
          <div class="selectedAccount">
            <template v-for="account in selectedAccountList" :key="account._id">
              <div
                class="checkbox_input_image checkbox_social_profile"
                style="position: relative"
              >
                <input
                  :id="'selection_comment_' + account[account.channel.key]"
                  type="checkbox"
                  :value="account[account.channel.key]"
                />
                <label
                  v-tooltip="{
                    content: channelTooltipHTML(account.channel.name, account),
                    classes: 'team_tooltip',
                    allowHTML: true,
                    theme: 'light',
                  }"
                  :for="'selection_comment_' + account[account.channel.key]"
                  class="checkbox_right"
                  data-cy="account-selection"
                >
                  <individual-channel-tooltip
                    :account="account"
                    :type="account.channel.name"
                  ></individual-channel-tooltip>
                </label>
              </div>
            </template>
          </div>
        </div>
        <!-- multiple tweets -->
        <div
          v-for="(threaded_tweet, key) in getTwitterOptions.threaded_tweets"
          :key="key"
        >
          <div class="threaded-tweets__wrapper">
            <div class="threaded-tweets__text-field">
              <textarea
                :id="'ThreadedTweets_' + key"
                v-auto-height
                :maxlength="280"
                placeholder="Add your threaded tweet here..."
                :value="threaded_tweet.message"
                @input="typedTextArea($event, key)"
              ></textarea>
              <!-- :class ="{ 'show-error':(socialPostSpecificBoxContentErrors('twitter',threaded_tweet))}" -->
            </div>
            <div
              v-show="getTwitterOptions.threaded_tweets.length > 1"
              class="threaded-tweets__close-btn"
            >
              <div @click.prevent="closeTweet(key)">
                <i class="fa fa-close" data-cy="close-tweet"> </i>
              </div>
            </div>
          </div>
          <div class="threaded-tweets__bottom">
            <div class="bottom-options">
              <form method="post" enctype="multipart/form-data" class="d-none">
                <input
                  :id="'threadedTweetsSharingImageUpload_' + key"
                  type="file"
                  name="socialFile"
                  accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
                  :multiple="true"
                  data-index=""
                  @change="
                    uploadSharingImage($event, 'ThreadedTweets', Number(key))
                  "
                />
              </form>

              <form method="post" enctype="multipart/form-data" class="d-none">
                <input
                  :id="'threadedTweetsSharingImageUpdate_' + key"
                  type="file"
                  name="socialFileUpdate"
                  accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
                  data-index=""
                  @change="
                    processsUpdateSharingImage(
                      $event,
                      'ThreadedTweets',
                      Number(key),
                    )
                  "
                />
              </form>

              <form method="post" enctype="multipart/form-data" class="d-none">
                <input
                  :id="'threadedTweetsSharingVideoUpload_' + key"
                  type="file"
                  name="socialFile"
                  accept=".avi,.mov,.m4v,.mp4"
                  data-index=""
                  @change="
                    uploadSharingVideo({
                      event: $event,
                      type: 'ThreadedTweets',
                      threadedTweetIndex: key,
                    })
                  "
                />
              </form>
              <div class="bottom-options-single">
                <i
                  v-tooltip.top="'Add Image'"
                  class="min_width far fa-image"
                  data-cy="upload-image"
                  @click.prevent="
                    triggerSharingImageUpload(
                      'threadedTweetsSharingImageUpload_' + Number(key),
                    )
                  "
                >
                </i>
              </div>
              <div class="bottom-options-single">
                <i
                  v-tooltip.top="'Add Video'"
                  class="min_width far fa-video"
                  @click.prevent="
                    triggerSharingVideoUpload(
                      'threadedTweetsSharingVideoUpload_' + key,
                    )
                  "
                >
                </i>
              </div>
              <div class="bottom-options-single">
                <i
                  v-tooltip.top="'Add via Media Library/Other Sources'"
                  class="min_width far fa-folder-open"
                  data-cy="image-folder"
                  @click.prevent="showMediaModal('ThreadedTweets_' + key)"
                >
                </i>
              </div>
              <div class="bottom-options-single">
                <Hashtag
                  :type="'ThreadedTweets_' + key"
                  :is-threaded-tweets-box="true"
                ></Hashtag>
              </div>
              <div class="bottom-options-single">
                <emoji
                  :is="emojiComponent"
                  :type="'ThreadedTweets_' + key"
                ></emoji>
              </div>
            </div>
            <div class="charcount">
              <div class="d-flex align-items-center">
                <p class="mr-2 chart_limit font-0-87rem">
                  Hashtag Count:
                  <span>{{ hashtags_count[key] }}</span>
                </p>
              </div>
              <div class="d-flex align-items-center">
                <p class="mr-2 chart_limit font-0-87rem">
                  Characters Left:
                  <span :class="{ char_exceed: char_count[key] <= 0 }">{{
                    char_count[key]
                  }}</span>
                </p>
              </div>
            </div>
          </div>
            <!-- <link-section v-if="getTwitterSharingDetails.url" type="ThreadedTweets" :details="getTwitterSharingDetails"></link-section> -->
            <VideoSection
              v-if="
                (threaded_tweet.video && threaded_tweet.video.link) ||
                threaded_tweet.upload_thread_tweets_sharing_video
              "
              type="ThreadedTweets"
              :details="threaded_tweet"
              :threaded-tweet-index="key"
            ></VideoSection>
            <ImageSection
              v-else-if="
                threaded_tweet.uploadThreadedTweetsSharingImage.status ||
                (threaded_tweet.image && threaded_tweet.image.length > 0) ||
                (threaded_tweet.image_suggestions &&
                  threaded_tweet.image_suggestions.length > 0)
              "
              type="ThreadedTweets"
              :details="threaded_tweet"
              :threaded-tweet-index="key"
            ></ImageSection>
            <ErrorSection
              :errors="
                socialPostSpecificBoxContentErrors('twitter', threaded_tweet)
              "
              :warnings="
                socialPostSpecificBoxContentWarnings('twitter', threaded_tweet)
              "
            ></ErrorSection>
          <div class="path-line"></div>
        </div>
        <!-- multiple tweets end-->
        <div class="threaded-tweets__footer">
          <div class="btn">
            <i class="far fa-plus"></i
            ><input
              type="button"
              class="add-tweet-btn"
              value="Add another threaded tweet"
              @click="addTweet"
            />
          </div>
          <div class="threaded-tweets__info-box">
            <div class="threaded-tweets__info-box-icon ml-3">
              <v-menu
                :popper-triggers="['hover']"
                placement="bottom-end"
                popper-class="threaded-tweets__info-popover"
              >
                <!-- This will be the popover target (for the events and position) -->
                <a href="#"><i class="far fa-question-circle"></i></a>
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <div> Threaded Tweets are available for: </div>
                  <ul>
                    <li> <i class="fa fa-twitter !bg-black-900"></i> Profiles </li>
                  </ul>
                  <p class="threaded-tweets__message-box"
                    >How to add threaded tweets?
                    <a
                      v-close-popover="true"
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="61826fd112c07c18afde3ed5"
                      >Click here</a
                    ></p
                  >
                </template>
              </v-menu>
            </div>
            <div class="threaded-tweets__info-box-tray ml-2">
              <i class="fab fa-twitter"></i>
            </div>
            Available for
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hashtag from '@src/modules/publish/components/posting/social/options/Hashtag'
import { socialChannelsArray } from '@common/constants/common-attributes'
import { mapGetters, mapActions } from 'vuex'
import { getDefaultThreadedTweetsDetails } from '@src/modules/publish/store/states/platforms/social'
import {EventBus} from "@common/lib/event-bus";
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import LinkSection from '../sections/LinkSection'
import Options from '../options/Options'
import ErrorSection from './ErrorSection.vue'
export default {
  name: 'ThreadedTwitterSection',
  components: {
    Hashtag,
    Options,
    LinkSection,
    ImageSection,
    VideoSection,
    ErrorSection,
  },
  directives: {
    'auto-height': {
      // directive definition
      inserted: function (el) {
        // Auto Resize Hack
        el.style.overflow = 'hidden'
        el.style.height = 'auto'

        setTimeout(() => {
          el.style.height = el.scrollHeight + 'px'
        }, 100)
      },
    },
  },
  props: [],
  data() {
    return {
      social_channels_list: socialChannelsArray,
      char_count: [],
      hashtags_count: [],
      isThreadedTweetsEnabled: true,
      default_threaded_tweet: getDefaultThreadedTweetsDetails,
      threaded_tweets: [],
    }
  },
  computed: {
    ...mapGetters(['getAccountSelection', 'getTwitterOptions']),
    emojiComponent() {
      return () =>
        import('@src/modules/publish/components/posting/social/emoji/Emoji.vue')
    },
    selectedAccountIds() {
      return this.selectedAccountList.map((account) => {
        return account[account.channel.key]
      })
    },

    selectedAccountList() {
      const accountList = []
      if (this.accountSelection.length) {
        this.social_channels_list.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if (
              channel.name === 'twitter' &&
              this.accountSelection.includes(account[channel.key])
            ) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key },
              })
            }
          })
        })
      }

      return accountList
    },

    accountSelection() {
      const twitterAccounts = this.getAccountSelection.twitter
      return [...twitterAccounts]
    },
  },
  created() {
    // if (this.getTwitterOptions.has_threaded_tweets) this.threaded_tweets = [...this.getTwitterOptions.threaded_tweets]
    // else this.getTwitterOptions.threaded_tweets.push({ ...this.default_threaded_tweet })
    if (this.getTwitterOptions.threaded_tweets.length > 0) {
      this.getTwitterOptions.threaded_tweets.forEach((tweet, key) => {
        this.char_count[key] = this.remainingCharacters(tweet.message)
        if (this.getHashTags(tweet.message)) {
          this.hashtags_count[key] = this.getHashTags(tweet.message).length
        } else this.hashtags_count[key] = 0
      })
    }
  },
  methods: {
    ...mapActions([
      'uploadSharingVideo',
      'setThreadedTweetsMessage',
      'setHasThreadedTweets',
    ]),

    triggerSharingVideoUpload(selector) {
      document.getElementById(selector).click()
    },
    typedTextArea(e, index) {
      console.debug('typedTextArea', e, index)
      this.char_count[index] = this.remainingCharacters(e.target.value)
      if (this.getHashTags(e.target.value)) {
        this.hashtags_count[index] = this.getHashTags(e.target.value).length
      } else {
        this.hashtags_count[index] = 0
      }
      this.getTwitterOptions.threaded_tweets[index].message = e.target.value
      // this.update('comment', e.target.value)
      // Auto Resize Hack
      e.target.style.overflow = 'hidden'
      e.target.style.height = 'auto'
      e.target.style.height = e.target.scrollHeight + 'px'
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    remainingCharacters(message) {
      const twitterPostLimit = 280
      if (this.getAccountSelection.twitter.length && message) {
        let remaining = twitterPostLimit - message.length
        const urls = this.getLinks(message)
        if (urls) {
          urls.forEach((el, index) => {
            remaining = remaining + el.length - 23
          })
        }
        return remaining
      }
      return twitterPostLimit
    },
    showMediaModal (type) {
      EventBus.$emit('show-media-library-modal', {source: type, details: {}, sideTabIndex: 1})
    },
    addTweet() {
      console.debug('add another tweet', getDefaultThreadedTweetsDetails)
      this.getTwitterOptions.threaded_tweets.push({
        ...getDefaultThreadedTweetsDetails,
      })
    },
    closeTweet(index) {
      console.debug('close tweet', index)
      if (this.getTwitterOptions.threaded_tweets.length > 1) {
        this.getTwitterOptions.threaded_tweets.splice(index, 1)
      }
    },
  },
}
</script>

<style scoped lang="less">
.chart_limit {
  color: #737373;
  span {
    color: black;
  }
  .char_exceed {
    color: red;
  }
}
</style>
