<template>
  <b-modal
    :id="isEdit ? 'edit-share-plan-modal' : 'create-share-plan-modal'"
    centered
    no-close-on-backdrop
    hide-header
    hide-footer
    body-class="!px-0"
    dialog-class="!w-[484px]"
    @show="onModalOpen"
    @hide="onModalHide"
  >
    <div class="relative">
      <!--      modal close     -->
      <div class="w-full flex justify-end mt-2 pt-[5px] px-[5px]">
        <img
          src="@src/assets/img/integration/cross_dark_gray.svg"
          class="w-6 h-6 mr-[12px] cursor-pointer"
          alt=""
          @click="
            $bvModal.hide(
              isEdit ? 'edit-share-plan-modal' : 'create-share-plan-modal'
            )
          "
        />
      </div>

      <!--      main modal body     -->
      <div class="px-10 mb-12 w-full flex flex-col justify-center items-center">
        <div class="w-full flex justify-center items-center gap-x-[8px]">
          <img
            :src="require('@assets/img/icons/chain-link.svg')"
            class="w-4 h-4"
            alt=""
          />
          <p class="font-medium text-lg text-[#202324] select-none">{{
            state.mainText
          }}</p>
        </div>
        <p
          class="text-base text-[#757A8A] mt-4 text-center px-[2.406rem]"
          v-html="state.subText"
        ></p>
        <hr class="text-[#ECEEF5] w-full mb-0 mr-0 ml-0 mt-4" />

        <!--        modal inputs      -->
        <div class="mt-6 w-full flex flex-col justify-center items-center">
          <div v-if="state.isLinkCreated">
            <label for="shareable-link" class="flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none">Link </span>
              <span class="relative">
                <input
                  id="shareable-link"
                  v-tooltip="'Click to copy'"
                  :value="state.sharedLink"
                  type="text"
                  placeholder="Add secret here"
                  class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-4 rounded-[8px] bg-[#F2F3F8] cst-editor cursor-pointer"
                  autocomplete="off"
                  readonly
                  @click="copyToClipboard"
                />
              </span>
            </label>
            <div class="flex justify-center mt-[3rem] gap-[0.625rem]">
              <button
                class="px-8 py-4 rounded-xl border !border-[#2961D2] bg-[#2961D2] text-[1rem] leading-none text-white"
                @click.prevent="
                  () => {
                    copyToClipboard()
                    $bvModal.hide(
                      isEdit
                        ? 'edit-share-plan-modal'
                        : 'create-share-plan-modal'
                    )
                  }
                "
              >
                Copy Link
              </button>
            </div>
          </div>
          <div
            v-else
            @keyup.enter.prevent="isEdit ? editShareableLink() : generateLink()"
          >
            <label for="title-input" class="flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none"
                >Title<span class="text-red-600">*</span></span
              >
              <input
                id="title-input"
                v-model="state.linkName"
                placeholder="Enter a title to make your link easily identifiable."
                class="color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 text-[#3a4557] rounded-[8px] bg-[#F2F3F8] cst-editor"
                autocomplete="off"
                required
                type="text"
                @input="handleInput"
              />
            </label>
            <label
              for="password-input"
              class="flex flex-col mb-0 space-y-2 mt-5"
            >
              <span class="text-[14px] font-medium select-none"
                >Password Protection
                <CstSwitch
                  v-model="state.passwordProtected"
                  size="small"
                  class="inline-block ml-3"
                />
              </span>
              <span class="relative">
                <input
                  id="password-input"
                  v-model="state.password"
                  :type="state.passInputType"
                  placeholder="Password"
                  class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 rounded-[8px] bg-[#F2F3F8] cst-editor"
                  autocomplete="off"
                  :disabled="!state.passwordProtected"
                />
                <img
                  :src="secretEyeIconSrc"
                  class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                  alt=""
                  @click="changePasswordType"
                />
              </span>
            </label>
            <div class="flex justify-center mt-[3rem] gap-[0.625rem]">
              <button
                class="px-8 py-4 rounded-xl border border-[#757A8A] text-[1rem] leading-none text-[#757A8A]"
                @click.prevent="
                  $bvModal.hide(
                    isEdit ? 'edit-share-plan-modal' : 'create-share-plan-modal'
                  )
                "
                >Cancel</button
              >
              <button
                v-tooltip="{
                  content: tooltipMessage ?? '',
                }"
                class="px-8 py-4 rounded-xl border !border-[#2961D2] bg-[#2961D2] text-[1rem] leading-none text-white"
                :class="{
                  'opacity-50 !cursor-not-allowed': isSaveButtonDisabled,
                }"
                @click.prevent="isEdit ? editShareableLink() : generateLink()"
              >
                <span class="flex justify-center items-center gap-2">
                  <span>{{ isEdit ? 'Save' : 'Create' }}</span>
                  <clip-loader
                    v-if="state.loader"
                    :color="'#9da6ac'"
                    :size="'13px'"
                    class="spinner"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import CstSwitch from '@ui/Switch/CstSwitch'
import {
  onMounted,
  reactive,
  computed,
  onBeforeUnmount,
  inject,
  watch,
} from 'vue'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  editLink: {
    type: Object,
    default: () => {},
  },
})
const emit = defineEmits(['re-fetch-links'])

const root = inject('root')
const { $bvModal } = root
const { dispatch, getters } = useStore()

const initialState = {
  mainText: 'Create a Secure Shareable Link',
  subText:
    'Create a secure link to easily share your content with clients and gather their valuable feedback.',
  loader: false,
  plans: [],
  linkName: '',
  passwordProtected: false,
  password: '',
  passInputType: 'password',
  sharedLink: '',
  isLinkCreated: false,
}

const state = reactive({ ...initialState })

const linkName = computed(() => state.linkName?.trim())
const password = computed(() => state.password?.trim())

const isSaveButtonDisabled = computed(() => {
  if (linkName.value?.length < 3 || linkName.value?.length >= 50) return true
  if (state.passwordProtected && password.value?.length < 6) return true
  return (
    props?.editLink?.link_name === linkName.value &&
    props?.editLink?.password === password.value &&
    props?.editLink?.is_secure === state.passwordProtected
  )
})

const tooltipMessage = computed(() => {
  if (!linkName.value || linkName.value.length < 3) {
    return 'Link name must be at least 3 characters.'
  }

  if (linkName.value.length >= 50) {
    return 'Link name must be less than 50 characters.'
  }

  if (
    state.passwordProtected &&
    (!password.value || password.value.length < 6)
  ) {
    return 'Password must be at least 6 characters when protection is enabled.'
  }

  if (
    props?.editLink?.link_name === linkName.value &&
    props?.editLink?.password === password.value &&
    props?.editLink?.is_secure === state.passwordProtected
  ) {
    return 'Please make some changes to save.'
  }

  return '' // No tooltip when the button is enabled
})

const secretEyeIconSrc = computed(() =>
  state.passInputType === 'password'
    ? '/img/eye_disabled.svg'
    : '/img/eye_allowed.svg'
)

watch(
  () => props.editLink,
  (newVal, oldVal) => {
    if (newVal === oldVal) return
    state.linkName = props?.editLink?.name
    state.password = props?.editLink?.password
    state.passwordProtected = props?.editLink?.is_password_protected
    state.mainText = 'Edit Shareable Link'
  }
)

onMounted(() => {
  // listen to show event
  EventBus.$on('share-plan::show', (plans) => {
    state.plans = plans
    $bvModal.show('create-share-plan-modal')
  })
})

onBeforeUnmount(() => {
  EventBus.$off('share-plan::show')
})

const handleInput = () => {
  // Use a regular expression to remove unwanted characters
  state.linkName = state.linkName.replace(/[^\sA-Za-z0-9_-]/g, '')
}

const changePasswordType = () => {
  state.passInputType === 'password'
    ? (state.passInputType = 'text')
    : (state.passInputType = 'password')
}

/**
 * Generate the shareable link
 */
const generateLink = async () => {
  state.loader = true
  if (isSaveButtonDisabled.value) return
  try {
    const response = await serviceShareLink(ACTION_TYPES.CREATE_LINK, {
      name: state.linkName,
      password: state.password,
      is_password_protected: state.passwordProtected,
      plans: state.plans,
      workspace_id: getters.getActiveWorkspace._id,
    })

    if (response.link) {
      state.isLinkCreated = true
      state.sharedLink = `${process.env.VUE_APP_BASE_URL}share/planner/${response.link}`

      state.mainText = 'Shareable Link Created'
      state.subText = `Your link is ready! Share it with your clients to gather their feedback.`
    }
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
  state.loader = false
}

const editShareableLink = async () => {
  if (isSaveButtonDisabled.value) return
  if (state.passwordProtected && !state.password) {
    await dispatch('toastNotification', {
      message: 'Password is required for password protected link!',
      type: 'error',
    })
    return
  }

  const details = {
    id: props.editLink._id,
    name: state.linkName,
    password: state.password,
    is_password_protected: state.passwordProtected,
    workspace_id: getters.getActiveWorkspace._id,
    is_disabled: props.editLink.is_disabled,
  }

  try {
    await serviceShareLink(ACTION_TYPES.UPDATE_LINK, {
      ...details,
    })

    await dispatch('toastNotification', {
      message: 'Link details successfully updated' || UNKNOWN_ERROR,
      type: 'success',
    })

    emit('re-fetch-links')
    $bvModal.hide('edit-share-plan-modal')
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
}

/**
 * Copy link to clipboard
 */
const copyToClipboard = () => {
  navigator.clipboard.writeText(state.sharedLink)

  dispatch('toastNotification', {
    message: 'Shareable link copied to clipboard',
    type: 'success',
  })
}

const onModalOpen = async () => {
  if (props.isEdit) {
    state.linkName = props.editLink?.name
    state.password = props.editLink?.password
    state.passwordProtected = props.editLink?.is_password_protected
  }
}

const onModalHide = () => {
  Object.assign(state, initialState)
}
</script>
