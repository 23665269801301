<script>
import { mapGetters, mapActions } from 'vuex'
import integrations, { getPlatformName } from '@common/lib/integrations'
import {
  socialChannelsArray,
  allChannelsArray
} from '@common/constants/common-attributes'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils.js'

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: ''
    },
    dropdownPosition: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      integrations,
      social_channels_list: socialChannelsArray,
      blog_integrations: blogIntegrationsNames,
      all_channels_array: allChannelsArray
    }
  },
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getInstagramAccounts',
      'getTumblrAccounts',
      'getGmbAccounts',
      'getPinterestBoards'
    ]),

    filteredplatformsCount () {
      let platformsCount = 0
      this.all_channels_array.forEach((item) => {
        const getter =
            item.getter === 'getBlogs'
                ? this.$store.getters.getBlogs[item.name]
                : this.$store.getters[item.getter]
        const itemsList = getter.hasOwnProperty('items') ? getter.items : getter
        platformsCount += itemsList.filter((account) =>
            this.showPlatformSelection(account)
        ).length
      })
      return platformsCount
    }
  },
  watch: {},
  created () {},
  methods: {
    ...mapActions(['setPublishSelectionSearch']),

    platformText (platform) {
      // console.debug('Method::platformText', platform)
      return getPlatformName(platform)
    },

    showPlatformSelection (account) {
      return (
        this.platformText(account)
          .toLowerCase()
          .indexOf(this.getPublishSelection.platforms.search.toLowerCase()) >= 0
      )
    },

    clearInput () {
      this.setPublishSelectionSearch('')
    },

    filterPlatformVisibleStatus (platform) {
      let count = 0
      let status = false
      const selectedPlatforms = this.getPublishSelection.platforms.selection

      this.all_channels_array.forEach((channel) => {
        const getter =
          channel.getter === 'getBlogs'
            ? this.$store.getters.getBlogs[channel.name]
            : this.$store.getters[channel.getter]
        const channelItems = getter.hasOwnProperty('items')
          ? getter.items
          : getter

        if (!status) {
          channelItems.forEach((item) => {
            if (selectedPlatforms.includes(item[channel.key])) {
              count += 1
              if (platform[channel.key] === item[channel.key] && count < 6) {
                status = true
                return false
              }
            }
          })
        }
      })
      return status
    }
  },

}
</script>

<template>
  <div>
    <div
      v-if="true"
      data-display="static"
      style="max-height: 37px"
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <div class="text d-inline-block align-middle">
        <template v-if="getPublishSelection.platforms.selection.length < 1">
          <i class="align-middle far fa-user icon_first"></i>
          <span class="align-middle">Select Account(s)</span>
        </template>
        <!-- for the blog channels tooltip -->
        <div class="circle_boxes_inline">
          <template v-if="type === 'Blog'">
            <template v-for="(integration, index) in blog_integrations" :key="`integration_${index}`">
              <template
                v-for="account in $store.getters.getBlogs[integration].items"
              >
                <span
                  v-if="filterPlatformVisibleStatus(account)"
                  v-tooltip="{
                    content: channelTooltipHTML(integration, account),
                    classes: 'team_tooltip',
                    allowHTML: true,
                    theme: 'light',
                  }"
                >
                  <individual-channel-tooltip
                    :account="account"
                    :type="integration"
                    :flex_class="'d-flex align-items-start'"
                  ></individual-channel-tooltip>
                </span>
              </template>
            </template>
          </template>
          <!-- for the social channels tooltip -->
          <template v-for="(channel, index) in social_channels_list" :key="`list_${index}`">
            <template v-if="source === 'planner' || channel.name !== 'youtube'">
            <template
              v-for="(account, accountIndex) in getChannelItems(channel)"
              :key="`account_${accountIndex}`"
            >
              <span
                v-if="filterPlatformVisibleStatus(account)"
                v-tooltip="{
                  content: channelTooltipHTML(channel.name, account),
                  classes: 'team_tooltip',
                  allowHTML: true,
                  theme: 'light',
                }"
              >
                <individual-channel-tooltip
                  :account="account"
                  :type="channel.name"
                  :flex_class="'d-flex align-items-start'"
                ></individual-channel-tooltip>
              </span>
            </template></template>
          </template>

          <div
            v-if="getPublishSelection.platforms.selection.length > 5"
            class="profile_picture d-flex align-items-start"
          >
            <div class="picture_block picture_block_background">
              <div class="img text"
                >+{{ getPublishSelection.platforms.selection.length - 5 }}</div
              >
            </div>
          </div>
        </div>
      </div>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>

    <div
      v-if="true"
      :class="dropdownPosition"
      class="dropdown-menu platform_filters_dropdown_inner"
    >
      <ul class="inner">
        <template
          v-if="
            filteredplatformsCount > 0 ||
            getPublishSelection.platforms.search !== ''
          "
        >
          <div class="search-bar-input m-3">
            <div class="search-bar-inner">
              <input
                id="platformSearchInput"
                v-model="getPublishSelection.platforms.search"
                type="text"
                class=""
                placeholder="Search by name"
              />

              <div class="search_btn">
                <i class="icon-Search"></i>
              </div>

              <button
                v-if="getPublishSelection.platforms.search !== ''"
                class="clear-search-input"
                @click="clearInput"
              >
                <i class="cs-cross"></i>
              </button>
            </div>
          </div>

          <hr />
          <div
            v-if="getPublishSelection.platforms.search === ''"
            class="select_all"
          >
            <div class="search_inner">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="platform_filter1" class="checkbox_right">
                    Select All
                    <input
                      id="platform_filter1"
                      v-model="getPublishSelection.platforms.check_all"
                      data-cy="platform-check-all"
                      type="checkbox"
                      @change="platformFilterChange(type, 'All')"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="type === 'Blog'">
          <template v-for="(integration, index) in blog_integrations" :key="`blog_${index}`">
            <template
              v-for="(account, blogIndex) in $store.getters.getBlogs[integration].items"
              :key="`blogItem_${blogIndex}`"
            >
              <li v-if="showPlatformSelection(account)" class="list_item_li">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label
                      :for="'platform_filter_' + account.platform_identifier"
                      class="checkbox_right"
                    >
                      <input
                        :id="'platform_filter_' + account.platform_identifier"
                        v-model="getPublishSelection.platforms.selection"
                        :data-cy="
                          'platform_filter_' + account.platform_identifier
                        "
                        :value="account.platform_identifier"
                        type="checkbox"
                        @change="platformFilterChange(type)"
                      />

                      <span class="check"></span>

                      <individual-channel-dropdown
                        :account="account"
                        :type="integration"
                        :account_name="trimURL(platformText(account))"
                        :account_type="'Blog'"
                        :integration="'blog'"
                      ></individual-channel-dropdown>
                    </label>
                  </div>
                </div>
              </li>
            </template>
          </template>
        </template>

        <template v-for="(channel, channelIndex) in social_channels_list" :key="`channelList_${channelIndex}`">
          <template v-for="(account, channelAccountIndex) in getChannelItems(channel)" :key="`channelAccount_${channelAccountIndex}`">
            <li
              v-if="
                showPlatformSelection(account) &&
                (source === 'planner' || channel.name !== 'youtube')
              "
              class="list_item_li"
            >
              <div class="field_group">
                <div class="checkbox_container">
                  <label
                    :for="'platform_filter_' + account[channel.key]"
                    class="checkbox_right"
                  >
                    <input
                      :id="'platform_filter_' + account[channel.key]"
                      v-model="getPublishSelection.platforms.selection"
                      :data-cy="'platform_filter_' + account[channel.key]"
                      :value="account[channel.key]"
                      type="checkbox"
                      @change="platformFilterChange(type)"
                    />
                    <span class="check"></span>
                    <individual-channel-dropdown
                      :account="account"
                      :type="channel.name"
                      :account_name="platformText(account)"
                      :account_type="account.type"
                      :integration="'social'"
                    ></individual-channel-dropdown>
                  </label>
                </div>
              </div>
            </li>
          </template>
        </template>

        <li v-if="filteredplatformsCount === 0" class="p-3 text-center">
          <p v-if="getPublishSelection.platforms.search"
            >No results found for your search query, please try again</p
          >
          <div v-else>
            <p class="mb-3 mt-2">You have not connected any account yet.</p>
            <template
              v-if="
                hasPermission('can_save_blog') ||
                hasPermission('can_save_social')
              "
            >
              <router-link
                :to="{ name: 'social' }"
                class="btn btn-studio-theme-space"
              >
                <span>Connect an account</span>
              </router-link>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
