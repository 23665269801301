import proxy from '@common/lib/http-common'
import {
  SAVE_REWRITING_ACCOUNT_ERROR,
  SAVE_REWRITING_ACCOUNT_SUCCESS,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import { saveRewriterAccountURL } from '../../config/api-utils'
import { rewriter } from './mutation-types'

export default {
  state: {
    spinner: {
      chimprewriter: null,
      spinrewriter: null,
      quillbot: null
    },
    translator: {
      yandex: null
    }
  },
  getters: {
    getSpinner: (state) => state.spinner,
    getTranslator: (state) => state.translator,
    getChimpRewriterAccount: (state) => state.spinner.chimprewriter,
    getSpinRewriterAccount: (state) => state.spinner.spinrewriter,
    getQuillbotAccount: (state) => state.spinner.quillbot,
    getYandexAccount: (state) => state.translator.yandex
  },
  actions: {
    async saveRewriterAccount (
      { commit, getters, rootGetters, dispatch },
      data
    ) {
      console.debug('Action:saveRewriterAccount', data)
      return proxy
        .post(saveRewriterAccountURL, data)
        .then((response) => {
          if (response.data.status === false) {
            dispatch('toastNotification', {
              message: response.data.message
                ? response.data.message
                : SAVE_REWRITING_ACCOUNT_ERROR,
              type: 'error'
            })
            return false
          }
          dispatch('toastNotification', {
            message: SAVE_REWRITING_ACCOUNT_SUCCESS,
            type: 'success'
          })
          switch (data.type) {
            case 'chimp_rewriter':
              commit(rewriter.SET_CHIMP_REWRITER_ACCOUNT, {
                username: data.username,
                password: data.password
              })

              break
            case 'spin_rewriter':
              commit(rewriter.SET_SPIN_REWRITER_ACCOUNT, {
                username: data.username,
                password: data.password
              })

              break
            case 'quillbot':
              commit(rewriter.SET_QUILLBOT_ACCOUNT, {
                key: data.key
              })

              break
            case 'yandex_translate':
              commit(rewriter.SET_YANDEX_ACCOUNT, {
                key: data.key
              })

              break
          }
          return true
        })
        .catch((error) => {
          console.debug('Exception in saveRewriterAccount', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error'
          })
          return false
        })
    },

    setDefaultRewritingStates ({ commit }) {
      commit(rewriter.SET_CHIMP_REWRITER_ACCOUNT, null)
      commit(rewriter.SET_SPIN_REWRITER_ACCOUNT, null)
      commit(rewriter.SET_QUILLBOT_ACCOUNT, null)
      commit(rewriter.SET_YANDEX_ACCOUNT, null)
    }
  },
  mutations: {
    [rewriter.SET_CHIMP_REWRITER_ACCOUNT] (state, status) {
      state.spinner.chimprewriter = status
    },

    [rewriter.SET_SPIN_REWRITER_ACCOUNT] (state, status) {
      state.spinner.spinrewriter = status
    },

    [rewriter.SET_QUILLBOT_ACCOUNT] (state, status) {
      state.spinner.quillbot = status
    },

    [rewriter.SET_YANDEX_ACCOUNT] (state, status) {
      state.translator.yandex = status
    }
  }
}
