<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_left_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Total Engagement by Network </h2>
    <div
      id="totalEngagementByNetwork"
      class="topics-insights-container__chart_legends"
    />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        ref="highcharts_articles_published"
        :options="totalEngagementByNetwork()"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'
import highcharts from "@ui/Highcharts";

export default {
  components: {
    highcharts
  },
  props: {
    total_channel_engagements: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.total_channel_engagements.hasOwnProperty('sum')) {
        return (
          this.total_channel_engagements.sum.facebook +
            this.total_channel_engagements.sum.reddit +
            this.total_channel_engagements.sum.pinterest ===
          0
        )
      }
      return false
    }
  },
  methods: {
    totalEngagementByNetwork () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f} at {point.name}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#totalEngagementByNetwork')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: [
          '#4e92ff',
          '#ff4500',
          '#cb2026',
          '#0078b5',
          '#39475d',
          '#989eb5'
        ],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['Facebook', 'Reddit', 'Pinterest'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          formatter: function () {
            return itemsList[this.value]
          },
          useHtml: true
        },
        yAxis: {
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          gridLineColor: '#eaf0f4',
          title: {
            text: 'Number of Engagements',
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          },
          min: 0
          // categories: ['0', '5', '10', '15', '20', '25']
        },
        plotOptions: {
          series: {
            pointWidth: 25,
            borderWidth: 0,
            lineWidth: 3
          },
          column: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            colorByPoint: true
          }
        },
        series: [
          {
            name: 'Total Engagement',
            data: [
              [
                'Facebook',
                this.total_channel_engagements.sum.facebook &&
                this.total_channel_engagements.sum.facebook > 0
                  ? this.total_channel_engagements.sum.facebook
                  : this.isContentNotAvailable
                    ? 50
                    : 0
              ],
              [
                'Reddit',
                this.total_channel_engagements.sum.reddit &&
                this.total_channel_engagements.sum.reddit > 0
                  ? this.total_channel_engagements.sum.reddit
                  : this.isContentNotAvailable
                    ? 30
                    : 0
              ],
              [
                'Pinterest',
                this.total_channel_engagements.sum.pinterest &&
                this.total_channel_engagements.sum.pinterest > 0
                  ? this.total_channel_engagements.sum.pinterest
                  : this.isContentNotAvailable
                    ? 40
                    : 0
              ]
            ]
          }
        ]
      }
      return chartOptions
    }
  }
}
</script>
