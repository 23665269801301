import { analyticsBaseUrl } from '@src/config/api-utils'

export const getTwitterAnalyticsURL = analyticsBaseUrl + 'analytics/twitter'
export const getTwitterAnalyticsV2URL = analyticsBaseUrl + 'v2/twitter'
export const getFacebookAnalyticsURL = analyticsBaseUrl + 'analytics/facebook'
export const getLinkedinAnalyticsURL = analyticsBaseUrl + 'analytics/linkedin'
export const getPinterestAnalyticsURL = analyticsBaseUrl + 'analytics/pinterest'
export const getInstagramAnalyticsURLv1 =
  analyticsBaseUrl + 'analytics/instagram'
export const getInstagramAnalyticsPostsURLv1 =
  analyticsBaseUrl + 'analytics/instagram/posts'
export const getInstagramAnalyticsDemographicsURLv1 =
  analyticsBaseUrl + 'analytics/instagram/demographics'
export const getInstagramAnalyticsInsightsURL =
  analyticsBaseUrl + 'analytics/instagram/insights'
export const addToRefetchQueueURL = analyticsBaseUrl + 'analytics/sync'
export const getGroupAnalyticsURL = analyticsBaseUrl + 'analytics/group'
export const getReportsURL = analyticsBaseUrl + 'analytics/reports/show'
export const saveReportsURL = analyticsBaseUrl + 'analytics/reports/save'
export const downloadReportsURL = analyticsBaseUrl + 'analytics/reports/list'
export const removeDownloadReportsURL =
  analyticsBaseUrl + 'analytics/reports/remove'
export const scheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/save'
export const getScheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/show'
export const removeScheduleReportsURL =
  analyticsBaseUrl + 'analytics/reports/schedule/remove'

// analytics v2
export const getGroupAnalyticsOverviewURL =
  analyticsBaseUrl + 'analytics/overview/summary'
export const getGroupAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/topPosts'
export const getGroupAnalyticsPageEngagementURL =
  analyticsBaseUrl + 'analytics/overview/postsEngagement'
export const getGroupAnalyticsEngagementRollupURL =
  analyticsBaseUrl + 'analytics/overview/engagementRollup'
export const getGroupAnalyticsAccountPerformanceURL =
  analyticsBaseUrl + 'analytics/overview/accountPerformance'

export const getFacebookAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/facebook/summary'
export const getFacebookAnalyticsAudienceGrowthURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewAudienceGrowth'
export const getFacebookAnalyticsPublishingBehaviourURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewPublishingBehaviour'
export const getFacebookAnalyticsOverviewTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewTopPosts'
export const getFacebookAnalyticsActiveUsersURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewActiveUsers'
export const getFacebookAnalyticsImpressionsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewImpressions'
export const getFacebookAnalyticsReachURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewReach'
export const getFacebookAnalyticsReelsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewReelsAnalytics'
export const getFacebookAnalyticsEngagementURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewEngagement'
export const getFacebookAnalyticsVideoInsightsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewVideoAnalytics'

export const getFacebookAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/facebook/getTopPosts'
export const getFacebookAnalyticsOverviewDemographicAgeGenderUrl =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewDemographics'
export const getFacebookAnalyticsOverviewDemographicCountryUrl =
  analyticsBaseUrl + 'analytics/overview/facebook/overviewAudienceLocation'

export const getInstagramAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/summary'
export const getInstagramAnalyticsAudienceGrowthURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/audience_growth'
export const getInstagramAnalyticsPublishingBehaviourURL =
  analyticsBaseUrl +
  'analytics/overview/instagram/overview/publishing_behaviour'
export const getInstagramAnalyticsOverviewTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/top_posts'
export const getInstagramAnalyticsActiveUsersURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/active_users'
export const getInstagramAnalyticsImpressionsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/impressions'
export const getInstagramAnalyticsEngagementURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/engagement'
export const getInstagramAnalyticsHashtagsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/hashtags'
export const getInstagramAnalyticsStoriesPerformanceURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/stories_performance'
export const getInstagramAnalyticsReelsPerformanceURL =
  analyticsBaseUrl + 'analytics/overview/instagram/overview/reels_performance'
export const getInstagramAnalyticsDemographicsAudienceAgeURL =
  analyticsBaseUrl + 'analytics/overview/instagram/demographics/audience_age'
export const getInstagramAnalyticsDemographicsCountryCityURL =
  analyticsBaseUrl + 'analytics/overview/instagram/demographics/country_city'
export const getInstagramAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/getTopPosts'
export const getInstagramAnalyticsDemographicsURL =
  analyticsBaseUrl + 'analytics/overview/instagram/demographics'

export const getLinkedinAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/summary'
export const getLinkedinAnalyticsAudienceGrowthURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/audienceGrowth'
export const getLinkedinAnalyticsEngagementURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/engagement'
export const getLinkedinAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/topPosts'
export const getLinkedinAnalyticsPostsPerDaysURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/postsPerDays'
export const getLinkedinAnalyticsHashtagsURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/hashtags'

export const getLinkedinAnalyticsGetTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/linkedin/getTopPosts'
export const getPinterestAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/pinterest/summary'
export const getPinterestAnalyticsTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/pinterest/getTopPosts'

export const getTiktokAnalyticsSummaryURL =
  analyticsBaseUrl + 'analytics/overview/tiktok/getPageAndPostsInsights'
export const getTiktokAnalyticsAudienceGrowthURL =
  analyticsBaseUrl + 'analytics/overview/tiktok/getPageFollowersAndViews'
export const getTiktokAnalyticsPublishingBehaviourURL =
  analyticsBaseUrl + 'analytics/overview/tiktok/getDailyEngagementsData'
export const getTiktokAnalyticsOverviewTopPostsURL =
  analyticsBaseUrl + 'analytics/overview/tiktok/getTopAndLeastPerformingPosts'
export const getTiktokAnalyticsEngagementURL =
  analyticsBaseUrl + 'analytics/overview/tiktok/getPostsAndEngagements'

// dashboard analytics
export const fetchContentPublishingStatsURL =
  analyticsBaseUrl + 'getContentPublishingStats'
export const fetchInboxStatsURL = analyticsBaseUrl + 'getInboxStats'
export const fetchContentApprovalStatsURL =
  analyticsBaseUrl + 'getContentApprovalStats'

// composer

export const getTimeRecommendationUrl =
  analyticsBaseUrl + 'analytics/overview/timeRecommendation'
