<template>
  <DataTable
    :bulk-change-status-option-visibility-status="
      bulkChangeStatusOptionVisibilityStatus
    "
    :check-all-items="checkAllItems"
    :filter-sidebar="filterSidebar"
    :items="plans"
    :loader="loader"
    :search-loader="searchLoader"
    :search-query="searchQuery"
    :selected-items-count="selectedItemsCount"
    :show-bulk-actions="showBulkActions"
    :total-items="totalItems"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import DataTable from '../components/DataTable'

export default {
  name: 'ListView',
  components: {
    DataTable,
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    checkAllItems: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    filterSidebar: {
      type: Boolean,
      default: false,
    },
    selectedItemsCount: {
      type: Number,
      default: 0,
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
    bulkChangeStatusOptionVisibilityStatus: {
      type: Boolean,
      default: true,
    },
    searchLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      channel: null,
    }
  }
}
</script>

<style scoped></style>
