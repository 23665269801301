<template>
  <ul
    v-if="
      getCustomSidebar.my_topics.status || getSidebarCustomization.customization
    "
    :class="{ disable_click: getTopicDisabled }"
    class="topics-left-pane-items"
  >
    <li
      class="topics-left-pane-items__heading"
      :class="[sibarItemStatus('myTopics') ? '' : 'opacity']"
    >
      <i class="icon-reports_icon title_icon"> </i>
      <span class="topics-left-pane-items__heading__text">Custom Topics</span>
      <button
        v-if="!getSidebarCustomization.customization"
        v-tooltip.top="'New Topic'"
        class="topics-left-pane-items__heading__button"
        data-cy="add-custom-topic"
        @click.prevent="createNewTopic"
      >
        <i class="far fa-plus d-block"></i>
      </button>

      <div v-else class="topics-left-pane-items__heading__customization">
        <i
          class="view_icon"
          :class="[sibarItemStatus('myTopics') ? ' cs-eye' : '  cs-eye-close']"
          @click.prevent="selectionSibarItem('myTopics')"
        >
        </i>
      </div>
    </li>
    <!--:class="{ selected_disable: !sibarItemStatus('myTopics'),disable_box : getSidebarCustomization}"-->
    <template v-if="getTopics.length > 0">
      <transition-group name="topics-list" tag="div">
        <template  v-for="(topic, index) in getTopics">
          <li
              v-if="
              getSidebarCustomization.customization ||
              !getCustomSidebar.my_topics.options.includes(topic._id)
            "
              :key="topic._id"
              :class="[
            sibarItemStatus('myTopics', topic._id) ? '' : 'opacity',
            getSidebarCustomization.customization
              ? 'topics-left-pane-items__list__customization'
              : '',
            $route.params.topic_id === topic._id ? 'active' : '',
          ]"
              class="topics-left-pane-items__list"
              :disabled="
            getTopicDisabled === true || getSidebarCustomization.customization
          "
          >
              <!--:class="{selected_disable: !sibarItemStatus('myTopics',topic._id),disable_box : getSidebarCustomization}">-->
              <router-link
                  v-if="topic.filters"
                  href="javascript:;"
                  :class="{ active: $route.params.topic_id === topic._id }"
                  :to="{
                name: 'discover_topics_custom_web_content',
                params: { topic_id: topic._id },
              }"
              >
                {{ topic.filters.topics.name }}
              </router-link>
              <div class="t_icon topics-left-pane-items__list__action_buttons">
                <!-- if the sidebar customization is not enabled -->
                <template v-if="!getSidebarCustomization.customization">
                  <i
                      v-tooltip.top="'Edit'"
                      style="font-size: 13px"
                      class="icon-edit-cs topics-left-pane-items__list__action_buttons__edit"
                      @click.prevent="editTopic(topic)"
                  >
                  </i>
                  <!--@click.prevent="removeCustomTopic(topic._id, topic.workspace_id, index)"-->

                  <span class="dropdown dropdown-confirmation">
                  <i
                      :id="'dropdown_' + topic._id"
                      v-tooltip.top="'Remove'"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      class="icon-delete-cs topics-left-pane-items__list__action_buttons__remove"
                  ></i>
                  <div
                      :aria-labelledby="'dropdown_' + topic._id"
                      class="dropdown-menu dropdown-confirmation__menu"
                  >
                    <div class="dropdown-confirmation__menu__header">
                      Confirmation
                    </div>
                    <div class="dropdown-confirmation__menu__actions">
                      Are you sure?
                      <span
                          class="dropdown-confirmation__menu__actions__delete"
                          @click.prevent="
                          $store.dispatch('deleteCustomTopic', {
                            id: topic._id,
                            index: index,
                          })
                        "
                      >Yes</span
                      >
                      <span class="dropdown-confirmation__menu__actions__cancel"
                      >No</span
                      >
                    </div>
                  </div>
                </span>
                </template>
                <!-- sidebar customization is enabled -->
                <template v-else>
                  <i
                      class="view_icon"
                      :class="[
                    sibarItemStatus('myTopics', topic._id)
                      ? 'cs-eye '
                      : 'cs-eye-close ',
                  ]"
                      @click.prevent="selectionSibarItem('myTopics', topic._id)"
                  >
                  </i>
                  <!--<span v-if="getCustomSidebar.myTopics.options.includes(topic._id)" @click.prevent="selectionSibarItem('myTopics',topic._id)" class="edit_icon" >-->
                  <!--<span class="icon"></span>-->
                  <!--</span>-->
                  <!--<span v-else @click.prevent="selectionSibarItem('myTopics',topic._id)" class="remove_icon">-->
                  <!--<span class="icon"></span>-->
                  <!--</span>-->
                </template>
              </div>

              <!--<div class="line_animate" :class="{'active animate':getActiveTopicTab === topic._id}"></div>-->
          </li>
        </template>
      </transition-group>
    </template>
    <template v-else>
      <p class="topics-left-pane-items__no_results"
        >You do not have any topic created.</p
      >
    </template>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getTopics',
      'getTopicsFilters',
      'getActiveTopicTab',
      'getTopicDisabled',
      'getSidebarCustomization',
      'getCustomSidebar',
      'getActiveChannelRoute',
    ]),
  },
  methods: {
    ...mapActions([
      'stateResetTopicFilters',
      'setTopic',
      'setTopicCreationModal',
    ]),
  },
}
</script>

<style>
.topics-list-enter-active,
.topics-list-leave-active {
  transition: all 0.5s;
}

.topics-list-enter, .topics-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-45px);
}
.side_bar_block .ds_topic_sidebar .sidebar_inner ul li.heading span {
}
.side_bar_block .ds_topic_sidebar .sidebar_inner .head_links .active span {
  z-index: auto !important;
}
.side_bar_block
  .ds_topic_sidebar
  .sidebar_inner
  .footer_links
  li.heading:hover
  span {
  z-index: auto !important;
}
</style>
