<template>
  <div v-if="isLoading">
    <SkeletonBox class="!w-full" :style="{ height: chartHeight }"></SkeletonBox>
  </div>
  <div
    v-else-if="isAllDataFetched"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
    <p>Data is being fetched.</p>
  </div>
  <div
    v-else-if="!data.length"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img
      src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
      alt=""
    />
    <p>No Reacts Data Found.</p>
  </div>
  <div v-else class="flex w-full h-full">
    <div class="flex-1">
      <div
        ref="chartRef"
        :style="{ height: isModal ? '85vh' : chartHeight }"
      ></div>
    </div>
  </div>
</template>

<script>
// libraries
import { computed, watch, defineComponent, onMounted } from 'vue'

// components
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// helpers
import { commonMethods } from '@common/store/common-methods'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'

export default defineComponent({
  name: 'PostReactsDistributionChart',
  components: {
    SkeletonBox,
  },
  props: {
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    chartTypes: {
      type: Array,
      default: () => ['bar'],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const allReactions = [
      'Likes',
      'Comments',
      'Shares',
      'Love',
      'Care',
      'Haha',
      'Wow',
      'Angry',
      'Sad',
    ]

    const reactionIcons = {
      Likes: require('@assets/img/analytics/facebook/fb_like.svg'),
      Comments: require('@assets/img/analytics/facebook/fb_comment.svg'),
      Shares: require('@assets/img/analytics/facebook/fb_share.svg'),
      Love: require('@assets/img/analytics/facebook/fb_love.svg'),
      Care: require('@assets/img/analytics/facebook/fb_care.svg'),
      Haha: require('@assets/img/analytics/facebook/fb_haha.svg'),
      Wow: require('@assets/img/analytics/facebook/fb_wow.svg'),
      Angry: require('@assets/img/analytics/facebook/fb_angry.svg'),
      Sad: require('@assets/img/analytics/facebook/fb_sad.svg'),
    }

    // mounted
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })

    // methods

    /**
     * @description method to check and create labels(having slug) or label objects having images as background colors to be plotted on axisLabels
     */
    const getLabels = () => {
      const labels = {}
      allReactions.forEach((type) => {
        labels[type] = {
          height: 32,
          width: 32,
          align: 'center',
          backgroundColor: {
            image: reactionIcons[type]
          }
        };
      });
      return labels
    }

    /*
     * Tooltip formatter
     */
    const tooltipFormatter = (params) => {
      const name = params[0]?.name
      const value = params[0]?.data ?? 'N/A'

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <img src="${
              props.data[0]?.image
            }" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />
            <span class="text-[#000D21] text-base font-weight-500 w-[10rem] 2xl:w-[14rem] truncate">${
              props.data[0]?.page_name
            }</span>
          </div>
          <div class="flex items-end justify-between mt-4">
              <span class="text-[979CA0] text-sm mr-1.5">${name}</span>
              <span class="text-[#69788E] text-sm font-bold">${value}</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Total Posts</span>
              <span class="text-sm font-bold">${props.data[0]?.total_posts?.toLocaleString(
                'en-US',
                {
                  notation: 'compact',
                  compactDisplay: 'short',
                }
              )}</span>
            </div>
<div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Post Reactions</span>
              <span class="text-sm font-bold">${props.data[0]?.total_post_reactions?.toLocaleString(
                'en-US',
                {
                  notation: 'compact',
                  compactDisplay: 'short',
                }
              )}</span>
            </div>
        </div>
      `
    }

    /**
     * @description creates data for bar chart
     */
    const getBarChartData = () => {
      if (!props.data.length) return
      return {
        data: [
          props.data[0]?.total_likes,
          props.data[0]?.comments,
          props.data[0]?.shares,
          props.data[0]?.total_love,
          props.data[0]?.total_thankful,
          props.data[0]?.total_hahas,
          props.data[0]?.total_wow,
          props.data[0]?.total_sad,
          props.data[0]?.total_angry,
        ],
        cursor: 'auto',
      }
    }

    // computed chart options. uses helper methods & props to generate the options
    const chartOptions = computed(() => {
      if (props.isAllDataFetched) return {}
      return {
        legend: props.legend.show,
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              color: '#61BAE4',
              backgroundColor: '#E6F7FF',
            },
            crossStyle: {
              color: '#61BAE4',
              width: 1,
            },
          },
          formatter: tooltipFormatter,
        },
        grid: {
          left: 35,
          top: 30,
          right: 40,
          bottom: 30,
          show: true,
          width: 'auto',
          borderColor: '#E9EFF6',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          name: props.xAxisLabel,
          data: allReactions,
          axisPointer: {
            type: 'shadow',
            label: {
              show: false,
            },
          },
          axisLabel: {
            color: '#979CA0',
            interval: 0,
            align: 'center',
            margin: 14,
            formatter: (value) => {
              return '{' + value + '| }\n{value|' + value + '}'
            },
            rich: {
              value: {
                lineHeight: 20,
                align: 'center'
              },
              ...getLabels(),
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'white',
            },
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            lineStyle: {
              color: '#E9EFF6',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            name: props.yAxisLabel,
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            nameTextStyle: {
              color: '#979CA0',
            },
            axisLabel: {
              color: '#979CA0',
              fontSize: 12,
              fontWeight: 400,
              formatter: (value) => {
                return value?.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
            axisPointer: {
              type: 'line',
              label: {
                formatter: (dataPoint) => {
                  return dataPoint.value?.toLocaleString('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  })
                },
              },
            },
            splitLine: {
              lineStyle: {
                color: '#F2F4F6',
              },
            },
          },
        ],
        series: [
          {
            name: props.yAxisLabel,
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
            },
            color: '#78CEA0',
            barCategoryGap: '70%',
            ...getBarChartData(),
          },
        ],
      }
    })

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => [chartRef.value, chartOptions.value],
      () => {
        if (!chartRef.value) return
        setup(chartOptions.value)
      }
    )

    return {
      // data
      chartRef,

      // methods
      commonMethods,
    }
  },
})
</script>
