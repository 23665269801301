<template>
  <div>
    <div
      :class="{
        loader_overlay_with_loader:
          getUtms.loader ||
          ($route.name !== 'miscellaneous' && getPublishLoaders.fetchLongLink),
      }"
    >
      <clip-loader
        v-if="
          getUtms.loader ||
          ($route.name !== 'miscellaneous' && getPublishLoaders.fetchLongLink)
        "
        class="d-block text-center"
        style="padding: 15px"
        color="#4165ed"
        :size="'16px'"
      ></clip-loader>
      <div
        v-if="$route.name !== 'miscellaneous'"
        class="field_group"
        :class="{ 'mt-0': nomargin }"
      >
        <label for="">Select from saved UTM presets</label>
        <div class="field_group">
          <select
            id=""
            v-model="getPublishSelection.utm.selected"
            name=""
            class="sm-for-mobile"
            data-cy="select-utm"
            @change="changeUtmSelectionById(getPublishSelection.utm.selected)"
          >
            <option value="" data-cy="create-newutm">Create New</option>
            <option v-for="item in getUtms.items" :value="item._id">{{
              item.name
            }}</option>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>
      <template
        v-if="
          $route.name !== 'miscellaneous' && getPublishSelection.utm.selected
        "
      >
        <div
          v-show="getPublishSelection.utm.edit_mode === false"
          class="content_preview"
        >
          <div class="p_content_inner">
            <div
              v-if="
                $route.name !== 'miscellaneous' && getPublishUtmSelection.link
              "
              class="field_group"
            >
              <label for="">URL</label>
              <p>{{ getPublishUtmSelection.link }}</p>
            </div>

            <div
              v-if="!getCurrentUtms.auto_add_social_channel"
              class="field_group"
            >
              <label for="">Campaign Source</label>
              <p>{{ getCurrentUtms.source }}</p>
            </div>
            <div v-else class="field_group">
              <label for="">Campaign Source</label>
              <p>Automatically add social's channel name as a source</p>
            </div>
            <div class="field_group">
              <label for="">Campaign Medium</label>
              <p>{{ getCurrentUtms.medium }}</p>
            </div>

            <div class="field_group">
              <label for="">Campaign Name</label>
              <p>{{ getCurrentUtms.name }}</p>
            </div>

            <div v-if="getCurrentUtms.content" class="field_group">
              <label for="">Campaign Ads Content</label>
              <p>{{ getCurrentUtms.content }}</p>
            </div>
          </div>
          <div class="input_field text-right">
            <button class="btn" @click="setUtmEditMode(true)">
              <span>Edit</span>
            </button>
          </div>
        </div>

        <!-- edit mode --->
        <div v-show="getPublishSelection.utm.edit_mode">
          <div
            v-if="$route.name !== 'miscellaneous'"
            class="field_group field_no_placeholder"
          >
            <input
              id="utmURL"
              v-model="getPublishUtmSelection.link"
              class=""
              placeholder="URL"
              type="text"
            />
            <label for="utmURL" class="label-animated">URL</label>
          </div>

          <div class="field_group field_no_placeholder clear">
            <input
              id="utmCampaignSource"
              v-model="getCurrentUtms.source"
              class=""
              placeholder="Campaign Source"
              type="text"
              :class="{ disabled: getCurrentUtms.auto_add_social_channel }"
              :disabled="getCurrentUtms.auto_add_social_channel"
              data-cy="utm-campaign-source"
            />
            <label for="utmCampaignMedium" class="label-animated"
              >Campaign Source</label
            >
          </div>

          <div class="field_group">
            <div class="checkbox_container">
              <label for="SourceEdit" data-cy="automatic-add-checkbox">
                Automatically add social channel's name as a source
                <input
                  id="SourceEdit"
                  v-model="getCurrentUtms.auto_add_social_channel"
                  type="checkbox"
                  name="Source"
                  value="Source"
                />
                <span class="check"></span>
              </label>
            </div>
          </div>
          <div class="field_group field_no_placeholder">
            <input
              id="utmCampaignMediumEdit"
              v-model="getCurrentUtms.medium"
              class=""
              type="text"
              placeholder="Campaign Medium"
              data-cy="utm-campaign-medium"
            />
            <label for="utmCampaignMediumEdit" class="label-animated"
              >Campaign Medium</label
            >
          </div>
          <div class="field_group field_no_placeholder">
            <input
              id="utmCampaignEdit"
              v-model="getCurrentUtms.name"
              class=""
              type="text"
              placeholder="Campaign name e.g marketing posts"
              data-cy="utm-campaign-name"
              maxlength="90"
            />
            <label for="utmCampaignEdit" class="label-animated"
              >Campaign Name</label
            >
          </div>

          <div class="field_group field_no_placeholder">
            <input
              id="utmCampaignContentEdit"
              v-model="getCurrentUtms.content"
              class=""
              type="text"
              placeholder="Campaign Content"
              data-cy="utm-campaign-content"
            />
            <label for="utmCampaignContentEdit" class="label-animated"
              >Campaign Ad Content</label
            >
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="$route.name !== 'miscellaneous'"
          class="field_group field_no_placeholder"
        >
          <input
            id="utmURL"
            v-model="getPublishUtmSelection.link"
            class=""
            placeholder="URL"
            type="text"
          />
          <label for="utmURL" class="label-animated">URL</label>
        </div>

        <div class="field_group field_no_placeholder clear">
          <input
            id="utmCampaignSource"
            v-model="getCurrentUtms.source"
            class=""
            placeholder="Campaign Source"
            type="text"
            :class="{ disabled: getCurrentUtms.auto_add_social_channel }"
            :disabled="getCurrentUtms.auto_add_social_channel"
            data-cy="utm-campaign-source"
          />
          <label for="utmCampaignSource" class="label-animated"
            >Campaign Source</label
          >
        </div>
        <div class="field_group">
          <div class="checkbox_container">
            <label for="Source" data-cy="automatic-add-checkbox">
              Automatically add social channel's name as a source
              <input
                id="Source"
                v-model="getCurrentUtms.auto_add_social_channel"
                type="checkbox"
                name="Source"
                value="Source"
              />
              <span class="check"></span>
            </label>
          </div>
        </div>

        <div class="field_group field_no_placeholder">
          <input
            id="utmCampaignMedium"
            v-model="getCurrentUtms.medium"
            class=""
            type="text"
            placeholder="Campaign Medium"
            data-cy="utm-campaign-medium"
          />
          <label for="utmCampaignMedium" class="label-animated"
            >Campaign Medium</label
          >
        </div>

        <div class="field_group field_no_placeholder">
          <input
            id="utmCampaign"
            v-model="getCurrentUtms.name"
            class=""
            type="text"
            placeholder="Campaign name e.g marketing posts"
            data-cy="utm-campaign-name"
            maxlength="90"
          />
          <label for="utmCampaign" class="label-animated">Campaign Name</label>
        </div>

        <div class="field_group field_no_placeholder">
          <input
            id="utmCampaignContent"
            v-model="getCurrentUtms.content"
            class=""
            type="text"
            placeholder="Campaign Content"
            data-cy="utm-campaign-content"
          />
          <label for="utmCampaignContent" class="label-animated"
            >Campaign Ad Content</label
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  props: ['type', 'nomargin'],

  data () {
    return {
      hashtag_test: ''
    }
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getUtms',
      'getCurrentUtms',
      'getPublishUtmSelection',
      'getPublishLoaders'
    ])
  },
  methods: {
    ...mapActions(['setUtmEditMode'])
  },

  watch: {}
}
</script>
