<script>
import { mapGetters } from 'vuex'
import {
  EMPTY_BOARD_NAME_ERROR,
  EMPTY_BOARD_PROFILE_ERROR
} from '@common/constants/messages'
import { pinterest } from '../../store/states/mutation-types'

export default {
  // props: ['board_profile'],
  components: {},

  data () {
    return {
      board_profile: '',
      board_name: '',
      create_board_loader: false,
      validations: {
        name: false,
        profile: false
      },
      messages: {
        name: EMPTY_BOARD_NAME_ERROR,
        profile: EMPTY_BOARD_PROFILE_ERROR
      }
    }
  },

  created () {},
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPinterestAccounts',
      'getPinterestProfiles'
    ]),
    createBoardLoaderStatus () {
      return this.create_board_loader
    },
    isEmptyName () {
      return this.validations.name
    },
    isEmptyProfile () {
      return this.validations.profile
    }
  },
  watch: {
    board_name (value) {
      if (value && value.length > 0) {
        console.debug('Watch::board_name', value)
        this.validations.name = false
      }
    },
    board_profile (value) {
      if (value && value.length > 0) {
        console.debug('Watch::board_profile', value)
        this.validations.profile = false
      }
    }
  },
  mounted () {},

  methods: {
    hideModal () {
      this.$bvModal.hide('createBoard')
    },
    createBoardKeyDown (event) {
      if (event.key === 'Enter' && this.createBoardLoaderStatus === false) {
        this.createBoard()
      }
    },
    async createBoard () {
      console.debug('Method:createBoard')
      this.validations.name = this.requiredCheck(this.board_name)
      this.validations.profile = this.requiredCheck(this.board_profile)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        this.create_board_loader = true
        const response = await this.$store.dispatch('processBoardCreation', {
          name: this.board_name,
          profile: this.board_profile,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        })
        if (response.data.status) {
          const items = JSON.parse(
            JSON.stringify(this.getPinterestAccounts.items)
          )
          const allItems = JSON.parse(
            JSON.stringify(this.getPinterestAccounts.all_items)
          )
          items.unshift(response.data.item)
          allItems.unshift(response.data.item)
          this.$store.commit(pinterest.SET_ACCOUNTS, items)
          this.$store.commit(pinterest.SET_ALL_ACCOUNTS, allItems)
          this.board_name = ''
          this.hideModal()
        }
        this.create_board_loader = false
      }
    }
  }
}
</script>

<template>
  <b-modal
    id="createBoard"
    modal-class="create_board normal_modal"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Create Board</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="hideModal"
        >&times;</button
      >
    </div>

    <div class="modal_body basic_form m_t_15">
      <div class="input_field mt-0">
        <label for="">Board Name</label>
        <div class="input_validation">
          <input
            v-model="board_name"
            :class="{ 'input-field-error': validations.name }"
            type="text"
            placeholder="Board Name..."
            @keydown="createBoardKeyDown($event)"
          />
          <span v-if="isEmptyName" class="input-error">{{
            messages.name
          }}</span>
        </div>
      </div>
      <div class="input_field">
        <label for="">Pinterest Profile</label>
        <div class="input_validation">
          <select
            id=""
            v-model="board_profile"
            :class="{ 'input-field-error': validations.profile }"
            class="with_bg"
            name=""
          >
            <option value="">Select Pinterest profile</option>
            <option
              v-for="profile in getPinterestProfiles"
              :value="profile.pinterest_id"
              >{{ profile.first_name }} {{ profile.last_name }}</option
            >
          </select>
          <span v-if="isEmptyProfile" class="input-error">{{
            messages.profile
          }}</span>
        </div>
      </div>
      <div class="input_field">
        <button
          class="btn gradient_btn large_btn btn_loader loader_right"
          :disabled="createBoardLoaderStatus"
          @click.prevent="createBoard"
        >
          <span>Create</span>
          <clip-loader
            v-if="createBoardLoaderStatus"
            :color="'#ffffff'"
            :size="'16px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>
