<template>
  <div class="scroll_content influencer-shared-article">
    <template v-if="getInfluencerLoaders.shared_articles">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="shared_articles">
      <!--List view-->
      <div
        v-for="(post, index) in shared_articles"
        :key="'list_' + post.id"
        class="article_box_list"
      >
        <div v-if="post" class="box_inner">
          <div class="d-flex align-items-center">
            <div class="image_block">
              <div v-if="post.categories" class="tags_block">
                <span
                  v-for="(category, categoryIndex) in post.categories"
                  :key="`categoryIndex_${categoryIndex}`"
                  class="tag capitalize"
                  >{{ category }}</span
                >
              </div>

              <div
                :style="{
                  backgroundImage: `url('${backgroundImageURL(post)}')`,
                }"
                class="img"
              >
                <div class="article_type">
                  <span v-if="post.read_time && post.read_time === 1">
                    Less than 1 Min. Read
                  </span>
                  <span v-if="post.read_time && post.read_time > 1">
                    {{ post.read_time }} Min. Read
                  </span>
                  - <span v-if="post.has_abstract">Abstract Content</span>
                  <span v-else>Full Content</span></div
                >
              </div>
            </div>
            <div class="content_block d-flex align-items-center">
              <div class="left_content">
                <h2
                  :title="limitTextLength(post.title, 0)"
                  @click.prevent="handleArticlePreview(post.id,index)"
                  v-html="limitTextLength(post.title, 80)"
                ></h2>
                <div class="detail clear">
                  <div class="d-flex align-items-center">
                    <div class="align-left d-flex align-items-center">
                      <p class="text_row">
                        <a href="javascript:;">{{
                          trimURL(post.domain_url)
                        }}</a>
                      </p>
                      <span class="line fas fa-circle"></span>
                      <p class="text_row">{{
                        $filters.relative(post.post_date)
                      }}</p>
                      <span class="line fas fa-circle"></span>
                      <p class="author_detail">
                        <template v-if="post.author">
                          By
                          <span
                            style="color: #4e92ff"
                            v-html="post.author"
                          ></span>
                          <a
                            v-if="post.twitter_handler"
                            target="_blank"
                            :href="getTwitterLink(post.twitter_handler)"
                          >
                            {{ post.twitter_handler }}</a
                          >
                          <a
                            v-for="(
                              account, twitterAccountIndex
                            ) in post.twitter_accounts"
                            v-else-if="
                              post.twitter_accounts && twitterAccountIndex < 1
                            "
                            :key="`twitterAccountIndex_${twitterAccountIndex}`"
                            target="_blank"
                            :href="getTwitterLink(account)"
                          >
                            {{ account }}</a
                          >
                        </template>
                        <template v-else-if="post.twitter_handler">
                          By
                          <a
                            target="_blank"
                            :href="getTwitterLink(post.twitter_handler)"
                            >{{ post.twitter_handler }}</a
                          >
                        </template>
                        <template
                          v-else-if="
                            post.twitter_accounts &&
                            post.twitter_accounts.length > 0
                          "
                        >
                          By
                          <a
                            v-for="(account, index) in post.twitter_accounts"
                            :key="`account_${index}`"
                            target="_blank"
                            :href="getTwitterLink(account)"
                          >
                            <template v-if="post.twitter_accounts && index < 1">
                              {{ account }}
                            </template>
                          </a>
                        </template>
                      </p>
                    </div>
                    <div class="align-right ml-auto">
                      <div
                        v-if="post.top_twitter_influencers"
                        class="top_influencers d-inline-block mt-2"
                      >
                        <div
                          v-if="post.total_influencers_shares"
                          class="d-inline-block influencer_left"
                        >
                          <p
                            >Shared by
                            <strong>{{
                              $filters.numberToCommas(
                                post.total_influencers_shares,
                              )
                            }}</strong>
                            influencers</p
                          >
                        </div>
                        <div
                          v-if="post.total_influencers_shares > 0"
                          class="d-inline-block influencer_right ml-3"
                        >
                          <!--                                                      @click.prevent="showSharers(post.id)"-->
                          <!--                                                      v-if="influencer.follower_count > 1000"-->
                          <!--                                                      v-if="index <= 3"-->
                          <template
                            v-for="(
                              influencer, index
                            ) in post.top_twitter_influencers"
                            :key="index"
                          >
                            <a
                              v-tooltip="{
                                content: accountTooltipHtml(
                                  'influencer',
                                  influencer,
                                ),
                                classes: 'team_tooltip',
                              }"
                              target="_blank"
                              :href="
                                'https://twitter.com/' + influencer.screen_name
                              "
                            >
                              <div
                                v-if="influencer.profile_image"
                                class="img"
                                :style="{
                                  background:
                                    'url(' + influencer.profile_image + ')',
                                }"
                              ></div>
                              <div
                                v-else
                                class="img"
                                style="
                                  background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                "
                              ></div>
                            </a>
                          </template>
                          <!--                                                        <div class="rounded_more" v-if="post.top_twitter_influencers.length > 3 && post.total_influencers_shares - 4 > 0">+{{post.total_influencers_shares - 4}}</div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tag_section">
                    <template
                      v-for="(item, topicIndex) in post.topics"
                      :key="`topics_${topicIndex}`"
                    >
                      <template
                        v-if="isTopicsDisplayAllowed({ _source: post })"
                      >
                        <span
                          v-for="(
                            finalized_topic, finalizedTopicIndex
                          ) in checkIfMultipleTopics(item.label)"
                          :key="`${finalizedTopicIndex}_${finalized_topic}`"
                        >
                          {{ finalized_topic }}
                        </span>
                      </template>
                    </template>
                  </div>
                </div>
                <div
                  v-if="post.related_ids_count > 1"
                  class="similar_content"
                  @click.prevent="retrieveSimilarArticles(post.id, post._index)"
                  >{{ post.related_ids_count }} similar articles</div
                >
                <div class="btn_block">
                  <div
                    class="
                      article_share_dropdown article_post_dropdown
                      inline_block
                      dropdown
                      default_style_dropdown
                    "
                  >
                    <div class="dropdown_header" data-toggle="dropdown">
                      <button class="btn light_gray">
                        <i class="cs-share mr-2"></i>
                        <span>Share this post</span>
                      </button>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <li
                          class="list_item_li"
                          @click.prevent="
                            initializeArticlePostCreation(
                              post.id,
                              'social_share_modal',
                            )
                          "
                        >
                          <i class="cs-social-share"></i>
                          <span class="icon_text">Social Media</span>
                        </li>
                        <li
                          class="list_item_li"
                          @click.prevent="
                            initializeArticlePostCreation(
                              post.id,
                              'blog_share_modal',
                            )
                          "
                        >
                          <i class="cs-rss"></i>
                          <span class="icon_text">Blog Post</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="right_content clear d-flex align-items-center">
                <div class="social_stats">
                  <div
                    class="
                      social_stats_inner
                      d-flex
                      justify-content-center
                      flex-column
                    "
                  >
                    <div class="stat_item custom_tooltip">
                      <div class="name">
                        <p>Engagement</p>
                      </div>
                      <div class="value">
                        <h3>{{ intToString(calculateEngagements(post)) }}</h3>
                      </div>

                      <div class="tool_tip_box left_middle">
                        <div class="tool_tip_inner">
                          <div class="d-flex align-items-center">
                            <div
                              v-tooltip.top="'Facebook Shares'"
                              class="engagement_stat"
                            >
                              <span class="icon_item">
                                <i class="fb cs-facebook"></i>
                              </span>
                              <span v-if="post.facebook_shares">{{
                                post.facebook_shares
                              }}</span>
                              <span v-else>0</span>
                            </div>
                            <div
                              v-tooltip.top="'X (Twitter) Shares'"
                              class="engagement_stat"
                            >
                              <span class="icon_item">
                                <i class="tw cs-twitter"></i>
                              </span>
                              <span v-if="post.twitter_shares">{{
                                post.twitter_shares
                              }}</span>
                              <span v-else>0</span>
                            </div>
                            <div
                              v-tooltip.top="'Pinterest Shares'"
                              class="engagement_stat"
                            >
                              <span class="icon_item">
                                <i class="pin cs-pinterest"></i>
                              </span>
                              <span v-if="post.pins">{{ post.pins }}</span>
                              <span v-else>0</span>
                            </div>
                            <div
                              v-tooltip.top="'Reddit Shares'"
                              class="engagement_stat"
                            >
                              <span class="icon_item">
                                <i class="reddit cs-reddit"></i>
                              </span>
                              <span v-if="post.reddit">{{ post.reddit }}</span>
                              <span v-else>0</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="post.velocity && post.velocity > 5"
                      class="stat_item"
                    >
                      <div class="name">
                        <p>Trending Score</p>
                      </div>
                      <div class="value">
                        <h3>{{ intToString(post.velocity) }}</h3>
                      </div>
                    </div>
                    <div class="stat_item">
                      <div class="name">
                        <p>Sentiment</p>
                      </div>
                      <div
                        v-if="post.sentiment && post.sentiment.length > 0"
                        class="value"
                      >
                        <i
                          v-if="post.sentiment[0].label === 'negative'"
                          v-tooltip="'Negative'"
                          class="cs-sad-face sad"
                        >
                        </i>
                        <i
                          v-if="
                            post.sentiment[0].label === 'positive' &&
                            post.sentiment[0].probability >= 0.65
                          "
                          v-tooltip="'Positive'"
                          class="cs-happy-face smile"
                        >
                        </i>
                        <i
                          v-else-if="
                            post.sentiment[0].label === 'positive' &&
                            post.sentiment[0].probability < 0.65
                          "
                          v-tooltip="'Neutral'"
                          class="cs-normal-face normal"
                        >
                        </i>
                        <i
                          v-if="post.sentiment[0].label === 'neutral'"
                          v-tooltip="'Neutral'"
                          class="cs-normal-face normal"
                        >
                        </i>
                      </div>
                      <div v-else class="value">
                        <i
                          v-tooltip="'Neutral'"
                          class="cs-normal-face normal"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn_block_footer">
            <div
              class="
                article_share_dropdown article_post_dropdown
                inline_block
                dropdown
                default_style_dropdown
              "
            >
              <div class="dropdown_header" data-toggle="dropdown">
                <button
                  v-tooltip.top="'Share this post'"
                  class="btn light_gray"
                >
                  <i class="icon-Share_icon d-block"></i>
                </button>
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    class="list_item_li"
                    @click.prevent="
                      initializeArticlePostCreation(
                        post.id,
                        'social_share_modal',
                      )
                    "
                  >
                    <i class="cs-social-share"></i>
                    <span class="icon_text">Social Media</span>
                  </li>
                  <li
                    class="list_item_li"
                    @click.prevent="
                      initializeArticlePostCreation(post.id, 'blog_share_modal')
                    "
                  >
                    <i class="cs-rss"></i>
                    <span class="icon_text">Blog Post</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="not_found_text pt-2">
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <p>No data found.</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    shared_articles: {},
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders']),
  },
  methods: {
    ...mapActions(['articlePreview']),
    handleArticlePreview(source,index){
      this.articlePreview({ id: source, index, appContainer: this })
    }
  },
}
</script>
