<script setup>
import { watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@state/base'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import debounce from 'lodash.debounce'
import CardsComponent from '@/src/modules/analytics/views/pinterest/components/CardsComponent'
import FollowersGraph from '@/src/modules/analytics/views/pinterest/components/graphs/FollowersGraph.vue'
import ImpressionsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/EngagementsGraph.vue'
import PinsPostingAndEngagements from '@/src/modules/analytics/views/pinterest/components/graphs/PinsPostingAndEngagements.vue'
import PostingByDayGraph from '@/src/modules/analytics/views/pinterest/components/graphs/PostingByDayGraph.vue'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/pinterest/components/TopAndLeastEngagingPosts'

// state
const route = useRoute()
const store = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  topEngagingPosts,
  leastEngagingPosts,
  getPerformanceChartPayload,
  getPostingByDayChartPayload,
  getTopLeastEngagingPayload,
  selectedTopLeastSortType,
  fetchMedia,
  resetDefaults,
} = usePinterestAnalytics()

watch(
  () => [dateRange, route.params.accountId],
  debounce(async (newVal, oldVal) => {
    if (!route.name.includes('pinterest')) return
    selectedAccount.value = store.getters.getPinterestAccounts.items?.find(
      (item) => {
        return item.type.toLowerCase() === 'profile'
          ? item.pinterest_id === route.params.accountId
          : item.board_id === route.params.accountId
      }
    )
    try {
      // If the account has been updated regardless of date we will reset to defaults
      if (newVal?.length > 1 && oldVal?.length > 1 && newVal[1] !== oldVal[1]) {
        resetDefaults()
      }
    } catch (e) {
      console.error(e)
    }
    fetchMedia(routes.OVERVIEW_SUMMARY, {
      type: 'summary',
    })
    fetchMedia(routes.FOLLOWERS_TREND)
    fetchMedia(routes.IMPRESSIONS_TREND)
    fetchMedia(routes.ENGAGEMENTS_TREND)
    fetchMedia(routes.PIN_POSTING_PER_DAY, getPostingByDayChartPayload())
    fetchMedia(routes.PIN_POSTING_ROLLUP, getPostingByDayChartPayload())
    fetchMedia(routes.TOP_PERFORMING_PINS, getTopLeastEngagingPayload('top'))
    fetchMedia(
      routes.LEAST_PERFORMING_PINS,
      getTopLeastEngagingPayload('least')
    )
    fetchMedia(routes.PIN_POSTING_PERFORMANCE, getPerformanceChartPayload())
  }, 300),
  { deep: true, immediate: true }
)

const getSelectedTopPosts = computed(() => {
  return topEngagingPosts.value[selectedTopLeastSortType.value?.top?.api_key]
})

const getSelectedLeastPosts = computed(() => {
  return leastEngagingPosts.value[selectedTopLeastSortType.value?.least?.api_key]
})

</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-6 gap-4 mb-7">
      <CardsComponent />
    </div>

    <div class="flex flex-col gap-y-[1.75rem]">
      <!-- Graphs Here -->

      <FollowersGraph />
      <ImpressionsGraph />
      <EngagementsGraph />
      <PinsPostingAndEngagements />
      <PostingByDayGraph />

      <!-- Engaging Posts -->
      <TopAndLeastEngagingPosts
        :top-posts="getSelectedTopPosts"
        :least-posts="getSelectedLeastPosts"
        :selected-account="selectedAccount"
      />
    </div>
  </div>
</template>
