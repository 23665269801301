<template>
  <!-- The Modal -->
  <div
    id="twitter_influencer_modal"
    class="full_height modal fade twitter_influencer_modal right side_slide_right w_1050"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>X (Twitter) Influencers </h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body height">
          <!-- Show the loader meanwhile we are fetching the posts -->
          <div
            v-if="getTwitterSharesLoader.sharerLoader"
            class="d-flex height align-items-center justify-content-center"
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
          <!-- get the list of shared tweets and iterate them -->
          <template v-else-if="getSharerData.length > 0">
            <div
              v-for="(user, key) in getSharerData"
              :key="key"
              class="twitter_influencer_box"
            >
              <div class="box_inner">
                <div class="d-flex align-items-center">
                  <div class="t_left">
                    <div class="d-flex item_row">
                      <div class="row_left">
                        <div class="picture_box">
                          <img :src="user.profile_image" alt="" />
                        </div>
                      </div>
                      <div class="row_right border_bottom">
                        <div class="top_content">
                          <h3 v-html="twitterUsername(user)"></h3>
                          <p v-if="user.bio" v-html="user.bio"></p>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex item_row">
                      <div class="row_left">
                        <template v-if="user.tweet_created_at">
                          <p>{{ $filters.relative(user.tweet_created_at) }}</p>
                        </template>
                      </div>
                      <div class="row_right">
                        <div class="bottom_content">
                          <p v-html="user.text"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="t_right">
                    <p>Followers</p>
                    <h3>{{ intToString(user.follower_count) }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- if no post has been shared-->
          <template v-else>
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p>This post has not been shared by any influencer.</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getTwitterSharesLoader', 'getSharerData'])
  },
  methods: {
    twitterUsername (user) {
      return (
        user.name +
        '<a href="' +
        this.handleLink(user.screen_name) +
        '" target="_blank">@' +
        user.screen_name +
        '</a>'
      )
    }
  }
}
</script>
