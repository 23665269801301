<template>
  <div>
    <VideoPreviewDialog></VideoPreviewDialog>
    <div class="articles-content-container">
      <div class="articles-content-container__inner">
        <!--<search-total-results :total_results="total_results" :can_change_view="false"></search-total-results>-->
        <template v-if="!loaders.retrieve">
          <template v-for="video in videos">
            <YoutubeVideoItem :video="video"></YoutubeVideoItem>
          </template>
          <InfiniteLoading
            ref="infiniteWebYoutubeSearch"
            :identifier="infiniteId"
            @infinite="getYoutubeVideosList"
          >
            <span slot="spinner" class="d-block pt-3 pb-4">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <div slot="no-more" class="not_found_text">
              <div class="no_data_found_content">
                <content-available-state :type="'no_results'">
                  <h2 slot="headline">No more results available.</h2>
                  <p slot="text"
                    >Please try a different search string or change search
                    filters</p
                  >
                </content-available-state>
              </div>
            </div>

            <div slot="no-results">
              <div class="no_data_found_content">
                <content-available-state :type="'no_results'">
                  <h2 slot="headline">No results found.</h2>
                  <p slot="text"
                    >Please try a different search string or change search
                    filters</p
                  >
                </content-available-state>
              </div>
            </div>
          </InfiniteLoading>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { EventBus } from '@common/lib/event-bus'
import { mapGetters } from 'vuex'
import SearchTotalResults from '../../common/components/SearchTotalResults'
import YoutubeVideoItem from '../../common/components/YoutubeVideoItem'
import VideoPreviewDialog from '../../common/preview/VideoPreviewDialog'

const getDefaultYoutubeFilters = () => {
  return {
    order: 'Relevance',
    search_type: 'search_youtube',
    query: '',
    pageToken: '',
    videoDefinition: ''
  }
}
export default {
  components: {
    SearchTotalResults,
    YoutubeVideoItem,
    InfiniteLoading,
    VideoPreviewDialog
  },
  created () {
    this.refreshYoutubeVideosFilters()
    this.loaders.retrieve = false
  },
  computed: {
    ...mapGetters(['getActiveWorkspace'])
  },
  data () {
    return {
      total_results: 0,
      filters: getDefaultYoutubeFilters(),
      videos: [],
      infiniteId: +new Date(),
      loaders: {
        retrieve: false
      }
    }
  },
  watch: {
    '$route.query.q' (value) {
      this.refreshYoutubeVideosFilters()
      EventBus.$emit('reset-related-topics-suggestions')
    },
    '$route.query.order' (value) {
      this.refreshYoutubeVideosFilters()
    },
    '$route.query.videoDefinition' (value) {
      this.refreshYoutubeVideosFilters()
    },
    'getActiveWorkspace._id' (value) {
      this.$router.push({ name: 'discover_search' })
    }
  },
  methods: {
    refreshYoutubeVideosFilters () {
      this.loaders.retrieve = true
      this.videos = []
      this.infiniteId += 1
      this.filters = getDefaultYoutubeFilters()
      if (this.$route.query.q) {
        this.filters.query = this.$route.query.q
      }
      this.filters = Object.assign(this.filters, this.$route.query)
      this.loaders.retrieve = false
    },
    getYoutubeVideosList ($state) {
      const youtubeResponse = this.$store.dispatch(
        'fetchDiscoverSearchYoutubeVideos',
        this.filters
      )
      youtubeResponse
        .then((res) => {
          if (res.data) {
            if (res.data.videos && res.data.videos.length) {
              this.videos = this.videos.concat(res.data.videos)
            }
            if (res.data.page_token) {
              this.filters.pageToken = res.data.page_token
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {})
        .finally()
      if (!this.filters.pageToken) {
        EventBus.$emit('related-topics-suggestions')
      }
    }
  }
}
</script>
