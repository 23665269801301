<script setup>
import { computed, watch } from 'vue'
import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/instagram_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/instagram_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/instagram_v2/components/graphs/AudienceGrowthChart.vue'
import ActiveUserByHour from '@src/modules/analytics/views/instagram_v2/components/graphs/ActiveUserByHour.vue'
import ActiveUserByDay from '@src/modules/analytics/views/instagram_v2/components/graphs/ActiveUserByDay.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import ReelsPerformanceChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/ReelsPerformanceChart.vue'
import RollupCard from '@/src/modules/analytics/views/instagram_v2/components/RollupCard.vue'
import StoriesPerformanceChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/StoriesPerformanceChart.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/PublishingBehaviourChart.vue'
import PublishingBehaviourBreakdownTable from '@/src/modules/analytics/views/instagram_v2/components/PublishingBehaviourBreakdownTable.vue'
import HashtagsChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/HashtagsChart.vue'

const {
  routes,
  dateRange,
  selectedAccount,
  overviewPublishingBehaviourData,
  isLoadingStates,
  isReportView,
  rollupTypes,
  fetchMedia,
} = useInstagramAnalytics()

watch(
  () => [dateRange.value, selectedAccount.value],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.OVERVIEW_AUDIENCE_GROWTH)
    fetchMedia(routes.OVERVIEW_PUBLISHING_BEHAVIOUR)
    fetchMedia(routes.OVERVIEW_TOP_POSTS)
    fetchMedia(routes.OVERVIEW_ACTIVE_USERS)
    fetchMedia(routes.OVERVIEW_HASHTAGS)
    fetchMedia(routes.OVERVIEW_REELS_ANALYTICS)
    fetchMedia(routes.OVERVIEW_STORY_ANALYTICS)
  }, 500),
  { deep: true, immediate: true }
)

const breakdownTableData = computed(()=>{
  return overviewPublishingBehaviourData.value?.publishing_behaviour_rollup || []
})

</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-6 lg:grid-cols-8 2xl:grid-cols-6 gap-4 mb-4">
      <CardsComponent />
    </div>
    <div class="flex flex-col gap-y-4">
      <!-- Audience Growth Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <AudienceGrowthChart />
        </div>
        <RollupCard :type="rollupTypes.AUDIENCE" />
      </div>
      <!-- Publishing Behaviour Chart -->
      <PublishingBehaviourChart />
      <!-- Publishing Behaviour Breakdown Table -->
      <PublishingBehaviourBreakdownTable
        :data-list="breakdownTableData"
        :is-loading="isLoadingStates[routes.OVERVIEW_PUBLISHING_BEHAVIOUR]"
        :is-report-view="isReportView"
        />

      <!-- Hastags Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <HashtagsChart />
        </div>
        <RollupCard :type="rollupTypes.HASHTAGS" />
      </div>
      <div class="grid grid-cols-1 desktop:grid-cols-2 gap-4 ">
        <!-- Active User By Hour -->
        <ActiveUserByHour />
        <!-- Active User By Day -->
        <ActiveUserByDay />
      </div>
      <!-- Reels Performance Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <ReelsPerformanceChart />
        </div>
        <RollupCard :type="rollupTypes.REELS" />
      </div>
      <!-- Story Performance Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <StoriesPerformanceChart />
        </div>
        <RollupCard :type="rollupTypes.STORIES" />
      </div>
      <!-- Top Posts -->
      <TopPosts />
    </div>
  </div>
</template>

<style scoped>
</style>
