<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
export default {
  components: {
    InfiniteLoading,
  },
  setup(){
    const {formatNotificationDate} = useDashboard()
    return {formatNotificationDate}
  },

  data() {
    return {
      active_tab: 'all',
      notification_shown: true,
    }
  },
  computed: {
    ...mapGetters(['getWorkspaceNotifications']),

    getNotificationsItems() {
      switch (this.active_tab) {
        case 'all':
          return this.getWorkspaceNotifications.all_list.data
        case 'system':
          return this.getWorkspaceNotifications.system_list.data
        case 'team':
          return this.getWorkspaceNotifications.team_list.data
        case 'inbox':
          return this.getWorkspaceNotifications.inbox_list.data
      }
      return []
    },

    hasNotificationsItems() {
      return this.getNotificationsItems.length > 0
    },

    getNotificationsDates() {
      const dates = []
      this.getNotificationsItems.forEach((item) => {
        let date = item.created_at ? item.created_at : item.created_time
        date = this.getWorkspaceTimeZoneTime(date, 'YYYY-MM-DD HH:mm:ss')
        date = date.substr(0, 10)
        if (dates.indexOf(date) === -1) dates.push(date)
      })
      return dates
    },

    showInfiniteLoading() {
      return (
        this.hasNotificationsItems &&
        this.getWorkspaceNotifications.scroll_status &&
        this.hasRemainingRecords
      )
    },

    hasRemainingRecords() {
      switch (this.active_tab) {
        case 'all':
          return (
            this.getWorkspaceNotifications.all_list.current_page <=
            this.getWorkspaceNotifications.all_list.last_page
          )
        case 'system':
          return (
            this.getWorkspaceNotifications.system_list.current_page <=
            this.getWorkspaceNotifications.system_list.last_page
          )
        case 'team':
          return (
            this.getWorkspaceNotifications.team_list.current_page <=
            this.getWorkspaceNotifications.team_list.last_page
          )
        case 'inbox':
          return (
            this.getWorkspaceNotifications.inbox_list.current_page <=
            this.getWorkspaceNotifications.inbox_list.last_page
          )
      }
      return false
    },
  },
  methods: {
    async onClick(notification) {
      if(await this.onClickNotification(notification))
        EventBus.$emit('refreshPlannerTableV2')
    },

    closeNotificationModal() {
      this.notification_shown = false
    },
    isTabActive(tab) {
      return tab === this.active_tab
    },

    changeNotificationTab(tab) {
      this.active_tab = tab
      // document.documentElement.scrollTop = 0;
      document.querySelector('.content_scroll').scrollTop = 0
    },

    getNotificationBlockClass(item) {
      if (item.type) {
        switch (item.type) {
          case 'post_publish':
            return item.title === 'Post Published'
              ? 'bg_publish_cs'
              : 'status_bg failed'
          case 'automation_queued':
            return 'bg_automation_queued'
          case 'inbox':
            if (item.toast_text === 'A post has been marked as done.') {
              return 'bg_publish_cs'
            } else if (
              item.toast_text === 'A post has been marked as archived.'
            ) {
              return 'status_bg failed'
            }
            return 'bg_inbox_cs'
          case 'reject_approval':
            return 'bg_reject_approval'
          case 'pending_approval':
            return 'bg_pending_approval'
          case 'approve_approval':
            return 'bg_approve_approval'
          case 'post_rejected':
            return 'bg_post_rejected'
          case 'removed_from_workspace':
            return 'bg_removed_from_workspace'
          case 'role_changed':
            return 'bg_role_changed'
          case 'new_comment':
          case 'comment_in_my_post':
            return 'bg_new_comment'
          case 'posts_rejected':
            return 'bg_posts_rejected'
          case 'account_expired':
            return 'bg_account_expired'
          case 'new_post_created':
            return 'bg_new_post_created'
          case 'new_task_assigned':
            return 'bg_new_task_assigned'
          case 'workspace_removed':
            return 'bg_workspace_removed'
          case 'posts_approved':
            return 'bg_posts_approved'
          case 'post_approved':
            return 'bg_post_approved'
          case 'added_to_workspace':
            return 'bg_added_to_workspace'
          case 'mention_in_comment':
            return 'bg_mention_in_comment'
          case 'opml_import':
            return 'bg_opml_import'
          case 'feedly_import':
            return 'bg_feedly_import'
          case 'task_assigned_completed':
            return 'bg_task_assigned_completed'
          case 'workspace_joined':
            return 'bg_workspace_joined'
          case 'missed_plans':
            return 'bg_missed_plans'
        }
      }
      return ''
    },

    getNotificationIconClass(item) {
      if (item.type) {
        switch (item.type) {
          case 'post_publish':
            return item.title === 'Post Published'
              ? 'icon-published-cs'
              : item.title === 'Partially Failed'
              ? 'fas fa-adjust'
              : 'fa fa-exclamation-circle'
          case 'automation_queued':
            return 'fa fa-cogs'
          case 'inbox':
            if (item.toast_text === 'A post has been marked as done.') {
              return 'icon-published-cs'
            } else if (
              item.toast_text === 'A post has been marked as archived.'
            ) {
              return 'icon-published-cs'
            }
            return 'fa fa-inbox'
          case 'reject_approval':
            return 'icon-rejected-cs'
          case 'pending_approval':
            return 'fa fa-clock'
          case 'approve_approval':
            return 'fa fa-check'
          case 'post_rejected':
            return 'fa fa-ban'
          case 'removed_from_workspace':
            return 'fa fa-minus-circle'
          case 'role_changed':
            return 'fa fa-users'
          case 'new_comment':
          case 'comment_in_my_post':
            return 'fa fa-comments'
          case 'posts_rejected':
            return 'fa fa-ban'
          case 'account_expired':
            return 'fa fa-hourglass-end'
          case 'new_post_created':
            return 'fa fa-sticky-note'
          case 'new_task_assigned':
            return 'fa fa-tasks'
          case 'workspace_removed':
            return 'fa fa-building'
          case 'posts_approved':
            return 'fa fa-check'
          case 'post_approved':
            return 'fa fa-check'
          case 'added_to_workspace':
            return 'fa fa-plus'
          case 'mention_in_comment':
            return 'fa fa-at'
          case 'opml_import':
            return 'fa fa-file-import'
          case 'feedly_import':
            return 'fa fa-file-import'
          case 'task_assigned_completed':
            return 'fa fa-list'
          case 'workspace_joined':
            return 'fa fa-building'
          case 'missed_plans':
            return 'fas fa-phone-slash'
        }
      }
      return ''
    },

    fetchNotifications($state) {
      this.fetchWorkspaceNotifications({ $state })
    },

    markAllNotificationsRead() {
      this.$store.dispatch('markAllNotificationsRead')
    },

    checkNotificationDateInclusion(day, item) {
      let date = item.created_at ? item.created_at : item.created_time
      date = this.getWorkspaceTimeZoneTime(date, 'YYYY-MM-DD HH:mm:ss')
      date = date.substr(0, 10)
      return date === day
    },
  },
}
</script>

<template>
  <div v-if="notification_shown" class="tophead_notification_dropdown">
    <div class="tophead_notification_dropdown_container">
      <div class="mb-3 header_section d-flex align-items-center">
        <div class="head_left">
          <h2>Notifications</h2>
        </div>
        <div class="ml-auto d-flex">
          <router-link
            v-tooltip="'Notification settings'"
            :to="{ name: 'notifications' }"
            class="notification_setting"
          >
            <i class="icon-settings-cs" @click="closeNotificationModal"></i>
          </router-link>
          <div
            v-if="getWorkspaceNotifications.all_count > 0"
            class="head_right"
            @click.prevent="markAllNotificationsRead"
          >
            <span class="mark_text">Mark all as read</span>
          </div>
        </div>
      </div>

      <div class="notification_tab">
        <div
          class="tab_btn"
          :class="{ active: isTabActive('all') }"
          @click="changeNotificationTab('all')"
        >
          <div class="btn_inner">
            <i class="icon-all-cs"></i>
            <span class="text">All</span>
            <template v-if="getWorkspaceNotifications.all_count > 0">
              <span class="count">{{
                getWorkspaceNotifications.all_count
              }}</span>
            </template>
          </div>
        </div>

        <div
          class="tab_btn"
          :class="{ active: isTabActive('system') }"
          @click="changeNotificationTab('system')"
        >
          <div class="btn_inner">
            <i class="icon-publishing-cs"></i>
            <span class="text">Publishing</span>
            <template v-if="getWorkspaceNotifications.system_count > 0">
              <span class="count">{{
                getWorkspaceNotifications.system_count
              }}</span>
            </template>
          </div>
        </div>

        <div
          class="tab_btn"
          :class="{ active: isTabActive('team') }"
          @click="changeNotificationTab('team')"
        >
          <div class="btn_inner">
            <i class="icon-team-member-cs"></i>
            <span class="text">Team</span>
            <template v-if="getWorkspaceNotifications.team_count > 0">
              <span class="count">{{
                getWorkspaceNotifications.team_count
              }}</span>
            </template>
          </div>
        </div>

        <div
          v-if="hasPermission('can_access_top_header')"
          class="tab_btn"
          :class="{ active: isTabActive('inbox') }"
          @click="changeNotificationTab('inbox')"
        >
          <div class="btn_inner">
            <i class="icon-inbox-cs"></i>
            <span class="text">Inbox</span>
            <template v-if="getWorkspaceNotifications.inbox_count > 0">
              <span class="count">{{
                getWorkspaceNotifications.inbox_count
              }}</span>
            </template>
          </div>
        </div>
      </div>

      <div class="content_scroll">
        <div v-if="!hasNotificationsItems" class="notification_item">
          <div class="has-no-notification">
            <div class="align-items-center justify-content-center">
              <img
                style="width: 10.625rem"
                src="@assets/img/top_navigation/no-notifications.png"
                alt="No Notifications"
              />
            </div>
            <div class="text_block">
              <p class="sub_text"
                ><strong>You do not have any notifications yet.</strong></p
              >
            </div>
          </div>
        </div>

        <template v-for="(date, k) in getNotificationsDates" :key="k">
          <div id="scrolToTop" class="heading">{{
            formatNotificationDate(date)
          }}</div>
          <template v-for="(item, key) in getNotificationsItems" :key="`notification_item_${active_tab}_${key}`">
            <div
              v-if="checkNotificationDateInclusion(date, item)"
              class="notification_item"
              :class="{ 'bg-gray-300': item.is_read }"
              @click="onClick(item)"
            >
              <div class="profile_picture d-flex align-items-start">
                <div
                  class="picture_block d-flex align-items-center justify-content-center"
                  :class="getNotificationBlockClass(item)"
                >
                  <i
                    :class="getNotificationIconClass(item)"
                    :style="[
                      item.title === 'Partially Failed'
                        ? { transform: 'rotate(270deg)' }
                        : '',
                    ]"
                  ></i>
                  <span v-if="!item.is_read" class="status"></span>
                </div>
                <div class="text_block notification-text-block">
                  <div class="text d-flex align-items-start">
                    <p class="text_left notification-title">
                      <span class="title"
                        >{{
                          item.type === 'inbox' ? item.toast_text : item.title
                        }}
                      </span>
                    </p>
                    <p
                      v-if="item.created_at"
                      class="ml-auto text_right notification-date"
                      >{{ getUtcTimeAgoFromNow(item.created_at) }}</p
                    >
                    <p
                      v-else-if="item.created_time"
                      class="ml-auto text_right notification-date"
                      >{{ getUtcTimeAgoFromNow(item.created_time) }}</p
                    >
                  </div>
                  <p
                    class="sub_text pointer-events-none notification-text"
                    v-html="simplifiedNotificationDescription(item.description)"
                    ></p
                  >
                </div>
              </div>
            </div>
          </template>
        </template>

        <InfiniteLoading
          v-if="showInfiniteLoading"
          ref="infiniteLoading"
          @infinite="fetchNotifications"
        >
          <span slot="spinner">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.has-no-notification {
  text-align: center;
  img {
    width: 10.625rem;
    padding: 0.625rem;
  }
}
.infinite-loading-container {
  padding: 0.625rem;
}
</style>

<style lang="less">
.bg_automation_queued {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.notification_bg_inbox {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_reject_approval {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_pending_approva {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_approve_approval {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_post_rejected {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_removed_from_workspace {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_role_changed {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_new_comment {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_posts_rejected {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_account_expired {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_new_post_created {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_new_task_assigned {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_workspace_removed {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_posts_approved {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_post_approved {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_post_approved {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_added_to_workspace {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_mention_in_comment {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_opml_import {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_feedly_import {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_task_assigned_completed {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_workspace_joined {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_missed_plans {
  background-color: #9ce2ff;
  border-radius: 5px;
  color: white;
}
.bg_publish_cs {
  background-color: #1ecb7b;
  border-radius: 5px;
  color: white;
}
.bg_inbox_cs {
  background-color: rgba(184, 189, 209, 0.3);
  border-radius: 5px;
  color: white;
}
.notification-text-block {
  width: 100%;
}
.notification-title {
  width: 100%;
}
.notification-date {
  text-align: right;
  width: 30%;
  float: right;
}

.notification-text {
  a {
    padding: 0 !important;
    display: inline !important;
  }
}
.notification_setting {
  cursor: pointer;
  font-size: 1rem !important;
  padding: 0 !important;
  i {
    color: #3a4557 !important;
    padding: 5px !important;
    font-size: 1rem !important;
  }
}
</style>
