<template>
  <tbody>
    <tr
      v-for="(competitor, index) in tableData"
      :key="`biography_data_${index}`"
      class="hover:bg-gray-50"
      :class="{
        '!mt-2': index === 0,
        '!border-l-0 !border-t-0 !border-r-0 border !border-gray-200':
          index !== tableData.length - 1,
      }"
    >
      <td class="py-2.5 pl-4 flex items-center">
        <div class="flex items-center">
          <img
            :src="competitor.image"
            class="
              h-8
              w-8
              mr-3
              rounded-full
              overflow-hidden
              object-cover
              border
            "
            alt=""
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            "
          />
          <span
            v-tooltip="{
              content: `${
                competitor.name
                  ? 'Name: ' + competitor.name
                  : 'Slug: ' + competitor.slug
              }`,
              theme: 'light',
            }"
            class="truncate w-44 font-0-95rem"
            >{{ competitor.name ? competitor.name : competitor.slug }}</span
          >
        </div>
      </td>
      <td
        v-for="(dataItem, key) in headers"
        :key="`bio_table-data_${key}`"
        class="py-2.5"
      >
        <div v-if="headers[key] === 'biography'">
          <p
            class="text-sm whitespace-pre-line !min-w-[30rem] px-3"
            v-html="getDescription(competitor['biography'])"
          >
          </p>
        </div>
        <div
          v-if="headers[key] === 'biography_length'"
          class="flex items-center px-3 gap-5"
        >
          <span
            :class="
              getDataClasses(
                competitor,
                dataItem,
                extremeValues,
                tableData.length,
              )
            "
            >{{ `${competitor['biography_length']}/ ${platformName === 'facebook' ? '101' : '150'}` }}</span
          >
          <b-progress
            :max="150"
            animated
            class="min-w-[5rem] w-full h-[0.75rem]"
          >
            <b-progress-bar
              :variant="'studio-theme'"
              :value="competitor['biography_length']"
              :title="`Bio length: ${competitor['biography_length']}/ 150`"
            ></b-progress-bar>
          </b-progress>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script setup>
// libraries
import { computed } from 'vue'
import { parseDescriptionHtml } from '@common/lib/helper'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  platformName: {
    type: String,
    default: '',
  }
})
const { getDataClasses, getMinMaxValues } = useCompetitorHelper()

/**
 * @description checks if the min and max values are passed through props, if not, calls getMinMaxValues method to get min and max values of all columns.
 * @type {ComputedRef<unknown>}
 */
const extremeValues = computed(() => {
  return getMinMaxValues(props.tableData, props.headers)
})

/**
 * @description returns html parsed text
 */
const getDescription = (description = '') => {
  return parseDescriptionHtml(description, 5000, true, true)
}
</script>

<style scoped></style>
