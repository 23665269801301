<script setup>
import { ref, inject } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import UserMavenLogo from '@assets/img/integration/usermaven-logo.svg'
import { swalAttributes } from '@common/constants/common-attributes'
import CstPopup from '@ui/Popup/CstPopup.vue'
import useUsermavenAnalytics from '@/src/modules/analytics/views/web-analytics/usermaven/composables/useUsermavenAnalytics.js'
import linkIcon from '@/src/assets/img/icons/analytic/link-chain.svg'
import PlayIcon from '@assets/img/icons/analytic/play-icon.svg'

const root = inject('root')
const { $bvModal } = root

const { API_ACTIONS, makeApiCall, processUsermavenURL } =
  useUsermavenAnalytics()

const showPopup = ref(false)
const usermavenUrl = ref('')
const isValidURL = ref(false)

const closePopup = () => {
  showPopup.value = false
  // reset the value
  usermavenUrl.value = ''
  isValidURL.value = false
}
const togglePopup = () => {
  showPopup.value = !showPopup.value
}
/**
 * @description method to disconnect to usermaven
 * @returns {Promise<void>}
 */
const disconnect = async () => {
  const res = await $bvModal.msgBoxConfirm(
    'Are you sure you want to remove current Usermaven integration?',
    {
      title: 'Delete Response',
      ...swalAttributes(),
    }
  )
  if (res) {
    await makeApiCall(null, API_ACTIONS.DISCONNECT)
  }
}

const updateUsermavenUrl = async () => {
  const url = processUsermavenURL(usermavenUrl.value)
  if (url) {
    isValidURL.value = false
    // isConnecting.value = true
    await makeApiCall(url, API_ACTIONS.UPDATE)
    // isConnecting.value = false
    closePopup()
  } else {
    isValidURL.value = true
  }
}
</script>

<template>
  <AnalyticsFilterBarWrapper type="usermaven" class="bg-white shadow-sm">
    <template v-slot:left>
      <div class="mr-3">
        <img :src="UserMavenLogo" alt="UserMaven" />
      </div>
    </template>
    <template v-slot:right>
      <div class="flex items-center gap-3">
        <button class="btn btn-danger" @click="disconnect">
          <span class="text-white">Disconnect</span>
        </button>
        <div class="relative">
          <button class="btn btn-primary" @click="togglePopup">
            <span class="text-white">Update URL</span>
          </button>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <CstPopup
              v-if="showPopup"
              v-click-away="closePopup"
              parent-class="top-[110%] right-0 w-[27.875rem] h-[7rem] cursor-default"
              confirm-text="Update"
              :hide-do-not-show-again="true"
              @close-popup="closePopup"
              @confirm-popup="updateUsermavenUrl"
            >
              <template v-slot:popup-text>
                <div
                  class="
                    px-4
                    py-6
                    w-full
                    flex flex-1
                    items-center
                    h-[40px]
                    gap-3
                    rounded-md
                    bg-white
                  "
                >
                  <img :src="linkIcon" alt="link icon" />
                  <input
                    v-model="usermavenUrl"
                    type="text"
                    class="
                      bg-transparent
                      flex-1 flex-shrink
                      border-0
                      outline-none
                    "
                    placeholder="Enter your new usermaven URL"
                    @keydown.enter="updateUsermavenUrl"
                  />
                  <div
                    class="flex items-center gap-1 cursor-pointer"
                    @click="$bvModal.show('usermaven-connect-modal')"
                  >
                    <img :src="PlayIcon" alt="play icon" />
                    <span
                      class="
                        text-sm text-[#595959]
                        hover:text-cs-primary
                        transition-all
                        leading-none
                      "
                    >
                      How to Connect?
                    </span>
                  </div>
                </div>
                <label v-if="isValidURL" class="text-xs text-cs-red" for="">
                  Please enter a valid usermaven URL
                </label>
              </template>
            </CstPopup>
          </transition>
        </div>
      </div>
    </template>
  </AnalyticsFilterBarWrapper>
</template>
