<template>
  <div class="flex">
    <div
        class="p-4 bg-[#E3F2FF] rounded-e-2xl max-w-[75%] self-end ml-auto mb-4 relative"
        style="border-radius: 16px 0 16px 16px"
    >
      <span class="text-[#000D21] text-sm text-left whitespace-pre-wrap">{{ message.content }}</span>
      <div class="flex justify-between mt-3 items-center">
      <span class="text-xs text-[#595C5F]"
      >{{ calculateText.wordCount }} words /
        {{ calculateText.charCount }} characters</span
      >
        <div class="flex justify-end items-center">
          <img
              v-tooltip="{ content: 'Copy' }"
              src="@src/assets/img/chat_bot/copy.svg"
              width="18"
              height="18"
              alt="copy"
              class="ml-3 cursor-pointer active:opacity-50"
              @click="copyPrompt(message.content)"
          />
          <img
              v-tooltip="{ content: 'Save Prompt' }"
              src="@src/assets/img/chat_bot/save.svg"
              class="ml-3 cursor-pointer active:opacity-50"
              width="18"
              height="18"
              alt="save"
              @click="props.savePrompt(message.content,'save')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { computed, defineProps } from 'vue'
const props = defineProps({
  message: {
    type: Object,
    default: () => {},
  },
  type: {
    type: String,
    default: 'modal',
  },
  savePrompt: {
    type: Function,
    default: () => {},
  },
})
const calculateText = computed(() => {
  if (props.message?.content) {
    const words = props.message.content.split(' ')
    const wordCount = words.length
    const charCount = props.message.content.length
    return { wordCount, charCount }
  }
  return { wordCount: 0, charCount: 0 }
})

const copyPrompt = (prompt) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(prompt)
  }
}
</script>

<style scoped></style>
