<template>
  <div>
    <div
      v-for="(photo, index) in getImagesPosts"
      :key="index"
      class="assistant_box _giphy _photo"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
    >
      <div
        class="box_inner"
        :draggable="$route.name === 'composerBlog'"
        @dragstart="dragstartHandler($event, photo)"
      >
        <div
          class="image_block"
          @click="previewImage(backgroundGiphyImageURL(photo))"
        >
          <div class="play_icon">
            <i class="cs-eye"></i>
          </div>
          <div
            v-lazy:background-image="backgroundGiphyImageURL(photo)"
            class="img"
          ></div>
        </div>

        <div class="content_block">
          <div class="detail">
            <p
              >By: <span v-if="photo['username']">{{ photo['username'] }}</span>
              <span v-else>-</span>
            </p>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_giphy_block editor_dragable_box editor_image_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option no_flickr">
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>
            <br />
            <p style="text-align: center">
              <img
                style="max-width: 100%"
                class=""
                :src="backgroundGiphyImageURL(photo)"
                alt=""
              />
            </p>
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getImagesPosts'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    backgroundGiphyImageURL (photo) {
      return photo.images && photo.images.original && photo.images.original.url
        ? photo.images.original.url
        : ''
    },
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', photo)

      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'image',
          heading: '',
          media: this.backgroundGiphyImageURL(photo),
          p: photo.title || '',
          link: this.backgroundGiphyImageURL(photo)
        })
      )
    }
  }
}
</script>
