<script setup>
import { computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import useNumber from '@src/modules/common/composables/useNumber.js'
import useDateFormat from '@common/composables/useDateFormat'

const { roundNumber } = useNumber()
// state
const {
  activeUsersData,
  dataZoomOptions,
  lineChartOptions: chartOptions,
  isReportView,
  isLoadingStates,
  routes,
} = useFacebookAnalytics('Active User By Hour')
const { getUserTimePreference } = useDateFormat()
defineProps({
  type: {
    type: String,
    default: 'All',
  },
})

const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_ACTIVE_USERS]
)

const isNoData = computed(() => {
  return activeUsersData.value?.active_users_hours?.highest_value === 0
})

const bestHour = computed(() => {
  const hour = activeUsersData.value?.active_users_hours?.highest_hour
  if (hour === null || hour === undefined) return null
  return formatHour(hour, getUserTimePreference.value)
})

const highestValue = computed(() =>
  roundNumber(activeUsersData.value?.active_users_hours?.highest_value)
)

const formatHour = (hour, format) => {
  if (format === '12h') {
    if (hour === 0) {
      return '12:00 AM'
    } else if (hour === 12) {
      return '12:00 PM'
    } else if (hour < 12) {
      return `${hour}:00 AM`
    } else {
      return `${hour - 12}:00 PM`
    }
  } else {
    return `${hour.toString().padStart(2, '0')}:00`
  }
}

watch(
  () => activeUsersData.value,
  () => {
    const options = activeUsersData.value?.active_users_hours || {}
    chartOptions.value.series[0].data = options?.values || []

    chartOptions.value.xAxis.data =
      options?.buckets?.map((hour) =>
        formatHour(hour, getUserTimePreference.value)
      ) || []
    chartOptions.value.xAxis.axisLabel.formatter = null
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="active-by-hour-fb" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Active Users By Hour
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Track the number of active users on your page by hour over the
                  selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
        <p v-if="bestHour && highestValue" class="text-center mt-6">
          Best hour to post is
          <span class="font-semibold">{{ bestHour }}</span> with highest value
          <span class="font-semibold">{{ highestValue }}</span>
        </p>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
