<template>
  <div class="card_v1">
    <div class="card_inner">
      <div class="card_head">
        <h2>Popular Sentiments</h2>
        <ul id="popular_sentiments_item_highlight" class="ds_item_highlight">
        </ul>
      </div>
      <div class="card_content" :class="{ card_content_blur: isZero() }">
        <template v-if="isZero()">
          <div class="no_data_for_chart">{{ noDataMessage }}</div>
        </template>
        <div :class="{ blur_div: isZero() }">
          <highcharts
            ref="popular_sentiments_chart"
            :options="popularSentiments"
          ></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_sentiments_with_count: {}
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      popularSentiments: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f}</span> with <b>{point.category}</b> sentiment.</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popular_sentiments_item_highlight')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#745ebc', '#a88cdd'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['-ve', 'Neutral', '+ve'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
            pointPadding: 0,
            groupPadding: 0.4
          }
        },
        series: [
          {
            type: 'column',
            name: 'Engagement Per Article',
            data: [121, 143, 42]
          },
          {
            type: 'column',
            name: 'Number of Articles',
            data: [44, 43, 43],
            yAxis: 1
          }
        ]
      }
    }
  },
  computed: {},
  created () {
    if (!this.isZero()) {
      this.popularSentiments.series[0].data = [
        this.articles_sentiments_with_count.engagement.negative,
        this.articles_sentiments_with_count.engagement.neutral,
        this.articles_sentiments_with_count.engagement.positive
      ]
      this.popularSentiments.series[1].data = [
        this.articles_sentiments_with_count.docs.negative,
        this.articles_sentiments_with_count.docs.neutral,
        this.articles_sentiments_with_count.docs.positive
      ]
    }
  },
  methods: {
    isZero () {
      return (
        this.articles_sentiments_with_count.docs.negative === 0 &&
        this.articles_sentiments_with_count.docs.neutral === 0 &&
        this.articles_sentiments_with_count.docs.positive === 0
      )
    }
  }
}
</script>
