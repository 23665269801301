<script setup>
import { computed } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'
import MultipleAccountSelect from '@src/modules/analytics/views/common/MultipleAccountSelect.vue'
import LabelAndCampaignSelect from '@src/modules/analytics/views/performance-report/label-and-campaign/components/LabelAndCampaignSelect.vue'
import { EventBus } from '@common/lib/event-bus'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'

const { selectAllAccounts } = useAnalyticsUtils()

const { IGNORE_ACCOUNTS, PREFERNCES_TYPES, dateRange, selectedAccount, isAllAccountsSelected, allCampaignsAndLabels , isNoLabelOrCampaignSelected, getAnalyticsPreference, updatePreferences } =
useLabelAndCampaign()

const getAllAccounts = computed(() => {
  return selectAllAccounts(IGNORE_ACCOUNTS)
})
const disabled = computed(() => {
  return (
    (
      allCampaignsAndLabels.value?.campaigns?.length === 0
      &&
      allCampaignsAndLabels.value?.labels?.length === 0
    )
  ||
    getAllAccounts.value?.length === 0)
  || isNoLabelOrCampaignSelected.value
  || selectedAccount.value?.length === 0
})

const handleDatechange = ({ dateRange }) => {
  setDateRange(dateRange)
}
const setDateRange = (newDate) => {
  dateRange.value = newDate.split(' - ')
  updatePreferences(PREFERNCES_TYPES.date)
}
const handleAccountsChange = (data) => {
  selectedAccount.value = data.value
  isAllAccountsSelected.value = data.allSelected
  EventBus.$emit('set-is-all-accounts-selected', isAllAccountsSelected.value)
}

const defaultSelectedAccounts = computed(() => {
  const accounts = [];
  const keys = Object.keys(getAnalyticsPreference.value)
    .filter(key => key.endsWith('_accounts'))
  if(!keys.length) return undefined

  keys.forEach(key => {
    accounts.push(...getAnalyticsPreference.value[key])
  });

  return accounts
})

</script>

<template>
  <AnalyticsFilterBarWrapper
    type="campaign-and-label"
    :selected-accounts="selectedAccount"
    :disabled="disabled"
    @date-change="handleDatechange"
  >
    <template v-slot:left>
      <div class="mr-3">
        <MultipleAccountSelect
          :ignore-accounts="IGNORE_ACCOUNTS"
          :on-apply="() => {
              updatePreferences(PREFERNCES_TYPES.accounts)
          }"
          :default-selected-accounts="defaultSelectedAccounts"
          @on-accounts-change="handleAccountsChange"
        />
      </div>
      <div class="relative">
        <LabelAndCampaignSelect />
        <div
          v-if="isNoLabelOrCampaignSelected"
          class="
            select-none
            absolute
            left-1/2
            top-[7.5rem]
            -translate-x-1/2
            -translate-y-1/2
            text-white
            z-10
            bg-[#404040]
            w-[32rem]
            rounded-lg
            shadow-lg
          "
        >
            <div class="relative px-2.5 py-2">
              <h5 class="font-semibold leading-normal mb-1">Select Campaign or Label!</h5>
              <p class="text-sm leading-normal">
                Please select one or more campaigns or labels to view performance analytics!
              </p>
              <span
                  class="
                  text-[#404040]
                  text-xl
                  absolute
                  left-1/2
                  right-1/2
                  -translate-x-1/2
                  -translate-y-1/2
                  -rotate-90
                  top-2

                "
              >&#9658;</span>
            </div>
          </div>
      </div>
    </template>
  </AnalyticsFilterBarWrapper>
</template>
