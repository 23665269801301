<template>
  <div class="">
    <clip-loader
      v-if="getWorkspaceHashtags.loader"
      color="#4165ed"
      :size="'16px'"
      class="d-inline-block align-middle"
    ></clip-loader>
    <template v-else>
      <!-- publish case -->
      <div v-if="$route.name !== 'miscellaneous'" class="field_group">
        <label for="">Select from Saved Hashtags</label>

        <div class="field_group">
          <select
            id=""
            v-model="getPublishSelection.hashtag.selected"
            class="sm-for-mobile"
            name=""
            data-cy="select-hashtag"
            @change="
              changeHashtagSelectionById(getPublishSelection.hashtag.selected)
            "
          >
            <option value="" data-cy="create-newhashtag"
              >Select from saved Hashtags</option
            >
            <option
              v-for="item in getWorkspaceHashtags.items"
              :value="item._id"
              data-cy="existing-hashtag"
              >{{ item.name }}</option
            >
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>

      <!-- publish case -->
      <template
        v-if="
          $route.name !== 'miscellaneous' &&
          getPublishSelection.hashtag.selected
        "
      >
        <div
          v-show="getPublishSelection.hashtag.edit_mode === false"
          class="content_preview input_field"
        >
          <div class="p_content_inner">
            <div class="input_field mt-0">
              <div class="multi_select">
                <ul class="multi_tags">
                  <li
                    v-for="(item, index) in getNewWorkspaceHashtag.items"
                    :key="index"
                  >
                    <span class="value">{{ item }}</span>
                  </li>
                </ul>
              </div>
              <ul class="list_desc">
                <li class="mt-2"
                  >{{ getNewWorkspaceHashtag.count }} hashtag(s) from above will
                  be randomly chosen</li
                >
                <li
                  v-if="getNewWorkspaceHashtag.position === 'Append'"
                  class="mt-2"
                  >Hashtag(s) will be appended</li
                >
                <li v-else class="mt-2">Hashtag(s) will be replaced</li>
              </ul>
            </div>
          </div>
          <div class="input_field text-right">
            <button class="btn" @click="setHashtagEditMode(true)">
              <span>Edit</span>
            </button>
          </div>
        </div>

        <!--for edit mode ----------->
        <div v-show="getPublishSelection.hashtag.edit_mode">
          <!-- miscellaneous case -->
          <div class="field_group field_no_placeholder">
            <template
              v-if="
                $route.name === 'miscellaneous' ||
                !getPublishSelection.hashtag.selected
              "
            >
              <input
                id="namehashtag"
                v-model="getNewWorkspaceHashtag.name"
                class="prevent-listener"
                type="text"
                placeholder="Name"
                data-cy="newhashtag-name"
              />
              <label for="namehashtag" class="label-animated">Name</label>
            </template>
          </div>

          <!-- coming in both publish and miscellenous page -->
          <div class="field_group field_no_placeholder">
            <input
              id="hashtagsList"
              v-model="getWorkspaceHashtags.hashtag_item"
              class="prevent-listener"
              placeholder="Hashtags"
              type="text"
              data-cy="newhashtags"
              @keyup.enter="
                addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
              "
              @keyup.space="
                addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
              "
              @paste="pastehashtags"
            />

            <label for="hashtagsList" class="label-animated">Hashtags</label>
          </div>
          <div v-if="getNewWorkspaceHashtag.items.length" class="multi_select">
            <ul class="multi_tags">
              <li v-for="(item, index) in getNewWorkspaceHashtag.items">
                <span class="value">{{ item }}</span>
                <span
                  class="remove"
                  @click.prevent="REMOVE_NEW_WORKSPACE_HASHTAG_ITEM(index)"
                  >&times;</span
                >
              </li>
            </ul>
          </div>

          <div class="field_group d-flex align-items-center">
            <label style="min-width: 118px" class="mb-0" for=""
              >Randomly choose</label
            >
            <input
              v-model="hashtagCount"
              class="w-auto prevent-listener hash_count"
              type="number"
              min="1"
              :max="getNewWorkspaceHashtag.items.length"
              data-cy="hashtag-number"
            />
            <label class="ml-2 mb-0" for="">hashtag(s) for each post.</label>
          </div>

          <div class="field_group">
            <label for="">Placement</label>
          </div>

          <div class="field_group">
            <label class="radio_container" data-cy="hashtag-append">
              <input
                :id="type + 'hashtags_position_append'"
                v-model="getNewWorkspaceHashtag.position"
                :name="type + 'hashtags_position'"
                type="radio"
                value="Append"
              />
              <div :for="type + 'hashtags_position_append'">
                Placement
                <span class="check"></span>
              </div>
            </label>

            <label class="radio_container">
              <input
                :id="type + 'hashtags_position_replace'"
                v-model="getNewWorkspaceHashtag.position"
                type="radio"
                :name="type + 'hashtags_position'"
                value="Replace"
              />
              <div :for="type + 'hashtags_position_replace'">
                Replace (if keyword is matched)
                <span class="check"></span>
              </div>
            </label>
          </div>
        </div>
      </template>

      <template v-else>
        <!-- miscellaneous case -->
        <div class="field_group field_no_placeholder">
          <template
            v-if="
              $route.name === 'miscellaneous' ||
              !getPublishSelection.hashtag.selected
            "
          >
            <input
              id="namehashtag"
              v-model="getNewWorkspaceHashtag.name"
              class="prevent-listener"
              type="text"
              placeholder="Name"
              data-cy="newhashtag-name"
            />
            <label for="namehashtag" class="label-animated">Name</label>
          </template>
        </div>

        <!-- coming in both publish and miscellaneous page -->
        <div class="field_group field_no_placeholder">
          <input
            id="hashtagsList"
            v-model="getWorkspaceHashtags.hashtag_item"
            class="prevent-listener"
            placeholder="Hashtags"
            type="text"
            data-cy="newhashtags"
            @keyup.enter="
              addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
            "
            @keyup.space="
              addWorkspaceHashtagItems(getWorkspaceHashtags.hashtag_item)
            "
            @paste="pastehashtags"
          />

          <label for="hashtagsList" class="label-animated">Hashtags</label>
        </div>
        <div v-if="getNewWorkspaceHashtag.items.length" class="multi_select">
          <ul class="multi_tags">
            <li v-for="(item, index) in getNewWorkspaceHashtag.items">
              <span class="value">{{ item }}</span>
              <span
                class="remove"
                @click.prevent="REMOVE_NEW_WORKSPACE_HASHTAG_ITEM(index)"
                >&times;</span
              >
            </li>
          </ul>
        </div>

        <div class="field_group d-flex align-items-center">
          <label style="min-width: 118px" class="mb-0" for=""
            >Randomly choose</label
          >
          <input
            v-model="hashtagCount"
            class="w-auto prevent-listener hash_count"
            type="number"
            min="1"
            :max="getNewWorkspaceHashtag.items.length"
            data-cy="hashtag-number"
          />
          <label class="ml-2 mb-0" for="">hashtag(s) for each post.</label>
        </div>

        <div class="field_group">
          <label for="">Placement</label>
        </div>

        <div class="field_group">
          <label class="radio_container" data-cy="hashtag-append">
            <input
              :id="type + 'hashtags_position_append'"
              v-model="getNewWorkspaceHashtag.position"
              :name="type + 'hashtags_position'"
              type="radio"
              value="Append"
            />
            <div :for="type + 'hashtags_position_append'">
              Placement
              <span class="check"></span>
            </div>
          </label>

          <label class="radio_container">
            <input
              :id="type + 'hashtags_position_replace'"
              v-model="getNewWorkspaceHashtag.position"
              type="radio"
              :name="type + 'hashtags_position'"
              value="Replace"
            />
            <div :for="type + 'hashtags_position_replace'">
              Replace (if keyword is matched)
              <span class="check"></span>
            </div>
          </label>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {},
  props: ['type'],

  data () {
    return {
      hashtagCount: 1,
    }
  },
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getWorkspaceHashtags',
      'getNewWorkspaceHashtag'
    ]),
  },
  watch: {
    hashtagCount: {
      handler: function (val) {
        this.getNewWorkspaceHashtag.count = val
      },
    },
    'getNewWorkspaceHashtag.count': {
      handler: function (newVal) {
        if(this.getNewWorkspaceHashtag?._id){
          this.hashtagCount =  newVal
        }
      },
      immediate: true
    },
    'getNewWorkspaceHashtag.items.length': {
      handler: function (newVal) {
        if(!this.getNewWorkspaceHashtag?._id){
          this.hashtagCount = newVal
        }
      }
    },
    'getWorkspaceHashtags.hashtag_item' (value) {
      if (value && value.indexOf(',') > -1) {
        const item = value.replace(',', '')
        this.addWorkspaceHashtagItems(item)
      }
    }
  },
  methods: {
    ...mapMutations(['REMOVE_NEW_WORKSPACE_HASHTAG_ITEM']),
    ...mapActions(['setHashtagEditMode', 'addWorkspaceHashtagItems']),

    pastehashtags () {
      const selfObject = this
      setTimeout(function () {
        selfObject.addWorkspaceHashtagItems(
          selfObject.getWorkspaceHashtags.hashtag_item
        )
      }, 200)
    },

    onClickButton () {
      console.log('clicked')
    }
  },
}
</script>
