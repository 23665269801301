<template>
  <div class="setting_miscellaneous_component setting_notifications_component">
    <div v-if="getNotificationsPreferenceLoader" class="component_inner">
      <div class="ps_box p-4">
        <div class="box_content">
          <div class="box_row text-center">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="getNotificationsPreference" class="component_inner">
      <!--TODO: @Arif notification display bar-->
      <div
        v-if="getNotificationsPreferenceBrowserAlert"
        class="warning_box mb-3"
      >
        <p>
          {{ browserNotificationsDisabled }}.
          <a href="javascript:;" @click.prevent="enableNotifications"
            >Click here to enable them.</a
          >
        </p>
      </div>

      <template v-if="getNotificationsPreference">
        <div class="ps_box">
          <div class="box_row box_heading d-flex align-items-center">
            <div
              class="icon"
              data-toggle="collapse"
              href="#email_notifications"
            >
              <i class="cs-angle-down arrow_icon"></i>
            </div>
            <div class="heading">
              <h2>Email Notifications Frequency</h2>
            </div>
            <div class="col" style="width: 230px; min-width: 230px">
              <h3>How often</h3>
            </div>
            <div class="col" style="width: 230px; min-width: 230px">
              <h3>When</h3>
            </div>
          </div>
          <div id="email_notifications" class="collapse show box_content">
            <div class="box_row d-flex align-items-center">
              <div class="icon"> &nbsp; </div>
              <div class="heading">
                <p v-if="getNotificationsPreference.email_notifications">{{
                  getNotificationsPreference.email_notifications.description
                }}</p>
              </div>
              <div
                class="col field_group"
                style="width: 230px; min-width: 230px"
              >
                <div class="dropdown default_style_dropdown">
                  <div
                    class="
                      dropdown_header dropdown-sm
                      d-flex
                      align-items-center
                    "
                    data-toggle="dropdown"
                  >
                    <span
                      v-if="
                        getNotificationsPreference.email_notifications &&
                        getNotificationsPreference.email_notifications
                          .selected_how_often
                      "
                      class="text"
                      >{{
                        getNotificationsPreference.email_notifications
                          .selected_how_often
                      }}</span
                    >
                    <span v-else class="text">How often</span>
                    <span class="ml-auto">
                      <i class="icon-dropdown-cs"></i>
                    </span>
                  </div>

                  <div class="dropdown-menu dropdown-menu-right">
                    <ul
                      v-if="
                        getNotificationsPreference &&
                        getNotificationsPreference.email_notifications &&
                        getNotificationsPreference.email_notifications.how_often
                      "
                      class="inner"
                    >
                      <li
                        v-for="(how_often, index) in getNotificationsPreference
                          .email_notifications.how_often"
                        :key="index"
                        class="list_item"
                      >
                        <a class="" @click.prevent="setHowOften(how_often)">{{
                          how_often
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="col field_group"
                style="width: 230px; min-width: 230px"
              >
                <div
                  v-if="
                    getNotificationsPreference.email_notifications &&
                    getNotificationsPreference.email_notifications
                      .selected_how_often !== 'Never'
                  "
                  class="dropdown default_style_dropdown"
                >
                  <div
                    class="
                      dropdown_header dropdown-sm
                      d-flex
                      align-items-center
                    "
                    data-toggle="dropdown"
                  >
                    <span
                      v-if="
                        getNotificationsPreference.email_notifications
                          .selected_when
                      "
                      class="text"
                      >{{
                        getNotificationsPreference.email_notifications
                          .selected_when
                      }}</span
                    >
                    <span
                      v-else-if="
                        getNotificationsPreference.email_notifications
                          .selected_how_often === 'Never'
                      "
                      class="text"
                    ></span>
                    <span v-else class="text">When</span>
                    <span class="ml-auto">
                      <i class="icon-dropdown-cs"></i>
                    </span>
                  </div>

                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="inner">
                      <li
                        v-for="when in getNotificationsPreference
                          .email_notifications.when"
                        class="list_item"
                      >
                        <a class="" @click.prevent="setWhen(when)">{{
                          when
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ps_box">
          <div class="box_row box_heading d-flex align-items-center">
            <div class="icon" data-toggle="collapse" href="#workspace">
              <i class="cs-angle-down arrow_icon"></i>
            </div>
            <div class="heading">
              <h2>Workspace</h2>
            </div>
            <div class="col">
              <h3>Dashboard</h3>
            </div>
            <div class="col">
              <h3>Email</h3>
            </div>
            <div class="col">
              <h3>Browser</h3>
            </div>
          </div>

          <div id="workspace" class="collapse show box_content">
            <div
              v-for="(
                notification, index
              ) in getNotificationsPreference.workspace"
              class="box_row d-flex align-items-center"
            >
              <div class="icon"> &nbsp; </div>
              <div class="heading">
                <p>{{ notification.description }}</p>
              </div>

              <div class="col field_group">
                <div class="checkbox_container">
                  <label :for="'workspace_notification_dashboard' + index">
                    <input
                      :id="'workspace_notification_dashboard' + index"
                      v-model="notification.dashboard"
                      :checked="notification.dashboard"
                      :disabled="notification.disabled === -1"
                      type="checkbox"
                      name="option2"
                      @change.prevent="saveNotificationsPreference"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>

              <div class="col field_group">
                <div class="checkbox_container">
                  <label :for="'workspace_notification_email' + index">
                    <input
                      :id="'workspace_notification_email' + index"
                      v-model="notification.email"
                      :checked="notification.email"
                      :disabled="notification.email === -1"
                      type="checkbox"
                      name="option2"
                      @change.prevent="saveNotificationsPreference"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>

              <div class="col field_group">
                <div class="checkbox_container">
                  <label :for="'workspace_notification_browser' + index">
                    <input
                      :id="'workspace_notification_browser' + index"
                      v-model="notification.browser"
                      :disabled="notification.browser === -1"
                      :checked="notification.browser"
                      type="checkbox"
                      name="option2"
                      @change.prevent="saveNotificationsPreference"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ps_box">
          <div
            class="
              box_row box_heading
              d-flex
              align-items-center
              box_heading_sticky
            "
          >
            <div class="icon" data-toggle="collapse" href="#publisher">
              <i class="cs-angle-down arrow_icon"></i>
            </div>
            <div class="heading">
              <h2>Publisher</h2>
            </div>
            <div class="col">
              <h3>Dashboard</h3>
            </div>
            <div class="col">
              <h3>Email</h3>
            </div>
            <div class="col">
              <h3>Browser</h3>
            </div>
          </div>
          <div id="publisher" class="collapse show box_content">
            <div class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Task</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <div
                v-for="(
                  notification, index
                ) in getNotificationsPreference.tasks"
                class="box_row d-flex align-items-center"
              >
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>{{ notification.description }}</p>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'task_notification_dashboard' + index">
                      <input
                        :id="'task_notification_dashboard' + index"
                        v-model="notification.dashboard"
                        :checked="notification.dashboard"
                        :disabled="notification.dashboard === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'task_notification_email' + index">
                      <input
                        :id="'task_notification_email' + index"
                        v-model="notification.email"
                        :checked="notification.email"
                        type="checkbox"
                        :disabled="notification.email === -1"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'task_notification_browser' + index">
                      <input
                        :id="'task_notification_browser' + index"
                        v-model="notification.browser"
                        :checked="notification.browser"
                        :disabled="notification.browser === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Discussions</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <div
                v-for="(
                  notification, index
                ) in getNotificationsPreference.discussions"
                class="box_row d-flex align-items-center"
              >
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>{{ notification.description }}</p>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'comment_notification_dashboard_' + index">
                      <input
                        :id="'comment_notification_dashboard_' + index"
                        v-model="notification.dashboard"
                        :checked="notification.dashboard"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'comment_notification_email_' + index">
                      <input
                        :id="'comment_notification_email_' + index"
                        v-model="notification.email"
                        :checked="notification.email"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'comment_notification_browser_' + index">
                      <input
                        :id="'comment_notification_browser_' + index"
                        v-model="notification.browser"
                        :checked="notification.browser"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Posts</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <div
                v-for="(
                  notification, index
                ) in getNotificationsPreference.posts"
                class="box_row d-flex align-items-center"
              >
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>{{ notification.description }}</p>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'posts_notification_dashboard_' + index">
                      <input
                        :id="'posts_notification_dashboard_' + index"
                        v-model="notification.dashboard"
                        :disabled="notification.dashboard === -1"
                        :checked="notification.dashboard"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'posts_notification_email_' + index">
                      <input
                        :id="'posts_notification_email_' + index"
                        v-model="notification.email"
                        :disabled="notification.email === -1"
                        :checked="notification.email"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'posts_notification_browser_' + index">
                      <input
                        :id="'posts_notification_browser_' + index"
                        v-model="notification.browser"
                        :checked="notification.browser"
                        :disabled="notification.browser === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="getUserRole !== 'Approver'" class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Accounts</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <div
                v-for="(
                  notification, index
                ) in getNotificationsPreference.accounts"
                class="box_row d-flex align-items-center"
              >
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>{{ notification.description }}</p>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'account_notification_dashboard' + index">
                      <input
                        :id="'account_notification_dashboard' + index"
                        v-model="notification.dashboard"
                        :checked="notification.dashboard"
                        :disabled="notification.dashboard === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'account_notification_email' + index">
                      <input
                        :id="'account_notification_email' + index"
                        v-model="notification.email"
                        :checked="notification.email"
                        :disabled="notification.email === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'account_notification_browser' + index">
                      <input
                        :id="'account_notification_browser' + index"
                        v-model="notification.browser"
                        :checked="notification.browser"
                        :disabled="notification.browser === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="getUserRole !== 'Approver'" class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Automation</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <div
                v-for="(
                  notification, index
                ) in getNotificationsPreference.automations"
                class="box_row d-flex align-items-center"
              >
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>{{ notification.description }}</p>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'automation_notification_dashboard' + index">
                      <input
                        :id="'automation_notification_dashboard' + index"
                        v-model="notification.dashboard"
                        type="checkbox"
                        :disabled="notification.dashboard === -1"
                        :checked="notification.dashboard"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'automation_notification_email' + index">
                      <input
                        :id="'automation_notification_email' + index"
                        v-model="notification.email"
                        :checked="notification.email"
                        :disabled="notification.email === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
                <div class="col field_group">
                  <div class="checkbox_container">
                    <label :for="'automation_notification_browser' + index">
                      <input
                        :id="'automation_notification_browser' + index"
                        v-model="notification.browser"
                        :checked="notification.browser"
                        :disabled="notification.browser === -1"
                        type="checkbox"
                        name="option2"
                        @change.prevent="saveNotificationsPreference"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            getActiveWorkspaceAddonsLifetime.social_inbox_beta ||
            getActiveWorkspaceAddonsLifetime.social_inbox ||
            (isAnalyticsPlanAllowed && getUserRole !== 'Approver')
          "
          class="ps_box"
        >
          <div class="box_row box_heading d-flex align-items-center">
            <div class="icon" data-toggle="collapse" href="#inbox">
              <i class="cs-angle-down arrow_icon"></i>
            </div>
            <div class="heading">
              <h2>Inbox</h2>
            </div>
            <div class="col">
              <h3>Dashboard</h3>
            </div>
            <div class="col">
              <h3>Email</h3>
            </div>
            <div class="col">
              <h3>Browser</h3>
            </div>
          </div>

          <div id="inbox" class="collapse show box_content">
            <div class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Conversation/Post</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <template
                v-if="
                  getNotificationsPreference &&
                  getNotificationsPreference.inbox &&
                  getNotificationsPreference.inbox.conversation_post
                "
              >
                <div
                  v-for="(notification, index) in getNotificationsPreference
                    .inbox.conversation_post"
                  class="box_row d-flex align-items-center"
                >
                  <div class="icon"> &nbsp; </div>
                  <div class="heading">
                    <p>{{ notification.description }}</p>
                  </div>
                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'task_notification_dashboard' + index">
                        <input
                          :id="'task_notification_dashboard' + index"
                          v-model="notification.dashboard"
                          :checked="notification.dashboard"
                          :disabled="notification.dashboard === -1"
                          type="checkbox"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'task_notification_email' + index">
                        <input
                          :id="'task_notification_email' + index"
                          v-model="notification.email"
                          :checked="notification.email"
                          type="checkbox"
                          :disabled="notification.email === -1"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'task_notification_browser' + index">
                        <input
                          :id="'task_notification_browser' + index"
                          v-model="notification.browser"
                          :checked="notification.browser"
                          :disabled="notification.browser === -1"
                          type="checkbox"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="section">
              <div class="section_heading box_row d-flex align-items-center">
                <div class="icon"> &nbsp; </div>
                <div class="heading">
                  <p>Team Member</p>
                </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
                <div class="col"> &nbsp; </div>
              </div>
              <template
                v-if="
                  getNotificationsPreference &&
                  getNotificationsPreference.inbox &&
                  getNotificationsPreference.inbox.team_member
                "
              >
                <div
                  v-for="(notification, index) in getNotificationsPreference
                    .inbox.team_member"
                  :key="`${index}_inbox`"
                  class="box_row d-flex align-items-center"
                >
                  <div class="icon"> &nbsp; </div>
                  <div class="heading">
                    <p>{{ notification.description }}</p>
                  </div>
                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'comment_notification_dashboard_' + index">
                        <input
                          :id="'comment_notification_dashboard_' + index"
                          v-model="notification.dashboard"
                          :checked="notification.dashboard"
                          type="checkbox"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'comment_notification_email_' + index">
                        <input
                          :id="'comment_notification_email_' + index"
                          v-model="notification.email"
                          :checked="notification.email"
                          type="checkbox"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col field_group">
                    <div class="checkbox_container">
                      <label :for="'comment_notification_browser_' + index">
                        <input
                          :id="'comment_notification_browser_' + index"
                          v-model="notification.browser"
                          :checked="notification.browser"
                          type="checkbox"
                          name="option2"
                          @change.prevent="saveNotificationsPreference"
                        />
                        <span class="check"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BROWSER_NOTIFICATION_DISABLED } from '@common/constants/messages'

export default {
  data() {
    return {
      browserNotificationsDisabled: BROWSER_NOTIFICATION_DISABLED,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspaceAddonsLifetime',
      'getNotificationsPreference',
      'getNotificationsPreferenceLoader',
      'getNotificationsPreferenceBrowserAlert',
    ]),
  },

  created() {
    this.fetchNotificationsPreference()
  },
  mounted() {},
  methods: {
    setHowOften(howOften) {
      this.getNotificationsPreference.email_notifications.selected_how_often =
        howOften
      this.saveNotificationsPreference()
    },
    setWhen(when) {
      this.getNotificationsPreference.email_notifications.selected_when = when
      this.saveNotificationsPreference()
    },

    ...mapActions([
      'fetchNotificationsPreference',
      'saveNotificationsPreference',
      'enableNotifications',
      'isAnalyticsPlanAllowed',
    ]),
  },
}
</script>
