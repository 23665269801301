<template>
  <div
    class="
      z-[1050]
      flex flex-col flex-1
      w-full
      min-w-[30rem]
      max-w-[30rem]
      h-full
      bg-[#FFFFFF]
      p-0
      rounded-lg
      relative
      right-[-3px]
    "
    :class="{ 'right-[0] !rounded-none': props.type === 'blog' }"
    style="
      border: 1px solid rgba(210, 213, 223, 0.5);
      border-right: 0;
      box-shadow: 0 2px 50px 0 rgba(0, 39, 114, 0.15);
    "
  >
    <!-- header -->
    <div
      v-tooltip="{ content: 'Collapse' }"
      class="
        absolute
        -left-3
        top-[1rem]
        rounded-full
        w-[1.875rem]
        h-[1.875rem]
        flex
        justify-center
        items-center
        cursor-pointer
        bg-white
        z-[20000]
        border
      "
      @click="props.toggleChatDialogs('openSavedPrompts')"
    >
      <i class="cs-angle-right w-[0.688rem] text-[#979CA0]"></i>
    </div>

    <div
      class="
        w-full
        min-h-[60px]
        h-[60px]
        bg-gray-300
        flex
        items-center
        justify-between
        px-3
        bg-opacity-30
      "
    >
      <!-- Left Section: Prompts Label and Search Icon -->
      <div class="flex items-center">
        <!-- Prompts Label -->
        <span class="ml-3 text-lg font-semibold text-black-900">Prompts</span>

        <!-- Search Icon/Input -->
        <div class="relative flex items-center ml-3">
          <!-- <img
            v-if="!state.showSearchInput"
            src="@src/assets/img/chat_bot/search-icon.svg"
            width="20"
            height="20"
            class="cursor-pointer"
            alt="Search"
            @click="toggleSearchInput"
          /> -->

          <div
            class="flex items-center bg-gray-300/70 rounded-md px-3 py-1"

          >
            <img
              src="@src/assets/img/chat_bot/search-icon.svg"
              class="h-4 w-4"
              alt="search"
               @click="toggleSearchInput"
            />
            <input
            v-model="state.searchTerm"
              type="text"
              class="
                chatInput
                bg-transparent
                w-20
                ml-2
                text-sm
                border-0
                px-2
                py-1
                rounded
              "
              :class="{ '!w-full': state.showSearchInput }"
              placeholder="Search"
              @focus="state.showSearchInput = true"
            />
            <img
              v-show="state.showSearchInput"
              src="@src/assets/img/common/circle-xmark.svg"
              alt="xmark"
              class="h-4 w-4 cursor-pointer"
              @click.stop="clearSearch"
            />
          </div>

        </div>
      </div>

      <!-- Right Section: Favourite Prompts Toggle -->
      <div class="flex items-center">
        <span class="text-sm font-medium text-black-900">Favorite</span>

        <div
          class="
            flex
            items-center
            rounded-full
            ml-2
            w-[30px]
            h-[20px]
            cursor-pointer
            duration-300
            ease-in-out
          "
          :class="state.showFavoritePrompts ? 'bg-[#549AEE]' : 'bg-gray-300'"
          @click="toggleAllFavourite"
        >
          <div
            :class="[
              'ml-1 rounded-full shadow-md border-white border-2 w-[12px] h-[12px] bg-white transition-transform duration-300 ease-in-out',
              state.showFavoritePrompts ? 'translate-x-3' : 'translate-x-0',
            ]"
          ></div>
        </div>
      </div>
    </div>

    <div class="overflow-y-scroll h-full scrollable-div">
      <!-- custom prompts -->
      <div class="w-full bg-white sticky top-0 z-99">
        <div
          class="pt-4 pb-3 px-4 flex items-center justify-between"
          :class="state.customPrompts.length > 0 && 'cursor-pointer'"
          style="border-bottom: 0.031rem solid rgba(221, 227, 231, 0.5)"
          @click="toggleCustomPrompt"
          v-if="!state.showFavoritePrompts"
        >
          <div class="flex items-center">
            <span
              class="text-sm font-medium text-black-900 select-none"
              :class="{ '!text-cs-primary': state.toggle }"
            >
              Custom
              <span class="mx-1">({{ state.customPrompts.length }})</span>
            </span>
            <div
              class="
                mx-1.5
                w-6
                h-6
                bg-cs-primary
                text-white
                font-normal
                rounded
                flex
                items-center
                justify-center
                z-99
                cursor-pointer
              "
              @click.stop="toggleCustomPromptInput"
            >
              <i v-if="!state.showCustomPromptInput" class="far fa-plus"></i>
              <i v-else class="far fa-minus"></i>
            </div>
          </div>
          <i
            v-if="state.customPrompts.length > 0 || state.showCustomPromptInput"
            class="fa fa-chevron-down cursor-pointer text-xs"
            :class="{
              'rotate-180': state.customToggle || state.showCustomPromptInput,
            }"
          ></i>
        </div>
      </div>

      <div
        class="show-favourite pl-4 pr-4 pb-0.5 pt-2"
        v-if="state.showFavoritePrompts"
      >
        <template v-for="item in getAllFavourites" :key="item._id">
          <div
            class="
              cursor-pointer
              hover:text-cs-primary
              transition-all
              bg-[#F4F6FA]
              rounded-lg
              p-3
              mb-3
              flex
              justify-between
            "
          >
            <div
              class="block w-full"
              @click="props.handleCustomPrompts(item.prompt, 'append')"
            >
              <span class="block text-[#3C4549] text-sm font-medium">{{
                item.title
              }}</span>
              <span class="block mt-2 text-[#757A8A] text-sm font-normal">{{
                item.prompt
              }}</span>
            </div>

            <span
              class="flex justify-center mt-1"
              v-tooltip="'Remove from favorite'"
              @click.stop="
                toggleFavorite(item, item.is_default ? 'default' : 'custom')
                "
            >
              <i
                style="font-size: 14px"
                class="fa-star !shadow-none rounded-md"
                :class="item.is_favourite
                  ? 'fas text-[#0068e5]'
                  : 'far text-[#8B8B8B]'
                  "
              ></i>
            </span>
          </div>
        </template>
        <template v-if="!getAllFavourites?.length">
          <div class="flex justify-center items-center w-full h-[72vh]">
            <span class="text-[#000000] text-center text-lg"
              >No favorite prompt found!</span
            >
          </div>
        </template>
      </div>
      <div v-else>
        <div
          v-show="(state.customToggle && state.customPrompts.length) ||
            state.showCustomPromptInput
            "
          class="pl-4 pr-4 pb-0.5 pt-2"
        >
          <!-- custom prompt inputs -->
          <div
            v-if="state.showCustomPromptInput"
            class="w-full mb-4 rounded-xl"
            style="
              box-shadow: 0 1px 15px rgba(204, 209, 224, 0.5);
              border: 1px solid #eceef5;
            "
          >
            <input
              ref="titleInput"
              type="text"
              placeholder="Write your title here"
              class="
                chatInput
                !border-0
                rounded-xl
                w-100
                p-3
                text-sm text-[#3C4549]
                leading-5
                font-medium
              "
              @keyup="titleChange"
            />
            <textarea
              ref="promptTextInput"
              placeholder="Write prompt here"
              class="
                chatInput
                min-h-[19px]
                !border-0
                w-100
                px-3
                text-sm
                leading-5
                text-[#757A8A]
                placeholder:text-[#BABDC5]
              "
              rows="4"
              @keyup="promptChange"
            />
            <div
              class="w-100 flex justify-end items-center p-3"
              style="border: 1px solid #eceef5; border-radius: 1px"
            >
              <button
                class="
                  text-[#2961D2] text-sm
                  font-normal
                  rounded
                  px-4
                  py-2
                  cursor-pointer
                  bg-white
                  hover:!bg-[#E3F2FF]
                "
                style="border: 1px solid #0068e5"
                @click="resetInputValues"
              >
                Cancel
              </button>
              <button
                class="
                  bg-cs-primary
                  text-white text-sm
                  font-normal
                  rounded
                  px-4
                  py-2
                  cursor-pointer
                  flex
                  items-center
                  gap-x-2
                  hover:bg-opacity-75
                  ml-2
                "
                style="border: 1px solid #0068e5"
                :class="{
                  ' !bg-blue-400 ':
                    state.promptLoader ||
                    (state.isEdit && !state.isEditableStateMutated),
                }"
                :disabled="state.promptLoader ||
                  (state.isEdit && !state.isEditableStateMutated)
                  "
                @click="addCustomPrompt"
              >
                {{ state.isEdit ? 'Update' : 'Save' }}
                <img
                  v-if="state.promptLoader"
                  class="w-5 mx-0.5"
                  src="@src/assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </div>
          <!-- custom prompt list -->
          <template
            v-if="state.customPrompts.length && state.showCustomPrompts"
          >
            <template>
              <template v-for="item in state.customPrompts" :key="item._id">
                <div
                  class="
                    cursor-pointer
                    hover:text-cs-primary
                    transition-all
                    bg-[#F4F6FA]
                    rounded-lg
                    p-3
                    mb-3
                    flex
                    justify-between
                  "
                >
                  <div
                    class="block w-full"
                    @click="props.handleCustomPrompts(item.prompt, 'append')"
                  >
                    <span class="block text-[#3C4549] text-sm font-medium">{{
                      item.title
                    }}</span>
                    <span
                      class="block mt-2 text-[#757A8A] text-sm font-normal"
                      >{{ item.prompt }}</span
                    >
                  </div>

                  <span
                    class="flex justify-center mt-1"
                    @click.stop="toggleFavorite(item)"
                    v-tooltip="item.is_favourite ? 'Remove from favorite' : 'Mark as favorite'"
                  >
                    <i
                      style="font-size: 14px"
                      class="fa-star !shadow-none rounded-md"
                      :class="item.is_favourite
                        ? 'fas text-[#0068e5]'
                        : 'far text-[#8B8B8B]'
                        "
                    ></i>
                  </span>

                  <div class="group relative">
                    <img
                      src="@src/assets/img/chat_bot/more-vertical-black.svg"
                      width="18"
                      height="18"
                      alt="more-vertical.svg"
                      class="ml-3 z-10 cursor-pointer active:opacity-50"
                    />
                    <!--  DROPDOWN-->
                    <div
                      class="
                        w-[160px]
                        flex-col
                        p-4
                        absolute
                        -right-3
                        rounded
                        top-7
                        z-[1049]
                        bg-white
                        hidden
                        group-hover:!flex
                      "
                      style="
                        filter: drop-shadow(
                          0px 3px 20px rgba(201, 207, 224, 0.4)
                        );
                        border-radius: 10px;
                      "
                    >
                      <img
                        src="@src/assets/img/chat_bot/top-edge.svg"
                        alt="arrow"
                        class="absolute -top-2 -right-2"
                      />
                      <div
                        class="cursor-pointer hover:font-bold"
                        @click="updateCustomPrompt(item)"
                      >
                        <img
                          src="@src/assets/img/chat_bot/handwitting.svg"
                          alt="external-link"
                          width="18"
                          height="18"
                        />
                        <span class="text-[#3C4549] text-sm ml-3">Edit</span>
                      </div>
                      <div
                        style="border-bottom: 1px solid #f2f3f8"
                        class="my-2"
                      ></div>
                      <div
                        class="cursor-pointer hover:font-bold"
                        @click="deleteCustomPrompt(item._id)"
                      >
                        <img
                          src="@src/assets/img/chat_bot/trash-2.svg"
                          alt="external-link"
                          width="18"
                          height="18"
                        />
                        <span class="text-[#D65151] text-sm ml-3">Delete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <!-- default prompts -->
        <template v-if="state.defaultPrompts.length > 0">
          <template>
            <PromptTemplate
              v-for="(prompt, index) in state.defaultPrompts"
              :search-term="state.searchTerm"
              :key="index"
              :category="prompt"
              :handle-custom-prompts="props.handleCustomPrompts"
              :toggle-favorite="toggleFavorite"
            ></PromptTemplate>
          </template>
        </template>
      </div>

      <template v-if="showEmptyMessage">
        <div class="flex items-center justify_center h-[70%]">
          <span class="text-center text-[#000000] text-lg">No prompt found!</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from 'vue'
import { AiChatPrompts } from '@src/modules/AI-tools/Prompts.js'
import PromptTemplate from '@src/modules/AI-tools/PromptTemplate.vue'
import proxy from '@common/lib/http-common'
import {
  deleteCustomPromptUrl,
  fetchCustomPromptsUrl,
  saveCustomPromptUrl,
} from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import { getFavoritePrompts } from '@common/lib/helper'

const store = useStore()

const props = defineProps({
  type: {
    type: String,
    default: 'modal',
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
})

const state = reactive({
  customPrompts: [],
  defaultPrompts: [],
  allCustomPrompts: [],
  allDefaultPrompts: [],
  promptLoader: false,
  customToggle: true,
  isEdit: false,
  editableState: {},
  isEditableStateMutated: false,
  showCustomPromptInput: false,
  showCustomPrompts: true,
  activePrompt: '',
  searchTerm: '',
  showFavoritePrompts: false,
  favouritePromptLoader: false,
  isSearching: false,
  showSearchInput: false, // Controls whether the search input is shown
})

const titleInput = ref(null)
const promptTextInput = ref(null)

const toggleSearchInput = () => {
  console.log('toggleSearchInput')
  state.showSearchInput = !state.showSearchInput
}

const toggleCustomPromptInput = () => {
  state.showCustomPromptInput = !state.showCustomPromptInput
  state.isEdit = false

  if (state.showCustomPromptInput) {
    state.customToggle = true
  }
}

const toggleCustomPrompt = () => {
  state.customToggle = !state.customToggle

  if (!state.customToggle) {
    state.showCustomPromptInput = false
  }
}

const toggleAllFavourite = () => {
  state.showFavoritePrompts = !state.showFavoritePrompts
}

const showEmptyMessage = computed(() => {
  return (
    state.isSearching &&
    !state.customPrompts.length &&
    !state.defaultPrompts.length &&
    !state.showFavoritePrompts
  )
})

const getAllFavourites = computed(() => {
  return getFavoritePrompts(state.defaultPrompts, state.customPrompts)
})

onBeforeMount(() => {
  fetchCustomPrompts()
})

onMounted(() => {
  state.customToggle = true
  EventBus.$on('save-user-prompt', (prompt) => {
    props.toggleChatDialogs('closeHistory')
    state.customToggle = true
    state.showCustomPromptInput = true
    setTimeout(() => {
      if (promptTextInput.value) {
        promptTextInput.value.value = prompt
        promptTextInput.value.focus()
      }
    }, 1)
  })
})

onUnmounted(() => {
  EventBus.$off('save-user-prompt')
})

watch(
  () => store.getters.getWorkspaces.activeWorkspace._id,
  () => {
    fetchCustomPrompts()
  }
)

watch(
  () => state.searchTerm,
  (newVal) => {
    performLocalSearch(newVal)
  }
)

const resetInputValues = (inputBox = true) => {
  titleInput.value.value = ''
  promptTextInput.value.value = ''
  state.activePrompt = ''
  state.editableState = {}
  state.isEditableStateMutated = true
  state.isEdit = false
  if (inputBox) {
    state.showCustomPromptInput = false
  }
}

const addCustomPrompt = () => {
  if (titleInput.value.value && promptTextInput.value.value) {
    saveCustomPrompt({
      title: titleInput.value.value,
      prompt: promptTextInput.value.value,
    })
    return
  }
  store.dispatch('toastNotification', {
    message: 'Prompt and title are required!',
    type: 'info',
  })
}

const titleChange = (e) => {
  if (state.editableState.title) {
    if (
      state.editableState.title === e.target.value &&
      state.editableState.prompt === promptTextInput.value.value
    ) {
      state.isEditableStateMutated = false
    } else {
      state.isEditableStateMutated = true
    }
  }
}

const promptChange = (e) => {
  if (state.editableState.prompt) {
    if (
      state.editableState.prompt === e.target.value &&
      state.editableState.title === titleInput.value.value
    ) {
      state.isEditableStateMutated = false
    } else {
      state.isEditableStateMutated = true
    }
  }
}

const fetchCustomPrompts = () => {
  if (store?.getters?.getWorkspaces?.activeWorkspace?._id) {
    proxy
      .post(fetchCustomPromptsUrl, {
        workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
      })
      .then((response) => {
        if (response.data.status) {
          state.allCustomPrompts = response.data?.data?.custom_prompts
          state.allDefaultPrompts = response.data?.data?.default_prompts
          state.customPrompts = [...state.allCustomPrompts]
          state.defaultPrompts = [...state.allDefaultPrompts]

          EventBus.$emit(`${props.type}-update-prompts`, response.data?.data)
        }
      })
      .catch((error) => {
        console.debug(error)
        store.dispatch('toastNotification', {
          message: error.response?.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
      })
  }
}

const performLocalSearch = (searchTerm) => {
  const { allCustomPrompts, allDefaultPrompts } = state

  if (!searchTerm) {
    state.customPrompts = allCustomPrompts
    state.defaultPrompts = allDefaultPrompts
    state.isSearching = false
    return
  }

  state.isSearching = true
  const lowerSearchTerm = searchTerm.toLowerCase()

  const filterPrompts = (prompts) =>
    prompts.filter(
      ({ title, prompt, section }) =>
        (title && title.toLowerCase().includes(lowerSearchTerm)) ||
        (prompt && prompt.toLowerCase().includes(lowerSearchTerm)) ||
        (section && section.toLowerCase().includes(lowerSearchTerm))
    )

  state.customPrompts = filterPrompts(allCustomPrompts)

  state.defaultPrompts = allDefaultPrompts
    .map((category) => ({
      ...category,
      prompts: filterPrompts(category.prompts),
    }))
    .filter((category) => category.prompts.length > 0)
}

const toggleFavorite = (prompt, type = 'custom') => {
  if (state.promptLoader || state.favouritePromptLoader) return
  const updatedPrompt = { ...prompt, is_favourite: !prompt.is_favourite }
  state.activePrompt = prompt._id
  saveCustomPrompt(updatedPrompt, 'favourite', type)
}

const saveCustomPrompt = async (value, action = 'save', type = 'custom') => {
  if (state.promptLoader || state.favouritePromptLoader) return

  const payload = {
    workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
    title: value.title,
    prompt: value.prompt,
    type: type,
  }

  if (action === 'favourite') {
    payload.is_favourite = value.is_favourite
    state.favouritePromptLoader = true
  } else {
    state.promptLoader = true
  }

  if (state.activePrompt) {
    payload._id = state.activePrompt
  }

  try {
    const response = await proxy.post(saveCustomPromptUrl, payload)
    if (response.data.status) {
      if (state.activePrompt) {
        if (type === 'default') {
          state.allDefaultPrompts = state.allDefaultPrompts.map((item) => {
            item.prompts = item.prompts.map((prompt) => {
              if (prompt._id === state.activePrompt) {
                return {
                  ...prompt,
                  is_favourite: response.data.data?.is_favourite,
                  color_code: response.data.data?.color_code,
                  updated_at: response.data.data?.updated_at,
                }
              }
              return prompt
            })
            return item
          })
        } else {
          state.allCustomPrompts = state.allCustomPrompts.map((item) => {
            if (item._id === state.activePrompt) {
              return response.data.data
            }
            return item
          })
        }
      } else {
        state.allCustomPrompts.push(response.data.data)
      }

      performLocalSearch(state.searchTerm)

      EventBus.$emit(`${props.type}-update-prompts`, {
        custom_prompts: state.allCustomPrompts,
        default_prompts: state.allDefaultPrompts,
      })

      if (action === 'favourite') {
        state.activePrompt = ''
      } else {
        resetInputValues(false)

        const message = `Prompt ${
          state.activePrompt ? 'updated' : 'saved'
        } successfully!`
        store.dispatch('toastNotification', {
          message,
          type: 'success',
        })
      }
    } else {
      throw new Error(response.data?.message || UNKNOWN_ERROR)
    }
  } catch (error) {
    console.debug(error)
    store.dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  } finally {
    state.promptLoader = false
    state.favouritePromptLoader = false
  }
}

const updateCustomPrompt = (prompt) => {
  state.activePrompt = prompt._id
  state.customToggle = true
  state.showCustomPromptInput = true
  state.isEdit = true
  state.editableState.title = prompt.title
  state.editableState.prompt = prompt.prompt
  state.isEditableStateMutated = false
  setTimeout(() => {
    if (titleInput.value) {
      titleInput.value.value = prompt.title
      promptTextInput.value.value = prompt.prompt
    }
  }, 1)
}

const deleteCustomPrompt = async (id) => {
  try {
    const response = await proxy.post(deleteCustomPromptUrl, {
      workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
      _id: id,
    })
    if (response.data.status) {
      state.allCustomPrompts = state.allCustomPrompts.filter(
        (item) => item._id !== id
      )
      performLocalSearch(state.searchTerm)
      EventBus.$emit(`${props.type}-update-prompts`, {
        custom_prompts: state.allCustomPrompts,
        default_prompts: state.allDefaultPrompts,
      })
      store.dispatch('toastNotification', {
        message: 'Prompt deleted successfully!',
        type: 'success',
      })
    } else {
      throw new Error(response.data?.message || UNKNOWN_ERROR)
    }
  } catch (error) {
    console.debug(error)
    store.dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
}

const clearSearch = () => {
  state.searchTerm = ''
  state.showSearchInput = false
  performLocalSearch('')
}
</script>

<style lang="css" scoped>
.scrollable-div {
  position: relative;
  width: 100%;
  overflow: scroll;
}

.scrollable-div::-webkit-scrollbar {
  width: 10px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-div::-webkit-scrollbar-track:hover {
  border-left: 1px solid #f4f6fa;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: white;
  border: 0;
}

.scrollable-div:hover::-webkit-scrollbar-thumb {
  background-color: #dbdcdd;
  border-radius: 0;
}
</style>
