<template>
  <b-modal
      id="facebook_background_modal"
      centered
      hide-header
      hide-footer
      body-class="!px-0"
      dialog-class="!w-[484px]"
  >
    <div class="relative">
      <!--      modal close     -->
      <div class="w-full flex justify-between mt-2 pt-[5px] pr-2 pl-4">
        <p class="font-medium text-lg text-[#202324] select-none"
        >Choose Background</p>
        <div class="flex items-center gap-x-4 cursor-pointer">
          <div class="border flex items-center gap-x-1 rounded px-2 py-1" @click.prevent="$emit('set-facebook-background-id', '')">
            <i class="fa fa-ban text-red-700 flex justify-center items-center" aria-hidden="true"></i>
            <p class="text-xs text-gray-800">Remove Background</p>
          </div>
          <img
              src="@src/assets/img/integration/cross_dark_gray.svg"
              class="w-6 h-6 cursor-pointer"
              alt=""
              @click="$bvModal.hide('facebook_background_modal')"
          />
        </div>
      </div>
      <hr>
      <!--      main modal body     -->
      <div class="px-2 mb-12 w-full flex flex-col justify-center items-center">
        <!--        modal inputs      -->
        <div class="w-full flex flex-col justify-center items-center h-[40rem] overflow-y-scroll mt-[2rem] pt-[42.4rem]">
          <div v-for="(value, key) in FACEBOOK_BACKGROUNDS" :key="key" class="">
            <div>
              <h2 class="text-[#202324] font-medium text-lg my-5 capitalize">{{ key }}</h2>
              <div class="grid grid-cols-5 gap-4">
                <template v-for="(item, index) in FACEBOOK_BACKGROUNDS[key]" :key="index">
                  <div class="cursor-pointer" v-if="item.preset_id" @click.prevent="$emit('set-facebook-background-id', item.preset_id)">
                    <img v-if="item.type === 'image'" :class="{ 'ring-2 ring-[#0086ff]': item.preset_id === facebookBackgroundId }" class="w-16 h-16 border border-transparent rounded-lg" :src="require(`@assets/img/composer/facebook_template/${item.thumbnail}`)" :alt="item.background_description" />
                    <div
                        v-if="(item.type === 'solid' || item.type === 'gradient')"
                        :class="['w-16 h-16 border border-transparent rounded-lg', { 'ring-2 ring-[#0086ff]': item.preset_id === facebookBackgroundId }]"
                        :style="{
                      backgroundColor: item.type === 'solid' ? item.background_color : '',
                      backgroundImage: item.type === 'gradient' ? item.background_color : '',
                     }"
                    ></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'

// declarations
const { FACEBOOK_BACKGROUNDS } = useComposerHelper()

defineProps({
  facebookBackgroundId: {
    type: String,
    default: ''
  }
})

defineEmits(['set-facebook-background-id'])
</script>
