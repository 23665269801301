<script setup>
import { computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/twitter/components/graphs/MainGraph.vue'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

const props = defineProps({
  chartType: {
    type: String,
    default: 'total_engagement',
  },
  title: {
    type: String,
    default: 'Engagement',
  },
})

// state
const {
  engagementsAndImpressionsData,
  TweetsAndEngagementCharts,
  TweetsAndImpressionsCharts,
  dataZoomOptions,
  analyticsDesignSystem,
  isReportView,
  videosAndEngagementChartOptions: chartOptionsEngagements,
  videosAndImpressionsChartOptions: chartOptionsImpressions,
} = useTwitterAnalytics()

const chartOptions = computed(() => {
  return props.chartType === 'total_engagement'
    ? chartOptionsEngagements.value
    : chartOptionsImpressions.value
})

const noDataFound = computed(() => {
  return props.chartType === 'total_engagement'
    ? !engagementsAndImpressionsData.value?.total_engagement
    : !engagementsAndImpressionsData.value?.impression_count
})

watch(
  () => engagementsAndImpressionsData,
  () => {
    chartOptions.value.series = []
    const options = engagementsAndImpressionsData.value || {}

    const selectedChartOption =
      props.chartType === 'total_engagement'
        ? TweetsAndEngagementCharts
        : TweetsAndImpressionsCharts

    selectedChartOption.value?.forEach((chart, index) => {
      const series = {
        name: chart.name,
        type: 'bar',
        data: options[chart && chart?.api_response_key] || [],
        color: chart.color,
        colorBy: 'series',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
        yAxisIndex: index,
        barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }

      chartOptions.value.series?.push(series)
    })

    chartOptions.value.xAxis.data = options?.tweeted_at_date
    chartOptions.value.yAxis = [
      {
        ...chartOptions.value.yAxis[0],
        min: 0,
      },
      {
        ...chartOptions.value.yAxis[1],
        min: 0,
      },
    ]
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    :type="`${chartType}-specific-chart`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              {{ title }} vs Daily Posting Pattern
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Assess the relationship between your daily tweet posting
                  frequency and subsequent impression metrics, over the selected
                  time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt="no analytics found"
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
