<template>
  <div class="feeder-follow-sources-view feeds-follow-categories">
    <div class="feeds-follow-categories__action_section">
      <h3 class="feeds-follow-categories__action_section__heading"
        >Monitor the content for any topic</h3
      >
      <div
        v-click-away="closeSearchDropdown"
        class="
          feeds-follow-categories__action_section__search_bar
          search-bar-input search-bar-input-discovery
          with-icon-right
          align-items-center
        "
      >
        <div
          class="
            feeds-follow-categories__action_section__search_bar__left
            search-bar-inner
          "
        >
          <input
            id=""
            v-model="search_query"
            type="text"
            placeholder="Add your Feed URL, keyword or domain here"
            data-cy="discovery_search_bar"
            class="discoverySearchInput"
            style="padding-top: 12px; padding-bottom: 12px"
            @keyup.enter="searchQuery"
            @focus="is_visible = true"
            @click="is_visible = true"
          />
          <button
            data-cy="discovery_search_button"
            class="search_btn input_icon search_icon gradient_color"
            @click="searchQuery"
          >
            <i class="icon-Search"></i
          ></button>

          <FeederSearchHistory
            v-if="search_history_terms.length"
            :terms="search_history_terms"
            :is_visible="is_visible"
            :is-visible-history="isVisibleHistory"
            :query="search_query"
          ></FeederSearchHistory>
        </div>
        <div class="feeds-follow-categories__action_section__search_bar__right">
          <span>OR</span>
          <router-link
            :to="{ name: 'feeder_opml_import' }"
            class="underline_opml"
            href="#"
            ><span>Import OPML</span></router-link
          >
        </div>
      </div>

      <div class="feeds-follow-categories__featured_block">
        <div class="feeds-follow-categories__featured_block__content_block">
          <div
            class="feeds-follow-categories__featured_block__content_block__head"
          >
            <h4>Featured</h4>
          </div>
        </div>
      </div>
      <!-- showing the list of categories -->

      <div class="feeds-follow-categories__list">
        <template v-if="loader.featured">
          <clip-loader :color="'#436aff'" :size="'14px'"></clip-loader>
        </template>
        <template v-else>
          <div
            v-for="item in featured_categories_list"
            :key="item.label"
            class="feeds-follow-categories__list__item"
          >
            <div
              class="feeds-follow-categories__list__item__inner"
              @click="
                $router.push({
                  name: 'feeder_follow_actions',
                  query: { tag: item.label },
                })
              "
            >
              <div class="feeds-follow-categories__list__item__inner__content">
                <div
                  :style="{ 'background-image': 'url(' + item.image + ')' }"
                  class="
                    feeds-follow-categories__list__item__inner__content__image
                  "
                ></div>
              </div>
              <div
                class="
                  feeds-follow-categories__list__item__inner__content__button
                "
                ><p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@common/lib/http-common'
import { feederFeedSuggestionURI } from '../../../config/api-utils'
import FeederSearchHistory from '../components/search-assistant/FeederSearchHistory'

export default {
  components: {
    FeederSearchHistory,
  },
  data() {
    return {
      featured_categories_list: [],
      search_history_terms: [],
      loader: {
        featured: false,
      },
      is_visible: false,
      search_query: '',
    }
  },
  created() {
    this.fetchFeaturedFeedsList()
    this.fetchFeederSearchHistory()
  },
  methods: {
    isVisibleHistory(value) {
      this.is_visible = value
    },
    closeSearchDropdown() {
      this.is_visible = false
    },
    async fetchFeaturedFeedsList() {
      this.loader.featured = true
      await http
        .post(feederFeedSuggestionURI + 'featured')
        .then((res) => {
          if (res.data.status) {
            this.featured_categories_list = res.data.data
          }
          return res
        })
        .catch((err) => {
          return err
        })
      this.loader.featured = false
    },
    searchQuery() {
      this.$router.push({
        name: 'feeder_follow_actions',
        query: { q: this.search_query },
      })
    },
    async fetchFeederSearchHistory() {
      const res = await this.$store.dispatch('feederSearchHistory')
      if (res && res.data && res.data.status) {
        this.search_history_terms = res.data.data
      }
    },
  },
}
</script>
