<template>
  <div v-if="!type.includes('ThreadedTweets_')">
    <div
      v-if="!isCommentBox"
      id="hashtag_dropdown_id"
      class="dropdown data_boundary_dd prevent_close_dropdown ml-1 dropdown default_style_dropdown form_dropdown hashtag_dropdown sharing_hashtag_dropdown"
    >
      <div
        data-display="static"
        class="min_width dropdown_header"
        data-toggle="dropdown"
      >
        <i
          v-tooltip.top="'Add Hashtag'"
          class="far fa-hashtag d-block"
          data-cy="add-hashtags"
        >
        </i>
      </div>

      <div class="dropdown-menu dropdown-menu-left">
        <ul class="inner">
          <HashtagOptions :type="type"></HashtagOptions>

          <div v-if="getPublishSelection.hashtag.selected" class="field_group">
            <button
              class="btn btn-studio-theme-space"
              data-cy="hashtag-apply"
              @click.prevent="applyHashtagItems('common_sharing_message')"
              ><span>Apply</span></button
            >
          </div>

          <div v-else class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getNewWorkspaceHashtagLoader"
              :class="{ disabled: getNewWorkspaceHashtagLoader }"
              data-cy="hashtag-save"
              @click.prevent="saveAndApply"
            >
              <span>Save &amp; Apply</span>
              <clip-loader
                v-if="getNewWorkspaceHashtagLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </ul>
      </div>
    </div>
    <div
      v-else
      id="hashtag_dropdown_id_comment"
      class="dropdown data_boundary_dd prevent_close_dropdown ml-1 dropdown default_style_dropdown form_dropdown hashtag_dropdown sharing_hashtag_dropdown"
    >
      <div
        data-display="static"
        class="min_width dropdown_header"
        data-toggle="dropdown"
      >
        <i
          v-tooltip.top="'Add Hashtag'"
          class="far fa-hashtag d-block"
          data-cy="add-hashtags"
        >
        </i>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <HashtagOptions :type="type"></HashtagOptions>

          <div v-if="getPublishSelection.hashtag.selected" class="field_group">
            <button
              class="btn btn-studio-theme-space"
              data-cy="hashtag-apply"
              @click.prevent="applyHashtagItems('commentbox')"
              ><span>Apply</span></button
            >
          </div>

          <div v-else class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getNewWorkspaceHashtagLoader"
              :class="{ disabled: getNewWorkspaceHashtagLoader }"
              data-cy="hashtag-save"
              @click.prevent="saveAndApply"
            >
              <span>Save &amp; Apply</span>
              <clip-loader
                v-if="getNewWorkspaceHashtagLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      id="hashtag_dropdown_id"
      class="dropdown data_boundary_dd prevent_close_dropdown ml-1 dropdown default_style_dropdown form_dropdown hashtag_dropdown sharing_hashtag_dropdown"
    >
      <div
        data-display="static"
        class="min_width dropdown_header"
        data-toggle="dropdown"
      >
        <i
          v-tooltip.top="'Add Hashtag'"
          class="far fa-hashtag d-block"
          data-cy="add-hashtags"
        >
        </i>
      </div>

      <div class="dropdown-menu dropdown-menu-left">
        <ul class="inner">
          <HashtagOptions :type="type"></HashtagOptions>

          <div v-if="getPublishSelection.hashtag.selected" class="field_group">
            <button
              class="btn btn-studio-theme-space"
              data-cy="hashtag-apply"
              @click.prevent="applyHashtagItems(type)"
              ><span>Apply</span></button
            >
          </div>

          <div v-else class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getNewWorkspaceHashtagLoader"
              :class="{ disabled: getNewWorkspaceHashtagLoader }"
              data-cy="hashtag-save"
              @click.prevent="saveAndApply"
            >
              <span>Save &amp; Apply</span>
              <clip-loader
                v-if="getNewWorkspaceHashtagLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HashtagOptions from '../../options/HashtagOptions'

export default {
  components: {
    HashtagOptions
  },
  props: ['type', 'isCommentBox', 'isThreadedTweetsBox'],

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getPublishSelection', 'getNewWorkspaceHashtagLoader'])
  },
  methods: {
    saveAndApply () {
      const self = this
      this.saveWorkspaceHashtag('Sharing', this).then((resp) => {
        console.debug('resp=>', resp)
        if (this.isCommentBox && this.type === 'first-comment') {
          self.applyHashtagItems('commentbox', false)
        } else if (
          this.isThreadedTweetsBox &&
          this.type.includes('ThreadedTweets_')
        ) {
          self.applyHashtagItems(this.type, false)
        } else {
          self.applyHashtagItems('', false)
        }
      })
    }
  },

  watch: {}
}
</script>
