<template>
  <tbody>
    <template
      v-for="(competitor, index) in tableData"
      :key="`comparative_table_row_${index}`"
    >
      <tr
        class="hover:bg-gray-50 cursor-pointer"
        :class="{
          '!mt-2': index === 0,
          '!border-l-0 !border-t-0 !border-r-0 border !border-gray-200':
            index !== tableData.length - 1,
          'main-row z-10 relative rounded-md':
            state.expandedRowIndex.includes(index),
        }"
        @click="getFollowersGraph(competitor, index)"
      >
        <td class="py-2.5 pl-4 flex items-center">
          <i
            v-tooltip="{
              content: `Click to toggle the follower's trend graph.`,
              theme: 'light',
            }"
            class="mr-3 fa text-gray-800 text-xs"
            :class="
              state.expandedRowIndex.includes(index)
                ? 'fa-chevron-up text-cs-blue'
                : 'fa-chevron-down'
            "
          >
          </i>
          <div class="flex items-center">
            <img
              :src="competitor.image"
              class="
                h-8
                w-8
                mr-3
                rounded-full
                overflow-hidden
                object-cover
                border
              "
              alt=""
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              "
            />
            <span
              v-tooltip="{
                content: `${
                  competitor.name
                    ? 'Name: ' + competitor.name
                    : 'Slug: ' + competitor.slug
                }`,
                theme: 'light',
              }"
              class="truncate w-44 font-0-95rem"
              >{{ competitor.name ? competitor.name : competitor.slug }}</span
            >
          </div>
          <PulseCompetitorGrowth
            :data="
              followersGrowthData.data
                ? followersGrowthData.data[
                    getCompetitorIndex(competitor[platformName === 'facebook' ? 'facebook_id' : 'business_account_id'])
                  ]
                : {}
            "
            :platform-name="platformName"
            :is-loading="followersGrowthData.isLoading"
          />
        </td>
        <td
          v-for="(dataItem, key) in headers"
          :key="`comparative_table_data_${key}`"
          class="py-2.5"
        >
          <v-menu
            v-if="competitor[headers[key]] === 'N/A'"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
          >
            <p class="px-2 flex items-center font-0-95rem select-none"
              ><span
                :class="
                  getDataClasses(
                    competitor,
                    dataItem,
                    extremeValues,
                    tableData.length,
                  )
                "
                >{{ getTableData(dataItem, competitor[headers[key]]) }}</span
              ></p
            >
            <template v-slot:popper>
              <p
                class="text-gray-900"
                v-html="getTooltipForNA(competitor[headers[key]], headers[key])"
              ></p>
            </template>
          </v-menu>
          <p v-else class="px-2 flex items-center font-0-95rem select-none">
            <span
              v-tooltip="{
                content: `${competitor[headers[key]]}`,
                theme: 'light',
              }"
              :class="
                getDataClasses(
                  competitor,
                  dataItem,
                  extremeValues,
                  tableData.length,
                )
              "
              >{{ getTableData(dataItem, competitor[headers[key]]) }}</span
            >
            <i
              v-if="
                headers[key].includes('Diff') &&
                competitor[headers[key]] !== 'N/A' &&
                competitor[headers[key]] !== 0
              "
              class="fa ml-1"
              :class="
                competitor[headers[key]] > 0
                  ? 'fa-arrow-up text-[#56C288]'
                  : 'fa-arrow-down text-red-500'
              "
            ></i>
          </p>
        </td>
      </tr>
      <Transition
        name="slide"
      >
        <tr
            v-if="
          state.expandedRowIndex.length &&
          state.expandedRowIndex.includes(index)
        ">
          <td colspan="100%">
            <FollowersGrowthComparison
              :title="competitor.name"
              :platform-name="platformName"
              :data="
                followersGrowthData.data
                  ? followersGrowthData.data[
                      getCompetitorIndex(competitor[platformName === 'facebook' ? 'facebook_id' : 'business_account_id'])
                    ]
                  : {}
              "
              :is-loading="followersGrowthData.isLoading"
            />
          </td>
        </tr>
      </Transition>
    </template>
  </tbody>
</template>

<script setup>
// libraries
import {reactive, computed} from 'vue'

// components
import PulseCompetitorGrowth from '@src/modules/analytics_v3/components/PulseCompetitorGrowth.vue'
import FollowersGrowthComparison from '@src/modules/analytics_v3/components/FollowersGrowthComparison.vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  indexToSort: {
    type: Number,
    default: 0,
  },
  followersGrowthData: {
    type: Object,
    default: () => {},
  },
  platformName: {
    type: String,
    default: ''
  }
})
const { getDataClasses, getMinMaxValues } = useCompetitorHelper()


const state = reactive({
  isSorted: true,
  sortedHeaderKey: props.indexToSort,
  isLoading: false,
  expandedRowIndex: [],
})

/**
 * @description checks if the min and max values are passed through props, if not, calls getMinMaxValues method to get min and max values of all columns.
 * @type {ComputedRef<unknown>}
 */
const extremeValues = computed(() => {
  return getMinMaxValues(props.tableData, props.headers)
})

/**
 * @description return a tooltip for N/A data.
 * @param value
 * @param headerName
 * @returns {string}
 */
const getTooltipForNA = (value, headerName) => {
  if (headerName.includes('Diff')) {
    return `<p class='text-sm text-left font-bold'>Percentage (%) change in ${getTooltipHeader(
      headerName
    )}</p><p class='text-sm text-left'>No past data available for comparison to measure the magnitude of the change. This metric will be measured from the date the competitor was added to ContentStudio.</p>`
  }
}

const getTooltipHeader = (name) => {
  switch (name) {
    case 'followersCountDiff':
      return 'followers'
    case 'averagePostsPerWeekDiff':
      return 'post/ week'
    case 'engagementRateDiff':
      return 'engagement rate by followers.'
    default:
      return 'Analytics'
  }
}

/**
 * @description checks key and value to return the proper values for row data
 * @param header
 * @param value
 * @returns {*|string}
 */
const getTableData = (header, value) => {
  if (value === 'N/A') return value
  if (header.includes('Diff')) return `${value > 0 ? '+' : ''}${value}%`
  if (header.includes('Rate')) return `${value}%`
  return value > 1000
    ? new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
    : value
}

/**
 * @description returns the index of specific competitor
 * @param competitorID
 * @returns {*}
 */
const getCompetitorIndex = (competitorID) => {
  return props.followersGrowthData?.data?.findIndex(
    (item) => item[props.platformName === 'facebook' ? 'facebook_id' : 'business_account_id'] === competitorID
  )
}

/**
 * @description creates the data for expanded view of followers growth graph
 * @param competitor
 * @param index
 */
const getFollowersGraph = (competitor, index) => {
  if (props.followersGrowthData?.data?.length) {
    const expandedRowIndex = state.expandedRowIndex.slice()
    const indexFound = expandedRowIndex.indexOf(index)
    if (indexFound === -1) {
      expandedRowIndex.push(index)
    } else {
      expandedRowIndex.splice(indexFound, 1)
    }
    state.expandedRowIndex = expandedRowIndex
  }
}
</script>

<style lang="scss" scoped>
.main-row {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.slide-enter-active {
  animation: slide-in 0.3s;
}
.slide-leave-active {
  animation: slide-in 0.3s reverse;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
</style>
