<template>
  <div class="workspace_component">
    <remove-team-confirmation></remove-team-confirmation>
    <RemoveIntegration :type="type" :platform="platform"></RemoveIntegration>
    <RemoveConfirmation />
    <div class="component_inner">
      <!--<router-link class="add_icon" v-if="getWorkspaces.activeWorkspace._id" :to="{'name': 'discovery', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <button
        type="button"
        class="close_icon float-right"
        @click.prevent="redirectForDashboard"
        >&times;
      </button>
      <!--</router-link>-->
      <div class="component_inner__workspace_list">
        <div class="component_inner__workspace_list__top_heading">
          <h2>Manage Limits</h2>
          <p>Here you can remove extra items according to your plan limits.</p>
        </div>
      </div>

      <div
        v-if="filteredWorkspacesOwnedBy(true).length"
        class="col-md-10 mx-auto manage-limits-tabs"
      >
        <h3>Workspaces</h3>
        <hr class="solid" />
        <b-tabs vertical nav-class="flex-column-reverse">
          <ManageLimitWorkspaceTab
            v-for="(workspace, index) in filteredWorkspacesOwnedBy(true)"
            :key="index"
            :is-active="workspace.isActive"
            :workspace="workspace"
            @onRemoveWorkspace="removeWorkspace"
          >
            <template v-slot:teams>
              <TeamsTab :workspace="workspace" />
            </template>
            <template v-slot:social>
              <SocialTab :workspace_id="workspace.workspace_id" />
            </template>
            <template v-slot:blogs>
              <BlogTab :workspace_id="workspace.workspace_id" />
            </template>
            <template v-slot:automations>
              <AutomationTab :workspace_id="workspace.workspace_id" />
            </template>
          </ManageLimitWorkspaceTab>
        </b-tabs>
      </div>
      <div v-else class="text-center">
        <i>You don't own any workspace</i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RemoveIntegration from '../../../integration/components/dialogs/remove/RemoveIntegration'
import RemoveConfirmation from '../workspace/RemoveConfirmation'
import ManageLimitWorkspaceTab from './ManageLimitWorkspaceTab'
import TeamsTab from './tabs/TeamsTab'
import SocialTab from './tabs/SocialTab'
import BlogTab from './tabs/BlogTab'
import AutomationTab from './tabs/AutomationTab'

import removeTeamConfirmation from './../workspace/team/RemoveTeamConfirmation'
import {baseUrl} from "@src/config/api-utils";



export default {
  components: {
    ManageLimitWorkspaceTab,
    TeamsTab,
    SocialTab,
    BlogTab,
    AutomationTab,
    removeTeamConfirmation,
    RemoveIntegration,
    RemoveConfirmation
  },

  data () {
    return {
      type: '',
      platform: ''
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces'])
  },
  created () {
    // this.$root.$on('removeAccount', obj => {
    //   this.platform = obj.platform
    //   this.type = obj.type
    //
    //   this.$bvModal.show('removeIntegration')
    // })
  },
  mounted () {
    this.getMyWorkspaces()
  },

  methods: {
    removeWorkspace (item) {
      console.log('Event')
      this.checkWorkspaceRemovalAction(item)
    },
    getMyWorkspaces () {
      const myWorkspaces = this.filteredWorkspacesOwnedBy(true)
      console.log('myWorkspaces', myWorkspaces)
    },
    async redirectForDashboard () {
      await this.fetchWorkspaces(true)
      if (this.getWorkspaces.activeWorkspace.has_payment_issue) {
        console.debug('Workspace Super Admin deleted')
        window.location.href = baseUrl + 'workspaces'
      }
      if (this.getWorkspaces.activeWorkspace) {
        const activeWorkspaceMember = this.getLoggedUserRole(
          this.getWorkspaces.activeWorkspace
        )
        if (
          activeWorkspaceMember &&
          activeWorkspaceMember.role === 'approver'
        ) {
          this.$router.push({
            name: 'feed_view',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug }
          })
        } else {
          if (this.getCoveredStories) {
            this.$router.push({
              name: 'cover_stories',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug }
            })
            return
          }
          if (this.getCuratedQuotes) {
            this.$router.push({
              name: 'topics_quotes',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug }
            })
            return
          }
          this.$router.push({
            name: 'dashboard',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
hr.solid {
  border-top: 0.5px solid #bbb;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
