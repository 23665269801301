import proxy from '@common/lib/http-common'
import {
  HASHTAG_SAVE_SUCCESS,
  INVALID_COUNT,
  INVALID_NAME_HASHTAG,
  LABEL_NAME_MISSING,
  NO_HASHTAG,
  UNKNOWN_ERROR
} from '@common/constants/messages'
import {
  fetchHashTagsUrl,
  removeHashTagUrl,
  saveHashTagUrl
} from '../../config/api-utils'
import { hashtagsTypes } from './mutation-types'

export const getDefaultCurrentHashtag = () => {
  return {
    _id: '',
    name: '',
    items: [],
    count: 1,
    position: 'Append'
  }
}

const state = {
  hashtags: {
    items: [],
    search: '',
    current_hashtag: getDefaultCurrentHashtag(),
    hashtag_item: '',
    loaders: {
      save: false
    },
    loader: false,
    saveLoader: false,
    validate: true
  }
}

// getters
const getters = {
  getWorkspaceHashtags: (state) => {
    return state.hashtags
  },
  getNewWorkspaceHashtag: (state) => {
    return state.hashtags.current_hashtag
  },
  getNewWorkspaceHashtagLoader: (state) => {
    return state.hashtags.loaders.save
  },
  getHashtagLoader: (state) => {
    return state.hashtags.loader
  },
  getWorkspaceHashtagValidation: (state) => {
    return state.hashtags.validate
  }
}

// actions
const actions = {
  /**
   * validate the input before the user is trying to save the data
   * @param commit
   * @param dispatch
   * @param getters
   * @param type
   * @returns {boolean}
   */
  async validateHashtagInput ({ commit, dispatch, getters }, type) {
    console.debug('Method:hashtagValidation')
    const details = getters.getNewWorkspaceHashtag
    console.debug(details.items.length)
    if (details.name.trim() === '') {
      console.debug(INVALID_NAME_HASHTAG)
      dispatch('toastNotification', {
        message: INVALID_NAME_HASHTAG,
        type: 'error'
      })
      commit('SET_VALIDATE', false)
      return false
    } else if (!details.items.length) {
      dispatch('toastNotification', { message: NO_HASHTAG, type: 'error' })
      commit('SET_VALIDATE', false)
      return false
    } else if (details.count < 1) {
      dispatch('toastNotification', {
        message: INVALID_COUNT + ' ' + details.items.length,
        type: 'error'
      })
      commit('SET_VALIDATE', false)
    } else if (details.items.length && details.count > details.items.length) {
      dispatch('toastNotification', {
        message: INVALID_COUNT + ' ' + details.items.length,
        type: 'error'
      })
      commit('SET_VALIDATE', false)
      return false
    }
    commit('SET_VALIDATE', true)

    return true
  },

  /**
   * Fetch hashtags list.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param type
   */
  fetchWorkspaceHashtags ({ commit, getters, state, dispatch }, type) {
    console.debug('Action::fetchWorkspaceHashtags')
    proxy
      .post(fetchHashTagsUrl, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      })
      .then((resp) => {
        commit('SET_WORKSPACE_HASHTAGS', resp.data.hashtags)
      })
      .catch()
  },

  /**
   * Save new hashtag into the workspace
   *
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param rootGetters
   * @param type
   * @returns {Promise<*>}
   */
  async saveWorkspaceHashtag (
    { commit, getters, state, dispatch, rootGetters },
    details
  ) {
    console.debug('Method::saveWorkspaceHashtag', details)
    const payload = getters.getNewWorkspaceHashtag
    let response = false
    if (details.type === 'influencer') response = true
    else response = await dispatch('validateHashtagInput')
    if (response) {
      commit('SET_NEW_WORKSPACE_HASHTAG_LOADER', true)
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
      return proxy
        .post(saveHashTagUrl, payload)
        .then((resp) => {
          commit('SET_NEW_WORKSPACE_HASHTAG_LOADER', false)
          if (resp.data.status) {
            // hide modal, otherwise set the selected hashtag id.
            dispatch('trackEvent', { event: 'hashtags_created' })

            if (details.type === 'miscellaneous') {
              // $('#add_hashtag_modal').modal('hide')

              details.state.$bvModal.hide('add_hashtag_modal')
            } else if (details.type !== 'influencer') {
              dispatch('setSelectedHashtag', resp.data.hashtag._id)
            }

            // for only new items case.

            if (payload._id && payload._id.length > 2) {
              commit('UPDATE_WORKSPACE_HASHTAG_BY_INDEX', resp.data.hashtag)
            } else {
              commit('ADD_WORKSPACE_HASHTAG', resp.data.hashtag)
            }

            if (details.type !== 'influencer') {
              dispatch('toastNotification', {
                message: HASHTAG_SAVE_SUCCESS,
                type: 'success'
              })
            }
            return true
          } else {
            dispatch('toastNotification', {
              message: resp.data.message,
              type: 'error'
            })
            return false
          }
        })
        .catch()
    }
  },

  /**
   * Add the items to the hashtag list.
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param item
   */
  addWorkspaceHashtagItems ({ commit, getters, state, dispatch }, item) {
    if (item) {
      const splitHashtags = item.split(' ')
      if (splitHashtags) {
        const tagsList = []

        // filter the hashtags which are starting with # and without those.
        splitHashtags.filter((tag) => {
          if (tag) {
            // if it starts with #
            if (tag.startsWith('#')) {
              tagsList.push(tag)
            } else {
              // add the # to the start

              tag = '#' + tag
              tagsList.push(tag)
            }
          }
        })

        commit('APPEND_HASHTAGS_ITEMS', tagsList)
        commit('SET_NEW_WORKSPACE_HASHTAG_ITEM', '')
      }
    }
  },

  /**
   * remove workspace hashtag value
   * @param commit
   * @param getters
   * @param state
   * @param dispatch
   * @param data
   */
  removeWorkspaceHashtag ({ commit, getters, state, dispatch }, data) {
    proxy
      .post(removeHashTagUrl, {
        hashtag: data.tag, // tag mongodb id
        workspace_id: data.workspace_id
      })
      .then((resp) => {
        if (resp.data.status) {
          // successfully removed
          commit('REMOVE_WORKSPACE_HASHTAG_BY_INDEX', data.tag)
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'success'
          })
        } else {
          // status is false
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'error'
          })
          dispatch('setConfirmActionStage', 'error')
        }
        $('#' + data.confirmation.type).modal('hide')
        dispatch('setConfirmActionStage', '')
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// mutations
const mutations = {
  /**
   * Set the list of hashtags
   * @param state
   * @param items
   * @constructor
   */
  SET_WORKSPACE_HASHTAGS (state, items) {
    state.hashtags.items = items
  },

  /**
   * Add the hashtag item to the list, sorted by the recent created one
   *
   * @param state
   * @param hashtag
   * @constructor
   */
  ADD_WORKSPACE_HASHTAG (state, hashtag) {
    state.hashtags.items.unshift(hashtag)
  },

  /**
   * Update the hashtag value by index.
   * @param state
   * @param hashtag
   * @constructor
   */
  UPDATE_WORKSPACE_HASHTAG_BY_INDEX (state, hashtag) {
    if (state.hashtags.items) {
      const index = state.hashtags.items.findIndex(
        (item) => hashtag._id === item._id
      )
      state.hashtags.items.splice(index, 1, hashtag)
    }
  },

  REMOVE_WORKSPACE_HASHTAG_BY_INDEX (state, tagId) {
    if (state.hashtags.items) {
      const index = state.hashtags.items.findIndex((item) => tagId === item._id)
      state.hashtags.items.splice(index, 1)
    }
  },

  /**
   * Reset the modal values, called from the New Workspace Modal.
   * @param state
   * @constructor
   */

  RESET_NEW_WORKSPACE_HASHTAG (state) {
    state.hashtags.hashtag_item = ''
    state.hashtags.current_hashtag = getDefaultCurrentHashtag()
  },

  /**
   * Set the current hashtag item, so that for example the user has selected hashtag option for the automation
   * @param state
   * @param hashtag
   * @constructor
   */
  SET_NEW_WORKSPACE_HASHTAG (state, hashtag) {
    state.hashtags.current_hashtag = hashtag
  },

  /**
   * Add the list of hashtags that user may have entered such as #digital marketing, #digital etc.
   * @param state
   * @param items
   * @constructor
   */
  APPEND_HASHTAGS_ITEMS (state, items) {
    const hashtagsList = state.hashtags.current_hashtag.items.concat(items)
    state.hashtags.current_hashtag.items = Array.from(new Set(hashtagsList))
  },

  /**
   * Removing an individual item from the list.
   * @param state
   * @param item
   * @constructor
   */
  REMOVE_NEW_WORKSPACE_HASHTAG_ITEM (state, index) {
    console.debug(
      'REMOVE_NEW_WORKSPACE_HASHTAG_ITEM',
      state.hashtags.current_hashtag.items.length,
      state.hashtags.current_hashtag.count
    )
    state.hashtags.current_hashtag.items.splice(index, 1)
    if (
      state.hashtags.current_hashtag.items.length <
        state.hashtags.current_hashtag.count &&
      state.hashtags.current_hashtag.count > 1
    ) {
      state.hashtags.current_hashtag.count =
        state.hashtags.current_hashtag.count - 1
    }
  },
  /**
   * Set the workspace hashtag item text, the user entered query e.g #digital, #marketing
   * @param state
   * @param tag
   * @constructor
   */
  SET_NEW_WORKSPACE_HASHTAG_ITEM (state, tag) {
    state.hashtags.hashtag_item = tag
  },

  /**
   * Reset hashtags items
   * @param state
   * @constructor
   */
  RESET_WORKSPACE_HASHTAGS (state) {
    state.hashtags.items = []
  },

  SET_NEW_WORKSPACE_HASHTAG_NAME (state, value) {
    state.hashtags.current_hashtag.name = value
  },
  SET_NEW_WORKSPACE_HASHTAG_ITEMS (state, value) {
    state.hashtags.current_hashtag.items = value
  },

  /** Validate the user input for the hashtag
   *
   * @param state
   * @param value
   * @constructor
   */
  SET_VALIDATE (state, value) {
    state.hashtags.validate = value
  },

  SET_NEW_WORKSPACE_HASHTAG_LOADER (state, value) {
    state.hashtags.loaders.save = value
  },

  [hashtagsTypes.SET_CURRENT_HASHTAG_VALUE] (state, value) {
    state.hashtags.current_hashtag.name = value.name
    state.hashtags.current_hashtag.items = value.items
    state.hashtags.current_hashtag.count = value.count
    state.hashtags.current_hashtag.position = value.position
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
