<template>
  <div id="addGroup" class="modal fade normal_modal addTeamMember addGroup">
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getTeam',
      'getSettingLoaders',
      'getFacebookAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getTwitterAccounts',
      'getCurrentGroup'
    ])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions([''])
  }
}
</script>
