/**
 * @description Composable for handling competitor report
 */

import {computed, reactive, toRefs} from 'vue'

// libs
import moment from "moment";

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'

// store
import {useStore} from '@state/base'

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'
import {useRoute} from "vue-router";


const {getters} = useStore()

const state = reactive({
    reportId: '',
    timeZone: '',
    dateRange: '',
    defaultDateRange: '',
    dateRangeDiff: '30',
    reportCompetitorsInfo: null
})

const colors = [
    '#61BAE4',
    '#8AC1A2',
    '#145B9B',
    '#777777',
    '#8081B9',
    '#32a487',
    '#339ca8',
    '#cda819',
]

export default function useCompetitorReport() {
    const route = useRoute()

    const reportId = computed(() => route?.params?.reportId)

    const {
        fetchCompetitorInfo: fetchReportInfo,
        apiResponse: reportInfoResponse,
        loading: reportInfoLoadingState,
    } = useCompetitorsFactory()


    const getReportInfo = async () => {
        if (!reportId.value) return
        const payLoad = {
            _id: reportId.value,
            time_zone: state.timeZone,
            date_filter: state.dateRange,
        }
        try {
            await fetchReportInfo(COMMON_API_ENDPOINTS.GET_REPORT_INFO, payLoad, COMMON_ERROR_MESSAGES.GET_REPORT_INFO)
            state.reportCompetitorsInfo = reportInfoResponse.value
        } catch (error) {
        }
    }

    const setDateValues = () => {
        state.reportId = reportId.value;
        state.timeZone = getters.getActiveWorkspace.timezone;

        state.dateRange = `${moment().subtract(30, 'days')} - ${moment()}`;
        state.defaultDateRange = [
            moment().subtract(30, 'days').toDate(),
            moment().toDate(),
        ];
    }

    return {
        // data
        ...toRefs(state),
        reportInfoLoadingState,
        reportId,
        colors,

        // methods
        getReportInfo,
        setDateValues,
    }
}