<template>
  <b-modal
    id="create-workspace-modal"
    class="createWorkspaceModal"
    hide-header
    hide-footer
    title="Using Component Methods"
  >
    <div class="modal-dialog mt-0">
      <div class="modal-content">
        <div class="modal_head">
          <div>
            <h4>New Workspace</h4>
          </div>
          <button
            class="modal_head__close"
            type="button"
            @click="$bvModal.hide('create-workspace-modal')"
            >&times;</button
          >
        </div>
        <div class="modal_body m_t_15">
          <workspace-fields></workspace-fields>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import workspaceFields from '@src/modules/setting/components/workspace/WorkspaceFields'

import { mapActions } from 'vuex'
export default {
  components: {
    workspaceFields
  },

  data () {
    return {}
  },
  computed: {},

  created () {},
  mounted () {},

  methods: {
    ...mapActions([])
  }
}
</script>
