import { mapGetters, mapActions, mapMutations } from 'vuex'
import hashtags from '@src/modules/setting/store/states/hashtags'
import hashtag from '@common/lib/hashtag'

export const hashtagMixin = {
  data() {
    return {}
  },

  computed: {
    filteredTags() {
      const tags = []
      const stateObject = this

      if (
        this.getWorkspaceHashtags.search &&
        this.getWorkspaceHashtags.items.length > 0
      ) {
        this.getWorkspaceHashtags.items.filter((tag) => {
          if (
            tag.name
              .toLowerCase()
              .indexOf(stateObject.getWorkspaceHashtags.search.toLowerCase()) >=
            0
          ) {
            tags.push(tag)
          }
        })
      } else {
        return tags.push(this.getWorkspaceHashtags.items)
      }

      return tags
    },
    ...mapGetters([
      'getHashtagLoader',
      'getWorkspaceHashtags',
      'getCommonBoxOptions',
      'getCommonSharingDetails',
      'getSocialSharingSelection',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getInstagramSharingDetails',
      'getNewWorkspaceHashtag',
      'getGmbSharingDetails',
      'getYoutubeSharingDetails',
      'getSocialSharingFirstComment',
      'getAccountSelection',
    ]),
  },
  methods: {
    ...mapMutations(['APPEND_HASHTAGS_ITEMS']),
    ...mapActions([
      'saveWorkspaceHashtag',
      'removeWorkspaceHashtag',
      'setSelectedHashtag',
      'validateHashtagInput',
    ]),

    removeTag(confirmation, data) {
      if (confirmation.status) {
        const payload = data
        payload.confirmation = confirmation
        this.removeWorkspaceHashtag(payload)
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },

    async applyHashtagItems(flag = '', validate = true) {
      console.debug('Method:applyHashtagItems', flag)

      if (validate) {
        await this.validateHashtagInput('rss')
      }

      if (this.getWorkspaceHashtagValidation === false) {
        return false
      }

      const details = this.getNewWorkspaceHashtag
      if (details.count > details.items.length) {
        details.count = details.items.length
      }

      console.debug('Hashtag', details)
      if (flag === 'commentbox') {
        const commentboxvalue = document.getElementById('firstcommentbox').value
          ? document.getElementById('firstcommentbox').value
          : ''
        const event = new Event('input')
        if (details.position === 'Append') {
          document.getElementById('firstcommentbox').value =
            hashtag.appendHashTag(commentboxvalue, details)
          // Works in most modern browsers.
          document.getElementById('firstcommentbox').dispatchEvent(event)
        } else {
          document.getElementById('firstcommentbox').value =
            hashtag.replaceHashTag(commentboxvalue, details)
          // Works in most modern browsers.
          document.getElementById('firstcommentbox').dispatchEvent(event)
        }
      } else if (flag.includes('ThreadedTweets_')) {
        const message = document.getElementById(flag).value
          ? document.getElementById(flag).value
          : ''
        const event = new Event('input')
        if (details.position === 'Append') {
          document.getElementById(flag).value = hashtag.appendHashTag(
            message,
            details
          )
          document.getElementById(flag).dispatchEvent(event)
        } else {
          document.getElementById(flag).value = hashtag.replaceHashTag(
            message,
            details
          )
          document.getElementById(flag).dispatchEvent(event)
        }
      } else {
        if (details.position === 'Append') {
           if (this.getCommonBoxOptions.status) {
            this.setSharingMessage(
              'Common',
              hashtag.appendHashTag(
                this.getCommonSharingDetails.message,
                details
              ),
            )
          } else {
            switch (this.getSocialSharingSelection.activeTab) {
              case 'facebook_tab':
                this.setSharingMessage(
                  'Facebook',
                  hashtag.appendHashTag(
                    this.getFacebookSharingDetails.message,
                    details
                  )
                )
                break
              case 'twitter_tab':
                this.setSharingMessage(
                  'Twitter',
                  hashtag.appendHashTag(
                    this.getTwitterSharingDetails.message,
                    details
                  )
                )
                break
              case 'linkedin_tab':
                this.setSharingMessage(
                  'Linkedin',
                  hashtag.appendHashTag(
                    this.getLinkedinSharingDetails.message,
                    details
                  )
                )
                break
              case 'pinterest_tab':
                this.setSharingMessage(
                  'Pinterest',
                  hashtag.appendHashTag(
                    this.getPinterestSharingDetails.message,
                    details
                  )
                )
                break
              case 'tumblr_tab':
                this.setSharingMessage(
                  'Tumblr',
                  hashtag.appendHashTag(
                    this.getTumblrSharingDetails.message,
                    details
                  )
                )
                break
              case 'instagram_tab':
                this.setSharingMessage(
                  'Instagram',
                  hashtag.appendHashTag(
                    this.getInstagramSharingDetails.message,
                    details
                  )
                )
                break
              case 'youtube_tab':
                this.setSharingMessage(
                  'Youtube',
                  hashtag.appendHashTag(
                    this.getYoutubeSharingDetails.message,
                    details
                  )
                )
                break
              case 'gmb_tab':
                this.setSharingMessage(
                  'Gmb',
                  hashtag.appendHashTag(
                    this.getGmbSharingDetails.message,
                    details
                  )
                )
                break
            }
          }
        } else {
          if (this.getCommonBoxOptions.status) {
            this.setSharingMessage(
              'Common',
              hashtag.replaceHashTag(
                this.getCommonSharingDetails.message,
                details
              )
            )
          } else {
            switch (this.getSocialSharingSelection.activeTab) {
              case 'facebook_tab':
                this.setSharingMessage(
                  'Facebook',
                  hashtag.replaceHashTag(
                    this.getFacebookSharingDetails.message,
                    details
                  )
                )
                break
              case 'twitter_tab':
                this.setSharingMessage(
                  'Twitter',
                  hashtag.replaceHashTag(
                    this.getTwitterSharingDetails.message,
                    details
                  )
                )
                break
              case 'linkedin_tab':
                this.setSharingMessage(
                  'Linkedin',
                  hashtag.replaceHashTag(
                    this.getLinkedinSharingDetails.message,
                    details
                  )
                )
                break
              case 'pinterest_tab':
                this.setSharingMessage(
                  'Pinterest',
                  hashtag.replaceHashTag(
                    this.getPinterestSharingDetails.message,
                    details
                  )
                )
                break
              case 'tumblr_tab':
                this.setSharingMessage(
                  'Tumblr',
                  hashtag.replaceHashTag(
                    this.getTumblrSharingDetails.message,
                    details
                  )
                )
                break
              case 'instagram_tab':
                this.setSharingMessage(
                  'Instagram',
                  hashtag.replaceHashTag(
                    this.getInstagramSharingDetails.message,
                    details
                  )
                )
                break
              case 'youtube_tab':
                this.setSharingMessage(
                  'Youtube',
                  hashtag.replaceHashTag(
                    this.getYoutubeSharingDetails.message,
                    details
                  )
                )
                break
              case 'gmb_tab':
                this.setSharingMessage(
                  'Gmb',
                  hashtag.replaceHashTag(
                    this.getGmbSharingDetails.message,
                    details
                  )
                )
                break
            }
          }
        }
      }
      this.setSelectedHashtagSelection(null)
      this.changeHashtagSelectionById('')
      $('.sharing_hashtag_dropdown .dropdown-menu').removeClass('show')
    },
    checkInstaHash() {
      let message = []
      let error = ''
      let accountSelected = false
      if (this.getInstagramAccounts.items.length > 0) {
        this.getInstagramAccounts.items.forEach((item) => {
          if (
            this.getSocialSharingFirstComment.has_first_comment &&
            this.getSocialSharingFirstComment.first_comment_accounts.includes(
              item.instagram_id
            )
          ) {
            accountSelected = true
          }
        })
      }
      if (this.getCommonBoxOptions.status) {
        message = [
          ...new Set(this.getHashTags(this.getCommonSharingDetails.message)),
        ]
        if (message && message.length > 30) {
          error =
            'The composer box contains ' +
            message.length +
            ' hashtags, Instagram allows for upto 30. Please reduce the amount of hashtags used in Composer to avoid the post getting failed.'
          return error
        }
      } else {
        message = [
          ...new Set(this.getHashTags(this.getInstagramSharingDetails.message)),
        ]
        if (message && message.length > 30) {
          error =
            'The composer box contains ' +
            message.length +
            ' hashtags, Instagram allows for upto 30. Please reduce the amount of hashtags used in Composer to avoid the post getting failed.'
          return error
        }
      }
      if (accountSelected) {
        let composer_Message = []
        if (message) composer_Message = message
        message = [
          ...new Set(
            this.getHashTags(
              this.getSocialSharingFirstComment.first_comment_message
            )
          ),
        ]
        if (message && message.length > 30) {
          error =
            'The first comment box contains ' +
            message.length +
            ' hashtags, Instagram allows for upto 30. Please reduce the amount of hashtags used in the First Comment so that the first comment goes with the post. The post will not fail in this case.'
          return error
        }
        if (message) composer_Message = composer_Message.concat(message)
        message = [...new Set(composer_Message)]

        if (message && message.length > 30) {
          error =
            'The post and first comment accumulated contain ' +
            message.length +
            ' hashtags, Instagram allows for a total of 30. Please reduce the amount of hashtags used in Composer or First Comment to avoid the post getting failed.'
          return error
        }
      }
    },
  },
}
