// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function useTopAndLeastPerformingPosts() {
    const {
        fetchCompetitorInfo: fetchTopAndLeastPerformingPosts,
        apiResponse: topAndLeastPerformingPostsResponse,
        loading: topAndLeastPerformingPostsLoadingState
    } = useCompetitorsFactory()
    const {reportId, timeZone, dateRange} = useCompetitorReport()

    const getTopAndLeastPerformingPosts = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonTopAndLeastPerformingPosts()
                break
            default:
                break
        }
    }

    const fetchCommonTopAndLeastPerformingPosts = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }

        try {
            await fetchTopAndLeastPerformingPosts(COMMON_API_ENDPOINTS.TOP_AND_LEAST_PERFORMING_POSTS, payload, COMMON_ERROR_MESSAGES.TOP_AND_LEAST_PERFORMING_POSTS)
        } catch (error) {
        }
    }


    return {
        // data
        topAndLeastPerformingPostsLoadingState,
        topAndLeastPerformingPostsResponse,

        // methods
        getTopAndLeastPerformingPosts
    }
}