import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import moment from 'moment'

export const swalAttributes = () => {
  return {
    size: 'md',
    buttonSize: 'md',
    okVariant: 'danger',
    okTitle: 'Yes',
    cancelTitle: 'No',
    footerClass: 'p-2',
    hideHeader: true,
    hideHeaderClose: false,
    centered: true,
    id: 'sweet-alert',
    modalClass: 'sweet-alert-confirmation fade',
    titleClass: 'sweet-alert-title',
    contentClass: 'sweet-alert-content',
  }
}

/**
 * Option for edit delete modal
 * @returns {{hideHeader: boolean, centered: boolean, hideHeaderClose: boolean, okTitle: string, cancelTitle: string, buttonSize: string, footerClass: string, size: string, okVariant: string, contentClass: string, modalClass: string, id: string, titleClass: string}}
 */
export const editDeleteOptions = () => {
  return {
    size: 'md',
    buttonSize: 'md',
    okVariant: 'danger',
    okTitle: 'Edit',
    cancelTitle: 'Delete',
    footerClass: 'p-2',
    hideHeader: true,
    hideHeaderClose: false,
    centered: true,
    id: 'sweet-alert',
    modalClass: 'sweet-alert-confirmation fade',
    titleClass: 'sweet-alert-title',
    contentClass: 'sweet-alert-content',
  }
}

let channels = [
  {
    name: 'facebook',
    key: 'facebook_id',
    getter: 'getFacebookAccounts',
  },
  {
    name: 'instagram',
    key: 'instagram_id',
    getter: 'getInstagramAccounts',
  },
  {
    name: 'threads',
    key: 'platform_identifier',
    getter: 'getThreadsAccounts',
  },
  {
    name: 'twitter',
    key: 'twitter_id',
    getter: 'getTwitterAccounts',
  },
  {
    name: 'linkedin',
    key: 'linkedin_id',
    getter: 'getLinkedinAccounts',
  },
  {
    name: 'pinterest',
    key: 'board_id',
    getter: 'getPinterestBoards',
  },
  {
    name: 'gmb',
    key: 'name',
    getter: 'getGmbAccounts',
  },
  {
    name: 'youtube',
    key: 'platform_identifier',
    getter: 'getYoutubeAccounts',
  },
  {
    name: 'tiktok',
    key: 'platform_identifier',
    getter: 'getTiktokAccounts',
  },
  {
    name: 'tumblr',
    key: 'platform_identifier',
    getter: 'getTumblrAccounts',
  },
]

export const socialChannelsArray = channels
export const socialChannelsNameArray = [
  'facebook',
  'instagram',
  'threads',
  'twitter',
  'linkedin',
  'pinterest',
  'gmb',
  'youtube',
  'tiktok',
  'tumblr',
]

channels = JSON.parse(JSON.stringify(channels))

for (const key in blogIntegrationsNames) {
  channels.push({
    name: blogIntegrationsNames[key],
    key: 'platform_identifier',
    getter: 'getBlogs',
  })
}

export const allChannelsArray = channels

export const getDefaultTimeSlots = () => {
  return {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }
}

export function getDuplicateCampaignName(name) {
  name = name.trim()
  const lastcharater = name.slice(-1)

  if (isNaN(lastcharater)) {
    name = name + ' 1'
  } else {
    name = name.substring(0, name.length - 1).trim()
    name = name + ' ' + (parseInt(lastcharater) + 1)
  }
  return name
}

export function getChartColorCode(series) {
  try {
    // Object.prototype.hasOwnProperty.call(series.color,'stops')
    // eslint-disable-next-line no-prototype-builtins
    if (series.color.hasOwnProperty('stops')) {
      console.log('getChartColorCode in', series)
      return series.color.stops[0][1]
    } else {
      return series.color
    }
  } catch (m) {}
}

export const FacebookCallToAction = [
  {
    name: 'No Button',
    type: 'NO_BUTTON',
  },
  {
    name: 'Add To Cart',
    type: 'ADD_TO_CART',
  },
  {
    name: 'Apply Now',
    type: 'APPLY_NOW',
  },
  {
    name: 'Bet Now',
    type: 'BET_NOW',
  },
  {
    name: 'Book Now',
    type: 'BOOK_TRAVEL',
  },
  {
    name: 'Buy Now',
    type: 'BUY_NOW',
  },
  {
    name: 'Buy Tickets',
    type: 'BUY_TICKETS',
  },
  {
    name: 'Call Now',
    type: 'CALL_NOW',
  },
  {
    name: 'Contact Us',
    type: 'CONTACT_US',
  },
  {
    name: 'Download',
    type: 'DOWNLOAD',
  },
  {
    name: 'Get Directions',
    type: 'GET_DIRECTIONS',
  },
  {
    name: 'Get Offer',
    type: 'GET_OFFER',
  },
  {
    name: 'Get Quote',
    type: 'GET_QUOTE',
  },
  {
    name: 'Go Live',
    type: 'GO_LIVE',
  },
  {
    name: 'Install Now',
    type: 'INSTALL_MOBILE_APP',
  },
  {
    name: 'Learn More',
    type: 'LEARN_MORE',
  },
  {
    name: 'Like Page',
    type: 'LIKE_PAGE',
  },
  {
    name: 'Listen Now',
    type: 'LISTEN_MUSIC',
  },
  {
    name: 'Open Link',
    type: 'OPEN_LINK',
  },
  {
    name: 'Order Now',
    type: 'ORDER_NOW',
  },
  {
    name: 'Play Game',
    type: 'PLAY_GAME',
  },
  {
    name: 'Register Now',
    type: 'REGISTER_NOW',
  },
  {
    name: 'Request Time',
    type: 'REQUEST_TIME',
  },
  {
    name: 'Save',
    type: 'SAVE',
  },
  {
    name: 'Send Message',
    type: 'MESSAGE_PAGE',
  },
  {
    name: 'Send message to Whatsapp',
    type: 'WHATSAPP_MESSAGE',
  },
  {
    name: 'Shop Now',
    type: 'SHOP_NOW',
  },
  {
    name: 'Sign Up',
    type: 'SIGN_UP',
  },
  {
    name: 'Subscribe',
    type: 'SUBSCRIBE',
  },
  {
    name: 'Use App',
    type: 'USE_APP',
  },
  {
    name: 'Watch More',
    type: 'WATCH_MORE',
  },
  {
    name: 'Watch Video',
    type: 'WATCH_VIDEO',
  },
]

export const ALL_CHANNELS = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  THREADS: 'threads',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  PINTEREST: 'pinterest',
  GMB: 'gmb',
  TUMBLR: 'tumblr',
  TIKTOK: 'tiktok',
  YOUTUBE: 'youtube',
}

// Allowed routes for approver
export const APPROVER_ALLOWED_ROUTES = [
  'planner_feed_v2',
  'planner_list_v2',
  'planner_calendar_v2',
  'notifications',
  'profile',
  'planner_tiktok_grid_view',
  'planner_instagram_grid_view',
  'setPassword',
]


export const datePickerShortcuts = [
  {
    text: 'Today',
    onClick() {
      const date = moment().toDate()
      // return a Date
      return [date, date]
    },
  },
  {
    text: 'Yesterday',
    onClick() {
      const date = moment().subtract(1, 'days').toDate()
      return [date, date]
    },
  },
  {
    text: 'Tomorrow',
    onClick() {
      const date = moment().add(1, 'days').toDate()
      return [date, date]
    },
  },
  {
    text: 'Next 7 Days',
    onClick() {
      const start = moment().toDate()
      const end = moment().add(7, 'days').toDate()
      return [start, end]
    },
  },
  {
    text: 'Next 14 Days',
    onClick() {
      const start = moment().toDate()
      const end = moment().add(14, 'days').toDate()
      return [start, end]
    },
  },
  {
    text: 'Next 30 Days',
    onClick() {
      const start = moment().toDate()
      const end = moment().add(30, 'days').toDate()
      return [start, end]
    },
  },
  {
    text: 'This Week',
    onClick() {
      const start = moment().startOf('week').toDate()
      const end = moment().endOf('week').toDate()
      return [start, end]
    },
  },
  {
    text: 'Next Week',
    onClick() {
      const start = moment().add(1, 'week').startOf('week').toDate()
      const end = moment().add(1, 'week').endOf('week').toDate()
      return [start, end]
    },
  },
  {
    text: 'Last Week',
    onClick() {
      const start = moment().subtract(1, 'week').startOf('week').toDate()
      const end = moment().subtract(1, 'week').endOf('week').toDate()
      return [start, end]
    },
  },
  {
    text: 'This Month',
    onClick() {
      const start = moment().startOf('month').toDate()
      const end = moment().endOf('month').toDate()

      return [start, end]
    },
  },
  {
    text: 'Next Month',
    onClick() {
      const start = moment().add(1, 'month').startOf('month').toDate()
      const end = moment().add(1, 'month').endOf('month').toDate()

      return [start, end]
    },
  },
  {
    text: 'Last Month',
    onClick() {
      const start = moment().subtract(1, 'month').startOf('month').toDate()
      const end = moment().subtract(1, 'month').endOf('month').toDate()

      return [start, end]
    },
  },
  {
    text: 'This Year',
    onClick() {
      const start = moment().startOf('year').toDate()
      const end = moment().endOf('year').toDate()

      return [start, end]
    },
  },
]
