<template>
  <div
    class="dropdown default_style_dropdown emoji_dropdown prevent_close_dropdown"
  >
    <div class="dropdown_header" data-toggle="dropdown" @click="loaded = true">
      <i
        v-tooltip.bottom="'Add an emoji'"
        :class="{ 'thread-emoji': type.includes('ThreadedTweets_') }"
        class="fal fa-smile-beam d-block font-weight-500"
      ></i>
    </div>

    <div
      :class="{
        'dropdown-menu-left': dropdownPosition === 'left_side',
        'dropdown-menu-bottom': dropdownPosition === 'bottom_side',
        'dropdown-menu-right': dropdownPosition === 'right_side' || !dropdownPosition,
        'dropdown-menu-top': dropdownPosition === 'top_side',
      }"
      class="dropdown-menu emojis-list-position"
      style="top: -425px !important; transform: unset !important"
    >
      <div class="emoji_list">
        <template v-if="loaded">
          <Picker
              :data="index"
              :emoji-size="18"
              :emoji-tooltip="true"
              :native="false"
              class="emoji-mart-wrap"
              emoji="point_up"
              title="Pick your emoji..."
              @select="addEmoji"
          ></Picker>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
const index = new EmojiIndex(data)

export default {
  components: {
    Picker,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    dropdownPosition: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      index,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    addEmoji(emoji) {
      this.addSharingEmojiFast(emoji, this.type)
    },
  },
}
</script>

<style scoped>
.thread-emoji {
  font-size: 1.2rem !important;
}
</style>
