<template>
  <div id="add_source_modal" class="modal fade normal_modal add_source_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Add a new source</h2>
          <button id="close" type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form">
          <div class="add_source_box_list">
            <div class="source_inline d-flex align-items-center">
              <div class="add_source_box">
                <div class="add_box_inner">
                  <h3>Search by Keyword</h3>
                  <div class="search_input with_icon_right">
                    <div class="search_inner">
                      <input
                        v-model="getSources.keyword"
                        type="text"
                        placeholder="Type keyword or domain name here..."
                        @keydown="searchKeywordSuggestion($event)"
                      />
                      <!--<button :class="{'have_text_bg_blue' : getSources.keyword}" class="search_btn input_icon search_icon gradient_color ">-->
                      <!--<i class="icon-Search"></i>-->
                      <!--</button>-->
                    </div>
                    <div
                      :class="{
                        'd-none':
                          getSources.keyword.length < 1 ||
                          getSources.show_selected_suggestion == true ||
                          getSources.rss_change,
                      }"
                      class="multi_dropdown"
                    >
                      <div class="multi_dropdown_inner">
                        <template v-if="getSourcesLoader.keyword">
                          <beat-loader
                            :color="'#436aff'"
                            class="pt-3 pb-3"
                          ></beat-loader>
                        </template>
                        <template v-else>
                          <ul>
                            <li
                              v-for="feed in getSources.suggested_feeds"
                              class="item d-flex align-items-center"
                              @click.prevent="
                                getSourcePreview({
                                  url: feed.value,
                                  select: true,
                                  isKeyword: true,
                                })
                              "
                            >
                              <span class="icon">
                                <i class="cs-rss"></i>
                              </span>
                              <span class="text">
                                <span class="heading">{{ feed.label }}</span>
                                <span class="url">{{ feed.value }}</span>
                              </span>
                              <span class="view">
                                <i class="fa fa-eye"></i>
                              </span>
                            </li>
                          </ul>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add_source_box">
                <div class="add_box_inner">
                  <h3>Add RSS feed</h3>

                  <div class="search_input with_icon_right">
                    <div class="search_inner">
                      <input
                        v-model="getSources.preview_rss_url"
                        type="text"
                        placeholder="Type domain or RSS URL here..."
                        @keyup.enter="
                          getSourcePreview({ url: getSources.preview_rss_url })
                        "
                      />
                      <button
                        :class="{
                          have_text_bg_orange: getSources.preview_rss_url,
                        }"
                        :disabled="getSourcesLoader.preview"
                        class="search_btn input_icon search_icon gradient_color"
                        @click.prevent="
                          getSourcePreview({ url: getSources.preview_rss_url })
                        "
                      >
                        <i class="cs-rss"></i>
                      </button>
                    </div>
                    <div
                      v-if="
                        getSources.suggested_rss_links &&
                        getSources.suggested_rss_links.length > 0
                      "
                      :class="{
                        'd-none':
                          getSources.suggested_rss_links.length == 0 ||
                          getSources.selected_rss_link == true,
                      }"
                      class="multi_dropdown"
                    >
                      <div class="multi_dropdown_inner">
                        <template v-if="getSourcesLoader.preview">
                          <beat-loader
                            :color="'#436aff'"
                            class="pt-3 pb-3"
                          ></beat-loader>
                        </template>
                        <template v-else>
                          <ul>
                            <li
                              class="item d-flex align-items-center no_hover_effect"
                            >
                              Suggested RSS feeds:
                            </li>
                            <li
                              v-for="feed in getSources.suggested_rss_links"
                              class="item d-flex align-items-center"
                              @click.prevent="
                                getSourcePreview({ url: feed, select: true })
                              "
                            >
                              <span class="icon">
                                <i class="cs-rss"></i>
                              </span>
                              <span class="text">
                                <span class="heading">{{ feed.label }}</span>
                                <span class="url">{{ feed }}</span>
                              </span>
                              <span class="view">
                                <i class="fa fa-eye"></i>
                              </span>
                            </li>
                          </ul>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="or_separator_circle d-flex align-items-center justify-content-center"
            >
              <div class="or_separator">
                <span class="sep_text">OR</span>
              </div>
            </div>

            <div class="add_source_box m-auto last_child">
              <div class="add_box_inner">
                <h3>Upload your OPML file</h3>
                <div class="rss_upload">
                  <div class="rss_upload_inner">
                    <span class="input_file_name">
                      <template v-if="fileName">{{ fileName }}</template>
                      <template v-else>No file selected</template>
                    </span>

                    <label
                      for="rss_upload"
                      class="rss_upload_label"
                      :class="{ selected_file: fileName }"
                    >
                      <i class="cs-upload-arrow upload_icon"></i>
                    </label>
                    <input
                      id="rss_upload"
                      :accept="'application/xml, text/xml, text/x-opml, .opml'"
                      style="display: none"
                      class="hide"
                      type="file"
                      @change.prevent="uploadSourcesFile($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Source Preview for the content.-->
          <transition>
              <div
                v-if="
                  ((getSources.preview_url || getSources.preview_rss_url) &&
                    getSources.posts.length > 0) ||
                  getSourcesLoader.preview
                "
                class="source_feed_list"
              >
                <h3 v-if="getSources.preview_url"
                  >Source Feed Preview ({{ getSources.preview_url }})</h3
                >
                <h3 v-else-if="getSources.preview_rss_url"
                  >Source Feed Preview ({{ getSources.preview_rss_url }})</h3
                >
                <div class="source_feed_items">
                  <template v-if="getSourcesLoader.preview">
                    <beat-loader :color="'#436aff'"></beat-loader>
                  </template>
                  <template v-else>
                    <div
                      v-if="getSources.posts.length > 0"
                      class="source_feed_scroll"
                    >
                      <div
                        v-for="post in getSources.posts"
                        class="article_box_grid source_box_grid"
                      >
                        <div class="box_inner">
                          <div v-if="post.image" class="image_block">
                            <div
                              class="img"
                              :style="{
                                'background-image': 'url(' + post.image + ')',
                              }"
                            ></div>
                          </div>
                          <div v-else class="image_block">
                            <div
                              class="img"
                              style="
                                background-image: url('https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png');
                              "
                            ></div>
                          </div>
                          <div class="content_block">
                            <h2 v-html="post.title"></h2>
                            <div class="detail clear">
                              <div
                                class="author_detail d-flex align-items-center"
                              >
                                <p
                                  ><a target="_blank" :href="post.link">{{
                                    extractHostname(post.link)
                                  }}</a>
                                </p>
                                <p
                                  v-if="post.published"
                                  class="ml-auto"
                                  v-html="getPostDate(post.published)"
                                ></p>
                              </div>
                            </div>
                            <p class="desc" v-html="post.summary"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <!-- Feed is from the non rss type-->
              <div
                v-else-if="
                  getSources.is_non_rss &&
                  getSources.blog_checked === false &&
                  getSources.preview_state != 'invalid'
                "
                class="source_feed_list"
              >
                <div class="center_content">
                  <h3 v-if="getSources.is_non_rss"
                    >Source Feed Preview ({{ getSources.preview_rss_url }})</h3
                  >
                  <p
                    >We are not able to find an RSS feed for this domain. Our
                    system can try direct crawling, would you like to go
                    ahead?</p
                  >
                  <button
                    :disabled="getSourcesLoader.check_blog"
                    class="blue_gradient btn large_btn btn_loader loader_right yes_btn"
                    @click.prevent="verifyBlogSource"
                  >
                    <span>Yes</span>
                    <clip-loader
                      v-if="getSourcesLoader.check_blog"
                      :size="'14px'"
                      :color="'#ffffff'"
                      style="height: 0.875rem; margin-right: 4px"
                    ></clip-loader>
                  </button>
                  <button
                    :disabled="getSourcesLoader.check_blog"
                    class="red_gradient btn large_btn"
                    @click.prevent="noOptionSelected"
                  >
                    <span>No</span>
                  </button>
                </div>
              </div>
              <div
                v-else-if="
                  getSources.blog_url.length > 3 && getSources.blog_checked
                "
                class="source_feed_list"
              >
                <div class="center_content">
                  <h3 v-if="getSources.isNonRSS"
                    >Source Feed Preview ({{ getSources.previewRSSURL }})</h3
                  >
                  <p class="text-center"
                    >This source has been added in our database, our crawlers
                    will start fetching content from this source in a few hours.
                  </p>
                </div>
              </div>

              <div
                v-else-if="
                  getSources.blog_url === '' && getSources.blog_checked
                "
                class="source_feed_list"
              >
                <h3 v-if="getSources.isNonRSS"
                  >Source Feed Preview ({{ getSources.previewRSSURL }})</h3
                >
                <p
                  >The domain you provided does not have a blog URL. Please try
                  changing your domain URL</p
                >
              </div>
          </transition>
          <!-- if the rss feed has posts or non rss type -->
          <transition>
            <div
              v-show="
                getSources.posts.length > 0 ||
                getSources.isNonRSS ||
                sourceFile
              "
              :class="{
                source_feed_list:
                  getSources.posts.length <= 0 && !getSources.previewLoader,
              }"
              class="add_topic_feed pt-5"
            >
              <h3>Select Your Topics to Add RSS Feeds</h3>

              <template
                v-if="fileName && getSourcesTopics.topicsSelected.length == 0"
              >
                <div class="warning_box text-center custom_topics_warning">
                  <p
                    >Note: If you do not select any custom topics, your OPML
                    categories will be created for custom topics.</p
                  >
                </div>
              </template>
              <div v-if="getTopics.length > 0" class="add_topic_feed_inner">
                  <div
                    class="rss_dropdown_modal with_background prevent_close_dropdown d-inline-block dropdown default_style_dropdown checkbox_dropdown planner_filter_dd"
                  >
                    <div class="dropdown_header" data-toggle="dropdown">
                      <i class="fas fa-file-edit icon_first"></i>
                      <span class="text">Select Custom Topics</span>
                      <i class="dropdown_arrow icon_last cs-angle-down"></i>
                    </div>

                    <div
                      class="dropdown-menu dropdown-menu-right"
                      style="min-width: 200px;left: 7px; !important"
                    >
                      <ul class="inner">
                        <li v-for="topic in getTopics" class="list_item_li">
                          <div class="checkbox_input_image">
                            <input
                              :id="'select_topic_' + topic._id"
                              v-model="getSourcesTopics.topicsSelected"
                              :value="topic._id"
                              type="checkbox"
                            />
                            <label
                              :for="'select_topic_' + topic._id"
                              class="checkbox_right"
                            >
                              {{ topic.filters.topics.name }}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul class="selected_topics_for_sources">
                    <li v-for="topic in getSourcesTopics.topicsSelected">
                      {{ getNameOfTopic(topic) }}
                      <i
                        class="fa fa-times"
                        @click="removeSelectedSourcesTopic(topic)"
                      ></i>
                    </li>
                  </ul>

                <!-- <div class="input_field" >
                                     <label for="">Select Custom Topics</label>

                                     <option v-for="item in getTopics" :value="item">{{item.name}}</option>

                                 </div>-->

                <!--<div class="input_field">
                                    <label for="">Selected Topics</label>
                                    <div class="curated_topic_list select_topic_list">
                                        <div class="checkbox_input_image">
                                            <input type="checkbox" class="" id="topic.id"/>
                                            <label for="topic.id" class="checkbox_left">Sports</label>
                                        </div>
                                        <div class="checkbox_input_image">
                                            <input type="checkbox" class="" id="topic.id1"/>
                                            <label for="topic.id1" class="checkbox_left">games</label>
                                        </div>
                                    </div>
                                </div>-->
              </div>
              <div v-else class="add_topic_feed_inner text-center">
                <p style="font-size: 1rem" class="mb-3"
                  >You have not created any custom topics yet. Please create a
                  custom topic.</p
                >
                <button
                  class="gradient_btn btn large_btn"
                  @click.prevent="hideSourcesModal"
                >
                  <span>Create Custom Topic</span>
                </button>
              </div>
            </div>
          </transition>

          <!-- add source button -->
          <transition>
            <div
              v-show="
                getSources.posts.length > 0 || getSources.isNonRSS || sourceFile
              "
              class="btn_block"
            >
              <template v-if="sourceFile">
                <button
                  :disabled="sourceFileLoader"
                  class="btn gradient_btn large_btn btn_loader loader_right"
                  @click.prevent="addOpmlFile(sourceFile)"
                >
                  <span>Done</span>
                  <clip-loader
                    v-if="sourceFileLoader"
                    :size="'14px'"
                    :color="'#ffffff'"
                    style="height: 0.875rem; margin-right: 4px"
                  ></clip-loader>
                </button>
              </template>
              <template v-else>
                <button
                  :disabled="getSourcesLoader.button"
                  class="btn gradient_btn large_btn btn_loader loader_right"
                  @click.prevent="addIndividualSource"
                >
                  <span>Done</span>
                  <clip-loader
                    v-if="getSourcesLoader.button"
                    :size="'14px'"
                    :color="'#ffffff'"
                    style="height: 0.875rem; margin-right: 4px"
                  ></clip-loader>
                </button>
              </template>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters([
      'getTopics',
      'getWorkspaces',
      'getSourcesTopics',
      'getSources',
      'getSourcesLoader'
    ])
  },

  data () {
    return {
      fileType: '',
      fileName: '',
      sourceFile: '',
      sourceFileLoader: false
    }
  },

  created () {},
  mounted () {},

  methods: {
    ...mapMutations([
      'SET_SOURCES_RSS_CHANGE',
      'SET_SOURCES_RSS_CHANGE',
      'SET_SOURCES_SHOW_RSS_SUGGESTIONS',
      'SET_SOURCES_KEYWORD_SUGGESTION_LOADER',
      'SET_SOURCES_RSS_SUGGESTIONS_LIST',
      'SET_SOURCES_FEEDS_SUGGESTIONS_LIST',
      'SET_SOURCES_SELECTED_RSS_LINK'
    ]),
    ...mapActions([
      'addIndividualSource',
      'verifyBlogSource',
      'getSourcePreview',
      'noOptionSelected'
    ]),

    getPostDate (value) {
      return value.substring(0, 24)
    },
    getNameOfTopic (topicId) {
      const topic = this.getTopics.find((item) => item._id === topicId)
      console.log('getNameOfTopic > ', topic)
      return topic.filters.topics.name
    },
    removeSelectedSourcesTopic (topicId) {
      this.getSourcesTopics.topicsSelected.splice(
        this.getSourcesTopics.topicsSelected.indexOf(topicId),
        1
      )
    }
  },
  watch: {
    'getSources.previewRSSURL' (value) {
      this.SET_SOURCES_RSS_CHANGE(true)
    }
  }
}
</script>

<style scoped lang="less">
.selected_file {
  background: #1ecb7b !important;
}

.custom_topics_warning {
  width: 670px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}

.no_hover_effect {
  background: #f5f9fc !important;
  font-weight: bold !important;
}

.no_hover_effect:hover {
  background: #f5f9fc !important;
}

.selected_topics_for_sources {
  margin-top: 20px;
  li {
    margin-bottom: 0.625rem;
    display: inline-block;
    padding: 5px 0.625rem;
    color: #3a4557;
    margin-left: 0.625rem;
    /* background: #afe1ff; */
    border: 1px solid #4e92ff;
    background: #eaf1ff;
    border-radius: 4px;
    i {
      color: #bbc4ce;
      cursor: pointer;
    }
    i:hover {
      color: #a3acb5;
    }
  }
}
</style>
