<template>
  <ModalHeader :modal_heading="modal_heading" :modal-close="modalClose" :modal-shown="modalShown">
    <slot />
  </ModalHeader>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

// Components
import ModalHeader from '@common/components/modal-wrapper/ModalHeader.vue'

export default defineComponent({
  name: 'ModalWrapper',
  components: {
    ModalHeader,
  },
  props: {
    modal_heading: {
      type: String,
      default: '',
    },
    modalClose: {
      type: Function,
      default: () => {},
    },
    modalShown: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { root }) {},
})
</script>
