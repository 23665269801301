import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getAudienceGrowthSeries (audience) {
        for(let i = 1; i<audience.audience_growth.paid_follower_count.length; i++ ){
          if(audience.audience_growth.paid_follower_count[i] === 0){
            audience.audience_growth.paid_follower_count[i] = audience.audience_growth.paid_follower_count[i - 1]
          }
          if (audience.audience_growth.organic_follower_count[i] === 0){
            audience.audience_growth.organic_follower_count[i] = audience.audience_growth.organic_follower_count[i - 1]
          }
          if (audience.audience_growth.total_follower_count[i] === 0){
            audience.audience_growth.total_follower_count[i] = audience.audience_growth.total_follower_count[i - 1]
          }
        }
      return [
        {
          name: 'Paid Followers Count',
          data: audience?.audience_growth?.paid_follower_count,
          color: '#ffe202'
        },
        {
          name: 'Organic Followers Count',
          data: audience?.audience_growth?.organic_follower_count,
          color: '#2fe095'
        },
        {
          name: 'Total Followers Count',
          data: audience?.audience_growth?.total_follower_count,
          color: '#e02f5f'
        }
      ]
    },
    getEngagementSeries (engagement) {
      return [
        {
          name: 'Comments Count',
          data: engagement?.engagement?.comments,
          color: '#ffe202'
        },
        {
          name: 'Favorites Count',
          data: engagement?.engagement?.favorites,
          color: '#2fe095'
        },
        {
          name: 'Total Engagement',
          data: engagement?.engagement?.total_engagement,
          color: '#e02f5f'
        }
      ]
    },
    getDaysBuckets (posts_density) {
      return Object.keys(posts_density?.data?.days)
    },
    getPostsDensitySeries (posts_density) {
      return [
        {
          name: 'Posts',
          data: Object.values(posts_density?.data?.days),
          color: '#6173ff'
        }
      ]
    },
    getHashtagsSeries (data) {
      return [
        {
          name: 'Occurrences',
          type: 'wordcloud',
          data
        }
      ]
    }
  }
}
