<template>
  <div
      class="bg-white p-5 rounded-md w-full relative flex"
      :class="{ 'color-border cst-editor': !isModal }"
  >
  <div class="flex-1 " :class="customClass">
    <div class="mb-6 w-full flex items-center gap-4">
      <slot v-if="!isModal" name="card-header" :is-modal="isModal" />
      <slot name="modal-header" :is-modal="isModal" />

      <div class="ml-auto flex justify-center items-center">
        <div
            v-if="!isModal && enableModal && !isReportView"
            v-tooltip="{
            content: 'Maximize',
            theme: 'light',
          }"
            class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
            @click="showModal"
        >
          <img
              src="@src/assets/img/full_screen.svg"
              alt="fullScreen"
              class="w-5 h-5"
          />
        </div>
        <div
            v-if="isModal && !isReportView"
            v-tooltip="{
            content: 'Minimize',
            theme: 'light',
          }"
            class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
            @click="hideModal"
        >
          <i class="fa fa-times fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <slot name="card-body" :is-modal="isModal" :show-modal="showModal" :hide-modal="hideModal" />
    <b-modal
        :id="`analytics-wrapper-${type}-modal`"
        centered
        hide-footer
        hide-header
        content-class="mt-2 !rounded-none h-screen"
        dialog-class="cst-modal"
        @hide="commonMethods.toggleWidgets(false)"
        @shown="commonMethods.toggleWidgets(true)"
    >
      <AnalyticsCardWrapper
          :is-modal="true"
          :enable-modal="enableModal"
          :type="type"
          @toggle-is-modal="toggleIsModal"
      >
        <template v-slot:modal-header>
          <slot name="card-header" />
        </template>
        <template v-slot:card-body="slotProps">
          <slot name="card-body" :is-modal="slotProps.isModal" :show-modal="slotProps.showModal" :hide-modal="slotProps.hideModal" />
        </template>
      </AnalyticsCardWrapper>
    </b-modal>
  </div>
  <slot name="card-sidebar" :is-modal="isModal" />

  </div>
</template>

<script setup>
import { defineProps, defineEmits , inject } from 'vue'

import { commonMethods } from '@common/store/common-methods'
import useAnalytics from "@src/modules/analytics/components/common/composables/useAnalytics";

const emit = defineEmits(['toggleIsModal'])

const root = inject('root')
const { $bvModal } = root

const { isReportView } = useAnalytics()
const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  enableModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
  customClass: {
    type: String,
    default: '',
  },
})

const showModal = () => {
  $bvModal.show(`analytics-wrapper-${props.type}-modal`)
  toggleIsModal(true)
}

const hideModal = () => {
  $bvModal.hide(`analytics-wrapper-${props.type}-modal`)
  toggleIsModal(false)
}

const toggleIsModal = (value) => {
  emit('toggleIsModal', value)
}
</script>
