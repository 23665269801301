<template>
  <b-modal
    id="videoPreviewDialog"
    modal-class="video_preview normal_modal"
    hide-footer
    hide-header
    @hidden="onHideVideoPreviewModal"
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Video Preview</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('videoPreviewDialog')"
        >&times;
      </button>
    </div>

    <div class="modal_body">
      <p class="desc" v-html="getPreviewForVideo.title"></p>
      <!--<div class="video_box_iframe" v-if="getPreviewForVideo.source=='Facebook'">-->
      <!--&lt;!&ndash;<div v-html="getPreviewForVideo.url">   </div>&ndash;&gt;-->
      <!--<iframe :src="getPreviewForVideo.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>-->
      <!--</div>-->
      <!--<div v-else class="video_box_iframe">-->

      <!--</div>-->

      <div class="video_box_iframe">
        <iframe
          v-if="getPreviewForVideo.heightRatio"
          :height="getPreviewForVideo.heightRatio"
          :src="getPreviewForVideo.url"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
        <iframe
          v-else
          class="youtube_iframe"
          :src="getPreviewForVideo.url"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
      <div class="content">
        <p v-html="getPreviewForVideo.description"></p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'

const getDefaultVideoPreview = () => {
  return {
    title: '',
    description: '',
    url: '',
    source: '',
    height: null
  }
}
export default {
  data () {
    return {
      video_preview: getDefaultVideoPreview()
    }
  },
  computed: {
    getPreviewForVideo () {
      return this.video_preview
    }
  },
  beforeUnmount () {
    EventBus.$off('video-preview-dialog')
    console.log('video-preview-dialog off')
  },
  mounted () {
    EventBus.$on('video-preview-dialog', (res) => {
      this.video_preview = res
      console.log('video-preview-dialog fired')
    })
  },
  methods: {
    onHideVideoPreviewModal () {
      this.video_preview = getDefaultVideoPreview()
    }
  }
}
</script>
