<script>
import { mapGetters } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },

  created () {},
  computed: {
    ...mapGetters(['getPluginToken'])
  },
  mounted () {},

  methods: {
    hideModal () {
      this.$bvModal.hide('plugin_token_modal')
      return false
    }
  }
}
</script>

<template>
  <b-modal
    id="plugin_token_modal"
    modal-class="add_utm_modal normal_modal wordpress_api_blog_modal"
    class=""
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Your Blog API Key</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="hideModal"
        >&times;</button
      >
    </div>
    <div class="modal_body basic_form m_t_15">
      <div class="row">
        <div class="col-md-12 col-sm-12 input_field no_margin">
          <label for="">Key</label>
          <input
            v-model="getPluginToken"
            type="text"
            placeholder="Enter your API key..."
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>
