<script setup>
import { onMounted, inject, reactive } from 'vue'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import CstButton from '@ui/Button/CstButton.vue'

const root = inject('root')
const { $bvModal } = root

const state = reactive({
  message: '',
  image: '',
  buttonText: '',
  buttonLink: '',
})
onMounted(() => {

  // pusher integration
  const pusher = pusherAuthSocketInbox()
  const channel = pusher?.subscribe('general-notification-channel')
  channel.bind('general-notification-event', (data) => {
    state.message = data?.message
    state.image = data?.image
    state.buttonText = data?.cta
    state.buttonLink = data?.cta_url
    $bvModal.show('banner-notification-modal')
  })
})
const handleButtonClick = () => {
  window.open(state.buttonLink, '_blank')
  $bvModal.hide('banner-notification-modal')
}

</script>

<template>
  <b-modal
    id="banner-notification-modal"
    centered
    hide-header
    hide-footer
    content-class="rounded-xl"
    body-class="!p-0"
  >
    <div class="relative p-2">
      <div class="absolute right-0.5 top-4">
        <img
          src="@src/assets/img/integration/cross_dark_gray.svg"
          class="w-6 h-6 mr-[12px] cursor-pointer"
          alt=""
          @click="$bvModal.hide('banner-notification-modal')"
        />
      </div>
      <div class="flex flex-col gap-2 min-w-[300px] min-h-[300px]">
        <img
          v-if="state.image"
          class="w-full h-full object-cover object-center rounded-[10px]"
          :src="state.image"
          alt=""
        />
        <div v-if="state.message" class="text-center">
          <p class="text-[#2b2b2b] text-sm text-wrap font-bold">
            {{ state.message }}
          </p>
        </div>
        <div v-if="state.buttonText" class="!mt-auto">
          <CstButton
            variant="primary"
            class="w-full !rounded-[10px]"
            :text="state.buttonText"
            @click="handleButtonClick"
          >
            />
          </CstButton>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped></style>
