<template>
  <div class="relative" @click="handleBulkDropdown">
    <template v-if="externalPreview">
      <button
        class="text-[#757A8A] align-items-center !bg-[#F9F9F9] inline-flex gap-[0.5rem] text-[0.875rem] leading-4 !border border-solid font-weight-400 py-[0.67rem] px-[1rem] !border-[#D2D5DF] rounded-[0.3rem]"
        :class="{
          '!py-[0.295rem]': externalCampaign?.name,
          'mr-[0.5rem]': mode === 'planner',
        }"
      >
        <img
          v-tooltip="'Campaigns'"
          src="@src/assets/img/chat_bot/compaigns.svg"
          alt=""
          class="h-[1rem] w-[1rem]"
        />
        <template v-if="externalCampaign.name">
          <span
            class="px-3 py-1 rounded-md text-sm flex items-center gap-1 text-white text-nowrap"
            :style="getCampaignColor(externalCampaign?.color)"
            :v-tooltip="externalCampaign?.name.length > 22 ? externalCampaign.name : ''  "
          >
            {{
              externalCampaign?.name.length > 22
                ? externalCampaign?.name?.substring(0, 22) + '...'
                : externalCampaign?.name
            }}
          </span>
        </template>
        <span v-else>Campaign</span>
      </button>
    </template>
    <template v-else>
      <span v-if="mode === 'bulk-move'"> Campaign </span>

      <button
        v-else
        class="text-[#757A8A] align-items-center !bg-[#F9F9F9] inline-flex gap-[0.5rem] text-[0.875rem] leading-4 !border border-solid font-weight-400 py-[0.67rem] px-[0.5rem] 2xl:px-[1rem] !border-[#D2D5DF] rounded-[0.3rem]"
        :class="{
          '!py-[0.295rem]':
            selectedCampaign && selectedCampaign !== 'edit_default_folder' && selectedCampaign !== 'default',
          'mr-[0.5rem]': mode === 'planner',
        }"
        @click="showCampaignDropdown = !showCampaignDropdown"
      >
        <img
          v-tooltip="'Campaigns'"
          src="@src/assets/img/chat_bot/compaigns.svg"
          alt=""
          class="h-[1rem] w-[1rem]"
        />
        <template
          v-if="selectedCampaign && selectedCampaign !== 'edit_default_folder' && selectedCampaign !== 'default'"
        >
          <span
            class="px-3 py-1 rounded-md text-sm flex items-center gap-1 text-white text-nowrap"
            :style="getCampaignColor(getAttachedCampaign?.color)"
            v-tooltip="{
              content:  getAttachedCampaign?.name.length > (isSmallScreen ? 10 : 16)
                ? getAttachedCampaign?.name : ''
            }"
          >
            {{
              getAttachedCampaign?.name.length > (isSmallScreen ? 10 : 16)
                ? getAttachedCampaign?.name?.substring(0, isSmallScreen ? 10 : 16) + '...'
                : getAttachedCampaign?.name
            }}
          </span>
        </template>
        <span v-else class="text-nowrap">Campaign</span>
      </button>

      <div
        v-if="showCampaignDropdown"
        v-click-away="handleClickAway"
        class="absolute top-full mt-1 z-10"
        :class="getDropdownPosition"
      >
        <div
          class="w-80 bg-white rounded-xl shadow-md border border-gray-200 overflow-hidden transition duration-300 ease-in-out"
          @click="preventCLick"
        >
          <!-- Header -->
          <div
            class="flex justify-between items-center px-4 py-3 border border-b border-gray-100"
          >
            <!-- Add Campaign -->
            <div v-if="!isSearchActive" class="flex items-center gap-2">
              <span class="text-[#3A4557] font-medium text-base">Campaign</span>
              <img
                v-if="!showAddCampaign"
                v-tooltip="'Add a new Campaign'"
                src="@src/assets/img/common/add-circle.svg"
                class="h-5 w-5 cursor-pointer"
                alt="add campaign"
                @click.stop="showAddCampaign = true"
              />
              <img
                v-else
                v-tooltip="'Add a new Campaign'"
                src="@src/assets/img/common/add-circle-disabled.svg"
                class="h-5 w-5 cursor-pointer"
                alt="add campaign disabled"
              />
            </div>

            <div class="flex items-center gap-x-1">
              <!-- Search -->
              <div
                class="flex items-center bg-gray-100/70 rounded-md px-3 py-1"
                :class="{ 'w-full': isSearchActive }"
                @click.stop
              >
                <img
                  src="@src/assets/img/chat_bot/search-icon.svg"
                  class="h-4 w-4"
                  alt="search"
                />
                <input
                  v-model="searchQuery"
                  type="text"
                  class="bg-transparent w-20 ml-1 text-sm border-0 px-2 py-1 rounded"
                  :class="{ '!w-full': isSearchActive }"
                  placeholder="Search"
                  @focus="isSearchActive = true"
                />
                <img
                  v-show="isSearchActive"
                  src="@src/assets/img/common/circle-xmark.svg"
                  alt="xmark"
                  class="h-4 w-4 cursor-pointer"
                  @click.stop="resetSearch"
                />
              </div>

              <!-- Settings -->
              <div
                v-tooltip="'Manage and edit Campaigns here'"
                class="cursor-pointer font-normal text-gray-900 hover:text-primary-variant-1 transition-colors"
                @click="handleNavigation"
              >
                <i
                  class="icon-settings-cs h-9 w-9 text-xl text-center pt-1"
                ></i>
              </div>
            </div>
          </div>

          <!-- Add Campaign -->
          <div
            v-if="showAddCampaign"
            class="px-4 py-2 border border-b border-gray-100"
          >
            <div class="mb-3 relative">
              <input
                v-model="campaignName"
                type="text"
                placeholder="Type campaign name"
                class="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm"
                @click.stop
              />
              <img
                src="@src/assets/img/chat_bot/compaigns.svg"
                alt="campaign icon"
                class="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2"
              />
            </div>

            <div class="flex items-center justify-between mb-2">
              <div class="flex gap-2 relative mt-1 ml-2">
                <p class="text-sm font-medium text-gray-900 mb-2">Color</p>
                <img
                  v-click-away="hideColorPicker"
                  src="@src/assets/img/common/color-picker.svg"
                  class="h-5 w-5 cursor-pointer rounded-md p-0.5 border border-gray-400"
                  :style="{
                    backgroundColor: hexToRGBA(
                      LABELS_COLOR_MAP[selectedColor],
                      0.8
                    ),
                  }"
                  alt="color picker"
                  @click.stop="showColorPicker = !showColorPicker"
                />
                <div
                  v-if="showColorPicker"
                  class="absolute left-0 top-full bg-white rounded-xl w-72 p-1 grid grid-cols-10 shadow-sm gap-1 border border-gray-200 z-100"
                  @click.stop
                >
                  <div
                    v-for="color in Object.values(LABELS_COLOR_MAP)"
                    :key="color"
                    :class="`w-6 h-6 rounded-md border border-gray-200 relative cursor-pointer`"
                    :style="`background-color: ${hexToRGBA(color, 0.8)}`"
                    @click="selectColor(color)"
                  >
                    <img
                      v-if="color === LABELS_COLOR_MAP[selectedColor]"
                      src="@src/assets/img/chat_bot/tick.svg"
                      alt="selected"
                      class="absolute inset-0 m-auto w-3 h-3"
                    />
                  </div>
                </div>
              </div>

              <div class="flex justify-end gap-2 mr-2">
                <CstButton
                  size="small"
                  variant="text"
                  @click.stop="resetStates"
                >
                  Cancel
                </CstButton>
                <CstButton
                  size="small"
                  :disabled="!campaignName || !selectedColor || isLoading"
                  variant="primary"
                  @click.stop="createCampaign"
                >
                  Create
                </CstButton>
              </div>
            </div>
          </div>

          <!-- Unselect Campaign Option -->
          <div  class="flex justify-end items-center mr-6 mt-2" v-if="selectedCampaign && mode !== 'bulk-move'">
            <span
                class="text-sm text-cs-primary cursor-pointer"
                @click.stop="handleUnselect"
            >
              Unselect
            </span>
          </div>

          <!-- Campaigns -->
          <div class="overflow-y-auto my-1" :class="getDropdownHeight">
            <div
              v-if="!searchCampaigns.length && !searchQuery.length"
              class="flex items-center justify-center text-gray-700 my-4"
            >
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >No Campaign yet. Click</p
              >
              <img
                src="@src/assets/img/common/add-circle.svg"
                class="h-5 w-5 cursor-pointer mx-1"
                alt="add label"
                @click.stop="showAddCampaign = true"
              />
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >to create one.</p
              >
            </div>
            <!-- No Data Found  -->
            <div
              v-else-if="!searchCampaigns.length && searchQuery.length"
              class="flex items-center justify-center text-gray-700 my-4"
            >
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >No Campaigns found</p
              >
            </div>
            <div
              v-for="(campaign, index) in searchCampaigns"
              :key="index"
              class="flex flex-col"
            >
              <div class="flex justify-between items-center px-3 py-2 my-1"  @click.stop="campaignSelected = true">
                <CstRadio
                  :id="campaign._id"
                  v-model="selectedCampaign"
                  :value="campaign._id"
                >
                  <span
                    v-tooltip="campaign.name"
                    class="px-3 py-2 rounded-md text-sm text-white text-nowrap"
                    :style="getCampaignColor(campaign.color)"
                  >
                    {{
                      campaign?.name.length > 22
                        ? campaign?.name?.substring(0, 22) + '...'
                        : campaign?.name
                    }}
                  </span>
                </CstRadio>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div
            v-if="mode === 'bulk-move'"
            class="flex justify-end items-center px-4 py-3 gap-x-2 border border-t border-gray-100"
          >
            <CstButton size="small" variant="text" @click.stop="handleClose">
              Cancel
            </CstButton>
            <CstButton
              size="small"
              variant="primary"
              :disabled="!campaignSelected"
              @click.stop="handleSubmit"
            >
              {{ 'Save' }}
            </CstButton>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
// Core Imports
import {computed, defineEmits, defineProps, inject, onMounted, onUnmounted, ref} from 'vue'

// Other Imports
import CstButton from '@ui/Button/CstButton.vue'
import CstRadio from '@ui/Radio/CstRadio.vue'
import { useStore } from '@state/base'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { usePublicationFolder } from '@modules/composer_v2/composables/usePublicationFolder'
import { useRouter } from 'vue-router'
import { swalAttributes } from '@common/constants/common-attributes'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'

// Helpers
const emit = defineEmits([
  'update-attached-campaign',
  'handle-campaigns-changes',
  'bulk-action',
  'minimize-composer',
])
const store = useStore()
const router = useRouter()
const { LABELS_COLOR_MAP, hexToRGBA, getCampaignColor } = useLabelAndCampaign()
const { createPublicationFolder, movePostCampaign } = usePublicationFolder()
const root = inject('root')
const { $bvModal } = root
const { isDraftComposer } = useComposerHelper()
// Props
const props = defineProps({
  attachedCampaign: {
    type: String,
    default: '',
  },
  planId: {
    type: String,
    default: '',
  },
  mode: {
    type: String,
    default: 'planner',
  },
  externalCampaign: {
    type: Array,
    default: Object,
  },
  externalPreview: {
    type: Boolean,
    default: false,
  },
})

// Data
const showCampaignDropdown = ref(false)
const showAddCampaign = ref(false)
const selectedColor = ref('color_1')
const campaignName = ref('')
const showColorPicker = ref(false)
const searchQuery = ref('')
const campaigns = ref(store.getters?.getPublicationFolders || [])
const campaignSelected = ref(false)
const selectedCampaign = ref(props.attachedCampaign || '')
const isSearchActive = ref(false)
const isLoading = ref(false)
const isSmallHeight = ref(false)
const isSmallScreen = ref(false)

// Computed
const getAttachedCampaign = computed(() => {
  return campaigns.value?.find(
    (campaign) =>
      campaign._id === selectedCampaign.value &&
      campaign._id !== 'edit_default_folder'
  )
})

const searchCampaigns = computed(() => {
  const query = searchQuery.value.toLowerCase()
  return campaigns.value?.filter(
    (campaign) =>
      campaign._id !== 'edit_default_folder' &&
      campaign.name.toLowerCase().includes(query)
  )
})


// Get Dropdown position
const getDropdownPosition = computed(() => {
  switch (props.mode) {
    case 'planner':
      return 'right-2'
    case 'bulk-move':
      // dropdown should open on the right side of the button
      if(isSmallHeight.value) return 'left-full -top-20'
      return 'left-full -top-2'
    default:
      return 'right-0'
  }
})

const getDropdownHeight = computed(() => {
  if(props.mode !== 'bulk-move') return 'max-h-96'

  return isSmallHeight.value ? 'max-h-56' : 'max-h-80'
})

// Methods

// Small Screen
const checkSmallScreen = () => {
  return (isSmallScreen.value = window.innerWidth < 1530)
}

const checkSmallHeight = () => {
  return isSmallHeight.value = window.innerHeight < 710
}


// Lifecycle Hooks
onMounted(() => {
  checkSmallScreen()
  checkSmallHeight()
  window.addEventListener('resize', checkSmallScreen)
  window.addEventListener('resize', checkSmallHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkSmallScreen)
  window.removeEventListener('resize', checkSmallHeight)
})

const selectColor = (color) => {
  selectedColor.value = getObjectKey(color)
  showColorPicker.value = false
}

const resetStates = () => {
  showAddCampaign.value = false
  campaignName.value = ''
  selectedColor.value = 'color_1'

  if (props.mode === 'planner')
    selectedCampaign.value = props.attachedCampaign || ''
}

// Handle Navigation
const handleNavigation = async () => {
  if (props.mode === 'composer') {
    let res = false
    if (isDraftComposer) {
      const options = {
        ...swalAttributes(),
        modalClass:
          'sweet-alert-confirmation fade sweet-alert-minimize-composer',
        okVariant: 'primary',
        hideHeader: false,
      }
      res = await $bvModal.msgBoxConfirm(
        'Your post will be saved and minimized while you make changes in Settings. You can continue editing afterward',
        {
          title: '⚠️ Unfinished Post is in the Composer!',
          ...options,
          cancelTitle: 'Cancel',
          okTitle: 'Proceed',
        }
      )
    }
    if (res === null || res === false) {
      return
    }
    emit('minimize-composer')
  }
  await router.push({ name: 'miscellaneous', hash: 'campaigns' })
}

// Handle Submit
const handleSubmit = async () => {
  if (campaignSelected.value ) {
    isLoading.value = true
    if (props.mode === 'bulk-move') {
      emit('bulk-action', 'move')
      setTimeout(async () => {
        store.commit(composer.SET_MOVE_CAMPAIGN_ID, selectedCampaign.value)
        await movePostCampaign()
        handleClose()
      }, 300)
    } else {
      await attachCampaignToPlan()
    }
  }
  showCampaignDropdown.value = false
}

const handleBulkDropdown = () => {
  if (props.mode === 'bulk-move') {
    showCampaignDropdown.value = !showCampaignDropdown.value
  }
}

// Stop Propagation on click
const preventCLick = (e) => {
  if (props.mode === 'bulk-move') {
    e.stopPropagation()
  }
}

// Handle Click Away
const handleClickAway = () => {
  if (props.mode !== 'bulk-move') {
    handleSubmit()
  } else {
    handleClose()
  }
}

// Attach Campaign
const attachCampaignToPlan = async () => {
  // Logic for attaching campaign to plan
  if (props.mode === 'planner') {
    store.commit(composer.SET_MOVE_CAMPAIGN_ID, selectedCampaign.value)
    store.commit(composer.SET_MOVE_CAMPAIGN_PLAN_ID, [props.planId])
    await movePostCampaign()
    emit('update-attached-campaign', selectedCampaign.value)
    return
  }
  emit('handle-campaigns-changes', 'set', selectedCampaign.value)
}

const createCampaign = async () => {
  isLoading.value = true
  // Logic for creating a campaign
  if (campaignName.value && selectedColor) {
    const campaign = {
      name: campaignName.value,
      color: selectedColor.value,
    }
    store.commit(composer.SET_PUBLICATIONS_NEW_FOLDER, campaign)
    await createPublicationFolder()
    isLoading.value = false
    selectedCampaign.value = store.getters?.getPublicationFolders?.[0]?._id
    campaignSelected.value = true

    resetStates()
  }
}

const handleUnselect = () => {
  selectedCampaign.value = null
  campaignSelected.value = true
}

// Get key of object
const getObjectKey = (value) => {
  return Object.keys(LABELS_COLOR_MAP)?.find(
    (key) => LABELS_COLOR_MAP[key] === value
  )
}

// Handle Close
const handleClose = () => {
  showCampaignDropdown.value = false
  campaignSelected.value = false
  selectedCampaign.value = props.attachedCampaign || ''
  isLoading.value = false
  resetStates()
  resetSearch()
}

// Reset Search
const resetSearch = () => {
  searchQuery.value = ''
  isSearchActive.value = false
}

// Hide Color Picker
const hideColorPicker = () => {
  showColorPicker.value = false
}
</script>

<style scoped></style>
