<template>
  <div>
    <template v-if="loader">
      <clip-loader
        class="spinner mt-4 mb-3"
        :color="'#6d76ff'"
        :size="'20px'"
      ></clip-loader>
    </template>
    <template v-else>
      <div v-if="!(automations.length > 0)" class="text-center px-3 py-3">
        You have not created any automation yet.
      </div>
      <b-table
        v-else
        class="onboarding-team__table mt-1"
        thead-tr-class="onboarding-team__table-header"
        tbody-tr-class="onboarding-team__table-body"
        table-class="text-left"
        responsive
        borderless
        :items="automations"
        :fields="automationFields"
      >
        <template v-slot:cell(actions)="data">
          <button
            class="btn onboarding-team__trash"
            @click="removeWSAutomation(data.item)"
            ><i class="fas fa-trash"></i
          ></button>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
export default {
  name: 'AutomationTab',
  props: ['workspace_id'],
  data () {
    return {
      automationFields: [
        { key: 'name' },
        { key: 'type' },
        { key: 'actions', label: 'Action' }
      ],

      automations: [],
      loader: false
    }
  },
  mounted () {
    this.fetchCurrentWSAutomations()
  },
  methods: {
    ...mapActions(['fetchWSAutomations', 'removeSocialIntegration']),
    async fetchCurrentWSAutomations () {
      this.loader = true
      this.automations = await this.fetchWSAutomations({
        workspace_id: this.workspace_id
      })
      this.loader = false
    },
    async removeWSAutomation (automation) {
      console.debug('Method::removeWSAutomation', automation)
      const payload = {
        type: automation.type,
        id: automation.id,
        workspace_id: this.workspace_id,
        context: this
      }
      const context = this
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to remove automation.', {
          title: 'Remove Automation',
          ...swalAttributes()
        })
        .then(async (value) => {
          if (value) {
            const resp = await context.removeAutomationFromManageLimits(payload)
            console.log('I AM A FUCKING RESP .. ', resp)
            if (resp && resp.status) context.fetchCurrentWSAutomations()
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    }
  }
}
</script>
