<template>
  <div>
    <div
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <i class="trending_icon icon_first cs-trending-chart"></i>
      <span class="text" data-cy="influencer_list_filter_selected_text">
        <template v-if="$route.query && $route.query.list">{{
          $parent.getInfluencerListName($route.query.list)
        }}</template>
        <template v-else> Saved Lists </template>
      </span>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>
    <div class="dropdown-menu dropdown-menu-left">
      <ul class="inner" data-cy="influencer_list_filter">
        <li
          v-for="folder in getInfluencerFolders.data"
          class="list_item_li"
          :data-cy="'influencer_list_filter_' + folder._id"
          @click.prevent="selectInfluencerList(folder)"
        >
          <div class="radio_input_image">
            <input
              :id="folder._id"
              :data-cy="'influencer_list_filter_input_' + folder._id"
              :checked="checkSelectedList(folder._id)"
              :value="folder._id"
              type="radio"
            />
            <label
              :for="folder._id"
              class="radio_left"
              :data-cy="'influencer_list_filter_text_' + folder._id"
            >
              {{ folder.name }}
            </label>
          </div>
        </li>
        <li v-if="getInfluencerFolders.data.length === 0" class="no_text_li">
          No influencer list created yet, please create one.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  methods: {
    checkSelectedList (listId) {
      return this.$route.query && this.$route.query.list === listId
    },
    selectInfluencerList (list) {
      const value =
        this.$route.query && this.$route.query.list === list._id
          ? null
          : list._id
      if (value) {
        this.$router.push({ query: { ...this.$route.query, list: value } })
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.list
        this.$router.replace({ query })
      }
    }
  },
  computed: {
    ...mapGetters(['getInfluencerFilters', 'getInfluencerFolders'])
  },
  watch: {
    '$route.query.list' () {
      this.$parent.getInfluencers(this.$parent.checkInfluencerType)
    }
  }
}
</script>
