import usePermissions from '@/src/modules/common/composables/usePermissions'

export default function useApproval() {
  const { getUserRole } = usePermissions()

  /**
   * Fetches the approval status of a plan item
   * @param data
   * @param itemRequired
   * @param storeData
   * @returns {boolean|T}
   */
  const checkApprovalStatus = (
    data,
    itemRequired = false,
    storeData = null
  ) => {
    if (data?.approvers) {
      const approvalUser = data.approvers.find(
        (user) => user.user_id === storeData?.getters.getProfile._id
      )
      if (approvalUser && approvalUser.status === 'pending') {
        if (itemRequired) return approvalUser
        return true
      }
    }
    return false
  }

  const canEditPost = (item) => {
    if (item?.can_perform?.edit) {
      if (getUserRole.value !== 'Approver') return true
      if (
        item.approval &&
        item.approval.status === 'pending_approval' &&
        checkApprovalStatus(item.approval)
      )
        return true
    }
    return false
  }

  return {
    canEditPost,
    checkApprovalStatus,
  }
}
