<script setup>
import SidebarPost from '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/SidebarPost'
import useTiktokGridView from "@src/modules/planner_v2/composables/SocialMediaManager/Tiktok/useTiktokGridView";
import EmptyState from '@/src/modules/planner_v2/components/SocialMediaViewer/EmptyState'

// no need to filter by platform as it is already filtered in parent component
const {
  filteredItemsWithNoRemoteMedia,
  fetchingMoreItems,
} = useTiktokGridView()
</script>

<template>
  <template v-if="filteredItemsWithNoRemoteMedia?.length">
    <SidebarPost
        v-for="element in filteredItemsWithNoRemoteMedia"
        :key="element?._id"
        :item="element"
        :is-tiktok="true"
    />

    <div class="h-[6rem]">
      <clip-loader
          v-if="fetchingMoreItems"
          class="flex items-center spinner ml-2 mt-5"
          color="#e0dfdf"
          size="1.875rem"
      />
    </div>
  </template>

  <div v-else class="py-8">
    <EmptyState :compose="true" />
  </div>
</template>
