<template>
  <div class="topics-pane">
    <TopicsSidebar />
    <div class="topics-top-pane force-white-bg">
      <div class="topics-top-pane__inner">
        <div class="topics-top-pane__inner__left_section">
          <div class="topics-top-pane__inner__left_section__inner">
            <h3>Custom Topic</h3>
            <h2 v-if="custom_topic.filters">{{
              custom_topic.filters.topics.name
            }}</h2>
          </div>
        </div>
        <div class="topics-top-pane__inner__right_section">
          <div class="topics-top-pane__inner__right_section__content_types">
            <div
              class="topics-top-pane__inner__right_section__content_types__buttons"
            >
              <router-link
                :to="{
                  name: 'discover_topics_custom_web_content',
                  params: { topic_id: $route.params.topic_id },
                }"
              >
                <i class="icon-Content-Feed_icon"></i>
                <span>Content Feed</span>
              </router-link>
              <router-link
                href="javascript:;"
                :class="{
                  active:
                    $route.name ===
                    'discover_topics_custom_web_insights_review',
                }"
                :to="{
                  name: 'discover_topics_custom_web_insights',
                  params: { topic_id: $route.params.topic_id },
                }"
              >
                <i class="icon-Insights_icon"></i>
                <span>Insights</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <div class="topics-filters-pane__container__left_section" />
        <CuratedAndCustomInsightsDatePicker
          :date="getCustomTopicDateRange"
        />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import moment from 'moment'
import TopicsFilterResults from '../../sub-components/TopicsFilterResults'
import TopicsSidebar from '../../sub-components/TopicsSidebar'
import CuratedAndCustomInsightsDatePicker from '../../channel-filters/CuratedAndCustomInsightsDatePicker'

export default {
  components: {
    TopicsSidebar,
    TopicsFilterResults,
    CuratedAndCustomInsightsDatePicker
  },
  data () {
    return {
      total_results: 0,
      custom_topic: {}
    }
  },
  computed: {
    getCustomTopicDateRange () {
      if (this.custom_topic.filters) {
        return this.custom_topic.filters.topics.date.value
      }
      return ''
    }
  },
  watch: {
    '$route.params.topic_id' (value) {
      this.custom_topic = {}
      this.fetchCustomTopic()
    }
  },
  created () {
    this.fetchCustomTopic()
  },
  methods: {
    fetchCustomTopic () {
      proxy
        .post(`${discoveryBaseUrl}v2/topics/retrieve_custom_topic_by_id`, {
          workspace_id: this.$store.getters.getActiveWorkspace._id,
          topic_id: this.$route.params.topic_id
        })
        .then((res) => {
          if (res.data.data) {
            this.custom_topic = res.data.data
          }
        })
        .catch((err) => {})
    }
  }
}
</script>
