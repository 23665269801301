<template>
  <div class="helper_main_block">
    <div class="d-flex align-items-center clear">
      <h2 class="title">Helper</h2>
    </div>
    <div class="helper_progres_outer">
      <HelperProgress />
      <HelperContent :blog-post="blogPost" :emotions="emotions" :heading-count="headingCount" :media-count="mediaCount" :sentiments="sentiments" :read-time="readTime" />
      <HelperTabs :keywords="keywords" :questions="questions" :topics="topics" />
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import {seoPredictionUrl} from "../../../../modules/publish/config/api-utils"
import HelperTabs from './HelperTabs.vue'
import HelperProgress from './HelperProgress.vue'
import HelperContent from './HelperContent.vue'
export default {
  components: {
    HelperTabs,
    HelperProgress,
    HelperContent,
  },
  data(){
  return{
   emotions:"",
   keywords:[],
   sentiments:"",
   headingCount:0,
   mediaCount:0,
   questions:[],
   readTime:0,
   topics:[],
  }
  },
  created() {
  const title=this.getTitle();
   if(title) this.getPredictions(title)
    },
  computed: {
    ...mapGetters([
      'getBlogPostingHTML',
      'getWordsCount'
    ]),
    blogPost(){
      this.getHeadings(this.getBlogPostingHTML)
       return this.stripHtml(this.getBlogPostingHTML)
    }
  },
 watch: {
    'blogPost'(n,o){
     if(n){
      this.getPredictions(n)
     }else{
       this.emotions="",
       this.keywords=[],
       this.sentiments="",
       this.questions=[],
       this.readTime=0,
       this.topics=[]
     }
    }
 },

  methods:{
    getTitle(){
      this.getHeadings(this.getBlogPostingHTML);
       return this.stripHtml(this.getBlogPostingHTML)
    },
    getPredictions: _.debounce(
     async function(title) {
     const response = await axios.get(
        seoPredictionUrl+title
        )
        if(response.status===200){
          const {emotions,keywords,sentiments,readtime}=response.data.prediction
           this.questions=response.data.questions.questions
           this.readTime=readtime
          this.emotions=Object.keys(emotions).reduce(function(a, b){ return emotions[a] > emotions[b] ? a : b }),
          this.keywords=keywords,
          this.sentiments=Object.keys(sentiments).reduce(function(a, b){ return sentiments[a] > sentiments[b] ? a : b }),
          this.topics=response.data.topics.concepts
        }
       return response
      }, 500),
      /// / fetch headings and media tagg from html /////
  getHeadings(innerHtml) {
  const parsed = new DOMParser().parseFromString(innerHtml, 'text/html');
  const headings = parsed.querySelectorAll('h1,h2,h3,h4,h5,h6');
  const mediaTag = parsed.querySelectorAll('img,video,iframe');
  this.headingCount=headings.length;
  this.mediaCount=mediaTag.length
    },
  stripHtml(html) {
      // Create a new div element
      const temporalDivElement = document.createElement('div')
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html
      // Retrieve the text property of the element (cross-browser support)
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ''
      )
    },

  },

}
</script>

<style lang="less">
.helper_main_block {
  height: 100%;
  overflow: auto;
}
</style>
