<template>
  <div
    v-if="post._source"
    class="article-list-view-container__inner"
    :class="{ shared: post._source.shared }"
  >
    <div class="article-list-view-container__inner__image_section">
      <div
        v-if="post._source.categories"
        class="article-list-view-container__inner__categories"
      >
        <span
          v-for="category in post._source.categories"
          class="tag capitalize"
          >{{ category }}</span
        >
      </div>
      <div class="article-list-view-container__inner__article_type">
        <span v-if="post._source.read_time && post._source.read_time == 1">
          Less than 1 Min. Read
        </span>
        <span v-if="post._source.read_time && post._source.read_time > 1">
          {{ post._source.read_time }} Min. Read
        </span>
        - <span v-if="post._source.has_abstract">Abstract Content</span>
        <span v-else>Full Content</span>
      </div>

      <div
        :style="{
          backgroundImage: `url('${getResizedImageURL(
            backgroundImageURL(post._source),
            305,
            204.84
          )}')`,
        }"
        class="article-list-view-container__inner__image"
      ></div>

      <div
        v-if="
          post._source.shared &&
          post._source.plan_status &&
          post._source.plan_status === 'scheduled'
        "
        class="article-grid-view-container__inner__shared"
      >
        <span>
          Scheduled On
          <b>
            {{
              getWorkspaceTimeZoneTime(
                post._source['execution_time']['date'],
                'MMM DD YYYY, hh:mm A'
              )
            }}
          </b>
        </span>
      </div>

      <div
        v-else-if="post._source.shared && post._source.shared_count"
        class="article-grid-view-container__inner__shared"
      >
        <span v-if="post._source.plan_status === 'draft'">
          Drafted on
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>

        <span v-else-if="post._source.plan_status === 'queued'">
          Queued on
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>

        <span v-else>
          Shared to
          <b
            >{{ post._source.shared_count }}
            <template v-if="post._source.shared_count === 1"
              >Social Media Channel</template
            >
            <template v-else> Social Media Channels</template>
          </b>
        </span>
      </div>
    </div>
    <div class="article-list-view-container__inner__content_section">
      <div
        class="
          article-list-view-container__inner__content_section__left_section
        "
      >
        <h2
          :title="limitTextLength(post._source.title, 0)"
          @click.prevent="searchWebPreview"
          v-html="limitTextLength(post._source.title, 100)"
        ></h2>
        <div
          class="
            clear
            article-list-view-container__inner__content_section__left_section__details
          "
        >
          <p class="text_row">
            <a
              v-tooltip="'View articles from this domain'"
              href="javascript:;"
              @click.prevent="
                changeRouteByDomain(trimURL(post._source.domain_url))
              "
              >{{ trimURL(post._source.domain_url) }}</a
            >
            <!--<a class="left_link" href="">Insight</a> <a class="left_link" href="">All Articles</a>
                        <a class="left_link" href="">Block Globally</a>-->
          </p>
          <p class="text_row">{{
            $filters.relative(post._source.post_date)
          }}</p>
          <p
            class="
              article-list-view-container__inner__content_section__left_section__author_information
            "
          >
            <template v-if="post._source.author">
              By
              <span
                v-tooltip="'View articles from this author'"
                style="color: #4e92ff"
                @click="
                  changeRouteForAuthor(
                    post._source.author,
                    trimURL(post._source.domain_url)
                  )
                "
                v-html="post._source.author"
              ></span>
              <template v-if="post._source.twitter_accounts">
                <a
                  v-for="(account, index) in post._source.twitter_accounts"
                  @click.prevent="
                    callGetInfluencerPreviewDiscoveryRedirect(account)
                  "
                >
                  <span v-if="index < 1">{{ account }}</span></a
                >
              </template>

              <a
                v-else-if="post._source.twitter_handler"
                target="_blank"
                :href="getTwitterLink(post._source.twitter_handler)"
              >
                {{ post._source.twitter_handler }}</a
              >
              <clip-loader
                v-if="loader"
                class="d-inline-block ml-1"
                :color="'#436aff'"
                :size="'12px'"
              ></clip-loader>
            </template>
            <template v-else-if="post._source.twitter_handler">
              By
              <a
                target="_blank"
                :href="getTwitterLink(post._source.twitter_handler)"
                >{{ post._source.twitter_handler }}</a
              >
            </template>
            <template
              v-else-if="
                post._source.twitter_accounts &&
                post._source.twitter_accounts.length > 0
              "
            >
              By
              <a
                v-for="(account, index) in post._source.twitter_accounts"
                @click.prevent="
                  callGetInfluencerPreviewDiscoveryRedirect(account)
                "
                ><span v-if="index < 1">{{ account }}</span></a
              >
              <clip-loader
                v-if="loader"
                class="d-inline-block ml-1"
                :color="'#436aff'"
                :size="'12px'"
              ></clip-loader>
            </template>
          </p>
          <div
            class="
              article-list-view-container__inner__content_section__left_section__topics
            "
          >
            <template v-for="item in post._source.topics">
              <template v-if="isTopicsDisplayAllowed(post)">
                <span
                  v-for="finalized_topic in checkIfMultipleTopics(item.label)"
                  class="topic_tag"
                  @click.prevent="changeTopicRouteLabel(item, finalized_topic)"
                >
                  {{ finalized_topic }}
                </span>
              </template>
            </template>
          </div>

          <div
            v-if="post._source.top_twitter_influencers"
            class="article-list-view-top-influencers"
          >
            <div
              v-if="post._source.total_influencers_shares"
              class="article-list-view-top-influencers__left"
            >
              <p>
                <i class="icon-profile-cs mr-2"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(post._source.total_influencers_shares)
                }}</strong>
                influencers</p
              >
            </div>
            <!--                      v-if="influencer.follower_count > 1000"-->
            <!--                      v-if="index <= 3"-->
            <!--                      @click.prevent="influencersWebPreview()"-->
            <div v-if="post._source.total_influencers_shares > 0" class="ml-3">
              <template
                v-for="(influencer, index) in post._source
                  .top_twitter_influencers"
              >
                <a
                  v-tooltip="{
                    content: accountTooltipHtml('influencer', influencer),
                    classes: 'team_tooltip',
                  }"
                  target="_blank"
                  :href="'https://twitter.com/' + influencer.screen_name"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      'background-image': getBackgroundURLWithDefault(
                        influencer.profile_image
                      ),
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
              <!--                            <div @click.prevent="influencersWebPreview()" class="rounded_more"-->
              <!--                                 v-if="post._source.top_twitter_influencers.length > 3 && post._source.total_influencers_shares - 4 > 0">-->
              <!--                                +{{post._source.total_influencers_shares - 4}}-->
              <!--                            </div>-->
            </div>
          </div>
        </div>
        <!--<div class="similar_content"
                     @click.prevent="retrieveSimilarArticles(post._source.id, post._index)"
                     v-if="post._source.related_ids_count > 1">{{post._source.related_ids_count}}
                    similar articles
                </div>-->
      </div>
      <div
        class="
          article-list-view-container__inner__content_section__right_section
        "
      >
        <div
          class="
            article-list-view-container__inner__content_section__right_section__signals
          "
        >
          <div
            class="
              article-list-view-container__inner__content_section__right_section__signals__inner
            "
          >
            <div
              class="
                popover-list-view-social-statistics
                article-list-view-container__inner__content_section__right_section__signals__inner__item
              "
            >
              <div class="name">
                <p>Engagement</p>
              </div>
              <div class="value">
                <h3>{{ intToString(calculateEngagements(post._source)) }}</h3>
              </div>
              <div
                class="
                  popover-list-view-social-statistics__container
                  top_center
                "
              >
                <div
                  class="popover-list-view-social-statistics__container__box"
                >
                  <div
                    class="
                      popover-list-view-social-statistics__container__box__inner
                    "
                  >
                    <div class="d-flex align-items-center">
                      <div
                        v-tooltip.top="'Facebook Shares'"
                        class="
                          popover-list-view-social-statistics__container__box__inner__item
                        "
                      >
                        <span class="icon_item">
                          <i class="fb cs-facebook"></i>
                        </span>
                        <span v-if="post._source.facebook_shares">{{
                          post._source.facebook_shares
                        }}</span>
                        <span v-else>0</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="post._source.velocity && post._source.velocity > 5"
              class="
                article-list-view-container__inner__content_section__right_section__signals__inner__item
              "
            >
              <div class="name">
                <p>Trending Score</p>
              </div>
              <div class="value">
                <h3>{{ intToString(post._source.velocity) }}</h3>
              </div>
            </div>
            <SentimentIcon
              :post="post"
              :class="'article-list-view-container__inner__content_section__right_section__signals__inner__item'"
            ></SentimentIcon>
          </div>
        </div>
        <div
          class="
            article-list-view-container__inner__content_section__right_section__action_buttons
          "
        >
          <button
            id="dropdown-archive-articles"
            class="btn btn-studio-icon-theme post_archi_btn"
            @click.prevent="
              initializeArticlePostCreation(
                post._source.id,
                'social_share_modal'
              )
            "
          >
            <i
              v-tooltip="'Social Media Share'"
              class="far fa-share-alt icon"
            ></i>
          </button>

          <button
            id="dropdown-archive-articles"
            class="btn btn-studio-icon-theme post_archi_btn"
            @click.prevent="
              initializeArticlePostCreation(post._source.id, 'blog_share_modal')
            "
          >
            <i v-tooltip="'Blog Post share'" class="far fa-rss"></i>
          </button>

          <!--&lt;!&ndash; share buttons &ndash;&gt;-->
          <!--<b-dropdown  variant="studio-icon-theme" id="dropdown-share-articles" dropleft class="studio-icon-theme-dropdown mr-3" no-caret>-->
          <!--<template v-slot:button-content>-->
          <!--<i v-tooltip="'Share this post'" class="far fa-share-alt icon"></i>-->
          <!--</template>-->
          <!--<b-dropdown-item-->
          <!--variant="studio-icon-theme"-->
          <!--@click.prevent="initializeArticlePostCreation(post._source.id,'social_share_modal')"-->
          <!--&gt;<i class="far fa-share share-ico-align"></i> Social Media-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-item-->
          <!--variant="studio-icon-theme"-->
          <!--@click.prevent="initializeArticlePostCreation(post._source.id,'blog_share_modal')"-->
          <!--&gt;<i class="far fa-rss"></i> Blog Post-->
          <!--</b-dropdown-item>-->
          <!--</b-dropdown>-->

          <!--&lt;!&ndash; favorite article button &ndash;&gt;-->

          <b-dropdown
            id="dropdown-favorite-articles"
            ref="dropdown_favorite"
            variant="studio-icon-theme"
            dropleft
            class="studio-icon-theme-dropdown mr-3"
            no-caret
            block
          >
            <template v-if="post._source.is_favorite" v-slot:button-content>
              <i
                v-tooltip="'Unfavorite'"
                class="far fa-heart icon is-favorite"
                @click="unfavoritePostItemEventFire(post._source.id)"
              ></i>
              <span
                :class="{ ' active': post._source.is_active }"
                class="icon_animate"
              ></span>
            </template>
            <template v-else v-slot:button-content>
              <i
                v-tooltip="'Favorite'"
                class="far fa-heart icon"
                :class="{ 'animated tada': post._source.is_active }"
              ></i>
            </template>
            <BDropdownFavoriteArticle
              v-show="!post._source.is_favorite"
              :post_id="post._source.id"
            />
          </b-dropdown>

          <!-- archive post button -->
          <!--                  <b-dropdown variant="studio-icon-theme" id="dropdown-archive-articles" dropleft class="studio-icon-theme-dropdown mr-3" no-caret block-->
          <!--                  >-->
          <!--                    <template v-slot:button-content>-->
          <!--                      <i @click="archivePostItemEventFire(post._source.id, 'archive')" v-tooltip="'Archive Post'" class="far fa-archive icon"></i>-->
          <!--                    </template>-->
          <!--                  </b-dropdown>-->

          <template
            v-if="
              getAllAutomations.evergreen &&
              getAllAutomations.evergreen.length > 0
            "
          >
            <b-dropdown
              id="dropdown-actions-evergreen"
              variant="studio-icon-theme"
              dropleft
              class="studio-icon-theme-dropdown mr-3"
              no-caret
            >
              <template v-slot:button-content>
                <i
                  v-tooltip="'Add post to Evergreen Automation'"
                  class="far fa-robot icon"
                ></i>
              </template>

              <b-dropdown-item
                v-for="(automation, key) in getAllAutomations.evergreen"
                :key="key"
                variant="studio-icon-theme"
                @click="addInEvergreenAutomation(post._source, automation._id)"
              >
                {{ automation.name }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-else>
            <button
              id="dropdown-archive-articles"
              class="btn btn-studio-icon-theme post_archi_btn"
              style=""
              @click="archivePostItemEventFire(post._source.id, 'archive')"
            >
              <i v-tooltip="'Archive Post'" class="far fa-archive icon"></i>
            </button>
          </template>

          <!-- more actions button-->
          <b-dropdown
            id="dropdown-actions-articles"
            variant="studio-icon-theme"
            dropleft
            class="studio-icon-theme-dropdown mr-3"
            no-caret
          >
            <template v-slot:button-content>
              <i class="cs-dots-h icon"></i>
            </template>

            <template
              v-if="
                getAllAutomations.evergreen &&
                getAllAutomations.evergreen.length > 0
              "
            >
              <b-dropdown-item
                variant="studio-icon-theme"
                @click.prevent="
                  archivePostItemEventFire(post._source.id, 'archive')
                "
                ><i class="far fa-archive"></i> Archive Post
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              variant="studio-icon-theme"
              target="_blank"
              :href="savePocketContent(post._source.url)"
              ><i class="fab fa-get-pocket"></i> Add to Pocket
            </b-dropdown-item>

            <b-dropdown-item
              variant="studio-icon-theme"
              @click.prevent="influencersWebPreview"
              ><i class="fa fa-users-crown"></i> View Influencers
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                getTopicType === 'archived' &&
                $route.params &&
                $route.params.module === 'archived'
              "
              variant="studio-icon-theme"
              @click.prevent="
                unarchive({ post_id: post._source.id, index: index })
              "
              ><i class="far fa-archive"></i> Unarchive
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'
import SharedCountTooltip from '../snippets/SharedCountTooltip'
import SentimentIcon from '../snippets/SentimentIcon'

export default {
  components: {
    SharedCountTooltip,
    SentimentIcon,
    BDropdownFavoriteArticle,
  },
  props: {
    post: {},
    index: {},
  },
  data() {
    return {
      loader: false,
    }
  },
  computed: {
    ...mapGetters(['getAllAutomations']),
  },
  methods: {
    ...mapActions(['archive', 'unarchive', 'articlePreview']),
    changeTopicRouteLabel(item, tag) {
      if (this.$route.name === 'discover_search_web') {
        this.$router.push({
          name: this.$route.name,
          query: { tag, view: 'list' },
        })
      }
    },
    changeRouteForAuthor(author, domain) {
      // this.$router.push({ name: this.$route.name, query: { q: `author:${author} AND site:${domain}`, view: 'list' } })
      this.$router.push({
        name: this.$route.name,
        query: { q: `author:'${author}'` },
      })
    },
    changeRouteByDomain(domain) {
      this.$router.push({
        name: this.$route.name,
        query: { q: `site:${domain}`, view: 'list' },
      })
    },
    searchWebPreview() {
      const payload = {
        name: `${this.$route.name}_item_preview`,
        params: { post_id: this.post._source.id },
        query: { module: 'search', ...this.$route.query },
      }
      console.log(payload)
      this.$router.push(payload)
    },
    influencersWebPreview() {
      const payload = {
        name: `${this.$route.name}_item_influencers`,
        params: { post_id: this.post._source.id },
        query: { module: 'search', ...this.$route.query },
      }
      console.log(payload)
      this.$router.push(payload)
    },
    archivePostItemEventFire(postId, state = 'archive') {
      EventBus.$emit('archive-post-item', { postId, state })
    },
    unfavoritePostItemEventFire(postId) {
      EventBus.$emit('unfavorite-post-item', { postId })
    },
    async callGetInfluencerPreviewDiscoveryRedirect(account) {
      this.loader = true
      await this.$parent.getInfluencerPreviewDiscoveryRedirect(account)
      this.loader = false
    },
    addInEvergreenAutomation(post, automationId) {
      console.debug('before emit')
      EventBus.$emit('add-evergreen-post', {
        postId: post.id,
        post,
        automation_id: automationId,
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
