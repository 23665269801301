<template>
  <div id="pasteFromWord" class="modal fade normal_modal pasteFromWord">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Paste from Doc</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form">
          <div class="input_field mt-0">
            <label for=""
              >Paste your content here
              <span style="font-size: 13px">
                (It will convert to plain HTML)</span
              ></label
            >
            <div id="wordContentID" contenteditable="true" class="text"></div>
          </div>
          <div class="input_field">
            <button class="btn gradient_btn" @click="pasteToEditor">
              <span>Add to editor</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },

  created () {},
  computed: {
    ...mapGetters(['getBlogEditorSelector'])
  },
  mounted () {},

  methods: {}
}
</script>
