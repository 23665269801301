import { mapActions, mapGetters } from 'vuex'
import { article } from '@src/modules/automation/store/recipes/mutation-types'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import momentJS from 'moment'
// import bootstrapDatepicker from 'bootstrap-daterangepicker'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export const dateRangePickerMixin = {
  filters: {
    date(value) {
      if (!value) {
        return ''
      }
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
    onlyDate(value) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },

    publishedDate(value) {
      value = momentJS(value).toDate()
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        weekday: 'long',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
    publishedDateWithShortMonth(value) {
      value = momentJS(value).toDate()
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        weekday: 'long',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
    publishedOnlyDate(value) {
      value = momentJS(value).toDate()
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
    publishedOnlyDateWithShortWeekday(value) {
      value = momentJS(value).toDate()
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
    publishedOnlyDateWithShortWeekdayAndMonth(dateTime) {
      return moment(dateTime).format('ddd,MMM DD,YYYY')
    },
    publishedOnlyDateWithWeekdayAndMonth(value) {
      value = momentJS(value).toDate()
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      }
      return Intl.DateTimeFormat('en-US', options).format(value)
    },
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPublishTimeOptions',
      'getArticleAutomationDetails',
      'getBlogSelection',
    ]),
  },

  methods: {
    ...mapActions([
      'setAutomationStartTime',
      'setAutomationEndTime',
      'setPublishingPostDate',
    ]),
    getDatePickerLabel(startDate, endDate) {
      const endDateTime = moment(endDate).format('YYYY-MM-DD')
      const todaysDate = moment().format('YYYY-MM-DD')
      console.log(todaysDate, endDateTime)
      if (todaysDate === endDateTime) {
        console.log('getDatePickerLabel > ', endDate)
        const start = moment.unix(Date.parse(startDate) / 1000)
        const end = moment.unix(Date.parse(endDate) / 1000)
        const days = end.diff(start, 'days')
        return this.getLabelValueFromDay(days)
      } else {
        return 'Custom'
      }
    },
    getSearchDatePicker(startDate, endDate) {
      console.log(startDate, endDate)
      const start = moment.unix(Date.parse(startDate) / 1000)
      const end = moment.unix(Date.parse(endDate) / 1000)
      const days = end.diff(start, 'days')
      console.log(days)
      if (days === 0) {
        return false
      }
      return this.getLabelValueFromDay(days)
    },

    getLabelValueFromDay(days) {
      switch (days) {
        case 1:
          return 'Last 24 Hours'
        case 2:
          return 'Last 48 Hours'
        case 3:
          return 'Last 3 Days'
        case 7:
          return 'Last 7 Days'
        case 30:
          return 'Last 30 Days'
        case 90:
          return 'Last 3 Months'
        case 180:
          return 'Last 6 Months'
        case 360:
          return 'Last 1 Year'
        case 720:
          return 'Last 2 Years'
        default:
          return 'Custom'
      }
    },

    checkTimeDurationLabel(startDate, endDate) {
      const endDateTime = moment(endDate).format('YYYY-MM-DD')
      const todaysDate = moment().format('YYYY-MM-DD')
      if (todaysDate === endDateTime) {
        console.log('checkTimeDurationLabel > today date ', todaysDate)
        const start = moment.unix(Date.parse(startDate) / 1000)
        const end = moment.unix(Date.parse(endDate) / 1000)
        const days = end.diff(start, 'days')
        return this.getLabelValueFromDay(days)
      } else {
        return 'Custom'
      }
    },
    showAutomationDatePickers() {
      const stateObject = this

      setTimeout(function () {
        // eslint-disable-next-line no-undef
        $(document).ready(function () {
          const minDate = moment()
            .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
            .add(1, 'hours')
          if (stateObject.getAutomationScheduleOptions.start_time === '') {
            stateObject.setAutomationStartTime(
              minDate.format('YYYY-MM-DD HH:mm:ss')
            )
          }
        })
      }, 200)
    },

    showAutomationPostAgeDatePickers() {
      console.debug('Method::showAutomationPostAgeDatePickers')
      const stateObject = this

      const maxDate = moment()
        .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      if (this.getArticleAutomationDetails.article.post_date === '')
        this.$store.commit(
          article.SET_ARTICLE_AUTOMATION_POST_DATE,
          maxDate.format('YYYY-MM-DD')
        )
    },

    showPublishingPostDatePickers() {
      const stateObject = this

      const minDate = moment()
        .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
        .add(60, 'm')
      if (stateObject.getPublishTimeOptions.post_date === '') {
        stateObject.setPublishingPostDate(minDate.format('MMMM DD, YYYY HH:mm'))
      }
    },

    // don't remove this method (added by umair)
    showBlogCurationPrimaryDatePickers() {
      const stateObject = this
      const minDate = moment()
        .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      if (stateObject.getBlogSelection.primaryTime.postSelectedDate === '') {
        stateObject.$store.commit(
          blogPosting.SET_PRIMARY_BLOG_DATE,
          minDate.format('YYYY-MM-DD HH:mm:ss')
        )
      }
    },
    // don't remove this method (added by umair)
    showBlogCurationSecondaryDatePickers(id, index) {
      console.debug('Method:showBlogCurationSecondaryDatePickers')
      const stateObject = this
      let minDate = null
      if (stateObject.getBlogSelection.primaryTime.time_type === 'now') {
        minDate = moment()
          .tz(stateObject.getWorkspaces.activeWorkspace.timezone)
          .add(1, 'hours')
      } else {
        minDate = moment(
          stateObject.getBlogSelection.primaryTime.postSelectedDate,
          'YYYY-MM-DD HH:mm:ss'
        ).add(1, 'hours')
        console.debug(minDate)
      }

      if (
        stateObject.getSecondaryBlogSelection[index].time.postSelectedDate ===
        ''
      ) {
        stateObject.getSecondaryBlogSelection[index].time.postSelectedDate =
          minDate.format('YYYY-MM-DD HH:mm:ss')
      }
    },
  },
}
