<template>
  <div id="youtube_tab">
    <div class="youtube_post_preview">
      <div
        v-if="details.video.link"
        class="youtube_post_preview__preview_video"
      >
        <!--              -->
        <!--                <iframe width="100%" height="320" :src="details.video.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
        <!--                -->
        <video
          width="100%"
          height="240px"
          controls
          :poster="details.video.thumbnail"
        >
          <source :src="details.video.link" type="video/mp4" />
          <source :src="details.video.link" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="youtube_post_preview__head">
        <div class="youtube_post_preview__head__profile_picture">
          <div
            class="youtube_post_preview__head__profile_picture__picture_block"
          >
            <img
              v-if="account.platform_logo"
              :src="account.platform_logo"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img
              v-else
              src="../../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>
          <div class="youtube_post_preview__head__profile_picture__text_block">
            <p v-if="options.title" class="text">{{ options.title }}</p>
            <p v-if="account.platform_name" class="sub_text">{{
              account.platform_name
            }}</p>
          </div>
        </div>
      </div>

      <div
        class="youtube_post_preview__preview_desc"
        v-html="processSharingPreviewDesciption(details.message, 'Youtube')"
      >
      </div>
      <template v-if="planner">
        <div class="first-comment-preview">
          <div class="first-comment-preview__img">
            <img
              v-if="account.platform_logo"
              :src="account.platform_logo"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img
              v-else
              src="../../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>
          <div class="first-comment-preview__text" v-html="n2br(data.message)">
          </div>
        </div>
        <div v-if="data.commentResponse" class="comments-status-box">
          <p class="ml-2" v-html="data.commentResponse"></p>
        </div>
      </template>
      <template v-else>
        <div
          v-if="
            getSocialSharingFirstComment.has_first_comment &&
            getSocialSharingFirstComment.first_comment_message.trim().length &&
            hasEnableFirstComment(getPreviewAccount.youtube.platform_identifier)
          "
          class="first-comment-preview"
        >
          <div class="first-comment-preview__img">
            <img
              v-if="account.platform_logo"
              :src="account.platform_logo"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img
              v-else
              src="../../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>
          <div
            class="first-comment-preview__text"
            v-html="n2br(getSocialSharingFirstComment.first_comment_message)"
          >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { encode } from 'html-entities'

export default {
  props: ['details', 'options', 'account', 'planner', 'data'],
  data () {
    return {
      PreviewMode: 'Desktop'
    }
  },

  methods: {
    n2br (str) {
      return encode(str).replace(/\n/g, '&nbsp;<br/>')
    },
    hasEnableFirstComment (id) {
      return (
        this.getSocialSharingFirstComment.first_comment_accounts &&
        this.getSocialSharingFirstComment.first_comment_accounts.includes(id)
      )
    }
  },
  computed: {
    ...mapGetters(['getPreviewAccount', 'getSocialSharingFirstComment'])
  }
}
</script>

<style scoped></style>
