<template>
  <div class="flex w-full mt-4">
    <div class="mt-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="25"
        height="25"
        :src="
          state.comment.posted_by
            ? state.comment.posted_by.image
            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
        "
        alt=""
        @error="
          $event.target.src = fallbackImagePreview(
            commonMethods
              .getPostedByName(
                state.comment.posted_by ? state.comment.posted_by : '',
              )[0]
              .toUpperCase(),
            '63A3F2',
            'white',
          )
        "
      />
    </div>
    <div class="w-full flex flex-column">
      <div class="flex flex-column">
        <div
          class="flex flex-column ml-2 rounded-lg !bg-gray-200 py-2 px-2 !overflow-y-hidden select-none"
        >
          <div class="flex justify-between">
            <div class="flex items-center">
              <p class="font-bold mr-1"
                >{{
                  state.comment.posted_by
                    ? state.comment.posted_by.name
                    : 'Linkedin User'
                }}
              </p>
              <p class="text-sm text-gray-800">{{
                state.comment.posted_by ? state.comment.posted_by.type : 'User'
              }}</p>
            </div>
            <div>
              <p class="text-sm text-gray-800">
                {{ commonMethods.timesAgo(state.comment.created_time) }}
              </p>
            </div>
          </div>
          <div>
            <span
              class="text-gray-800 whitespace-pre-line"
              v-html="getDescription"
            >
            </span>
            <span
              v-if="getDescription.length > state.char && state.load"
              class="text-gray-900 cursor-pointer ml-1 font-semibold"
              @click="changeLoadMore(false)"
              >Show More</span
            >
            <span
              v-if="getDescription.length > state.char && !state.load"
              class="text-gray-900 cursor-pointer ml-1 font-semibold"
              @click="changeLoadMore(true)"
              >Show Less</span
            >
          </div>

          <div
            v-if="state.comment.attachment"
            class="flex flex-col group relative w-24 m-2"
          >
            <img
              :src="state.comment.attachment.url"
              class="w-24 h-24 rounded border"
              alt=""
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
              "
            />
            <i
              v-tooltip.right="'Preview Image'"
              class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
              @click="previewMediaImage(state.comment.attachment.url)"
            ></i>
          </div>
        </div>
        <div class="flex justify-start items-center ml-3 mt-1">
          <div v-if="state.comment.likes_count > 0" class="mx-2">
            <span class="text-sm font-bold text-[#0A68C6]">
              <i class="far fa-thumbs-up fa-flip-horizontal"></i>
              .
              {{ state.comment.likes_count }}
            </span>
            |
          </div>
          <p
            class="text-xs font-bold text-red-700 cursor-pointer"
            @click="deleteComment"
            >Delete
          </p>
          <div class="mx-2 flex justify-start items-center text-gray-800">
            |
            <p
              class="text-xs ml-1 font-bold cursor-pointer text-gray-800"
              @click="onClickReply"
              >Reply
            </p>
          </div>
          <div v-if="state.deletingLoader">
            <div class="w-full flex justify_end items-center">
              <clip-loader
                class="d-inline-block align-middle"
                color="rgba(152, 158, 181, 0.8)"
                :size="'12px'"
              ></clip-loader>
            </div>
          </div>
        </div>
      </div>
      <div v-if="comment.comment_replies.length">
        <template v-for="(nestedComment, index) in comment.comment_replies" :key="`nested_comment_${index}`">
          <LinkedinInboxComment
            type="nested"
            :comment="nestedComment"
            :inbox-data="state.activeInboxData"
            v-bind="$attrs"
            v-on="$listeners"
            @toggle-reply-box="(val) => onClickReply(null, val, nestedComment)"
          ></LinkedinInboxComment>
        </template>
      </div>
      <div
        v-if="state.showReplyBox && state.commentType === 'main'"
        class="mt-1"
        :class="[
          state.postingLoader || state.deletingLoader
            ? 'opacity-50 select-none'
            : '',
        ]"
      >
        <LinkedInReplyBox
            :id="`NestedInputFile${state.comment.comment_id}`"
          v-bind="$attrs"
          :inbox-data="state.activeInboxData"
          :loader="state.postingLoader"
          v-on="$listeners"
          @post-comment="handlePostComment"
        ></LinkedInReplyBox>
      </div>
    </div>
  </div>
</template>

<script>
import LinkedInReplyBox from '@src/modules/inbox/components/reusable/Linkedin-post/LinkedInReplyBox'
import { computed, defineComponent, inject, nextTick, reactive } from 'vue'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import { swalAttributes } from '@common/constants/common-attributes'
import { parseDescriptionHtml } from '@common/lib/helper'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'LinkedinInboxComment',
  components: {
    LinkedInReplyBox,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    inboxData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'main',
    },
  },
  setup(props, { emit }) {
    const root = inject('root')
    const { $bvModal } = root
    const { dispatch, getters } = useStore()
    const state = reactive({
      comment: computed(() => props.comment),
      activeInboxData: computed(() => props.inboxData),
      showReplyBox: false,
      commentType: computed(() => props.type),
      postingResponse: '',
      deleteResponse: '',
      postingLoader: false,
      deletingLoader: false,
      childComment: null,
      load: true,
      char: 377,
    })

    const userId = computed(() => getters.getProfile._id)

    const previewMediaImage = (image) => {
      console.log('openImageLightBox', image)
      if (image) {
        const imageData = {
          link: image,
          visible: true,
        }
        emit('preview-image', imageData)
      }
    }

    const onClickReply = ($event, val, childComment) => {
      if (val === 'child') {
        state.childComment = childComment
      }
      if (state.commentType === 'nested') {
        emit('toggle-reply-box', 'child')
      }
      state.showReplyBox = true
    }

    /**
     * @description handles the posting of a reply to a comment
     * @param comment
     * @param attachment
     */
    const handlePostComment = async (comment, attachment) => {
      state.postingLoader = true
      const commentPayload = new FormData()
      commentPayload.append('workspace_id', state.activeInboxData.workspace_id)
      commentPayload.append('platform', state.activeInboxData.platform)
      commentPayload.append('platform_id', state.activeInboxData.platform_id)
      commentPayload.append('comment_id', state.comment.comment_id)
      commentPayload.append('reply_for', 'post')
      commentPayload.append(
        'element_id',
        state.activeInboxData.element_details.element_id
      )
      commentPayload.append('replied_by', userId.value)
      commentPayload.append('is_private_reply', false)
      commentPayload.append('is_for', 'reply_post')

      if (attachment) {
        commentPayload.append('attachment_file', attachment)
        commentPayload.append('attachment_type', 'image')
      }
      if (comment) {
        commentPayload.append('message', comment)
      }
      commentPayload.append(
        'comment_urn',
        state.childComment
          ? state.childComment.comment_urn
          : state.comment.comment_urn
      )

      try {
        state.commentsResponse = await dispatch('reply', commentPayload)
        if (state.commentsResponse?.isValid) {
          dispatch('toastNotification', {
            message: 'Comment has been posted.',
            type: 'success',
          })
          EventBus.$emit('refresh-inbox-detail')
          await nextTick()
          state.postingLoader = false
          state.showReplyBox = false
          emit('comment-posted', state.comment, state.commentsResponse.response)
        } else {
          state.postingLoader = false
          dispatch('toastNotification', {
            message: 'Could not reply to the comment.',
            type: 'error',
          })
        }
      } catch (error) {
        state.postingLoader = false
        dispatch('toastNotification', {
          message: error.message,
          type: 'error',
        })
      }
    }

    /**
     * @description handles the deletion of a comment or a reply
     * @returns {Promise<void>}
     */
    const deleteComment = async () => {
      const res = await $bvModal.msgBoxConfirm(
        'Are you sure you want to delete this comment?',
        {
          title: 'Remove Comment',
          ...swalAttributes(),
        }
      )
      if (res) {
        state.deletingLoader = true
        const deletedPayload = new FormData()
        deletedPayload.append('comment_id', state.comment.comment_id)
        deletedPayload.append('post_id', state.comment.post_id)
        deletedPayload.append('platform_id', state.activeInboxData.platform_id)
        deletedPayload.append('platform_type', state.activeInboxData.platform)
        deletedPayload.append('reply_of', 'null')
        deletedPayload.append(
          'workspace_id',
          state.activeInboxData.workspace_id
        )

        try {
          state.deleteResponse = await dispatch('deleteComment', deletedPayload)
          if (
            state.deleteResponse?.isValid &&
            state.deleteResponse?.message !== 'Error'
          ) {
            dispatch('toastNotification', {
              message: 'Comment has been deleted.',
              type: 'success',
            })
            EventBus.$emit('refresh-inbox-detail')
            await nextTick()
            state.deletingLoader = false
            emit('comment-deleted', state.comment, deletedPayload)
          } else {
            state.deletingLoader = false
            dispatch('toastNotification', {
              message: 'Could not delete the comment.',
              type: 'error',
            })
          }
        } catch (error) {
          state.deletingLoader = false
          dispatch('toastNotification', {
            message: 'Could not delete the comment.',
            type: 'error',
          })
        }
      }
    }

    const getDescription = computed(() => {
      let msg = state.comment.comment_message
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > state.char && state.load) {
        msg = msg.substr(0, state.char).concat('...')
        return msg
      }
      return msg
    })

    const changeLoadMore = (val) => {
      state.load = val
    }

    return {
      state,
      commonMethods,
      getDescription,
      changeLoadMore,
      previewMediaImage,
      onClickReply,
      handlePostComment,
      deleteComment,
    }
  },
})
</script>

<style scoped></style>
