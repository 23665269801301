<template>
  <div
    class="topics-insights-container__chart_full_width"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Top Domains on all networks based on Published Articles </h2>
    <div class="topics-insights-container__chart_full_width__sections">
      <div class="topics-insights-container__chart_full_width__left_section">
        <div
          class="topics-insights-container__chart_full_width__chart charts-legends-capitalize"
        >
          <highcharts
            ref="highcharts_articles_published"
            :options="topDomainsInsights()"
          ></highcharts>
        </div>
      </div>
      <div class="topics-insights-container__chart_full_width__right_section">
        <ul
          id="topDomains"
          class="topics-insights-container__chart_legends"
        ></ul>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_CHART_DATA } from '@common/constants/messages'
import highcharts from "@ui/Highcharts";

export default {
  components: {
    highcharts
  },
  props: {
    top_domains: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.top_domains.hasOwnProperty('domains_list_for_chart')) {
        return (
          (this.top_domains.domains_list_for_chart &&
            this.top_domains.domains_list_for_chart.length) === 0
        )
      }
      return false
    }
  },
  methods: {
    topDomainsInsights () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        chart: {
          height: 405,
          type: 'pie',
          spacingBottom: 0,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,

          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $('#facebook_item_highlight').empty()
              $(chart.series[0].data).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="border:3px solid ' +
                    serie.color +
                    ';" class=" color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#topDomains')
              })
            }
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0
          },
          pie: {
            // innerSize: '60%',
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: false
          }
        },
        colors: [
          '#7cb5ec',
          '#434348',
          '#90ed7d',
          '#f7a35b',
          '#e6ce6e',
          '#8085e9',
          '#f45b5b',
          '#e6ce6e',
          '#e6766e'
        ],
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y} on {point.name}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        title: {
          text: ''
        },
        series: [
          {
            name: 'Articles Published',
            size: '80%',
            innerSize: '55%',
            data:
              this.top_domains.domains_list_for_chart &&
              this.top_domains.domains_list_for_chart.length > 0
                ? this.top_domains.domains_list_for_chart
                : [
                  ['forbes.com', 10],
                  ['inc.com', 20],
                  ['techcrunch.com', 40],
                  ['mashable.com', 10],
                  ['gizmodo.com', 20],
                  ['cnn.com', 40],
                  ['bbc.com', 10]
                ]
          }
        ]
      }

      return chartOptions
    }
  }
}
</script>
