<template>
  <div class="social_campaign_block">
    <div class="head_text">
      <h3>Social Campaign</h3>
      <p
        >Share your blog post on social networks. This helps in extending your
        reach and getting traffic to your blog post. Sharing it multiple times
        allows your post to reach a large number of fans/followers.</p
      >
      <p
        >Please use the step-by-step process below to create your social
        campaign.</p
      >
    </div>
    <div class="create_automation_btn">
      <h3>Social Campaign Creation Wizard</h3>
    </div>

    <div class="composer_tabs">
      <ul id="nav-tab" class="nav" role="tablist">
        <li class="nav-item nav-link">
          <a
            id="content_selection_link"
            class="active show"
            href="#content_selection"
            data-toggle="tab"
            role="tab"
            aria-controls="content_selection"
            aria-selected="true"
          >
            1. Content Selection
          </a>
        </li>
        <li class="nav-item nav-link">
          <a
            id="content_hashtag_link"
            class=" "
            href="#content_hashtag"
            data-toggle="tab"
            role="tab"
            aria-controls="content_hashtag"
            aria-selected="true"
          >
            2. Hashtags
          </a>
        </li>
        <li class="nav-item nav-link">
          <a
            id="content_review_link"
            class=""
            href="#content_review"
            data-toggle="tab"
            role="tab"
            aria-controls="content_review"
            aria-selected="true"
          >
            1. Content Review
          </a>
        </li>
      </ul>
      <div class="tab-content align-self-lg-stretch">
        <div
          id="content_selection"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="content_selection_link"
        >
          <div class="content_selection_block">
            <div class="tab_head d-flex align-items-center">
              <div class="tab_left">
                <h3>Selected Content Variations</h3>
              </div>
              <div class="tab_right">
                <button class="add_icon btn border_btn gradient_btn large_btn">
                  <i class="icon_left cs-plus"></i>
                  <span class="text">Add custom entery</span>
                </button>
              </div>
            </div>
            <div class="tab_inner_body">
              <div class="text_entry basic_form">
                <!--.....V-Else .......-->
                <div class="text_item input_field">
                  <div class="inner">
                    <textarea id="" name="" cols="30" rows="2"></textarea>
                    <div class="btn_block">
                      <button class="btn border_btn edit_icon">
                        <i class="icon-edit-cs"></i>
                      </button>
                      <button class="btn border_btn red ml-2">
                        <i class="icon-delete-cs red"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="text_item input_field">
                  <div class="inner">
                    <textarea id="" name="" cols="30" rows="2"></textarea>
                    <div class="btn_block">
                      <button class="btn border_btn edit_icon">
                        <i class="icon-edit-cs"></i>
                      </button>
                      <button class="btn border_btn red ml-2">
                        <i class="icon-delete-cs red"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="suggestion_list">
                <div class="tab_head d-flex align-items-center">
                  <div class="tab_left">
                    <h3>Suggestions</h3>
                    <button
                      class="add_icon btn border_btn dark_black large_btn add_all_btn"
                    >
                      <span class="text">Add All </span>
                    </button>
                  </div>
                </div>

                <div class="suggestion_box_list row">
                  <div class="suggestion_box_item">
                    <div class="inner">
                      <p
                        >Experiment 07 comes with 5 different parts we want to
                        share and discuss with you: iPad/Tablet Preview, Power
                        Search, Settings, Paged Scrolling, and Pro upgrade – all
                        on both Android and iOS.</p
                      >
                      <button class="add_suggestion_btn btn">
                        <i class="icon_left cs-plus"></i>
                        <span class="text">Add</span>
                      </button>
                    </div>
                  </div>
                  <div class="suggestion_box_item">
                    <div class="inner">
                      <p
                        >Experiment 07 comes with 5 different parts we want to
                        share and discuss with you: iPad/Tablet Preview, Power
                        Search, Settings, Paged Scrolling, and Pro upgrade – all
                        on both Android and iOS.</p
                      >
                      <button class="add_suggestion_btn btn">
                        <i class="icon_left cs-plus"></i>
                        <span class="text">Add</span>
                      </button>
                    </div>
                  </div>
                  <div class="suggestion_box_item">
                    <div class="inner">
                      <p
                        >Experiment 07 comes with 5 different parts we want to
                        share and discuss with you: iPad/Tablet Preview, Power
                        Search, Settings, Paged Scrolling, and Pro upgrade – all
                        on both Android and iOS.</p
                      >
                      <button class="add_suggestion_btn btn">
                        <i class="icon_left cs-plus"></i>
                        <span class="text">Add</span>
                      </button>
                    </div>
                  </div>
                  <div class="suggestion_box_item">
                    <div class="inner">
                      <p
                        >Experiment 07 comes with 5 different parts we want to
                        share and discuss with you: iPad/Tablet Preview, Power
                        Search, Settings, Paged Scrolling, and Pro upgrade – all
                        on both Android and iOS.</p
                      >
                      <button class="add_suggestion_btn btn">
                        <i class="icon_left cs-plus"></i>
                        <span class="text">Add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab_footer">
              <a href="" class="btn btn-studio-theme-space">
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div
          id="content_hashtag"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="content_hashtag_link"
        >
          <div class="hashtag_content_block basic_form">
            <div class="d-flex align-items-start">
              <div class="hashtag_left">
                <div class="input_field mt-0">
                  <div class="d-flex align-items-center">
                    <label class="text w-50">Add a custom hashtag</label>
                    <div class="multi_select w-100">
                      <div class="multi_input">
                        <input
                          type="text"
                          placeholder="Example : #Marketing…"
                        />
                        <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input_field">
                  <div class="d-flex align-items-center">
                    <label class="text w-50">Load from saved hashtags</label>
                    <div class="with_right_icon w-100">
                      <select id="" name="" class="with_bg w-100">
                        <option value="">test</option>
                      </select>
                      <i class="right_icon fa fa-caret-down"></i>
                    </div>
                  </div>
                </div>
                <div class="selected_suggestion_hashtag">
                  <h3 class="title"> Selected Hashtags </h3>
                  <ul>
                    <li class="d-flex align-items-center">
                      <span class="value">#iphone</span>
                      <div class="right ml-auto">
                        <div class="d-flex align-items-center">
                          <div class="ratting_block">
                            <span class="fill"></span>
                            <span class="fill"></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <i class="remove_icon icon-delete-cs red"></i>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex align-items-center">
                      <span class="value">#block</span>
                      <div class="right ml-auto">
                        <div class="d-flex align-items-center">
                          <div class="ratting_block">
                            <span class="fill"></span>
                            <span class="fill"></span>
                            <span class="fill"></span>
                            <span class="fill"></span>
                            <span></span>
                          </div>
                          <i class="remove_icon icon-delete-cs red"></i>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex align-items-center">
                      <span class="value">#hacker</span>
                      <div class="right ml-auto">
                        <div class="d-flex align-items-center">
                          <div class="ratting_block">
                            <span class="fill"></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <i class="remove_icon icon-delete-cs red"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="option_section">
                  <div class="input_field">
                    <h3 class="title">Options</h3>
                    <div class="d-flex align-items-center">
                      <div class="checkbox_input_image w-50">
                        <input id="recurring232" checked type="checkbox" />
                        <label for="recurring232" class="checkbox_left"
                          >Randomly choose</label
                        >
                      </div>
                      <div class="w-100">
                        <input value="0" type="number" class="with_bg w-auto" />
                        <span class="ml-3">hashtag(s) for each post.</span>
                      </div>
                    </div>
                  </div>
                  <div class="radio_list input_field">
                    <div class="radio_input_image">
                      <input
                        id="recurring"
                        checked
                        type="radio"
                        name="tes22t11"
                      />
                      <label for="recurring" class="radio_left">Append</label>
                    </div>
                    <div class="radio_input_image">
                      <input id="custom" type="radio" name="tes22t11" />
                      <label for="custom" class="radio_left"
                        >Replace (if keyword is matched in the post)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="hashtag_right">
                <div class="suggestion_hashtag">
                  <h3 class="title"> Suggestions </h3>
                  <ul>
                    <li class="d-flex align-items-center">
                      <span class="value">#iphone</span>
                      <span class="add_icon ml-auto">
                        <i class="cs-plus"></i>
                        <span class="text">Add</span>
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span class="value">#iphone</span>
                      <span class="add_icon ml-auto">
                        <i class="cs-plus"></i>
                        <span class="text">Add</span>
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span class="value">#iphone</span>
                      <span class="add_icon ml-auto">
                        <i class="cs-plus"></i>
                        <span class="text">Add</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tab_footer">
              <a href="" class="btn btn-studio-theme-space">
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div
          id="content_review"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="content_review_link"
        >
          <div class="content_review_block basic_form">
            <div class="review_box_item focus_out">
              <div class="inner">
                <div class="top_accounts d-flex align-items-center">
                  <div class="social_accounts_list animate_account_list">
                    <div
                      class="deactive_list"
                      :class="{ open: showAccountsStatus }"
                    >
                      <div
                        class="checkbox_input_image add_icon_green"
                        @click="showAccounts"
                      >
                        <div class="profile_picture">
                          <div
                            class="d-flex d-align-items-center justify-content-center picture_block"
                          >
                            <span class="far fa-plus d-block"></span>
                          </div>
                        </div>
                      </div>
                      <div class="list_accounts">
                        <div
                          class="checkbox_input_image checkbox_social_profile"
                        >
                          <input
                            id="accounts.id"
                            type="checkbox"
                            name="option2"
                          />
                          <label for="accounts.id" class="checkbox_right">
                            <div class="profile_picture w_40 tw">
                              <div class="picture_block">
                                <div class="icon">
                                  <i class="cs-twitter"></i>
                                </div>
                                <img
                                  src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                        <div
                          class="checkbox_input_image checkbox_social_profile"
                        >
                          <input
                            id="accounts.id"
                            type="checkbox"
                            name="option2"
                          />
                          <label for="accounts.id" class="checkbox_right">
                            <div class="profile_picture w_40 tw">
                              <div class="picture_block">
                                <div class="icon">
                                  <i class="cs-twitter"></i>
                                </div>
                                <img
                                  src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="active_list">
                      <div
                        class="with_cross checkbox_input_image checkbox_social_profile"
                      >
                        <input id="a242323" type="checkbox" name="option2" />
                        <label for="a242323" class="checkbox_right">
                          <div class="profile_picture w_40 tw">
                            <div class="picture_block">
                              <i class="cross_icon cs-cross"></i>
                              <div class="icon">
                                <i class="cs-twitter"></i>
                              </div>
                              <img
                                src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <h3 class="remaining_days">Day <span>03</span></h3>

                  <!--........Create new case............-->
                </div>
                <div class="d-flex align-items-center">
                  <div class="r_content">
                    <div class="textarea_input input_field mt-0">
                      <textarea
                        id=""
                        disabled
                        readonly
                        name=""
                        cols="30"
                        rows="4"
                      >
asda sad asda sdaas daas asd asdaas daas asd asdasdas</textarea
                      >
                      <p class="text_limit"
                        >Characters Remaining: <span>912</span></p
                      >
                    </div>
                    <div
                      class="arrow_block d-flex align-items-center justify-content-between"
                    >
                      <i class="arrow_left cs-angle-left"></i>
                      <p>Post Content Variation</p>
                      <i class="arrow_right cs-angle-right"></i>
                    </div>
                  </div>
                  <div class="r_image">
                    <Slick ref="slick" :options="slickOptions">
                      <div class="slide">
                        <div
                          class="img"
                          style="
                            background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                          "
                        ></div>
                      </div>
                      <div class="slide">
                        <div
                          class="img"
                          style="
                            background: url('https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg');
                          "
                        ></div>
                      </div>
                    </Slick>

                    <div
                      class="arrow_block d-flex align-items-center justify-content-between"
                    >
                      <i class="arrow_left cs-angle-left"></i>
                      <p>Post Content Variation</p>
                      <i class="arrow_right cs-angle-right"></i>
                    </div>
                  </div>
                </div>
                <div class="r_footer d-flex align-items-center">
                  <div class="left d-flex align-items-center">
                    <p
                      >This post will be send on
                      <span>Wednesday, December 31th @9:30 AM</span></p
                    >

                    <!--........edit case..........-->
                  </div>
                  <div class="right ml-auto">
                    <button class="edit_btn btn border_btn large_btn edit_icon">
                      <i class="icon_left icon-edit-cs"></i>
                      <span class="text">Edit</span>
                    </button>

                    <button
                      class="icon-delete-cs btn border_btn large_btn remove_icon ml-2"
                    >
                      <i class="icon_left icon-delete-cs"></i>
                      <span class="text">Remove</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="add_box_container">
              <div class="add_box">
                <div class="box_inner">
                  <p>Click to add another post</p>
                </div>
              </div>
            </div>

            <div class="tab_footer start_date_footer">
              <div class="head_text">
                <h3>Social Campaign</h3>
                <p
                  >If you are done with creating/editing the social media post
                  variations, you can pick a start date below. We recommend
                  keeping it to default (Today).</p
                >
              </div>

              <div class="head_text text-center mt-5 mb-4">
                <h3>Start Date</h3>
                <div class="datepicker_style start_datepicker">
                  <i class="icon-Calendar"></i>
                  <input
                    value="09/10/2018"
                    class=""
                    readonly
                    type="text"
                    name="daterange"
                  />
                </div>
              </div>
            </div>

            <div class="tab_footer">
              <div class="head_text mb-3">
                <p
                  >Ok, so this campaign has 9 social media post variations that
                  will be sent over a period of 365 days.</p
                >
              </div>
              <a href="" class="btn btn-studio-theme-space">
                <span>Finish & Create Campaign</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Slick from 'vue-slick'
export default {
  components: {
    Slick
  },
  data () {
    return {
      showAccountsStatus: false,
      slickOptions: {
        slidesToShow: 1
        // Any other options that can be got from plugin documentation
      }
    }
  },
  created () {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    next () {
      this.$refs.slick.next()
    },

    prev () {
      this.$refs.slick.prev()
    },
    showAccounts () {
      this.showAccountsStatus = !this.showAccountsStatus
    }
  }
}
</script>
