<template>
  <div class="topics-follow-container">
    <div class="topics-follow-container__box">
      <h2 class="topics-follow-container__box__heading">Welcome to Topics</h2>
      <p class="topics-follow-container__box__text"
        >Create customized feeds to bring fresh and engaging content relevant to
        the keywords, topics and source of your choice.</p
      >
      <div class="topics-follow-container__box__selection">
        <div class="topics-follow-container__box__selection__items">
          <div
            class="topics-follow-container__box__selection__items__box"
            @click.prevent="followCuratedTopicsModal"
          >
            <img
              src="../../../../../assets/img/discover/topics/follow-curated-topics.svg"
              alt=""
            />
            <h4> Follow Curated Topics </h4>
            <p>
              Monitor content from more than 20,000 pre-made curated topics.
            </p>
            <div
              class="topics-follow-container__box__selection__items__box__divider"
            ></div>
          </div>
          <div class="topics-follow-container__box__selection__items__or_text">
            <span>OR</span>
          </div>
          <div
            class="topics-follow-container__box__selection__items__box"
            :disabled="true"
            :class="{
              'topics-follow-container__box__selection__items__disabled_box':
                !hasPermission('can_save_topic'),
            }"
            @click.prevent="createNewTopic"
          >
            <img
              src="../../../../../assets/img/discover/topics/create-custom-topics.svg"
              alt=""
            />
            <h4> Create a Custom Topic </h4>
            <p>
              Tailor results according to your niche by creating a custom topic.
            </p>
            <p
              v-if="!hasPermission('can_save_topic')"
              class="topics-follow-container__box__selection__items__box__warning"
            >
              Please ask your admin to give you permission to create a custom
              topic.
            </p>
            <div
              class="topics-follow-container__box__selection__items__box__divider"
            ></div>
          </div>
        </div>
        <div class="topics-follow-container__box__selection__avatar"> </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
