import { threads } from '@src/modules/integration/store/states/mutation-types'

const getDefaultThreadsAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultThreadsAccountsState()
  },
  getters: {
    getThreadsAccounts: (state) => state.accounts,
  },
  actions: {},
  mutations: {
    [threads.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.platform_name.toLowerCase().localeCompare(b.platform_name.toLowerCase()))
    },

    [threads.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.platform_name.toLowerCase().localeCompare(b.platform_name.toLowerCase()))
    },

    [threads.SET_DEFAULT] (state) {
      state.accounts = getDefaultThreadsAccountsState()
    }
  }
}
