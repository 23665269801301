<script setup>
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'

const { cards, getCardData, isReportView } =
  useTiktokAnalytics()

</script>

<template>
  <NewStatsCard
    v-for="card in cards"
    :key="card"
    class="col-span-2 2xl:col-auto"
    :data="getCardData(card)"
    :show-info-tooltip="!isReportView"
  />
</template>
