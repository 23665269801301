import { mapGetters } from 'vuex'
const blogComposerMixin = {
  methods: {
    getBlogTitle (lowerCase = true) {
      if (lowerCase) {
        return this.getBlogPostingDetails.title.toLowerCase()
      } else {
        return this.getBlogPostingDetails.title
      }
    },

    getBlogDescription () {
      return this.limitTextLength(
        this.getEditorMetaDescription().toLowerCase(),
        153
      )
    },

    getEditorMetaDescription () {
      try {
        return $(this.getBlogPostingHTML).text()
      } catch (e) {
        return ''
      }
    },

    /**
     * Range calculator for disabled dates.
     * @param date
     * @returns {boolean}
     */
    disabledDate(date, currentValue) {
      // Converting current time to given timezone.
      const timeStart = new Date(
          new Date().toLocaleString('en-US', {
            timeZone: this.getActiveWorkspace.timezone,
          })
      )
      const timeEnd = new Date(date)

      // Removing 1 day from current time.
      const now = timeStart.setHours(
          timeStart.getHours() - 24,
          timeStart.getMinutes(),
          timeStart.getSeconds()
      )
      const value = timeEnd.setHours(
          timeEnd.getHours(),
          timeEnd.getMinutes(),
          timeEnd.getSeconds()
      )

      return value < now
    }
  },
  computed: {
    ...mapGetters([
      'getBlogPostingCustomizeSeoPreviewStatus',
      'getBlogPostingDetails',
      'getBlogEditorSelector'
    ])
  }
}
export { blogComposerMixin }
