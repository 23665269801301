<template>
  <div
    id="add-label-modal"
    class="modal fade add_utm_modal normal_modal z-[1060]"
  >
    <div class="modal-dialog w-1/3">
      <div class="modal-content">
        <div class="modal_head">
          <h2>{{ isEditMode ? 'Edit Label' : 'Create Label' }}</h2>
          <button
            id="close"
            type="button"
            class="modal_head__close"
            data-dismiss="modal"
            @click.prevent="HideModal"
            >&times;
          </button>
        </div>
        <div class="modal_body">
          <div class="mb-3 relative">
            <input
              v-model="labelName"
              type="text"
              placeholder="Type label name"
              class="
                w-full
                pl-12
                pr-3
                py-3
                border border-gray-300
                rounded-md
                shadow-sm
              "
              ref="myInput"
              autofocus
              @keyup.enter="createLabel"
            />
            <img
              src="@src/assets/img/chat_bot/labels.svg"
              alt="label icon"
              class="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2"
            />
          </div>

          <div class="field_group">
            <div class="cst_label_box p-2 border rounded-md mt-1">
              <span class="text-sm font-bold text-gray-900 block py-2 ml-2"
                >Choose Color</span
              >
              <template
                v-for="color in Object.values(LABELS_COLOR_MAP)"
                :key="color"
              >
                <div
                  :class="`box_item m-1 relative w-9 h-9 float-left p-1 rounded-md cursor-pointer`"
                  :style="`background-color: ${hexToRGBA(color, 0.8)}`"
                  @click="selectColor(color)"
                >
                  <img
                    v-if="color === LABELS_COLOR_MAP[labelColor]"
                    src="@src/assets/img/chat_bot/tick.svg"
                    alt="selected"
                    class="absolute inset-0 m-auto w-3 h-3"
                  />
                </div>
              </template>
            </div>
          </div>

          <div class="field_group float-right">
            <button
              class="btn btn-studio-theme-space"
              @click.prevent="createLabel"
              :disabled="!labelName || !labelColor"
            >
              {{ isEditMode ? 'Update' : 'Create' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed, watch } from 'vue'
import $ from 'jquery'
import { useStore } from '@state/base'
import useLabelAndCampaign from '@modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'

const { LABELS_COLOR_MAP, hexToRGBA } = useLabelAndCampaign()
const store = useStore()
const root = inject('root')
const { $bvModal } = root

const props = defineProps({
  editLabel: {
    type: Object,
    default: null,
  },
})

const labelName = ref('')
const labelColor = ref('')

const isEditMode = computed(() => !!props.editLabel)

watch(
  () => props.editLabel,
  (newVal) => {
    if (newVal) {
      labelName.value = newVal.name
      labelColor.value = newVal.color
    }
  }
)

const createLabel = async () => {
  if (labelName.value.trim() && labelColor.value.trim())  {
    if (isEditMode.value) {
      // Logic to update label
      store.commit('SET_NEW_LABEL', {
        _id: props.editLabel?._id,
        name: labelName.value,
        color: labelColor.value,
      })
    } else {
      // Logic to create label
      store.commit('SET_NEW_LABEL', {
        name: labelName.value,
        color: labelColor.value,
      })
    }
    await store.dispatch('saveLabel')
    hideModal()
  }
}

const selectColor = (color) => {
  labelColor.value = getObjectKey(color)
}

const getObjectKey = (value) => {
  return Object.keys(LABELS_COLOR_MAP)?.find(
    (key) => LABELS_COLOR_MAP[key] === value
  )
}

const hideModal = () => {
  labelName.value = ''
  labelColor.value = ''
  props.editLabel = null
  $('#add-label-modal').modal('hide')
}
</script>

<style scoped>
.cst_label_box {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
</style>
