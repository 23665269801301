<template>
  <transition name="slide-bottom">
    <div
      class="post_share_option post_share_option_gmb post_share_option_social basic_form_inline"
    >
      <p class="text-capitalize">Set Pinterest Post Description</p>
      <div class="flex-row d-flex">
        <div class="col">
          <div class="">
            <!--<label class="label-animated">Description</label>-->
            <b-textarea
              v-model="getPinterestOptions.postDescription"
              style="border-radius: 12px"
              :state="
                getPinterestOptions.postDescription.length <= 500 ? null : false
              "
              class="p-3 mt-3"
              no-resize
              rows="5"
              placeholder="Add Pinterest Post Description..."
            ></b-textarea>
            <div class="d-flex flex-row justify-content-end">
              <p
                >{{ getPinterestOptions.postDescription.length }}/{{
                  pinterestDescriptionLimit
                }}</p
              >
            </div>

            <!--<input type="text" placeholder="Add Pinterest Post Description..." v-model="getPinterestOptions.postDescription">-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PinterestOptions',
  data () {
    return {
      pinterestDescriptionLimit: 500
    }
  },
  computed: {
    ...mapGetters(['getPinterestOptions'])
  },
  methods: {}
}
</script>

<style scoped></style>
