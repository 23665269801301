// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'
import {ref} from "vue";
import {EventBus} from "@common/lib/event-bus";


export default function usePostingActivityGraphByTypes() {
    const {
        fetchCompetitorInfo: fetchPostingActivityGraphByTypes,
        apiResponse: postingActivityGraphByTypesResponse,
        loading: postingActivityGraphByTypesLoadingState
    } = useCompetitorsFactory()

    const sortOrder = ref('')
    const mediaType = ref('')
    const mediaProductType = ref('')

    const {reportId, dateRange, dateRangeDiff, timeZone} = useCompetitorReport()

    const getPostingActivityGraphByTypes = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonPostingActivityGraphByTypes()
                break
            default:
                break
        }
    }
    const fetchCommonPostingActivityGraphByTypes = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
            sort_order: sortOrder.value || 'avgTotalEngagements'
        }

        try {
            await fetchPostingActivityGraphByTypes(COMMON_API_ENDPOINTS.POSTING_ACTIVITY_GRAPH_BY_TYPES, payload, COMMON_ERROR_MESSAGES.POSTING_ACTIVITY_GRAPH_BY_TYPES)
            mediaType.value = postingActivityGraphByTypesResponse.value[0]?.mediaType || ''
            if(Object.hasOwn(postingActivityGraphByTypesResponse.value[0], 'mediaProductType')) mediaProductType.value = postingActivityGraphByTypesResponse.value[0]?.mediaProductType || ''
            if(mediaType.value || mediaProductType.value) EventBus.$emit('fetch-specific-type-data')
        } catch (error) {
        }
    }

    return {
        // data
        dateRangeDiff,
        reportId,
        postingActivityGraphByTypesResponse,
        postingActivityGraphByTypesLoadingState,
        mediaType,
        mediaProductType,
        sortOrder,

        // methods
        getPostingActivityGraphByTypes
    }
}