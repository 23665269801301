<template>
  <ul v-if="location === 'planner'" class="inner planner_member_list">
    <template
      v-if="
        getWorkspaces.activeWorkspace.members &&
        getWorkspaces.activeWorkspace.members.length > 0
      "
    >
      <li class="list_item_li select_all_li">
        <div class="ui_check_container">
          <input
            id="assigned_members"
            v-model="getPlans.check_all_assigned_members"
            type="checkbox"
            @change="selectAllAssignedMembers"
          />
          <span class="check"></span>
          <label for="assigned_members" class="checkbox_right">
            <span class="">All</span>
          </label>
        </div>
      </li>
      <li
        v-for="member in getWorkspaces.activeWorkspace.members"
        class="list_item_li"
      >
        <div
          v-if="member.status === 'joined' && member.user"
          class="ui_check_container showlink"
        >
          <input
            :id="'assigned_by_' + member._id"
            :value="member.user._id"
            :checked="isAssignedMemberSelected(member.user._id)"
            type="checkbox"
            @change="changeAssignedMemberFilter(member.user._id)"
          />
          <span class="check"></span>
          <label :for="'assigned_by_' + member._id" class="checkbox_right">
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block planner_member_block">
                <div class="inline_block">
                  <p
                      v-tooltip="getUserInitialsTooltip(member.user)"
                    class="text"
                  >
                    {{getUserInitials(member.user)}}
                  </p>
                  <a
                    class="onlyLink"
                    @click.prevent="
                      assignedMemberOnlySelection(member.user._id)
                    "
                    >Only</a
                  >
                </div>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </li>
    </template>

    <li v-else class="no_text_li !w-full"> No member found. </li>
  </ul>
  <ul
    v-else-if="location === 'created_by_filter'"
    class="inner filter_memebr_list"
  >
    <template v-if="fetchFilteredMembers.length">
      <li class="list_item_li select_all_li">
        <div class="ui_check_container">
          <input
            id="created_members"
            v-model="getPlans.check_all_created_members"
            type="checkbox"
            @change="selectAllCreatedMembers"
          />
          <span class="check"></span>
          <label for="created_members" class="checkbox_right">
            <span class="">All</span>
          </label>
        </div>
      </li>
      <li
        v-for="member in getWorkspaces.activeWorkspace.members"
        class="list_item_li"
      >
        <div
          v-if="member.status === 'joined' && member.user"
          class="ui_check_container showlink"
        >
          <input
            :id="'created_by_' + member._id"
            :value="member.user._id"
            :checked="isCreatedMemberSelected(member.user._id)"
            type="checkbox"
            @change="changeCreatedMemberFilter(member.user._id)"
          />
          <span class="check"></span>
          <label :for="'created_by_' + member._id" class="checkbox_right">
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block filter_member_block">
                <div class="inline_block">
                  <p
                      v-tooltip="getUserInitialsTooltip(member.user)"
                    class="text"
                  >
                    {{getUserInitials(member.user)}}
                  </p>
                  <a
                    class="onlyLink"
                    @click.prevent="createdMemberOnlySelection(member.user._id)"
                    >Only</a
                  >
                </div>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </li>
    </template>

    <li v-else class="no_text_li"> No member found. </li>
  </ul>
  <!-- checking composer  -->
  <ul v-else-if="location === 'composer'" class="inner member_list_inner">
    <div class="search-bar-input m-3">
      <div class="search-bar-inner">
        <input
          v-model="memberSearch"
          type="text"
          class=""
          placeholder='Search by name e.g "John"'
        />
        <button class="search_btn">
          <i class="icon-Search"></i>
        </button>
      </div>
    </div>

    <hr />
    <!-- select all members checkbox -->
    <li v-if="fetchFilteredMembers.length > 0" class="list_item_li member_list w-100">
      <div class="field_group m-1">
        <div class="checkbox_container">
          <label class="checkbox_right">
            <!-- used getPublications for composer  -->
            <input
              v-model="getPublications.check_all_members"
              type="checkbox"
              @change="selectAllAssignedMembers"
            />
            <span class="check top_10px"></span>
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background"></div>
              <div class="text_block">
                <span class="align-items-center">Select All</span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </li>

    <template v-for="(member, memberIndex) in getWorkspaces.activeWorkspace.members" :key="`member_${memberIndex}`">
      <li v-if="member.user && !hide" class="list_item_li member_list w-100">
        <div v-if="member.status === 'joined'" class="ui_check_container">
          <input
            :id="member._id"
            v-model="getPublications.members"
            :value="member.user._id"
            type="checkbox"
            @change="memberFilterChange"
          />
          <span class="check"></span>
          <label :for="member._id" class="checkbox_right">
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block">
                <p
                    v-tooltip="getUserInitialsTooltip(member.user)"
                  class="text"
                >
                  {{getUserInitials(member.user)}}
                </p>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </li>
    </template>

    <!-- for the auto-complete results -->
    <template v-if="hide">
    <li
      v-for="member in fetchFilteredMembers"
      :key="`listMember_${member.id}`"
      class="list_item_li member_list w-100"
    >
      <div class="field_group m-1">
        <div
          v-if="member.status === 'joined' && member.user"
          class="checkbox_container"
        >
          <label :for="member._id" class="checkbox_right">
            <input
              :id="member._id"
              v-model="getPublications.members"
              :value="member.user._id"
              type="checkbox"
              @change="memberFilterChange"
            />
            <span class="check top_10px"></span>
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block">

                <p
                  v-tooltip="getUserInitialsTooltip(member.user)"
                  class="text"
                  data-cy="select-member"
                >
                  {{getUserInitials(member.user)}}
                </p>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </div>
    </li>
    </template>
    <li v-if="fetchFilteredMembers.length < 1" class="no_text_li">
      No member found.
    </li>
  </ul>
  <ul v-else class="inner member_list_inner">
    <div class="search-bar-input m-3">
      <div class="search-bar-inner">
        <input
          v-model="memberSearch"
          type="text"
          class=""
          placeholder='Search by name e.g "John"'
        />
        <button class="search_btn">
          <i class="icon-Search"></i>
        </button>
      </div>
    </div>

    <hr />

    <template v-for="(member, activeMemberIndex) in getWorkspaces.activeWorkspace.members" :key="`activeMember_${activeMemberIndex}`">
      <li v-if="member.user && !hide" class="list_item_li member_list w-100">
        <div v-if="member.status === 'joined'" class="ui_check_container">
          <input
            :id="member._id"
            v-model="getPublishSelection.members"
            :value="member.user._id"
            type="checkbox"
            @change="memberFilterChange"
          />
          <span class="check"></span>
          <label :for="member._id" class="checkbox_right">
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block">
                <p
                    v-tooltip="getUserInitialsTooltip(member.user)"
                  class="text"
                >
                  {{getUserInitials(member.user)}}
                </p>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </li>
    </template>

    <!-- for the auto-complete results -->
    <template v-if="hide">
    <li
      v-for="(member, filteredMemberIndex) in fetchFilteredMembers"
      :key="`filteredMember_${filteredMemberIndex}`"
      class="list_item_li member_list w-100"
    >
      <div class="field_group m-1">
        <div
          v-if="member.status === 'joined' && member.user"
          class="checkbox_container"
        >
          <label :for="member._id" class="checkbox_right">
            <input
              :id="member._id"
              v-model="getPublishSelection.members"
              :value="member.user._id"
              type="checkbox"
              @change="memberFilterChange"
            />
            <span class="check top_10px"></span>
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="member.user.image"
                  class="img"
                  :style="{ background: 'url(' + member.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>

              <div class="text_block">
                <p
                    v-tooltip="getUserInitialsTooltip(member.user)"
                  class="text"
                  data-cy="select-member"
                >
                  {{getUserInitials(member.user)}}
                </p>
                <p
                  class="sub_text role_text"
                  :class="getRoleClass(member.role)"
                >
                  {{ getMutatedUserRole(member.role) }}
                </p>
              </div>
            </div>
          </label>
        </div>
      </div>
    </li>
    </template>
    <li v-if="fetchFilteredMembers.length < 1" class="no_text_li">
      No member found.
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { planner } from '@src/modules/planner/store/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import { composer } from '@src/modules/composer/store/composer-mutation-type.js'
export default {
  components: {},
  props: {
    location: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      memberSearch: '',
      hide: false
    }
  },
  computed: {
    ...mapGetters([
      'getPublications', // for composer
      'getWorkspaces',
      'getProfile',
      'getPublishSelection',
      'getActivePlannerFilters'
    ]),
    fetchFilteredMembers (memberSearch) {
      const members = []
      const stateObject = this
      if (memberSearch) {
        stateObject.hide = true
        if (
            this.getWorkspaces &&
            this.getWorkspaces.activeWorkspace &&
            this.getWorkspaces.activeWorkspace.members
        ) {
          this.getWorkspaces.activeWorkspace.members.forEach(
              (workspaceMember, index) => {
                if (workspaceMember.status === 'joined' && workspaceMember.user) {
                  if (
                      (workspaceMember.user.firstname && workspaceMember.user.firstname.toLowerCase().indexOf(stateObject.memberSearch.toLowerCase()) >= 0) ||
                      (workspaceMember.user.lastname && workspaceMember.user.lastname.toLowerCase().indexOf(stateObject.memberSearch.toLowerCase()) ) >= 0
                  ) {
                    members.push(workspaceMember)
                  } else if (
                      workspaceMember.user.email
                          .toLowerCase()
                          .indexOf(stateObject.memberSearch.toLowerCase()) >= 0
                  ) {
                    members.push(workspaceMember)
                  }
                }
              }
          )
        }
      } else {
        stateObject.hide = false
        return []
      }
      return members
    }
  },
  watch: {
    'getActiveWorkspace._id' () {
      if (this.$route.name === 'composer') {
        this.setPublishMembers(
            this.fetchFilteredMembers.map((obj) => obj.user._id)
        )
      }
    }
  },
  created () {},
  mounted () {
    this.$nextTick(() => {
      if (this.$route.name === 'composer') {
        this.setPublishMembers(
          this.fetchFilteredMembers.map((obj) => obj.user._id)
        )
      }
    })
    EventBus.$on('set_members_for_composer', () => {
      if (this.$route.name === 'composer') {
        this.$store.commit(
          composer.SET_PUBLICATIONS_MEMBERS,
          this.fetchFilteredMembers.map((obj) => obj.user._id)
        )
        // this.setPublishMembers(this.fetchFilteredMembers.map((obj) => obj.user._id))
      }
    })
  },
  beforeUnmount () {
    EventBus.$off('set_members_for_composer')
  },
  methods: {
    ...mapActions(['setPublishMembers']),
    memberFilterChange () {
      console.debug(
        'Method:memberFilterChangeUmair',
        this.fetchFilteredMembers.length ===
          this.getPublishSelection.members.length
      )
      const name = this.$route.name
      if (
        name === 'calender_plans' ||
        name === 'list_plans' ||
        name === 'feed_view'
      ) {
        this.$store.dispatch('refetchPlans', this.$route)
      } else if (name === 'composer') {
        this.$store.commit(
          composer.SET_CHECK_ALL_MEMBERS,
          this.fetchFilteredMembers.length ===
            this.getPublications.members.length
        )
        this.$store.commit(
          composer.SET_PUBLICATIONS_MEMBERS,
          JSON.parse(JSON.stringify(this.getPublications.members))
        )
        this.refetchPublications(
          this.fetchFilteredMembers.length ===
            this.getPublishSelection.members.length
        )
      }
    },
    isAssignedMemberSelected (id) {
      return (
        this.getPlans.check_all_assigned_members ||
        this.$parent.getFiltersMembers.indexOf(id) >= 0
      )
      // return (!this.$route.query.members && this.getPlans.check_all_assigned_members) || (this.$route.query.members && this.$route.query.members.indexOf(id) >= 0) || this.getPlans.check_all_assigned_members
    },
    isCreatedMemberSelected (id) {
      return (
        this.getPlans.check_all_created_members ||
        this.$parent.getFiltersCreatedMembers.indexOf(id) >= 0
      )
      // return (!this.$route.query.created_by && this.getPlans.check_all_created_members) || (this.$route.query.created_by && this.$route.query.created_by.indexOf(id) >= 0) || this.getPlans.check_all_created_members
    },
    changeAssignedMemberFilter (id) {
      console.debug('Method:changeAssignedMemberFilter')
      let ids = []
      if (this.$route.query.filter_id && !this.$route.query.members) {
        ids = this.$parent.getFiltersMembers.length
          ? this.$parent.getFiltersMembers
          : this.getPlans.check_all_assigned_members
            ? this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            : []
        this.getActivePlannerFilters.members = []
      } else {
        ids = this.$route.query.members
          ? this.$route.query.members.split(',')
          : this.getPlans.check_all_assigned_members
            ? this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            : []
      }
      ids.indexOf(id) >= 0 ? ids.splice(ids.indexOf(id), 1) : ids.push(id)
      this.$store.commit(
        planner.SET_CHECK_ALL_ASSIGNED_MEMBERS,
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length === ids.length
      )
      if (ids.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, members: ids.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.members
      this.$router.push({ query })
    },
    assignedMemberOnlySelection (id) {
      this.getActivePlannerFilters.members = []
      if (
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length > 1
      ) {
        this.$store.commit(planner.SET_CHECK_ALL_ASSIGNED_MEMBERS, false)
      }
      this.$router.push({ query: { ...this.$route.query, members: id } })
    },
    selectAllAssignedMembers () {
      if (this.$route.name === 'composer') {
        if (this.getPublications.check_all_members) {
          //  select all members
          this.$store.commit(
            composer.SET_PUBLICATIONS_MEMBERS,
            this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          )
        } else {
          //  unselect all members
          this.$store.commit(composer.SET_PUBLICATIONS_MEMBERS, [])
        }
        this.refetchPublications(true)
      } else {
        this.getActivePlannerFilters.members = []
        let ids = []
        if (this.getPlans.check_all_assigned_members) {
          ids = this.getTeamMembersIds(
            this.getWorkspaces.activeWorkspace.members
          )
        }
        if (ids.length > 0) {
          this.$router.push({
            query: { ...this.$route.query, members: ids.join() }
          })
          return false
        }
        const query = Object.assign({}, this.$route.query)
        delete query.members
        this.$router.push({ query })
        this.$store.dispatch('refetchPlans', this.$route)
      }
    },
    changeCreatedMemberFilter (id) {
      console.debug('Method:changeCreatedMemberFilter')
      let ids = []
      if (this.$route.query.filter_id && !this.$route.query.created_by) {
        ids = this.$parent.getFiltersCreatedMembers.length
          ? this.$parent.getFiltersCreatedMembers
          : this.getPlans.check_all_created_members
            ? this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            : []
        this.getActivePlannerFilters.created_by = []
      } else {
        ids = this.$route.query.created_by
          ? this.$route.query.created_by.split(',')
          : this.getPlans.check_all_created_members
            ? this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
            : []
      }
      ids.indexOf(id) >= 0 ? ids.splice(ids.indexOf(id), 1) : ids.push(id)
      this.$store.commit(
        planner.SET_CHECK_ALL_CREATED_MEMBERS,
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length === ids.length
      )
      if (ids.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, created_by: ids.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.created_by
      this.$router.push({ query })
    },
    selectAllCreatedMembers () {
      console.debug('Method:selectAllCreatedMembers')
      this.getActivePlannerFilters.created_by = []
      let ids = []
      if (this.getPlans.check_all_created_members) {
        ids = this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
      }
      if (ids.length > 0) {
        this.$router.push({
          query: { ...this.$route.query, created_by: ids.join() }
        })
        return false
      }
      const query = Object.assign({}, this.$route.query)
      delete query.created_by
      this.$router.push({ query })
      this.$store.dispatch('refetchPlans', this.$route)
    },
    createdMemberOnlySelection (id) {
      this.getActivePlannerFilters.created_by = []
      if (
        this.getTeamMembersIds(this.getWorkspaces.activeWorkspace.members)
          .length > 1
      ) {
        this.$store.commit(planner.SET_CHECK_ALL_CREATED_MEMBERS, false)
      }
      this.$router.push({ query: { ...this.$route.query, created_by: id } })
    },
    getUserInitials(user){
      if(user.firstname && user.lastname) return user.firstname + ' ' + user.lastname
      return user.email
    },
    getUserInitialsTooltip(user){
      if(this.getUserInitials(user).length > 30) return this.getUserInitials(user)
      return ''
    }
  },
}
</script>

<style lang="scss">
.member_list_inner {
  max-height: 450px !important;
  @media (max-width: 1360px) {
    max-height: 300px !important;
  }
  .profile_picture {
    .text {
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.onlyLink {
  visibility: hidden;
  text-decoration: underline;
  font-size: 11px !important;
  border: 1px solid #dee2e8;
  padding: 2px 9px;
  border-radius: 13px;
}
.onlyLink:hover {
  font-weight: bold;
}
.showlink:hover .onlyLink {
  visibility: visible;
}
.member_list {
  padding: 2px 10px !important;
}
.filter_memebr_list {
  .filter_member_block {
    .inline_block {
      display: inline-block;
      .text {
        max-width: 140px;
        float: left;
      }
      .onlyLink {
        padding: 0px 9px;
        float: right;
      }
    }
  }
}
.planner_member_list {
  .planner_member_block {
    .inline_block {
      display: inline-block;
      .text {
        max-width: 140px;
        float: left;
      }
      .onlyLink {
        padding: 0px 9px;
        float: right;
      }
    }
  }
}
</style>
