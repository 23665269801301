<script setup>
import {defineProps} from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  statusList: {
    type: Array,
    default: () => [],
    required: true,
  },
})

const getStatusTooltip = (item) => {
  return item.status ? 'Published' : `Failed ${item?.error_code || ''}: ${item?.error_message || 'Unknown error'}`
}

</script>

<template>
  <div class="py-4 px-[1.5rem] bg-[#F4F6FA] flex flex-col">
    <div>
      <div class="flex items-center">
        <img alt="Image icon" class="w-[1.2rem] h-[1.2rem] opacity-60 mr-2" src="/img/instagram-stories-icon-active.svg" />
        <p class="text-gray-900 text-sm leading-none text-left">{{title}}</p>
      </div>
      <hr />
    </div>
    <div v-for="(item, index) in statusList" :key="index" class="text-[#202324] mb-2 text-sm text-left">
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-x-2">
          <img :src="item?.preview" alt="image" class="w-10 h-10 rounded"/>
          <p v-tooltip="item?.title" class="text-gray-900 cursor-pointer text-sm leading-none text-left truncate w-20 xl:w-60">{{item?.title}}</p>
        </div>
        <div class="flex align-items-center">
          <div v-if="item?.link" class="flex align-items-center">
            <a v-tooltip="'Live Link'" :href="item?.link" class="hover:bg-[#F2F3F8] rounded-full px-2 py-1" target="_blank">
              <img src="@assets/img/icons/live-link.svg" alt="Live link" class="w-[0.875rem] h-[0.875rem]"/>
            </a>
            <div class="h-[1.2rem] mx-2 w-px bg-gray-600"></div>
          </div>
          <v-menu
              class="flex items-center gap-x-1"
              :popper-triggers="['hover']"
              :delay="0"
              placement="right"
              popper-class="first-comment__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#"
            ><img :src="item.status ? require('@assets/img/icons/planner/published_icon.svg') : require('@assets/img/icons/planner/failed_icon.svg')" alt="status icon" class="ml-2 w-5 h-5"></a>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>{{ getStatusTooltip(item) }}</div>
            </template>
          </v-menu>
          <i class="far fa-angle-up ml-4 text-2xl text-transparent"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.svg-hover:hover svg path {
  fill: #214EA8;
}
</style>
