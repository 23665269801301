<script setup>
  import {VueTelInput} from 'vue3-tel-input'
  import 'vue3-tel-input/dist/vue3-tel-input.css'
  import {ref, computed, onMounted, nextTick} from 'vue'
  import {useStore} from "@state/base"
  import { timezones } from '@src/modules/setting/config/timezone.js'
  import { useOnboarding } from '@src/modules/account/composables/useUserOnboarding'
  import SideDetails from '@/src/modules/account/views/onboarding/SideDetails.vue'
  import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput.vue'

  const { getters } = useStore()

  // Defining the component local state
  const { phoneField, userCredentials, dialCode } = useOnboarding()

  const isValidPhone = ref(false)
  const search_timezone = ref('')

  const measurePhoneFieldWidth = async () => {
    if (phoneField.value) {
      const phoneFieldWidth = phoneField.value.offsetWidth
      if (phoneFieldWidth > 0) {
        const sheet = document.createElement('style')
        sheet.innerHTML = `.vti__responsive-width {width: ${phoneFieldWidth}px}`
        document.body.appendChild(sheet)
        const dropdownList = phoneField.value.getElementsByClassName('vti__dropdown-list')[0]
        if (dropdownList) {
          dropdownList.classList.add('vti__responsive-width')
        }
      } else {
        // Retry after a short delay if width is not available
        setTimeout(measurePhoneFieldWidth, 100)
      }
    }
  }

  // mounted
  onMounted(async() => {
    await measurePhoneFieldWidth()
  })

  // computed
  const getFilteredTimezones = computed(() => {
    return timezones.filter((timezone) =>
        timezone.value.toLowerCase().includes(search_timezone.value.toLowerCase())
    )
  })

  const computeTimezone = computed(() => {
    return timezones.find((timezone) => timezone.value === getters.getActiveWorkspace.timezone)
  })

  // Methods
  const phoneInput = (str, { number, isValid, country }) => {
    dialCode.value = country?.dialCode?.length ? '+' + country?.dialCode : '';
  }

  const onCountryChange = (country) => {
    // Guard clause for invalid country data
    if (!country?.dialCode) return;

    const currentNumber = userCredentials.phone_no || ''
    const newDialCode = '+' + country.dialCode

    // Remove all spaces and special characters for clean comparison
    const cleanNumber = currentNumber.replaceAll(' ', '').replace(/[()-]/g, '')

    // Different scenarios handling
    if (
        // Case 1: Empty phone number
        !cleanNumber ||
        // Case 2: Only dial code exists (no numbers after it)
        cleanNumber === newDialCode ||
        // Case 3: Current number doesn't include the new dial code
        !cleanNumber.includes(newDialCode)
    ) {
      // If there are digits after removing old dial code, preserve them
      const existingNumber = cleanNumber.replace(/^\+\d+/, '').replace(/^0+/, '')
      userCredentials.phone_no = existingNumber
          ? newDialCode + existingNumber
          : newDialCode
    } else {
      // Case 4: Keep existing number as it already has correct dial code
      userCredentials.phone_no = currentNumber
    }
  }

  const phoneValidate = ({ number, isValid }) => {
    isValidPhone.value = isValid
    // eslint-disable-next-line no-useless-escape
    const regex = /^[+\d]?[\d.\s()]*$/
    if (!regex.test(number)) {
      isValidPhone.value = false
    }
  }

  const changeTimezone = (timezone) => {
    getters.getActiveWorkspace.timezone = timezone
    userCredentials.timezone = timezone
    search_timezone.value = ''
  }

</script>

<template>
  <div class="flex items-center w-full  lg:justify-between h-full">
    <div class="w-[43.5rem] max-w-[43.5rem] 2xl:w-[57.5rem] 2xl:max-w-[57.5rem] flex flex-col gap-4 bg-gray-100 bg-opacity-80 px-10 pt-8 rounded-tl-2xl h-full">
      <img
          src="../../assets/img/logo/contentstudio-logo-original.png"
          class="w-[130px] 2xl:w-[150px]"
          alt="ContentStudio"
      />
      <SideDetails
          :title="`Welcome <span class='gradient-text'>Onboard!</span> 👋</span>`"
          :sub_title="`Let's setup your first <br> workspace`"
          description="This will help us create a more personalized experience for you" />
    </div>
    <div class="w-full mt-10 lg:mt-0 px-20 2xl:px-32 flex">
      <div class="p-[2.86rem] bg-gray-100 w-full bg-opacity-80 rounded-2xl auth-form-container">
        <div class="auth-form space-y-5">
          <CstFloatingLabelInput
              id="fname"
              v-model="userCredentials.firstname"
              class="!h-[3.275rem] bg-white"
              type="text"
              label="Full Name"
              :maxlength="46"
              show-icon-left
              value="userCredentials.firstname">
            <template v-slot:icon>
              <i class="icon-User"></i>
            </template>
          </CstFloatingLabelInput>
          <div ref="phoneField" class="auth-form__phone-field">
            <VueTelInput
                v-model="userCredentials.phone_no"
                data-cy="telephone"
                mode="international"
                placeholder="Phone Number"
                :show-dial-code-in-list="true"
                :show-dial-code-in-selection="false"
                :valid-characters-only="true"
                @country-changed="onCountryChange"
                @input="phoneInput"
                @validate="phoneValidate"
            >
              <template v-slot:arrow-icon="open">
                <i v-if="open" class="fas fa-chevron-up"></i>
                <i v-else class="fas fa-chevron-down"></i>
              </template>
            </VueTelInput>
          </div>
          <!-- Workspace timezone -->
          <div class="field_group">
            <!--<label for="">Timezone</label>-->

            <div
                class="social_dropdown dropdown default_style_dropdown"
            >
              <div data-display="static"
                   data-toggle="dropdown"
                   class="dropdown_header d-flex align-items-center bg-white flex items-center h-14 !rounded-md border text-gray-900 text-sm focus-within:outline-blue w-full !py-2.5 !px-3"
                   data-cy="basic_workspace_timezone_clickable"
              >
                <label
                    class="label-animated !left-3 !top-0 block !text-gray-900 !text-xs !py-2 m-0 leading-normal !z-0"
                >Workspace Timezone</label
                >
                <div
                    class="relative_top_ten w-full border-0 py-2 bg-transparent leading-normal rounded !text-gray-900"
                >{{
                    getters.getActiveWorkspace.timezone !== null
                        ? computeTimezone.name
                        : 'Select time zone'
                  }}</div
                >
                <span class="ml-auto">
                          <i class="dropdown_arrow icon_last cs-angle-down"></i>
                        </span>
              </div>

              <div
                  class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
              >
                <ul class="inner h-72">
                  <div class="search_input with_icon_right">
                    <div class="search_inner w-100">
                      <input
                          id="search_timezone"
                          v-model="search_timezone"
                          class="!rounded-[6px]"
                          type="text"
                          placeholder="Search by name"
                          data-cy="search-timezone"
                      />
                    </div>
                  </div>

                  <template v-if="getFilteredTimezones.length">
                    <template
                        v-for="(timezone, i) in getFilteredTimezones"
                        :key="i"
                    >
                      <li
                          class="list_item_li !p-4"
                          data-cy="basic_workspace_timezone"
                          @click.prevent="changeTimezone(timezone.value)"
                      >
                        {{ timezone.name }}
                      </li>
                    </template>
                  </template>

                  <li
                      v-if="!getFilteredTimezones.length"
                      class="no_text_li"
                  >
                    No Results found
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.form_bg_gradient {
  background: linear-gradient(22.28deg, rgba(200, 232, 255, 0.29) 0%, rgba(238, 244, 247, 0.29) 34.96%, rgba(238, 240, 247, 0.29) 72.73%) !important;
  border: 1px solid rgba(210, 213, 223, 0.16);
  padding: 2.86rem !important;
}

.auth-form__phone-field {
  height: 3.3rem;
  border: 1px solid #dee2e6;
  border-radius: 5px !important;
  background: white;
  .vue-tel-input {
    padding-right: 2px;
    background: white;
    border-radius: 5px !important;
    .vti__dropdown {
      border: transparent !important;
      height: 3rem !important;
      outline: none !important;
      margin-right: 0px !important;
      background: white;
      width: auto;
      &:hover {
        background: white;
      }
    }
    input {
      border: transparent !important;
      border-radius: 5px;
      height: 3rem !important;
      outline: none !important;
      color: #757A8A !important;
    }
  }
}
</style>
