<template>
  <div class="relative">
    <b-tabs
      v-model="tabIndex"
      nav-class="analytics-tabs analytics-main-tabs"
      content-class="mt-3 analytics-tabs-content  "
      lazy
    >
      <b-tab title="Overview" href="#overview">
        <slot name="overview"></slot>
      </b-tab>
      <b-tab title="Posts" href="#posts">
        <slot name="posts"></slot>
      </b-tab>
    </b-tabs>
    <div class="absolute right-4 top-0 h-16 flex items-center">
      <PlatformTooltip type="linkedin" :enable-peek="true" />
    </div>
  </div>
</template>

<script>
import PlatformTooltip from '@src/modules/analytics/components/common/PlatformTooltip.vue'

export default {
  name: 'LinkedInTabs',
  components: {
    PlatformTooltip,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['#overview', '#posts'],
    }
  },
  watch: {
    tabIndex(val) {
      location.hash = this.tabs[val]
    },
  },
  mounted() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  },
}
</script>

<style scoped></style>
