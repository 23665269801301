<template>
  <!-- This is used in the ArticleSearchHistory component -->
  <div class="search_dropdown_inner">
    <div class="search_operations_dropdown">
      <div class="search_operators_examples">
        <h5>Examples</h5>
        <div class="examples_list">
          <div class="example_operator">
            <div class="example_text">
              <i class="icon-Search"></i> Search for a keyword:
            </div>
            <div class="example_idea"> marketing </div>
          </div>
          <div class="example_operator">
            <div class="example_text">
              <i class="icon-Search"></i> Search for an exact phrase:
            </div>
            <div class="example_idea"> "digital marketing" </div>
          </div>
          <!--                    <div class="example_operator">-->
          <!--                        <div class="example_text">-->
          <!--                            <i class="icon-Search"></i> Search by hashtag:-->
          <!--                        </div>-->
          <!--                        <div class="example_idea"> #marketing-->
          <!--                        </div>-->
          <!--                    </div>-->
          <div class="example_operator">
            <div class="example_text">
              <i class="icon-Search"></i> Search by site:
            </div>
            <div class="example_idea">nytimes.com </div>
          </div>
          <div class="example_operator">
            <div class="example_text">
              <i class="icon-Search"></i> Search by author:
            </div>
            <div class="example_idea">author:'Joe Pulizzi' </div>
          </div>
          <div class="example_operator">
            <div class="example_text">
              <i class="icon-Search"></i> Search for keywords in a site:
            </div>
            <div class="example_idea">forbes.com marketing </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
@ds-darkGray: #4a4a4a; // article social stats text color
@ds-lightGray: #989eb5;
@ds-lightGray_3: #72778a; // social tab icon background // search input border color
// operators default text
.search_operations_dropdown {
  color: @ds-lightGray;
  padding: 10px 20px 15px 20px;

  h5 {
    padding: 10px 0;
    font-weight: 500;
  }

  .example_operator {
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    .example_text {
      i {
        padding-right: 5px;
      }
    }

    .example_idea {
      font-weight: 500;
      color: @ds-darkGray;
      padding-left: 5px;
    }
  }
}
</style>
