import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import { fetchPlansURL, removePlanURL } from '../../planner/config/api-utils'
import { composer } from '../store/composer-mutation-type'

const PublicationsMixin = {

  data () {
    return {}
  },

  created () {
  },

  computed: { ...mapGetters(['getWorkspaces', 'getPublications', 'getAxiosRequests', 'getJWTToken']) },

  methods: {

    ...mapActions([]),

    refetchPublications () {
      this.$store.commit(composer.SET_PUBLICATIONS_PAGE, 1)
      this.$store.commit(composer.SET_FETCH_PUBLICATION_CONTENT_LOADER, true)
      this.$store.commit(composer.SET_FETCH_PUBLICATION_PAGINATION_LOADER, false)
      this.fetchPublications(null)
    },

    searchPublication (search) {
      this.$store.commit(composer.SET_PUBLICATIONS_SEARCH, search.trim())
      this.refetchPublications()
    },

    fetchFilteredMembersComposer () {
      const members = []
      if (this.getWorkspaces && this.getWorkspaces.activeWorkspace && this.getWorkspaces.activeWorkspace.members) {
        this.getWorkspaces.activeWorkspace.members.forEach((workspaceMember, index) => {
          if (workspaceMember.status === 'joined' && workspaceMember.user) {
            members.push(workspaceMember)
          }
        })
      }
      return members
    },

    fetchPublications ($state = null) {
      console.debug('Method:fetchPublications')

      const publications = this.getPublications
      const members = publications.members && publications.members.length === this.fetchFilteredMembersComposer().length ? [] : publications.members
      const contentCategories = publications.category && publications.category.length === this.getContentCategoryList.length ? [] : publications.category
      const statuses = publications.status && publications.status.length === 9 ? [] : publications.status

      const payload = {
        type: 'publication',
        labels: publications.labels,
        members,
        search: publications.search,
        folder: publications.folder.id,
        statuses,
        content_categories: contentCategories,
        page: publications.page,
        postType: publications.type,
        limit: publications.limit,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }

      const stateObject = this
      const CancelToken = axios.CancelToken
      for (let i = 0; i < stateObject.getAxiosRequests.fetchPublications.length; i++) {
        const remove = stateObject.getAxiosRequests.fetchPublications[i]
        remove()
      }

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getJWTToken
      }
      let cancel

      axios.post(fetchPlansURL, payload, {

        cancelToken: new CancelToken(function executor (c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        })
      }).then(
        response => {
          if (response.data.status === true) {
            const plans = response.data.plans.data

            if (this.getPublications.page > 1) {
              this.$store.commit(composer.SET_PUBLICATIONS, this.getPublications.publications.concat(plans))
            } else {
              this.$store.commit(composer.SET_PUBLICATIONS, plans)
            }

            if (plans.length === 12) {
              this.$store.commit(composer.SET_FETCH_PUBLICATION_PAGINATION_LOADER, true)
              if ($state) {
                console.debug('$state.loaded')
                $state.loaded()
              }
            } else {
              try {
                this.$store.commit(composer.SET_FETCH_PUBLICATION_PAGINATION_LOADER, false)
                if ($state) {
                  console.debug('$state.complete')
                  $state.complete()
                }
              } catch (ex) {
                console.log(ex)
              }
            }
          } else {
            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage('Unable to get publications. Please try again.', 'error')
            }
          }
          this.$store.commit(composer.SET_FETCH_PUBLICATION_CONTENT_LOADER, false)
        },
        response => {
          console.debug('Exception in fetchPublications ', response)
        })
      this.getAxiosRequests.fetchPublications.push(cancel)
    },

    removePublicationModel (item, index, bvModal) {
      if (item.blog_reference) this.confirmAction('removePublication', { id: [item._id], index, type: 'single' })
      else this.removePublicationRequest({ id: [item._id], index, type: 'single' })
      bvModal.hide(item._id)
    },

    /**
     * this method will remove publication
     */
    removePublication (confirmation, plan) {
      console.debug('Method:removePublication', confirmation, plan)
      if (confirmation.status) {
        this.removePublicationRequest(plan)
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },

    removePublicationRequest (data) {
      this.postRequest(removePlanURL, {
        id: data.id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        type: 'publication'
      }, response => {
        if (response.data.status === true) {
          this.fetchPublicationFolders()
          this.setConfirmActionStage('success')
          if (data.type === 'single') {
            this.getPublications.publications.splice(data.index, 1)
            this.alertMessage('Publication successfully removed.', 'success')
          } else {
            this.$store.commit(composer.SET_PUBLICATIONS_SELECT_ALL, false)
            this.$store.commit(composer.SET_PUBLICATIONS_BULK_IDS, [])
            this.alertMessage('Publications successfully removed.', 'success')
            this.refetchPublications()
          }
        } else {
          if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            if (data.type === 'single') {
              this.alertMessage('Unable to remove Publication.', 'error')
            } else {
              this.alertMessage('Unable to remove Publications.', 'error')
            }
          }
          this.setConfirmActionStage('fail')
        }
        this.closeConfirmAction('removePublications')
        this.closeConfirmAction('removePublication')
      }, error => {
        this.setConfirmActionStage('fail')
        console.debug('Exception in removePublicationRequest', error)
        this.closeConfirmAction('removePublications')
        this.closeConfirmAction('removePublication')
      })
    }
  }

}

export { PublicationsMixin }
