<script>
import { mapGetters, mapActions } from 'vuex'

import {
  NULL_AUTOMATION_NAME_ERROR,
  NULL_SOCIAL_ACCOUNT_SELECTION_ERROR
} from '@common/constants/messages'
import InsertFileModal from '@src/modules/publish/components/widget/file/InsertFileModal'
import ReplaceCurrentTypeConfirmation from '@src/modules/publish/components/posting/social/dialogs/ReplaceCurrentTypeConfirmation'
import ReplaceGifConfirmation from '@src/modules/publish/components/posting/social/dialogs/ReplaceGifConfirmation'
import ReplaceImageConfirmation from '@src/modules/publish/components/posting/social/dialogs/ReplaceImageConfirmation'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import RemoveEvergreenPostConfirmation from '../confirmation/RemoveEvergreenPostConfirmation'
import removeEvergreenVariationConfirmation from '../confirmation/RemoveEvergreenVariationConfirmation'
import RemoveAllEvergreenPostsConfirmation from '../confirmation/RemoveAllEvergreenPostsConfirmation'
import { evergreen } from '../../../store/automation-mutation-type'
import VideoPreview from '../../../../discovery/components/common/preview/VideoPreview'
import UploadMediaModal from '../../../../publish/components/media-library/components/UploadMediaModal'
import AutoGenerateVariationModal from './URLVariationModal'

import TabsContent from './TabsContent'
import EvergreenAccountSelection from './EvergreenAccountSelection'
import EvergreenOptimization from './EvergreenOptimization'
import EvergreenFinalization from './EvergreenFinalization'
import AddEvergreenPost from './AddEvergreenPost'





export default {
  components: {
    UploadMediaModal,
    ReplaceImageConfirmation,
    ReplaceGifConfirmation,
    ReplaceCurrentTypeConfirmation,
    InsertFileModal,
    AddEvergreenPost,
    AutoGenerateVariationModal,
    TabsContent,
    EvergreenAccountSelection,
    EvergreenOptimization,
    EvergreenFinalization,
    RemoveEvergreenPostConfirmation,
    removeEvergreenVariationConfirmation,
    RemoveAllEvergreenPostsConfirmation,
    VideoPreview
  },
  mixins: [AutoMixin],

  data () {
    return {
      validations: {
        name: false,
        social: false
      },
      messages: {
        name: NULL_AUTOMATION_NAME_ERROR,
        social: NULL_SOCIAL_ACCOUNT_SELECTION_ERROR
      }
    }
  },
  created () {
    this.setCommonSharingDetails(null)
    this.initializeSaveEvergreenAutomationSection()
    this.fetchEvergreenAutomation()
  },

  mounted () {
    // adding event listener for enter key
    document.addEventListener('keyup', this.keyPressListener)
  },

  unmounted () {
    // removing event listener when component unmounted
    document.removeEventListener('keyup', this.keyPressListener)
  },

  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getEvergreenSelection',
      'getPlans',
      'getAccountSelection',
      'getAllAutomations',
      'getContentCategoryAvailableSlot',
      'getEvergreenAutomationBulk'
    ]),
    // check evergreen automation name exist
    emptyMessage () {
      return (
        this.getEvergreenSelection.name === null ||
        this.getEvergreenSelection.name.trim().length === 0
      )
    },
    // check evergreen name and social selection is exist
    isValidStep () {
      return (
        !this.getEvergreenSelection.name ||
        this.getEvergreenSelection.name.trim().length === 0 ||
        !this.socialAccountSelectionValidation('EvergreenAutomation')
      )
    }
  },

  methods: {
    ...mapActions([
      'setAccountSelection',
      'saveEvergreenAutomation',
      'setCommonSharingDetails'
    ]),

    /*
     * event listener for escape press in evergreen automation
     * check route name and redirect back to previous step
     */
    keyPressListener (event) {
      if (event.keyCode === 27) {
        switch (this.$route.name) {
          case 'saveEvergreenAutomationAccounts':
            this.$router.push({ name: 'automation' })
            break
          case 'saveEvergreenAutomationOptimization':
            this.$router.push({ name: 'saveEvergreenAutomationFinalize' })
            break
        }
      }
    },

    /*
     * fetch all evergreen automation
     * and check if params evergreenId exist
     * we set states according to that automations
     */
    async fetchEvergreenAutomation () {
      // check automation is already get or not
      if (this.getAllAutomations.fetch_status === false) {
        // fetch evergreen automations and wait until get
        // await this.fetchAllEvergreenAutomations()
        // this.setPlannerEvergreenAutomationsFetchStatus(true)
        if (this.$route.params.evergreenId) {
          // if param evergreenId exist
          const selfObject = this
          // find specific automation according to id
          this.getAllAutomations.evergreen.forEach(function (element, index) {
            if (element._id === selfObject.$route.params.evergreenId) {
              selfObject.$store.commit(
                evergreen.SET_EVERGREEN_AUTOMATION,
                element
              )
              selfObject.$store.commit(
                'setAccountSelection',
                element.account_selection
              )
              selfObject.processAutomationScheduleOptions(
                element.schedule_options
              )
              if (element.schedule_options.time_type === 'content_category') {
                this.$store.dispatch(
                  'nextAvailableSlot',
                  element.content_category_id
                )
              }
            }
          })
        }
      }
    },

    /*
     * validate step one of evergreen automation
     * check if automation name is exist
     * and social account also selected
     * than redirect to second step
     */
    processStepFirst (validation = false) {
      if (this.emptyMessage) {
        // check if campaign name is empty show error message
        // this.alertMessage(NULL_AUTOMATION_NAME_ERROR, 'error')
        this.validations.name = true
        return false
      } else if (
        !this.socialAccountSelectionValidation('EvergreenAutomation')
      ) {
        // social validation check account selection is not null
        this.validations.social = true
        return false
      }
      if (validation) return true
      this.redirectEvergreen('saveEvergreenAutomationFinalize')
    },

    /*
     * validate step two of evergreen automation
     * check number of posts is 2 or greter
     * and any post does not contain any error
     * than redirect to 3rd step schedule
     */
    processStepSecond () {
      console.debug('Method:processStepSecond')

      if (this.getEvergreenAutomationBulk.csvFileUpload) {
        this.alertMessage('Please wait until the CSV is uploaded.', 'error')
        return false
      }

      if (this.getEvergreenSelection.posts.length < 2) {
        // check number of posts
        this.alertMessage('Please add atleast 2 posts for processing.', 'error')
        return false
      }

      // check all posts are valid
      let validForCreate = true
      const selfObject = this

      // return false if any post contain any error
      selfObject.getEvergreenSelection.posts.forEach(function (el, index) {
        const postError = selfObject.singleBoxErrorMessageEvergreen(
          el,
          index + 1
        )
        if (postError && postError.length > 0) {
          selfObject.alertMessage(postError[0], 'error')
          validForCreate = false
          return false
        }
      })
      if (!validForCreate) return false
      this.redirectEvergreen('saveEvergreenAutomationOptimization')
    },

    /*
     * validate step third of evergreen automation
     * check schedule options are valid
     * and check cycle gap validation
     * than request to save automation
     */
    processStepThird (validation = false) {
      if (!this.automationScheduleOptionsValidation('evergreen')) return false
      if (
        this.getEvergreenSelection.cycleGapStatus &&
        (this.getEvergreenSelection.cycleGap < 1 ||
          this.getEvergreenSelection.cycleGap > 400)
      ) {
        this.alertMessage('Please enter cycle gap between 1 and 400.', 'error')
        return false
      }
      if (
        this.getEvergreenSelection.content_category_id &&
        this.getAutomationScheduleOptions.time_type === 'content_category' &&
        !this.getContentCategoryAvailableSlot.available
      ) {
        this.alertMessage(
          'You have not created any slots for your category.',
          'error'
        )
        return false
      }
      if (validation) return true
      this.$store.dispatch('saveEvergreenAutomation') // request to save automation
    },

    /*
     * evergreen common redirect method
     */
    redirectEvergreen (route) {
      if (this.$route.params.evergreenId) {
        this.$router.push({
          name: route,
          params: { evergreenId: this.$route.params.evergreenId }
        })
      } else {
        this.$router.push({ name: route })
      }
    },

    /*
     * fetch all posts error messages
     */
    singleBoxErrorMessageEvergreen (posts) {
      const socialPlatforms = this.getAccountSelection
      const message = []
      // check if pinterest or instagram account is selected and any post does't contain image
      // we show error because pinterest and instagram does't allow text or without image post
      posts.forEach(function (details, index) {
        if (socialPlatforms.pinterest.length) {
          // checking pinterest accout selected
          if (!details.image.length) {
            // checking post contain image
            message.push(
              'Please add an image for Pinterest post. It cannot be empty. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }
        if (socialPlatforms.instagram && socialPlatforms.instagram.length) {
          // checking instagram accout selected
          if (
            !details.image.length &&
            (!details.video || details.video.link === '')
          ) {
            // checking post contain image
            message.push(
              'Please add an image for Instagram post. It cannot be empty. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }
      })
      return message
    }
  }
}
</script>

<template>
  <div>
    <VideoPreview class="automation_video_modal"></VideoPreview>
    <AddEvergreenPost></AddEvergreenPost>
    <AutoGenerateVariationModal></AutoGenerateVariationModal>

    <RemoveEvergreenPostConfirmation></RemoveEvergreenPostConfirmation>
    <remove-evergreen-variation-confirmation></remove-evergreen-variation-confirmation>
    <RemoveAllEvergreenPostsConfirmation></RemoveAllEvergreenPostsConfirmation>
    <ReplaceCurrentTypeConfirmation></ReplaceCurrentTypeConfirmation>
    <ReplaceGifConfirmation></ReplaceGifConfirmation>
    <ReplaceImageConfirmation></ReplaceImageConfirmation>

    <UploadMediaModal type="evergreen"></UploadMediaModal>
    <InsertFileModal
      class="planner_insertFile_modal"
      type="evergreen"
    ></InsertFileModal>
    <div class="automation_main_component create_automation_main_component">
      <div class="component_inner">
        <button
          class="close_icon float-right"
          @click="
            exitConfirmation('evergreenAutomationListing','Evergreen', getWorkspaces.activeWorkspace.slug)
          "
        >
          &times;
        </button>

        <!--<router-link class="close_icon float-right" :to="{'name': 'automation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
        <!--&times;-->
        <!--</router-link>-->

        <div class="top_content">
          <h2>New Campaign</h2>
          <img
            src="../../../../../assets/img/automation/article_social.svg"
            alt=""
          />
          <h3>Evergreen</h3>
          <p
            >Recycle your evergreen posts at pre-defined time intervals over and
            over again for increased engagement.</p
          >
        </div>

        <!-- Top steps component -->
        <TabsContent
          :active-tab="getEvergreenSelection.tabStatus"
        ></TabsContent>

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
