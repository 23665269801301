// libs
import {computed} from "vue";

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function useTopHashtags() {
    const {
        fetchCompetitorInfo: fetchTopHashtags,
        apiResponse: topHashtagsResponse,
        loading: topHashtagsLoadingState
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone, reportCompetitorsInfo} = useCompetitorReport()

    const getTopHashtags = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonTopHashtags()
                break
            default:
                break
        }
    }

    const fetchCommonTopHashtags = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }

        try {
            await fetchTopHashtags(COMMON_API_ENDPOINTS.TOP_HASHTAGS, payload, COMMON_ERROR_MESSAGES.TOP_HASHTAGS)
            topHashtagsResponse.value = replaceCompetitorIDs(topHashtagsResponse.value, getAddedCompetitors.value)
        } catch (error) {
        }
    }

    const replaceCompetitorIDs = (data, competitors) => {
        if (data && competitors?.length) {
            data.forEach((item) => {
                item.companies_using.forEach((companyId, index) => {
                    const company = competitors.find(
                        (obj) => obj.accountId === companyId
                    )
                    if (company) {
                        item.companies_using[index] = company
                    }
                })
            })
            return data
        }
    }

    const getAddedCompetitors = computed(() => {
        return reportCompetitorsInfo.value?.competitors.map((competitor) => ({
            accountId: competitor.competitor_id,
            name: competitor.name,
            image: competitor.image,
            slug: competitor.slug,
        }))
    })

    return {
        // data
        topHashtagsLoadingState,
        topHashtagsResponse,

        // methods
        getTopHashtags
    }
}