import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    getHashtagsSeries (data) {
      return [
        {
          name: 'Occurrences',
          type: 'wordcloud',
          data
        }
      ]
    },
    getAudienceGrowthSeries (audience) {
        audience?.audience_growth?.followers?.forEach(function (val, index) {
              if (index > 0) {
                if (val === 0) {
                  audience.audience_growth.followers[index] = audience?.audience_growth?.followers[index - 1]
                }
              }
            }
        )
      return [
        {
          name: 'Followers',
          data: audience?.audience_growth?.followers,
          color: '#ffe202'
        }
      ]
    },
    getPublishingBehaviourSeries (publishing_behaviour) {
      return [
        {
          name: 'Image Posts',
          data: publishing_behaviour?.publishing_behaviour?.image,
          color: '#ffe202'
        },
        {
          name: 'Video Posts',
          data: publishing_behaviour?.publishing_behaviour?.video,
          color: '#2fe095'
        },
        {
          name: 'Carousel Album',
          data: publishing_behaviour?.publishing_behaviour?.carousel_album,
          color: '#e02f5f'
        },
        {
          name: 'Reels',
          data: publishing_behaviour?.publishing_behaviour?.reel,
          color: '#4cc9f0'
        }
      ]
    },
    getActiveUsersSeries (active_users, period) {
      if (period === 'days') {
        return [
          {
            name: 'Active Users By Days',
            data: active_users?.active_users_days?.values,
            color: '#2fe095'
          }
        ]
      }
      if (period === 'hours') {
        return [
          {
            name: 'Active Users By Hours',
            data: active_users?.active_users_hours?.values,
            color: '#6173ff'
          }
        ]
      }
    },
    getImpressionsSeries (impressions) {
      return [
        {
          name: 'Impressions',
          data: impressions?.impressions?.impressions,
          color: '#2fe095'
        }
      ]
    },
    getEngagementSeries (engagement) {
      return [
        {
          name: 'Total Engagement',
          data: engagement?.engagements?.engagement,
          color: '#ffe202',
          type: 'column'
        }
      ]
    },
    getReelsSeries (reels_performance, selectedReelMetric) {
      if (selectedReelMetric === 'Engagement')
      {
         return [
        {
          name: 'Total Engagement',
          data: reels_performance?.reels?.engagement,
          color: '#123212',
          type: 'column'
        }
      ]
      }
      else if (selectedReelMetric === 'Watch Time (sec)')
      {
        return [
        {
          name: 'Total Watch Time (sec)',
          data: reels_performance?.reels?.total_watch_time,
          color: '#f3f232',
          type: 'column'
        }
        ]
      }
      else if (selectedReelMetric === 'Shares')
      {
        return [
          {
            name: 'Total Shares',
            data: reels_performance?.reels?.shares,
            color: '#23f232',
            type: 'column'
          }
        ]
      }
    },
    getReelsRollupTooltips(value){
      if (value === 'Total Reels') {
         return 'See how many reels have been posted within the selected time period.'
      }
      else if (value === 'Total Time Watched (sec)') {
        return 'See the cumulative watch time of your reels, posted within the selected time period.'
      }
      else if (value === 'Shares') {
        return 'See how often your reels, posted within the selected time period, were shared.'
      }
      else if (value === 'Engagement'){
        return 'See how the Facebook community engaged with your reels, posted within the selected time period.'
      }
      else if (value === 'Avg. Time Watched (sec)') {
        return 'See the average watch time per play of your reels, posted within the selected time period.'
      }
    },
    getReelDropdownOptions(){
      return [
        "Engagement",
        "Watch Time (sec)",
        "Shares"
      ]
    },
    getStoriesPerformanceSeries (stories_performance) {
      return [
        {
          name: 'Story Replies',
          data: stories_performance?.stories_performance?.story_reply,
          color: '#ffe202',
          type: 'column'
        },
        {
          name: 'Story Taps Back',
          data: stories_performance?.stories_performance?.story_taps_back,
          color: '#2fe095',
          type: 'column'
        },
        {
          name: 'Story Taps Forward',
          data: stories_performance?.stories_performance?.story_taps_forward,
          color: '#6173ff',
          type: 'column'
        },
        {
          name: 'Story Exits',
          data: stories_performance?.stories_performance?.story_exits,
          color: '#e02f5f',
          type: 'column'
        }
      ]
    },
    getStoriesPublishedSeries (stories_performance) {
      return [
        {
          name: 'Stories Published',
          data: stories_performance?.stories_performance?.published_stories,
          color: '#e02f5f',
          type: 'column'
        }
      ]
    }
  }
}
