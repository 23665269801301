<template>
  <div class="setting_groups_component">

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const TimeQueue = () => import('../../integration/components/dialogs/TimeQueue')
const AddGroup = () => import('./AddGroup')
export default {
  components: {
    TimeQueue,
    AddGroup
  },

  data () {
    return {}
  },

  created () {
    // this.initializeGroupSection()
    // this.fetchGroup()
  },
  mounted () {},

  computed: {
    ...mapGetters(['getGroupStatus', 'getGroup', 'getGroupLoader'])
  },

  methods: {
    ...mapActions(['setCurrentGroup']),
    createGroup (data) {
      $('#addGroup').modal('show')
      this.setCurrentGroup(data)
    }
  }
}
</script>
