<template>
  <div class="social_dropdown_list">
    <div class="social_checkbox_list">
      <div class="social_checkbox_btn">
        <input
          id="wordpress_ck"
          v-model="getBlogOptions.wordpress.selection"
          type="checkbox"
          @change="
            changeCurationType('wordpress', getBlogOptions.wordpress.selection)
          "
        />
        <label
          for="wordpress_ck"
          class="wordpress_icon_checkbox d-flex align-items-center justify-content-center"
        >
          <i class="cs-wordpress"></i>
        </label>
      </div>
      <div class="social_checkbox_btn">
        <input
          id="tumblr_ck"
          v-model="getBlogOptions.tumblr"
          type="checkbox"
          @change="changeCurationType('tumblr', getBlogOptions.tumblr)"
        />
        <label
          for="tumblr_ck"
          class="tumblr_icon_checkbox d-flex align-items-center justify-content-center"
        >
          <i class="cs-tumblr"></i>
        </label>
      </div>
      <div class="social_checkbox_btn">
        <input
          id="medium_ck"
          v-model="getBlogOptions.medium"
          type="checkbox"
          @change="changeCurationType('medium', getBlogOptions.medium)"
        />
        <label
          for="medium_ck"
          class="medium_icon_checkbox d-flex align-items-center justify-content-center"
        >
          <i class="cs-medium"></i>
        </label>
      </div>
      <div class="social_checkbox_btn">
        <input
          id="shopify_ck"
          v-model="getBlogOptions.shopify.selection"
          type="checkbox"
          @change="
            changeCurationType('shopify', getBlogOptions.shopify.selection)
          "
        />
        <label
          for="shopify_ck"
          class="shopify_icon_checkbox d-flex align-items-center justify-content-center"
        >
          <i class="shopify_icon small_icon"></i>
        </label>
      </div>
      <div class="social_checkbox_btn">
        <input
          id="webflow_ck"
          v-model="getBlogOptions.webflow"
          type="checkbox"
          @change="changeCurationType('webflow', getBlogOptions.webflow)"
        />
        <label
          for="webflow_ck"
          class="webflow_icon_checkbox d-flex align-items-center justify-content-center"
        >
          <i class="webflow_icon small_icon"></i>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getBlogOptions'])
  },
  methods: {
    changeCurationType (type, status) {
      console.log('Method::changeCurationType', type, status)
      console.debug('Method::changeCurationType', type, status)
      if (status) {
        const options = this.getBlogOptions
        switch (type) {
          case 'wordpress':
            options.medium = false
            options.tumblr = false
            options.shopify.selection = false
            options.webflow = false
            return false
          case 'medium':
            options.wordpress.selection = false
            options.tumblr = false
            options.shopify.selection = false
            options.webflow = false
            return false
          case 'tumblr':
            options.wordpress.selection = false
            options.medium = false
            options.shopify.selection = false
            options.webflow = false
            return false
          case 'shopify':
            options.wordpress.selection = false
            options.medium = false
            options.tumblr = false
            options.webflow = false
            return false
          case 'webflow':
            options.wordpress.selection = false
            options.medium = false
            options.tumblr = false
            options.shopify.selection = false
            return false
        }
      }
    }
  }
}
</script>
