<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted } from 'vue'

import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/facebook_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/facebook_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/facebook_v2/components/graphs/AudienceGrowthChart.vue'
import ImpressionsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/ImpressionsChart.vue'
import EngagementsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/EngagementsChart.vue'
import ActiveUserByHour from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByHour.vue'
import ActiveUserByDay from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByDay.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import ReelsPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/ReelsPerformanceChart.vue'
import RollupCard from '@/src/modules/analytics/views/facebook_v2/components/RollupCard.vue'
import VideoPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/VideoPerformanceChart.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/PublishingBehaviourChart.vue'
import DeprecatedBanner from '@/src/modules/analytics/views/facebook_v2/components/DeprecatedBanner.vue'

const { getters } = useStore()
const {
  routes,
  dateRange,
  selectedAccount,
  rollupTypes,
  fetchMedia,
  showActiveUserChart,
} = useFacebookAnalytics()

const facebookBannerStatus = ref(true)

const shouldShowBanner = computed(() => {
  const fbAnalyticsBanner =
    getters?.getProfile?.preferences?.fb_analytics_banner
  return (
    (fbAnalyticsBanner === undefined || fbAnalyticsBanner) &&
    facebookBannerStatus.value
  )
})

watch(
  () => [dateRange.value, selectedAccount.value],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.OVERVIEW_AUDIENCE_GROWTH)
    fetchMedia(routes.OVERVIEW_ACTIVE_USERS)
    fetchMedia(routes.OVERVIEW_IMPRESSIONS)
    fetchMedia(routes.OVERVIEW_ENGAGEMENT)
    fetchMedia(routes.OVERVIEW_TOP_POSTS)
    fetchMedia(routes.OVERVIEW_REELS_ANALYTICS)
    fetchMedia(routes.OVERVIEW_VIDEO_INSIGHTS)
    fetchMedia(routes.OVERVIEW_PUBLISHING_BEHAVIOUR)
  }, 500),
  { deep: true, immediate: true }
)

onMounted(() => {
  EventBus.$on(
    'hide-fb-analytics-banner',
    () => (facebookBannerStatus.value = false)
  )
})

onBeforeUnmount(() => {
  EventBus.$off('hide-fb-analytics-banner')
})
</script>

<template>
  <div v-if="shouldShowBanner" class="mb-5">
    <DeprecatedBanner />
  </div>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-6 lg:grid-cols-8 2xl:grid-cols-6 gap-4 mb-4">
      <CardsComponent />
    </div>
    <div class="flex flex-col gap-y-4">
      <!-- Audience Growth Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <AudienceGrowthChart />
        </div>
        <RollupCard :type="rollupTypes.AUDIENCE" />
      </div>
      <!-- Impressions Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <ImpressionsChart />
        </div>
        <RollupCard :type="rollupTypes.IMPRESSIONS" />
      </div>
      <!-- Impressions Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <EngagementsChart />
        </div>
        <RollupCard :type="rollupTypes.ENGAGEMENTS" />
      </div>
      <!-- Publishing Behaviour Chart -->
      <PublishingBehaviourChart />

      <div
        v-if="showActiveUserChart"
        class="grid grid-cols-1 desktop:grid-cols-2 gap-4"
      >
        <!-- Active User By Hour -->
        <ActiveUserByHour />
        <!-- Active User By Day -->
        <ActiveUserByDay />
      </div>
      <!-- Video Performance Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <VideoPerformanceChart />
        </div>
        <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
      </div>
      <!-- Reels Performance Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <ReelsPerformanceChart />
        </div>
        <RollupCard :type="rollupTypes.REELS" />
      </div>
      <!-- Top Posts -->
      <TopPosts />
    </div>
  </div>
</template>

<style scoped></style>
