<template>
  <div
      id="tiktok_sharing_box"
      class="input_field social_content_box social_content_box_multi"
      :class="{
      in_active: getSocialSharingSelection.activeTab !== 'tiktok_tab',
      editor_hover: isFileHover,
    }"
      @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Tiktok' })
    "
      @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Tiktok' })
    "
      @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Tiktok' })"

      @click="focusSharingBox('Tiktok')"
  >
    <div class="box_social_tag tiktok_bg">
      <img src="@src/assets/img/integration/tiktok-icon_1.svg" alt="tiktok_logo" />
<!--      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" style="width: 15px;">
        <path fill="#ffffff" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
      </svg>-->
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <Options
        type="Tiktok"
        :class="{ hide: getSocialSharingSelection.activeTab !== 'tiktok_tab' }"
    ></Options>
    <VideoSection
        v-if="
          (getTiktokSharingDetails.video &&
          getTiktokSharingDetails.video.link) ||
        getPublishLoaders.upload_tiktok_sharing_video
      "
        :class="{ hide: getSocialSharingSelection.activeTab !== 'tiktok_tab' }"
        type="Tiktok"
        :details="getTiktokSharingDetails"
    ></VideoSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Options from '../options/Options'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'
import { commonTypes } from '../../../../store/states/mutation-types'

export default {
  components: {
    Options,
    ImageSection,
    VideoSection,
    HashtagSuggestions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {
    this.$store.commit(
        commonTypes.SET_YOUTUBE_BOX_REF,
        this.$refs.youtube_sharing_message
    )
  },

  computed: {
    ...mapGetters([
      'getTiktokSharingDetails',
      'getPublishLoaders',
      'getSocialSharingSelection',
      'getTiktokSharingLoaders'
    ]),
    isFileHover () {
      return (
          this.getSocialSharingSelection.drag_over_status.youtube &&
          this.getSocialSharingSelection.activeTab === 'youtube_tab'
      )
    }
  },
  methods: {
    ...mapActions([]),
    handleKeysOnPopup (e) {
      if (
          this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
          (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('youtube_sharing_message').value
      if (!text) {
        const message = this.getYoutubeSharingDetails.message
        document.getElementById('youtube_sharing_message').value = message
        this.setSharingBoxHtml(
            this.processSharingBoxHtml(message, 'Youtube'),
            'Youtube'
        )
      }
    }
  },

  watch: {
    'getYoutubeSharingDetails.message' (value) {
      this.setSharingBoxHtml(
          this.processSharingBoxHtml(value, 'Youtube'),
          'Youtube'
      )
    }
  }
}
</script>
