<script setup>
import {platformAccountsList} from "@common/composables/useSocialChannels";
import isEmpty from "lodash/isEmpty";
import {useStore} from "@state/base";
import {reactive, watch} from "vue";
import {retryPosting} from "@src/modules/planner_v2/composables/postStatus";
import {platformPosting} from "@common/lib/planner";
import {getPlatformIdentifierValue} from "@common/lib/integrations";
const state = reactive({
  statusUrl: '',
  errorMsg: '',
  isError: false,
  isViewMore: true,
})
const props = defineProps({
  account: {
    type: Object,
    required: true,
  },
  plan: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },

})
const store = {
  getters: useStore().getters,
}

// return the full detail of current blog account
const getFullBlogAccount = (id) => {
  const accountsList = platformAccountsList(store)
  console.log('accounList',accountsList.find((item) => item?.platform_identifier === id))
  if (!isEmpty(accountsList)) {
    return accountsList.find((item) => item?.platform_identifier === id)
  }
}

// to check if the live link is available and also return the link
const isLink = (id, checkLink = false) => {
  const accountsList = platformAccountsList(store)
  if (!isEmpty(accountsList)) {
    const accountDetail = accountsList.find((item) => item?.platform_identifier === id)
    const postedBlogObj = platformPosting(props.plan, props.type, getPlatformIdentifierValue(props.account, props.type))
    if(checkLink) {
      return postedBlogObj?.hasOwnProperty('link')
    }
    else {
      return postedBlogObj?.link
    }
  }
}

// to get the blog image
const getBlogImage = (platform) => {
  switch (platform) {
    case 'wordpress':
      return 'wordpress.svg'
    case 'medium':
      return 'medium.svg'
    case 'webflow':
      return 'webflow.svg'
    case 'tumblr':
      return 'tumblr-rounded.svg'
    case 'shopify':
      return 'shopify.svg'
  }
}

// return the blog posting status image
const getStatusUrl = () => {
  const accountsList = platformAccountsList(store)
  if (!isEmpty(accountsList)) {
    const accountDetail = accountsList.find((item) => item?.platform_identifier === props.account.website)
    const postedBlogObj = platformPosting(props.plan, props.type, getPlatformIdentifierValue(props.account, props.type))
    console.log('postedBlogObj',postedBlogObj)
    if(postedBlogObj?.error){
      return require('@assets/img/icons/planner/failed_icon.svg')
    }else {
      return require('@assets/img/icons/planner/published_icon.svg')
    }
  }
}

// set the platform posting error details
const setStatusDetail = () => {
  const accountsList = platformAccountsList(store)
  if (!isEmpty(accountsList)) {
    const accountDetail = accountsList.find((item) => item?.platform_identifier === props.account.website)
    const postedBlogObj = platformPosting(props.plan, props.type, getPlatformIdentifierValue(props.account, props.type))
    if(postedBlogObj?.error){
      state.errorMsg = postedBlogObj?.error_message
      state.isError = true
    }else {
      state.isError = false
    }
  }
}

const isInProgress = () => {
  const posting = platformPosting(props.plan, props.type, getPlatformIdentifierValue(props.account, props.type))
  if(posting?.retrying){
    return true
  }
}

watch(() => props.plan,() => {
  setStatusDetail()
}, { immediate: true })



</script>

<template>
  <template v-if="account.website">
<div
    class="group flex items-center cursor-pointer px-6 py-4 border !border-b border-[#ECEEF5] !border-r-0 !border-t-0 bg-[#E3F2FF] pl-4 !border-l-[#2593FC] !border-l-8"
    @click="setStatusDetail"
>
                      <span class="relative">
                        <img
                            :src="getFullBlogAccount(account.website).platform_logo"
                            alt=""
                            class="rounded-full object-cover border w-[2.625rem] h-[2.625rem]"
                            data-fallback="0"
                            @error="
                            onError(
                              $event,
                              getFullBlogAccount(account.website).platform_logo,
                            )
                          "
                        />

                        <img
                            v-if="
                            getFullBlogAccount(account.website).integration ===
                            'wordpress'
                          "
                            src="https://storage.googleapis.com/lumotive-web-storage/default/wordpress.png"
                            alt=""
                            class="absolute -right-0.5 -bottom-0.5 w-4 h-4"
                        />

                        <img
                            v-else
                            :src="
                            require('@assets/img/integration/' +
                              getBlogImage(
                                getFullBlogAccount(account.website).integration,
                              ))
                          "
                            alt=""
                            class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                        />
                      </span>
                      <span class="flex flex-col ml-3 flex-1">
                        <span
                            v-tooltip="{
                            content:
                              getFullBlogAccount(account.website).platform_name
                                .length > 12
                                ? getFullBlogAccount(account.website)
                                    .platform_name
                                : '',
                          }"
                            class="text-sm font-normal text-black-100 select-none"
                        >
                          {{
                            truncateString(
                                getFullBlogAccount(account.website).platform_name,
                                12,
                            )
                          }}
                        </span>

                        <span class="block text-xs select-none text-gray-700">
                          Blog
                        </span>
                      </span>
                      <div v-if='(plan.post_state === "published" || plan.post_state === "failed") && !isInProgress()' class="flex align-items-center" >
                          <!-- status section -->
                          <div v-if="isLink(account.website, true)"  class="flex align-items-center">
                            <a :href="isLink(account.website, false)" class="group-hover:bg-[#F2F3F8] border border-[#ECEEF5] rounded-full px-2 py-1 " target="_blank" v-tooltip="'Live Link'">
                              <img src="@assets/img/icons/live-link.svg" alt="Live link" class="w-[0.875rem] h-[0.875rem]"/>
                            </a>
                            <div class="ml-3 h-[1.2rem] w-px bg-[#D2D5DF]"></div>
                          </div>
                          <img :src="getStatusUrl()" alt="status icon" class="ml-3 w-6 h-6">
                      </div>

                    </div>
    <Transition name="bounce">
      <div v-if="state.isError && !isInProgress()" class="py-4 px-[2.5rem] bg-[#F4F6FA] flex flex-col">
        <div class="text-[#202324] text-sm text-left">
          {{state.errorMsg}} [Failed]
        </div>
        <div class="flex align-items-center justify-content-center">
          <div @click="retryPosting(plan, account, type)" class="px-2 py-[0.625rem] text-[#2961D2] hover:text-[#214EA8] cursor-pointer">
            <span class="mr-2">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2489 0.999326C9.15047 0.750802 8.00703 0.784572 6.92522 1.09749C5.84686 1.4094 4.86477 1.98856 4.06999 2.78118L2.10726 4.62548V2.16716C2.10726 1.79897 1.80878 1.50049 1.44059 1.50049C1.0724 1.50049 0.773926 1.79897 0.773926 2.16716L0.773926 6.16684L0.773926 6.16716C0.773926 6.53535 1.0724 6.83383 1.44059 6.83383H5.44059C5.80878 6.83383 6.10726 6.53535 6.10726 6.16716C6.10726 5.79897 5.80878 5.50049 5.44059 5.50049H3.12316L4.99044 3.74588C4.99552 3.74111 5.00052 3.73626 5.00545 3.73134C5.64234 3.09413 6.43026 2.62864 7.29571 2.37831C8.16115 2.12798 9.0759 2.10097 9.95461 2.29979C10.8333 2.4986 11.6473 2.91678 12.3207 3.51529C12.9941 4.1138 13.5049 4.87313 13.8054 5.72245C13.9283 6.06954 14.3092 6.25135 14.6563 6.12853C15.0034 6.0057 15.1852 5.62475 15.0624 5.27765C14.6867 4.21601 14.0482 3.26684 13.2065 2.5187C12.3648 1.77057 11.3472 1.24785 10.2489 0.999326ZM16.7362 8.6118C16.7063 8.52689 16.6587 8.44668 16.5931 8.37686C16.4555 8.23048 16.2674 8.16005 16.0813 8.16721H12.1074C11.7392 8.16721 11.4408 8.46569 11.4408 8.83388C11.4408 9.20206 11.7392 9.50054 12.1074 9.50054H14.4237L12.5574 11.2542L12.5514 11.26L12.5424 11.2688C11.9055 11.906 11.1176 12.3715 10.2521 12.6218C9.3867 12.8721 8.47195 12.8991 7.59324 12.7003C6.71453 12.5015 5.90051 12.0833 5.22712 11.4848C4.55374 10.8863 4.04295 10.127 3.7424 9.27765C3.61958 8.93055 3.23863 8.74874 2.89153 8.87157C2.54443 8.9944 2.36262 9.37535 2.48545 9.72244C2.86113 10.7841 3.49962 11.7333 4.34135 12.4814C5.18308 13.2295 6.20061 13.7522 7.29899 14.0008C8.39738 14.2493 9.54082 14.2155 10.6226 13.9026C11.701 13.5907 12.6831 13.0115 13.4778 12.2189L15.4408 10.3745V12.8339C15.4408 13.2021 15.7392 13.5005 16.1074 13.5005C16.4756 13.5005 16.7741 13.2021 16.7741 12.8339V8.83388C16.7741 8.75601 16.7607 8.68126 16.7362 8.6118Z" fill="#2961D2"/>
          </svg>
        </span>
            <span class="text-xs ">Retry</span>
          </div>
        </div>
      </div>
    </Transition>

  </template>
</template>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.svg-hover:hover svg path {
  fill: #214EA8;
}

</style>
