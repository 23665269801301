<template>
  <!-- Check if detail.url exists and if there are multimedia items -->
  <div class="px-2" :class="{'pl-16': isMainPreview}">
    <div class="border rounded-xl">
      <!-- Image container with dynamic background image -->
      <div
          class="bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px] rounded-tl-xl rounded-tr-xl"
          :style="{ 'background-image': 'url(' + detail.multimedia[0] + ')' }"
      >
        <!-- Invisible image for handling loading errors -->
        <img
            class="max-w-full max-h-full opacity-0 w-full"
            :src="detail.multimedia[0]"
            alt=""
            @error="handleImageError"
        />
      </div>
      <!-- Check if detail.website or detail.title exists -->
      <div v-if="detail.website || detail.title" class="rounded-bl-xl rounded-br-xl w-full h-max py-4 px-3 relative">
        <!-- Display website host if detail.website exists -->
        <div v-if="detail.website" class="block text-gray-700 text-sm">
          {{ getHostOnly(detail.website) }}
        </div>
        <!-- Display title if detail.title exists -->
        <div v-if="detail.title" class="text-[0.9rem] mt-1 text-gray-900 leading-4 truncate">
          {{ detail.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Define the props
const props = defineProps({
  detail: {
    type: Object,
    required: true
  },
  isMainPreview: {
    type: Boolean,
    default: true,
    required: false
  }
})

// Function to extract the host from a URL
const getHostOnly = (url) => {
  const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
  return urlParts[1] ? urlParts[1] : ''
}

// Function to handle image loading errors
const handleImageError = (event) => {
  event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
  event.target.classList.remove('opacity-0')
}
</script>
