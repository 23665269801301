<template>
  <div class="input_field">
    <label class="heading_label" for="">Refine Your Query</label>
    <div class="multi_select">
      <!--<div class="d-flex align-items-center mb-3">-->
      <!--<p class="sub_label">Each result must contain</p>-->
      <!--<div  class=" ml-4 mr-4 with_background_open_blue dropdown option_dropdown default_style_dropdown">-->

      <!--<div style="padding: 9px 12px;" class="dropdown_header d-flex align-items-center" data-toggle="dropdown">-->

      <!--<span class="text">All</span>-->
      <!--<span class=" ml-auto">-->
      <!--<span class="dropdown_arrow icon_last ">-->
      <!--<span class="icon"></span>-->
      <!--</span>-->
      <!--</span>-->
      <!--</div>-->

      <!--<div class="dropdown-menu dropdown-menu-right">-->
      <!--<ul class="inner">-->
      <!--<li class="list_item_li">-->
      <!--All-->
      <!--</li>-->
      <!--<li class="list_item_li">-->
      <!--One-->
      <!--</li>-->

      <!--</ul>-->
      <!--</div>-->
      <!--</div>-->
      <!--<p class="sub_label">of these keywords</p>-->
      <!--</div>-->
      <div class="d-flex">
        <p class="sub_label"
          >Each result <span class="bold">MUST ALSO </span>contain
          <span class="bold">ONE</span> of these keywords</p
        >
        <button
          v-if="
            getTopicSelection.must_also_keywords.length > 0 ||
            (mustAlsoKeywordValue !== null &&
              mustAlsoKeywordValue.replace(/^\s+/, '') !== '')
          "
          class="reset_btn"
          @click="clearAllKeywords"
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <div class="multi_input">
        <input
          v-model="mustAlsoKeywordValue"
          class="prevent-listener"
          type="text"
          placeholder="Enter keywords or phrases, e.g. tips, trends…"
          data-cy="include-keyword"
          @keyup.enter="
            addAnyKeyword(
              getTopicSelection.must_also_keywords,
              mustAlsoKeywordValue,
              'must_also_keyword',
            )
          "
          @keyup.188="
            addAnyKeyword(
              getTopicSelection.must_also_keywords,
              mustAlsoKeywordValue,
              'must_also_keyword',
            )
          "
        />
        <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
      </div>
      <template v-if="getTopicSelection.must_also_keywords">
        <div class="multi_tags">
          <ul>
            <li
              v-for="(
                must_also_keyword, index
              ) in getTopicSelection.must_also_keywords"
              class="green"
            >
              <span class="value">{{ must_also_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(getTopicSelection.must_also_keywords, index)
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      mustAlsoKeywordValue: null
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection'])
  },
  methods: {
    clearAllKeywords () {
      this.mustAlsoKeywordValue = ''
      this.getTopicSelection.must_also_keywords = []
    }
  }
}
</script>
