// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function useIndividualHashtagData() {
    const {
        fetchCompetitorInfo: fetchIndividualHashtagData,
        apiResponse: individualHashtagDataResponse,
        loading: individualHashtagDataLoadingState
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone} = useCompetitorReport()
    const getIndividualHashtag = async (type = '', hashtag) => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonSingleHashtag(hashtag)
                break
            default:
                break
        }
    }

    const fetchCommonSingleHashtag = async (hashtag) => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
            hashtag,
        }

        try {
            await fetchIndividualHashtagData(COMMON_API_ENDPOINTS.INDIVIDUAL_HASHTAG_DATA, payload, COMMON_ERROR_MESSAGES.INDIVIDUAL_HASHTAG_DATA)
            if(individualHashtagDataResponse.value) {
                individualHashtagDataResponse.value = { [hashtag.substring(1)]: individualHashtagDataResponse.value }
            }
        } catch (error) {
        }
    }

    return {
        // data
        individualHashtagDataLoadingState,
        individualHashtagDataResponse,

        // methods
        getIndividualHashtag
    }
}