<template>
  <div class="name popover-social-statistics__container">
    <p>Engagement</p>
    <div class="popover-social-statistics__container__box">
      <div class="popover-social-statistics__container__box__inner">
        <div
          v-tooltip.top="'Facebook Shares'"
          class="popover-social-statistics__container__box__inner__item"
        >
          <span class="icon_item facebook_color">
            <i class="fb fab fa-facebook-f"></i>
          </span>
          <span v-if="facebook_shares">{{ facebook_shares }}</span>
          <span v-else>0</span>
        </div>
<!--        <div-->
<!--          v-tooltip.top="'Twitter Shares'"-->
<!--          class="popover-social-statistics__container__box__inner__item"-->
<!--        >-->
<!--          <span class="icon_item">-->
<!--            <i class="tw fab fa-twitter"></i>-->
<!--          </span>-->
<!--          <span v-if="twitter_shares">{{ twitter_shares }}</span>-->
<!--          <span v-else>0</span>-->
<!--        </div>-->
<!--        <div-->
<!--          v-tooltip.top="'Pinterest Shares'"-->
<!--          class="popover-social-statistics__container__box__inner__item"-->
<!--        >-->
<!--          <span class="icon_item">-->
<!--            <i class="pin fab fa-pinterest-p"></i>-->
<!--          </span>-->
<!--          <span v-if="pins_shares">{{ pins_shares }}</span>-->
<!--          <span v-else>0</span>-->
<!--        </div>-->
<!--        <div-->
<!--          v-tooltip.top="'Reddit Shares'"-->
<!--          class="popover-social-statistics__container__box__inner__item"-->
<!--        >-->
<!--          <span class="icon_item">-->
<!--            <i class="reddit fab fa-reddit"></i>-->
<!--          </span>-->
<!--          <span v-if="reddit_shares">{{ reddit_shares }}</span>-->
<!--          <span v-else>0</span>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    facebook_shares: {},
    twitter_shares: {},
    pins_shares: {},
    reddit_shares: {}
  }
}
</script>
