import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import {
  getKeywordsSuggestionURL,
  getFeedsAutoCompleteURL
} from '../config/api-utils'

export const autocompleteMixin = {
  computed: {
    ...mapGetters(['getFilters', 'getSearchRequests', 'getSearchContentType'])
  },
  methods: {
    ...mapActions([
      'setArticleSuggestion',
      'searchSuggestions',
      'articlesPreviewSuggestion',
      'articlesPreviewSuggestion',
      'articlesPreviewSuggestion'
    ]),
    changeSearchKeyDown: debounce(function (event) {
      console.debug('changeSearchKeyDown', event)
      if (
        event.key !== 'Enter' &&
        this.getFilters.search.length >= 3 &&
        this.getSearchContentType === 'article'
      ) {
        this.searchSuggestions()
      } else {
        /*
        //NOTE: not sure why are we calling this...
        console.log('Autocomplete performing search...')
        this.setArticleSuggestion(false)
        this.setDomain(null)
        if (event.key === 'Enter') {
          this.changeSearch()
        } */

        $('.header_search_input').blur()
      }
    }, 250),

    asyncIncludeDomainResults: debounce(function (query) {
      console.debug('Method:asyncLimitDomainResults:_.debounce')

      if (query.length >= 3) {
        console.debug(
          'AsyncIncludeDomainResults query length is greater than 3'
        )
        this.isIncludeDomainsLoading = false
        this.includeDomainsLoader = true
        this.include_domains_suggestion_list = []
        this.postRequest(
          getFeedsAutoCompleteURL,
          {
            keyword: query
          },
          (response) => {
            const stateObject = this
            this.include_domains_suggestion_list = []

            response.data.forEach(function (element) {
              const item = element._source
              item.url = stateObject.trimURL(item.url)

              if (!this.getTopicSelection.include_domains.includes(item.url)) {
                this.include_domains_suggestion_list.push(item)
              }
            }, this)

            this.isIncludeDomainsLoading = true
            this.includeDomainsLoader = false
          },
          (response) => {
            console.debug('Error in asyncIncludeGetFeeds:_.debounce', response)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )

        console.debug(query)
      }
    }, 250),

    asyncExcludeDomainResults: debounce(function (query) {
      console.debug('Method:asyncLimitDomainResults:_.debounce')

      if (query.length >= 3) {
        console.debug(
          'AsyncIncludeDomainResults query length is greater than 3'
        )
        this.isExcludeDomainsLoading = false
        this.excludeDomainsLoader = true
        this.exclude_domains_suggestion_list = []
        this.postRequest(
          getFeedsAutoCompleteURL,
          {
            keyword: query
          },
          (response) => {
            const stateObject = this
            this.exclude_domains_suggestion_list = []

            response.data.forEach(function (element) {
              const item = element._source
              item.url = stateObject.trimURL(item.url)
              if (!this.getTopicSelection.exclude_domains.includes(item.url)) {
                this.exclude_domains_suggestion_list.push(item)
              }
            }, this)

            this.isExcludeDomainsLoading = true
            this.excludeDomainsLoader = false
          },
          (response) => {
            console.debug('Error in asyncIncludeGetFeeds:_.debounce', response)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )

        console.debug(query)
      }
    }, 250),
    asyncLimitDomainResults: debounce(function (query) {
      console.debug('Method:asyncLimitDomainResults:_.debounce')

      if (query.length >= 3) {
        console.debug(
          'AsyncIncludeDomainResults query length is greater than 3'
        )
        this.isLimitDomainsLoading = false
        this.limitDomainsLoader = true
        this.limit_domains_suggestion_list = []
        this.postRequest(
          getFeedsAutoCompleteURL,
          {
            keyword: query
          },
          (response) => {
            const stateObject = this
            this.limit_domains_suggestion_list = []

            response.data.forEach(function (element) {
              const item = element._source
              item.url = stateObject.trimURL(item.url)
              if (
                !this.getTopicSelection.limit_domains_results.includes(item.url)
              ) {
                this.limit_domains_suggestion_list.push(item)
              }
            }, this)

            this.isLimitDomainsLoading = true
            this.limitDomainsLoader = false
          },
          (response) => {
            console.debug('Error in asyncIncludeGetFeeds:_.debounce', response)
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )

        console.debug(query)
      }
    }, 250),
    addIncludeDomain (domain, index) {
      if (!this.getTopicSelection.include_domains.includes(domain.url)) {
        this.getTopicSelection.include_domains.push(domain.url)
        this.include_domains_suggestion_list.splice(index, 1)
      }
      this.articlesPreviewSuggestion()
    },

    addExcludeDomain (domain, index) {
      if (!this.getTopicSelection.exclude_domains.includes(domain.url)) {
        this.getTopicSelection.exclude_domains.push(domain.url)
        this.exclude_domains_suggestion_list.splice(index, 1)
      }
      this.articlesPreviewSuggestion()
    },
    addLimitDomain (domain, index) {
      if (!this.getTopicSelection.limit_domains_results.includes(domain.url)) {
        this.getTopicSelection.limit_domains_results.push(domain.url)
        this.limit_domains_suggestion_list.splice(index, 1)
      }
      this.articlesPreviewSuggestion()
    }
  }
}
