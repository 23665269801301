<template>
  <!--<h2 class="story_heading pink_block">Cover Stories From Your <span>Curated Topics</span></h2>-->
  <div class="topics-cover-stories-container__item">
    <div class="topics-cover-stories-container__item__inner">
      <div
        class="topics-cover-stories-container__item__inner__image__container"
      >
        <div class="top-cover-stories-action-buttons">
          <div class="top-cover-stories-action-buttons__list">
            <!-- share buttons -->
            <b-dropdown
              id="dropdown-share-articles"
              variant="studio-icon-theme"
              dropright
              class="studio-icon-theme-dropdown"
              no-caret
            >
              <template v-slot:button-content>
                <i
                  v-tooltip="'Share this post'"
                  class="far fa-share-alt icon"
                ></i>
              </template>
              <b-dropdown-item
                variant="studio-icon-theme"
                @click.prevent="shareToSocial(post.record_id)"
                ><i class="far fa-share"></i> Social Media
              </b-dropdown-item>
              <b-dropdown-item
                v-show="false"
                variant="studio-icon-theme"
                @click.prevent="routeToBlogPost(post.record_id)"
                ><i class="far fa-rss d-none"></i> Blog Post
              </b-dropdown-item>
            </b-dropdown>

            <!-- favorite article button -->
            <div class="dropdown b-dropdown studio-icon-theme-dropdown mt-3">
              <template v-if="post.is_starred">
                <button
                  @click.prevent="
                    EventBus.$emit('feeder_starred_is_collection', {
                      /* eslint-disable */
                      record_id: post.record_id,
                      index: index,
                      state: 'unstarred',
                      group_id: collection_uuid,
                    })
                  "
                  class="btn btn-studio-icon-theme"
                >
                  <i
                    v-tooltip="'Unfavorite'"
                    class="far fa-heart icon is-favorite"
                  ></i>
                  <span
                    :class="{ ' active': post.is_starred }"
                    class="icon_animate"
                  ></span>
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-studio-icon-theme"
                  @click.prevent="
                    EventBus.$emit('feeder_starred_is_collection', {
                      record_id: post.record_id,
                      index: index,
                      state: 'starred',
                      group_id: collection_uuid,
                      /* eslint-enable */
                    })
                  "
                >
                  <i v-tooltip="'Favorite'" class="far fa-heart icon"></i>
                </button>
              </template>
            </div>

            <!-- more actions button-->
            <b-dropdown
              id="dropdown-actions-articles"
              variant="studio-icon-theme"
              dropright
              class="studio-icon-theme-dropdown mt-3"
              no-caret
            >
              <template v-slot:button-content>
                <i class="cs-dots-h icon"></i>
              </template>

              <b-dropdown-item
                variant="studio-icon-theme"
                target="_blank"
                :href="savePocketContent(post.url)"
                ><i class="fab fa-get-pocket"></i> Add to Pocket
              </b-dropdown-item>

              <b-dropdown-item
                v-show="false"
                variant="studio-icon-theme"
                data-toggle="modal"
                data-target="#twitter_influencer_modal"
                @click.prevent="influencersWebPreview"
                ><i class="far fa-users"></i> X (Twitter) Influencers
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div
          :style="{ backgroundImage: `url(${backgroundImageURL(post)})` }"
          class="topics-cover-stories-container__item__inner__image__container__source"
        ></div>
      </div>
      <div class="topics-cover-stories-container__item__inner__content">
        <h3
          @click.prevent="routeToCoverArticlePreview(post.record_id)"
          v-html="limitTextLength(post.title, 60)"
        ></h3>
        <p class="topics-cover-stories-container__item__inner__content__date">
          {{ $filters.relative(post.post_date) }}</p
        >
        <p class="topics-cover-stories-container__item__inner__content__domain">
          {{ trimURL(post.url_netloc) }}</p
        >

        <template v-if="post.author">
          <p
            class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <span style="color: #9298a2 !important" v-html="post.author"></span>
            <template
              v-if="
                post.twitter_accounts &&
                post.twitter_accounts.length > 0 &&
                index < 1
              "
            >
              <a
                v-for="(account, index) in getTwitterAccountsForView(
                  post.twitter_accounts,
                )"
                :key="index"
                class="cursor-pointer"
                @click.prevent="getTwitterLink(account)"
              >
                {{ account }}</a
              >
            </template>
            <a
              v-else-if="post.twitter_handler"
              target="_blank"
              :href="getTwitterLink(post.twitter_handler)"
            >
              {{ post.twitter_handler }}</a
            >
          </p>
        </template>
        <template v-else-if="post.twitter_handler">
          <p
            class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <a target="_blank" :href="getTwitterLink(post.twitter_handler)">{{
              post.twitter_handler
            }}</a>
          </p>
        </template>
        <template
          v-else-if="post.twitter_accounts && post.twitter_accounts.length > 0"
        >
          <p
            class="topics-cover-stories-container__item__inner__content__author"
          >
            By
            <template v-if="index < 1">
              <a
                v-for="(account, index) in getTwitterAccountsForView(
                  post.twitter_accounts,
                )"
                :key="index"
                target="_blank"
                :href="getTwitterLink(account)"
                >{{ account }}</a
              >
            </template>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { feederItemMixin } from '@src/modules/discovery/components/feeder/mixins/feeder-item-mixin'
import {EventBus} from "@common/lib/event-bus";

export default {
  mixins: [feederItemMixin],
  props: {
    /* eslint-disable */
    collection_uuid: {},
    post: {
      type: Array,
      default: [],
    },
    /* eslint-enable */
  },
  computed: {
    EventBus() {
      return EventBus
    }
  },
  methods: {
    routeToFeederGroup() {
      console.log(this.collection_uuid)
      this.$router.push({
        name: 'feeder_collection',
        params: { collection_id: this.collection_uuid },
      })
    },
    routeToCoverArticlePreview(postId) {
      this.$router.push({
        name: 'aggregate_feed_item_preview',
        params: { post_id: postId },
        query: { module: 'feeds' },
      })
    },
    routeToShareSocial(postId) {
      const payload = {
        name: 'aggregate_feed_item_social_share',
        params: { post_id: postId },
        query: { ...this.$route.query, module: 'feeds' },
      }
      console.log(payload)
      this.$router.push(payload)
    },
    routeToBlogPost(postId) {
      const payload = {
        name: 'aggregate_feed_item_blog_post',
        params: { post_id: postId },
        query: { module: 'feeds' },
      }
      console.log(payload)
      this.$router.push(payload)
    },
  },
}
</script>
