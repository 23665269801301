<template>
  <div class="topics-filters-pane__container__right_section__view_change">
    <span
      v-tooltip.top="'Grid View'"
      class="grid_icon"
      :class="{ active: getDiscoveryView === 'grid' }"
      @click.prevent="changeGridViewPreference('grid')"
    >
      <i class="icon-groups-cs"></i>
    </span>
    <span
      v-tooltip.top="'List View'"
      class="list_icon"
      :class="{ active: getDiscoveryView === 'list' }"
      @click.prevent="changeGridViewPreference('list')"
    >
      <i class="icon-Listview_icon"></i>
    </span>
  </div>

  <!--    <div class="topics-filters-pane__container__right_section__view_change">-->
  <!--        &lt;!&ndash;<div class="view_left">&ndash;&gt;-->
  <!--        &lt;!&ndash;<h3 class="results">12,085 Results</h3>&ndash;&gt;-->
  <!--        &lt;!&ndash;</div>&ndash;&gt;-->

  <!--        <div class="view_change_btn_inner ml-auto" v-if="total > 0">-->
  <!--            <div class="view-change-icons">-->
  <!--                <p v-tooltip.top="'Grid View'" @click="changeGridViewPreference('grid')" class="grid_icon"-->
  <!--                   :class="{'active':getFeederPreferences.item_style === 'grid'}"-->
  <!--                >-->
  <!--                    <i class="icon-groups-cs"></i>-->
  <!--                </p>-->
  <!--                <p class="list_icon" v-tooltip.top="'List View'" @click="changeGridViewPreference('list')"-->
  <!--                   :class="{'active':getFeederPreferences.item_style === 'list'}"-->
  <!--                >-->
  <!--                    <i class="icon-Listview_icon"></i>-->
  <!--                </p>-->
  <!--            </div>-->
  <!--        </div>-->

  <!--    </div>-->
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { feederTypes } from '@src/modules/discovery/store/mutation-types'
import proxy from "@common/lib/http-common";
import {discoveryDefaultView} from "@src/modules/publish/config/api-utils";

export default {
  props: {
    total: {}
  },
  computed: {
    ...mapGetters(['getFeederPreferences','getDiscoveryView'])
  },
  methods: {
    ...mapActions(['setDiscoveryDefaultView']),
    /**
     * change view (list,grid)
     * @param typeOfView
     * @returns {Promise<void>}
     */
    async changeGridViewPreference (typeOfView) {
      this.$store.commit(feederTypes.CHANGE_ITEM_VIEW_PREFERENCE, typeOfView)
      if (this.getDiscoveryView === typeOfView) {
        return
      }
      try {
        await this.setDiscoveryDefaultView(typeOfView)
        await this.$router.replace({query: {...this.$route.query, view: typeOfView}})
      }catch(e){
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
.view-change-icons {
  align-items: center;
  display: inline-flex;
}
</style>
