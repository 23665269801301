import { mapGetters, mapActions, mapMutations } from 'vuex'
import { createApp, h } from 'vue'
import CstToast from '@ui/Toast/CstToast'
import { toast } from 'vue3-toastify'

const notificationMixin = {
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  methods: {
    ...mapActions(['setFetchImportantNotificationsLoader']),
    ...mapMutations(['SET_IMPORTANT_NOTIFICATIONS_ITEMS']),
    /**
     * To pop up a message
     * @param message The message string to be displayed to the user
     * @param type The type of the message to be displayed (info, error, success) etc
     * @param duration
     */
    alertMessage(message, type, duration = 5000) {
      if (this.$route.name === 'analytics_pdf_report') {
        return
      }
      toast(({ closeToast }) => h(CstToast, { closeToastCallback:closeToast, type, message }));
    },
    createAlertMessage(type, message) {
      const app = createApp({
        render() {
          return h(CstToast, { type, message })
        },
      })
      const component = app.mount(document.createElement('div'))
      return component.$el.outerHTML
    },
  },
}

export { notificationMixin }
