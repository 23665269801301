export const baseUrl = process.env.VUE_APP_BASE_URL
export const apiUrl = process.env.VUE_APP_CS_BACKEND_URL
export const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN
export const authenticationBaseUrl = apiUrl
export const accountBaseUrl = apiUrl
export const settingsBaseUrl = apiUrl
export const integrationsBaseURL = apiUrl
export const discoveryBaseUrl = apiUrl
export const feederBaseURL = process.env.VUE_APP_FEEDER_BASE_URL
export const influencerBaseURL = apiUrl
export const automationBaseUrl = apiUrl
export const composerBaseUrl = apiUrl
export const plannerBaseUrl = apiUrl
export const storageBaseUrl = apiUrl
export const analyticsBaseUrl = apiUrl
export const adminBaseUrl = apiUrl
export const billingBaseUrl = apiUrl

export const loginURL = authenticationBaseUrl + 'login'
export const registerURL = authenticationBaseUrl + 'register'
export const accountExistURL = authenticationBaseUrl + 'accountExist'
export const isAuthenticatedURL = authenticationBaseUrl + 'isAuthenticated'
export const refreshTokenURL = authenticationBaseUrl + 'refresh'
export const verifyEmailURL = authenticationBaseUrl + 'verifyEmail/code'
export const forgotPasswordURL = authenticationBaseUrl + 'forgotPassword'
export const resetPasswordTokenURL =
  authenticationBaseUrl + 'resetPasswordToken'
export const recoverPasswordURL = authenticationBaseUrl + 'recoverPassword'
export const resendVerificationEmail =
  authenticationBaseUrl + 'resendVerificationEmail'
export const ssoLinksURL = integrationsBaseURL + 'fetchSSOLinks'
export const loginWithMagicLinkURL = authenticationBaseUrl + 'magicLink'
export const checkValidMagicTokenURL = authenticationBaseUrl + 'checkMagictoken'
export const setPasswordURL = authenticationBaseUrl + 'setPassword'
// export const publicPath = process.env.BASE_URL

export const aiImageGenerationApiUrl = plannerBaseUrl + 'fetchAiImages'

export const fetchAllCaptionsApiUrl = plannerBaseUrl + 'caption/fetchAll'

export const saveCaptionApiUrl = plannerBaseUrl + 'caption/save'

export const deleteCaptionApiUrl = plannerBaseUrl + 'caption/'

export const updateCaptionApiUrl = plannerBaseUrl + 'caption/update'

export const usedCaptionApiUrl = plannerBaseUrl + 'caption/used/'

export const APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT
export const APP_RELEASE_VERSION = process.env.VUE_APP_RELEASE_VERSION
export const DROPBOX_APP_KEY = process.env.VUE_APP_DROPBOX_APP_KEY

export const RELEASE_WIDGET_VERSION = process.env.VUE_APP_RELEASE_WIDGET_VERSION

export const TEXT_IMAGE_PREVIEW_URL = process.env.VUE_APP_TEXT_IMAGE_PREVIEW

export const fetchAnnouncementURL = apiUrl + 'announcements/get'
export const fetchDashboardVideosURL = apiUrl + 'announcements/videos/get'
export const fetchDashboardBannerViewStatus =
  apiUrl + 'preferences/dashboardBannerViewStatus'

export const fetchComposerToolkitViewStatus =
  apiUrl + 'preferences/composerToolkitViewStatus'
export const setUserPreferences = apiUrl + 'preferences/setPreferences'

// ai-chat url

export const fetchAIChatsUrl = apiUrl + 'ai/fetchChats'

export const conversationSend = apiUrl + 'ai/chat'

export const conversationSendWithStream = apiUrl + 'ai/chatWithStreaming'

export const saveCustomPromptUrl = apiUrl + 'ai/saveCustomPrompts'

export const fetchCustomPromptsUrl = apiUrl + 'ai/fetchCustomPrompts'

export const deleteCustomPromptUrl = apiUrl + 'ai/removeCustomPrompts'

export const deleteChatMessage = apiUrl + 'ai/deleteMessage'

export const fetchChatByIdUrl = apiUrl + 'ai/fetchChat'

export const fetchAiTitlesUrl = apiUrl + 'ai/suggestBlogTitle'
