<script>
import { mapGetters } from 'vuex'
import { redirectConnectorRequest } from '@common/lib/integrations'
import { integrations } from '@src/modules/integration/store/states/mutation-types'
import {
  NULL_REPLUG_BRAND,
  NULL_REPLUG_CAMPAIGN,
} from '@common/constants/messages'

export default {
  components: {},
  data() {
    return {
      save_replug_settings_loader: false,
      bitly_link_visibility: true,
      replug_link_visibility: true,
      validations: {
        selected_brand: false,
        selected_cta: false,
      },
      messages: {
        selected_brand: NULL_REPLUG_BRAND,
        selected_cta: NULL_REPLUG_CAMPAIGN,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getBitlyAccount',
      'getIntegrationAuthorization',
      'getReplug',
    ]),
    bitlyLinkVisibilityStatus() {
      return (
        this.bitly_link_visibility && this.getIntegrationAuthorization.bitly
      )
    },
    replugLinkVisibilityStatus() {
      return (
        this.replug_link_visibility && this.getIntegrationAuthorization.replug
      )
    },
    isNoShorteningActive() {
      return !this.getWorkspaces.activeWorkspace.shortener
    },
    isCstuShortenerActive() {
      return this.getWorkspaces.activeWorkspace.shortener === 'cstudio'
    },
    isBitlyShortenerActive() {
      return this.getWorkspaces.activeWorkspace.shortener === 'bitly'
    },
    isBitlyConnected() {
      return this.getBitlyAccount.username
    },
    isGooglShortenerActive() {
      return this.getWorkspaces.activeWorkspace.shortener === 'googl'
    },
    isReplugShortenerActive() {
      return this.getWorkspaces.activeWorkspace.shortener === 'replug'
    },
    isReplugConnected() {
      return this.getReplug.item && this.getReplug.item.replug_email
    },
    isReplugEditMode() {
      return this.getReplug.edit_status
    },
    isReplugBrandSelected() {
      return this.getReplug.selected_brand
    },
    saveReplugSettingsLoaderStatus() {
      return this.save_replug_settings_loader
    },
    isInvalidSelectedBrand() {
      return this.validations.selected_brand
    },
    isInvalidSelectedCta() {
      return this.validations.selected_cta
    },
  },
  watch: {
    'getReplug.selected_brand'(value) {
      if (value) {
        console.debug('Watch::getReplug.selected_brand', value)
        this.validations.selected_brand = false
      }
    },
    'getReplug.selected_cta'(value) {
      if (value) {
        console.debug('Watch::getReplug.selected_cta', value)
        this.validations.selected_cta = false
      }
    },
  },
  methods: {
    changeDefaultShortener(shortener) {
      this.$store.dispatch('changeDefaultShortener', shortener)
    },
    changeBitlyBase(base) {
      this.$store.dispatch('changeBitlyBase', base)
    },
    isActiveBase(base) {
      return base === this.getBitlyAccount.base
    },
    /* eslint-disable camelcase */
    connectAccount(type, process, connector_id) {
      let link = ''
      const details = { type, process }
      switch (type) {
        case 'bitly':
          link = this.getIntegrationAuthorization.bitly
          this.bitly_link_visibility = false
          break
        case 'replug':
          link = this.getIntegrationAuthorization.replug
          this.replug_link_visibility = false
          break
      }
      if (typeof connector_id !== 'undefined') {
        details.connector_id = connector_id
      }
      redirectConnectorRequest(
        details,
        link,
        this.getWorkspaces.activeWorkspace
      )
    },
    changeReplugBrand() {
      this.$store.commit(integrations.SET_REPLUG_SELECTED_CTA, '')
    },
    changeReplugEditStatus(status) {
      this.$store.commit(integrations.SET_REPLUG_EDIT_STATUS, status)
    },
    async saveReplugSettings() {
      const settings = this.getReplug
      this.validations.selected_brand = this.requiredCheck(
        settings.selected_brand
      )
      this.validations.selected_cta = this.requiredCheck(settings.selected_cta)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        const payload = {
          brand: this.getReplug.selected_brand._id,
          cta: this.getReplug.selected_cta._id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          id: this.getReplug.item._id,
        }
        this.save_replug_settings_loader = true
        await this.$store.dispatch('saveReplugShortenerSettings', payload)
        this.save_replug_settings_loader = false
        this.changeReplugEditStatus(false)
      }
    },
    removeShortenerAccount(type, platform) {
      console.debug('Method::removeShortenerAccount', type, platform)
      this.$parent.type = type
      this.$parent.platform = platform
      this.$bvModal.show('removeIntegration')
    },
  },
}
</script>

<template>
  <div class="ps_box">
    <div id="link-Shortener-section" class="box_heading">
      <h2>Link Shortener</h2>
    </div>

    <div class="shortner_contain">
      <div class="radio_box">
        <div class="box_main">
          <div class="field_group">
            <label class="radio_container no_hover_color">
              <input
                id="nullintegration"
                type="radio"
                name="option2"
                :checked="isNoShorteningActive"
                @click="changeDefaultShortener('')"
              />
              <div for="nullintegration"
                >No Shortening
                <span class="check"></span>
              </div>
              <p>Clicks on links will not be tracked</p>
            </label>
          </div>
        </div>
      </div>

      <div class="radio_box">
        <div class="box_main">
          <div class="field_group">
            <label class="radio_container no_hover_color">
              <input
                id="cstu"
                type="radio"
                name="option2"
                :checked="isCstuShortenerActive"
                @click="changeDefaultShortener('cstudio')"
              />
              <div for="cstu" class="!normal-case flex items-center">
                Cstu.io
                <v-menu
                  :popper-triggers="['hover']"
                  placement="top"
                  popper-class="first-comment__info-popover"
                  :delay="300"
                  class="!px-1"
                >
                  <i
                    class="far fa-question-circle text-blue-400 cursor-pointer"
                  ></i>
                  <template v-slot:popper>
                    <p class="text-sm text-gray-900">
                      Links added to your social composer will be shortened
                      automatically.
                    </p>
                    <p class="text-sm text-gray-900">
                      You can still revert (unshorten) them if needed before
                      posting.
                    </p>
                  </template>
                </v-menu>
                <span class="check"></span>
              </div>
              <p>Automatically shorten links while composing.</p>
            </label>
          </div>
        </div>
      </div>

      <div class="radio_box">
        <div class="box_main">
          <div class="field_group">
            <label class="radio_container no_hover_color">
              <input
                id="replug"
                type="radio"
                name="option2"
                :disabled="!getReplug.item.cta"
                :checked="isReplugShortenerActive"
                @click="changeDefaultShortener('replug')"
              />
              <div for="replug" class="!normal-case">
                Replug.io
                <span class="check"></span>
              </div>
              <p class="sub-text"
                >Shorten, optimize and track performance of your links with
                detailed analytics. Replug also gives you the ability to add
                retargeting pixels and call-to-actions.</p
              >
            </label>
          </div>

          <div v-if="isReplugConnected" class="r_account_list">
            <div v-if="isReplugEditMode" class="replug_account">
              <div class="account_form d-flex defualt-section">
                <div class="field_group mr-2">
                  <label>Default Brand</label>
                  <select
                    v-model="getReplug.selected_brand"
                    :class="{ 'input-field-error': validations.selected_brand }"
                    class="select_sm"
                    name=""
                    @change="changeReplugBrand"
                  >
                    <option value=""> Select Brand </option>
                    <option
                      v-for="(brand, index) in getReplug.brands"
                      :key="index"
                      :value="brand"
                    >
                      {{ brand.name }}
                    </option>
                  </select>
                  <i class="icon-dropdown-cs"></i>
                  <span v-if="isInvalidSelectedBrand" class="input-error">{{
                    messages.selected_brand
                  }}</span>
                </div>

                <div class="field_group mr-2">
                  <label>Select Campaign</label>
                  <select
                    v-model="getReplug.selected_cta"
                    :class="{ 'input-field-error': validations.selected_cta }"
                    name=""
                    :disabled="!isReplugBrandSelected"
                    class="select_sm"
                  >
                    <option value=""> Select Campaign </option>
                    <template v-if="getReplug.selected_brand">
                      <option
                        v-for="(cta, index) in getReplug.selected_brand
                          .campaign"
                        :key="index"
                        :value="cta"
                      >
                        {{ cta.name }}
                        <span v-if="cta.cta_type">( {{ cta.cta_type }} )</span>
                      </option>
                    </template>
                  </select>
                  <i class="icon-dropdown-cs"></i>
                  <span v-if="isInvalidSelectedCta" class="input-error">{{
                    messages.selected_cta
                  }}</span>
                </div>

                <div class="field_group mr-2">
                  <label>&nbsp;</label>
                  <button
                    class="btn btn-studio-theme-space btn-size-small"
                    :disabled="saveReplugSettingsLoaderStatus"
                    :class="{ disabled: saveReplugSettingsLoaderStatus }"
                    @click.prevent="saveReplugSettings"
                  >
                    <span>Save</span>
                    <clip-loader
                      v-if="saveReplugSettingsLoaderStatus"
                      color="#ffffff"
                      :size="'12px'"
                    ></clip-loader>
                  </button>
                </div>
              </div>
            </div>

            <div v-else class="table_box w-50 ml-4">
              <table class="table-sm">
                <thead>
                  <tr>
                    <th>Default Brand</th>
                    <th>Default Campaign</th>
                    <th style="width: 100px">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ getReplug.selected_brand.name }}</td>
                    <td>{{ getReplug.selected_cta.name }}</td>
                    <td>
                      <div
                        class="action_icons text-right"
                        @click="changeReplugEditStatus(true)"
                      >
                        <i
                          v-tooltip.top="'Edit'"
                          class="action_icon sm icon-edit-cs"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr />
          <div
            v-if="isReplugConnected"
            class="center-equal ml-auto p-only-horizontal"
          >
            <p class="connected"
              ><b>Connected with: </b>
              <span>{{ getReplug.item.replug_email }}</span></p
            >
            <div class="action_icons ml-auto">
              <i
                v-if="replugLinkVisibilityStatus"
                v-tooltip.top="'Reconnect'"
                class="action_icon sm cs-refresh"
                @click.prevent="
                  connectAccount(
                    'replug',
                    'reconnect',
                    getReplug.item.replug_id
                  )
                "
              ></i>
              <i
                v-tooltip.top="'Remove'"
                class="action_icon sm icon-delete-cs"
                @click.prevent="
                  removeShortenerAccount('replug', getReplug.item._id)
                "
              ></i>
            </div>
          </div>

          <div v-else class="head_right ml-auto">
            <button
              v-if="replugLinkVisibilityStatus"
              class="btn btn-studio-theme-space"
              @click.prevent="connectAccount('replug', 'connect')"
              ><span>Connect</span></button
            >
            <button
              v-if="!replugLinkVisibilityStatus"
              disabled="disabled"
              class="btn btn-studio-theme-space"
              ><span>Connect</span></button
            >
            <div class="replug_label float-right">
              <h6 class="heading"
                >Special 20% discount on Replug for ContentStudio users.
                <br />
                <span class="coupon"
                  >Apply code <b>REPLUGFORCS</b> on purchase.</span
                >
              </h6>
              <a
                v-tooltip.top="'Visit Replug.io'"
                target="_blank"
                href="https://app.replug.io/"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  class="dig-UIIcon dig-UIIcon--standard"
                  width="24"
                  height="24"
                  focusable="false"
                >
                  <path
                    d="M11.5 5V6.5H16.4395L10.861 12.0785L11.9215 13.139L17.5 7.5605V12.5H19V5H11.5Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M17.5 16.75C17.5 16.9489 17.421 17.1397 17.2803 17.2803C17.1397 17.421 16.9489 17.5 16.75 17.5H7.25C7.05109 17.5 6.86032 17.421 6.71967 17.2803C6.57902 17.1397 6.5 16.9489 6.5 16.75V7.25C6.5 7.05109 6.57902 6.86032 6.71967 6.71967C6.86032 6.57902 7.05109 6.5 7.25 6.5H10V5H7.25C6.65326 5 6.08097 5.23705 5.65901 5.65901C5.23705 6.08097 5 6.65326 5 7.25V16.75C5 17.3467 5.23705 17.919 5.65901 18.341C6.08097 18.7629 6.65326 19 7.25 19H16.75C17.0455 19 17.3381 18.9418 17.611 18.8287C17.884 18.7157 18.1321 18.5499 18.341 18.341C18.5499 18.1321 18.7157 17.884 18.8287 17.611C18.9418 17.3381 19 17.0455 19 16.75V14H17.5V16.75Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <br />
        <div class="box_main mt-2">
          <div class="field_group">
            <label class="radio_container no_hover_color">
              <input
                id="google"
                type="radio"
                name="option2"
                :checked="isGooglShortenerActive"
                @click="changeDefaultShortener('googl')"
              />
              <div for="google">
                Firebase Dynamic Link
                <span class="check"></span>
              </div>
              <p class="sub-text"
                >Shortened links will have aggregated click tracking.</p
              >
            </label>
          </div>
        </div>
      </div>

      <div id="bitly-section" class="radio_box">
        <div class="box_main">
          <div class="field_group">
            <label class="radio_container no_hover_color">
              <input
                id="bitly"
                type="radio"
                name="option2"
                :disabled="!isBitlyConnected"
                :checked="isBitlyShortenerActive"
                @click="changeDefaultShortener('bitly')"
              />
              <div for="bitly">
                Bit.ly
                <span class="check"></span>
              </div>
              <p>Shortened links will have aggregated click tracking.</p>
            </label>
          </div>

          <div v-if="isBitlyConnected" class="m-4">
            <p class="sub-heading"> Default domain</p>

            <div class="field_group d-flex flex-wrap">
              <label
                v-for="(base, index) in getBitlyAccount.domain_options"
                :key="index"
                class="radio_container multi_radio"
              >
                <input
                  :id="base"
                  type="radio"
                  name="option3"
                  :disabled="!isBitlyShortenerActive"
                  :checked="isActiveBase(base)"
                  @click="changeBitlyBase(base)"
                />
                <div :for="base"
                  >{{ base }}
                  <span class="check"></span>
                </div>
              </label>
            </div>
          </div>

          <hr />
          <div v-if="isBitlyConnected" class="center-equal p-only-horizontal">
            <p class="connected"
              ><b>Connected with: </b>
              <span>{{ getBitlyAccount.username }}</span></p
            >
            <div class="ml-auto">
              <i
                v-if="bitlyLinkVisibilityStatus"
                v-tooltip.top="'Reconnect'"
                class="action_icon sm cs-refresh"
                @click="
                  connectAccount('bitly', 'reconnect', getBitlyAccount.username)
                "
              ></i>
              <i
                v-tooltip.top="'Remove'"
                class="action_icon sm icon-delete-cs"
                @click="removeShortenerAccount('bitly', getBitlyAccount._id)"
              ></i>
            </div>
          </div>

          <div v-else class="center-equal">
            <button
              v-if="bitlyLinkVisibilityStatus"
              class="btn btn-studio-theme-space"
              @click="connectAccount('bitly', 'connect')"
              ><span>Connect</span></button
            >
            <button
              v-if="!bitlyLinkVisibilityStatus"
              disabled="disabled"
              class="btn btn-studio-theme-space"
              ><span>Connect</span></button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="head_left">
          <div class="main-padding">
            <p
              >All the shortened links will be put under the default Brand and
              Campaign. However, you can still use a different Brand or Campaign
              for a specific post or automation campign using their own
              settings.</p
            >
            <p
              >We recommend creating a separate campaign (without CTA or 3rd
              party widgets).</p
            >
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.replug_label {
  text-align: end;
  .heading {
    font-size: 16px;
    color: black;
    padding-right: 25px;
  }
  .coupon {
    color: #6173ff;
    font-size: 14px;
  }
  a {
    position: relative;
    right: 0px;
    bottom: 30px;
    font-size: 16px;
    color: #6173ff;
  }
}
</style>
