<template>
  <div
    class="input_field mt-0"
    :class="{ 'input-field-light-error': social_accounts_validation }"
  >
    <template v-if="isWorkspaceOnHold">
      <div class="input_field">
        <div class="warning_box warning-alert">
          <p v-if="hasPermission('can_change_hold_status')"
            ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because you've paused publishing for this workspace,
            <router-link :to="{ name: 'workspaces' }" class="ml-2"
              >Click here to resume publishing
            </router-link>
          </p>
          <p v-if="!hasPermission('can_change_hold_status')"
            ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because the admin of this workspace has paused publishing for this
            workspace.
          </p>
        </div>
      </div>
    </template>

    <template v-if="!isWorkspaceOnHold">
      <template v-if="checkAccountDisable(type, false)">
        <template
          v-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'published'
          "
        >
          <label>Social Post is posted on that platform</label>
        </template>
        <template
          v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'failed'
          "
        >
          <label>Social Post is failed on that platform</label>
        </template>
        <template
          v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'rejected'
          "
        >
          <label>Social Post is Rejected on that platform</label>
        </template>
        <template v-else-if="checkSocialMissedReview()">
          <label>Social Post is Missed Reviewed on that platform</label>
        </template>
      </template>
      <template v-else>
        <label v-if="showLabel"
          >Please select account(s) from social networks below</label
        >
      </template>

      <div v-if="selectedAccountList.length > 0" class="social_accounts_list">
        <template v-if="showAllAccounts">
          <template v-for="account in selectedAccountList" :key="account._id">
            <div
              class="checkbox_input_image checkbox_social_profile"
              style="position: relative"
            >
              <i
                v-if="
                  commonMethods.isPinterestBoard(account) &&
                  commonMethods.isPinterestAccountIsManuallyConnected(
                    account,
                  ) &&
                  commonMethods.showZapierIntegration(account)
                "
                style="
                  position: absolute;
                  left: 1px;
                  top: 1px;
                  z-index: 999;
                  color: orangered;
                "
                class="c-pointer icon-zapier_icon"
              >
              </i>
              <i
                v-if="
                  commonMethods.isPinterestBoard(account) &&
                  commonMethods.isPinterestAccountIsManuallyConnected(
                    account,
                  ) &&
                  !commonMethods.showZapierIntegration(account)
                "
                v-tooltip.top="
                  'Please connect with Zapier in order to post successfully.'
                "
                class="fas fa-exclamation warning_icon_sm"
              >
              </i>
              <i
                v-if="
                  !checkForZapierAndPinterestAccount(account) &&
                  showExpiredWarning(account)
                "
                v-tooltip.top="'Reauthentication Required'"
                class="fas fa-exclamation warning_icon_sm"
              >
              </i>
              <i
                v-if="
                  commonMethods.showInstagramWarning(getMobileDevices, account)
                "
                v-tooltip.top="
                  'Mobile Device is not connected, however you can still post via Instagram API'
                "
                class="fas fa-exclamation warning_icon_sm"
              />
              <i
                v-if="
                  commonMethods.isFacebookGroup(account) &&
                  !commonMethods.isFbPageTokenIsSelectedForPosting(account, getFacebookPostedAs)
                "
                v-tooltip.top="
                  'First comment can only be published on a Facebook Group when a Facebook Page is configured as a Publishing Method for the Group from Facebook Account Settings'
                "
                class="fas fa-exclamation warning_icon_sm"
              />
              <input
                v-if="
                  commonMethods.isFacebookGroup(account) &&
                  !commonMethods.isFbPageTokenIsSelectedForPosting(account, getFacebookPostedAs)
                "
                :id="'selection_comment_' + account[account.channel.key]"
                type="checkbox"
                :value="account[account.channel.key]"
                :disabled="true"
              />
              <input
                v-else
                :id="'selection_comment_' + account[account.channel.key]"
                v-model="getSocialSharingFirstComment.first_comment_accounts"
                type="checkbox"
                :value="account[account.channel.key]"
                :disabled="checkAccountDisable(type) || getUserRole === 'Approver'"
              />
              <!--                      @change="accountSelectionChanges(`${account.channel.name}_tab`, account)"-->

              <label
                v-tooltip="{
                  content: channelTooltipHTML(account.channel.name, account),
                  classes: 'team_tooltip',
                  allowHTML: true,
                  theme: 'light',
                }"
                :for="'selection_comment_' + account[account.channel.key]"
                class="checkbox_right"
                data-cy="account-selection"
              >
                <individual-channel-tooltip
                  :account="account"
                  :type="account.channel.name"
                ></individual-channel-tooltip>
              </label>
            </div>
          </template>

          <div
            v-if="!checkAccountDisable(type) && selectedAccountList.length > 1 && getUserRole !== 'Approver'"
            class="selection_option"
          >
            <span
              class="checkAllAccountSelection"
              @click="checkAllFirstCommentAccountSelection(true)"
              >Select all</span
            >
            |
            <span
              class="unCheckAllAccountSelection"
              @click="checkAllFirstCommentAccountSelection(false)"
              >None</span
            >
          </div>
        </template>

        <template v-if="!showAllAccounts">
          <div
            data-cy="posting-platform-selection"
            class="prevent_close_dropdown social_dropdown platform_filters_planner platform_filters_dd dropdown option_dropdown default_style_dropdown"
          >
            <div
              data-display="static"
              style="max-height: 37px"
              class="dropdown_header d-flex align-items-center"
              data-toggle="dropdown"
            >
              <div class="text d-inline-block align-middle">
                <template v-if="accountSelectionCount < 1">
                  <i class="align-middle far fa-user icon_first"></i>
                  <span class="align-middle" data-cy="social-dropdown"
                    >Select Account(s)</span
                  >
                </template>
                <!-- for the blog channels tooltip -->
                <div class="circle_boxes_inline">
                  <!-- for the social channels tooltip -->
                  <template v-for="(account, i) in selectedAccountList" :key="'selected-account-item_' + i">
                    <span
                      v-if="filterPlatformVisibleStatus(account)"
                      v-tooltip="{
                        content: channelTooltipHTML(
                          account.channel.name,
                          account,
                        ),
                        classes: 'team_tooltip',
                        allowHTML: true,
                        theme: 'light',
                      }"
                    >
                      <individual-channel-tooltip
                        :account="account"
                        :type="account.channel.name"
                        :flex_class="'d-flex align-items-start'"
                      ></individual-channel-tooltip>
                    </span>
                  </template>

                  <div
                    v-if="accountSelectionCount > 5"
                    class="profile_picture d-flex align-items-start"
                  >
                    <div class="picture_block picture_block_background">
                      <div class="img text"
                        >+{{ accountSelectionCount - 5 }}</div
                      >
                    </div>
                  </div>
                </div>
              </div>
              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>

            <div
              class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
            >
              <ul class="inner">
                <template v-if="filteredplatformsCount > 0 || search !== ''">
                  <div class="search-bar-input m-3">
                    <div class="search-bar-inner">
                      <input
                        id="platform_selection_search_input"
                        v-model="search"
                        class="full_label"
                        type="text"
                        placeholder="Search by name"
                      />
                      <div class="search_btn">
                        <i class="icon-Search"></i>
                      </div>

                      <button
                        v-if="search !== ''"
                        class="clear-search-input"
                        @click="clearInput"
                      >
                        <i class="cs-cross"></i>
                      </button>
                    </div>
                  </div>

                  <hr />

                  <div v-if="search === '' && getUserRole !== 'Approver'" class="select_all test">
                    <div class="search_inner">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label
                            for="platform_selection_check_all_comment"
                            class="checkbox_right"
                          >
                            <span class="checkAllAccountSelection"
                              >Select All</span
                            >
                            <span class="unCheckAllAccountSelection"></span>
                            <input
                              id="platform_selection_check_all_comment"
                              v-model="check_all"
                              data-cy="platform_selection_check_all"
                              type="checkbox"
                              @change="
                                checkAllFirstCommentAccountSelection(check_all)
                              "
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(account, key) in selectedAccountList" :key="key + '_' + account.channel.name">
                  <li
                    v-if="showPlatformSelection(account)"
                    :data-key="key + '_' + account.channel.name"
                    class="list_item_li"
                  >
                    <div class="field_group">
                      <div class="checkbox_container">
                        <i
                          v-if="
                            commonMethods.isPinterestBoard(account) &&
                            commonMethods.showZapierIntegration(account)
                          "
                          style="
                            position: absolute;
                            left: 1px;
                            top: 1px;
                            z-index: 999;
                            color: orangered;
                          "
                          class="c-pointer icon-zapier_icon"
                        >
                        </i>
                        <i
                          v-if="
                            commonMethods.isPinterestBoard(account) &&
                            !commonMethods.showZapierIntegration(account)
                          "
                          v-tooltip.top="
                            'Please connect with Zapier in order to post successfully.'
                          "
                          class="fas fa-exclamation warning_icon_sm"
                        >
                        </i>
                        <i
                          v-if="
                            !checkForZapierAndPinterestAccount() &&
                            showExpiredWarning(account)
                          "
                          v-tooltip.top="'Reauthentication Required'"
                          class="fas fa-exclamation warning_icon_sm"
                        >
                        </i>
                        <i
                          v-if="
                            commonMethods.showInstagramWarning(
                              getMobileDevices,
                              account,
                            )
                          "
                          v-tooltip.top="
                            'Mobile Device is not connected, however you can still post via Instagram API'
                          "
                          class="fas fa-exclamation warning_icon_sm_2"
                        />
                        <div
                          v-if="commonMethods.isFacebookGroup(account) &&
                            !commonMethods.isFbPageTokenIsSelectedForPosting(account, getFacebookPostedAs)"
                        >
                          <label
                            :for="
                              'platform_selection_comment_' +
                              account[account.channel.key]
                            "
                            class="checkbox_right"
                            data-cy="select-account"
                          >
                            <input
                              :id="
                                'selection_comment_' +
                                account[account.channel.key]
                              "
                              type="checkbox"
                              :value="account[account.channel.key]"
                              :disabled="true"
                            />
                            <i
                              v-tooltip.top="
                                'First comment can only be published on a Facebook Group when a Facebook Page is configured as a Publishing Method for the Group from Facebook Account Settings'
                              "
                              class="fas fa-exclamation icon_right"
                            />
                            <!-- <span class="check"></span> -->
                            <individual-channel-dropdown
                              :account="account"
                              :type="account.channel.name"
                              :account_name="platformText(account)"
                              :account_type="account.type"
                              :integration="'social'"
                            ></individual-channel-dropdown>
                          </label>
                        </div>
                        <div v-else>
                          <label
                            :for="'platform_selection_comment_' + account[account.channel.key]"
                            class="checkbox_right"
                            data-cy="select-account"
                          >
                            <!--                                                      || ($route.name !== 'composerSocial' && channel.name === 'youtube')-->
                            <input
                              :id="
                                'platform_selection_comment_' +
                                account[account.channel.key]
                              "
                              v-model="
                                getSocialSharingFirstComment.first_comment_accounts
                              "
                              :data-cy="
                                'platform_selection_comment_' +
                                account[account.channel.key]
                              "
                              :value="account[account.channel.key]"
                              type="checkbox"
                              :disabled="getUserRole === 'Approver'"
                            />
                            <span class="check" :class="{ 'border-0': getUserRole === 'Approver' }"></span>
                            <individual-channel-dropdown
                              :account="account"
                              :type="account.channel.name"
                              :account_name="platformText(account)"
                              :account_type="account.type"
                              :integration="'social'"
                            ></individual-channel-dropdown>
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>

                <li v-if="filteredplatformsCount === 0" class="p-3 text-center">
                  <p v-if="search"
                    >No results found for your search query, please try again</p
                  >
                  <div v-else>
                    <p class="mb-3 mt-2"
                      >You have not connected any account yet.</p
                    >
                    <template v-if="hasPermission('can_save_social')">
                      <router-link
                        :to="{ name: 'social' }"
                        class="btn btn-studio-theme-space"
                      >
                        <span>Connect an account</span>
                      </router-link>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>

      <div v-else>
        <span v-if="getInstagramPostingOption === 'mobile'">
          First Comment is not allowed via Push Notification Method.</span
        >
        <span v-else> You have not selected any page or profile yet.</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { socialChannelsArray } from '@common/constants/common-attributes'
import { getPlatformName } from '@common/lib/integrations'
import { commonMethods } from '@common/store/common-methods'
import { sharingTypes } from '@src/modules/publish/store/states/mutation-types.js'

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
    type: {},
    social_accounts_validation: {
      type: Boolean,
      default: false
    },
    blog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      search: '',
      check_all: false,
      social_channels_list: socialChannelsArray,
      commonMethods
    }
  },

  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getBlogPostingDetails',
      'getPublishSelection',
      'getWorkspaces',
      'getMobileDevices',
      'getSocialSharingFirstComment',
      'getInstagramPostingOption',
      'getYoutubeOptions',
      'getFacebookPostedAs'
    ]),

    selectedAccountIds () {
      const ids = []
      this.selectedAccountList.forEach((account) => {
        if (
            commonMethods.isFacebookGroup(account) &&
            !commonMethods.isFbPageTokenIsSelectedForPosting(account, this.getFacebookPostedAs)
        ) {
          return
        }
        ids.push(account[account.channel.key])
      })
      return ids
    },

    selectedAccountList () {
      const accountList = []
      if (this.accountSelection.length) {
        this.social_channels_list.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if (
                ((channel.name === 'facebook' && !commonMethods.isFacebookGroup(account)) ||
                    channel.name === 'linkedin' ||
                    (channel.name === 'youtube' &&
                        !this.getYoutubeOptions.made_for_kids &&
                        this.getYoutubeOptions.privacy_status === 'public') ||
                    (channel.name === 'instagram' &&
                        this.getInstagramPostingOption === 'api')) &&
                this.accountSelection.includes(account[channel.key])
            ) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key }
              })
            }
          })
        })
      }

      return accountList
    },

    accountSelection () {
      const facebookAccounts = this.getAccountSelection.facebook
      const instagramAccounts = this.getAccountSelection.instagram
      const youtubeAccounts = this.getAccountSelection.youtube
      const linkedinAccounts = this.getAccountSelection.linkedin

      return [
        ...facebookAccounts,
        ...instagramAccounts,
        ...youtubeAccounts,
        ...linkedinAccounts
      ]
    },

    showAllAccounts () {
      return this.selectedAccountList.length <= 10
    },

    filteredplatformsCount () {
      let platformsCount = 0
      this.social_channels_list.forEach((item) => {
        const getter = this.$store.getters[item.getter]
        const itemsList = getter.hasOwnProperty('items') ? getter.items : getter
        platformsCount += itemsList.filter((account) =>
            this.showPlatformSelection(account)
        ).length
      })
      return platformsCount
    },

    accountSelectionCount () {
      return this.selectedAccountList.length
    },

    isWorkspaceOnHold () {
      return !!(
          this.getWorkspaces.activeWorkspace &&
          this.getWorkspaces.activeWorkspace.on_hold
      )
    }
  },

  watch: {
    selectedAccountIds (newArray, oldArray) {
      console.debug('accountSelection', newArray, oldArray)
      if (newArray.length > oldArray.length) {
        // Get Diff
        const newAccount = newArray.filter((x) => !oldArray.includes(x))

        this.$store.commit(sharingTypes.SET_FIRST_COMMENT_ACCOUNTS, [
          ...this.getSocialSharingFirstComment.first_comment_accounts,
          ...newAccount
        ])
      } else {
        let firstCommentAccounts = []
        // eslint-disable-next-line camelcase
        if (
            this.getSocialSharingFirstComment.first_comment_accounts &&
            this.getSocialSharingFirstComment.first_comment_accounts.length > 0
        ) {
          firstCommentAccounts =
              this.getSocialSharingFirstComment.first_comment_accounts.filter(
                  (accountId) => {
                    return newArray.includes(accountId)
                  }
              )
        }

        this.$store.commit(
            sharingTypes.SET_FIRST_COMMENT_ACCOUNTS,
            firstCommentAccounts
        )
      }
    },
    'getSocialSharingFirstComment.first_comment_accounts' (newArray, oldArray) {
      console.debug('first_comment_accounts ', newArray)
    }
  },

  created () {},

  mounted () {
    console.debug('selectedAccountList', this.selectedAccountList)
    console.debug(
      'selectedAccountList getSocialSharingFirstComment',
      this.getSocialSharingFirstComment
    )
  },

  methods: {
    ...mapActions([]),
    checkValidForYoutube (channel) {
      if (channel.name !== 'youtube') return true
      return !!(
        this.$route.name === 'composerSocial' ||
        ((this.$route.name === 'list_plans' ||
          this.$route.name === 'calender_plans') &&
          !this.blog)
      )
    },
    getInstagramWarningText (account) {
      return `<div class='team_detail_tooltip'>
                  <div class='t_inner'>
                      <div class='t_content'>
                          <p style="margin-top: 0;font-size: 0.875rem;font-weight: normal;">Unable to post on ${account.name}, Mobile Device or Zapier needs to be connected in order to post on Instagram.</p>
                      </div>
                  </div>
              </div>`
    },
    /** all button for the social channels.
     *
     * @param status boolean
     */
    checkAllFirstCommentAccountSelection (status) {
      console.debug('Method:checkAllFirstCommentAccountSelection')
      if (status) {
        const accountsToSelect = []
        this.selectedAccountList.forEach((account) => {
          if (
            commonMethods.isFacebookGroup(account) &&
            !commonMethods.isFbPageTokenIsSelectedForPosting(account, this.getFacebookPostedAs)
          ) {
            return
          }
          accountsToSelect.push(account[account.channel.key])
        })

        this.$store.commit(
          sharingTypes.SET_FIRST_COMMENT_ACCOUNTS,
          accountsToSelect
        )
      } else {
        this.$store.commit(sharingTypes.SET_FIRST_COMMENT_ACCOUNTS, [])
      }
    },

    GoToSocial () {
      this.$router.push({ name: 'social' })
      $('.modal').modal('hide')
    },

    checkAccountDisable (type, message = true) {
      console.debug(type, message)
      console.debug('checkAccountDisable', this.getBlogPostingDetails.type)
      // type && type == 'composer' &&
      if (
        (this.getBlogPostingDetails.type === 'Composer Article' ||
          this.getBlogPostingDetails.type === 'Discovery Article' ||
          this.getBlogPostingDetails.type === 'Automation Article' ||
          this.getBlogPostingDetails.type === 'Discovery Video' ||
          this.getBlogPostingDetails.type === 'Automation Video') &&
        this.getPublishSelection.socialPlansDetail &&
        this.getPublishSelection.socialPlansDetail.length > 0 &&
        (this.getPublishSelection.socialPlansDetail[0].status === 'published' ||
          this.getPublishSelection.socialPlansDetail[0].status === 'failed' ||
          this.getPublishSelection.socialPlansDetail[0].status === 'rejected' ||
          (this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
            this.getPublishSelection.socialPlansDetail[0].execution_time &&
            this.getPublishSelection.socialPlansDetail[0].execution_time.date <
              moment.utc().format('YYYY-MM-DD HH:mm:ss')))
      ) {
        return true
      } else if (
        type === 'socialComposer' &&
        message &&
        this.getPublishSelection.status === 'published'
      ) {
        return true
      } else if (message && this.getPublishSelection.queueStatus) {
        return true
      }
      return false
    },

    checkSocialMissedReview () {
      if (
        this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
        this.getPublishSelection.socialPlansDetail[0].execution_time.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return true
      }
      return false
    },

    platformText (platform) {
      console.debug('Method::platformText', platform)
      return getPlatformName(platform)
    },

    showPlatformSelection (account) {
      return (
        this.platformText(account)
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
      )
    },

    clearInput () {
      this.search = ''
    },

    filterPlatformVisibleStatus (platform) {
      let count = 0
      let status = false
      const accounts = this.$store.getters.getAccountSelection

      this.social_channels_list.forEach((channel) => {
        const getter = this.$store.getters[channel.getter]
        const channelItems = getter.hasOwnProperty('items')
          ? getter.items
          : getter

        if (!status) {
          channelItems.forEach((item) => {
            if (accounts[channel.name].includes(item[channel.key])) {
              count += 1
              if (platform[channel.key] === item[channel.key] && count < 6) {
                status = true
                return false
              }
            }
          })
        }
      })
      return status
    },

    showExpiredWarning (account) {
      return (
        account &&
        account.validity &&
        (account.validity === 'invalid' || account.validity === 'expired')
      )
    },
    checkForZapierAndPinterestAccount (account) {
      return (
        account &&
        account.linked_to &&
        account.linked_to.zapier &&
        account.board_id
      )
    }
  },
}
</script>
