import { blogIntegrationsConfigrations } from '@src/modules/integration/config/api-utils.js'

const getDefaultPermissionsObject = () => {
  const options = {
    addBlog: true,
    addSocial: true,
    addSource: true,
    addTopic: true,
    viewTeam: false,
    approverCanEditPost:false,
    hasBillingAccess: false,
    postsReview: true,
    rescheduleQueue: false,
    changeFBGroupPublishAs: true,
    tumblr_profiles: [],
    facebook: [],
    twitter: [],
    pinterest: [],
    linkedin: [],
    instagram: [],
    gmb: [],
    youtube: [],
    tiktok: [],
    threads: []
  }

  for (const key in blogIntegrationsConfigrations) {
    key === 'tumblr' ? (options.tumblr_blogs = []) : (options[key] = [])
  }

  return options
}

const getDefaultSellectAllOptions = () => {
  const options = {
    topics: true,
    favorites: true,
    facebook: true,
    twitter: true,
    pinterest: true,
    linkedin: true,
    instagram: true,
    gmb: true,
    youtube: true,
    tiktok: true,
    threads: true
  }
  for (const key in blogIntegrationsConfigrations) {
    options[key] = true
  }
  return options
}

const state = {
  sellectAllOptions: getDefaultSellectAllOptions(),
  team: {
    id: null,
    // first_name: null,
    // last_name: null,
    email: null,
    role: 'admin',
    permissions: getDefaultPermissionsObject(),
    membership: 'team'
  },
  userEmails: [],
  selectAllAccounts: {
    status: true
  }
}

// getters
const getters = {
  getTeam: (state) => {
    return state.team
  },
  getUserEmails: (state) => {
    return state.userEmails
  },
  getTeamSellectAllOptions: (state) => {
    return state.sellectAllOptions
  },
  getTeamAccountSelectAll: (state) => {
    return state.selectAllAccounts
  }
}

// actions
const actions = {
  setTeam ({ commit }, team) {
    if (team.hasOwnProperty('permissions')) {
      team.permissions = Object.assign(
        getDefaultPermissionsObject(),
        team.permissions
      )
    }
    commit('setTeam', team)
  },
  setEmails ({ commit }, userEmails) {
    commit('setEmails', userEmails)
    // state.userEmails = payload
    // console.log(state.userEmails)
  },
  setTeamSellectAll ({ commit }, option) {
    commit('setTeamSellectAll', option)
  },

  setDefaultTeam ({ commit }) {
    commit('setDefaultTeam')
  },

  setTeamAccountSelectAll ({ commit }, status) {
    commit('setTeamAccountSelectAll', status)
  }
}

// mutations
const mutations = {
  setTeam (state, team) {
    state.team = team
  },

  setEmails (state, payload) {
    state.userEmails = payload
  },

  setTeamSellectAll (state, option) {
    state.sellectAllOptions[option.type] = option.status
  },

  setTeamAccountSelectAll (state, status) {
    state.selectAllAccounts.status = status
  },

  setDefaultTeam (state) {
    state.sellectAllOptions = getDefaultSellectAllOptions()
    state.team = {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      role: 'admin',
      membership: 'team',

      permissions: getDefaultPermissionsObject()
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
