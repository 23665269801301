<template>
    <div v-click-away="closeDropdown" class="dropdown option_dropdown export_dropdown default_style_dropdown influencer-search-dropdown">

        <div class="dropdown_header d-flex align-items-center" data-cy="influencers_accounts" @click="showDropdown = !showDropdown">
            <span class="text"><i class="fas fa-file-export"></i> Export Into CSV</span>
            <span class="ml-auto align-middle"><i class="dropdown_arrow icon_last cs-angle-down  "></i></span>
        </div>

        <div class="dropdown-menu dropdown-menu-left min-w-250" :class="{ 'show': showDropdown }">
            <ul class="inner results">
              <span>Select number of results</span>
              <li class="list_item_li">
                <div class="radio_input_image" >
                  <input id="50_res" v-model="export_limit" value="50" name="export_page" type="radio"/>
                  <label for="50_res" class="radio_left" >50</label>
                </div>
              </li>

              <li class="list_item_li">
                <div class="radio_input_image" >
                  <input id="100_res" v-model="export_limit" value="100" name="export_page" type="radio"/>
                  <label for="100_res" class="radio_left" >100</label>
                </div>
              </li>

              <li class="list_item_li">
                <div class="radio_input_image" >
                  <input id="500_res" v-model="export_limit" value="500" name="export_page" type="radio"/>
                  <label for="500_res" class="radio_left" >500</label>
                </div>
              </li>
            </ul>

            <ul class="inner fields">
              <span>Select fields</span>
              <li class="checkbox_input_image">
                <input
id="fields_screen_name" disabled="disabled" type="checkbox" value="screen_name"
                           :checked="isExportAttrSelected('screen_name')">
                <label for="fields_screen_name" class="checkbox_right">
                  <p>Screen Name</p>
                </label>
              </li>

              <li class="checkbox_input_image">
                <input
id="fields_profile_image" type="checkbox" value="profile_image"
                           :checked="isExportAttrSelected('profile_image')" @change.prevent="changeExportSelection('profile_image')">
                    <label for="fields_profile_image" class="checkbox_right">
                        <p>Profile Image</p>
                    </label>
              </li>

              <li class="checkbox_input_image">
                <input
id="fields_display_name" type="checkbox" value="display_name"
                           :checked="isExportAttrSelected('display_name')"  @change.prevent="changeExportSelection('display_name')">
                    <label for="fields_display_name" class="checkbox_right">
                        <p>Display Name</p>
                    </label>
              </li>

              <li class="checkbox_input_image">
                <input
id="fields_verified" type="checkbox" value="verified"
                           :checked="isExportAttrSelected('verified')"  @change.prevent="changeExportSelection('verified')">
                    <label for="fields_verified" class="checkbox_right">
                        <p>Verified</p>
                    </label>
              </li>

                <li class="checkbox_input_image">
                    <input
id="fields_bio" type="checkbox" value="bio"
                           :checked="isExportAttrSelected('bio')"  @change.prevent="changeExportSelection('bio')">
                    <label for="fields_bio" class="checkbox_right">
                        <p>Bio</p>
                    </label>
                </li>

              <li class="checkbox_input_image">
                <input
id="fields_follower_count" type="checkbox" value="follower_count"
                           :checked="isExportAttrSelected('follower_count')"  @change.prevent="changeExportSelection('follower_count')">
                    <label for="fields_follower_count" class="checkbox_right">
                        <p>Followers Count</p>
                    </label>
              </li>

              <li class="checkbox_input_image">
                <input
id="fields_average_interaction_per_tweet" type="checkbox" value="average_interaction_per_tweet"
                           :checked="isExportAttrSelected('average_interaction_per_tweet')"  @change.prevent="changeExportSelection('average_interaction_per_tweet')">
                    <label for="fields_average_interaction_per_tweet" class="checkbox_right">
                        <p>Average Int. Per Tweet</p>
                    </label>
              </li>
            </ul>

            <ul class="inner buttons">
              <li class="submit_btns text-center">
                <button v-if="loader" class="btn btn-studio-theme-space">Exporting...
                  <clip-loader class="d-inline" :color="'#ffffff'" :size="'12px'" ></clip-loader>
                </button>
                <button v-else class="btn btn-studio-theme-space" @click.prevent="exportInfluencersCall({ 'export_limit': parseInt(export_limit),'export_fields': export_fields })">
                  Export
                  </button>
              </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import textHelper from '@common/lib/text-helper'

export default {
  components: {},
  props: {},
  data () {
    return {
      export_limit: 50,
      export_fields: ['screen_name'],
      loader: false,
      showDropdown: false
    }
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['exportInfluencers']),
    isExportAttrSelected (value) {
      return this.export_fields && this.export_fields.indexOf(value) >= 0
    },
    changeExportSelection (field) {
      const index = this.export_fields.indexOf(field)
      if (index > -1) this.export_fields.splice(index, 1)
      else this.export_fields.push(field)
    },

    async exportInfluencersCall (data) {
      this.loader = true
      const res = await this.exportInfluencers(data)
      this.loader = false
      if (!res || !res.data || (res.data.status && res.data.status === 'not found')) {
        this.alertMessage('Failed to export influencers to CSV.', 'error')
        return false
      }
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url

      // improve the file download name
      const fileName = `Influencers Report (${textHelper.ucFirst(this.getActiveWorkspace.slug)}) - ${this.generateRandomId()}.csv`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      this.alertMessage('Successfully exported influencers to CSV.', 'success')
      this.export_limit = 50
      this.export_fields = ['screen_name']
    },
    closeDropdown () {
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less" scoped>
  .export_dropdown {
    width: 33%;
    margin-left: 1%;
    .inner {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      font-size: 0.875rem;
      max-height: initial;
      overflow: initial;
    }
    .results {
      padding: 20px 10px 15px 10px !important;
      .list_item_li {
        padding: 7px 10px !important;
      }
    }
    .fields {
      padding-top: 0 !important;
      .checkbox_input_image {
        padding: 7px 0.625rem;
        display: block;
        color: #3a4557;
        font-size: 0.875rem;
        cursor: pointer;
        .checkbox_right {
          padding-right: 0 !important;
          padding-left: 26px;
        }
        &:hover {
          background: #f5f9fc;
        }
      }
    }
    .buttons {
      padding-top: 0 !important;
      .submit_btns {
        .btn {
          background: #4165ed !important;
          box-shadow: 0 4.3px 15px 0 rgba(65, 101, 237, 0.4);
          color: #ffffff !important;
        }
      }
    }
    .fa-file-export {
      color: #9399a3 !important;
      font-size: 15px !important;
    }
  }
  .op-7 {
    opacity: 0.7;
  }
  .d-inline {
    display: inline-block !important;
  }
  .checkbox_input_image input[type="checkbox"] + label.checkbox_right:after {
    left: 0 !important;
  }
  .checkbox_input_image input[type="checkbox"]:checked:disabled + label:before {
    left: 0 !important;
  }
  .checkbox_input_image input[type="checkbox"] + label.checkbox_right:before {
    left: 0 !important;
  }
  .default_style_dropdown .dropdown-menu.dropdown-menu-left:after {
    top: -5px;
  }
  .default_style_dropdown .dropdown-menu.dropdown-menu-left:before {
    top: -6px;
  }
</style>
