<template>
  <div :class="class_name">
    <div class="name">
      <p>Sentiment</p>
    </div>
    <div
      v-if="post._source.sentiment && post._source.sentiment.length > 0"
      class="value"
    >
      <i
        v-if="post._source.sentiment[0].label == 'negative'"
        v-tooltip="'Negative'"
        class="cs-sad-face sad"
      >
      </i>
      <i
        v-if="
          post._source.sentiment[0].label == 'positive' &&
          post._source.sentiment[0].probability >= 0.65
        "
        v-tooltip="'Positive'"
        class="cs-happy-face smile"
      >
      </i>
      <i
        v-else-if="
          post._source.sentiment[0].label == 'positive' &&
          post._source.sentiment[0].probability < 0.65
        "
        v-tooltip="'Neutral'"
        class="cs-normal-face normal"
      >
      </i>
      <i
        v-if="post._source.sentiment[0].label == 'neutral'"
        v-tooltip="'Neutral'"
        class="cs-normal-face normal"
      >
      </i>
    </div>
    <div v-else class="value">
      <i v-tooltip="'Neutral'" class="cs-normal-face normal"> </i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {},
    class_name: {
      type: String
    }
  }
}
</script>
