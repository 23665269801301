<template>
  <clip-loader
    v-if="isLoading"
    class="spinner"
    :color="'#e0dfdf'"
    :size="'48px'"
  ></clip-loader>
  <div class="instagram-preview-container">
    <blockquote
      v-show="!isLoading"
      class="instagram-media"
      data-instgrm-captioned
      :data-instgrm-permalink="postLink"
      data-instgrm-version="14"
      style="
        background: #fff;
        border: 0;
        border-radius: 3px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5),
          0 1px 10px 0 rgba(0, 0, 0, 0.15);
        margin: 1px;
        max-width: 540px;
        min-width: 326px;
        padding: 0;
        width: 99.375%;
        width: -webkit-calc(100% - 2px);
        width: calc(100% - 2px);
      "
    ></blockquote>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, onUnmounted } from 'vue'
defineProps({
  postLink: {
    type: String,
    required: true,
  },
})

const isLoading = ref(true)
let observer = null

const loadInstagramScript = () => {
  return new Promise((resolve, reject) => {
    if (!window.instgrm) {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = 'https://www.instagram.com/embed.js'
      document.body.appendChild(script)

      script.onload = () => {
        console.log('Instagram script loaded')
        if (window.instgrm) {
          resolve()
        } else {
          reject(
            new Error(
              'Instagram embed script loaded but window.instgrm is not available'
            )
          )
        }
      }

      script.onerror = () => {
        reject(new Error('Failed to load Instagram embed script'))
      }
    } else {
      resolve()
    }
  })
}

const processEmbeds = () => {
  if (window.instgrm && window.instgrm.Embeds) {
    window.instgrm.Embeds.process()
  }
}

onMounted(async () => {
  try {
    await loadInstagramScript()

    observer = new MutationObserver((mutations) => {
      if (document.querySelector('.instagram-media-rendered')) {
        observer.disconnect()
        isLoading.value = false
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    processEmbeds()
  } catch (error) {
    console.error(error)
    isLoading.value = false
  }
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style scoped>
.instagram-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
</style>
