<template>
  <div>
    <div v-if="computeFacebookCurrentTemplate.type === 'image'" class="relative h-[252px] rounded-bl-xl rounded-br-xl" :style="{ color: computeFacebookCurrentTemplate.color }">
      <img class="w-full h-full rounded-bl-xl rounded-br-xl" :src="require(`@assets/img/composer/facebook_template/${computeFacebookCurrentTemplate.image}`)" :alt="computeFacebookCurrentTemplate.background_description"/>
      <div class="w-full h-full absolute left-0 top-0 flex items-center justify-center">
        <span
            :class="`text-[${computeFacebookCurrentTemplate.color}]`"
            class="whitespace-pre-wrap text-center px-3 text-2xl font-bold" v-html="processedDescription(computeFacebookCurrentTemplate.color)"></span>
      </div>
    </div>
    <div
        v-if="computeFacebookCurrentTemplate.type === 'solid' || computeFacebookCurrentTemplate.type === 'gradient'"
        class="relative w-full h-[252px] rounded-bl-xl rounded-br-xl"
        :style="{
        backgroundColor: computeFacebookCurrentTemplate.type === 'solid' ? computeFacebookCurrentTemplate.background_color : '',
        backgroundImage: computeFacebookCurrentTemplate.type === 'gradient' ? computeFacebookCurrentTemplate.background_color : '',
        color: computeFacebookCurrentTemplate.color,
    }"
    >
      <div class="w-full h-full absolute left-0 top-0 flex items-center justify-center">
        <span
            :class="`text-[${computeFacebookCurrentTemplate.color}] whitespace-pre-wrap text-center px-3 text-2xl font-bold`"
            v-html="processedDescription(computeFacebookCurrentTemplate.color)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { computed } from "vue";

// declarations
const { FACEBOOK_BACKGROUNDS } = useComposerHelper()

const FACEBOOK_DEFAULT_TEMPLATE = {
  preset_id: '',
  category: '',
  type: '',
  thumbnail: '',
  image: '',
  background_color: '',
  background_description: ''
}

const props = defineProps({
  facebookBackgroundId: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
})

// Compute Facebook current template
const computeFacebookCurrentTemplate = computed(() => {
  const categories = Object.keys(FACEBOOK_BACKGROUNDS)
  for (const category of categories) {
    const templates = FACEBOOK_BACKGROUNDS[category]
    const foundTemplate = templates.find(template => template.preset_id === props.facebookBackgroundId)
    if (foundTemplate) {
      return foundTemplate
    }
  }
  return FACEBOOK_DEFAULT_TEMPLATE
})

/**
 * we are applying the color to the description for all a tags
 * @param color
 * @returns {*}
 */
const processedDescription = (color) => {
  return props.description.replace(/<a /g, `<a style="color: ${color};" `);
}

</script>
