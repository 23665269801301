<template>
  <div class="w-[63.5rem]">
    <div class="h-full w-full left_section">
      <div class="flex flex-col h-full justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem]">
        <div class="px-[5rem] py-[1rem] 2xl:py-[2rem]">
          <LogoComponent />
        </div>
        <div class="px-[5rem] flex-grow flex flex-col justify-center">
          <SignupTestimonial />
        </div>
        <div class="px-14">
          <div class="trusted_section py-5 2xl:py-10 px-2">
            <h2 class="text-[#3C3C3C] text-sm 2xl:text-[1.5rem] font-bold text-center">Trusted brands and Agencies </h2>
            <div class="flex items-center justify-around mt-4">
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/unilever.svg" alt="unilever"/>
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/mintsocial.png" alt="mintsocial"/>
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/moderntv.png" alt="moderntv"/>
            </div>
            <div class="flex items-center justify-around mt-4">
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/decathlon.svg" alt="decathlon"/>
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/getdone.png" alt="getdone"/>
              <img class="w-20 2xl:w-24" src="@assets/img/onboarding/ecocarrier.png" alt="ecocarrier"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const SignupTestimonial = () => import("@src/components/authentication/SignupTestimonial.vue")

const LogoComponent = () => import("@src/modules/account/components/LogoComponent.vue")
</script>
<style lang="scss" scoped>
.left_section {
  background: linear-gradient(22deg, #C8E8FF 0%, #EEF4F7 32.18%, #EEF0F7 95.94%);
  box-shadow: 0px 0px 0px 1px #D1E3FB;

  .trusted_section {
    border-radius: 7.24px;
    border-top: 1px solid rgba(255, 255, 255, 0.30);
    background: radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.07) 100%);
    backdrop-filter: blur(14.395000457763672px);
  }
}

</style>
