<template>
  <div class="feeds-follow-empty-view">
    <div class="m-auto feeds-follow-empty-view__art-section">
      <img
        src="../../../../../assets/img/states/follow-sources-icon-style-1.svg"
        alt=""
        class="m-auto"
      />
      <h2> Follow Your Sources </h2>
      <p class="p-3">
        Following content has never been easier. You can follow blogs and
        websites via RSS feeds.
      </p>
      <b-button
        variant="studio-theme-space"
        @click="$router.push({ name: 'feeder_follow_categories' })"
        >Add Sources</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepAction: {}
  }
}
</script>
