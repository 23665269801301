<template>
  <ul
    v-if="
      getCustomSidebar.quick_topics.status ||
      getSidebarCustomization.customization
    "
    class="topics-left-pane-items"
    :class="{ disable_click: getTopicDisabled }"
  >
    <li
      class="topics-left-pane-items__heading"
      :class="[sibarItemStatus('quickTopics') ? '' : 'opacity']"
    >
      <i class="icon-reports_icon"></i>
      <span class="topics-left-pane-items__heading__text">Curated Topics</span>
      <button
        v-if="
          !getSidebarCustomization.customization &&
          hasPermission('can_save_topic')
        "
        v-tooltip.top="'Add Curated Topic'"
        class="topics-left-pane-items__heading__button"
        data-cy="add-topic"
        @click.prevent="followCuratedTopicsModal"
      >
        <i class="far fa-plus d-block"></i>
      </button>

      <div
        v-if="getSidebarCustomization.customization"
        class="topics-left-pane-items__heading__customization"
      >
        <i
          class="view_icon"
          :class="[
            sibarItemStatus('quickTopics') ? 'cs-eye ' : 'cs-eye-close ',
          ]"
          @click.prevent="selectionSibarItem('quickTopics')"
        >
        </i>
      </div>
    </li>

    <template v-if="getMyCuratedTopicsList.length > 0">
      <transition-group name="topics-list" tag="div">
        <template v-for="(topic, index) in getMyCuratedTopicsList">
          <li
              v-if="
            getSidebarCustomization.customization ||
            !getCustomSidebar.quick_topics.options.includes(topic.name)
          "
              :key="topic._id"
              class="topics-left-pane-items__list"
              :class="[
            sibarItemStatus('quickTopics', topic.name) ? '' : 'opacity',
            getSidebarCustomization.customization
              ? 'topics-left-pane-items__list__customization'
              : '',
            $route.params.topic_id === topic._id ? 'active' : '',
          ]"
          >
            <router-link
                :to="{
              name: 'discover_topics_curated_web_content',
              params: { topic_id: topic._id },
            }"
                :class="{ active: $route.params.topic_id === topic._id }"
            >{{ topic.name }}</router-link
            >
            <div class="topics-left-pane-items__list__action_buttons">
              <i
                  v-if="getSidebarCustomization.customization"
                  v-tooltip.top="'Show / Hide'"
                  style="right: 0"
                  class="view_icon"
                  :class="[
                sibarItemStatus('quickTopics', topic.name)
                  ? 'cs-eye '
                  : 'cs-eye-close  ',
              ]"
                  @click.prevent="selectionSibarItem('quickTopics', topic.name)"
              >
              </i>

              <!--<i v-tooltip.top="'Remove'"-->
              <!--v-else-if="hasPermission('can_save_topic')"-->
              <!--class="topics-left-pane-items__list__action_buttons__remove icon-delete-cs"-->
              <!--@click.prevent="unfollowCuratedTopic({topic:topic, index: index})">-->
              <!--</i>-->

              <i
                  v-else-if="hasPermission('can_save_topic')"
                  :id="topic._id"
                  v-tooltip.top="'Remove'"
                  class="topics-left-pane-items__list__action_buttons__remove icon-delete-cs"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              ></i>

              <div
                  :aria-labelledby="topic._id"
                  class="dropdown-menu dropdown-confirmation__menu"
              >
                <div class="dropdown-confirmation__menu__header">
                  Confirmation
                </div>
                <div class="dropdown-confirmation__menu__actions">
                  Are you sure?
                  <span
                      class="dropdown-confirmation__menu__actions__delete"
                      @click.prevent="
                    unfollowCuratedTopic({ topic: topic, index: index })
                  "
                  >Yes</span
                  >
                  <span class="dropdown-confirmation__menu__actions__cancel"
                  >No</span
                  >
                </div>
              </div>
            </div>
            <!--<div class="line_animate" :class="{'active animate': getActiveTopicTab === topic._id}"></div>-->
          </li>
        </template>
      </transition-group>
      <!--:class="{selected_disable: !sibarItemStatus('quickTopics',topic.name),disable_box : getSidebarCustomization}"-->
    </template>

    <template v-else>
      <p class="topics-left-pane-items__no_results"
        >You are not following any curated topic.</p
      >
    </template>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getMyCuratedTopicsList',
      'getActiveTopicTab',
      'getTopicModule',
      'getTopicDisabled',
      'getCustomSidebar',
      'getSidebarCustomization',
      'getTopicContentType',
      'getActiveChannelRoute'
    ])
  },
  created () {
    /* if(this.getMyCuratedTopicsList.length > 0)
      {
          this.getContentByTopic()
      } */
  },
  methods: {
    ...mapActions([
      'setInsightsLoader',
      'setVideoPage',
      'setVideoPageToken',
      'setDiscoveryVideoLoader',
      'setPosts',
      'setTrendingStatus',
      'unfollowCuratedTopic'
    ])
  },
}
</script>
