import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  evergreen,
  bulkCSV,
} from '@src/modules/automation/store/automation-mutation-type'
import {
  articlesTypes as articleTypes,
  topicTypes,
} from '@src/modules/discovery/store/mutation-types'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { planner } from '@src/modules/planner/store/mutation-types'
import {
  blogPosting,
  commonTypes,
  publish,
} from '@src/modules/publish/store/states/mutation-types'
import { rssTypes } from '@src/modules/automation/store/recipes/mutation-types'

export const initializeMixin = {
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getFetchBlogsStatus',
      'getFetchAccountsStatus',
      'getBlogAuthorization',
      'getSocialAuthorization',
      'getFetchIntegrationsAccountsStatus',
      'getPocket',
      'getReplug',
      'getFeedly',
      'getArticleBlogAutomationListing',
      'getArticleSocialAutomationListing',
      'getTopics',
      'getVideoBlogAutomationListing',
      'getVideoSocialAutomationListing',
      'getRssAutomationListing',
      'getPublishSelection',
      'getPublicationFolderStatus',
      'getProfile',
      'getEvergreenAutomationListing',
      'getCSVAutomationListing',
      // discovery
    ]),
  },
  methods: {
    ...mapMutations([
      'RESET_SOURCES_STATE',
      'SET_TOPIC_MODULE',
      'SET_POSTS',
      topicTypes.SET_POSTS_TOTAL,
      topicTypes.SET_CHANGE_TOPIC_FILTERS,
      'SET_TOPICS_PAGE_LOADED',
      'SET_TOPIC_CONTENT_TYPE',
      articleTypes.SET_SEARCH_CONTENT_TYPE,
      articleTypes.SET_SEARCH_ARTICLES_TOPIC,
      articleTypes.SET_SEARCH_DISPLAY_TOPICS,
    ]),
    ...mapActions([
      'resetArticleSearch',
      'resetVideo',
      'resetFacebook',
      'resetTwitter',
      'resetImagesStates',
      'resetPocket',
      'resetQuotes',
      'setPosts',
      'setTrendingStatus',
      'setSidebarCustomization',
      'setCoveredStoriesRunOnce',
      'setCoveredStoriesLoader',
      'setArticleAutomationListingSelectedAll',
      'setVideoAutomationListingSelectedAll',
      'setVideoSelectedAutomations',
      'setRssAutomationListingSelectedAll',
      'setPublicationStatus',
      'setPostReopenStatus',
      'setPostRepostStatus',
      'fetchWorkspaceNotifications',
      'resetNotificationsPreference',
      'resetNotifications',
      'fetchLabels',
      'fetchWorkspaceHashtags',
      'fetchOPMLHistory',
      'fetchBlockedSources',
      'fetchAddedSources',
      'allCuratedTopics',
      'fetchCustomSidebar',
      'fetchDiscover',
      'fetchGmbAccounts',
      'fetchEvergreenAutomations',
      'fetchAllEvergreenAutomations',
      'fetchImportantNotifications',
      'fetchCSVAutomationsListing',
    ]),

    // initialize workspace change

    initializeDiscoveryWorkspaceChange(routeName) {
      this.fetchDiscover()
      if (!routeName) {
        routeName = 'topics'
        if (this.$route.name === 'topics') {
          this.fetchCustomSidebar()
          this.SET_TOPICS_PAGE_LOADED(false)
          console.log('set status to false')
        }

        if (this.$route.name !== 'workspaces') {
          routeName = this.$route.name
        }
      }
      if (routeName && routeName === 'articles') {
        this[articleTypes.SET_SEARCH_DISPLAY_TOPICS](true)
        this[articleTypes.SET_SEARCH_ARTICLES_TOPIC](null)
        this[articleTypes.SET_SEARCH_CONTENT_TYPE]('article')

        this.SET_TOPIC_MODULE('content')
        this.setPosts([])
      }
      this.initializePublishSection('Discovery')
    },

    // initialize Quotes

    initializeQuotes() {
      console.log('Created section Quotes')
      this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({ topic: null, type: 'quotes' })
      this.$router.push({ name: 'topics_quotes' })
    },

    initializeUtms() {
      console.debug('Method:initializeUtms')
      this.fetchAllUtms()
    },

    initializeHashtags() {
      console.debug('Method:initializeHashtags')
      this.fetchWorkspaceHashtags()
    },

    initializePlanSection() {
      console.debug('Method::initializePlanSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.Plan()
      }
    },

    initializeTeamSection() {
      console.debug('Method::initializeTeamSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchBlogs')
        this.$store.dispatch('fetchSocialAccounts')
      }
    },

    initializeMiscellaneousSection() {
      console.debug('Method::initializeMiscellaneousSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.initializeUtms()
      }
    },

    initializeAutomationSection() {
      console.debug('Method::initializeAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.fetchAutomationsCounts()
      }
    },

    initializeSaveArticleBlogAutomationSection() {
      console.debug('Method::initializeSaveArticleBlogAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchBlogs')
        this.$store.dispatch('fetchSocialAccounts')
        this.$store.dispatch('fetchIntegrationsAccounts')
        this.initializeHashtags()
        if (this.getTopics.length === 0) {
          this.getCustomTopics()
        }
      }
    },

    initializeSaveArticleSocialAutomationSection() {
      console.debug('Method::initializeSaveArticleSocialAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        if (this.getTopics.length === 0) {
          this.getCustomTopics()
        }
        this.initializeHashtags()
        this.$store.dispatch('fetchIntegrationsAccounts')
      }
    },

    initializeVideoBlogAutomationListingSection() {
      console.debug('Method::initializeVideoBlogAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      this.setVideoAutomationListingSelectedAll(false)
      this.setVideoSelectedAutomations([])
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchBlogs')
        this.$store.dispatch('fetchSocialAccounts')
        if (this.getVideoBlogAutomationListing.fetchStatus === false) {
          this.fetchVideoBlogAutomations()
        }
      }
    },

    initializeVideoSocialAutomationListingSection() {
      console.debug('Method::initializeVideoSocialAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      this.setVideoAutomationListingSelectedAll(false)
      this.setVideoSelectedAutomations([])
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        if (this.getVideoSocialAutomationListing.fetchStatus === false) {
          this.fetchVideoSocialAutomations()
        }
      }
    },

    initializeSaveVideoBlogAutomationSection() {
      console.debug('Method::initializeSaveVideoBlogAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchBlogs')
        this.$store.dispatch('fetchSocialAccounts')
        this.initializeHashtags()
        this.$store.dispatch('fetchIntegrationsAccounts')
      }
    },

    initializeSaveVideoSocialAutomationSection() {
      console.debug('Method::initializeSaveVideoSocialAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        this.initializeHashtags()
        this.$store.dispatch('fetchIntegrationsAccounts')
      }
    },

    initializeEvergreenAutomationListingSection() {
      console.debug('Method::initializeEvergreenAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      // this.setEvergreenAutomationListingSelectedAll(false)
      this.$store.commit(evergreen.SET_EVERGREEN_LISTING_SELECT_ALL, false)
      this.$store.commit(evergreen.SET_EVERGREEN_SELECTED_AUTOMATION, [])
      // this.setEvergreenSelectedAutomations([])
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        if (this.getEvergreenAutomationListing.fetchStatus === false) {
          // this.fetchEvergreenAutomations()
          this.$store.dispatch('fetchEvergreenAutomations')
        }
      }
    },

    initializeBulkAutomationListingSection() {
      console.debug('Method::initializeBulkAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      this.$store.commit(bulkCSV.SET_BULK_CSV_LISTING_SELECT_ALL, false)
      this.$store.commit(bulkCSV.SET_BULK_CSV_SELECTED_AUTOMATION, [])
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        console.debug(this.getCSVAutomationListing.fetchStatus)
        if (this.getCSVAutomationListing.fetchStatus === false) {
          this.$store.dispatch('fetchCSVAutomationsListing')
        }
      }
    },

    initializeSaveEvergreenAutomationSection() {
      console.debug('Method::initializeSaveRssAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        this.initializeHashtags()
        this.initializeUtms()
        this.$store.dispatch('fetchIntegrationsAccounts')
      }
    },

    initializeRssAutomationListingSection() {
      console.debug('Method::initializeRssAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      this.$store.commit(
        rssTypes.SET_RSS_AUTOMATION_LISTING_SELECTED_ALL,
        false
      )
      this.$store.commit(rssTypes.SET_SELECTED_RSS_AUTOMATION_ITEMS, [])
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        if (this.getRssAutomationListing.fetchStatus === false) {
          // this.fetchRssAutomations()
          this.$store.dispatch('fetchRSSAutomationsList')
        }
      }
    },

    initializeSaveRssAutomationSection() {
      console.debug('Method::initializeSaveRssAutomationSection')
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        this.$store.dispatch('fetchIntegrationsAccounts')
        this.initializeHashtags()
      }
    },

    initializePublishSection(type) {
      console.debug('Method::initializePublishSection', type)
      if (this.getWorkspaces.activeWorkspace._id) {
        if (type === 'Blog' || type === 'Discovery') {
          this.$store.dispatch('fetchBlogs')
          this.$store.dispatch('fetchIntegrationsAccounts')
          this.initializeHashtags()
          this.$store.dispatch('fetchAllAutomations')
        }
        this.$store.dispatch('fetchSocialAccounts')

        if (type === 'Social' || type === 'Discovery') {
          this.$store.dispatch('fetchIntegrationsAccounts')
          this.initializeHashtags()
          this.initializeUtms()
        }
      }
    },

    initializeComposerCommonSection() {
      console.debug('Method::initializeComposerCommonSection')
      this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, 'article')
      this.resetArticleSearch()
      this.resetVideo()
      this.resetFacebook()
      this.resetTwitter()
      this.resetImagesStates()
      this.resetPocket()
      this.resetQuotes()
    },

    initializeComposerSection(setFolder = false) {
      console.debug('Method::initializeComposerSection')
      // This code will be move to "App.vue" later
      setTimeout(function () {
        // eslint-disable-next-line
        var $carousel = $('#notificationCarousel')
        const totalItems = $carousel.find('.carousel-item').length
        const currentIndex = $carousel.find('.carousel-item.active').index() + 1
        const slideCounter = currentIndex + ' / ' + totalItems
        $carousel.find('.sliderCount_custom').html(slideCounter)

        // eslint-disable-next-line
        $('#notificationCarousel').on('slid.bs.carousel', function () {
          const totalItems = $carousel.find('.carousel-item').length
          const currentIndex =
            $carousel.find('.carousel-item.active').index() + 1
          const slideCounter = currentIndex + ' / ' + totalItems
          $carousel.find('.sliderCount_custom').html(slideCounter)
        })
      }, 500)
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
      this.$store.commit(composer.SET_PUBLICATIONS_DEFAULT, setFolder)
      this.initializeComposerCommonSection()
      this.resetComposerStates()
      this.initializePublishSection('Blog')
    },

    initializeComposerBlogSection() {
      console.debug('Method::initializeComposerBlogSection')
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
      this.$store.commit(composer.SET_COMPOSER_VERSION_HISTORY, [])
      this.$store.commit(composer.SET_EDITOR_TYPING_STATUS, false)
      this.$store.commit(composer.SET_EDITOR_TYPING_INTERVAL, null)
      this.$store.commit(composer.SET_TYPING_TEXT_INTERVAL, null)
      this.$store.commit(composer.SET_PLAN_CONTROL, true)
      this.$store.commit(composer.SET_COMPOSER_ACTIVE_PLAN_MEMBERS, [])

      if (this.getWorkspaces.activeWorkspace._id) {
        this.initializePublishSection('Blog')
        if (this.getPublicationFolderStatus === false) {
          this.fetchPublicationFolders()
          this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, true)
        }
      }

      this.initializeComposerCommonSection()
      // if (this.getPublishSelection.status === 'draft' && !this.getPublishSelection.repost && !this.getPublishSelection.reopen) {
      //   this.blogDraftTimmer()
      // }
      // && !this.getPublishSelection.repost
      if (
        this.getPublishSelection.status === '' &&
        !this.getPublishSelection.reopen
      ) {
        if (this.$route.params.id && !this.getPublishSelection.repost) {
          this.editPublication(
            this.$route.params.id,
            false,
            false,
            true,
            'blog'
          )
        } else {
          this.setPublicationStatus('draft')
          this.$store.commit(
            blogPosting.SET_BLOG_POSTING_TYPE,
            'Composer Article'
          )
          this.processBlogPost(true, false, true)
        }
      }

      this.setPostRepostStatus(false)
    },

    initializeComposerSocialSection() {
      console.debug('Method::initializeComposerSocialSection')
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)

      if (this.getWorkspaces.activeWorkspace._id) {
        this.initializePublishSection('Social')
        if (this.getPublicationFolderStatus === false) {
          this.fetchPublicationFolders()
          this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, true)
        }
      }

      this.initializeComposerCommonSection()
      this.$store.commit(commonTypes.SET_TWITTER_VARIATION, [])
      if (
        this.getPublishSelection.status === 'draft' &&
        !this.getPublishSelection.repost &&
        !this.getPublishSelection.reopen
      ) {
        this.socialDraftTimmer()
      }

      if (
        this.getPublishSelection.status === '' &&
        !this.getPublishSelection.repost &&
        !this.getPublishSelection.reopen
      ) {
        if (this.$route.params.id) {
          this.editPublication(this.$route.params.id, false, false, true)
        } else {
          this.$store.commit(
            blogPosting.SET_BLOG_POSTING_TYPE,
            'Composer Social'
          )
          this.processSocialShare(true, false, true)
        }
      }
      this.setPostRepostStatus(false)
    },
    socialDraftTimmer() {
      this.socialPublicationInterval = this.triggerSocialPublicationInterval()
    },
    // blogDraftTimmer () {
    //   this.blogPublicationInterval = this.triggerBlogPublicationInterval()
    // },

    // sources section
    initializeWebSource() {
      this.RESET_SOURCES_STATE()
      this.getCustomTopics()
      this.fetchAddedSources()
      this.fetchBlockedSources()
      this.fetchOPMLHistory()
    },

    initializeSection(notificationFlag = true) {
      console.debug('Method::initializeSection', this.$route.name)
      const routeName = this.$route.name
      const activeWorkspaceMember = this.getLoggedUserRole(
        this.getWorkspaces.activeWorkspace
      )
      if (activeWorkspaceMember?.role !== 'approver') {
        this.fetchImportantNotifications()
      }
      this.workspaceBroadCastListeners()
      this.resetNotifications()
      if (notificationFlag) {
        this.resetNotificationsPreference()
      }
      this.fetchWorkspaceNotifications({})

      // this.fetchUserNotifications(null, 'system')
      // this.fetchUserNotifications(null, 'team')

      switch (routeName) {
        case 'plan':
          this.initializePlanSection()
          break
        case 'team':
          this.initializeTeamSection()
          break
        case 'miscellaneous':
          this.initializeMiscellaneousSection()
          break
        case 'automation':
          this.initializeAutomationSection()
          break
        /* case 'articleBlogAutomationListing':
          this.initializeArticleBlogAutomationListingSection()
          break
        case 'articleSocialAutomationListing':
          // this.initializeArticleSocialAutomationListingSection()
          break */
        case 'saveArticleBlogAutomation':
          this.initializeSaveArticleBlogAutomationSection()
          break
        case 'saveArticleSocialAutomation':
          this.initializeSaveArticleSocialAutomationSection()
          break
        case 'videoBlogAutomationListing':
          this.initializeVideoBlogAutomationListingSection()
          break
        case 'videoSocialAutomationListing':
          this.initializeVideoSocialAutomationListingSection()
          break
        case 'saveVideoBlogAutomation':
          this.initializeSaveVideoBlogAutomationSection()
          break
        case 'saveVideoSocialAutomation':
          this.initializeSaveVideoSocialAutomationSection()
          break
        case 'evergreenAutomationListing':
          this.initializeEvergreenAutomationListingSection()
          break
        case 'rssAutomationListing':
          this.initializeRssAutomationListingSection()
          break
        case 'saveRssAutomation':
          this.initializeSaveRssAutomationSection()
          break
        case 'web_sources':
          this.initializeWebSource()
          break
        case 'composer':
          this.initializeComposerSection()
          break
        case 'composerBlog':
          this.initializeComposerBlogSection()
          break
        case 'composerSocial':
          this.initializeComposerSocialSection()
          break
        default:
          break
      }
    },
  },
}
