<script setup>
import { ref, computed, watch, defineProps} from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/instagram_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

// state
const {
  overviewReelsAnalyticsData,
  dataZoomOptions,
  isReportView,
  isLoadingStates,
  legendOptions,
  analyticsDesignSystem,
  routes,

  generateStackedChartOptions,
} = useInstagramAnalytics('Reels Performance')

const props = defineProps({
  type: {
    type: String,
    default: 'engagement',
  },
})

const optionsDropdown = [
  {
  label: 'Reels Performance by Engagement',
  type:'engagement',
  chartLabel: 'Engagement',
  tooltip: `Engagement measures user interactions with your reel, including likes, comments, and shares during the selected time period, highlighting how well your content is resonating with viewers.`,
  },
  {
    label: 'Reels Performance by Watch Time (sec)',
    type:'watch_time',
    chartLabel: 'Watch Time (sec)',
    tooltip: `Watch Time (sec) shows the total number of seconds users have spent watching your reel during the selected time period, reflecting how engaging and captivating your content is.`,
  },
  {
    label: 'Reels Performance by Shares',
    type:'shares',
    chartLabel: 'Shares',
    tooltip: `Initial Plays measure the number of times your reel was played for the first time during the selected time period, indicating its immediate reach and viewer interest.`,
  },
]

const engagementChartMap = [
  {
    name: 'Likes',
    stack: 'Engagements',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'likes',
    yIndex: 0,
  },
  {
    name: 'Comments',
    stack: 'Engagements',
    color: '#8081B9',

    position: 'left',
    api_response_key: 'comments',
    yIndex: 0,
  },
  {
    name: 'Saved',
    stack: 'Engagements',
    color: '#F2CA6B',
    position: 'left',
    api_response_key: 'saves',
    yIndex: 0,
  },
  {
    name: 'Total Reels',
    stack: 'Reels',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]

const watchTimeChartMap = [
  {
    name: 'Watch Time',
    stack: 'Watch Time',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'total_watch_time',
    yIndex: 0,
  },
  {
    name: 'Total Reels',
    stack: 'Reels',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]

const initialPlaysChartMap = [
  {
    name: 'Shares',
    stack: 'Shares',
    color: '#8AC1A2',
    position: 'left',
    api_response_key: 'shares',
    yIndex: 0,
  },
  {
    name: 'Total Reels',
    stack: 'Reels',
    color: '#8ABAF3',
    position: 'right',
    api_response_key: 'total_posts',
    yIndex: 1,
  },
]
// Dynamic chart configuration generation
const generateSeries = (charts, options) => {
  return charts.map((chart) => ({
    name: chart.name,
    type: 'bar',
    stack: chart?.stack || undefined,
    data: options[chart.api_response_key] || [],
    color: chart.color,
    colorBy: 'series',
    barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
    yAxisIndex: chart?.yIndex,
    areaStyle: { opacity: 0.4, cursor: 'auto' },
    cursor: 'auto',
  }));
};

const generateLegend = (charts) => ({
  ...legendOptions,
  data: charts
    .map((chartType) =>
      !IGNORE_LEGENDS.includes(chartType.name) ? chartType.name : null
    )
    .filter(Boolean),
});

const updateChartOptions = (chartOptions, charts, options) => {
  chartOptions.value.series = generateSeries(charts, options);
  chartOptions.value.legend = generateLegend(charts);
  chartOptions.value.xAxis.data = options?.buckets || [];
};

const engagementChartOptions = ref(generateStackedChartOptions(engagementChartMap));
const watchTimeChartOptions = ref(generateStackedChartOptions(watchTimeChartMap));
const initialPlaysChartOptions = ref(generateStackedChartOptions(initialPlaysChartMap));

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.type === 'engagement')
)
const getSelectedType = computed(() => {
  return isReportView.value ? optionsDropdown.find((i) => i.type === props.type)?.label || '' : selectedOptionType.value.label
})

const isNoData = computed(() => {
  return overviewReelsAnalyticsData.value?.reels?.buckets?.length === 0
})

const getSelectedChartOptions = computed(() => {
  const option = isReportView.value ? props.type : selectedOptionType.value.type
  switch (option) {
    case 'watch_time':
      return watchTimeChartOptions.value;
    case 'shares':
      return initialPlaysChartOptions.value;
    case 'engagement':
      return engagementChartOptions.value;
    default:
      return watchTimeChartOptions.value;
  }
})

const IGNORE_LEGENDS = ['Total Reels']

watch(
  () => overviewReelsAnalyticsData.value,
  () => {
    const options = overviewReelsAnalyticsData.value?.reels || {}
    updateChartOptions(engagementChartOptions, engagementChartMap, options);
    updateChartOptions(watchTimeChartOptions, watchTimeChartMap, options);
    updateChartOptions(initialPlaysChartOptions, initialPlaysChartMap, options);
  },
  {
    deep: true,
    immediate: true,
  }
)

const isLoading = computed(() => isLoadingStates.value?.[routes.OVERVIEW_REELS_ANALYTICS])

</script>

<template>
  <AnalyticsCardWrapper type="impressions-chart-fb" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2 w-7">
            <CstDropdown
                dropdown-placement="bottom"
                container-classes="!max-h-96"
                dropdown-classes= '!rounded-md !border-[#70707029]'
                button-classes="flex !px-5 !w-[23rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
              >
                <template v-slot:arrow>
                  <img :src="ChevronDownIcon" alt="chevron-down" />
                </template>
                <template v-slot:selected>
                  <p class="text-[14px] font-weight-500 capitalize">
                    {{ getSelectedType }}
                  </p>
                </template>
                <template v-slot>
                  <template
                    v-for="(item, index) in optionsDropdown"
                    :key="`activity_type_${index}`"
                  >
                    <CstDropdownItem @click="selectedOptionType = item">
                      <p class="text-gray-900 text-sm">{{ item.label }}</p>
                    </CstDropdownItem>
                  </template>
                </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ selectedOptionType.tooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...getSelectedChartOptions,
                  ...dataZoomOptions,
                  grid: { ...getSelectedChartOptions.grid, bottom: 70 },
                }
              : getSelectedChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found h-[350px]">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
