<template>
  <div class="cs-hr-scroll w-full px-1 overflow-auto absolute h-full pb-20">
          <div class="flex items-center sticky top-0 bg-white">
                   <span
class="text-cs-primary cursor-pointer rounded-md text-lg my-2 px-2.5 py-1 mx-px hover:bg-cs-secondary"
                         @click="returnBack"><i class="fas fa-arrow-left "></i>
                   </span>
            <span class="text-black text-base font-semibold ml-auto mx-2">Video Preview</span>
          </div>
    <hr class="m-0 hr-color">
                  <div v-if="previewPost" class="body px-2 py-2">
                    <p class="my-2" v-html="previewPost.title"></p>
                    <div class="w-100">
                      <iframe
                          v-if="previewPost.heightRatio"
                          width="100%"
                          :height="previewPost.heightRatio"
                          :src="previewPost.url"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                      ></iframe>
                      <iframe
                          v-else
                          width="100%"
                          height="350"
                          :src="previewPost.url"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                      ></iframe>
                    </div>
                    <p v-if="previewPost.description" class="text-justify">{{ previewPost.description }}</p>
                  </div>
  </div>
</template>

<script>
export default {
  name: "VideoPreview",
  props:{
    previewPost: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    returnBack(){
      this.$emit('handle-preview','closeVideo',false);
    },
  }
}
</script>

<style scoped>

</style>