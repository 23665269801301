<script setup>
import ConfirmBox from '@common/components/dialogs/ConfirmBox'

defineProps({
  removeLabel: {
    type: Function,
    default: () => {},
  },
})
</script>

<template>
  <ConfirmBox
    id="removeLabel"
    :call-back-action="removeLabel"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Label</h2>
    </template>

    <template v-slot:footer>
      <p class="text-center">
        Deleting this label will also remove it from all other posts where it's
        been used. Are you sure you want to continue?
      </p>
    </template>
  </ConfirmBox>
</template>

<style scoped></style>
