<template>
  <div
    id="influencer_follow_modal"
    class="modal fade normal_modal retweet_model"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Follow/Unfollow</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div v-if="getTwitterAccounts.items.length === 0" class="modal_body">
          <InfluencerTwitterNoAccountExist></InfluencerTwitterNoAccountExist>
        </div>
        <template v-else>
          <div class="modal_body">
            <div
              v-if="getInfluencerFollow.fetchInfluencerFollowersLoader"
              class="p-4"
            >
              <beat-loader :color="'#436aff'"></beat-loader>
            </div>

            <div v-else class="social_accounts follow-accounts">
              <div
                v-for="(account, index) in getTwitterAccounts.items"
                class="follow-item d-flex align-items-center"
              >
                <div class="checkbox_input_image checkbox_social_profile">
                  <label
                    v-tooltip="{
                      content: channelTooltipHTML('twitter', account),
                      classes: 'team_tooltip',
                      allowHTML: true,
                      theme: 'light',
                    }"
                    :for="'selection_' + account.twitter_id"
                    class="checkbox_right"
                  >
                    <individual-channel-tooltip
                      :account="account"
                      :type="'twitter'"
                    >
                    </individual-channel-tooltip>
                  </label>
                  <span class="ml-3 align-content-center">
                    <template v-if="isAccountFollow(account._id)">
                      You X (Twitter) account {{ account.name }} already following
                      this influencer.
                    </template>
                    <template v-else>
                      Follow influencer from your X (Twitter) account ({{
                        account.name
                      }}).
                    </template>
                  </span>
                </div>

                <button
                  :class="{ dark_gray: isAccountFollow(account._id) }"
                  :data-cy="'twitter_influencer_follow_button_' + account._id"
                  class="btn gradient_btn ml-auto"
                  :disabled="account.is_processing"
                  @click.prevent="
                    processInfluencerFollow({
                      account: account._id,
                      follow: !isAccountFollow(account._id),
                      index: index,
                    })
                  "
                >
                  <span v-if="isAccountFollow(account._id)">Unfollow</span>
                  <span v-else>Follow</span>
                  <!--<clip-loader :color="'#fffhhh'" :size="'16px'" v-if="getInfluencerFollow.processInfluencerFollowLoader"></clip-loader>-->
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfluencerTwitterNoAccountExist from './dialogs/sub-components/InfluencerTwitterNoAccountExist'

export default {
  components: {
    InfluencerTwitterNoAccountExist
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getInfluencerTweet',
      'getInfluencerFollow',
      'getWorkspaces'
    ])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions(['processInfluencerFollow']),
    isAccountFollow (accountId) {
      const list = this.getInfluencerFollow.influencerFollowers.filter(
        (item) => {
          console.debug(list)
          if (
            item.influencer === this.getInfluencerFollow.influencer &&
            item.workspace_id === this.getWorkspaces.activeWorkspace._id &&
            item.account_id === accountId
          ) {
            return true
          }
        }
      )
      if (list.length === 0) return false
      return true
    }
  }
}
</script>
