<template>
  <b-modal
    v-model="modelShow"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    size="lg"
  >
    <div class="flex flex-col max-h-[500px]">
      <!--Header::start-->
      <div class="flex flex-row items-center justify-between px-6 py-5">
        <div class="flex flex-col justify-start">
          <span class="font-medium text-base">Bulk Reconnect Accounts</span>
        </div>
        <!--ButtonClose::start-->
        <div>
          <span
            class="h-8 w-8 cursor-pointer flex justify-center items-center bg-gray-300 hover:bg-gray-500 text-black-400 rounded-lg p-3.5 text-lg border-0"
            @click="closeBulkReconnect"
          >
            <i class="fal fa-times"></i>
          </span>
        </div>
        <!--ButtonClose::end-->
      </div>
      <!--Header::end-->

      <!--Body::start-->
      <div
        v-if="selectedAccounts.length"
        class="px-6 py-5 flex flex-col space-y-3 max-h-[430px]"
      >
        <!--Info::start-->
        <div
          class="flex flex-row space-x-2 bg-[#0068e51a] opacity-1 p-3 rounded-sm text-cs-primary"
        >
          <img
            class="w-5 h-5"
            alt="info_icon"
            :src="require('@assets/img/icons/info_icon_blue.svg')"
          />
          <span
            >{{ selectedAccounts.length - failedAccounts.length }} of
            {{ selectedAccounts.length }} accounts successfully updated</span
          >
        </div>
        <!--Info::end-->

        <!--SecondaryInfo::start-->
        <div v-if="failedAccounts.length">
          Following social accounts were unable to reconnect due to various
          reasons. Please make sure you are logged in with the same account.
        </div>
        <!--SecondaryInfo::end-->

        <!--FailedAccounts::start-->
        <div class="grid gap-x-3.5 gap-y-2 grid-cols-3 h-full overflow-y-auto">
          <template v-for="(account, index) in failedAccounts" :key="`bulk_reconnected_accounts_${index}`">
            <SocialAccount
              class="border-2 border-gray-300 border-solid rounded-lg shadow-sm p-3"
              :account="account"
              :external-link="externalLink"
              :platform-identifier="platformType"
            />
          </template>
        </div>
        <!--FailedAccounts::end-->
      </div>
      <!--Body::end-->
    </div>
  </b-modal>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  onUnmounted,
  inject,
} from 'vue'
import SocialAccount from '@common/components/PublicAccounts/SocialAccount.vue'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import {getPlatformIdentifierValue} from "@common/lib/integrations";

export default defineComponent({
  name: 'BulkReconnectedAccounts',
  components: { SocialAccount },
  props: {
    externalLink: {
      type: Boolean,
      default: false,
    },
    externalPlatform: {
      type: Array,
      default: () => []
    },
  },
  setup(props) {
    const root = inject('root')
    const { getters } = useStore()

    const modelShow = ref(false)
    const bulkConnectionData = ref({})
    const platformType = ref('')

    onMounted(() => {
      EventBus.$on(
        'toggleBulkReconnectedAccounts',
        ({ toggleValue, connectionData }) => {
          bulkConnectionData.value = connectionData
          platformType.value = Object.values(connectionData)[0]?.process?.toLowerCase()
          if (failedAccounts.value.length) {
            modelShow.value = toggleValue
          } else {
            root.alertMessage(
              `${
                selectedAccounts.value.length - failedAccounts.value.length
              } of ${
                selectedAccounts.value.length
              } accounts successfully updated`,
              'success'
            )
          }
        }
      )
    })

    onUnmounted(() => {
      EventBus.$off('toggleBulkReconnectedAccounts')
    })

    /**
     * List of accounts that were selected for bulk reconnect.
     * @type {ComputedRef<*>}
     */
    const selectedAccounts = computed(() => {
      if(props.externalLink){
        return props.externalPlatform?.filter((account) =>
            Object.keys(bulkConnectionData.value).includes(
                String(getPlatformIdentifierValue(account, platformType.value))
            )
        )
      }
      return getters.getPlatformsList.filter((account) =>
        Object.keys(bulkConnectionData.value).includes(
          String(account[account.channel_identifier])
        )
      )
    })

    /**
     * List of accounts that have failed to successfully update.
     * @type {ComputedRef<*>}
     */
    const failedAccounts = computed(() => {
      if(props.externalLink){
        return selectedAccounts.value.filter(
            (account) =>
                !bulkConnectionData.value[String(getPlatformIdentifierValue(account, platformType.value))]
                    ?.status
        )
      }
      return selectedAccounts.value.filter(
        (account) =>
          !bulkConnectionData.value[String(account[account.channel_identifier])]
            ?.status
      )
    })

    const closeBulkReconnect = () => {
      modelShow.value = false
    }

    return {
      modelShow,
      bulkConnectionData,
      selectedAccounts,
      failedAccounts,
      platformType,
      closeBulkReconnect,
    }
  },
})
</script>

<style scoped></style>
