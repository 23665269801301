<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      step: 'first',
      cancellation_reason: '',
      can_stay: '',
      question: 'I have a question about getting started with Contentstudio.io',
      question_details: '',
      permission_granted: false,
      created_at: '',
      subscription_name: ''
    }
  },
  computed: {
    ...mapGetters(['getCancelPlanLoader', 'getProfile', 'getPlans']),
    showBackButton () {
      return (
        this.step === 'second' || this.step === 'third' || this.step === 'forth'
      )
    },
    getSubscription () {
      return this.getPlan.subscription.display_name
    },
    getAccountCreationDate () {
      return this.getProfile.created_at
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.subscription_name = this.getSubscription
      this.created_at = this.getAccountCreationDate
    })
  },
  methods: {
    moveBack () {
      if (this.step === 'second') return this.moveToStep('first')
      if (this.step === 'third') return this.moveToStep('second')
      if (this.step === 'forth') return this.moveToStep('third')
    },
    moveToStep (step) {
      if (step === 'third') {
        if (this.cancellation_reason === '') { return this.alertMessage('Please select canceling reason.', 'error') }
        if (this.can_stay === '') {
          return this.alertMessage(
            'Please provide some details of canceling.',
            'error'
          )
        }
        if (this.can_stay.length < 10) {
          return this.alertMessage(
            'Canceling details must be at least 10 characters.',
            'error'
          )
        }
      }
      this.step = step
    },
    async cancelPlan () {
      console.debug('Method::cancelPlan')

      const response = await this.$store.dispatch('cancelPlan', {
        name: null,
        cancellation_reason: this.cancellation_reason,
        can_stay: this.can_stay,
        subscription_plan: this.subscription_name,
        account_created_at: this.created_at
      })
      if (response) {
        this.$bvModal.hide('cancelPlanModal')
        this.step = 'first'
        this.$router.push({
          name: 'subscription_cancelled',
          query: { state: 'cancelled' }
        })
      }
    },

    async submitSupportRequest () {
      console.debug('Method::submitSupportRequest')
      if (this.question_details === '') {
        return this.alertMessage(
          'Please provide some details about your question.',
          'error'
        )
      }
      if (this.question_details.length < 10) { return this.alertMessage(' ', 'error') }
      const response = await this.$store.dispatch('submitSupportRequest', {
        name: null,
        cancellation_reason: this.cancellation_reason,
        can_stay: this.can_stay,
        subscription_plan: this.subscription_name,
        account_created_at: this.created_at,
        question: this.question,
        question_details: this.question_details,
        permission_granted: this.permission_granted
      })
      if (response) {
        this.$bvModal.hide('cancelPlanModal')
        this.step = 'first'
      }
    }
  }
}
</script>

<template>
  <!--step_one-->
  <b-modal
    id="cancelPlanModal"
    modal-class="normal_modal cancelPlanStepsModal"
    hide-footer
    hide-header
    :dialog-class="{ step_four: step === 'forth' }"
    @hidden="moveToStep('first')"
  >
    <div class="basic_form">
      <div class="modal_head">
        <h2 v-if="step === 'first'" class=" ">Closing your account?</h2>
        <h2 v-else-if="step === 'second'" class=" "
          >It breaks our <i class="far fa-heart"></i> to see you leave</h2
        >
        <h2 v-else-if="step === 'third'">We still think you're missing out</h2>
        <h2 v-else-if="step === 'forth'">Let us know what's on your mind</h2>
        <i
          class="fal fa-times normal_close"
          @click="$bvModal.hide('cancelPlanModal')"
        ></i>
      </div>

      <div class="modal_body basic_form">
        <transition name="fadeIn" mode="out-in">
          <div v-if="step === 'first'" class="step_one">
            <p class="warning_box"
              ><i class="far fa-exclamation mr-2"></i>You will lose access to
              your account if you cancel your account.</p
            >
          </div>

          <div v-else-if="step === 'second'" class="step_two">
            <p class="desc"
              >You'll lose access to your work if you cancel your account.
              <br />
              Please export anything you want to save first.</p
            >
            <div class="input_field">
              <label for="" class="font-weight-500"
                >Why are you canceling?<span class="reddit ml-1">*</span></label
              >
              <select v-model="cancellation_reason">
                <option value="" disabled="disabled">Select a reason</option>
                <option value="Team not adapting">Team not adapting</option>
                <option value="Missing features">Missing features</option>
                <option value="I don't see enough value for the price"
                  >I don't see enough value for the price</option
                >
                <option value="Price is too high for us to afford it"
                  >Price is too high for us to afford it</option
                >
                <option value="Closing Company/Project/Downsizing"
                  >Closing Company/Project/Downsizing</option
                >
                <option value="Already paying for another account"
                  >Already paying for another account</option
                >
                <option
                  value="Could not upgrade/downgrade due to credit card issue"
                  >Could not upgrade/downgrade due to credit card issue</option
                >
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="input_field">
              <label for="" class="font-weight-500"
                >Please provide some details?
                <span class="reddit ml-1">*</span></label
              >
              <textarea
                v-model="can_stay"
                name=""
                cols="30"
                rows="3"
              ></textarea>
            </div>
          </div>

          <div v-else-if="step === 'third'" class="step_three">
            <p class="desc"
              >We believe the Contentstudio app can greatly benefit your team
              and unfortunately we didn't manage to convey all it can do for
              you.</p
            >
            <br />

            <p class="desc"
              >If you are willing to give us another chance to demonstrate the
              Contentstudio app to you, our amazing support team will be happy
              to assist you.</p
            >
          </div>

          <div v-else-if="step === 'forth'" class="step_four">
            <!--<p class="desc warning_box">Hello! We were about to cancel our plan but decided otherwise. Please help us get more value from <%= brand_name %></p>-->
            <div class="input_field">
              <label for="" class="font-weight-500"
                >What is your question about?</label
              >
              <select v-model="question" name="">
                <option
                  value="I have a question about getting started with Contentstudio.io"
                  >I have a question about getting started with
                  Contentstudio.io</option
                >
                <option
                  value="I am using Contentstudio.io and want to learn more"
                  >I am using Contentstudio.io and want to learn more</option
                >
                <option value="Pricing Questions">Pricing Questions</option>
                <option value="Billing Questions">Billing Questions</option>
                <option value="IOS App Questions">IOS App Questions</option>
                <option value="Android App Questions"
                  >Android App Questions</option
                >
                <option value="Report a bug">Report a bug</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <!--<div class="input_field">-->
            <!--<label for="" class="font-weight-500">Choose support speed</label>-->
            <!--<div class="radio_input_image block">-->
            <!--<input value="upgrade_annually"   type="radio" id="awesomeSupport"-->
            <!--name="awesomeSupport">-->
            <!--<label class="radio_left" for="awesomeSupport">Awesome support</label>-->
            <!--</div>-->
            <!--<div class="radio_input_image block">-->
            <!--<input value="upgrade_annually"   type="radio" id="dropAndAnswer"-->
            <!--name="awesomeSupport">-->
            <!--<label class="radio_left" for="dropAndAnswer">Drop everything and answer me</label>-->
            <!--</div>-->
            <!--</div>-->
            <div class="input_field">
              <label for="" class="font-weight-500">Write us details</label>
              <textarea
                id=""
                v-model="question_details"
                name=""
                cols="30"
                rows="6"
              ></textarea>
            </div>

            <div class="input_field">
              <div class="checkbox_input_image block">
                <input
                  id="detailCheckbox"
                  v-model="permission_granted"
                  value="upgrade_annually"
                  type="checkbox"
                />
                <label class="checkbox_left top_side" for="detailCheckbox"
                  >I give permission for Contentstudio.io developers to perform
                  a one-time login to my account, If this is necessary to solve
                  a technical issue or bug I'm experiencing.</label
                >
              </div>
            </div>
          </div>
        </transition>

        <div class="input_field d-flex align-content-center">
          <div v-if="showBackButton" class="left">
            <b-button variant="studio-theme-grey-space" @click="moveBack">
              Back
            </b-button>
          </div>

          <div class="right ml-auto">
            <template v-if="step === 'first' || step === 'second'">
              <button
                v-if="step === 'first'"
                class="p-0 border-0 red border_btn btn large_btn btn_loader loader_right"
                @click="moveToStep('second')"
              >
                <span>Close account</span>
              </button>

              <button
                v-if="step === 'second'"
                class="p-0 border-0 red border_btn btn large_btn btn_loader loader_right"
                @click="moveToStep('third')"
              >
                <span>Cancel plan</span>
              </button>

              <b-button
                variant="studio-theme-space"
                class="ml-3"
                @click="$bvModal.hide('cancelPlanModal')"
              >
                Keep my account
              </b-button>
            </template>

            <template v-else-if="step === 'third'">
              <button
                class="p-0 border-0 red border_btn btn large_btn btn_loader loader_right"
                :disabled="getCancelPlanLoader"
                @click="cancelPlan"
              >
                <span>Disable my plan</span>
                <clip-loader
                  v-if="getCancelPlanLoader"
                  :color="'#db5353'"
                  :size="'16px'"
                ></clip-loader>
              </button>

              <b-button
                variant="studio-theme-space"
                class="ml-3"
                @click="moveToStep('forth')"
              >
                Talk to support
              </b-button>
            </template>
            <template v-else-if="step === 'forth'">
              <b-button
                variant="studio-theme-space"
                class="ml-3"
                :disabled="getCancelPlanLoader"
                @click="submitSupportRequest"
              >
                Submit
                <span>
                  <clip-loader
                    v-if="getCancelPlanLoader"
                    class="ml-2"
                    :color="'#fff'"
                    :size="'16px'"
                  ></clip-loader>
                </span>
              </b-button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="less">
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.2s;
}

.fadeIn-enter, .fadeIn-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.step_one {
  .warning_box {
    padding: 0.8rem 1rem;
  }
}
</style>
