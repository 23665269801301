<template>
  <div>
    <template
      v-if="parentState.savedPromptsToggler || parentState.historyToggler"
    >
      <div
        class="fixed w-[70%] h-screen min-h-[100vh] bottom-0 left-0 bg-[#20232440]"
        @click="handleBackdropClose"
      ></div>
    </template>
    <div
      class="w-full flex items-center justify-end h-screen overflow-auto"
      :style="{
        boxShadow: '-2px 0px 10px rgba(69, 68, 125, 0.1)',
      }"
    >
      <!--saved Prompts dialog -->
      <transition name="slide">
        <div
          v-show="parentState.savedPromptsToggler || parentState.historyToggler"
          class="absolute right-[100%] h-screen flex"
        >
          <div class="w-16 h-16 bg-transparent"></div>
          <SavedPrompts
            v-show="
              parentState.savedPromptsToggler && !parentState.historyToggler
            "
            :type="parentState.type"
            :toggle-chat-dialogs="parentState.toggleChatDialogs"
            :handle-custom-prompts="parentState.handleCustomPrompts"
          />
          <ChatHistory
            v-show="
              parentState.historyToggler && !parentState.savedPromptsToggler
            "
            :chat-history="parentState.chats"
            :active-chat-id="parentState.activeChat.id"
            :toggle-chat-dialogs="parentState.toggleChatDialogs"
            :fetch-chat-by-id="parentState.fetchChatById"
          />
        </div>
      </transition>
      <!--chat dialog -->
      <div
        class="z-1050 flex flex-col w-full h-full bg-[#FBFCFC] p-0 relative overflow-hidden transition-all"
        :style="{
          boxShadow:
            parentState.savedPromptsToggler || parentState.historyToggler
              ? ' -2px 0px 10px rgba(69, 68, 125, 0.1)'
              : 'none',
          borderLeft: '1px solid #DDE3E7',
        }"
      >
        <!-- head -->
        <ChatHeader
          :type="parentState.type"
          :history-toggler="parentState.historyToggler"
          :toggle-chat-dialogs="parentState.toggleChatDialogs"
          :handle-new-chat="parentState.handleNewChat"
          @toggle-dialog-size="parentState.toggleSidebarSize"
        >
        </ChatHeader>
        <!--                chat box-->
        <ChatBox
          :type="parentState.type"
          :modal-toggler="parentState.modalToggler"
          :active-chat="parentState.activeChat"
          :active-chat-loader="parentState.activeChatLoader"
          :fetch-active-chat="parentState.fetchActiveChat"
          :message-loader="parentState.messageLoader"
          :toggle-chat-dialogs="parentState.toggleChatDialogs"
          :send-message="parentState.sendChatMessage"
          :delete-message="parentState.deleteChatMessage"
          :prompt-input="parentState.promptInput"
          :handle-custom-prompts="parentState.handleCustomPrompts"
          :prompts="prompts"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { reactive, onMounted, onUnmounted, ref } from 'vue'
import ChatBox from '@src/modules/AI-tools/ChatBox.vue'
import SavedPrompts from '@src/modules/AI-tools/SavedPrompts.vue'
import ChatHistory from '@src/modules/AI-tools/ChatHistory.vue'
import ChatHeader from '@src/modules/AI-tools/ChatHeader.vue'
import { EventBus } from '@/src/modules/common/lib/event-bus'
import { getFavoritePrompts } from '@common/lib/helper'

const props = defineProps({
  backdropClose: {
    type: Boolean,
    default: false,
  },
  parentState: {
    type: Object,
    default: () => {},
  },
})

const prompts = ref([])

onMounted(() => {
  EventBus.$on('blog-update-prompts', (data) => {
    prompts.value = getFavoritePrompts(data.default_prompts, data.custom_prompts) ?? [];
  })
})

onUnmounted(() => {
  EventBus.$off('blog-update-prompts')
})

const handleBackdropClose = (event) => {
  if (props.parentState.type === 'blog') {
    if (props.parentState.savedPromptsToggler) {
      props.parentState.toggleChatDialogs('openSavedPrompts')
    } else if (props.parentState.historyToggler) {
      props.parentState.toggleChatDialogs('openHistory')
    }
  }
}
</script>

<style lang="scss" scoped>
// Add this to your CSS
.slide-enter-active,
.slide-leave-active {
  transition: all 500ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
