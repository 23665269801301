<template>
  <div
    v-if="
      displayBillingNotification &&
      $store.getters.getBillingActionRequiredStatus
    "
    class="top_head_slider top_head_offer_bar top_side w-max -ml-20"
  >
    <div
      class="notificationCarousel pink d-flex align-items-center justify-content-between w-100"
    >
      <div
        v-if="showDefault"
        class="d-flex align-items-center justify-content-between make-text-center"
      >
        <template v-if="getWorkspaceSuperAdminDetails()._id === getProfile._id">
          <div class="text">
            <p class="d-inline-block align-middle"
              ><strong>Action Required:</strong> We've been trying to charge
              your card several times with no success. Please update your credit
              card to proceed with the charge.
            </p>
          </div>
          <button
            style="
              background: #ff9300;
              color: #fff;
              margin-left: 10px !important;
            "
            class="ml-4 btn btn_white large_btn ml-auto"
            @click="$router.push({ name: 'plan', params: { workspace: getActiveWorkspace.slug  } })"
          >
            View Billing Details
          </button>
        </template>
        <template v-else>
          <div class="text">
            <p class="d-inline-block align-middle"
              ><strong>Action Required:</strong> You have outstanding dues,
              please ask your account owner to clear the pending dues.
            </p>
          </div>
        </template>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-between make-text-center"
      >
        <template v-if="getWorkspaceSuperAdminDetails()._id === getProfile._id">
          <div class="text">
            <p v-if="dateRetry" class="d-inline-block align-middle">
              <strong>Action Required:</strong>
              We have tried charging your card {{ attemptNumber }} times now
              with no success. Please update your card details before
              {{ dateRetry }} to avoid account suspension.
            </p>
            <p v-else class="d-inline-block align-middle">
              <strong>Action Required:</strong>
              We have tried charging your card {{ attemptNumber }} times now
              with no success, account has been set for Cancellation.
            </p>
          </div>

          <button
            v-if="updateUrl"
            style="
              background: #ff9300;
              color: #fff;
              margin-left: 10px !important;
            "
            class="ml-4 btn btn_white large_btn ml-auto"
            @click="updateOrCancelButtonPress(updateUrl)"
          >
            Update Details
          </button>
        </template>
        <template v-else>
          <div class="text">
            <p class="d-inline-block align-middle">
              <strong>Action Required:</strong>
              You have outstanding dues, please ask your account owner to clear
              the pending dues.
            </p>
          </div>
        </template>
      </div>

      <button
        type="button"
        class="close close_slider"
        style="font-size: 26px !important; color: #fff; right: 12px"
        @click="$store.commit('SET_BILLING_ACTION_REQUIRED_STATUS', false)"
        >&times;
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const moment = require('moment')

export default {
  components: {},

  props: {
    displayBillingNotification: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showDefault: true,
      attemptNumber: 0,
      dateRetry: null,
      cancelUrl: null,
      updateUrl: null,
    }
  },
  computed: {
    ...mapGetters(['getPaymentFailedDetails', 'getActiveWorkspace']),
  },
  mounted() {
    this.setText()
  },
  methods: {
    updateOrCancelButtonPress(url) {
      window.open(url, 'self')
    },
    setText() {
      if (
        typeof this.getPaymentFailedDetails !== 'undefined' &&
        this.getPaymentFailedDetails &&
        this.getPaymentFailedDetails.length > 0
      ) {
        this.showDefault = false
        const transaction = this.getPaymentFailedDetails[0]

        this.attemptNumber = transaction.attempt_number

        if (transaction.next_retry_date) {
          const date = transaction.next_retry_date
          this.dateRetry = moment(date).format('Do MMMM, YYYY')
        } else {
          this.dateRetry = null
        }

        this.cancelUrl = transaction.cancel_url
        this.updateUrl = transaction.update_url
      } else {
        this.showDefault = true
      }
    },
  },
}
</script>
