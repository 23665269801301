import { useStore } from '@state/base'
import { computed } from 'vue'
import proxy from '@common/lib/http-common'
import { setUsermavenAnalyticsUrl } from '@src/modules/setting/config/api-utils'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'

const store = useStore()
const {getters, dispatch, commit} = store

const DEFAULT_LINK = "https://app.usermaven.com/share/UMt3MP3KoE/web?domain=contentideas.io";

const API_ACTIONS = {
  CONNECT: 'connect',
  UPDATE: 'update',
  DISCONNECT: 'disconnect',
}
const successMessages = {
  connect: 'Successfully connected to Usermaven!',
  update: 'Successfully updated Usermaven URL!',
  disconnect: 'Successfully disconnected from Usermaven!',
}

/**
 * Computes whether the user is connected to Usermaven analytics.
 *
 * @returns {boolean} True if the user is connected to Usermaven analytics, false otherwise.
 */
const isConnected = computed(() => {
  const activeWorkspace = getters.getActiveWorkspace;
  const usermavenLink = activeWorkspace?.usermaven_link;
  return Array.isArray(usermavenLink) && usermavenLink.length > 0;
});

/**
 * Computes the connected URL for Usermaven analytics.
 *
 * @returns {string} The connected URL for Usermaven analytics.
 */
const usermavenConnectedURL = computed(() => {
  const storedURL = getters.getActiveWorkspace?.usermaven_link
  if(storedURL && Array.isArray(storedURL) && storedURL.length > 0) {
    console.log('storedURL', storedURL)
    return storedURL[0]
  }
  return processUsermavenURL(DEFAULT_LINK)
})

/**
 * Processes a Usermaven URL and returns an updated URL with a query parameter 'hideHeader=true' if the URL meets certain conditions.
 * If the URL is not valid or an error occurs, null is returned.
 *
 * @param {string} url - The Usermaven URL to be processed.
 * @returns {string|null} - The updated URL with 'hideHeader=true' query parameter if the URL is valid and meets the conditions, otherwise null.
 */
function processUsermavenURL(url) {
  try {
    // Create a new URL object
    const parsedURL = new URL(url);

    // Check if the protocol is http or https
    const isValidProtocol = parsedURL.protocol === "http:" || parsedURL.protocol === "https:";
     if (!isValidProtocol) {
      parsedURL.protocol = 'https:'
     }
    // Check if the domain is usermaven.com
    const isValidDomain = parsedURL.hostname === "usermaven.com" || parsedURL.hostname.endsWith(".usermaven.com");

    // Check if the path contains /share
    const containsShare = parsedURL.pathname.includes('/share');

    // Return the updated URL if all conditions are met
    if (isValidDomain && containsShare) {
      // Add the query parameter 'hideHeader=true'
      parsedURL.searchParams.set('hideHeader', 'true');
      return parsedURL.toString();
    } else {
      // Return null if the URL is not valid
      return null;
    }
  } catch (error) {
    // If URL constructor throws an error, the URL is invalid
    return null;
  }
}

export default function useUsermavenAnalytics() {

  /**
   * Makes an API call to setUsermavenAnalyticsUrl with the given URL and action.
   * @param {string} url - The URL to be used in the API call.
   * @param {string} action - The action to be performed in the API call.
   * @returns {Promise<void>} - A promise that resolves when the API call is completed.
   * @throws {Error} - If an unexpected error occurs during the API call.
   */
  const makeApiCall = async (url='',action = '') => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
      }

      switch(action) {
        case 'connect':
        case 'update':
          payload.usermaven_link = [url]
          break
        case 'disconnect':
          payload.usermaven_link = []
          break
        default:
          break
      }

      const { data } = await proxy.post(
        setUsermavenAnalyticsUrl,
        payload
      )

      if (data?.status === true) {
        await commit(workspaceTypes.SET_WORKSPACE_USERMAVEN_ANALYTICS_URL, data?.usermaven_link)
        await dispatch('toastNotification', {
          message: successMessages[action]|| 'Action completed successfully',
          type: 'success',
        })
      }
      else{
        dispatch('toastNotification', {
          message: 'An Unexpected Error Occurred!',
          type: 'error',
        })
      }

    } catch (error) {
      dispatch('toastNotification', {
        message: 'An Unexpected Error Occurred!',
        type: 'error',
      })
    }
  }

  return {
    isConnected,
    usermavenConnectedURL,
    API_ACTIONS,
    DEFAULT_LINK,

    makeApiCall,
    processUsermavenURL,
  }
}
