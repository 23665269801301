<template>
  <div class="article_search_curated_topics">
    <div
      v-for="explore_item in getCuratedTopicsList"
      class="article_search_curated_topics__block"
    >
      <div class="article_search_curated_topics__block__head">
        <h3 v-if="explore_item.heading">{{ explore_item.heading }}</h3>
        <h4>{{ explore_item.sub_heading }}</h4>
      </div>
      <div class="article_search_curated_topics__block__list">
        <template v-if="!explore_item.show_all">
          <template
            v-for="(story, index) in explore_item.list"
            :key="'story_' + index"
          >
            <div
              class="article_search_curated_topics__block__list__item"
              v-if="index < 4"
            >
              <div
                class="article_search_curated_topics__block__list__item__inner"
              >
                <div
                  class="
                    article_search_curated_topics__block__list__item__inner__content
                  "
                  @click.prevent="searchOnCuratedTopic(story)"
                >
                  <div
                    :style="{
                      backgroundImage: `url('${story.picture}')`,
                    }"
                    class="
                      article_search_curated_topics__block__list__item__inner__image
                    "
                  ></div>
                </div>
                <div
                  class="
                    article_search_curated_topics__block__list__item__inner__button
                  "
                >
                  <p
                    :data-cy="'discovery_search_topic_' + story.id"
                    @click.prevent="searchOnCuratedTopic(story)"
                    >{{ story.name }}</p
                  >
                  <button
                    v-if="story.followed"
                    class="
                      btn
                      article_search_curated_topics__block__list__item__inner__button__unfollow
                    "
                    @click.prevent="
                      unfollowCuratedTopic({ topic: story, index: null })
                    "
                  >
                    <i class="cs-checked"></i>
                    <i class="unfollow_icon cs-cross"></i>
                    <span>Following</span>
                    <span class="unfollow_text">Unfollow</span>
                  </button>
                  <button
                    v-else
                    class="
                      btn
                      article_search_curated_topics__block__list__item__inner__button__follow
                    "
                    @click.prevent="handleFollowCuratedTopic({ topic: story })"
                  >
                    <span>Follow</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            v-for="(story, index) in explore_item.list"
            class="article_search_curated_topics__block__list__item"
          >
            <div
              class="article_search_curated_topics__block__list__item__inner"
            >
              <div
                class="
                  article_search_curated_topics__block__list__item__inner__content
                "
                @click.prevent="searchOnCuratedTopic(story)"
              >
                <div
                  :style="{
                    backgroundImage: `url('${story.picture}')`,
                  }"
                  class="
                    article_search_curated_topics__block__list__item__inner__image
                  "
                ></div>
              </div>
              <div
                class="
                  article_search_curated_topics__block__list__item__inner__button
                "
              >
                <p
                  :data-cy="'discovery_search_topic_' + story.id"
                  @click.prevent="searchOnCuratedTopic(story)"
                  >{{ story.name }}</p
                >
                <button
                  v-if="story.followed"
                  class="
                    btn
                    article_search_curated_topics__block__list__item__inner__button__unfollow
                  "
                  @click.prevent="
                    unfollowCuratedTopic({ topic: story, index: null })
                  "
                >
                  <i class="cs-checked"></i>
                  <i class="unfollow_icon cs-cross"></i>
                  <span>Following</span>
                  <span class="unfollow_text">Unfollow</span>
                </button>
                <button
                  v-else
                  class="
                    btn
                    article_search_curated_topics__block__list__item__inner__button__follow
                  "
                  @click.prevent="handleFollowCuratedTopic({ topic: story })"
                >
                  <span>Follow</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="!explore_item.show_all"
        class="article_search_curated_topics__block__footer"
      >
        <p @click.prevent="explore_item.show_all = !explore_item.show_all"
          >Show All</p
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    is_type_of: {},
  },
  computed: {
    ...mapGetters(['getCuratedTopicsList']),
  },
  methods: {
    ...mapActions(['followCuratedTopic', 'unfollowCuratedTopic']),
    async handleFollowCuratedTopic(topic) {
      await this.followCuratedTopic(topic)

      if (!this.getActiveWorkspace.onboarding_steps.discover_content.status) {
        await this.onboardingStepsCompleted('discover_content')
      }
    },
    searchOnCuratedTopic(story) {
      console.log(story)
      const routeName =
        this.is_type_of === 'content'
          ? 'discover_search_web'
          : 'discover_insights_web'
      this.$router.push({ name: routeName, query: { tag: story.name } })
    },
  },
}
</script>
