<template>
  <b-modal
    id="subscription-limits-exceeded-modal"
    size="xl"
    :modal-class="'instaConnect modal fade normal_modal'"
    dialog-class="max_width_for_modal_1000"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Subscription Limits Exceeded</h2>
      <!--            <button-->
      <!--                type="button"-->
      <!--                class="close button-custom"-->
      <!--                data-dismiss="modal"-->
      <!--                v-tooltip.bottom="'Close'"-->
      <!--                @click="$bvModal.hide('subscription-limits-exceeded-modal')">-->
      <!--                &times;-->
      <!--            </button>-->
    </div>
    <div class="modal-content">
      <div class="modal_body basic_form m_t_15">
        <div class="flex-row">
          <div v-if="hasPermission('can_see_subscription')" class="col-12 text-center">
            <h1>You've exceeded your plan limits.</h1>
            <p
              >Our system has detected that you've exceeded some of the limits
              allowed according to your plan.
            </p>
            <br />
            <p>
              You'll have to settle your account by either increasing your
              limits (via add-ons) or removing the extra limits to continue
              using the account.
            </p>
            <br />

            <p> Explore more workspaces <router-link :to="{ name: 'workspaces' }"><b>click here</b></router-link></p>
          </div>
          <div v-else class="col-12 text-center container_for_not_super_admin">
            <h1>This account has exceeded the plan limits.</h1>
            <p
              >Our system has detected that you've exceeded some of the limits
              allowed according to your plan.</p
            >
            <br />
            <p>
              Please notify the account owner
              <b>{{ getSuperAdminDetails().email }}</b> to settle this account
              by either increasing your limits (via add-ons) or removing the
              extra limits to continue using the account.
            </p>
            <br />
            <p> Explore more workspaces <router-link :to="{ name: 'workspaces' }"><b>click here</b></router-link></p>
          </div>
        </div>
        <div v-if="hasPermission('can_see_subscription')" class="flex-row margin-auto d-flex">
          <div class="col text-center center_both_blocks">
            <div class="grey-box center_child">
              <div class="subscription-plan-limits">
                <h3>Plan Limits</h3>

                <div class="subscription-plan-widget">
                  <SubscriptionLimitContainer
                    :title="'Workspaces'"
                    :is-over-used="
                      getActiveWorkspaceOverUsedStatus().workspaces
                    "
                    :total-limit="
                      getSubscriptionLimits('workspaces')
                    "
                    :used-limit="getUsedLimits('workspaces')"
                  ></SubscriptionLimitContainer>
                  <SubscriptionLimitContainer
                    :title="'Social Accounts'"
                    :is-over-used="
                      getActiveWorkspaceOverUsedStatus().profiles
                    "
                    :total-limit="getSubscriptionLimits('profiles')"
                    :used-limit="getUsedLimits('profiles')"
                  ></SubscriptionLimitContainer>
                  <SubscriptionLimitContainer
                    :title="'Team Members'"
                    :is-over-used="
                      getActiveWorkspaceOverUsedStatus().members
                    "
                    :total-limit="getSubscriptionLimits('members')"
                    :used-limit="getUsedLimits('members')"
                  ></SubscriptionLimitContainer>
                  <SubscriptionLimitContainer
                    :title="'Blogs'"
                    :is-over-used="
                      getActiveWorkspaceOverUsedStatus().blogs
                    "
                    :total-limit="getSubscriptionLimits('blogs')"
                    :used-limit="getUsedLimits('blogs')"
                  ></SubscriptionLimitContainer>
                  <SubscriptionLimitContainer
                    :title="'Automations'"
                    :is-over-used="
                      getActiveWorkspaceOverUsedStatus().automations
                    "
                    :total-limit="
                      getSubscriptionLimits('automations')
                    "
                    :used-limit="
                      getUsedLimits('automations')
                    "
                  ></SubscriptionLimitContainer>
                </div>
              </div>
            </div>
            <div class="grey-box center_child">
              <div class="subscription-plan-limits">
                <h3>What you can do to solve this?</h3>
                <div class="text_block_container">
                  <h5 class="ml-0"
                    ><b><u>Option 1:</u></b> Click on the button below to
                    increase your limits with add-ons</h5
                  >
                  <div class="flex justify-content-around mt-3.5 mb-5">
                    <b-button
                        v-if="isPlanUpgradeable"
                        variant="studio-theme"
                        @click="$bvModal.show('upgrade-plan-dialog')"
                    >
                      Upgrade Subscription
                    </b-button>
                    <b-button variant="studio-theme-space" @click="$bvModal.show('increase-limits-dialog')">
                      Increase Limits
                    </b-button>
                  </div>
                </div>
                <div class="text_block_container ml-0">
                  <template v-if="isSuperAdmin">
                  <h5
                    ><b><u>Option 2:</u></b> Remove extra items to stay within
                    your limits</h5
                  >
                  <p
                    v-if="getActiveWorkspaceOverUsedStatus().workspaces"
                    class="ml-0"
                  >
                    <i
                      class="fas fa-exclamation-triangle limit_issue_billing"
                    ></i>
                    {{ getDifference('workspaces', 'Workspace') }}
                  </p>
                  <p
                    v-if="getActiveWorkspaceOverUsedStatus().profiles"
                    class="ml-0"
                  >
                    <i
                      class="fas fa-exclamation-triangle limit_issue_billing"
                    ></i>
                    {{ getDifference('profiles', 'Social Profile') }}
                  </p>
                  <p
                    v-if="getActiveWorkspaceOverUsedStatus().members"
                    class="ml-0"
                  >
                    <i
                      class="fas fa-exclamation-triangle limit_issue_billing"
                    ></i>
                    {{ getDifference('members', 'Team Member') }}
                  </p>
                  <p
                    v-if="getActiveWorkspaceOverUsedStatus().blogs"
                    class="ml-0"
                  >
                    <i
                      class="fas fa-exclamation-triangle limit_issue_billing"
                    ></i>
                    {{ getDifference('blogs', 'Blog') }}
                  </p>
                  <p
                    v-if="getActiveWorkspaceOverUsedStatus().automations"
                    class="ml-0"
                  >
                    <i
                      class="fas fa-exclamation-triangle limit_issue_billing"
                    ></i>
                    {{ getDifference('automations', 'Automation Campaign') }}
                  </p>

                  <div
                    class="text_center"
                    @click="$router.push({ name: 'manageLimits' })"
                  >
                    <b-button variant="studio-theme-space"
                      >Manage Limits</b-button
                    >
                  </div>
                  </template>
                  <template v-else>
                    <h5><b><u>Option 2:</u></b> Notify the account owner <b>{{ getSuperAdminDetails().email }}</b> to remove extra items.
                    </h5>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import usePermissions from "@common/composables/usePermissions";
import SubscriptionLimitContainer from './reusable/SubscriptionLimitContainer'
import useWorkspace from "@common/composables/useWorkspace";

export default {
  components: {
    SubscriptionLimitContainer,
  },
  props: ['isSuperAdmin'],

  setup(){
    const {hasPermission, isPlanUpgradeable} = usePermissions()
    const {getSubscriptionLimits, getUsedLimits} = useWorkspace()
    return {
      hasPermission,
      getSubscriptionLimits,
      getUsedLimits,
      isPlanUpgradeable
    }
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['getProfile', 'getWorkspaces']),
  },
  mounted() {
    this.$bvModal.show('subscription-limits-exceeded-modal')
  },
  methods: {
    ...mapActions([]),
    getDifference(key, type) {
      const total = this.getActiveWorkspaceTotalLimits()[key]
      const used = this.getActiveWorkspaceUsedLimits()[key]

      const diff = Math.abs(total - used)

      let message = 'Remove ' + diff + ' ' + type
      // add an "s" incase plural :P
      message += diff === 1 ? '' : 's'

      return message
    },
    getSuperAdminDetails() {
      const teamMembers = this.getWorkspaces.activeWorkspace.members
      for (let i = 0; i < teamMembers.length; i++) {
        const member = teamMembers[i]
        if (member.role === 'super_admin') {
          return member.user
        }
      }
      return null
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.container_for_not_super_admin {
  padding: 4em 3em !important;
}
.center_both_blocks {
  display: flex !important;
}
.center_child {
  flex: 1;
}
.text_block_container {
  text-align: start;
  margin-top: 20px;
}
</style>
