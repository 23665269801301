<script>
import { mapGetters } from 'vuex'

import Options from '../options/Options'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'

import { commonTypes } from '../../../../store/states/mutation-types'

export default {
  components: {
    Options,
    ImageSection,
    VideoSection,
    HashtagSuggestions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {
    this.$store.commit(
      commonTypes.SET_GMB_BOX_REF,
      this.$refs.gmb_sharing_message
    )
  },

  computed: {
    ...mapGetters([
      'getGmbSharingLoaders',
      'getGmbSharingDetails',
      'getSocialSharingSelection'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.gmb &&
        this.getSocialSharingSelection.activeTab === 'gmb_tab'
      )
    }
  },
  methods: {
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('gmb_sharing_message').value
      if (!text) {
        const message = this.getGmbSharingDetails.message
        document.getElementById('gmb_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Gmb'),
          'Gmb'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Gmb')
    }
  },

  watch: {
    'getGmbSharingDetails.message' (value) {
      this.setSharingBoxHtml(this.processSharingBoxHtml(value, 'Gmb'), 'Gmb')
    }
  }
}
</script>

<template>
  <div
    id="gmb_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'gmb_tab',
      editor_hover: isFileHover,
    }"
    @dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Gmb' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Gmb' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Gmb' })"
  >
    <div class="box_social_tag google_b_bg">
      <i class="fab fa-google"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="gmb_sharing_message"
        ref="gmb_sharing_message"
        :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @keydown="handleKeysOnPopup"
        @paste="sharingMessageChanges($event, 'Gmb')"
        @keyup.space="sharingMessageChanges($event, 'Gmb')"
        @focus="focusSharingBox('Gmb')"
        @click="focusSharingBox('Gmb')"
        @keydown.ctrl.b="sharingMessageFormatting('Gmb', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Gmb', 'is')"
      ></resize-textarea>
      <div id="clone_txt_gmb_tab" class="clone_txt">
        <span
          class="desc"
          :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
        ></span>
        <span
          class="in_active desc"
          :class="{ hide: getSocialSharingSelection.activeTab === 'gmb_tab' }"
          @click="
            focusSharingBox('Gmb'), textareaFocus($refs.gmb_sharing_message)
          "
        ></span>
        <span
          class="exceed"
          :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
        ></span>
      </div>

      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Gmb"
      ></HashtagSuggestions>

      <!--<caption-generation-modal type="Gmb"></caption-generation-modal>-->
      <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'gmb_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="$bvModal.show('upgrade-plan-dialog')">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text_content_bottom">
          <button
              v-if="getSocialSharingSelection.activeTab == 'gmb_tab'"
              :disabled="
            getGmbSharingLoaders.fetch_short_links || !getGmbSharingDetails.url
          "
              class="btn btn-studio-theme-space caption-btn"
              @click="fetchAiCaption(getGmbSharingDetails.url, 'Gmb')"
          >
          <span
              v-tooltip="{
              content: getGmbSharingDetails.url
                ? null
                : 'Caption generation button is disabled until a valid URL is put in the composer box',
              placement: 'top-center',
            }"
          >
            Generate Caption
          </span>
          </button>
        </div>
      </template>

    </div>

    <Options
      type="Gmb"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
    ></Options>
    <clip-loader
      v-if="
        getGmbSharingLoaders.fetch_short_links ||
        getGmbSharingLoaders.fetch_sharing_preview
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>
    <template v-else>
      <VideoSection
          v-if="(getGmbSharingDetails.video && getGmbSharingDetails.video.link) || getGmbSharingLoaders.upload_sharing_video"
          type="Gmb" :details="getGmbSharingDetails"></VideoSection>
      <ImageSection
        v-if="
          getGmbSharingLoaders.upload_sharing_image ||
          getGmbSharingDetails.image
        "
        :class="{ hide: getSocialSharingSelection.activeTab !== 'gmb_tab' }"
        type="Gmb"
        :details="getGmbSharingDetails"
      ></ImageSection>
    </template>
  </div>
</template>
