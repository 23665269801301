<template>
  <DynamicScroller
    ref="scroller"
    :items="arrItems"
    :min-item-size="10"
    class="scroller"
    :page-mode="true"
    key-field="id"
  >
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :data-index="index"
        :size-dependencies="[item.name]"
    >
      <div class="item-row gap-1">
        <div v-for="(media, mediaIndex) in item.chunk" :key="mediaIndex" class="item-card">
          <Asset
              :id="media._id"
              :hide-eye="false"
              :src="isVideo(media['mime_type']) ? media.thumbnail : media.link"
              :alt="media.name"
              :info="media"
              :is-media-asset="true"
              @check="(el) => $emit('check', el)"
              @delete="(id, link) => $emit('delete', id, link)"
              @view="(item) => $emit('view', item)"
              :folder-id="folderId"
          />
        </div>
      </div>
      </DynamicScrollerItem>
    </template>

    <template v-slot:after>
      <beat-loader v-show="atBottom" :color="'#436aff'"></beat-loader>
    </template>
  </DynamicScroller>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import Asset from './Asset'
DynamicScrollerItem.compatConfig = {
  RENDER_FUNCTION: false
}
DynamicScroller.compatConfig = {
  RENDER_FUNCTION: false
}
export default {
  name: 'VirtualAssets',
  components: {
    Asset,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    atBottom: {
      type:Boolean,
      default:false
    },
    folderId: {
      type: String,
      default: 'all',
    }
  },
emits: ['check', 'delete', 'view'],
  data () {
    return {
      count: 0,
      arrItems: []
    }
  },

  watch: {
    assets (newVal, oldVal) {

      this.getData(newVal, 40)
    }
  },
  mounted () {
    this.getData(this.assets, 40)
  },
  methods: {
    isVideo (mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },
    spliteArray (arr, size) {
      if (this.count !== arr.length) {
        const arrLength = arr.length
        arr = arr.slice(this.count, arrLength)
        this.count += arrLength - this.count
      }

      while (arr.length > 0) {
        let chunk = []

        if (this.arrItems.length !== 0) {
          const lastObj = { ...this.arrItems[this.arrItems.length - 1] }.chunk

          if (lastObj.length < 3) {
            chunk = arr.splice(0, 3 - lastObj.length)

            this.arrItems[this.arrItems.length - 1] = {
              id: this.generateId(10),
              chunk: [...lastObj, ...chunk]
            }

            continue
          }
        }

        chunk = arr.splice(0, size)
        this.arrItems.push({
          id: this.generateId(10),
          chunk
        })
      }
    },
    getData (assets, chunk) {
      if (this.count === 0 || this.count === undefined) {
        this.count = assets.length
      }

      return this.spliteArray([...assets], chunk)
    },
    generateId (len) {
      function dec2hex (dec) {
        return dec.toString(16).padStart(2, '0')
      }
      const arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, dec2hex).join('')
    }
  }
}
</script>

<style scoped></style>
