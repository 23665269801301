<template>
  <b-modal
    id="manage-competitors-modal"
    centered
    header-class="cs-modal-header"
    title-class="cs-modal-title"
    no-close-on-backdrop
    dialog-class="!w-[37.5rem]"
    @shown="onShownManageModal"
    @hide="onHideManageModal"
  >
    <!--    header-->
    <template v-slot:modal-title>Add Competitors</template>

    <!--    body-->
    <div class="flex flex-col p-2 !h-[29rem]">
      <!-- title -->
      <div class="mb-1.5 analytic-competitors-addform__search-wrapper">
        <b-form-input
          v-model="reportTitle"
          class="!h-11 !pl-3"
          type="text"
          placeholder="Enter title here..."
          :autofocus="reportTitle === 'Untitled'"
        ></b-form-input>
      </div>
      <!--      search input-->
      <div
        v-tooltip.top="{
          content:
            competitors.length === 5
              ? `You've already added five competitors. Please remove one of them to add another.`
              : '',
          classes: 'w-[14rem]',
        }"
        class="analytic-competitors-addform__search-wrapper"
        :class="competitors.length === 5 ? '!cursor-not-allowed' : ''"
      >
        <b-input-group class="mt-2">
          <template v-slot:prepend>
            <i class="far fa-search"></i>
          </template>
          <b-form-input
            v-model="searchedText"
            class="!h-11"
            :class="competitors.length === 5 ? '!cursor-not-allowed' : ''"
            type="text"
            debounce="500"
            :placeholder="`Enter competitor’s ${platform === 'instagram' ? 'slug/url' : 'page name'} here...`"
            :autofocus="reportTitle !== 'Untitled'"
            :disabled="competitors.length === 5"
          ></b-form-input>
          <template v-slot:append>
            <clip-loader
              v-if="searchLoadingState && searchedText"
              class="append mr-2"
              :color="'#5773fa'"
              :size="'22px'"
            ></clip-loader>
          </template>
        </b-input-group>
        <!--        searched results dropdown-->
        <div
          v-if="showDropDown"
          v-click-away="onClickAway"
          class="relative z-20"
        >
          <transition name="slide-bottom">
            <div
              class="
                w-full
                p-2
                border border-[#E9EFF6]
                shadow-lg
                !bg-white
                rounded-lg
                absolute
                top-2
                max-h-[20rem]
                overflow-y-auto
              "
            >
              <template v-if="showData('success')">
                <template
                  v-for="(searchedResult, index) in searchedCompetitor"
                  :key="`${index}_searchedResult`"
                >
                  <div
                    class="
                      py-1
                      flex
                      items-center
                      rounded-lg
                      px-4
                      hover:bg-gray-50
                      cursor-pointer
                    "
                    @click="addCompetitor(index)"
                  >
                    <img
                      v-if="searchedResult.image"
                      :src="searchedResult.image"
                      :alt="searchedResult.name"
                      class="
                        w-10
                        h-10
                        object-cover
                        border
                        rounded-full
                        border-[#E9EFF6]
                      "
                      @error="
                        $event.target.src =
                          'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                    />
                    <div class="ml-3">
                      <div class=" flex items-center justify-start gap-2">
                        <p class="font-medium truncate max-w-[20rem]">{{
                            searchedResult.name
                                ? searchedResult.name
                                : searchedResult.slug
                          }}</p>
                        <i v-if="searchedResult.verification_status === 'blue_verified'" aria-hidden="true" class="fa fa-check-circle verified_icon text-primary"></i>
                      </div>
                      <p v-if="searchedResult?.location?.city && searchedResult?.location?.country" class="font-0-75rem">{{searchedResult.location.city}}, {{searchedResult.location.country}}</p>
                    </div>
                  </div>
                </template>
              </template>
              <div
                v-else-if="showData('error')"
                class="p-3 !bg-white !cursor-default"
              >
                <p class="capitalize">{{ searchResponse.error }}</p>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!--      competitors list-->
      <div
        v-if="competitors && competitors.length"
        class="w-full flex flex-col items-center justify-center mt-4"
      >
        <transition-group tag="div" class="w-full" name="slide-fade">
          <div
            v-for="(competitor, index) in competitors"
            :key="`competitors_list_${index}`"
            class="
              flex
              items-center
              justify-between
              w-full
              hover:bg-gray-100
              px-4
              my-2
              py-2
              rounded-lg
            "
          >
            <div
              v-if="competitor"
              v-tooltip.top="{
                content: `${
                  competitor.slug ? competitor.slug : competitor.name
                }`,
              }"
              class="flex justify-start items-center select-none"
            >
              <img
                :src="competitor.image"
                class="
                  h-10
                  w-10
                  mr-3
                  rounded-full
                  overflow-hidden
                  object-cover
                  border border-[#E9EFF6]
                "
                alt=""
                @error="
                  $event.target.src =
                    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                "
              />
              <div class="w-64">
                <p class="font-bold truncate">{{
                  competitor.name ? competitor.name : competitor.slug
                }}</p>
              </div>
            </div>
            <span
              v-tooltip="'Remove Competitor'"
              class="
                w-7
                h-7
                rounded-full
                flex
                justify-center
                items-center
                bg-cs-white
                drop-shadow-lg
                z-10
                cursor-pointer
              "
              @click="removeCompetitor(index)"
            >
              <i class="fa fa-times text-red-500" aria-hidden="true"></i>
            </span>
          </div>
        </transition-group>
      </div>
      <div v-else class="w-full h-full flex justify-center items-center">
        <p>No competitors selected.</p>
      </div>
    </div>

    <!--    Footer    -->
    <template v-slot:modal-footer>
      <div class="flex w-full justify-between items-center gap-2">
        <div
          class="
            bg-[#faf2dc]
            flex
            justify-center
            items-center
            rounded-lg
            gap-2
            p-2
          "
        >
          <i class="far fa-exclamation-circle text-[#2f8ae0]"></i>
          <p class="text-sm text-[#b57e00]"
            >Upto 5 competitors can be added.</p
          ></div
        >
        <div class="flex justify-center items-center">
          <CstButton
            variant="secondary"
            class="mr-3 w-28"
            text="Cancel"
            @click="$bvModal.hide('manage-competitors-modal')"
          />
          <CstButton
            class="w-28"
            text="Continue"
            :disabled="
              !competitors.length ||
              saveReportLoadingState ||
              !reportTitle ||
              searchLoadingState
            "
            @click="saveReport"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
// libraries
import { onMounted, inject, computed, watch } from 'vue'
import { EventBus } from '@common/lib/event-bus'

// components
import CstButton from '@ui/Button/CstButton.vue'

// composable
import useCompetitorsManagement from '@src/modules/analytics_v3/composables/useCompetitorsManagement'

const {
  searchResponse,
  searchedCompetitor,
  searchLoadingState,
  saveReportLoadingState,
  reportData,
  competitors,
  reportTitle,
  searchedText,
  platform,
  searchCompetitor,
  addCompetitor,
  removeCompetitor,
  saveReport,
  deleteReport,
} = useCompetitorsManagement() // composable

const root = inject('root')
const { $bvModal } = root

const showDropDown = computed(() => !!searchedCompetitor.value || !!searchResponse.value?.error )

watch(
  () => searchedText.value,
  (value) => {
    if (value) searchCompetitor()
  }
)

onMounted(() => {
  EventBus.$on('show-manage-competitors-modal', (data = null) => {
    if (data) {
      reportData.value = data
    }
    $bvModal.show('manage-competitors-modal')
  })

  EventBus.$on('delete-competitors-report', ({ reportId, reportName }) => {
    deleteReport(reportId, reportName)
  })
})

const onShownManageModal = () => {
  competitors.value = reportData.value?.competitors
    ? [...reportData.value.competitors]
    : []
  reportTitle.value = reportData.value?.name
    ? reportData.value.name
    : 'Untitled'
}

/**
 * @description method to reset the local properties when the modal is hides.
 */
const onHideManageModal = () => {
  searchedText.value = ''
  competitors.value = []
  reportTitle.value = ''
  searchResponse.value = []
  searchLoadingState.value = false
  searchedCompetitor.value = null
  reportData.value = {}
}

const showData = (type = '') => {
  if (type === 'success') {
    return !!searchResponse.value[0]?.competitor_id
  } else if (type === 'error') {
    return !!searchResponse.value?.error
  }
  return false
}

const onClickAway = () => {
  searchedCompetitor.value = null
  searchResponse.value = null
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
