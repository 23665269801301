import { mapGetters, mapActions } from 'vuex'
import {
  fetchShortLinksUrl,
  fetchLinkPreviewUrl,
} from '@src/modules/publish/config/api-utils'
import {
  commonTypes,
  gmb,
  instagramTypes,
  linkedinSharingTypes,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { fetchVariationsURL } from '@src/modules/automation/config/api-utils'
import router from '@src/router'
import { authenticationTypes } from '@state/mutation-types'
import { EventBus } from '@common/lib/event-bus'

export const sharingLinkMixin = {
  computed: {
    ...mapGetters([
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getThreadedTweetsDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getWorkspaces',
      'getBitlyAccount',
      'getReplug',
      'getInstagramSharingDetails',
      'getFacebookSharingCancelledLink',
      'getTwitterSharingCancelledLink',
      'getThreadedTweetsCancelledLink',
      'getLinkedinSharingCancelledLink',
      'getPinterestSharingCancelledLink',
      'getTumblrSharingCancelledLink',
      'getInstagramSharingCancelledLink',
      'getGmbSharingDetails',
      'getGmbSharingCancelledLink',
      'getYoutubeSharingCancelledLink',
    ]),
  },
  methods: {
    ...mapActions([
      'setCommonSharingMessage',
      'setFacebookSharingMessage',
      'setTwitterSharingMessage',
      'setPinterestSharingMessage',
      'setPinterestSharingMessage',
      'setCommonSharingLink',
      'setFacebookSharingLink',
      'setTwitterSharingLink',
      'setThreadedTweetsSharingLink',
      'setPinterestSharingLink',
      'setTumblrSharingLink',
      'setCommonSharingPreview',
      'setFacebookSharingPreview',
      'setTwitterSharingPreview',
      'setPinterestSharingPreview',
      'setTumblrSharingPreview',
      'setCommonSharingImage',
      'setFacebookSharingImage',
      'setCommonCancelledLink',
      'setFacebookCancelledLink',
      'setCommonFacebookSharingLink',
      'setCommonTwitterSharingLink',
      'setCommonLinkedinSharingLink',
      'setCommonPinterestSharingLink',
      'setCommonTumblrSharingLink',
      'setCommonInstagramSharingLink',
      'setShrinkCommonSharingSourceLinkLoader',
      'setShrinkPinterestSharingSourceLinkLoader',
      'setCommonSharingSourceLink',
      'setPinterestSharingSourceLink',
      'setTwitterCancelledLink',
      'setPinterestCancelledLink',
      'setTumblrCancelledLink',
      'setTwitterSharingImage',
      'setInitializeArticlePostCreationLoader',
      'setCommonYoutubeSharingLink',
      'setFetchYoutubeShortLinksLoaders',
      'setFetchCommonSharingPreviewLoader',
      'setFetchLinkedinSharingPreviewLoader',
      'setFetchPinterestSharingPreviewLoader',
      'setFetchTumblrSharingPreviewLoader',
      'setFetchInstagramSharingPreviewLoader',
      'setFetchYoutubeSharingPreviewLoader',
      'setFetchTwitterSharingPreviewLoader',
      'setFetchFacebookShortLinksLoaders',
    ]),

    /**
     * This method is used to cancel the existing preview after that preview of the same link will not be fetched or visible
     * @param type
     */
    cancelSocialPreview(type) {
      console.debug('Method:cancelSocialPreview', type)

      const stateObject = this
      setTimeout(function () {
        if (type === 'Common') {
          stateObject.setCommonCancelledLink(
            stateObject.getCommonSharingDetails.url
          )
          stateObject.setCommonSharingPreview(null)
          stateObject.setCommonSharingImage([])
        } else if (type === 'Facebook') {
          stateObject.setFacebookCancelledLink(
            stateObject.getFacebookSharingDetails.url
          )
          stateObject.setFacebookSharingPreview(null)
          stateObject.setFacebookSharingImage([])
        } else if (type === 'Twitter') {
          stateObject.setTwitterCancelledLink(
            stateObject.getTwitterSharingDetails.url
          )
          stateObject.setTwitterSharingPreview(null)
          stateObject.setTwitterSharingImage([])
        } else if (type === 'Linkedin') {
          stateObject.$store.commit(
            linkedinSharingTypes.SET_SHARING_CANCELLED_PREVIEW,
            stateObject.getLinkedinSharingDetails.url
          )
          stateObject.$store.commit(
            linkedinSharingTypes.SET_SHARING_PREVIEW,
            null
          )
        }
      }, 200)
    },

    checkLongLinkExistence(urls) {
      let status = false
      const stateObject = this
      urls.forEach(function (url, index) {
        if (!stateObject.isShortLink(url)) {
          status = true
          return false
        }
      })
      return status
    },

    fetchShortLinks(urls, type) {
      console.debug('Method:fetchShortLinks', type)
      switch (type) {
        case 'Common':
          // only showing loader if images not added
          if (
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '') &&
            !this.getCommonSharingDetails.url &&
            urls[0] !== this.getCommonSharingCancelledLink
          ) {
            this.setCommonSharingLink(urls[0])
            this.setCommonFacebookSharingLink(urls[0])
            this.setCommonTwitterSharingLink(urls[0])
            this.setCommonLinkedinSharingLink(urls[0])
            this.setCommonPinterestSharingLink(urls[0])
            this.setCommonTumblrSharingLink(urls[0])
            this.setCommonInstagramSharingLink(urls[0])
            this.setCommonYoutubeSharingLink(urls[0])
            this.$store.commit(commonTypes.SET_GMB_SHARING_LINK, urls[0])
            this.setFetchCommonShortLinksLoaders(true)
          }
          break
        case 'Facebook':
          // only showing loader if images not added
          if (
            this.getFacebookSharingDetails.image.length === 0 &&
            (!this.getFacebookSharingDetails.video ||
              this.getFacebookSharingDetails.video.link === '') &&
            !this.getFacebookSharingDetails.url &&
            urls[0] !== this.getFacebookSharingCancelledLink
          ) {
            this.setFacebookSharingLink(urls[0])
            this.setFetchFacebookShortLinksLoaders(true)
          }
          break
        case 'Twitter':
          // only showing loader if images not added
          if (
            this.getTwitterSharingDetails.image.length === 0 &&
            (!this.getTwitterSharingDetails.video ||
              this.getTwitterSharingDetails.video.link === '') &&
            !this.getTwitterSharingDetails.url &&
            urls[0] !== this.getTwitterSharingCancelledLink
          ) {
            this.setTwitterSharingLink(urls[0])
            this.setFetchTwitterShortLinksLoaders(true)
          }
          break
        case 'ThreadedTweets':
          // only showing loader if images not added
          if (
            this.getThreadedTweetsDetails.image.length === 0 &&
            (!this.getThreadedTweetsDetails.video ||
              this.getThreadedTweetsDetails.video.link === '') &&
            !this.getThreadedTweetsDetails.url &&
            urls[0] !== this.getThreadedTweetsCancelledLink
          ) {
            this.setThreadedTweetsSharingLink(urls[0])
            this.setFetchThreadedTweetsShortLinksLoaders(true)
          }
          break
        case 'Linkedin':
          // only showing loader if images not added
          if (
            this.getLinkedinSharingDetails.image.length === 0 &&
            (!this.getLinkedinSharingDetails.video ||
              this.getLinkedinSharingDetails.video.link === '') &&
            !this.getLinkedinSharingDetails.url &&
            urls[0] !== this.getLinkedinSharingCancelledLink
          ) {
            this.$store.commit(linkedinSharingTypes.SET_SHARING_URL, urls[0])
            this.setFetchLinkedinShortLinksLoaders(true)
          }
          break
        case 'Pinterest':
          // only showing loader if images not added
          if (
            this.getPinterestSharingDetails.image.length === 0 &&
            (!this.getPinterestSharingDetails.video ||
              this.getPinterestSharingDetails.video.link === '') &&
            !this.getPinterestSharingDetails.url &&
            urls[0] !== this.getPinterestSharingCancelledLink
          ) {
            this.setPinterestSharingLink(urls[0])
            this.setFetchPinterestShortLinksLoaders(true)
          }
          break
        case 'Tumblr':
          // only showing loader if images not added
          if (
            this.getTumblrSharingDetails.image.length === 0 &&
            (!this.getTumblrSharingDetails.video ||
              this.getTumblrSharingDetails.video.link === '') &&
            !this.getTumblrSharingDetails.url &&
            urls[0] !== this.getTumblrSharingCancelledLink
          ) {
            this.setTumblrSharingLink(urls[0])
            this.setFetchTumblrShortLinksLoaders(true)
          }
          break
        case 'Instagram':
          // only showing loader if images not added
          if (
            this.getInstagramSharingDetails.image.length === 0 &&
            (!this.getInstagramSharingDetails.video ||
              this.getInstagramSharingDetails.video.link === '') &&
            !this.getInstagramSharingDetails.url &&
            urls[0] !== this.getInstagramSharingCancelledLink
          ) {
            this.$store.commit(instagramTypes.SET_SHARING_URL, urls[0])
            this.setFetchInstagramShortLinksLoaders(true)
          }
          break
        case 'Youtube':
          // only showing loader if video not added
          if (
            (!this.getYoutubeSharingDetails.video ||
              this.getYoutubeSharingDetails.video.link === '') &&
            urls[0] !== this.getYoutubeSharingCancelledLink
          ) {
            this.$store.commit(youtubeTypes.SET_SHARING_URL, urls[0])
            this.setFetchYoutubeShortLinksLoaders(true)
          }
          break
        case 'Gmb':
          // only showing loader if images not added
          if (
            this.getGmbSharingDetails.image.length === 0 &&
            (!this.getGmbSharingDetails.video ||
              this.getGmbSharingDetails.video.link === '') &&
            !this.getGmbSharingDetails.url &&
            urls[0] !== this.getGmbSharingCancelledLink
          ) {
            this.$store.commit(gmb.SET_SHARING_URL, urls[0])
            this.$store.commit(gmb.SET_FETCH_SHORT_LINKS_LOADER, true)
          }
          break
        default:
          this.setFetchShortLinksLoaders(type, true)
      }

      this.postRequest(
        fetchShortLinksUrl,
        {
          urls,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          this.processShortLinks(urls, type, response)
          this.setFetchShortLinksLoaders(type, false)
        },
        (error) => {
          console.debug('Exception in fetchShortLinks', error)
          this.setFetchShortLinksLoaders(type, false)
        }
      )
    },

    processShortLinks(urls, type, response) {
      console.debug('Method:processShortLinks', urls, type)

      const stateObject = this
      let url = urls[0]
      if (response.data.status === true) {
        if (response.data.links.length) {
          let message = ''
          if (type === 'All' || type === 'Common') {
            message = this.getCommonSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }

                if (el.long === stateObject.getCommonSharingDetails.url) {
                  url = el.short
                  stateObject.setCommonSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.facebook_url
                ) {
                  stateObject.setCommonFacebookSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.twitter_url
                ) {
                  stateObject.setCommonTwitterSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.linkedin_url
                ) {
                  stateObject.setCommonLinkedinSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.pinterest_url
                ) {
                  stateObject.setCommonPinterestSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.tumblr_url
                ) {
                  stateObject.setCommonTumblrSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.instagram_url
                ) {
                  stateObject.setCommonInstagramSharingLink(el.short)
                }
                if (
                  el.long === stateObject.getCommonSharingDetails.youtube_url
                ) {
                  stateObject.setCommonYoutubeSharingLink(el.short)
                }
                if (el.long === stateObject.getCommonSharingDetails.gmb_url) {
                  stateObject.$store.commit(
                    commonTypes.SET_GMB_SHARING_LINK,
                    el.short
                  )
                }
              }
            })
            this.setSharingMessage('Common', message)

            if (
              type === 'All' ||
              (this.getCommonSharingDetails.image.length === 0 &&
                (!this.getCommonSharingDetails.video ||
                  this.getCommonSharingDetails.video.link === '') &&
                urls[0] !== this.getCommonSharingCancelledLink)
            ) {
              this.setCommonSharingLink(url)
            }
          }

          if (type === 'All' || type === 'Facebook') {
            let facebookLink = url
            message = this.getFacebookSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }

                if (el.long === stateObject.getFacebookSharingDetails.url) {
                  facebookLink = el.short
                }
              }
            })
            this.setSharingMessage('Facebook', message)

            if (
              type === 'All' ||
              (this.getFacebookSharingDetails.image.length === 0 &&
                (!this.getFacebookSharingDetails.video ||
                  this.getFacebookSharingDetails.video.link === '') &&
                urls[0] !== this.getFacebookSharingCancelledLink)
            ) {
              this.setFacebookSharingLink(facebookLink)
            }
          }

          if (type === 'All' || type === 'Twitter') {
            let twitterLink = url
            message = this.getTwitterSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getTwitterSharingDetails.url) {
                  twitterLink = el.short
                }
              }
            })
            this.setSharingMessage('Twitter', message)

            if (
              type === 'All' ||
              (this.getTwitterSharingDetails.image.length === 0 &&
                (!this.getTwitterSharingDetails.video ||
                  this.getTwitterSharingDetails.video.link === '') &&
                urls[0] !== this.getTwitterSharingCancelledLink)
            ) {
              this.setTwitterSharingLink(twitterLink)
            }
          }

          if (type === 'ThreadedTweets') {
            let threadedTweetsLink = url
            message = this.getThreadedTweetsDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getThreadedTweetsDetails.url) {
                  threadedTweetsLink = el.short
                }
              }
            })
            this.setSharingMessage('ThreadedTweets', message)

            if (
              this.getThreadedTweetsDetails.image.length === 0 &&
              (!this.getThreadedTweetsDetails.video ||
                this.getThreadedTweetsDetails.video.link === '') &&
              urls[0] !== this.getThreadedTweetsCancelledLink
            ) {
              this.setThreadedTweetsSharingLink(threadedTweetsLink)
            }
          }

          if (type === 'All' || type === 'Linkedin') {
            let linkedinLink = url
            message = this.getLinkedinSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getLinkedinSharingDetails.url) {
                  linkedinLink = el.short
                }
              }
            })
            this.setSharingMessage('Linkedin', message)

            if (
              type === 'All' ||
              (this.getLinkedinSharingDetails.image.length === 0 &&
                (!this.getLinkedinSharingDetails.video ||
                  this.getLinkedinSharingDetails.video.link === '') &&
                urls[0] !== this.getLinkedinSharingCancelledLink)
            ) {
              this.$store.commit(
                linkedinSharingTypes.SET_SHARING_URL,
                linkedinLink
              )
            }
          }

          if (type === 'All' || type === 'Pinterest') {
            let pinterestLink = url
            message = this.getPinterestSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getPinterestSharingDetails.url) {
                  pinterestLink = el.short
                }
              }
            })
            this.setSharingMessage('Pinterest', message)

            if (
              type === 'All' ||
              (this.getPinterestSharingDetails.image.length === 0 &&
                (!this.getPinterestSharingDetails.video ||
                  this.getPinterestSharingDetails.video.link === '') &&
                urls[0] !== this.getPinterestSharingCancelledLink)
            ) {
              this.setPinterestSharingLink(pinterestLink)
            }
          }

          if (type === 'All' || type === 'Tumblr') {
            let tumblrLink = url
            message = this.getTumblrSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getTumblrSharingDetails.url) {
                  tumblrLink = el.short
                }
              }
            })
            this.setSharingMessage('Tumblr', message)

            if (
              type === 'All' ||
              (this.getTumblrSharingDetails.image.length === 0 &&
                (!this.getTumblrSharingDetails.video ||
                  this.getTumblrSharingDetails.video.link === '') &&
                urls[0] !== this.getTumblrSharingCancelledLink)
            ) {
              this.setTumblrSharingLink(tumblrLink)
            }
          }

          if (type === 'All' || type === 'Instagram') {
            let instagramLink = url
            message = this.getInstagramSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getInstagramSharingDetails.url) {
                  instagramLink = el.short
                }
              }
            })
            this.setSharingMessage('Instagram', message)

            if (
              type === 'All' ||
              (this.getInstagramSharingDetails.image.length === 0 &&
                (!this.getInstagramSharingDetails.video ||
                  this.getInstagramSharingDetails.video.link === '') &&
                urls[0] !== this.getInstagramSharingCancelledLink)
            ) {
              this.$store.commit(instagramTypes.SET_SHARING_URL, instagramLink)
            }
          }

          if (type === 'All' || type === 'Youtube') {
            let youtubeLink = url
            message = this.getYoutubeSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getYoutubeSharingDetails.url) {
                  youtubeLink = el.short
                }
              }
            })
            this.setSharingMessage('Youtube', message)

            if (
              type === 'All' ||
              ((!this.getYoutubeSharingDetails.video ||
                this.getYoutubeSharingDetails.video.link === '') &&
                urls[0] !== this.getYoutubeSharingCancelledLink)
            ) {
              this.$store.commit(youtubeTypes.SET_SHARING_URL, youtubeLink)
            }
          }

          if (type === 'All' || type === 'Gmb') {
            let gmbLink = url
            message = this.getGmbSharingDetails.message
            response.data.links.forEach(function (el, index) {
              if (el.short) {
                if (message.indexOf(el.long) > -1) {
                  message = message.replace(el.long, el.short)
                }
                if (el.long === stateObject.getGmbSharingDetails.url) {
                  gmbLink = el.short
                }
              }
            })
            this.setSharingMessage('Gmb', message)

            if (
              type === 'All' ||
              (this.getGmbSharingDetails.image.length === 0 &&
                (!this.getGmbSharingDetails.video ||
                  this.getGmbSharingDetails.video.link === '') &&
                urls[0] !== this.getGmbSharingCancelledLink)
            ) {
              this.$store.commit(gmb.SET_SHARING_URL, gmbLink)
            }
          }
        }
      }
      this.parseLinkPreview(urls, type)
      // this.allTextareaFocus()
    },

    parseLinkPreview(urls, type) {
      console.debug('Method::parseLinkPreview', urls, type)

      if (type === 'All' || type === 'Common') {
        let previewUrl = urls[0]
        if (this.getCommonSharingDetails.url) {
          previewUrl = this.getCommonSharingDetails.url
        }
        if (previewUrl !== this.getCommonSharingCancelledLink) {
          if (
            !this.getCommonSharingDetails.url &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.setCommonSharingLink(urls[0])
            this.setCommonFacebookSharingLink(urls[0])
            this.setCommonTwitterSharingLink(urls[0])
            this.setCommonLinkedinSharingLink(urls[0])
            this.setCommonPinterestSharingLink(urls[0])
            this.setCommonTumblrSharingLink(urls[0])
            this.setCommonInstagramSharingLink(urls[0])
            this.setCommonYoutubeSharingLink(urls[0])
            this.$store.commit(commonTypes.SET_GMB_SHARING_LINK, urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.setCommonCancelledLink(previewUrl)
          if (type === 'Common') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else {
          this.setFetchCommonSharingPreviewLoader(false)
        }
      }
      if (type === 'All' || type === 'Facebook') {
        let previewUrl = urls[0]
        if (this.getFacebookSharingDetails.url) {
          previewUrl = this.getFacebookSharingDetails.url
        }

        if (previewUrl !== this.getFacebookSharingCancelledLink) {
          if (
            !this.getFacebookSharingDetails.url &&
            this.getFacebookSharingDetails.image.length === 0 &&
            (!this.getFacebookSharingDetails.video ||
              this.getFacebookSharingDetails.video.link === '')
          ) {
            this.setFacebookSharingLink(urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.setFacebookCancelledLink(previewUrl)
          if (type === 'Facebook') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchFacebookSharingPreviewLoader(false)
      }
      if (type === 'All' || type === 'Twitter') {
        let previewUrl = urls[0]
        if (this.getTwitterSharingDetails.url) {
          previewUrl = this.getTwitterSharingDetails.url
        }

        if (previewUrl !== this.getTwitterSharingCancelledLink) {
          if (
            !this.getTwitterSharingDetails.url &&
            this.getTwitterSharingDetails.image.length === 0 &&
            (!this.getTwitterSharingDetails.video ||
              this.getTwitterSharingDetails.video.link === '')
          ) {
            this.setTwitterSharingLink(urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.setTwitterCancelledLink(previewUrl)
          if (type === 'Twitter') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchTwitterSharingPreviewLoader(false)
      }

      if (type === 'All' || type === 'ThreadedTweets') {
        // to do
      }

      if (type === 'All' || type === 'Linkedin') {
        let previewUrl = urls[0]
        if (this.getLinkedinSharingDetails.url) {
          previewUrl = this.getLinkedinSharingDetails.url
        }

        if (previewUrl !== this.getLinkedinSharingCancelledLink) {
          if (
            !this.getLinkedinSharingDetails.url &&
            this.getLinkedinSharingDetails.image &&
            this.getLinkedinSharingDetails.image.length === 0 &&
            (!this.getLinkedinSharingDetails.video ||
              this.getLinkedinSharingDetails.video.link === '')
          ) {
            this.$store.commit(linkedinSharingTypes.SET_SHARING_URL, urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_CANCELLED_PREVIEW,
            previewUrl
          )
          if (type === 'Linkedin') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchLinkedinSharingPreviewLoader(false)
      }

      if (type === 'All' || type === 'Pinterest') {
        let previewUrl = urls[0]
        if (this.getPinterestSharingDetails.url) {
          previewUrl = this.getPinterestSharingDetails.url
        }

        if (previewUrl !== this.getPinterestSharingCancelledLink) {
          if (
            !this.getPinterestSharingDetails.url &&
            this.getPinterestSharingDetails.image.length === 0 &&
            (!this.getPinterestSharingDetails.video ||
              this.getPinterestSharingDetails.video.link === '')
          ) {
            this.setPinterestSharingLink(urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.setPinterestCancelledLink(previewUrl)
          if (type === 'Pinterest') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchPinterestSharingPreviewLoader(false)
      }
      if (type === 'All' || type === 'Tumblr') {
        let previewUrl = urls[0]
        if (this.getTumblrSharingDetails.url) {
          previewUrl = this.getTumblrSharingDetails.url
        }

        if (previewUrl !== this.getTumblrSharingCancelledLink) {
          if (
            !this.getTumblrSharingDetails.url &&
            this.getTumblrSharingDetails.image.length === 0 &&
            (!this.getTumblrSharingDetails.video ||
              this.getTumblrSharingDetails.video.link === '')
          ) {
            this.setTumblrSharingLink(urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.setTumblrCancelledLink(previewUrl)
          if (type === 'Tumblr') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchTumblrSharingPreviewLoader(false)
      }
      if (type === 'All' || type === 'Instagram') {
        let previewUrl = urls[0]
        if (this.getInstagramSharingDetails.url) {
          previewUrl = this.getInstagramSharingDetails.url
        }
        if (previewUrl !== this.getInstagramSharingCancelledLink) {
          if (
            !this.getInstagramSharingDetails.url &&
            this.getInstagramSharingDetails.image.length === 0 &&
            (!this.getInstagramSharingDetails.video ||
              this.getInstagramSharingDetails.video.link === '')
          ) {
            this.$store.commit(instagramTypes.SET_SHARING_URL, urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.$store.commit(
            instagramTypes.SET_SHARING_CANCELLED_PREVIEW,
            previewUrl
          )
          if (type === 'Instagram') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchInstagramSharingPreviewLoader(false)
      }

      if (type === 'All' || type === 'Gmb') {
        let previewUrl = urls[0]
        if (this.getGmbSharingDetails.url) {
          previewUrl = this.getGmbSharingDetails.url
        }

        if (previewUrl !== this.getGmbSharingCancelledLink) {
          if (
            !this.getGmbSharingDetails.url &&
            this.getGmbSharingDetails.image.length === 0 &&
            (!this.getGmbSharingDetails.video ||
              this.getGmbSharingDetails.video.link === '')
          ) {
            this.$store.commit(gmb.SET_SHARING_URL, urls[0])
            this.setFetchSharingPreviewLoaders(type, true)
          }
          this.$store.commit(gmb.SET_SHARING_CANCELLED_PREVIEW, previewUrl)
          if (type === 'Gmb') {
            this.fetchLinkPreview(previewUrl, type)
          }
        } else this.setFetchYoutubeSharingPreviewLoader(false)
      }
      if (type === 'All' || type === 'Youtube') {
        this.$store.commit(youtubeTypes.SET_SHARING_URL, urls[0])
      }
      if (type === 'All') {
        let previewUrl = urls[0]
        if (this.getCommonSharingDetails.url) {
          previewUrl = this.getCommonSharingDetails.url
        }
        this.fetchLinkPreview(previewUrl, type)
      }
    },

    fetchComposerVariation(url, message = '') {
      console.debug('Method:fetchComposerVariation', url)
      // this.setInitializeArticlePostCreationLoader(true)
      this.postRequest(
        fetchVariationsURL,
        { url },
        (response) => {
          if (
            response.data.status === true &&
            response.data.variations &&
            response.data.variations.length > 0
          ) {
            const stateObject = this
            const allVariation = [
              this.removeLinksFromText(
                message !== '' ? message : this.getCommonSharingDetails.message
              ),
            ]
            response.data.variations.forEach(function (el, index) {
              const variation = stateObject.removeHtmlTags(el.text)
              if (
                variation &&
                variation &&
                variation.length <= 250 &&
                allVariation.indexOf(variation) < 0
              )
                allVariation.push(variation)
            })
            if (allVariation.length > 2)
              this.$store.commit(
                commonTypes.SET_TWITTER_VARIATION,
                allVariation
              )
            // this.setInitializeArticlePostCreationLoader(false)
          }
        },
        (error) => {
          console.debug('Exception in fetchComposerVariation', error)
          // this.setInitializeArticlePostCreationLoader(false)
        }
      )
    },

    postJsonRequest(URL, parameters, successFunc, errorFunc) {
      console.debug('Method::postRequest', this.getJWTToken)
      this.$http
        .post(URL, parameters, {
          headers: {
            Authorization: 'Bearer ' + this.getJWTToken,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          successFunc(response)
        })
        .catch((error) => {
          console.log(error)
          if (URL.includes('isAuthenticated')) {
            if (!error.status) {
              if (!window.location.href.includes('login')) {
                router.push('/login')
              }
            }
          }
          console.log('error message', error)
          if (error && error.response && error.response.status === 401) {
            this.$store.commit(authenticationTypes.SET_JWT_TOKEN, null)
            console.log(window.location.href)
            if (!window.location.href.includes('login')) {
              router.push('/login')
            }
          }
          errorFunc(error)
        })
    },

    fetchAiCaption(isOldComposer = true, type = 'common') {
      EventBus.$emit('AiCaptionModal_show', { isOldComposer, source: type })
    },
    openAiImageModal() {
      EventBus.$emit('imageGeneratorModal', { isOldComposer: true })
    },
    fetchLinkPreview(url, type) {
      console.debug(
        'Method:fetchLinkPreview',
        url,
        type,
        this.$router.currentRoute.name
      )

      if (url) {
        switch (type) {
          case 'Common':
            // only showing loader if images not added
            if (
              this.getCommonSharingDetails.image.length === 0 &&
              (!this.getCommonSharingDetails.video ||
                this.getCommonSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            if (
              this.$router.currentRoute.name === 'composerSocial' ||
              this.$router.currentRoute.name ===
                'saveEvergreenAutomationFinalize'
            ) {
              let newURl
              try {
                newURl = new URL(url)
                this.fetchComposerVariation(newURl)
              } catch (_) {
                newURl = 'http://' + url
                this.fetchComposerVariation(newURl)
              }
            }

            if (this.$router.currentRoute.name === 'composerSocial')
              this.fetchPostHashTags({ url })
            break
          case 'Facebook':
            // only showing loader if images not added
            if (
              this.getFacebookSharingDetails.image.length === 0 &&
              (!this.getFacebookSharingDetails.video ||
                this.getFacebookSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Twitter':
            // only showing loader if images not added
            if (
              this.getTwitterSharingDetails.image.length === 0 &&
              (!this.getTwitterSharingDetails.video ||
                this.getTwitterSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Linkedin':
            // only showing loader if images not added
            if (
              this.getLinkedinSharingDetails.image.length === 0 &&
              (!this.getLinkedinSharingDetails.video ||
                this.getLinkedinSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Pinterest':
            // only showing loader if images not added
            if (
              this.getPinterestSharingDetails.image.length === 0 &&
              (!this.getPinterestSharingDetails.video ||
                this.getPinterestSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Tumblr':
            console.log('this is fetching testing')

            // only showing loader if images not added
            if (
              this.getTumblrSharingDetails.image.length === 0 &&
              (!this.getTumblrSharingDetails.video ||
                this.getTumblrSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Instagram':
            // only showing loader if images not added
            if (
              this.getInstagramSharingDetails.image.length === 0 &&
              (!this.getInstagramSharingDetails.video ||
                this.getInstagramSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          case 'Gmb':
            /// only showing loader if images not added
            if (
              this.getGmbSharingDetails.image.length === 0 &&
              (!this.getGmbSharingDetails.video ||
                this.getGmbSharingDetails.video.link === '')
            ) {
              this.setFetchSharingPreviewLoaders(type, true)
            }
            break
          default:
            this.setFetchSharingPreviewLoaders(type, true)
        }

        this.postRequest(
          fetchLinkPreviewUrl,
          { url },
          (response) => {
            this.processLinkPreview(url, type, response)
            this.setFetchSharingPreviewLoaders(type, false)
          },
          (error) => {
            console.debug('Exception in fetchShortLinks', error)
            this.setFetchSharingPreviewLoaders(type, false)
          }
        )
      }
    },

    processLinkPreview(url, type, response) {
      console.debug('Method:processLinkPreview', url, type)

      if (response.data.status) {
        let title = ''
        // eslint-disable-next-line camelcase
        let twitter_title = ''
        let description = ''
        // eslint-disable-next-line camelcase
        let twitter_description = ''
        let website = ''
        // eslint-disable-next-line camelcase
        let twitter_website = ''
        let image = ''
        let imageArray = []
        let twitterImage = []
        // eslint-disable-next-line camelcase
        const image_suggestions = []
        // eslint-disable-next-line camelcase
        const twitter_image_suggestions = []

        // for unique images
        response.data.images.forEach(function (el, index) {
          if (image_suggestions.includes(el) === false) {
            image_suggestions.push(el)
          }

          if (twitter_image_suggestions.includes(el) === false) {
            twitter_image_suggestions.push(el)
          }
        })

        if (response.data.facebook || response.data.twitter) {
          if (response.data.twitter) {
            // eslint-disable-next-line camelcase
            twitter_title = response.data.twitter.title
            // eslint-disable-next-line camelcase
            twitter_description = response.data.twitter.description
            // eslint-disable-next-line camelcase
            twitter_website = response.data.twitter.website

            if (
              // eslint-disable-next-line camelcase
              twitter_website !== 'www.youtube.com' ||
              twitter_website !== 'www.dailymotion.com'
            ) {
              if (response.data.twitter.image) {
                try {
                  url = new URL(response.data.twitter.image)
                  twitterImage = [url]
                } catch (_) {
                  // eslint-disable-next-line camelcase
                  url = [twitter_website + response.data.twitter.image]
                  if (new URL(url)) {
                    twitterImage = [url]
                  }
                }
                if (
                  twitter_image_suggestions.includes(
                    response.data.twitter.image
                  ) === false
                ) {
                  twitter_image_suggestions.push(response.data.twitter.image)
                }
              } else {
                twitterImage = [
                  'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png',
                ]
              }
              // else if (twitter_image_suggestions.length > 0) {
              // twitterImage = [twitter_image_suggestions[0]]
              // console.debug("i am here",twitterImage)
              // }
            }
          }

          if (response.data.facebook) {
            if (response.data.facebook.title) {
              title = response.data.facebook.title
            } else if (response.data.meta.title) {
              title = response.data.meta.title
            }

            if (response.data.facebook.description) {
              description = response.data.facebook.description
            } else if (response.data.meta.description) {
              description = response.data.meta.description
            }

            website = response.data.facebook.website

            if (response.data.facebook.image) {
              try {
                url = new URL(response.data.facebook.image)
                image = url
                imageArray = [image]
              } catch (_) {
                url = website + response.data.facebook.image
                if (new URL(url)) {
                  image = url
                  imageArray = [image]
                }
              }

              if (image_suggestions.includes(image) === false) {
                image_suggestions.push(image)
              }
            } else if (image_suggestions.length > 0) {
              image = image_suggestions[0]
              imageArray = [image]
            }
          }
        } else {
          if (response.data.meta) {
            // eslint-disable-next-line camelcase
            title = twitter_title = response.data.meta.title
            // eslint-disable-next-line camelcase
            description = twitter_description = response.data.meta.description
          }
        }

        if (type === 'All' || type === 'Common') {
          // using same images if images are already added
          if (this.getCommonSharingDetails.image.length) {
            imageArray = this.getCommonSharingDetails.image
          }

          this.setCommonSharingPreview({
            title,
            twitter_title,
            description,
            twitter_description,
            website,
            twitter_website,
            image: imageArray,
            twitter_image: twitterImage,
            image_suggestions,
          })
        }

        if (type === 'All' || type === 'Facebook') {
          // using same images if images are already added
          if (this.getFacebookSharingDetails.image.length) {
            imageArray = this.getFacebookSharingDetails.image
          }

          this.setFacebookSharingPreview({
            title,
            description,
            website,
            image: imageArray,
            image_suggestions,
          })
        }

        if (type === 'All' || type === 'Twitter') {
          // using same images if images are already added
          if (this.getTwitterSharingDetails.image.length) {
            twitterImage = this.getTwitterSharingDetails.image
          }

          this.setTwitterSharingPreview({
            title: twitter_title,
            description: twitter_description,
            website: twitter_website,
            image: twitterImage,
            image_suggestions: twitter_image_suggestions,
          })
        }

        if (type === 'All' || type === 'Linkedin') {
          // using same image if image is already added
          if (this.getLinkedinSharingDetails.image.length) {
            imageArray = this.getLinkedinSharingDetails.image
          }

          this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, {
            title,
            description,
            website,
            image: imageArray,
            image_suggestions,
          })
        }

        if (type === 'All' || type === 'Pinterest') {
          // only updating if image not exist
          if (this.getPinterestSharingDetails.image.length === 0) {
            this.setPinterestSharingPreview({
              image,
            })
          }
        }

        if (type === 'All' || type === 'Tumblr') {
          // only updating if image not exist
          if (this.getTumblrSharingDetails.image.length === 0) {
            this.setTumblrSharingPreview({
              image,
            })
          }
        }

        if (type === 'All' || type === 'Instagram') {
          // only updating if image not exist
          if (this.getInstagramSharingDetails.image.length === 0) {
            this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, {
              image: imageArray,
            })
          }
        }

        if (type === 'All' || type === 'Gmb') {
          // only updating if image not exist
          if (this.getGmbSharingDetails.image.length === 0) {
            this.$store.commit(gmb.SET_SHARING_PREVIEW, {
              image,
            })
          }
        }

        this.setFetchSharingPreviewLoaders(type, false)
      } else {
        this.setSharingPreview(type, null)
      }
    },

    isShortLink(link) {
      console.debug('Method:isShortLink', link)

      let check = false

      if (link) {
        if (
          link.includes('http://cstu.co') ||
          link.includes('http://cstu.io') ||
          link.includes('https://cstu.io') ||
          link.includes('https://cstu-shortener-stage.cstuinternal.com')
        ) {
          check = 'cstu'
        } else if (link.includes('contentstudio.page.link')) {
          check = 'google'
        } else if (this.checkBitlyUrl(link)) {
          check = 'bitly'
        } else if (this.checkReplugUrl(link)) {
          check = 'replug'
        }
      }

      return check
    },

    checkBitlyUrl(url) {
      console.debug('Method:checkBitlyUrl', this.getBitlyAccount)
      let status = false
      const domianOption = this.getBitlyAccount.domain_options
      // if (domianOption) {
      domianOption.forEach(function (element) {
        if (url.includes(element)) {
          status = true
          return false
        }
      })
      // }
      return status
    },

    checkReplugUrl(url) {
      let status = false
      // eslint-disable-next-line camelcase
      const domian_option = this.getReplug.item.domain_options
      // eslint-disable-next-line camelcase
      if (domian_option) {
        domian_option.forEach(function (element) {
          if (url.includes(element)) {
            status = true
            return false
          }
        })
      }
      return status
    },

    shrinkSharingSourceLink(type, link) {
      console.debug('Method:shrinkSharingSourceLink')

      if (this.isValidUrl(link)) {
        if (type === 'Common') {
          this.setShrinkCommonSharingSourceLinkLoader(true)
        } else if (type === 'Pinterest') {
          this.setShrinkPinterestSharingSourceLinkLoader(true)
        }

        this.postRequest(
          fetchShortLinksUrl,
          {
            urls: [link],
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.links && response.data.links.length) {
              if (type === 'Common') {
                this.setCommonSharingSourceLink(response.data.links[0].short)
              } else if (type === 'Pinterest') {
                this.setPinterestSharingSourceLink(response.data.links[0].short)
              }
            } else if (
              response.data.status === false &&
              response.data.message
            ) {
              this.alertMessage(response.data.message, 'error')
            }

            if (type === 'Common') {
              this.setShrinkCommonSharingSourceLinkLoader(false)
            } else if (type === 'Pinterest') {
              this.setShrinkPinterestSharingSourceLinkLoader(false)
            }
          },
          (error) => {
            console.debug('Exception in shrinkSharingSourceLink', error)
            if (type === 'Common') {
              this.setShrinkCommonSharingSourceLinkLoader(false)
            } else if (type === 'Pinterest') {
              this.setShrinkPinterestSharingSourceLinkLoader(false)
            }
          }
        )
      } else {
        this.alertMessage('Please enter a valid URL to shorten.', 'error')
      }
    },
  },
}
