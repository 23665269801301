import proxy from '@common/lib/http-common'
import { intToString } from '@common/lib/helper'
import { getSearchInsightsURL } from '../config/api-utils'
import Router from '../../../router'
import { insightTypes, topicTypes } from './mutation-types'

export default {
  state: {
    insights: {
      socialNetwork: 'facebook',
      wordcloud: [],
      count: {
        articlesAnalyzed: 0,
        totalEngagements: 0,
        averageEngagements: 0,
        averageFacebookEngagement: 0,
        averagePinterestEngagement: 0,
        averageRedditEngagement: 0
      },
      sentiments: {
        percentage: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        engagement: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        docs: {
          positive: 0,
          negative: 0,
          neutral: 0
        }
      },
      articlesPublishedOverTimePostsCount: [],
      articlesPublishedOverTimeDate: [],
      articlesPublishedOverTimeEngagement: [],
      articlesPublishedOverTimeSentiments: {
        positive: [],
        negative: [],
        netural: []
      },
      contentTypes: {
        categories: [],
        engagements: [],
        docs: []
      },
      engagement: {
        average: {
          facebook: 0,
          pinterest: 0,
          reddit: 0
        },
        sum: {
          facebook: 0,
          pinterest: 0,
          reddit: 0
        }
      },
      popularDays: {
        days: [],
        interactions: [],
        articles: [],
        biggest_interactions_day: {
          day: '',
          value: ''
        },
        biggest_published_articles_day: {
          day: '',
          value: ''
        }
      },
      popularReadingLevels: {
        interactions: [],
        articles: [],
        categories: []
      },
      popularWordCount: {
        interactions: [],
        articles: [],
        categories: []
      },
      topDomainsForChart: [],
      topDomains: [],
      topAuthors: []
    },
    loaders: {
      insights: false,
      showCharts: false
    }
  },
  actions: {
    articlesInsights ({ commit, getters, dispatch }) {
      commit(insightTypes.SET_INSIGHTS_LOADER, true)
      commit(insightTypes.SET_INSIGHTS_CHARTS_LOADER, false)
      const filters = getters.getFilters
      let payload = {
        articles: {
          sort: filters.articles.sort,
          page: filters.articles.page,
          limit: filters.articles.limit
        },
        folder_id: null,
        archived: null
      }

      if (!payload.archived && !payload.folder_id) {
        payload = {
          topic: filters.topic,
          category: filters.category,
          search: filters.search,
          search_type: filters.search_type,
          related: filters.related,
          articles: filters.articles
        }
      }

      let insightsType = 'search'
      if (filters.topic) {
        insightsType = 'curated'
      }

      proxy
        .post(getSearchInsightsURL, {
          filters: payload,
          module: Router.history.current.name,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          module_type: getters.getArticlesModuleType,
          insights_type: insightsType
        })
        .catch(() => {})
        .then((resp) => {
          if (resp.data.status) {
            dispatch('renderInsights', resp)
          } else {
            commit(insightTypes.SET_INSIGHTS_LOADER, false)
          }
        })
    },
    topicInsights ({ commit, getters, dispatch }) {
      commit(insightTypes.SET_INSIGHTS_LOADER, true)
      const payload = {
        filters: getters.getTopicsFilters,
        type: getters.getTopicType,
        workspace_id: getters.getWorkspaces.activeWorkspace._id
      }
      switch (getters.getTopicType) {
        case 'custom_topic':
          payload.insights_type = 'topics'
          payload.selection = getters.getTopicSelection
          break
        case 'curated':
          payload.filters.topic = getters.getCuratedTopic
          payload.insights_type = 'curated'
          break
      }
      proxy
        .post(getSearchInsightsURL, payload)
        .catch(() => {})
        .then((resp) => {
          if (resp.data.status) {
            dispatch('renderInsights', resp)
          } else {
            commit(insightTypes.SET_INSIGHTS_LOADER, false)
          }
        })
    },
    async renderInsights ({ commit, getters, dispatch }, resp) {
      const articlesPublishedArrayPostsCount = []
      const articlesPublishedarrayEngagement = []
      const sentiments = {
        positive: [],
        negative: [],
        neutral: []
      }

      // percentage of shared data
      console.debug(resp.data.insights.aggregations.total_stats.value)
      commit(insightTypes.SET_INSIGHTS_COUNT, {
        articlesAnalyzed: resp.data.insights.hits.total,
        totalEngagements: resp.data.insights.aggregations.total_stats.value,
        averageEngagements: resp.data.insights.aggregations.average_stats.value,
        averageFacebookEngagement:
          resp.data.insights.aggregations.average_facebook.value,
        averagePinterestEngagement:
          resp.data.insights.aggregations.average_pins.value,
        averageRedditEngagement:
          resp.data.insights.aggregations.average_reddit.value
      })

      // sentiment analysis
      // published articles over time with their average shares.

      resp.data.insights.aggregations.articles_published_per_day.buckets.forEach(
        (element) => {
          const parsedDate = Date.parse(element.key_as_string)
          const docCount = element.doc_count || 0
          const averageStats = element.avg_stats.value || 0
          articlesPublishedArrayPostsCount.push([parsedDate, docCount])
          articlesPublishedarrayEngagement.push([
            parsedDate,
            Math.ceil(averageStats)
          ])
          element.sentiment.sentiment_labels.buckets.forEach(function (item) {
            let itemDocCount = 0
            console.debug(parsedDate, item.doc_count)

            if (item.doc_count) {
              itemDocCount = item.doc_count
            }
            switch (item.key) {
              case 'neutral':
              case 'positive':
              case 'negative':
                sentiments[item.key].push([parsedDate, itemDocCount])
                break
              default:
                break
            }
          }, this)
        },
        this
      )

      commit(
        insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_POSTS_COUNT,
        articlesPublishedArrayPostsCount
      )
      commit(
        insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_ENGAGEMENT,
        articlesPublishedarrayEngagement
      )
      commit(
        insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_SENTIMENTS,
        sentiments
      )
      commit(
        insightTypes.SET_INSIGHTS_POPULAR_DAYS,
        resp.data.insights.aggregations.popular_days
      )
      commit(
        insightTypes.SET_INSIGHTS_TOP_AUTHORS,
        resp.data.insights.aggregations.top_authors.buckets
      )
      await dispatch(
        'sentimentPercentage',
        resp.data.insights.aggregations.average_sentiment_statistics
      )
      await dispatch(
        'popularContentTypes',
        resp.data.insights.aggregations.categories_list
      )
      await dispatch('insightsEngagement', resp.data.insights.aggregations)
      await dispatch(
        'popularDomains',
        resp.data.insights.aggregations.top_domains.buckets
      )
      await dispatch(
        'wordCloud',
        resp.data.insights.aggregations.top_popular_hashtags.buckets
      )
      await dispatch(
        'popularReadingLevels',
        resp.data.insights.aggregations.popular_reading_levels.buckets
      )
      await dispatch(
        'wordCountLevel',
        resp.data.insights.aggregations.temporarily_popular_word_count.buckets
      )
      commit(insightTypes.SET_INSIGHTS_LOADER, false)
      commit(insightTypes.SET_INSIGHTS_CHARTS_LOADER, true)
    },

    sentimentPercentage ({ commit }, sentiments) {
      const totalDocs = sentiments.doc_count
      const sentimentsCount = {
        percentage: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        engagement: {
          positive: 0,
          negative: 0,
          neutral: 0
        },
        docs: {
          positive: 0,
          negative: 0,
          neutral: 0
        }
      }
      sentiments.sentiment_labels.buckets.forEach((item) => {
        const itemDocCount = item.doc_count || 0
        switch (item.key) {
          case 'neutral':
          case 'positive':
          case 'negative':
            sentimentsCount.percentage[item.key] = (
              (item.doc_count / totalDocs) *
              100
            ).toFixed(0)
            sentimentsCount.docs[item.key] = itemDocCount
            sentimentsCount.engagement[item.key] = parseInt(
              (item.social_shares.average_engagements.value || 0).toFixed(0)
            )
            break
          default:
            break
        }
      })

      commit(insightTypes.SET_INSIGHTS_SENTIMENT_COUNT, sentimentsCount)
      return true
    },
    popularContentTypes ({ commit }, contentList) {
      const contentTypes = {
        categories: [],
        engagements: [],
        docs: []
      }
      contentList.buckets.forEach((item) => {
        let average = 0
        if (item.average.value) {
          average = Math.ceil(item.average.value)
        }
        contentTypes.categories.push(item.key)
        contentTypes.engagements.push(average)
        contentTypes.docs.push(item.doc_count)
      }, this)

      commit(insightTypes.SET_INSIGHTS_CONTENT_TYPES, contentTypes)
      return true
    },

    insightsEngagement ({ commit }, aggregation) {
      const engagement = {
        average: {
          facebook: parseInt(aggregation.average_facebook.value) || 0,
          pinterest: parseInt(aggregation.average_pins.value) || 0,
          reddit: parseInt(aggregation.average_reddit.value) || 0
        },
        sum: {
          facebook: parseInt(aggregation.total_facebook.value) || 0,
          pinterest: parseInt(aggregation.total_pins.value) || 0,
          reddit: parseInt(aggregation.total_reddit.value) || 0
        }
      }

      console.log(engagement)
      commit(insightTypes.SET_INSIGHTS_ENGAGEMENT, engagement)
      return true
    },

    popularDomains ({ commit }, bucket) {
      const domainsList = []
      bucket.forEach((item) => {
        console.debug(item)
        domainsList.push([item.key, item.doc_count])
      }, this)
      commit(insightTypes.SET_INSIGHTS_TOP_DOMAINS_FOR_CHART, domainsList)
      commit(insightTypes.SET_INSIGHTS_TOP_DOMAINS, bucket)
      return true
    },

    wordCloud ({ commit }, bucket) {
      const wordcloudList = []
      bucket.forEach(function (item) {
        const removeHashtag = item.key.replace('#', '')
        const splitWord = removeHashtag.replace(/([a-z])([A-Z])/g, '$1 $2')
        wordcloudList.push({
          name: splitWord,
          weight: item.doc_count
        })
      })
      commit(insightTypes.SET_INSIGHTS_WORDCLOUD, wordcloudList)
      return true
    },

    popularReadingLevels ({ commit }, bucket) {
      const readingLevel = {
        categories: [],
        articles: [],
        interactions: []
      }
      bucket.forEach(function (item) {
        console.debug(item)
        readingLevel.categories.push(item.key)
        readingLevel.articles.push(item.doc_count)
        readingLevel.interactions.push(parseInt(item.avg_stats.value))
      }, this)

      console.log('readingLevel', readingLevel)
      commit(insightTypes.SET_INSIGHTS_READING_LEVELS, readingLevel)
      return true
    },

    async wordCountLevel ({ commit, dispatch }, range) {
      let wordCountFirstRange = range
      const wordCountSecondRange = range

      if (wordCountFirstRange.length > 4) {
        // pick only first two elements

        if (wordCountFirstRange.length > 2) {
          wordCountFirstRange = wordCountFirstRange.slice(0, 2)
        }
        // remove first element which is 0 index.
        if (wordCountSecondRange.length > 2) {
          wordCountSecondRange.splice(0, 1)
        }

        // merge two dicts and pass the value to set for the chart.
        console.debug(wordCountFirstRange, wordCountSecondRange)
        await dispatch(
          'popularReadingWordCount',
          wordCountFirstRange.concat(wordCountSecondRange)
        )
      } else {
        await dispatch('popularReadingWordCount', wordCountFirstRange)
      }
    },

    popularReadingWordCount ({ commit }, bucket) {
      const wordCountList = {
        categories: [],
        articles: [],
        interactions: []
      }
      let count = 0
      bucket.forEach(function (item) {
        count += 1
        let docCount = 0
        let avgStats = 0
        if (count <= bucket.length - 1) {
          console.debug(item)
          let countValue = parseInt(item.key)
          const nextItem = bucket[count]

          countValue =
            intToString(countValue).toString() +
            ' - ' +
            intToString(nextItem.key).toString()
          docCount = item.doc_count
          avgStats = item.avg_stats.value

          wordCountList.categories.push(countValue)
          wordCountList.articles.push(docCount)
          wordCountList.interactions.push(parseInt(avgStats))
        }
      }, this)
      commit(
        insightTypes.SET_INSIGHTS_POPULAR_READING_WORD_COUNT,
        wordCountList
      )
      return true
    },

    // insights work

    setInsightsCount ({ commit }, countValues) {
      commit('setInsightsCount', countValues)
    },
    setArticlesPublishedOverTimePostsCount ({ commit }, values) {
      commit('setArticlesPublishedOverTimePostsCount', values)
    },
    setArticlesPublishedOverTimeEngagement ({ commit }, values) {
      commit('setArticlesPublishedOverTimeEngagement', values)
    },
    setArticlesPublishedOverTimeDate ({ commit }, values) {
      commit('setArticlesPublishedOverTimeDate', values)
    },
    setArticlesPublishedOverTimeSentiments ({ commit }, values) {
      commit('setArticlesPublishedOverTimeSentiments', values)
    },
    setInsightsSentimentCount ({ commit }, values) {
      commit('setInsightsSentimentCount', values)
    },
    setInsightsContentTypes ({ commit }, values) {
      commit('setInsightsContentTypes', values)
    },
    setInsightsEngagement ({ commit }, value) {
      commit('setInsightsEngagement', value)
    },
    setInsightsReadingLevels ({ commit }, values) {
      commit('setInsightsReadingLevels', values)
    },
    setInsightsPopularWordCount ({ commit }, values) {
      commit('setInsightsPopularWordCount', values)
    },
    setInsightsPopularDays ({ commit }, values) {
      commit('setInsightsPopularDays', values)
    },
    setInsightsTopDomainsForChart ({ commit }, values) {
      commit('setInsightsTopDomainsForChart', values)
    },
    setInsightsTopDomains ({ commit }, values) {
      commit('setInsightsTopDomains', values)
    },
    setInsightsTopAuthors ({ commit }, values) {
      commit('setInsightsTopAuthors', values)
    },
    setWordCloud ({ commit }, value) {
      commit('setWordCloud', value)
    },
    setInsightsLoader ({ commit }, loader) {
      commit('setInsightsLoader', loader)
    },
    setShowChartsLoader ({ commit }, loader) {
      commit('setShowChartsLoader', loader)
    }
  },
  mutations: {
    [insightTypes.SET_INSIGHTS_LOADER] (state, value) {
      state.loaders.insights = value
    },
    [insightTypes.SET_INSIGHTS_CHARTS_LOADER] (state, value) {
      state.loaders.showCharts = value
    },
    [insightTypes.SET_INSIGHTS_COUNT] (state, value) {
      Object.entries(state.insights.count).forEach((item) => {
        if (value.hasOwnProperty(item[0])) {
          state.insights.count[item[0]] = value[item[0]]
        }
      })
    },

    [insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_POSTS_COUNT] (state, values) {
      state.insights.articlesPublishedOverTimePostsCount = values
    },

    [insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_ENGAGEMENT] (state, values) {
      state.insights.articlesPublishedOverTimeEngagement = values
    },
    [insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_DATE] (state, values) {
      state.insights.articlesPublishedOverTimeDate = values
    },
    [insightTypes.SET_ARTICLES_PUBLISHED_OVER_TIME_SENTIMENTS] (state, values) {
      state.insights.articlesPublishedOverTimeSentiments = values
    },
    [insightTypes.SET_INSIGHTS_SENTIMENT_COUNT] (state, values) {
      state.insights.sentiments = values
    },
    [insightTypes.SET_INSIGHTS_POPULAR_DAYS] (state, values) {
      state.insights.popularDays = values
    },
    [insightTypes.SET_INSIGHTS_TOP_AUTHORS] (state, values) {
      state.insights.topAuthors = values
    },
    [insightTypes.SET_INSIGHTS_TOP_DOMAINS] (state, values) {
      state.insights.topDomains = values
    },
    [insightTypes.SET_INSIGHTS_TOP_DOMAINS_FOR_CHART] (state, values) {
      state.insights.topDomainsForChart = values
    },
    [insightTypes.SET_INSIGHTS_CONTENT_TYPES] (state, values) {
      state.insights.contentTypes = values
    },
    [insightTypes.SET_INSIGHTS_READING_LEVELS] (state, values) {
      state.insights.popularReadingLevels = values
    },
    [insightTypes.SET_INSIGHTS_POPULAR_READING_WORD_COUNT] (state, values) {
      state.insights.popularWordCount = values
    },
    [insightTypes.SET_INSIGHTS_WORDCLOUD] (state, values) {
      state.insights.wordcloud = values
    },
    [insightTypes.SET_INSIGHTS_ENGAGEMENT] (state, values) {
      state.insights.engagement = values
    },

    // insights work
    setInsightsCount (state, countValues) {
      console.log(countValues)
      state.insights.count.articlesAnalyzed = countValues.articlesAnalyzed
      state.insights.count.totalEngagements = countValues.totalEngagements
      state.insights.count.averageEngagements = countValues.averageEngagements
      state.insights.count.averageFacebookEngagement =
        countValues.averageFacebookEngagement
      state.insights.count.averagePinterestEngagement =
        countValues.averagePinterestEngagement
      state.insights.count.averageRedditEngagement =
        countValues.averageRedditEngagement
    },

    setArticlesPublishedOverTimePostsCount (state, values) {
      state.insights.articlesPublishedOverTimePostsCount = values
    },
    setArticlesPublishedOverTimeEngagement (state, values) {
      state.insights.articlesPublishedOverTimeEngagement = values
    },
    setArticlesPublishedOverTimeDate (state, values) {
      state.insights.articlesPublishedOverTimeDate = values
    },
    setArticlesPublishedOverTimeSentiments (state, values) {
      state.insights.articlesPublishedOverTimeSentiments = values
    },

    setInsightsSentimentCount (state, values) {
      state.insights.sentiments = values
    },

    setInsightsContentTypes (state, values) {
      state.insights.contentTypes = values
    },
    setInsightsEngagement (state, value) {
      state.insights.engagement = value
    },
    setInsightsReadingLevels (state, value) {
      state.insights.popularReadingLevels = value
    },
    setInsightsPopularWordCount (state, value) {
      state.insights.popularWordCount = value
    },
    setInsightsPopularDays (state, value) {
      state.insights.popularDays = value
    },
    setInsightsTopDomainsForChart (state, value) {
      state.insights.topDomainsForChart = value
    },
    setInsightsTopDomains (state, value) {
      state.insights.topDomains = value
    },
    setInsightsTopAuthors (state, value) {
      state.insights.topAuthors = value
    },
    setWordCloud (state, value) {
      state.insights.wordcloud = value
    },
    setInsightsLoader (state, loader) {
      state.loaders.insights = loader
    },
    setShowChartsLoader (state, loader) {
      state.loaders.showCharts = loader
    }
  },
  getters: {
    // insights work

    getInsightsCount: (state) => {
      return state.insights.count
    },
    getInsightsArticlesPublishedPostsCount: (state) => {
      return state.insights.articlesPublishedOverTimePostsCount
    },
    getInsightsArticlesPublishedDate: (state) => {
      return state.insights.articlesPublishedOverTimeDate
    },
    getInsightsArticlesPublishedEngagement: (state) => {
      return state.insights.articlesPublishedOverTimeEngagement
    },
    getInsightsArticlesPublishedSentiments: (state) => {
      return state.insights.articlesPublishedOverTimeSentiments
    },
    getInsightsSentimentsCount: (state) => {
      return state.insights.sentiments
    },
    getInsightsContentTypes: (state) => {
      return state.insights.contentTypes
    },
    getInsightsEngagement: (state) => {
      return state.insights.engagement
    },
    getInsightsPopularDays: (state) => {
      return state.insights.popularDays
    },
    getInsightsPopularReadingLevels: (state) => {
      return state.insights.popularReadingLevels
    },
    getInsightsPopularWordCount: (state) => {
      return state.insights.popularWordCount
    },
    getInsightsTopDomainsForChart: (state) => {
      return state.insights.topDomainsForChart
    },
    getInsightsTopDomains: (state) => {
      return state.insights.topDomains
    },
    getInsightsTopAuthors: (state) => {
      return state.insights.topAuthors
    },
    getWordCloud: (state) => {
      return state.insights.wordcloud
    },
    getInsightsLoader: (state) => {
      return state.loaders.insights
    },
    getShowChartsLoader: (state) => {
      return state.loaders.showCharts
    }
  }
}
