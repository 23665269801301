<template>
  <div class="first-comment">
    <div class="grid-flex">
      <div class="cell-12">
        <div class="first-comment__label">
          <div class="d-flex align-items-center mb-3">
            <div class="left_input mr-3">
              <div class="label">First Comment </div>
            </div>
            <div class="right_input d-flex align_center">
              <label class="switch-radio">
                <input
                  v-model="getSocialSharingFirstComment.has_first_comment"
                  type="checkbox"
                  :disabled="!isFirstCommentEnabled"
                  @change="HasFirstCommentChanged"
                />
                <div
                  class="slider round"
                  :class="{ 'disabled grey': !isFirstCommentEnabled }"
                ></div>
              </label>
            </div>
          </div>

          <AccountSelectionFirstComment
            v-show="getSocialSharingFirstComment.has_first_comment"
            :show-label="false"
          />
        </div>

        <div
          v-show="getSocialSharingFirstComment.has_first_comment"
          class="first-comment__wrapper"
        >
          <div class="first-comment__text-field">
            <textarea
              id="firstcommentbox"
              v-auto-height
              :maxlength="firstCommentTextLimit()"
              placeholder="Your comment"
              :value="value.comment"
              @input="typedTextArea($event)"
            ></textarea>
          </div>
          <div class="first-comment__hashtag-btn">
            <Hashtag type="first-comment" :is-comment-box="true" class="ml-0" />
          </div>
        </div>

        <div class="first-comment__info-box">
          <div class="first-comment__info-box-icon ml-3">
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"><i class="far fa-question-circle"></i></a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div> First comment is available for: </div>
                <ul>
                  <li> <i class="fab fa-facebook-f"></i> Pages </li>
                  <li> <i class="fab fa-instagram"></i> API Posting Method </li>
                  <li>
                    <i class="fab fa-linkedin-in"></i> Profiles and Pages
                  </li>
                  <li>
                    <i class="fab fa-youtube"></i> All videos except 'Private'
                    and 'Made for Kids'
                  </li>
                </ul>

                <p class="first-comment__message-box"
                  >How to add first comment?
                  <a
                    v-close-popover="true"
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="60407b770a2dae5b58fb5bef"
                    >Click here</a
                  ></p
                >
              </template>
            </v-menu>
          </div>
          <div class="first-comment__info-box-tray ml-2">
            <i class="fab fa-facebook-f"></i>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-linkedin-in"></i>
            <i class="fab fa-youtube"></i>
          </div>
          Available for
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hashtag from '@src/modules/publish/components/posting/social/options/Hashtag'
import { mapActions, mapGetters } from 'vuex'
import AccountSelectionFirstComment from '@src/modules/publish/components/posting/social/AccountSelectionFirstComment'
export default {
  name: 'FirstCommentSection',

  components: {
    Hashtag,
    AccountSelectionFirstComment,
  },
  directives: {
    'auto-height': {
      // directive definition
      inserted: function (el) {
        // Auto Resize Hack
        el.style.overflow = 'hidden'
        el.style.height = 'auto'

        setTimeout(() => {
          el.style.height = el.scrollHeight + 'px'
        }, 100)
      },
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isFirstCommentEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['getSocialSharingFirstComment', 'getInstagramAccounts']),
  },
  watch: {
    value(newVal, oldVal) {
      console.debug('watch comment', newVal, oldVal)
      if (oldVal.comment === '') {
        const firstcommentboxBox = document.getElementById('firstcommentbox')
        firstcommentboxBox.value = newVal.comment
        setTimeout(() => {
          firstcommentboxBox.style.height =
            firstcommentboxBox.scrollHeight + 'px'
        }, 100)
        console.debug('watch comment done', firstcommentboxBox.value)
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['setHasFirstComment']),
    handleFocus() {
      this.$refs['location-box'].$el.querySelector('input').focus()
    },
    typedTextArea(e) {
      console.debug('typedTextArea')
      this.update('comment', e.target.value)

      // Auto Resize Hack
      e.target.style.overflow = 'hidden'
      e.target.style.height = 'auto'
      e.target.style.height = e.target.scrollHeight + 'px'
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    firstCommentTextLimit() {
      let limit = 8000
      if (
        this.getSocialSharingFirstComment.first_comment_accounts &&
        this.getSocialSharingFirstComment.first_comment_accounts.length >= 0
      ) {
        if (this.getInstagramAccounts.items.length > 0) {
          this.getInstagramAccounts.items.forEach((item) => {
            if (
              this.getSocialSharingFirstComment.first_comment_accounts.includes(
                item.instagram_id
              )
            ) {
              limit = 2200
            }
          })
        }
      }
      return limit
    },
    HasFirstCommentChanged(event) {
      this.$emit('firstCommentChange', event.target.checked)
    },
  },
}
</script>
