<template>
  <div class="topics-top-pane__inner__left_section__inner">
    <h2 class="pt-0">
      {{ limitTextLength(title, 40) }}
    </h2>
    <p v-if="$route.params.feed_id" class="">
      <span
        v-if="avg_articles_per_week && avg_articles_per_week > 0"
        class="feed_activity"
      >
        {{ avg_articles_per_week }}
        <template v-if="avg_articles_per_week === 1">article</template
        ><template v-else>articles</template> per week
      </span>
      <span
        v-if="hasGreaterCategoriesOccurrence.length > 0"
        class="feed_activity"
      >
        {{ getTopicsListInString }}
        <!--<template v-for="category, index in hasGreaterCategoriesOccurrence" v-if="index <=2">{{category.interest}}<template
                          v-if="index!==2">, </template> </template>-->
      </span>
      <span v-if="hashtags && hashtags.length > 0" class="feed_activity">
        <template v-for="hashtag in hashtags"
          >#<span v-html="hashtag.value"></span>
        </template>
      </span>
    </p>
  </div>
</template>

<script>
import ContentTotalResults from './ContentTotalResults'

export default {
  components: {
    ContentTotalResults
  },
  props: {
    title: {},
    image: {},
    categories: {},
    hashtags: {},
    avg_articles_per_week: {},
    show_add_sources_button: {}
  },
  computed: {
    hasGreaterCategoriesOccurrence () {
      if (this.categories && this.categories.length > 0) {
        return this.categories.filter((item) => item.occurrence > 5)
      } else {
        return []
      }
    },
    getTopicsListInString () {
      const categoriesList = this.hasGreaterCategoriesOccurrence.map(
        (item) => item.interest
      )
      return categoriesList.join(', ')
    }
  }
}
</script>

<style lang="less">
.feed_activity {
  font-size: 13px;
}

.feed_activity:first-child::before {
  content: '';
}

.feed_activity::before {
  content: ' / ';
}
</style>
