<template>
  <div
    v-if="related_topics.length"
    class="related_topic_list article-search-bar-pane__related_topics"
  >
    <p>Related Topics:</p>
    <ul class="related_topic_list_inner">
      <li
        v-for="related_topic in related_topics.slice(0, 20)"
        @click.prevent="searchForRelatedTopic(related_topic)"
        >{{ related_topic }}
      </li>
    </ul>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'

export default {
  data () {
    return {
      related_topics: []
    }
  },
  mounted () {
    EventBus.$on('related-topics-suggestions', () => {
      console.log('related topics suggestion event fired')
      this.findRelatedTopicsSuggestions()
    })
    EventBus.$on('reset-related-topics-suggestions', () => {
      this.related_topics = []
    })
  },
  beforeUnmount () {
    EventBus.$off('related-topics-suggestions')
    EventBus.$off('reset-related-topics-suggestions')
  },
  methods: {
    findRelatedTopicsSuggestions () {
      const responseItem = this.$store.dispatch(
        'fetchRelatedTopicsSuggestions',
        this.$route.query.q
      )
      responseItem
        .then((res) => {
          console.log('related topics suggestions', res)
          if (res.data.status) {
            this.related_topics = res.data.topics
          } else {
            this.related_topics = []
          }
        })
        .catch((err) => {})
        .finally()
    },
    searchForRelatedTopic (keyword) {
      this.$router.replace({ query: { q: keyword.toLowerCase() } }) // ...this.$route.query, removed this, to change the view to the default routes.
    }
  }
}
</script>
