<script setup>
import OverlayPost from './OverlayPost'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import useDateFormat from "@common/composables/useDateFormat";
import moment from "moment";
const {momentWrapper} = useDateFormat()
defineProps({
  item: {
    type: Object,
    required: true,
  },
  isDropping: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    required: true,
  },
})
defineEmits(['dragover', 'dragstart', 'dragend'])
const {
  getThumbnail,
  getOverlayDetails,
  shouldDisplayIcon,
  getStatusIcon,
  getFeedStatus,
  isRemoteMedia,
  getFormattedDate,
  getFormattedTime
} = useInstagramGridView()


</script>

<template>
  <div
    class="flex relative col-span-3 w-full pr-[1px] pt-[1px] aspect-static cursor-pointer group"
    draggable="true"
    @dragstart="$emit('dragstart', $event, index)"
    @dragover="$emit('dragover', $event, index)"
    @dragend="$emit('dragend', $event)"
  >
    <OverlayPost
      :details="getOverlayDetails(item)"
      :item="item"
      item-type="story"
    >
      <div v-if="isDropping">
        <div
          class="absolute z-20 inset-0 text-center flex items-center justify-center text-gray-900 bg-white text-sm font-semibold"
        >
          Drop to swap posts
        </div>
      </div>
      <div
        v-if="!isRemoteMedia(item)"
        class="backdrop-blur-sm !z-10 inset-0 bg-gray-800/30 rounded flex flex-col items-center justify-center invisible absolute group-hover:!visible pointer-events-none"
      >
        <p
          class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
        >
          {{
           getFormattedDate(item?.execution_time?.date)
          }}
        </p>
        <p
          class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
        >
          {{
            getFormattedTime(item?.execution_time?.date)
          }}
        </p>
      </div>

      <img
        class="w-full h-full object-cover"
        loading="lazy"
        alt="Post"
        :src="getThumbnail(item)"
        @error="
          $event.target.src = require('@src/assets/img/no_data_images/no_media_found.svg')
        "
      />
      <div class="absolute rounded bottom-1 left-1 p-1 z-10">
        <img
          src="@assets/img/common/video-play-outlined.svg"
          alt="Top Right Icon"
          class="w-3 h-3"
          loading="lazy"
        />
      </div>
      <div
        v-if="shouldDisplayIcon(item)"
        class="flex align-items-center justify-content-center absolute top-1 left-1 p-[0.2rem] bg-[#202324] bg-opacity-60 rounded border !border-[#D2D5DF] z-10"
      >
        <img
          v-tooltip="getFeedStatus(item)"
          :src="getStatusIcon(item)"
          alt="Status Icon"
          class="w-[1rem] h-[1rem]"
        />
      </div>
      <div
        v-if="item?.isDraggable"
        v-tooltip="'Drag to swap posts'"
        draggable="false"
        class="invisible group-hover:!visible absolute top-1.5 right-1 !z-20 drop-shadow"
      >
        <img
          src="@assets/img/icons/planner/grid-view/drag-item.svg"
          alt="Drag Icon"
          class="w-[1.25rem] h-[1.25rem]"
        />
      </div>
    </OverlayPost>
  </div>
</template>

<style lang="scss" scoped>
.aspect-static {
  aspect-ratio: 1;
}
</style>
