<template>
  <div :class="{ disable_click: getTopicDisabled }">
    <div class="checkbox_input_image ml-0 transparent-bd">
      <input
        id="Paginate"
        v-model="getVideoFilter.youtube.hd"
        type="checkbox"
        class=""
        @click="getYoutubeVideo"
      />
      <label for="Paginate" class="checkbox_left">HD Videos</label>
    </div>
    &nbsp;

    <div
      v-tooltip.top="'Sort By'"
      class="dropdown option_dropdown default_style_dropdown"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="sorting_icon icon_first cs-filter"></i>
        <span class="text">{{ getYoutubeSort.name }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li
            class="list_item"
            @click.prevent="changeYoutubeSort('Relevance', 'Relevance')"
          >
            <a class="dropdown-item">Relevance</a>
          </li>
          <li
            class="list_item"
            @click.prevent="changeYoutubeSort('Most Viewed', 'ViewCount')"
          >
            <a class="dropdown-item">Most Viewed</a>
          </li>
          <li
            class="list_item"
            @click.prevent="changeYoutubeSort('Recent', 'Date')"
          >
            <a class="dropdown-item">Recent</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getYoutubeSort', 'getVideoFilter', 'getTopicDisabled'])
  },
  methods: {}
}
</script>
