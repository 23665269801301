<template>
  <div id="ckeditor-wrapper" class="ckeditor-wrapper">
    <div v-show="editorLoaderStatus" class="editor_loader_area py-5">
      <clip-loader :color="'#4165ed'" :size="'16px'"></clip-loader>
    </div>

    <ckeditor
      v-show="!editorLoaderStatus"
      v-model="editorData"
      :editor="build"
      :config="editorConfiguration"
      @ready="handleReady"
    ></ckeditor>
    <div
      v-show="!editorLoaderStatus"
      class="ckeditor-count d-flex justify-content-end p-2"
    >
      <div class="mr-2"> Characters: {{ wordCount.characters }} </div>
      <div> Words: {{ wordCount.words }} </div>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@seeratawan01/ckeditor5-custom-build'
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types.js'
import { storageBaseUrl } from '@src/config/api-utils.js'

export default {
  name: 'App',
  components: {
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      build: ClassicEditor,
      editorData: '',
      editor: null,
      editorLoaderStatus: true,
      wordCount: {
        characters: 0,
        words: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['getJWTToken', 'getWorkspaces', 'getBlogPostingHTML']),
    editorConfiguration() {
      const self = this
      const IFRAME_SRC = 'https://cdn.iframe.ly/api/iframe'
      const API_KEY = 'e40f048005f7c337ac3aaae8e31df0cb'

      return {
        autosave: {
          waitingTime: 5000,
          save(editor) {
            return self.autoSave(editor.getData())
          },
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: storageBaseUrl + 'media_library/assets/blogImageUpload',

          // // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,
          //
          // // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            'X-WORKSPACE-ID': this.getWorkspaces.activeWorkspace._id,
            Authorization: 'Bearer ' + this.getJWTToken,
          },
        },
        wordCount: {
          onUpdate: (stats) => {
            // Prints the current content statistics.
            self.wordCount.characters = stats.characters
            self.wordCount.words = stats.words

            console.log(
              `Characters: ${stats.characters}\nWords: ${stats.words}`
            )
            this.$store.commit(blogPosting.SET_WORDS_COUNT, stats.words)
          },
        },
        mediaLibrary: {
          callback: () => {
            this.showInsertFileModel('BlogEditorFile')
          },
        },
        // Configure 'mediaEmbed' with Iframely previews.
        mediaEmbed: {
          // Previews are always enabled if there’s a provider for a URL (below regex catches all URLs)
          // By default `previewsInData` are disabled, but let’s set it to `false` explicitely to be sure
          previewsInData: false,

          providers: [
            {
              // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
              name: 'iframely previews',

              // Match all URLs or just the ones you need:
              url: /.+/,

              html: (match) => {
                const url = match[0]

                const iframeUrl =
                  IFRAME_SRC +
                  '?app=1&key=' +
                  API_KEY +
                  '&url=' +
                  encodeURIComponent(url)
                // alternatively, use &key= instead of &api_key with the MD5 hash of your api_key
                // more about it: https://iframely.com/docs/allow-origins

                return (
                  // If you need, set maxwidth and other styles for 'iframely-embed' class - it's yours to customize
                  '<div class="iframely-embed">' +
                  '<div class="iframely-responsive">' +
                  `<iframe src="${iframeUrl}" ` +
                  'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                  '</iframe>' +
                  '</div>' +
                  '</div>'
                )
              },
            },
          ],
        },
        toolbar:{
          shouldNotGroupWhenFull:true
        }
      }
    },
  },
  watch: {
    editorData(val) {
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_HTML,
        `<div class='cs-blog-content'>${val}</div>`
      )
    },

    getBlogPostingHTML(val) {
      if (val !== `<div class='cs-blog-content'>${this.editorData}</div>`) {
        this.editorData = val
      }
    },
  },
  mounted() {

    EventBus.$on('blog-keyword', (keyword) => {
      this.insertTag(keyword)
    })

    EventBus.$on('insert-chat-content', (keyword) => {
      this.editor.model.change( writer => {
        const viewFragment = this.editor.data.processor.toView( keyword );
        const modelFragment = this.editor.data.toModel( viewFragment );
        this.editor.model.insertContent( modelFragment );
      } );
    })

    EventBus.$on('blog-Questions', (questions) => {
      this.insertQuestions(questions)
    })

    if (document.getElementById('iframeScript')) {
      return
    }
    const src =
      'https://cdn.iframe.ly/embed.js?key=e40f048005f7c337ac3aaae8e31df0cb'
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('charset', 'utf-8')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'iframeScript')
    document.head.appendChild(script)

    EventBus.$on('insert-image-to-blog', ({ link, type = 'image' }) => {
      console.log('insert-image-to-blog', link)

      this.editor.model.change((writer) => {
        if (type === 'image') {
          const imageElement = writer.createElement('imageBlock', {
            src: link,
          })

          this.editor.model.insertContent(
            imageElement,
            this.editor.model.document.selection
          )
        } else if(type === 'video') {
          this.editor.execute('mediaEmbed', link)
        }
      })
    })
  },
  beforeUnmount() {
    const el = document.getElementById('iframeScript')
    if (el) {
      el.remove()
    }
    EventBus.$off('blog-keyword')
    EventBus.$off('blog-Questions')
    EventBus.$off('insert-chat-content')
  },
  methods: {
    autoSave(data) {
      this.blogPostAutoSave()
      this.$emit('autosave', data)
    },
    insertTag(keyword = '') {
      const model = this.editor.model
      const text = ' ' + keyword.trim()
      const selection = this.editor.model.document.selection
      model.change((writer) => {
        const currentAttributes = model.document.selection.getAttributes()
        const insertPosition = selection.focus
        model.insertContent(
          writer.createText(text, currentAttributes, insertPosition)
        )
      })
    },
    insertQuestions(questions = '') {
      const model = this.editor.model
      const htmlString = `<br><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">${questions}</span>`
      // Convert an HTML string to a view document fragment:
      const viewFragment = this.editor.data.processor.toView(htmlString)
      const modelFragment = this.editor.data.toModel(viewFragment)
      model.insertContent(modelFragment)
    },
    handleReady(editor) {
      console.log('Method::handleReady', this.getBlogPostingHTML)
      this.editor = editor

      this.editorLoaderStatus = false

      this.editor.editing.view.document.on('drop', () => {
        console.log('drop')
      })

      this.editor.editing.view.document.on('dragover', () => {
        console.log('dragover')
      })
    },
  },
}
</script>

<style lang="scss">
.ckeditor-wrapper {
  box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
}
.editor__editable,
main .ck-editor[role='application'] .ck.ck-content,
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
.ck.editor[role='textbox'] {
  width: 100%;
  background: #fff;
  font-size: 1rem;
  line-height: 1.6em;
  min-height: 400px;
  padding: 1.5rem 2rem;

  ul,
  ol {
    padding-left: 2rem;
  }

  ul:not(.todo-list) {
    li {
      list-style: disc;
    }
  }

  ol:not(.todo-list) {
    li {
      list-style: decimal;
    }
  }
}

.ck.ck-balloon-panel {
  z-index: 99999 !important;
}

.ckeditor-count {
  border: 1px solid #c4c4c4;
  border-top: 0;
  background: #fff;
  //box-shadow: 0 2px 6px 0 rgba(184, 189, 209, 0.3);
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ck .ck-link_selected,
.ck-content a {
  pointer-events: none;
}
</style>
