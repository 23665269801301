import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  uploadSharingImageUrl,
  uploadMediaAssetUrl,
} from '@src/modules/publish/config/api-utils'
import {
  blogPostingTypes,
  gmb,
  instagramTypes,
  linkedinSharingTypes,
  youtubeTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@src/modules/common/lib/event-bus'
import { UNKNOWN_ERROR } from '../../../constants/messages'
import proxy from '../../../lib/http-common'

export const sharingImageMixin = {
  computed: {
    ...mapGetters([
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getThreadedTweetsSharingDetails',
      'getJWTToken',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getInstagramSharingDetails',
      'getBlogPostingDetails',
      'getWorkspaces',
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_COMMON_SHARING_VIDEO',
      'SET_FACEBOOK_SHARING_VIDEO',
      'SET_TWITTER_SHARING_VIDEO',
      'SET_TUMBLR_SHARING_VIDEO',
      'SET_PINTEREST_SHARING_VIDEO',
    ]),
    ...mapActions([
      'setPinterestSharingImage',
      'setTumblrSharingImage',
      'setThreadedTweetsSharingVideo',
      'setPinterestSharingPreview',
      'setUploadPinterestSharingImageLoader',
      'setTumblrSharingPreview',
      'setUploadCommonSharingImageLoader',
      'setUploadFacebookSharingImageLoader',
      'setUploadTwitterSharingImageLoader',
      'setUploadThreadedTweetsSharingImageLoader',
      'setUploadTumblrSharingImageLoader',
      'setCommonImagePercentage',
      'setFacebookImagePercentage',
      'setTwitterImagePercentage',
      'setThreadedTweetsImagePercentage',
      'setPinterestImagePercentage',
      'setTumblrImagePercentage',
      'setCommonSharingNumberOfImages',
      'setTwitterSharingImage',
      'setThreadedTweetsSharingImage',
      'setFacebookSharingImage',
      'setCommonSharingImage',
      'setCommonSharingPreview',
      'setFacebookSharingPreview',
      'setTwitterSharingPreview',
      'setFacebookSharingNumberOfImages',
      'setTwitterSharingNumberOfImages',
      'setThreadedTweetsSharingNumberOfImages',
      'setPinterestSharingLink',
      'setTumblrSharingLink',
      'setMediaStorageLimit',
    ]),

    addSharingImage(imageUrl, type, threadedTweetIndex = 0) {
      console.debug('Method:addSharingImage')

      let gifCount = 0
      const stateObject = this
      setTimeout(function () {
        if (type === 'Common') {
          const images = stateObject.getCommonSharingDetails.image
          if (images && images.length > 0) {
            $(images).each(function (index, el) {
              const extension = el.split('.').pop()

              if (extension === 'gif') {
                gifCount = gifCount + 1
              }
            })
          }

          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
          } else {
            const social = stateObject.getCommonSharingDetails
            if (social.image.includes(imageUrl) === false) {
              social.image.push(imageUrl)
            }
          }
        } else if (type === 'Facebook') {
          const images = stateObject.getFacebookSharingDetails.image
          if (images && images.length > 0) {
            $(images).each(function (index, el) {
              const extension = el.split('.').pop()

              if (extension === 'gif') {
                gifCount = gifCount + 1
              }
            })
          }

          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
          } else {
            const facebook = stateObject.getFacebookSharingDetails
            if (facebook.image.includes(imageUrl) === false) {
              facebook.image.push(imageUrl)
            }
          }
        } else if (type === 'Linkedin') {
          const images = stateObject.getLinkedinSharingDetails.image
          if (images && images.length > 0) {
            images.forEach(function (el) {
              if (el.split('.').pop() === 'gif') gifCount = gifCount + 1
            })
          }

          // showing error if user add multiple gif to the images
          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
          } else {
            // adding image to the images array
            const linkedin = stateObject.getLinkedinSharingDetails
            if (linkedin.image.includes(imageUrl) === false) {
              if (linkedin.image.length > 8) linkedin.image.pop()
              linkedin.image.push(imageUrl)
            }
          }
        } else if (type === 'Instagram') {
          const images = stateObject.getInstagramSharingDetails.image

          // calculating gif counts
          if (images && images.length > 0) {
            $(images).each(function (index, el) {
              if (el.split('.').pop() === 'gif') gifCount = gifCount + 1
            })
          }

          // checking gif counts
          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
            return false
          }
          const instagram = stateObject.getInstagramSharingDetails
          if (instagram.image.includes(imageUrl) === false) {
            if (instagram.image.length > 9) instagram.image.pop()
            instagram.image.push(imageUrl)
          }
        } else if (type === 'ThreadedTweets') {
          const images =
            stateObject.getThreadedTweetsSharingDetails[threadedTweetIndex]
              .image
          if (images && images.length > 0) {
            $(images).each(function (index, el) {
              const extension = el.split('.').pop()

              if (extension === 'gif') {
                gifCount = gifCount + 1
              }
            })
          }

          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
          } else {
            const threadedTweet =
              stateObject.getThreadedTweetsSharingDetails[threadedTweetIndex]
            if (threadedTweet.image.length > 3) {
              threadedTweet.image.pop()
            }

            if (threadedTweet.image.includes(imageUrl) === false) {
              threadedTweet.image.push(imageUrl)
            }
          }
        } else {
          const images = stateObject.getTwitterSharingDetails.image
          if (images && images.length > 0) {
            $(images).each(function (index, el) {
              const extension = el.split('.').pop()

              if (extension === 'gif') {
                gifCount = gifCount + 1
              }
            })
          }

          if (gifCount) {
            stateObject.alertMessage(
              'You can only add one type of file.',
              'error'
            )
          } else {
            const twitter = stateObject.getTwitterSharingDetails
            if (twitter.image.length > 3) {
              twitter.image.pop()
            }

            if (twitter.image.includes(imageUrl) === false) {
              twitter.image.push(imageUrl)
            }
          }
        }
      }, 200)
    },

    updateSharingImage(imageUrl, imageIndex, type, threadedTweetIndex = 0) {
      console.debug(
        'Method:updateSharingImage',
        imageUrl,
        imageIndex,
        type,
        threadedTweetIndex
      )

      if (type === 'Common') {
        const images = []
        $(this.getCommonSharingDetails.image).each(function (index, el) {
          if (index === parseInt(imageIndex)) {
            images.push(imageUrl)
          } else {
            images.push(el)
          }
        })

        this.setCommonSharingImage(images)
      } else if (type === 'Facebook') {
        const images = []
        $(this.getFacebookSharingDetails.image).each(function (index, el) {
          if (index === parseInt(imageIndex)) {
            images.push(imageUrl)
          } else {
            images.push(el)
          }
        })

        this.setFacebookSharingImage(images)
      } else if (type === 'Linkedin') {
        const images = []
        $(this.getLinkedinSharingDetails.image).each(function (index, el) {
          if (index === parseInt(imageIndex)) {
            images.push(imageUrl)
          } else {
            images.push(el)
          }
        })

        this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, images)
      } else if (type === 'Instagram') {
        const images = []
        $(this.getInstagramSharingDetails.image).each(function (index, el) {
          images.push(index === parseInt(imageIndex) ? imageUrl : el)
        })
        this.$store.commit(instagramTypes.SET_SHARING_IMAGE, images)
      } else if (type === 'ThreadedTweets') {
        const images = []
        $(this.getThreadedTweetsSharingDetails[threadedTweetIndex].image).each(
          function (index, el) {
            if (index === parseInt(imageIndex)) {
              images.push(imageUrl)
            } else {
              images.push(el)
            }
          }
        )
        this.setThreadedTweetsSharingImage({
          image: images,
          index: threadedTweetIndex,
        })
      } else {
        const images = []
        $(this.getTwitterSharingDetails.image).each(function (index, el) {
          if (index === parseInt(imageIndex)) {
            images.push(imageUrl)
          } else {
            images.push(el)
          }
        })

        this.setTwitterSharingImage(images)
      }
    },

    removeSharingImage(type, image, threadedTweetIndex = 0) {
      console.debug(
        'Method:removeSharingImage',
        type,
        image,
        threadedTweetIndex
      )

      const stateObject = this
      let index = -1
      setTimeout(function () {
        switch (type) {
          case 'Common':
            const social = stateObject.getCommonSharingDetails
            index = social.image.indexOf(image)
            if (index > -1) {
              social.image.splice(index, 1)
            }
            break
          case 'Facebook':
            const facebook = stateObject.getFacebookSharingDetails
            index = facebook.image.indexOf(image)
            if (index > -1) {
              facebook.image.splice(index, 1)
            }
            break
          case 'Twitter':
            const twitter = stateObject.getTwitterSharingDetails
            index = twitter.image.indexOf(image)
            if (index > -1) {
              twitter.image.splice(index, 1)
            }
            break
          case 'ThreadedTweets':
            const threadedTweet =
              stateObject.getThreadedTweetsSharingDetails[threadedTweetIndex]
            index = threadedTweet.image.indexOf(image)
            if (index > -1) {
              threadedTweet.image.splice(index, 1)
            }
            break
          case 'Linkedin':
            // finding and removing image link from image array
            const linkedin = stateObject.getLinkedinSharingDetails
            index = linkedin.image.indexOf(image)
            if (index > -1) linkedin.image.splice(index, 1)
            stateObject.$store.commit(linkedinSharingTypes.SET_SHARING_URL, '')
            break
          case 'Pinterest':
            stateObject.setPinterestSharingImage('')
            stateObject.setPinterestSharingLink('')
            break
          case 'Tumblr':
            stateObject.setTumblrSharingImage('')
            stateObject.setTumblrSharingLink('')
            break
          case 'Instagram':
            const details = stateObject.getInstagramSharingDetails
            index = details.image.indexOf(image)
            if (index > -1) details.image.splice(index, 1)
            break
          case 'Gmb':
            stateObject.$store.commit(gmb.SET_SHARING_IMAGE, '')
            break
          case 'Youtube':
            stateObject.$store.commit(youtubeTypes.SET_SHARING_IMAGE, '')
            break
        }
      }, 200)
    },

    triggerSharingImageUpload(selector) {
      console.debug('Method:triggerSharingImageUpload', selector)
      document.getElementById(selector).click()
    },

    triggerSharingImageUpdate(selector, index) {
      console.debug('Method:triggerSharingImageUpdate', selector)

      let imageIndex = ''
      if (typeof index !== 'undefined') {
        imageIndex = index
      }

      document.getElementById(selector).setAttribute('data-index', imageIndex)
      document.getElementById(selector).click()
    },

    uploadImage(event) {
      console.debug('Method:uploadSharingImage')

      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()

      const fileType = []
      let gifCount = 0
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'image/gif') {
          gifCount = gifCount + 1
        }

        if (fileType.includes(files[i].type) === false) {
          fileType.push(files[i].type)
        }

        formData.append('media_assets[]', files[i])
      }

      // for uncategorized assets
      formData.append('folder_id', null)
      formData.append('workspace_id', this.getActiveWorkspace._id)

      return proxy
        .post(uploadMediaAssetUrl, formData)
        .then((response) => {
          console.debug('response', response)
          // service call successful
          if (response.status === 200) {
            return response.data
          }
        })
        .catch((error) => {
          console.log('failed for some reason .. ', error)
        })
    },

    /**
     * in case of 'paste' event, images are fetched from clipboard and uploaded against 'type' box.
     * @param event
     * @param type
     * @param threadedTweetIndex
     * @returns {boolean}
     */
    uploadSharingImage(event, type, threadedTweetIndex = 0) {
      console.debug('Method:uploadSharingImage')
      console.log('Method:uploadSharingImage ~ Type -->  ', type)

      let files = []
      if (event.type === 'paste') {
        const itemImages = []
        const items = (event.clipboardData || event.originalEvent.clipboardData)
          .items
        for (let i = 0; i <= items.length - 1; i++) {
          const item = items[i].getAsFile()
          if (item) {
            itemImages.push(item)
          }
        }
        files = itemImages
      } else {
        files = event.target.files || event.dataTransfer.files
      }

      const formData = new FormData()

      const fileType = []
      let gifCount = 0
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'image/gif') {
          gifCount = gifCount + 1
        }

        if (fileType.includes(files[i].type) === false) {
          fileType.push(files[i].type)
        }

        formData.append('media_assets[]', files[i])
      }

      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('folder_id', null)

      // checking video already uploaded or not
      if (
        [
          'Common',
          'Facebook',
          'Twitter',
          'ThreadedTweets',
          'Tumblr',
          'Instagram',
        ].indexOf(type) >= 0
      ) {
        let details = null
        if (type === 'ThreadedTweets') {
          details = this['get' + type + 'SharingDetails'][threadedTweetIndex]
        } else {
          details = this['get' + type + 'SharingDetails']
        }
        if (details.video && details.video.link) {
          // showing modal to confirm the video replacing
          this.confirmAction('replaceCurrentTypeConfirmation', {
            event,
            type,
            formData,
          })
          return false
        }
      }

      if (
        [
          'Common',
          'Facebook',
          'Twitter',
          'ThreadedTweets',
          'Linkedin',
          'Instagram',
        ].includes(type)
      ) {
        if (
          fileType.includes('image/gif') &&
          (fileType.includes('image/jpg') ||
            fileType.includes('image/jpeg') ||
            fileType.includes('image/png'))
        ) {
          this.alertMessage('Please upload one file type at a time.', 'error')
          return false
        } else if (gifCount > 1) {
          this.alertMessage(
            'Your are allowed to upload only 1 GIF image.',
            'error'
          )
          return false
        } else {
          if (type === 'Common') {
            if (this.getCommonSharingDetails.url) {
              this.setCommonSharingImage([])
            }

            this.setCommonSharingNumberOfImages(files.length)
            const images = this.getCommonSharingDetails.image
            if (images && images.length > 0) {
              $(images).each(function (index, el) {
                const extension = el.split('.').pop()

                if (extension === 'gif') {
                  gifCount = gifCount + 1
                }

                if (fileType.includes('image/' + extension) === false) {
                  fileType.push('image/' + extension)
                }
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
            } else if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
            } else {
              this.setCommonSharingPreview(null)
              this.SET_COMMON_SHARING_VIDEO(null)
              this.setUploadCommonSharingImageLoader({
                status: true,
                position: -1,
              })
              this.processSharingImageUpload(event, formData, type)
            }
          } else if (type === 'Facebook') {
            if (this.getFacebookSharingDetails.url) {
              this.setFacebookSharingImage([])
            }

            this.setFacebookSharingNumberOfImages(files.length)
            const images = this.getFacebookSharingDetails.image
            if (images && images.length > 0) {
              $(images).each(function (index, el) {
                const extension = el.split('.').pop()

                if (extension === 'gif') {
                  gifCount = gifCount + 1
                }

                if (fileType.includes('image/' + extension) === false) {
                  fileType.push('image/' + extension)
                }
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
            } else if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
            } else {
              this.setFacebookSharingPreview(null)
              this.SET_FACEBOOK_SHARING_VIDEO(null)
              this.setUploadFacebookSharingImageLoader({
                status: true,
                position: -1,
              })
              this.processSharingImageUpload(event, formData, type)
            }
          } else if (type === 'Twitter') {
            if (this.getTwitterSharingDetails.url) {
              this.setTwitterSharingImage([])
            }

            this.setTwitterSharingNumberOfImages(files.length)
            const images = this.getTwitterSharingDetails.image
            if (images && images.length > 0) {
              $(images).each(function (index, el) {
                const extension = el.split('.').pop()

                if (extension === 'gif') {
                  gifCount = gifCount + 1
                }

                if (fileType.includes('image/' + extension) === false) {
                  fileType.push('image/' + extension)
                }
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
            } else if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
            } else {
              this.setTwitterSharingPreview(null)
              this.SET_TWITTER_SHARING_VIDEO(null)
              this.setUploadTwitterSharingImageLoader({
                status: true,
                position: -1,
              })
              this.processSharingImageUpload(event, formData, type)
            }
          } else if (type === 'ThreadedTweets') {
            if (this.getThreadedTweetsSharingDetails[threadedTweetIndex].url) {
              this.setThreadedTweetsSharingImage({
                image: [],
                index: threadedTweetIndex,
              })
            }

            this.setThreadedTweetsSharingNumberOfImages({
              images: files.length,
              index: threadedTweetIndex,
            })
            const images =
              this.getThreadedTweetsSharingDetails[threadedTweetIndex].image
            if (images && images.length > 0) {
              $(images).each(function (index, el) {
                const extension = el.split('.').pop()

                if (extension === 'gif') {
                  gifCount = gifCount + 1
                }

                if (fileType.includes('image/' + extension) === false) {
                  fileType.push('image/' + extension)
                }
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
            } else if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
            } else {
              // this.setThreadedTweetsSharingPreview(null)
              this.setThreadedTweetsSharingVideo({
                video: null,
                index: threadedTweetIndex,
              })
              this.setUploadThreadedTweetsSharingImageLoader({
                loader: {
                  status: true,
                  position: -1,
                },
                index: threadedTweetIndex,
              })
              this.processSharingImageUpload(
                event,
                formData,
                type,
                threadedTweetIndex
              )
            }
          } else if (type === 'Linkedin') {
            if (this.getLinkedinSharingDetails.url)
              this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
            this.$store.commit(
              linkedinSharingTypes.SET_SHARING_NUMBER_OF_IMAGES,
              files.length
            )
            const images = this.getLinkedinSharingDetails.image
            if (images && images.length > 0) {
              images.forEach(function (el) {
                const extension = el.split('.').pop()
                if (extension === 'gif') gifCount = gifCount + 1
                if (fileType.includes('image/' + extension) === false)
                  fileType.push('image/' + extension)
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
            } else if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
            } else {
              this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
              this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
              this.$store.commit(
                linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
                {
                  status: true,
                  position: -1,
                }
              )
              this.processSharingImageUpload(event, formData, type)
            }
          } else if (type === 'Instagram') {
            if (this.getInstagramSharingDetails.url)
              this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
            this.$store.commit(
              instagramTypes.SET_SHARING_NUMBER_OF_IMAGES,
              files.length
            )
            const images = this.getInstagramSharingDetails.image
            if (images && images.length > 0) {
              $(images).each(function (index, el) {
                const extension = el.split('.').pop()
                if (extension === 'gif') gifCount = gifCount + 1
                if (fileType.includes('image/' + extension) === false)
                  fileType.push('image/' + extension)
              })
            }

            if (gifCount > 1) {
              this.confirmAction('replaceGifConfirmation', {
                event,
                type,
                formData,
              })
              return false
            }
            if (
              fileType.includes('image/gif') &&
              (fileType.includes('image/jpg') ||
                fileType.includes('image/jpeg') ||
                fileType.includes('image/png'))
            ) {
              this.confirmAction('replaceCurrentTypeConfirmation', {
                event,
                type,
                formData,
              })
              return false
            }
            this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
            this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
              status: true,
              position: -1,
            })
            this.processSharingImageUpload(event, formData, type)
          }
        }
      } else {
        switch (type) {
          case 'Pinterest':
            this.setPinterestSharingPreview(null)
            this.SET_PINTEREST_SHARING_VIDEO(null)
            this.setUploadPinterestSharingImageLoader(true)
            break
          case 'Tumblr':
            this.setTumblrSharingPreview(null)
            this.SET_TUMBLR_SHARING_VIDEO(null)
            this.setUploadTumblrSharingImageLoader(true)
            break
          case 'Gmb':
            this.$store.commit(gmb.SET_SHARING_PREVIEW, null)
            this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, true)
            break
        }

        this.processSharingImageUpload(event, formData, type)
      }
    },

    processSharingImageUpload(event, formData, type, threadedTweetIndex = 0) {
      console.debug('Method:processSharingImageUpload')
      formData.append('workspace_id', this.getWorkspaces.activeWorkspace._id)
      const stateObject = this
      const ajax = new XMLHttpRequest()

      ajax.upload.addEventListener('progress', function (progressEvent) {
        stateObject.sharingImageUploadProgressHandler(
          progressEvent,
          type,
          threadedTweetIndex
        )
      })

      ajax.addEventListener('load', function (loadEvent) {
        stateObject.sharingImageUploadCompleteHandler(
          loadEvent,
          type,
          threadedTweetIndex
        )
      })

      ajax.addEventListener('error', function (loadEvent) {
        stateObject.sharingImageUploadErrorHandler(
          loadEvent,
          type,
          threadedTweetIndex
        )
      })

      ajax.open('POST', uploadMediaAssetUrl)
      ajax.setRequestHeader('Authorization', 'Bearer ' + this.getJWTToken)
      ajax.send(formData)

      event.target.value = ''
    },

    processsUpdateSharingImage(event, type, threadedTweetIndex = 0) {
      console.debug(
        'Method:processsUpdateSharingImage',
        type,
        threadedTweetIndex
      )

      const indexPosition = $(event.target).attr('data-index')
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()

      formData.append('media_assets[]', files[0])

      // for uncategorized assets
      formData.append('folder_id', null)
      formData.append('workspace_id', this.getActiveWorkspace._id)

      switch (type) {
        case 'Common':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getCommonSharingDetails.image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }

          this.setUploadCommonSharingImageLoader({
            status: true,
            position: indexPosition,
          })
          break
        case 'Facebook':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getFacebookSharingDetails.image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }

          this.setUploadFacebookSharingImageLoader({
            status: true,
            position: indexPosition,
          })
          break
        case 'Twitter':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getTwitterSharingDetails.image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }

          this.setUploadTwitterSharingImageLoader({
            status: true,
            position: indexPosition,
          })
          break
        case 'ThreadedTweets':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getTwitterSharingDetails[threadedTweetIndex].image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }

          this.setUploadThreadedTweetsSharingImageLoader({
            loader: {
              status: true,
              position: indexPosition,
            },
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getLinkedinSharingDetails.image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            {
              status: true,
              position: indexPosition,
            }
          )
          break
        case 'Pinterest':
          this.setUploadPinterestSharingImageLoader(true)
          break
        case 'Tumblr':
          this.setUploadTumblrSharingImageLoader(true)
          break
        case 'Instagram':
          if (
            files[0] &&
            files[0].type === 'image/gif' &&
            this.getInstagramSharingDetails.image.length > 1
          ) {
            this.alertMessage(
              'GIF file cannot be uploaded with other file formats.Either select GIF or other file formats.',
              'error'
            )
            return false
          }
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: indexPosition,
          })
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, true)
          break
      }

      const stateObject = this
      const ajax = new XMLHttpRequest()

      ajax.upload.addEventListener('progress', function (progressEvent) {
        stateObject.sharingImageUploadProgressHandler(
          progressEvent,
          type,
          threadedTweetIndex
        )
      })

      ajax.addEventListener('load', function (loadEvent) {
        stateObject.sharingImageUpdateCompleteHandler(
          loadEvent,
          type,
          indexPosition,
          threadedTweetIndex
        )
      })

      ajax.addEventListener('error', function (loadEvent) {
        stateObject.sharingImageUploadErrorHandler(
          loadEvent,
          type,
          threadedTweetIndex
        )
      })

      ajax.open('POST', uploadMediaAssetUrl)
      ajax.setRequestHeader('Authorization', 'Bearer ' + this.getJWTToken)
      ajax.send(formData)

      event.target.value = ''
    },

    replaceSharingImageConfirmation(
      confirmation,
      details,
      threadedTweetIndex = 0
    ) {
      console.debug(
        'Method:replaceSharingImageConfirmation',
        confirmation,
        details
      )

      if (confirmation.status) {
        if (details.type === 'Common') {
          this.setCommonSharingImage([])
          this.setCommonSharingPreview(null)
          this.SET_COMMON_SHARING_VIDEO(null)
          this.setUploadCommonSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        } else if (details.type === 'Facebook') {
          this.setFacebookSharingImage([])
          this.setFacebookSharingPreview(null)
          this.SET_FACEBOOK_SHARING_VIDEO(null)
          this.setUploadFacebookSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        } else if (details.type === 'Twitter') {
          this.setTwitterSharingImage([])
          this.setTwitterSharingPreview(null)
          this.SET_TWITTER_SHARING_VIDEO(null)
          this.setUploadTwitterSharingImageLoader({
            status: true,
            position: -1,
          })
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        } else if (details.type === 'ThreadedTweets') {
          this.setThreadedTweetsSharingImage({
            image: [],
            index: threadedTweetIndex,
          })
          // this.setThreadedTweetsSharingPreview(null)
          this.setThreadedTweetsSharingVideo({
            video: null,
            index: threadedTweetIndex,
          })
          this.setUploadThreadedTweetsSharingImageLoader({
            loader: {
              status: true,
              position: -1,
            },
            index: threadedTweetIndex,
          })
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        } else if (details.type === 'Linkedin') {
          this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
          this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
          this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: true, position: -1 }
          )
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        } else if (details.type === 'Instagram') {
          this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
          this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, null)
          this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: true,
            position: -1,
          })
          this.processSharingImageUpload(
            details.event,
            details.formData,
            details.type
          )
        }
      } else {
        details.event.target.value = ''
      }

      $('#' + confirmation.type).modal('hide')
    },

    triggerSharingImageEnhance(type, index, threadedTweetIndex = 0) {
      console.debug(
        'Method:triggerSharingImageEnhance',
        type,
        index,
        threadedTweetIndex
      )

      let image = ''
      switch (type) {
        case 'Blog':
          image = this.getBlogPostingDetails.image.link
          break
        case 'Common':
        case 'Facebook':
        case 'Twitter':
        case 'Linkedin':
        case 'Instagram':
          // making getters dynamic 'get' + type + 'SharingDetails' if type is Facebook then the getter will be getFacebookSharingDetails
          image = this['get' + type + 'SharingDetails'].image[index]
          break
        case 'ThreadedTweets':
          image =
            this.getThreadedTweetsSharingDetails[threadedTweetIndex].image[
              index
            ]
          break
        case 'Pinterest':
        case 'Tumblr':
        case 'Gmb':
          image = this['get' + type + 'SharingDetails'].image
          break
      }
      // Old version implementation.
      /* EventBus.$emit('enhanceImage', { type: type, image: image, index: index })
      this.$bvModal.show('doka-editor-modal') */

      // New Version Implementation.
      EventBus.$emit('enhanceImagePintura', {
        type,
        image,
        index,
        threadedTweetIndex,
        modalVisible: true,
      })
    },

    // file upload handlers start

    sharingImageUploadProgressHandler(event, type, threadedTweetIndex = 0) {
      console.debug('Method:sharingImageUploadProgressHandler', event)

      let percent = (event.loaded / event.total) * 100
      percent = Math.round(percent)

      console.debug('Method:sharingImageUploadProgressHandler', percent)

      switch (type) {
        case 'Common':
          this.setCommonImagePercentage(percent)
          break
        case 'Facebook':
          this.setFacebookImagePercentage(percent)
          break
        case 'Twitter':
          this.setTwitterImagePercentage(percent)
          break
        case 'ThreadedTweets':
          this.setThreadedTweetsImagePercentage({
            percent,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_IMAGE_PERCENTAGE,
            percent
          )
          break
        case 'Pinterest':
          this.setPinterestImagePercentage(percent)
          break
        case 'Tumblr':
          this.setTumblrImagePercentage(percent)
          break
        case 'Instagram':
          this.$store.commit(
            instagramTypes.SET_SHARING_IMAGE_PERCENTAGE,
            percent
          )
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_SHARING_IMAGE_PERCENTAGE, percent)
          break
      }
    },

    sharingImageUploadCompleteHandler(event, type, threadedTweetIndex = 0) {
      console.debug(
        'Method:sharingImageUploadCompleteHandler',
        event,
        type,
        threadedTweetIndex
      )

      try {
        let response = event.target.responseText
        response = JSON.parse(response)
        response.media = response.media_container

        if (response.status === true) {
          this.$store.dispatch('updateSocialSharingMedia', response.media)
          switch (type) {
            case 'Common':
              const social = this.getCommonSharingDetails
              $(response.media).each(function (index, el) {
                social.image.push(el.link)
              })
              break
            case 'Facebook':
              const facebook = this.getFacebookSharingDetails
              $(response.media).each(function (index, el) {
                facebook.image.push(el.link)
              })
              break
            case 'Twitter':
              const twitter = this.getTwitterSharingDetails
              $(response.media).each(function (index, el) {
                if (twitter.image.length > 3) {
                  twitter.image.pop()
                }

                twitter.image.push(el.link)
              })
              break
            case 'ThreadedTweets':
              const threadedTweet = JSON.parse(
                JSON.stringify(
                  this.getThreadedTweetsSharingDetails[threadedTweetIndex]
                )
              )
              console.debug('ThreadedTweets', threadedTweet, threadedTweetIndex)
              $(response.media).each(function (index, el) {
                if (threadedTweet.image.length > 3) {
                  threadedTweet.image.pop()
                }

                threadedTweet.image.push(el.link)
              })
              this.setThreadedTweetsSharingImage({
                image: threadedTweet.image,
                index: threadedTweetIndex,
              })
              break
            case 'Linkedin':
              const linkedin = this.getLinkedinSharingDetails
              $(response.media).each(function (index, el) {
                if (linkedin.image.length > 8) linkedin.image.pop()
                linkedin.image.push(el.link)
              })
              break
            case 'Pinterest':
              this.setPinterestSharingImage(response.media[0].link)
              break
            case 'Tumblr':
              this.setTumblrSharingImage(response.media[0].link)
              break
            case 'Instagram':
              const details = this.getInstagramSharingDetails
              $(response.media).each(function (index, el) {
                if (details.image.length > 9) details.image.pop()
                details.image.push(el.link)
              })
              break
            case 'Gmb':
              this.$store.commit(gmb.SET_SHARING_IMAGE, response.media[0].link)
              break
          }
        } else if (response.storageFull) {
          this.setMediaStorageLimit(response.usedStorage)
          this.$bvModal.show('media-storage-limits-exceeded-modal')
        } else {
          this.alertMessage(response.message, 'error')
        }
      } catch (e) {
        console.debug('Exception::sharingImageUploadCompleteHandler', e)
        this.alertMessage('Unable to upload image(s).', 'error')
      }

      switch (type) {
        case 'Common':
          this.setUploadCommonSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setCommonSharingNumberOfImages(0)
          this.setCommonImagePercentage(0)
          break
        case 'Facebook':
          this.setUploadFacebookSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setFacebookSharingNumberOfImages(0)
          this.setFacebookImagePercentage(0)
          break
        case 'Twitter':
          this.setUploadTwitterSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setTwitterImagePercentage(0)
          this.setTwitterSharingNumberOfImages(0)
          break
        case 'ThreadedTweets':
          this.setUploadThreadedTweetsSharingImageLoader({
            loader: {
              status: false,
              position: -1,
            },
            index: threadedTweetIndex,
          })
          this.setThreadedTweetsImagePercentage({
            percent: 0,
            index: threadedTweetIndex,
          })
          this.setThreadedTweetsSharingNumberOfImages({
            images: 0,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: false, position: -1 }
          )
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_IMAGE_PERCENTAGE,
            0
          )
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_NUMBER_OF_IMAGES,
            0
          )
          break
        case 'Pinterest':
          this.setUploadPinterestSharingImageLoader(false)
          this.setPinterestImagePercentage(0)
          break
        case 'Tumblr':
          this.setUploadTumblrSharingImageLoader(false)
          this.setTumblrImagePercentage(0)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: false,
            position: -1,
          })
          this.$store.commit(instagramTypes.SET_SHARING_IMAGE_PERCENTAGE, 0)
          this.$store.commit(instagramTypes.SET_SHARING_NUMBER_OF_IMAGES, 0)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
          this.$store.commit(gmb.SET_SHARING_IMAGE_PERCENTAGE, 0)
          break
      }
    },

    sharingImageUpdateCompleteHandler(
      event,
      type,
      indexPosition,
      threadedTweetIndex = 0
    ) {
      console.debug(
        'Method:sharingImageUpdateCompleteHandler',
        threadedTweetIndex
      )
      try {
        let response = event.target.responseText
        response = JSON.parse(response)
        response.media = response.media_container

        if (response.status === true) {
          this.$store.dispatch('updateSocialSharingMedia', response.media)
          switch (type) {
            case 'Common':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'Common'
              )
              break
            case 'Facebook':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'Facebook'
              )
              break
            case 'Twitter':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'Twitter'
              )
              break
            case 'ThreadedTweets':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'ThreadedTweets',
                threadedTweetIndex
              )
              break
            case 'Linkedin':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'Linkedin'
              )
              break
            case 'Pinterest':
              this.setPinterestSharingImage(response.media[0].link)
              break
            case 'Tumblr':
              this.setTumblrSharingImage(response.media[0].link)
              break
            case 'Instagram':
              this.updateSharingImage(
                response.media[0].link,
                indexPosition,
                'Instagram'
              )
              break
            case 'Gmb':
              this.$store.commit(gmb.SET_SHARING_IMAGE, response.media[0].link)
              break
          }
        } else {
          this.alertMessage(response.data.message, 'error')
        }
      } catch (e) {
        console.debug('Exception::sharingImageUpdateCompleteHandler', e)
        this.alertMessage('Unable to upload image.', 'error')
      }

      switch (type) {
        case 'Common':
          this.setUploadCommonSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setCommonSharingNumberOfImages(0)
          this.setCommonImagePercentage(0)
          break
        case 'Facebook':
          this.setUploadFacebookSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setFacebookSharingNumberOfImages(0)
          this.setFacebookImagePercentage(0)
          break
        case 'Twitter':
          this.setUploadTwitterSharingImageLoader({
            status: false,
            position: -1,
          })
          this.setTwitterImagePercentage(0)
          this.setTwitterSharingNumberOfImages(0)
          break
        case 'ThreadedTweets':
          this.setUploadThreadedTweetsSharingImageLoader({
            loader: {
              status: false,
              position: -1,
            },
            index: threadedTweetIndex,
          })
          this.setThreadedTweetsImagePercentage({
            percent: 0,
            index: threadedTweetIndex,
          })
          this.setTwitterSharingNumberOfImages({
            images: 0,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: false, position: -1 }
          )
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_IMAGE_PERCENTAGE,
            0
          )
          this.$store.commit(
            linkedinSharingTypes.SET_SHARING_NUMBER_OF_IMAGES,
            0
          )
          break
        case 'Pinterest':
          this.setUploadPinterestSharingImageLoader(false)
          this.setPinterestImagePercentage(0)
          break
        case 'Tumblr':
          this.setUploadTumblrSharingImageLoader(false)
          this.setTumblrImagePercentage(0)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: false,
            position: -1,
          })
          this.$store.commit(instagramTypes.SET_SHARING_IMAGE_PERCENTAGE, 0)
          this.$store.commit(instagramTypes.SET_SHARING_NUMBER_OF_IMAGES, 0)
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
          this.$store.commit(gmb.SET_SHARING_IMAGE_PERCENTAGE, 0)
          break
      }
    },

    sharingImageUploadErrorHandler(event, type, threadedTweetIndex = 0) {
      console.debug('Method:sharingImageUploadErrorHandler')

      this.alertMessage(UNKNOWN_ERROR, 'error')

      switch (type) {
        case 'Common':
          this.setUploadCommonSharingImageLoader({
            status: false,
            position: -1,
          })
          break
        case 'Facebook':
          this.setUploadFacebookSharingImageLoader({
            status: false,
            position: -1,
          })
          break
        case 'Twitter':
          this.setUploadTwitterSharingImageLoader({
            status: false,
            position: -1,
          })
          break
        case 'ThreadedTweets':
          this.setUploadThreadedTweetsSharingImageLoader({
            loader: {
              status: false,
              position: -1,
            },
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          this.$store.commit(
            linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
            { status: false, position: -1 }
          )
          break
        case 'Pinterest':
          this.setUploadPinterestSharingImageLoader(false)
          break
        case 'Tumblr':
          this.setUploadTumblrSharingImageLoader(false)
          break
        case 'Instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status: false,
            position: -1,
          })
          break
        case 'Gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, false)
          break
      }
    },

    /**
     * Changing loader status of image upload base on the parameters passed
     * @param type
     * @param status
     * @param position
     */
    changeSharingImageUploadLoader(type, status, position) {
      switch (type.toLowerCase()) {
        case 'blog':
          this.$store.commit(
              blogPostingTypes.SET_UPLOAD_POSTING_IMAGE_LOADER,
              status
          )
          break
        case 'common':
          this.$store.commit('setUploadCommonSharingImageLoader', {
            status,
            position,
          })
          break
        case 'facebook':
          this.$store.commit('setUploadFacebookSharingImageLoader', {
            status,
            position,
          })
          break
        case 'twitter':
          this.$store.commit('setUploadTwitterSharingImageLoader', {
            status,
            position,
          })
          break
        case 'threadedtweets':
        case 'threaded-tweet':
          this.$store.commit('setUploadThreadedTweetsSharingImageLoader', {
            status,
            position,
          })
          break
        case 'linkedin':
          this.$store.commit(
              linkedinSharingTypes.SET_UPLOAD_SHARING_IMAGE_LOADER,
              {status, position}
          )
          break
        case 'pinterest':
          this.$store.commit('setUploadPinterestSharingImageLoader', status)
          break
        case 'tumblr':
          this.$store.commit('setUploadTumblrSharingImageLoader', status)
          break
        case 'instagram':
          this.$store.commit(instagramTypes.SET_UPLOAD_SHARING_IMAGE_LOADER, {
            status,
            position,
          })
          break
        case 'gmb':
          this.$store.commit(gmb.SET_UPLOAD_SHARING_IMAGE_LOADER, status)
          break
      }
    },
  },
}
