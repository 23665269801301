<template>
  <div
    v-if="
      getSocialSharingSelection.mentionsSuggestions.dropdown.status &&
      (type === 'Common' ||
        (type === 'Facebook' &&
          (getPublishLoaders.fetchFacebookMentionsSuggestions ||
            (getSocialSharingSelection.mentionsSuggestions.facebook &&
              getSocialSharingSelection.mentionsSuggestions.facebook
                .length))) ||
        (type === 'Twitter' &&
          (getPublishLoaders.fetchTwitterMentionsSuggestions ||
            (getSocialSharingSelection.mentionsSuggestions.twitter &&
              getSocialSharingSelection.mentionsSuggestions.twitter.length))))
    "
    class="social_mention_dropdown"
    :class="{
      move_left:
        getSocialSharingSelection.mentionsSuggestions.dropdown.left > 530,
    }"
    :style="{
      left: getSocialSharingSelection.mentionsSuggestions.dropdown.left + 'px',
      top: getSocialSharingSelection.mentionsSuggestions.dropdown.top + 'px',
    }"
  >
    <div
      v-if="type === 'Common'"
      class="close_this top_heading d-flex align-items-center"
    >
      <div
        class="head_left"
        :class="{
          active:
            getSocialSharingSelection.mentionsSuggestions.type === 'Facebook',
        }"
        @click="fetchMentionSuggestions('Facebook')"
      >
        <p>Facebook</p>
      </div>
      <div
        class="head_right ml-auto"
        :class="{
          active:
            getSocialSharingSelection.mentionsSuggestions.type === 'Twitter',
        }"
        @click="fetchMentionSuggestions('Twitter')"
      >
        <p>X (Twitter)</p>
      </div>
      <!--<div class="head_right ml-auto" :class="{'active' : getSocialSharingSelection.mentionsSuggestions.type === 'Linkedin' }"-->
      <!--@click="fetchMentionSuggestions('Linkedin')">-->
      <!--<p>Linkedin</p>-->
      <!--</div>-->
    </div>

    <ul
      v-if="
        type === 'Common' &&
        ((getSocialSharingSelection.mentionsSuggestions.type === 'Facebook' &&
          getPublishLoaders.fetchFacebookMentionsSuggestions) ||
          (getSocialSharingSelection.mentionsSuggestions.type === 'Twitter' &&
            getPublishLoaders.fetchTwitterMentionsSuggestions))
      "
      class="inner"
    >
      <li class="no_text">
        <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
      </li>
    </ul>
    <ul
      v-else-if="
        type === 'Facebook' &&
        getPublishLoaders.fetchFacebookMentionsSuggestions
      "
      class="inner"
    >
      <li class="no_text">
        <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
      </li>
    </ul>
    <ul
      v-else-if="
        type === 'Twitter' && getPublishLoaders.fetchTwitterMentionsSuggestions
      "
      class="inner"
    >
      <li class="no_text">
        <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
      </li>
    </ul>
    <ul
      v-else-if="
        type === 'Linkedin' &&
        getPublishLoaders.fetchLinkedinMentionsSuggestions
      "
      class="inner"
    >
      <li class="no_text">
        <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
      </li>
    </ul>
    <template v-else>
      <ul
        v-if="
          (getSocialSharingSelection.mentionsSuggestions.type === 'Facebook' &&
            type === 'Common') ||
          type === 'Facebook'
        "
        class="inner"
      >
        <template
          v-for="suggestion in getSocialSharingSelection.mentionsSuggestions
            .facebook"
        >
          <li
            v-if="suggestion.name"
            @click="addSharingMention(removeUsername(suggestion), type)"
          >
            <div class="profile_picture d-flex align-items-center">
              <div class="picture_block picture_block_background">
                <div
                  v-if="
                    suggestion.picture &&
                    suggestion.picture.data &&
                    suggestion.picture.data.url
                  "
                  class="img"
                  :style="{
                    background: 'url(' + suggestion.picture.data.url + ')',
                  }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>

              <div class="text_block padding-left-ten">
                <div class="text">
                  <p class="text_left"
                    >{{ suggestion.name
                    }}<i
                      v-if="suggestion.verification_status === 'blue_verified'"
                      class="fa fa-check-circle facebook verified_icon"
                      aria-hidden="true"
                    ></i
                  ></p>
                </div>
                <div class="sub_text">
                  <p v-if="suggestion.username" class=""
                    >@{{ suggestion.username }}</p
                  >
                  <p v-else class="sub_text"
                    >@{{ facebookUsernameFromLink(suggestion.link) }}</p
                  >
                  <p v-if="suggestion.fan_count" class="followers"
                    >Followers
                    <span>{{ intToString(suggestion.fan_count) }}</span></p
                  >
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>

      <ul
        v-else-if="
          getSocialSharingSelection.mentionsSuggestions.type === 'Twitter' ||
          type === 'Twitter'
        "
        class="inner"
      >
        <template
          v-for="suggestion in getSocialSharingSelection.mentionsSuggestions
            .twitter"
        >
          <li
            v-if="suggestion.name"
            @click="addSharingMention(suggestion, type)"
          >
            <div class="profile_picture">
              <div class="text_block">
                <div class="text">
                  <p class="text_left"
                    >{{ suggestion.name
                    }}<i
                      v-if="suggestion.is_verified"
                      class="fa fa-check-circle twitter verified_icon"
                      aria-hidden="true"
                    ></i
                  ></p>
                </div>
                <div class="sub_text">
                  <p class="">@{{ suggestion.username }}</p>
                  <p v-if="suggestion.followers_count" class="followers"
                    >Followers
                    <span>{{
                      intToString(suggestion.followers_count)
                    }}</span></p
                  >
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sharingTypes } from '../../../../store/states/mutation-types'

export default {
  components: {},
  props: ['type'],

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters([
      'getSocialSharingSelection',
      'getPublishLoaders',
      'getFacebookAccounts',
      'getTwitterAccounts'
    ])
  },
  methods: {
    removeUsername (suggestion) {
      if (suggestion.hasOwnProperty('username')) delete suggestion.username
      return suggestion
    }
  },

  watch: {
    'getSocialSharingSelection.mentionsSuggestions.dropdown.left' (value) {
      if (value > 530) {
        value = value - 150
        this.$store.commit(
          sharingTypes.SET_MENTIONS_DROPDOWN_LEFT_POSITION,
          value
        )
      }
    }
  }
}
</script>
