import shortener from './states/shortener'

import platforms from './states/platforms/platforms'

import blog from './states/platforms/blog'

// social states
import social from './states/platforms/social/social'
import facebook from './states/platforms/social/facebook'
import twitter from './states/platforms/social/twitter'
import pinterest from './states/platforms/social/pinterest'
import linkedin from './states/platforms/social/linkedin'
import tumblr from './states/platforms/social/tumblr'
import instagram from './states/platforms/social/instagram'
import gmb from './states/platforms/social/gmb'
import youtube from './states/platforms/social/youtube'
import tiktok from './states/platforms/social/tiktok'
import threads from './states/platforms/social/threads'

// rewriter states
import rewriter from './states/rewriter'
import integrations from './states/integration'

const shortenerStore = {
  modules: {
    shortener
  }
}

export const socialStore = {
  modules: {
    social,
    facebook,
    twitter,
    pinterest,
    linkedin,
    tumblr,
    instagram,
    gmb,
    youtube,
    tiktok,
    threads
  }
}
export const platformsStore = {
  modules: {
    platforms,
    blog,
    social: socialStore
  }
}
export const integrationStore = {
  modules: {
    platforms: platformsStore,
    rewriter,
    shortener: shortenerStore,
    integrations
  }
}
