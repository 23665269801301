<template>
  <div class="input_field">
    <div class="multi_select">
      <div class="d-flex">
        <label for="" class="sub_label"
          ><span class="bold">EXCLUDE</span> results from these domains</label
        >
        <button
          v-if="
            getTopicSelection.exclude_domains.length > 0 ||
            excludeDomainsValue.replace(/^\s+/, '') !== ''
          "
          class="reset_btn"
          @click="clearAllKeywords"
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <input
        v-model="excludeDomainsValue"
        class="prevent-listener"
        type="text"
        placeholder="Enter domains that you think are giving irrelevant results, e.g. careers.com…"
        data-cy="exclude-domain"
        @input="asyncExcludeDomainResults(excludeDomainsValue)"
        @keyup.enter="
          addAnyKeyword(
            getTopicSelection.exclude_domains,
            excludeDomainsValue,
            'exclude_domains',
            $event
          )
        "
        @keyup.188="
          addAnyKeyword(
            getTopicSelection.exclude_domains,
            excludeDomainsValue,
            'exclude_domains',
            $event
          )
        "
      />
      <div class="multi_input">
        <div
          v-if="isExcludeDomainsLoading && excludeDomainsValue.length >= 3"
          v-click-away="closeDropdown"
          class="multi_dropdown"
        >
          <div class="multi_dropdown_inner">
            <ul>
              <!--<transition-group name="slide-bottom">-->
              <!--:key="suggested_domain"-->
              <li
                v-for="(
                  suggested_domain, index
                ) in exclude_domains_suggestion_list"
                class="item d-flex align-items-center"
                @click.prevent="addExcludeDomain(suggested_domain, index)"
              >
                <span
                  v-if="!suggested_domain.is_parent"
                  class="icon world_icon_size"
                >
                  <i class="cs-rss"></i>
                </span>
                <span v-else-if="suggested_domain.favicon_16" class="icon">
                  <img :src="suggested_domain.favicon_16" alt="" />
                </span>
                <span v-else class="icon world_icon_size">
                  <i class="cs-world-o"></i>
                </span>
                <span class="text">
                  <span class="heading">{{ suggested_domain.name }}</span>
                  <span
                    class="url"
                    v-html="
                      highlightText(suggested_domain.url, excludeDomainsValue)
                    "
                  ></span>
                </span>
                <span class="view">
                  <i class="fa fa-eye"></i>
                </span>
              </li>
              <!--</transition-group>-->
            </ul>
          </div>
        </div>
        <div
          v-else-if="excludeDomainsLoader"
          v-click-away="closeDropdown"
          class="multi_dropdown"
        >
          <div
            class="
              multi_dropdown_inner
              min_height
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
      <template v-if="getTopicSelection.exclude_domains">
        <div class="multi_tags">
          <ul>
            <li
              v-for="(
                must_exclude_keyword, index
              ) in getTopicSelection.exclude_domains"
              class="red"
            >
              <span class="value">{{ must_exclude_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(getTopicSelection.exclude_domains, index)
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isExcludeDomainsLoading: false,
      excludeDomainsValue: '',
      exclude_domains_suggestion_list: [],
      excludeDomainsLoader: false,
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection']),
  },
  watch: {
    excludeDomainsValue(value) {
      if (value.length == 0) {
        this.exclude_domains_suggestion_list = []
      }
    },
  },
  methods: {
    closeDropdown() {
      this.isExcludeDomainsLoading = false
    },
    clearAllKeywords() {
      this.excludeDomainsValue = ''
      this.getTopicSelection.exclude_domains = []
      this.excludeDomainsLoader = false
    },
  },
}
</script>
