<template>
  <div
      v-if="post._source"
      class="article-list-view-container__inner"
      :class="{ shared: post._source.shared }"
  >
    <div class="article-list-view-container__inner__image_section">
      <div
          v-if="post._source.categories"
          class="article-list-view-container__inner__categories"
      >
        <span v-for="category in post._source.categories" class="tag capitalize">{{
            category
          }}</span>
      </div>
      <div class="article-list-view-container__inner__article_type">
        <span v-if="post._source.read_time && post._source.read_time === 1">
          Less than 1 Min. Read
        </span>
        <span v-if="post._source.read_time && post._source.read_time > 1">
          {{ post._source.read_time }} Min. Read
        </span>
        - <span v-if="post._source.has_abstract">Abstract Content</span>
        <span v-else>Full Content</span>
      </div>

  <div
           :style="{
      backgroundImage: `url('${getResizedImageURL(
      backgroundImageURL(post._source))}')`,
      }"
       class="article-list-view-container__inner__image"
   ></div>
      <div
          v-if="post._source.shared && post._source.shared_count"
          class="article-list-view-container__inner__shared"
      >
        <span>
          Shared to {{ post._source.shared_count }}
          <template v-if="post._source.shared_count === 1">channel</template>
          <template v-else>channels</template>
        </span>
      </div>
    </div>
    <div class="article-list-view-container__inner__content_section">
      <div
          class="article-list-view-container__inner__content_section__left_section"
      >
        <h2
            :title="limitTextLength(post._source.title, 0)"
            @click.prevent="searchWebPreview"
            v-html="limitTextLength(post._source.title, 100)"
        ></h2>
        <div
            class="clear article-list-view-container__inner__content_section__left_section__details"
        >
          <p class="text_row">
            <a
                v-tooltip="'View articles from this domain'"
                href="javascript:;"
                @click.prevent="
                changeRouteByDomain(trimURL(post._source.domain_url))
              "
            >{{ trimURL(post._source.domain_url) }}</a
            >
            <!--<a class="left_link" href="">Insight</a> <a class="left_link" href="">All Articles</a>
            <a class="left_link" href="">Block Globally</a>-->
          </p>
          <p class="text_row">{{ $filters.relative(post._source.post_date) }}</p>
          <p
              class="article-list-view-container__inner__content_section__left_section__author_information"
          >
            <template v-if="post._source.author">
              By
              <span
                  v-tooltip="'View articles from this author'"
                  style="color: #4e92ff"
                  @click="
                  changeRouteForAuthor(
                    post._source.author,
                    trimURL(post._source.domain_url),
                  )
                "
                  v-html="post._source.author"
              ></span>
              <template v-for="(account, twitterAccountIndex) in post._source.twitter_accounts">
                <a
                    v-if="post._source.twitter_accounts && twitterAccountIndex < 1"
                    :key="`twitterAccount_${twitterAccountIndex}`"
                    @click.prevent="openTwitterLinkForInfluencer(account)"
                >
                  {{ account }}
                </a>

                <a
                    v-else-if="post._source.twitter_handler"
                    :key="`twitterHandler_${twitterAccountIndex}`"
                    target="_blank"
                    :href="getTwitterLink(post._source.twitter_handler)"
                >
                  {{ post._source.twitter_handler }}
                </a>
              </template>
              <clip-loader
                  v-if="loader"
                  class="d-inline-block ml-1"
                  :color="'#436aff'"
                  :size="'12px'"
              ></clip-loader>
            </template>
            <template v-else-if="post._source.twitter_handler">
              By
              <a
                  target="_blank"
                  :href="getTwitterLink(post._source.twitter_handler)"
              >{{ post._source.twitter_handler }}</a
              >
            </template>
            <template
                v-else-if="
                post._source.twitter_accounts &&
                post._source.twitter_accounts.length > 0
              "
            >
              By
              <a
                  v-for="(account, accountsIndex) in post._source.twitter_accounts"
                  :key="`accountsIndex_${accountsIndex}`"
                  @click.prevent="openTwitterLinkForInfluencer(account)"
              ><template v-if="accountsIndex < 1">{{ account }}</template></a
              >
              <clip-loader
                  v-if="loader"
                  class="d-inline-block ml-1"
                  :color="'#436aff'"
                  :size="'12px'"
              ></clip-loader>
            </template>
          </p>
          <div
              v-if="isTopicsDisplayAllowed(post)"
              class="article-list-view-container__inner__content_section__left_section__topics"
          >
            <template
                v-for="(item, allowedTopicsIndex) in post._source.topics"
                :key="`allowedTopics_${allowedTopicsIndex}`"
            >
              <span
                  v-for="(finalized_topic, finalizedTopicsIndex) in checkIfMultipleTopics(item.label)"
                  :key="`finalizedTopics_${finalizedTopicsIndex}`"
                  v-tooltip="'View articles from this topic'"
                  class="topic_tag"
              >
                {{ finalized_topic }}
              </span>
              <!--@click.prevent="changeTopicRouteLabel(item, finalized_topic)"-->
            </template>
          </div>

          <div
              v-if="post._source.top_twitter_influencers"
              class="article-list-view-top-influencers"
          >
            <div
                v-if="post._source.total_influencers_shares"
                class="article-list-view-top-influencers__left"
            >
              <p>
                <i class="icon-profile-cs mr-2"></i> Shared by
                <strong>{{
                    $filters.numberToCommas(post._source.total_influencers_shares)
                  }}</strong>
                influencers</p
              >
            </div>
            <div
                v-if="post._source.total_influencers_shares > 0"
                class="ml-3"
                @click.prevent="influencersWebPreview"
            >
              <template
                  v-for="(influencer, index) in post._source
                  .top_twitter_influencers"
              >
                <!--                v-if="index <= 3"-->
                <!--                v-if="influencer.follower_count > 1000"-->
                <a
                    v-tooltip="{
                    content: accountTooltipHtml('influencer', influencer),
                    classes: 'team_tooltip',
                  }"
                    target="_blank"
                    :href="'https://twitter.com/' + influencer.screen_name"
                >
                  <div
                      v-if="influencer.profile_image"
                      class="img"
                      :style="{
                      'background-image': getBackgroundURLWithDefault(
                        influencer.profile_image,
                      ),
                    }"
                  ></div>
                  <div
                      v-else
                      class="img"
                      style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
              <!--              <div @click.prevent="influencersWebPreview()" class="rounded_more"-->
              <!--                   v-if="post._source.top_twitter_influencers.length > 3 && post._source.total_influencers_shares - 4 > 0">-->
              <!--                +{{post._source.total_influencers_shares - 4}}-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!--<div class="similar_content"
             @click.prevent="retrieveSimilarArticles(post._source.id, post._index)"
             v-if="post._source.related_ids_count > 1">{{post._source.related_ids_count}}
            similar articles
        </div>-->
      </div>
      <div
          class="article-list-view-container__inner__content_section__right_section"
      >
        <div
            class="article-list-view-container__inner__content_section__right_section__signals"
        >
          <div
              class="article-list-view-container__inner__content_section__right_section__signals__inner"
          >
            <div
                class="popover-list-view-social-statistics article-list-view-container__inner__content_section__right_section__signals__inner__item"
            >
              <div class="name">
                <p>Engagement</p>
              </div>
              <div class="value">
                <h3>{{ intToString(calculateEngagements(post._source)) }}</h3>
              </div>
              <div
                  class="popover-list-view-social-statistics__container top_center"
              >
                <div
                    class="popover-list-view-social-statistics__container__box"
                >
                  <div
                      class="popover-list-view-social-statistics__container__box__inner"
                  >
                    <div class="d-flex align-items-center">
                      <div
                          v-tooltip.top="'Facebook Shares'"
                          class="popover-list-view-social-statistics__container__box__inner__item"
                      >
                        <span class="icon_item">
                          <i class="fb cs-facebook"></i>
                        </span>
                        <span v-if="post._source.facebook_shares">
                          {{ intToString(post._source.facebook_shares) }}</span
                        >
                        <span v-else>0</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
                v-if="post._source.velocity && post._source.velocity > 5"
                class="article-list-view-container__inner__content_section__right_section__signals__inner__item"
            >
              <div class="name">
                <p>Trending Score</p>
              </div>
              <div class="value">
                <h3>{{ intToString(post._source.velocity) }}</h3>
              </div>
            </div>
            <SentimentIcon
                :post="post"
                :class="'article-list-view-container__inner__content_section__right_section__signals__inner__item'"
            ></SentimentIcon>
          </div>
        </div>
        <div
            class="article-list-view-container__inner__content_section__right_section__action_buttons"
        >
          <button
              id="dropdown-archive-articles"
              class="btn btn-studio-icon-theme post_archi_btn"
          >
            <i
                v-tooltip="'Social Media Share'"
                class="far fa-share-alt icon"
                @click.prevent="
                initializeArticlePostCreation(
                  post._source.id,
                  'social_share_modal',
                )
              "
            ></i>
          </button>

          <button
              id="dropdown-archive-articles"
              class="btn btn-studio-icon-theme post_archi_btn"
          >
            <i
                v-tooltip="'Blog Post share'"
                class="far fa-rss"
                @click.prevent="
                initializeArticlePostCreation(
                  post._source.id,
                  'blog_share_modal',
                )
              "
            ></i>
          </button>

          <!--&lt;!&ndash; share buttons &ndash;&gt;-->
          <!--<b-dropdown  variant="studio-icon-theme" id="dropdown-share-articles" dropleft class="studio-icon-theme-dropdown mr-3" no-caret>-->
          <!--<template v-slot:button-content>-->
          <!--<i v-tooltip="'Share this post'" class="far fa-share-alt icon"></i>-->
          <!--</template>-->
          <!--<b-dropdown-item-->
          <!--variant="studio-icon-theme"-->
          <!--@click.prevent="initializeArticlePostCreation(post._source.id,'social_share_modal')"-->
          <!--&gt;<i class="far fa-share share-ico-align"></i> Social Media-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-item-->
          <!--variant="studio-icon-theme"-->
          <!--@click.prevent="initializeArticlePostCreation(post._source.id,'blog_share_modal')"-->
          <!--&gt;<i class="far fa-rss"></i> Blog Post-->
          <!--</b-dropdown-item>-->
          <!--</b-dropdown>-->

          <!-- favorite article button -->

          <button
              v-if="post._source.is_favorite"
              id="dropdown-archive-articles"
              class="btn btn-studio-icon-theme post_archi_btn"
          >
            <i
                v-tooltip="'Unfavorite'"
                class="far fa-heart icon is-favorite"
                @click="unfavoritePostItemEventFire(post._source.id)"
            ></i>
            <span
                :class="{ ' active': post._source.is_active }"
                class="icon_animate"
            ></span>
          </button>

          <b-dropdown
              v-else
              id="dropdown-favorite-articles"
              ref="dropdown_favorite"
              variant="studio-icon-theme"
              dropleft
              class="studio-icon-theme-dropdown mr-4 "
              no-caret
              block
          >
            <template v-slot:button-content>
              <i
                  v-tooltip="'Favorite'"
                  class="far fa-heart icon"
                  :class="{ 'animated tada': post._source.is_active }"
              ></i>
            </template>
            <BDropdownFavoriteArticle
                v-show="!post._source.is_favorite"
                :post_id="post._source.id"
            />
          </b-dropdown>

          <!-- archive post button -->
          <!--          <b-dropdown variant="studio-icon-theme" id="dropdown-archive-articles" dropleft class="studio-icon-theme-dropdown mr-3" no-caret block-->
          <!--          >-->
          <!--            <template v-slot:button-content>-->
          <!--              <template v-if="$route.name === 'discover_topics_archived'">-->
          <!--                <i @click="unarchivePostItemEventFire(post._source.id)" v-tooltip="'Unarchive Post'" class="far fa-archive icon is-archived"></i>-->
          <!--              </template>-->
          <!--              <template v-else>-->
          <!--                <i @click="archivePostItemEventFire(post._source.id)" v-tooltip="'Archive Post'" class="far fa-archive icon"></i>-->
          <!--              </template>-->

          <!--            </template>-->
          <!--          </b-dropdown>-->

          <template
              v-if="
              getAllAutomations.evergreen &&
              getAllAutomations.evergreen.length > 0
            "
          >
            <b-dropdown
                id="dropdown-actions-evergreen"
                variant="studio-icon-theme"
                dropleft
                class="studio-icon-theme-dropdown post_btn_rm"
                no-caret
            >
              <template v-slot:button-content>
                <i
                    v-tooltip="'Add post to Evergreen Automation'"
                    class="far fa-robot icon"
                ></i>
              </template>

              <b-dropdown-item
                  v-for="(automation, key) in getAllAutomations.evergreen"
                  :key="key"
                  variant="studio-icon-theme"
                  @click="addInEvergreenAutomation(post._source, automation._id)"
              >
                {{ automation.name }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-else>
            <button
                id="dropdown-archive-articles"
                class="btn btn-studio-icon-theme post_archi_btn"
                style=""
            >
              <template v-if="$route.name === 'discover_topics_archived'">
                <i
                    v-tooltip="'Unarchive Post'"
                    class="far fa-archive icon is-archived"
                    @click="unarchivePostItemEventFire(post._source.id)"
                ></i>
              </template>
              <template v-else>
                <i
                    v-tooltip="'Archive Post'"
                    class="far fa-archive icon"
                    @click="archivePostItemEventFire(post._source.id)"
                ></i>
              </template>
            </button>
          </template>

          <!-- more actions button-->
          <b-dropdown
              id="dropdown-actions-articles"
              variant="studio-icon-theme"
              dropleft
              class="studio-icon-theme-dropdown mr-4"
              no-caret
          >
            <template v-slot:button-content>
              <i class="cs-dots-h icon"></i>
            </template>

            <b-dropdown-item
                variant="studio-icon-theme"
                target="_blank"
                :href="savePocketContent(post._source.url)"
            ><i class="fab fa-get-pocket"></i> Add to Pocket
            </b-dropdown-item>

            <template
                v-if="
                getAllAutomations.evergreen &&
                getAllAutomations.evergreen.length > 0
              "
            >
              <template v-if="$route.name === 'discover_topics_archived'">
                <b-dropdown-item
                    variant="studio-icon-theme"
                    @click.prevent="unarchivePostItemEventFire(post._source.id)"
                ><i class="far fa-archive"></i> Unarchive Post
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item
                    variant="studio-icon-theme"
                    @click.prevent="
                    archivePostItemEventFire(post._source.id, 'archive')
                  "
                ><i class="far fa-archive"></i> Archive Post
                </b-dropdown-item>
              </template>
            </template>

            <b-dropdown-item
                variant="studio-icon-theme"
                @click.prevent="influencersWebPreview"
            ><i class="fa fa-users-crown"></i> View Influencers
            </b-dropdown-item>

            <!--            <b-dropdown-item-->
            <!--                v-if="$route.name === 'discover_topics_archived'"-->
            <!--                variant="studio-icon-theme"-->
            <!--                @click.prevent="unarchivePostItemEventFire(post._source.id)"-->
            <!--            ><i class="far fa-archive"></i> Unarchive-->
            <!--            </b-dropdown-item>-->
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {EventBus} from '@common/lib/event-bus'
import BDropdownFavoriteArticle from '../../folders/BDropdownFavoriteArticle'
import SharedCountTooltip from '../snippets/SharedCountTooltip'
import SentimentIcon from '../snippets/SentimentIcon'

export default {
  components: {
    SharedCountTooltip,
    SentimentIcon,
    BDropdownFavoriteArticle
  },
  props: {
    post: {},
    index: {}
  },
  data() {
    return {
      loader: false
    }
  },
  computed: {
    ...mapGetters(['getAllAutomations', 'getWorkspaces'])
  },
  methods: {
    ...mapActions([
      'archive',
      'unarchive',
      'articlePreview',
      'addEvergreenPost'
    ]),
    changeTopicRouteLabel(item, tag) {
      this.$router.push({
        name: 'topics_web',
        params: {module: 'curated', topicId: 'list', topicName: tag}
      })
    },
    changeRouteForAuthor(author, domain) {
      this.$router.push({
        name: 'discover_search_web',
        query: {q: `author:${author} AND site:${domain}`, view: 'list'}
      })
      this.$router.push({
        name: 'discover_search_web',
        query: {q: `author:'${author}'`, view: 'list'}
      })
    },
    changeRouteByDomain(domain) {
      this.$router.push({
        name: 'discover_search_web',
        query: {q: `site:${domain}`, view: 'list'}
      })
    },
    searchWebPreview() {
      this.articlePreview({ id: this.post._source.id, index: this.index, appContainer: this })
    },
    influencersWebPreview() {
      this.showSharers(this.post._source.id)
    },
    archivePostItemEventFire(postId) {
      EventBus.$emit('archive-post-item', {postId})
    },
    unarchivePostItemEventFire(postId) {
      EventBus.$emit('unarchive-post-item', {postId})
    },
    unfavoritePostItemEventFire(postId) {
      EventBus.$emit('unfavorite-post-item', {postId})
      // this.$refs.dropdown_favorite.toggle(false)
    },
    openTwitterLinkForInfluencer(account) {
      window.open(`https://twitter.com/${account}`, '_blank')
    },
    async addInEvergreenAutomation(post, automationId) {
      console.debug('before emit')
      await this.$store.dispatch('addEvergreenPost', {
        title: post.title,
        url: post.url,
        automation_id: automationId,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
