<template>
  <div
    v-if="!isWorkspaceOnHold"
    class="toggle_option align-items-center m-2 mt-3"
  >
    <div class="d-flex justify_space_between">
      <div class="d-flex justify_content_start">
        <label
          class="switch-radio"
          @change="changeSharingBoxOption"
          @click="showDisableMessage"
        >

          <input
            type="checkbox"
            :checked="!getCommonBoxOptions.status"
            :disabled="
              disableSharingBoxToggleOption ||
              getCommonBoxOptions.globalCategoryStatus
            "
          />
          <div class="slider round"></div>
        </label>
        <p class="mr-2 ml-2">Customize content for each channel</p>
      </div>

      <div class="flex-row text-right">
        <a
          v-if="showClearContentOption"
          class="clear_content_link"
          @click="clearContent"
          >Clear all content
          <i
            v-tooltip="
              'By clearing content, your content from all the social media message box will be removed.'
            "
            class="far fa-question-circle"
          ></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
import {
  facebookSharingTypes,
  twitterSharingTypes,
  linkedinSharingTypes,
  pinterestSharingTypes,
  tumblrSharingTypes,
  instagramSharingTypes,
  gmb,
  youtubeSharingTypes,
  tiktokSharingTypes,
  social
} from '../../../store/states/mutation-types'

export default {
  computed: {
    ...mapGetters(['getCommonBoxOptions', 'getWorkspaces']),
    isWorkspaceOnHold () {
      return !!(
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.on_hold
      )
    },
    showClearContentOption () {
      return !this.getCommonBoxOptions.status
    }
  },

  watch: {},

  methods: {
    showDisableMessage () {
      console.debug('Method:showDisableMessage')
      if (this.disableSharingBoxToggleOption) { this.alertMessage('Please select an account first.', 'error') }
    },
    async clearContent () {
      console.debug('Method:clearContent')
      // asking for confirmation
      const res = await this.$bvModal
        .msgBoxConfirm('Are you sure you want to clear all content?', {
          title: 'Clear all content',
          ...swalAttributes()
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return false
        })

      if (res) {
        // reseting box states
        this.$store.commit(facebookSharingTypes.RESET_FACEBOOK_SHARING_DETAILS)
        this.$store.commit(twitterSharingTypes.RESET_TWITTER_SHARING_DETAILS)
        this.$store.commit(linkedinSharingTypes.SET_DEFAULT_SHARING)
        this.$store.commit(
          pinterestSharingTypes.RESET_PINTEREST_SHARING_DETAILS
        )
        this.$store.commit(tumblrSharingTypes.RESET_TUMBLR_SHARING_DETAILS)
        this.$store.commit(
          instagramSharingTypes.RESET_INSTAGRAM_SHARING_DETAILS
        )
        this.$store.commit(youtubeSharingTypes.RESET_YOUTUBE_SHARING_DETAILS)
        this.$store.commit(tiktokSharingTypes.RESET_TIKTOK_SHARING_DETAILS)
        this.$store.commit(gmb.SET_DEFAULT_SHARING)
        this.$store.commit(social.SET_TWITTER_OPTIONS, null)
        this.$store.commit(social.RESET_CAROUSEL_OPTIONS)
        document.getElementById('facebook_sharing_message').value = ''
        document.getElementById('twitter_sharing_message').value = ''
        document.getElementById('linkedin_sharing_message').value = ''
        document.getElementById('pinterest_sharing_message').value = ''
        document.getElementById('tumblr_sharing_message').value = ''
        document.getElementById('instagram_sharing_message').value = ''
        document.getElementById('youtube_sharing_message').value = ''
        document.getElementById('gmb_sharing_message').value = ''
      }
    }
  }
}
</script>
