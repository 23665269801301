<template>
  <b-modal
    id="tabImagePreviewModal"
    modal-class="video_preview commentImagePreviewModal normal_modal"
    hide-footer
    hide-header
  >
    <div class="basic_form">
      <div class="modal_head d-flex align-items-center">
        <div class="flex gap-2 items-center">
          <h2>Preview</h2>
          <i
              v-b-tooltip.hover
              :title="`PDF and Video files cannot have previews before uploading.`"
              class="far fa-question-circle p-0 cursor-pointer"
            ></i>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="$bvModal.hide('tabImagePreviewModal')"
          >&times;</button
        >
      </div>
      <div class="modal_body">
        <div class="video_box_iframe">
          <div class="modal_inner clear text-center">
            <img class="lazy modal_image" :src="validSrc" alt="" />
            <!-- <img class="lazy modal_image" v-else src="https://storage.googleapis.com/lumotive-web-storage/comments/5c57ceb4d6c2b4000721e383/cQwFE1SOuMxAFAuSMWkN.png" alt=""> -->
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";

export default {
  data () {
    return {
      validSrc: ''
    }
  },
  mounted () {
    EventBus.$on('open-tab-media-preview', (item) => {
      this.validSrc = item
      this.$bvModal.show('tabImagePreviewModal')
    })
  }
}
</script>
