<template>
  <b-modal
    :id="modalId"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="workspace_limit_reached"
    hide-footer
    hide-header
  >
    <div class="modal_head">
      <h4>Subscription Limit Exceeded</h4>
      <button
        class="modal_head__close"
        type="button"
        @click="$bvModal.hide(modalId)"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <div v-if="isActiveUserSuperAdmin" class="content mt-5">
        <p
          >{{ getProfile.full_name }} your total workspace limit of
          <b>{{ total_workspaces_limit }}</b> has already been fully consumed.
        </p>
        <br />
        <p>
          Please remove a workspace or purchase workspace limits to make space
          for a new one
        </p>

        <div class="mt-5">
          <a
            v-if="isUserTrialPlan() && hasPermission('can_see_subscription')"
            class="btn btn-studio-theme-transparent mr-3"
            href="javascript:;"
            @click="showLimitModal"
            >Change Trial Plan</a
          >
          <a
            v-else
            class="btn btn-studio-theme-transparent mr-3"
            href="javascript:;"
            @click="showLimitModal"
            >Increase Limits</a
          >
          <a
            class="btn btn-studio-theme-transparent ml-auto"
            href="javascript:;"
            @click="manageLimits"
            >Manage Limits</a
          >
        </div>
      </div>
      <div v-else class="content">
        <p
          >The super admin {{ full_name }} has a total workspace limit of
          <b>{{ total_workspaces_limit }}</b> and it has been fully consumed.</p
        >
        <br />
        <p
          >Please get in touch with them at {{ email }} to either increase their
          workspace limit or remove a workspace to make space for a new one.</p
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'WorkspaceLimitsDialog',
  components: {},
  data() {
    return {
      modalId: 'workspace-limits-dialog',
      is_super_admin: '',
      full_name: '',
      total_workspaces_limit: '',
      email: '',
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getWorkspaces']),
    isActiveUserSuperAdmin() {
      const teamMembers = this.getWorkspaces.activeWorkspace.members
      if (teamMembers) {
        for (let i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (member.user && this.getProfile.email === member.user.email) {
            return member.role === 'super_admin'
          }
        }
      }

      return false
    },
  },
  created () {
    EventBus.$on('workspace-limit-reached', (data) => {
      this.is_super_admin = data.is_super_admin
      this.total_workspaces_limit = data.total_workspaces_limit
      this.full_name = data.full_name
      this.email = data.email
    })
  },
  beforeUnmount() {
    EventBus.$off('workspace-limit-reached')
  },
  methods: {
    manageLimits() {
      this.$bvModal.hide(this.modalId)
      this.$router.push({ name: 'manageLimits' })
    },
    showLimitModal() {
      this.$bvModal.hide(this.modalId)
      if (this.isUserTrialPlan() && this.hasPermission('can_see_subscription'))
        this.$bvModal.show('change-trial-plan-dialog')
      else this.$bvModal.show('increase-limits-dialog')
    },
  },
}
</script>

<style lang="scss">
.workspace_limit_reached {
  .modal-dialog {
    max-width: 50%;
    align-items: center;
    height: -webkit-fill-available;
    display: flex;
    @media (max-width: 1360px) {
      max-width: 60%;
    }
  }
  .modal_body {
    margin-bottom: 2rem;
    h1 {
      text-align: center;
      line-height: 7rem;
      font-size: 1.8rem;
      font-weight: 600;
    }
    .content {
      max-width: 80%;
      margin: auto;
      text-align: center;
      @media (max-width: 1360px) {
        max-width: 90%;
      }
      .action_btns {
        display: flex;
        align-items: center;
        padding: 3rem 0rem 0.5rem 0rem;
      }
    }
  }
}
</style>
