<template>
  <div class="filledReply">
    <div class="modal_body basic_form m_t_15">
      <div class="flex-row d-flex align-items-center">
        <div class="search_input with_icon_left w-100">
          <div class="search_inner w-100 cs-input-grey">
            <input
              v-model="searchTerm"
              type="text"
              placeholder="Find a saved reply ..."
            />
            <button class="search_btn">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
        <button
          class="btn loader m-2 createNewButton white-space-nowrap"
          @click="createNew"
        >
          <i class="fa fa-plus"></i><span>Create New </span>
        </button>
        <clip-loader
          v-if="isLoading"
          class="d-inline-block align-middle ml-1"
          style="margin-right: 0.625rem"
          color="#989eb5"
          :size="'30px'"
        />
      </div>

      <template v-if="!isLoading && filteredSavedReplies.length === 0">
        <div ref="no_save_reply" class="flex-row">
          <div class="flex-row text-center">
            <img
              src="../../../../assets/img/states/empty-inbox-reply.png"
              alt=""
            />
            <h3 v-if="isLoading">Loading Saved Replies ..</h3>
            <h3 v-else>No Saved Replies Found.</h3>

            <br />
            <div v-if="!isSearching">
              <button class="btn blue loader royal_blue m-4" @click="createNew">
                <span>Create New</span>
              </button>
              <br />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex-row savedRepliesItem">
          <div v-for="savedReply in filteredSavedReplies" :key="savedReply._id">
            <SavedReplyItem
              :saved-reply="savedReply"
              :selected-saved-reply="selectedSavedReply"
              :edit-existing-reply="editExistingReply"
              :delete-reply="deleteReply"
            ></SavedReplyItem>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import SavedReplyItem from './SavedReplyItem'

export default {
  components: {
    SavedReplyItem,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: 'savedReplyModal',
    },
    applySavedReply: {
      type: Function,
      default: () => {},
    },
    shouldShowCreateNew: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      editReply: false,
      editPayload: null,
      isLoading: false,
      savedReplies: [],
      filteredSavedReplies: [],
      searchTerm: null,
      isSearching: false,
      commonMethods,
    }
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {
    // filteredSavedReplies
    searchTerm: function (newVal, oldVal) {
      if (newVal !== '') {
        this.isSearching = true
        this.filteredSavedReplies = this.savedReplies.filter((item) => {
          // return newVal.toLowerCase() === item.title.toLowerCase()
          return item.title.toLowerCase().includes(newVal.toLowerCase())
        })
      } else {
        this.isSearching = false
        this.filteredSavedReplies = this.savedReplies
      }
    },
  },
  mounted() {
    this.fetchAllSavedReplies()
  },
  methods: {
    ...mapActions(['fetchSavedReplies', 'selectSavedReply', 'deleteSaveReply']),
    createNew() {
      this.shouldShowCreateNew(false, null)
    },
    editExistingReply(payload) {
      this.shouldShowCreateNew(true, payload)
    },
    async fetchAllSavedReplies() {
      console.log('I AM HERE >.. ', this.activeInboxDetail)
      if (!this.isLoading) {
        this.isLoading = true
        const resp = await this.fetchSavedReplies({
          workspace_id: this.activeInboxDetail.workspace_id,
        })

        if (resp.isValid) {
          this.isSearching = false
          this.savedReplies = resp.savedReplies
        } else {
          this.savedReplies = []
        }

        this.filteredSavedReplies = this.savedReplies
        this.isLoading = false
      }
    },
    async selectedSavedReply(replyId) {
      if (!this.isLoading) {
        this.isLoading = true
        const payload = {
          saved_reply_id: replyId,
          inbox_details_id: this.activeInboxDetail._id,
        }

        const response = await this.selectSavedReply(payload)

        if (response.isValid) {
          this.isLoading = false
          this.applySavedReply(response.savedReply)
          this.alertMessage(response.message, 'success')
          this.$bvModal.hide(this.id)
        } else {
          this.isLoading = false
          this.alertMessage(response.message, 'error')
        }
      }
    },
    async deleteReply(replyId) {
      if (!this.isLoading) {
        this.isLoading = true
      }
      const payload = {
        saved_reply_id: replyId,
      }

      const resp = await this.deleteSaveReply(payload)
      if (resp.isValid) {
        this.isLoading = false
        this.savedReplies = []
        this.fetchAllSavedReplies()
      } else {
        this.isLoading = false
        this.alertMessage(resp.message, 'error')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.createNewButton {
  padding: 0.8em 2em !important;
  border-radius: 0.625rem;
  text-transform: capitalize;
  border: 1px solid #cccdcd !important;
  background: transparent;

  i {
    font-weight: 300;
    font-size: 1.4em;
    margin-right: 0.7em;
    cursor: pointer;

    &:before {
      color: #3a4557;
      opacity: 0.4;
    }
  }

  &:hover {
    border: 1px solid #2f8ae0 !important;
    cursor: pointer;
  }
}

.savedRepliesItem {
  height: 453px;
  overflow-y: auto;
}
</style>
