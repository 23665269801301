<template>
  <div class="article-search-bar-pane__search__hints">
    <svg width="10" height="15" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path
          d="M7.727 11H2.2726v-.4581a2.8052 2.8052 0 00-.8321-1.9633c-1.9367-1.9789-1.918-5.1645.0417-7.1203 1.9598-1.9556 5.1204-1.9427 7.0644.0288 1.944 1.9716 1.9371 5.1574-.0153 7.1204a2.726 2.726 0 00-.8041 1.9344V11z"
          fill="#FFBE0A"
        ></path>
        <path
          d="M2.5 12h5a.5.5 0 110 1h-5a.5.5 0 010-1zM3.5 14h3a.5.5 0 110 1h-3a.5.5 0 010-1z"
          fill="#78909C"
        ></path>
      </g>
    </svg>
    <p v-html="getRandomHint"> </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hints: [
        'We recommend starting with a basic term like <span class="search_hint_bold_text">"content marketing"</span> and refining your search based on the results you get back.',
        'Did you know you can find the mention of a person or company by their X (Twitter) handle? Here is an example, <span class="search_hint_bold_text">@contentstudioio</span>',
        'Combine searches by adding <span class="search_hint_bold_text">"OR"</span> between each search query. For example, content marketing OR content strategy.',
        'Expand your results to include more relevant terms by using the <span class="search_hint_bold_text">OR</span> operator: weight loss OR fat loss',
        'Narrow your results by using negative operator to exclude non relevant terms, for example: skincare -beauty',
        'You can find the results by searching for hashtag. For example: #contentmarketing',
        'Did you know you can filter the results using <span class="search_hint_bold_text">"AND"</span> operator? For example, content marketing AND tips',
        'Did you know you can find all the tweets from a specific author by placing <span class="search_hint_bold_text">from:</span> in front of their name.',
        'Did you know you can filter the results for a keyword from a specific website. For example, <span class="search_hint_bold_text">techcrunch.com startups</span>.'
      ]
    }
  },
  computed: {
    getRandomHint () {
      if (
        ['discover_search', 'discover_insights'].indexOf(this.$route.name) >= 0
      ) {
        return this.hints[0]
      } else {
        return this.hints[Math.floor(Math.random() * this.hints.length)]
      }
    }
  }
}
</script>
