<script setup>
import useYoutubeAnalytics from "@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics";
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'

const { cards, getCardData, isReportView } =
    useYoutubeAnalytics()
</script>

<template>
  <NewStatsCard
      v-for="card in cards"
      :key="card"
      :show-info-tooltip="!isReportView"
      :data="getCardData(card)"
  />
</template>
