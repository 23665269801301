import { shallowRef, ref, computed, defineAsyncComponent } from 'vue'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import { useRoute } from 'vue-router'
import proxy from '@common/lib/http-common'
import { platformPosting, allPlanHeadAttachments } from '@common/lib/planner'
import { getPlatformIdentifierValue } from '@common/lib/integrations'
import {
  SOCIAL_PLATFORMS_MEDIA_ENDPOINT,
  swapPlanDatesUrl,
} from '@src/modules/planner/config/api-utils'
import { useStore } from '@state/base'
import {
  SUPPORTED_PLATFORMS,
  TIKTOK_TAB_NAMES,
} from '@src/modules/planner_v2/constants/GridView'
import useSocialGridView from '@src/modules/planner_v2/composables/useSocialGridView'
import { PLAN_STATUS } from '@/src/modules/common/constants/composer'
import useSocialMediaUtils from '@/src/modules/common/composables/useSocialMediaUtils'
import useTime from '@/src/modules/common/composables/useTime'

const FeedsComponent = shallowRef(
  defineAsyncComponent({
    loader: () =>
      import(
        '@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/FeedsComponent.vue'
      ),
    loadingComponent: ClipLoader,
  })
)

const FeedsSidebarComponent = shallowRef(
  defineAsyncComponent({
    loader: () =>
      import(
        '@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/FeedsSidebarComponent.vue'
      ),
    loadingComponent: ClipLoader,
  })
)

const {
  selectedPost,
  selectedAccount,
  fetchingMoreItems,
  selectedTab,
  noStatusSelected,
  fetching,
  tabs,
} = useSocialGridView()

const DEFAULT_SELECTED_TAB = {
  name: 'Feed',
  components: {
    main: FeedsComponent,
    sidebar: FeedsSidebarComponent,
  },
}

// composables
const { isPlanPublished, isPlanFailed, isPlanDraft } = useSocialMediaUtils()
const { getWorkspaceTimeZoneTime } = useTime()

// Global Reactive variables to share state
const lastTimeStampOfRemoteMedia = ref(null)
const remoteMedia = ref([])
const movedTilesOnDrag = ref([])
const isLocked = ref(false)
const filteredItems = ref([])
const showRemoteMedia = ref(false)
const fetchedMediaCursor = ref(null)
const hasMoreRemoteMedia = ref(true)
const fetchingRemoteMedia = ref(false)
const selfOnlyPosts = ref([])

let prevIndex = null
const movingItemIndex = ref(null)
const futureItemIndex = ref(null)
const feedItems = ref([])

// computed global states
const filteredItemsAndRemoteMedia = computed(() => {
  const filteredItemsValue = filteredItems.value || []
  const remoteMediaValue = remoteMedia.value || []

  if (selectedTab.value?.name === TIKTOK_TAB_NAMES.SELF_ONLY) {
    return selfOnlyPosts.value
  }

  // Check if remoteMediaValue is empty, return filteredItemsValue directly
  if (!remoteMediaValue.length) {
    return sortArrayByDate(filteredItemsValue)
  }

  // Check if filteredItemsValue is empty, return remoteMediaValue directly
  if (!filteredItemsValue.length) {
    return sortArrayByDate(remoteMediaValue)
  }

  const postedLinksSet = new Set()
  const toBeRemovedRemoteMedia = []

  for (const item of filteredItemsValue) {
    const tiktokPosting = item?.posting?.find(
      (x) => x.platform_type === 'TikTok'
    )

    if (tiktokPosting?.link) {
      const linkId = getIdFromLink(tiktokPosting.link)

      if (linkId) {
        postedLinksSet.add(linkId)
      }
    }
  }

  const combinedItems = remoteMediaValue.filter((item) => {
    if (!postedLinksSet.has(item.id)) {
      return true
    }
    toBeRemovedRemoteMedia.push(item)
    return false
  })

  for (const item of toBeRemovedRemoteMedia) {
    const viewCount = item.view_count
    const linkId = item.id

    for (const obj of filteredItemsValue) {
      const tiktokPosting = obj?.posting?.find(
        (x) => x.platform_type === 'TikTok'
      )

      if (getIdFromLink(tiktokPosting?.link) === linkId) {
        obj.view_count = viewCount
      }
    }
  }

  const combinedAndSortedItems = showRemoteMedia.value
    ? [...filteredItemsValue, ...combinedItems]
    : filteredItemsValue

  return sortArrayByDate(combinedAndSortedItems)
})

const getIdFromLink = (link = '') => {
  if (!link) return null
  const linkParts = link?.split('/')
  return linkParts[linkParts.length - 1]?.split('?')[0] // Extract ID from the link
}

const hasFilteredItems = computed(() => {
  return !!filteredItems.value.length > 0
})

const hasFilteredAndRemoteItems = computed(
  () => filteredItemsAndRemoteMedia.value.length > 0
)
const filteredItemsWithNoRemoteMedia = computed(() =>
  selectedTab.value?.name === TIKTOK_TAB_NAMES.SELF_ONLY
    ? feedItems.value
    : feedItems.value?.filter((i) => !isRemoteMedia(i))
)

// methods
const formatDate = (date, hours = 0) => {
  const newDate = new Date(date)
  newDate.setHours(newDate.getHours() + hours)
  return newDate
}

const isRemoteMedia = (element) => Object.hasOwn(element, 'id')

const hasError = (e) => Object.hasOwn(e, 'error') && e.error === true

const isViaPushNotification = (e) => {
  return (
    Object.hasOwn(e, 'device_notification') && e.device_notification === true
  )
}

const isPushPostProcessed = (e) => {
  return (
    Object.hasOwn(e, 'notification_processed') &&
    e.notification_processed === true
  )
}

const isPushAndDeclined = (e) => {
  return isViaPushNotification(e) && isPushPostProcessed(e) && hasError(e)
}

const isPushAndAccepted = (e) => {
  return isViaPushNotification(e) && isPushPostProcessed(e) && !hasError(e)
}

const isPushAndNoOptionIsSelected = (e) => {
  return isViaPushNotification(e) && !isPushPostProcessed(e)
}

const showOnGridView = (i) => {
  if (isRemoteMedia(i)) return true

  if (i.posting_details === undefined) return true

  return (
    !isPushAndDeclined(i.posting_details) &&
    !isPushAndAccepted(i.posting_details) &&
    !isPushAndNoOptionIsSelected(i.posting_details)
  )
}

const isApproved = (element) => element?.status === PLAN_STATUS.APPROVED

const isPast = (date, otherDate = null) => {
  const formattedDate = formatDate(date)

  if (!otherDate) return formattedDate < new Date()

  const formattedOtherDate = formatDate(otherDate)
  return formattedDate < formattedOtherDate
}

const isVideo = (element) => {
  return element?.thumbnail_url || element?.common_box_status
    ? element?.common_sharing_details?.video?.thumbnail
    : element?.tiktok_sharing_details?.video?.thumbnail
}

const mediaType = (item) => {
  if (!isVideo(item)) {
    return 'Image'
  }

  return 'Video'
}

const shouldDisplayIcon = (plan) => {
  return !isRemoteMedia(plan)
}

const isPlanCarousel = (plan) => {
  return !isRemoteMedia(plan) && allPlanHeadAttachments(plan).length > 1
}

const isPlanFailedOnSelectedAccount = (post) => {
  if (!post?.posting) return false

  const postingDetailsForSelectedAccount = platformPosting(
    post,
    'tiktok',
    getPlatformIdentifierValue(selectedAccount.value, 'tiktok')
  )

  return (
    postingDetailsForSelectedAccount &&
    Object.hasOwn(postingDetailsForSelectedAccount, 'error') &&
    postingDetailsForSelectedAccount.error
  )
}

const sortArrayByDate = (array = []) => {
  if (!array?.length) return array
  // Sort array by execution_time or timestamp
  return array.sort((a, b) => {
    const dateA = a.execution_time
      ? formatDate(getWorkspaceTimeZoneTime(a.execution_time.date))
      : new Date(a.create_time)
    const dateB = b.execution_time
      ? formatDate(getWorkspaceTimeZoneTime(b.execution_time.date))
      : new Date(b.create_time)

    return dateB - dateA
  })
}

const getPostingDetailsOnSelectedAccount = (plan) =>
  plan?.posting?.find(
    (i) => i.platform_id === selectedAccount.value?.platform_identifier
  )

const isPlanPublishedButDeletedFromTiktok = (plan) => {
  const postingLink = plan.posting?.find(
    (item) => item.platform_type === 'TikTok'
  )?.link

  if (
    !postingLink ||
    remoteMedia.value?.length < 1 ||
    plan.tiktok_options?.privacy_level !== 'PUBLIC_TO_EVERYONE'
  ) {
    return false
  }

  if (
    lastTimeStampOfRemoteMedia.value &&
    remoteMedia.value.length >= 100 &&
    isPast(
      getWorkspaceTimeZoneTime(plan.execution_time?.date),
      lastTimeStampOfRemoteMedia.value
    )
  ) {
    // If conditions meet, it's not considered deleted from TikTok
    return false
  }

  const linkID = getIdFromLink(postingLink)

  return remoteMedia.value.every((i) => i.id !== linkID)
}

const getThumbnail = (element) => {
  let media = null

  if (isRemoteMedia(element)) {
    return element.media_url
  }

  media = element?.common_box_status
    ? element?.common_sharing_details
    : element.tiktok_sharing_details

  if (media?.image && media.image.length > 0) {
    return media.image[0]
  }

  media = media.video

  return (
    media?.thumbnail ??
    'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
  )
}
// TODO:: Remove after swapPosts method is implemented
const getUpdatedTimeAfterSwap = (element) => {
  // Get execution_time of moved element
  const movedElement = movedTilesOnDrag.value.find((e) => e.id === element._id)

  if (movedElement) {
    return movedElement.execution_time
  }

  return null
}

const reset = () => {
  selectedAccount.value = null
  fetching.value = false
  remoteMedia.value = []
  movedTilesOnDrag.value = []
  filteredItemsAndRemoteMedia.value = []
  isLocked.value = false
  selectedTab.value = DEFAULT_SELECTED_TAB
  fetchedMediaCursor.value = null
  fetchingRemoteMedia.value = false
}

export default function useTiktokGridView() {
  const route = useRoute()
  const store = useStore()

  const isGridView = computed(() => route?.meta?.isGridView)

  /**
   * Functionality to handle drag and drop events
   * @param {Event} event - The drag event.
   * @param {number} index - The index of the grid item.
   */

  const handleDragStart = (event, index) => {
    movingItemIndex.value = index
    if (feedItems.value[index]) {
      feedItems.value[index].dragging = true
    }
  }

  /**
   * Handles the move event for a grid item.
   *
   * @param {Event} event - The move event.
   * @param {number} index - The index of the grid item.
   */
  const handleMove = (event, index) => {
    event.preventDefault()

    // If index is out of bounds, return early
    if (index < 0 || index >= feedItems.value?.length) {
      return
    }

    // If there was a previous item being dragged over and the current index is different from the previous index, reset its dropping property
    if (
      prevIndex !== null &&
      prevIndex !== index &&
      feedItems.value[prevIndex]
    ) {
      feedItems.value[prevIndex].dropping = false
    }

    futureItemIndex.value = index

    if (
      movingItemIndex.value !== futureItemIndex.value &&
      futureItemIndex.value !== null &&
      feedItems.value[index]
    ) {
      feedItems.value[index].dropping = true
    }

    // Update prevIndex to the current index
    prevIndex = index
  }

  /**
   * Handles the drag end event for the Tiktok grid view.
   * This function swaps the execution times of two items in the feedItems array,
   * updates the execution times in the API, and displays a toast notification
   * based on the success or failure of the swap operation.
   *
   * @returns {Promise<void>} A promise that resolves when the swap operation is complete.
   */
  const handleDragEnd = async () => {
    // Reset the dragging and dropping properties of the items
    feedItems.value[movingItemIndex.value].dragging = false
    feedItems.value[futureItemIndex.value].dropping = false
    if (movingItemIndex.value === futureItemIndex.value) {
      return
    }
    const isMovingItemDraggable =
      feedItems.value[movingItemIndex.value]?.isDraggable
    const isFutureItemDraggable =
      feedItems.value[futureItemIndex.value]?.isDraggable

    if (!isMovingItemDraggable || !isFutureItemDraggable) {
      return store.dispatch('toastNotification', {
        message: 'Only scheduled, In review and draft posts can be swapped!',
        type: 'error',
      })
    }

    const movingItem = feedItems.value[movingItemIndex.value]
    const futureItem = feedItems.value[futureItemIndex.value]

    // Swap the execution  times of the future and moving items
    const _items = [...feedItems.value]
    _items[futureItemIndex.value] = {
      ...movingItem,
      execution_time: futureItem.execution_time,
    }
    _items[movingItemIndex.value] = {
      ...futureItem,
      execution_time: movingItem.execution_time,
    }

    // Prepare the payload for the API call - id and execution_time for both items
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      post_ids: [movingItem._id, futureItem._id],
    }

    // Call the swapPosts function and handle the response
    const status = await swapPosts(payload)
    if (status) {
      feedItems.value = _items
      return store.dispatch('toastNotification', {
        message: 'Post Swapped successfully',
        type: 'success',
      })
    } else {
      // set the items back to their original positions
      feedItems.value = filteredItemsAndRemoteMedia.value
      return store.dispatch('toastNotification', {
        message: 'Failed to swap posts',
        type: 'error',
      })
    }
  }

  // Function to swap the dates of two posts
  /**
   * Makes a POST request to the swapPlanDatesUrl with the given payload.
   * @param {Object} payload - The payload to be sent in the POST request.
   * @returns {Promise<string|undefined>} - A promise that resolves to the status string if the response is successful, or undefined otherwise.
   */
  const swapPosts = async (payload) => {
    // Make a POST request to the swapPlanDatesUrl with the payload
    try {
      const response = await proxy.post(swapPlanDatesUrl, payload)
      // If the response is successful, return the status
      if (response?.data?.status) {
        return response.data.status
      }
    } catch (error) {
      console.error(
        'An error occurred in useTiktokGridView Swap post method: ',
        error
      )
    }
  }

  const fetchMedia = async () => {
    // will keep on fetching media until it reaches 100 or has_more is false
    if (!hasMoreRemoteMedia.value || remoteMedia?.value?.length >= 100) return
    fetchingRemoteMedia.value = true
    try {
      const payload = {
        platform: SUPPORTED_PLATFORMS.TIKTOK.route_suffix,
        workspace_id: store.getters.getActiveWorkspace._id,
        platform_id: selectedAccount.value?.platform_identifier,
      }

      if (fetchedMediaCursor.value) {
        payload.cursor = fetchedMediaCursor.value
      }

      const { data } = await proxy.post(
        SOCIAL_PLATFORMS_MEDIA_ENDPOINT,
        payload
      )

      hasMoreRemoteMedia.value = data?.data?.has_more

      if (
        data?.data?.has_more &&
        fetchedMediaCursor.value !== data?.data?.cursor
      ) {
        fetchedMediaCursor.value = data?.data?.cursor
      }

      const newData =
        data?.data?.videos?.map((element) => {
          return {
            ...element,
            isDraggable: false,
          }
        }) ?? []

      const newRemoteMedia = [...remoteMedia.value, ...newData]
      remoteMedia.value = [
        ...new Map(newRemoteMedia.map((item) => [item.id, item])).values(),
      ]

      const lastTimeStamp =
        remoteMedia.value[remoteMedia.value.length - 1]?.create_time

      if (lastTimeStamp) {
        lastTimeStampOfRemoteMedia.value = formatDate(lastTimeStamp)
      } else {
        lastTimeStampOfRemoteMedia.value = null
      }
    } catch (e) {
      hasMoreRemoteMedia.value = false
    } finally {
      fetchingRemoteMedia.value = false
    }
  }

  // Return properties and methods
  return {
    DEFAULT_SELECTED_TAB,
    tabs,
    selectedTab,
    fetching,
    fetchingMoreItems,
    selectedAccount,
    selectedPost,
    lastTimeStampOfRemoteMedia,
    remoteMedia,
    showRemoteMedia,
    noStatusSelected,

    hasFilteredItems,
    hasFilteredAndRemoteItems,

    filteredItems,
    filteredItemsWithNoRemoteMedia,
    filteredItemsAndRemoteMedia,
    movedTilesOnDrag,
    hasMoreRemoteMedia,
    fetchedMediaCursor,
    fetchingRemoteMedia,
    isLocked,
    isGridView,
    selfOnlyPosts,

    // drag and drop properties
    feedItems,

    reset,
    getUpdatedTimeAfterSwap,
    sortArrayByDate,
    formatDate,

    fetchMedia,
    getThumbnail,
    mediaType,
    shouldDisplayIcon,
    getPostingDetailsOnSelectedAccount,

    isPlanPublished,
    isPlanFailed,
    isPlanDraft,
    isPlanPublishedButDeletedFromTiktok,
    isPlanFailedOnSelectedAccount,
    isPast,
    isApproved,
    isVideo,
    isRemoteMedia,
    isPlanCarousel,

    // drag and drop methods
    handleDragStart,
    handleMove,
    handleDragEnd,

    // push notifications methods
    isViaPushNotification,
    hasError,
    isPushAndAccepted,
    isPushAndDeclined,
    isPushAndNoOptionIsSelected,
    isPushPostProcessed,
    showOnGridView,
  }
}
