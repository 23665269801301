import { removeGCSMediaURL } from '@src/modules/publish/config/api-utils'
import proxy from '@common/lib/http-common'
import { blogIntegrationsNames } from '../../../integration/config/api-utils'
import { activityTypes, publish } from './mutation-types'

const defaultPublishHashtag = {
  status: false,
  selected: '',
  edit_mode: false
}

export const getDefaultPublishHashtag = () => {
  return {
    status: false,
    selected: '',
    edit_mode: false
  }
}

export const getDefaultPublishReplug = () => {
  return {
    status: false,
    brand: '',
    campaign: '',
    link: '',
    selectedItem: {
      status: false,
      brand: '',
      campaign: ''
    }
  }
}

export const getDefaultApprovalData = () => {
  return {
    approvers: [],
    approve_option: 'anyone',
    notes: '',
    status: 'pending_approval',
    members: []
  }
}

const defaultPublish = {
  publish_source: '',
  label: [],
  members: [],
  hashtag: getDefaultPublishHashtag(),
  utm: {
    status: false,
    selected: '',
    edit_mode: false,
    link: ''
  },
  replug: getDefaultPublishReplug(),
  platforms: {
    search: '',
    check_all: false,
    selection: []
  },
  plan_id: '',
  secondaryPlansIds: [],
  status: '',
  folderId: null,
  planExecutionTime: null,
  socialPlansDetail: [],
  queueStatus: false,
  repeatStatus: false,
  reopen: false,
  repost: false,
  csvPlanId: null,
  content_category_id: null,
  planApprovalData: null,
  created_by_members: []
}

export default {
  state: {
    selection: JSON.parse(JSON.stringify(defaultPublish)),
    status: 'task',
    activities: {
      data: [],
      totalActivities: 0
    }
  },
  actions: {
    resetPublishingDetails ({ commit }) {
      commit('resetPublishingDetails')
    },
    async changePlatformsSelection (
      { commit, getters, state, dispatch },
      options
    ) {
      console.debug('Action:changePlatformsSelection', options)
      if (options.selection_type !== 'all') {
        // for single platform changes e.g facebook, twitter etc
        let total =
          getters.getFacebookAccounts.items.length +
          getters.getTwitterAccounts.items.length +
          getters.getLinkedinAccounts.items.length +
          getters.getPinterestBoards.length +
          getters.getTumblrAccounts.items.length +
          getters.getInstagramAccounts.items.length +
          getters.getGmbAccounts.items.length +
          getters.getTiktokAccounts.items.length
        if (options.filter_type === 'blog') {
          blogIntegrationsNames.forEach((integration) => {
            total += getters.getBlogs[integration].items.length
          })
        }
        commit(
          publish.SET_PLATFORMS_CHECK_ALL_STATUS,
          getters.getPublishSelection.platforms.selection.length === total
        )
        return true
      }
      const platforms = []
      if (getters.getPublishSelection.platforms.check_all) {
        console.debug(
          'Action:changePlatformsSelection',
          getters.getFacebookAccounts.items
        )
        if (options.filter_type === 'blog') {
          blogIntegrationsNames.forEach((integration) => {
            getters.getBlogs[integration].items.forEach(function (platform) {
              platforms.push(platform.platform_identifier)
            })
          })
        }

        getters.getFacebookAccounts.items.forEach(function (platform) {
          platforms.push(platform.facebook_id)
        })

        getters.getTwitterAccounts.items.forEach(function (platform) {
          platforms.push(platform.twitter_id)
        })

        getters.getLinkedinAccounts.items.forEach(function (platform) {
          platforms.push(platform.linkedin_id)
        })

        getters.getPinterestBoards.forEach(function (platform) {
          platforms.push(platform.board_id)
        })

        getters.getTumblrAccounts.items.forEach(function (platform) {
          platforms.push(platform.name)
        })

        getters.getInstagramAccounts.items.forEach(function (platform) {
          platforms.push(platform.instagram_id)
        })

        getters.getYoutubeAccounts.items.forEach(function (platform) {
          platforms.push(platform.platform_identifier)
        })

        getters.getGmbAccounts.items.forEach(function (platform) {
          platforms.push(platform.name)
        })

        getters.getTiktokAccounts.items.forEach(function (platform) {
          platforms.push(platform.platform_identifier)
        })
      }
      console.debug(
        'Action:changePlatformsSelection',
        platforms,
        getters.getPublishSelection.platforms.check_all
      )
      commit(publish.SET_PLATFORMS_SELECTION, platforms)
      if (platforms.length === 0) { commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false) }
      return true
    },

    /**
     * remove from GCP and database
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param link
     */
    async removeGCSMedia ({ commit, getters, state, dispatch }, link) {
      console.debug('Action:removeGCSMedia', link)
      return proxy
        .post(removeGCSMediaURL, { link })
        .then((response) => {
          return response.data.status
        })
        .catch((response) => {
          console.debug('Exception in removeGCSMedia', response)
          return false
        })
    },

    setPublishSource ({ commit }, source) {
      commit('setPublishSource', source)
    },

    setHashtagStatus ({ commit }, status) {
      commit('setHashtagStatus', status)
    },

    setSelectedHashtag ({ commit }, hashtag) {
      commit('setSelectedHashtag', hashtag)
    },
    setPublishSelectionSearch ({ commit }, value) {
      commit('setPublishSelectionSearch', value)
    },
    setHashtagEditMode ({ commit }, status) {
      commit('setHashtagEditMode', status)
    },
    setUtmEditMode ({ commit }, status) {
      commit('setUtmEditMode', status)
    },

    setSharingUtmLink ({ commit }, link) {
      commit('setSharingUtmLink', link)
    },

    setSharingReplugBrand ({ commit }, brand) {
      commit('setSharingReplugBrand', brand)
    },

    setSharingReplugCampaign ({ commit }, campaign) {
      commit('setSharingReplugCampaign', campaign)
    },

    setSharingReplugLink ({ commit }, link) {
      commit('setSharingReplugLink', link)
    },

    setSelectedHashtagSelection ({ commit }, selection) {
      commit('setSelectedHashtagSelection', selection)
    },

    setSelectedReplugSelection ({ commit }, selection) {
      commit('setSelectedReplugSelection', selection)
    },
    setSelectedReplugSelectedItem ({ commit }, getPublishSelectionitem) {
      commit('setSelectedReplugSelectedItem', item)
    },

    setDefaultPublishStates ({ commit }) {
      commit('setDefaultPublishStates')
    },

    setPublishPlanId ({ commit }, planId) {
      commit('setPublishPlanId', planId)
    },
    setPublishPlanSecondaryIds ({ commit }, planIds) {
      commit('setPublishPlanSecondaryIds', planIds)
    },
    setPublicationStatus ({ commit }, status) {
      commit('setPublicationStatus', status)
    },
    setPublicationFolderId ({ commit }, id) {
      commit('setPublicationFolderId', id)
    },
    setPublishMembers ({ commit }, members) {
      commit('setPublishMembers', members)
    },
    setPublishLabels ({ commit }, labels) {
      commit('setPublishLabels', labels)
    },
    setPublishPlanExecutionTime ({ commit }, time) {
      commit('setPublishPlanExecutionTime', time)
    },
    setSocialPlanDetail ({ commit }, detail) {
      commit('setSocialPlanDetail', detail)
    },
    setEditQueueStatus ({ commit }, status) {
      commit('setEditQueueStatus', status)
    },
    setEditRepeatStatus ({ commit }, status) {
      commit('setEditRepeatStatus', status)
    },

    setSelectedUtm ({ commit }, utm) {
      commit('setSelectedUtm', utm)
    },
    setPostReopenStatus ({ commit }, status) {
      commit('setPostReopenStatus', status)
    },
    setPostRepostStatus ({ commit }, status) {
      commit('setPostRepostStatus', status)
    },
    setSidebarStatus ({ commit }, status) {
      commit('setSidebarStatus', status)
    },

    setCSVPlanId ({ commit }, id) {
      commit('setCSVPlanId', id)
    }
  },
  mutations: {
    resetPublishingDetails (state) {
      Object.assign(state.selection, JSON.parse(JSON.stringify(defaultPublish)))
    },
    [publish.SET_PUBLISH_REPLUG_VALUE] (state, replug) {
      if (replug) {
        state.selection.replug = replug
      } else {
        state.selection.replug = getDefaultPublishReplug()
      }
    },
    [publish.SET_PUBLISH_REPLUG_SELECTED_ITEM_VALUE] (state, value) {
      state.selection.replug.selectedItem = value
    },
    [publish.RESET_PUBLISH_REPLUG_VALUE] (state) {
      state.selection.replug = getDefaultPublishReplug()
    },
    [publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE] (state, value) {
      state.selection.content_category_id = value
    },

    [publish.SET_PLATFORMS_CHECK_ALL_STATUS] (state, status) {
      state.selection.platforms.check_all = status
    },
    [publish.ADD_PUBLISH_LABELS_ITEM] (state, value) {
      state.selection.label.push(value)
    },
    [publish.SET_PUBLISH_CAMPAIGN] (state, value) {
      state.selection.folderId = value
    },

    [publish.SET_PLATFORMS_SELECTION] (state, platform) {
      state.selection.platforms.selection = platform
    },
    [publish.SET_PUBLISH_HASHTAG_VALUE] (state, value) {
      state.selection.hashtag.selected = value.id
      state.selection.hashtag.status = value.status
    },
    [publish.SET_PUBLISH_HASHTAG_EDIT_MODE] (state, value) {
      state.selection.hashtag.edit_mode = value
    },
    [publish.SET_PUBLISH_PLAN_ID] (state, value) {
      state.selection.plan_id = value
    },
    [publish.RESET_PUBLISH_HASHTAG_VALUE] (state) {
      state.selection.hashtag = getDefaultPublishHashtag()
    },
    [publish.SET_PUBLISH_SOURCE] (state, value) {
      state.selection.publish_source = value
    },
    [publish.SET_PLAN_APPROVAL_DATA] (state, data) {
      state.selection.planApprovalData = data
      // else state.selection.planApprovalData = getDefaultApprovalData()
    },
    [publish.RESET_SOCIAL_SHARE_SELECTION] (state) {
      state.selection.labels = []
      state.selection.members = []
      state.selection.content_category_id = null
      state.selection.time_type = 'queued'
    },

    // activities

    [activityTypes.SET_ACTIVITIES] (state, value) {
      state.activities.data = value
    },

    [activityTypes.SET_TOTAL_ACTIVITIES] (state, value) {
      state.activities.totalActivities = value
    },

    setPublishSource (state, source) {
      state.selection.publish_source = source
    },

    setHashtagStatus (state, status) {
      state.selection.hashtag.status = status
    },

    setSelectedHashtag (state, hashtag) {
      state.selection.hashtag.selected = hashtag
    },
    setPublishSelectionSearch (state, value) {
      state.selection.platforms.search = value
    },
    setHashtagEditMode (state, status) {
      state.selection.hashtag.edit_mode = status
    },

    setUtmEditMode (state, status) {
      state.selection.utm.edit_mode = status
    },

    setSharingUtmLink (state, link) {
      state.selection.utm.link = link
    },

    setSharingReplugBrand (state, brand) {
      state.selection.replug.brand = brand
    },

    setSharingReplugCampaign (state, campaign) {
      state.selection.replug.campaign = campaign
    },

    setSharingReplugLink (state, link) {
      state.selection.replug.link = link
    },

    setSelectedHashtagSelection (state, campaign) {
      if (campaign && campaign.hashtag && campaign.hashtag.status) {
        state.selection.hashtag.status = true
        state.selection.hashtag.selected = campaign.hashtag.id
        state.selection.hashtag.edit_mode = false
      } else {
        state.selection.hashtag = getDefaultPublishHashtag()
      }
    },

    setSelectedReplugSelection (state, replug) {
      if (replug) {
        state.selection.replug = replug
      } else {
        state.selection.replug = getDefaultPublishReplug()
      }
    },

    setSelectedReplugSelectedItem (state, item) {
      state.selection.replug.selectedItem = item
    },

    setDefaultPublishStates (state) {
      state.selection = JSON.parse(JSON.stringify(defaultPublish))
    },

    setPublishPlanId (state, planId) {
      state.selection.plan_id = planId
    },
    setPublishPlanSecondaryIds (state, planIds) {
      state.selection.secondaryPlansIds = planIds
    },
    setPublicationStatus (state, status) {
      state.selection.status = status
    },
    setPublicationFolderId (state, status) {
      state.selection.folderId = status
    },
    setPublishMembers (state, members) {
      if (members) {
        state.selection.members = members
      } else {
        state.selection.members = []
      }
    },

    setPublishCreatedByMembers (state, members) {
      if (members) {
        state.selection.created_by_members = members
      } else {
        state.selection.created_by_members = []
      }
    },
    setPublishLabels (state, labels) {
      if (labels) {
        state.selection.label = labels
      } else {
        state.selection.label = []
      }
    },
    setPublishPlanExecutionTime (state, time) {
      state.selection.planExecutionTime = time
    },
    setSocialPlanDetail (state, detail) {
      state.selection.socialPlansDetail = detail
    },
    setEditQueueStatus (state, status) {
      state.selection.queueStatus = status
    },
    setEditRepeatStatus (state, status) {
      state.selection.repeatStatus = status
    },

    setSelectedUtm (state, utm) {
      state.selection.utm.selected = utm
    },
    setPostReopenStatus (state, status) {
      state.selection.reopen = status
    },
    setPostRepostStatus (state, status) {
      state.selection.repost = status
    },
    setSidebarStatus (state, status) {
      state.status = status
    },
    setCSVPlanId (state, id) {
      state.selection.csvPlanId = id
    }
  },
  getters: {
    getPublishSelection: (state) => {
      return state.selection
    },

    getPublishUtmSelection: (state) => {
      return state.selection.utm
    },
    getSidebarStatus: (state) => {
      return state.status
    },

    getActivities: (state) => {
      return state.activities
    }
  }
}
