import { mapActions, mapGetters } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { twitterSharesURL } from '../config/api-utils'

export const twitterSharesMixin = {
  computed: {
    ...mapGetters(['getTwitterAccounts'])
  },
  methods: {
    ...mapActions([
      'setSharerLoader',
      'setSharerData',
      'fetchInfluencerTopSharers'
    ]),

    // get the twitter external link so that user can be redirected on that..
    getTwitterLink (account) {
      return 'https://twitter.com/' + account
    },

    getTwitterAccountsForView (list) {
      if (list && list.length > 0) {
        return [list[0]]
      } else {
        return []
      }
    },

    // fetch influencers and follower sort
    async showSharers (id) {
      console.debug('Method:showSharers', id)

      this.setSharerLoader(true)
      $('#twitter_influencer_modal').modal('show')

      const topMentioned = await this.fetchInfluencerTopSharers(id)

      topMentioned.sort(function (x, y) {
        if (x.follower_count > y.follower_count) return -1
        if (x.follower_count < y.follower_count) return 1
        return 0
      })
      this.setSharerData(topMentioned)

      this.setSharerLoader(false)
    },

    handleLink (handle) {
      return 'https://twitter.com/' + handle
    }
  }
}
