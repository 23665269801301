<template>
  <div class="w-full flex flex-col h-full px-8 items-center bg-cs-light-gray">
    <img
      src="@src/assets/img/captionGenerationBot.gif"
      alt="ContentStudio logo"
      class="w-full mt-16 mb-16 px-8"
    />

    <p class="mb-6 text-center text-base font-medium">
      Our AI Bot is busy generating the {{ text }}!
    </p>
    <div class="text-zinc-300 flex flex-col">
      <template v-for="(step, si) in steps" :key="si">
        <p class="mb-3">
          <span
            class="w-7 h-7 mr-2 rounded-full inline-flex justify-center items-center bg-cs-white drop-shadow-lg"
            :class="{
              'bg-green-400': step.status === true,
              'bg-cs-white': step.status === false,
            }"
          >
            <i v-if="step.status === true" class="fa fa-check text-white"></i>
            <i v-else class="fa fa-spinner fa-pulse fa-fw text-blue-400"></i>
          </span>
          <span :class="{ 'text-green-600': step.status }">
            {{ step.message }}
          </span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent, ref, watch } from 'vue'
const clone = require('rfdc/default')

export default defineComponent({
  name: 'AiGenerationLoader',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    loadingCompleted: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const defaultSteps = [
      { message: 'Initializing', status: false },
      { message: 'Refining content', status: false },
      { message: `Generating ${props.text}`, status: false },
      { message: `Your AI ${props.text} is ready!`, status: false },
    ]
    const loader = ref(false)
    const steps = ref(defaultSteps)

    watch(
      () => props.loading,
      (value, oldValue) => {
        // estimated time in ms
        const stepTime = 2000
        steps.value = clone(defaultSteps)
        if (value) {
          // waiting for response
          let i = 0
          const id = setInterval(() => {
            steps.value[i].status = true
            i++
            if (i === 2) clearInterval(id)
          }, stepTime)
        } else {
          // in case of response
          steps.value[0].status =
            steps.value[1].status =
            steps.value[2].status =
              true
          setTimeout(function () {
            steps.value[3].status = true
          }, 1000)
          setTimeout(function () {
            props.loadingCompleted()
            steps.value = clone(defaultSteps)
          }, 2000)
        }
      }
    )

    return { steps, loader }
  },
})
</script>
