// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function useFollowersGrowthComparison() {
    const {
        fetchCompetitorInfo: fetchFollowersGrowthComparison,
        apiResponse: followersGrowthComparisonResponse,
        loading: followersGrowthComparisonLoadingState
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone, colors} = useCompetitorReport()
    const getFollowersGrowthComparison = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonFollowersGrowthComparison()
                break
            default:
                break
        }
    }

    const fetchCommonFollowersGrowthComparison = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }

        try {
            await fetchFollowersGrowthComparison(COMMON_API_ENDPOINTS.FOLLOWERS_GROWTH_COMPARISON, payload, COMMON_ERROR_MESSAGES.FOLLOWERS_GROWTH_COMPARISON)
            followersGrowthComparisonResponse.value = followersGrowthComparisonResponse.value.map((item, index) => ({
                ...item,
                color: colors[index],
            }))
        } catch (error) {
        }
    }

    return {
        // data
        followersGrowthComparisonLoadingState,
        followersGrowthComparisonResponse,

        // methods
        getFollowersGrowthComparison
    }
}