// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'
import {ref} from "vue";


export default function usePostingBySpecificType () {
    const {fetchCompetitorInfo: fetchPostingActivityBySpecificType, apiResponse: postingActivitySpecificTypeResponse, loading: postingActivitySpecificTypeLoadingState} = useCompetitorsFactory()
    const sortOrder = ref(null)

    const {reportId, dateRange, dateRangeDiff, timeZone} = useCompetitorReport()

    const getPostingActivityBySpecificType = async (type = '', mediaType = '', mediaProductType = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonPostingActivityBySpecificType(mediaType, mediaProductType)
                break
            default:
                break
        }
    }

    const fetchCommonPostingActivityBySpecificType = async (mediaType, mediaProductType) => {
        if(!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
            sort_order: sortOrder.value || 'avgTotalEngagements',
            media_type: mediaType,
        }

        if(mediaProductType) payload.media_product_type = mediaProductType

        try{
            await fetchPostingActivityBySpecificType(COMMON_API_ENDPOINTS.POSTING_BY_SPECIFIC_TYPE, payload, COMMON_ERROR_MESSAGES.POSTING_BY_SPECIFIC_TYPE)
        }catch(error){}
    }

    return {
        // data
        dateRangeDiff,
        reportId,
        sortOrder,
        postingActivitySpecificTypeResponse,
        postingActivitySpecificTypeLoadingState,

        // methods
        getPostingActivityBySpecificType
    }
}