<template>
  <div>
    <b-modal
      id="create-media-folder"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="modal_head">
        <h4 v-if="!isSubFolder">Create Folder</h4>
        <h4 v-else>Create Sub Folder</h4>
        <button
          type="button"
          class="modal_head__close"
          data-dismiss="modal"
          @click="$bvModal.hide('create-media-folder')"
          >&times;
        </button>
      </div>

      <div class="modal_body mt-3">
        <b-breadcrumb v-if="isSubFolder" class="breadcrumb-media">
          <b-breadcrumb-item> Media Library </b-breadcrumb-item>
          <b-breadcrumb-item>{{ selectedFolderName }}</b-breadcrumb-item>
          <b-breadcrumb-item active>{{ folderDetail.name }}</b-breadcrumb-item>
        </b-breadcrumb>

        <div class="simple-form__text-field pl-0 flex-grow-1">
          <input
            v-model="folderDetail.name"
            type="text"
            :placeholder="isSubFolder ? 'Sub Folder Name' : 'Folder Name'"
            :maxlength="40"
            @keydown.enter="createNewFolder"
          />
        </div>
        <div class="simple-form__counter">
          <small> {{ folderDetail.name.length }} / 40</small>
        </div>

        <!--        <div class="simple-form__text-field pl-0 flex-grow-1">-->
        <!--          <textarea v-model="folderDetail.description" type="text" placeholder="Folder Description" ></textarea>-->
        <!--        </div>-->

        <!--        <div class="simple-form__text-field pl-0 flex-grow-1">-->
        <!--          <b-form-tags-->
        <!--              class="custom-tags"-->
        <!--              input-class="custom-tags__input"-->
        <!--              tag-class="custom-tags__tag"-->
        <!--              input-id="tags-separators"-->
        <!--              v-model="folderDetail.tags"-->
        <!--              :separator="[' ', ',', ';']"-->
        <!--              placeholder="Enter new tags"-->
        <!--              remove-on-delete-->
        <!--          ></b-form-tags>-->
        <!--        </div>-->

        <!--        <div class=" mb-3">-->
        <!--          <b-form-checkbox class="custom-switch-small" v-model="isSubFolder"  switch :disabled="folders.length === 0">-->
        <!--            Do you want to create a new sub folder?-->
        <!--          </b-form-checkbox>-->

        <!--        </div>-->

        <!--        <div class="simple-form__select-field" v-if="isSubFolder">-->
        <!--          <b-form-select size="sm" class="height-auto" v-model="selectedFolder" :options="rootFolders"></b-form-select>-->
        <!--        </div>-->

        <div class="mt-4 mb-3 d-flex flex-row-reverse">
          <button
            class="btn btn-studio-theme-space ml-2"
            :disabled="isCreatingFolder"
            @click="createNewFolder"
          >
            Create {{ isSubFolder ? 'Sub' : '' }} Folder
            <clip-loader
              v-if="isCreatingFolder"
              class="ml-2"
              :color="'#ffffff'"
              :size="'12px'"
            />
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";
import { MediaHelperMixin } from '../utils/MediaHelpers'
export default {
  name: 'CreateFolderModal',
  mixins: [MediaHelperMixin],
  props: ['folders'],
  data () {
    return {
      isCreatingFolder: false,
      isSubFolder: false,
      selectedFolder: null,
      selectedFolderName: null,
      // rootFolders: [
      //   { value: null, text: 'Please select an folder' },
      //   { value: '60daa75c286dd1036c748bd2', text: 'People' },
      //   { value: '345', text: 'Animals' }
      // ],
      folderDetail: {
        name: ''
      }
    }
  },

  computed: {
    rootFolders () {
      const rootFolders = []
      rootFolders.push({
        value: null,
        text: 'Please select an folder'
      })
      this.folders.forEach((folder) => {
        rootFolders.push({
          value: folder._id,
          text: folder.folder_name
        })
      })

      return rootFolders
    }
  },

  mounted () {
    // Clear Data on modal hide
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'create-media-folder') {
        this.selectedFolder = null
        this.selectedFolderName = null
        this.isSubFolder = false
        this.isCreatingFolder = false
        this.folderDetail = {
          name: ''
        }
      }
    })

    // Bind Events
    EventBus.$on('create-new-folder', (data) => {
      this.selectedFolderName = data.folderName
      this.selectedFolder = data.folderId
      this.isSubFolder = true
      this.$bvModal.show('create-media-folder')
    })
  },

  beforeUnmount () {
    EventBus.$off('create-new-folder')
  },

  methods: {
    async createNewFolder () {
      // Structuring folder details
      this.isCreatingFolder = true
      const details = {
        ...this.folderDetail,
        isRootFolder: !this.isSubFolder,
        rootFolderId: this.selectedFolder
      }
      await this.createNewFolderHelper(details, (status) => {
        if (status) {
          this.$bvModal.hide('create-media-folder')
          EventBus.$emit('refetch-folders')
        }
      })
      this.isCreatingFolder = false
    }
  }
}
</script>

<style scoped></style>
