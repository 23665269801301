<template>
  <div
    class="bg-white p-5 rounded-md w-full relative"
    :class="{ 'color-border cst-editor': !isModal }"
  >
    <div class="mb-6 w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <h2 class="text-base font-weight-500 select-none">{{ title }}</h2>
        <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{getComponentInfoTooltip(type, platformName)}}
              <span class="block text-gray-800 text-xs mt-2"
              >Note: The magnitude of change can only be calculated if
                historical data is available.</span
              >
            </p>
          </template>
        </v-menu>
      </div>
      <div
        v-if="!isModal && data.length"
        v-tooltip="{
          content: 'Maximize',
          theme: 'light',
        }"
        class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
        @click="$bvModal.show(`followers-comparison-${type}-modal`)"
      >
        <img
          src="@src/assets/img/full_screen.svg"
          alt="fullScreen"
          class="w-5 h-5"
        />
      </div>
      <div
        v-if="isModal"
        v-tooltip="{
          content: 'Minimize',
          theme: 'light',
        }"
        class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
        @click="$bvModal.hide(`followers-comparison-${type}-modal`)"
      >
        <i class="fa fa-times fa-lg" aria-hidden="true"></i>
      </div>
    </div>
    <div v-if="isLoading">
      <SkeletonBox class="!w-full" :style="{ height: chartHeight }"></SkeletonBox>
    </div>
    <div
      v-else-if="isAllDataFetched"
      class="
        analytics-no-data-found
        h-5/6
        relative
        right-0
        left-0
        z-10
        bg-white
      "
    >
      <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
      <p>Data is being fetched.</p>
    </div>
    <div
      v-else-if="!data.length"
      class="
        analytics-no-data-found
        h-5/6
        relative
        right-0
        left-0
        z-10
        bg-white
      "
    >
      <img
        src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
        alt=""
      />
      <p>No comparison data found.</p>
    </div>
    <div v-else class="flex w-full h-full">
      <div class="flex-1">
        <div
          ref="chartRef"
          :style="{ height: isModal ? '85vh' : chartHeight }"
        ></div>
      </div>
    </div>
    <b-modal
      :id="`followers-comparison-${type}-modal`"
      centered
      hide-footer
      hide-header
      content-class="mt-2 !rounded-none h-screen"
      dialog-class="cst-modal"
      @hide="commonMethods.toggleWidgets(false)"
      @shown="commonMethods.toggleWidgets(true)"
    >
      <FollowersComparisonBarChart
        :title="title"
        :x-axis-label="xAxisLabel"
        :y-axis-label="yAxisLabel"
        :y-axis-second-label="yAxisSecondLabel"
        :type="type"
        :chart-types="chartTypes"
        :legend="legend"
        :data="data"
        :is-all-data-fetched="isAllDataFetched"
        :is-loading="isLoading"
        :is-modal="true"
        :platform-name="platformName"
      />
    </b-modal>
  </div>
</template>

<script>
// libraries
import { computed, watch, ref, defineComponent, onMounted } from 'vue'

// components
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// helpers
import { commonMethods } from '@common/store/common-methods'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import useCompetitorHelper from "@src/modules/analytics_v3/composables/useCompetitorHelper";

export default defineComponent({
  name: 'FollowersComparisonBarChart',
  components: {
    SkeletonBox,
  },
  props: {
    title: {
      type: String,
      default: "Competitors' Performance",
    },
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    yAxisSecondLabel: {
      type: String,
      default: 'Followers %',
    },
    type: {
      type: String,
      default: 'followersComparisonGraph',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    chartTypes: {
      type: Array,
      default: () => ['bar'],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const {getComponentInfoTooltip} = useCompetitorHelper()
    const labelBgColor = ref('#ffff')

    const id = computed(() => props.platformName === 'facebook' ? 'facebook_id' : 'business_account_id')

    // mounted
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })

    // methods

    /**
     * @description method to check and create labels(having slug) or label objects having images as background colors to be plotted on axisLabels
     */
    const getLabels = () => {
      const labels = {}
      props.data?.forEach((item) => {
        labels[
          item[id.value]
        ] = `https://microlinks.cstuinternal.com/format_image/?link=${encodeURIComponent(
          item.image
        )}`
      })
      for (const item in labels) {
        labels[item] = {
          height: 32,
          width: 32,
          align: 'center',
          backgroundColor: {
            image: labels[item],
          },
        }
      }
      return labels
    }

    /*
     * Tooltip formatter
     */
    const tooltipFormatter = (params) => {
      const { data } = params[0]

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <img src="${
              data[4]
            }" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />
            <span class="text-[#000D21] text-base font-weight-500 w-[10rem] 2xl:w-[14rem] truncate">${
              data[5] ? data[5] : data[1]
            }</span>
          </div>
          <div class="flex items-end justify-between mt-4">
              <span class="text-[979CA0] text-sm mr-1.5">Followers</span>
              <span class="text-[#69788E] text-sm font-bold">${data[2]?.toLocaleString(
                'en-US',
                {
                  notation: 'compact',
                  compactDisplay: 'short',
                }
              )}</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Followers % chg.</span>
              <span class="text-sm font-bold ${
                data[3] > 0 ? '!text-[#56C288]' : '!text-red-500'
              }" :class="">${data[3] > 0 ? `+${data[3]}` : data[3]}${
        data[3] === 'N/A' ? '' : '%'
      }</span>
            </div>
          <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Engagement</span>
              <span class="text-[#69788E] text-sm font-bold">${data[6]?.toLocaleString(
                'en-US',
                {
                  notation: 'compact',
                  compactDisplay: 'short',
                }
              )}</span>
            </div>
          <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Avg. Posts/Week</span>
              <span class="text-[#69788E] text-sm font-bold">${data[7]}</span>
            </div>
        </div>
      `
    }

    /**
     * @description creates data for bar chart
     */
    const getBarChartData = () => {
      return {
        data: [
          ...props.data?.map((item) => [
            item[id.value],
            item[props.platformName === 'instagram' ? 'slug' : 'name'],
            item.followersCount,
            item.followersCountDiff,
            item.image,
            item.name,
            item.averageEngagement,
            item.averagePostsPerWeek,
          ]),
        ],
        color: [...props.data?.map((item) => item.color)],
        cursor: 'auto',
      }
    }

    /**
     * @description sets the background styling for xAxis label
     */
    const getXAxisBackgroundStyle = () => {
      return {
        borderRadius: 20,
        borderColor: 'gray',
        borderWidth: 0.5,
      }
    }

    // computed chart options. uses helper methods & props to generate the options
    const chartOptions = computed(() => {
      if (props.isAllDataFetched) return {}
      return {
        legend: props.legend.show,
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              color: '#61BAE4',
              backgroundColor: '#E6F7FF',
            },
            crossStyle: {
              color: '#61BAE4',
              width: 1,
            },
          },
          formatter: tooltipFormatter,
        },
        grid: {
          left: 35,
          top: 30,
          right: 40,
          bottom: 50,
          show: true,
          width: 'auto',
          borderColor: '#E9EFF6',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          name: props.xAxisLabel,
          nameLocation: 'center',
          nameGap: 60,
          nameTextStyle: {
            color: '#979CA0',
          },
          axisPointer: {
            type: 'shadow',
            label: {
              show: false,
            },
          },
          axisLabel: {
            color: '#979CA0',
            interval: 0,
            align: 'center',
            ...getXAxisBackgroundStyle(),
            margin: 14,
            formatter: (value) => {
              return '{' + value + '| }'
            },
            rich: {
              ...getLabels(),
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'white',
            },
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            lineStyle: {
              color: '#E9EFF6',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            name: props.yAxisLabel,
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            nameTextStyle: {
              color: '#979CA0',
            },
            axisLabel: {
              color: '#979CA0',
              fontSize: 12,
              fontWeight: 400,
              formatter: (value) => {
                return value?.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
            axisPointer: {
              type: 'line',
              label: {
                formatter: (dataPoint) => {
                  return dataPoint.value?.toLocaleString('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  })
                },
              },
            },
            splitLine: {
              lineStyle: {
                color: '#F2F4F6',
              },
            },
          },
        ],
        series: [
          {
            name: props.yAxisLabel,
            type: 'bar',
            barMaxWidth: 30,
            colorBy: 'data',
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
            },
            barCategoryGap: '70%',
            yAxisIndex: 0,
            encode: {
              x: 0,
              y: 2,
            },
            label: {
              show: true,
              position: 'top',
              distance: 0,
              precision: 2,
              formatter: (barData) => {
                labelBgColor.value =
                  barData.data[3] > 0 ? 'positive' : 'negative'
                const value = barData.data[3]
                return value === 'N/A'
                  ? ''
                  : value === 0
                  ? '0%'
                  : `{${labelBgColor.value}|${value > 0 ? '▲+' : '▼'}${value}%}`
              },
              rich: {
                positive: {
                  fontSize: 13,
                  color: '#56C288',
                  padding: [2, 3],
                  borderRadius: 3,
                  fontWeight: 'bold',
                },
                negative: {
                  fontSize: 13,
                  color: 'rgb(248 113 113)',
                  padding: [2, 3],
                  borderRadius: 3,
                  fontWeight: 'bold',
                },
              },
            },
            ...getBarChartData(),
          },
        ],
      }
    })

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => [chartRef.value, chartOptions.value],
      () => {
        if (!chartRef.value) return
        setup(chartOptions.value)
      }
    )

    return {
      // data
      chartRef,

      // methods
      commonMethods,
      getComponentInfoTooltip,
    }
  },
})
</script>
