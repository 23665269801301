<template>
  <div
    class="relative inline-block m-0 hover:text-black-900 cst-simple-checkbox"
    :class="{ 'opacity-60': disabled }"
  >
    <label
      class="cursor-pointer m-0 p-0 flex items-center justify-between"
      :class="{ 'flex-row-reverse': reverse }"
    >
      <span v-show="hasLabelSlot" class="flex items-center text-sm">
        <slot name="label"></slot>
      </span>

      <span class="text-xs" :class="{ 'ml-1.5': reverse }">
        <slot name="count"></slot>
      </span>
      <input
        ref="simpleCheckbox"
        v-model="model"
        class="opacity-0 absolute"
        name="name"
        :checked="isChecked"
        :disabled="disabled"
        type="checkbox"
        :value="name"
        :indeterminate.prop="indeterminate"
        @change="$emit('change', $event)"
      />
      <slot name="input">
        <div
          class="
            check-icon
            relative
            cursor-pointer
            rounded-md
            w-5
            h-5
            flex flex-shrink-0
            justify-center
            items-center
            focus-within:border-blue-500
          "
          :class="{ 'ml-2': hasLabelSlot && !reverse }"
        >
          <svg
            class="
              fill-current
              hidden
              w-2.5
              h-2.5
              text-blue-500
              pointer-events-none
            "
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fill-rule="evenodd">
              <g
                transform="translate(-9 -11)"
                fill="#0088cc"
                fill-rule="nonzero"
              >
                <path
                  d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                />
              </g>
            </g>
          </svg>
          <span v-tooltip.left="'Selected in some posts'" class="hidden">
            <svg
              class="w-5 h-5"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="19" height="19" rx="5" fill="#0088cc" />
              <line
                x1="5"
                y1="10"
                x2="15"
                y2="10"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SimpleCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: [Array, Boolean],
      default: () => [],
    },
    name: {
      type: [String, Number], // eslint-disable-line vue/require-prop-type-constructor
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    }
  },
  computed: {
    hasLabelSlot() {
      return !!this.$slots.label
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    checked(val) {
      this.isChecked = val
    },
  },
  mounted() {
    this.isChecked = this.checked
  },
}
</script>

<style scoped>
input + div {
  background-color: transparent;
  border: 1.5px solid #99a3a982;
  border-radius: 0.3rem;
  width: 16px;
  height: 16px;
}
input:checked + div {
  @apply border-blue-300;
}
input:checked + div svg {
  @apply block;
}
input:indeterminate + div span {
  @apply block;
}
</style>
