<script setup>
import { watch } from 'vue'
import { isEqual, cloneDeep } from 'lodash'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import NoContent from '@/src/modules/planner_v2/components/SocialMediaViewer/NoContent'
import StoryItem from '@/src/modules/planner_v2/components/SocialMediaViewer/Instagram/StoryItem'

const {
  filteredStoryItems,
  storyItems,
  hasFilteredStoryItems,
  handleMove,
  handleDragStart,
  handleDragEnd,
} = useInstagramGridView()

/**
 * Watcher function that monitors changes in the filteredItemsAndRemoteMedia value.
 * If the length of the feedItems array is different from the length of the new value,
 * it updates the feedItems array with a deep clone of the new value.
 * If the lengths are equal, it performs a deep comparison between the feedItems array and the new value,
 * and updates the feedItems array if they are not equal.
 *
 * @param {Function} callback - The callback function to be executed when the filteredItemsAndRemoteMedia value changes.
 * @param {Object} options - The options object for the watcher.
 */
watch(
  () => filteredStoryItems.value,
  (newVal) => {
    // If the lengths of the arrays are not equal or the arrays are not deeply equal, clone newVal into feedItems.value
    if (!isEqual(storyItems.value, newVal)) {
      storyItems.value = cloneDeep(newVal)
    }
  },
  {
    onCleanup: () => {
      storyItems.value = []
    },
  }
)
</script>

<template>
  <template v-if="hasFilteredStoryItems">
    <data class="grid grid-cols-9">
      <template v-for="(element, index) in storyItems" :key="element._id">
        <StoryItem
          :item="element"
          :index="index"
          :class="{
            draggable: element.isDraggable,
            'not-draggable': !element.isDraggable,
            dropping: element.dropping,
            dragging: element.dragging,
          }"
          :is-dropping="element.dropping"
          draggable="true"
          @dragover="handleMove"
          @dragstart="handleDragStart"
          @dragend="handleDragEnd"
        />
      </template>
    </data>
  </template>

  <NoContent v-else platform="Instagram" />
</template>

<style scoped>
.dragging {
  border: 2px dashed #808080;
  border-radius: 2px;
  opacity: 0.7;
}
.dropping {
  border: 2px dashed #4a90e2;
  border-radius: 4px;
  opacity: 0.5;
}
.not-draggable {
  cursor: no-drop;
}
.draggable {
  cursor: grab;
}
</style>
