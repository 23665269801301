<template>
  <div>
    <!--DropdownAccordion::start-->
    <div
      v-b-toggle="dropdownId"
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <!--AccordionContent::start-->
      <div class="flex flex-row space-x-2 items-center w-10/12">
        <!--PlanImage::end-->
        <div>
          <img
            :src="planThumbnail"
            class="h-24 w-32 rounded-sm"
            alt="post_comment"
          />
        </div>
        <!--PlanImage::end-->

        <!--PlanMessage::start-->
        <div class="font-normal line-clamp-4 select-text">
          {{ truncatedMessage }}
        </div>
        <!--PlanMessage::end-->
      </div>
      <!--AccordionContent::end-->
      <div class="flex space-x-2">
        <img
          class="w-5 h-5"
          :src="require('@assets/img/icons/error_exclamation.svg')"
          alt="error"
        />
        <span>{{ errorsLength }} error(s)</span>
      </div>
    </div>
    <b-collapse
      :id="dropdownId"
      accordion="my-accordion"
      role="tabpanel"
      visible
    >
      <div class="p-3 flex flex-column space-y-2">
        <template v-for="(error, key) in errors" :key="`plan_issue_${key}`">
          <!--Error::start-->
          <span>
            <img
              class="w-5 h-5 mr-2"
              src="@assets/img/composer/error-icon.svg"
              alt="plan_image"
            />
            {{ error }}
          </span>
          <!--Error::end-->
        </template>
      </div>
    </b-collapse>
    <!--DropdownAccordion::start-->
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'PlanIssuesDropdown',
  props: {
    dropdownId: {
      type: String,
      default: 'plan_issues',
    },
    plan: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const sharingDetails = computed(() => {
      if (props.plan.common_box_status) return props.plan.common_sharing_details
      else {
        const accountSelection = props.plan.account_selection
        for (const key in accountSelection) {
          if (
            accountSelection[key].length &&
            props.plan[`${key}_sharing_details`].message.length
          ) {
            return props.plan[`${key}_sharing_details`]
          }
        }
      }
    })

    const truncatedMessage = computed(() => {
      const stringLimit = 200
      if (sharingDetails.value?.message?.length > stringLimit) {
        return sharingDetails.value.message.slice(0, stringLimit) + '...'
      } else {
        return sharingDetails.value?.message
      }
    })

    const planThumbnail = computed(() => {
      if (sharingDetails.value?.video?.link) {
        return sharingDetails.value.video?.thumbnail
      }

      if (sharingDetails.value?.image?.length) {
        return sharingDetails.value?.image[0]
      }

      return require('@src/assets/img/no_data_images/no_media_found.svg')
    })

    const errorsLength = computed(() => {
      return props.errors.length
    })

    return {
      sharingDetails,
      truncatedMessage,
      planThumbnail,
      errorsLength,
    }
  },
})
</script>

<style scoped></style>
