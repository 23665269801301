<template>
  <div class="topics-pane">
    <TopicsSidebar />
    <div class="topics-top-pane force-white-bg">
      <div class="topics-top-pane__inner">
        <div class="topics-top-pane__inner__left_section">
          <div class="topics-top-pane__inner__left_section__inner">
            <h3>Curated Topic</h3>
            <h2 v-if="curated_topic.name">{{ curated_topic.name }}</h2>
          </div>
        </div>
        <div class="topics-top-pane__inner__right_section">
          <div class="topics-top-pane__inner__right_section__content_types">
            <div
              class="topics-top-pane__inner__right_section__content_types__buttons"
            >
              <!--              for trending issue resolved, go to trending content -> insights -> back to content issue -->
              <template
                v-if="
                  $route.query &&
                  $route.query.topic &&
                  $route.query.topic === 'News' &&
                  !$route.params.topic_id
                "
              >
                <router-link
                  :to="{
                    name: 'discover_topics_curated_trending',
                    params: { topic_id: $route.params.topic_id },
                    query: { topic: $route.query.topic },
                  }"
                >
                  <i class="icon-Content-Feed_icon"></i>
                  <span>Content Feed</span>
                </router-link>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: 'discover_topics_curated_web_content',
                    params: { topic_id: $route.params.topic_id },
                  }"
                >
                  <i class="icon-Content-Feed_icon"></i>
                  <span>Content Feed</span>
                </router-link>
              </template>

              <router-link
                href="javascript:;"
                :class="{
                  active:
                    $route.name ===
                    'discover_topics_curated_web_insights_review',
                }"
                :to="{
                  name: 'discover_topics_curated_web_insights',
                  params: { topic_id: $route.params.topic_id },
                }"
              >
                <i class="icon-Insights_icon"></i>
                <span>Insights</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <div class="topics-filters-pane__container__left_section" />
        <CuratedAndCustomInsightsDatePicker />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import moment from 'moment'
import TopicsFilterResults from '../../sub-components/TopicsFilterResults'
import TopicsSidebar from '../../sub-components/TopicsSidebar'
import CuratedAndCustomInsightsDatePicker from '../../channel-filters/CuratedAndCustomInsightsDatePicker'

export default {
  components: {
    TopicsSidebar,
    TopicsFilterResults,
    CuratedAndCustomInsightsDatePicker
  },
  data () {
    return {
      total_results: 0,
      curated_topic: {}
    }
  },
  computed: {},
  watch: {
    '$route.params.topic_id' (value) {
      this.curated_topic = {}
      this.fetchCuratedTopic()
    },
    '$route.params.topic' (value) {
      this.curated_topic = {}
      this.fetchCuratedTopic()
    }
  },
  created () {
    this.fetchCuratedTopic()
  },
  methods: {
    fetchCuratedTopic () {
      if (this.$route.params.topic_id) {
        proxy
          .post(`${discoveryBaseUrl}v2/topics/retrieve_curated_topic_by_id`, {
            workspace_id: this.$store.getters.getActiveWorkspace._id,
            topic_id: this.$route.params.topic_id
          })
          .then((res) => {
            if (res.data.data) {
              this.curated_topic = res.data.data
            }
          })
          .catch((err) => {})
      } else {
        // if there is no topic available and user click on the topic label from the grid view item.
        proxy
          .post(`${discoveryBaseUrl}v1/topics/find`, {
            tag: this.$route.query.topic,
            workspace_id: this.$store.getters.getActiveWorkspace._id
          })
          .then((res) => {
            if (res.data.status) {
              this.curated_topic = res.data.data
            }
            return res
          })
          .catch(() => null)
          .finally()
      }
    }
  }
}
</script>
