/**
 * For all the services calls to the backend for analytics v2
 */

import proxy from '@common/lib/http-common'
import {analyticsBaseUrl} from '@src/config/api-utils'
import {useStore} from "@state/base";
import useCompetitorsFactory from "@src/modules/analytics_v3/composables/useCompetitorsFactory";

const {dispatch} = useStore()

export const fetchAnalyticsData = (endpoint = '', payload = {}) => {
    const {platform} = useCompetitorsFactory()
    return new Promise((resolve, reject) => {
        proxy
            .post(
                `${analyticsBaseUrl}analytics/overview/${platform.value}/competitor/${endpoint}`,
                payload,
                {}
            )
            .then((response) => {
                if (response?.data?.data || response?.data?.res) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
            .catch((error) => {
                if (error?.response?.data?.message) reject(error.response.data)
                else reject(error)
            })
    })
}

export const notificationHandler = async (message = '', type = 'error') => {
    await dispatch('toastNotification', {
        message,
        type,
    })
}