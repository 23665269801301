<template>
  <div class="color-border shadow-xs rounded-lg bg-cs-light-gray">
    <div class="relative flex items-center">
      <input
        v-model.trim="input"
        class="border-0 py-3 px-3 rounded-xl bg-cs-light-gray w-full"
        placeholder="YouTube video tags"
        type="text"
        @keydown="handleKeydown"
        @focus="isFocused = true"
        @blur="handleBlur"
      />
      <span
        v-if="isFocused"
        :class="input.length > 10 ? '2xl:hidden' : '2xl:block'"
        class="
          absolute
          right-1
          px-1
          2xl:px-2
          py-1
          rounded-lg
          text-xs
          2xl:text-sm text-gray-600
          bg-gray-400
        "
      >
        Press , or Enter to add
      </span>
    </div>
    <div v-if="localTags.length" class="flex flex-wrap items-center mt-1 p-2">
      <span
        v-for="(tag, index) in localTags"
        :key="index"
        class="
          inline-flex
          items-center
          px-2
          py-1
          mr-2
          mb-1
          text-sm
          bg-green-400/30
          border
          !border-green-500
          rounded-lg
        "
      >
        {{ tag }}
        <i
          class="fas fa-times ml-2 cursor-pointer text-green-500 pt-[1px]"
          v-tooltip="'Remove Tag'"
          @click.prevent="removeTag(index)"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { social } from '@src/modules/publish/store/states/mutation-types'

export default {
  name: 'YoutubeTags',
  props: {
    videoTags: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      input: '',
      localTags: [],
      isFocused: false,
    }
  },
  computed: {
    ...mapGetters(['getYoutubeOptions']),
    youtubeTags() {
      return this.getYoutubeOptions.tags || []
    },
  },
  watch: {
    youtubeTags: {
      handler(newTags) {
        if (!this.arraysEqual(this.localTags, newTags)) {
          this.localTags = [...newTags]
        }
      },
    },
  },
  mounted() {
    this.localTags = [...this.youtubeTags]
  },
  methods: {
    handleKeydown(e) {
      if (e.key === ',' || e.key === 'Enter') {
        e.preventDefault()
        this.addTag()
      }
    },
    addTag() {
      const tag = this.input.replace(/,+$/, '').trim()
      if (tag && !this.localTags.includes(tag)) {
        this.localTags.push(tag)
        this.$emit('addedTag')
        this.updateStoreTags()
        this.input = ''
      }
    },
    removeTag(index) {
      this.localTags.splice(index, 1)
      this.updateStoreTags()
    },
    handleBlur() {
      this.isFocused = false
      this.addTag()
    },
    updateStoreTags() {
      this.$store.commit(social.SET_YOUTUBE_TAGS, [...this.localTags])
    },
    arraysEqual(a, b) {
      if (a.length !== b.length) return false
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false
      }
      return true
    },
  },
}
</script>