<template>
  <div>
    <div class="filter grid grid-cols-2 gap-1 w-100 mt-2 py-2">
      <CstDropdown  class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-if="selectedFolder && selectedFolder.name" v-slot:selected>
          {{selectedFolder.name}}
        </template>
        <template v-else v-slot:selected>
          Select a Folder
        </template>
          <CstDropdownItem @click="handleFolder({})" >Select a Folder</CstDropdownItem>
          <CstDropdownItem v-for="(item,index) in folders" :key="index" @click="handleFolder(item)">
            {{item.name}}
          </CstDropdownItem>
      </CstDropdown>
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{sort}}
        </template>
        <template v-slot>
          <CstDropdownItem @click="handleSort('Recent')" >Recent</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Relevance')" >Relevance</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Most Shared')" >Most Shared</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Facebook Shares')" >Facebook Shares</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Twitter Shares')" >X (Twitter) Shares</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Pinterest Shares')" >Pinterest Shares</CstDropdownItem>
          <CstDropdownItem @click="handleSort('Reddit Shares')" >Reddit Shares</CstDropdownItem>
        </template>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length ===  0" class="p-3" >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template  v-else-if="posts && posts.length > 0">
        <template v-for="(post, index) in posts" :key="index">
          <div
               :draggable="true"
                class="flex my-2 w-100 p-2 rounded-lg bg-cs-white border hover:bg-cs-foggray hover:shadow-sm"
               @dragstart="dragstartHandler($event, post)">
            <div class="image my-2 w-28 min-h-full">
              <div
class="w-full h-full bg-cover bg-no-repeat bg-center rounded-md"
                   :style="{ 'background-image': 'url(' + backgroundImageURL(post._source) + ')' }">
                <img class="max-w-full max-h-full opacity-0"  :src="backgroundImageURL(post._source)" alt="">
              </div>
            </div>
            <div class="content w-full px-2">
              <div class="font-bold">{{ limitTextLength(post._source.title, 65) }}</div>
              <div class="my-1 font-normal text-xs text-gray-800">{{trimURL(post._source.domain_url) }}</div>
              <div class="my-1 font-normal text-xs text-gray-800">{{ $filters.relative(post._source.post_date) }}</div>
              <hr>
              <div class="flex justify-between">
                <i class="icon-engagement-ico"></i>
                <i class="cs-happy-face text-yellow-500 font-bold"></i>
                <span class="text-cs-primary cursor-pointer" @click="handleFavArticlePreview({ id: post._source.id, index: index })">Preview <i class="fas fa-arrow-right"></i></span>
              </div>
            </div>
          </div>
        </template>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template v-slot:no-results>
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
    </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import {removeHtmlTags} from "@common/lib/helper";
import NoResult from './NoResult'
export default {
  name: "Favourite",
  components: {
    InfiniteLoading,
    NoResult,
    CstDropdown,
    CstDropdownItem
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sort: {
      type: String,
      default: '',
    },
    folders: {
      type: Array,
      default: () => []
    },
    selectedFolder: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods:{
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'article',
            heading: post._source.title,
            media: this.backgroundImageURL(post._source),
            p: this.truncateString(
                removeHtmlTags(post._source.description),
                400
            ) || '',
            link: post._source.url || ''
          })
      )
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleFolder(folder){
      this.$emit('handle-change','folder',folder)
    },
    handleSort(sort){
      this.$emit('handle-change','sort',sort)
    },
    handleFavArticlePreview(article){
      this.$emit('handle-change','preview',article)
    },
  }
}
</script>

<style scoped>

</style>
