<script setup>
import { computed, watch } from 'vue'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
})

const {
  multipleSeriesBarChartOptions: barChartOptions,
  legendOptions,
  engagementsData,
  isReportView,
  dataZoomOptions,
  analyticsDesignSystem,
  isNoAnalyticsData,
} = usePinterestAnalytics(props.label)

const isNoData = computed(() => {
  return isNoAnalyticsData(engagementsData)
})

const chartTypes = [
  {
    name: 'Clicks',
    api_response_key: {
      daily: 'pin_clicks_daily',
      total: 'pin_clicks_total',
    },
    color: '#8AC1A2',
  },

  {
    name: 'Outbound Clicks',
    api_response_key: {
      daily: 'outbound_clicks_daily',
      total: 'outbound_clicks_total',
    },
    color: '#8081B9',
  },
  {
    name: 'Saves',
    api_response_key: {
      daily: 'saves_daily',
      total: 'saves_total',
    },
    color: '#F2CA6B',
  },
]

watch(
  () => engagementsData,
  async () => {
    barChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    barChartOptions.value.series = []

    const options = engagementsData.value || {}
    const xAxisData = options?.buckets

    // bar and line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const seriesStack = {
        stack: 'total',
      }
      const barSeries = {
        name: chartType.name,
        type: 'bar',
        color: chartType.color,
        data: options[apiKey.daily] || [],
        yAxisIndex: 0,
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }

      barChartOptions.value.series.push({ ...seriesStack, ...barSeries })
    })
    barChartOptions.value.yAxis = {
      ...barChartOptions.value.yAxis,
      name: '{Clicks|} {OutboundClicks|} {Saves|} {b|Engagements}',
      nameTextStyle: {
        rich: {
          Clicks: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: '#8AC1A2',
          },
          OutboundClicks: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: '#8081B9',
          },
          Saves: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: '#F2CA6B',
          },
          b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
        },
      },
    }
    barChartOptions.value.xAxis.data = xAxisData
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="Engagements-Graph" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Daily Engagement Activity
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Inspect the day-to-day engagement metrics (Pin clicks,
                  outbound clicks and saves) received each day during the
                  selected time period, regardless of the post publication date.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No Pins data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
