import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import useDateFormat from "@common/composables/useDateFormat";
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import EyeIcon from '@src/assets/img/icons/analytic/eye-icon.svg'
import PointSelectIcon from '@src/assets/img/icons/analytic/point-select.svg'
import GraphUpIcon from '@src/assets/img/icons/analytic/graph-up.svg'
import ImageIcon from '@src/assets/img/icons/analytic/image-icon.svg'
import FollowerIcon from '@src/assets/img/icons/analytic/followers-icon.svg'
import CommentIcon from '@src/assets/img/icons/analytic/comment-icon.svg'
import LikeIcon from '@src/assets/img/icons/analytic/like-icon.svg'

import FollowersGrowthIcon from "@/src/assets/img/icons/analytic/colored/followers-growth-icon.svg";
import FollowingIcon from "@/src/assets/img/icons/analytic/colored/following-icon.svg";
import EngagementsColorIcon from "@/src/assets/img/icons/analytic/colored/engagements-color-icon.svg";
import TweetColorIcon from "@/src/assets/img/icons/analytic/colored/tweet-colored-icon.svg";
import ReplyColorIcon from "@/src/assets/img/icons/analytic/colored/reply-colored-icon.svg";
import QuotesColorIcon from "@/src/assets/img/icons/analytic/colored/quotes-colored-icon.svg";
import ListedColorIcon from "@/src/assets/img/icons/analytic/colored/list-colored-icon.svg";
import SavesColorIcon from "@/src/assets/img/icons/analytic/colored/saves-colored-icon.svg";
import RepostIcon from "@/src/assets/img/icons/analytic/colored/repost-icon.svg";
import HeartColorIcon from "@/src/assets/img/icons/analytic/colored/heart-icon.svg";


const { momentWrapper }  = useDateFormat()
const { formatNumber } = useNumber()
const { DEFAULT_DATE_RANGE } = useAnalytics()

// constants
const selectedAccount = ref(null)
const dateRange = ref(DEFAULT_DATE_RANGE)
const sortingOrder = {
  DESC: 'desc',
  ASC: 'asc',
}
const sortingFields = {
  ENGAGEMENT: 'total_engagement',
  LIKES: 'like_count',
  REPLIES: 'reply_count',
  QUOTES: 'quote_count',
  RETWEETS: 'retweet_count',
  IMPRESSION: 'impression_count',
  BOOKMARKS: 'bookmark_count',
}

const routes = {
  PAGE_AND_POST_INSIGHTS: 'getPageAndPostsInsights',
  ENGAGEMENTS_AND_IMPRESSIONS: 'getEngagementImpressionData',
  FOLLOWERS_TREND: 'getFollowersTrendData',
  TOP_PERFORMING_TWEETS: 'getTopTweets',
  LEAST_PERFORMING_TWEETS: 'getLeastTweets',
  CREDITS_USED_COUNT: 'getCreditsUsedCount',
}

const postModalFields = [
  {
    label: 'Retweets',
    key: 'retweets',
    iconSrc: GraphUpIcon,
    tooltip: `The total number of times other X (Twitter) users have shared this tweet (with their own followers) published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    iconSrc: EyeIcon,
    tooltip: `The total number of times this tweet (published during the selected time period) has been displayed on users' screen, based on the lifetime performance.`,
  },
  {
    label: 'Total Engagements',
    key: 'engagement',
    iconSrc: PointSelectIcon,
    tooltip: `The total number of engagement actions (likes, retweets + replies + quotes) on this tweet published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Likes',
    key: 'likes',
    iconSrc: LikeIcon,
    tooltip: `The total number of likes received on this tweet published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Replies',
    key: 'replies',
    iconSrc: CommentIcon,
    tooltip: `The total number of times other X (Twitter) users have responded directly (by mentioning your username in their replies) to this tweet published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Quote Tweets',
    key: 'quotes',
    iconSrc: CommentIcon,
    tooltip: `The total number of times other X (Twitter) users have quoted this tweet (in their own tweets) published during the selected time period, based on the lifetime performance.`,
  },
  {
    label: 'Listed',
    key: 'listed',
    iconSrc: FollowerIcon,
    tooltip: `The total number of times other X (Twitter) users have added your account to a curated X (Twitter) list, based on the lifetime performance.`,
  },
  {
    label: 'Type',
    key: 'tweetType',
    iconSrc: ImageIcon,
    tooltip: `Tweet, Quoted or Reply`,
  },
]

// state
const postsLimit = ref(10)
const postsMaxLimit = ref(40)
const cardsData = ref({})
const followersData = ref({})
const topPostsData = ref({})
const leastPostsData = ref({})
const creditsUsedCount = ref(0)
const engagementsAndImpressionsData = ref({})
const platformSelected = ref(null)
const platformJob = ref(null)
const allPlatformJobs = ref(null)

// Filter job data based on the selected platform
const filterJobData = () => {
  if (allPlatformJobs.value) {
    platformJob.value = allPlatformJobs.value?.find(
        (job) => job.platform_id === platformSelected.value?.twitter_id
    )
  }
}

// get weekday based on the day number
const getWeekday = (day) => {
  const weekdays = [
    '', // 0 is not a valid day
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  return weekdays[day]
}

function getDaySuffix(day) {
  if (day === 1 || day === 21 || day === 31) return 'st'
  if (day === 2 || day === 22) return 'nd'
  if (day === 3 || day === 23) return 'rd'
  return 'th'
}

const twitterJobTooltip = () => {
  if (platformJob.value?.job_type === 'daily') {
    return 'Data syncs Every 24 Hours'
  } else if (platformJob.value?.job_type === 'weekly') {
    return `Data syncs Weekly on ${getWeekday(platformJob.value?.trigger_day)}`
  } else if (platformJob.value?.job_type === 'monthly') {
    const day = parseInt(platformJob.value?.trigger_day)
    return `Data syncs ${day}${getDaySuffix(day)} of every Month`
  } else if (platformJob.value?.job_type === 'never') {
    return 'Data syncs Never'
  }
  return 'Data syncs Weekly on Monday'
}
const twitterPostCountTooltip = () => {
  if (platformJob.value && platformJob.value.job_type !== 'never') {
    return `The stats of the last ${platformJob.value.post_count} tweets are updated each time the data is processed.`;
  } else if (platformJob.value && platformJob.value.job_type === 'never') {
    return 'No post count limit is set.';
  } else {
    return 'The stats of the last 40 tweets are updated each time the data is processed.';
  }
}

const totalAccountPosts = computed(() =>
    topPostsData.value?.length > 0 ? topPostsData.value[0]?.total || 0 : 0
)

const cards = ref([
  {
    title: 'Followers',
    key: 'followers_count',
    tooltip:
        'The total number of people following your X (Twitter) account up to the end of the selected time period.',
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Following',
    key: 'following_count',
    tooltip:
        'The total number of X (Twitter) accounts you are following to up to the end of the selected time period.',
    icon: FollowingIcon,
  },
  {
    title: 'Engagement',
    key: 'total_engagement',
    tooltip:
        'The total number of engagement actions (likes, retweets + replies + quotes) on all the tweets published during the selected time period, based on their lifetime performance.',
    icon: EngagementsColorIcon,
  },
  {
    title: 'Tweets',
    key: 'tweet_count',
    tooltip:
        'The total number of tweets published on your X (Twitter) account up to the end of the selected time period.',
    icon: TweetColorIcon,
  },
  {
    title: 'Listed',
    key: 'listed_count',
    tooltip:
        'The total number of times other X (Twitter) users have added your account to a curated X (Twitter) list, up to the end of the selected time period.',
    icon: ListedColorIcon,
  },
  {
    title: 'Replies',
    key: 'reply_count',
    tooltip:
        'The total number of times other X (Twitter) users have responded directly (by mentioning your username in their replies) to your tweets published during the selected time period, based on their lifetime performance.',
    icon: ReplyColorIcon,
  },
  {
    title: 'Retweets',
    key: 'retweet_count',
    tooltip:
        'The total number of times other X (Twitter) users have shared your tweets (with their own followers) published during the selected time period, based on their lifetime performance.',
    icon: RepostIcon,
  },
  {
    title: 'Bookmarks',
    key: 'bookmark_count',
    tooltip:
        'The total number of times other X (Twitter) users have saved your tweets (to their personal collections) published during the selected time period, based on their lifetime performance.',
    icon: SavesColorIcon,
  },
  {
    title: 'Likes',
    key: 'like_count',
    tooltip:
        'The total number of likes received on all the tweets published during the selected time period, based on their lifetime performance.',
    icon: HeartColorIcon,
  },
  {
    title: 'Quote',
    key: 'quote_count',
    tooltip:
        'The total number of times other X (Twitter) users have quoted your tweets (in their own tweets) published during the selected time period, based on their lifetime performance.',
    icon: QuotesColorIcon,
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
      .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
      .join(' - ')
}

const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: card?.icon,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  return {
    title: card.title,
    total: !isNaN(cardsData.value[card.key])
        ? formatNumber(+cardsData.value[card.key])
        : cardsData.value[card.key],
    growth: cardsData.value[card.key + '_growth'],
    difference: cardsData.value[card.key + '_diff'],
    originalValue: cardsData.value[card.key],
    tooltip: card.tooltip,
    icon: card?.icon,
  }
}

const canFetchMedia = computed(() => {
  return !!(selectedAccount.value?.twitter_id && dateRange.value.length > 0)
})

const validPostsTableHeaders = [
  'thumbnail',
  'engagement',
  'likes',
  'retweets',
  'replies',
  'quotes',
  'impressions',
  'bookmarks',
]

const validPostsTableApiKeys = {
  engagement: 'total_engagement',
  likes: 'like_count',
  retweets: 'retweet_count',
  replies: 'reply_count',
  quotes: 'quote_count',
  impressions: 'impression_count',
  bookmarks: 'bookmark_count',
}

const nonSortableItems = ['thumbnail', 'description']

const headerTooltips = {
  engagement:
      'The total number of engagement actions (likes, retweets + replies + quotes) on the tweet published during the selected time period, based on the lifetime performance.',
  likes:
      'The total number of likes received on the tweet published during the selected time period, based on the lifetime performance.',
  retweets:
      'The total number of times other X (Twitter) users have shared the tweet (with their own followers) published during the selected time period, based on the lifetime performance.',
  replies:
      'The total number of times other X (Twitter) users have responded directly (by mentioning your username in their replies) to the tweet published during the selected time period, based on the lifetime performance.',
  quotes:
      'The total number of times other X (Twitter) users have quoted the tweet (in their own tweets) published during the selected time period, based on the lifetime performance.',
  impressions:
      "The total number of times the tweet (published during the selected time period) has been displayed on users' screen, based on the lifetime performance.",
  bookmarks:
      'The total number of times other X (Twitter) users have saved the tweet (to their personal collections) published during the selected time period, based on the lifetime performance.',
}

// object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  engagement: 'Engagement',
  likes: 'Likes',
  retweets: 'Retweets',
  replies: 'Replies',
  quotes: 'Quotes',
  impressions: 'Impressions',
  bookmarks: 'Bookmarks',
}
// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  thumbnail: (value) => value,
  description: (value) => value,
  engagement: (value) => formatNumber(value),
  retweets: (value) => formatNumber(value),
  replies: (value) => formatNumber(value),
  impressions: (value) => formatNumber(value),
  likes: (value) => formatNumber(value),
  quotes: (value) => formatNumber(value),
  bookmarks: (value) => formatNumber(value),
}

const DUAL_GRAPHS_TITLE_AND_TOOLTIPS = {
  FOLLOWER: {
    daily: {
      title: 'Followers Change',
      tooltip:
          'Inspect the number of new followers gained or lost on each specific day during the selected time period.',
    },
    cumulative: {
      title: 'Followers Trend',
      tooltip:
          'Examine the overall growth or decline in followers over time, aggregating daily changes to show a running total, throughout the selected time period.',
    },
  },
  FOLLOWING: {
    daily: {
      title: 'Following Gained',
      tooltip:
          'Inspect the number of new followings gained on each specific day during the selected time period.',
    },
    cumulative: {
      title: 'Following Trend',
      tooltip:
          'Analyze the cumulative growth or decline in followings over time by aggregating daily changes to display a running total for the selected time period.',
    },
  },
}

const DUAL_GRAPHS = {
  FOLLOWER: 'FOLLOWER',
  FOLLOWING: 'FOLLOWING',
}

const getDualGraphTitle = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.FOLLOWER:
      return (
          DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.title || ''
      )
    case DUAL_GRAPHS.FOLLOWING:
      return (
          DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWING[loweredViewMode]?.title || ''
      )
    default:
      return 'Title here'
  }
}

const getDualGraphTooltip = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.FOLLOWER:
      return (
          DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.tooltip || ''
      )
    case DUAL_GRAPHS.FOLLOWING:
      return (
          DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWING[loweredViewMode]?.tooltip ||
          ''
      )
    default:
      return 'ToolTip here'
  }
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

// method to get the api key corresponding to a given header value
const getHeaderApiKey = (header = '') => {
  return validPostsTableApiKeys[header] || ''
}

const isPostDataLoading = ref(false)

export default function useTwitterAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()
  const store = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    TWITTER_JOB_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}) => {
    if (canFetchMedia.value) {
      try {
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          twitter_id: selectedAccount.value?.twitter_id,
          timezone: getters.getActiveWorkspace?.timezone,
          ...extraPayload,
        }

        const { data } = await proxy.post(
            BASE_ENDPOINT + 'twitter/' + type,
            payload
        )

        switch (type) {
          case routes.PAGE_AND_POST_INSIGHTS:
            cardsData.value = data.data
            break
          case routes.ENGAGEMENTS_AND_IMPRESSIONS:
            engagementsAndImpressionsData.value = data
            break
          case routes.FOLLOWERS_TREND:
            followersData.value = data
            break
          case routes.TOP_PERFORMING_TWEETS:
            topPostsData.value = data.top_tweets?.length
                ? transformObject(data.top_tweets)
                : []
            break
          case routes.LEAST_PERFORMING_TWEETS:
            leastPostsData.value = data.least_tweets?.length
                ? transformObject(data.least_tweets)
                : []
            break
          case routes.CREDITS_USED_COUNT:
            creditsUsedCount.value = Number.isFinite(data?.data?.credits_used)
                ? data?.data?.credits_used
                : 0
            break
          default:
            break
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      }
    }
  }

  const transformObject = (data) => {
    return data.map((post) => {
      return {
        id: post.id,
        description: post.tweet_text,
        thumbnail: post.media_url && post.media_url[0],
        engagement: post.total_engagement,
        likes: post.like_count,
        listed: post.listed_count,
        quotes: post.quote_count,
        tweetType: post.tweet_type,
        impressions: post.impression_count,
        bookmarks: post.bookmark_count,
        retweets: post.retweet_count,
        replies: post.reply_count,
        createdAt: post.tweeted_at,
        link: post.permalink,
      }
    })
  }

  const TweetsAndEngagementCharts = ref([
    {
      name: 'Engagements',
      color: '#78CEA0',
      position: 'left',
      api_response_key: 'total_engagement',
    },
    {
      name: 'Tweet Count',
      color: '#8ABAF3',
      position: 'right',
      api_response_key: 'tweet_count',
    },
  ])

  const TweetsAndImpressionsCharts = ref([
    {
      name: 'Impressions',
      color: '#78CEA0',
      position: 'left',
      api_response_key: 'impression_count',
    },
    {
      name: 'Tweet Count',
      color: '#8ABAF3',
      position: 'right',
      api_response_key: 'tweet_count',
    },
  ])

  const videosAndImpressionsChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    yAxis: [
      ...TweetsAndImpressionsCharts.value?.map((chart, i) => ({
        type: 'value',
        sort: 'ascending',
        name: `{a|} {b|${chart.name}}`,
        nameTextStyle: {
          rich: {
            a: {
              backgroundColor: chart.color,
              width: 11,
              height: 11,
              borderRadius: 2,
              display: 'inline-block',
              textAlign: 'center',
              lineHeight: 14,
              fontSize: 12,
            },
            b: {
              color: chart.color,
              fontSize: 12,
              padding: [0, 0, 0, 6],
            },
          },
        },
        position: chart.position,
        nameLocation: 'center',
        nameGap: 40,
        min: 'dataMin',
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => formatNumber(value ? value.toFixed(0) : '0'),
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 'int',
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        axisPointer: {
          label: {
            backgroundColor: chart.color,
            color: 'white',
            formatter: (dataPoint) =>
                formatNumber(dataPoint?.value) ?? dataPoint.value,
          },
        },
        splitLine: {
          show: i === 0,
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      })),
    ],
    series: [],
  })
  const videosAndEngagementChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    yAxis: [
      ...TweetsAndEngagementCharts.value?.map((chart, i) => ({
        type: 'value',
        sort: 'ascending',
        name: `{a|} {b|${chart.name}}`,
        nameTextStyle: {
          rich: {
            a: {
              backgroundColor: chart.color,
              width: 11,
              height: 11,
              borderRadius: 2,
              display: 'inline-block',
              textAlign: 'center',
              lineHeight: 14,
              fontSize: 12,
            },
            b: {
              color: chart.color,
              fontSize: 12,
              padding: [0, 0, 0, 6],
            },
          },
        },
        position: chart.position,
        nameLocation: 'center',
        nameGap: 40,
        min: 'dataMin',
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => formatNumber(value ? value.toFixed(0) : '0'),
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 'int',
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        axisPointer: {
          label: {
            backgroundColor: chart.color,
            color: 'white',
            formatter: (dataPoint) =>
                formatNumber(dataPoint?.value) ?? dataPoint.value,
          },
        },
        splitLine: {
          show: i === 0,
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      })),
    ],
    series: [],
  })

  //  Twitter Jobs Methods

  /**
   * This function fetches all Twitter jobs.
   * It sends a POST request to the 'getTwitterAnalyticsSetting' endpoint with the workspace_id.
   * If the request is successful, it updates the allPlatformJobs value and filters the job data.
   */
  const fetchTwitterJobs = async () => {
    try {
      const response = await proxy.post(
          TWITTER_JOB_ENDPOINT + 'getTwitterAnalyticsSetting',
          {
            workspace_id: getters.getActiveWorkspace._id,
          }
      )
      if (response?.data) {
        allPlatformJobs.value = response.data
        filterJobData()
      }
    } catch (error) {
      console.error('fetch twitter jobs:::', error)
    }
  }

  /**
   * This function creates a new Twitter job.
   * It sends a POST request to the 'createTwitterAnalyticsSetting' endpoint with the necessary data.
   * If the request is successful, it fetches the updated list of Twitter jobs and shows a success notification.
   *
   * @param {Object} payload - Additional data to be sent in the request body.
   * @returns {Promise} - A promise that resolves to the result of the 'toastNotification' dispatch, if the request was successful.
   */
  const createTwitterJob = async (payload = {}) => {
    try {
      const response = await proxy.post(
          TWITTER_JOB_ENDPOINT + 'createTwitterAnalyticsSetting',
          {
            workspace_id: getters.getActiveWorkspace._id,
            platform_id: platformSelected.value?.twitter_id,
            platform_name: platformSelected.value?.name,
            created_by: getters.getProfile?._id,
            updated_by: getters.getProfile?._id,
            ...payload,
          }
      )
      if (response.data) {
        fetchTwitterJobs()
        return store.dispatch('toastNotification', {
          message: 'X (Twitter) Job Created Successfully',
          type: 'success',
        })
      }
    } catch (error) {
      console.error('create twitter job:::', error)
    }
  }

  /**
   * This function updates an existing Twitter job.
   * It sends a POST request to the 'updateTwitterAnalyticsSetting' endpoint with the necessary data.
   * If the request is successful, it fetches the updated list of Twitter jobs and shows a success notification.
   *
   * @param {Object} payload - Additional data to be sent in the request body.
   * @returns {Promise} - A promise that resolves to the result of the 'toastNotification' dispatch, if the request was successful.
   */
  const updateTwitterJob = async (payload = {}) => {
    try {
      const response = await proxy.post(
          TWITTER_JOB_ENDPOINT + 'updateTwitterAnalyticsSetting',
          {
            workspace_id: getters.getActiveWorkspace._id,
            platform_id: platformSelected.value?.twitter_id,
            platform_name: platformSelected.value?.name,
            created_by: getters.getProfile?._id,
            updated_by: getters.getProfile?._id,
            ...payload,
          }
      )
      if (response.data) {
        fetchTwitterJobs()
        return store.dispatch('toastNotification', {
          message: 'X (Twitter) Job Updated Successfully',
          type: 'success',
        })
      }
    } catch (error) {
      console.error('update twitter job:::', error)
    }
  }

  /**
   * This function triggers a Twitter job.
   * It sends a POST request to the 'triggerTwitterAnalyticsJob' endpoint with the workspace_id and platform_id.
   * If the request is successful and the job was triggered successfully, it shows a success notification.
   * If the job was not triggered successfully, it shows an error notification.
   */
  const triggerTwitterJob = async () => {
    try {
      const response = await proxy.post(
          TWITTER_JOB_ENDPOINT + 'triggerTwitterAnalyticsJob',
          {
            workspace_id: getters.getActiveWorkspace._id,
            platform_id: platformSelected.value?.twitter_id,
          }
      )
      if (response.data.status) {
        return store.dispatch('toastNotification', {
          message: 'X (Twitter) Job Triggered Successfully',
          type: 'success',
        })
      } else {
        return store.dispatch('toastNotification', {
          message: 'X (Twitter) Job Triggered Failed',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('trigger twitter job:::', error)
    }
  }

  return {
    analyticsDesignSystem,
    DUAL_GRAPHS,

    // variables
    screenWidth,
    routes,
    legendOptions,
    dataZoomOptions,
    postModalFields,

    isPostDataLoading,
    validPostsTableHeaders,
    validPostsTableApiKeys,
    nonSortableItems,

    // state
    selectedAccount,
    cards,
    dateRange,
    engagementsAndImpressionsData,
    followersData,
    topPostsData,
    leastPostsData,
    barChartOptions,
    lineChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    TweetsAndEngagementCharts,
    TweetsAndImpressionsCharts,
    videosAndEngagementChartOptions,
    videosAndImpressionsChartOptions,
    postsLimit,
    isReportView,
    sortingFields,
    sortingOrder,
    totalAccountPosts,
    cardsData,
    postsMaxLimit,
    creditsUsedCount,

    // twitter jobs
    platformSelected,
    platformJob,

    // methods
    fetchMedia,
    getCardData,
    getFormattedDateRange,
    getDualGraphTitle,
    getDualGraphTooltip,
    isNoAnalyticsData,
    getHeaderTitles,
    getBodyValues,
    getHeaderApiKey,
    getHeaderTooltips,
    axisLabelFormatter,

    // Twitter Jobs
    fetchTwitterJobs,
    createTwitterJob,
    updateTwitterJob,
    triggerTwitterJob,
    filterJobData,
    twitterJobTooltip,
    twitterPostCountTooltip
  }
}
