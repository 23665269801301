<template>
  <b-modal
    :id="`${isModal ? `${type}-double` : `${type}`}`"
    centered
    hide-footer
    hide-header
    :content-class="`${modalContentClass} bg-gray-cs-200`"
    dialog-class="fixed max-w-full m-0 top-[2%] left-[20%] right-[20%]"
    @show="onModalShow"
    @hidden="onModalHidden"
  >
    <!--    modal header-->
    <div class="modal_head">
      <slot name="modal-title"></slot>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        @click="$bvModal.hide(`${isModal ? `${type}-double` : `${type}`}`)"
        >&times;
      </button>
    </div>
    <!--    modal body-->
    <div
      class="modal_body flex flex-col xl:flex-row gap-y-2 xl:gap-5 px-7 pt-[1rem] bg-white absolute left-0 right-0 top-[8%] bottom-[1%] rounded-md"
    >
      <!--      preview section-->
      <div
        class="flex-1 order-1 xl:order-0 w-full border !border-2 !border-gray-500 rounded-xl mb-[1rem] bg-[#fafafa] !p-3.5 !overflow-y-auto"
      >
        <slot name="post-preview"></slot>
      </div>
      <!--      side bar-->
      <div class="order-0 xl:order-1 xl:w-[25%] overflow-y-auto">
        <slot name="sidebar-title"></slot>
        <slot name="sidebar-content"></slot>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
// libraries
import { computed, reactive, defineEmits, defineProps } from 'vue'

const emit = defineEmits(['show', 'hidden'])

defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
})

const state = reactive({
  screenHeight: window?.innerHeight,
})

/**
 * @description adds event listener when the modal is shown
 */
const onModalShow = () => {
  emit('show')
  window.addEventListener('resize', updateModalHeight)
}

/**
 * @description performs the required functionality once the modal is hidden.
 */
const onModalHidden = () => {
  emit('hidden')
  window.removeEventListener('resize', updateModalHeight)
}

/**
 * @description gets the inner height of the main screen, method called when the screen's height is changed
 */
const updateModalHeight = () => {
  state.screenHeight = window.innerHeight
}

/**
 * @description computes the modal class according to the screenHeight
 * @type {ComputedRef<string>}
 */
const modalContentClass = computed(() =>
  state.screenHeight >= 1112 ? 'min-h-[72rem] mt-5' : 'min-h-[92vh] mt-5'
)
</script>

<style scoped lang="scss">
.statistics-hover:hover {
  background-color: #fafafa;
  border-width: 1rem;
  border-color: #409aff !important;
  transition: all 0.4s ease;
}

.media-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
}
</style>
