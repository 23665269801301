<script>
import { mapGetters } from 'vuex'
export default {
  props: ['activeTab'],
  computed: {
    ...mapGetters(['getEvergreenSelection'])
  },
  methods: {
    callStep (step) {
      switch (step) {
        case 1:
          this.$router.push({ name: 'saveEvergreenAutomationAccounts' })
          break
        case 2:
          this.$parent.processStepFirst()
          break
        case 3:
          if (this.$parent.processStepFirst(true)) {
            this.$parent.processStepSecond()
          }
          break
      }
    }
  }
}
</script>

<template>
  <div class="steps_count">
    <div class="count_inner">
      <a
        class="d-flex align-items-center justify-content-center"
        :class="{ active: activeTab === 'first', past: activeTab !== 'first' }"
        @click.prevent="callStep(1)"
      >
        <span>1</span>
      </a>
      <a
        class="d-flex align-items-center justify-content-center"
        :class="{
          active: activeTab === 'second',
          past: activeTab !== 'first' && activeTab !== 'second',
        }"
        @click.prevent="callStep(2)"
      >
        <span>2</span>
      </a>
      <a
        class="d-flex align-items-center justify-content-center"
        :class="{
          active: activeTab === 'third',
          past:
            activeTab !== 'first' &&
            activeTab !== 'second' &&
            activeTab !== 'third',
        }"
        @click.prevent="callStep(3)"
      >
        <span>3</span>
      </a>
    </div>
  </div>
</template>
