import Vue, {createApp, defineComponent} from 'vue'

export const conversationOptions = [
  {
    name: 'Unassigned',
    key: 'UNASSIGNED',
    image: 'icon-Unassigned',
    isActive: 'active',
  },
  {
    name: 'Mine',
    key: 'MINE',
    image: 'icon-Mine',
    isActive: '',
  },
  {
    name: 'Assigned',
    key: 'ASSIGNED',
    image: 'icon-Assigned',
    isActive: '',
  },
  {
    name: 'Marked As Done',
    key: 'MARKED_AS_DONE',
    image: 'icon-Mark-as-Done',
    isActive: '',
  },
  {
    name: 'Archived',
    key: 'ARCHIVED',
    image: 'icon-Archived-2',
    isActive: '',
  },
  {
    name: 'All',
    key: 'ALL',
    image: 'icon-All',
    isActive: '',
  },
]
export const conversationCounts = {
  ALL: 0,
  UNASSIGNED: 0,
  MINE: 0,
  ASSIGNED: 0,
  MARKED_AS_DONE: 0,
  ARCHIVED: 0,
}

export const filterTypes = [
  {
    name: 'Private Replies',
    key: 'conversation',
  },
  {
    name: 'Posts',
    key: 'post',
  },
  {
    name: 'Reviews',
    key: 'review',
  },
]

export const orderFilters = {
  desc: {
    name: 'Newest first',
    icon: 'fa-arrow-up',
  },
  asc: {
    name: 'Oldest first',
    icon: 'fa-arrow-down',
  },
}

export const teamMemberFilters = {
  ASSIGNED: 'Assigned to:',
  MARKED_AS_DONE: 'Marked done by:',
  ARCHIVED: 'Archived by:',
}

export const socialChannelsArray = [
  {
    name: 'facebook',
    key: 'facebook_id',
    getter: 'getFacebookAccounts',
  },
  {
    name: 'instagram',
    key: 'instagram_id',
    getter: 'getInstagramAccounts',
  },
  {
    name: 'twitter',
    key: 'twitter_id',
    getter: 'getTwitterAccounts',
  },
  {
    name: 'linkedin',
    key: 'linkedin_id',
    getter: 'getLinkedinAccounts',
  },
  {
    name: 'gmb',
    key: 'name',
    getter: 'getGmbAccounts',
  }
]

export var inboxFilters = {
  filter: null
}

/* export var inboxFilters = new Vue({
  data: {
    filter: null,
  },
}) */
