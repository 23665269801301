<script>
import { mapGetters } from 'vuex'
import { swalAttributes } from '@common/constants/common-attributes'
import { article } from '@src/modules/automation/store/recipes/mutation-types'
import { publish } from '@src/modules/publish/store/states/mutation-types'
import SelectedPlatforms from '../../SelectedPlatforms'
import ListingFilters from './ListingFilters'

export default {
  components: {
    ListingFilters,
    SelectedPlatforms,
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getArticleBlogAutomationListing',
      'getAutomationLoaders',
      'getWorkspaces',
      'getArticleAutomationListing',
      'getProfile',
      'getArticleAutomationLoaders',
    ]),
    automationStatus: {
      get() {
        return this.getArticleBlogAutomationListing.items.map(
            (automation) => automation.status === 1
        );
      },
      set(newValues) {
        this.getArticleBlogAutomationListing.items.forEach(
            (automation, index) => {
              automation.status = newValues[index] ? 1 : 0;
            }
        );
      },
    },
  },
  watch: {
    watch: {
      'getActiveWorkspace._id'(id) {
        if (id) {
          console.log('SocialListing.vue -> workspace id -> ', id)
          this.initBlogAutomationsListing()
        } else {
          console.log('SocialListing.vue -> without workspace id -> ', id)
        }
      },
      'getArticleAutomationListing.selected.length'(value) {
        const result =
          value === this.getArticleSocialAutomationListing.items.length
        this.$store.commit(
          article.SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER,
          result
        )
      },
    },
  },
  created() {
    this.initBlogAutomationsListing()
  },
  mounted() {
    document.addEventListener('keyup', this.keyPressListener)
  },
  unmounted() {
    document.removeEventListener('keyup', this.keyPressListener)
  },

  methods: {
    changeStatus(automation, index) {
      automation.status = this.automationStatus[index] ? 1 : 0
      this.$store.dispatch('changeArticleAutomationStatus', automation)
    },
    initBlogAutomationsListing() {
      console.debug('Method::initializeArticleSocialAutomationListingSection')
      this.$store.commit(publish.SET_PLATFORMS_SELECTION, [])
      this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
      this.$store.commit(article.SET_SELECTED_ARTICLE_AUTOMATIONS, [])
      this.$store.commit(
        article.SET_ARTICLE_AUTOMATION_SELECT_ALL_FILTER,
        false
      )
      if (this.getWorkspaces.activeWorkspace._id) {
        this.$store.dispatch('fetchSocialAccounts')
        this.$store.dispatch('fetchArticleToBlogAutomations')
        // if (this.getArticleSocialAutomationListing.fetchStatus === false) {
        //   this.fetchArticleSocialAutomations()
        // }
      }
    },
    keyPressListener(event) {
      console.debug('Method::keyPressListener', event.keyCode)
      if (this.$route.name === 'articleBlogAutomationListing') {
        if (event.keyCode === 27) {
          this.$router.push({
            name: 'automation',
            params: { workspace: this.getWorkspaces.activeWorkspace.slug },
          })
          return false
        }
      }
    },
    removeArticleToBlogDialog(automation, index) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this campaign?', {
          title: 'Remove Campaign',
          ...swalAttributes(),
        })
        .then((value) => {
          if (value) {
            automation.index = index
            automation.is = 'blog'
            this.$store.dispatch('deleteArticleAutomation', automation)
          }
        })
        .catch((err) => {
          console.error(err)
          // An error occurred
        })
    },
    changeArticleToBlogSelectAll() {
      console.debug('Method:changeArticleToSocialSelectAll')
      const automations = this.getArticleAutomationListing
      const list = automations.select_all
        ? automations.blog.items
            .filter((item) => item.lock === 0)
            .map((item) => item._id)
        : []
      this.$store.commit(article.SET_SELECTED_ARTICLE_AUTOMATIONS, list)
    },
  },
}
</script>

<template>
  <div class="max_container_1800 automation_main_component">
    <div class="component_inner">
      <!--<confirm-box id="changeArticleAutomationStatus"  confirmText="Yes" cancelText="No" :callBackAction="changeArticleAutomationStatus">-->

      <!--<template v-slot:header>-->
      <!--<h2>Change automation status</h2>-->
      <!--</template>-->

      <!--<template v-slot:description>-->
      <!--<p>By making this automation inactive, its:</p>-->
      <!--</template>-->

      <!--<template slot="alert_list">-->

      <!--<ul class="alert_list warning">-->
      <!--<li>Posts will be removed.</li>-->
      <!--</ul>-->

      <!--</template>-->

      <!--<template v-slot:footer>-->
      <!--<p class="text-center">This action cannot be undone.</p>-->
      <!--</template>-->

      <!--</confirm-box>-->

      <div class="top_content_listing">
        <div class="d-flex align-items-center">
          <div class="col-md-4 text-left nopad-l">
            <router-link
              class="btn btn-studio-theme-grey-space"
              :to="{
                name: 'automation',
                params: { workspace: getWorkspaces.activeWorkspace.slug },
              }"
            >
              <i
                style="color: #ffffff"
                class="cs-angle-left icon_left arrow_right_active"
              ></i>
              <span>Back</span>
            </router-link>
          </div>

          <h2 class="col-md-4 text-center">View Existing Campaigns</h2>
          <p class="col-md-4 text-right nopad-r"
            >Timezone: {{ getWorkspaces.activeWorkspace.timezone }}
            (<clock />)
          </p>
        </div>

        <img
          src="../../../../../assets/img/automation/article_blog.svg"
          alt=""
        />
        <h3>Article to Blog</h3>
        <p>Share articles on your blog with smart rules and filters.</p>
      </div>
      <div class="automation_listing">
        <div class="page_inner">
          <ListingFilters type="Blog"></ListingFilters>

          <div class="table_box">
            <table class="table-sm">
              <thead>
                <tr>
                  <th class="text-center">
                    <div class="field_group">
                      <div class="checkbox_container">
                        <label for="select_all">
                          <input
                            id="select_all"
                            v-model="getArticleAutomationListing.select_all"
                            type="checkbox"
                            :disabled="
                              getArticleBlogAutomationListing.items.length < 1
                            "
                            @change="changeArticleToBlogSelectAll"
                          />
                          <span class="check"></span>
                        </label>
                      </div>
                    </div>
                  </th>
                  <th class="text-center">Status</th>
                  <th>Name</th>
                  <th class="text-center" style="width: 200px"
                    >Platforms Selected</th
                  >
                  <th class="text-center"
                    >Next Refill Time
                    <i
                      v-tooltip.top="
                        'Posts for the next cycle are planned and added in the calendar at the execution time of the 2nd last post of the current cycle.'
                      "
                      class="fas fa-info-circle"
                      style="margin-left: 5px"
                    ></i
                  ></th>
                  <th class="text-center">Published Posts</th>
                  <th style="text-align: center; width: 200px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    getArticleAutomationLoaders.blog.retrieve ||
                    getAutomationLoaders.fetchArticleAutomation
                  "
                >
                  <td colspan="7" class="text-center">
                    <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
                  </td>
                </tr>

                <template
                  v-else-if="getArticleBlogAutomationListing.items.length"
                >
                  <template
                    v-for="(
                      automation, index
                    ) in getArticleBlogAutomationListing.items"
                    :key="index"
                  >
                    <tr
                      :class="{ pause_automation: !automation.status }"
                    >
                      <td class="text-center">
                        <div class="field_group">
                          <div class="checkbox_container">
                            <label :for="index">
                              <input
                                :id="index"
                                type="checkbox"
                                :checked="
                                  getArticleAutomationListing.selected.includes(
                                    automation._id,
                                  )
                                "
                                :disabled="automation.lock !== 0"
                                @change="
                                  changeAutomationSelection(
                                    getArticleAutomationListing.selected,
                                    automation._id,
                                    'Article',
                                  )
                                "
                              />
                              <span class="check"></span>
                            </label>
                          </div>
                        </div>

                        <!--<div class="checkbox_input_image">-->
                        <!--<input :id="index" type="checkbox"-->
                        <!--:checked="getArticleAutomationListing.selected.includes(automation._id)"-->
                        <!--:disabled="automation.lock !== 0"-->
                        <!--@change="changeAutomationSelection(getArticleAutomationListing.selected,automation._id,'Article')">-->
                        <!--<label :for="index" class="no_text checkbox_left"></label>-->
                        <!--</div>-->
                      </td>
                      <td class="text-center">
                        <label
                          v-tooltip.top="'Toggle ON/OFF'"
                          class="switch-radio"
                        >
                          <input
                            id="automation_toggle"
                            v-model="automationStatus[index]"
                            type="checkbox"
                            :disabled="automation.lock !== 0"
                            @change="changeStatus(automation, index)"
                          />
                          <div
                            class="slider round"
                            :class="{ disabled: automation.lock !== 0 }"
                          ></div>
                        </label>
                      </td>
                      <td>{{ automation.name }}</td>
                      <SelectedPlatforms
                        :blog_selection="automation.blog_selection"
                        :account_selection="automation.account_selection"
                      ></SelectedPlatforms>
                      <td class="text-center">
                        {{
                          automationNextRefill(automation, 'ArticleAutomation')
                        }}
                      </td>
                      <td v-if="automation.posts" class="text-center">{{
                        automation.posts
                      }}</td>
                      <td v-else class="text-center">0</td>
                      <td class="text-center">
                        <div class="d-flex align-items-center">
                          <clip-loader
                            v-if="automation.lock"
                            class="inline_loader"
                            color="#4165ed"
                            :size="'16px'"
                          ></clip-loader>
                            <i
                              v-tooltip.top="'View Posts'"
                              class="action_icon sm icon-Listview_icon"
                              @click="
                                viewAutomationPosts(
                                  automation._id,
                                  'article_automation',
                                )
                              "
                            >
                            </i>

                            <i
                              v-if="
                                automation.policy && automation.policy.can_edit
                              "
                              v-tooltip.top="'Edit'"
                              class="action_icon sm icon-edit-cs"
                              @click="fetchArticleAutomation(automation._id)"
                            >
                            </i>

                            <i
                              v-if="
                                automation.policy && automation.policy.can_edit
                              "
                              v-tooltip.top="'Duplicate'"
                              class="action_icon sm fa fa-files-o"
                              @click="
                                fetchArticleAutomation(automation._id, true)
                              "
                            >
                            </i>

                            <i
                              v-if="
                                automation.policy &&
                                automation.policy.can_remove
                              "
                              v-tooltip.top="'Remove'"
                              class="action_icon sm icon-delete-cs"
                              @click.prevent="
                                removeArticleToBlogDialog(automation, index)
                              "
                            >
                            </i>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>

                <tr v-else>
                  <td
                    v-if="getArticleBlogAutomationListing.search"
                    colspan="12"
                    class="text-center"
                  >
                    No results found for your search query, please try again.
                  </td>
                  <td v-else colspan="12" class="text-center"
                    >You have not created any automation yet.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              getArticleBlogAutomationListing.nextPage ||
              getArticleBlogAutomationListing.previousPage
            "
            class="field_group text-center"
          >
            <button
              class="btn btn-studio-theme-transparent-grey mr-1"
              :disabled="!getArticleBlogAutomationListing.previousPage"
              @click="
                $store.dispatch(
                  'fetchArticleToBlogAutomations',
                  getArticleBlogAutomationListing.page - 1,
                )
              "
            >
              <i class="icon_left cs-angle-left"></i>
              <span>Prev</span>
            </button>

            <button
              class="btn btn-studio-theme-space"
              :disabled="!getArticleBlogAutomationListing.nextPage"
              @click="
                $store.dispatch(
                  'fetchArticleToBlogAutomations',
                  getArticleBlogAutomationListing.page + 1,
                )
              "
            >
              <span>Next</span>
              <i class="icon_right arrow_right_active cs-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
