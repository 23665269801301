<script setup>
import {reactive} from 'vue'
import { useOnboarding } from '@src/modules/account/composables/useUserOnboarding'
import SideDetails from "@modules/account/views/onboarding/SideDetails.vue";

const { userCredentials } = useOnboarding()

// Defining the component local state
const state = reactive({
  business_type_list: [
    {
      key: 'agency',
      value: 'Agency',
      description: 'Marketing & creative agency managing multiple clients',
      image: 'agency.svg',
      isPopular: true
    },
    {
      key: 'solopreneur',
      value: 'Solopreneur',
      description: 'Individual creators, freelancers and consultants',
      image: 'solopreneur.svg'
    },
    {
      key: 'ecommerce',
      value: 'E-commerce',
      description: 'Online stores, D2C brands, and marketplace sellers',
      image: 'ecommerce.svg'
    },
    {
      key: 'tech_company',
      value: 'Tech Company',
      description: 'SaaS, software companies, and tech startups',
      image: 'tech_company.svg'
    },
    {
      key: 'small_business',
      value: 'Small Business',
      description: 'Local businesses, service providers, and startups',
      image: 'small_business.svg'
    },
    {
      key: 'enterprise',
      value: 'Enterprise',
      description: 'Large organizations with multiple teams',
      image: 'enterprise.svg'
    }
  ],
  account: {
    business_type: ''
  }
})

// Methods
</script>

<template>
  <div class="flex items-center w-full  lg:justify-between h-full">
    <div class="w-[43.5rem] max-w-[43.5rem] 2xl:w-[57.5rem] 2xl:max-w-[57.5rem] flex flex-col gap-4 bg-gray-100 bg-opacity-80 px-10 pt-8 rounded-tl-2xl h-full">
      <img
          src="../../assets/img/logo/contentstudio-logo-original.png"
          class="w-[130px] 2xl:w-[150px]"
          alt="ContentStudio"
      />
      <SideDetails
                   :sub_title="`${userCredentials.firstname}, What best</br>describes your business?`"
                   description="This will help us understand your needs better and deliver a more customized experience." />
    </div>
    <div class="w-full mt-10 lg:mt-0 px-20 2xl:px-28 flex">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
        <div v-for="(item, index) in state.business_type_list"
             :key="index" @click="userCredentials.business_type = item.key"
             :class="{'!border-[#409aff] bg-[#FAFCFF]': userCredentials.business_type === item.key}"
             class="relative flex flex-col items-center justify-between gap-y-4 border border-[#D2D5DF] group hover:!border-[#409aff] rounded-xl py-2 2xl:py-6 px-2 2xl:px-3 cursor-pointer">
          <img v-if="userCredentials.business_type === item.key" src="@assets/img/icons/onboarding/check.svg" alt="selected icon" class="absolute top-3 2xl:top-6 right-2 w-4 2xl:w-5 h-4 2xl:h-5">
          <div class="flex flex-col px-1">
            <div class="flex items-center justify-between mb-2">
              <img class="w-6 h-6 mb-2" :src="require(`@assets/img/icons/onboarding/${item.image}`)" alt="business type" />
              <span v-if="item.isPopular" class="text-xs bg-blue-50 px-2 py-1 rounded-full mb-2"
              :class="{'mr-6': userCredentials.business_type === item.key}"
              > <i class="far fa-crown text-google mr-1"></i>Popular</span>
            </div>

            <p class="font-medium text-sm 2xl:text-md text-[#3C4549] group-hover:!text-[#3C4549] mb-1"
               :class="{'!text-[#409aff]': userCredentials.business_type === item.key}">
              {{ item.value }}
            </p>
            <p class="text-xs 2xl:text-sm text-gray-900">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
