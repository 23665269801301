<template>
  <div>
    <div class="media-tab__header"> Type or Paste Media URL </div>
    <div class="media-tab__body">
      <div class="simple-form__text-field mt-3">
        <label for="url">
          <i class="icon-Search"></i>
        </label>
        <input
          id="url"
          v-model="link"
          type="url"
          placeholder="Type or Paste Media URL"
          :disabled="isUploading"
          @keydown.enter="uploadFiles"
        />
      </div>

      <img
        class="img-fluid"
        :src="link"
        alt=""
        @load="disabledBtn = false"
        @error="disabledBtn = true"
      />
    </div>

    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>

          <TreeSelect
            v-model="selectedFolderValue"
            :normalizer="normalizer"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
          />
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          ml-2
        "
      >
        <button
          class="btn btn-studio-theme-space"
          :disabled="isUploading || link.trim().length === 0 || disabledBtn"
          @click="uploadFiles"
        >
          Upload
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import { MediaHelperMixin } from '../../utils/MediaHelpers'
export default {
  name: 'DirectUploadFileTab.vue',
  components: {

  },
  mixins: [MediaHelperMixin],
  props: ['folders', 'type'],
  data() {
    return {
      selectedFolderValue: 'uncategorized',
      link: '',
      isUploading: false,
      disabledBtn: false,
    }
  },
  computed: {
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id + '-root',
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
  },
  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },
  mounted() {
    if (!isEmpty(this.$route.query.type)) {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        let folderValue = this.$route.query.folderId

        if (this.$route.query.root) {
          folderValue = folderValue + '-root'
        }

        this.selectedFolderValue = folderValue
      }
    }
  },
  methods: {
    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    async uploadFiles() {
      this.isUploading = true

      let folderId = null
      let isRoot = false

      const filters = {
        link: [this.link],
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadLinksFilesHelper(filters, (status, message) => {
        if (status) {
          this.link = ''
          this.isUploading = false
          if (this.type === 'library') {
            this.$bvModal.hide('upload-media-modal')
            EventBus.$emit('refetch-folders')
            EventBus.$emit('refetch-media', { folderId, isRoot })
            EventBus.$emit('refetch-media-limits')
          } else {
            this.$emit('changeTab', 1)
          }
        }
      })
      this.isUploading = false
    },
  },
}
</script>
