<script setup>
import { inject } from 'vue'
import UserMavenxCSIcon from '@assets/img/icons/analytic/usermaven-x-cst.svg'

const root = inject('root')
const { $bvModal } = root
</script>

<template>
  <b-modal
    id="usermaven-connect-modal"
    title="Connect UserMaven"
    size="lg"
    centered
    hide-footer
    hide-header
  >
    <div class="flex flex-col gap-4 p-4">
      <div class="flex justify-center items-center relative">
        <div class="flex flex-col justify-center items-center">
          <img
            :src="UserMavenxCSIcon"
            alt="usermaven and cs icon"
            class="h-[50px]"
          />
        </div>
        <div class="absolute right-0 flex items-center gap-6">
          <a
            href="https://docs.contentstudio.io/article/1045-web-analytics-usermaven"
            target="_blank"
            class="my-1"
            >Learn More</a
          >
          <div
            class="
              text-2xl
              bg-gray-400
              w-10
              h-10
              rounded-lg
              cursor-pointer
              text-center
              pt-1
            "
            @click="$bvModal.hide('usermaven-connect-modal')"
          >
            &times;
          </div>
        </div>
      </div>
      <div>
        <iframe
          width="100%"
          height="460"
          src="https://www.youtube.com/embed/kv5AzD_E-KA?si=4nG_QdLY0OdAJ1ch"
          title="Introducing Usermaven analytics in ContentStudio | Simplify website tracking"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style="border: none"
        ></iframe>
      </div>
    </div>
  </b-modal>
</template>

<style scoped></style>
