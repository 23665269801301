export default function useNumber() {
  const DEFAULT_FORMAT = 'en-US'
  const DEFAULT_NOTATION = 'compact'
  const DEFAULT_COMPACT_DISPLAY = 'short'

  /**
   * @description format number according to the given format, notation and compactDisplay
   * @param number
   * @param format
   * @param notation
   * @param compactDisplay
   * @return {string}
   */
  const formatNumber = (
    number,
    format = DEFAULT_FORMAT,
    notation = DEFAULT_NOTATION,
    compactDisplay = DEFAULT_COMPACT_DISPLAY
  ) => {
    return new Intl.NumberFormat(format, {
      notation,
      compactDisplay,
    }).format(number)
  }

  /**
   * @description round number to 2 decimal places
   * @param number
   * @return {number}
   */
  const roundNumber = (number = 0) => {
    return Math.round((+number + Number.EPSILON) * 100) / 100
  }

  return {
    formatNumber,
    roundNumber,
  }
}
