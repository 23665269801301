<template>
  <div
    id="utm_dropdown_id"
    class="dropdown data_boundary_dd prevent_close_dropdown min_width ml-1 dropdown default_style_dropdown form_dropdown sharing_utm_dropdown utm_dropdown"
  >
    <div
      class="min_width dropdown_header"
      data-display="static"
      data-toggle="dropdown"
      @click="triggerUtmOption(type)"
    >
      <i
        v-tooltip.top="'Add UTM'"
        style="font-size: 23px"
        class="d-block cs-utm"
        data-cy="add-utm"
      >
      </i>
    </div>

    <div class="dropdown-menu dropdown-menu-left">
      <ul class="inner">
        <UtmOptions :nomargin="true"></UtmOptions>

        <div v-if="getPublishSelection.utm.selected" class="field_group">
          <button
            class="btn btn-studio-theme-space"
            data-cy="apply-utm"
            @click.prevent="applyUtmDetails(type)"
            ><span>Apply</span></button
          >
        </div>

        <div v-else class="field_group">
          <button
            class="btn btn-studio-theme-space"
            data-cy="save-utm"
            :disabled="getSaveUtmLoader"
            :class="{ disabled: getSaveUtmLoader }"
            @click.prevent="saveUtms('Sharing', type)"
          >
            <span>Save &amp; Apply</span>
            <clip-loader
              v-if="getSaveUtmLoader"
              :color="'#ffffff'"
              :size="'16px'"
            ></clip-loader>
          </button>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UtmOptions from '../../options/UtmOptions'
export default {
  components: {
    UtmOptions
  },
  props: ['type'],

  data () {
    return {
      utm_presets: 'create'
    }
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getSaveUtmLoader', 'getPublishSelection'])
  },
  methods: {},

  watch: {}
}
</script>
