<script setup>
import { ref, computed } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import VideoLightBox from '@ui/Media/VideoLightBox'
import { allPlanHeadAttachments } from '@common/lib/planner'
import useSocialGridView from "@src/modules/planner_v2/composables/useSocialGridView";
import useTiktokGridView from "@src/modules/planner_v2/composables/SocialMediaManager/Tiktok/useTiktokGridView";
import { capitalizeFirstLetter } from '@/src/modules/common/lib/helper'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import useTime from '@/src/modules/common/composables/useTime'
import ActionsButtons from '@/src/modules/planner_v2/components/ActionButtons'
import useDateFormat from "@common/composables/useDateFormat";
const { getWorkspaceTimeZoneTime } = useTime()
const {
  isVideo,
  getThumbnail,
  getOverlayDetails,
  mediaType,
  isPlanPublishedButDeletedFromInstagram,
  isPlanCarousel,
  isPlanFailedOnSelectedAccount,
  isPlanPublished,
  isPushAndAccepted,
  isPushAndDeclined,
  isPushAndNoOptionIsSelected,
  isViaPushNotification,
} = useInstagramGridView()

const {selectedPost} = useSocialGridView()
const {getAccountDateTimeFormat} = useDateFormat()
const {isPlanPublishedButDeletedFromTiktok} = useTiktokGridView()

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isStory: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  isTiktok: {
    type: Boolean,
    default: false,
  },
})
const overlayDetails = getOverlayDetails(props.item, props.isTiktok)
const imgs = ref()
const visible = ref(false)
const enableTileTooltip = ref(true)

const formattedExecutionDate = computed(() => {
  const executionDate = props?.item?.execution_time?.date

  if (!executionDate) {
    return '-'
  }

  return getWorkspaceTimeZoneTime(executionDate, getAccountDateTimeFormat.value)
})


const styles = computed(() => {
  return {
    backgroundColor: overlayDetails?.badge?.color?.bg,
    color: overlayDetails?.badge?.color?.text,
    fontSize: '12px',
  }
})

const openImageLightBox = (media) => {
  if (media) {
    imgs.value = media
    visible.value = true
  }
}

const handleThumbnailClick = (item) => {
  props.isStory
    ? (selectedPost.value = item)
    : openImageLightBox((item?.common_box_status
              ? item?.common_sharing_details
              :  props.isTiktok ? item.tiktok_sharing_details : item.instagram_sharing_details)?.image)
}

const getPushMessage = (postingDetails) => {
  if (!postingDetails) return false

  if (isPushAndAccepted(postingDetails)) {
    return 'This post is published through ContentStudio via push notification method.'
  }

  if (isPushAndDeclined(postingDetails)) {
    return 'This post was published through ContentStudio via push notification method but it has been marked decline from the mobile app.'
  }

  if (isPushAndNoOptionIsSelected(postingDetails)) {
    return `This post was published through ContentStudio via push notification method but it is still in the pending state. You should continue posting it from mobile ${props.isTiktok ? 'Tiktok' : 'Instagram'} Notifications.`
  }

  return false
}
// check if the post is deleted from content studio - item.posting.filter(i=> i.platform_type==='instagram' &&& i.deleted === true)
const isDeletedFromContentStudio = (item) => {
  const platform = props.isTiktok ? 'TikTok' : 'Instagram'
  return item.posting?.some((i) => i.platform_type === platform && i.deleted === true)
}
const pushMessage = getPushMessage(props.item.posting_details)
</script>

<template>
  <div v-if="overlayDetails?.element" class="p-2 flex items-center gap-6">
    <!-- Thumbnail -->
    <div
        v-tooltip="{
          content: `${
            enableTileTooltip ? 'Preview Post' : ''
          }`,
          maxWidth: enableTileTooltip ? 350 : 0,
          arrow: enableTileTooltip,
        }"
      class="
        w-[6rem]
        h-[5.5rem]
        rounded-full
        flex-shrink-0
        relative
      "
    >
      <template v-if="isVideo(item, isTiktok)">
        <template v-if="!isStory">
          <VideoLightBox :on-grid-view="true" :item="item" :is-tiktok="isTiktok" />
        </template>
        <template v-else>
          <div class="w-full h-full" @click="handleThumbnailClick(item)">
            <img
              class="w-full h-full rounded-lg object-cover pointer-events-none"
              loading="lazy"
              alt="Image"
              :src="getThumbnail(item, isTiktok)"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg'
              "
            />
          </div>
        </template>
        <img v-if="isTiktok" src="@assets/img/common/video-play-outlined.svg" alt="" class="absolute bottom-[0.2rem] left-[0.2rem] drop-shadow w-[0.8rem] h-[0.8rem]">
        <img
            v-else-if="item?.instagram_post_type === 'carousel'"
            alt="Bottom Left Icon"
            class="absolute top-[0.2rem] right-[0.2rem] w-4 h-4"
            src="@assets/img/icons/planner/grid-view/carousal.svg"
        />
        <img
          v-else
          alt="Bottom Left Icon"
          class="absolute top-[0.2rem] right-[0.2rem] w-4 h-4"
          src="@assets/img/icons/planner/grid-view/video-play.svg"
        />
      </template>
      <template v-else>
        <div class="w-full h-full cursor-pointer" @click="handleThumbnailClick(item)">
          <img
            class="w-full h-full rounded-lg object-cover pointer-events-none"
            loading="lazy"
            alt="Image"
            :src="getThumbnail(item, isTiktok)"
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg'
            "
          />
        </div>
        <img
          v-if="(isPlanCarousel(item) || allPlanHeadAttachments(item).length > 1) && !isTiktok"
          src="@assets/img/icons/planner/grid-view/carousal.svg"
          alt="Top Right Icon"
          class="absolute top-2 right-2 w-[0.875rem] h-[0.875rem]"
        />
        <img
            v-else-if="item?.instagram_post_type === 'carousel'"
            alt="Top Right Icon"
            class="absolute top-[0.2rem] right-[0.2rem] w-4 h-4"
            src="@assets/img/icons/planner/grid-view/carousal.svg"
        />
      </template>
      <img
          v-if="isTiktok && item.tiktok_options?.privacy_level === 'MUTUAL_FOLLOW_FRIENDS'"
          v-tooltip="'Mutual Follow Friends Post'"
          src="@assets/img/icons/planner/grid-view/mutual-follow-friends.svg"
          alt=""
          class="drop-shadow w-[1rem] h-[1rem] absolute bottom-[0.2rem] right-[0.2rem]"
          @mouseover="enableTileTooltip = false"
          @mouseout="enableTileTooltip = true"
      >
      <img
          v-else-if="isTiktok && item.tiktok_options?.privacy_level === 'SELF_ONLY'"
          v-tooltip="'Private Post'"
          :src="require('@assets/img/common/private_lock.svg')"
          alt=""
          class="drop-shadow absolute bottom-[0.2rem] right-[0.2rem] w-4 h-4"
          @mouseover="enableTileTooltip = false"
          @mouseout="enableTileTooltip = true"
      >
    </div>

    <!-- Vertical Line -->
    <div class="border !border-r-[0px] h-[38px] border-l border-gray-300"></div>

    <!-- Time and Details Block -->
    <div
      v-tooltip.top="'Open Details'"
      class="flex-grow flex flex-col cursor-pointer"
      @click="item?._id && EventBus.$emit('preview-plan', item?._id)"
    >
      <p v-if="!isTiktok" class="text-sm font-medium !text-[#3B4658]">
        {{ mediaType(item) }}
      </p>
      <p class="text-[12px] text-[#757A8A] mt-1">
        {{ formattedExecutionDate }}
      </p>
      <p class="flex p-0 mt-2">
        <span class="text-[12px] rounded-md px-2 py-1" :style="styles">
          {{ capitalizeFirstLetter(overlayDetails?.message || overlayDetails?.element.status) }}
        </span>

        <span
          v-if="
            item.posting_details !== undefined &&
            isViaPushNotification(item.posting_details) &&
            pushMessage
            && !isTiktok
          "
          class="flex align-items-center gap-2"
        >
          <span class="ml-[8px]">|</span>
          <img
            v-tooltip.bottom="{
              content: `<p class='w-[230px]'>${pushMessage}</p>`,
              allowHTML: true,
            }"
            :src="require('@assets/img/icons/planner/grid-view/info-icon.svg')"
            class="w-[0.8vw] mb-[2px]"
           alt=""/>
        </span>
        <span
          v-else-if="
            (!isTiktok &&
            !isStory &&
            isPlanPublished(item) &&
            !isPlanFailedOnSelectedAccount(item, isTiktok) &&
            isPlanPublishedButDeletedFromInstagram(item))
            ||
            (isTiktok &&
            isPlanPublished(item) &&
            !isPlanFailedOnSelectedAccount(item, isTiktok) &&
            isPlanPublishedButDeletedFromTiktok(item))
          "
          class="flex align-items-center gap-2"
        >
          <span class="ml-[8px]">|</span>
          <span class="text-[12px] text-[#B52D4A]">Deleted</span>
          <img
            v-tooltip.bottom="{
              content: `<p class='w-[230px]'>This post was published through ContentStudio but is no longer available on ${isTiktok ? 'Tiktok' : 'Instagram'}. It appears to have been removed or deleted from your account</p>`,
              allowHTML: true,
            }"
            :src="require('@assets/img/icons/planner/grid-view/info-icon.svg')"
            class="w-[0.8vw] mb-[2px]"
          />
        </span>
        <span
            v-else-if="
           !isStory &&
    isPlanPublished(item) &&
    !isPlanFailedOnSelectedAccount(item, isTiktok) &&
    (isDeletedFromContentStudio(item) || (isTiktok && isPlanPublishedButDeletedFromTiktok(item))
    || (!isTiktok && isPlanPublishedButDeletedFromInstagram(item)))
          "
            class="flex align-items-center gap-2"
        >
          <span class="ml-[8px]">|</span>
          <span class="text-[12px] text-[#B52D4A]">Deleted</span>
          <img
              v-tooltip.bottom="{
              content: `<p class='w-[230px]'>This post was deleted from ContentStudio but is still available on ${isTiktok ? 'Tiktok' : 'Instagram'}. </p>`,
              allowHTML: true,
            }"
              :src="require('@assets/img/icons/planner/grid-view/info-icon.svg')"
              class="w-[0.8vw] mb-[2px]"
          />
        </span>

      </p>
    </div>

    <!-- Buttons -->
    <ActionsButtons :item="item" :is-tiktok="isTiktok" />
  </div>

  <VueEasyLightbox :visible="visible" :imgs="imgs" @hide="visible = false">
  </VueEasyLightbox>
</template>
