<script setup>
import { defineProps, ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  tiktokEmbedHtml: {
    type: String,
    required: true,
  },
})
const tiktokContainer = ref(null)

onMounted(() => {
  if (props.tiktokEmbedHtml) {
    loadTikTokEmbed(props.tiktokEmbedHtml)
  }
})

onBeforeUnmount(() => {
  const script = document.getElementById('tiktok-embed')
  script?.remove()
})

const replaceEscapedQuotes = (str) => {
  return str.replace(/\\"/g, '"')
}

const loadTikTokEmbed = (embedHtml) => {
  const div = document.createElement('div')
  // add id to the script tag to remove it on unmount
  div.id = 'tiktok-embed'
  div.innerHTML = replaceEscapedQuotes(embedHtml)

  const scriptElement = div.querySelector('script')
  if (scriptElement) {
    const script = document.createElement('script')
    script.src = scriptElement.src
    script.async = true
    script.onload = () => {
      tiktokContainer.value.innerHTML = ''
      tiktokContainer.value.appendChild(div)
    }
    document.body.appendChild(script)
  } else {
    tiktokContainer.value.innerHTML = ''
    tiktokContainer.value.appendChild(div)
  }
}
</script>

<template>
  <div ref="tiktokContainer"></div>
</template>
