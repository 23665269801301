<template>
  <div>
    <div
      v-for="(photo, index) in getImagesPosts"
      :key="index"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
      class="assistant_box _flickr _photo"
    >
      <div
        class="box_inner"
        :draggable="$route.name === 'composerBlog'"
        @dragstart="dragstartHandler($event, photo)"
      >
        <div
          class="image_block"
          @click="previewImage(backgroundFlickrPreviewImageURL(photo))"
        >
          <div class="play_icon">
            <i class="cs-eye"></i>
          </div>
          <div
            v-lazy:background-image="backgroundFlickrImageURL(photo)"
            class="img"
          ></div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_flickr_block editor_dragable_box editor_image_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option">
              <div class="left_block">
                <i
                  class="move_left fa fa-align-left editor_tooltip"
                  title="Align Left"
                ></i>
                <i
                  class="move_center fa fa-align-center editor_tooltip"
                  title="Align Center "
                ></i>
                <i
                  class="move_right fa fa-align-right editor_tooltip"
                  title="Align Right"
                ></i>
              </div>
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div
              id=""
              class="align_box flickr_inner_embed"
              :data-link="backgroundFlickrPreviewImageURL(photo)"
              style="text-align: center"
            >
              <div class="inner_embed">
                <div class="custom_loader">
                  <div class="loader_inner"></div>
                </div>
                <a>
                  <img :src="backgroundFlickrPreviewImageURL(photo)" alt="" />
                </a>
              </div>
            </div>
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getImagesPosts'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    backgroundFlickrEmbed (photo) {
      return (
        'https://www.flickr.com/photos/' + photo.owner + '/' + photo.id + '/'
      )
    },
    backgroundFlickrImageURL (photo) {
      return (
        'https://farm' +
        photo.farm +
        '.staticflickr.com/' +
        photo.server +
        '/' +
        photo.id +
        '_' +
        photo.secret +
        '_m.jpg'
      )
    },
    backgroundFlickrPreviewImageURL (photo) {
      return (
        'https://farm' +
        photo.farm +
        '.staticflickr.com/' +
        photo.server +
        '/' +
        photo.id +
        '_' +
        photo.secret +
        '.jpg'
      )
    },
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', photo)

      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'image',
          heading: '',
          media: this.backgroundFlickrPreviewImageURL(photo),
          p: photo.title || '',
          link: this.backgroundFlickrPreviewImageURL(photo)
        })
      )
    }
  }
}
</script>
