<template>
  <div
    id="component-button"
    class="components-uitkit right-side-uitkit-container"
  >
    <h3>Useful Icons</h3>

    <div class="row">
      <input type="text" placeholder="Search Icon here" />
    </div>

    <div class="row useful-icons-contain">
      <div v-for="(icon, k) in icons" :key="k" class="col-area">
        <div class="icons">
          <i :class="icon.name"></i>
          <i :class="icon.name" class="ico-md"></i>
          <i :class="icon.name" class="ico-lg"></i>
        </div>

        <textarea @click="copyCode"><i :class='icon.name'></i></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        { name: 'icon-add-new-member-cs' },
        { name: 'icon-blogs-websites-cs' },
        { name: 'icon-delete-cs' },
        { name: 'icon-download-cs' },
        { name: 'icon-dropdown-cs' },
        { name: 'icon-duplicate-cs' },
        { name: 'icon-flag-cs' },
        { name: 'icon-groups-cs' },
        { name: 'icon-help-cs' },
        { name: 'icon-miscellaneous-cs' },
        { name: 'icon-more-cs' },
        { name: 'icon-notificaion-cs' },
        { name: 'icon-other-integration-cs' },
        { name: 'icon-plan-cs' },
        { name: 'icon-resend-cs' },
        { name: 'icon-settings-cs' },
        { name: 'icon-social-accounts-cs' },
        { name: 'icon-sources-cs' },
        { name: 'icon-star-cs' },
        { name: 'icon-team-member-cs' },
        { name: 'icon-profile-cs' },
        { name: 'icon-failed-cs' },
        { name: 'icon-published-cs' },
        { name: 'icon-schedule-cs' },
        { name: 'icon-missed-review-cs' },
        { name: 'icon-under-review-cs' },
        { name: 'icon-drafts-cs' },
        { name: 'icon-inbox-cs' },
        { name: 'icon-all-cs' },
        { name: 'icon-publishing-cs' },
        { name: 'icon-rejected-cs' },
        { name: 'icon-feeds_icon' },
        { name: 'icon-today_icon' },
        { name: 'icon-stared_icon' },
        { name: 'icon-trending_icon' },
        { name: 'icon-pinterest_icon' },

        { name: 'icon-flicker_icon' },
        { name: 'icon-pixabey_icon' },
        { name: 'icon-facebook_icon' },
        { name: 'icon-link_icon' },
        { name: 'icon-linkedin_icon' },
        { name: 'icon-giphy_icon' },
        { name: 'icon-upload-now_icon' },
        { name: 'icon-twitter_icon' },
        { name: 'icon-google-analytics_icon' },
        { name: 'icon-edit-cs' },
        { name: 'icon-report-builder_icon' },
        { name: 'icon-instagram_icon' },

        { name: 'icon-reports_icon' },
        { name: 'icon-ZtoA' },
        { name: 'icon-Image' },
        { name: 'icon-Film' },
        { name: 'icon-Calendar' },
        { name: 'icon-AtoZ' },
        { name: 'icon-Others' },
        { name: 'icon-Oldest' },
        { name: 'icon-Cancel' },
        { name: 'icon-Down_Sort' },
        { name: 'icon-Up_Sort' },
        { name: 'icon-zapier_icon' },

        { name: 'icon-cancel_icon' },
        { name: 'icon-Insights_icon' },
        { name: 'icon-Content-Feed_icon' },
        { name: 'icon-upload_now_light' },
        { name: 'icon-More-Horizontal_icon' },
        { name: 'icon-Listview_icon' },
        { name: 'icon-Globe_icon' },
        { name: 'icon-Youtube_icon' },
        { name: 'icon-Share_icon' },
        { name: 'icon-Sad-face_icon' },
        { name: 'icon-Happy-face_iconsvg' },
        { name: 'icon-Favorite_icon' },

        { name: 'icon-Archive_icon' },
        { name: 'icon-File-Empty' },
        { name: 'icon-Search' },
        { name: 'icon-language' },
        { name: 'icon-User' },
        { name: 'icon-Password' },
        { name: 'icon-Email' },
        { name: 'icon-Chrome' },
        { name: 'icon-GMB' },
        { name: 'icon-utm' },
        { name: 'icon-replug' },
        { name: 'icon-pocket' },

        { name: 'icon-feedly' },
        { name: 'icon-bitly' },
        { name: 'icon-Msg-Tagging' },
        { name: 'icon-Hashtag' },
        { name: 'icon-Curatedquotes' },
        { name: 'icon-Job-Title' },
        { name: 'icon-Company' },
        { name: 'icon-Location' },
        { name: 'icon-Attachements' },
        { name: 'icon-Resend' },
        { name: 'icon-Checkbox-Disable' },
        { name: 'icon-Checkbox-Active' },

        { name: 'icon-Info' },
        { name: 'icon-Mark-as-Done' },
        { name: 'icon-Mine' },
        { name: 'icon-Archived-2' },
        { name: 'icon-Unassigned' },
        { name: 'icon-All' },
        { name: 'icon-Assigned' },
        { name: 'icon-More-2' },
        { name: 'icon-Conversation' },
        { name: 'icon-Cheecked' },
        { name: 'icon-Archive' },
        { name: 'icon-Link' },

        { name: 'icon-Refresh-2' },
        { name: 'icon-Phone' },
        { name: 'icon-Save' },
        { name: 'icon-Sort-2' },
        { name: 'icon-SMP' },
        { name: 'icon-Move' },
        { name: 'icon-Campaign' },
        { name: 'icon-Label' },
      ],
      msgPreview: 'Copied',
    }
  },
  methods: {
    copyCode(e) {
      const getText = e.target
      getText.select()
      document.execCommand('copy')
    },
  },
}
</script>

<style scoped lang="less">
.useful-icons-contain {
  margin: 1rem 0rem;
  .col-area {
    align-items: center;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin: 1.5rem 0rem;
    i {
      margin: 0rem 0.6rem;
    }
    textarea {
      cursor: pointer;
      white-space: inherit;
      border: 0;
      background: #efefef;
      padding: 0.4rem;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid grey;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
}
</style>
