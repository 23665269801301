h<template>
  <div class="topics-insights-container__chart_full_width">
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ noDataMessage }}
        </p>
      </div>
    <h2> Popular Content Types </h2>
    <div
      id="popularContentTypes"
      class="topics-insights-container__chart_legends"
    />

    <div
      class="topics-insights-container__chart_full_width__chart charts-legends-capitalize"
    >
      <highcharts
        ref="popular_content__chart"
        :options="popularContent"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_categories_list: {}
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,
      popularContent: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} with <span style="text-transform: capitalize;">{point.category}</span>.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popularContentTypes')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#4e92ff', '#2866bb'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: [
            'Listicles',
            'How to',
            'Articles',
            'Video',
            'Podcast',
            'Ebook'
          ],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 30,
            borderRadiusTopRight: 30,
            pointPadding: 0,
            groupPadding: 0.42
          }
        },
        series: [
          {
            // type: 'column',
            name: 'Number of Content Items',
            data: [11, 23, 24, 23, 43, 20],
            yAxis: 1
          },
          {
            // type: 'column',
            name: 'Avg Engagement Per Content',
            data: [11, 13, 44, 73, 3, 5]
          }
        ]
      }
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.isZero()) {
        return true
      }
      return false
    }
  },
  created () {
    if (!this.isZero()) {
      this.popularContent.series[0].data = this.articles_categories_list.docs
      this.popularContent.series[1].data =
        this.articles_categories_list.engagements

      this.popularContent.xAxis.categories =
        this.articles_categories_list.categories
    }
  },
  methods: {
    isZero () {
      return (
        this.articles_categories_list.engagements.length === 0 &&
        this.articles_categories_list.docs.length === 0 &&
        this.articles_categories_list.categories.length === 0
      )
    }
  }
}
</script>
