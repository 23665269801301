import proxy from '@common/lib/http-common'
import {
  processCsvURL,
  fetchCsvPostsURL,
  csvPostActionURL,
  csvPostBulkActionURL,
  csvCheckProcessURL,
} from '@src/modules/setting/config/api-utils.js'
import { fetchCSVAutomationsUrl } from '@src/modules/automation/config/api-utils.js'
import Router from '@src/router'
import {
  bulkCSV,
  evergreen,
} from '@src/modules/automation/store/automation-mutation-type'
import { sharingTypes } from '@src/modules/publish/store/states/mutation-types'

const getDefaultCsvAutomation = () => {
  return {
    file: null,
    fileName: null,
    dateFormat: 'm/d/Y H:i',
    saveLoader: false,
    processLoader: false,
    csvId: null,
    totalProcess: 0,
    processed: 0,
    completed: false,
    validPosts: true,
    content_category_id: '',

    totalPosts: 0,
    approvedPosts: 0,
    errorPosts: 0,
  }
}

const getDefaultCsvAutomationTabStatus = () => {
  return {
    first: true,
    second: false,
    third: false,
    fourth: false,
  }
}

const getDefaultCSVListing = () => {
  return {
    items: [],
    page: 1,
    limit: 20,
    fetchCSVLoader: false,
    fetchCSVPaginationScroll: false,
    selectedItems: [],
    checkAll: false,
    showBulkActionMessage: false,
    bulkActionMessage: '',
  }
}

const getDefaultCSVAutomationListing = () => {
  return {
    items: [],
    search: '',
    page: 1,
    limit: 20,
    nextPage: false,
    previousPage: false,
    fetchStatus: false,
    selected_all: false,
    selected: [],
  }
}

export default {
  state: {
    selection: getDefaultCsvAutomation(),
    tab_status: getDefaultCsvAutomationTabStatus(),
    listing: getDefaultCSVListing(),
    csvListing: getDefaultCSVAutomationListing(),
    approvedLoading: false,
  },
  actions: {
    /**
     * Process CSV.
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    processCsv({ commit, getters, state, dispatch }) {
      const selection = getters.getCsvAutomationSelection
      const socialPlatforms = getters.getAccountSelection
      const firstCommentAccount =
        getters.getSocialSharingFirstComment.first_comment_accounts
      const scheduleOptions = getters.getAutomationScheduleOptions

      if (
        selection.content_category_id &&
        scheduleOptions.time_type === 'content_category' &&
        !getters.getContentCategoryAvailableSlot.available
      ) {
        dispatch('toastNotification', {
          message: 'You have not created any slots for your category.',
          type: 'error',
        })
        return false
      }

      const formData = new FormData()
      formData.append('inputFile', selection.file)
      formData.append('account', JSON.stringify(socialPlatforms))
      formData.append('dateSettingType', scheduleOptions.time_type)
      formData.append('recurring', JSON.stringify(scheduleOptions.recurring))
      formData.append('format', selection.dateFormat)
      formData.append('workspace_id', getters.getWorkspaces.activeWorkspace._id)
      formData.append('content_category_id', selection.content_category_id)
      formData.append(
        'first_comment_accounts',
        JSON.stringify(firstCommentAccount)
      )

      commit('SET_CSV_SAVE_LOADER', true)
      proxy
        .post(processCsvURL, formData)
        .then((resp) => {
          if (resp.data.status === true) {
            commit('SET_CSV_FIRST_TAB_STATUS', false)
            commit('SET_CSV_SECOND_TAB_STATUS', false)
            commit('SET_CSV_THIRD_TAB_STATUS', false)
            commit('SET_CSV_Fourth_TAB_STATUS', true)
            commit('SET_CSV_ID', resp.data.csv_id)
            commit('SET_CSV_TOTAL_PROCESSING', resp.data.total)
            commit('SET_CSV_PROCESSED_POSTS', 0)
            commit(sharingTypes.SET_FIRST_COMMENT_ACCOUNTS, [])
            Router.push({
              name: 'saveBulkCsvAutomation',
              params: { csvId: resp.data.csv_id },
            })
          } else {
            dispatch('toastNotification', {
              message: resp.data.message,
              type: 'error',
            })
          }
          commit('SET_CSV_SAVE_LOADER', false)
        })
        .catch()
    },

    fetchCSVPosts({ commit, getters, state, dispatch }, $state = null) {
      const listing = getters.getCsvListing

      const payload = {
        page: listing.page,
        limit: listing.limit,
        csv_id: getters.getCsvAutomationSelection.csvId,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }

      proxy
        .post(fetchCsvPostsURL, payload)
        .then((resp) => {
          if (resp.data.status === true) {
            if (getters.getCsvListing.page > 1) {
              commit(
                'SET_CSV_LISTING_ITEMS',
                getters.getCsvListing.items.concat(resp.data.items.data)
              )
            } else {
              commit('SET_CSV_LISTING_ITEMS', resp.data.items.data)
              commit('SET_CSV_TOTAL_POSTS', resp.data.total)
              commit('SET_CSV_APPROVED_POSTS', resp.data.approved)
              commit('SET_CSV_ERROR_POSTS', resp.data.errors)
            }

            if (resp.data.items.data.length === 20) {
              commit('SET_CSV_PAGINATION_LOADER', true)
              if ($state) {
                console.debug('$state.loaded')
                $state.loaded()
              }
            } else {
              try {
                commit('SET_CSV_PAGINATION_LOADER', false)
                if ($state) {
                  console.debug('$state.complete')
                  $state.complete()
                }
              } catch (ex) {
                console.log(ex)
              }
            }
          }
          commit('SET_CSV_LISTING_LOADER', false)
        })
        .catch()
    },

    cvsPlanOperations({ commit, getters, state, dispatch }, data) {
      const payload = {
        id: data.id,
        type: data.type,
        csv_id: getters.getCsvAutomationSelection.csvId,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      commit(bulkCSV.SET_APPROVED_LOADER, true)

      proxy
        .post(csvPostActionURL, payload)
        .then((resp) => {
          commit(bulkCSV.SET_APPROVED_LOADER, false)

          if (resp.data.status === true) {
            getters.getCsvListing.items.splice(data.index, 1)
            commit('SET_CSV_TOTAL_POSTS', resp.data.total)
            commit('SET_CSV_APPROVED_POSTS', resp.data.approved)
            commit('SET_CSV_ERROR_POSTS', resp.data.errors)
            if (data.type === 'delete') {
              dispatch('setConfirmActionStage', 'success')
              setTimeout(function () {
                dispatch('setConfirmActionData', '')

                setTimeout(function () {
                  dispatch('setConfirmActionStage', '')
                }, 1000)

                // eslint-disable-next-line no-undef
                $('#removeCsvPlan').modal('hide')
              }, 1000)
            }
          } else {
            if (resp.data.message) {
              dispatch('toastNotification', {
                message: resp.data.message,
                type: 'error',
              })
            } else {
              dispatch('toastNotification', {
                message:
                  'An error occurred while performing action, please try again.',
                type: 'error',
              })
            }
          }
        })
        .catch(() => {
          commit(bulkCSV.SET_APPROVED_LOADER, false)
        })
    },

    bulkCSVOperation({ commit, getters, state, dispatch }, type) {
      if (getters.getCsvListing.selectedItems.length === 0) {
        dispatch('toastNotification', {
          message: 'Please select plan(s) to process.',
          type: 'error',
        })
      } else {
        commit('SET_CSV_BULK_ACTION_MESSAGE_STATUS', true)
        if (type === 'delete') {
          commit('SET_CSV_BULK_ACTION_MESSAGE', 'Deleting posts...')
        } else {
          commit('SET_CSV_BULK_ACTION_MESSAGE', 'Approving posts...')
        }
        // dispatch('toastNotificationFixed', { message: 'Removing......', type: 'info' })
        const payload = {
          id: getters.getCsvListing.selectedItems,
          type,
          csv_id: getters.getCsvAutomationSelection.csvId,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        }
        proxy
          .post(csvPostBulkActionURL, payload)
          .then((resp) => {
            if (resp.data.status === true) {
              commit('SET_CSV_PAGE', 1)
              commit('SET_CSV_CHECK_ALL', false)
              commit('SET_CSV_SELECTED_IDS', [])

              if (type === 'delete') {
                dispatch('setConfirmActionStage', 'success')
                setTimeout(function () {
                  dispatch('setConfirmActionData', '')

                  setTimeout(function () {
                    dispatch('setConfirmActionStage', '')
                  }, 1000)

                  // eslint-disable-next-line no-undef
                  $('#removeCsvPlans').modal('hide')
                }, 1000)
              }
              commit('SET_CSV_BULK_ACTION_MESSAGE_STATUS', false)
              commit('SET_CSV_BULK_ACTION_MESSAGE', '')
              dispatch('fetchCSVPosts')
            } else {
              if (resp.data.message) {
                dispatch('toastNotification', {
                  message: resp.data.message,
                  type: 'error',
                })
              } else {
                dispatch('toastNotification', {
                  message:
                    'An error occurred while performing action, please try again.',
                  type: 'error',
                })
              }
            }
          })
          .catch()
      }
    },

    checkCSVProcessing({ commit, getters, state, dispatch }) {
      const payload = {
        csv_id: getters.getCsvAutomationSelection.csvId,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(csvCheckProcessURL, payload)
        .then((resp) => {
          if (resp.data.status === true) {
            if (resp.data.complete) {
              commit('SET_CSV_COMPLETED_POSTS', true)
            } else {
              commit('SET_CSV_COMPLETED_POSTS', false)
              commit('SET_CSV_TOTAL_PROCESSING', resp.data.total)
            }
            commit('SET_CSV_PROCESS_LOADER', false)
          } else {
            commit('SET_CSV_DEFAULT_STATE')
            Router.push({ name: 'saveBulkCsvAutomation' })
          }
        })
        .catch()
    },

    /**
     * get automation for listing
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     */
    fetchCSVAutomationsListing({ commit, getters, state, dispatch }) {
      const listing = getters.getCSVAutomationListing

      // prepare payload pass search, page etc
      const payload = {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        search: listing.search,
        page: listing.page,
        limit: listing.limit,
        platformSelection: getters.getPublishSelection.platforms.selection,
      }
      commit('setFetchCSVAutomationsLoader', true) // showing loader

      // sending request
      proxy
        .post(fetchCSVAutomationsUrl, payload)
        .then((response) => {
          // save automations in states
          if (response.data.automations.data) {
            commit(
              bulkCSV.SET_BULK_CSV_AUTOMATION_LISTING_ITEM,
              response.data.automations.data
            )
          } else {
            commit(bulkCSV.SET_BULK_CSV_AUTOMATION_LISTING_ITEM, [])
          }

          // process pagination check more pages exists ur not
          let next = false
          let previous = false
          if (response.data.automations.next_page_url) next = true
          if (response.data.automations.prev_page_url) previous = true
          commit(evergreen.SET_BULK_CSV_AUTOMATION_LISTING_PAGINATION, {
            nextPage: next,
            previousPage: previous,
          })

          commit('setFetchCSVAutomationsLoader', false) // hiding loader
        })
        .catch((response) => {
          commit('setFetchCSVAutomationsLoader', false)
        })
    },
  },
  mutations: {
    SET_CSV_FIRST_TAB_STATUS(state, status) {
      state.tab_status.first = status
    },

    SET_CSV_SECOND_TAB_STATUS(state, status) {
      state.tab_status.second = status
    },

    SET_CSV_THIRD_TAB_STATUS(state, status) {
      state.tab_status.third = status
    },

    SET_CSV_Fourth_TAB_STATUS(state, status) {
      state.tab_status.fourth = status
    },

    SET_CSV_FILE(state, file) {
      state.selection.file = file
    },

    SET_CSV_FILE_NAME(state, name) {
      state.selection.fileName = name
    },

    SET_CSV_DATE_FORMAT(state, format) {
      state.selection.dateFormat = format
    },

    SET_CSV_SAVE_LOADER(state, format) {
      state.selection.saveLoader = format
    },

    SET_CSV_PROCESS_LOADER(state, status) {
      state.selection.processLoader = status
    },

    SET_CSV_DEFAULT_STATE(state) {
      state.selection = getDefaultCsvAutomation()
      state.tab_status = getDefaultCsvAutomationTabStatus()
      state.listing = getDefaultCSVListing()
      state.csvListing = getDefaultCSVAutomationListing()
    },

    SET_CSV_ID(state, id) {
      state.selection.csvId = id
    },

    SET_CSV_TOTAL_PROCESSING(state, total) {
      state.selection.totalProcess = total
    },

    SET_CSV_PROCESSED_POSTS(state, processed) {
      state.selection.processed = processed
    },

    SET_CSV_COMPLETED_POSTS(state, status) {
      state.selection.completed = status
    },

    SET_CSV_VALID_POSTS(state, status) {
      state.selection.validPosts = status
    },

    SET_CSV_CONTENT_CATEGORY_ID(state, id) {
      state.selection.content_category_id = id
    },

    SET_CSV_LISTING_ITEMS(state, items) {
      state.listing.items = items
    },

    SET_CSV_LISTING_LOADER(state, status) {
      state.listing.fetchCSVLoader = status
    },

    SET_CSV_PAGINATION_LOADER(state, status) {
      state.listing.fetchCSVPaginationScroll = status
    },

    SET_CSV_PAGE(state, page) {
      state.listing.page = page
    },

    SET_CSV_TOTAL_POSTS(state, total) {
      state.listing.totalPosts = total
    },

    SET_CSV_APPROVED_POSTS(state, approved) {
      state.listing.approvedPosts = approved
    },

    SET_CSV_ERROR_POSTS(state, error) {
      state.listing.errorPosts = error
    },

    SET_CSV_CHECK_ALL(state, status) {
      state.listing.checkAll = status
    },

    SET_CSV_SELECTED_IDS(state, ids) {
      state.listing.selectedItems = ids
    },

    SET_CSV_BULK_ACTION_MESSAGE_STATUS(state, status) {
      state.listing.showBulkActionMessage = status
    },

    SET_CSV_BULK_ACTION_MESSAGE(state, message) {
      state.listing.bulkActionMessage = message
    },

    [bulkCSV.SET_BULK_CSV_LISTING_SELECT_ALL](state, select) {
      state.csvListing.selected_all = select
    },
    [bulkCSV.SET_BULK_CSV_SELECTED_AUTOMATION](state, data) {
      state.csvListing.selected = data
    },

    [bulkCSV.SET_BULK_CSV_AUTOMATION_LISTING_ITEM](state, items) {
      state.csvListing.items = items
    },

    [bulkCSV.SET_BULK_CSV_AUTOMATION_LISTING_PAGINATION](state, pagination) {
      state.csvListing.nextPage = pagination.nextPage
      state.csvListing.previousPage = pagination.previousPage
    },

    [bulkCSV.SET_BULK_CSV_AUTOMATION_LISTING_PAGE](state, page) {
      state.csvListing.page = page
    },
    [bulkCSV.SET_APPROVED_LOADER](state, status) {
      state.approvedLoading = status
    },
  },
  getters: {
    getCsvAutomationTabStatus: (state) => {
      return state.tab_status
    },
    getCsvAutomationSelection: (state) => {
      return state.selection
    },
    getCsvListing: (state) => {
      return state.listing
    },
    getCSVAutomationListing: (state) => {
      return state.csvListing
    },
    getApprovedLoading: (state) => {
      return state.approvedLoading
    },
  },
}
