<template>
  <b-modal
    id="feeder_logs_opml_import"
    modal-class="add_slot normal_modal feeder-logs-modal"
    hide-footer
    hide-header
  >
    <div class="basic_form">
      <div class="modal_head d-flex align-items-center">
        <h2>Logs</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="$bvModal.hide('feeder_logs_opml_import')"
        >
          &times;
        </button>
      </div>

      <div class="modal_body basic_form m_t_15">
        <div class="row">
          <div class="col-md-12">
            <!--<p class="pb-3">View the log history of your imported feeds.</p>-->
            <div class="table_wrapper logs-feeds-table-wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 80%">Feed URL</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="logs && logs.length === 0">
                    <tr>
                      <td colspan="2"> No logs available for your feed. </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="log in getSortedByStateLogs">
                      <td>
                        {{ log.url }}
                        <a :href="log.url" target="_blank"
                          ><i
                            class="far fa-external-link feeder-logs-external-link"
                          ></i
                        ></a>
                      </td>
                      <td>
                        <template v-if="log.state === 'added'">
                          <span class="feeder-logs-state added"> Added </span>
                        </template>
                        <template v-else>
                          <span class="feeder-logs-state failed"> Failed </span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'

export default {
  props: {
    logs: {}
  },
  data () {
    return {
      loaders: {
        store: false
      }
    }
  },
  computed: {
    ...mapGetters(['getFeederGroupStore', 'getFeederGroupValidations']),
    getSortedByStateLogs () {
      return sortBy(this.logs, ['state'], ['desc'])
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="less" scoped>
.modal.feeder-logs-modal.modal-dialog {
  max-width: 750px !important;
  width: 750px !important;
}

.modal.feeder-logs-modal.modal-content {
  width: 750px !important;
}

.logs-feeds-table-wrapper {
  position: relative;
  overflow: auto;
  height: 500px;
}

.feeder-logs-state {
  width: 100px;
  color: #fff;
  border-radius: 4px;
  padding: 4px 0.625rem;
}

.feeder-logs-state.added {
  background: #1ecb7b;
}

.feeder-logs-state.failed {
  background: #ea7070;
}

.feeder-logs-external-link {
  font-size: 12px;
  cursor: pointer;
}
</style>
