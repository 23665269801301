<template>
  <div
    v-if="languages.length > 0 && getTweetsContent.length > 0"
    :class="{ disable_click: getTopicDisabled }"
  >
    <div
      v-tooltip.top="'Language'"
      class="dropdown option_dropdown default_style_dropdown"
    >
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="flag_icon icon_first cs-flag"></i>
        <span class="text">{{ getTwitterLanguage.languageName }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul v-if="languages.length > 0" class="inner">
          <li
            v-for="language in languages"
            class="list_item"
            :value="language.code"
            @click.prevent="changeTwitterLanguage(language)"
          >
            <a href="#">{{ language.languageName }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { twitterLanguages } from '../../../config/twitter-languages'

export default {
  data () {
    return {
      languages: twitterLanguages
    }
  },

  computed: {
    ...mapGetters([
      'getTweetsContent',
      'getTwitterLanguage',
      'getTopicDisabled'
    ])
  },
  methods: {}
}
</script>
