<script setup>
import { computed } from 'vue'
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import TopAndLeastEngagingPosts from '@src/modules/analytics/views/tiktok/components/TopAndLeastEngagingPosts.vue'
import FollowersGraph from '@src/modules/analytics/views/tiktok/components/graphs/FollowersGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/tiktok/components/graphs/EngagementsGraph.vue'
import VideosGraph from '@src/modules/analytics/views/tiktok/components/graphs/VideosGraph.vue'
import VideosPostingAndEngagements from '@src/modules/analytics/views/tiktok/components/graphs/VideosPostingAndEngagements.vue'
import CardsComponent from '@/src/modules/analytics/views/tiktok/components/CardsComponent.vue'
import PostTable from '@/src/modules/analytics/views/tiktok/components/PostTable.vue'

import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'

const { topEngagingPosts, leastEngagingPosts, allPosts, selectedAccount } =
  useTiktokAnalytics()

const chunkSize = 10
/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topPostsChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < allPosts.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const hardCodedSections = 5
  return hardCodedSections + topPostsChunks.value.length
})
</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Tiktok'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-7">
          <CardsComponent />
        </div>

        <!-- Followers Graph -->
        <FollowersGraph class="!mb-7" />
        <!-- Followers Graph -->
        <FollowersGraph class="!mb-7" default-type="bar" />
      </div>
    </div>
  </div>

  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Tiktok'"
        :current="2"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Engagements Graph -->
      <EngagementsGraph class="!mb-7" />
      <!-- Engagements Graph -->
      <EngagementsGraph class="!mb-7" default-type="bar" />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Tiktok'"
        :current="3"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Videos Graph -->
      <VideosGraph class="!mb-7" />
      <!-- Videos Graph -->
      <VideosGraph class="!mb-7" default-type="bar" />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Tiktok'"
        :current="4"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Videos and Engagements Graph -->
      <VideosPostingAndEngagements class="!mb-7" />

      <!-- Engaging Posts -->
      <TopAndLeastEngagingPosts
        :top-posts="topEngagingPosts"
        :least-posts="leastEngagingPosts"
        :selected-account="selectedAccount"
      />
    </div>
  </div>
  <template v-for="(start, index) in topPostsChunks" :key="index">
    <div class="page_view">
      <div class="subpage">
        <ReportHeader
          :report-name="'Tiktok'"
          :current="5 + index"
          :total="totalPageSize"
        ></ReportHeader>
        <!-- Post Table for top posts -->
        <PostTable
          :is-report-view="true"
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, allPosts?.length)"
        />
      </div>
    </div>
  </template>
</template>
