import MainPlanner from '@src/modules/planner_v2/views/MainPlanner'
import ListView from '@src/modules/planner_v2/views/ListView'
import FeedView from '@src/modules/planner_v2/views/FeedView'
import CalendarView from '@src/modules/planner_v2/views/CalenderView'
import GridView from '@src/modules/planner_v2/views/GridView'
import SocialModal from '@src/modules/composer_v2/views/SocialModal'
import { commonMethods } from '@common/store/common-methods'

export const plannerRoutes = {
  planner: {
    path: '/:workspace/planner',
    name: 'planner_v2',
    redirect: { name: 'planner_list_v2' },
    component: MainPlanner,
    meta: {
      title: 'Planner',
    },
    children: [
      {
        path: '/:workspace/planner/list-view',
        name: 'planner_list_v2', // be careful when you change route name. This might break some code/feature
        component: ListView,
        meta: {
          title: 'Planner | List',
        },
        alias: ['/:workspace/composer'],
        children: [
          {
            path: '/:workspace/composer/:id?',
            name: 'social-modal', // be careful when you change route name. This might break some code/feature
            component: SocialModal,
            meta: {
              title: 'Social | Composer',
            },
          },
        ],
      },
      {
        path: '/:workspace/planner/compact-view',
        name: 'planner_list_compact_v2', // be careful when you change route name. This might break some code/feature
        component: ListView,
        meta: {
          title: 'Planner | Compact List',
        },
      },
      {
        path: '/:workspace/planner/instagram-grid-view',
        //! NAME IS IMPORTATANT
        name: 'planner_instagram_grid_view', // be careful when you change route name. This might break some code/feature
        component: GridView,
        meta: {
          title: 'Planner | Instagram Grid View',
          isGridView: true,
        },
        beforeEnter(to, from, next) {
          // hiding chatWidget on grid view
          commonMethods?.toggleAiChat(true)
          next();
        },
      },
      {
        path: '/:workspace/planner/tiktok-grid-view',
        //! NAME IS IMPORTANT
        name: 'planner_tiktok_grid_view', // be careful when you change route name. This might break some code/feature
        component: GridView,
        meta: {
          title: 'Planner | Tiktok Grid View',
          isGridView: true,
        },
        beforeEnter(to, from, next) {
          // hiding chatWidget on grid view
          commonMethods?.toggleAiChat(true)
          next();
        },
      },
      {
        path: '/:workspace/planner/feed-view',
        name: 'planner_feed_v2', // be careful when you change route name. This might break some code/feature
        component: FeedView,
        meta: {
          title: 'Planner | Feed',
        },
      },
      {
        path: '/:workspace/planner/calendar-view',
        name: 'planner_calendar_v2', // be careful when you change route name. This might break some code/feature
        component: CalendarView,
        meta: {
          title: 'Planner | Calendar',
        },
      },
    ],
  },
}
