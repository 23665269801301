/**
 *  This file is composable conversion of platformMixin (src/modules/common/mixins/integrations/platformMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
import * as _ from 'underscore'

import {ref} from "vue";

const topBannersHeight = ref(0);
export default function usePlatform() {

  function isIGPersonalAccount(account) {
    return account?.type === 'personal'
  }
  function hasInstaPermission(channel) {
    if (channel.account_type === 'instagram') {
      const analyticPermission = _.findWhere(channel.permissions, {
        permission: 'instagram_manage_insights',
      })
      return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
    } else return true
  }

  return {
    topBannersHeight,
    isIGPersonalAccount,
    hasInstaPermission,
  }
}
