<template>
  <div>
    <!-- Comment View -->
    <div
      v-if="!editMode"
      :class="getBackgroundColor"
      class="flex flex-row my-1 p-3 rounded-md"
    >
      <div v-if="type !== 'external'" class="w-10 h-10">
        <img
          alt="profile-image"
          class="w-10 h-10 rounded-full"
          :src="message?.user?.image || userProfileImage(message?.user_id)"
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </div>

      <div class="ml-2 flex-1">
        <div class="flex flex-col">
          <div class="flex item-center justify-between mb-2">
            <span v-tooltip="message?.email" class="font-semibold mr-1">{{
              message.user?.full_name || message?.name
            }}</span>
            <div
              v-tooltip="getCommentTypeTooltip()"
              :class="[getCommentColor]"
              class="text-xs xl:text-sm text-nowrap"
              >{{ getCommentType }}
            </div>
          </div>
          <p
            class="whitespace-pre-wrap text-sm"
            v-html="formattedComment(message?.comment)"
          ></p>
        </div>
        <div class="flex justify-between mt-1.5">
          <div class="text-xs text-gray-700 my-auto">{{
            moment(message?.created_at).fromNow()
          }}</div>

          <div
            v-if="type !== 'external' && !external && isAuthor"
            class="text-xs flex space-x-1.5"
          >
            <i
              v-tooltip="'Edit Comment'"
              class="far fa-edit cursor-pointer text-sm"
              @click="onClickEdit"
            ></i>
            <i
              v-tooltip="'Delete Comment'"
              class="far fa-trash-alt text-red-700 cursor-pointer text-sm"
              @click="onClickDelete"
            ></i>
          </div>
        </div>

        <!--    Attached Media    -->
        <div>
          <div
            v-if="message?.media?.length > 0"
            class="flex flex-wrap space-x-2 mt-2"
          >
            <div
              v-for="(media, index) in message.media"
              :key="index"
              class="shadow-sm"
            >
              <div class="relative group">
                <img
                  alt="media"
                  class="w-20 h-20 object-cover rounded-md"
                  :src="media.link"
                  @error="
                    $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/no_media_found.svg`
                  "
                />

                <!--      Overlay          -->
                <div
                  class="
                    absolute
                    top-0
                    left-0
                    w-full
                    h-full
                    bg-gray-900 bg-opacity-75
                    rounded-md
                    hidden
                    group-hover:block
                  "
                >
                  <div class="flex flex-col justify-center items-center h-full">
                    <i
                      class="fas fa-eye text-white text-xl cursor-pointer"
                      @click="openImageLightBox(media.link)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Mode -->
    <div v-if="editMode" class="my-2">
      <div class="py-2 border rounded-md border-gray-600 p-2 cst-editor">
        <AtTa
          v-model="comment.text"
          class="mentions-bottom"
          :hover-select="true"
          :members="getActiveWorkspaceMembersName"
        >
          <textarea
            id="comment-box-edit"
            ref="editComment"
            v-model="comment.text"
            :disabled="loader.comments || loader.file"
            class="rounded-md w-full p-2 border-0 text-sm min-h-[3rem]"
            maxlength="2200"
            placeholder="Add your comment here"
            rows="4"
          ></textarea>
        </AtTa>
        <div>
          <div
            v-if="comment.media.length"
            class="flex flex-wrap space-x-1.5 mt-1.5 mb-3.5"
          >
            <div
              v-for="(media, key) in comment.media"
              :key="key"
              v-tooltip="media.name"
              class="flex flex-col group relative"
            >
              <img
                :key="key"
                :src="media.link"
                alt=""
                class="w-16 h-16 rounded cursor-pointer"
              />
              <i
                class="
                  cs-cross
                  absolute
                  hidden
                  group-hover:block
                  top-0
                  right-0
                  text-center
                  p-1.5
                  bg-red-800
                  text-white
                  bg-opacity-75
                  hover:bg-opacity-90
                  cursor-pointer
                "
                @click="
                  () => {
                    comment.media.splice(key, 1)
                  }
                "
              ></i>
              <i
                class="
                  absolute
                  hidden
                  group-hover:block
                  w-full
                  bottom-0
                  cs-eye
                  text-center
                  p-1.5
                  bg-black-900
                  text-white
                  bg-opacity-75
                  hover:bg-opacity-90
                  cursor-pointer
                "
                @click="openImageLightBox(media.link)"
              ></i>
            </div>

            <div v-if="loader.file">
              <div
                class="
                  w-16
                  h-16
                  rounded
                  border
                  flex
                  justify-center
                  items-center
                "
              >
                <clip-loader
                  :color="'#9da6ac'"
                  :size="'13px'"
                  class="spinner"
                  variant="info"
                ></clip-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <!-- Emoji attachments and hashtags-->
          <div
            class="
              composer-comment-toolbar
              flex
              space-x-1
              justify-end
              items-center
              mt-1.5
            "
          >
            <div
              v-if="!isClient"
              class="flex items-center space-x-1 xl:space-x-2 ml-1"
            >
              <CstSwitch
                id="private-note"
                v-model="comment.note"
                :disabled="loader.comments || loader.file"
                size="small"
              ></CstSwitch>
              <label
                v-tooltip="
                  'Internal notes are visible only to your team. Clients will not see these notes.'
                "
                class="text-sm text-gray-700 pt-2 text-nowrap"
                for="private-note"
                >Internal Note</label
              >
            </div>
            <div
              v-if="!isClient"
              class="h-[1.2rem] mx-1 xl:mx-2 w-px bg-gray-600"
            ></div>
            <template v-if="isSmallScreen && !feedView && !isComposer">
              <div class="relative comment-dropdown">
                <!-- More options dropdown -->
                <b-dropdown
                  centered
                  dropup
                  menu-class="p-0 border-none bg-transparent"
                  no-caret
                  toggle-class="text-decoration-none !m-0 !p-0"
                  variant="link"
                >
                  <template #button-content>
                    <!-- Ellipsis Icon -->
                    <i
                      v-tooltip="{ content: 'More options' }"
                      class="fas fa-ellipsis-h text-lg !text-[#CECECE]"
                    ></i>
                  </template>

                  <!-- Custom dropdown items in a horizontal row -->
                  <div
                    class="
                      flex
                      items-center
                      justify-center
                      space-x-2
                      bg-white
                      shadow-lg
                      p-2
                      rounded-md
                      !w-[8rem]
                    "
                  >
                    <!-- Emoji Picker Option -->
                    <div
                      class="
                        w-8
                        h-8
                        flex
                        items-center
                        justify-center
                        bg-gray-400
                        rounded-md
                        cursor-pointer
                      "
                      @click="emojiPickerStates = !emojiPickerStates"
                    >
                      <i
                        class="
                          fal
                          fa-smile-beam
                          text-blue-700 text-base
                          font-weight-500
                        "
                      ></i>
                    </div>
                    <CstEmojiPicker
                      v-if="emojiPickerStates"
                      :is-open="emojiPickerStates"
                      position="top-start"
                      @on-select="onEmojiSelect"
                      @handle-click="emojiPickerStates = false"
                    >
                    </CstEmojiPicker>

                    <!-- Media Upload Option -->
                    <label
                      class="
                        cursor-pointer
                        w-8
                        h-8
                        mb-0
                        flex
                        items-center
                        justify-center
                        bg-gray-400
                        rounded-md
                      "
                      for="update-comment-file-input"
                    >
                      <img
                        :src="
                          require('@assets/img/icons/planner/add_image.svg')
                        "
                        alt="add_media"
                        height="16"
                        width="16"
                      />
                    </label>
                    <input
                      id="update-comment-file-input"
                      accept="image/*"
                      class="hidden"
                      type="file"
                      @change.prevent="uploadCommentImage"
                    />

                    <i
                      v-tooltip.top="{ content: 'Tag people' }"
                      class="
                        far
                        fa-at
                        cursor-pointer
                        flex
                        w-8
                        h-8
                        items-center
                        justify-center
                        bg-gray-400
                        rounded-md
                        text-blue-700
                        font-1rem
                      "
                      @click="triggerAt"
                    ></i>
                  </div>
                </b-dropdown>
              </div>
            </template>

            <template v-else>
              <div class="relative">
                <div @click="emojiPickerStates = !emojiPickerStates">
                  <div
                    v-tooltip.top="'Add an emoji'"
                    class="
                      w-8
                      h-8
                      flex
                      items-center
                      justify-center
                      bg-gray-400
                      rounded-md
                      cursor-pointer
                    "
                  >
                    <i
                      class="
                        fal
                        fa-smile-beam
                        text-blue-700 text-base
                        font-weight-500
                      "
                    >
                    </i>
                  </div>
                </div>
                <CstEmojiPicker
                  v-if="emojiPickerStates"
                  :is-open="emojiPickerStates"
                  position="top-start"
                  @on-select="onEmojiSelect"
                  @handle-click="emojiPickerStates = false"
                >
                </CstEmojiPicker>
              </div>

              <label
                v-tooltip.top="{
                  content: 'Add media file',
                }"
                class="
                  cursor-pointer
                  w-8
                  h-8
                  mb-0
                  flex
                  items-center
                  justify-center
                  bg-gray-400
                  rounded-md
                "
                for="update-comment-file-input"
              >
                <img
                  :src="require('@assets/img/icons/planner/add_image.svg')"
                  alt="add_media"
                  height="16"
                  width="16"
                />
              </label>
              <input
                id="update-comment-file-input"
                accept="image/*"
                class="d-none"
                type="file"
                @change.prevent="uploadCommentImage"
              />
              <i
                v-tooltip.top="{ content: 'Tag people' }"
                class="
                  far
                  fa-at
                  cursor-pointer
                  flex
                  w-8
                  h-8
                  items-center
                  justify-center
                  bg-gray-400
                  rounded-md
                  text-blue-700
                  font-1rem
                "
                @click="triggerAt"
              ></i>
            </template>
          </div>

          <!-- Edit and cancel Button -->
          <div class="flex justify-start items-center gap-3 mt-1.5">
            <!-- Cross Icon -->
            <span
              @click="editMode = !editMode"
              class="
                space-x-1
                cursor-pointer
                flex
                w-8
                h-8
                items-center
                justify-center
                bg-gray-400
                rounded-md
                text-blue-700
              "
            >
              <img
                alt="close icon"
                class="w-[1.25rem] h-[1.25rem]"
                src="@assets/img/icons/blue-cross-icon.svg"
              />
            </span>
            <button
              :class="{
                'cursor-not-allowed':
                  loader.comments || loader.file || comment.text === '',
              }"
              :disabled="loader.comments || loader.file || processingComment"
              class="
                space-x-1
                py-1.5
                px-2
                border-0
                bg-primary-variant-1
                rounded-md
              "
              @click="handleComment"
            >
              <clip-loader
                v-if="loader.comments"
                :color="'#dadada'"
                :size="'16px'"
                class="spinner"
                variant="info"
              ></clip-loader>
              <template v-else>
                <img
                  :src="require('@assets/img/icons/planner/send_message.svg')"
                  alt="send_message"
                  height="16"
                  width="16"
                />
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import CstButton from '@ui/Button/CstButton'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import { swalAttributes } from '@common/constants/common-attributes'
import { teamMixin } from '@common/mixins/setting/teamMixin'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import CstSwitch from '@ui/Switch/CstSwitch'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
export default {
  name: 'CommentCard',
  components: {
    AtTa,
    CstButton,
    CstEmojiPicker,
    CstSwitch,
  },
  mixins: [Helpers, teamMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    planId: {
      type: String,
      default: '',
    },
    isNote: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    feedView: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
    isAuthor: {
      type: Boolean,
      default: false,
    },
    isComposer: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'open-image-light-box',
    'update-comment-delete-list',
    'update-comment',
  ],
  data() {
    return {
      moment,
      emojiPickerStates: false,
      editMode: false,
      loader: {
        file: false,
        comments: false,
      },
      comment: {
        text: '',
        mentioned_user: [],
        media: [],
        note: this.isNote,
      },
      processingComment: false,
      deletedComments: [],
      isSmallScreen: false,
      showMoreOptions: false,
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspaceMembersName', 'getActiveWorkspace']),
    // get border color based on the type of comment
    getCommentColor() {
      switch (this.type) {
        case 'notes':
          return '!text-[#FFC555]'
        case 'comments':
          return '!text-[#4EB17C]'
        case 'external':
          return '!text-[#3386EA]'
        default:
          return
      }
    },
    getCommentType() {
      //No need to show the comment type for external comments
      switch (this.type) {
        case 'notes':
          return 'Internal Note'
        case 'comments':
          return
        case 'external':
          return 'Via Shareable Link'
        default:
          return
      }
    },
    getBackgroundColor() {
      switch (this.type) {
        case 'notes':
          return 'bg-[#fff9ed]'
        case 'comments':
          return 'bg-[#F0F0F0] bg-opacity-50'
        case 'external':
          return 'bg-[#F3F7FD]'
        default:
          return
      }
    },
  },
  mounted() {
    this.checkSmallScreen()
    window.addEventListener('resize', this.checkSmallScreen)
  },
  methods: {
    // Get the tooltips for the comment type
    getCommentTypeTooltip() {
      switch (this.type) {
        case 'notes':
          return 'Internal notes are visible only to your team. Clients will not see these notes.'
        case 'external':
          return 'This feedback was provided by a client via shared link.'
        default:
          return
      }
    },

    // format the comment and show the mentioned user in blue color
    formattedComment(comment) {
      //check if user is mentioned @user using regex and replace the @user with blue color else return the comment
      return comment.replace(/@(\w+)/g, '<span class="text-blue-700">$&</span>')
    },
    checkSmallScreen() {
      // Check if the screen size is less than 1510px
      this.isSmallScreen = window.innerWidth < 1510
    },
    onEmojiSelect(emoji) {
      const currentPosition = this.$refs.editComment.selectionStart

      // Create a new text by inserting the selected emoji at the current cursor position.
      const newText = [
        this.comment.text.slice(0, currentPosition),
        emoji,
        this.comment.text.slice(currentPosition),
      ].join('')

      this.comment.text = newText

      // Update the value of the comment input field with the new text.
      this.$refs.editComment.value = newText

      // Set the cursor position to the end of the inserted emoji.
      this.$refs.editComment.selectionStart = currentPosition + emoji.length
      this.$refs.editComment.selectionEnd = currentPosition + emoji.length
    },
    /**
     * Event for updating the comment on a plan. emits the result
     */
    async handleComment() {
      console.log('METHOD::handleComment')
      this.processingComment = true
      const payload = {
        id: this.message._id,
        comment: this.comment.text,
        is_note: this.comment.note,
        media: this.comment.media,
        mentioned_user: this.comment.mentioned_user,
        notification: '',
        plan_id: this.planId,
        title: null,
        type: 'list_plans',
        workspace_id: this.getActiveWorkspace._id,
      }

      this.$emit('update-comment', payload)
      await this.addComment(payload)
      this.editMode = false
      this.processingComment = false
    },
    /**
     * onClick Event for comment. syncs the plan media and comment with the comment editor
     */
    onClickEdit() {
      console.log('METHOD::onClickEdit')
      this.editMode = true
      this.comment.text = this.message.comment
      this.comment.mentioned_user = this.message.mentioned_user
      this.comment.media = this.message.media
      this.comment.note = this.message.is_note
    },
    /**
     * onClick event for deleting a comment.
     */
    async onClickDelete() {
      console.log('METHOD::onClickDelete')
      const response = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete your comment?',
        { title: 'Remove Comment', ...swalAttributes() }
      )
      if (!response) return
      const payload = {
        id: this.message._id,
        plan_id: this.planId,
        workspace_id: this.getActiveWorkspace._id,
      }
      try {
        await this.deleteComment(payload)
        this.deletedComments.push(this.message._id)
        this.$emit('update-comment-delete-list', this.message._id)
      } catch (e) {}
    },
    /**
     * Trigger method for tagging people on workspace
     */
    triggerAt() {
      const { editComment } = this.$refs
      editComment.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      editComment.dispatchEvent(e)
    },
    /**
     * calls an api for uploading the comment media.
     * @returns {Promise<void>}
     */
    uploadCommentImage: async function uploadCommentImage() {
      this.loader.file = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.loader.file = false
      if (res) {
        if (res.data.status) {
          if (this.comment.media) {
            this.comment.media.push(res.data.media)
          } else {
            this.$set(this.comment, 'media', [res.data.media])
          }
        } else {
          await this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    userProfileImage(userId) {
      return this.teamMemberImageById(userId)
    },
    openImageLightBox(image) {
      console.log('openImageLightBox', image)
      this.$emit('open-image-light-box', image)
    },
  },
}
</script>

<style scoped>
.b-dropdown {
  .dropdown-menu {
    width: auto !important;
    min-width: 3rem !important;
  }
}

@media screen and (max-width: 1220px) {
  .comment-btn {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
}
</style>
