<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-3">
      <img
        class="aacount rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        <span >No Account Selected</span>
        <span class="block font-normal text-xs text-gray-800">
          <span>{{ momentWrapper(executionTime.date).formatDateTime() }}</span>
        </span>
      </div>
    </div>
    <div v-if="getDescription" class="items-center px-3 py-1 pb-3">
      <span
        class="whitespace-pre-wrap leading-4"
        v-html="getDescription"
      ></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        See More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <div class="w-full h-full">
      <b-carousel
          v-if="computedMediaItems"
          ref="carousel"
          class="instagram-preview-carousel cursor-pointer"
          :interval="0"
          :controls="computedMediaItems.length > 1"
          :indicators="computedMediaItems.length > 1"
          @sliding-end="onSlideChange"
      >
        <b-carousel-slide
            v-for="(image, index) in computedMediaItems"
            :key="index"
        >
          <template v-if="typeof image === 'string' && !image" v-slot:img>
            <div class="max-h-[470px] w-full block relative">
              <div class="block overflow-hidden relative bg-white pt-[100%]">
                <img
                    class="w-full h-full absolute top-0 left-0"
                    src="@src/assets/img/no_data_images/no_media_found.svg"
                    alt=""
                />
              </div>
            </div>
          </template>
          <template v-else-if="typeof image === 'string'" v-slot:img>
            <div class="max-h-[470px] w-full block relative">
              <div class="block overflow-hidden relative pt-[100%]">
                <img
                    v-tooltip="{
                        content: 'Image Preview',
                        placement: 'auto',
                       }"
                    class="w-full h-full absolute top-0 left-0 object-cover"
                    :src="image"
                    alt=""
                    @click="displayFile('image',[image], 0)"
                    @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        }
                      "
                />
              </div>
            </div>
          </template>
          <template v-else v-slot:img >
            <div
                v-if="image.thumbnail"
                class="relative h-full text-white text-center"
            >
              <img
                  class="w-full"
                  style="max-height: 500px"
                  :src="getVideoThumbnail(image)"
                  alt="thumbnail"
              />
              <i
                  class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 pl-[1.2rem] pt-4 pb-4 pr-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
                  @click.prevent="displayFile('video',
                  image.converted_video || image.link,
                  0)
                  "
              ></i>
            </div>
            <img
                v-else
                class="w-full"
                src="@src/assets/img/no_data_images/no_media_found.svg"
                alt=""
            />
          </template>

        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="imgs"
    :index="selectedImageIndex"
    @hide="handleHide"
  ></VueEasyLightbox>
</template>

<script setup>
import { ref, computed, inject, } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import { parseDescriptionHtml } from '@common/lib/helper'
import useDateFormat from "@common/composables/useDateFormat"
import { EventBus } from '@common/lib/event-bus'

const root = inject('root')
const { $bvModal } = root

// Props are automatically available in <script setup>
const props = defineProps({
  detail: {
    type: Object,
    default: () => ({}),
    required: false,
  },
  executionTime: {
    type: Object,
    default: () => ({}),
    required: false,
  },
})

const { momentWrapper } = useDateFormat()

// Reactive data
const loadMore = ref(true)
const charLimit = ref(500)
const visible = ref(false)
const imgs = ref([])
const selectedImageIndex = ref(0)

const getDescription = computed(() => {
  let msg = props.detail?.message || ''
  msg = parseDescriptionHtml(msg, 5000)
  if (msg.length > charLimit.value && loadMore.value) {
    msg = msg.substr(0, charLimit.value).concat('...')
    return msg
  }
  return msg
})

const computedMediaItems = computed(() => {
  return props.detail?.multimedia?.length
    ? props.detail.multimedia
    : props.detail?.image?.length
    ? props.detail.image
    : []
})
// Methods
const changeLoadMore = (val) => {
  loadMore.value = val
}

const displayFile = (type, media, index) => {
  EventBus.$emit('displayFile', {
    type,
    media,
    index,
  })
  $bvModal.show('display-file-modal')
}

const handleHide = () => {
  visible.value = false
}

const getVideoThumbnail = (item) => {
  let thumbnail = item?.thumbnails_suggestions?.length
    ? item.thumbnails_suggestions[0]
    : item.thumbnail
  if (thumbnail) return thumbnail
  if (
    thumbnail.indexOf('/media_library/') > -1 &&
    thumbnail.indexOf('?offset=') === -1
  ) {
    thumbnail = item.thumbnails_suggestions[0]
  }
  return thumbnail
}

</script>

<style lang="scss" scoped>
</style>
