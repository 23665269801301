import { UNKNOWN_ERROR } from '@common/constants/messages'

import { mapActions, mapGetters } from 'vuex'
import { getQuotesURL } from '../../config/api-utils'
const quotesMixin = {
  methods: {
    ...mapActions([
      'setQuotes',
      'setQuoteLoader',
      'setQuotesScroll',
      'setQuotesPage',
      'setQuoteInfiniteLoader'
    ]),

    getWebQuotes ($state = null) {
      console.debug('Method:getWebQuotes')
      const filters = this.getQuotesFilter
      const payload = {
        page: filters.quotes.page,
        limit: filters.quotes.limit,
        search: filters.search
      }
      console.log(filters, payload)

      this.postRequest(
        getQuotesURL,
        payload,
        (response) => {
          if (response.data.status) {
            if (filters.quotes.page == 0) {
              this.setQuotes([])
            }

            if (filters.quotes.page >= 1) {
              this.setQuotes(this.getQuotes.concat(response.data.quotes))
            } else {
              this.setQuotes(response.data.quotes)
            }

            this.setQuoteInfiniteLoader(true)
            if (response.data.quotes.length === 20) {
              this.setQuotesScroll(true)
              try {
                if ($state) {
                  $state.loaded()
                }
              } catch (ex) {
                console.debug(ex)
              }
            } else {
              this.setQuotesScroll(false)
              try {
                console.log('Check state completed...', $state)
                if ($state) {
                  $state.complete() // if not work remove this -->adding this because loader showing even no more posts found
                }
              } catch (ex) {
                console.log(ex)
              }
            }

            this.checkComposerDragDrop()
          } else {
            this.alertMessage(response.data.message, 'error')
          }

          this.setQuoteLoader(false)
        },
        (response) => {
          console.log('Exception in getWebQuotes ', response)
          this.setQuoteLoader(false)
          this.setQuoteInfiniteLoader(true)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    backgroundQuoteImageURL (post) {
      if (post && post.image_url) {
        return post.image_url
      }
      return ''
    },
    searchQuotesByKeyword () {
      this.setQuotesPage(0)
      this.setQuotesScroll(true)
      this.setQuoteLoader(true)
      this.setQuoteInfiniteLoader(false)
      this.getWebQuotes()
    }
  },
  computed: {
    ...mapGetters(['getQuotesFilter', 'getQuotes'])
  }
}
export { quotesMixin }
