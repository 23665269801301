<script setup>
import CstPopup from '@ui/Popup/CstPopup.vue'
import { ref } from 'vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics'

const { setFacebookBannerStatus } = useFacebookAnalytics()

const showPopup = ref(false)
const hide = ref(false)

const closePopup = () => {
  showPopup.value = false
}
</script>

<template>
  <div v-if="!hide" class="w-full">
    <div
      class="z-10 bg-no-repeat bg-right-bottom flex gap-2 rounded-lg py-2 w-full pl-4 pr-6 items-center bg-gray-cs-800 relative"
    >
      <div class="flex flex-grow my-auto gap-3 items-center justify-left">
        <p class="text-white">
          Meta has deprecated Facebook
          <b> Active users by Days and Active users by Hours</b> data. Insights
          prior to <b> Sept 16, 2024 </b>, are available if your account was
          connected before that date.
        </p>
      </div>
      <i
        class="fas fa-times text-white text-xl font-light cursor-pointer ml-auto"
        @click="showPopup = !showPopup"
      />
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <CstPopup
          v-if="showPopup"
          v-click-away="closePopup"
          parent-class="top-[110%] right-0 w-[27.875rem] h-[7.25rem] cursor-default"
          confirm-text="Yes, Hide"
          @close-popup="closePopup"
          @confirm-popup="setFacebookBannerStatus"
        >
          <template v-slot:popup-text>
            <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4"
              >Are you sure you want to hide this banner?</p
            >
          </template>
        </CstPopup>
      </transition>
    </div>
  </div>
</template>
