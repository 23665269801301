<template>
  <div>
    <ImageSection></ImageSection>

    <div class="opt_box">
      <div class="head_text">
        <h3>SEO Settings</h3>
      </div>

      <template v-if="!getEditSeoPreview">
        <div class="seo_block">
          <p class="title">Search engine preview</p>
          <template v-if="getBlogPostingCustomizeSeoPreviewStatus">
            <h3>{{ limitTextLength(getBlogPostingDetails.metaTitle, 67) }}</h3>
            <p class="link"
              >https://www.yourwebsite.com ›
              {{ getBlogPostingDetails.metaUrl }}</p
            >
            <p>{{
              limitTextLength(getBlogPostingDetails.metaDescription, 153)
            }}</p>
          </template>

          <template v-else>
            <h3
              v-if="
                getBlogPostingDetails.title &&
                getBlogPostingDetails.title.length > 0
              "
              >{{ limitTextLength(getBlogPostingDetails.title, 67) }}</h3
            >
            <h3 v-else>Post Name</h3>
            <p class="link"
              >https://www.yourwebsite.com ›
              {{
                slugify(limitTextLength(getBlogPostingDetails.title, 67), '-')
              }}</p
            >
            <p>{{ limitTextLength(getEditorMetaDescription(), 153) }}</p>
          </template>
        </div>
        <button
          class="mt-3 btn large_btn gradient_btn"
          @click.prevent="editSeoPreview"
          ><span>Edit</span></button
        >
      </template>
      <template v-else>
        <div class="seo_block">
          <p class="title">Search engine preview</p>
          <h3 v-if="title && title.length > 0">{{
            limitTextLength(title, 67)
          }}</h3>
          <h3 v-else>Post Name</h3>
          <p class="link"
            >https://www.yourwebsite.com › {{ slugify(url, '-') }}</p
          >
          <p>{{ limitTextLength(description, 153) }}</p>
        </div>
        <div class="seo_form basic_form">
          <div class="input_field">
            <label for=""
              >SEO Title
              <span class="text_limit"
                >Characters Recommended: <span>70</span>, Used:
                <span>{{ title.length }}</span></span
              ></label
            >
            <input v-model="title" type="text" placeholder="SEO title..." />
          </div>
          <div class="input_field">
            <label for="">SEO URL</label>
            <input v-model="url" type="text" placeholder="SEO URL..." />
          </div>
          <div class="input_field">
            <label for=""
              >Meta Description
              <span class="text_limit"
                >Characters Recommended: <span>156</span>, Used:
                <span>{{ description.length }}</span></span
              ></label
            >
            <textarea
              id=""
              v-model="description"
              name=""
              cols="30"
              rows="3"
              placeholder="Meta Description..."
            ></textarea>
          </div>
        </div>
        <button
          class="btn btn-studio-theme-space mt-2.5"
          @click.prevent="saveSeoPreview"
          ><span>Save</span></button
        >
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageSection from '@src/modules/publish/components/posting/blog/ImageSection.vue'
import {
  COMPOSER_SEO_URL_REQUIRED,
  COMPOSER_SEO_TITLE_REQUIRED,
} from '@common/constants/messages'
import { composer } from '@src/modules/composer/store/composer-mutation-type.js'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'

export default {
  components: {
    ImageSection,
  },
  data() {
    return {
      title: '',
      url: '',
      description: '',
    }
  },
  computed: {
    ...mapGetters([
      'getBlogSubmissionSelection',
      'getEditSeoPreview',
      'getBlogPostingCustomizeSeoPreviewStatus',
      'getBlogPostingDetails',
    ]),
  },
  created() {
    this.$store.commit(composer.SET_EDIT_SEO_PREVIEW, false)
  },
  methods: {
    // we use local variables that
    editSeoPreview() {
      if (this.getBlogPostingCustomizeSeoPreviewStatus) {
        this.title = this.getBlogPostingDetails.metaTitle
        this.url = this.getBlogPostingDetails.metaUrl
        this.description = this.getBlogPostingDetails.metaDescription
      } else {
        this.title = this.limitTextLength(this.getBlogPostingDetails.title, 67)
        this.url = this.slugify(
          this.limitTextLength(this.getBlogPostingDetails.title, 67),
          '-'
        )
        this.description = this.limitTextLength(
          this.getBlogPostingDetails.optionalDescription,
          153
        )
      }
      this.$store.commit(composer.SET_EDIT_SEO_PREVIEW, true)
    },
    saveSeoPreview() {
      if (this.title.trim().length === 0) {
        this.alertMessage(COMPOSER_SEO_TITLE_REQUIRED, 'error')
        return
      } else if (this.url.trim().length === 0) {
        this.alertMessage(COMPOSER_SEO_URL_REQUIRED, 'error')
        return
      }
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_META_TITLE,
        this.title.trim()
      )
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_META_URL,
        this.slugify(this.url.trim(), '-')
      )
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_META_DESCRIPTION,
        this.description.trim()
      )
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_CUSTOMIZE_SEO_PREVIEW_STATUS,
        true
      )
      this.$store.commit(composer.SET_EDIT_SEO_PREVIEW, false)
    },
  },
}
</script>
