<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <template v-if="gmbSharingData.video && gmbSharingData.video.link">
      <div
        class="my-5 flex items-center justify-center rounded-lg p-3 bg-orange-100 text-orange-700"
      >
        <p
          >Incase of posting video to GMB, the post will go as media upload and
          the caption would not be passed.</p
        >
        <a
          v-tooltip.top="'Learn more about video posting'"
          class="beacon ml-2"
          href="#"
          data-beacon-article-modal="621de4f1aca5bb2b753c77b2"
          style="display: block"
        >
          <i
            class="far fa-question-circle p-0"
            style="background: transparent; padding: 0"
          ></i>
        </a>
      </div>
    </template>
    <template v-else>
      <div class="my-4 flex items-center">
        <div class="w-2/12">Post Type</div>
        <div class="w-10/12 flex items-center">
          <template v-for="(item, index) in topicType" :key="index">
            <CstRadio
              :id="item.name"
              v-model="gmbOptions.topic_type"
              :value="item.value"
              class="mr-8"
              name="gmb_post_type"
              @change="onSelectPostType(item)"
            >
              <div class="flex flex-col">
                <span class="">{{ item.name }}</span>
              </div>
            </CstRadio>
          </template>
        </div>
      </div>
      <template v-if="isCallToAction">
        <div class="my-4 flex items-center">
          <div class="w-2/12">Button Label</div>
          <div class="w-10/12 flex">
            <CstDropdown
              class="w-100"
              size="small"
              button-classes="flex justify-between"
            >
              <template v-slot:selected>
                {{ selectedActionType.name }}
              </template>

              <template v-slot>
                <CstDropdownItem
                  v-for="(item, index) in actionType"
                  :key="index"
                  @click="onSelectActionType(item)"
                >
                  {{ item.name }}
                </CstDropdownItem>
              </template>
            </CstDropdown>
          </div>
        </div>
        <div
          v-if="
            gmbOptions.action_type !== '' && gmbOptions.action_type !== 'CALL'
          "
          class="my-4 flex items-center"
        >
          <div class="w-2/12">Button Link</div>
          <div class="w-10/12 flex">
            <CstInputFields
              :value="gmbSharingData.cta_link"
              class="w-full required-input"
              type="text"
              placeholder="https://example.com"
              @value="onChangeButtonLink"
            />
          </div>
        </div>
      </template>
      <template v-else-if="isEvent">
        <div class="my-4 flex items-center">
          <div class="w-2/12">Title</div>
          <div class="w-10/12">
            <CstInputFields
              :value="gmbOptions.title"
              class="w-full required-input"
              type="text"
              placeholder="Example: Sale this week"
              @value="onChangeTitle"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Button Label</div>
          <div class="w-10/12 flex">
            <CstDropdown
              class="w-100"
              size="small"
              button-classes="flex justify-between"
            >
              <template v-slot:selected>
                {{ selectedActionType.name }}
              </template>

              <template v-slot>
                <CstDropdownItem
                  v-for="(item, index) in actionType"
                  :key="index"
                  @click="onSelectActionType(item)"
                >
                  {{ item.name }}
                </CstDropdownItem>
              </template>
            </CstDropdown>
          </div>
        </div>
        <div
          v-if="
            gmbOptions.action_type !== '' && gmbOptions.action_type !== 'CALL'
          "
          class="my-4 flex items-center"
        >
          <div class="w-2/12">Button Link</div>
          <div class="w-10/12 flex">
            <CstInputFields
              :value="gmbSharingData.cta_link"
              class="w-full required-input"
              type="text"
              placeholder="https://example.com"
              @value="onChangeButtonLink"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Date</div>
          <div class="w-10/12 flex">
            <CstInputFields
              type="date"
              class="w-full required-input"
              :date-options="dateOptions"
              :value="selectedStartEndDate"
              placeholder="Select start and end date"
              @value="onChangeStartEndDate"
            />
          </div>
        </div>
      </template>
      <template v-else-if="isOffer">
        <div class="my-4 flex items-center">
          <div class="w-2/12">Title</div>
          <div class="w-10/12">
            <CstInputFields
              :value="gmbOptions.title"
              class="w-full required-input"
              type="text"
              placeholder="Example: Sale this week"
              @value="onChangeTitle"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Date</div>
          <div class="w-10/12 flex">
            <CstInputFields
              type="date"
              class="w-full required-input"
              :date-options="dateOptions"
              :value="selectedStartEndDate"
              placeholder="Select start and end date"
              @value="onChangeStartEndDate"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Coupon code</div>
          <div class="w-10/12">
            <CstInputFields
              key="gmb_coupon_code"
              class="w-full"
              type="text"
              placeholder="Example: 15% off in store"
              @value="onChangeCouponCode"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Link to redeem offer</div>
          <div class="w-10/12 flex">
            <CstInputFields
              key="gmb_redeem_link"
              class="w-full"
              type="text"
              placeholder="Link to redeem offer"
              @value="onChangeRedeemOfferLink"
            />
          </div>
        </div>
        <div class="my-4 flex items-center">
          <div class="w-2/12">Terms and Conditions</div>
          <div class="w-10/12 flex">
            <textarea
              key="gmb_terms_condition"
              v-model="gmbOptions.terms_conditions"
              class="w-full px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none bg-cs-light-gray"
              type="text"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstInputFields from '@ui/Input/CstInputFields'
import CstRadio from '@ui/Radio/CstRadio'
import { social } from '@src/modules/publish/store/states/mutation-types'
import { mapGetters } from 'vuex'
import { getDateTimeFormat } from '@common/lib/date-time'
import debounce from 'lodash/debounce'
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  name: 'GmbOptions',
  components: {
    CstDropdown,
    CstDropdownItem,
    CstInputFields,
    CstRadio,
  },
  props: {
    gmbOptions: {
      type: Object,
      default: () => {},
    },
    gmbSharingData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      topicType: [
        {
          name: 'Call to Action',
          value: 'STANDARD',
        },
        {
          name: 'Event',
          value: 'EVENT',
        },
        {
          name: 'Offer',
          value: 'OFFER',
        },
      ],
      selectedTopicType: {
        name: 'Call to Action',
        value: 'STANDARD',
      },
      actionType: [
        {
          name: 'No Button',
          value: '',
        },
        {
          name: 'Book',
          value: 'BOOK',
        },
        {
          name: 'Order',
          value: 'ORDER',
        },
        {
          name: 'Shop',
          value: 'SHOP',
        },
        {
          name: 'Learn more',
          value: 'LEARN_MORE',
        },
        {
          name: 'Sign up',
          value: 'SIGN_UP',
        },
        {
          name: 'Call',
          value: 'CALL',
        },
      ],
      selectedActionType: {
        name: 'No Button',
        value: '',
      },
      selectedStartEndDate: [],
      title: '',
      buttonLink: '',
      couponCode: '',
      redeemOfferLink: '',
      termsAndConditions: '',
      dateOptions: {
        format: 'MMMM DD, YYYY HH:mm',
        defaultValue: null,
        disabledDate: this.disabledBeforeTodayAndAfterAWeek,
      },
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces']),

    /**
     * Returns true if CTA option is selected from dropdown.
     * @returns {boolean}
     */
    isCallToAction() {
      return this.selectedTopicType.value === 'STANDARD'
    },

    /**
     * Returns true if Event option is seleced from dropdown.
     * @returns {boolean}
     */
    isEvent() {
      return this.selectedTopicType.value === 'EVENT'
    },

    /**
     * Returns tru of offer option is selected from dropdown.
     * @returns {boolean}
     */
    isOffer() {
      return this.selectedTopicType.value === 'OFFER'
    },

    getStartMinDate() {
      let minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone)
      minDate = minDate.format('MMMM DD, YYYY HH:mm')
      if (!this.gmbOptions.start_date) {
        this.$store.commit(social.SET_GMB_OPTIONS_START_DATE, minDate)
      }
      return minDate
    },

    getEndMinDate() {
      return this.gmbOptions.start_date
    },

    getStartDate() {
      if (this.gmbOptions.start_date) {
        return this.gmbOptions.start_date
      }
      const minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone)
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    getEndStartDate() {
      if (this.gmbOptions.end_date) {
        return this.gmbOptions.end_date
      }
      const endDate = moment(
        this.gmbOptions.start_date,
        'MMMM DD, YYYY HH:mm'
      ).add(15, 'days')
      this.$store.commit(
        social.SET_GMB_OPTIONS_END_DATE,
        endDate.format('MMMM DD, YYYY HH:mm')
      )
      return endDate.format('MMMM DD, YYYY HH:mm')
    },
  },
  watch: {
    'gmbOptions.action_type': {
      handler(val) {
        this.selectedActionType = this.actionType.find(
          (type) => type.value === val
        )
      },
      deep: true,
    },
  },
  mounted() {
    console.debug('GMB Options Mounted')
    this.initGmbOptions()
  },
  methods: {
    /**
     * Initializes GMB Options with default selected values.
     */
    initGmbOptions() {
      console.log('METHOD::initGmbOptions ~ gmbOptions -> ', this.gmbOptions)
      if (this.gmbOptions.topic_type?.length) {
        this.selectedTopicType = this.topicType.find(
          (item) => item.value === this.gmbOptions.topic_type
        )
      }
      if (this.gmbOptions.action_type?.length) {
        this.selectedActionType = this.actionType.find(
          (item) => item.value === this.gmbOptions.action_type
        )
      } else {
        this.gmbOptions.action_type = this.selectedActionType.value
      }

      if(!this.gmbOptions.start_date && this.gmbOptions.end_date){
        this.gmbOptions.start_date = moment().tz(
            this.getWorkspaces.activeWorkspace.timezone
        )._d
        this.gmbOptions.end_date = moment(this.gmbOptions.start_date).add(
            15,
            'D'
        )._d
      }

      this.selectedStartEndDate.push(new Date(this.gmbOptions.start_date))
      this.selectedStartEndDate.push(new Date(this.gmbOptions.end_date))
    },

    /**
     * OnDropdownSelect CTA event for gmb options.
     * @param item
     */
    onSelectPostType(item) {
      this.gmbOptions.topic_type = item.value
      this.selectedTopicType = item
    },

    /**
     * OnDropdownSelect event for action type (Call to Action, Event, Offer).
     * @param item
     */
    onSelectActionType(item) {
      this.gmbOptions.action_type = item.value
      this.selectedActionType = item
      this.$emit('setGmbOptionsEdited', true)
    },

    /**
     * Updates the title on change.
     * @param text
     */
    onChangeTitle: debounce(function (text) {
      this.gmbOptions.title = text
    }, 500),

    /**
     * Updates the button link on change.
     * @param link
     */
    onChangeButtonLink: debounce(function (link) {
      this.$emit('setButtonLink', link)
      if (this.gmbSharingData.cta_link !== link) {
        this.$emit('setGmbOptionsEdited', true)
      }
    }, 500),

    /**
     * Updates the coupon code on change.
     * @param code
     */
    onChangeCouponCode(code) {
      this.gmbOptionsDebouncedUpdate('coupon_code', code)
    },

    /**
     * Updates the redeem offer link on change.
     * @param link
     */
    onChangeRedeemOfferLink(link) {
      this.gmbOptionsDebouncedUpdate('redeem_online_url', link)
    },

    /**
     * Updates the terms and conditions on change.
     * @param value
     */
    onChangeTermsAndConditions(value) {
      this.gmbOptionsDebouncedUpdate('terms_conditions', value.target.value)
    },

    gmbOptionsDebouncedUpdate: debounce(function (property, value) {
      this.gmbOptions[property] = value
    }, 500),

    /**
     * Updates the start and end date on change for 'Event'.
     * @param date
     */
    onChangeStartEndDate(date) {
      console.log('METHOD::onChangeStartEndDate ~ date -> ', date)
      if (date === null) return
      const [startDate, endDate] = date
      this.gmbOptions.start_date = getDateTimeFormat(
        startDate,
        'MMMM DD, YYYY hh:mm A'
      )
      this.gmbOptions.end_date = getDateTimeFormat(
        endDate,
        'MMMM DD, YYYY hh:mm A'
      )
    },
  },
}
</script>

<style scoped></style>
