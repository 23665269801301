<script>
import { mapGetters } from 'vuex'
import { evergreen } from '../../../store/automation-mutation-type'
import AccountSelection from '../../../../publish/components/posting/social/AccountSelection'
import ContentCategorySelection from '../../../../publish/components/posting/social/ContentCategorySelection'

export default {
  components: {
    AccountSelection,
    ContentCategorySelection
  },
  created () {
    this.$store.commit(evergreen.SET_EVERGREEN_AUTOMATION_TAB_STATUS, 'first')
  },

  mounted () {
    // adding event listener for enter key
    document.addEventListener('keyup', this.keyPressListener)
  },

  unmounted () {
    // removing event listener when component unmounted
    document.removeEventListener('keyup', this.keyPressListener)
  },

  computed: {
    ...mapGetters(['getEvergreenSelection', 'getAccountSelection'])
  },

  methods: {
    keyPressListener (event) {
      // if enter is press will verify route and check step one validation
      // if valid then redirect to step 2 Post creation
      if (
        event.keyCode === 13 &&
        this.$route.name === 'saveEvergreenAutomationAccounts'
      ) {
        this.processStepFirst()
      }
    }
  },
  watch: {
    'getEvergreenSelection.name' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.name = false
      }
    },
    'getAccountSelection.facebook' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.twitter' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.linkedin' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.pinterest' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.tumblr' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.instagram' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    },
    'getAccountSelection.gmb' (value) {
      if (value && value.length > 0) {
        this.$parent.$parent.validations.social = false
      }
    }
  }
}
</script>

<template>
  <div class="page_1 connect_social_accounts">
    <div class="automation_page width_medium">
      <div class="page_inner">
        <div class="step_heading">
          <h3>Step 1 - Campaign Name and Channels</h3>
        </div>

        <div class="field_group">
          <input
            v-model="getEvergreenSelection.name"
            :class="{ 'input-field-error': $parent.$parent.validations.name }"
            type="text"
            placeholder="Campaign name e.g marketing posts"
            maxlength="100"
            data-cy="campaign-name"
          />
          <label class="label-animated">Campaign Name</label>

          <span v-if="$parent.$parent.validations.name" class="input-error">
            {{ $parent.$parent.messages.name }}
          </span>
        </div>

        <!--<div class="input_field">-->
        <!--<div class="input_validation">-->
        <!--<input :class="{'input-field-error' : $parent.validations.name}" classtype="text" placeholder="Enter campaign name..." v-model="getEvergreenSelection.name">-->
        <!--<span class="input-error" v-if="$parent.validations.name">-->
        <!--{{$parent.messages.name}}-->
        <!--</span>-->
        <!--</div>-->

        <!--</div>-->

        <div class="input_validation">
          <div class="input_field">
            <ContentCategorySelection
              :module="'evergreen_automation'"
            ></ContentCategorySelection>
          </div>

          <AccountSelection :module="'automation'" :show-label="true"></AccountSelection>
          <span v-if="$parent.$parent.validations.social" class="input-error">
            {{ $parent.$parent.messages.social }}
          </span>
        </div>

        <div class="field_group text-center">
          <a
            href=""
            class="btn btn-studio-theme-space"
            data-cy="step1-button"
            @click.prevent="$parent.$parent.processStepFirst(false)"
          >
            <span>Next</span>
            <i class="icon_right arrow_right_active cs-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
