<template>
  <b-modal
    id="add_category"
    centered
    header-class="cs-modal-header"
    hide-footer
    hide-header
    size="lg"
    title-class="cs-modal-title"
    no-close-on-esc
    no-close-on-backdrop
    @hidden="handleClose"
  >
    <div class="modal_head">
      <h4 v-if="!getContentCategoryAdd._id">Add Category</h4>
      <h4 v-else>Edit Category</h4>
      <button type="button" class="modal_head__close" @click="handleModalClose">
        &times;
      </button>
    </div>

    <div class="modal_body">
      <div class="grid grid-cols-2">
        <!--- left col -->
        <div class="flex flex-col">
          <div class="font-medium mt-4">Category Name</div>
          <div class="w-full flex flex-col mt-1 relative">
            <CstInputFields
              :value="getContentCategoryAdd.name"
              class="w-full required-input"
              type="text"
              placeholder="Articles, Tips, Podcasts, Webinars"
              @value="
                (value) => {
                  getContentCategoryAdd.name = value
                }
              "
              @focus="
                (e) => {
                  validations.name = false
                }
              "
            />
            <span
              v-if="validations.name"
              class="absolute top-10 w-max input-error"
            >
              {{ messages.name }}
            </span>
          </div>
          <div class="font-medium mt-4">Choose Color</div>
          <div class="w-full">
            <div class="add_category_color_boxes px-2">
              <template
                v-for="(value, key) in fetchColor(null, null)"
                :key="key"
              >
                <div class="float-left w-10 h-10 m-2">
                  <input
                    :id="key"
                    v-model="getContentCategoryAdd.color"
                    :value="key"
                    type="radio"
                    class="icon"
                    name="color_select"
                  />
                  <label
                    :for="key"
                    :class="
                      value +
                      ' item_inner w-full h-full cursor-pointer rounded-md'
                    "
                    data-cy="new-category-color"
                  >
                    <i
                      class="check_icon_planner cs-checked flex h-full items-center justify-center text-white text-xl"
                    ></i>
                  </label>
                </div>
              </template>
            </div>
          </div>
          <div v-if="!getContentCategoryAdd._id" class="clear-both">
            <div class="block font-medium mt-4">Category Type</div>
            <div class="flex">
              <div class="field_group">
                <label
                  class="radio_container"
                  for="local"
                  data-cy="local-category"
                >
                  <input
                    id="local"
                    v-model="getContentCategoryAdd.category_state"
                    value="local"
                    type="radio"
                    name="category_type"
                  />

                  <div>
                    Local Category
                    <i
                      v-tooltip="
                        'With local category, you can select your accounts and distribute content within this workspace.'
                      "
                      class="far fa-question-circle ml-1"
                    ></i>
                    <span class="check"></span>
                  </div>
                </label>
              </div>
              <div class="field_group">
                <label
                  class="radio_container"
                  for="global"
                  data-cy="global-category"
                >
                  <input
                    id="global"
                    v-model="getContentCategoryAdd.category_state"
                    value="global"
                    type="radio"
                    :disabled="!isEnableGlobalCategoryOption"
                    name="category_type"
                  />

                  <div>
                    Global Category
                    <i
                      v-tooltip="
                        isEnableGlobalCategoryOption
                          ? 'With global category, you will be able to distribute content to all of your social channels under all workspaces which you created.'
                          : 'Global Content Category can only be created by Super Administrators or Internal Team members.'
                      "
                      class="far fa-question-circle ml-1"
                    ></i>
                    <span class="check"></span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!--- right col -->
        <div class="flex flex-col px-4">
          <template
            v-if="
              getContentCategoryAdd.category_state === 'local' ||
              (getContentCategoryAdd.category_state === 'global' &&
                getContentCategoryAdd._id)
            "
          >
            <transition name="slide-bottom">
              <div>
                <div class="flex w-full items-center justify-between mt-4">
                  <div class="font-medium"
                    >Select Account(s)
                    <i
                      v-tooltip="
                        'Select which social profiles this category should be used  for.NB: existing posts will not automatically be copied to any new profiles selected.'
                      "
                      class="far fa-question-circle ml-1"
                    ></i>
                  </div>
                  <!-- select all -->
                  <div class="w-max flex items-center">
                    <label
                      for="selectAllCategoriesAccounts"
                      class="mx-1 !mb-0 cursor-pointer font-medium select-none"
                      data-cy="social-selection-all"
                      >Select All</label
                    >
                    <input
                      id="selectAllCategoriesAccounts"
                      v-model="select_all_categories_accounts"
                      type="checkbox"
                      class="cursor-pointer"
                      @input="selectAllAccounts"
                    />
                  </div>
                </div>
                <div class="input_field social_accounts mt-2">
                  <template v-if="totalAddedAccountsCount > 0">
                    <!-- account selection -->
                    <div
                      v-if="totalSelectedAccount > 0"
                      class="flex items-center w-full h-12 px-2"
                    >
                      <template
                        v-for="(account, index) in getSelectedAccount"
                        :key="index"
                      >
                        <img
                          v-if="index < 10"
                          class="w-8 h-8 border rounded-full -ml-1"
                          :src="
                            account.channel_type === 'gmb'
                              ? 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
                              : account.image ?? account.platform_logo
                          "
                          alt=""
                          @error="
                            (event) => {
                              event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                            }
                          "
                        />
                      </template>
                      <span
                        v-if="totalSelectedAccount > 10"
                        class="w-8 h-8 bg-gray-600 font-medium rounded-full text-center pt-2"
                        >+{{ totalSelectedAccount - 10 }}</span
                      >

                      <!--                        <div class="ml-auto">-->
                      <!--                            <span class="font-medium block">-->
                      <!--                              {{ totalSelectedAccount }} Account{{-->
                      <!--                                totalSelectedAccount > 1 ? 's' : ''-->
                      <!--                              }}-->
                      <!--                            </span>-->
                      <!--                          Selected-->
                      <!--                        </div>-->
                    </div>
                    <div
                      v-else
                      class="flex items-center w-full h-12 px-2 font-medium"
                    >
                      No Account Selected
                    </div>

                    <!-- searched accounts input -->
                    <label class="w-full flex items-center !mb-0 relative">
                      <input
                        v-model="searchQuery"
                        class="bg-cs-light-gray color-border focus:outline-none font-normal px-3 py-2.5 rounded-t-lg shadow-xs text-gray-900 text-sm w-full"
                        placeholder="Search Account By Name"
                        type="text"
                      />
                      <i
                        v-if="searchQuery.length"
                        class="fas fa-times-circle absolute cursor-pointer opacity-80 right-3 hover:text-red-400"
                        @click="searchQuery = ''"
                      ></i>
                    </label>

                    <!-- accounts list -->
                    <div
                      class="border !border-t-0 min-h-[18rem] rounded-b-lg max-h-72 overflow-y-scroll"
                    >
                      <!-- if searched accounts -->
                      <template v-if="searchQuery">
                        <template v-if="searchedAccounts.length > 0">
                          <template
                            v-for="(account, index) in searchedAccounts"
                            :key="index"
                          >
                            <div
                              class="text-gray-800 block cursor-pointer hover:bg-gray-100 focus:outline-none text-sm"
                            >
                              <SimpleCheckbox
                                v-model="
                                  getContentCategoryAdd[account.channel_type]
                                "
                                :name="account.channel_identifier"
                                class="group px-3 py-2"
                              >
                                <template v-slot:label>
                                  <span class="flex items-center">
                                    <span class="relative">
                                      <img
                                        :src="
                                          channelImage(
                                            account,
                                            account.channel_type,
                                          )
                                        "
                                        alt=""
                                        class="rounded-full object-cover border"
                                        data-fallback="0"
                                        height="32"
                                        width="32"
                                        @error="
                                          (event) => {
                                            event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                          }
                                        "
                                      />
                                      <img
                                        :src="
                                          require('@assets/img/integration/' +
                                            getSocialImage(
                                              account.channel_type,
                                            ))
                                        "
                                        alt=""
                                        class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                                        :class = "{ 'bg-white rounded-full': account?.channel_type === 'twitter' }"
                                      />
                                    </span>
                                    <span class="flex flex-col ml-3">
                                      <span class="flex flex-row">
                                        <span
                                          v-tooltip="{
                                            content:
                                              'platform_name' in account
                                                ? account.platform_name
                                                : account.name,
                                          }"
                                          class="text-sm text-ellipsis font-normal text-black-100 select-none"
                                        >
                                          {{
                                            truncateString(
                                              'platform_name' in account
                                                ? account.platform_name
                                                : account.name,
                                              32,
                                            )
                                          }}
                                        </span>
                                      </span>

                                      <span
                                        class="block text-xs select-none text-gray-800"
                                      >
                                        {{
                                          'type' in account
                                            ? account.type
                                            : 'Profile'
                                        }}
                                      </span>
                                    </span>
                                  </span>
                                </template>
                              </SimpleCheckbox>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="text-center w-full pt-5 font-medium">
                            <span>No Account Found</span>
                          </div>
                        </template>
                      </template>
                      <!-- all accounts -->
                      <template
                        v-for="(account, index) in getAddedAccount"
                        v-else
                        :key="index"
                      >
                        <div
                          class="text-gray-800 block cursor-pointer hover:bg-gray-100 focus:outline-none text-sm"
                        >
                          <SimpleCheckbox
                            v-model="
                              getContentCategoryAdd[account.channel_type]
                            "
                            :name="account.channel_identifier"
                            class="group px-3 py-2"
                          >
                            <template v-slot:label>
                              <span class="flex items-center">
                                <span class="relative">
                                  <img
                                    :src="
                                      channelImage(
                                        account,
                                        account.channel_type,
                                      )
                                    "
                                    alt=""
                                    class="rounded-full object-cover border"
                                    data-fallback="0"
                                    height="32"
                                    width="32"
                                    @error="
                                      (event) => {
                                        event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                      }
                                    "
                                  />
                                  <img
                                    :src="
                                      require('@assets/img/integration/' +
                                        getSocialImage(account.channel_type))
                                    "
                                    alt=""
                                    class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                                    :class = "{ 'bg-white rounded-full': account?.channel_type === 'twitter' }"
                                  />
                                </span>
                                <span class="flex flex-col ml-3">
                                  <span class="flex flex-row">
                                    <span
                                      v-tooltip="{
                                        content:
                                          'platform_name' in account
                                            ? account.platform_name
                                            : account.name,
                                      }"
                                      class="text-sm text-ellipsis font-normal text-black-100 select-none"
                                    >
                                      {{
                                        truncateString(
                                          'platform_name' in account
                                            ? account.platform_name
                                            : account.name,
                                          32,
                                        )
                                      }}
                                    </span>
                                  </span>

                                  <span
                                    class="block text-xs select-none text-gray-800"
                                  >
                                    {{
                                      'type' in account
                                        ? account.type
                                        : 'Profile'
                                    }}
                                  </span>
                                </span>
                              </span>
                            </template>
                            <template
                              v-if="disableAccount(account)"
                              v-slot:error
                            >
                              <div v-if="!isApprover">
                                <i
                                  :id="'tooltip-target-' + accountName(account)"
                                  class="fas fa-exclamation-square text-red-500 text-xl"
                                ></i>
                                <b-tooltip
                                  :target="
                                    'tooltip-target-' + accountName(account)
                                  "
                                  triggers="hover"
                                >
                                  <div v-if="isTwitterDisabled(account)">
                                    X (Twitter) integration is not supported on your
                                    plan. Please reconnect with a custom app to
                                    proceed.
                                    <a
                                      target="_blank"
                                      href="https://docs.contentstudio.io/article/966-how-to-connect-with-a-twitter-custom-app"
                                      class="text-yellow-400 underline hover:text-yellow-600"
                                      >Learn more.</a
                                    >
                                  </div>
                                  <div v-else-if="isIGPersonalAccount(account)">
                                    Mobile device is not connected, direct
                                    publishing is not available for instagram
                                    personal accounts.
                                  </div>
                                  <div v-else-if="disableAccount(account)">
                                    <span
                                      v-if="
                                        instaMobileDeviceWarning(
                                          account.channel_type,
                                        )
                                      "
                                      class="font-semibold"
                                      >1.</span
                                    >
                                    The access token for this social account has
                                    been invalidated, please reconnect to
                                    continue posting.
                                  </div>
                                  <div
                                    v-if="
                                      instaMobileDeviceWarning(
                                        account.channel_type,
                                      ) && !isIGPersonalAccount(account)
                                    "
                                    ><span class="font-semibold">2.</span>
                                    Mobile device is not connected, however you
                                    can still post via Instagram API
                                  </div>
                                </b-tooltip>
                              </div>
                              <div
                                v-if="isIGPersonalAccount(account)"
                                class="ml-auto mr-2"
                              >
                                <i
                                  v-b-tooltip
                                  title="Direct publishing is not supported on IG personal profiles and will be sent via Push Notification method instead."
                                  class="fas fa-exclamation-square text-red-500 text-xl"
                                ></i>
                              </div>

                              <div
                                v-if="
                                  instaMobileDeviceWarning(
                                    account.channel_type,
                                  ) && !disableAccount(account)
                                "
                                class="ml-auto mr-2"
                              >
                                <i
                                  v-b-tooltip
                                  title="Mobile device is not connected, however you can still post via Instagram API"
                                  class="fas fa-exclamation-square text-red-500 text-xl"
                                ></i>
                              </div>
                            </template>
                          </SimpleCheckbox>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <span>You have not connected any account yet.</span>
                    <a
                      class="ml-2"
                      href=""
                      style="text-decoration: underline"
                      @click.prevent="GoToSocial"
                      >Connect an account</a
                    >
                  </template>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
            <transition name="slide-bottom">
              <div class="input_field text_block">
                <div class="warning_box">
                  <ul class="add_category_list_items">
                    <li>
                      Global content category will be created in all workspaces
                      with the same name.
                    </li>
                    <li>
                      By default, all accounts in all workspaces will be
                      selected. You'll be able to select/change accounts in each
                      workspace individually after creation of this category.
                    </li>
                    <li>
                      You'll be able to create slots in one or all workspaces
                      using the "Add slot" button. We recommend having different
                      slots in different workspaces to post the same content at
                      different times.
                    </li>
                    <li>
                      Global Content Category can only be created by Super
                      Administrators or Internal Team members.
                    </li>
                  </ul>
                </div>
              </div>
            </transition>
          </template>
        </div>
      </div>
      <!--- footer -->
      <div class="flex w-full justify-end">
        <div class="field_group">
          <CstButton
            variant="primary"
            :disabled="btnDisable"
            :loading="getContentCategoryLoaders?.store"
            :class="{
              enable:
                (!getContentCategoryAdd?.name?.trim() === '' &&
                  checkAccountStatus() === true) ||
                (!getContentCategoryAdd?.name?.trim() === '' &&
                  getContentCategoryAdd?.category_state === 'global'),
            }"
            @click="validateAndStoreCategory"
          >
            <span>
              {{ getCategoryStoreButton }}
            </span>
          </CstButton>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  socialChannelsArray,
  swalAttributes,
} from '@common/constants/common-attributes'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import CstInputFields from '@ui/Input/CstInputFields'
import CstButton from '@ui/Button/CstButton.vue'
import { getPlatformName } from '@common/lib/integrations'
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'
import { EventBus } from '@/src/modules/common/lib/event-bus'
export default {
  components: { SimpleCheckbox, CstInputFields, CstButton },
  mixins: [ComposerHelper],
  data() {
    return {
      messages: {
        name: 'Name cannot be empty.',
        color: 'Please choose a category color.',
      },
      validations: {
        name: false,
        color: false,
      },
      select_all_categories_accounts: false,
      socialChannels: [
        // {name: 'facebook', accountsGetter : '', key: ''},
      ],
      searchQuery: '',
      searchedAccounts: [],
      btnDisable: false,
      isEditing: false,
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategorySlotAdd',
      'getContentCategoryAdd',
      'getContentCategoryLoaders',
      'getFacebookAccounts',
      'getPinterestBoards',
      'getTwitterAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getYoutubeAccounts',
      'getTiktokAccounts',
      'getMobileDevices',
      'getThreadsAccounts'
    ]),
    /**
     * Determine the appropriate message to display based on whether a content category is being added or updated.
     */
    computeContentCategoryMessage() {
      return this.getContentCategoryAdd._id
        ? 'Content category has been successfully updated.'
        : 'Content category has been successfully created.'
    },
    isApprover() {
      return this.getUserRole === 'Approver'
    },
    getCategoryStoreButton() {
      return this.isEditing ? 'Update' : 'Next'
    },
    totalAddedAccountsCount() {
      let count = 0
      this.socialChannels.forEach((item) => {
        const accountsGetter = this.$store.getters[item.getter]
        // eslint-disable-next-line no-prototype-builtins
        count += accountsGetter.hasOwnProperty('items')
          ? accountsGetter.items.length
          : accountsGetter.length
      })
      return count
    },
    getAddedAccount() {
      const accounts = []
      this.socialChannels.forEach((channel) => {
        const accountsGetter = this.$store.getters[channel.getter]
        // eslint-disable-next-line no-prototype-builtins
        const getterAccounts = accountsGetter.hasOwnProperty('items')
          ? accountsGetter.items
          : accountsGetter
        getterAccounts.forEach((account) => {
          account.channel_type = channel.name
          account.channel_identifier = account[channel.key]
          accounts.push(account)
        })
      })
      return accounts
    },
    totalSelectedAccount() {
      let count = 0
      this.socialChannels.forEach((platform) => {
        count += this.getContentCategoryAdd[platform.name]?.length || 0
      })
      return count
    },
    getSelectedAccount() {
      const selectedAccounts = []
      this.socialChannels.forEach((channel) => {
        const accountsGetter = this.$store.getters[channel.getter]
        // eslint-disable-next-line no-prototype-builtins
        const getterAccounts = accountsGetter.hasOwnProperty('items')
          ? accountsGetter.items
          : accountsGetter
        getterAccounts.forEach((account) => {
          if (
            this.getContentCategoryAdd[channel.name]?.includes(
              account[channel.key]
            )
          ) {
            account.channel_type = channel.name
            selectedAccounts.push(account)
          }
        })
      })
      return selectedAccounts
    },
  },
  watch: {
    searchQuery: function (newVal, oldVal) {
      if (newVal) {
        this.searchedAccounts = this.getQueryAccounts(newVal)
        return
      }
      this.searchedAccounts = []
    },
    'getContentCategoryAdd.name'(value) {
      if (value && value.trim().length > 0) this.validations.name = false
    },
    'getContentCategoryAdd.color'(value) {
      if (value && value.trim().length > 0) this.validations.color = false
    },
    'getContentCategoryAdd.category_state'(value) {
      this.clearSearchedQuery()
    },
  },
  mounted() {
    EventBus.$on('edit-category', () => {
      this.isEditing = true
    })
    this.socialChannels = socialChannelsArray.filter(
      (channel) => !['tumblr'].includes(channel.name)
    )
  },
  unmounted() {
    EventBus.$off('edit-category')
    EventBus.$off('category-added')
  },
  methods: {
    instaMobileDeviceWarning(platform) {
      return platform === 'instagram'
        ? !(this.getMobileDevices && this.getMobileDevices.length > 0)
        : false
    },
    accountName(account) {
      return getPlatformName(account)
    },
    selectAllAccounts(event, flag = false) {
      // unset all of the selected accounts
      this.socialChannels.forEach((social) => {
        this.getContentCategoryAdd[social.name] = []
      })
      // select all accounts
      if (!this.select_all_categories_accounts) {
        this.getFacebookAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.facebook.push(item.facebook_id)
          }
        })

        this.getTwitterAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.twitter.push(item.twitter_id)
          }
        })

        this.getPinterestBoards.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.pinterest.push(item.board_id)
          }
        })

        this.getLinkedinAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.linkedin.push(item.linkedin_id)
          }
        })

        this.getInstagramAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.instagram.push(item.instagram_id)
          }
        })

        this.getGmbAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.gmb.push(item.name)
          }
        })

        this.getYoutubeAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.youtube.push(item.platform_identifier)
          }
        })

        this.getTiktokAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.tiktok.push(item.platform_identifier)
          }
        })

        this.getThreadsAccounts.items.forEach((item) => {
          if (!this.disableAccount(item) && !this.isApprover) {
            this.getContentCategoryAdd.threads.push(item.platform_identifier)
          }
        })
      }
    },
    getQueryAccounts(value) {
      let searchedAccounts = []
      if (value) {
        searchedAccounts = this.getAddedAccount.filter((item) =>
          item['platform_name' in item ? 'platform_name' : 'name']
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      }
      return searchedAccounts
    },
    clearSearchedQuery() {
      this.searchQuery = ''
    },
    checkAccountStatus() {
      if (
        this.getContentCategoryAdd.facebook !== '' ||
        this.getContentCategoryAdd.twitter !== '' ||
        this.getContentCategoryAdd.pinterest !== '' ||
        this.getContentCategoryAdd.linkedin !== '' ||
        this.getContentCategoryAdd.instagram !== '' ||
        this.getContentCategoryAdd.gmb !== '' ||
        this.getContentCategoryAdd.threads !== ''
      ) {
        return true
      }
    },
    async validateAndStoreCategory() {
      // document.getElementById(btn.id).disabled = true;
      const category = this.getContentCategoryAdd
      this.validations.name = this.requiredCheck(category.name)
      this.validations.color = this.requiredCheck(category.color)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        // check if no account is selected show error message
        if (
          this.getSelectedAccount?.length === 0 &&
          category.category_state === 'local'
        ) {
          this.$store.dispatch('toastNotification', {
            message: 'Please select at least one account.',
            type: 'error',
          })
          return
        }
        let res
        this.btnDisable = true
        try {
          res = await this.$store.dispatch(
            category.category_state === 'local'
              ? 'storeCategory'
              : 'storeGlobalCategory'
          )
          if (res?.status) {
            const category = {
              name:
                res &&
                res.data &&
                (res.data.name ||
                  (res.data.data.category && res.data.data.category.name)),
              id:
                res &&
                res.data &&
                (res.data._id ||
                  (res.data.data.category && res.data.data.category._id)),
            }
            // Only show the add slot modal if the category is being created for the first time.
            this.$store.commit(
              contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE,
              category.id
            )
            this.$bvModal.hide('add_category')
            if (this.isEditing) {
              // Dispatches a toast notification with a message indicating the status of the content category operation.
              await this.$store.dispatch('toastNotification', {
                message: this.computeContentCategoryMessage,
                type: 'success',
              })
            } else {
              // Show the add slot modal
              this.$bvModal.show('add_slot')
              EventBus.$emit('category-added', category)
            }
            EventBus.$emit('scroll-down', category)

            if (
              !this.getActiveWorkspace.onboarding_steps.content_category.status
            ) {
              await this.onboardingStepsCompleted('content_category')
            }
            this.trackUserMaven('publishing_queues_created')

            this.isEditing = false
          }
        } catch (e) {
          this.btnDisable = true
        }
      }
    },
    GoToSocial() {
      if (this.$route.name === 'contentcategories') {
        this.$router.push({ name: 'socialaccounts' })
        this.$bvModal.hide('add_category')
      } else {
        this.$router.push({ name: 'social' })
        this.$bvModal.hide('add_category')
      }
    },
    handleModalClose(event) {
      // show the confirmation dialog if the user is trying to close the modal while editing a category
      if (this.getContentCategoryAdd._id && !this.isEditing) {
        this.$root.$bvModal
          .msgBoxConfirm(
            'Content category has been created successfully. Do you want to add the slots later?',
            {
              title: 'Add Slots Later',
              ...swalAttributes(),
              cancelTitle: 'Cancel',
              okTitle: 'Add Slots Later',
            }
          )
          .then((value) => {
            if (value) {
              this.$bvModal.hide('add_category')
            }
          })
      } else {
        this.$bvModal.hide('add_category')
      }
    },
    handleClose() {
      this.clearSearchedQuery()
      this.validations.name = this.validations.color = false
      this.btnDisable = false
      this.isEditing = false
    },
  },
}
</script>

<style scoped lang="less">
.clear {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.border-radius (@radius: 3px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@ds-lightGray: #989eb5; // search navigation font color // search filter tab icon color
@ds-lightGray_2: #edf1fc; // social tab background // search filter tab background
@ds-lightGray_3: #c3cbeb; // social tab icon background // search input border color
@green2: #00c489;
@white: #ffffff;
.social_accounts {
  .account_list {
    .profile_picture {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      margin-bottom: 0.625rem;
    }
  }
}
.add_category_list_items {
  list-style: disc !important;
  margin-left: 20px;
  li {
    list-style-type: disc !important;
    padding-bottom: 0.625rem;
  }
  li:last-child {
    padding-bottom: 0px;
  }
}
.add_category_color_boxes {
  input[type='radio'] {
    display: none !important;
  }
  input[type='radio']:checked + .item_inner {
    .check_icon_planner {
      display: flex !important;
    }
  }
  .item_inner {
    .check_icon_planner {
      display: none !important;
    }
  }
}
</style>
