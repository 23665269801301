<script setup>
import { computed } from 'vue'
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import CardsComponent from '@src/modules/analytics/views/performance-report/label-and-campaign/components/CardsComponent.vue'
import ImpressionsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/EngagementsGraph.vue'
import LableAndCampaignTable from '@src/modules/analytics/views/performance-report/label-and-campaign/components/LableAndCampaignTable.vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'

// CONSTATS
import { PLATFORMS } from '@src/modules/analytics_v3/constants/constants'

const {
  breakdownDataByLabel,
  breakdownDataByImpression,
  breakdownDataByEngagement,
  insightsGraphBreakDown,
  isLoadingStates,
} = useLabelAndCampaign()

const chunkSize = 9
/**
 * Computes the array of start indexes for chunks of data.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const breakdownDataByLabelChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByLabel.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

const breakdownDataByImpressionChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByImpression.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

const breakdownDataByEngagementChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByEngagement.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of array.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  return (
    breakdownDataByLabelChunks.value.length +
    breakdownDataByImpressionChunks.value.length +
    breakdownDataByEngagementChunks.value.length
  )
})
</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Campaigns and Labels'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-4 gap-4 mb-7">
          <CardsComponent />
        </div>

        <LableAndCampaignTable
          :title="'Post Breakdown by Campaign & Label'"
          :data="
            breakdownDataByLabelChunks.length > 1
              ? breakdownDataByLabel.slice(
                  0,
                  Math.min(0 + chunkSize, breakdownDataByLabel.length))
              : breakdownDataByLabel
          "
          :is-all-data-fetched="false"
          :headers="['totalPost', 'rateOfChange']"
          :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
          :chart-data="insightsGraphBreakDown"
          :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
          :type="'breakdown'"
          :modal-key="'posts'"
        />
      </div>
    </div>
  </div>
  <template v-if="breakdownDataByLabelChunks.length > 1">
    <template v-for="(start, index) in breakdownDataByLabelChunks" :key="index">
      <!-- Iterate over each chunk of top engaging posts -->
      <div v-if="index !== 0" class="page_view">
        <div class="subpage">
          <!-- Render report header -->
          <ReportHeader
            :report-name="'Campaigns and Labels'"
            :current="1 + index"
            :total="totalPageSize"
          ></ReportHeader>
          <!-- Render table for the current chunk -->

          <LableAndCampaignTable
            :title="'Post Breakdown by Campaign & Label'"
            :data="
              breakdownDataByLabel.slice(
                start,
                Math.min(start + chunkSize, breakdownDataByLabel.length))
            "
            :is-all-data-fetched="false"
            :headers="['totalPost', 'rateOfChange']"
            :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
            :chart-data="insightsGraphBreakDown"
            :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
            :type="'breakdown'"
            :modal-key="'posts'"
          />
        </div>
      </div>
    </template>
  </template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Campaigns and Labels'"
        :current="1 + breakdownDataByLabelChunks.length"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <ImpressionsGraph
          :is-loading="isLoadingStates.getCampaignLabelInsightsBreakdown"
        />

        <LableAndCampaignTable
          :title="'Post Impressions by Campaign & Label'"
          :data="
            breakdownDataByImpressionChunks.length > 1
              ? breakdownDataByImpression.slice(
                  0,
                  Math.min(0 + chunkSize, breakdownDataByImpression.length))
              : breakdownDataByImpression
          "
          :is-all-data-fetched="false"
          :headers="['totalImpressions', 'rateOfChange']"
          :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
          :chart-data="insightsGraphBreakDown"
          :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
          :type="'breakdown'"
          :is-small-card="false"
          :modal-key="'impressions'"
        />
      </div>
    </div>
  </div>
  <template v-if="breakdownDataByImpressionChunks.length > 1">
    <template
      v-for="(start, index) in breakdownDataByImpressionChunks"
      :key="index"
    >
      <!-- Iterate over each chunk of top engaging posts -->
      <div v-if="index !== 0" class="page_view">
        <div class="subpage">
          <!-- Render report header -->
          <ReportHeader
            :report-name="'Campaigns and Labels'"
            :current="1 + index + breakdownDataByLabelChunks.length"
            :total="totalPageSize"
          ></ReportHeader>
          <!-- Render table for the current chunk -->

          <LableAndCampaignTable
            :title="'Post Impressions by Campaign & Label'"
            :data="
              breakdownDataByImpression.slice(
                start,
                Math.min(start + chunkSize, breakdownDataByImpression.length))
            "
            :is-all-data-fetched="false"
            :headers="['totalImpressions', 'rateOfChange']"
            :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
            :chart-data="insightsGraphBreakDown"
            :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
            :type="'breakdown'"
            :modal-key="'posts'"
          />
        </div>
      </div>
    </template>
  </template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Campaigns and Labels'"
        :current="
          1 +
          breakdownDataByLabelChunks.length +
          breakdownDataByImpressionChunks.length
        "
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col gap-7">
        <EngagementsGraph
          :is-loading="isLoadingStates.getCampaignLabelInsightsBreakdown"
        />

        <LableAndCampaignTable
          :title="'Post Engagements by Campaign & Label'"
          :data="
            breakdownDataByEngagement.length > 1
              ? breakdownDataByEngagement.slice(
                  0,
                  Math.min(0 + chunkSize, breakdownDataByEngagement.length))
              : breakdownDataByEngagement
          "
          :is-all-data-fetched="false"
          :headers="['totalEngagements', 'rateOfChange']"
          :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
          :chart-data="insightsGraphBreakDown"
          :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
          :type="'breakdown'"
          :is-small-card="false"
          :modal-key="'engagements'"
        />
      </div>
    </div>
  </div>
  <template v-if="breakdownDataByEngagementChunks.length > 1">
    <template
      v-for="(start, index) in breakdownDataByEngagementChunks"
      :key="index"
    >
      <!-- Iterate over each chunk of top engaging posts -->
      <div v-if="index !== 0" class="page_view">
        <div class="subpage">
          <!-- Render report header -->
          <ReportHeader
            :report-name="'Campaigns and Labels'"
            :current="
              1 +
              index +
              breakdownDataByLabelChunks.length +
              breakdownDataByImpressionChunks.length
            "
            :total="totalPageSize"
          ></ReportHeader>
          <!-- Render table for the current chunk -->

          <LableAndCampaignTable
            :title="'Post Engagements by Campaign & Label'"
            :data="
              breakdownDataByEngagement.slice(
                start,
                Math.min(start + chunkSize, breakdownDataByEngagement.length))
            "
            :is-all-data-fetched="false"
            :headers="['totalEngagements', 'rateOfChange']"
            :is-loading="isLoadingStates.getCampaignLabelBreakdownData"
            :chart-data="insightsGraphBreakDown"
            :platform-name="PLATFORMS.LABELSANDCAMPAIGNS"
            :type="'breakdown'"
            :modal-key="'posts'"
          />
        </div>
      </div>
    </template>
  </template>
</template>
