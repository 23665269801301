<template>
  <b-modal
    id="post-scheduler-modal"
    centered
    size="lg"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    modal-class="overflow-y-hidden side_slide_right !top-2"
    dialog-class="h-full m-0 ml-auto max-w-[460px]"
    content-class="h-full !rounded-none"
    body-class="!p-0 flex flex-col overflow-hidden"
    @show="handleModalShow"
  >
    <Header />
      <div class="flex justify-center items-center w-full py-4">
        <div class="flex">
          <div class="py-2.5 px-7 border !rounded-l-lg text-center cursor-pointer" :class="state.customSchedule ? 'bg-[#F4F6FA] !border-[#ECEEF5] text-[#757A8A]' :  'bg-[#2961D2] text-[white]'" @click="state.customSchedule = false">
            <p class="font-bold text-sm">Same Time</p>
            <p class="text-xs">for all account(s)</p>
          </div>
          <div
              v-tooltip="{
            content: selectedAccountsLength > 1 ? '' : 'Need to select more than one account for custom scheduling',
              }"
              class="py-2.5 px-7 border !rounded-r-lg text-center"
              :class="{
            'bg-[#2961D2] text-white': state.customSchedule && selectedAccountsLength > 1,
            'cursor-not-allowed': selectedAccountsLength === 1,
            'cursor-pointer': selectedAccountsLength > 1,
            'bg-[#F4F6FA] border-[#ECEEF5] text-[#757A8A]': !state.customSchedule,
          }" @click="state.customSchedule = selectedAccountsLength > 1">
            <p class="font-bold text-sm">Custom Time</p>
            <p class="text-xs">for each account</p>
          </div>
        </div>
      </div>

      <template v-if="!state.customSchedule">
        <div
          class="border !border-t-[#ECEEF5] !border-l-0 !border-r-0 !border-b-0 pt-6 px-6 flex-grow overflow-y-auto"
        >
          <div class="flex justify-between items-center mb-4">
            <p class="font-bold text-sm">Select Date & Time To Post</p>
            <div class="flex items-center">
              <button class="focus:outline-none border-0 bg-transparent leading-none" :class="bestTimeClass" :disabled="disableBestTime" @click="$emit('open-best-time-chart')">Best time to Post</button>
              <v-menu
                  class="mx-2"
                  :popper-triggers="['hover']"
                  placement="bottom-end"
                  popper-class="first-comment__info-popover"
                  :delay="{ hide: 300 }"
              >
                <!-- This will be the popover target (for the events and position) -->
                <a href="#"
                ><i class="far fa-question-circle text-base text-blue-900"></i
                ></a>
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <div>Available for:</div>
                  <ul>
                    <li>
                      <i class="fab fa-facebook-f"></i>
                      <i class="fab fa-instagram"></i>
                      <i class="fab fa-linkedin-in"></i>
                    </li>
                  </ul>
                  <p class="first-comment__message-box">
                    How to add recommended best time?
                    <a
                        v-close-popper
                        href="#"
                        class="beacon"
                        data-beacon-article-modal="61fb84dcd86136157d9a059a"
                    >Click here</a
                    >
                  </p>
                </template>
              </v-menu>
            </div>

          </div>
          <div class="flex items-center justify-center relative">
            <DateAndTime
              :fixed-schedule-time="fixedScheduleTime"
              @fixed-schedule="handleFixedDateChange"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="overflow-y-scroll overflow-x-hidden flex-grow border !border-t-[#ECEEF5] !border-l-0 !border-r-0 !border-b-0 pt-6"
        >
          <template v-for="(channel, index) in socialChannelsNameArray">
            <SocialMediaPostingScheduler
              v-if="
                schedulingData[channel] &&
                schedulingData[channel].accounts.length
              "
              :key="`custom-scheduler-${channel}_${index}`"
              :channel="channel"
              :channel-logo="getSocialIcon(channel)"
              :channel-logo-rounded="getSocialImageRounded(channel)"
              :custom-schedule-data-array="schedulingData[channel].accounts"
              :channel-time="schedulingData[channel].time"
              @channel-schedule-data="manageCustomScheduleData"
            />
          </template>
        </div>
      </template>

      <div class="bg-[#EAF0F6] py-4 w-100 pr-4 mt-2 flex item-center justify-end gap-3">
        <div v-if="scheduleError" class="mt-2">
          <img
              v-tooltip="'You are not allowed to select the past time to schedule a post.'"
              src="@assets/img/composer/error-icon.svg"
              alt=" "
              class="w-6 h-6"
          />
        </div>
        <button
            v-tooltip="{
            content: getButtonTooltip,
            }"
            class="bg-cs-primary rounded-md focus:outline-none text-cs-white border-0 px-4 py-2 float-right"
            :class="{ 'opacity-50 !cursor-not-allowed ': isButtonDisabled || scheduleError }"
            :disabled="isButtonDisabled || scheduleError"
            @click="handleScheduleConfirm"
        >Continue
        </button>
      </div>
  </b-modal>
</template>

<script setup>
// libraries
import { onMounted, inject, reactive, computed, ref } from 'vue'

// components
import SocialMediaPostingScheduler from '@src/modules/composer_v2/components/PostSchedulerModule/SocialMediaPostingScheduler.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import { socialChannelsNameArray } from '@common/constants/common-attributes'
import Header from '@/src/modules/composer_v2/components/PostSchedulerModule/Header'
import DateAndTime from '@/src/modules/composer_v2/components/PostSchedulerModule/DateAndTime'

// utils
import { EventBus } from '@/src/modules/common/lib/event-bus'
import useDateFormat from "@common/composables/useDateFormat";

// functionality
const props = defineProps({
  recommendedBestTime: {
    type: Array,
    default: () => [],
  },
  recommendedTimeChartData: {
    type: Array,
    default: () => [],
  },
  fixedScheduleTime: {
    type: String,
    default: '',
  },
  schedulingData: {
    type: Object,
    default: () => {},
  },
  showTimeRecommendation: {
    type: Boolean,
    default: false,
  },
  selectedAccountsLength: {
    type: Number,
    default: 0,
  },
  disableBestTime: {
    type: Boolean,
    default: false,
  },
  bestTimeClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['open-best-time-chart', 'custom-schedule-data', 'set-fixed-schedule', 'fixed-schedule'])
const {momentWrapper} = useDateFormat()
const root = inject('root')
const { $bvModal } = root
const state = reactive({
  allChannelsList: socialChannelsNameArray,
  customSchedule: false,
  customScheduleDataArray: {},
})
const { getSocialIcon, getSocialImageRounded } = useComposerHelper()

// computed
const isButtonDisabled = computed(() => {
  if (state.customSchedule) {
    return Object.values(props.schedulingData).some((channel) =>
        channel.accounts.some((account) => account.time === '')
    )
  }
  return !props.fixedScheduleTime
})

const scheduleError = computed(() => {
  if(state.customSchedule){
    return Object.values(props.schedulingData).some((channel) =>
        channel.accounts.some((account) => account.invalidTime)
    )
  }else {
    const currentTime = momentWrapper().formatTimezone().formatDateTime()
    return momentWrapper(currentTime).isAfter(props.fixedScheduleTime)
  }
})

const getButtonTooltip = computed(() => {
  if (isButtonDisabled.value) {
    return 'Please select time for all accounts'
  }else if(scheduleError.value){
    return 'You are not allowed to select the past time to schedule a post.'
  }
  return ''
})

// mounted
onMounted(() => {
  EventBus.$on('post-scheduler-modal', () => {
    $bvModal.show('post-scheduler-modal')
  })
})


// triggered on continue button click
const handleScheduleConfirm = () => {
  if(isButtonDisabled.value) return
  state.customSchedule
      ? emit('custom-schedule-data')
      : emit('set-fixed-schedule')
  $bvModal.hide('post-scheduler-modal')
}

// triggered on custom schedule data change
const manageCustomScheduleData = (singleChannelScheduleData) => {
  state.customScheduleDataArray = {
    ...state.customScheduleDataArray,
    ...singleChannelScheduleData,
  }
}

// triggered on fixed schedule data change
const handleFixedDateChange = (fixedScheduleTime) => {
  emit('fixed-schedule', fixedScheduleTime)
}

// opens best time chart modal
const handleModalShow = () => {
  if(props.selectedAccountsLength <= 1){
    state.customSchedule = false
  }
}

</script>

<style lang="scss" scoped>
.customize {
  background: #ffffff;
  border: 1px solid #eff0f1;
  box-shadow: 0 4px 8px rgba(225, 225, 225, 0.25);
  border-radius: 10px;
}
</style>
