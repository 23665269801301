import VueCookie from 'vue-cookie'
import moment from 'moment'
import _ from 'underscore'
import { EventBus } from '@common/lib/event-bus'
import {
  COOKIE_DOMAIN,
  DEFAULT_IMAGE,
  WORKSPACE_ID,
  INBOX_ACTION_TITLES,
} from './constants'
import { socialChannelsArray } from './inbox-filters'

const commonMethods = {
  addSharingEmoji(event) {
    console.debug(
      event.currentTarget,
      'Method::addSharingEmoji',
      event.currentTarget.children[0].attributes.alt.value
    )
    const selector = document.getElementById('common_sharing_message')
    const emojiUnicode = event.currentTarget.children[0].attributes.alt.value
    setTimeout(function () {
      const cursorPositionStart = selector.selectionStart
      selector.value =
        selector.value.substring(0, cursorPositionStart) +
        emojiUnicode +
        selector.value.substring(selector.selectionEnd)
      selector.selectionStart = cursorPositionStart + 2
      selector.selectionEnd = cursorPositionStart + 2
    }, 50)
    document.getElementById('common_sharing_message').value = selector.value
    selector.focus()
  },

  showToast(temp, title, message) {
    console.debug(title, message, 'title, messagetitle, message')
    const h = temp.$createElement
    let alertType = 'success'
    let alertIcon = 'far fa-check'
    if (title === 0 || title === 1) {
      alertType = 'error'
      alertIcon = 'far fa-ban'
    } else if (title === 3 || title === 4) {
      alertType = 'success'
      alertIcon = 'far fa-check'
    } else if (title === 2) {
      alertType = 'warning'
      alertIcon = 'far fa-exclamation-circle'
    }

    const toasterHTML = h(
      'div',
      { class: [`toastr_inner d-flex align-items-center ${alertType}`] },
      [
        h(
          'div',
          {
            class: 'icon_sec d-flex align-items-center justify-content-center',
          },
          [h('i', { class: alertIcon })]
        ),
        h('div', { class: 'content_sec' }, [

          h('h3', { class: 'alert-title' }, INBOX_ACTION_TITLES[title]),
          h('p', { class: 'alert-desc' }, message),
        ]),
      ]
    )

    temp.$bvToast.toast([toasterHTML], {
      toastClass: 'toasted',
      variant: 'default',
      autoHideDelay: 3000,
      solid: true,
      toaster: 'b-toaster-bottom-left',
      appendToast: true,
    })
  },

  timesAgo(date) {
    return moment.utc(date).fromNow()
  },

  daysAgo(date) {
    return moment().diff(date, 'days')
  },

  async getActiveWorkspace(temp) {
    let workspace = await temp.getDefaultWorkspace
    let workspaceSlug = ''
    if (workspace != null) {
      workspaceSlug = workspace.workspace_slug
    } else {
      const workspaceId = VueCookie.get(WORKSPACE_ID, { domain: COOKIE_DOMAIN })
      const wsResponse = await temp.getWorkspaceDetails({
        workspace_id: workspaceId,
      })
      if (wsResponse.isValid) {
        workspace = wsResponse.workspace
        workspaceSlug = workspace.workspace_slug
      } else {
        console.log('No workspace found!')
        return
      }
    }
    temp.$router.push({ path: '/' + workspaceSlug + '/inbox-main' })
  },

  getFailedResponseObject(responseObj, message) {
    responseObj.isValid = false
    responseObj.message = message
    return responseObj
  },

  limitText(value, limit) {
    if (value && value.length > limit) {
      return value.substring(0, limit) + '...'
    }
    return value
  },

  pushAllToArray(array, field, status, type = null) {
    const all = []
    if (status) {
      _.forEach(array, function (e, k) {
        if (!(type === 'platform' && e.inbox_permission !== 'granted')) {
          all.push(e[field])
          e.is_selected = true
        }
      })
    } else {
      _.forEach(array, function (e, k) {
        e.is_selected = false
      })
    }
    return all
  },
  async setUnsetPlatforms(temp, platform, all = false) {
    if (!(platform in temp.selectedChannels)) {
      temp.$set(temp.selectedChannels, platform, [])
    }
    temp.selectedChannels[platform] = commonMethods.pushAllToArray(
      temp.channels[platform],
      'platform_id',
      temp.allChannels[platform],
      platform === 'facebook' ? 'platform' : null
    )
    if (!all) {
      temp.all = Object.values(temp.allChannels).reduce((a, b) => a && b)
      await temp.fetchDetails(false)
      temp.executeFilter()
    }
  },

  async setUnsetAll(temp, type, refresh = true) {
    switch (type) {
      case 'channels':
        _.forEach(temp.channels, function (e, k) {
          if (!(k in temp.allChannels)) {
            temp.$set(temp.allChannels, k, true)
          }
          temp.allChannels[k] = temp.all
          commonMethods.setUnsetPlatforms(temp, k, true)
        })
        break
      case 'tags':
        temp.selectedTags = await commonMethods.pushAllToArray(
          temp.tags,
          '_id',
          temp.allTags
        )
        break
      case 'type':
        temp.selectedFilterTypes = await commonMethods.pushAllToArray(
          temp.filterTypes,
          'key',
          temp.allTypeFilter
        )
        if (refresh) {
          EventBus.$emit('update-side-bar', {
            typeFilters: temp.selectedFilterTypes,
          })
        }
        temp.executeFilter()
        break
    }
    if (refresh) {
      if (temp.fetchDetails) {
        await temp.fetchDetails(false, temp.getInboxFilter.inbox_types)
      }
      temp.executeFilter()
    }
  },

  async setOnly(temp, type, refresh = true) {},

  getSocialDetail(temp, inboxDetail) {
    const socialItems = socialChannelsArray.filter(function (social) {
      return social.name === inboxDetail.platform
    })

    if (socialItems.length > 0) {
      const socialItem = socialItems[0]
      const getter = temp.$store.getters[socialItem.getter]
      const channelItems = Object.hasOwn(getter, 'items')
        ? getter.items
        : getter

      const channelItem = channelItems.filter(function (channel) {
        return channel[socialItem.key] === inboxDetail.platform_id
      })

      if (channelItem.length > 0) {
        return channelItem[0]
      }
    }
    return { image: DEFAULT_IMAGE, name: 'Facebook User' }
  },

  redirectConversationLink(temp) {
    if (temp.activeInboxDetail !== null) {
      temp.$router.push({
        path:
          '/' +
          temp.getActiveWorkspace.slug +
          '/inbox/' +
          temp.getInboxFilter.action.toLowerCase() +
          '/' +
          temp.activeInboxDetail.element_details.element_id,
      })
    } else {
      temp.$router.push({
        path:
          '/' +
          temp.getActiveWorkspace.slug +
          '/inbox/' +
          temp.getInboxFilter.action.toLowerCase() +
          '/conversation',
      })
    }
  },

  setActiveUser(temp) {
    temp.activeUser = {
      user_id: temp.getProfile._id,
      user_name: temp.getProfile.full_name,
      user_image: temp.getProfile.image,
    }
  },

  isIncomingMessage(temp, message) {
    return message.recipient_id === temp.activeInboxDetail.platform_id
  },

  getUserImageForNote(temp, message) {
    const teamMembers = temp.getWorkspaceInboxTeamMembers
    if (message.note_added_by != null) {
      for (let x = 0; x < teamMembers.length; x++) {
        const member = teamMembers[x]
        if (message.note_added_by === member.user_id) {
          return member.user.image != null ? member.user.image : DEFAULT_IMAGE
        }
      }
    }

    return DEFAULT_IMAGE
  },

  getImageForUser(temp, message) {
    return commonMethods.isIncomingMessage(temp, message)
      ? temp.activeInboxDetail.inbox_details.posted_by !== null
        ? temp.activeInboxDetail.inbox_details.posted_by.image
        : DEFAULT_IMAGE
      : commonMethods.getSocialDetail(temp, temp.activeInboxDetail).image
  },

  moveScrollBottom(id) {
    alert('here')
    setTimeout(() => {
      const el = document.getElementById(id)
      el.scrollTop = el.scrollHeight
    }, 10)
  },
  getPlatformIcon(platform) {
    switch (platform) {
      case 'facebook':
        return 'fa-facebook-f fb_bg'
      case 'twitter':
        return 'fa-twitter tw_bg'
      case 'instagram':
        return 'fa-instagram ins_bg'
      case 'google_my_business':
        return 'icon-GMB gmb_bg'
      case 'linkedin':
        return 'fa-linkedin-in lin_bg'
    }
  },
  isEmpty(object) {
    return !_.isEmpty(object)
  },
  getDefaultImage() {
    return DEFAULT_IMAGE
  },
  getPostedByImage(postedBy) {
    return postedBy !== null && postedBy.image ? postedBy.image : DEFAULT_IMAGE
  },
  getPostedByName(postedBy, platform) {
    return postedBy !== null && postedBy.name
      ? postedBy.name
      : platform + ' User'
  },
  getPostedByUserName(postedBy) {
    return postedBy !== null && postedBy.user_name ? postedBy.user_name : ''
  },
}

export { commonMethods }
