import { mapGetters, mapActions } from 'vuex'
import {
  UNKNOWN_ERROR,
  COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED,
  COMPOSER_PUBLICATION_FOLDER_CREATED_SUCCESS,
  COMPOSER_PUBLICATION_FOLDER_CREATED_FAIL,
  COMPOSER_PUBLICATION_FOLDER_UPDATE_FAIL,
  COMPOSER_PUBLICATION_FOLDER_MOVE_FAIL,
  COMPOSER_PUBLICATION_FOLDER_MOVE_SUCCESS
} from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

import { composer } from '@src/modules/composer/store/composer-mutation-type.js'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import {
  getPublicationFoldersURL,
  createPublicationFolderURL,
  updatePublicationFolderNameURL,
  removePublicationFolderURL,
  updatePublicationFolderIdURL
} from '@src/modules/composer/config/api-utils.js'

const PublicationFolderMixin = {
  data () {
    return {}
  },
  mixins: [Helpers],
  created () {},

  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPublicationNewFolder',
      'getPublicationFolders',
      'getMoveCampaign',
    ])
  },

  methods: {
    ...mapActions([]),

    fetchPublicationFolders () {
      console.debug('Method:fetchPublicationFolders')

      this.$store.commit(composer.SET_FETCH_PUBLICATION_FOLDERS_LOADER, true)

      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        source: 'web'
      }
      this.postRequest(
        getPublicationFoldersURL,
        payload,
        (response) => {
          const folders = []
          if (response?.data?.folders ) {
            Object.keys(response.data.folders)?.forEach(function (element) {
              response.data.folders[element].updateStatus = false
              folders.push(response.data.folders[element])
            })
            folders.push({
              name: this.getDefaultCampaignName,
              default: true,
                _id: 'edit_default_folder',
            })

            folders.sort((a, b) => {
              const nameA = a.name.toUpperCase(); // Ensure case-insensitive sorting
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names must be equal
            });

            this.$store.commit(composer.SET_PUBLICATION_FOLDERS, folders)
            this.$store.commit(
                composer.SET_PUBLICATION_FOLDERS_TOTAL,
                response.data.total
            )

            this.$store.commit(
                composer.SET_FETCH_PUBLICATION_FOLDERS_LOADER,
                false
            )
          }
        },
        (error) => {
          console.debug('Exception in fetchPublicationFolders ', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.$store.commit(
            composer.SET_FETCH_PUBLICATION_FOLDERS_LOADER,
            false
          )
        }
      )
    },

    createPublicationFolder (editId = "") {
      console.debug('Method:createPublicationFolder')
      this.$store.commit(
        composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER,
        true
      )
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        folder: this.getPublicationNewFolder,
      }
      if(editId) payload.editId = editId

      this.postRequest(
        createPublicationFolderURL,
        payload,
        (response) => {
          if (response.data.status === true) {
            if(payload.editId && payload.editId === 'edit_default_folder') this.$set(this.getWorkspaces.activeWorkspace, 'default_campaign_name', payload.folder.name)
            this.alertMessage(
              COMPOSER_PUBLICATION_FOLDER_CREATED_SUCCESS,
              'success'
            )
            const newFolder = {
              _id: response.data?.data?._id,
              name: response.data?.data?.name,
              counts: 0,
              color: response.data?.data?.color,
            }
            EventBus.$emit('createNewCampaignFolder', newFolder)
            this.fetchPublicationFolders()
            $('#createFolder').modal('hide')
            this.getPublicationNewFolder.name = ''
            if (this.getMoveCampaign && this.getMoveCampaign.campaignCreate) {
              if (
                response.data &&
                response.data.data &&
                response.data.data._id
              ) {
                this.$store.commit(
                  composer.SET_MOVE_CAMPAIGN_ID,
                  response.data.data._id
                )
              }
              $('#movePublicationModal').modal('show')
              this.$store.commit(
                composer.SET_MOVE_CAMPAIGN_CREATE_STATUS,
                false
              )
            }
          } else {
            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage(
                COMPOSER_PUBLICATION_FOLDER_CREATED_FAIL,
                'error'
              )
            }
          }
          this.$store.commit(
            composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER,
            false
          )
        },
        (error) => {
          console.debug('Exception in fetchPublicationFolders ', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.$store.commit(
            composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER,
            false
          )
        }
      )
    },
    movePostCampaign () {
      console.debug('Method:movePostCampaign')

      this.$store.commit(composer.SET_MOVE_CAMPAIGN_LOADER, true)
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        planId: this.getMoveCampaign.planId,
        folderId: this.getMoveCampaign.id
      }

      this.postRequest(
        updatePublicationFolderIdURL,
        payload,
        (response) => {
          if (response.data.status === true) {
            this.alertMessage(
              COMPOSER_PUBLICATION_FOLDER_MOVE_SUCCESS,
              'success'
            )
            this.fetchPublicationFolders()
            this.refetchPublications()
            EventBus.$emit('refreshPlannerTableV2')
            this.$store.commit(composer.SET_MOVE_CAMPAIGN_DEFAULT)
            this.$store.commit(composer.SET_PUBLICATIONS_SELECT_ALL, false)
            this.$store.commit(composer.SET_PUBLICATIONS_BULK_IDS, [])
            $('#movePublicationModal').modal('hide')
          } else {
            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage(COMPOSER_PUBLICATION_FOLDER_MOVE_FAIL, 'error')
            }
          }
          this.$store.commit(composer.SET_MOVE_CAMPAIGN_LOADER, false)
        },
        (error) => {
          console.debug('Exception in fetchPublicationFolders ', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.$store.commit(
            composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER,
            false
          )
        }
      )
    },
    removePublicationFolder (confirmation, id) {
      console.debug('Method:removePublicationFolder', confirmation, id)
      const selfObject = this
      if (confirmation.status) {
        this.postRequest(
          removePublicationFolderURL,
          {
            id,
            workspace_id: selfObject.getWorkspaces.activeWorkspace._id
          },
          (response) => {
            if (response.data.status === true) {
              this.setConfirmActionStage('success')
              if (this.getPublications.folder.id === id) {
                this.$store.commit(composer.SET_PUBLICATIONS_FOLDER, null)
              }
              this.fetchPublicationFolders()
              this.alertMessage('Campaign successfully removed.', 'success')
            } else {
              if (response.data.message) {
                this.alertMessage(response.data.message, 'error')
              } else {
                this.alertMessage('Unable to remove Campaign .', 'error')
              }
              this.setConfirmActionStage('fail')
            }

            this.closeConfirmAction(confirmation.type)
          },
          (error) => {
            this.setConfirmActionStage('fail')
            console.debug('Exception in removePublication', error)
            this.closeConfirmAction(confirmation.type)
          }
        )
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },
    changeFolderEditStatus (folder) {
      const stateObject = this

      this.getPublicationFolders.forEach(function (element, index) {
        if (element._id === folder) {
          stateObject.getPublicationFolders[index].updateStatus = true
          stateObject.getPublicationFolders[index].updateName =
            stateObject.getPublicationFolders[index].name
        } else {
          stateObject.getPublicationFolders[index].updateStatus = false
        }
      })
    },
    updateFolderName (folder) {
      console.debug('Method:updateFolderName', folder)

      if (folder.updateName.trim() === '') {
        this.alertMessage(COMPOSER_PUBLICATION_FOLDER_NAME_REQUIRED, 'error')
        return false
      }

      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        folder
      }

      this.postRequest(
        updatePublicationFolderNameURL,
        payload,
        (response) => {
          if (response.data.status === true) {
            const stateObject = this

            this.getPublicationFolders.forEach(function (element, index) {
              if (element._id === folder._id) {
                stateObject.getPublicationFolders[index].updateStatus = false
                stateObject.getPublicationFolders[index].name =
                  folder.updateName
              }
            })
          } else {
            this.alertMessage(COMPOSER_PUBLICATION_FOLDER_UPDATE_FAIL, 'error')
          }
          this.$store.commit(
            composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER,
            false
          )
        },
        (error) => {
          console.debug('Exception in updateFolderName ', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },
    fetchFolderPublication (folder) {
      console.debug('Method:fetchFolderPublication', folder)
      this.$store.commit(composer.SET_PUBLICATIONS_FOLDER, folder)
      this.$store.commit(composer.SET_PUBLICATIONS_SELECT_ALL, false)
      this.$store.commit(composer.SET_PUBLICATIONS_BULK_IDS, [])
      this.refetchPublications()
    },

    changeComposerPostType (type) {
      console.debug('Method:changeComposerPostType', type)
      this.$store.commit(composer.SET_PUBLICATIONS_TYPE, type)
      this.refetchPublications()
    }
  }
}

export { PublicationFolderMixin }
