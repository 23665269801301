<script>
import useDateFormat from '@common/composables/useDateFormat'
import moment from 'moment'
import 'moment-duration-format'
import {
  getAssetId,
  DragDropMixin,
} from '@src/modules/publish/components/media-library/utils/helper.js'
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'

export default {
  name: 'Asset',
  mixins: [DragDropMixin],
  props: {
    id: {
      default: null,
      type: [String, Number],
    },
    tag: {
      default: 'div',
      type: String,
    },
    src: {
      default: '',
      type: String,
    },
    alt: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
    type: {
      default() {
        return 'primary'
      },
      type: String,
    },
    hideEye: {
      default() {
        return false
      },
      type: Boolean,
    },
    hideDelete: {
      default() {
        return false
      },
      type: Boolean,
    },
    showRestore: {
      default() {
        return false
      },
      type: Boolean,
    },
    info: {
      default() {
        return {
          name: 'Conference room.jpeg',
          size: '2.24 MB',
          updated: '',
          thumbnails: {},
        }
      },
      type: Object,
    },
    isMediaAsset: {
      default() {
        return false
      },
      type: Boolean,
    },
    showShare: {
      default() {
        return false
      },
      type: Boolean,
    },
    folderId: {
      default: 'all',
      type: String,
    },
  },
  emits: ['check', 'delete', 'restore', 'view', 'share'],
  setup() {
    const { momentWrapper } = useDateFormat()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    return { openDraftComposer, draftPlanId, momentWrapper }
  },
  data() {
    return {
      index: getAssetId(),
    }
  },
  computed: {
    updatedAt() {
      return this.info.updated_at
        ? this.momentWrapper(this.info.created_at)
            .formatTimezone()
            .formatDateTime()
        : ''
    },
    validSrc() {
      if (!isEmpty(this.info.thumbnails)) {
        return this.info.thumbnails.small
      }
      return this.src
    },
  },
  methods: {
    convertDuration(seconds) {
      const duration = moment.duration(seconds, 'seconds')
      return duration.format('mm [min] ss [s]')
    },
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      let thumbnails = []
      if (!isEmpty(this.info?.thumbnails)) {
        thumbnails = Object.values(this.info.thumbnails)
      }
      // for heif images
      if (this.info.type && this.info.type === 'image/heif') {
        event.target.src =
          'https://storage.googleapis.com/lumotive-web-storage/placeholder.png'
        return
      }

      const fallbacks = [
        ...thumbnails,
        this.src,
        'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png',
      ]

      if (fallbackIndex > fallbacks.length - 1) {
        event.target.src =
          'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        return
      }
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },

    checkBox() {
        this.$emit('check', this.$el)
    },
    deleteItem() {
      this.$emit('delete', this.id, this.info.link)
    },
    restoreItem() {
      this.$emit('restore', this.id, this.info.link)
    },
    viewItem() {
      this.$emit('view', this.info)
    },
    // open pdf in new tab
    viewPdf() {
      // open in new tab
      window.open(this.src, '_blank')
    },
    viewTabItem() {
      EventBus.$emit('open-tab-media-preview', this.src)
    },
    isVideo(mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },
    isPdf(mime) {
      if (mime) {
        return mime.includes('application/pdf')
      } else {
        return false
      }
    },
    async shareItem() {
      this.$emit('share', this.info)
    },
    previewFile(type) {
      if (type === 'pdf') {
        this.viewPdf()
      } else {
        this.viewItem()
      }
    },
  },
}
</script>

<template>
  <component
    :is="tag"
    :id="id"
    class="
      relative
      overflow-hidden
      rounded-xl
      my-2
      cursor-pointer
      group
      asset
      border border-gray-400
    "
    draggable="true"
    :data-item-selected="isSelected"
    @dragstart="onDragStart($event)"
    @dragend="onDragEnd"
    @click.stop.prevent="checkBox"
    :key="`asset-${id}`"
  >
    <img
      data-fallback="0"
      class="w-full h-[166px] object-contain rounded-xl cursor-pointer asset-img"
      :src="validSrc"
      :alt="alt"
      loading="lazy"
      @error="onError($event)"
    />


    <!-- Hover overlay with actions -->
    <div
      class="
        absolute
        inset-0
        transition-opacity
        duration-300
        flex flex-col
        justify-between
        p-2
        rounded-xl
        layout
      "
    >
      <!-- Top-left icons -->
      <div class="absolute top-2 left-2 flex items-center justify-center">
      <span
          class="icon-preview cursor-pointer !z-10"
          @click.stop="
          previewFile(isPdf(info['mime_type']) ? 'pdf' : 'image/video')
        "
          v-tooltip="'View Preview'"
      >
        <img
            src="@src/assets/img/planner/preview-file.svg"
            class="
            w-5
            h-5
            p-1
            bg-[#efefef]
            rounded-md
            hover:bg-white
            transition-colors
            duration-300
          "
            alt="preview file"
        />
      </span>

        <!-- File Type-->
        <span class="icon-file-type bg-[#0707074b] rounded-md text-white !z-10">
        <i
            v-if="isVideo(info['mime_type'])"
            class="fas fa-video p-[5px] text-[0.8rem]"
            v-tooltip="'Video'"
        ></i>
        <i
            v-else-if="isPdf(info['mime_type'])"
            class="fas fa-file-pdf p-[5px] text-[0.8rem]"
            v-tooltip="'PDF'"
        ></i>
        <i
            v-else
            class="fas fa-image p-[5px] text-[0.8rem]"
            v-tooltip="'Image'"
        ></i>
      </span>
      </div>

      <!-- Top-right actions -->
      <div class="flex justify-end">
        <span
            v-if="info?.is_global"
            class="icon-global bg-[#0707074b] p-1 rounded-md">
          <i
            class="far fa-crown text-google drop-shadow font-bold"
            v-tooltip="
              'This media is globally shared and accessible throughout all your workspaces.'
            "
          ></i>
        </span>
        <span class="actions space-x-2">
          <i
            v-if="showRestore"
            title="Restore"
            class="
              far
              fa-recycle
              bg-[#efefef]
              text-[#26cb85]
              p-[5px]
              rounded-md
              text-[0.8rem]
              cursor-pointer
              hover:bg-white
              transition-colors
              duration-300
            "
            @click.stop="restoreItem"
          ></i>
          <i
            v-if="!hideDelete"
            v-tooltip="'Delete'"
            class="
              far
              fa-trash-alt
              bg-[#efefef]
              text-[#e02f5f]
              p-[5px]
              rounded-md
              text-[0.8rem]
              cursor-pointer
              hover:bg-white
              transition-colors
              duration-300
            "
            @click.stop="deleteItem"
          ></i>
          <i
            v-if="
              type === 'primary' &&
              showShare &&
              $route.query.type !== 'archived'
            "
            v-tooltip="'Compose Post'"
            class="
              far
              fa-share-alt
              bg-[#efefef]
              text-[#6173ff]
              p-[5px]
              rounded-md
              text-[0.8rem]
              cursor-pointer
              hover:bg-white
              transition-colors
              duration-300
            "
            @click.stop="shareItem"
          ></i>
        </span>
      </div>

      <!-- Bottom row with file info and checkbox -->
      <div class="info-checkbox flex items-end justify-between">
        <div class="file-info">
          <p class="text-xs text-white">
            {{ info.size ? bytesToSize(info.size) : '' }}
            {{
              info.duration_seconds
                ? '&nbsp; ♦ &nbsp;' + convertDuration(info.duration_seconds)
                : ''
            }}
          </p>
          <p class="text-xs text-white">{{ updatedAt }}</p>
        </div>

        <div v-if="type === 'primary'" class="asset-action">
          <img src="@src/assets/img/planner/checkbox-unselected.svg" class='w-6 h-6 unchecked' alt="unchecked" />
          <img src="@src/assets/img/planner/checkbox-hovered.svg" class='w-6 h-6 hovered' alt="" />
          <img src="@src/assets/img/planner/checkbox-selected.svg" class='w-6 h-6 checked' alt="checked" />
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
/* Mixin for shadow inset effect */
@mixin shadow-inset-center {
  -webkit-box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
}

/* Styles for unselected assets */
.asset {
  &.asset-img {
    cursor: pointer;
  }

  &:not([data-item-selected='true']) {
    .icon-preview,
    .actions {
      display: none;
    }
    .info-checkbox {
      visibility: hidden;
    }

    &:hover {
      .icon-preview,
      .actions {
        display: block;
      }

      .icon-file-type, .icon-global {
        display: none;
      }

      .info-checkbox {
        visibility: visible;
      }
    }

  }
}

/* Styles for selected assets */
.asset[data-item-selected='true'] {
  .icon-preview,
  .actions {
    display: none;
  }
  .layout {
    background: linear-gradient(
            to bottom,
            rgba(15,34,77,0.3) -26%,
            rgba(38, 40, 44, 0.6) 100%
    );
    z-index: 9;
    border : 3px solid #409aff !important;
  }

  .info-checkbox {
    visibility: visible;

    .hovered, .unchecked{
      display: none;
    }
    .checked {
      display: block;
    }
  }

  &:hover {
    .layout {
      animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      z-index: 9;
    }
    .icon-preview,
    .actions {
      display: block;
    }
    .icon-file-type, .icon-global {
      display: none;
    }
  }
}

/* Styles for hovered assets */
.asset-action {
  .unchecked {
    display: block;
  }
  .hovered {
    display: none;
  }
  .checked {
    display: none;
  }

  &:not([data-item-selected='true']):hover {
    .unchecked, .checked {
      display: none;
    }

    .hovered {
      display: block;
    }
  }

}

/* General styles for hover and animations */
.layout:hover {
  @include shadow-inset-center;
  animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background: linear-gradient(
          to bottom,
          rgba(38, 40, 44, 0.3) -26%,
          rgba(38, 40, 44, 0.6) 100%
  );
  transition: 0.3s all ease-in;
}

@-webkit-keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  }
}

@keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>
