<template>
  <div class="cst-editor-first-comment color-border rounded-xl px-3 py-3">
    <div class="flex items-center">
      <div class="w-48 ml-1 flex items-center">
        <div
          class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary"
        >
          <img
            src="@assets/img/composer/composer-comment-icon.svg"
            alt=""
            class="w-5 h-5"
          />
        </div>
        <span class="mx-2 font-medium text-sm text-black-300"
          >First Comment</span
        >
        <v-menu
          class=""
          :popper-triggers="['hover']"
          placement="bottom-end"
          popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
            ><img
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> First comment is available for: </div>
            <ul>
              <li> <i class="fab fa-facebook-f"></i> Pages </li>
              <li> <i class="fab fa-instagram"></i> API Posting Method </li>
              <li> <i class="fab fa-linkedin-in"></i> Profiles and Pages </li>
              <li>
                <i class="fab fa-youtube"></i> All videos except 'Private' and
                'Made for Kids'
              </li>
            </ul>

            <p class="first-comment__message-box"
              >How to add first comment?
              <a
                v-close-popover="true"
                href="#"
                class="beacon"
                data-beacon-article-modal="60407b770a2dae5b58fb5bef"
                >Click here</a
              ></p
            >
          </template>
        </v-menu>
      </div>
      <div>
        <CstSwitch
          v-model="firstComment.has_first_comment"
          class="mx-2"
          size="small"
        />
      </div>
      <div class="ml-auto">
        <AccountSelectionDropdown
          type="firstComment"
          :first-comment="firstComment"
          :accounts-list="accountsList"
          :is-allow-first-comment="isAllowFirstComment"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
    </div>

    <div class="mt-3">
      <div class="first-comment__wrapper">
        <textarea
          v-model="firstComment.first_comment_message"
          class="rounded-lg w-full p-3 border-0 text-sm bg-cs-light-gray"
          :maxlength="getMaxLength()"
          placeholder="Your Comment..."
          rows="4"
          @input="onInputComment"
        ></textarea>
        <div
          class="cst-lite-editor__toolbar rounded-b-2xl text-base flex justify-end bg-white px-2 py-2 select-none"
        >
          <!-- Hashtag dropdown -->
          <div class="hashtag-dropdown relative mr-2">
            <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="hashtagToggle = !hashtagToggle"
            >
              <div
                v-tooltip.top="'Add Hashtags'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md"
              >
                <img
                  src="@assets/img/composer/hashtag-icon.svg"
                  alt=" "
                  class="w-4 h-4"
                />
              </div>
            </div>
            <HashtagDropdown
              v-if="hashtagToggle"
              :is-open="hashtagToggle"
              type="first-comment"
              :apply-hashtag="applyHashtag"
              style="right: 0"
              @handle-hashtags="handleHashtagsChanges"
              @handle-click="handleClickAwayEvents"
            >
            </HashtagDropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HashtagDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/HashtagDropdown'
import debounce from 'lodash/debounce'
import AccountSelectionDropdown from '@src/modules/composer_v2/components/AccountSelectionDropdown'
import CstSwitch from '@ui/Switch/CstSwitch'
const clone = require('rfdc/default')
export default {
  name: 'EditorFirstCommentBox',
  components: {
    AccountSelectionDropdown,
    HashtagDropdown,
    CstSwitch,
  },
  props: {
    firstComment: {
      type: Object,
      default: () => {},
    },
    accountsList: {
      type: Array,
      default: () => {
        return []
      },
    },
    isAllowFirstComment: {
      type: Function,
      default: () => {},
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      check_all: false,
      hashtagToggle: false,
    }
  },

  computed: {
    isCollapsed() {
      return this.accountsList.length <= 10
    },
    accountSelectionCount() {
      return this.accountsList.length
    },
  },

  mounted() {
    console.debug(
      'EditorFirstCommentBox mounted',
      this.firstComment,
      this.accountsList
    )
  },

  methods: {
    isAccountSelected(account) {
      if (this.firstComment && this.firstComment.first_comment_accounts) {
        return this.firstComment.first_comment_accounts.includes(
          account[account.channel_identifier]
        )
      }
      return false
    },
    getAccountImage(account) {
      if (account.image) return account.image
      else if (account.platform_logo) return account.platform_logo
      else return ''
    },

    getMaxLength() {
      if (
        this.accountsList.find(
          (acc) =>
            acc.channel_type === 'linkedin' &&
            this.firstComment.first_comment_accounts.includes(
              acc[acc.channel_identifier]
            )
        )
      )
        return 1250
      else if (
        this.accountsList.find(
          (acc) =>
            acc.channel_type === 'instagram' &&
            this.firstComment.first_comment_accounts.includes(
              acc[acc.channel_identifier]
            )
        )
      )
        return 2200
      else return 8000
    },

    selectAllAccounts(status) {
      const comment = clone(this.firstComment)
      comment.first_comment_accounts = []
      if (status) {
        this.accountsList.map((account) => {
          if (this.isAllowFirstComment(account))
            comment.first_comment_accounts.push(
              account[account.channel_identifier]
            )
        })
      }
      this.$emit('changeFirstComment', comment)
    },

    onChangeSelection(payload) {
      console.debug('EditorFirstCommentBox onChangeSelection', payload)
      const comment = clone(this.firstComment)
      if (payload.value) comment.first_comment_accounts.push(payload.channelId)
      else
        comment.first_comment_accounts.splice(
          this.firstComment.first_comment_accounts.indexOf(payload.channelId),
          1
        )
      this.$emit('changeFirstComment', comment)
    },

    handleHashtagsChanges(flag, data) {
      this.$emit('handle-hashtags', 'first-comment', flag, data)
    },

    handleClickAwayEvents(toggle, type) {
      if (toggle) {
        switch (type) {
          case 'hashtag':
            this.hashtagToggle = false
            break
        }
      }
    },

    onInputComment: debounce(function (event) {
      console.debug('EditorFirstCommentBox onInputComment', event)
      this.$emit('changeFirstComment', this.firstComment)
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.cst-editor-first-comment {
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  //&:focus-within {
  //  background-color: #fff;
  //  //border-color: #80bdff;
  //  border-color: #80bdff30;
  //  box-shadow: 0 0 0 1px rgba(0,123,255,.25);
  //  outline: 0;
  //}
}
</style>
