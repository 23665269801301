<template>
  <div
    v-if="comment !== null"
    class="write_block comment_response d-flex align-items-start"
    :class="isRoot ? '' : 'inbox_reply_comment'"
  >
    <div class="image_block">
      <div
        class="img"
        :style="
          'background: url(' +
          commonMethods.getSocialDetail(temp, post).image +
          ')'
        "
      >
      </div>
    </div>
    <div class="text_area">
      <span style="float: right">
        <clip-loader
          v-if="!comment.has_failed"
          style="padding-left: 10px"
          class="d-inline-block"
          color="rgba(152, 158, 181, 0.8)"
          :size="'20px'"
        ></clip-loader>
      </span>
      <div class="content">
        <span class="user_name mention">{{
          commonMethods.getSocialDetail(temp, post).name
        }}</span
        >{{ commentData.comment_message }}
      </div>
      <div class="content_date ml-3">
        <span
          v-if="commentData.attachment !== null"
          v-tooltip.top="commentData.attachment.name"
          class="attachment_file attachment_file_post"
        >
          <i
            class="d-flex align-items-center justify-content-center attachment-type fal fa-file"
          ></i>
          {{ commonMethods.limitText(commentData.attachment.name, 15) }}
          <i
            v-if="commentData.attachment"
            class="d-flex align-items-center justify-content-center cross_btn fal fa-times"
            @click="removeMedia"
          ></i>
        </span>
        <div
          v-if="commentData.has_failed === true"
          class="on-failed-comment pl-1"
        >
          <p
            ><i
              v-b-popover.hover.html.top="commentData.error_message"
              class="fas fa-exclamation has-tooltip rounded-full text-xs text-white pl-1.5 pr-1.5 mr-1 bg-red-400"
            ></i>
            Comment wasn't sent.
            <span class="link" @click="sendComment">Retry </span> or
            <span
              class="link"
              @click="
                removeComment(
                  rootCommentId,
                  isRoot ? null : commentData.comment_id,
                )
              "
              >Delete
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'

export default {
  components: {},
  props: {
    temp: { type: Object, default: () => {} },
    isRoot: { type: Boolean, default: false },
    rootCommentId: { type: String, default: '' },
    post: { type: Object, default: () => {} },
    comment: { type: Object, default: () => {} },
    removeComment: { type: Function, default: () => {} },
    appendComment: { type: Function, default: () => {} },
  },
  data() {
    return {
      commonMethods,
    }
  },
  computed: {
    commentData() {
      return this.comment
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['reply']),
    async sendComment() {
      this.comment.has_failed = false
      if (
        !this.commentData?.payload?.get('message') &&
        !this.commentData?.payload?.get('attachment_type')
      ) {
        await this.$store.dispatch('toastNotification', {
          message: 'Missing comment message or attachment.',
          type: 'info',
        })
        this.comment.has_failed = true
      } else {
        const response = await this.reply(this.commentData.payload)
        if (response.isValid) {
          this.removeComment(
            this.rootCommentId,
            this.isRoot ? null : this.comment.comment_id
          )
          this.appendComment(
            this.comment.type,
            response.response,
            this.comment.index
          )
        } else {
          this.comment.error_message = response.response?.error
            ? response.response.error
            : response.message
          this.comment.has_failed = true
        }
      }
    },
    removeMedia() {
      this.commentData.attachment = null
      this.commentData.payload.set('attachment_file', null)
      this.commentData.payload.set('attachment_type', '')
    },
  },
}
</script>

<style lang="less" scoped>
.inbox_reply_comment {
  padding-left: 50px;
}

.like {
  color: rgb(219, 83, 82);
  cursor: pointer;
}

.replies-toggle {
  float: right;
  text-decoration: underline;
  color: #385898;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
}

.on-failed-comment {
  .link {
    color: #00aced;
    font-weight: 500;
    cursor: pointer;
  }

  p {
    color: fade(#ff3366, 80%);
    font-size: 12px;
  }
}
</style>
