<script setup>
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import PostsSection from '@src/modules/analytics/views/twitter/components/PostsSection.vue'
import { computed } from 'vue'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

import PostEngagementAndImpression from '@src/modules/analytics/views/twitter/components/graphs/PostEngagementAndImpression.vue'
import CardsComponent from '@/src/modules/analytics/views/twitter/components/CardsComponent'
import FollowersGraph from '@/src/modules/analytics/views/twitter/components/graphs/FollowersGraph.vue'
import FollowingGraph from '@/src/modules/analytics/views/twitter/components/graphs/FollowingGraph.vue'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/twitter/components/graphs/TopAndLeastEngagingPosts.vue'

const { selectedAccount, topPostsData, leastPostsData } = useTwitterAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  for (let i = 0; i < topPostsData.value?.length; i += chunkSize) {
    startIndexes.push(i)
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const hardCodedSections = 7
  return hardCodedSections + topEngagingPostsChunks.value.length
})
</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'X (Twitter)'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-7">
          <CardsComponent />
        </div>

        <!-- Cumulative Followers Graph -->
        <FollowersGraph class="!mb-7" />

        <!-- Daily Followers Graph -->
        <FollowersGraph class="!mb-7" default-type="bar" />
      </div>
    </div>
  </div>

  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'X (Twitter)'"
        :current="2"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Cumulative Following Graph -->
      <FollowingGraph class="!mb-7" />

      <!-- Daily Following Graph -->
      <FollowingGraph class="!mb-7" default-type="bar" />
    </div>
  </div>

  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'X (Twitter)'"
        :current="3"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Engagements Graph -->
      <PostEngagementAndImpression
        class="!mb-7"
        :chart-type="'total_engagement'"
        :title="'Engagement'"
      />

      <!-- Impressions Graph -->
      <PostEngagementAndImpression
        class="!mb-7"
        :chart-type="'impression_count'"
        :title="'Impression'"
      />
    </div>
  </div>

  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'X (Twitter)'"
        :current="4"
        :total="totalPageSize"
      ></ReportHeader>

      <!-- Top and least posts -->
      <TopAndLeastEngagingPosts
        :top-posts="topPostsData"
        :least-posts="leastPostsData"
        :selected-account="selectedAccount"
      />
    </div>
  </div>

  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view">
      <div class="subpage">
        <!-- Render report header -->
        <ReportHeader
          :report-name="'X (Twitter)'"
          :current="5 + index"
          :total="totalPageSize"
        ></ReportHeader>
        <!-- Render post table for the current chunk of top posts -->
        <PostsSection
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, topPostsData?.length)"
        />
      </div>
    </div>
  </template>
</template>
