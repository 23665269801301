<template>
  <div class="feeds-follow-categories">
    <div class="feeds-follow-categories__action_section">
      <template v-if="$route.query.q && $route.query.q.length > 1">
        <div
          v-click-away="closeSearchDropdownOnActions"
          class="feeds-follow-categories__action_section__search_bar search-bar-input search-bar-input-discovery with-icon-right"
        >
          <div
            class="feeds-follow-categories__action_section__search_bar__left search-bar-inner"
          >
            <input
              id=""
              v-model="search_query"
              type="text"
              placeholder="Add your Feed URL, keyword or domain here"
              data-cy="discovery_search_bar"
              class="discoverySearchInput"
              style="padding-top: 12px; padding-bottom: 12px"
              @keyup.enter="searchFromActionsQuery"
              @focus="is_visible = true"
              @click="is_visible = true"
            />
            <button
              data-cy="discovery_search_button"
              class="search_btn input_icon search_icon gradient_color"
              @click="searchFromActionsQuery"
            >
              <i class="icon-Search"></i
            ></button>

            <FeederSearchHistory
              v-if="search_history_terms.length"
              :terms="search_history_terms"
              :is_visible="is_visible"
              :is-visible-history="isVisibleHistory"
              :query="search_query"
            ></FeederSearchHistory>
          </div>
          <div
            class="feeds-follow-categories__action_section__search_bar__right"
          >
            <span>OR</span>
            <router-link
              :to="{ name: 'feeder_opml_import' }"
              class="underline_opml"
              href="#"
              ><span>Import OPML</span></router-link
            >
          </div>
        </div>
      </template>

      <div class="suggested-feeds-breadcrumbs">
        <span>
          <strong
            ><a
              href="javascript:;"
              class="suggested-feeds-breadcrumbs__link"
              @click="breadcrumbAction"
              >Discover</a
            >
            > <span class="text-capitalize">{{ getCategoryName }}</span></strong
          >
        </span>

        <div class="suggested-feeds-breadcrumbs__close_suggestion">
          <button
            class="suggested-feeds-breadcrumbs__close_suggestion__back_button btn d-block m-auto s"
            @click="breadcrumbAction"
          >
            <i class="cs-angle-left"></i>
            <span> Back </span>
          </button>
        </div>
      </div>
      <div v-if="getSubCategories.length > 0" class="feeds-related-categories">
        <div class="d-flex align-items-start">
          <p class="feeds-related-categories__left_text">
            Related Categories:
          </p>
          <ul class="feeds-related-categories__item">
            <li
              v-for="suggested_category in getSubCategories"
              @click="
                $router.push({
                  name: 'feeder_follow_actions',
                  query: { tag: suggested_category.label },
                })
              "
            >
              {{ suggested_category.name }}
            </li>
            <template v-if="suggested_feeds.categories.length > 10">
              <template v-if="!suggested_feeds.show_more">
                <li
                  class="feeds-related-categories__item__show_more"
                  @click="suggested_feeds.show_more = true"
                >
                  Show More
                </li>
              </template>
              <template v-else>
                <li
                  class="feeds-related-categories__item__show_more"
                  @click="suggested_feeds.show_more = false"
                >
                  Show Less
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>

      <template v-if="loader.suggested_feeds && suggested_feeds.page === 1">
        <div class="mt-4">
          <clip-loader :color="'#436aff'" :size="'16px'"></clip-loader>
        </div>
      </template>
      <template
        v-else-if="
          !loader.suggested_feeds && suggested_feeds.items.length === 0
        "
      >
        <div class="mt-4">
          <div slot="no-more" class="not_found_text">
            <div class="no_data_found_content">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No feeds with matching titles</h2>
                <p slot="text"
                  >There are no feeds available for your entered query, please
                  try using a different search term.</p
                >
              </content-available-state>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="clear suggested-feeds-box mt-4">
          <transition-group name="fade-list-item" tag="div">
            <div
              v-for="feed in suggested_feeds.items"
              :key="feed.uuid"
              class="suggested-feeds-box__item"
            >
              <div class="suggested-feeds-box__item__img">
                <img :src="backgroundLogoFaviconURL(feed.domain)" />
              </div>

              <div class="suggested-feeds-box__item__content">
                <p
                  class="suggested-feeds-box__item__content__title"
                  v-html="limitTextLength(feed.title, 60)"
                ></p>
                <p class="suggested-feeds-box__item__content__url">{{
                  feed.domain
                }}</p>
                <p class="suggested-feeds-box__item__content__url">{{
                  limitTextLength(feed.url, 80)
                }}</p>
              </div>
              <div
                class="ember-view suggested-feeds-box__item__follow_dropdown prevent_close_dropdown default_style_dropdown follow-feeds-dropdown dropdown"
              >
                <div data-toggle="dropdown">
                  <template v-if="followed_feeds.indexOf(feed.uuid) >= 0">
                    <button
                      class="suggested-feeds-box__item__follow_dropdown__button btn btn green_bg btn_loader loader_right d-block m-auto btn-studio-theme-space--nowrap"
                    >
                      <i class="fas fa-check mr-1"></i>
                      <span> Following </span>
                    </button>
                  </template>
                  <template v-else>
                    <button
                      class="btn btn-studio-theme-space btn-studio-theme-space--nowrap"
                    >
                      <i class="fas fa-plus mr-1"></i>
                      <span> Follow </span>
                    </button>
                  </template>
                </div>
                <FeederAddToGroupsDropdown
                  :feed_id="feed.uuid"
                  :remove-from-followed-list="removeFromFollowedList"
                  :add-to-followed-list="addToFollowedList"
                ></FeederAddToGroupsDropdown>
              </div>
            </div>
          </transition-group>
        </div>
        <div
          v-if="!suggested_feeds.last_page"
          class="m-auto pt-4 pb-4"
        >
          <button
            class="black_color dark_gray btn btn btn_loader loader_right d-block m-auto s"
            @click="fetchSuggestedFeedsLoadMore(suggested_feeds.page + 1)"
          >
            <i class="far fa-chevron-circle-down pr-2"></i>
            <span> Load More </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import http from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";
import { feederFeedSuggestionURI } from '../../../config/api-utils'
import FeederAddToGroupsDropdown from '../components/FeederAddToGroupsDropdown'
import FeederSearchHistory from '../components/search-assistant/FeederSearchHistory'

export default {
  components: {
    FeederAddToGroupsDropdown,
    FeederSearchHistory
  },
  data () {
    return {
      suggested_feeds: {
        page: 1,
        last_page: false,
        items: [],
        categories: [],
        show_more: false
      },
      followed_feeds: [],
      loader: {
        suggested_feeds: false
      },
      search_history_terms: [],
      is_visible: false,
      search_query: ''
    }
  },
  created () {
    if (this.$route.query.tag) {
      this.fetchSuggestedFeedsByLabel(this.$route.query.tag, 1)
    }
    if (this.$route.query.q) {
      this.search_query = this.$route.query.q
      this.fetchSuggestedFeedsByQuery(this.$route.query.q, 1)
    }
  },
  mounted () {
    EventBus.$on('remove_from_followed_list', async (value) => {
      console.log('remove_from_followed_list', value)
      const feedIndex = this.followed_feeds.indexOf(value)
      this.followed_feeds.splice(feedIndex, 1)
    })
    EventBus.$on(
      'remove_group_feeds_from_followed_list',
      async (feedsList) => {
        console.log('remove_group_feeds_from_followed_list', feedsList)
        feedsList.forEach((item) => {
          const feedIndex = this.followed_feeds.indexOf(item)
          this.followed_feeds.splice(feedIndex, 1)
        })
      }
    )
  },
  beforeUnmount () {
    EventBus.$off('remove_from_followed_list')
    EventBus.$off('remove_group_feeds_from_followed_list')
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
    getSubCategories () {
      if (this.suggested_feeds.show_more) {
        if (
          this.suggested_feeds.categories &&
          this.suggested_feeds.categories.length > 0
        ) {
          return this.suggested_feeds.categories.filter(
            (item) => item.name !== this.getCategoryName
          )
        }
      } else {
        if (
          this.suggested_feeds.categories &&
          this.suggested_feeds.categories.length > 0
        ) {
          return this.suggested_feeds.categories
            .slice(0, 10)
            .filter((item) => item.name !== this.getCategoryName)
        }
      }
      return []
    },
    getCategoryName () {
      if (this.$route.query.tag) {
        const splitTag = this.$route.query.tag.split('/')
        return splitTag[splitTag.length - 1]
      }
      if (this.$route.query.q) {
        return this.$route.query.q
      }
    }
  },

  watch: {
    '$route.query.tag' (value) {
      if (value) {
        console.log('tag changed...')
        this.fetchSuggestedFeedsByLabel(value, 1)
      }
    },
    '$route.query.q' (value) {
      if (value) {
        console.log('tag changed...')
        this.search_query = value
        this.fetchSuggestedFeedsByQuery(value, 1)
      }
    }
  },
  methods: {
    breadcrumbAction () {
      this.suggested_feeds.items = []
      this.$router.go(-1)
    },
    fetchSuggestedFeedsLoadMore (page) {
      if (this.$route.query.tag) {
        this.fetchSuggestedFeedsByLabel(this.$route.query.tag, page)
      } else {
        this.fetchSuggestedFeedsByQuery(this.$route.query.q, page)
      }
    },
    async fetchSuggestedFeedsByLabel (label, page = 1) {
      if (page === 1) {
        this.suggested_feeds.categories = []
      }
      this.followed_feeds = []
      this.suggested_feeds.page = page
      this.loader.suggested_feeds = true
      await http
        .post(feederFeedSuggestionURI + 'label', {
          label,
          page: this.suggested_feeds.page,
          workspace_id: this.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            if (this.suggested_feeds.page > 1) {
              this.suggested_feeds.items = this.suggested_feeds.items.concat(
                res.data.data.suggested_feeds
              )
            } else {
              this.suggested_feeds.categories =
                res.data.data.suggested_categories
              this.suggested_feeds.items = res.data.data.suggested_feeds
            }
            this.followed_feeds = res.data.data.followed_feeds
            this.suggested_feeds.last_page = res.data.last_page
          }
          return res
        })
        .catch((err) => {
          return err
        })
      this.loader.suggested_feeds = false
    },
    async fetchSuggestedFeedsByQuery (query, page = 1) {
      this.suggested_feeds.categories = []
      this.followed_feeds = []
      this.suggested_feeds.page = page
      this.loader.suggested_feeds = true
      await http
        .post(feederFeedSuggestionURI + 'query', {
          q: query,
          page: this.suggested_feeds.page,
          workspace_id: this.getActiveWorkspace._id
        })
        .then((res) => {
          if (res.data.status) {
            this.suggested_feeds.categories = res.data.data.suggested_categories
            if (this.suggested_feeds.page > 1) {
              this.suggested_feeds.items = this.suggested_feeds.items.concat(
                res.data.data.suggested_feeds
              )
            } else {
              this.suggested_feeds.items = res.data.data.suggested_feeds
            }
            this.followed_feeds = res.data.data.followed_feeds
            this.suggested_feeds.last_page = res.data.last_page
          }
          return res
        })
        .catch((err) => {
          return err
        })
      this.loader.suggested_feeds = false
    },
    removeFromFollowedList (feedId) {
      console.log('[followedlist] remove', feedId)
      const followdFeedIndex = this.followed_feeds.indexOf(feedId)
      if (followdFeedIndex >= 0) {
        this.followed_feeds.splice(followdFeedIndex, 1)
      }
      // If someone follows or unfollow from the actions section.
      this.$store.dispatch('fetchFeederStatistics')
    },
    addToFollowedList (feedId) {
      console.log('[followedlist] add ', feedId)
      this.followed_feeds.push(feedId)
      this.$store.dispatch('fetchFeederStatistics')
    },
    closeSearchDropdownOnActions () {
      this.is_visible = false
    },
    searchFromActionsQuery () {
      this.$router.push({
        name: 'feeder_follow_actions',
        query: { q: this.search_query }
      })
    }
  }
}
</script>
