<template>
  <div>
    <!--.........if folder already exist...........-->
    <div v-if="getFolders.length > 0" class="dropdown-menu dropdown-menu-right">
      <li class="heading">Select Folder</li>
      <ul class="inner">
        <template v-for="(folder, index) in getFolders">
          <li class="list_item">
            <span
              @click.prevent="
                setFavorite(folder._id, post_id, folder.name, postIndex)
              "
              >{{ folder.name }}</span
            >
          </li>
        </template>
      </ul>
      <div class="dd_footer close_this text-center">
        <button
          v-if="addFolderStatus"
          class="btn gradient_btn"
          @click.prevent="addFolder"
        >
          <i class="far fa-plus mr-2"></i>
          <span>Create New Folder</span>
        </button>
        <div v-else class="create_input">
          <input
            v-model="folderName"
            type="text"
            placeholder="Enter Folder Name"
            @keyup.enter="saveFolder(folderName)"
          />
          <div class="input_icon">
            <img
              class="add_folder"
              src="../../assets/article/check_icon.svg"
              alt="Add Folder"
              @click.prevent="saveFolder(folderName)"
            />
            <img
              class="remove"
              src="../../assets/article/delete_icon.svg"
              alt="remove topic"
              @click="cancelFolder"
            />
          </div>
        </div>
      </div>
    </div>

    <!--.........if No  Folder exist...........-->
    <div v-else class="dropdown-menu dropdown-menu-right">
      <li class="heading new_folder">Create New Folder</li>
      <div class="dd_footer close_this">
        <div class="create_input">
          <input
            v-model="folderName"
            type="text"
            placeholder="Enter Folder Name"
            @keyup.enter="saveFolder(folderName)"
          />
          <div class="input_icon">
            <img
              class="add_folder"
              src="../../assets/article/check_icon.svg"
              alt="Add Folder"
              @click.prevent="saveFolder(folderName)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['post_id', 'postIndex'],
  data () {
    return {
      addFolderStatus: true,
      folderName: null
    }
  },
  computed: {
    ...mapGetters(['getFolderText', 'getFolders'])
  },
  methods: {
    // ...mapActions([''])
    addFolder () {
      this.addFolderStatus = false
    },
    cancelFolder () {
      this.addFolderStatus = true
    }
  }
}
</script>
