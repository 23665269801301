<template>
  <div>
    <VideoPreview></VideoPreview>
    <div class="automation_page d-flex align-items-start">
      <div
        class="page_left"
        :class="{
          open:
            getVideoAutomationDetails.video.keyword.length == 0 ||
            !showVideoPreview,
        }"
      >
        <div class="page_inner basic_form">
          <div class="step_heading">
            <h3>Step 2 - Content Matching Rules & Filters</h3>
          </div>
          <div class="input_field">
            <label>Channel type</label>

            <div class="radio_list">
              <div class="radio_input_image">
                <input
                  id="automation_youtube"
                  v-model="getVideoAutomationDetails.video.source"
                  type="radio"
                  name="automation_type"
                  value="Youtube"
                />
                <label for="automation_youtube" class="radio_left"
                  >YouTube</label
                >
              </div>
              <div class="radio_input_image">
                <input
                  id="custom"
                  v-model="getVideoAutomationDetails.video.source"
                  type="radio"
                  name="automation_type"
                  value="Dailymotion"
                />
                <label for="custom" class="radio_left">Dailymotion</label>
              </div>
            </div>
          </div>

          <div class="input_field">
            <div class="d-flex align-items-center">
              <p class="text w-25">Sorting result</p>

              <select
                v-if="getVideoAutomationDetails.video.source === 'Youtube'"
                v-model="getVideoAutomationDetails.video.youtube.sort"
                class="with_bg"
              >
                <option value="Date">Date</option>
                <option value="Rating">Rating</option>
                <option value="Relevance">Relevance</option>
                <option value="Title">Title</option>
                <option value="VideoCount">Video Count</option>
                <option value="ViewCount">View Count</option>
              </select>

              <select
                v-if="getVideoAutomationDetails.video.source === 'Dailymotion'"
                v-model="getVideoAutomationDetails.video.dailymotion.sort"
                class="with_bg"
              >
                <option value="recent">Recent</option>
                <option value="visited">Visited</option>
                <option value="visited-hour">Visited-hour</option>
                <option value="visited-today">Visited-today</option>
                <option value="visited-week">Visited-week</option>
                <option value="visited-month">Visited-month</option>
                <option value="commented">Commented</option>
                <option value="commented-hour">Commented-hour</option>
                <option value="commented-today">Commented-today</option>
                <option value="commented-week">Commented-week</option>
                <option value="commented-month">Commented-month</option>
                <option value="random">Random</option>
                <option value="trending">Trending</option>
                <option value="old">Old</option>
              </select>
            </div>
          </div>
          <div class="input_field multi_select">
            <div class="d-flex">
              <label
                >Add Keyword
                <template
                  v-if="
                    getVideoAutomationDetails.video.source === 'Dailymotion'
                  "
                >
                  <i
                    v-tooltip="'Only single keyword is allowed for dailymotion'"
                    class="cs-question-circle dailymotion_tooltip"
                  ></i>
                </template>
              </label>
              <button
                v-if="
                  (getVideoAutomationDetails.video.source !==
                    'Dailymotion' &&
                    getVideoAutomationDetails.video.keyword.length > 0) ||
                  (videoKeyword !== null &&
                    videoKeyword.replace(/^\s+/, '') !== '')
                "
                class="reset_btn"
                @click="clearAllKeywords"
                ><i class="fa fa-times"></i> Clear</button
              >
            </div>

            <div
              v-if="
                getVideoAutomationDetails.video.source === 'Youtube' ||
                getVideoAutomationDetails.video.keyword.length == 0
              "
              class="multi_input"
            >
              <input
                v-model="videoKeyword"
                class="prevent-listener"
                type="text"
                placeholder="Enter your main keywords or phrases, e.g. Social Media, Big Data…"
                data-cy="main-keyword"
                @keyup.enter="
                  addVideoKeyword(
                    getVideoAutomationDetails.video.keyword,
                    videoKeyword,
                  )
                "
                @keyup.188="
                  addVideoKeyword(
                    getVideoAutomationDetails.video.keyword,
                    videoKeyword,
                  )
                "
              />
              <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
            </div>
            <template v-if="getVideoAutomationDetails.video.keyword">
              <div class="multi_tags">
                <ul>
                  <li
                    v-for="(keyword, index) in getVideoAutomationDetails.video
                      .keyword"
                    :key="index"
                    class="green"
                  >
                    <span class="value">{{ keyword }}</span>
                    <span
                      class="remove"
                      @click.prevent="
                        removeVideoKeyword(
                          getVideoAutomationDetails.video.keyword,
                          index,
                        )
                      "
                      >&times;</span
                    >
                  </li>
                </ul>
              </div>
            </template>

            <!--<input type="text" placeholder="Enter keyword..." v-model="getVideoAutomationDetails.video.keyword">-->
          </div>

          <div
            v-if="
              getVideoAutomationDetails.video.source === 'Youtube' &&
              $parent.youtubeKey === ''
            "
            class="error_box text-center"
            style="margin-top: 19px"
            ><p style="font-size: 0.9rem">
              Please add your YouTube Data API key in order to use YouTube for
              content planning.

              <router-link
                :to="{
                  name: 'integrations',
                  params: { workspace: getWorkspaces.activeWorkspace.slug },
                }"
              >
                Click here to add the key.
              </router-link>
            </p>
          </div>
          <div class="field_group text-center">
            <button
              class="btn btn-studio-theme-transparent-grey mr-1"
              @click="changeActiveVideoAutomationTab(1)"
            >
              <i class="icon_left cs-angle-left"></i>
              <span>Prev</span>
            </button>
            <button
              :disabled="
                getVideoAutomationDetails.video.source === 'Youtube' &&
                $parent.youtubeKey === ''
              "
              class="btn btn-studio-theme-space"
              @click="$parent.validateYoutubeToken(2)"
            >
              <span>Next</span>
              <i class="icon_right arrow_right_active cs-angle-right"> </i>
            </button>
          </div>
        </div>
      </div>

      <!--// preview-->

      <div
        v-if="
          getVideoAutomationDetails.video.source === 'Dailymotion' ||
          (getVideoAutomationDetails.video.source === 'Youtube' &&
            $parent.youtubeKey !== '')
        "
        class="page_right"
        style="max-height: 600px; min-height: 600px"
        :class="{ open: !getVideoAutomationDetails.video.keyword.length == 0 }"
      >
        <div class="page_inner basic_form">
          <VideoPreviewSuggestion></VideoPreviewSuggestion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VideoPreview from '../../../../discovery/components/common/preview/VideoPreview'
import { video } from '../../../store/recipes/mutation-types'
import VideoPreviewSuggestion from './VideoPreviewSuggestion'

export default {
  components: {
    VideoPreviewSuggestion,
    VideoPreview
  },
  props: ['type'],

  data () {
    return {
      c_gap: false,
      schedule_time: 'recurring',
      schedule_type: 'campaign_continuously',
      videoKeyword: null
    }
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getVideoAutomationDetails', 'getWorkspaces']),
    showVideoPreview () {
      return (
        this.getVideoAutomationDetails.video.source === 'Dailymotion' ||
        (this.getVideoAutomationDetails.video.source === 'Youtube' &&
          this.$parent.youtubeKey !== '')
      )
    }
  },
  methods: {
    addVideoKeyword (arrayList, keyword) {
      console.debug('Method::addVideoKeyword', arrayList, keyword)
      if (keyword && keyword.trim()) {
        if (arrayList.constructor !== Array) {
          arrayList = arrayList ? [arrayList] : []
        }
        if (keyword.toString().includes(',')) {
          keyword = keyword.toString().replace(',', '')
        }
        keyword = keyword.trim(' ')
        if (!arrayList.includes(keyword)) {
          arrayList.push(keyword)
          this.$store.commit(video.SET_AUTOMATION_KEYWORD, arrayList)
          this.previewVideoSuggestion(false, null, this.$parent.youtubeKey)
        }
      }
      this.videoKeyword = null
    },
    removeVideoKeyword (arrayList, index) {
      arrayList.splice(index, 1)
      if (arrayList && arrayList.length > 0) {
        this.previewVideoSuggestion(false, null, this.$parent.youtubeKey)
      }
    },
    clearAllKeywords () {
      this.videoKeyword = ''
      this.getVideoAutomationDetails.video.keyword = []
    }
  },

  watch: {
    'getVideoAutomationDetails.video.source' (type) {
      if (
        this.getVideoAutomationDetails.video.keyword &&
        this.getVideoAutomationDetails.video.keyword.length > 0
      ) {
        if (
          type === 'Dailymotion' &&
          this.getVideoAutomationDetails.video.keyword.length > 1
        ) {
          // let dailymotion_keyword = this.getVideoAutomationDetails.video.keyword[0]
          // this.getVideoAutomationDetails.video.keyword = []
          this.getVideoAutomationDetails.video.keyword = [
            this.getVideoAutomationDetails.video.keyword[0]
          ]
        }
        this.previewVideoSuggestion(false, null, this.$parent.youtubeKey)
      }
    },
    'getVideoAutomationDetails.video.youtube.sort' () {
      if (
        this.getVideoAutomationDetails.video.keyword &&
        this.getVideoAutomationDetails.video.keyword.length > 0
      ) {
        this.previewVideoSuggestion(false, null, this.$parent.youtubeKey)
      }
    },
    'getVideoAutomationDetails.video.dailymotion.sort' () {
      if (
        this.getVideoAutomationDetails.video.keyword &&
        this.getVideoAutomationDetails.video.keyword.length > 0
      ) {
        this.previewVideoSuggestion(false, null, this.$parent.youtubeKey)
      }
    }
  }
}
</script>

<style>
.dailymotion_tooltip {
  color: #989fa5;
  font-size: 15px;
  padding-left: 5px;
}
</style>
