<script setup>
import { inject, ref, defineProps, onUnmounted } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import VueEasyLightbox from 'vue-easy-lightbox'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import { getPlanMedia } from '@/src/modules/common/lib/planner'
const root = inject('root')
const { $bvModal } = root
const { isVideo, isRemoteMedia, selectedPost } = useInstagramGridView()
const props = defineProps({
  details: {
    type: Object,
    default: () => ({
      show: false,
      color: 'bg-[#ff000026]',
      message: 'Failed',
    }),
  },
  isTiktok: {
    type: Boolean,
    default: false,
  },
  item: {
    type: Object,
    required: true,
  },
  itemType: {
    type: String,
    default: 'feed',
  },
})
const visible = ref(false)
const imageLink = ref('')

// Methods

const openImageLightBox = (media) => {
  if (media) {
    imageLink.value = media
    visible.value = true
  }
}

const closeImageLightBox = () => {
  visible.value = false
}

const handlePreviewFeed = (item) => {
  if(!isRemoteMedia(item) && item?.instagram_post_type === 'carousel') {
    console.log('In multimedia::')
    const detail = item?.common_box_status ? item?.common_sharing_details : item?.instagram_sharing_details ? item?.instagram_sharing_details : {}
    EventBus.$emit('displayFile', {
      type: 'multimedia',
      media: detail?.multimedia || [],
      index: 0,
    })
    $bvModal.show('display-file-modal')
    return
  }

  if (!isVideo(item, props.isTiktok)) {
    return !isRemoteMedia(item)
      ? openImageLightBox(
          (item?.common_box_status
            ? item?.common_sharing_details
            : props.isTiktok
            ? item.tiktok_sharing_details
            : item.instagram_sharing_details
          )?.image
        )
      : null
  }

  const attachment = {
    type: 'video',
    media: item?.common_box_status
      ? item.common_sharing_details?.video?.converted_video ||
        item.common_sharing_details?.video?.link
      : props.isTiktok
      ? item?.tiktok_sharing_details?.video?.converted_video ||
        item?.tiktok_sharing_details?.video?.link
      : item?.instagram_sharing_details?.video?.converted_video ||
        item?.instagram_sharing_details?.video?.link,
  }

  if (attachment) {
    EventBus.$emit('displayFile', {
      type: attachment.type,
      media: attachment.media,
      index: 0,
    })
    $bvModal.show('display-file-modal')
  }
}
const handlePreviewReel = (item) => {
  const attachment = getPlanMedia(item)

  if (attachment) {
    EventBus.$emit('displayFile', {
      type: attachment.type,
      media: attachment.media,
      index: 0,
    })
    $bvModal.show('display-file-modal')
  }
}
const handleClick = () => {
  if (props.itemType === 'reel') {
    handlePreviewReel(props.item)
  } else if (props.itemType === 'feed') {
    handlePreviewFeed(props.item)
  } else if (props.itemType === 'story') {
    selectedPost.value = props.item
  }
}

onUnmounted(() => {
  EventBus.$off('openImageLightBox')
})
</script>

<template>
  <div class="relative h-full w-full" @click="handleClick">
    <slot />
  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="imageLink"
    @hide="closeImageLightBox"
  >
  </VueEasyLightbox>
</template>
