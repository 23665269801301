<script setup>
import { watch, computed, onBeforeMount } from 'vue'

import debounce from 'lodash.debounce'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import AudienceLocationChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/AudienceLocationChart.vue'
import DemographicsByGender from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByGender.vue'
import DemographicsByAge from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByAge.vue'

const {
  routes,
  dateRange,
  selectedAccount,
  overviewDemographicsData,
  fetchMedia,
  isLoadingStates,
} = useFacebookAnalytics()

const GENDER_MAP = {
  M: 'Males',
  F: 'Females',
  U: 'Unspecified',
}

onBeforeMount(() => {
  isLoadingStates.value[routes.OVERVIEW_AUDIENCE_LOCATION] = true
  isLoadingStates.value[routes.OVERVIEW_DEMOGRAPHICS] = true
})

watch(
  () => [dateRange, selectedAccount],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.OVERVIEW_AUDIENCE_LOCATION)
    fetchMedia(routes.OVERVIEW_DEMOGRAPHICS)
  }, 500),
  { deep: true, immediate: true }
)

const leadingGender = computed(() => {
  return (
    GENDER_MAP[overviewDemographicsData.value?.max_gender_age?.gender] ||
    'Unknown'
  )
})
</script>

<template>
  <div class="flex flex-col gap-y-[1.75rem]">
    <div class="w-full text-white p-3 bg-[black] mb-2 rounded-md"
      >Meta has deprecated
      <span class="font-bold">Facebook Audience by Age and Gender</span> data.
      Insights prior to <span class="font-bold">March 14, 2024</span>, are
      available if your account was connected before that date.
    </div>
    <!-- Demographics Chart -->
    <!-- fans value is always 0 for accounts connected after 14 march 2024 so hiding for accounts connected after that -->
    <div
      v-if="overviewDemographicsData?.fans > 100"
      class="grid grid-cols-1 xl:grid-cols-2 gap-4"
    >
      <DemographicsByAge />
      <DemographicsByGender />

      <div
        class="bg-white p-5 rounded-md w-full relative flex justify-center color-border cst-editor xl:col-span-2"
      >
        <p>
          <span class="font-semibold"> {{ leadingGender }}</span> between the
          ages of
          <span class="font-semibold">
            {{ overviewDemographicsData?.max_gender_age?.age }}</span
          >
          appear to be the leading force among your followers.
        </p>
      </div>
    </div>
    <div class="grid grid-cols-1 2xl:grid-cols-2 gap-7">
      <AudienceLocationChart type="country" />
      <AudienceLocationChart type="city" />
    </div>
  </div>
</template>

<style scoped></style>
