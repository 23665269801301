<template>
  <div class="input_field">
    <div class="multi_select">
      <div class="d-flex">
        <label for="" class="sub_label"
          >Each result must <span class="bold">NOT</span> contain any of these
          keywords</label
        >
        <button
          v-if="
            getTopicSelection.must_not_contains_keywords.length > 0 ||
            (mustNotContainsKeywordValue !== null &&
              mustNotContainsKeywordValue.replace(/^\s+/, '') !== '')
          "
          class="reset_btn"
          @click="clearAllKeywords"
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <div class="multi_input">
        <input
          v-model="mustNotContainsKeywordValue"
          class="prevent-listener"
          type="text"
          placeholder="Enter keywords that you think are giving irrelevant results, e.g. job, course…"
          data-cy="exclude-keyword"
          @keyup.enter="
            addAnyKeyword(
              getTopicSelection.must_not_contains_keywords,
              mustNotContainsKeywordValue,
              'must_not_contains_keyword'
            )
          "
          @keyup.188="
            addAnyKeyword(
              getTopicSelection.must_not_contains_keywords,
              mustNotContainsKeywordValue,
              'must_not_contains_keyword'
            )
          "
        />
        <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
      </div>
      <template v-if="getTopicSelection.must_not_contains_keywords">
        <div class="multi_tags">
          <ul>
            <li
              v-for="(
                must_not_contains_keyword, index
              ) in getTopicSelection.must_not_contains_keywords"
              class="red"
            >
              <span class="value">{{ must_not_contains_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(
                    getTopicSelection.must_not_contains_keywords,
                    index
                  )
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
      <!--<div class="multi_tags">
                <ul>

                    <li class="red">
                        <span class="value">cnn.com</span>
                        <span class="remove">&times;</span>
                    </li>
                </ul>
            </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      mustNotContainsKeywordValue: null,
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection']),
  },
  methods: {
    clearAllKeywords() {
      this.mustNotContainsKeywordValue = ''
      this.getTopicSelection.must_not_contains_keywords = []
    },
  },
}
</script>
