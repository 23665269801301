<script setup>
import { computed, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'

// state
const {
  postsAndEngagements,
  videosAndEngagementCharts,
  dataZoomOptions,
  videosAndEngagementChartOptions: chartOptions,
  isReportView,
  analyticsDesignSystem,
  isNoAnalyticsData,
} = useTiktokAnalytics()

const isNoData = computed(() => {
  return isNoAnalyticsData(postsAndEngagements)
})

watch(
  () => postsAndEngagements,
  () => {
    chartOptions.value.series = []

    const options = postsAndEngagements.value[0] || {}
    videosAndEngagementCharts.value?.forEach((chart, i) => {
      const series = {
        name: chart.name,
        type: 'bar',
        data: options[chart && chart?.api_response_key] || [],
        color: chart.color,
        colorBy: 'series',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
        yAxisIndex: i,
        barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
      }

      chartOptions.value.series?.push(series)
    })

    chartOptions.value.xAxis.data = options?.days_bucket
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="specific-type-chart" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Engagement vs Daily Posting Pattern
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Assess the relationship between your daily tweet posting
                  frequency and subsequent engagement metrics, over the selected
                  time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
