<script setup>
import CstButton from '@ui/Button/CstButton'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  sub_title: {
    type: String,
    default: ''
  }
})


</script>

<template>
  <div class="h-full flex flex-col pl-5">
    <!-- Body -->
    <div class="mt-[30%]">
      <p v-if="title" class="text-xl 2xl:text-2xl text-[#3C4549] font-bold mb-8" v-html="title"></p>
      <p v-if="sub_title" class="mb-4 text-2xl 2xl:text-3xl text-[#3C4549] font-bold leading-[2rem] 2xl:leading-[2.8rem]" v-html="sub_title"></p>
      <p class="text-gray-900 2xl:max-w-[20rem] font-normal">{{ description }}</p>
    </div>
  </div>
</template>

<style lang="scss">
.buttons {
  button {
    width: fit-content;
  }
}
.gradient-text {
  background-image: linear-gradient(89.24deg,
      #137EFF 0%,
      #137EFF 11.82%,
      #1DA1F2 63.31%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #3C4549; /* Fallback color */
}
</style>
