<template>
  <div class="topics-filters-pane__container__right_section">
    <div class="view_change_btn d-flex align-items-center mb-3">
      <div
        class="topic_date_picker d-inline-block align-middle topics-filters-pane__container__right_section__datepicker flex-grow-1"
      >
        <span class="d-inline-block mr-2 topics-datepicker">
          <DateRangePicker
            class="datepicker_input"
            :opens="date_range_pickers.normal.opens"
            :start-date="getFilterFromDate"
            :end-date="getFilterToDate"
            :max-date="getMaxDate"
            :min-date="date_range_pickers.normal.minDate"
            :locale-data="date_range_pickers.normal.locale"
            :single-date-picker="date_range_pickers.normal.singleDatePicker"
            :time-picker="date_range_pickers.normal.timePicker"
            :time-picker24hour="date_range_pickers.normal.timePicker24Hour"
            :show-week-numbers="date_range_pickers.normal.showWeekNumbers"
            :show-dropdowns="date_range_pickers.normal.showDropdowns"
            :auto-apply="date_range_pickers.normal.autoApply"
            :ranges="date_range_pickers.normal.ranges"
            @update="redirectDateView"
          >
            <div
              slot="input"
              slot-scope="picker"
              class="datepicker_content discovery_white ml-2"
            >
              <i class="icon-Calendar"></i>
              <span
                v-if="getSearchDatePicker(picker.startDate, picker.endDate)"
              >
                {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
              </span>
              <span v-else-if="picker.label">
                {{ picker.label }}
              </span>
              <span v-else> Last 30 days </span>
            </div>
          </DateRangePicker>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import useDateFormat from "@common/composables/useDateFormat";
export default {
  components: {
    DateRangePicker
  },
  props: {
    date: {}
  },
  setup(){
    const { momentWrapper, getAccountDateTimeFormat} = useDateFormat()
    return {
      momentWrapper,
      getAccountDateTimeFormat
    }
  },
  data () {
    return {
      date_range_pickers: {
        normal: {
          locale: {
            format: this.getAccountDateTimeFormat // fomart of the dates displayed
          },

          opens: 'left',
          startDate: this.momentWrapper('\'2017-09-19\'').formatDate(),
          endDate: this.momentWrapper('2017-10-09').formatDate(),
          minDate: this.momentWrapper('2023-01-01').formatDate(),
          maxDate: this.getMaxDate,
          dateRange: {
            endDate: this.momentWrapper()
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          checkOpen: false,
          ranges: {
            // 'All Time': [this.momentWrapper().subtract(5, 'years'), this.momentWrapper()],
            '24 hours': [this.momentWrapper().subtract(24, 'hours'), this.momentWrapper()],
            '48 hours': [this.momentWrapper().subtract(48, 'hours'), this.momentWrapper()],
            'Last 3 days': [this.momentWrapper().subtract(3, 'day'), this.momentWrapper()],
            'Last 7 Days': [this.momentWrapper().subtract(7, 'days'), this.momentWrapper()],
            'Last 30 Days': [this.momentWrapper().subtract(30, 'days'), this.momentWrapper()],
            'Last 3 Months': [this.momentWrapper().subtract(90, 'days'), this.momentWrapper()],
            'Last 6 Months': [this.momentWrapper().subtract(180, 'days'), this.momentWrapper()],
            // 'Last 1 Year': [this.momentWrapper().subtract(360, 'days'), this.momentWrapper()],
            // 'Last 2 Years': [this.momentWrapper().subtract(720, 'days'), this.momentWrapper()]
          }
        }
      }
    }
  },
  computed: {
    getMaxDate () {
      return this.momentWrapper().formatDate()
    },
    getFilterFromDate () {
      const fromDate = this.date
        ? this.momentWrapper(this.date.split(' - ')[0]).formatDate()
        : this.momentWrapper().subtract(90, 'days').formatDate()
      return this.$route.query.from_date || fromDate
    },
    getFilterToDate () {
      const toDate = this.date
        ? this.momentWrapper(this.date.split(' - ')[1]).formatDate()
        : this.momentWrapper().formatDate()
      return this.$route.query.to_date || toDate
    }
  },
  methods: {
    redirectDateView (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = this.momentWrapper(values.startDate)
        const endDate = this.momentWrapper(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.formatDate(),
            to_date: endDate.formatDate()
          }
        })
      }
    }
  }
}
</script>
