<template>
  <div class="feeds-manage-sources">
    <div class="feeds-manage-sources__left_section">
      <h3 class="feeds-manage-sources__left_section__heading">OPML Import</h3>
      <p class="feeds-manage-sources__left_section__heading_text">
        Batch import sources from an OPML file
      </p>
    </div>
    <div class="feeds-manage-sources__right_section">
      <div class="buttons-content">
        <router-link
          :to="{ name: 'feeder_opml_import_history' }"
          class="mt-4 mb-4 btn btn dark_gray black_color btn_loader loader_right d-block m-auto ml-4"
          ><span>View Import History</span></router-link
        >
      </div>
    </div>
    <div
      class="feeds-manage-sources__opml_import"
      @dragleave.self="onDragLeaveFeeder($event)"
      @dragover="onDragOverFeeder($event)"
      @drop="onDropFeeder($event)"
    >
      <div>
        <label
          for="opml-file-picker-input"
          class="feeds-manage-sources__opml_import__file_picker"
        >
          <span class="text-center">
            <span class="btn btn-studio-theme-space">
              <span>
                <template v-if="file.name"> Change OPML File </template>
                <template v-else> Choose OPML File </template>
              </span>
            </span>
            <br />
            <span class="hint"
              ><template v-if="file.name">{{ file.name }} uploaded.</template
              ><template v-else>or drag an OPML file here</template></span
            >
          </span>
          <input
            id="opml-file-picker-input"
            type="file"
            :accept="'application/xml, text/xml, text/x-opml, .opml'"
            @change.prevent="inputOPML($event)"
          />
        </label>
      </div>
    </div>
    <div class="folder_section feeds-manage-sources__folder_section">
      <div class="left_input">
        <p class="label"
          >Default Folder
          <i
            v-tooltip="'Your feeds will be added into this folder.'"
            class="far fa-question-circle cursor_pointer"
            aria-hidden="true"
          ></i
        ></p>
      </div>
      <div class="mt-3 d-flex align-items-start basic_form">
        <template v-if="getFeederGroupList.length > 0">
          <div class="left_input">
            <div
              class="field_group mt-0 mb-0 inline-block align-middle"
              style="min-width: 100px"
            >
              <select v-model="group_id">
                <option value="" selected>None</option>
                <template v-for="(group_item, index) in sortedByNameGroupList" :key="index">
                  <option :value="group_item.uuid">{{
                    limitTextLength(group_item.name, 22)
                  }}</option>
                </template>
              </select>
              <i class="icon-dropdown-cs"></i>
            </div>

            <div v-if="group_id" class="ml-3 inline-block checkbox_input_image">
              <input
                id="override_opml_folder"
                v-model="override_opml_folder"
                type="checkbox"
                :value="false"
              />
              <label for="override_opml_folder" class="checkbox_left">
                Override OPML folder structure
              </label>
            </div>

            <!-- if none is selected -->
            <template v-if="group_id === ''">
              <div class="ml-3 inline-block checkbox_input_image">
                <p class="checkbox_left">
                  <a href="javascript:;" @click.prevent="createGroupFromOPML"
                    >Create a new group.</a
                  ></p
                >
              </div>
            </template>
          </div>
          <div class="right_input ml-auto">
            <button
              class="btn green_bg btn_loader loader_right"
              :disabled="!file.name || loaders.store"
              @click="uploadOPML"
            >
              <span> Import Sources </span>
              <clip-loader v-if="loaders.store" :size="'14px'" :color="'#fff'">
              </clip-loader>
            </button>
            <!-- <div class="input_field">
                             <select class="ml-2 inline w-auto" v-model="">
                                 <option value="">New Folder</option>
                                 <option value="Hour">Hour(s)</option>
                                 <option value="Day">Day(s)</option>
                                 <option value="Week">Week(s)</option>
                             </select>
                         </div>-->

            <!-- <div class="mt-2 mb-2">
                             <button class="btn blue_gradient">
                                 <span>
                                     Create Group
                                 </span>
                             </button>
                         </div>-->
          </div>
        </template>
        <template v-else>
          <p
            >You do not have any group created yet.
            <a href="javascript:;" @click.prevent="createGroupFromOPML"
              >Create a group.</a
            ></p
          >
        </template>
      </div>
      <!--<div class="action_button m-auto">-->

      <!--</div>-->
    </div>
  </div>
</template>

<script>
import { FILE_BIG, NO_FILE_SELECTED } from '@common/constants/messages'
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'

export default {
  data () {
    return {
      file: {
        source: '',
        name: '',
        type: ''
      },
      group_id: '',
      override_opml_folder: false,
      loaders: {
        store: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getFeederGroupStore',
      'getFeederGroupValidations',
      'getFeederGroupList'
    ]),
    sortedByNameGroupList () {
      return sortBy(this.getFeederGroupList, ['name'], ['asc'])
    }
  },
  methods: {
    // Drag and Drop for OPML files
    onDragLeaveFeeder (e) {
      e.stopPropagation()
      e.preventDefault()
      console.debug(e, 'Method:onDragLeaveFeeder')
    },
    onDragOverFeeder (e) {
      e.stopPropagation()
      e.preventDefault()
      console.debug(e, 'Method:onDragOverFeeder')
    },
    onDropFeeder (e) {
      e.stopPropagation()
      e.preventDefault()
      console.debug(e, 'Method:onDropFeeder')
      this.inputOPML(e)
    },

    // file upload work through opml

    inputOPML (event) {
      console.debug('Method:uploadSourcesFile')
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        files = files[0]
        console.log('OPML source file', files.type)

        if (files.size > 655360) {
          this.alertMessage(FILE_BIG, 'error')
        } else {
          if (
            files.type === 'text/x-opml+xml' ||
            files.type !== 'application/xml' ||
            files.type !== 'text/xml' ||
            files.type !== 'text/x-opml'
          ) {
            this.file.type = 'opml'
          }
          this.file.source = files
          this.file.name = files.name
        }
        event.target.value = ''
      } else {
        this.alertMessage(NO_FILE_SELECTED, 'error')
      }
    },
    async uploadOPML () {
      const formData = new FormData()
      formData.append('file', this.file.source)
      formData.append('filename', this.file.name)
      formData.append('type', this.file.type)
      formData.append('workspace_id', this.getActiveWorkspace._id)
      formData.append('group_id', this.group_id)
      formData.append('override_opml_folder', this.override_opml_folder)
      this.loaders.store = true
      const res = await this.$store.dispatch('feederOPML', formData)
      this.loaders.store = false
      if (res && res.data) {
        if (res.data.status) {
          this.$store.dispatch('toastNotification', {
            message: res.data.message
          })
          this.file = {
            source: '',
            name: '',
            type: ''
          }
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    },
    createGroupFromOPML () {
      this.$set(this.getFeederGroupStore, 'name', null)
      this.$set(this.getFeederGroupStore, '_id', null)
      this.$set(this.getFeederGroupValidations.fields, 'name', false)
      this.$bvModal.show('add_feeder_group')
    }
  }
}
</script>
