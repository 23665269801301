<script>
import { mapGetters } from 'vuex'
import { getPlatformName } from '@common/lib/integrations'
import { getStatusClass, platformPostingStats } from '@common//lib/planner'

import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import { pusherSocketPublish } from '@common/lib/pusher'
import PostingItem from './PostingItem'

const importedMethods = {
  getPlatformName,
  getStatusClass,
  platformPostingStats,
}
export default {
  components: { PostingItem },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      members: this.getActiveWorkspaceMembersDetails,
      integrations: blogIntegrationsNames,
      pusherChannel: null,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getBlogIntegrationsNames',
      'getSocialIntegrationsNames',
      'getActiveWorkspaceMembersDetails',
    ]),
    isItemActive() {
      return this.item.is_active
    },
    isPublishedPost() {
      return getStatusClass(this.item) === 'published'
    },
    hasBlogSelection() {
      return this.item.blog_selection
    },
    /**
     * Pusher channel name for the plan available for retry
     * @returns {string}
     */
    pusherChannelName() {
      return `plan_${this.item._id}_${this.getActiveWorkspace._id}`
    },
  },
  created() {
    this.subscribeAndBindPusher()
  },
  beforeUnmount() {
    pusherSocketPublish.unsubscribe(this.pusherChannelName)
  },
  methods: {
    ...importedMethods,
    hasSpecificBlogSelection(type) {
      return (
        this.item.blog_selection[type] && this.item.blog_selection[type].website
      )
    },
    hasSpecificSocialSelection(type) {
      return (
        this.item.account_selection[type] &&
        this.item.account_selection[type].length
      )
    },
    /**
     * Subscribing pusher channel and binding events for retrying post.
     */
    subscribeAndBindPusher() {
      // subscribe pusher channel
      this.pusherChannel = pusherSocketPublish.subscribe(this.pusherChannelName)

      // bind pusher plan status
      this.pusherChannel.bind(
        'posting_status',
        ({ status, errorMessage, posting }) => {
          const postingIndex = this.item.posting.findIndex(
            (item) => item._id === posting?._id
          )
          if (postingIndex < 0) return
          switch (status) {
            case 'in_progress':
              this.item.posting[postingIndex].retrying = true
              break
            case 'complete':
              this.$set(this.item.posting, postingIndex, posting)
              break
            case 'failed':
              this.item.posting[postingIndex].retrying = false
              this.alertMessage(errorMessage, 'error')
              break
          }
        }
      )
    },
  },
}
</script>

<template>
  <div v-if="isItemActive" class="content_bottom d-flex align-items-start">
    <div class="planner_table_block planner_width">
      <!--<h3>Table value</h3>-->
      <table class="table" style="table-layout: fixed">
        <thead>
          <tr>
            <th style="width: 250px">Account</th>
            <th style="width: 200px">Status</th>
            <template v-if="isPublishedPost">
              <th style="width: 100px; text-align: center">Statistics</th>
            </template>
          </tr>
        </thead>

        <tbody>
          <template v-if="hasBlogSelection">
            <template v-for="(integration, index) in integrations" :key="`integration_${index}`">
              <template v-if="hasSpecificBlogSelection(integration)">
                <PostingItem
                  :item="item"
                  :type="integration"
                 account=""></PostingItem>
              </template>
            </template>
          </template>

          <template v-if="!hasBlogSelection">
            <template v-for="(type, index) in getSocialIntegrationsNames" :key="`name_${index}`">
              <template v-if="hasSpecificSocialSelection(type)">
                <template
                  v-for="account in item.account_selection[type]"
                >
                  <PostingItem
                    :item="item"
                    :type="type"
                    :account="getFreshAccount(type, account)"
                  ></PostingItem>
                </template>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="less" scoped>
.planner_component,
.planner_inner,
.planner_list,
.list_inner,
.l_body,
.list_items,
.item_box,
.item_box_inner,
.item_content,
.comment_block_planner,
.edit_input,
.profile_picture,
.text_block,
.btn_block,
.btn[disabled='disabled'] {
  span {
    color: #fff !important;
  }
}
.planner_width {
  width: 100%;
}
</style>
