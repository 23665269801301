<template>
  <div class="extension-widget">
    <div class="extension-widget__inner">
      <div class="extension-widget__close">
        <i class="far fa-times"></i>
      </div>
      <div class="extension-widget__left">
        <h2>Install our Google Extension.</h2>
        <p
          >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna</p
        >
        <a href="#"
          >Install ContentStudio’s Extenion Now
          <i class="fas fa-arrow-right"></i
        ></a>
      </div>
      <div class="extension-widget__right">
        <img draggable="false" src="../../../assets/Devices-bro.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtensionWidget'
}
</script>
