<template>
  <tbody>
    <tr
      v-for="(competitor, index) in tableData"
      :key="`posting_activity_data_${index}`"
      class="hover:bg-gray-50"
      :class="{
        '!mt-2': index === 0,
        '!border-l-0 !border-t-0 !border-r-0 border !border-gray-200':
          index !== tableData.length - 1,
      }"
    >
      <td class="py-2.5 pl-4 flex items-center my-2">
        <div class="flex items-center">
          <img
            :src="competitor.image"
            class="
              h-8
              w-8
              mr-3
              rounded-full
              overflow-hidden
              object-cover
              border
            "
            alt=""
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            "
          />
          <span
            v-tooltip="{
              content: `${
                competitor.name
                  ? 'Name: ' + competitor.name
                  : 'Slug: ' + competitor.slug
              }`,
              theme: 'light',
            }"
            class="truncate w-44 font-0-95rem"
            >{{ competitor.name ? competitor.name : competitor.slug }}</span
          >
        </div>
      </td>
      <td
        v-for="(dataItem, key) in headers"
        :key="`activity_table-data_${key}`"
        class="py-2.5"
      >
        <p class="px-2 flex items-center font-0-95rem select-none">
          <span
            v-tooltip="{
              content: `${competitor[headers[key]]}`,
              theme: 'light',
            }"
            :class="
              getDataClasses(
                competitor,
                dataItem,
                extremeValues,
                tableData.length
              )
            "
            >{{ getTableData(dataItem, competitor[headers[key]]) }}</span
          >
          <i
            v-if="
              headers[key].includes('Diff') &&
              competitor[headers[key]] !== 'N/A'
            "
            class="fa ml-1"
            :class="
              competitor[headers[key]] > 0
                ? 'fa-arrow-up text-[#56C288]'
                : 'fa-arrow-down text-red-500'
            "
          ></i>
        </p>
      </td>
    </tr>
  </tbody>
</template>

<script setup>
// libraries
import { computed } from 'vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
})
const { getDataClasses, getMinMaxValues } = useCompetitorHelper()

/**
 * @description checks key and value to return the proper values for row data
 * @param header
 * @param value
 * @returns {*|string}
 */
const getTableData = (header, value) => {
  if (value === 'N/A') return value
  return value > 1000
    ? parseInt(value).toLocaleString('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      })
    : value
}

/**
 * @description checks if the min and max values are passed through props, if not, calls getMinMaxValues method to get min and max values of all columns.
 * @type {ComputedRef<unknown>}
 */
const extremeValues = computed(() => {
  return getMinMaxValues(props.tableData, props.headers)
})
</script>
