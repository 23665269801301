<template>
  <div class="flex flex-col screen-height bg-white">
    <!-- Body -->
    <div class="flex flex-col md:flex-row h-full overflow-x-auto">
      <div class="flex-1 p-[2rem] border-right border-top h-full">
        <slot name="body"></slot>
      </div>
      <div class="w-1/4 border-right border-top h-full min-w-[400px]">
        <slot name="timeline"></slot>
      </div>
    </div>
  </div>
</template>

<style>
.sortable-ghost {
  @apply opacity-20 bg-gray-100 border border-blue-500;
}
</style>

<style lang="scss" scoped>
.screen-height {
  height: calc(100% - 70px);
}
</style>
