import moment from 'moment'
import * as mt  from 'moment-timezone'
import { useStore } from '@/src/store/base'

export default function useTime() {
  const store = useStore()
  const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss'

  // Function to format a date using Moment.js
  const formatDate = (date, format = DEFAULT_FORMAT) => {
    return moment(date).format(format)
  }

  // Function to get the current date and time
  const getCurrentDateTime = () => {
    return moment().format(DEFAULT_FORMAT)
  }

  // Function to calculate the difference between two dates
  const calculateDateDiff = (startDate, endDate, unit = 'days') => {
    return moment(endDate).diff(moment(startDate), unit)
  }

  const getClientTimeZone = () => mt.tz.guess()

  function getWorkspaceTimeZoneTime(date, format, timezone = null) {
    if (!timezone) {
      timezone = store.getters.getActiveWorkspace.timezone
    }

    return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').tz(timezone).format(format)
  }

  return {
    DEFAULT_FORMAT,
    formatDate,
    getCurrentDateTime,
    getWorkspaceTimeZoneTime,
    calculateDateDiff,
    getClientTimeZone
  }
}
