import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getCustomTopicsURL, similarArticlesURL } from '../../config/api-utils'
import { topicTypes } from '../../store/mutation-types'

const topicsMixin = {
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getTopic',
      'getTopicSelection',
      'getTopicsFilters',
      'getTopicType',
      'getCuratedTopic',
      'getPosts',
      'getTopicsPage',
      'getTopicModule',
      'getSidebarCustomization',
      'getTopicContentType',
      'getTopics',
      'getMyCuratedTopicsList',
      'getTrendingStories',
      'getCoveredStoriesPosts',
      'getTopicsLayout',
      'getActiveTopicTab'
    ])
  },
  methods: {
    ...mapMutations([
      topicTypes.SET_CHANGE_TOPIC_FILTERS,
      'SET_POSTS',
      'SET_TOPICS_LAYOUT',
      'SET_TOPIC_CONTENT_TYPE',
      'SET_TOPIC_MODULE',
      'SET_POSTS_TOTAL',
      'SET_TOPIC_QUOTA_STATUS',
      'SET_CUSTOM_TOPICS',
      topicTypes.SET_TOPIC_DISABLED,
      topicTypes.SET_BEFORE_TOPIC_NAME,
      'SET_CUSTOM_TOPICS_LOADER',
      'SET_BEFORE_DATE',
      'SET_BEFORE_REGION',
      'SET_BEFORE_LANGUAGE',
      'SET_BEFORE_SORT',
      'SET_TOPIC_SELECTION'
    ]),
    ...mapActions([
      'setTopic',
      'setInsightsCount',
      'setSidebarCustomization',
      'stateResetTopicFilters',
      'setTopicCreationModal',
      'setSimilarArticles',
      'topicsContent',
      'topicInsights',
      'articlesPreviewSuggestion'
    ]),

    isTopicsDisplayAllowed (post) {
      // if (post._source.has_abstract) return false
      if (!post._source.hasOwnProperty('topics')) return false
      if (post._source.topics && post._source.topics.length === 0) return false
      if (
        post._source.hasOwnProperty('word_count') &&
        post._source.word_count <= 50
      ) {
        return false
      }
      if (
        post._source.hasOwnProperty('language') &&
        post._source.language === 'en'
      ) {
        return true
      }
      return false
    },
    createNewTopic () {
      // store before states, and if the user dismisses a modal, we will reset to the default ones.

      this.setTopicCreationModal(true)
      this[topicTypes.SET_BEFORE_REGION](this.getTopicsFilters.topics.region)
      this[topicTypes.SET_BEFORE_LANGUAGE](
        this.getTopicsFilters.topics.language
      )
      this[topicTypes.SET_BEFORE_SORT](this.getTopicsFilters.topics.sort)
      this[topicTypes.SET_BEFORE_DATE](this.getTopicsFilters.topics.date)
      this[topicTypes.SET_BEFORE_TOPIC_NAME](this.getTopicsFilters.topics.name)
      this.setTopic(null)
      this.stateResetTopicFilters()
      this.SET_TOPIC_SELECTION(null)
      // $('#create_topic_modal').modal('show')
      this.$bvModal.show('create_topic_modal')

      // onboarding tutorial for discovery

      // setTimeout(() => {
      //    // $('#create-custom-topic').modal('show')
      //      this.$bvModal.show('create-custom-topic')
      //  }, 3000)
    },

    selectCoverStoriesFilters () {
      this.SET_CHANGE_TOPIC_FILTERS({ topic: null, type: 'covered_stories' })
      this.SET_POSTS([])
    },
    selectCoveredStories () {
      // this.selectCoverStoriesFilters()
      // this.topicsContent()
      this.$router.push({ name: 'discover_topics_cover_stories' })
    },
    editTopic (topic) {
      this.setTopicCreationModal(true)
      this.SET_BEFORE_REGION(this.getTopicsFilters.topics.region)
      this.SET_BEFORE_LANGUAGE(this.getTopicsFilters.topics.language)
      this.SET_BEFORE_SORT(this.getTopicsFilters.topics.sort)
      this.SET_BEFORE_DATE(this.getTopicsFilters.topics.date)
      this[topicTypes.SET_BEFORE_TOPIC_NAME](this.getTopicsFilters.topics.name)
      console.debug(topic)
      this.setTopic(topic)
      // $('#create_topic_modal').modal('show')
      this.$bvModal.show('create_topic_modal')
      this.articlesPreviewSuggestion()
    },

    getCustomTopics () {
      console.debug('Method:getCustomTopics')
      this.showLoaderRequest = false
      this.SET_CUSTOM_TOPICS_LOADER(true)
      this.postRequest(
        getCustomTopicsURL,
        {
          workspace_id: this.getWorkspaces.activeWorkspace._id
        },

        (response) => {
          const getTopicList = response.data.topics
          for (let i = 0; i < response.data.topics.length; i++) {
            getTopicList[i].loader_state = false
          }
          // set the topics to state
          this.SET_CUSTOM_TOPICS(getTopicList)
          this.SET_TOPIC_QUOTA_STATUS(true)
          this.SET_CUSTOM_TOPICS_LOADER(false)
          // change the show loader request which is on the parent component.
        },
        (response) => {
          console.debug('Error in getContentTopics', response)
        }
      )
    },

    // when the user visits a disovery topics model
    // we are checking if he has custom or curated topic.
    // If they have that enabled, we will be using the first topic.
    defaultTopicOnView () {
      // get covered stories...

      if (
        this.getMyCuratedTopicsList &&
        this.getMyCuratedTopicsList.length > 0
      ) {
        this.$router.push({
          name: 'topics_web',
          params: {
            module: 'custom',
            topicId: this.getMyCuratedTopicsList[0]._id
          }
        })
      } else if (this.getTopics && this.getTopics.length > 0) {
        this.$router.push({
          name: 'topics_web',
          params: { module: 'custom', topicId: this.getTopics[0]._id }
        })
      }
    },

    async changeContentType (type) {
      this.SET_TOPIC_CONTENT_TYPE(type)
      console.log('method: changeContentType')
      const payload = {
        name: 'topics_web',
        params: {
          module: this.$route.params.module,
          topicId: this.$route.params.topicId
        },
        meta: {
          reuse: true
        }
      }
      console.log('method: type of a change content is:', type)

      switch (type) {
        case 'article':
          console.log('Redirecting router push to topics_web')
          this.$router.push(payload)
          break
        case 'youtube':
          payload.name = 'topics_youtube'
          this.$router.push(payload)
          break
        case 'twitter':
          payload.name = 'topics_twitter'
          this.$router.push(payload)
          break
        case 'facebook':
          payload.name = 'topics_facebook'
          this.$router.push(payload)
          break
      }
    },

    sidebarCustomization () {
      console.debug('Method:sidebarCustomization')

      this.setSidebarCustomization(!this.getSidebarCustomization.customization)

      setTimeout(function () {
        document
          .querySelector('.topics-left-pane__fixed_block__inner')
          .scrollTo({ top: 10000000000000, behavior: 'smooth' })
      }, 100)
    },
    selectionSibarItem (type, optionValue) {
      const sidebar = this.getCustomSidebar
      switch (type) {
        case 'quickTopics':
          if (typeof optionValue !== 'undefined') {
            if (sidebar.quick_topics.options.includes(optionValue) === false) {
              sidebar.quick_topics.options.push(optionValue)
            } else {
              const index = sidebar.quick_topics.options.indexOf(optionValue)

              if (index > -1) {
                sidebar.quick_topics.options.splice(index, 1)
              }
            }
          } else {
            sidebar.quick_topics.status = !sidebar.quick_topics.status
          }
          break
        case 'myTopics':
          if (typeof optionValue !== 'undefined') {
            if (sidebar.my_topics.options.includes(optionValue) === false) {
              sidebar.my_topics.options.push(optionValue)
            } else {
              const index = sidebar.my_topics.options.indexOf(optionValue)

              if (index > -1) {
                sidebar.my_topics.options.splice(index, 1)
              }
            }
          } else {
            sidebar.my_topics.status = !sidebar.my_topics.status
          }
          break
        case 'favorites':
          if (typeof optionValue !== 'undefined') {
            if (sidebar.favorites.options.includes(optionValue) === false) {
              sidebar.favorites.options.push(optionValue)
            } else {
              const index = sidebar.favorites.options.indexOf(optionValue)

              if (index > -1) {
                sidebar.favorites.options.splice(index, 1)
              }
            }
          } else {
            sidebar.favorites.status = !sidebar.favorites.status
          }
      }
    },
    sibarItemStatus (type, optionValue) {
      const sidebar = this.getCustomSidebar
      switch (type) {
        case 'quickTopics':
          if (typeof optionValue !== 'undefined') {
            return !sidebar.quick_topics.options.includes(optionValue)
          } else {
            return sidebar.quick_topics.status
          }
        case 'myTopics':
          if (typeof optionValue !== 'undefined') {
            return !sidebar.my_topics.options.includes(optionValue)
          } else {
            return sidebar.my_topics.status
          }
        case 'favorites':
          if (typeof optionValue !== 'undefined') {
            return !sidebar.favorites.options.includes(optionValue)
          } else {
            return sidebar.favorites.status
          }
      }
    },
    followCuratedTopicsModal () {
      // $('#curated_topic_modal').modal('show')
      this.$bvModal.show('curated_topic_modal')
    },

    retrieveSimilarArticles (id, index) {
      $('#similar_article_modal').modal('show')
      this.setSimilarArticles([])
      this.postRequest(
        similarArticlesURL,
        {
          id,
          index
        },

        (response) => {
          if (response.data.status === true) {
            this.setSimilarArticles(response.data.posts)
          } else {
          }
        },
        (response) => {
          console.debug('Error in sidebarCustomizationDone', response)
        }
      )
    },

    curatedQuotes () {
      this.SET_CHANGE_TOPIC_FILTERS({ topic: null, type: 'quotes' })
      this.$router.push({ name: 'topics_quotes' })
    },
    changeLayout (value) {
      console.log('method:changeLayout')
      console.log(value, this.layout)
      if (value !== this.getTopicsLayout) {
        this.SET_TOPICS_LAYOUT(value)
      }
    },
    changeCuratedTopicLabels (item, topicName) {
      // for discovery search

      if (this.$route.name === 'search_web') {
        item.name = topicName
        this.SET_POSTS([])
        this.searchByCuratedTopic(item)
      } else {
        // for the topics view
        this.$router.push({
          name: 'topics_web',
          params: { module: 'curated', topicId: 'list', topicName }
        })
      }
    },

    isCustomTopicAvailable (set = false) {
      const topicFind = this.getTopics.filter((value) => {
        if (value._id === this.$route.params.topicId) return true
      })
      if (topicFind.length > 0) {
        return { topic: topicFind[0] }
      }
      return null
    },

    isTrendingNews () {
      const topic = {
        id: '',
        name: 'Trending News',
        label: '/News',
        picture:
          'https://storage.googleapis.com/lumotive-web-storage/discovery/quick-topics/post_card-12%402x.png',
        followed: true,
        workspace_id: '5bdaa726c557ae00083822d3'
      }
      this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
        topic,
        type: 'trending'
      }) // we have the default trending object in the store.
    },

    isCuratedTopicAvailable (set = false) {
      if (this.$route.params.topicName) {
        const item = {
          name: this.$route.params.topicName,
          label: this.$route.params.topicName
        }
        return { topic: item, id_available: false }
      }

      // checking in followed list first
      const topicFind = this.getMyCuratedTopicsList.filter((value) => {
        if (value._id === this.$route.params.topicId) return true
      })

      if (topicFind.length > 0) {
        return { topic: topicFind[0], id_available: true }
      }
      return null
    }
  }
}
export { topicsMixin }
