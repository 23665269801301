<template>
  <DatePicker
      type="datetime"
      :default-value="calendarPointer"
      :value="calendarPointer"
      value-type="format"
      :format="getAccountDateTimeFormat"
      :title-format="getAccountDateTimeFormat"
      class="w-[85%] schedule-picker absolute !h-[34rem] !top-0"
      input-class="mx-input !text-[14px] !text-[#2973E5] px-4 py-6 border-0 bg-transparent shadow-none"
      popup-class="custom-date-picker border-0 z-0 popup-height"
      :popup-style="{ boxShadow: '0px 0px 0px 0px' }"
      :clearable="false"
      :open="true"
      :append-to-body="false"
      :show-time-panel="false"
      :disabled-date="checkDateDisable"
      :editable="false"
      @change="handleDateChange"
      @click.native="onClickPostDate"
  >
    <template v-slot:footer="{ emit, value }">
      <SelectTime
          :hour24="getUserTimePreference === '24h'"
          :emit="emit"
          :apply-button="false"
          :selected-date="value"
      />
    </template>
  </DatePicker>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// libraries
import {defineProps, ref, watchEffect} from 'vue'

// components
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime.vue'
import DatePicker from 'vue2-datepicker'
import useDateFormat from "@common/composables/useDateFormat";
import moment from "moment";
// constants
import {useComposerHelper} from '@src/modules/composer_v2/composables/useComposerHelper'

// props
const props = defineProps({
  fixedScheduleTime: {
    type: String,
    default: '',
  },
})

//refs
const calendarPointer = ref(props.fixedScheduleTime);
// emits
const emit = defineEmits(['fixed-schedule'])

// functionality
const {checkDateDisable, changeCalendarState} = useComposerHelper()
const {getAccountDateTimeFormat,getUserTimePreference,momentWrapper} = useDateFormat()
// methods
const handleDateChange = (value) => {
  if (!value) return
   emit('fixed-schedule', value)
}
const onClickPostDate = (event) => {
  calendarPointer.value = changeCalendarState(event, calendarPointer.value);
}

//watchers
watchEffect(() => {
  calendarPointer.value = props.fixedScheduleTime;
});

</script>

<style lang="scss">
.schedule-picker {
  .mx-input-wrapper {
    position: absolute;
    top: 29rem;
    width: 97%;
    left: 0.25rem;
    border: 1px solid #2973E5;
    border-radius: 8px;

    .mx-icon-calendar {
      display: none;
      color: #2973E5;
      padding-right: 0.59rem !important;
    }
  }
}

.popup-height {
  top: 0 !important;
  left: 0 !important;
  .mx-datepicker-content{
    .mx-datepicker-body{
      .mx-calendar{
        .mx-calendar-content{
          height: 19rem !important;
        }
      }
    }
  }
}

.custom-date-picker {
  .mx-calendar {
    width: 100%;
  }

  .mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 16px 32px 16px 10px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #26303e;
    background-color: #fff;
    border: 2px solid #eceff1;
    border-radius: 8px;
  }
}

.calendar-icon {
  .mx-icon-calendar {
    color: #26303e;
    padding-right: 1rem;
  }

  .mx-icon-clear {
    padding-right: 1rem;
  }
}

.mx-datepicker-footer{
  text-align: center !important;
  .mx-btn{
    font-size: 0.875rem;
    line-height: normal;
    padding: 0.6rem 1.5rem;
    background-color: #2973E5;
    color: #fff;
    border-radius: 0.375rem;
    border-width: 0;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    &:hover{
      background-color: #2973E5;
      color: #fff;
    }
  }
}
</style>