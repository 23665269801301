<template>
  <div class="rounded-xl overflow-hidden">
    <div ref="editor" class="cst-textarea text-base"></div>
  </div>
</template>

<script>
import SocialEditor from 'social-editor'
import { intToString } from '@common/lib/helper'

export default {
  name: 'CstTextArea',

  props: {
    type: {
      type: String,
      default: 'composer',
    },
    placeholder: {
      type: String,
      default: 'Write something amazing...',
    },
    initialValue: {
      type: String,
      default: '',
    },
    maxLimit: {
      type: Number,
      default: 0,
    },
    twitterTextEnable: {
      type: Boolean,
      default: false,
    },
    getSuggestions: {
      type: Function,
      default: () => {},
    },
    getSuggestionsLoader: {
      type: Function,
      default: () => {},
    },
    savedLinks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change', 'paste', 'drop', 'dragEnter', 'dragLeave','handle-keydown'],
  /**
   * Data
   */
  data: () => ({
    editor: null,
  }),

  /**
   * Before Destroy
   */
  beforeUnmount() {
    console.log('beforeUnmount editor')

    this.editor.destroy()
    this.editor = null
    if (this.$refs.editor) {
      this.$refs.editor.removeEventListener('keydown', this.handleKeydown)
    }

  },

  /**
   * Mounted
   */
  mounted() {
    this.init()
    this.$refs.editor.addEventListener('keydown', this.handleKeydown)
  },

  /**
   * Methods
   */
  methods: {
    init() {
      if (this.$el) {
        const elem = this.$refs.editor

        this.editor = new SocialEditor(elem, {
          placeholder: this.placeholder,

          initialValue: this.initialValue,

          max: this.maxLimit,

          twitterText: this.twitterTextEnable,

          onUpdate: (store) => {
            this.$emit('change', store)
          },
          onPaste: (event, view, data) => {
            this.$emit('paste', event, view, data)
          },
          onDrop: (event, view) => {
            event.preventDefault()
            this.$emit('drop', event, view)
          },
          onDragEnter: (event, view) => {
            this.$emit('dragEnter', event, view)
          },
          onDragLeave: (event, view) => {
            this.$emit('dragLeave', event, view)
          },

          getMentionSuggestionsHTML: (items) => `
  <div class="suggestion-item-list">
    ${items
      .map(
        (item) => `
      <div class="suggestion-item">
        <div class="flex items-center">
          <div class="picture" style="background: rgba(0, 0, 0, 0) url(${
            item.picture
          }) repeat scroll 0% 0%;"></div>
          <div class="info pl-2">
            <div class="text">
              ${item.name}
              ${
                item.verification_status
                  ? '<i aria-hidden="true" class="fa fa-check-circle verified_icon"></i>'
                  : ''
              }
            </div>
            <div class="sub_text">
              <p>${item.username}</p>
              ${
                item.fan_count
                  ? `<p>Followers: <b>${intToString(item.fan_count)}</b></p>`
                  : ''
              }
            </div>
          </div>
        </div>
      </div>
    `
      )
      .join('')}
  </div>
`,
          getTagSuggestionsHTML: (items) =>
            '<div class="suggestion-item-list suggestion-list">' +
            items
              .map(
                (i) => '<div class="suggestion-item item">' + i.tag + '</div>'
              )
              .join('') +
            '</div>',
          getSuggestions: this.getSuggestions,
          getMentionSuggestionsLoader: this.getSuggestionsLoader,
          getTagSuggestionsLoader: () => null,
          linkPreview: this.type !== 'threaded-tweet',
          onLinkClick: (link) => {
            const short = this.savedLinks.find((item) => item.short === link)
            if (short) {
              return {
                text: 'Unshort to:',
                link: short.original,
              }
            }
            const original = this.savedLinks.find(
              (item) => item.original === link
            )
            if (original) {
              return {
                text: 'Short to:',
                link: original.short,
              }
            }
            return {
              text: '',
              link,
            }
          },
        })
      }
    },
    handleKeydown(event) {
      if (event.ctrlKey && (event.key === 'b' || event.key === 'i')) {
        event.preventDefault()
        this.$emit('handle-keydown', event.key)
      }
    }
  },
}
</script>

<style lang="scss">
.cst-textarea {
  max-height: 31rem;
  overflow-y: auto;
  .ProseMirror {
    min-height: 8rem;
  }
}
.social-editor-box {
  border: 0 !important;
}

.suggestion-item-list {
  max-height: 300px;
  overflow-y: auto;
  width: 300px;

  .suggestion-item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #f5f9fc;
    background-color: #ffffff;
    &:hover {
      background: #f4f9fd;
    }

    .picture {
      height: 40px;
      width: 40px;
      min-width: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      background: #ebf3f7;
      background-size: cover !important;
    }

    .text {
      font-weight: 500;
      color: #3a4557;
      font-size: 0.875rem;
      word-break: break-word;
      word-wrap: break-word;

      .verified_icon {
        color: #2f8ae0;
      }
    }

    .sub_text {
      font-size: 12px;
      color: #989eb5;
      word-break: break-word;
      word-wrap: break-word;

      p {
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        border-right: 1px solid rgba(152, 158, 181, 0.5);
        margin-right: 0.625rem;
        padding-right: 0.625rem;

        &:last-child {
          border-right: 0;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
