<template>
  <div :id="containerId" class="flex justify-center h-full w-full"></div>
</template>

<script setup>
import { watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  tweetLink: {
    type: String,
    required: true,
  },
  containerId: {
    type: String,
    default: 'twitter-post-container',
  },
})

const TWITTER_WIDGET_SCRIPT_URL = 'https://platform.twitter.com/widgets.js'

watch(
  () => props.tweetLink,
  () => {
    // Render the Twitter post
    createTwitterPost()
  }
)

onMounted(() => {
  if (!document.getElementById('twitter-post-script')) {
    const script = document.createElement('script')
    script.id = 'twitter-post-script'
    script.src = TWITTER_WIDGET_SCRIPT_URL
    document.head.appendChild(script)
  }

  // Render the Twitter post
  createTwitterPost()
})

onBeforeUnmount(() => {
  // had to remove the script tag on unmount otherwise if u switch different posts, the preview doesn't work
  const script = document.getElementById('twitter-post-script')
  script?.remove()
})

/**
 * This method creates the Twitter post by removing any existing post, rendering the new post, and parsing the Twitter content.
 */
function createTwitterPost() {
  // Render the Twitter post
  const twitterPostContainer = document.getElementById(props.containerId)
  if (twitterPostContainer) {
    const twitterPostBlockQuote = document.createElement('blockquote')
    twitterPostBlockQuote.className = 'twitter-tweet'
    twitterPostBlockQuote.setAttribute('data-dnt', 'true')

    const twitterPostAnchor = document.createElement('a')
    twitterPostAnchor.setAttribute('href', props.tweetLink)
    twitterPostAnchor.setAttribute(
      'aria-label',
      `View Tweet: ${props.tweetLink}`
    )
    twitterPostBlockQuote.appendChild(twitterPostAnchor)

    twitterPostContainer.appendChild(twitterPostBlockQuote)
  }
}
</script>
