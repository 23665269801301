<template>
  <div
    class="topics-insights-container__chart_half_width topics-insights-container__chart_left_margin"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ noDataMessage }}
        </p>
      </div>
    </template>

    <h2>Popular Word Count</h2>

    <div id="wordCloud" class="topics-insights-container__chart_legends" />
    <div class="topics-insights-container__chart_half_width__chart">
      <highcharts
        ref="popular_reading_chart"
        :options="popularWordCount"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'
import { getChartColorCode } from '@common/constants/common-attributes'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_popular_reading_wordcount_list: {}
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,

      popularWordCount: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{point.key} Word Count</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} articles.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#wordCloud')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#5e69bc', '#8ca8dd'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas'],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Articles',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 40,
            borderRadiusTopRight: 40,
            pointPadding: 0,
            groupPadding: 0.3
          }
        },
        series: [
          {
            type: 'column',
            name: 'Engagement Per Article',
            data: [11, 13, 4, 73, 3],
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#1da1f2'],
                [1, '#9cd9ff']
              ]
            }
          },
          {
            type: 'column',
            name: 'Number of Articles',
            data: [11, 23, 4, 23, 43],
            yAxis: 1,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#6a98cf'],
                [1, '#9ec2ee']
              ]
            }
          }
        ]
      }
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.isZero()) {
        return true
      }
      return false
    }
  },
  created () {
    if (this.isZero() == false) {
      this.popularWordCount.series[0].data =
        this.articles_popular_reading_wordcount_list.interactions
      this.popularWordCount.series[1].data =
        this.articles_popular_reading_wordcount_list.articles
      this.popularWordCount.xAxis.categories =
        this.articles_popular_reading_wordcount_list.categories
    }
  },
  methods: {
    isZero () {
      return (
        this.articles_popular_reading_wordcount_list.categories.length === 0 &&
        this.articles_popular_reading_wordcount_list.articles.length === 0 &&
        this.articles_popular_reading_wordcount_list.interactions.length === 0
      )
    }
  }
}
</script>
