<template>
  <b-modal
    id="sendDirectMessageModal"
    hide-footer
    hide-header
    modal-class="sendDirectMessageModal  normal_modal"
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Reply as Private</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('sendDirectMessageModal')"
      >
        &times;
      </button>
    </div>

    <div class="modal_body basic_form m_t_15">
      <p
        >Replying via direct/private message to
        <span class="mention"
          >@<span v-if="replyTo.posted_by.name" class="user_name mention">{{
            replyTo.posted_by.name
          }}</span>
          <span v-else class="user_name mention">User</span></span
        ></p
      >
      <div class="input_field">
        <div class="text_area relative">
          <div class="comment_tool">
            <Emoji dropdown-position="left_side" type="Common"></Emoji>
          </div>
          <textarea
            id="common_sharing_message"
            v-model="message"
            name=""
            placeholder="Write a private message..."
            cols="30"
            rows="4"
          >
          </textarea>
        </div>
      </div>

      <div class="input_field">
        <button class="btn gradient_btn large_btn" @click="sendPrivateMessage">
          <span>Send</span>
        </button>
        <button
          class="btn light_gray ml-2 large_btn"
          @click="$bvModal.hide('sendDirectMessageModal')"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Emoji from '../../publish/components/posting/social/emoji/Emoji'

export default {
  components: { Emoji },
  props: ['replyTo'],
  data () {
    return {
      message: ''
    }
  },
  computed: {
    ...mapGetters([])
  },
  watch: {
    replyTo: async function (newVal, oldVal) {
      // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    }
  },

  mounted () {
    console.log(this.replyTo)
  },
  created () {},
  methods: {
    sendPrivateMessage (message) {
      this.$emit('reply', {
        comment_id: this.replyTo.comment_id,
        message: this.message
      })
    }
  }
}
</script>
