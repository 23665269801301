<template>
  <div
    v-if="account && account.id"
    class="mt-5 flex items-center justify-between pl-2 pr-[0.65rem] h-[45px]"
  >
    <div class="flex items-center w-full max-w-[8rem] mr-3">
      <div class="relative">
        <img
          :src="accountImage"
          alt=""
          class="w-[2.625rem] h-[2.625rem] rounded-full"
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
          "
        />
        <img
          :src="channelRoundedImage"
          alt=""
          class="absolute right-0 bottom-0 w-5 h-5 rounded-full"
        />
      </div>
      <div class="flex flex-col ml-3 w-full">
        <p class="text-[0.875rem] text-[#26303E] w-full truncate">
          {{ account.name }}</p
        >
        <span
          v-if="account.accountType"
          class="text-[0.813rem] text-[#69788E] mt-0.5"
          >{{
            account.accountType.charAt(0).toUpperCase() +
            account.accountType.slice(1)
          }}</span
        >
      </div>
    </div>
    <div ref="datePickerRef" class="flex items-center">
      <DatePicker
          :default-value="momentWrapper(calendarPointer).formatDateTime()"
          :value="momentWrapper(calendarPointer).formatDateTime()"
        type="datetime"
        value-type="format"
        :format="getAccountDateTimeFormat"
        :title-format="getAccountDateTimeFormat"
        class="account-date-picker w-[185px]"
        :class="accountTime ? '' : 'account-no-date rounded-[4px]'"
        :input-class="
          accountTime
            ? 'mx-input p-3 bg-transparent shadow-none h-[39px] !text-[13px] !text-[#3C4549]'
            : 'mx-input p-3 bg-transparent shadow-none h-[39px] !text-[13px] !text-[#3C4549] border-0'
        "
        popup-class="border-0	rounded-xl"
        :popup-style="{ boxShadow: '0px 3px 40px rgba(0, 26, 119, 0.08)' }"
        :disabled-date="checkDateDisable"
        :confirm="true"
        confirm-text="Apply"
        :show-time-panel="false"
        :append-to-body="false"
        :clearable="false"
        @change="handleDatePickerChange"
        @close="onClosePostDate"
        @click.native="onClickPostDate"
      >
        <template v-slot:footer="{ emit, value }">
          <SelectTime
            :hour24="getUserTimePreference === '24h'"
            :emit="emit"
            :selected-date="value"
            :timezone="getWorkspaces.activeWorkspace.timezone"
          />
        </template>
      </DatePicker>
      <div
          v-tooltip="account.invalidTime ? 'You are not allowed to select the past time to schedule a post.' : '' "
        class="
          rounded
          w-8
          h-8
          mix-blend-multiply
          ml-3
          flex
          justify-center
          items-center
        "
        :class="updatedDate ? 'bg-[#F1FAFF]' : 'bg-[#F2F4F6]'"
      >
        <img :src="getValidDateImg" alt="" :class="account.invalidTime ? 'w-5 h-5' : 'w-3.5'" />
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// libraries
import { defineProps, computed, ref, watchEffect, nextTick } from 'vue'
import useDateFormat from '@common/composables/useDateFormat'

// components
import DatePicker from 'vue2-datepicker'
import { EventBus } from '@common/lib/event-bus'

import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import SelectTime from '@src/modules/composer_v2/components/PostingSchedule/SelectTime.vue'
import moment from "moment";

const props = defineProps({
  account: {
    type: Object,
    default: () => {},
  },
  accountImage: {
    type: String,
    default: '',
  },
  channelRoundedImage: {
    type: Object,
    default: '',
  },
  accountTime: {
    type: String,
    default: '',
  },
})
// functionality
const { checkDateDisable, changeCalendarState } = useComposerHelper()
const { getAccountDateTimeFormat, getUserTimePreference,momentWrapper } = useDateFormat()

const updatedDate = computed(() => props.account?.time)

const getValidDateImg = computed(() =>
  require(updatedDate?.value && updatedDate.value !== 'Invalid date'
    ? props.account?.invalidTime ? '@assets/img/composer/error-icon.svg' : '@assets/img/tick.webp'
    : '@assets/img/common/tick_gray.svg')
)
const calendarPointer = ref(
  props.accountTime === ''
    ? momentWrapper().formatTimezone().formatDateTime()
    : props.accountTime
)
const datePickerRef = ref()

const updateInputField = async () => {
  await nextTick(() => {
    const childInput = datePickerRef.value.querySelector('.mx-input')
    if (childInput) {
      childInput.value = props.accountTime
    }
  })
}

const handleDatePickerChange = (time) => {
  if (!time) return
  EventBus.$emit('reset-channel-time', {
    channel: props.account?.channelType,
  })
  EventBus.$emit('account-time-change', {
    accountId: props.account?.id,
    time: time,
    channel: props.account?.channelType,
  })
}

const onClickPostDate = (event) => {
  calendarPointer.value = changeCalendarState(event, calendarPointer.value)
}

//watchers
watchEffect(async () => {
  calendarPointer.value =
    props.accountTime === ''
      ? momentWrapper().formatDateTime()
      : props.accountTime
  await updateInputField()
})
const onClosePostDate = async () => {
  await updateInputField()
}
</script>

<style lang="scss">
.account-no-date {
  .mx-input-wrapper {
    .mx-input {
      border: 1px solid #cccccc !important;
      border-radius: 4px;
      &:hover,
      &:focus {
        border: 1px solid #409aff !important;
      }
    }
  }
}
</style>
