<template>
  <div
    class="distribute_block overflow-y-auto"
    :class="{ 'blog_steps_common blog_steps_none_modal': noModel }"
  >
    <template v-if="isWorkspaceOnHold">
      <div class="input_field">
        <div class="warning_box warning-alert">
          <p v-if="hasPermission('can_change_hold_status')">
            <i class="fa fa-warning"></i> Note: You cannot select accounts
            because you've paused publishing for this workspace,<router-link
              :to="{ name: 'workspaces' }"
              class="ml-2"
              >Click here to resume publishing</router-link
            ></p
          >
          <p v-if="!hasPermission('can_change_hold_status')">
            <i class="fa fa-warning"></i> Note: You cannot select accounts
            because the admin of this workspace has paused publishing for this
            workspace.</p
          >
        </div>
      </div>
    </template>

    <template v-if="!isWorkspaceOnHold">
      <div class="opt_box basic_form">
        <div class="head_text">
          <h3>Primary Channel</h3>
          <p
            >Primary channel should be your main blogging platform. This is
            where your post gets published first. You can select only 1 primary
            channel here to avoid duplicate content issue.</p
          >
        </div>

        <template
          v-if="
            getBlogSelection.platform_name === '' ||
            getBlogSelectionEdit.primary
          "
        >
          <div class=" mt-0">
            <div class="d-flex align-items-center">
              <label class="text-base mr-4">Select blog platform</label>
              <BlogSelectionLinks></BlogSelectionLinks>
            </div>
          </div>
          <BlogSelection></BlogSelection>

          <div v-if="checkOptionSelection" class="btn_block mt-4">
            <button
              class="btn btn-studio-theme-space"
              :disabled="fetch_blog_options_loader || isNullSelected"
              data-cy="add-button"
              @click.prevent="addPrimarySelection"
            >
              <span v-if="getBlogSelectionEdit.primary">Update</span>
              <span v-else> Add </span>
            </button>
            <button
              class="btn btn-studio-theme-transparent-grey ml-2"
              @click.prevent="cancelEditPrimary"
            >
              <span>Cancel</span>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="added_boxes row">
            <div class="blog_box_item">
              <div class="inner">
                <div
                  class="box_head d-flex align-items-center"
                  :class="getBlogSelection.platform_name"
                >
                  <div class="h_left">
                    <i
                      :class="
                        configurations[getBlogSelection.platform_name]
                          .background_classes
                      "
                    ></i>
                    <span class="text">{{
                      startCaseText(getBlogSelection.platform_name) ===
                      'Wordpress'
                        ? 'WordPress'
                        : startCaseText(getBlogSelection.platform_name)
                    }}</span>
                  </div>
                  <div v-if="checkPrimaryEditPermission()" class="h_right">
                    <i
                      class="icon-edit-cs"
                      @click.prevent="editPrimaryAccount"
                    ></i>
                    <i
                      class="cs-cross ml-2"
                      @click.prevent="removePrimaryAccount"
                    ></i>
                  </div>
                </div>
                <div class="blog_content basic_form">
                  <div class="input_field mt-0">
                    <div class="profile_picture">
                      <div class="picture_block">
                        <img
                          :src="
                            getBlogLogo(
                              getBlogSelection.platform_name,
                              getBlogSelection.primaryBlog[
                                getBlogSelection.platform_name
                              ].website,
                            )
                          "
                          alt=""
                          data-cy="blog-platform"
                        />
                      </div>
                      <div class="text_block">
                        <p class="text">{{
                          getBlogURL(
                            getBlogSelection.platform_name,
                            getBlogSelection.primaryBlog[
                              getBlogSelection.platform_name
                            ].website,
                          )
                        }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].author
                    "
                    class="input_field"
                  >
                    <label for="">Author</label>
                    <p
                      v-if="getBlogSelection.platform_name === 'wordpress'"
                      class="p_text"
                      >{{
                        getWordpressAuthorName(
                          getBlogSelection.primaryBlogOption[
                            getBlogSelection.platform_name
                          ].authors,
                          getBlogSelection.primaryBlog[
                            getBlogSelection.platform_name
                          ].author,
                        )
                      }}</p
                    >
                    <p v-else class="p_text">{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].author
                    }}</p>
                  </div>
                  <div
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].categories
                    "
                    class="input_field"
                  >
                    <label for="">Category</label>
                    <p
                      v-for="(category, index) in getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].categories"
                      :key="`category-${index}`"
                      class="p_text"
                      >{{
                        getWordpressCategoryName(
                          getBlogSelection.primaryBlogOption[
                            getBlogSelection.platform_name
                          ].categories,
                          category,
                        )
                      }}</p
                    >
                  </div>
                  <div
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].post_tags
                    "
                    class="input_field"
                  >
                    <label for="">Tags</label>
                    <div>
                      <p
                        v-for="(tag, index) in getBlogSelection.primaryBlog[
                          getBlogSelection.platform_name
                        ].post_tags"
                        :key="index"
                        class="p_text d-inline-block mr-1"
                      >
                        {{ tag
                        }}{{
                          index !==
                          getBlogSelection.primaryBlog[
                            getBlogSelection.platform_name
                          ].post_tags.length -
                            1
                            ? ', '
                            : ''
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ] &&
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].status
                    "
                    class="input_field"
                  >
                    <label for="">Publish status</label>
                    <p class="p_text">{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].status
                    }}</p>
                  </div>
                  <div
                    v-else-if="
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ] &&
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].published
                    "
                    class="input_field"
                  >
                    <label for="">Publish status</label>
                    <p class="p_text">{{
                      getBlogSelection.primaryBlog[
                        getBlogSelection.platform_name
                      ].published
                    }}</p>
                  </div>
                  <div v-else class="input_field">
                    <label for="">Publish status</label>
                    <p class="p_text">Publish</p>
                  </div>
                </div>
                <template v-if="type !== 'Automations'">
                  <div class="footer_block">
                    <template v-if="getPublishSelection.status === 'published'">
                      Published on
                      {{
                        getWorkspaceTimeZoneTime(
                          getPublishSelection.planExecutionTime['date'],
                          'MMM DD, hh:mm a',
                        )
                      }}
                    </template>
                    <template
                      v-else-if="getPublishSelection.status === 'failed'"
                    >
                      Failed on this platform
                    </template>
                    <template v-else-if="checkPrimaryMissedReview()">
                      Missed Review on this platform
                    </template>
                    <template
                      v-else-if="getPublishSelection.status === 'rejected'"
                    >
                      Rejected on this platform
                    </template>
                    <template>
                      <div class="input_field mt-0">
                        <div class="radio_input_image">
                          <input
                            id="primaryAccountPostNow"
                            v-model="getBlogSelection.primaryTime.time_type"
                            value="now"
                            type="radio"
                            name="primaryAccountPostNow"
                            :disabled="!hasPermission('can_schedule_plan')"
                          />
                          <label for="primaryAccountPostNow" class="radio_left"
                            >Publish Immediately
                          </label>
                        </div>
                      </div>

                      <div
                        style="min-height: 48px"
                        class="input_field mt-2 d-flex align-items-center justify-between"
                      >
                        <div class="radio_input_image">
                          <input
                            id="primaryAccountSchedule"
                            v-model="getBlogSelection.primaryTime.time_type"
                            value="schedule"
                            type="radio"
                            name="primaryAccountSchedule"
                          />
                          <label for="primaryAccountSchedule" class="radio_left"
                            >Schedule</label
                          >
                        </div>

                        <div
                          v-if="
                            getBlogSelection.primaryTime.time_type === 'schedule'
                          "
                          class="datepicker_style primaryDatePicker_style w-auto "
                        >
                          <DatePicker
                              type="datetime"
                              value-type="format"
                              :format="getAccountDateTimeFormat"
                              :title-format="getAccountDateTimeFormat"
                              input-class="mx-input  !text-[14px] !text-[#3C4549] !px-4"
                              popup-class=" !left-[-15px]"
                              :popup-style="{ boxShadow: '0px 3px 40px rgba(0, 26, 119, 0.08)' }"
                              :default-value="momentWrapper(primaryCalendarPointer).formatDateTime()"
                              :value="momentWrapper(primaryCalendarPointer).formatDateTime()"
                              :clearable="false"
                              :append-to-body="false"
                              :show-time-panel="false"
                              :confirm="true"
                              confirm-text="Apply"
                              :disabled-date="disabledDate"
                              @change="updatePrimaryValues"
                              @click.native="onPrimaryArrowClick"
                              @close="onClosePostDate"
                          >
                            <template v-slot:footer="{ emit, value }">
                              <SelectTime
                                  :hour24="getUserTimePreference==='24h'"
                                  :emit="emit"
                                  :selected-date="value"
                                  :timezone="getWorkspaces.activeWorkspace.timezone"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="
          getBlogSelection.primaryBlog[getBlogSelection.platform_name] &&
          getBlogSelection.primaryBlog[getBlogSelection.platform_name]
            .website &&
          (getBlogSelection.primaryBlog[getBlogSelection.platform_name]
            .status === 'draft' ||
            getBlogSelection.primaryBlog[getBlogSelection.platform_name]
              .published === 'Hidden') &&
          !getBlogSelectionEdit.primary
        "
        class="input_field"
      >
        <div class="warning_box text-center">
          <p
            >Note: Hidden
            {{ startCaseText(getBlogSelection.platform_name) }} posts will not
            be shared on secondary & social media channels.</p
          >
        </div>
      </div>

      <!--v-else-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary"-->
      <div
        v-else-if="!getBlogSelectionEdit.primary"
        class="opt_box basic_form"
        :class="{ 'overlay-area': getBlogSelection.platform_name === '' }"
      >
        <div class="head_text">
          <h3>Secondary Channels</h3>
          <p
            >Secondary channels help in spreading your new blog post to multiple
            channels. Our publishing system takes care of all the necessary
            measures to not affect your SEO such as using rel canonical tag. You
            can select multiple Secondary channels here.</p
          >
        </div>

        <template v-if="getBlogSelection.platform_name">
          <div class="input_field mt-0">
            <div class="d-flex align-items-center">
              <label class="text w-25">Select blog platform</label>
              <BlogSelectionLinks></BlogSelectionLinks>
            </div>
          </div>
          <BlogSelection></BlogSelection>

          <div v-if="checkOptionSelection" class="btn_block mt-4">
            <button
              class="btn btn-studio-theme-space"
              :disabled="fetch_blog_options_loader || isNullSelected"
              @click.prevent="addSecondarySelection"
            >
              <span v-if="getBlogSelectionEdit.secondary">Update</span>
              <span v-else>Add</span>
            </button>
            <button
              class="btn border_btn large_btn border-0 ml-3"
              @click.prevent="cancelEditSecondary"
            >
              <span>Cancel</span>
            </button>
          </div>

          <div v-if="!getBlogSelectionEdit.secondary" class="added_boxes row">
            <template v-for="(blog, index) in getSecondaryBlogSelection" :key="`${index}_blog`">
              <div v-if="blog.selection[blogCategoryName(blog.option)]?.website" class="blog_box_item">
                <div class="inner">
                  <div
                    class="box_head d-flex align-items-center"
                    :class="blogCategoryName(blog.option)"
                  >
                    <div class="h_left">
                      <i
                        :class="
                          configurations[blogCategoryName(blog.option)]
                            ?.background_classes
                        "
                      ></i>
                      <span class="text">{{
                        startCaseText(blogCategoryName(blog.option))
                      }}</span>
                    </div>
                    <div
                      v-if="checkSecondaryEditPermission(blog)"
                      class="h_right"
                    >
                      <i
                        class="icon-edit-cs"
                        @click.prevent="
                          editSecondaryAccount(
                            index,
                            blog.selection[blogCategoryName(blog.option)]
                              ?.website,
                          )
                        "
                      ></i>
                      <i
                        class="cs-cross ml-2"
                        @click.prevent="removeSecondaryAccount(index)"
                      ></i>
                    </div>
                  </div>
                  <div class="blog_content basic_form">
                    <div class="input_field mt-0">
                      <div class="profile_picture">
                        <div class="picture_block">
                          <img
                            :src="
                              getBlogLogo(
                                blogCategoryName(blog.option),
                                blog.selection[blogCategoryName(blog.option)]
                                  ?.website,
                              )
                            "
                            alt=""
                          />
                        </div>
                        <div class="text_block">
                          <p class="text">{{
                            getBlogURL(
                              blogCategoryName(blog.option),
                              blog.selection[blogCategoryName(blog.option)]
                                ?.website,
                            )
                          }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        blog.selection[blogCategoryName(blog.option)]?.author
                      "
                      class="input_field"
                    >
                      <label for="">Author</label>
                      <p
                        v-if="blogCategoryName(blog.option) === 'wordpress'"
                        class="p_text"
                        >{{
                          getWordpressAuthorName(
                            blog.option[blogCategoryName(blog.option)]?.authors,
                            blog.selection[blogCategoryName(blog.option)]?.author,
                          )
                        }}</p
                      >
                      <p v-else class="p_text">{{
                        blog.selection[blogCategoryName(blog.option)]?.author
                      }}</p>
                    </div>
                    <div
                      v-if="
                        blog.selection[blogCategoryName(blog.option)]?.categories
                      "
                      class="input_field"
                    >
                      <label for="">Category</label>
                      <p
                        v-for="(category, categoryIndex) in blog.selection[
                          blogCategoryName(blog.option)
                        ].categories"
                        :key="`categoryIndex_${categoryIndex}`"
                        class="p_text"
                        >{{
                          getWordpressCategoryName(
                            blog.option[blogCategoryName(blog.option)]
                              .categories,
                            category,
                          )
                        }}</p
                      >
                    </div>
                    <div
                      v-if="
                        blog.selection[blogCategoryName(blog.option)].post_tags
                      "
                      class="input_field"
                    >
                      <label for="">Tags</label>
                      <div>
                        <p
                          v-for="(tag, tagIndex) in blog.selection[
                            blogCategoryName(blog.option)
                          ].post_tags"
                          :key="tagIndex"
                          class="p_text d-inline-block mr-1"
                        >
                          {{ tag
                          }}{{
                            tagIndex !==
                            blog.selection[blogCategoryName(blog.option)]
                              .post_tags.length -
                              1
                              ? ', '
                              : ''
                          }}
                        </p>
                      </div>
                    </div>

                    <div
                      v-if="
                        blog.selection[blogCategoryName(blog.option)] &&
                        blog.selection[blogCategoryName(blog.option)].status
                      "
                      class="input_field"
                    >
                      <label for="">Publish status</label>
                      <p class="p_text">{{
                        blog.selection[blogCategoryName(blog.option)].status
                      }}</p>
                    </div>
                    <div
                      v-else-if="
                        blog.selection[blogCategoryName(blog.option)] &&
                        blog.selection[blogCategoryName(blog.option)].published
                      "
                      class="input_field"
                    >
                      <label for="">Publish status</label>
                      <p class="p_text">{{
                        blog.selection[blogCategoryName(blog.option)].published
                      }}</p>
                    </div>
                    <div v-else class="input_field">
                      <label for="">Publish status</label>
                      <p class="p_text">Publish</p>
                    </div>
                  </div>

                  <template v-if="type !== 'Automations'">
                    <div class="footer_block">
                      <template v-if="blog.status === 'published'">
                        Published on
                        {{
                          getWorkspaceTimeZoneTime(
                            blog.execution_time['date'],
                            'MMM DD, hh:mm a',
                          )
                        }}
                      </template>
                      <template v-else-if="blog.status === 'failed'">
                        Failed on this platform
                      </template>
                      <template v-else-if="checkSecondaryMissedReview(blog)">
                        Missed Review on this platform
                      </template>

                      <template v-else-if="blog.status === 'rejected'">
                        Rejected on this platform
                      </template>

                      <template>
                        <div class="input_field mt-0">
                          <div class="radio_input_image">
                            <input
                              :id="
                                getBlogAccountId(blog) +
                                '_secondaryAccountPostNow'
                              "
                              v-model="blog.time.time_type"
                              value="now"
                              type="radio"
                            />
                            <label
                              class="radio_left"
                              :for="
                                getBlogAccountId(blog) +
                                '_secondaryAccountPostNow'
                              "
                              >Publish Immediately after primary
                            </label>
                          </div>
                        </div>

                        <div
                          style="min-height: 48px"
                          class="input_field mt-2 d-flex align-items-center justify-between"
                        >
                          <div class="radio_input_image">
                            <input
                              :id="
                                getBlogAccountId(blog) +
                                '_secondaryAccountSchedule'
                              "
                              v-model="blog.time.time_type"
                              value="schedule"
                              type="radio"
                            />
                            <label
                              class="radio_left"
                              :for="
                                getBlogAccountId(blog) +
                                '_secondaryAccountSchedule'
                              "
                              >Schedule</label
                            >
                          </div>

                          <div
                            :id="
                              getBlogAccountId(blog) +
                              '_secondaryDatePicker_parent'
                            "
                            class="datepicker_style w-auto ml-auto"
                            :class="{
                              hide: blog.time.time_type !== 'schedule',
                            }"
                          >
                            <DatePicker
                                type="datetime"
                                value-type="format"
                                :format="getAccountDateTimeFormat"
                                :title-format="getAccountDateTimeFormat"
                                input-class="mx-input  !text-[14px] !text-[#3C4549] !px-4"
                                popup-class=" !left-[-15px]"
                                :confirm="true"
                                confirm-text="Apply"
                                :popup-style="{ boxShadow: '0px 3px 40px rgba(0, 26, 119, 0.08)' }"
                                :default-value="this.getSecondaryDateValue(index)"
                                :value="this.getSecondaryDateValue(index)"
                                :clearable="false"
                                :append-to-body="false"
                                :show-time-panel="false"
                                :disabled-date="disabledDate"
                                @change="updateSecondaryValues($event, index)"
                                @click.native="onSecondaryArrowClick($event, index)"
                                @close="onCloseSecondaryPostDate(index)"
                            >
                              <template v-slot:footer="{ emit, value }">
                                <SelectTime
                                    :hour24="getUserTimePreference==='24h'"
                                    :emit="emit"
                                    :selected-date="value"
                                    :timezone="getWorkspaces.activeWorkspace.timezone"
                                />
                              </template>
                            </DatePicker>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
        <!--<account-selection :showLabel="true" v-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary" :type="type"></account-selection>-->
      </div>

      <!--/// this box is for social account..........-->
      <!--v-if="getBlogSelection.platform_name != '' && !getBlogSelectionEdit.primary"-->
      <div
        v-if="!getBlogSelectionEdit.primary"
        :class="{ 'overlay-area': getBlogSelection.platform_name === '' }"
        class="opt_box basic_form"
      >
        <div class="head_text">
          <h3>Social Sharing</h3>
        </div>

        <template v-if="type === 'Automations'">
          <AccountSelection
              class="mb-4"
              :show-label="true"
              :type="type"
              :module="'automation'"
              :blog="true"
          ></AccountSelection>
        </template>
        <template v-else>
          <AccountSelection
              class="mb-4"
              :show-label="true"
              :type="type"
              :blog="true"
          ></AccountSelection>
        </template>


        <ReplugSelection class="auto_width"></ReplugSelection>
        <HashtagSelection class="auto_width"></HashtagSelection>

        <div v-if="showVariationsOptions" class="input_field">
          <label for="">Post Variations</label>
          <div class="radio_list">
            <div class="radio_input_image">
              <input
                v-if="type === 'Automations'"
                id="without_post_variations"
                v-model="getArticleAutomationDetails.post_variations"
                type="radio"
                name="variations_type"
                value="post_title"
              />
              <input
                v-else
                id="without_post_variations"
                v-model="getBlogPostingDetails.post_variations"
                type="radio"
                name="variations_type"
                value="post_title"
              />
              <label for="without_post_variations" class="radio_left"
                >Use Post Titles + URL</label
              >
            </div>
            <div class="radio_input_image">
              <input
                v-if="type === 'Automations'"
                id="with_post_variations"
                v-model="getArticleAutomationDetails.post_variations"
                type="radio"
                name="variations_type"
                value="body_content"
              />
              <input
                v-else
                id="with_post_variations"
                v-model="getBlogPostingDetails.post_variations"
                type="radio"
                name="variations_type"
                value="body_content"
              />
              <label for="with_post_variations" class="radio_left"
                >Use intelligent post variation (extracted from body content) as
                caption</label
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {blogPosting} from '@src/modules/publish/store/states/mutation-types'
import {getPlatformByIdentifier} from '@common/lib/integrations'
import {COMPOSER_BLOG_PRIMARY_ALREADY_EXIST, COMPOSER_BLOG_SECONDARY_ALREADY_EXIST,} from '@common/constants/messages'
import {blogIntegrationsConfigrations, blogIntegrationsNames} from '@src/modules/integration/config/api-utils.js'
import AccountSelection from '@src/modules/publish/components/posting/social/AccountSelection.vue'
import HashtagSelection from '@src/modules/automation/components/Selection/HashtagSelection.vue'
import ReplugSelection from '@src/modules/automation/components/Selection/ReplugSelection.vue'
import DatePicker from 'vue2-datepicker'
import SelectTime from "@src/modules/composer_v2/components/PostingSchedule/SelectTime.vue";
import useDateFormat from "@common/composables/useDateFormat";
import {useComposerHelper} from "@src/modules/composer_v2/composables/useComposerHelper";
import BlogSelection from './BlogSelection'
import BlogSelectionLinks from './BlogSelectionLinks'

export default {
  components: {
    SelectTime,
    AccountSelection,
    BlogSelection,
    BlogSelectionLinks,
    HashtagSelection,
    ReplugSelection,
    DatePicker,
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    noModel: {
      type: Boolean,
      default: false
    },
    variationsOptions: {
      type: Boolean,
      default: false
    },
  },
  setup(){
    const {changeCalendarState} = useComposerHelper()
    const {momentWrapper,getAccountDateTimeFormat,getUserTimePreference} = useDateFormat()
    return {
      changeCalendarState,
      momentWrapper,
      getAccountDateTimeFormat,
      getUserTimePreference
    }
  },
  data () {
    return {
      integrations: blogIntegrationsNames,
      configurations: blogIntegrationsConfigrations,
      locale: {
        format: this.getAccountDateTimeFormat // format of the dates displayed
      },

      opens: 'center',
      startDate: this.momentWrapper('\'2017-09-19\'').formatDate(),
      endDate: this.momentWrapper('2017-10-09').formatDate(),
      minDate: this.momentWrapper('2016-09-02').formatDate(),
      maxDate:this.momentWrapper('2019-10-02').formatDate() ,
      dateRange: {
        startDate: this.getPrimaryMinDate
      },
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      fetch_blog_options_loader: false,
      primaryCalendarPointer: this.getPrimaryStartDate(),
      secondaryCalendarPointers:[],
    }
  },
  computed: {
    ...mapGetters([
      'getBlogOptions',
      'getBlogSelection',
      'getBlogSelectionEdit',
      'getPublishSelection',
      'getSecondaryBlogSelection',
      'getWorkspaces',
      'getArticleAutomationDetails'
    ]),
    isNullSelected (flag = true) {
      console.log(
          'BlogDistribute :: getBlogOptions -----> ',
          this.getBlogOptions
      )
      console.log(
          'BlogDistribute :: getSingleBlogSelection -----> ',
          this.getSingleBlogSelection
      )
      if (flag) {
        if (
            this.getBlogOptions.wordpress.selection === true &&
            this.getSingleBlogSelection.wordpress.website === ''
        ) {
          return true
        }
        if (
            this.getBlogOptions.tumblr === true &&
            this.getSingleBlogSelection.tumblr.website === ''
        ) {
          return true
        }
        if (
            this.getBlogOptions.medium === true &&
            this.getSingleBlogSelection.medium.website === ''
        ) {
          return true
        }
        if (
            this.getBlogOptions.shopify.selection === true &&
            this.getSingleBlogSelection.shopify.website === ''
        ) {
          return true
        }
        if (
            this.getBlogOptions.webflow === true &&
            (this.getSingleBlogSelection.webflow.website === '' ||
                this.getSingleBlogSelection.webflow.collection === null)
        ) {
          return true
        }

        return (
            this.getBlogOptions.wordpress.selection === false &&
            this.getBlogOptions.tumblr === false &&
            this.getBlogOptions.medium === false &&
            this.getBlogOptions.shopify.selection === false &&
            this.getBlogOptions.webflow === false
        )
      }
    },
    getPrimaryMinDate () {
      const minDate = this.momentWrapper()
          .formatTimezone()
          .add(1, 'hours')
      if (this.getBlogSelection.primaryTime.postSelectedDate === '') {
        this.$store.commit(
            blogPosting.SET_PRIMARY_BLOG_DATE,
            minDate.format('YYYY-MM-DD HH:mm:ss')
        )
      }
      return minDate.format('MMM DD, YYYY HH:mm')
    },
    checkOptionSelection () {
      let selection = false
      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
            Object.hasOwn(options[integration], 'selection') &&
            options[integration].selection
        ) {
          selection = true
          return
        }
        if (
            !Object.hasOwn(options[integration], 'selection') &&
            options[integration]
        ) {
          selection = true
        }
      })
      return selection
    },
    isWorkspaceOnHold () {
      return !!(
          this.getWorkspaces.activeWorkspace &&
          this.getWorkspaces.activeWorkspace.on_hold
      )
    },
    showVariationsOptions () {
      return this.variationsOptions
    },
    getSecondaryDateValue() {
      return (index) => {
        const date = this.secondaryCalendarPointers[index] || this.startDateSecondary(index);
        return this.momentWrapper(date).formatDateTime();
      };
    }
  },
  watch: {
    'getBlogSelection.primaryTime.time_type' (type) {
      if (type === 'schedule') {
        this.showBlogCurationPrimaryDatePickers()
      }
      this.initializeSecondaryDatePickers(true)
    },
    'getBlogSelection.primaryTime.postSelectedDate' () {
      this.primaryCalendarPointer = this.getPrimaryStartDate()
      this.initializeSecondaryDatePickers(true)
    },
    'getSecondaryBlogSelection.length' () {
      this.initializeSecondaryDatePickers()
    }
  },
  created () {
    if (this.getPublishSelection.plan_id) {
      this.showBlogCurationPrimaryDatePickers()
      this.initializeSecondaryDatePickers()
    }

    this.primaryCalendarPointer = this.getPrimaryStartDate();

    this.secondaryCalendarPointers = this.getSecondaryBlogSelection.map((_,index) => {
      return this.startDateSecondary(index)
    })
  },
  methods: {
    startCaseText (text) {
      return _.startCase(text)
    },

    updatePrimaryValues (changedDate) {
      const myDate = this.momentWrapper(changedDate)
      this.$store.commit(
        blogPosting.SET_PRIMARY_BLOG_DATE,
        myDate.format('YYYY-MM-DD HH:mm:ss')
      )
      const stateObject = this
      this.getSecondaryBlogSelection.forEach(function (element, index) {
        if (element.time.time_type === 'schedule') {
          stateObject.getSecondaryBlogSelection[index].time.postSelectedDate =
            ''
        }
      })
    },

    updateSecondaryValues (values, index) {
      const myDate = this.momentWrapper(values)
      this.getSecondaryBlogSelection[index].time.postSelectedDate =
        myDate.format('YYYY-MM-DD HH:mm:ss')
      return values
    },
    startDateSecondary (index) {
      let startDate = ''
      if (this.getSecondaryBlogSelection[index].time.postSelectedDate === '') {
        if (this.getBlogSelection.primaryTime.time_type === 'now') {
           startDate = this.momentWrapper()
               .formatTimezone()
            .add(1, 'hours')
        } else {
          startDate = this.momentWrapper(
              this.getBlogSelection.primaryTime.postSelectedDate,
          ).format('YYYY-MM-DD HH:mm:ss')
              .add(1, 'hours')
        }
        return startDate.formatDateTime()
      } else {
        return this.getSecondaryBlogSelection[index].time.postSelectedDate
      }
    },
    getSecondaryMinDate (index) {
      let minDate = null
      if (this.getBlogSelection.primaryTime.time_type === 'now') {
        minDate = this.momentWrapper()
            .formatTimezone()
          .add(1, 'hours')
      } else {
        minDate = this.momentWrapper(
          this.getBlogSelection.primaryTime.postSelectedDate,
          'YYYY-MM-DD HH:mm:ss'
        ).add(1, 'hours')
      }

      if (this.getSecondaryBlogSelection[index]?.time?.postSelectedDate === '') {
        this.getSecondaryBlogSelection[index].time.postSelectedDate =
          minDate.formatDateTime()
      }
      return minDate.formatDateTime()
    },

    checkSecondaryMissedReview (blog) {
      return blog.status === 'review' &&
          blog.execution_time.date < this.momentWrapper().formatDateTime();

    },
    checkPrimaryEditPermission () {
      // return !(this.getPublishSelection.status === 'published' ||
      //     this.getPublishSelection.status === 'failed' ||
      //     this.getPublishSelection.status === 'rejected' ||
      //     this.checkPrimaryMissedReview());
      return !this.checkPrimaryMissedReview();
    },
    checkSecondaryEditPermission (blog) {
      return !(blog.status === 'published' ||
          blog.status === 'failed' ||
          this.checkSecondaryMissedReview(blog) ||
          blog.status === 'rejected');

    },
    getBlogLogo (type, platform) {
      console.debug('Method::getBlogLogo', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_logo : ''
    },

    getBlogURL (type, platform) {
      console.debug('Method::getBlogURL', type, platform)
      platform = getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        platform
      )
      if (platform) return platform ? platform.platform_url : ''
    },
    async addPrimarySelection () {
      console.debug('Method:addPrimarySelection')
      if (!this.blogSelectionValidation()) return false
      const alreadySecondaryExistStatus =
        this.checkSecondaryBlogAccountAlreadyExist()
      if (alreadySecondaryExistStatus) {
        return this.alertMessageReturn(alreadySecondaryExistStatus, 'error')
      }

      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
            Object.hasOwn(options[integration], 'selection') &&
          options[integration].selection
        ) {
          this.$store.commit(
            blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME,
            integration
          )
          return
        }
        if (
          !Object.hasOwn(options[integration], 'selection') &&
          options[integration]
        ) {
          this.$store.commit(
            blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME,
            integration
          )
        }
      })

      const blogSelection = await this.$store.dispatch(
        'removeInvalidSelection',
        {
          selection: this.getSingleBlogSelection,
          selected_type: this.getBlogSelection.platform_name
        }
      )
      console.log('Method:addPrimarySelection ~ blogSelection', blogSelection)
      this.$store.commit(
        blogPosting.SET_PRIMARY_BLOG_OPTION,
        JSON.parse(JSON.stringify(this.getBlogOptions))
      )
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_SELECTION, blogSelection)
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_TIME, null)
      this.getBlogSelectionEdit.primary = false
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    async addSecondarySelection () {
      console.debug('Method:addSecondarySelection',  JSON.parse(JSON.stringify(this.getSingleBlogSelection)))
      if (!this.blogSelectionValidation()) return false
      const secondaryBlog = {
        option: JSON.parse(JSON.stringify(this.getBlogOptions)),
        selection: JSON.parse(JSON.stringify(this.getSingleBlogSelection)),
        time: {
          time_type: 'now',
          postSelectedDate: ''
        }
      }
      // checking account already exist as primary
      const alreadyExistStatus =
        this.checkBlogAccountAlreadyExist(secondaryBlog)
      if (alreadyExistStatus) {
        return this.alertMessageReturn(alreadyExistStatus, 'error')
      }

      // checking account already exist as secondary
      const alreadySecondaryExistStatus =
        this.checkSecondaryBlogAccountAlreadyExist()
      if (alreadySecondaryExistStatus) {
        return this.alertMessageReturn(alreadySecondaryExistStatus, 'error')
      }

      let selectedType = ''
      const options = this.getBlogOptions
      blogIntegrationsNames.forEach((integration) => {
        if (
            Object.hasOwn(options[integration], 'selection') &&
          options[integration].selection
        ) {
          selectedType = integration
          return
        }
        if (
          !Object.hasOwn(options[integration], 'selection') &&
          options[integration]
        ) {
          selectedType = integration
        }
      })

      // secondaryBlog.selection = await this.$store.dispatch(
      //     'removeInvalidSelection',
      //     {
      //       selection: this.jsonStringifyData(secondaryBlog.selection),
      //       selectedType
      //     }
      // )

      if (this.getBlogSelectionEdit.secondary === true) {
        // removing existing blog before adding new
        const secondaryBlogsIDs = this.getBlogSelection.secondaryAccountsId
        if (
          this.getBlogSelection.secondaryAccountsId.indexOf(
            this.getBlogSelectionEdit.secondaryAccountId
          ) !== -1
        ) {
          secondaryBlogsIDs.splice(
            this.getBlogSelection.secondaryAccountsId.indexOf(
              this.getBlogSelectionEdit.secondaryAccountId
            ),
            1
          )
          this.$store.commit(
            blogPosting.SET_SECONDARY_BLOG_IDS,
            secondaryBlogsIDs
          )
        }

        // adding blog in editing index
        const addedBlogs = this.getSecondaryBlogSelection
        addedBlogs[this.getBlogSelectionEdit.secondaryIndex] = secondaryBlog
        this.$store.commit(
          blogPosting.SET_SECONDARY_BLOG_SELECTION,
          JSON.parse(JSON.stringify(addedBlogs))
        )
        this.initializeSecondaryDatePickers()

        // adding new added blog in sendondary ids maintained array
        this.secondaryAccountId('add', secondaryBlog)
        this.cancelEditSecondary()
        this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
        return
      }
      this.$store.commit(
        blogPosting.SET_SECONDARY_BLOG_SELECTION,
        this.getSecondaryBlogSelection.concat(secondaryBlog)
      )
      this.secondaryAccountId('add', secondaryBlog)
      this.cancelEditSecondary()
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
      this.secondaryCalendarPointers.push(this.startDateSecondary(this.getBlogSelection?.secondaryBlog?.length-1))
    },

    cancelEditSecondary () {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    removeSecondaryAccount (index) {
      const secondaryBlogs = this.getSecondaryBlogSelection
      this.secondaryAccountId('remove', secondaryBlogs[index])
      secondaryBlogs.splice(index, 1)
      this.$store.commit(
        blogPosting.SET_SECONDARY_BLOG_SELECTION,
        secondaryBlogs
      )
      this.secondaryCalendarPointers.splice(index, 1)
    },

    cancelEditPrimary () {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    editPrimaryAccount () {
      console.debug('Method:editPrimaryAccount', this.getBlogSelection)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.getBlogSelectionEdit.primary = true
      this.$store.commit(
        blogPosting.SET_BLOG_OPTIONS,
        JSON.parse(JSON.stringify(this.getBlogSelection.primaryBlogOption))
      )
      this.$store.commit(
        blogPosting.SET_BLOG_SELECTION,
        JSON.parse(JSON.stringify(this.getBlogSelection.primaryBlog))
      )
    },

    removePrimaryAccount () {
      this.$store.commit(blogPosting.RESET_PRIMARY_BLOG_SELECTION)
      this.$store.commit(blogPosting.SET_PRIMARY_BLOG_PLATFORM_NAME, '')
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
    },

    editSecondaryAccount (index, accountId) {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION_EDIT_STATES)
      this.getBlogSelectionEdit.secondary = true
      this.getBlogSelectionEdit.secondaryIndex = index
      this.getBlogSelectionEdit.secondaryAccountId = accountId
      this.$store.commit(
        blogPosting.SET_BLOG_OPTIONS,
        JSON.parse(JSON.stringify(this.getSecondaryBlogSelection[index].option))
      )
      this.$store.commit(
        blogPosting.SET_BLOG_SELECTION,
        JSON.parse(
          JSON.stringify(this.getSecondaryBlogSelection[index].selection)
        )
      )
    },

    checkSecondaryBlogAccountAlreadyExist () {
      const type = this.blogCategoryName(this.getBlogOptions)
      if (type) {
        if (
          this.getBlogSelection.secondaryAccountsId.indexOf(
            this.getSingleBlogSelection[type].website
          ) !== -1 &&
          (!this.getBlogSelectionEdit.secondary ||
            this.getBlogSelectionEdit.secondaryAccountId !==
              this.getSingleBlogSelection[type].website)
        ) {
          return COMPOSER_BLOG_SECONDARY_ALREADY_EXIST
        }
      }
      return false
    },

    checkBlogAccountAlreadyExist (blog) {
      console.debug('Method:checkBlogAccountAlreadyExist', blog)
      const type = this.blogCategoryName(blog.option)
      if (type) {
        const primaryType = this.blogCategoryName(
          this.getBlogSelection.primaryBlogOption
        )
        if (
          primaryType === type &&
          blog.selection[type].website ===
            this.getBlogSelection.primaryBlog[type].website
        ) {
          return COMPOSER_BLOG_PRIMARY_ALREADY_EXIST
        }
      }
      return false
    },

    initializeSecondaryDatePickers (defaultState = false) {
      const stateObject = this
      this.getSecondaryBlogSelection.forEach(function (element, index) {
        if (defaultState && element.time.time_type === 'schedule') {
          stateObject.getSecondaryBlogSelection[index].time.postSelectedDate =
            ''
        }
        stateObject.showBlogCurationSecondaryDatePickers(
          stateObject.getBlogAccountId(element) + '_secondaryDatePicker',
          index
        )

      })
      this.secondaryCalendarPointers = this.getSecondaryBlogSelection.map((_,index) => {
        return this.startDateSecondary(index)
      })
    },

    checkPrimaryMissedReview () {
      return !!(
        this.getPublishSelection.status === 'review' &&
        this.getPublishSelection.planExecutionTime.date <
          this.momentWrapper().formatDateTime()
      )
    },
    getBlogAccountId (blog) {
      const selectedBlog = this.blogCategoryName(blog.option)
      console.debug('selectedBlog', selectedBlog, blog.selection)
      return this.integrations.indexOf(selectedBlog) >= 0
        ? blog.selection[selectedBlog].website
        : null
    },
    secondaryAccountId (type, blog) {
      console.debug(blog)
      const selectedBlog = this.blogCategoryName(blog.option)
      const websiteId =
        this.integrations.indexOf(selectedBlog) >= 0
          ? blog.selection[selectedBlog].website
          : null
      if (websiteId) {
        if (type === 'add') {
          if (
            this.getBlogSelection.secondaryAccountsId.indexOf(websiteId) === -1
          ) {
            this.$store.commit(
              blogPosting.SET_SECONDARY_BLOG_IDS,
              this.getBlogSelection.secondaryAccountsId.concat(websiteId)
            )
          }
        } else if (type === 'remove') {
          const secondaryBlogsIDs = this.getBlogSelection.secondaryAccountsId
          if (
            this.getBlogSelection.secondaryAccountsId.indexOf(websiteId) !== -1
          ) {
            secondaryBlogsIDs.splice(
              this.getBlogSelection.secondaryAccountsId.indexOf(websiteId),
              1
            )
            this.$store.commit(
              blogPosting.SET_SECONDARY_BLOG_IDS,
              secondaryBlogsIDs
            )
          }
        }
      }
    },

    blogCategoryName (options) {
      console.debug('Method::blogCategoryName', options)
      let selection = ''
      blogIntegrationsNames.forEach((integration) => {
        if (
            Object.hasOwn(options[integration], 'selection') &&
          options[integration].selection
        ) {
          selection = integration
          return
        }
        if (
          !Object.hasOwn(options[integration], 'selection') &&
          options[integration]
        ) {
          selection = integration
        }
      })
      return selection
    },
    onPrimaryArrowClick(event){
      this.primaryCalendarPointer = this.changeCalendarState(event,this.primaryCalendarPointer)
    },
    onClosePostDate(){
      this.primaryCalendarPointer = this.getPrimaryStartDate()
    },
    onSecondaryArrowClick(event,index){
      this.secondaryCalendarPointers[index] = this.changeCalendarState(event,this.secondaryCalendarPointers[index])
    },
    onCloseSecondaryPostDate(index){
      this.secondaryCalendarPointers[index] = this.startDateSecondary(index)
    },
    getPrimaryStartDate () {
      if (this.getBlogSelection?.primaryTime?.postSelectedDate) {
        return this.momentWrapper(
            this.getBlogSelection.primaryTime.postSelectedDate
        ).formatDateTime()
      }
      const minDate = this.momentWrapper()
          .formatTimezone()
          .add(1, 'hours')
      return minDate.formatDateTime()

    },
  },
}
</script>
