<script>
import { mapGetters } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import { Crisp } from 'crisp-sdk-web'
import { baseUrl, refreshTokenURL } from './config/api-utils'

export default {
  components: {
    // GetStarted,
  },
  /**
   * @legacy This is a legacy method and will probably be changed in vue3 update
   * @returns {{root: NeverFallback<Instance, Vue>}}
   */
  provide() {
    return {
      root: this.$root,
    }
  },
  data() {
    return {
      showUpdateUI: false,
      image: require('@assets/img/logo/logo-animation.gif'),
    }
  },
  computed: {
    ...mapGetters(['getJWTToken', 'getProfile', 'getPlan']),
    /**
     * checking application is loaded or not
     **/
    checkLoadedOnce() {
      return this.$store.getters.getLoadedOnce
    },
  },
  watch: {
    '$route.name'() {
      this.$bvModal.hide('media-storage-limits-exceeded-modal')
      this.$bvModal.hide('increase-limits-dialog')
    },
    'getPlan'() {
      if (this.getPlan.subscription && this.getPlan.subscription.display_name) {
        Crisp.session.setData({
          phone_number:this.getProfile?.phone_no,
          fastspring_name: this.getPlan?.subscription?.fastspring_name,
          business_type: this.getProfile?.business_type,
          plan_display: this.getPlan?.subscription?.display_name,
          count_social_accounts: this.getPlan?.used_limits?.profiles,
          count_blogs_accounts: this.getPlan?.used_limits?.blogs,
          count_team_members: this.getPlan?.used_limits?.members,
          count_workspaces: this.getPlan?.used_limits?.workspaces,
          count_custom_topics: this.getPlan?.used_limits?.topics,
        })
      }
    },
    'getJWTToken'() {
      this.setCrispUserDetails()
    },
    'getProfile'(){
      this.setCrispUserDetails()
    }
    // 'getWorkspaces.onboardingSteps.watch_video'() {
    //   if (!this.getWorkspaces.activeWorkspace.onboarding && this.getActiveWorkspace.onboarding_steps.watch_video === false
    //       && this.$route.name !== 'onboardingWorkspace') {
    //     this.$bvModal.show('get-started-modal')
    //   }
    // }
  },
  created() {
    this.initUserMaven()
  },
  mounted() {
      Crisp.chat.hide()
      this.setCrispUserDetails()

    // calling the endpoint for the refresh token. The refresh token will be updated once we call it.
    setInterval(() => {
      console.debug('In Set Interval method...')
      if (this.getJWTToken && this.getJWTToken !== 'null') {
        this.postRequest(
          refreshTokenURL,
          { token: this.getJWTToken },
          (response) => {
            this.$store.commit(
              authenticationTypes.SET_JWT_TOKEN,
              response.data.token
            )
          },
          (error) => {
            console.debug(error)
            // set the token to expire
            if (error.response && error.response.status === 401) {
              this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
              window.location.href = baseUrl + 'logout'
            }
          }
        )
      }
    }, 5 * 60 * 1000) // 5 minutes interval
  },
  updated() {
      Crisp.chat.hide()
  },
    methods: {
    async update() {
      this.showUpdateUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },
      /**
       * triggering crisp event on profile or jwt change
       */
      setCrispUserDetails() {
          if (this.getJWTToken && this.getJWTToken !== 'null' && this.getProfile?.email) {
              Crisp.user.setEmail(this.getProfile.email)
              if(this.getProfile.phone_no) Crisp.user.setPhone(this.getProfile.phone_no)
              Crisp.user.setNickname(this.getProfile.full_name)

          }
      }
  },
}
</script>

<template>
  <div class="app_container">
    <div v-if="showUpdateUI" class="update-dialog">
      <div class="update-dialog__content">
        A new version is found. Refresh to load it?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Update
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="showUpdateUI = false"
        >
          Cancel
        </button>
      </div>
    </div>

    <template v-if="checkLoadedOnce">
      <router-view />
    </template>
    <div
      v-if="!checkLoadedOnce"
      class="loading-logo flex justify-center items-center"
    >
      <img style="width: 50px" :src="image" alt="ContentStudio" />
    </div>
  </div>
</template>

<style lang="scss">
@import 'assets/sass/bootstrap-core';
</style>

<style lang="less">
@import './assets/less/main.less';
</style>

<style lang="scss">
@import 'assets/sass/main.scss';

@import 'assets/tailwind.css';

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}

.square-aspect-ratio {
  aspect-ratio: 1;
}

</style>
