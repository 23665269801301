<template>
  <div class="grid-flex">
    <div class="cell-md-7 mx-auto">
      <div class="basic_form">
        <div class="field_group">
          <input
            id="w-name"
            v-model="getWorkspaceDetails.name"
            data-cy="workspace_name"
            type="text"
            placeholder="Workspace Name - e.g. Nike"
            maxlength="35"
            @keydown="fetchWorkspaceLogoSuggessions($event)"
          />
          <label for="w-name" class="label-animated">Workspace Name - e.g. Nike</label>
        </div>


        <div class="field_group">
          <div
            id="timezone-dropdown"
            class="timezone_search_dropdown social_dropdown dropdown default_style_dropdown"
          >
            <div
              data-toggle="dropdown"
              class="dropdown_header d-flex align-items-center"
              data-cy="create_workspace_timezone_clickable"
            >
              <div class="text d-inline-block align-middle">{{
                getWorkspaceDetails.timezone
              }}</div>
              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>
            <div
              class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
              data-cy="create_workspace_timezone"
            >
              <ul class="inner">
                <div class="search_input with_icon_right">
                  <div class="search_inner w-100">
                    <input
                      id="search_timezone"
                      v-model="search_timezone"
                      type="text"
                      placeholder="Search by name"
                      data-cy="search-timezone"
                    />
                  </div>
                </div>

                <template v-if="getFilteredTimezones.length">
                  <template v-for="(timezone, key) in getFilteredTimezones" :key="key">
                    <li
                      class="list_item_li"
                      data-cy="timezone-selection"
                      @click.prevent="changeTimezone(timezone.value)"
                    >
                      {{ timezone.name }}
                    </li>
                  </template>
                </template>

                <li v-if="!getFilteredTimezones.length" class="no_text_li">
                  No Results found
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="input_field col-md-4" style="text-align: left">
            <label style="margin-left: 0.3rem">Workspace Logo</label>
            <div class="upload_logo">
              <div class="image_upload">
                <div
                  class="upload_section"
                  :class="{
                    loader_overlay_with_loader:
                      getSettingLoaders.uploadWorkspaceLogo,
                  }"
                >
                  <!--// for real dummy image -->
                  <div v-if="!getWorkspaceDetails.logo" class="img">
                    <img
                      class="picture"
                      src="../../../assets/img/settings/leaf_upload_image.svg"
                      alt=""
                    />
                  </div>
                  <!--// for real image -->
                  <div v-else class="img">
                    <img
                      class="picture"
                      :src="getWorkspaceDetails.logo"
                      alt=""
                    />
                  </div>

                  <clip-loader
                    v-if="getSettingLoaders.uploadWorkspaceLogo"
                    class="center_loader"
                    color="#436aff"
                    :size="'16px'"
                  ></clip-loader>
                  <div
                    v-else
                    data-cy="workspace_logo"
                    :class="{ image_default: !getWorkspaceDetails.logo }"
                    class="upload_btn_block"
                    @click="triggerWorkspaceChangeLogo('w_logo_image')"
                  >
                    <input
                      id="w_logo_image"
                      type="file"
                      autocomplete="off"
                      accept="image/*"
                      @change.prevent="uploadWorkspaceLogo"
                    />
                    <i class="icon-Camera"></i>
                    <p v-if="!getWorkspaceDetails.logo">Upload</p>
                    <p v-else>Change</p>
                  </div>
                </div>
                <p style="text-align: center"
                  >Recommended size: Less than 2MB (130px x 130px)</p
                >
              </div>
            </div>
          </div>
          <div
            v-if="
              getWorkspaceDetails.logoSuggestions.length ||
              getWorkspaceLogoSuggestionsLoader
            "
            class="input_field col-md-8"
          >
            <label>Logo Suggestions</label>
            <div class="clearfix"></div>
            <div
              class="workspace_logo_suggestions"
              data-cy="workspace_logo_suggestions"
            >
              <div class="logo_suggestions_inner">
                <ul>
                  <li
                    v-if="getWorkspaceLogoSuggestionsLoader"
                    class="loader_overlay_with_loader"
                  >
                    <clip-loader
                      :color="'#5c77f8'"
                      :size="'16px'"
                    ></clip-loader>
                  </li>
                  <li
                    v-for="(
                      suggestion, index
                    ) in getWorkspaceDetails.logoSuggestions"
                    v-else
                    :key="index"
                    class=""
                    data-cy="workspace_logo_suggestion"
                    @click="SET_WORKSPACE_LOGO(suggestion.logo)"
                  >
                    <img :src="suggestion.logo" alt="" />
                  </li>
                </ul>
              </div>

              <p v-if="getWorkspaceLogoSuggestionsLoader === false" class="mt-2"
                >Click on one of the logos above to set as the Workspace
                logo.</p
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { timezones } from '@src/modules/setting/config/timezone.js'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'

export default {
  components: {},

  data () {
    return {
      timezones,
      slugError: false,
      manuallySlugUpdated: false,
      search_timezone: ''
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaceDetails',
      'getSettingLoaders',
      'getWorkspaceLogoSuggestionsLoader',
      'getSlugAvailableLoader',
      'getWorkspaceSlugValidation'
    ]),
    getFilteredTimezones () {
      return this.timezones.filter((s) =>
        s.value.toLowerCase().includes(this.search_timezone.toLowerCase())
      )
    }
  },

  created () {},
  mounted () {
    this.fetchWorkspaceLogoSuggessions('social media')
    if (!this.getWorkspaceDetails.name && !this.getWorkspaceDetails.slug) {
      this.$store.commit(
        workspaceTypes.SET_WORKSPACE_TIMEZONE,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    }
    // eslint-disable-next-line no-undef
    const self = this
    // eslint-disable-next-line no-undef
    $('#timezone-dropdown').on('hidden.bs.dropdown', function () {
      // do something…

      self.search_timezone = ''
    })
  },

  methods: {
    ...mapMutations([workspaceTypes.SET_WORKSPACE_LOGO]),
    slugUpdated () {
      this.manuallySlugUpdated = true
    },
    changeTimezone (timezone) {
      console.debug('Method::changeTimezone', timezone)
      this.getWorkspaceDetails.timezone = timezone
      this.search_timezone = ''
    }
  },
  watch: {
    'getWorkspaceDetails.name' (value) {
      // if the length of the slug has been stripped down to 0.
      if (this.getWorkspaceDetails.slug.length === 0) {
        this.manuallySlugUpdated = false
      }

      if (value && this.manuallySlugUpdated === false) {
        this.$store.commit(
          workspaceTypes.SET_WORKSPACE_SLUG,
          value.toLowerCase().replace(/\s+/g, '-')
        )
      }
    },
    'getWorkspaceDetails.slug' (value) {
      if (value.length === 0) {
        this.$store.commit(workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION, '')
        return
      }
      const regexp = /^[A-Za-z0-9-]+$/i

      if (!value.match(regexp)) {
        this.$store.commit(
          workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION,
          'invalid'
        )
      } else {
        this.$store.commit(workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION, '')
      }
    }
  }
}
</script>
