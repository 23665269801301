<template>
  <div
    v-click-away="closeSearchHistoryDropdown"
    class="dropdown-search-suggestions article-search-bar-pane__search__input__history"
  >
    <template v-if="getSearchKeywords.length > 0">
      <div
        class="search_dropdown_inner"
        :class="{ 'pb-1': !getShowOperatorsSuggestions }"
      >
        <div
          class="article-search-bar-pane__search__input__history__recent_searches"
        >
          <div class="text"> Recent Searches </div>
          <div class="clear_all_action" @click="clearAllSearchHistoryAction">
            Clear All
          </div>
        </div>
        <ul
          v-if="getSearchKeywords.length > 0"
          class="search_history_item_element article-search-bar-pane__search__input__history__item"
        >
          <!--<li class="head">Related Keywords</li>-->
            <template
                    v-for="(value, index) in getSearchKeywords"
            >
                <li
                        v-if="index <= 4"
                        class="item"
                        :class="{
                active:
                  search_key_input.selector === 'search_history' &&
                  value === search_key_input.key,
              }"
                >
              <span
                      class="li_text"
                      @click="routeSearchQueryFromSuggestion(value.toLowerCase())"
              >
                <i class="far fa-clock"></i>{{ value }}
              </span>
                    <span
                            v-tooltip="'Remove'"
                            class="li_action"
                            @click="clearIndividualSearchHistoryAction(value, index)"
                    >
                <i class="far fa-times"></i>
              </span>
                </li>
            </template>
        </ul>
        <div
          v-if="getShowOperatorsSuggestions"
          class="article-search-bar-pane__search__input__history__operators"
        >
          <h5 @click="show_search_operators = !show_search_operators"
            ><i
              class="fas fa-caret-right"
              :class="{ 'carret-rotate-animation': show_search_operators }"
            ></i
            >Search Operators</h5
          >
          <transition name="slide-fade">
            <ArticleSearchOperatorsList
              v-if="show_search_operators"
            ></ArticleSearchOperatorsList>
          </transition>
        </div>
        <div
          v-if="
            $route.meta.is_active && $route.meta.is_active === 'twitter_tweets'
          "
          class="article-search-bar-pane__search__input__history__operators"
        >
          <h5
            @click="
              show_twitter_search_operators = !show_twitter_search_operators
            "
            ><i
              class="fas fa-caret-right"
              :class="{
                'carret-rotate-animation': show_twitter_search_operators,
              }"
            ></i
            >Search Operators</h5
          >
          <transition name="slide-fade">
            <TwitterSearchOperatorsList
              v-if="show_twitter_search_operators"
            ></TwitterSearchOperatorsList>
          </transition>
        </div>
      </div>
    </template>

    <template
      v-if="getSearchKeywords.length === 0 && getShowOperatorsSuggestions"
    >
      <ArticleSearchOperatorsList></ArticleSearchOperatorsList>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import TwitterSearchOperatorsList from '../twitter/TwitterSearchOperatorsList'
import ArticleSearchOperatorsList from './ArticleSearchOperatorsList'

export default {
  components: {
    ArticleSearchOperatorsList,
    TwitterSearchOperatorsList
  },
  props: {
    keywords: {},
    search_key_input: {}
  },
  data () {
    return {
      show_search_operators: false,
      show_twitter_search_operators: false
    }
  },
  computed: {
    ...mapGetters([]),
    getSearchKeywords () {
      return this.keywords
    },
    getShowOperatorsSuggestions () {
      return (
        this.$route.meta.is_active &&
        ['content', 'insights'].indexOf(this.$route.meta.is_active) >= 0
      )
    }
  },
  methods: {
    closeSearchHistoryDropdown () {
      EventBus.$emit('show-search-history')
    },

    routeSearchQueryFromSuggestion (value) {
      if (
        this.$route.name === 'discover_search' ||
        this.$route.name === 'discover_insights'
      ) {
        return this.$router.push({
          name: `${this.$route.name}_web`,
          query: { q: value }
        })
      } else {
        this.$router.replace({ query: { q: value.toLowerCase() } })
      }
    },
    async clearIndividualSearchHistoryAction (value, index) {
      const res = await this.$store.dispatch('deleteIndividualSearchHistory', {
        query: value
      })
      if (res && res.data.status) {
        EventBus.$emit('clear-individual-search-history', {
          query: value,
          index
        })
      }
    },
    async clearAllSearchHistoryAction () {
      const res = await this.$store.dispatch('deleteAllSearchHistory')
      if (res && res.data.status) {
        EventBus.$emit('clear-search-history')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@ds-darkGray: #4a4a4a; // article social stats text color
@ds-lightGray: #989eb5;
@ds-lightGray_3: #72778a; // social tab icon background // search input border color
</style>
