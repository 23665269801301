<script setup>
import { computed } from 'vue'

const props = defineProps({
  path: {
    type: String,
    default: '',
  },
  activeSuffix: {
    type: String,
    default: '-active',
  },
  active: {
    type: Boolean,
    default: false,
  },
  alt: {
    type: String,
    default: 'Image icon',
  },
})

const src = computed(() => {
  const fileName = props.active
    ? `${props.path.replace(/(\.[^.]+)$/, `${props.activeSuffix}$1`)}`
    : props.path

  return require(`@assets/img/icons/planner/grid-view/${fileName}`)
})
</script>

<template>
  <img
    v-if="props.path !== ''"
    :src="src"
    :alt="alt"
    class="w-[1vw] h-[1vw]"
  />
</template>
