<template>
  <div class="input_field">
    <div class="text-center warning_box" data-cy="queue-note">
        <p
          ><strong>Note:</strong>
          There will be a separate post for each social account according to the
          next time-slot available in the respective account's queue.
          <a
            href="https://docs.contentstudio.io/article/691-what-are-social-queues"
            target="_blank"
            class="insta-link"
            >Learn more</a
          >
        </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: ''
    }
  }
}
</script>
