<template>
  <div>
    <SavedReplyModal
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>
    <div class="write_block d-flex">
      <div class="text_area contentEditableTextArea">

        <div
          ref="commentArea"
          :placeholder="getPlaceholder()"
          contenteditable
          @keydown.enter.exact.prevent="
            replyToComments(
              isRoot ? null : rootCommentIndex,
              $event,
              isRoot ? 'new' : '',
            )
          "
          @input="typeComment"
        >
        </div>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="relative">
          <div
            v-floatingtip.hover.bottom="'Add an emoji'"
            @click="emojiHandler = !emojiHandler"
          >
            <i
              class="fal fa-smile-beam attachment_btn !cursor-pointer font-weight-normal font-1rem"
            >
            </i>
          </div>
          <CstEmojiPicker
            position="top-end"
            :is-open="emojiHandler"
            @on-select="(unicode) => onEmojiSelect(unicode)"
            @handle-click="emojiHandler = false"
          >
          </CstEmojiPicker>
        </div>
        <i
          v-tooltip.bottom="'Saved Replies'"
          class="icon-Save attachment_btn m-3"
          style="cursor: pointer"
          @click="$bvModal.show('savedReplyModal')"
        />
        <span
          :class="{ disabled: !typedComment }"
          class="post_btn"
          @click="
            replyToComments(
              isRoot ? null : rootCommentIndex,
              null,
              isRoot ? 'new' : '',
            )
          "
          >Post</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import SavedReplyModal from '@src/modules/inbox/components/savereplies/SavedReplyModal'
import { EventBus } from '@common/lib/event-bus'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker.vue'

export default {
  components: {
    CstEmojiPicker,
    SavedReplyModal,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => ({}),
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    rootCommentIndex: {
      type: Number,
      default: 0,
    },
    rootCommentId: {
      type: String,
      default: '',
    },
    temp: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    replyingTo: {
      type: String,
      default: '',
    },
    removeComment: {
      type: Function,
      default: () => {},
    },
    appendComment: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      commonMethods,
      typedComment: '',
      emojiHandler: false,
    }
  },
  computed: {
    ...mapGetters(['getInstagramAccounts', 'getProfile']),
  },
  watch: {
    replyingTo: async function (newVal, oldVal) {
      this.typedComment = newVal
    },
    'activeInboxDetail'(newObj, oldObj) {
    this.resetToDefault()
  }
  },
  mounted() {
    this.typedComment = this.replyingTo
  },
  methods: {
    typeComment(e) {
      this.typedComment = e.target.innerText

      if (e.target.textContent === '') {
        e.target.innerHTML = ''
      }
    },
    ...mapActions(['reply']),

    onEmojiSelect(emojiUnicode) {
      this.typedComment = this.typedComment || ''
      this.typedComment += emojiUnicode
      this.$refs.commentArea.innerHTML += emojiUnicode
    },
    applySavedReply(applyText) {
      this.typedComment += applyText
      this.$refs.commentArea.innerHTML += applyText
    },
    getPlaceholder() {
      const getter = this.getInstagramAccounts
      const channelItems = 'items' in getter ? getter.items : getter
      const temp = this
      const channelItem = channelItems.filter(function (channel) {
        return channel.instagram_id === temp.post.platform_id
      })
      if (channelItem.length > 0) {
        return 'Write comment as ' + channelItem[0].name + ' ...'
      } else {
        return 'Write a comment ...'
      }
    },
    async replyToComments(index, e, type) {
      // if user press enter + shift it will only add next line in textarea...
      // else case only enter key will send message

      const canSend = e === null ? true : e.keyCode === 13 && !e.shiftKey
      if (canSend) {
        if (e != null) {
          e.preventDefault()
        }
        const payload = this.createPayload()
        if (payload === null) {
          return
        }

        const commentBoxPayload = {
          comment_message: this.typedComment,
          comment_id:
            this.post.platform_id + '_' + Math.round(Math.random() * 100000),
          event_type: 'comment',
          sending: true,
          has_failed: false,
          type,
          index,
          comment_replies: [],
          payload,
        }

        this.appendComment(type, commentBoxPayload, index)
        this.resetToDefault()

        const response = await this.reply(payload)
        if (response.isValid) {
          EventBus.$emit('refresh-inbox-detail')
          this.removeComment(
            this.isRoot ? commentBoxPayload.comment_id : this.rootCommentId,
            this.isRoot ? null : commentBoxPayload.comment_id
          )
          this.appendComment(type, response.response, index)
        } else {
          commentBoxPayload.has_failed = true
          commentBoxPayload.error_message = response.response?.error
            ? response.response.error
            : response.message
          this.appendComment(type, commentBoxPayload, index)
        }
      }
    },
    createPayload() {
      if (this.typedComment === '' || this.typedComment === null) {
        this.alertMessage('Comment cannot be empty.', 'error')
        return null
      }

      const payload = new FormData()
      payload.append('platform', this.post.platform)
      payload.append('platform_id', this.post.platform_id)
      payload.append(
        'platform_name',
        this.post.inbox_details.posted_by.user_name
      )
      payload.append('comment_id', this.isRoot ? null : this.rootCommentId)
      payload.append('workspace_id', this.post.workspace_id)
      payload.append('reply_for', 'post')
      payload.append('element_id', this.post.element_details.element_id)
      payload.append('message', this.typedComment)
      payload.append('replied_by', this.getProfile._id)
      payload.append('is_main_comment', this.isRoot)
      return payload
    },
    resetToDefault() {
      this.typedComment = ''
      this.$refs.commentArea.innerHTML = ''
    },
  },
}
</script>

<style lang="less" scoped></style>
