<template>
  <div class="multi_select">
    <div
      v-if="getTopicSelection.sources == 'topics_and_keywords'"
      class="input_field"
    >
      <div class="d-flex align-items-center">
        <label for="" class="sub_label"
          ><span class="bold">LIMIT</span> results to these domains only</label
        >
        <button
          v-if="
            getTopicSelection.limit_domains_results.length > 0 ||
            (limitDomainsResultsValue !== null &&
              limitDomainsResultsValue.replace(/^\s+/, '') !== '')
          "
          class="reset_btn"
          @click="clearAllKeywords"
          ><i class="fa fa-times"></i> Clear</button
        >
      </div>

      <input
        v-model="limitDomainsResultsValue"
        class="prevent-listener"
        type="text"
        placeholder="Enter domains to see results from only these sites… e.g cnn.com, bbc.com"
        data-cy="include-domain"
        @input="asyncLimitDomainResults(limitDomainsResultsValue)"
        @keyup.enter="
          addAnyKeyword(
            getTopicSelection.limit_domains_results,
            limitDomainsResultsValue,
            'limit_domains_results',
            $event
          )
        "
        @keyup.188="
          addAnyKeyword(
            getTopicSelection.limit_domains_results,
            limitDomainsResultsValue,
            'limit_domains_results',
            $event
          )
        "
      />
      <div class="multi_input">
        <div
          v-if="isLimitDomainsLoading && limitDomainsResultsValue.length >= 3"
          v-click-away="closeDropdown"
          class="multi_dropdown"
        >
          <div class="multi_dropdown_inner">
            <ul>
              <!--<transition-group name="slide-bottom">-->
              <!--:key="suggested_domain+index+'content'"-->
              <li
                v-for="(
                  suggested_domain, index
                ) in limit_domains_suggestion_list"
                class="item d-flex align-items-center"
                @click.prevent="addLimitDomain(suggested_domain, index)"
              >
                <span
                  v-if="!suggested_domain.is_parent"
                  class="icon world_icon_size"
                >
                  <i class="cs-rss"></i>
                </span>
                <span v-else-if="suggested_domain.favicon_16" class="icon">
                  <img :src="suggested_domain.favicon_16" alt="" />
                </span>
                <span v-else class="icon world_icon_size">
                  <i class="cs-world-o"></i>
                </span>
                <span class="text">
                  <!--<span class="heading">{{suggested_domain.name}}</span>-->
                  <span
                    class="url"
                    v-html="
                      highlightText(
                        suggested_domain.url,
                        limitDomainsResultsValue
                      )
                    "
                  ></span>
                </span>
                <span class="view">
                  <i class="fa fa-eye"></i>
                </span>
              </li>
              <!--</transition-group>-->
            </ul>
          </div>
        </div>
        <div
          v-else-if="limitDomainsLoader"
          v-click-away="closeDropdown"
          class="multi_dropdown"
        >
          <div
            class="
              multi_dropdown_inner
              min_height
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
      <template v-if="getTopicSelection.limit_domains_results">
        <div class="multi_tags">
          <ul>
            <li
              v-for="(
                limit_domain_keyword, index
              ) in getTopicSelection.limit_domains_results"
              class="green"
            >
              <span class="value">{{ limit_domain_keyword }}</span>
              <span
                class="remove"
                @click.prevent="
                  removeKeyword(getTopicSelection.limit_domains_results, index)
                "
                >&times;</span
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLimitDomainsLoading: false,
      limit_domains_suggestion_list: [],
      limitDomainsLoader: false,
      limitDomainsResultsValue: null,
    }
  },
  methods: {
    closeDropdown() {
      this.isLimitDomainsLoading = false
    },
    clearAllKeywords() {
      this.limitDomainsResultsValue = ''
      this.getTopicSelection.limit_domains_results = []
      this.limitDomainsLoader = false
    },
  },
  computed: {
    ...mapGetters(['getTopicSelection']),
  },
  watch: {
    limitDomainsResultsValue(value) {
      if (value && value.length === 0) {
        this.limit_domains_suggestion_list = []
      }
    },
  },
}
</script>
