<template>
    <div ref="facebookCompetitorMainBody" class="pt-5 px-5 pb-0">
      <div
        v-if="dataTableMetricsLoadingState"
        class="grid grid-cols-5 gap-7 mb-7"
      >
        <SkeletonBox class="!h-[8.5rem]" />
        <SkeletonBox class="!h-[8.5rem]" />
        <SkeletonBox class="!h-[8.5rem]" />
        <SkeletonBox class="!h-[8.5rem]" />
        <SkeletonBox class="!h-[8.5rem]" />
      </div>
      <transition-group
        v-else-if="dataTableMetricsResponse.length"
        tag="div"
        class="grid grid-cols-5 gap-7 mb-7"
        name="slide-fade"
      >
        <template
          v-for="(competitor, index) in dataTableMetricsResponse"
          :key="`competitor_tile_${index}`"
        >
          <CompetitorTile
            :id="competitor.business_account_id"
            :name="competitor.name ? competitor.name : competitor.slug"
            :slug="competitor.slug"
            :image="competitor.image"
            :color="competitor.color"
            :followers="competitor.followersCount"
            :graph="getTileGraphValue(index)"
            :change="competitor.followersCountDiff"
            :state="competitor.state"
            :engagement-rate="competitor.engagementRate"
            :max-er="state.maxER"
            :min-er="state.minER"
            :max-followers="state.maxFollowers"
            :min-followers="state.minFollowers"
          />
          <CompetitorTile
            v-if="
              dataTableMetricsResponse.length < 5 &&
              index + 1 === dataTableMetricsResponse.length
            "
            :key="`empty_tile${index}`"
            :is-empty="true"
            name=""
            image=""
            color=""
            followers=""
            @click="
              EventBus.$emit(
                'show-manage-competitors-modal',
                reportCompetitorsInfo
              )
            "
          />
        </template>
      </transition-group>

      <div class="mb-7 relative">
        <PerformanceComparison
          :title="performanceComparisonData.title"
          :legend="{
            show: true,
            data: getPerformanceComparisonLegends,
          }"
          :x-axis-label="performanceComparisonData.xAxisLabel"
          :y-axis-label="performanceComparisonData.yAxisLabel"
          :is-all-data-fetched="isAllDataFetched"
          :data="filteredDataTableMetrics"
          :is-loading="dataTableMetricsLoadingState"
          type="performanceComparison"
        />
      </div>

      <div class="mb-7">
        <CompetitorsTable
          :title="performanceReviewTableData.title"
          :data="filteredDataTableMetrics"
          :is-all-data-fetched="isAllDataFetched"
          :headers="performanceReviewTableData.headers"
          :is-loading="dataTableMetricsLoadingState"
          :followers-growth-data="getFollowersGrowthData"
          :platform-name="PLATFORMS.FACEBOOK"
          :type="performanceReviewTableData.type"
        />
      </div>

      <div class="mb-7 grid grid-cols-2 gap-4">
        <FollowersComparisonBarChart
          :title="performanceReviewGraphData.title"
          :x-axis-label="performanceReviewGraphData.xAxisLabel"
          :y-axis-label="performanceReviewGraphData.yAxisLabel"
          :is-all-data-fetched="isAllDataFetched"
          :data="filteredDataTableMetrics"
          :is-loading="dataTableMetricsLoadingState"
          :platform-name="PLATFORMS.FACEBOOK"
          type="followersComparisonGraph"
        />

        <AnalyticsCardWrapper
          type="specific-type-chart"
          :enable-modal="
            !!(postingBySpecificType && postingBySpecificType.data && postingBySpecificType.data.length)
          "
        >
          <template v-slot:card-header>
            <div class="w-full flex justify-between items-center">
              <div class="flex items-center gap-2">
                <CstDropdown
                  v-if="
                    postingBySpecificType && postingBySpecificType.data && postingBySpecificType.data.length
                  "
                  dropdown-placement="bottom"
                  container-classes="!max-h-96"
                  button-classes="flex !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !w-[7.5rem] !px-0"
                >
                  <template v-slot:arrow>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </template>
                  <template v-slot:selected>
                    <p
                      v-if="selectedPostType"
                      class="text-base font-weight-500 capitalize"
                      >{{ selectedPostType + ' Post' }}</p
                    >
                  </template>
                  <template v-slot>
                    <template
                      v-for="(media, index) in getAllAvailablePostsTypes"
                      :key="`activity_type_${index}`"
                    >
                      <CstDropdownItem @click="changePostTypeChart(media)">
                        <p class="text-gray-900 text-sm">{{
                          media + ' Post'
                        }}</p>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
                <h2 class="text-base font-weight-500 select-none"
                  >Activity by Competitors</h2
                >
                <v-menu
                  :popper-triggers="['hover']"
                  placement="top"
                  popper-class="first-comment__info-popover"
                  :delay="300"
                >
                  <i class="far fa-question-circle p-0 cursor-pointer"></i>
                  <template v-slot:popper>
                    <p class="text-sm text-gray-900">
                      {{getComponentInfoTooltip('fbPostingActivityBySpecificType', platform, selectedPostType)}}
                      <span v-if="selectedPostType === 'Others'" class="block text-gray-800 text-xs mt-2"
                      >Note: Others post type can consist of Polls, Check-Ins, Events, Offers, Milestones, Reviews & Recommendations, Job Listings, Fundraisers, Questions, Notes, Music & Playlists, Documents & Files, Feeling/Activity, Wishes, Charity Donations, Recipes, Travel Recommendations, Ask for Recommendations.</span
                      >
                    </p>
                  </template>
                </v-menu>
              </div>
              <div class="flex items-center cursor-pointer">
                <div
                  v-if="
                    !!(
                      postingBySpecificType && postingBySpecificType.data.length
                    )
                  "
                  class="rounded flex bg-[#f4f6fa] w-full"
                >
                  <div
                    v-tooltip="{ content: 'Chart View', theme: 'light' }"
                    class="flex-1 rounded w-full"
                    @click="selectedView = 'chart'"
                  >
                    <img :src="imgSrcChart" alt="Chart" />
                  </div>
                  <div
                    v-tooltip="{ content: 'List View', theme: 'light' }"
                    class="flex-1 rounded w-full"
                    @click="selectedView = 'table'"
                  >
                    <img :src="imgSrcTable" alt="Table" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:card-body="slotProps">
            <SpecificPostTypeChart
              v-show="selectedView === 'chart'"
              :key="Math.random()"
              :title="postingBySpecificType.title"
              :date-range-diff="dateRangeDiff"
              :data="postingBySpecificType.data"
              :is-all-data-fetched="isAllDataFetched"
              :x-axis-label="postingBySpecificType.xAxisLabel"
              :y-axis-label="postingBySpecificType.yAxisLabel"
              :y-axis-second-label="postingBySpecificType.yAxisSecondLabel"
              :is-loading="postingActivitySpecificTypeLoadingState"
              :platform-name="PLATFORMS.FACEBOOK"
              :is-modal="slotProps.isModal"
              type="postingActivityBySpecificType"
            />
            <CompetitorsTableComponent
              v-if="selectedView === 'table'"
              :title="activityTypeTableData.title"
              :data="postingBySpecificType.data"
              :is-all-data-fetched="isAllDataFetched"
              :headers="activityTypeTableData.headers"
              :is-loading="postingActivitySpecificTypeLoadingState"
              :type="activityTypeTableData.type"
              :selected-type="selectedPostType"
              :all-available-types="getAllAvailablePostsTypes"
              :platform-name="PLATFORMS.FACEBOOK"
              @chart-type="changePostTypeChart"
            />
          </template>
        </AnalyticsCardWrapper>
      </div>

      <div class="mb-7">
        <CompetitorsTable
          :title="hashtagsTableData.title"
          :show-info-cell="false"
          :data="topHashtagsResponse"
          :headers="hashtagsTableData.headers"
          :is-all-data-fetched="isAllDataFetched"
          :is-loading="topHashtagsLoadingState"
          :type="hashtagsTableData.type"
          :single-hashtag-data="individualHashtagDataResponse"
          :single-hashtag-header="singleHashtagContent.headers"
          :index-to-sort="2"
          :platform-name="PLATFORMS.FACEBOOK"
          @single-hashtag="fetchSingleHashtagData"
        />
      </div>

      <div class="mb-7 grid grid-cols-2 gap-4">
        <AnalyticsCardWrapper
          type="post-engagement"
          :enable-modal="
            !!(
              postEngagementByCompetitorResponse &&
              postEngagementByCompetitorResponse.length
            )
          "
        >
          <template v-slot:card-header>
            <div class="flex items-center gap-2">
              <h2 class="text-base font-weight-500 select-none"
                >Post Engagement</h2
              >
              <v-menu
                  :popper-triggers="['hover']"
                  placement="top"
                  popper-class="first-comment__info-popover"
                  :delay="300"
              >
                <i class="far fa-question-circle p-0 cursor-pointer"></i>
                <template v-slot:popper>
                  <p class="text-sm text-gray-900">
                    {{getComponentInfoTooltip('postEngagement', platform)}}
                  </p>
                </template>
              </v-menu>
            </div>
          </template>
          <template v-slot:card-body="slotProps">
            <PostEngagementChart
              :title="postEngagementGraphData.title"
              :is-modal="slotProps.isModal"
              :date-range-diff="dateRangeDiff"
              :data="postEngagementByCompetitorResponse"
              :is-all-data-fetched="isAllDataFetched"
              :x-axis-label="postEngagementGraphData.xAxisLabel"
              :y-axis-label="postEngagementGraphData.yAxisLabel"
              :y-axis-second-label="postEngagementGraphData.yAxisSecondLabel"
              :is-loading="postEngagementByCompetitorLoadingState"
              @chart-type="changePostTypeChart"
            />
          </template>
        </AnalyticsCardWrapper>
        <AnalyticsCardWrapper
          type="post-engagement-over-time"
          :enable-modal="
            !!(getPostEngagementOverTime && getPostEngagementOverTime.length)
          "
        >
          <template v-slot:card-header>
            <div class="w-full flex justify-between items-center">
              <div class="flex items-center gap-2">
                <h2 class="text-base font-weight-500 select-none"
                  >Posts/Day by</h2
                >
                <CstDropdown
                  v-if="competitorsWithEngagement.length"
                  dropdown-placement="bottom"
                  container-classes="!max-h-96"
                  button-classes="flex !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !w-[8.5rem] !px-0"
                >
                  <template v-slot:arrow>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </template>
                  <template v-slot:selected>
                    <p
                      v-if="selectedCompany"
                      class="text-base font-weight-500 capitalize truncate"
                      >{{ selectedCompany.name }}</p
                    >
                  </template>
                  <template v-slot>
                    <template
                      v-for="(company, index) in competitorsWithEngagement"
                      :key="`activity_type_${index}`"
                    >
                      <CstDropdownItem @click="changeCompanyCharts(company)">
                        <p class="text-gray-900 text-sm truncate">{{
                          company.name
                        }}</p>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
                <v-menu
                    :popper-triggers="['hover']"
                    placement="top"
                    popper-class="first-comment__info-popover"
                    :delay="300"
                >
                  <i class="far fa-question-circle p-0 cursor-pointer"></i>
                  <template v-slot:popper>
                    <p class="text-sm text-gray-900">
                      {{getComponentInfoTooltip('postEngagementOverTime', platform)}}
                    </p>
                  </template>
                </v-menu>
              </div>
            </div>
          </template>
          <template v-slot:card-body="slotProps">
            <PostEngagementOverTime
              :is-modal="slotProps.isModal"
              :x-axis-label="postEngagementOverTimeData.xAxisLabel"
              :y-axis-label="postEngagementOverTimeData.yAxisLabel"
              :data="getPostEngagementOverTime"
              :is-all-data-fetched="isAllDataFetched"
              :is-loading="postEngagementOverTimeLoadingState"
            />
          </template>
        </AnalyticsCardWrapper>
      </div>

      <div class="mb-7">
        <AnalyticsCardWrapper
          type="post-react-distribution"
          :enable-modal="
            !!(getPostReactsDistribution && getPostReactsDistribution.length)
          "
        >
          <template v-slot:card-header>
            <div class="w-full flex justify-between items-center">
              <div class="flex items-center gap-2">
                <h2 class="text-base font-weight-500 select-none">{{
                  postReactsDistributionByCompanyData.title
                }}</h2>
                <CstDropdown
                  v-if="competitorsWithEngagement.length"
                  dropdown-placement="bottom"
                  container-classes="!max-h-96"
                  button-classes="flex !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !w-[8.5rem] !px-0"
                >
                  <template v-slot:arrow>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </template>
                  <template v-slot:selected>
                    <p
                      v-if="selectedCompany"
                      class="text-base font-weight-500 capitalize truncate"
                      >{{ selectedCompany.name }}</p
                    >
                  </template>
                  <template v-slot>
                    <template
                      v-for="(company, index) in competitorsWithEngagement"
                      :key="`activity_type_${index}`"
                    >
                      <CstDropdownItem @click="changeCompanyCharts(company)">
                        <p class="text-gray-900 text-sm truncate">{{
                          company.name
                        }}</p>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
                <v-menu
                    :popper-triggers="['hover']"
                    placement="top"
                    popper-class="first-comment__info-popover"
                    :delay="300"
                >
                  <i class="far fa-question-circle p-0 cursor-pointer"></i>
                  <template v-slot:popper>
                    <p class="text-sm text-gray-900">
                      {{getComponentInfoTooltip('postReactsDistribution', platform)}}
                    </p>
                  </template>
                </v-menu>
              </div>
            </div>
          </template>
          <template v-slot:card-body="slotProps">
            <PostReactsDistributionChart
              :is-modal="slotProps.isModal"
              :x-axis-label="postReactsDistributionByCompanyData.xAxisLabel"
              :y-axis-label="postReactsDistributionByCompanyData.yAxisLabel"
              :data="getPostReactsDistribution"
              :is-all-data-fetched="isAllDataFetched"
              :is-loading="postReactsDistributionByCompanyLoadingState"
            />
          </template>
        </AnalyticsCardWrapper>
      </div>

      <div class="mb-7">
        <CompetitorsTable
          :title="bioAnalysisTableData.title"
          :data="getBioAnalysisTableData"
          :is-all-data-fetched="isAllDataFetched"
          :is-loading="biographyDataLoadingState"
          :headers="bioAnalysisTableData.headers"
          :platform-name="PLATFORMS.FACEBOOK"
          :type="bioAnalysisTableData.type"
        />
      </div>

      <div class="mb-7">
        <TopAndLeastPerformingPosts
          :data="topAndLeastPerformingPostsResponse"
          :is-all-data-fetched="isAllDataFetched"
          :is-loading="topAndLeastPerformingPostsLoadingState"
          :platform-name="PLATFORMS.FACEBOOK"
        />
      </div>
    </div>
</template>

<script setup>
// libraries
import {
  onMounted,
  reactive,
  computed,
  watch,
  ref,
  onBeforeMount,
  defineProps,
  defineEmits,
  inject,
  onBeforeUnmount,
} from 'vue'
import { EventBus } from '@common/lib/event-bus'
import { useStore } from '@state/base'
import { useRoute } from 'vue-router'

// components
import CompetitorTile from '@src/modules/analytics_v3/components/CompetitorTile'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison'
import CompetitorsTable from '@src/modules/analytics_v3/components/CompetitorsTable.vue'
import TopAndLeastPerformingPosts from '@src/modules/analytics_v3/components/TopAndLeastPerformingPosts.vue'
import FollowersComparisonBarChart from '@src/modules/analytics_v3/components/FollowersComparisonBarChart.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import PostReactsDistributionChart from '@src/modules/analytics_v3/components/PostReactsDistributionChart.vue'
import PostEngagementOverTime from '@src/modules/analytics_v3/components/PostEngagementOverTime.vue'
import PostEngagementChart from '@src/modules/analytics_v3/components/PostEngagementChart.vue'
import SpecificPostTypeChart from '@src/modules/analytics_v3/components/SpecificPostTypeChart.vue'
import CompetitorsTableComponent from '@src/modules/analytics_v3/components/CompetitorsTableComponent.vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'
import useCompetitorReport from '@src/modules/analytics_v3/composables/useCompetitorReport'
import useDataTableMetrics from '@src/modules/analytics_v3/composables/useDataTableMetrics'
import usePostingActivityGraphByTypes from '@src/modules/analytics_v3/composables/usePostingActivityGraphByTypes'
import usePostingBySpecificType from '@src/modules/analytics_v3/composables/usePostingBySpecificType'
import useFollowersGrowthComparison from '@src/modules/analytics_v3/composables/useFollowersGrowthComparison'
import useTopHashtags from '@src/modules/analytics_v3/composables/useTopHashtags'
import useIndividualHashtagData from '@src/modules/analytics_v3/composables/useIndividualHashtagData'
import useBiographyData from '@src/modules/analytics_v3/composables/useBiographyData'
import useTopAndLeastPerformingPosts from '@src/modules/analytics_v3/composables/useTopAndLeastPerformingPosts'
import usePostEngagementByCompetitor from '@src/modules/analytics_v3/composables/usePostEngagementByCompetitor'
import usePostEngagementOverTime from '@src/modules/analytics_v3/composables/usePostEngagementOverTime'
import usePostReactsDistributionByCompany from '@src/modules/analytics_v3/composables/usePostReactsDistributionByCompany'
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'

// constants
import { PLATFORMS } from '@src/modules/analytics_v3/constants/constants'

const props = defineProps({
  isCompetitorAnalyticsEnabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['show-manage-competitors-modal'])

// functionality

/** data */
const root = inject('root')
const { $router } = root
const { getters } = useStore()
const $route = useRoute()

const state = reactive({
  labelBgColor: '#ffff',
  maxER: 0,
  minER: 0,
  maxFollowers: 0,
  minFollowers: 0,
})
const facebookCompetitorMainBody = ref(null)
const selectedCompany = ref(null)
const selectedPostType = ref(null)
const selectedView = ref('chart')
const colors = [
  '#339ca8',
  '#cda819',
  '#37A2DA',
  '#8081B9',
  '#FFE27D',
  '#FFAE8B',
  '#71F6F9',
]

const { getMinMax, getComponentInfoTooltip } = useCompetitorHelper()
const { platform } = useCompetitorsFactory()
const { reportCompetitorsInfo, reportId, getReportInfo } = useCompetitorReport()
const {
  getDataTableMetrics,
  dataTableMetricsResponse,
  dataTableMetricsLoadingState,
} = useDataTableMetrics()
const {
  getPostingActivityGraphByTypes,
  postingActivityGraphByTypesResponse,
  dateRangeDiff,
  mediaType,
} = usePostingActivityGraphByTypes()
const {
  getPostingActivityBySpecificType,
  postingActivitySpecificTypeLoadingState,
  postingActivitySpecificTypeResponse,
} = usePostingBySpecificType()
const {
  getFollowersGrowthComparison,
  followersGrowthComparisonResponse,
  followersGrowthComparisonLoadingState,
} = useFollowersGrowthComparison()
const { getTopHashtags, topHashtagsResponse, topHashtagsLoadingState } =
  useTopHashtags()
const { getIndividualHashtag, individualHashtagDataResponse } =
  useIndividualHashtagData()
const { getBiographyData, biographyDataResponse, biographyDataLoadingState } =
  useBiographyData()
const {
  getTopAndLeastPerformingPosts,
  topAndLeastPerformingPostsResponse,
  topAndLeastPerformingPostsLoadingState,
} = useTopAndLeastPerformingPosts()
const {
  getPostEngagementByCompetitorData,
  postEngagementByCompetitorResponse,
  postEngagementByCompetitorLoadingState,
} = usePostEngagementByCompetitor()
const {
  getPostEngagementOverTimeData,
  postEngagementOverTimeResponse,
  postEngagementOverTimeLoadingState,
} = usePostEngagementOverTime()
const {
  getPostReactsDistributionByCompanyData,
  postReactsDistributionByCompanyResponse,
  postReactsDistributionByCompanyLoadingState,
} = usePostReactsDistributionByCompany()

// data for the performance comparison graph
const performanceComparisonData = {
  title: "Competitors' Performance Comparison",
  xAxisLabel: 'Engagement Rate',
  yAxisLabel: 'Followers',
  yAxisSecondLabel: 'Followers %',
}

// data for performance review table
const performanceReviewTableData = {
  title: 'Competitors’ Comparative Table',
  type: 'performanceReviewTable',
  headers: [
    'followersCount',
    'followersCountDiff',
    'averagePostsPerWeek',
    'averagePostsPerWeekDiff',
    'engagementRate',
    'engagementRateDiff',
  ],
}

// data for performance review graph
const performanceReviewGraphData = {
  title: 'Competitors by Total Followers vs Net Change in Followers',
  xAxisLabel: 'Competitors',
  yAxisLabel: 'Followers',
}

// data for specific activity type table
const activityTypeTableData = {
  type: 'postingActivityTableByType',
  headers: ['totalPosts', 'totalEngagement', 'avgEngagementRate'],
  title: 'Activity by Competitors',
}

// data for most engaged hashtags (table)
const hashtagsTableData = {
  headers: [
    'tag',
    'companies_using',
    'count',
    'engagement_per_post',
    'engagement_per_follower',
    'engagement_rate_by_follower',
  ],
  type: 'mostEngagedHashtagsTable',
  title: 'Most Engaged Hashtags',
}

// data for single hashtag data w.r.t competitors
const singleHashtagContent = {
  headers: [
    'name',
    'count',
    'engagement_per_post',
    'engagement_per_follower',
    'engagement_rate_by_follower',
  ],
  type: 'singleHashtagTableData',
}

// data for bio analysis (table)
const bioAnalysisTableData = {
  headers: ['biography', 'biography_length'],
  type: 'bioAnalysisTableData',
  colors,
  title: 'Bio Analysis',
}

const postEngagementGraphData = {
  xAxisLabel: 'Competitors',
  title: 'Post Engagement',
  yAxisLabel: 'Engagement',
  yAxisSecondLabel: 'Number of Posts',
  colors,
}

const postReactsDistributionByCompanyData = {
  xAxisLabel: 'Reacts',
  title: 'Distribution of post reactions by',
  yAxisLabel: 'Engagement',
  colors,
}

const postEngagementOverTimeData = {
  xAxisLabel: 'TimeLine',
  title: 'Post(s)/Day by Company',
  yAxisLabel: 'Number of Posts',
  colors,
}

/** computed */

// returns boolean on the basis of presence of 'Added' value against state key in competitors' objects.
const isAllDataFetched = computed(() =>
  dataTableMetricsResponse.value?.every((item) => item.state === 'Added')
)

const filteredDataTableMetrics = computed(() => {
  if (isAllDataFetched.value) return []
  return dataTableMetricsResponse.value?.filter(
    (item) => item.state !== 'Added'
  )
})

const getPostEngagementOverTime = computed(() => {
  if (postEngagementByCompetitorResponse.value?.length) {
    return postEngagementOverTimeResponse.value
  }
  return []
})

const getPostReactsDistribution = computed(() => {
  if (postEngagementByCompetitorResponse.value?.length) {
    return postReactsDistributionByCompanyResponse.value
  }
  return []
})

// This computed property returns filtered Facebook analytics data for CompetitorsTable having type bioAnalysisTableData.
const getBioAnalysisTableData = computed(() =>
  biographyDataResponse.value?.filter(
    (competitor) => competitor.state !== 'Added'
  )
)

// This computed property returns all the available posts types of competitors in report. /
const getAllAvailablePostsTypes = computed(() =>
  postingActivityGraphByTypesResponse.value?.map((item) =>
    getFormattedMediaType(item.mediaType)
  )
)

// This computed property returns all the names of all the added competitors.
const getPerformanceComparisonLegends = computed(() =>
  filteredDataTableMetrics.value?.map((item) =>
    item.name ? item.name : item.slug
  )
)

// data for followers growth comparison graph
const getFollowersGrowthData = computed(() => ({
  data: followersGrowthComparisonResponse.value,
  xAxisLabel: 'Timeline',
  yAxisLabel: 'Followers',
  title: 'Followers Growth Comparison',
  isLoading: followersGrowthComparisonLoadingState.value,
}))

// data for specific activity graph, maps the colors w.r.t specific competitors & then filter on the basis of state.
const postingBySpecificType = computed(() => {
  const data = postingActivitySpecificTypeResponse.value
    ?.map((item) => {
      for (const competitor of dataTableMetricsResponse.value) {
        if (competitor.name === item.name) {
          if (competitor.state === 'Added') {
            return null
          }
          return { ...item, color: competitor.color }
        }
      }
      return null
    })
    .filter((competitor) => competitor && competitor.color)

  return {
    xAxisLabel: 'Competitors',
    yAxisLabel: 'Average Engagement',
    yAxisSecondLabel: 'Posts/ Week',
    title: 'Activity by Competitors',
    dateRangeDiff: dateRangeDiff.value,
    data,
  }
})

const competitorsWithEngagement = computed(() =>
  postEngagementByCompetitorResponse.value?.map((item) => ({
    name: item.page_name,
    id: item.facebook_id,
  }))
)

const imgSrcChart = computed(() =>
  selectedView.value === 'chart'
    ? require('@src/assets/img/analytics/chart_selected.svg')
    : require('@src/assets/img/analytics/chart_unselected.svg')
)

const imgSrcTable = computed(() =>
  selectedView.value === 'table'
    ? require('@src/assets/img/analytics/list_selected.svg')
    : require('@src/assets/img/analytics/list_unselected.svg')
)

/** watch */
watch(
  () => getters.getActiveWorkspace._id,
  () => {
    if ($route?.path?.includes('/facebook-competitor')) {
      $router.push({ name: 'facebook_competitor_overview_v3' })
    }
  }
)

watch(
  () => dataTableMetricsResponse.value,
  (newVal) => {
    state.loading = false
    if (newVal) {
      const engagementRate = getMinMax(newVal, 'engagementRate')
      const followers = getMinMax(newVal, 'followersCount')
      state.maxFollowers = followers.max
      state.minFollowers = followers.min
      state.maxER = engagementRate.max
      state.minER = engagementRate.min
    }
  }
)

/**  lifecycle hooks */
onBeforeMount(() => {
  // explicitly check for false  ** router in component guard not used due to vue router migration
  if (props.isCompetitorAnalyticsEnabled === false) {
    $router.push({ name: 'competitor_analytics' })
  }
})

onMounted(async () => {
  if (!platform.value) platform.value = 'facebook'
  if(reportId.value){
    await getReportInfo();
    fetchReportAnalytics()
  }

  facebookCompetitorMainBody.value.scrollIntoView({ behavior: 'smooth' })

  EventBus.$on('re-fetch-report-data', () => {
    if (reportId.value && platform.value) fetchReportAnalytics()
  })

  EventBus.$on('fetch-specific-type-data', () => {
    if (mediaType.value && $route.name === 'facebook_competitor_v3') {
      selectedPostType.value = getFormattedMediaType(mediaType.value)
      getPostingActivityBySpecificType(PLATFORMS.FACEBOOK, mediaType.value)
    }
  })

  EventBus.$on('fetch-engagement-data', (companyObject) => {
    changeCompanyCharts(companyObject, companyObject?.type !== 'echarts')
  })

  EventBus.$on('chart-type', (type) => {
    changePostTypeChart(type)
  })
})

onBeforeUnmount(() => {
  EventBus.$off('re-fetch-report-data')
  EventBus.$off('fetch-specific-type-data')
  EventBus.$off('fetch-engagement-data')
  EventBus.$off('chart-type')
})

/** methods */
const fetchReportAnalytics = () => {
  getDataTableMetrics(PLATFORMS.FACEBOOK)
  getPostingActivityGraphByTypes(PLATFORMS.FACEBOOK)
  getPostEngagementByCompetitorData(PLATFORMS.FACEBOOK)
  getFollowersGrowthComparison(PLATFORMS.FACEBOOK)
  getTopHashtags(PLATFORMS.FACEBOOK)
  getBiographyData(PLATFORMS.FACEBOOK)
  getTopAndLeastPerformingPosts(PLATFORMS.FACEBOOK)
}

const getFormattedMediaType = (value) => {
  switch (value ?? mediaType.value) {
    case 'text':
      return 'Text'
    case 'image':
      return 'Image'
    case 'others':
      return 'Others'
    case 'videos':
      return 'Video'
    case 'carousel':
      return 'Carousel'
    case 'link':
      return 'Link'
    case 'share':
      return 'Shared'
    case 'images':
      return 'Images'
    default:
      return ''
  }
}

/**
 * @description method called upon clicking a label/bar/scatter point of a graph. Changes the chart and table data of specific type chart and table.
 * @param value
 */
const changePostTypeChart = (value) => {
  let type = ''
  switch (value) {
    case 'Text':
      type = 'text'
      break
    case 'Image':
      type = 'image'
      break
    case 'Others':
      type = 'others'
      break
    case 'Video':
      type = 'videos'
      break
    case 'Carousel':
      type = 'carousel'
      break
    case 'Shared':
      type = 'share'
      break
    case 'Link':
      type = 'link'
      break
    case 'Images':
      type = 'images'
      break
    default:
      return ''
  }
  if (mediaType.value === type) return
  mediaType.value = type
  selectedPostType.value = value
  getPostingActivityBySpecificType(PLATFORMS.FACEBOOK, type)
}

const changeCompanyCharts = (companyObject, forceReFetch = false) => {
  if ((companyObject && selectedCompany.value?.id !== companyObject.id) || forceReFetch) {
    selectedCompany.value = companyObject

    getPostEngagementOverTimeData(PLATFORMS.FACEBOOK, selectedCompany.value.id)
    getPostReactsDistributionByCompanyData(
      PLATFORMS.FACEBOOK,
      selectedCompany.value.id
    )
  }
}

/**
 * @description emits the hashtag whose date is to be fetched
 * @param hashtag
 */
const fetchSingleHashtagData = (hashtag) => {
  getIndividualHashtag(PLATFORMS.FACEBOOK, hashtag)
}

/**
 * @description depending on the ER, sets the status of competitor tile graph.
 * @param index
 * @returns {string}
 */
const getTileGraphValue = (index) => {
  const engagementRate = dataTableMetricsResponse.value[index].engagementRate
  let status
  if (engagementRate > 0) {
    status = 'up'
  } else if (engagementRate < 0) {
    status = 'down'
  } else {
    status = 'default'
  }
  return status
}
</script>
