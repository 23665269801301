<script setup>
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'

const { routes, cards, getCardData, isReportView, isLoadingStates,isNoLabelOrCampaignSelected, SAMPLE_SUMMARY_DATA } = useLabelAndCampaign()
</script>

<template>
  <template v-if="isNoLabelOrCampaignSelected">
    <NewStatsCard
    v-for="card in SAMPLE_SUMMARY_DATA"
    :key="card?.title"
    :show-info-tooltip="!isReportView"
    class="col-span-2 2xl:col-auto"
    :data="card"
    />
  </template>
  <template
    v-else-if="isLoadingStates[routes?.SUMMARY_ANALYTICS]"
  >
    <SkeletonBox
    v-for="card in cards"
    :key="card"
    :height="'7.25rem'"
    width="'100%'"
    class="col-span-2 2xl:col-auto"
    />
  </template>
  <template v-else>
    <NewStatsCard
      v-for="card in cards"
      :key="card"
      :show-info-tooltip="!isReportView"
      class="col-span-2 2xl:col-auto"
      :data="getCardData(card)"
    />
  </template>
</template>
