import {h} from 'vue'
import CstToast from '@ui/Toast/CstToast'
import { toast } from 'vue3-toastify'

export default {
  state: {
    is_new_version_available: false,
    is_new_version_available_display: true,
  },
  getters: {
    getIsNewVersionAvailable: (state) => {
      return state.is_new_version_available
    },
    getIsNewVersionAvailableDisplay: (state) => {
      return state.is_new_version_available_display
    },
  },

  actions: {
    toastNotification({ commit, getters }, payload) {
      // set the payload to success if no property defined.
      if (this.$route && this.$route.name === 'analytics_pdf_report') {
        return
      }
      if (!('type' in payload)) {
        payload.type = 'success'
      }

      const type = payload.type
      const message = payload.message
      console.debug('notification detail message ==== ', message, type)

      toast(({ closeToast }) => h(CstToast, { closeToastCallback:closeToast, type, message }));
    },
  },
  mutations: {
    setIsNewVersionAvailable(state, value) {
      console.log('setIsNewVersionAvailable')
      state.is_new_version_available = value
    },
    setDisplayNewVersionAvailable(state, value) {
      state.is_new_version_available_display = value
    },
  },
}
