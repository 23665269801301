<template>
  <div class="dropdown option_dropdown default_style_dropdown">
    <div
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <i class="trending_icon icon_first cs-trending-chart"></i>
      <span class="text">{{ getCurationFolderText }}</span>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>

    <div class="dropdown-menu dropdown-menu-right">
      <ul v-if="getFolders.length > 0" class="inner">
        <li v-for="folder in getFolders" class="list_item">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="selectFavoriteFolder(folder)"
            >{{ folder.name }}</a
          >
        </li>
      </ul>
      <ul v-else class="inner">
        <li class="no_text_li">
          <p>No Folder found</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { topicTypes } from '../../store/mutation-types'
import { composer } from '../../../composer/store/composer-mutation-type'

export default {
  props: {
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },

  methods: {
    ...mapActions(['topicsContent']),
    ...mapMutations([topicTypes.SET_CHANGE_TOPIC_FILTERS]),
    async selectFavoriteFolder (folder) {
      console.debug('Method:selectFavoriteFolder', folder)
      this[topicTypes.SET_CHANGE_TOPIC_FILTERS]({
        topic: folder,
        type: 'favorite'
      })
      this.$store.commit(composer.SET_CURATION_FAVORITE_NAME, folder.name)
      await this.topicsContent()
      this.checkComposerDragDrop()
    }
  },
  computed: {
    ...mapGetters([
      'getArticlesSort',
      'getTopicsSort',
      'getFolders',
      'getCurationFolderText'
    ])
  }
}
</script>
