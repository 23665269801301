<template>
  <b-modal
    id="public-password-protected"
    body-class="px-10 py-11 text-center"
    centered
    dialog-class="w-[27.813rem]"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="mb-4 flex items-center justify-center gap-2">
      <img
        alt="key"
        class="w-4 h-4"
        src="@assets/img/common/key-password.svg"
      />
      <p class="text-[#202324] font-semibold">Password Required</p>
    </div>
    <p class="text-[#757A8A] text-sm mb-1">You need a password to access</p>
    <p class="text-[#202324] font-medium text-s mb-8"
      >ContentStudio - Shareable Link</p
    >
    <span class="relative">
      <input
        id="external-password"
        v-model="state.password"
        :disabled="state.loading"
        :type="state.passInputType"
        autocomplete="off"
        class="color-border w-[22.813rem] h-10 p-4 pr-9 text-[#3a4557] rounded-lg bg-[#F2F3F8] cst-editor mb-8"
        placeholder="Enter Password"
        @keydown.enter="verifyPassword"
      />
      <img
        :src="secretEyeIconSrc"
        alt=""
        class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
        @click="changePasswordType"
      />
    </span>

    <!--    Footer    -->
    <button
      :class="{
        'opacity-50 !cursor-not-allowed': isContinueDisabled,
      }"
      :disabled="isContinueDisabled"
      class="px-8 py-4 rounded-xl border border-[#2961D2] bg-[#2961D2] text-[1rem] leading-none text-white"
      @click="verifyPassword"
    >
      Continue
    </button>
  </b-modal>
</template>

<script setup>
import { computed, reactive } from 'vue'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'

const emit = defineEmits(['on-verify'])
const props = defineProps({
  linkId: {
    type: String,
    default: '',
  },
})

const { dispatch } = useStore()

const state = reactive({
  password: '',
  loading: false,
  passInputType: 'password',
})

const secretEyeIconSrc = computed(() =>
  state.passInputType === 'password'
    ? '/img/eye_disabled.svg'
    : '/img/eye_allowed.svg'
)

const isContinueDisabled = computed(
  () => state.loading || !state.password.trim()
)

const changePasswordType = () => {
  state.passInputType = state.passInputType === 'password' ? 'text' : 'password'
}

/**
 * Method to verify the password
 */
const verifyPassword = async () => {
  if (isContinueDisabled.value) return
  state.loading = true
  try {
    const response = await serviceShareLink(ACTION_TYPES.VERIFY_PASSWORD, {
      link_id: props.linkId,
      password: state.password,
    })

    emit('on-verify', response.token)
  } catch (error) {
    await dispatch('toastNotification', {
      message: error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  } finally {
    state.loading = false
  }
}
</script>

<style lang="scss">
#public-password-protected___BV_modal_outer_ {
  .modal-backdrop {
    opacity: 1 !important;
  }
}
</style>
