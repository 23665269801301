<template>
  <div
    v-if="
      getPublishLoaders.fetchHashTagsVelocity ||
      getSocialSharingSelection.tagsSuggestions.items.length
    "
    class="hashtag_section bg-white">

    <template v-if="getPublishLoaders.fetchHashTagsVelocity">
      <div class="flex w-full">
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
        <SkeletonBox width="80px" height="2rem" radius=".2rem" />
      </div>

    </template>


    <template
      v-else-if="getSocialSharingSelection.tagsSuggestions.items.length"
    >
      <div class="toggle_option d-flex align-items-center">
        <p class="font-bold text-sm">Suggested Hashtags</p>
        <!--                <div class="left ml-auto">-->
        <!--                    <p class="mr-2 d-inline-block align-middle">Replace keyword with hashtag</p>-->
        <!--                    <label class="switch-radio d-inline-block align-middle">-->
        <!--                        <input type="checkbox" v-model="getSocialSharingSelection.tagsSuggestions.replaceKeyword">-->
        <!--                        <div class="slider round"></div>-->
        <!--                    </label>-->
        <!--                </div>-->
      </div>

      <div class="suggested_tag_list">
        <span
          v-for="(
            item, index
          ) in getSocialSharingSelection.tagsSuggestions.items.slice(0, 10)"
          class="tag_item blue"
          @click.prevent="addSocialSharesTags(item, index), $emit('addHashtag', item, index)"
        >
          <span class="text">{{ item }}</span>
          <!--                    <span class="text_detail" v-if="item.retweets"><strong>{{item.retweets}}</strong> Avg. Tweets/Hour</span>-->
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SkeletonBox from "@src/modules/analytics/views/common/SkeletonBox";

export default {
  components: {
    SkeletonBox
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getPublishLoaders'])
  },
  methods: {},

  watch: {}
}
</script>

<style lang="scss">
.hashtag_section {
  border-top: 1px solid #dee2e6;
}
</style>