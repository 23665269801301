<template>
  <div
    v-click-away="handleClickAway"
    :class="{ 'relative z-100': isOpen }"
    class="cst-tags-dropdown inline-block text-left w-full"
  >
    <div>
      <div class="flex flex-wrap items-start">
        <transition-group name="fade-list-inline-item">
          <div
            v-for="(tag, i) in selectedTags"
            :key="tag.id"
            :data-tag-id="tag.id"
            class="
              inline-flex
              justify-center
              items-center
              text-xs
              capitalize
              leading-tight
              rounded-md
              px-3
              py-2
              mr-2
              mb-2.5
              focus:outline-none
              focus:ring-0
            "
            :style="getLabelColor(getLabelById(tag.id).color)"
          >
            {{ tag.text }}
            <div
              ><i
                class="far fa-times ml-1.5 cursor-pointer hover:text-blue-700"
                @click="handleRemove(tag.id)"
              ></i>
            </div>
          </div>
        </transition-group>
      </div>

      <div
        class="
          inline-flex
          overflow-hidden
          text-ellipsis
          whitespace-pre
          justify-between
          items-center
          w-full
          rounded-lg
          color-border
          shadow-xs
          bg-white
          font-normal
          text-gray-800
          hover:bg-gray-50
          focus:outline-none
          px-3
          py-2.5
          text-sm
          flex
          justify-between
          bg-main-gray
        "
      >
        <div class="flex items-center justify-between w-full">
          <input
            v-model="search"
            :placeholder="placeholder"
            class="
              cst-tags-dropdown-input
              border-0
              bg-transparent
              flex-1
              w-full
              text-sm
            "
            type="text"
            @focus="isOpen = true"
            @input="isOpen = true"
          />
          <i
            :class="isOpen ? 'fa-rotate-180' : ''"
            class="far fa-angle-down transition cursor-pointer"
            @click="isOpen = !isOpen"
          ></i>
        </div>
      </div>
    </div>
    <div
      v-show="isOpen"
      class="
        max-h-72
        overflow-y-auto
        origin-top-right
        absolute
        right-0
        mt-2
        w-100
        rounded-xl
        shadow-lg
        bg-white
        focus:outline-none
        z-50
      "
    >
      <div
        ref="dropdownList"
        :data-active="selected"
        aria-labelledby="menu-button"
        aria-orientation="vertical"
        class="py-2"
        role="menu"
        tabindex="-1"
      >
        <div
          v-if="tagsList.length"
          class="
            text-gray-900
            block
            cursor-pointer
            hover:bg-gray-50
            active:bg-gray-50
            focus:outline-none
            focus:ring-1
            focus:ring-offset-1
            focus:ring-offset-gray-100
            focus:ring-gray-500
            px-3
            py-2.5
            text-sm
          "
        >
          <div class="field_group m-0">
            <div class="checkbox_container">
              <label
                class="
                  m-0
                  p-0
                  font-weight-500
                  d-flex
                  justify-between
                  items-center
                "
              >
                Select All

                <input
                  :checked="selectedTags.length === tags.length"
                  type="checkbox"
                  @change="handleSelectAll"
                />
                <span class="check mr-1.5 relative flex flex-shrink-0"></span>
              </label>
            </div>
          </div>
        </div>

        <div v-else class="px-3 py-2.5 text-sm">
          <slot name="no-data"> No Data Found!</slot>
        </div>
        <div
          v-for="(tag, i) in tagsList"
          :key="tag.id"
          class="
            text-gray-900
            block
            cursor-pointer
            hover:bg-gray-50
            active:bg-gray-50
            focus:outline-none
            focus:ring-1
            focus:ring-offset-1
            focus:ring-offset-gray-100
            focus:ring-gray-500
            px-3
            py-2.5
            text-sm
          "
        >
          <div class="field_group m-0">
            <div class="checkbox_container">
              <label class="m-0 p-0 d-flex justify-between items-center">
                <span
                  class="px-3 py-2 rounded-md"
                  :style="getLabelColor(getLabelById(tag.id).color)"
                >
                  {{ tag.text }}
                </span>
                <input
                  :checked="selectedTags.includes(tag)"
                  type="checkbox"
                  @change="handleSelect(tag)"
                />
                <span class="check mr-1.5 relative flex flex-shrink-0"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { labelsMixin } from '@common/mixins/publish/labelsMixin'
import useLabelAndCampaignAnalytics from '@modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'

export default {
  name: 'CstLabelsDropdown',
  mixins: [labelsMixin],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Type here...',
    },
  },
  setup() {
    const { getLabelColor } = useLabelAndCampaignAnalytics()
    return {
      getLabelColor,
    }
  },
  data() {
    return {
      isOpen: false,
      search: '',
      selected: 0,
      selectedTags: [],
      tagsList: [],
    }
  },
  watch: {
    tags(val, old) {
      if (!_.isEqual(val, old)) {
        this.tagsList = val

        const tempArr = []
        val.forEach((tag) => {
          if (tag.checked) {
            tempArr.push(tag)
          }
        })

        this.selectedTags = [...tempArr]
      }
    },
    search(newVal) {
      const sorted = this.tagsList.sort((a, b) => {
        if (a.text.toLowerCase().includes(newVal.toLowerCase())) {
          return -1
        }
        if (b.text.toLowerCase().includes(newVal.toLowerCase())) {
          return 1
        }
        if (a.text.startsWith(newVal) && b.text.startsWith(newVal)) {
          return a.text.localeCompare(b.text)
        }

        return 0
      })

      this.tagsList = sorted
    },
    selectedTags(newVal) {
      this.$emit('change', newVal)
    },
  },
  mounted() {
    this.tagsList = [...this.tags]
  },
  methods: {
    handleClickAway() {
      this.isOpen = false
    },
    handleRemove(id) {
      this.selectedTags = this.selectedTags.filter((tag) => tag.id !== id)
      this.isOpen = false
    },
    handleSelectAll(event) {
      if (event.target.checked) {
        this.selectedTags = [...this.tagsList]
        this.isOpen = false
      } else {
        this.selectedTags = []
      }
      this.search = ''
    },
    handleSelect(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter((t) => t.id !== tag.id)
      } else {
        this.selectedTags = this.selectedTags.concat(tag)
      }
    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
        setTimeout(() => {
          this.$refs.dropdownList.focus()
          this.$refs.dropdownList.scrollTop = 0
        }, 100)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cst-tags-dropdown-input {
  min-width: 60px;
}

.checkbox_container {
  label {
    padding: 0 !important;
    margin: 0;
  }
}
</style>
