<template>
  <div class="topic_inner">
    <div class="heading">
      <h3>Preview</h3>
      <p>
        This is the type of content you will get through this automation
        campaign.
      </p>
    </div>
    <template v-if="getPreviewSuggestionLoader">
      <div class="pt-3 pb-3">
        <beat-loader :color="'#436aff'"></beat-loader
      ></div>
    </template>

    <div class="topic_listed_items">
      <template v-if="getPreviewSuggestion.posts.length > 0">
        <div
          v-for="(video, index) in getPreviewSuggestion.posts"
          :key="index"
          class="topic_content_box video_content_box"
        >
          <template v-if="getVideoAutomationDetails.video.source === 'Youtube'">
            <div class="box_inner">
              <div class="d-flex">
                <div class="image_block">
                  <!--.......For video icon..............-->

                  <div
                    v-if="video.snippet && video.snippet.thumbnails.maxres"
                    v-lazy:background-image="
                      videoImageURL(video.snippet.thumbnails.maxres, 'youtube')
                    "
                    class="img"
                  >
                    <i
                      class="cs-play"
                      @click.prevent="
                        previewVideo(
                          getVideoTitle(video),
                          getVideoDescription(video),
                          videoEmbedUrl(video.id, 'youtube'),
                          'Youtube',
                        )
                      "
                    ></i>
                  </div>
                  <div
                    v-else-if="video.snippet && video.snippet.thumbnails.high"
                    v-lazy:background-image="
                      videoImageURL(video.snippet.thumbnails.high, 'youtube')
                    "
                    class="img"
                  >
                    <i
                      class="cs-play"
                      @click.prevent="
                        previewVideo(
                          getVideoTitle(video),
                          getVideoDescription(video),
                          videoEmbedUrl(video.id, 'youtube'),
                          'Youtube',
                        )
                      "
                    ></i>
                  </div>
                  <div
                    v-else-if="
                      video.snippet && video.snippet.thumbnails.default
                    "
                    v-lazy:background-image="
                      videoImageURL(video.snippet.thumbnails.default, 'youtube')
                    "
                    class="img"
                  >
                    <i
                      class="cs-play"
                      @click.prevent="
                        previewVideo(
                          getVideoTitle(video),
                          getVideoDescription(video),
                          videoEmbedUrl(video.id, 'youtube'),
                          'Youtube',
                        )
                      "
                    ></i>
                  </div>
                </div>
                <div class="content_block">
                  <div class="content_block_inner">
                    <h3
                      @click.prevent="
                        previewVideo(
                          getVideoTitle(video),
                          getVideoDescription(video),
                          videoEmbedUrl(video.id, 'youtube'),
                          'Youtube',
                        )
                      "
                    >
                      {{ limitTextLength(getVideoTitle(video), 62) }}</h3
                    >
                    <div class="detail_box d-flex align-items-center">
                      <p
                        v-if="video.snippet && video.snippet.publishedAt"
                        class="date"
                        >{{
                          videoDate('youtube', video.snippet.publishedAt)
                        }}</p
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer clear">
                <div v-tooltip.top="'View'" class="social_detail">
                  <i class="cs-eye"></i>
                  <span v-if="video.statistics" class="state_txt">{{
                    intToString(video.statistics.viewCount)
                  }}</span>
                </div>
                <div v-tooltip.top="'Like'" class="social_detail">
                  <i class="cs-thumbs-up"></i>
                  <span v-if="video.statistics" class="state_txt">{{
                    intToString(video.statistics.likeCount)
                  }}</span>
                </div>
                <div v-tooltip.top="'Comment'" class="social_detail">
                  <i class="cs-comment"></i>
                  <span v-if="video.statistics" class="state_txt">{{
                    intToString(video.statistics.commentCount)
                  }}</span>
                </div>
              </div>
            </div>
          </template>
          <template
            v-else-if="getVideoAutomationDetails.video.source === 'Dailymotion'"
          >
            <div class="box_inner">
              <div class="d-flex">
                <div class="image_block">
                  <div
                    v-lazy:background-image="
                      videoImageURL(video, 'dailymotion')
                    "
                    class="img"
                  >
                    <i
                      class="cs-play"
                      @click.prevent="
                        previewVideo(
                          video.title,
                          '',
                          videoEmbedUrl(video, 'dailymotion'),
                          'Dailymotion',
                        )
                      "
                    ></i>
                  </div>
                </div>

                <div class="content_block">
                  <div class="content_block_inner">
                    <h3>{{ limitTextLength(video.title, 42) }}</h3>
                    <div class="detail_box d-flex align-items-center">
                      <p class="date">{{ videoDate('dailymotion', video) }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="footer clear">
                <div v-tooltip.top="'View'" class="social_detail">
                  <i class="cs-eye"></i>
                  <span class="state_txt">{{
                    intToString(video.views_total)
                  }}</span>
                </div>
                <div v-tooltip.top="'Like'" class="social_detail">
                  <i class="cs-thumbs-up"></i>
                  <span class="state_txt">{{
                    intToString(video.likes_total)
                  }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div v-if="!getPreviewSuggestionLoader" class="no_data_found_content">
          <NoResultsFound></NoResultsFound>
        </div>
      </template>

      <!-- show the loader in case of the paginated posts-->
      <template
        v-if="
          getPreviewSuggestion.posts &&
          getPreviewSuggestion.posts.length >= 20 &&
          getPreviewSuggestionInfiniteLoader == false &&
          getPreviewSuggestionNoMoreResult == false
        "
      >
        <span class="pt-5 d-block">
          <beat-loader :color="'#436aff'"></beat-loader>
        </span>
      </template>

      <template
        v-if="
          getPreviewSuggestionNoMoreResult &&
          getPreviewSuggestion.posts &&
          getPreviewSuggestion.posts.length > 20
        "
      >
        <NoResultsFound
          :more="true"
          :message="'No more results found. Please try adding more keywords or domains'"
        ></NoResultsFound>
      </template>

      <InfiniteLoading
        v-if="
          getPreviewSuggestionInfiniteLoader &&
          getPreviewSuggestionNoMoreResult == false
        "
        ref="infiniteLoading"
        @infinite="onInfinite"
      >
        <span slot="spinner" class="pt-5 d-block">
          <beat-loader :color="'#436aff'"></beat-loader>
        </span>
        <span slot="no-more" class="not_found_text">
          <NoResultsFound :more="true"></NoResultsFound>
        </span>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../../../../discovery/components/topics/NoResultsFound'
export default {
  components: {
    InfiniteLoading,
    NoResultsFound
  },
  methods: {
    ...mapActions([]),
    onInfinite ($state) {
      console.log('PreviewSuggestion infinite Loading...')
      if (this.getPreviewSuggestionScroll) {
        console.log('Inside infinite loading ...')
        this.setPreviewSuggestionPage(this.getPreviewSuggestionPage + 1)
        this.previewVideoSuggestion(
          true,
          $state,
          this.$parent.$parent.youtubeKey
        )
      }
    },
    getVideoTitle (video) {
      return video.snippet ? video.snippet.title : ''
    },
    getVideoDescription (video) {
      return video.snippet ? video.snippet.description : ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getPreviewSuggestion',
      'getPreviewSuggestionScroll',
      'getPreviewSuggestionPage',
      'getPreviewSuggestionInfiniteLoader',
      'getPreviewSuggestionLoader',
      'getPreviewSuggestionNoMoreResult',
      'getVideoAutomationDetails'
    ])
  }
}
</script>
