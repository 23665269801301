<template>
  <div
    class="topics-insights-container__chart_full_width"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Articles Published Over Time & Engagement </h2>
    <div
      id="articlesPublishedOverTime"
      class="topics-insights-container__chart_legends"
    />

    <div class="topics-insights-container__chart_full_width__chart">
      <highcharts
        ref="highcharts_articles_published"
        :options="articlesPublishedOverTime()"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'
import highcharts from "@ui/Highcharts";

export default {
  components: {
    highcharts
  },
  props: {
    articles_published_per_day: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      if (this.articles_published_per_day.hasOwnProperty('buckets')) {
        return this.articles_published_per_day.buckets.length === 0
      }
      return false
    }
  },
  methods: {
    articlesPublishedOverTime () {
      const numberOfArticlesPublished = []
      const numberOfArticlesPublishedEngagements = []

      this.articles_published_per_day.buckets.forEach((item) => {
        const parsedDate = Date.parse(item.key_as_string)
        numberOfArticlesPublished.push([parsedDate, item.doc_count || 0])
        numberOfArticlesPublishedEngagements.push([
          parsedDate,
          Math.ceil(item.avg_stats.value || 0)
        ])
      })

      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,

          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $(chart.series).each(function (i, series) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    getChartColorCode(series) +
                    ';" class="pink color_box"></span> <span>' +
                    series.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (series.visible) {
                      series.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      series.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#articlesPublishedOverTime')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#436aff', '#324380'],

        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%l %P',
            month: '%e. %b',
            year: '%b'
          },
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          labels: {
            style: {
              color: 'rgba(67, 66, 93, 0.5)',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Average Number of Engagements',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: 'rgba(67, 66, 93, 0.5)',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Articles Published',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
          }
        ],
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            baseSeries: 1,
            pointWidth: 10,
            borderWidth: 0,
            // borderRadiusTopLeft: 15,
            // borderRadiusTopRight: 15,
            lineWidth: 2,
            lineColor: '#5467aa',
            groupPadding: 0.1
          }
        },
        series: [
          {
            baseSeries: 1,
            name: 'Number of Articles Published',
            data:
              numberOfArticlesPublished.length > 0
                ? numberOfArticlesPublished
                : [
                  11, 13, 14, 11, 12, 11, 13, 14, 17, 21, 51, 3, 4, 1, 2, 3,
                  3, 7, 2, 7, 1
                ],
            // borderRadiusTopLeft: '50%',
            // borderRadiusTopRight: '50%',
            yAxis: 1,
            borderRadiusTopLeft: 50,
            borderRadiusTopRight: 50,
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1
              },
              stops: [
                [0, '#4e92ff'],
                [1, '#73a3ef']
              ]
            }
          },
          {
            type: 'spline',
            name: 'Average Shares',
            data:
              numberOfArticlesPublishedEngagements.length > 0
                ? numberOfArticlesPublishedEngagements
                : [
                  0, 12, 20, 20, 30, 31, 30, 33, 37, 32, 35, 33, 34, 37, 30,
                  20, 23, 7, 2, 7, 2
                ],
            marker: {
              enabled: false,
              lineWidth: 2,
              lineColor: '#324380',
              fillColor: '#324380',
              yAxis: 1
            }
          }
        ]
      }

      return chartOptions
    }
  }
}
</script>
