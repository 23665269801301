import setting from './states/setting-common'
import notification from './states/notification'
import workspaceNotifications from './states/notifications'
import notificationsPreference from './states/notifications-perference'
import userDisplay from './states/user-display'
import workspaces from './states/workspaces'
import loader from './states/loader'
import team from './states/team'
import sources from './states/sources'
import hashtags from './states/hashtags'
import utm from './states/utm'
import plan from './states/plan'
import contentCategories from './states/content-categories'
import organization from './states/organization'

export const settingStore = {
  modules: {
    setting,
    workspace: workspaces,
    loader,
    team,
    hashtags,
    utms: utm,
    notifications: notification,
    workspaceNotifications,
    notificationsPreference,
    userNotifications: userDisplay,
    plan,
    sources,
    contentCategories,
    organization
  }
}
