<template>
  <div
    class="topics-insights-container__chart_full_width"
    :class="{ 'no-data-available-blur relative': isContentNotAvailable }"
  >
    <template>
      <div
        v-if="isContentNotAvailable"
        class="no_data_for_chart no-data-available-for-charts no-data-available-for-charts-full-width-padding"
      >
        <div>
          <img
            src="../../../../../assets/img/states/no-analytical-data-available.svg"
            alt=""
          />
        </div>
        <p>
          {{ no_data_available }}
        </p>
      </div>
    </template>
    <h2> Popular Content Types </h2>
    <div
      id="popularContentTypesId"
      class="topics-insights-container__chart_legends"
    ></div>

    <div
      class="topics-insights-container__chart_full_width__chart charts-legends-capitalize"
    >
      <highcharts
        ref="top_highcharts_articles_published"
        :options="popularContentTypes()"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { getChartColorCode } from '@common/constants/common-attributes'
import { NO_CHART_DATA } from '@common/constants/messages'
import highcharts from "@ui/Highcharts";

export default {
  components: {
    highcharts
  },
  props: {
    popular_content_types: {}
  },
  data () {
    return {
      no_data_available: NO_CHART_DATA
    }
  },
  computed: {
    isContentNotAvailable () {
      if (
        this.popular_content_types.hasOwnProperty('docs') &&
        this.popular_content_types.hasOwnProperty('engagements')
      ) {
        return (
          this.popular_content_types.docs.length +
            this.popular_content_types.engagements.length ===
          0
        )
      }
      return false
    }
  },
  methods: {
    popularContentTypes () {
      const chartOptions = {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:,.0f} with <span style="text-transform: capitalize;">{point.category}</span>.</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              $('#popularContentTypesId').html('')
              const chart = this
              $(chart.series).each(function (i, serie) {
                console.debug('highcharts_articles_published', serie.color)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#popularContentTypesId')
              })
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#4e92ff', '#2866bb'],

        xAxis: {
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          categories:
            this.popular_content_types.categories &&
            this.popular_content_types.categories.length > 0
              ? this.popular_content_types.categories
              : [
                'Listicles',
                'How to',
                'Articles',
                'Video',
                'Podcast',
                'Ebook'
              ],
          title: {
            text: ''
          },
          labels: {
            style: {
              color: '#989eb5',
              fontSize: '13px'
            }
          }
        },
        yAxis: [
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Avg Engagement Per Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0
            // categories: ['0', '5', '10', '15', '20', '25']
          },
          {
            gridLineWidth: 1,
            labels: {
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            gridLineColor: '#eaf0f4',
            title: {
              text: 'Number of Published Content',
              style: {
                color: '#989eb5',
                fontSize: '13px'
              }
            },
            min: 0,
            opposite: true
            // categories: ['0', '5', '10', '15', '20', '25']
          }
        ],
        plotOptions: {
          series: {
            borderRadiusTopLeft: 30,
            borderRadiusTopRight: 30,
            pointPadding: 0,
            groupPadding: 0.42
          }
        },
        series: [
          {
            // type: 'column',
            name: 'Number of Content Items',
            data:
              this.popular_content_types.docs &&
              this.popular_content_types.docs.length > 0
                ? this.popular_content_types.docs
                : [33, 45, 22, 66, 88, 34],
            yAxis: 1
          },
          {
            // type: 'column',
            name: 'Avg Engagement Per Content',
            data:
              this.popular_content_types.engagements &&
              this.popular_content_types.engagements.length > 0
                ? this.popular_content_types.engagements
                : [25, 40, 13, 33, 44, 22]
          }
        ]
      }

      return chartOptions
    }
  }
}
</script>

<style lang="scss">
.popular-content-types {
  .highcharts-xaxis-label {
    text-transform: capitalize;
  }
}
</style>
