<template>
  <div
    class="bg-white rounded-[8px] flex"
    style="box-shadow: rgb(0 0 0 / 15%) 0 8px 40px"
  >
    <div class="w-full min-w-[28rem] max-w-[28rem] p-6">
      <!--head-->
      <div class="flex gap-2">
        <img
          src="../../../../assets/img/icons/lock_black.svg"
          class="w-5 mt-[5px] h-[16px]"
          alt=""
        />
        <span class="text-xl text-black-900 font-black mt-0.5"
          >Competitor Analytics</span
        >
      </div>
      <!--body-->
      <div class="mt-4 text-justify text-base font-normal tracking-normal"
        >Unlock the secrets of your competitors and get ahead with Competitor
        Analytics. Gather insights and use them to develop effective strategies
        for success!
      </div>
      <div class="my-[20px] px-10 w-full flex gap-4">
        <label
          class="w-40 inline-flex justify-between bg-gray-200 rounded-[8px] p-2.5 cursor-pointer"
        >
          <span class="w-full ml-4 py-3">
            <span class="text-black-900 text-base">
              <b>$</b><span class="text-3xl font-black">15</span>/month</span
            >
            <span class="block text-black-900 text-xs">Billed Monthly</span>
          </span>
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
          >
            <input
              v-model="state.planType"
              type="radio"
              name="competitor_analytic_price"
              value="monthly"
              class="scale-125"
            />
          </span>
        </label>
        <label
          class="w-40 inline-flex justify-between bg-gray-200 rounded-[8px] p-2.5 cursor-pointer"
        >
          <span class="w-full ml-4 py-3">
            <span class="text-black-900 text-base">
              <b>$</b><span class="text-3xl font-black">99</span>/yearly</span
            >
            <span class="block text-black-900 text-xs">Billed Yearly</span>
          </span>
          <span>
            <input
              v-model="state.planType"
              type="radio"
              name="competitor_analytic_price"
              value="annual"
              class="scale-125"
            />
          </span>
        </label>
      </div>
      <div
        class="text-base font-bold leading-5 tracking-normal text-left text-black-900"
        >With Competitor Analytics, you can quickly:
      </div>
      <div class="mt-2">
        <div class="flex gap-3 my-1.5 items-baseline">
          <img src="../../../../assets/img/icons/tick_green.svg" class="h-[10px]" alt="" />
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
            >Compare competitors’ performance</span
          >
        </div>
        <div class="flex gap-3 my-1.5 items-baseline">
          <img src="../../../../assets/img/icons/tick_green.svg" class="h-[10px]" alt="" />
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
            >Analyze growth trends</span
          >
        </div>
        <div class="flex gap-3 my-1.5 items-baseline">
          <img src="../../../../assets/img/icons/tick_green.svg" class="h-[10px]" alt="" />
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
            >Review posting activities</span
          >
        </div>
        <div class="flex gap-3 my-1.5 items-baseline">
          <img src="../../../../assets/img/icons/tick_green.svg" class="h-[10px]" alt="" />
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
            >Find out the most engaging hashtags
          </span>
        </div>
        <div class="flex gap-3 my-1.5 items-baseline">
          <img src="../../../../assets/img/icons/tick_green.svg" class="h-[10px]" alt="" />
          <span
            class="text-base font-normal leading-5 tracking-normal text-left"
            >Review their top and least performing posts
          </span>
        </div>
      </div>
      <div class="px-0.5 mt-10 flex items-center gap-4">
        <div
          class="w-full border-2 border-solid border-cs-primary text-cs-primary font-medium text-base text-center rounded-lg px-5 py-3 cursor-pointer"
          @click="handleUpgradePlan"
          >Upgrade Plan
        </div>
        <div
          v-tooltip.top="{
            content: state.planType === '' ? 'Kindly select a subscription plan prior to proceeding: $15/month OR $99/year' : '',
            delay: 0,
          }"
          :class="{
            'bg-opacity-50 cursor-not-allowed select-none': state.planType === '',
          }"
          class="w-full bg-cs-primary text-white font-medium text-base text-center rounded-lg px-5 py-3.5 cursor-pointer"
          @click="handlePurchaseNow"
          >Purchase Now
        </div>
      </div>
    </div>
    <div class="w-full pt-6 pr-6 pb-6 pl-0">
      <iframe
        class="w-full lg:min-w-[27rem] xl:min-w-[35rem] 2xl:min-w-[48rem] h-full rounded-2xl"
        src="https://www.youtube.com/embed/MDksxxwcTsw?showinfo=0&rel=0"
        title="How to Beat the Competition with Instagram Competitor Analytics"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */
import { computed, inject, reactive } from 'vue'
import { paddle_ids } from '@common/constants/pricing.js'
import { useStore } from '@state/base'

const props = defineProps({
  type: {
    type: String,
    default: 'instagram',
  },
})

const { dispatch, getters } = useStore()
const root = inject('root')
const { $bvModal } = root
const state = reactive({ planType: '' })
const handlePurchaseNow = (event) => {
  const self = this;
  if (state.planType === '') return
  const passthrough = {
    user_id: getSuperAdmin.value?._id,
    user_email: getSuperAdmin.value?.email,
  }

  Paddle.Checkout.open({
    email: getSuperAdmin.value?.email,
    title: 'Competitor Analytics',
    passthrough: JSON.stringify(passthrough),
    product:
      state.planType === 'monthly'
        ? paddle_ids[process.env.VUE_APP_ENVIRONMENT]
            .competitor_analytics_monthly
        : paddle_ids[process.env.VUE_APP_ENVIRONMENT]
            .competitor_analytics_annual,
    successCallback: function (data) {
      alert('Thanks for your purchase.')
      setTimeout(async () => {
        // reload the page to update the limits
        window.location.reload();
      }, 3000)

    },
  })
}
const getSuperAdmin = computed(() => {
  const teamMembers = getters.getWorkspaces.activeWorkspace.members
  if (teamMembers)
    return teamMembers.find((member) => member.role === 'super_admin').user
  return null
})
const handleUpgradePlan = (event) => {
  // EventBus.$emit('limited-upgrade-plan-dialog',4)
  $bvModal.show('upgrade-plan-dialog')
}
</script>
