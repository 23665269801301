<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import {learnMoreLink} from "@src/modules/planner_v2/composables/postStatus";
import {useStore} from "@state/base";

const props = defineProps({
  msg: {
    type: String,
    required: true,
  },
  canPerform: {
    type: Boolean,

  },
  firstCommentStatus: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  resendNotificationAvailability: {
    type: Boolean,
    required: true,
  },
})

const { getters } = useStore()
const isViewMore = ref(true)
const truncatedMsg = ref(props.msg)

watch(() => isViewMore.value,() => {
  console.log('isViewMore', props.msg.length)
  if(props.msg.length > 100 && isViewMore.value) {
    console.log('view more', props.msg.length)
    isViewMore.value = true
    truncatedMsg.value = props.msg.slice(0, 100) + '...'

  }else if(props.msg.length > 100 && isViewMore.value === false) {
    isViewMore.value = false
    truncatedMsg.value = props.msg
  }
}, { immediate: true })



</script>

<template>
  <div class="py-4 px-[1.5rem] bg-[#F4F6FA] flex flex-col">
    <div v-if="msg.length <= 100" class="text-[#202324] text-sm text-left">
      {{msg}} <span class="text-[#B52D4A]">[Failed]</span>
    </div>
    <div v-else-if="msg.length > 100" class="text-[#202324] text-sm text-left">
      {{truncatedMsg}} <span class="text-[#B52D4A]">[Failed]</span> <span class="text-[#2961D2] cursor-pointer" @click="isViewMore = !isViewMore">{{!isViewMore ? 'View Less' : 'View More'}}</span>
    </div>
    <div v-if="!firstCommentStatus && canPerform" class="flex align-items-center justify-content-center">
      <a v-if="learnMoreLink(type) !== ''" :href="learnMoreLink(type)" target="_blank" class="svg-hover px-1 py-[0.625rem] text-[#2961D2] hover:text-[#214EA8] align-text-center">
        <span class="mr-2 failed-options remove-margin">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.22607 1.95455C4.61112 1.95455 1.68062 4.88505 1.68062 8.5C1.68062 12.115 4.61112 15.0455 8.22607 15.0455C11.841 15.0455 14.7715 12.115 14.7715 8.5C14.7715 4.88505 11.841 1.95455 8.22607 1.95455ZM0.226074 8.5C0.226074 4.08172 3.8078 0.5 8.22607 0.5C12.6444 0.5 16.2261 4.08172 16.2261 8.5C16.2261 12.9183 12.6444 16.5 8.22607 16.5C3.8078 16.5 0.226074 12.9183 0.226074 8.5ZM8.22607 7.77273C8.62774 7.77273 8.95335 8.09834 8.95335 8.5V11.4091C8.95335 11.8108 8.62774 12.1364 8.22607 12.1364C7.82441 12.1364 7.4988 11.8108 7.4988 11.4091V8.5C7.4988 8.09834 7.82441 7.77273 8.22607 7.77273ZM8.22607 4.86364C7.82441 4.86364 7.4988 5.18925 7.4988 5.59091C7.4988 5.99257 7.82441 6.31818 8.22607 6.31818H8.23335C8.63501 6.31818 8.96062 5.99257 8.96062 5.59091C8.96062 5.18925 8.63501 4.86364 8.23335 4.86364H8.22607Z" fill="#2961D2"/>
          </svg>
        </span>
        <span class="text-xs ">Learn More</span>
      </a>
      <div v-if="resendNotificationAvailability && type === 'instagram'" data-v-2ac61aa1="" class="mx-1 h-[1.2rem] w-px bg-[#D2D5DF]"></div>
      <div v-if="resendNotificationAvailability && type === 'instagram'" class="svg-hover px-1 py-[0.625rem] text-[#2961D2] cursor-pointer hover:text-[#214EA8] align-text-center" @click="$emit('resend-mobile-notification')">
        <span class="mr-2 failed-options remove-margin">
          <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50003 0.5C6.14962 0.5 4.85452 1.03645 3.89963 1.99133C2.94475 2.94622 2.4083 4.24132 2.4083 5.59173C2.4083 8.03042 1.88671 9.54482 1.40603 10.4261C1.16469 10.8685 0.929681 11.1588 0.766434 11.3311C0.684621 11.4175 0.620331 11.4747 0.581622 11.507C0.562258 11.5231 0.549271 11.533 0.543671 11.5372L0.541592 11.5387C0.280834 11.7179 0.165441 12.0455 0.257342 12.349C0.350228 12.6558 0.632984 12.8656 0.953521 12.8656H14.0465C14.3671 12.8656 14.6498 12.6558 14.7427 12.349C14.8346 12.0455 14.7192 11.7178 14.4585 11.5387L14.4564 11.5372C14.4508 11.533 14.4378 11.5231 14.4184 11.507C14.3797 11.4747 14.3154 11.4175 14.2336 11.3311C14.0704 11.1588 13.8354 10.8685 13.594 10.4261C13.1133 9.54482 12.5918 8.03042 12.5918 5.59173C12.5918 4.24132 12.0553 2.94622 11.1004 1.99133C10.1455 1.03645 8.85044 0.5 7.50003 0.5ZM12.3169 11.1227C12.3722 11.2242 12.4278 11.3202 12.4831 11.4108H2.51692C2.57226 11.3202 2.62783 11.2242 2.68318 11.1227C3.29358 10.0036 3.86308 8.24477 3.86308 5.59173C3.86308 4.62715 4.24626 3.70208 4.92832 3.02002C5.61038 2.33796 6.53545 1.95478 7.50003 1.95478C8.46461 1.95478 9.38968 2.33796 10.0717 3.02002C10.7538 3.70208 11.137 4.62715 11.137 5.59173C11.137 8.24477 11.7065 10.0036 12.3169 11.1227ZM6.87085 14.6828C6.66928 14.3353 6.22417 14.217 5.87668 14.4186C5.52918 14.6202 5.41089 15.0653 5.61247 15.4128C5.80429 15.7435 6.07962 16.018 6.4109 16.2088C6.74217 16.3996 7.11775 16.5 7.50004 16.5C7.88234 16.5 8.25792 16.3996 8.58919 16.2088C8.92047 16.018 9.1958 15.7435 9.38762 15.4128C9.5892 15.0653 9.47091 14.6202 9.12341 14.4186C8.77592 14.217 8.33081 14.3353 8.12924 14.6828C8.06529 14.793 7.97352 14.8845 7.86309 14.9481C7.75267 15.0117 7.62748 15.0452 7.50004 15.0452C7.37261 15.0452 7.24742 15.0117 7.13699 14.9481C7.02657 14.8845 6.93479 14.793 6.87085 14.6828Z" fill="#2961D2"/>
          </svg>
        </span>
        <span class="text-xs ">Send Mobile Notification</span>
      </div>
      <div v-if="learnMoreLink(type) !== ''" class="mx-1 h-[1.2rem] w-px bg-[#D2D5DF]"></div>
      <div class="px-1 py-[0.625rem] text-[#2961D2] cursor-pointer hover:text-[#214EA8] svg-hover align-text-center" @click="$emit('retry-post')">
        <span class="mr-2 failed-options remove-margin">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2489 0.999326C9.15047 0.750802 8.00703 0.784572 6.92522 1.09749C5.84686 1.4094 4.86477 1.98856 4.06999 2.78118L2.10726 4.62548V2.16716C2.10726 1.79897 1.80878 1.50049 1.44059 1.50049C1.0724 1.50049 0.773926 1.79897 0.773926 2.16716L0.773926 6.16684L0.773926 6.16716C0.773926 6.53535 1.0724 6.83383 1.44059 6.83383H5.44059C5.80878 6.83383 6.10726 6.53535 6.10726 6.16716C6.10726 5.79897 5.80878 5.50049 5.44059 5.50049H3.12316L4.99044 3.74588C4.99552 3.74111 5.00052 3.73626 5.00545 3.73134C5.64234 3.09413 6.43026 2.62864 7.29571 2.37831C8.16115 2.12798 9.0759 2.10097 9.95461 2.29979C10.8333 2.4986 11.6473 2.91678 12.3207 3.51529C12.9941 4.1138 13.5049 4.87313 13.8054 5.72245C13.9283 6.06954 14.3092 6.25135 14.6563 6.12853C15.0034 6.0057 15.1852 5.62475 15.0624 5.27765C14.6867 4.21601 14.0482 3.26684 13.2065 2.5187C12.3648 1.77057 11.3472 1.24785 10.2489 0.999326ZM16.7362 8.6118C16.7063 8.52689 16.6587 8.44668 16.5931 8.37686C16.4555 8.23048 16.2674 8.16005 16.0813 8.16721H12.1074C11.7392 8.16721 11.4408 8.46569 11.4408 8.83388C11.4408 9.20206 11.7392 9.50054 12.1074 9.50054H14.4237L12.5574 11.2542L12.5514 11.26L12.5424 11.2688C11.9055 11.906 11.1176 12.3715 10.2521 12.6218C9.3867 12.8721 8.47195 12.8991 7.59324 12.7003C6.71453 12.5015 5.90051 12.0833 5.22712 11.4848C4.55374 10.8863 4.04295 10.127 3.7424 9.27765C3.61958 8.93055 3.23863 8.74874 2.89153 8.87157C2.54443 8.9944 2.36262 9.37535 2.48545 9.72244C2.86113 10.7841 3.49962 11.7333 4.34135 12.4814C5.18308 13.2295 6.20061 13.7522 7.29899 14.0008C8.39738 14.2493 9.54082 14.2155 10.6226 13.9026C11.701 13.5907 12.6831 13.0115 13.4778 12.2189L15.4408 10.3745V12.8339C15.4408 13.2021 15.7392 13.5005 16.1074 13.5005C16.4756 13.5005 16.7741 13.2021 16.7741 12.8339V8.83388C16.7741 8.75601 16.7607 8.68126 16.7362 8.6118Z" fill="#2961D2"/>
          </svg>
        </span>
        <span class="text-xs whitespace-nowrap">Retry</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.svg-hover:hover svg path {
  fill: #214EA8;
}
@media (width <= 1366px) {
  .failed-options{
    display: block;
  }
  .align-text-center{
   text-align: center;
  }
  .remove-margin{
    margin: 0;
  }
}
@media only screen and (min-width: 1366px) {
  @media {
    .failed-options{
      display: block;
    }
    .align-text-center{
      text-align: center;
    }
    .remove-margin{
      margin: 0;
    }
  }}
</style>
