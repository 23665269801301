import { computed } from 'vue'
import { useStore } from '@state/base'
import { onboardingStepsURL, setWorkspacePreferencesUrl } from '@src/modules/setting/config/api-utils'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import proxy from '@common/lib/http-common'

export default function useWorkspace() {
  const store = useStore()

  const isActiveUserSuperAdmin = () => {
    const teamMembers = store.getters.getActiveWorkspace.members
    for (let i = 0; i < teamMembers.length; i++) {
      const member = teamMembers[i]
      if (member.user && store.getters.getProfile.email === member.user.email) {
        return member.role === 'super_admin'
      }
    }
    return false
  }

  const getSuperAdmin = () => {
    if (
      store.getters.getActiveWorkspace &&
      store.getters.getActiveWorkspace.members
    ) {
      const superAdmin = store.getters.getActiveWorkspace.members.find(
        (member) => member.role === 'super_admin'
      )
      if (superAdmin) {
        return superAdmin.user
      }
    }
    return store.getters.getProfile
  }

  const onboardingStepsCompleted = async (step = null) => {
    if (step) {
      try {
        const resp = await proxy.post(onboardingStepsURL, {
          workspace_id: store.getters.getActiveWorkspace._id,
          step,
        })

        if (resp.data.status === true) {
          store.commit(
            workspaceTypes.SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS,
            resp.data.steps
          )
        }
      } catch (e) {
        console.error('ERROR IN ONBOARDING STEP', e)
      }
    }
  }

  const isMonthlyPlan = () => {
        const plan = store.getters?.getPlan?.subscription
        return plan?.fastspring_name?.includes('monthly') || plan?.display_name?.includes('Monthly')
     }

  const getSubscriptionLimits = (key) => {
    if (
        store.getters?.getPlan &&
        store.getters?.getPlan.subscription &&
        store.getters?.getPlan.subscription.limits &&
        store.getters?.getPlan.subscription.limits[key]
    ) {
      return store.getters.getPlan.subscription.limits[key] ?? 0
    }
    return 0
  }

  const getUsedLimits = (key) => {
    if (
        store.getters?.getPlan &&
        store.getters?.getPlan.used_limits &&
        store.getters?.getPlan.used_limits[key]
    ) {
      return store.getters?.getPlan.used_limits[key] ?? 0
    }
    return 0
  }
  const updateWorkspacePreferences = async (key, data) => {

    try {
      const resp = await proxy.post(
        setWorkspacePreferencesUrl,
        {
          workspace_id: store.getters.getActiveWorkspace?._id,
          preferences: {
            [key]: data,
          },
        }
      )
      if (resp.data.status === true) {
        store.commit(workspaceTypes.SET_WORKSPACE_PREFERENCES, resp.data.preferences)
      }
    } catch (e) {
      console.error('ERROR IN SETTING PREFERENCES', e)
    }
  }

  const getPreferences = computed(() => {
    const activeWorkspace = store.getters.getWorkspaces?.items?.find(
      (item) => item.workspace_id === store.getters.getActiveWorkspace._id
    )

    if (activeWorkspace) {
      return activeWorkspace?.preferences || {}
    }
    return null
  })

  return {
        // computed
        getPreferences,
        // onboarding
        onboardingStepsCompleted,
        // permissions
        isActiveUserSuperAdmin,
        getSuperAdmin,
        isMonthlyPlan,
        getSubscriptionLimits,
        getUsedLimits,
        updateWorkspacePreferences,
    }
}
