<template>
  <div class="card_v1">
    <div class="card_inner">
      <div class="card_head">
        <h2>Articles Published Over Time & Sentiment</h2>
        <ul id="article_published_item_highlight" class="ds_item_highlight">
        </ul>
      </div>

      <div class="card_content" :class="{ card_content_blur: isZero() }">
        <template v-if="isZero()">
          <div class="no_data_for_chart">{{ noDataMessage }}.</div>
        </template>
        <div :class="{ blur_div: isZero() }">
          <highcharts
            ref="article_published_chart"
            :options="articlePublishedSentiments"
          ></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NO_DATA_AVAILABLE } from '@common/constants/messages'
import { mapGetters } from 'vuex'

import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: {
    articles_sentiments: {
      default: {}
    }
  },
  computed: {
    ...mapGetters(['getInsightsArticlesPublishedSentiments'])
  },
  data () {
    return {
      noDataMessage: NO_DATA_AVAILABLE,
      articlePublishedSentiments: {
        credits: {
          enabled: false
        },
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y:f}</span> on {point.x:%A, %B %e}</p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        chart: {
          type: 'column',
          spacingBottom: 10,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function (event) {
              const chart = this
              $(chart.series).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="background: ' +
                    serie.color +
                    ';" class="pink color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#article_published_item_highlight')
              })
              event.target.reflow()
            }
          }
        },
        legend: {
          enabled: false
        },

        title: {
          text: ''
        },
        colors: ['#6ee5a5', '#e6ce6e', '#e6766e'],

        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%l %P',
            month: '%e. %b',
            year: '%b'
          },
          gridLineWidth: 1,
          gridLineColor: '#eaf0f4',
          title: {
            text: ''
          },
          labels: {
            style: {
              color: 'rgba(67, 66, 93, 0.5)',
              fontSize: '13px'
            }
          }
        },
        yAxis: {
          gridLineWidth: 1,
          labels: {
            style: {
              color: 'rgba(67, 66, 93, 0.5)',
              fontSize: '13px'
            }
          },
          gridLineColor: '#eaf0f4',
          title: {
            text: 'Number of Articles Published'
          },
          min: 0
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            pointWidth: 25,
            borderWidth: 0,
            lineWidth: 3
          }
        },
        series: [
          {
            type: 'spline',
            name: 'Positive',
            data: [51, 63, 14, 11, 12, 11, 63, 14, 17, 61, 72, 67, 51]
          },
          {
            type: 'spline',
            name: 'Neutral',
            data: [21, 13, 14, 11, 12, 81, 23, 4, 17, 21, 32, 27, 11]
          },
          {
            type: 'spline',
            name: 'Negative',
            data: [1, 3, 14, 11, 12, 61, 3, 14, 17, 1, 2, 7, 1]
          }
        ]
      }
    }
  },
  created () {
    if (this.articles_sentiments.positive.length > 0) {
      this.articlePublishedSentiments.series[0].data =
        this.articles_sentiments.positive
    }
    if (this.articles_sentiments.neutral.length > 0) {
      this.articlePublishedSentiments.series[1].data =
        this.articles_sentiments.neutral
    }
    if (this.articles_sentiments.negative.length > 0) {
      this.articlePublishedSentiments.series[2].data =
        this.articles_sentiments.negative
    }
  },
  methods: {
    isZero () {
      return (
        this.articles_sentiments.positive.length === 0 &&
        this.articles_sentiments.neutral.length === 0 &&
        this.articles_sentiments.negative.length === 0
      )
    }
  }
}
</script>
