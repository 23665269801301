<template>
  <b-modal
    v-model="modalToggler"
    modal-class="ai-caption-modal side_slide_right"
    title="AiCaptionGeneration"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    @hidden="closeModal"
    @shown="handleShow"
  >
    <!-- modal head -->
    <template v-slot:modal-header>
      <div class="w-full flex items-center justify-between">
          <!-- modal title -->
          <div class="flex items-center">
              <img
                      src="@assets/img/common/cyrus-bot.svg"
                      alt=""
                      class="w-10 h-10 mr-3"
              />
              <span class="font-medium text-lg mt-2">AI Captions</span>
          </div>
          <!-- captions limit -->
          <div class="flex">
              <div
v-tooltip="{
                content: captionLimitsTooltip,
                allowHTML: true,
            }">
                  <b-progress
                          :variant="
                getCreditUsedLimit >= getCreditSubscribeLimit
                  ? 'danger'
                  : 'primary'
              "
                          class="mt-4 min-w-[13rem]"
                          height="8px"
                          :value="getCreditUsedLimit"
                          :max="getCreditSubscribeLimit"
                  ></b-progress>
                  <div class="flex justify-between">
                      <span> AI Credits(words) </span>
                      <span>
                {{ formatNumber(getCreditUsedLimit) }} of
                {{ formatNumber(getCreditSubscribeLimit) }}
              </span>
                  </div>
              </div>
          <button
            id="close"
            type="button"
            class="modal_head__close ml-5 inline-block"
            @click="closeModal"
            >&times;
          </button>
        </div>
      </div>
    </template>
    <!-- modal body -->
      <div class="flex flex-row h-full" style="max-height: calc(100vh - 70px)">
        <div class="relative w-full min-w-[30rem] max-w-[30rem] flex flex-col">
          <!-- Caption Limit Exceeded -->
          <template v-if="isLimitExceeded">
            <div class="p-14 text-center">
              <h4 class="mb-4 text-2xl">
                AI Text Credits(words) Fully Consumed
              </h4>
              <p v-if="isActiveUserSuperAdmin">
                Hi <b>{{ store.getters.getProfile.full_name }}</b
                >, the AI text credits(words) allowed for this account have been
                fully consumed. If you think the credits
                {{ formatNumber(getCreditSubscribeLimit) }}
                are not enough for your monthly usage, you can always purchase
                more by clicking on the button below.
              </p>
              <p v-else>
                Hi {{ store.getters.getProfile.full_name }}, the caption
                generation credits allowed for this workspace's account have
                been fully consumed. If you think the credits
                {{ formatNumber(getCreditUsedLimit) }}
                are not enough for your monthly usage, please ask the super
                admin of the workspace
                <b>{{ store.getters.getWorkspaces.activeWorkspace.name }}</b> to
                purchase more uninterrupted usage.
              </p>

              <div
                class="compose_warning_box text-center mt-3 mb-4 flex justify-center items-center p-1"
              >
                <p>
                  Note: Credits automatically get fully restored on 1st of every
                  month :)
                </p>
              </div>
              <div v-if="isActiveUserSuperAdmin" class="">
                <div
                  class="text_center"
                  @click="closeModal(), $bvModal.show('increase-limits-dialog')"
                >
                  <b-button variant="studio-theme-space"
                    >Increase AI Text Credits(words) Limits Now
                  </b-button>
                </div>
              </div>
            </div>
          </template>
          <!-- Limit Not Exceeded -->
          <template v-if="!isLimitExceeded">
            <div class="overflow-y-auto px-5 pt-5 pb-24">
              <!-- Select prompt dropdown -->
              <div>
                <CstDropdown
                  class="w-full"
                  size="small"
                  button-classes="flex justify-between rounded-lg bg-cs-light-gray"
                  container-classes="max-h-[20rem]"
                >
                  <template v-slot:selected>
                    {{
                      input.heading
                        ? input.heading
                        : 'Select a predefined prompt or describe your idea below'
                    }}
                  </template>
                  <template v-slot>
                    <template v-for="(item, pi) in predefinePrompts" :key="pi">
                      <CstDropdownItem
                        :class="{
                          'bg-gray-100': input.heading === item.heading,
                        }"
                        @click="() => handlePromptSelection(item)"
                      >
                        <div class="flex items-center justify-between">
                          <div class="w-max">
                            <span class="font-medium block text-gray-800">{{
                              item.heading
                            }}</span>
                            <span class="text-gray-700">{{
                              item.subHeading
                            }}</span>
                          </div>
                          <div
                            v-if="input.heading === item.heading"
                            class="w-max"
                          >
                            <SimpleCheckbox
                              :name="item.heading"
                              :value="input.heading === item.heading"
                            ></SimpleCheckbox>
                          </div>
                        </div>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
              </div>
              <!-- write prompt textarea -->
              <div class="mt-5">
                <textarea
                  ref="promptInput"
                  v-model="input.prompt"
                  :placeholder="input.placeholder"
                  :rows="textareaHeight"
                  class="resize-none rounded-xl w-full p-3 border-0 color-border cst-caption-editor"
                >
                </textarea>
              </div>
              <!-- No of Words -->
              <div class="mt-5 w-full">
                <div class="flex justify-between items-center">
                  <span class="ml-2 font-normal text-sm text-black-300"
                    >Approx. Words
                    <i
v-tooltip="{
                      content: 'This approximate number of words in the output may\n' +
'                          vary slightly depending on the topic you provide. By\n' +
'                          default, the number of words is set according to the\n' +
'                          selected prompt, but you can adjust the length to suit\n' +
'                          your needs.',
                    }" class="far fa-question-circle p-0" />
                  </span>
                  <input
                    v-model="wordCount"
                    type="number"
                    min="1"
                    max="750"
                    class="w-14 !mb-0 font-medium rounded-lg color-border shadow-xs font-normal text-gray-900 focus:outline-none bg-cs-light-gray"
                  />
                </div>
                <div class="mt-3 px-1">
                  <input
                    v-model="wordCount"
                    type="range"
                    min="1"
                    max="750"
                    style="-webkit-appearance: none"
                    class="w-full h-2 rounded-lg bg-gray-200 cursor-pointer"
                  />
                </div>
              </div>
              <!-- select tune dropdown -->
              <div class="flex items-center mt-6">
                <span class="font-normal text-sm text-black-300 min-w-[40%]"
                  >Voice Tone</span
                >
                <CstDropdown
                  class="w-full"
                  size="small"
                  dropdown-placement="top"
                  container-classes="!max-h-96"
                  button-classes="flex justify-between rounded-lg bg-cs-light-gray"
                >
                  <template v-slot:selected>
                    {{ mode ? mode : 'Select Voice Tone' }}
                  </template>
                  <template v-slot>
                    <template v-for="(tune, ti) in voiceTones" :key="ti">
                      <CstDropdownItem

                        @click="
                          () => {
                            mode = tune
                          }
                        "
                        >{{ tune }}
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
              </div>
              <!-- include hashtags radio -->
              <div class="flex items-center mt-6">
                <span class="font-normal text-sm text-black-300 min-w-[40%]"
                  >Generate Hashtags</span
                >
                <CstSwitch
                  v-model="includeHashtags"
                  class="mr-2"
                  :disabled="input.prompt.trim() === ''"
                  size="small"
                />
              </div>
              <!-- include emojis radio -->
              <div class="flex items-center mt-6">
                <span class="font-normal text-sm text-black-300 min-w-[40%]"
                  >Include Emoji</span
                >
                <CstSwitch
                  v-model="includeEmoji"
                  class="mr-2"
                  :disabled="input.prompt.trim() === ''"
                  size="small"
                />
              </div>
              <!-- variations -->
              <div class="flex items-center mt-5">
                <span class="font-normal text-sm text-black-300 min-w-[40%]"
                  >Number of Variation</span
                >
                <div class="inline-block mr-16 mb-6">
                  <label class="variation_input">
                    <input
                      v-model="variation"
                      type="radio"
                      value="1"
                      name="variations"
                    />
                    <span class="check_btn"> 1 </span>
                  </label>
                </div>
                <div class="inline-block mr-16 mb-6">
                  <label class="variation_input">
                    <input
                      v-model="variation"
                      type="radio"
                      value="2"
                      name="variations"
                    />
                    <span class="check_btn"> 2 </span>
                  </label>
                </div>
                <div class="inline-block mr-16 mb-6">
                  <label class="variation_input">
                    <input
                      v-model="variation"
                      type="radio"
                      value="3"
                      name="variations"
                    />
                    <span class="check_btn"> 3 </span>
                  </label>
                </div>
              </div>

              <div class="absolute bottom-0 bg-white left-0 px-8 py-5 w-full">
                <button
                  v-if="output.length > 0"
                  class="btn btn-studio-theme-green-empty w-full bg-[#26cb85]"
                  :disabled="
                    input.prompt.trim() === '' ||
                    variation > 3 ||
                    isGenerating ||
                    !isGenerated
                  "
                  @click.prevent="() => handleGenerate(true)"
                >
                  <i class="icon-resend-cs text-white mx-2"></i>
                  <span class="text-white">Re-Generate Caption</span>
                </button>
                <CstButton
                  v-else
                  class="w-full"
                  size="default"
                  :disabled="
                    input.prompt.trim() === '' || isGenerating || variation > 3
                  "
                  @click.prevent="() => handleGenerate()"
                >
                  <i class="fa fa-magic mx-2"></i>
                  <span class="">Generate</span>
                </CstButton>
              </div>
            </div>
          </template>
        </div>
        <!-- **** second output column **** -->
        <div
          class="relative transition-all duration-300 w-full min-w-[30rem] w-full max-w-[30rem]"
          :style="{
            'margin-right':
              isGenerating || output.length > 0 ? '0rem' : '-30rem',
          }"
        >
          <!-- output screen-->
          <div
            v-if="output.length > 0 && isGenerated"
            class="h-full bg-cs-light-gray overflow-y-auto"
          >
            <div
              class="bg-cs-light-gray flex items-center justify-between left-1 px-5 pt-5 pb-3 sticky top-0"
            >
              <span class="font-medium text-base"> Results </span>
              <!-- show Caption history -->
              <div
                  v-if="history.length > 1"
                  v-tooltip="{
                  content: 'History of generated captions is maintained for the\n' +
'                        current post. You can choose the previous captions if\n' +
'                        you want.',
                }"
                  class="mr-2 w-max relative inline-block"
              >
                  <span
                      class="font-medium text-sm cursor-pointer hover:bg-gray-100 px-2 py-2 rounded-md"
                      :class="{ 'bg-gray-100': showHistory }"
                      :disabled="isGenerating"
                      @click="showHistory = !showHistory"
                  >
                    <i class="fa fa-history mr-1" aria-hidden="true"></i>
                    <span class=""> Show Caption History</span>
                  </span>
                <div
                    v-if="showHistory"
                    class="absolute top-8 -left-4 bg-white rounded-lg border border-black shadow-md z-20 p-1 overflow-auto max-h-80"
                >
                  <template v-for="(item, key) in history.slice().reverse()" :key="`caption-${key}`">
                    <div

                        class="flex flex-col items-start hover:bg-gray-100 m-1 p-1 text-gray-800 hover:text-black-900 cursor-pointer select-none w-full rounded-md w-[11rem]"
                        @click="
                        () => {
                          output = [...item.caption]
                          showHistory = false
                        }
                      "
                    >
                      <p class="text-sm font-medium !m-0"
                      >Caption Version {{ history.length - key }}</p
                      >
                      <p class="text-xs !m-0">{{ item.generatedTime }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="px-5 pt-2 pb-10">
              <template v-for="(result, oi) in output" :key="oi">
                <div

                  class="p-3 mb-3 rounded-xl w-full border-0 color-border cst-caption-editor whitespace-pre-line select-none hover:bg-cs-slate hover:border-blue-300"
                >
                  <span v-html="linkifyText(result)"></span>
                  <div class="flex items-center justify-between mt-2">
                    <span class="text-gray-700 text-xs"
                      >{{ countWords(result) }} words /
                      {{ result.length }} chars</span
                    >
                    <div>
                      <span
                        class="py-1 px-3 border rounded-lg cursor-pointer text-sm font-normal text-gray-700 hover:text-black-900 mr-2"
                        @click="() => copyToClipboard(result)"
                      >
                        <i class="icon-duplicate-cs text-xs"></i> Copy
                      </span>
                      <span
                        class="py-1 px-3 border rounded-lg cursor-pointer text-sm font-normal text-gray-700 hover:text-black-900"
                        @click="() => addToEditor(result)"
                      >
                        <i class="icon-add-new-member-cs text-xs"></i> Add to
                        Editor
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- loading screen -->
          <div
            v-else
            class="w-full flex flex-col h-full px-8 pb-10 items-center bg-cs-light-gray overflow-y-auto"
          >
            <img
              src="@src/assets/img/captionGenerationBot.gif"
              alt="ContentStudio logo"
              class="w-full mt-16 mb-16 px-8"
            />

            <p class="mb-6 text-center text-base font-medium">
              Our AI Bot is busy generating the caption!
            </p>
            <div class="text-zinc-300 flex flex-col">
              <template v-for="(step, si) in steps" :key="si">
                <p class="mb-3">
                  <span
                    class="w-7 h-7 mr-2 rounded-full inline-flex justify-center items-center bg-cs-white drop-shadow-lg"
                    :class="{
                      'bg-green-400': step.status === true,
                      'bg-cs-white': step.status === false,
                    }"
                  >
                    <i
                      v-if="step.status === true"
                      class="fa fa-check text-white"
                    ></i>
                    <i
                      v-else
                      class="fa fa-spinner fa-pulse fa-fw text-blue-400"
                    ></i>
                  </span>
                  <span :class="{ 'text-green-600': step.status }">
                    {{ step.message }}
                  </span>
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
  </b-modal>
</template>

<script>
import {ref, computed, onMounted, onUnmounted, watch, inject, nextTick} from 'vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import CstSwitch from '@ui/Switch/CstSwitch.vue'
import CstButton from '@ui/Button/CstButton.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import { EventBus } from '@common/lib/event-bus'
import linkifyHtml from 'linkify-html'
import proxy from '@common/lib/http-common'
import { fetchAiCaption } from '@src/modules/publish/config/api-utils'
import { useStore } from '@state/base'
import { commonMethods } from '@common/store/common-methods'
const clone = require('rfdc/default')

const promptsTemplate = [
  {
    heading: 'Social media post caption',
    subHeading: 'Engage your audience with attractive captions',
    prompt: '',
    wordCount: 25,
    placeholder:
      'Describe the topic for which you need Post Caption Ideas.\n\nFor example:\n\n“Importance of social media marketing in fashion & beauty business growth”',
  },
  {
    heading: 'Tweet Idea',
    subHeading: 'Engage your followers with retweetable content',
    prompt: '',
    wordCount: 40,
    placeholder:
      'Describe the topic for which you need Tweet Ideas.\n\nFor example:\n\n“Marketing a social media management tool online.” ',
  },
  {
    heading: 'Instagram caption',
    subHeading: 'Generate scroll-stopping engaging caption',
    prompt: '',
    wordCount: 25,
    placeholder:
      'Describe the topic for which you need Instagram Caption Ideas.\n\nFor example:\n\n"Technology & Gadgets", "Fashion & Beauty", "Nature" ',
  },
  {
    heading: 'Inspirational quotes',
    subHeading: 'Generate interesting, popular or inspirational quotes',
    prompt: '',
    wordCount: 15,
    placeholder:
      'Describe the topic about which you need Inspirational Quote Ideas.\n\nFor example:\n\n“Life”, “Self-Belief”, “Overcoming Challenges” etc. ',
  },
  {
    heading: 'Extend / Expand',
    subHeading: 'Continue where you’re blocked',
    prompt: '',
    wordCount: 80,
    placeholder:
      'Provide a brief description of the topic that you need to extend.\n\nFor example:\n\n"The future of digital marketing"\n“5 best SEO strategies for better rankings”',
  },
  {
    heading: 'Improve',
    subHeading:
      'Improve your content to make it look exciting and interesting.',
    prompt: '',
    wordCount: 50,
    placeholder:
      'Provide the content that you want to improve to make it look exciting and interesting.\n\nFor example:\n\n"Digital marketing is the perfect way to reach your target audience and grow your business. Make the most of it!"',
  },
  {
    heading: 'Summarize the text',
    subHeading: 'Extract the key points from the given text',
    prompt: '',
    wordCount: 80,
    placeholder: 'Summarize the key points from the given text.',
  },
  {
    heading: 'Summarize the URL',
    subHeading: 'Summarize the key points from the given URL',
    prompt: '',
    wordCount: 80,
    placeholder:
      'Provide a valid URL you want to fetch the content from, to summarize it.\n\nFor example:\n\nhttps://replug.link/xyz',
  },
  {
    heading: 'Pros and cons list',
    subHeading: 'Get a list of pros and cons related to the topic',
    prompt: '',
    wordCount: 80,
    placeholder:
      'Provide a brief description of the topic for which you need a list of pros and cons.\n\nFor example:\n\n“Digital Marketing”,“Technology & Gadgets” ',
  },
  {
    heading: 'Rewrite',
    subHeading: 'Say something differently',
    prompt: '',
    wordCount: 50,
    placeholder:
      'Provide the content that you want the AI to rewrite in order to have a more varied output.\n\nFor example:\n\n“Digital Marketing is the way to success! Get all the latest tips and tricks to take your business to the next level”',
  },
]
const defaultSteps = [
  { message: 'Initializing', status: false },
  { message: 'Refining content', status: false },
  { message: 'Generating caption', status: false },
  { message: 'Your AI caption is ready!', status: false },
]
const defaultInput = {
  heading: '',
  prompt: '',
  placeholder:
    'Describe your idea related to business, service, product, audience, or any topic you want. \n\nFor example,\n\nHelp me write on the topic of “How to make your business stay competitive in the digital world?”',
}
export default {
  name: 'AiCaptionModal',
  components: {
    SimpleCheckbox,
    CstDropdown,
    CstDropdownItem,
    CstSwitch,
    CstButton,
  },
  setup(props, { emit }) {
    const root = inject('root')
    const modalToggler = ref(false)
    const type = ref('common')
    const isOldComposer = ref(false)
    const textareaHeight = ref(15)
    const isGenerating = ref(false)
    const isGenerated = ref(false)
    const predefinePrompts = ref(promptsTemplate)
    const input = ref(clone(defaultInput))
    const promptInput = ref(null)
    const voiceTones = ref([
      'Curious',
      'Ecstatic',
      'Fearful',
      'Formal',
      'Fun',
      'Happy',
      'Hopeful',
      'Hypocritical',
      'Informal',
      'Informative',
      'Joyful',
      'Optimistic',
      'Persuasive',
      'Pessimistic',
      'Professional',
      'Sad',
      'Serious',
      'Sincere',
      'Witty',
    ])
    const mode = ref('')
    const wordCount = ref(50)
    const variation = ref(1)
    const includeHashtags = ref(false)
    const includeEmoji = ref(false)
    const output = ref([])
    const history = ref([])
    const showHistory = ref(false)
    const store = useStore()
    const steps = ref(defaultSteps)

    onMounted(() => {
      EventBus.$on('AiCaptionModal_show', (args) => {
        type.value = args.source ? args.source : 'common'
        isOldComposer.value = args.isOldComposer
        commonMethods.toggleHelpDropdown(true)
        modalToggler.value = true
        steps.value = clone(defaultSteps)
        if (window.innerHeight < 800) textareaHeight.value = 10
      })
      EventBus.$on('AiCaptionModal_reset', () => {
        commonMethods.toggleHelpDropdown(false)
        modalToggler.value = false
        resetState()
      })
      window.addEventListener('resize', () => {
        if (window.innerHeight < 800) textareaHeight.value = 10
        else textareaHeight.value = 15
      })
    })
    onUnmounted(() => {
      EventBus.$off('AiCaptionModal_show')
      EventBus.$off('AiCaptionModal_reset')
      window.removeEventListener('resize', () => {
        textareaHeight.value = 15
      })
    })
    const closeModal = () => {
      console.log('Method:caption Modal Close')
      commonMethods.toggleHelpDropdown(false)
      modalToggler.value = false
    }
    /**
     * reset event handler to reset modal state
     */
    const resetState = () => {
      input.value = clone(defaultInput)
      mode.value = ''
      includeHashtags.value =
        includeEmoji.value =
        isGenerating.value =
        isGenerated.value =
          false
      output.value = []
      steps.value = clone(defaultSteps)
      history.value = []
    }

    const countWords = (str) => {
      str = str.replace(/(^\s*)|(\s*$)/gi, '')
      str = str.replace(/[ ]{2,}/gi, ' ')
      return str.trim().split(/\s+/).length
    }

    const formatNumber = (number) => {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      return formatter.format(number)
    }

    /**
     * predefined prompt selection from dropdown
     */
    const handlePromptSelection = (item) => {
      if (input.value.heading === item.heading) {
        input.value = clone(defaultInput)
        wordCount.value = 50
        return
      }
      input.value.heading = item.heading
      input.value.placeholder = item.placeholder
      wordCount.value = item.wordCount
      promptInput.value.focus()
    }
    /**
     * payload preparation and Api request for caption generation
     */
    const handleGenerate = async (regenerate = false) => {
      isGenerated.value = false
      steps.value = clone(defaultSteps)
      isGenerating.value = true
      const payload = {
          workspace_id: store.getters.getActiveWorkspace._id,
          prompt: input.value.prompt,
          text_length: wordCount.value.toString(),
          tone: mode.value,
          hashtags: includeHashtags.value,
          emojis: includeEmoji.value,
          template: input.value.heading ? input.value.heading : '',
          regenerate,
          variation: Number(variation.value),
      }
      await proxy
        .post(fetchAiCaption, payload)
        .then((response) => {
          if (!response.data.status) {
            if (response.data.creditFull)
              store.getters.getPlan.used_limits.caption_generation_credit =
                response.data.usedCredits
            root.alertMessage(response.data.message, 'error')
            isGenerating.value = false
            return
          }
          output.value = [...response.data.text.map((item) => item.trimStart())]
          history.value.push({
            generatedTime: root.getWorkspaceTimezonCurrentTime('hh:mm A'),
            caption: output.value,
          })
          store.getters.getPlan.used_limits.caption_generation_credit =
            response.data.limits.used
          isGenerating.value = false
        })
        .catch((err) => {
          console.log('FetchAiCaptionCall', err)
          isGenerating.value = false
          root.alertMessage('Something went wrong. Please try again.', 'error')
        })
    }
    const linkifyText = (text) => {
      return linkifyHtml(text, {
        target: '_blank',
      })
    }

    const copyToClipboard = (value) => {
      navigator.clipboard.writeText(value)
      root.alertMessage('Content has been copied.', 'success')
    }

    /**
     * Add Caption To Relevant Editor
     */
    const addToEditor = (value) => {
      // emit caption output,old composer flag and type of editor box which initiate this modal
      emit('caption-output', value, isOldComposer.value, type.value)
      closeModal()
    }

    const getSuperAdminDetails = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      if (teamMembers)
        return teamMembers.find((member) => member.role === 'super_admin').user
      return { full_name: '' }
    })

    const isActiveUserSuperAdmin = computed(() => {
      const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
      if (teamMembers) {
        return teamMembers.find(
          (member) =>
            member.role === 'super_admin' &&
            store.getters.getProfile.email === member.user.email
        )
      }
      return false
    })

    const isLimitExceeded = computed(() => {
      if (store.getters.getPlan.subscription.limits)
        return (
          store.getters.getPlan.used_limits?.caption_generation_credit >=
          store.getters.getPlan.subscription.limits?.caption_generation_credit
        )
      return true
    })

    const getCreditSubscribeLimit = computed(() => {
      return store.getters.getPlan?.subscription?.limits
        ?.caption_generation_credit
    })

    const getCreditUsedLimit = computed(() => {
      return store.getters.getPlan?.used_limits?.caption_generation_credit
    })

    /**
     * api processing request steps for loading
     */
    watch(isGenerating, (value, oldValue) => {
      const textLength =
        countWords(input.value.prompt) + Number(wordCount.value)
      // estimated time in ms
      const stepTime = textLength * 10
      if (value) {
        // waiting for response
        let i = 0
        const id = setInterval(() => {
          steps.value[i].status = true
          i++
          if (i === 2) clearInterval(id)
        }, stepTime)
      } else {
          // in case of response
          steps.value[0].status =
              steps.value[1].status =
                  steps.value[2].status =
                      true
          setTimeout(function () {
              steps.value[3].status = true
          }, 1000)
          setTimeout(function () {
              isGenerated.value = true
              steps.value = clone(defaultSteps)
          }, 2000)
      }
    })

      const captionLimitsTooltip = () => {
          const formattedCreditUsedLimit = formatNumber(getCreditUsedLimit.value);
          const formattedCreditSubscribeLimit = formatNumber(getCreditSubscribeLimit.value);

          return `
              <div class="text-left">
      <span class="font-medium block">AI text Credits (words)</span>
      <span class="block my-2">${formattedCreditUsedLimit} of ${formattedCreditSubscribeLimit} used</span>
      <span class="block">
        This limit is subscribed by ${getSuperAdminDetails.value.full_name} and being consumed by all team members.
        And the credits get reset on the start of the month.
      </span>
    </div>
                `;
      };

    const handleShow = () => {
      nextTick(() => {
        promptInput.value?.focus()
      })
    }


      return {
          modalToggler,
          type,
          isOldComposer,
          textareaHeight,
          isGenerating,
          isGenerated,
          input,
          promptInput,
          includeHashtags,
          includeEmoji,
          output,
          predefinePrompts,
          voiceTones,
          wordCount,
          variation,
          mode,
          store,
          history,
          showHistory,
          steps,
          getSuperAdminDetails,
          isActiveUserSuperAdmin,
          isLimitExceeded,
          getCreditSubscribeLimit,
          getCreditUsedLimit,
          closeModal,
          resetState,
          handlePromptSelection,
          handleGenerate,
          linkifyText,
          countWords,
          formatNumber,
          copyToClipboard,
          addToEditor,
          captionLimitsTooltip,
          handleShow,

      }
  },
}
</script>

<style lang="scss">
.ai-caption-modal {
  overflow-y: hidden !important;

  .modal-dialog {
    margin: 0 0 0 auto;
    height: 100%;
    max-width: max-content;

    .modal-content {
      height: 100%;
      border-radius: 10px 0 0 10px;

      .modal-body {
        padding: 0 !important;
      }
    }
  }
}

.cst-caption-editor {
  @apply p-px;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }

  textarea::placeholder {
    color: #a4a8ac;
    font-size: 0.875rem;
  }
}
.variation_input {
  position: relative;
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    opacity: 0;
  }
  .check_btn {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 20px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 0.9rem;
    width: max-content;
    border: 1px solid #eee;
  }
  &:hover .check_btn {
    background-color: #f2f4f6;
    cursor: pointer;
  }
  input[type='radio']:checked ~ .check_btn {
    border: 1px solid #2196f3;
    background-color: #2196f30a;
    color: #2196f3;
  }
}
</style>
