import { mapActions, mapGetters, mapMutations } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import moment from 'moment'
import axios from 'axios'
import {
  getYoutubeContentByTopicURL,
  getDailymotionContentByTopicURL,
  previewVideoSuggestionURL
} from '../../config/api-utils'
const videoMixin = {
  methods: {
    ...mapActions([
      'setVideos',
      'setVideoScroll',
      'setVideoPaginationLoader',
      'setDiscoveryVideoLoader',
      'setVideoPageToken',
      'setYoutubeSort',
      'setVideoPage',
      'setVideoPreview',
      'setDailymotionSort',
      'setPreviewSuggestionScroll',
      'setPreviewSuggestionPage',
      'setPreviewSuggestionPosts',
      'setPreviewSuggestionLoader',
      'setPreviewSuggestionInfiniteLoader',
      'setPreviewSuggestionToken'
    ]),
    getYoutubeContentByTopic ($state = null) {
      this.SET_TOPIC_DISABLED(true)
      let payload = null
      if (this.getVideoContentType === 'search') {
        payload = {
          filters: this.getVideoFilter,
          type: 'search',
          search: this.getFilters.search,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        }
      } else {
        let topicType = this.getTopicType
        if (this.$route.params && this.$route.params.module) {
          topicType = this.$route.params.module
        }

        payload = {
          selection: this.getTopicSelection,
          filters: this.getVideoFilter, // video filters
          type: topicType,
          topic: this.getTopic,
          curated: this.getCuratedTopic,
          workspace_id: this.getWorkspaces.activeWorkspace._id
        }
        /* if (this.getTopic) {
          this.setActiveTopicTab(this.getTopic)
        }
        else if(this.getCuratedTopic && this.getCuratedTopic._id)
        {
          this.setActiveTopicTab(this.getCuratedTopic._id)
        } */
      }
      if (payload) {
        this.postRequest(
          getYoutubeContentByTopicURL,
          payload,
          (response) => {
            if (response.data.status) {
              this.SET_TOPIC_DISABLED(false)

              if (this.getVideoPage === 0) {
                this.setVideos([])
              }

              if (!response.data.videos || response.data.videos.length === 0) {
                response.data.items = []
              }

              if (this.getVideoPage >= 1) {
                this.setVideos(this.getVideos.concat(response.data.videos))
              } else {
                this.setVideos(response.data.videos)
              }

              this.setVideoPaginationLoader(true)
              if (response.data.page_token) {
                this.setVideoPageToken(response.data.page_token)
              }
              if (response.data.videos.length === 20) {
                this.setVideoScroll(true)
                if ($state) {
                  $state.loaded()
                }
              } else {
                this.setVideoScroll(false)
                try {
                  if ($state) {
                    $state.complete()
                  }
                } catch (ex) {
                  console.log(ex)
                }
              }

              this.setDiscoveryVideoLoader(false)
              this.checkComposerDragDrop()
            }
          },
          (response) => {
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },

    getDailymotionVideos ($state = null) {
      this.SET_TOPIC_DISABLED(true)
      const payload = {
        filters: this.getVideoFilter,
        search: this.getFilters.search,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }
      if (payload) {
        this.postRequest(
          getDailymotionContentByTopicURL,
          payload,
          (response) => {
            if (response.data.status) {
              this.SET_TOPIC_DISABLED(false)

              if (this.getVideoPage === 0) {
                this.setVideos([])
              }

              if (!response.data.items || response.data.items.length === 0) {
                response.data.items = []
              }

              if (this.getVideoPage >= 1) {
                this.setVideos(this.getVideos.concat(response.data.items))
              } else {
                this.setVideos(response.data.items)
              }

              this.setVideoPaginationLoader(true)
              if (response.data.items.length === 20) {
                this.setVideoScroll(true)
                if ($state) {
                  $state.loaded()
                }
              } else {
                this.setVideoScroll(false)
                try {
                  if ($state) {
                    $state.complete()
                  }
                } catch (ex) {
                  console.log(ex)
                }
              }

              this.setDiscoveryVideoLoader(false)
              this.checkComposerDragDrop()
            }
          },
          (response) => {
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },

    videoImageURL (video, type) {
      if (type === 'youtube') {
        return video.url
      } else if (type === 'dailymotion') {
        return video.thumbnail_360_url
      }
    },

    videoDate (type, video) {
      if (type === 'youtube') {
        return moment(moment.unix(video)).local().fromNow()
      } else if (type === 'dailymotion') {
        return moment(moment.unix(video.created_time)).local().fromNow()
      }
      return 0
    },

    changeYoutubeSort (name, code) {
      console.debug('Method:changeYoutubeSort')
      this.setYoutubeSort({ name, code })
      this.getYoutubeVideo()
    },

    getYoutubeVideo () {
      this.setVideoPage(0)
      this.setVideoPageToken(null)
      this.setVideoScroll(true)
      this.setVideos([])
      this.setVideoPaginationLoader(false)
      this.setDiscoveryVideoLoader(true)
      this.getYoutubeContentByTopic()
    },

    previewVideo (title, description, url, source, link = null) {
      console.debug('Method:previewVideo')
      const videoPreview = {
        title,
        url,
        description,
        source,
        height: 350
      }
      if (source === 'Facebook') {
        const width = this.getFacebookPreviewLink(url, 'width="')
        const height = this.getFacebookPreviewLink(url, 'height="')
        if (height) {
          // get height ratio
          const newWidth = 638
          let heightRatio = width / height
          heightRatio = Math.round(newWidth / heightRatio)
          videoPreview.height = height
          videoPreview.width = width
          videoPreview.heightRatio = heightRatio
          videoPreview.source = 'Youtube'
          videoPreview.url =
            'https://www.facebook.com/plugins/video.php?href=' + link
        } else {
          link = link.replace(/\/$/, '')
          videoPreview.url =
            'https://www.facebook.com/plugins/video.php?href=' +
            link +
            '/height=350'
        }
      }
      this.setVideoPreview(videoPreview)
      this.$bvModal.show('videoPreview')
      console.log('VideoPreviewModal')
    },

    getFacebookPreviewLink (url, str) {
      try {
        return parseInt(url.split(str)[1].split('"')[0])
      } catch (e) {
        return null
      }
    },

    videoEmbedUrl (item, type) {
      if (type === 'youtube') {
        return 'https://youtube.com/embed/' + item
      } else if ('dailymotion') {
        return 'https://www.dailymotion.com/embed/' + item.url.substring(27)
      }
    },

    // dailymotion

    changeDailymotionSort (name, code) {
      console.debug('Method:changeDailymotionSort')
      this.setDailymotionSort({ name, code })
      this.getDailymotionVideo()
    },

    // changeDailymotionRegion (region) {
    //   console.debug('Method:changeDailymotionRegion')
    //   // this.setDailymotionRegion({ name: region.name, code: region.code })
    //   this.getDailymotionVideo()
    // },

    getDailymotionVideo () {
      this.setVideoPage(0)
      this.setVideoScroll(true)
      this.setVideos([])
      this.setVideoPaginationLoader(false)
      this.setDiscoveryVideoLoader(true)
      this.getDailymotionVideos()
    },

    /**
     *
     * @param pagination is true in case of the suggestion preview, and in other cases we have set it to false.
     */
    previewVideoSuggestion (pagination = false, $state = null, youtubeKey = '') {
      console.debug('previewVideoSuggestion', youtubeKey)
      // if there are no keywords or domains, don't send a request.
      if (this.getVideoAutomationDetails.video.keyword.length === 0) {
        return
      }

      if (
        this.getVideoAutomationDetails.video.source === 'Youtube' &&
        youtubeKey === ''
      ) {
        return false
      }

      // resetting all the states if the user has entered a new keyword and pagination is set to false.
      this.setPreviewSuggestionInfiniteLoader(false)
      // this.setPreviewSuggestionLoader(true)
      if (pagination === false) {
        this.setPreviewSuggestionPosts([])
        this.setPreviewSuggestionPage(1)
        this.setPreviewSuggestionToken(null)
        this.setPreviewSuggestionLoader(true)
      }

      // set the default to false.

      this.setPreviewSuggestionScroll(false)

      const CancelToken = axios.CancelToken

      // removing all existing axios requests

      for (let i = 0; i < this.getAxiosRequests.previewSuggestion.length; i++) {
        const remove = this.getAxiosRequests.previewSuggestion[i]
        remove()
      }

      let cancel

      axios
        .post(
          previewVideoSuggestionURL,
          {
            selection: this.getVideoAutomationDetails.video,
            preview: this.getPreviewSuggestion,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
            access_token: youtubeKey
          },
          { headers: { Authorization: 'Bearer ' + this.getJWTToken } },
          {
            cancelToken: new CancelToken(function executor (c) {
              cancel = c
            })
          }
        )
        .then(
          (response) => {
            if (response.data.status) {
              // hide the loaders

              let posts = []
              if (this.getVideoAutomationDetails.video.source === 'Youtube') {
                posts = response.data.videos
                this.setPreviewSuggestionToken(response.data.page_token)
              } else if (
                this.getVideoAutomationDetails.video.source === 'Dailymotion'
              ) {
                posts = response.data.items
              }
              this.setPreviewSuggestionLoader(false)

              // add the posts to the array if they are called through the pagination
              if (pagination) {
                this.setPreviewSuggestionPosts(
                  this.getPreviewSuggestionPosts.concat(posts)
                )
              } else {
                this.setPreviewSuggestionPosts(posts)
                this.setPreviewSuggestionTotal(0)
              }
              // if the posts are 20, we need to set the infinite loading to scrollable behavior
              if (posts && posts.length === 20) {
                console.log('Set Preview Suggestion Scroll')
                this.setPreviewSuggestionScroll(true)
                this.setPreviewSuggestionInfiniteLoader(true)
                console.log('Value set for the infinite loader')

                if ($state) {
                  console.log('State loaded for the preview suggestion')
                  $state.loaded()
                }
              } else if (posts && posts.length > 0 && posts.length < 20) {
                console.log(
                  'Posts length is less, setting all values to false.'
                )
                this.setPreviewSuggestionScroll(false)
                this.setPreviewSuggestionNoMoreResult(true)
                this.setPreviewSuggestionInfiniteLoader(false)
                if ($state) {
                  $state.complete()
                }
              } else {
                console.log('Posts length is zero...')

                this.setPreviewSuggestionScroll(false)
                this.setPreviewSuggestionNoMoreResult(true)
                this.setPreviewSuggestionInfiniteLoader(false)
                if ($state) {
                  $state.complete()
                }
              }
            } else if (response.data.message) {
              this.alertMessage(
                this.removeHtmlTags(response.data.message),
                'error'
              )
              this.setPreviewSuggestionLoader(false)
              this.setPreviewSuggestionScroll(false)
              this.setPreviewSuggestionNoMoreResult(true)
              this.setPreviewSuggestionInfiniteLoader(false)
            }
          },
          (response) => {}
        )
      this.getAxiosRequests.previewSuggestion.push(cancel)
    }
  },
  computed: {
    ...mapGetters([
      'getVideoFilter',
      'getVideoPage',
      'getVideos',
      'getVideoContentType',
      'getAxiosRequests'
    ])
  }
}
export { videoMixin }
