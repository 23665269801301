<template>
  <div>
    <h3 class="filter_section_heading article-search-bar-pane__search__heading">
      <template
        v-if="$route.meta.is_active && $route.meta.is_active === 'content'"
      >
        Find the top performing content
      </template>
      <template
        v-else-if="
          $route.meta.is_active && $route.meta.is_active === 'insights'
        "
      >
        Find key insights from top performing content
      </template>
      <template v-else-if="$route.name === 'discover_search_youtube'">
        Find the top performing videos
      </template>
      <template v-else-if="$route.name === 'discover_search_twitter'">
        Find the top performing tweets
      </template>
    </h3>
    <!-- search bar -->
    <div
      class="
        search-bar-input search-bar-input-discovery
        with-icon-left
        article-search-bar-pane__search__input
      "
    >
      <div class="search-bar-inner">
        <input
          v-model="search_query"
          class="discoverySearchInput"
          style="padding-top: 12px; padding-bottom: 12px"
          type="text"
          placeholder="Search for any topic or domain, e.g ‘marketing’ or ‘cnn.com’"
          data-cy="discovery_search_bar"
          @input="inputArticleSearchKeyword($event)"
          @focus="searchHistory"
          @keyup.arrow-up="inputSelectItemFromList('up')"
          @keyup.arrow-down="inputSelectItemFromList('down')"
          @keyup.enter="articleSearchKeyword(search_query)"
        />
        <button
          class="search_btn input_icon search_icon gradient_color"
          data-cy="discovery_search_button"
          @click.prevent="articleSearchKeyword(search_query)"
        >
          <i class="icon-Search"></i>
          <!--<beat-loader :color="'#ffffff'" :size="'1.125rem'" v-if="getSearchLoader.button.search"></beat-loader>-->
        </button>

        <!-- search option component added -->
        <!-- search suggestion dropdown -->
        <transition name="slide-bottom">
          <ArticleSearchSuggestions
            v-if="
              suggestions.show && search_query && search_query.trim().length > 3
            "
            :topics="suggestions.topics"
            :keywords="suggestions.keywords"
            :search_key_input="suggestions.active_key_type"
            :query="search_query"
          ></ArticleSearchSuggestions>
        </transition>
        <transition name="slide-bottom">
          <ArticleSearchHistory
            v-if="
              suggestions.search_history &&
              (!search_query ||
                (search_query && search_query.trim().length === 0))
            "
            :keywords="suggestions.search_history_keywords"
            :search_key_input="suggestions.active_key_type"
          ></ArticleSearchHistory>
        </transition>

        <!--<search-suggestions></search-suggestions>-->
      </div>

      <template v-if="getShowHintsRoute">
        <ArticleSearchAssistantTable></ArticleSearchAssistantTable>
      </template>
      <template
        v-if="
          $route.meta.is_active && $route.meta.is_active === 'twitter_tweets'
        "
      >
        <TwitterSearchAssistantTable></TwitterSearchAssistantTable>
      </template>
    </div>
    <ArticleSearchSuggestionsHints
      v-if="getShowHintsRoute"
    ></ArticleSearchSuggestionsHints>
    <TwitterSearchSuggestionsHints
      v-if="$route.meta.is_active && $route.meta.is_active === 'twitter_tweets'"
    ></TwitterSearchSuggestionsHints>
    <YoutubeSearchSuggestionsHints
      v-if="$route.meta.is_active && $route.meta.is_active === 'youtube_videos'"
    ></YoutubeSearchSuggestionsHints>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import axios from 'axios'
import { EventBus } from '@common/lib/event-bus'
import { getKeywordsSuggestionURL } from '../../../config/api-utils'
import ArticleSearchAssistantTable from './suggestions/content/ArticleSearchAssistantTable'
import TwitterSearchAssistantTable from './suggestions/twitter/TwitterSearchAssistantTable'
import YoutubeSearchSuggestionsHints from './suggestions/youtube/YoutubeSearchSuggestionsHints'
import ArticleSearchSuggestions from './suggestions/content/ArticleSearchSuggestions'
import ArticleSearchHistory from './suggestions/content/ArticleSearchHistory'
import ArticleSearchSuggestionsHints from './suggestions/content/ArticleSearchSuggestionsHints'
import TwitterSearchSuggestionsHints from './suggestions/twitter/TwitterSearchSuggestionsHints'

let cancel
const CancelToken = axios.CancelToken
const getDefaultKeyActive = () => {
  return {
    selector: '',
    key: '',
    items: [],
    index_of_key: 0,
  }
}
export default {
  components: {
    ArticleSearchAssistantTable,
    TwitterSearchAssistantTable,
    ArticleSearchSuggestions,
    ArticleSearchHistory,
    ArticleSearchSuggestionsHints,
    TwitterSearchSuggestionsHints,
    YoutubeSearchSuggestionsHints,
  },
  props: {
    search_module: {},
  },
  data() {
    return {
      search_query: '',
      suggestions: {
        keywords: [],
        topics: [],
        requests: [],
        show: false,
        search_history: false,
        search_history_keywords: [],
        active_key_type: getDefaultKeyActive(),
      },
    }
  },
  computed: {
    getShowHintsRoute() {
      return (
        this.$route.meta.is_active &&
        ['content', 'insights'].indexOf(this.$route.meta.is_active) >= 0
      )
    },
  },
  watch: {
    '$route.name'(value) {
      if (value === 'discover_search') this.search_query = ''
    },
    async '$route.query.q'(value) {
      this.search_query = value
      await this.fetchSuggestionsForKeyword('')
    },
  },
  created() {
    // if the user have opened the web page directly, open this page.
    if (this.$route.query.q) {
      this.search_query = this.$route.query.q
    }
  },
  mounted() {
    EventBus.$on('show-suggestions', () => {
      this.suggestions.show = false
    })
    EventBus.$on('show-search-history', () => {
      this.suggestions.search_history = false
    })
    EventBus.$on('clear-search-history', () => {
      this.suggestions.search_history_keywords = []
    })
    EventBus.$on('clear-individual-search-history', (res) => {
      if (res.query) {
        const keywordsList = this.suggestions.search_history_keywords
        console.log(keywordsList.indexOf(res.query))
        console.log(keywordsList)
        keywordsList.splice(keywordsList.indexOf(res.query), 1)
        this.suggestions.search_history_keywords = keywordsList
        console.log(this.suggestions.search_history_keywords)
      }
    })
  },
  beforeUnmount() {
    EventBus.$off('show-suggestions')
    EventBus.$off('show-search-history')
    EventBus.$off('clear-search-history')
    EventBus.$off('clear-individual-search-history')
  },

  methods: {
    cancelAllPreviousAutoCompleteRequests() {
      console.log(this.suggestions.requests)
      for (let i = 0; i < this.suggestions.requests.length; i++) {
        const remove = this.suggestions.requests[i]
        remove()
      }
      this.suggestions.show = false
    },
    /**
     * If someone presses a key down or key up, select the active items from the list.
     * */
    inputSelectItemFromList(keyValue) {
      if (this.search_query.trim().length === 0) {
        this.suggestions.active_key_type.selector = 'search_history'
        this.suggestions.active_key_type.items =
          this.suggestions.search_history_keywords
      } else {
        this.suggestions.active_key_type.selector = 'search_autocomplete'
        this.suggestions.active_key_type.items = this.suggestions.keywords
      }

      // if the items has length greater than 0.
      if (this.suggestions.active_key_type.items.length > 0) {
        if (keyValue === 'up') {
          // if the user does not have entered any key term and index key is set to 0
          // decrement from the original length.

          if (
            !this.suggestions.active_key_type.key ||
            this.suggestions.active_key_type.index_of_key === 0
          ) {
            this.suggestions.active_key_type.key =
              this.suggestions.active_key_type.items[
                this.suggestions.active_key_type.items.length - 1
              ]
            this.suggestions.active_key_type.index_of_key =
              this.suggestions.active_key_type.items.length - 1
          } else {
            this.suggestions.active_key_type.key =
              this.suggestions.active_key_type.items[
                this.suggestions.active_key_type.index_of_key - 1
              ]
            this.suggestions.active_key_type.index_of_key -= 1
          }
          console.log('arrow up')
        } else {
          // if the length and the user key indexes are the same one
          if (
            this.suggestions.active_key_type.index_of_key ===
            this.suggestions.active_key_type.items.length - 1
          ) {
            this.suggestions.active_key_type.key =
              this.suggestions.active_key_type.items[0]
            this.suggestions.active_key_type.index_of_key = 0
            return
          }
          // dealing with the use case if the user have not entered any value and index of key is set to 0.

          if (
            !this.suggestions.active_key_type.key &&
            this.suggestions.active_key_type.index_of_key === 0
          ) {
            this.suggestions.active_key_type.key =
              this.suggestions.active_key_type.items[
                this.suggestions.active_key_type.index_of_key
              ]
            this.suggestions.active_key_type.index_of_key = 0
          } else {
            // increment the key and increase the index.
            this.suggestions.active_key_type.index_of_key += 1
            this.suggestions.active_key_type.key =
              this.suggestions.active_key_type.items[
                this.suggestions.active_key_type.index_of_key
              ]
          }
          console.log('arrow down')
        }

        if (
          this.suggestions.active_key_type.selector === 'search_autocomplete'
        ) {
          this.search_query = this.suggestions.active_key_type.key
        }
      }
    },
    inputArticleSearchKeyword: debounce(async function (event) {
      console.debug('changeSearchKeyDown', event)
      if (event.key !== 'Enter' && this.search_query.length > 3) {
        this.suggestions.active_key_type = getDefaultKeyActive()
        this.fetchSuggestionsForKeyword()
      } else {
        $('.header_search_input').blur()
      }
    }, 250),
    fetchSuggestionsForKeyword(keyword = this.search_query) {
      console.log(
        'Autocomplete characters are greater than 3 and perfomring search...'
      )
      /* for (let i = 0; i < this.suggestions.requests.length; i++) {
                                                                                                  let remove = this.suggestions.requests[i]
                                                                                                  console.log(remove)
                                                                                                  remove && remove.cancel()
                                                                                                } */
      if (cancel !== undefined) {
        cancel()
        console.log('cancelled')
      }
      this.suggestions.keywords = []
      this.suggestions.topics = []
      this.suggestions.show = false
      // for search content suggestions
      console.log(keyword)
      axios({
        method: 'post',
        url: getKeywordsSuggestionURL,
        data: {
          keyword,
        },
        headers: { Authorization: 'Bearer ' + this.$store.getters.getJWTToken },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
      })
        .then((resp) => {
          let results = []
          // if (resp.data.keywords && resp.data.keywords.CompleteSuggestion) {
          //   resp.data.keywords.CompleteSuggestion.forEach(function (element) {
          //     // for only 5 keywords
          //     if (results.length < 7) {
          //       results.push(element.suggestion.attributes.data)
          //     }
          //   }, this)
          // }
          if (resp.data.keywords) results = resp.data.keywords

          if (resp.data.topics.length > 0) {
            const topicsList = resp.data.topics.filter((item, index) => {
              if (index < 5) return true
            })
            this.suggestions.topics = topicsList
          }
          this.suggestions.keywords = results
          this.suggestions.show = true
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('axios post request cancelled')
          }
          this.suggestions.keywords = []
          this.suggestions.topics = []
          this.suggestions.show = false
        })
    },
    async articleSearchKeyword() {
      this.cancelAllPreviousAutoCompleteRequests()
      this.trackUserMaven('discover_search_keyword', {
        query: this.search_query,
      })

      this.trackUserMaven('discover_searched_content')

      const steps = this.getActiveWorkspace.onboarding_steps

      if (steps.discover_content !== undefined && steps.discover_content.status === false) {
        await this.onboardingStepsCompleted('discover_content')
      }

      // performing the search request if the user has selected the key etc.
      if (this.search_query.trim().length === 0) {
        let routeName = this.$route.name

        if (
          routeName === 'discover_search' ||
          routeName === 'discover_insights'
        ) {
          routeName = routeName + '_web'
        }

        //   alert('test')

        // let routeName = this.$route.name
        //
        // if (routeName === 'discover_search' || routeName === 'discover_insights') {
        //     routeName = routeName + '_web'
        // } else if (routeName === 'discover_search/web?q=') {
        //     routeName = routeName + 'discover_search/web?q=world'
        // }

        if (this.suggestions.active_key_type.key) {
          this.search_query = this.suggestions.active_key_type.key
        }
      } else {
        // depending on the route, wee are redirecting the user.
        let routeName = this.$route.name
        if (
          routeName === 'discover_search' ||
          routeName === 'discover_insights'
        ) {
          routeName = routeName + '_web'
        }
        this.$router.push({ name: routeName, query: { q: this.search_query } })
      }
    },
    async searchHistory() {
      console.log('searchHistoryFocus Event')
      if (
        !this.search_query ||
        (this.search_query && this.search_query.length < 1)
      ) {
        const res = await this.$store.dispatch('fetchSearchHistory')
        if (res && res.data.status) {
          if (res.data.data) {
            this.suggestions.search_history_keywords = res.data.data
            this.suggestions.search_history = true
          } else {
            this.suggestions.search_history = false
          }
        }
      }
    },
  },
}
</script>
