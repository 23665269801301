<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl px-2">
    <div class="flex items-center py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.account_image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        <span class="leading-4">{{ account.platform_name }}</span>
        <span class="block font-normal text-sm text-gray-800">
          <span v-if="executionTime.date">{{
              momentWrapper(executionTime.date).formatDateTime()
          }}</span>
          <span v-else>{{ momentWrapper().formatDateTime() }}</span>
        </span>
      </div>
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-blue"
      >
        <img src="@assets/img/integration/gmb-icon.svg" alt=" " class="w-5" />
      </div>
    </div>
    <div class="w-100 h-100">
      <div
        v-if="
          (Array.isArray(detail.image) && detail.image.length > 0) ||
          (typeof detail.image === 'string' && detail.image !== '')
        "
        class="flex flex-wrap relative rounded-lg overflow-hidden"
        style="max-height: 500px; min-height: 150px"
      >
        <div
          class="
            flex-img
            bg-contain bg-no-repeat bg-center
            min-h-[160px]
            max-h-[500px]
          "
          :style="{ 'background-image': 'url(' + getImage + ')' }"
        >
          <img
            v-tooltip="{
              content: 'Image Preview',
              placement: 'auto',
            }"
            class="max-w-full max-h-full w-full opacity-0 cursor-pointer"
            :src="getImage"
            alt=""
            @click="openLightbox(getImage)"
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white text-center"
      >
        <img
          class="w-full min-h-48"
          style="max-height: 500px"
          :src="detail.video.thumbnail"
          alt=""
        />
        <!-- playback icon -->
        <div
          class="
            absolute
            top-0
            left-0
            h-full
            w-full
            cursor-pointer
            z-[9999999]
            !important
          "
          @click.prevent="
            displayFile(
              'video',
              detail.video.converted_video || detail.video.link,
              0,
            )
          "
        >
          <i
            class="
              fas
              fa-play
              cursor-pointer
              absolute
              top-1/2
              left-1/2
              p-4
              rounded-full
              bg-[#00000070]
              border-solid
              -translate-x-1/2 -translate-y-1/2
            "
          ></i>
        </div>
      </div>
    </div>
    <div v-if="eventTitle" class="items-center p-2">
      <span class="text-xl font-bold pointer-events-none">{{
        eventTitle
      }}</span>
    </div>
    <div v-if="eventDate && eventTitle" class="p-2">
      {{ eventDate }}
    </div>
    <div v-if="detail.message" class="items-center p-2">
      <span class="whitespace-pre-wrap" v-html="getDescription"></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        See More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <div v-if="selectedActionType" class="flex items-center p-2 mb-1">
      <span class="text-blue-300 font-semibold pointer-events-none">{{
        selectedActionType
      }}</span>
      <span class="ml-auto"
        ><i class="fas fa-share-alt text-gray-700"></i
      ></span>
    </div>
  </div>

  <VueEasyLightbox
    :visible="visible"
    :imgs="detail.image"
    @hide="visible = false"
  ></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'
import useDateFormat from "@common/composables/useDateFormat";

export default {
  name: 'GmbPreview',
  components: {
    VueEasyLightbox,
  },
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    cta: {
      type: String,
      default: '',
      required: false,
    },
    eventTitle: {
      type: String,
      default: '',
      required: false,
    },
    eventDate: {
      type: String,
      default: '',
      required: false,
    },
    executionTime: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  setup(){
    const {momentWrapper} = useDateFormat()
    return {momentWrapper}
  },
  data() {
    return {
      actionType: [
        {
          name: 'Book',
          value: 'BOOK',
        },
        {
          name: 'Order',
          value: 'ORDER',
        },
        {
          name: 'Shop',
          value: 'SHOP',
        },
        {
          name: 'Learn more',
          value: 'LEARN_MORE',
        },
        {
          name: 'Sign up',
          value: 'SIGN_UP',
        },
        {
          name: 'Call',
          value: 'CALL',
        },
      ],
      loadMore: true,
      charLimit: 600,
      visible: false,
      imgs: [],
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    selectedActionType() {
      const type = this.actionType.find((item) => item.value === this.cta)
      return type?.name.toUpperCase()
    },
    getImage() {
      if (Array.isArray(this.detail.image)) return this.detail.image[0]
      return this.detail.image
    },
  },
  methods: {
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    changeLoadMore(val) {
      this.loadMore = val
    },
    openLightbox(item) {
      this.imgs = item
      this.visible = true
    },
  },
}
</script>

<style scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
</style>
