<template>
  <div class="auth_parent_container">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Two-Factor authentication</h2>
              <CstAlert type="info" class="mt-5 text-left">
                You have 2FA enabled for enhanced security, please complete the
                verification.
              </CstAlert>
            </div>

            <div class="flex flex-col gap-2 mt-12">
              <CstFloatingLabelInput
                  id="code"
                  v-model="state.code"
                  class="!h-[3.3rem] mb-2.5"
                  type="text"
                  :label="state.backup ? 'Backup Code' : 'Authenticator Code'"
                  :maxlength="6"
                  show-icon-left
                  @keydown="filterKey"
                  @enter="handleSubmit"
              >
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
              </CstFloatingLabelInput>

              <p class="text-[#3A4557] text-center">
                {{
                  state.backup
                      ? 'Enter a 6 digit code from the backup codes generated.'
                      : 'Enter a 6 digit code from the Google Authenticator app.'
                }}
              </p>

            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  data-cy="sign-in"
                  :disabled="state.loader"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="handleSubmit"
              >
                <span class="w-full">
                  <template v-if="state.loader">Processing <img
                      v-if="state.loader"
                      style="width: 20px; margin-left: 8px"
                      src="../assets/img/common/gif_loader_white.gif"
                      alt=""
                  /></template>
                  <template v-else>Submit</template>
                </span>
              </button>
            </div>
          </form>

          <div class="auth-form-container__footer mt-[1.6rem]">
            <p v-if="state.backup" class="text-[#3A4557] text-center">
              If you are having trouble logging in please contact support.
              <span
                  class="text-[#157FFF] hover:text-[#157FFF] hover:underline cursor-pointer block"
                  @click="toggleBackup"
              >Authenticate with Google Authenticator</span
              >
            </p>
            <p v-else class="text-[#3A4557] text-center">
              Don’t have your phone?
              <span
                  class="text-[#157FFF] hover:text-[#157FFF] cursor-pointer hover:underline"
                  @click="toggleBackup"
              >Verify with backup code</span
              >
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from '@jambonn/vue-concise-carousel'
import CstAlert from '@ui/Alert/CstAlert'
import { defineComponent, inject, onMounted, reactive, watch } from 'vue'
import { verify2FACode } from '@src/modules/account/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { authenticationTypes } from '@state/mutation-types'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import { useStore } from '@state/base'
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");

export default defineComponent({
  name: 'SignIn',
  components: {
    CstFloatingLabelInput,
    CstAlert,
    Slide,
    LoginSideComponent
  },
  setup() {
    const root = inject('root')
    const { dispatch, commit } = useStore()
    const {
      $route,
      $router,
      trackUserMaven,
      fetchProfile,
      isEmailVerified,
      getFrillParams,
    } = root

    const state = reactive({
      code: '',
      backup: false,
      loader: false,
    })
    const toggleBackup = () => {
      state.backup = !state.backup
    }
    const handleSubmit = async () => {
      state.loader = true

      /**
       * If query params contain Frill.co SSO
       * Authenticate user and redirect to frill redirect URI
       */
      const postData = getFrillParams()

      try {
        const response = await verify2FACode(
          {
            user_info: $route.params.token,
            code: state.code,
            ...postData,
          },
          state.backup
        )

        // set the cookies
        commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        commit(authenticationTypes.SET_LOGGED_USER, response.logged_user)
        commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        // custom event for user maven
        trackUserMaven('logged_in')

        // If login is request from SSO - Frill.co, redirect user
        if (response.redirect) {
          window.location.href = response.redirect
        }

        // check for redirected url
        if (
          $route.query.redirected_url &&
          $route.query.redirected_url !== '/logout' &&
          $route.query.redirected_url !== '/login'
        ) {
          window.location.href = $route.query.redirected_url
          return
        }

        if (response.slug) {
          await $router.push({
            name: response?.user?.preferences?.default_landing_page || 'dashboard',
            params: { workspace: response.slug },
          })
          return
        }

        await fetchProfile()
        isEmailVerified()
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
      state.loader = false
    }

    // make api call when state code length is 6
    watch(
      () => state.code.length,
      (newValue) => {
        if (newValue === 6) {
          handleSubmit()
        }
      }
    )

    onMounted(() => {
      console.log('mounted')
      // check if the token is in the route params
      const token = $route.params.token
      if (!token) {
        $router.push({ name: 'login' })
      }
    })

    /*
     * Method to handle code input validation
     */
    const filterKey = (e) => {
      const isModifierkeyPressed = e.metaKey || e.ctrlKey || e.shiftKey
      const isCursorMoveOrDeleteAction =
        [46, 8, 37, 38, 39, 40].indexOf(e.keyCode) !== -1
      const isNumKeyPressed =
        (e.keyCode >= 48 && e.keyCode <= 58) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      const vKey = 86
      const cKey = 67
      const aKey = 65
      switch (true) {
        case isCursorMoveOrDeleteAction:
        case isModifierkeyPressed === false && isNumKeyPressed:
        case (e.metaKey || e.ctrlKey) &&
          [vKey, cKey, aKey].indexOf(e.keyCode) !== -1:
          break
        default:
          e.preventDefault()
      }
    }

    return {
      state,
      filterKey,
      toggleBackup,
      handleSubmit,
    }
  },
})
</script>
