<template>
  <div class="post-preview min-h-full flex flex-col bg-cs-light-gray">
    <div
      class="
        flex
        items-center
        gap-x-4
        px-6
        h-14
        preview-head
      "
    >
      <span class="font-medium text-base">Post Preview</span>
      <CstIconSwitch v-model="togglePreview" />
    </div>
    <hr class="m-0 hr-color" />
    <div
      v-if="hasPostDetail()"
      class="
        my-2
        mx-1
        text-center text-sm text-gray-700
        leading-5
        bg-cs-light-gray
      "
    >
      <img src="@assets/img/composer/info-icon-gray.svg" class="w-5 h-5" />
      <span>
        The generated previews are not an exact depiction of what the post will
        look like on the platform, there will be some minor difference.
      </span>
    </div>
    <div
      class="
        py-3.5
        px-4
        flex-grow flex-shrink flex-basis-auto
        overflow-y-scroll
        h-0
        cs-hr-scroll
        scroll-padding
      "
      :class="{ 'relative overflow-hidden': togglePreview }"
    >
      <transition name="fade">
        <div
          class="web"
          :class="{ 'mobile-preview w-11/12 absolute': togglePreview }"
        >
          <div class="flex flex-col gap-y-3" :class="{ 'mobile-inner snap-y': togglePreview }">
            <div
              v-if="Object.keys(previewAccounts).length === 0"
              class="h-100 text-center py-8"
            >
              <img
                src="@assets/img/composer/no-preview.png"
                alt=""
                class="mt-4 w-52 opacity-70"
              />
              <span class="mt-6 block text-md text-gray-800 leading-5">
                You have not selected any social accounts yet.
              </span>
            </div>

            <template v-else-if="hasPostDetail() || facebookBackgroundId">
              <FacebookPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'facebook', 'facebook') &&
                  previewAccounts['facebook']
                "
                class="mb-3 scroll-snap-start"
                :common-box-status="status"
                :account="getAccountDetail('facebook')"
                :detail="getSharingDetail('facebook')"
                :publish-as="getFacebookPublishAs"
                :carousel="carousel"
                :carousel-account="getFirstCarouselAccount()"
                :facebook-post-type="facebookPostType"
                :toggle-preview="togglePreview"
                :comment="getComment('facebook')"
                :facebook-background-id="facebookBackgroundId"
                :facebook-group-selected="facebookGroupSelected"
                :facebook-page-selected="facebookPageSelected"
              />
              <InstagramPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'instagram') &&
                  previewAccounts['instagram']
                "
                class="mb-3 scroll-snap-start"
                :common-box-status="status"
                :account="getAccountDetail('instagram')"
                :detail="getSharingDetail('instagram')"
                :instagram-post-type="instagramPostType"
                :toggle-preview="togglePreview"
                :comment="getComment('instagram')"
                :user-tags="getUserTags(status ? 'common' : 'instagram')"
                :instagram-collaborators="instagramCollaborators"
                :instagram-share-to-story="instagramShareToStory"
              />
              <ThreadsPreview
                  v-if="
                  hasPlatformDetail(status ? 'common' : 'threads') &&
                  previewAccounts['threads']
                "
                  class="mb-3 scroll-snap-start"
                  :account="getAccountDetail('threads')"
                  :detail="getSharingDetail('threads')"
                  :multi-threads="[...getMultiThreads()]"
              />
              <TwitterPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'twitter') &&
                  previewAccounts['twitter']
                "
                class="mb-3 scroll-snap-start"
                :common-box-status="status"
                :account="getAccountDetail('twitter')"
                :detail="getSharingDetail('twitter')"
                :link-detail="getTwitterUrl()"
                :thread="[...getThreadedTweet()]"
              />
              <LinkedinPreview
                v-if="
                  hasPlatformDetail(
                    status ? 'common' : 'linkedin',
                    'linkedin',
                  ) && previewAccounts['linkedin']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('linkedin')"
                :detail="getSharingDetail('linkedin')"
                :comment="getComment('linkedin')"
                :linkedin-options="linkedinOptions"
                :is-mobile-view="togglePreview"
              />
              <PinterestPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'pinterest') &&
                  previewAccounts['pinterest']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('pinterest')"
                :detail="getSharingDetail('pinterest')"
                :followers-count="180"
                source-url=""
              />
              <GmbPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'gmb') &&
                  previewAccounts['gmb']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('gmb')"
                :detail="getSharingDetail('gmb')"
                :cta="getCTA(status ? 'common' : 'gmb')"
                :event-title="getGmbEventTitle()"
                :event-date="getGmbEventDate()"
              />
              <YoutubePreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'youtube') &&
                  previewAccounts['youtube']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('youtube')"
                :detail="getSharingDetail('youtube')"
                :video-title="youtubeTitle"
                :youtube-post-type="youtubePostType"
                :comment="getComment('youtube')"
              />
              <TikTokPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'tiktok') &&
                  previewAccounts['tiktok']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('tiktok')"
                :detail="getSharingDetail('tiktok')"
                :tiktok-options="tiktokOptions"
              />
              <TumblrPreview
                v-if="
                  hasPlatformDetail(status ? 'common' : 'tumblr') &&
                  previewAccounts['tumblr']
                "
                class="mb-3 scroll-snap-start"
                :account="getAccountDetail('tumblr')"
                :detail="getSharingDetail('tumblr')"
              />
            </template>
            <div v-else class="h-100 text-center py-8">
              <img
                src="@assets/img/composer/no-preview.png"
                alt=""
                class="mt-4 w-52 opacity-70"
              />
              <span class="mt-6 block text-md text-gray-800 leading-5">
                You have not composed any message or attached any image/video
                yet.</span
              >
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/**
 * @param youtube.platform_logo     Information about the object's members.
 */
import CstIconSwitch from '@ui/Switch/CstIconSwitch'
import InstagramPreview from '../components/SocialPreviews/InstagramPreview'
import FacebookPreview from '../components/SocialPreviews/FacebookPreview'
import TwitterPreview from '../components/SocialPreviews/TwitterPreview'
import LinkedinPreview from '../components/SocialPreviews/LinkedinPreview'
import PinterestPreview from '../components/SocialPreviews/PinterestPreview'
import YoutubePreview from '../components/SocialPreviews/YoutubePreview'
import TumblrPreview from '../components/SocialPreviews/TumblrPreview'
import TikTokPreview from '../components/SocialPreviews/TikTokPreview'
import GmbPreview from '../components/SocialPreviews/GmbPreview'
import ThreadsPreview from "../components/SocialPreviews/ThreadsPreview.vue";

export default {
  name: 'PostPreview',
  components: {
    CstIconSwitch,
    TikTokPreview,
    TumblrPreview,
    YoutubePreview,
    PinterestPreview,
    LinkedinPreview,
    TwitterPreview,
    InstagramPreview,
    FacebookPreview,
    GmbPreview,
    ThreadsPreview
  },
  props: {
    previewAccounts: {
      type: Object,
      default: () => {},
    },
    publishAs: {
      type: Object,
      default: () => {},
    },
    details: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Boolean,
      default: false,
    },
    carousel: {
      type: Object,
      default: () => {},
    },
    carouselAccounts: {
      type: Array,
      default: () => [],
    },
    firstComment: {
      type: Object,
      default: () => {},
    },
    youtubeTitle: {
      type: String,
      default: '',
    },
    twitterOptions: {
      type: Object,
      default: () => {},
    },
    threadsOptions: {
      type: Object,
      default: () => {},
    },
    gmbOptions: {
      type: Object,
      default: () => {},
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    youtubePostType: {
      type: String,
      default: 'video',
    },
    linkedinOptions: {
      type: Object,
      default: () => {},
    },
    tiktokOptions: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: String,
      default: 'facebook',
    },
    instagramCollaborators: {
      type: Array,
      default: null,
    },
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    facebookGroupSelected: {
      type: Boolean,
      default: false,
    },
    facebookPageSelected: {
      type: Boolean,
      default: false,
    },
    instagramShareToStory: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      togglePreview: false,
    }
  },
  computed: {
    getFacebookPublishAs() {
      let account = {}
      if (
        this.previewAccounts.facebook &&
        this.previewAccounts.facebook.type === 'Group'
      ) {
        account = this.previewAccounts.facebook?.posted_as?.find(
          (item) =>
            item.id ===
            this.publishAs[this.previewAccounts.facebook.facebook_id]
        )
      }
      return account
    },
  },
  methods: {
    // this methods check for post detail to show previews
    hasPostDetail() {
      if (Object.keys(this.previewAccounts).length > 0) {
        if (this.status) {
          return this.hasPlatformDetail('common', 'linkedin')
        } else {
          for (const type in this.previewAccounts) {
            if (this.hasPlatformDetail(type, type)) return true
          }
        }
      }
      return false
    },
    hasPlatformDetail(type, previewType = '') {
      if (this.activeTab !== type && !this.status) return false
      if (previewType === 'facebook' && this.facebookBackgroundId) return true
      if (previewType === 'linkedin' && this.linkedinOptions?.document_added)
        return true
      return !!(
        this.details[type + '_sharing_details'].message ||
        this.details[type + '_sharing_details'].image?.length > 0 ||
        this.details[type + '_sharing_details'].video?.link ||
        this.details[type + '_sharing_details'].url ||
        (this.carousel.is_carousel_post && this.carousel.cards.length > 0)
      )
    },
    getAccountDetail(type) {
      try {
        return this.previewAccounts[type]
      } catch (e) {
        console.error(e)
        return ' '
      }
    },
    getSharingDetail(type) {
      if (this.status) return this.details.common_sharing_details
      return this.details[type + '_sharing_details']
    },
    getFirstCarouselAccount() {
      let account = {}
      if (this.carousel.is_carousel_post && this.carousel.cards.length > 0) {
        if (this.carousel.accounts.length > 0) {
          account = this.carouselAccounts.find(
            (account) => account.facebook_id === this.carousel.accounts[0]
          )
        }
      }
      return account
    },
    getUserTags(type) {
      if (this.details[type + '_sharing_details'].image_tagging_details) {
        return this.details[type + '_sharing_details'].image_tagging_details
      }

      return {}
    },
    getComment(type) {
      const account = this.previewAccounts[type]
      if (
        this.firstComment &&
        this.firstComment.has_first_comment &&
        this.firstComment.first_comment_accounts.includes(
          account[account.channel_identifier]
        )
      ) {
        return this.firstComment.first_comment_message
      }
      return ''
    },
    getThreadedTweet() {
      if (this.twitterOptions && this.twitterOptions.has_threaded_tweets) {
        return this.twitterOptions.threaded_tweets || []
      }
      return []
    },
    getMultiThreads() {
      if (this.threadsOptions && this.threadsOptions.has_multi_threads) {
        return this.threadsOptions.multi_threads || []
      }
      return []
    },
    getTwitterUrl() {
      if (this.status) {
        const detail = this.details.common_sharing_details
        return {
          url: detail.twitter_website,
          title: detail.twitter_title,
          description: detail.twitter_description,
          image: detail.twitter_image,
        }
      }
      const detail = this.details.twitter_sharing_details
      return {
        url: detail.website,
        title: detail.title,
        description: detail.description,
        image: detail.image,
      }
    },
    getCTA(type) {
      return this.gmbOptions.action_type &&
        this.details[type + '_sharing_details'].cta_link
        ? this.gmbOptions.action_type
        : ''
    },
    getGmbEventTitle() {
      if (this.gmbOptions.topic_type !== 'STANDARD') {
        return this.gmbOptions.title ? this.gmbOptions.title : ''
      }
      return ''
    },
    getGmbEventDate() {
      if (this.gmbOptions.topic_type !== 'STANDARD') {
        return (
          this.getDateTimeFormat(this.gmbOptions.start_date, 'MMM DD') +
          ' - ' +
          this.getDateTimeFormat(this.gmbOptions.end_date, 'MMM DD')
        )
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden !important;
  background-image: url('~@assets/img/composer/mockup.svg') !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-position: center center;
  @media (max-height: 800px) {
    height: 97%;
    background-size: 96% !important;
  }

  .mobile-inner {
    width: 100%;
    margin: 0 1.2rem;
    height: 525px;
    max-height: 525px;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    @media (max-height: 800px) {
      margin: 1.5rem 1.6rem !important;
    }
    @media (max-width: 1200px) {
      margin: 1.5rem 1.2rem;
      height: 400px;
      max-height: 400px;
    }
    @media (max-width: 1500px) {
      margin: 1.5rem 1.2rem;
      height: 500px;
      max-height: 500px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-gray-500;
    }
  }

  .scroll-snap-start {
    scroll-snap-align: start;
  }
}
</style>
