<template>
  <div
    class="bg-white p-5 rounded-md"
    :class="{ 'color-border cst-editor': !isModal }"
  >
    <div class="mb-6 w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <h2 class="text-base font-weight-500 select-none">{{ title }}</h2>
        <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{getComponentInfoTooltip('performanceComparison')}}
            </p>
          </template>
        </v-menu>
      </div>

      <div
        v-if="!isModal && data.length"
        v-tooltip="{
          content: 'Maximize',
          theme: 'light',
        }"
        class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
        @click="$bvModal.show(`performance-${type}-modal`)"
      >
        <img
          src="@src/assets/img/full_screen.svg"
          alt="fullScreen"
          class="w-5 h-5"
        />
      </div>
      <div
        v-if="isModal"
        v-tooltip="{
          content: 'Minimize',
          theme: 'light',
        }"
        class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
        @click="$bvModal.hide(`performance-${type}-modal`)"
      >
        <i class="fa fa-times fa-lg" aria-hidden="true"></i>
      </div>
    </div>
    <div v-if="isLoading">
      <SkeletonBox
        class="!w-full"
        :style="{ height: chartHeight }"
      ></SkeletonBox>
    </div>
    <div
      v-else-if="isAllDataFetched"
      class="
        analytics-no-data-found
        h-5/6
        relative
        right-0
        left-0
        z-10
        bg-white
      "
    >
      <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
      <p>Data is being fetched.</p>
    </div>

    <div v-else class="flex">
      <!-- Custom y axis label -->
      <div
        class="min-w-0 flex justify-center items-center relative"
        :style="{ height: isModal ? '85vh' : chartHeight, flex: '0 0 50px' }"
      >
        <div
          class="
            flex flex-col
            items-center
            justify-between
            whitespace-nowrap
            h-full
            py-14
            before:content-['']
            before:block
            before:absolute
            before:h-[300px]
            before:w-px
            before:bg-[#ECEFF1]
            overflow-hidden
          "
        >
          <span
            class="
              text-sm
              px-2.5
              py-1
              rounded-full
              bg-[#EEF9F3]
              relative
              pl-2
              whitespace-nowrap
              -rotate-90
            "
            >Highest</span
          >
          <span
            class="
              text-[#979CA0
              ]
              bg-white
              text-sm
              px-4
              whitespace-nowrap
              -rotate-90
            "
            >{{ yAxisLabel }}</span
          >
          <span
            class="
              text-sm
              px-2.5
              py-1
              rounded-full
              bg-[#FFECF0]
              pr-2
              whitespace-nowrap
              -rotate-90
            "
          >
            Lowest</span
          >
        </div>
      </div>
      <div class="flex-1">
        <div
          ref="chartRef"
          :style="{ height: isModal ? '85vh' : chartHeight }"
        ></div>
        <!-- Custom X axis label -->
        <div
          class="
            flex
            items-center
            justify-center
            mb-6
            text-center
            relative
            overflow-hidden
            mx-14
          "
        >
          <div
            class="
              flex
              items-center
              w-full
              justify-between
              before:left-0
              before:content-['']
              before:block
              before:absolute
              before:w-full
              before:h-px
              before:bg-[#ECEFF1]
            "
          >
            <span
              class="
                text-sm
                px-2.5
                py-1
                rounded-full
                bg-[#FFECF0]
                relative
                pr-2
              "
              >Lowest</span
            >
            <span class="text-[#979CA0 ] bg-white text-sm relative px-4">{{
              xAxisLabel
            }}</span>
            <span
              class="
                text-sm
                px-2.5
                py-1
                rounded-full
                bg-[#EEF9F3]
                relative
                pl-2
              "
              >Highest</span
            >
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :id="`performance-${type}-modal`"
      centered
      hide-footer
      hide-header
      content-class="mt-3 !rounded-none"
      dialog-class="cst-modal"
      @hide="commonMethods.toggleWidgets(false)"
      @shown="commonMethods.toggleWidgets(true)"
    >
      <PerformanceComparison
        :title="title"
        :legend="legend"
        :data="data"
        :is-all-data-fetched="isAllDataFetched"
        :is-loading="isLoading"
        :x-axis-label="xAxisLabel"
        :y-axis-label="yAxisLabel"
        :type="type"
        :is-modal="true"
      />
    </b-modal>
  </div>
</template>

<script>
// libraries
import { defineComponent, computed, watch, onMounted } from 'vue'

// components
import { commonMethods } from '@common/store/common-methods'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

export default defineComponent({
  name: 'PerformanceComparison',
  components: { SkeletonBox },
  props: {
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    type: {
      type: String,
      default: 'comparison',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Competitors' Performance",
    },
    chartTypes: {
      type: Array,
      default: () => ['custom'],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { getComponentInfoTooltip } = useCompetitorHelper()

    // mounted
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })

    // methods

    /*
     * renderItem function
     */
    const renderItem = (params, api) => {
      const group = {
        type: 'group',
        children: [],
      }

      const encode = params.encode

      const x = api.value(encode.x)
      const y = api.value(encode.y)
      const image = api.value(encode.image)
      const color = api.value(encode.color)

      const point = api.coord([x, y])

      // size of the image 45x45
      const size = 45

      // style for the circular border
      const borderStyle = {
        stroke: color,
        lineWidth: 1,
        fill: null,
      }

      // add circular border to the group
      group.children.push({
        type: 'circle',
        shape: {
          cx: point[0],
          cy: point[1],
          r: size / 2 + borderStyle.lineWidth / 2 + 1.5,
        },
        style: borderStyle,
      })

      // image style
      const imageStyle = {
        image: `https://microlinks.cstuinternal.com/format_image/?link=${encodeURIComponent(
          image
        )}`,
        x: -size / 2,
        y: -size / 2,
        width: size,
        height: size,
      }

      // add image to the group
      group.children.push({
        type: 'image',
        style: imageStyle,
        position: [point[0], point[1]],
      })

      return group
    }

    /*
     * Tooltip formatter
     */
    const tooltipFormatter = (params) => {
      const { data } = params
      const [name, engagementByFollowers, followers, image, postsPerWeek] = data

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <img src="${image}" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />
            <span class="text-[#000D21] text-base font-weight-500">${name}</span>
          </div>
          <div class="flex justify-between items-center mt-4">
            <div class="flex items-end">
              <span class="text-[979CA0] text-sm mr-1.5">Followers</span>
              <span class="text-[#69788E] text-sm font-bold">
                  ${followers.toLocaleString('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  })}
              </span>
            </div>

          </div>
           <div class="flex justify-between items-center mt-2">
            <div class="flex items-end">
              <span class="text-[979CA0] text-sm mr-1.5">Engagement Rate</span>
              <span class="text-[#69788E] text-sm font-bold">${engagementByFollowers}</span>
            </div>
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="flex items-end">
              <span class="text-[979CA0] text-sm mr-1.5">Avg. Posts Per Day</span>
              <span class="text-[#69788E] text-sm font-bold">${postsPerWeek}</span>
            </div>
          </div>
        </div>
      `
    }

    const getSeriesData = () => {
      return props.data?.map((item) => ({
        name: item.name ? item.name : item.slug,
        type: 'custom',
        dimensions: ['name', 'followers', 'engagementByFollowers'],
        animation: true,
        renderItem,
        itemStyle: {
          borderWidth: 2,
        },
        encode: {
          x: [1],
          y: [2],
          name: 0,
          image: 3,
          color: 5,
        },
        color: item.color,
        data: [
          [
            item.name ? item.name : item.slug,
            item.engagementRate,
            item.followersCount,
            item.image,
            item.averagePostsPerDay,
            item.color,
          ],
        ],
      }))
    }

    // computed chart options. uses helper methods & props to generate the options
    const chartOptions = computed(() => {
      if (props.isAllDataFetched) return {}
      return {
        legend: {
          show: props.legend.show,
          data: props.legend.data,
          width: '100%',
          top: 0,
          left: 'center',
          icon: 'circle',
          formatter: (value) => {
            return value.length > 10 ? value.substring(0, 16) + '...' : value
          },
        },
        grid: {
          left: 80,
          top: 50,
          right: 50,
          bottom: 50,
          width: 'auto',
          borderColor: '#E9EFF6',
        },
        xAxis: {
          type: 'value',
          name: props.xAxisLabel,
          boundaryGap: 0.1,
          nameLocation: 'middle',
          nameTextStyle: {
            color: 'white',
          },
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value < 0 ? '' : value
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          axisPointer: {
            type: 'line',
            snap: true,
            label: {
              precision: '2',
            },
          },
        },
        yAxis: {
          type: 'value',
          name: props.yAxisLabel,
          boundaryGap: 0.1,
          nameLocation: 'middle',
          nameTextStyle: {
            color: 'white',
          },
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value < 0
                ? ''
                : value.toLocaleString('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  })
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          axisPointer: {
            type: 'line',
            snap: true,
            label: {
              formatter: (dataPoint) => {
                return dataPoint.value.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'cross',
            label: {
              color: '#61BAE4',
              backgroundColor: '#E6F7FF',
            },
            crossStyle: {
              color: '#61BAE4',
              width: 1,
            },
          },
          formatter: tooltipFormatter,
        },
        series: [...getSeriesData()],
      }
    })

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => [chartRef.value, chartOptions.value],
      () => {
        if (!chartRef.value) return
        setup(chartOptions.value)
      }
    )

    return {
      // data
      chartRef,
      chartOptions,

      // methods
      commonMethods,
      getComponentInfoTooltip,
    }
  },
})
</script>
