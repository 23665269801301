<template>
  <div class="articles-content-container">
    <RetweetDialog></RetweetDialog>
    <div class="articles-content-container__inner">
      <!-- if no accounts connected, throw an error to the end user -->
      <div
        v-if="getTwitterAccounts.items.length === 0"
        class="no_data_found_content"
      >
        <content-available-state :type="'no_twitter_channel'">
          <h2 slot="headline">No X (Twitter) accounts connected.</h2>
          <p slot="text"
            >To get content from X (Twitter), you need to connect your X (Twitter)
            account first. </p
          ><div slot="action_btn" class="action_btn">
            <router-link
              class="mt-3 btn blue_gradient"
              :to="{
                name: 'social',
                hash: '#twitter_tab',
                params: { workspace: getActiveWorkspace.slug },
              }"
            >
              <span class="text">Connect a X (Twitter) account</span>
            </router-link>
          </div>
        </content-available-state>
      </div>
      <template v-else>
        <!-- twitter tweet item list section over here -->
        <!---->
        <template v-for="(tweet, index) in tweets">
          <TwitterTweetItem :tweet="tweet"></TwitterTweetItem>
        </template>
        <InfiniteLoading
          ref="infiniteWebYoutubeSearch"
          :identifier="infiniteId"
          @infinite="getTwitterTweetsList"
        >
          <span slot="spinner" class="d-block pt-3 pb-4">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
          <div slot="no-more" class="not_found_text">
            <div class="no_data_found_content">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No more results available.</h2>
                <p slot="text"
                  >Please try a different search string or change search
                  filters</p
                >
              </content-available-state>
            </div>
          </div>

          <div slot="no-results">
            <div class="no_data_found_content">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No results found.</h2>
                <p slot="text"
                  >Please try a different search string or change search
                  filters</p
                >
              </content-available-state>
            </div>
          </div>
        </InfiniteLoading>
      </template>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import TwitterTweetItem from '../../common/components/TwitterTweetItem'
import RetweetDialog from '../../common/RetweetDialog'

const getDefaultTwitterFilters = () => {
  return {
    language: 'all',
    limit: 30,
    page_token: null,
    placeId: null, // place id used for the location
    sinceId: null, // since id used for the pagination
    sort: 'recent',
    query: '',
    search_type: 'search_twitter'
  }
}

export default {
  components: {
    InfiniteLoading,
    TwitterTweetItem,
    RetweetDialog
  },
  data () {
    return {
      infiniteId: +new Date(),
      loaders: {
        retrieve: false
      },
      tweets: [],
      filters: getDefaultTwitterFilters()
    }
  },
  computed: {
    ...mapGetters([
      'getTwitterAccounts',
      'getActiveWorkspace',
      'getDiscoverCuratedTopic'
    ])
  },
  watch: {
    '$route.query.q' (value) {
      this.refreshTwitterSearchFilters()
      EventBus.$emit('reset-related-topics-suggestions')
    },
    '$route.query.language' (value) {
      this.refreshTwitterSearchFilters()
    },
    'getActiveWorkspace._id' (value) {
      this.$router.push({ name: 'discover_search' })
    }
  },
  created () {
    this.refreshTwitterSearchFilters()
    this.loaders.retrieve = false
  },
  methods: {
    refreshTwitterSearchFilters () {
      this.loaders.retrieve = true
      this.tweets = []
      this.infiniteId += 1
      this.filters = getDefaultTwitterFilters()
      if (this.$route.query.q) {
        this.filters.query = this.$route.query.q
      } else if (
        this.getDiscoverCuratedTopic &&
        this.getDiscoverCuratedTopic.name
      ) {
        this.filters.query = this.getDiscoverCuratedTopic.name
      }
      this.filters = Object.assign(this.filters, this.$route.query)
      this.loaders.retrieve = false
    },
    getTwitterTweetsList ($state) {
      console.debug('method:getTwitterTweetsList')
      const twitterResponse = this.$store.dispatch(
        'fetchDiscoverSearchTwitterTweets',
        this.filters
      )
      twitterResponse
        .then((res) => {
          if (res.data.status) {
            if (res.data.tweets) {
              if (res.data.tweets.length > 0) {
                this.tweets = this.tweets.concat(res.data.tweets)
              } else {
                $state.complete()
              }
            }
            if (res.data.lowest_id) {
              this.filters.page_token = this.decStrNum(res.data.lowest_id)
              $state.loaded()
            }
          } else {
            this.tweets = []
            $state.complete()
          }
        })
        .catch((err) => {})
        .finally()
      if (!this.filters.page_token) {
        EventBus.$emit('related-topics-suggestions')
      }
    }
  }
}
</script>

<style lang="less">
.action_btn {
  display: flex;
  justify-content: center;
}
</style>
