<template>
  <div class="checkbox_list">
    <div class="field_group">
      <div class="checkbox_container">
        <label for="hashtag">
          Add Hashtags to your posts
          <input
            id="hashtag"
            v-model="getPublishSelection.hashtag.status"
            type="checkbox"
            name="option2"
          />
          <span class="check"></span>
        </label>
      </div>
    </div>

    <!--<div class="checkbox_input_image block">-->
    <!--<input id="hashtag" v-model="getPublishSelection.hashtag.status" type="checkbox" name="option2">-->
    <!--<label  for="hashtag" class="checkbox_left">Add Hashtags to your posts</label>-->
    <!--</div>-->

    <transition name="slide-bottom">
      <HashtagOptions
        v-if="getPublishSelection.hashtag.status"
      ></HashtagOptions>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HashtagOptions from '../../../publish/components/posting/options/HashtagOptions'

export default {
  components: {
    HashtagOptions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getPublishSelection'])
  },
  methods: {},

  watch: {}
}
</script>
