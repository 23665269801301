export default function useCompetitorHelper() {
  /**
   * @description method to return conditional classes
   * @param competitor
   * @param dataItem
   * @param extremeValues
   * @param dataLength
   * @returns {string}
   */
  const getDataClasses = (
    competitor,
    dataItem,
    extremeValues = '',
    dataLength = 0
  ) => {
    const { minValues, maxValues } = extremeValues || {
      minValues: '',
      maxValues: '',
    }
    const value = competitor[dataItem]
    let classes =
      dataItem === 'tag'
        ? 'px-4 py-0.5 rounded-xl min-w-[6rem]'
        : 'px-4 py-0.5 rounded-xl min-w-[6rem] flex justify-center'
    if (value === 'N/A' || dataLength === 1) {
      return classes
    }

    if (extremeValues) {
      if (value <= minValues[dataItem]) {
        classes += ' bg-[#FFECF0] font-weight-500'
      } else if (value >= maxValues[dataItem]) {
        classes += ' bg-[#EEF9F3] font-weight-500'
      }
    }

    return classes
  }

  /**
   * @description returns min and max values of all the columns of the tableData
   * @param data
   * @param headers
   * @returns {{minValues: {}, maxValues: {}}}
   */
  const getMinMaxValues = (data, headers) => {
    const minValues = {}
    const maxValues = {}
    headers.forEach((key) => {
      const values = data.map((obj) => obj[key]).filter((val) => !isNaN(val))
      minValues[key] = Math.min(...values)
      maxValues[key] = Math.max(...values)
    })
    return { minValues, maxValues }
  }

  /**
   * @description as the header data is created by the keys of tableData, this method returns proper names on basis of the key names.
   * @param value
   * @returns {string}
   */
  const getHeaderTitles = (value) => {
    switch (value) {
      case 'followersCount':
        return 'Followers'
      case 'followersCountDiff':
        return 'Followers % chg.'
      case 'count':
      case 'totalPosts':
        return 'Posts'
      case 'averagePostsPerWeek':
        return 'Post/ Week'
      case 'averagePostsPerWeekDiff':
        return 'Post/ Week % chg.'
      case 'totalEngagement':
        return 'Total Engagement'
      case 'engagementRate':
        return 'ER by Followers'
      case 'engagementRateDiff':
        return 'ER by Followers % chg.'
      case 'tag':
        return 'Hashtags'
      case 'companies_using':
        return 'Competitors'
      case 'engagement_per_post':
        return 'Average Eng./ Post'
      case 'engagement_per_follower':
        return 'Eng./ Follower'
      case 'engagement_rate_by_follower':
      case 'avgEngagementRate':
        return 'ER by Followers'
      case 'biography':
        return 'Description'
      case 'biography_length':
        return `Length <span class="text-gray-700 !font-normal">(Characters)</span>`
      default:
        return 'Analytics'
    }
  }

  /**
   * @description method to find min and max values, takes any array of object and the key as params
   * @param data
   * @param key
   * @returns {{min: number, max: number}}
   */
  const getMinMax = (data = [], key = '') => {
    let max = Number.MIN_SAFE_INTEGER
    let min = Number.MAX_SAFE_INTEGER

    for (let i = 0; i < data.length; i++) {
      const value = data[i][key]
      if (value > max) {
        max = value
      }
      if (value < min) {
        min = value
      }
    }

    return { max, min }
  }

  /**
   * @description returns the refactored media type.
   * @param mediaType
   * @param mediaProductType
   * @param platformName
   * @returns {string}
   */
  const getMediaTypeName = (platformName = '', mediaType, mediaProductType = '') => {
    switch(platformName){
      case 'instagram':
        switch (mediaType) {
          case 'CAROUSEL_ALBUM':
            return 'Carousel'
          case 'VIDEO':
            return mediaProductType === 'REELS' ? 'Reel' : 'Video'
          case 'IMAGE':
            return 'Image'
        }
        break;
      case 'facebook':
        switch (mediaType) {
          case 'image':
            return 'Image'
          case 'videos':
            return 'Video'
          case 'text':
            return 'Text'
          case 'link':
            return 'Link'
          case 'carousel':
            return 'Carousel'
          case 'share':
            return 'Share'
          case 'others':
            return 'Others'
        }
        break;
      default:
        return ''
    }
  }

  const getEngagementRate = (followers, totalEngagement) => {
    return (
        ((parseInt(totalEngagement)/ followers) *
            100
        ).toFixed(2))
  }

  /**
   * @description creates a thumbnail for every media link. (was required in case of videos and reels.)
   * @param url
   * @returns {string}
   */
  const getMediaLink = (url) => {
    switch (true) {
      case url.includes('/tv/'):
        return `${url.replace('/tv/', '/p/')}media?size=l`
      case url.includes('/reel/'):
        return `${url.replace('/reel/', '/p/')}media?size=l`
      default:
        return `${url}media?size=l`
    }
  }

  const getComponentInfoTooltip = (name = '', platform = '', postType = '') => {
    switch(name){
      case 'performanceComparison':
        return `Analyze competitors' performance in terms of their followers and
              the amount of engagement they receive from their followers such as
              likes, comments on their posts.`
      case 'followersComparisonGraph':
        return `Comparing the competitors by their total number of followers vs
              the magnitude of change (increase or decrease) in the followers
              over the selected time period.
             `
      case 'postEngagement':
        return `Compare which competitor has the highest posts engagements, relative to the number of posts published during the selected time period.`
      case 'postEngagementOverTime':
        return `Analyze the daily posting patterns of competitors in relation to the level of engagements received.`
      case 'bioAnalysisTableData':
        return `Compare and analyze competitors’ bios to craft compelling and impactful bios for your own ${platform === 'facebook' ? 'page' : 'profile'}.`
      case 'mostEngagedHashtagsTable':
        return `Compare competitor posts with the most popular hashtags published during the selected time period. Gain insights to optimize your strategy.`
      case 'performanceReviewTable':
        return `Analyze competitors to gain valuable insights into their performance, including followers' growth, posting frequency, and engagements on their posts during the selected time period.`
      case 'followersGrowthComparison':
        return `Review the increase or decrease in the number of ${platform === 'facebook' ? 'Facebook' : 'Instagram' } followers, during the selected time period.`
      case 'postReactsDistribution':
        return `Gain insights into the distribution of post engagement reactions by competitors during the selected time period.`
      case 'postingActivityBySpecificType':
      case 'postingActivityTableByType':
        if (!postType) return 'Shows the highest engagement of competitors on posts of different types.'

        switch (postType) {
          case 'Reel':
          case 'VIDEO REEL':
            return 'Compare which competitor has the highest engagement on Reel posts, relative to the number of reel posts published during the selected time period.'
          case 'Video':
          case 'VIDEO':
            return 'Compare which competitor has the highest engagement on Video posts, relative to the number of video posts published during the selected time period.'
          case 'Image':
          case 'IMAGE':
            return 'Compare which competitor has the highest engagement on Image posts, relative to the number of image posts published during the selected time period.'
          case 'Carousel':
          case 'CAROUSEL ALBUM':
            return 'Compare which competitor has the highest engagement on Carousel posts, relative to the number of carousel posts published during the selected time period.'
          default:
            return 'Data not available'
        }
      case 'fbPostingActivityBySpecificType':
        if (!postType)
          return 'Shows the highest engagement of competitors on posts of different types.'

        switch (postType) {
          case 'Text':
            return 'Compare which competitor has the highest engagement on Text posts, relative to the number of text posts published during the selected time period.'
          case 'Images':
          case 'Image':
            return 'Compare which competitor has the highest engagement on Image posts, relative to the number of image posts published during the selected time period.'
          case 'Video':
            return 'Compare which competitor has the highest engagement on Video posts, relative to the number of video posts published during the selected time period.'
          case 'Shared':
            return 'Compare which competitor has the highest engagement on Shared posts, relative to the number of shared posts published during the selected time period.'
          case 'Others':
            return `Compare which competitor has the highest engagement on Other posts, relative to the number of others posts published during the selected time period.`
          case 'Link':
            return 'Compare which competitor has the highest engagement on Link posts, relative to the number of link posts published during the selected time period.'
          case 'Carousel':
            return 'Compare which competitor has the highest engagement on Carousel posts, relative to the number of carousel posts published during the selected time period.'
          default:
            return 'Data not available'
        }
      default:
        return ``
    }
  }

  const getTableHeaderTooltips = (value, platform, tableType, selectedPostType) => {
    switch (value) {
      case 'biography':
        return `<p class="text-left text-sm">Description in ${platform === 'facebook' ? 'Facebook' :  'Instagram Bio'}</p>`
      case 'biography_length':
        return `<p class="text-left text-sm">Length of description in ${platform === 'facebook' ? 'Facebook': 'Instagram'} Bio.</p>`
      case 'tag':
        return `<p class="text-left text-sm">List of top most hashtags used by the competitors.</p>`
      case 'companies_using':
        return `<p class="text-left text-sm">Shows a list of competitors who use the given hashtag.</p>`
      case 'engagement_per_post':
        return `<p class="text-sm text-left font-bold">Average Engagement Per Post</p><p class="text-sm text-left">The average number of engagement actions (reactions, comments${platform === 'facebook' ? ' and shares': ''}) on every post which used the given hashtag, published during the selected time period.</p>`
      case 'engagement_per_follower':
        return '<p class="text-sm text-left font-bold">Engagement Per Page Follower</p><p class="text-sm text-left">The average number of engagement actions by followers.</p>'
      case 'engagement_rate_by_follower':
        return `<p class="text-sm text-left font-bold">Engagement Rate by Followers</p><p class="text-sm text-left">The average number of engagement actions (reactions, comments${platform === 'facebook' ? ' and shares': ''}) per post, per follower.</p>`
      case 'followersCount':
        return `<p class="text-left text-sm">The total number of people following on ${platform === 'facebook' ? 'Facebook': 'Instagram'}.</p>`
      case 'followersCountDiff':
        return '<p class="text-sm text-left font-bold">Percentage (%) change in Followers</p><p class="text-sm text-left">The percentage increase or decrease in the followers during the selected time period.</p>'
      case 'count':
      case 'totalPosts':
        if (tableType === 'mostEngagedHashtagsTable')
          return `<p class="text-left text-sm">The number of posts published which contain the given hashtag.</p>`
        return tableType === 'postingActivityTableByType'
            ? '<p class="text-sm text-left">The number of posts published during the selected time period.</p>'
            : '<p class="text-sm text-left">The average number of posts published per week during the selected time period.</p>'
      case 'averagePostsPerWeek':
        return '<p class="text-sm text-left">The average number of posts published per week during the selected time period.</p>'
      case 'averagePostsPerWeekDiff':
        return '<p class="text-sm text-left font-bold">Percentage (%) change in Post/Week</p><p class="text-sm text-left">The percentage increase or decrease in the posts per week during the selected time period.</p>'
      case 'totalEngagement':
        return `<p class="text-left text-sm">The total number of engagement actions (reactions, comments${platform === 'facebook' ? ' and shares': ''}) on the ${selectedPostType} posts, published during the selected time period.</p>`
      case 'engagementRate':
        return `<p class="text-sm text-left font-bold">Engagement Rate by Followers</p><p class="text-sm text-left">The average number of engagement actions (reactions, comments${platform === 'facebook' ? ' and shares': ''}) per post, per follower on ${
            tableType === 'postingActivityTableByType'
                ? selectedPostType
                : 'all posts'
        }, published during the selected time period, expressed as a percentage.</p>`
      case 'engagementRateDiff':
        return '<p class="text-sm text-left font-bold">Percentage (%) change in Engagement Rate by Followers</p><p class="text-sm text-left">The percentage increase or decrease in the Eng. Rate during the selected time period.</p>'
      default:
        return ``
    }
  }

  return {
    getDataClasses,
    getMinMaxValues,
    getHeaderTitles,
    getMinMax,
    getMediaTypeName,
    getEngagementRate,
    getMediaLink,
    getComponentInfoTooltip,
    getTableHeaderTooltips
  }
}
