<template>
  <div class="ds_card_container engagement_rate_block_1_3">
    <div class="card_v1">
      <div class="card_inner">
        <div class="card_head">
          <h2>{{ title }}</h2>
        </div>
        <div
          class="card_content relative"
          :class="{ card_content_blur: isZero() }"
        >
          <template v-if="isZero()">
            <div class="no_data_for_chart">{{ noDataMessage }}</div>
          </template>
          <!--<div :class="{'blur_div': isZero()}">-->
          <div class="content_left" :class="{ blur_div: isZero() }">
            <highcharts
              ref="influencer_overview_chart"
              :options="overviewData"
            ></highcharts>
          </div>
          <div class="content_right" :class="{ opacity_div: isZero() }">
            <ul
              id="influencer_domain_item_highlight"
              class="ds_item_highlight ds_item_highlight_domain"
            >
            </ul>
          </div>
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  components: {
    highcharts
  },
  props: ['title', 'data'],
  data () {
    return {
      noDataMessage: 'No data found',

      overviewData: {
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: 'pie',
          spacingBottom: 0,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,

          style: {
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          events: {
            load: function () {
              const chart = this
              $('#facebook_item_highlight').empty()
              $(chart.series[0].data).each(function (i, serie) {
                // console.log(serie)
                $(
                  '<li><span style="border:3px solid ' +
                    serie.color +
                    ';" class=" color_box"></span> <span>' +
                    serie.name +
                    '</span></li>'
                )
                  .click(function () {
                    if (serie.visible) {
                      serie.setVisible(false)
                      $(this).addClass('disabled')
                    } else {
                      serie.setVisible(true)
                      $(this).removeClass('disabled')
                    }
                  })
                  .appendTo('#influencer_domain_item_highlight')
              })
            }
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0
          },
          pie: {
            // innerSize: '60%',
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: false
          }
        },
        colors: ['#8085e9', '#f45b5b', '#e6ce6e'],
        tooltip: {
          headerFormat:
            '<div class="chart_tooltip engagement_tooltip"><h4>{series.name}</h4>',
          pointFormat:
            '<p class="engagement_point"><span>{point.y}% on {point.name}</span></p>',
          footerFormat: '</div>',
          backgroundColor: null,
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          crosshairs: {
            width: 50,
            color: '#fafafa'
          }
        },
        title: {
          text: ''
        },
        series: [
          {
            name: 'Domain Shares',
            size: '100%',
            innerSize: '65%',
            data: [
              ['forbes.com', 10],
              ['inc.com', 20],
              ['techcrunch.com', 40],
              ['mashable.com', 10],
              ['gizmodo.com', 20],
              ['cnn.com', 40],
              ['bbc.com', 10]
            ]
          }
        ]
      }
    }
  },
  created () {
    if (this.isZero() === false) {
      this.overviewData.series[0].name = this.title
      this.overviewData.series[0].data = this.data
    }
  },
  methods: {
    isZero () {
      if (this.data && this.data.length === 0) return true
      return false
    }
  }
}
</script>
