<template>
  <div>
    <div class="row">
      <template v-for="(platform, key) in accountPerformance">
        <div
          v-if="
            key !== 'overall' &&
            key !== 'twitter' &&
            key !== 'pinterest' &&
            ((key === 'facebook' && accountSelectFlags.facebook) ||
              (key === 'instagram' && accountSelectFlags.instagram) ||
              (key === 'linkedin' && accountSelectFlags.linkedin) ||
              (key === 'tiktok' && accountSelectFlags.tiktok) ||
              (key === 'youtube' && accountSelectFlags.youtube))
          "
          :key="key"
          class="col-6"
        >
          <div class="analytics-engagement-box">
            <div class="analytics-engagement-box__header">
              <img :src="getPlatformImagePath(key)" alt="" class="w-7 h-7" />
              <h3>{{ capitalizeFirstLetter(key) }}</h3>
            </div>

            <div class="analytics-engagement-box__chart">
              <div class="flex-center-center-column">
                <PieInnerChart
                  :width="280"
                  :chart-ref="`${key}-analytics-pie`"
                  :height="280"
                  :colors="getColors(key)"
                  :data="getData(platform)"
                  :series-name="capitalizeFirstLetter(key)"
                  :title="platform['total_engagement']"
                  @mounted="getPieRef"
                />
                <small
                  >Total Engagements from
                  <b>{{ platform['total_posts'] }}</b> Posts Sent</small
                >
              </div>
            </div>

            <div class="analytics-engagement-box__detail">
              <div class="analytics-legend-wrapper">
                <template
                  v-for="(obj, i) in legends[`${key}-analytics-pie`]"
                  :key="i"
                >
                  <div
                    class="analytics-engagement-box__detail-item"
                    @click="legendClick(obj, obj.name !== 'No Data Found')"
                  >
                    <div class="analytics-engagement-box__detail-label">
                      <span
                        :style="{ borderColor: obj.color }"
                        class="analytics-engagement-box__detail-label-icon"
                      ></span>
                      {{ capitalizeFirstLetter(obj.name) }}
                    </div>
                    <div
                      v-if="obj.name !== 'No Data Found'"
                      class="analytics-engagement-box__detail-value"
                    >
                      {{ obj.y }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper.js'
import PieInnerChart from '@src/modules/analytics/views/common/Infographics/PieInnerChart'

export default {
  name: 'AnalyticsEngagement',
  components: {
    PieInnerChart,
  },
  mixins: [analyticsUtilsMixin, ComposerHelperMixin],
  props: {
    accountPerformance: {
      type: Object,
      default: () => {},
    },
    accountSelectFlags: {
      type: Object,
      default: () => {
        return {
          facebook: false,
          instagram: false,
          linkedin: false,
          tiktok: false,
          twitter: false,
          pinterest: false,
          youtube: false,
        }
      },
    },
  },
  data() {
    return {
      legends: {},
    }
  },
  methods: {
    getPlatformImagePath(key) {
      return require('@assets/img/integration/' + this.getSocialImage(key))
    },
    legendClick(point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef(chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    getColors(network) {
      switch (network) {
        case 'facebook':
          return ['#6186d2', '#91A9DB', '#c3cde2']
        case 'instagram':
          return ['#e58cf8', '#ad98ef', '#c199e1', '#b29fee']
        case 'linkedin':
          return ['#1177b5', '#2a90cb', '#1177b5']
        case 'twitter':
          return ['#1cadee', '#188abe', '#7bd6ff']
        case 'tiktok':
          return ['#1fa1ee', '#148ebe', '#7776ff']
        case 'youtube':
          return ['#f81d1d', '#FF6666', '#FF9999', '#FFFFFF']
      }
    },
    getData(account) {
      const data = []
      for (const value in account) {
        if (value !== 'total_engagement' && value !== 'total_posts') {
          data.push([this.capitalizeFirstLetter(value), account[value]])
        }
      }
      return data
    },
  },
}
</script>
