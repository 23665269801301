<script setup>
import { computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'

// state
const {
  activeUsersData,
  dataZoomOptions,
  lineChartOptions: chartOptions,
  isReportView,
  isLoadingStates,
  routes,
} = useFacebookAnalytics('Active User By Days')

defineProps({
  type: {
    type: String,
    default: 'All',
  },
})


const isNoData = computed(() => {
  return activeUsersData.value?.active_users_days?.highest_value === 0
})

const bestDay = computed(() => activeUsersData.value?.active_users_days?.highest_day)
const highestValue = computed(() => activeUsersData.value?.active_users_days?.highest_value)

watch(
  () => activeUsersData.value,
  () => {
    const options = activeUsersData.value?.active_users_days || {}
    chartOptions.value.series[0].data = options?.values || []

    chartOptions.value.xAxis.data = options?.buckets
    chartOptions.value.xAxis.axisLabel.formatter = null;
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(() => isLoadingStates.value?.[routes.OVERVIEW_ACTIVE_USERS])

</script>

<template>
  <AnalyticsCardWrapper type="active-by-days-fb" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Active Users By Days
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Track the number of active users on your page by days over the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
        <p v-if="bestDay && highestValue" class="text-center mt-6">
          Best day to post is <span class="font-semibold">{{bestDay}}</span> with highest value <span class="font-semibold">{{highestValue}}</span>
        </p>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
