<template>
  <b-modal
    :id="id"
    hide-header
    no-close-on-backdrop
    modal-class="saved_replies_modal"
    no-close-on-esc
    hide-footer
    @show="shouldShowReplyListing"
  >
    <div class="modal_head d-flex align-items-center top-bar">
      <button
        v-if="!showListing"
        v-tooltip.bottom="'Back'"
        type="button"
        class="close ml-0 button-custom w-8"
        data-dismiss="modal"
        style="padding: 0"
        @click="shouldShowReplyListing"
      >
        <i class="far fa-angle-left"></i>
      </button>
      <p class="header-custom">
        {{
          !showListing
            ? editReply
              ? 'Update Reply'
              : 'Create New Reply'
            : 'Saved Replies'
        }}</p
      >
      <button
        v-if="showListing"
        v-tooltip.bottom="'Close'"
        type="button"
        class="close button-custom"
        data-dismiss="modal"
        @click="$bvModal.hide(id)"
      >
        &times;
      </button>
      <div
        v-if="
          !showListing &&
          editPayload !== null &&
          editPayload.user_details !== null
        "
        class="d-flex user-details user-details-custom"
      >
        <div class="text">
          <p style="font-size: 13px"
            >Updated {{ commonMethods.timesAgo(editPayload.updated_time) }}</p
          >
          <p style="font-size: 12px"
            >by <b>{{ editPayload.user_details.full_name }}</b></p
          >
        </div>
        <div class="img" style="display: inline-block; margin-left: 0.625rem">
          <img
            v-if="
              typeof editPayload.user_details.image !== 'undefined' &&
              editPayload.user_details.image !== null &&
              editPayload.user_details.image
            "
            :src="editPayload.user_details.image"
            alt=""
          />
          <img
            v-else
            :src="'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            alt=""
          />
        </div>
      </div>
    </div>
    <SavedReplyListing
      v-if="showListing"
      :id="id"
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
      :should-show-create-new="shouldShowCreateNew"
    ></SavedReplyListing>
    <SavedReplyCreateNew
      v-else
      :active-inbox-detail="activeInboxDetail"
      :edit-reply="editReply"
      :edit-payload="editPayload"
      :should-show-reply-listing="shouldShowReplyListing"
    ></SavedReplyCreateNew>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import SavedReplyListing from './SavedReplyListing'
import SavedReplyCreateNew from './SavedReplyCreateNew'

export default {
  components: {
    SavedReplyListing,
    SavedReplyCreateNew,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: 'savedReplyModal',
    },
    applySavedReply: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      commonMethods,
      showListing: true, // will show saved reply listing else create
      editReply: false, // editing?
      editPayload: null, // editing payload .. will be null for create new
    }
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions([]),
    shouldShowReplyListing() {
      this.editReply = false
      this.editPayload = null
      this.showListing = true
    },
    shouldShowCreateNew(isEditReply, editPayloadExist) {
      this.editReply = isEditReply
      this.editPayload = editPayloadExist
      this.showListing = false
    },
  },
}
</script>

<style lang="less" scoped>
.modal.normal_modal .modal-dialog .modal-content .modal_head .close {
  background: transparent;
}

.top-bar {
  padding-top: 0.625rem;
  padding-right: 20px;
}

.button-custom {
  height: 35px;
  //width: 35px;
  font-size: 25px !important;
  background: #f6f6f6 !important;
}

.header-custom {
  font-weight: bold;
  font-size: 20px;
}

.user-details-custom {
  text-align: right;
  display: inline-flex !important;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 3px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}
</style>
