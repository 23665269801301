<template>
  <b-modal
    id="createLabelModal"
    hide-footer
    hide-header
    modal-class="createLabelModal  normal_modal"
    @hidden="modalHide"
  >
    <div class="modal_head d-flex align-items-center p-0">
      <h2>Assign Tag</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('createLabelModal')"
      >
        &times;
      </button>
    </div>

    <div
      class="modal_body basic_form -mt-8"
      style="display: flex; flex-direction: column"
    >
      <div v-if="labelList" class="old_label_list">
        <div v-if="allTags.length > 0" class="input_field mt-0">
          <label for="">Select from existing tags</label>
          <div class="tag_list_new filter_label mt-0">
            <div
              v-for="tag in allTags"
              :key="tag._id"
              class="checkbox_input_image"
            >
              <input
                :id="tag._id + 'create'"
                v-model="selectedTags"
                type="checkbox"
                :value="tag._id"
                :disabled="alreadySelected.includes(tag._id)"
              />
              <label :for="tag._id + 'create'" class="checkbox_left">
                <span
                  class="mb-1 mr-1 label_tag_round_dark"
                  :class="tag.tag_color"
                  >{{ tag.tag_name }}
                  <i class="check-icon fas fa-check"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div v-else class="input_field mt-0">
          <label for=""
            >You have not created any tags yet. Create a new one.</label
          >
        </div>
      </div>

      <div v-else class="create_label_common">
        <div class="input_field mt-0">
          <label for="">Name</label>
          <input
            v-model="tagName"
            type="text"
            placeholder="Enter tag name..."
          />
        </div>

        <div class="field_group">
          <div class="create_label_common">
            <label for="" class="p15">Choose Color</label>
            <div class="create_label_common__label_boxes">
              <template v-for="(value, index) in labelsCount" :key="index" >
                <div class="create_label_common__box_item">
                  <input
                    :id="'color_' + index"
                    type="radio"
                    name="color_select"
                    :v-bind="tagColor"
                    class="icon"
                    :value="'color_' + index"
                    @input="tagColor = 'color_' + index"
                  />
                  <label
                    :for="'color_' + index"
                    :class="'item_inner color_' + index"
                  >
                    <i
                      class="check_icon_planner far fa-check d-flex h-100 align-items-center justify-content-center"
                    >
                    </i>
                  </label>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-if="labelList" class="input_field">
        <button
          v-if="allTags.length > 0"
          class="btn gradient_btn large_btn"
          @click="assignTag"
        >
          <span>Assign</span>
        </button>
        <button
          v-if="allTags.length > 0"
          class="btn gray_light ml-2 large_btn"
          @click="labelList = false"
        >
          <span>Create A New Tag</span>
        </button>
        <button
          v-else
          class="btn gradient_btn justify_center align_center large_btn"
          @click="labelList = false"
        >
          <span>Create A New Tag</span>
        </button>
      </div>

      <div v-else class="input_field">
        <button class="btn gradient_btn large_btn" @click="createTag">
          <span>Create</span>
        </button>
        <button class="btn light_gray ml-2 large_btn" @click="resetTag">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'underscore'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {},
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
    typeFilters: { type: Array, default: () => [] },
    allTags: { type: Array, default: () => [] },
  },
  data() {
    return {
      labelsCount: 20,
      tagName: '',
      tagColor: null,
      labelList: true,
      selectedTags: [],
      alreadySelected: [],
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
  },
  watch: {
    'activeInboxDetail.element_details.element_id': function (newVal, oldVal) {
      this.selectedTags = _.pluck(
        this.activeInboxDetail.inbox_details.tags,
        '_id'
      )
      this.alreadySelected = this.selectedTags
      this.tagColor = null
      this.tagName = ''
    },
  },

  mounted() {
    this.selectedTags = _.pluck(
      this.activeInboxDetail.inbox_details.tags,
      '_id'
    )
  },

  methods: {
    ...mapActions(['createTagDetails', 'fetchWorkspaceTags']),
    resetTag() {
      this.labelList = true
      this.tagColor = null
      this.tagName = ''
    },
    modalHide() {
      this.labelList = true
      this.selectedTags = _.pluck(
        this.activeInboxDetail.inbox_details.tags,
        '_id'
      )
      this.tagColor = null
      this.tagName = ''
    },
    assignTag() {
      this.$emit('apply-tag', {
        tags: this.selectedTags,
      })
      this.selectedTags = []
      this.$bvModal.hide('createLabelModal')
    },

    async fetchAllTags() {
      const response = await this.fetchWorkspaceTags({
        workspace_id: this.getActiveWorkspace._id,
      })
      if (response.isValid) {
        this.allTags = _.sortBy(response.tags, 'tag_name')
      } else {
        this.allTags = []
      }
    },
    async createTag() {
      if (this.tagName === '' || this.tagColor == null) {
        this.alertMessage('Tag name or Tag color is missing.', 'error')
        return
      }

      const createTagResp = await this.createTagDetails({
        workspace_id: this.getActiveWorkspace._id,
        name: this.tagName,
        color: this.tagColor,
        tag_ids: null,
      })

      if (createTagResp.isValid) {
        this.tagName = ''
        this.tagColor = null
        this.selectedTags = []
        this.selectedTags.push(createTagResp.tagDetails._id)
        this.$emit('apply-tag', {
          tags: this.selectedTags,
        })
        EventBus.$emit('update-side-bar', {typeFilters: this.typeFilters, isDefault: true})
        this.selectedTags = []
        this.$bvModal.hide('createLabelModal')
      } else {
        this.alertMessage(createTagResp.message, 'error')
      }
    },
  },
}
</script>
