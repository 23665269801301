/**
 * Vue composable for the competitor analytics.
 * Functionality:
 * - Performs the api call
 * - Provides the api response to the template
 * - Provides the error state to the template
 */

// libs
import {reactive, ref, toRefs} from 'vue'

// helpers
import {fetchAnalyticsData, notificationHandler} from '@src/modules/analytics_v3/helper/services'

// constants
import {COMMON_API_ENDPOINTS, COMMON_ERROR_MESSAGES} from '@src/modules/analytics_v3/constants/constants'


const state = reactive({
    allReports: [],
    platform: '',
})

export default function useCompetitorsFactory() {
    const apiResponse = ref([])
    const loading = ref(false)

    async function fetchCompetitorInfo(apiEndPoint, apiPayload, errorText) {
        try {
            loading.value = true;
            const response = await fetchAnalyticsData(apiEndPoint, apiPayload);
            if(response?.error){
                apiResponse.value = response?.data || response?.res || []
                await notificationHandler(response?.error, 'error')
                return
            }
            const responseData = response?.data || response?.res || [];
            if (apiEndPoint === COMMON_API_ENDPOINTS.DATA_TABLE_METRICS && response?.data_table_metrics) {
                apiResponse.value = response.data_table_metrics;
            } else if (apiEndPoint === COMMON_API_ENDPOINTS.FETCH_ALL_REPORTS) {
                // Sort and set allReports state
                state.allReports = responseData.sort((a, b) => (a?.created_at < b?.created_at ? 1 : -1)) || [];
            } else {
                apiResponse.value = responseData;
            }

            return responseData;
        } catch (error) {
            if(apiEndPoint === COMMON_API_ENDPOINTS.FETCH_ALL_REPORTS) state.allReports = []
            apiResponse.value = error
            await notificationHandler(errorText || error?.error || error?.message || COMMON_ERROR_MESSAGES.DEFAULT, 'error')
        } finally {
            loading.value = false
        }
    }

    return {
        // data
        ...toRefs(state),
        apiResponse,
        loading,

        // methods
        fetchCompetitorInfo,
    }
}
