<script setup>
import { ref } from 'vue'
import PerformancePostPreviewModal from '@src/modules/analytics_v3/components/PerformancePostPreviewModal.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import FacebookPublishedPostPreview from '@/src/modules/analytics_v3/components/FacebookPublishedPostPreview.vue';

defineProps({
  selectedPost: {
    type: Object,
    default: () => ({}),
  },
  selectedAccount: {
    type: Object,
    default: () => ({}),
  },
})

const isLoading = ref(true)
const { tooltipHtml } = useComposerHelper()
const { postModalFields } = useFacebookAnalytics()

const handleModalShow = () => {
  isLoading.value = true
}
const handleModalHidden = () => {
  isLoading.value = true
}
</script>

<template>
  <PerformancePostPreviewModal
    :preview-post-data="selectedPost"
    :is-modal="false"
    :type="'post-details-modal'"
    @show="handleModalShow"
    @hidden="handleModalHidden"
  >
    <template v-slot:modal-title>
      <h4>Post Preview</h4>
    </template>
    <template v-slot:sidebar-title>
      <p class="font-weight-500 mb-[1rem]">Statistics</p>
    </template>
    <template v-slot:sidebar-content>
      <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
        <template v-for="({ iconSrc, label, key, tooltip, iconClass }, index) in postModalFields" :key="index">
          <div class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]">
            <img
              :src="iconSrc"
              :alt="`${label} Icon`"
              class="w-6 h-6 my-auto"
              :class="iconClass"
            />
            <div class="flex flex-col ml-5">
              <span
              v-tooltip="{
                content: tooltipHtml(tooltip),
                theme: 'light',
                allowHTML: true,
              }"
               class="text-gray-800 group-hover:text-black-900"
               >
                {{ label }}
              </span>
              <span
                class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500 capitalize"
              >
                {{ selectedPost[key] }}
              </span>
            </div>
          </div>
        </template>

      </div>
    </template>
    <template v-slot:post-preview>
      <FacebookPublishedPostPreview
        :container-id="`fb-post-container-${selectedPost?.id}`"
        class="flex justify-center"
        :post-link="selectedPost?.permalink"
        width="350"
      />
    </template>
  </PerformancePostPreviewModal>
</template>
