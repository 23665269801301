<template>
  <div class="media-library-sidebar">
    <!--  Sidebar Header  -->
    <div class="media-library-sidebar__header">
      <div class="media-library-sidebar__header__label">Folders</div>
      <div class="media-library-sidebar__header__button">
        <button
          v-b-tooltip.left.hover
          class="btn btn-studio-theme-transparent-grey btn-size-small"
          title="Create New Folder"
          @click="$bvModal.show('create-media-folder')"
        >
          <i class="icon-add-new-member-cs"></i>
        </button>
      </div>
    </div>

    <!--  Sidebar Options  -->
    <div class="media-library-sidebar__options">
      <!--  Sidebar Option  -->
      <div
        class="media-library-sidebar__option"
        :class="{ active: $route.query.type === 'all' }"
        @click="handleRoute('all')"
      >
        <div class="media-library-sidebar__option__item">
          <div class="media-library-sidebar__option__item-icon">
            <i class="far fa-layer-group"></i>
          </div>
          <div class="media-library-sidebar__option__item-text">
            All Uploads
          </div>
        </div>
        <div class="media-library-sidebar__option__item-count">
          {{ filesCount.all }}
        </div>
      </div>

      <div
        id="archived"
        class="media-library-sidebar__option"
        :class="{ active: $route.query.type === 'archived' }"
        data-dragover="false"
        @drop="
          onDrop($event, getElement('archived'), selectedItems, isAllSelected)
        "
        @dragenter.prevent="onDragEnter($event, getElement('archived'))"
        @dragleave.prevent="onDragLeave($event, getElement('archived'))"
        @dragover.prevent
        @click="handleRoute('archived')"
      >
        <div class="media-library-sidebar__option__item">
          <div class="media-library-sidebar__option__item-icon">
            <i class="far fa-trash"></i>
          </div>
          <div class="media-library-sidebar__option__item-text"> Archived </div>
        </div>
        <div class="media-library-sidebar__option__item-count">
          {{ filesCount.archived }}
        </div>
      </div>

      <!--  Sidebar Option  -->
      <div
        id="uncategorized"
        class="media-library-sidebar__option"
        :class="{ active: $route.query.type === 'uncategorized' }"
        data-dragover="false"
        @drop="
          onDrop(
            $event,
            getElement('uncategorized'),
            selectedItems,
            isAllSelected,
          )
        "
        @dragenter.prevent="onDragEnter($event, getElement('uncategorized'))"
        @dragleave.prevent="onDragLeave($event, getElement('uncategorized'))"
        @dragover.prevent
        @click="handleRoute('uncategorized')"
      >
        <div class="media-library-sidebar__option__item">
          <div class="media-library-sidebar__option__item-icon">
            <i class="far fa-folder"></i>
          </div>
          <div class="media-library-sidebar__option__item-text">
            Uncategorized
          </div>
        </div>
        <div class="media-library-sidebar__option__item-count">
          {{ filesCount.uncategorized }}
        </div>
      </div>
    </div>

    <div class="sidebar-divider"></div>

    <!--  Sidebar Folders  -->
    <div class="media-library-sidebar__folders">
      <template v-if="isFolderFetching">
        <div class="stack-folder__wrapper">
          <SkeletonBox width="100%" height="40px" radius="12px" />
        </div>
        <div class="stack-folder__wrapper">
          <SkeletonBox width="100%" height="40px" radius="12px" />
        </div>
      </template>
      <template v-else>
        <div v-if="folders.length === 0" class="media-library-sidebar__note">
          <i
            >You don't have any folder yet, please
            <span @click="$bvModal.show('create-media-folder')"
              >create a new folder</span
            >.</i
          >
        </div>
        <Folder
          v-for="(folder, i) in folders"
          v-else
          :key="i"
          type="primary"
          :folder="folder"
          @drop="(e, ele) => onDrop(e, ele, selectedItems, isAllSelected)"
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
          @folderclick="handleClick"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { DragDropMixin } from '../utils/helper'
import SkeletonBox from '../../../../analytics/views/common/SkeletonBox'
import Folder from './Folder'

export default {
  name: 'SideBar',
  components: {
    Folder,
    SkeletonBox
  },
  mixins: [DragDropMixin],
  props: [
    'selectedItems',
    'folders',
    'isFolderFetching',
    'filesCount',
    'isAllSelected'
  ],
  watch: {
    isMediaMoving (val) {
      this.$emit('move', val)
    }
  },
  methods: {
    handleClick (folderId, isRoot) {
      console.debug('Method:handleClick', folderId)
      this.$router.push({
        name: 'media-library',
        query: {
          type: 'folder',
          folderId,
          root: isRoot
        }
      })
    },
    getElement (id) {
      return document.getElementById(id)
    },
    handleRoute (type) {
      console.debug('Method:handleRoute', type)
      this.$router.push({
        name: 'media-library',
        query: {
          type
        }
      })
    }
  }
}
</script>
