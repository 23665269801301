<template>
  <div
      class="bg-white p-5 rounded-md w-full relative"
      :class="{ 'color-border cst-editor': !isModal }"
  >
    <div class="mb-6 w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <template
            v-if="selectedType && type === 'postingActivityBySpecificType'"
        >
          <CstDropdown
              v-if="selectedType"
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              button-classes="flex !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !w-[7.5rem] !px-0"
              :disabled="!data.length"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-base font-weight-500 capitalize">{{
                  selectedType + ' Post'
                }}</p>
            </template>
            <template v-slot>
              <template
                  v-for="(mediaType, index) in allAvailableTypes"
                  :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="changeChartType(mediaType)">
                  <p class="text-gray-900 text-sm">{{ mediaType + ' Post' }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <h2 class="text-base font-weight-500 select-none capitalize">{{
              title
            }}</h2>
        </template>
        <p v-else class="text-base font-weight-500 select-none"
        >Posting Activity by Post Type
          <span class="text-gray-800 font-normal"
          >(Carousel, Reel, Video, Image)</span
          >
        </p>
        <v-menu
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
            class="inline-block"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{
                type === 'allTypesGraph'
                    ? 'Compare which post type (i.e. reel, carousel, video, and image) has the highest engagement relative to the number of posts published by all competitors during the selected time period.'
                    : getComponentInfoTooltip(type, platform, data && data[0] ? data[0].mediaType : '')
              }}
            </p>
          </template>
        </v-menu>
      </div>
      <div
          v-if="!isModal && data.length"
          v-tooltip="{
          content: 'Maximize',
          theme: 'light',
        }"
          class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
          @click="$bvModal.show(`double-bar-${type}-modal`)"
      >
        <img
            src="@src/assets/img/full_screen.svg"
            alt="fullScreen"
            class="w-5 h-5"
        />
      </div>
      <div
          v-if="isModal"
          v-tooltip="{
          content: 'Minimize',
          theme: 'light',
        }"
          class="
          cursor-pointer
          w-10
          h-10
          flex
          justify-center
          items-center
          bg-gray-50
          hover:bg-gray-100
          active:bg-gray-300
          rounded-full
        "
          @click="$bvModal.hide(`double-bar-${type}-modal`)"
      >
        <i class="fa fa-times fa-lg" aria-hidden="true"></i>
      </div>
    </div>
    <div v-if="isLoading">
      <SkeletonBox class="!w-full !h-[30rem]"></SkeletonBox>
    </div>
    <div
        v-else-if="isAllDataFetched"
        class="
        analytics-no-data-found
        h-5/6
        relative
        right-0
        left-0
        z-10
        bg-white
      "
    >
      <img src="@src/assets/img/analytics/data_fetching.gif" alt=""/>
      <p>Data is being fetched.</p>
    </div>
    <div
        v-else-if="!data.length"
        class="
        analytics-no-data-found
        h-5/6
        relative
        right-0
        left-0
        z-10
        bg-white
      "
    >
      <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
      />
      <p v-if="type === 'allTypesGraph'"
      >No posting activity found for all the types.</p
      >
      <p v-else>No posting activity found for any type.</p>
    </div>
    <div v-else class="flex w-full h-full">
      <div class="flex-1">
        <div
            ref="chartRef"
            :style="{ height: isModal ? '85vh' : chartHeight }"
        ></div>
      </div>
    </div>
    <b-modal
        :id="`double-bar-${type}-modal`"
        centered
        hide-footer
        hide-header
        content-class="mt-2 !rounded-none h-screen"
        dialog-class="cst-modal"
        @hide="commonMethods.toggleWidgets(false)"
        @shown="commonMethods.toggleWidgets(true)"
    >
      <DoubleBarChart
          :title="title"
          :x-axis-label="xAxisLabel"
          :y-axis-label="yAxisLabel"
          :y-axis-second-label="yAxisSecondLabel"
          :type="type"
          :is-modal="true"
          :selected-type="selectedType"
          :all-available-types="allAvailableTypes"
          :data="data"
          :is-loading="isLoading"
          :legend="legend"
          :colors="colors"
          :date-range-diff="dateRangeDiff"
          :platform="platform"
      />
    </b-modal>
  </div>
</template>

<script>

// libraries
import {defineComponent, computed, watch, onMounted} from 'vue'

// components
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import {commonMethods} from '@common/store/common-methods'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import useCompetitorHelper from "@src/modules/analytics_v3/composables/useCompetitorHelper";

export default defineComponent({
  name: 'DoubleBarChart',
  components: {SkeletonBox, CstDropdownItem, CstDropdown},
  props: {
    title: {
      type: String,
      default: "Competitors' Analytics",
    },
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    yAxisSecondLabel: {
      type: String,
      default: 'Followers %',
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'allTypesGraph',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    selectedType: {
      type: String,
      default: '',
    },
    allAvailableTypes: {
      type: Array,
      default: () => [],
    },
    chartTypes: {
      type: Array,
      default: () => ['bar'],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dateRangeDiff: {
      type: String,
      default: '30',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    platform: {
      type: String,
      default: '',
    }
  },
  emits: ['chart-type'],
  setup(props, {emit}) {

    const {getComponentInfoTooltip} = useCompetitorHelper()
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })


    /**
     * @description emits the selected type to change chart.
     * @param value
     */
    const changeChartType = (value) => {
      let type = ''
      switch (value) {
        case 'Image':
          type = 'IMAGE'
          break
        case 'Video':
          type = 'VIDEO'
          break
        case 'Reel':
          type = 'VIDEO REEL'
          break
        case 'Carousel':
          type = 'CAROUSEL ALBUM'
          break
      }
      emit('chart-type', type)
    }

    /**
     * @description method to check and create labels(having slug) or label objects having images as background colors to be plotted on axisLabels
     */
    const getLabels = () => {
      if (props.type !== 'allTypesGraph') {
        const labels = {}
        props.data.forEach((item) => {
          labels[
              item.businessAccountId
              ] = `https://microlinks.cstuinternal.com/format_image/?link=${encodeURIComponent(
              item.image
          )}`
        })
        for (const item in labels) {
          labels[item] = {
            height: 35,
            width: 35,
            align: 'center',
            backgroundColor: {
              image: labels[item],
            },
          }
        }
        return labels
      }
    }

    /*
     * Tooltip formatter
     */
    const tooltipFormatter = (params) => {
      if (props.type === 'allTypesGraph') {
        return `
        <div class="flex flex-col">
          <span class="text-[#000D21] text-base font-weight-500">${params[0].data[0]
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase())}
          </span>
          <div class="flex items-end justify-between mt-3">
              <span class="text-[979CA0] text-sm mr-1.5">Average Engagement</span>
              <span class="text-[#69788E] text-sm font-bold">${
            params[0].data[2]
        }</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">${
            props.dateRangeDiff >= 7
                ? 'Posts/ Week'
                : props.dateRangeDiff > 1
                    ? 'Posts/ Day'
                    : 'Posts/ Hour'
        }</span>
              <span class="text-[#69788E] text-sm font-bold">${
            params[0].data[3]
        }</span>
            </div>
          <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Engagement Rate</span>
              <span class="text-[#69788E] text-sm font-bold">${
            params[0].data[4]
        }%</span>
            </div>
          <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Total Posts</span>
              <span class="text-[#69788E] text-sm font-bold">${
            params[0].data[5]
        }</span>
            </div>

            <p class="text-xs text-[#979CA0] border border-t border-red-100 !border-l-0 !border-r-0 !border-b-0 mt-2 pt-2">Click to view specific posting activity.</p>
        </div>
      `
      }

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <img src="${
          params[0].data[5]
      }" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />
            <span class="text-[#000D21] text-base font-weight-500 w-[10rem] 2xl:w-[14rem] truncate">${
          params[0].data[6]
      }</span>
          </div>
            <div class="flex items-end justify-between mt-3">
              <span class="text-[979CA0] text-sm mr-1.5">Average Engagement</span>
              <span class="text-[#69788E] text-sm font-bold">${
          params[0].data[2]
      }</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">${
          props.dateRangeDiff >= 7
              ? 'Posts/ Week'
              : props.dateRangeDiff > 1
                  ? 'Posts/ Day'
                  : 'Posts/ Hour'
      }</span>
              <span class="text-[#69788E] text-sm font-bold">${
          params[0].data[3]
      }</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Engagement Rate</span>
              <span class="text-[#69788E] text-sm font-bold">${
          params[0].data[4]
      }%</span>
            </div>
        </div>
        `
    }

    /**
     * @description creates data for bar chart
     */
    const getBarChartData = () => {
      if (props.type === 'allTypesGraph') {
        return {
          id: 'y-allTypesGraph',
          data: [
            ...props.data.map((item) => [
              item.mediaType,
              item.mediaProductType,
              item.avgTotalEngagements,
              props.dateRangeDiff >= 7
                  ? item.postsPerWeek
                  : props.dateRangeDiff > 1
                      ? item.postsPerDay
                      : item.postsPerHour,
              item.avgEngagementRate,
              item.totalPosts,
            ]),
          ],
        }
      } else {
        return {
          data: [
            ...props.data.map((item) => [
              item.businessAccountId,
              item.mediaType,
              item.avgTotalEngagements,
              props.dateRangeDiff >= 7
                  ? item.avgCountByWeek
                  : props.dateRangeDiff > 1
                      ? item.avgCountByDay
                      : item.avgCountByHour,
              item.avgEngagementRate,
              item.image,
              item.name ? item.name : item.slug,
            ]),
          ],
        }
      }
    }

    /**
     * @description creates data for scatter chart
     */
    const getSecondBarData = () => {
      if (props.type === 'allTypesGraph') {
        return {
          id: 'y2-allTypesGraph',
          data: [
            ...props.data?.map((item) => [
              item.mediaType,
              item.mediaProductType,
              item.avgTotalEngagements,
              props.dateRangeDiff >= 7
                  ? item.postsPerWeek
                  : props.dateRangeDiff > 1
                      ? item.postsPerDay
                      : item.postsPerHour,
              item.avgEngagementRate,
              item.totalPosts,
            ]),
          ],
        }
      } else {
        return {
          data: [
            ...props.data?.map((item) => [
              item.businessAccountId,
              item.mediaType,
              item.avgTotalEngagements,
              props.dateRangeDiff >= 7
                  ? item.avgCountByWeek
                  : props.dateRangeDiff > 1
                      ? item.avgCountByDay
                      : item.avgCountByHour,
              item.avgEngagementRate,
              item.image,
              item.name ? item.name : item.slug,
            ]),
          ],
        }
      }
    }

    /**
     * @description sets the background styling for xAxis label
     */
    const getXAxisBackgroundStyle = () => {
      if (props.type === 'allTypesGraph') {
        return {
          fontSize: 12,
          fontWeight: 'bold',
        }
      } else {
        return {
          borderRadius: 20,
          borderColor: 'gray',
          borderWidth: 0.5,
        }
      }
    }

    /**
     * @description creates proper labels for posting types on xAxis
     * @param value
     * @returns {*|string}
     */
    const getXAxisLabelValue = (value) => {
      switch (value) {
        case 'VIDEO REEL':
          return 'Reel'
        case 'CAROUSEL ALBUM':
          return 'Carousel'
        case 'IMAGE':
          return 'Image'
        case 'VIDEO':
          return 'Video'
        default:
          return value
      }
    }

    const chartOptions = computed(() => ({
      legend: props.legend,
      color: props.colors,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            color: '#61BAE4',
            backgroundColor: '#E6F7FF',
          },
          crossStyle: {
            color: '#61BAE4',
            width: 1,
          },
        },
        formatter: tooltipFormatter,
      },
      grid: {
        left: 40,
        top: 30,
        right: 60,
        bottom: 50,
        show: true,
        width: 'auto',
        borderColor: '#E9EFF6',
        containLabel: true,
      },
      xAxis: {
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        type: 'category',
        name: props.xAxisLabel,
        nameLocation: 'center',
        nameTextStyle: {
          color: '#979CA0',
        },
        nameGap: props.type === 'postingActivityBySpecificType' ? 60 : 50,
        axisLabel: {
          color: '#979CA0',
          interval: 0,
          align: 'center',
          ...getXAxisBackgroundStyle(),
          margin: 14,
          formatter: (value) => {
            return props.xAxisLabel === 'Post Type'
                ? getXAxisLabelValue(value)
                : '{' + value + '| }'
          },
          rich: {
            ...getLabels(),
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'white',
          },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          name: `{a|} {b|${props.yAxisLabel}}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ED6AF',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'left',
          nameLocation: 'center',
          nameGap: 50,
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value?.toLocaleString('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              })
            },
          },
          axisPointer: {
            label: {
              backgroundColor: '#8ED6AF',
              color: 'white',
              formatter: (dataPoint) => {
                return dataPoint.value?.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
        },
        {
          type: 'value',
          name: `{a|}{b|${
              props.dateRangeDiff >= 7
                  ? 'Posts/ Week'
                  : props.dateRangeDiff > 1
                      ? 'Posts/ Day'
                      : 'Posts/ Hour'
          }}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ABAF3',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'right',
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
          },
          nameLocation: 'center',
          nameGap: 45,
          splitLine: {
            show: false,
          },
          axisPointer: {
            label: {
              color: 'white',
              backgroundColor: '#8ABAF3',
              precision: '2',
            },
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#333',
              type: 'dashed',
              width: 5,
            },
          },
        },
      ],
      series: [
        {
          name: props.yAxisLabel,
          type: 'bar',
          color: '#8ED6AF',
          colorBy: 'series',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          yAxisIndex: 0,
          encode: {
            x: 0,
            y: 2,
          },
          ...getBarChartData(),
        },
        {
          name: props.yAxisSecondLabel,
          type: 'bar',
          colorBy: 'series',
          color: '#8ABAF3',
          yAxisIndex: 1,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          encode: {
            x: 0,
            y: 3,
          },
          ...getSecondBarData(),
        },
      ],
    }))

    // setting up the eChart
    const {setup, chartRef} = useEcharts(
        chartOptions.value,
        props.chartTypes,
    )

    watch(
        () => chartRef.value,
        () => {
          if(!chartRef.value) return
          setup(chartOptions.value)
        }
    )

    return {
      chartRef,

      changeChartType,
      getComponentInfoTooltip,
      commonMethods,
    }
  },
})
</script>
