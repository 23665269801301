<template>
  <tbody>
    <template
      v-for="(competitor, index) in tableData"
      :key="`hashtag_data${index}`"
    >
      <tr
        class="hover:bg-gray-50 cursor-pointer"
        :class="{
          '!mt-2': index === 0,
          '!border-l-0 !border-t-0 !border-r-0 border !border-gray-200':
            index !== tableData.length - 1,
          'main-row z-10 relative rounded-md':
            state.expandedRowIndex.includes(index),
        }"
        @click="getHashtagCompetitorDetails(competitor.tag, index)"
      >
        <td
          v-for="(dataItem, key) in headers"
          :key="`hashtag_table-data_${key}`"
          class="py-2.5"
        >
          <div
            v-if="headers[key] === 'companies_using'"
            class="flex justify-start items-center select-none"
          >
            <img
              v-for="(data, imageKey) in getTableData(
                dataItem,
                competitor[headers[key]]
              )"
              :key="imageKey"
              v-tooltip="{
                content: `${data.name ? data.name : data.slug}`,
                theme: 'light',
              }"
              :src="data.image"
              alt=""
              class="
                h-6
                w-6
                m-1
                rounded-full
                overflow-hidden
                object-cover
                border
              "
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
              "
            />
          </div>
          <p
            v-else
            class="px-2 flex gap-x-3 items-center font-0-95rem select-none"
          >
            <i
              v-if="key === 0"
              v-tooltip="{
                content:
                  'Click to toggle visibility of detailed hashtag information of competitors.',
                theme: 'light',
              }"
              class="ml-2 fa text-gray-800 text-xs"
              :class="
                state.expandedRowIndex.includes(index)
                  ? 'fa-chevron-up text-cs-blue'
                  : 'fa-chevron-down'
              "
            >
            </i>
            <span
              v-tooltip="{
                content: `${competitor[headers[key]]}`,
                theme: 'light',
              }"
              :class="
                getDataClasses(
                  competitor,
                  dataItem,
                  extremeValues,
                  tableData.length
                )
              "
              >{{ getTableData(dataItem, competitor[headers[key]]) }}</span
            >
          </p>
        </td>
      </tr>
      <template
        v-if="
          state.expandedRowIndex.length &&
          state.expandedRowIndex.includes(index)
        "
      >
        <template
          v-if="state.singleHashDataArray[getHashtagIndex(competitor.tag)]"
        >
          <tr
            v-for="(
              singleCompetitor, singleCompetitorKey
            ) in getSingleHashTableData(competitor.tag)"
            :key="`nested_hashtag_row_${singleCompetitorKey}_${singleCompetitor.business_account_id}_${index}`"
            class="bg-gray-50"
          >
            <td></td>
            <template v-if="singleCompetitor">
              <td
                v-for="(
                  singleCompetitorHeader, singleCompetitorHeaderKey
                ) in singleHashtagHeader"
                :key="`${singleCompetitorHeaderKey}_${singleCompetitor.name}_nested_row`"
                class="!border-t-0 !border-r-0 border !border-gray-200 py-2.5"
              >
                <div
                  v-if="singleCompetitorHeader === 'name'"
                  class="flex justify-start items-center"
                >
                  <img
                    :src="singleCompetitor.image"
                    alt=""
                    class="w-6 h-6 m-1 rounded-full border"
                    @error="
                      $event.target.src =
                        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                    "
                  />
                  <span
                    v-tooltip="{
                      content: `${
                        singleCompetitor.name
                          ? 'Name: ' + singleCompetitor.name
                          : 'Slug: ' + singleCompetitor.slug
                      }`,
                      theme: 'light',
                    }"
                    class="truncate w-40 font-0-95rem select-none"
                    >{{
                      singleCompetitor.name
                        ? singleCompetitor.name
                        : singleCompetitor.slug
                    }}</span
                  >
                </div>
                <p
                  v-else
                  class="px-2 flex items-center font-0-95rem select-none"
                  ><span
                    v-tooltip="{
                      content: `${singleCompetitor[singleCompetitorHeader]}`,
                      theme: 'light',
                    }"
                    class="
                      flex
                      justify-center
                      px-8
                      py-0.5
                      rounded-xl
                      min-w-[6rem]
                    "
                    >{{
                      getTableData(
                        singleCompetitorHeader,
                        singleCompetitor[singleCompetitorHeader]
                      )
                    }}</span
                  ></p
                >
              </td>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr :key="`${index}_clip_loader`">
            <td colspan="100%" class="color-border cst-editor p-5 !m-2">
              <clip-loader
                class="flex justify-center"
                color="rgba(152, 158, 181, 0.8)"
                :size="'20px'"
              ></clip-loader>
            </td>
          </tr>
        </template>
      </template>
    </template>
  </tbody>
</template>

<script setup>
// libraries
import { computed, reactive, watch } from 'vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

const emit = defineEmits(['single-hashtag'])
const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  singleHashtagData: {
    type: Object,
    default: () => {},
  },
  singleHashtagHeader: {
    type: Array,
    default: () => [],
  },
})
const { getDataClasses, getMinMaxValues } = useCompetitorHelper()

const state = reactive({
  singleHashDataArray: [],
  isLoading: false,
  expandedRowIndex: [],
})

watch(
  () => props.singleHashtagData,
  (newData) => {
    state.isLoading = false
    state.singleHashDataArray.push(newData)
  }
)

watch(
  () => props.tableData,
  () => {
    state.singleHashDataArray = []
    state.expandedRowIndex = []
  }
)

/**
 * @description checks if the min and max values are passed through props, if not, calls getMinMaxValues method to get min and max values of all columns.
 * @type {ComputedRef<unknown>}
 */
const extremeValues = computed(() => {
  return getMinMaxValues(props.tableData, props.headers)
})

/**
 * @description checks key and value to return the proper values for row data
 * @param header
 * @param value
 * @returns {*|string}
 */
const getTableData = (header, value) => {
  if (value === 'N/A') return value
  switch (true) {
    case header === 'companies_using':
      return value
    case header === 'engagement_rate_by_follower':
      return `${value}%`
    default:
      return value > 1000
        ? parseInt(value).toLocaleString('en-US', {
            notation: 'compact',
            compactDisplay: 'short',
          })
        : value
  }
}

const getHashtagIndex = (hashtag) => {
  return hashtag
    ? state.singleHashDataArray?.findIndex(
        (obj) => Object.keys(obj)[0] === hashtag.substring(1)
      )
    : null
}

/**
 * @description creates the data for expanded view of hashtags with respect to competitors
 * @param hashtag
 * @param index
 */
const getHashtagCompetitorDetails = (hashtag, index) => {
  if (!state.singleHashDataArray.some((obj) => obj[hashtag.substring(1)])) {
    state.isLoading = true
    emit('single-hashtag', hashtag)
  }
  const expandedRowIndex = state.expandedRowIndex.slice()
  const indexFound = expandedRowIndex.indexOf(index)
  if (indexFound === -1) {
    expandedRowIndex.push(index)
  } else {
    expandedRowIndex.splice(indexFound, 1)
  }
  state.expandedRowIndex = expandedRowIndex
}

const getSingleHashTableData = (hashtag) => {
  return state.singleHashDataArray[getHashtagIndex(hashtag)]
    ? Object.values(state.singleHashDataArray[getHashtagIndex(hashtag)])[0]
    : []
}
</script>

<style scoped>
.main-row {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}
</style>
