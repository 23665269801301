<template>
  <div
      id="composer_widget"
      class="fixed z-[1100]"
      :style="{ left: position.x + 'px', top: position.y + 'px', userSelect: 'none' }"
  >
    <div
        class="main-widget flex items-center justify-between pl-2 pr-4 transition-all bg-[#fdf7ed] rounded-xl ease-in-out w-[22rem] h-16 shadow-lg glow-border"
    >
      <div
          ref="draggable"
          class="flex items-center cursor-move w-full"
          @mousedown="startDrag"
      >
        <img
            src="@src/assets/img/composer/draggable.svg"
            alt="drag"
            :draggable="false"
            class="w-6 h-6"
        />
        <div class="w-10 h-10 flex justify-center ml-1 items-center rounded-md bg-[#ffc555]">
          <img
              src="@src/assets/img/composer/editable.svg"
              alt="edit"
              class="w-5 h-5"
          />
        </div>
        <p class="ml-4 text-[#26303E]">Post Composer</p>
      </div>
      <div class="flex items-center">
        <img
            v-tooltip="'Maximize'"
            class="cursor-pointer w-9 h-9"
            src="@src/assets/img/composer/fullscreen.svg"
            alt="expand"
            @click="handleClick('open')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed} from 'vue'

const emit = defineEmits(['openComposer'])

// Define offsets from bottom and left edges
const BOTTOM_OFFSET = 20 // pixels from bottom
const LEFT_OFFSET = 20 // pixels from left
// Define widget size in rem
const WIDGET_WIDTH_REM = 22; // 22rem
const WIDGET_HEIGHT_REM = 4; // Assuming the height is 4rem (64px / 16px per rem)

// Function to convert rem to pixels
const remToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const windowSize = ref({width: window.innerWidth, height: window.innerHeight})
const widgetSize = computed(() => ({
  width: remToPx(WIDGET_WIDTH_REM),
  height: remToPx(WIDGET_HEIGHT_REM)
}))

// Calculate initial position based on window size
const calculateInitialPosition = () => {
  return {
    x: LEFT_OFFSET,
    y: window.innerHeight - widgetSize.value.height - BOTTOM_OFFSET
  }
}

// Update position ref to use a function for its initial value
const position = ref(calculateInitialPosition())

const draggable = ref(null)
let startX = 0
let startY = 0

const maxX = computed(() => windowSize.value.width - widgetSize.value.width)
const maxY = computed(() => windowSize.value.height - widgetSize.value.height)

const startDrag = (e) => {
  if (e.target === draggable.value || draggable.value.contains(e.target)) {
    e.preventDefault()
    startX = e.clientX - position.value.x
    startY = e.clientY - position.value.y
    document.addEventListener('mousemove', drag)
    document.addEventListener('mouseup', stopDrag)
  }
}

const drag = (e) => {
  requestAnimationFrame(() => {
    let newX = e.clientX - startX
    let newY = e.clientY - startY

    // Constrain the position within the viewport
    newX = Math.max(0, Math.min(newX, maxX.value))
    newY = Math.max(0, Math.min(newY, maxY.value))

    position.value = {x: newX, y: newY}
  })
}

const stopDrag = () => {
  document.removeEventListener('mousemove', drag)
  document.removeEventListener('mouseup', stopDrag)
  savePosition()
}

const savePosition = () => {
  localStorage.setItem('composerWidgetPosition', JSON.stringify(position.value))
}

const loadPosition = () => {
  const savedPosition = localStorage.getItem('composerWidgetPosition')
  if (savedPosition) {
    const loadedPosition = JSON.parse(savedPosition)
    // Ensure loaded position is within current viewport
    position.value = {
      x: Math.max(0, Math.min(loadedPosition.x, maxX.value)),
      y: Math.max(0, Math.min(loadedPosition.y, maxY.value))
    }
  }
}

const handleClick = (type) => {
  emit('openComposer', type)
}

const updateWindowSize = () => {
  windowSize.value = {width: window.innerWidth, height: window.innerHeight}

  // Recalculate widget size (in case font size changed)
  const newWidgetWidth = remToPx(WIDGET_WIDTH_REM)
  const newWidgetHeight = remToPx(WIDGET_HEIGHT_REM)

  // Adjust position if it's outside the new window size
  position.value = {
    x: Math.max(0, Math.min(position.value.x, windowSize.value.width - newWidgetWidth)),
    y: Math.max(0, Math.min(position.value.y, windowSize.value.height - newWidgetHeight))
  }
}

onMounted(() => {
  loadPosition()
  window.addEventListener('resize', updateWindowSize)
})

onUnmounted(() => {
  document.removeEventListener('mousemove', drag)
  document.removeEventListener('mouseup', stopDrag)
  window.removeEventListener('resize', updateWindowSize)
})
</script>

<style scoped>
.main-widget {
  transition: all 0.3s ease;
  border: 2px solid #ffc555;
  box-shadow: 0 0 10px rgba(255, 197, 85, 0.5);
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #ffc555;
  }
  50% {
    box-shadow: 0 0 10px #ffc555, 0 0 30px #ffc555;
  }
  100% {
    box-shadow: 0 0 5px #ffc555;
  }
}

.glow-border {
  animation: glowing 2s infinite;
}
</style>
