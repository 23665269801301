<script setup>
import { computed, defineEmits } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import PlatformAccountSelect from '@src/modules/analytics/views/common/PlatformAccountSelect.vue'

const emit = defineEmits(['scroll-to-top'])
const { dateRange, selectedAccount, } =
useInstagramAnalytics()

const disabled = computed(() => {
  return false
})

const handleDatechange = ({ dateRange }) => {
  setDateRange(dateRange)
}
const setDateRange = (newDate) => {
  dateRange.value = newDate.split(' - ')
}
  const onchange = (account) => {
    console.log(account)
    selectedAccount.value = account
    emit('scroll-to-top')
}
</script>

<template>
  <AnalyticsFilterBarWrapper
    type="instagram"
    :selected-accounts="selectedAccount"
    :disabled="disabled"
    @date-change="handleDatechange"
  >
    <template v-slot:left>
      <PlatformAccountSelect type="instagram" @on-account-change="onchange"/>
    </template>
  </AnalyticsFilterBarWrapper>
</template>
