<template>
  <div class="h-100 d-flex flex-column">
    <InboxConversationHead
        :active-inbox-detail="activeInboxDetail"
        :is-assigned="activeInboxDetail && activeInboxDetail.inbox_details.assigned && activeInboxDetail.inbox_details.assigned.is_assigned"
        :assigned-to-name="activeInboxDetail && activeInboxDetail.inbox_details.assigned && activeInboxDetail.inbox_details.assigned.assigned_to && activeInboxDetail.inbox_details.assigned.assigned_to.user_name"
        :reset-view="resetView"
    ></InboxConversationHead>

    <template v-if="privateReplyTo !== null">
      <ReplyPrivate
        :reply-to="privateReplyTo"
        @reply="sendPrivateMessage"
      ></ReplyPrivate>
    </template>
    <clip-loader
      v-if="postLoader"
      class="d-inline-block align-middle mt-4 mr-2 ml-1"
      color="#989eb5"
      :size="'20px'"
    />
    <div v-else class="conversation_messages">
      <div v-if="post !== null" class="message m_in _comment_post">
        <div class="profile_picture d-flex align-items-start">
          <div class="text_block w-100">
            <div class="__text">
              <!--// for post section...-->
              <div class="_comment">
                <template
                  v-if="
                    post.element_details.post_attachment !== null &&
                    post.element_details.post_attachment.length > 0 &&
                    post.element_details.post_attachment[0] !== null
                  "
                >
                  <div
                    v-if="
                      post.element_details.post_attachment[0]['is_type'] ===
                      'carousel_album'
                    "
                    class="preview_images"
                  >
                    <b-carousel
                      id="carousel-1"
                      :interval="4000"
                      controls
                      indicators
                      background="#ababab"
                      style="text-shadow: 1px 1px 2px #333"
                    >
                      <template
                        v-for="postAttachment in post.element_details
                          .post_attachment[0].post_image_album"
                        :key="postAttachment.post_image"
                      >
                        <b-carousel-slide
                          :img-src="postAttachment.post_image"
                        ></b-carousel-slide>
                      </template>
                    </b-carousel>
                  </div>
                  <div
                    v-if="
                      post.element_details.post_attachment[0]['is_type'] ===
                      'link'
                    "
                  >
                    <div class="_link d-flex align-items-start link_bg">
                      <div
                        v-if="
                          post.element_details.post_attachment[0].post_image !==
                          null
                        "
                        class="img ml-0 mr-2 align_center justify_center center"
                      >
                        <img
                          :src="
                            post.element_details.post_attachment[0].post_image
                          "
                          :alt="post.element_details.post_attachment[0].link"
                        />
                      </div>
                      <div class="content"
                        ><a
                          :href="post.element_details.post_attachment[0].link"
                          >{{
                            post.element_details.post_attachment[0].title
                          }}</a
                        >
                        <p>{{
                          post.element_details.post_attachment[0].description
                        }}</p></div
                      >
                    </div>
                  </div>
                  <div
                    v-if="
                      post.element_details.post_attachment[0]['is_type'] ===
                        'video' &&
                      checkForValidVideo(
                        post.element_details.post_attachment[0].post_video,
                      )
                    "
                    class="_video"
                  >
                    <video controls>
                      <source
                        :src="
                          post.element_details.post_attachment[0].post_video
                        "
                        type="video/mp4"
                      />
                      <source
                        :src="
                          post.element_details.post_attachment[0].post_video
                        "
                        type="video/ogg"
                      />
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div
                    v-if="
                      post.element_details.post_attachment[0]['is_type'] ===
                        'photo' ||
                      post.element_details.post_attachment[0]['is_type'] ===
                        'image_album'
                    "
                    class="_single_image"
                  >
                    <div
                      v-if="
                        post.element_details.post_attachment[0]['is_type'] ===
                        'photo'
                      "
                    >
                      <img
                        v-if="
                          post.element_details.post_attachment[0].post_image
                        "
                        :src="
                          post.element_details.post_attachment[0].post_image
                        "
                        alt=""
                      />
                    </div>

                    <div
                      v-if="
                        post.element_details.post_attachment[0].post_image_album
                      "
                      class="flex flex-wrap relative overflow-hidden"
                    >
                      <template
                        v-for="(image, key) in post.element_details
                          .post_attachment[0].post_image_album"
                        :key="key"
                      >
                        <div
                          v-if="key <= 3"
                          class="flex-img bg-cover bg-no-repeat bg-center max-h-96 border-2"
                          :class="{
                            'img-three':
                              post.element_details.post_attachment[0]
                                .post_image_album.length === 3,
                            'background-image': 'url(' + image + ')',
                          }"
                        >
                          <span
                            v-if="
                              post.element_details.post_attachment[0]
                                .post_image_album.length > 4 && key === 3
                            "
                            class="block w-100 h-100"
                            style="background-color: rgba(0, 0, 0, 0.4)"
                          ></span>
                          <img :src="image" alt="" />
                        </div>
                        <div
                          v-else
                          class="absolute top-3/4 left-3/4 p-2 text-2xl text-bold text-white"
                          style="transform: translate(-50%, -50%)"
                          >+{{
                            post.element_details.post_attachment[0]
                              .post_image_album.length - 4
                          }}
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <div class="desc">
                  <h3
                    v-if="post.element_details.post_message !== null"
                    v-html="stringToHtml(post.element_details.post_message)"
                  ></h3>
                </div>
                <div class="option_block d-flex align-items-center">
                  <div class="option_left">
                    <span>
                      <i class="fal fa-comment"></i>
                      {{ total !== null ? total : 0 }}
                      <i
                        class="fas fa-circle align-middle"
                        style="font-size: 4px"
                      ></i>
                      <span>Comments</span>
                    </span>
                    <span class="mt-2 sub_text">{{
                      commonMethods.timesAgo(post.element_details.updated_time)
                    }}</span>
                  </div>
                  <div class="option_right ml-auto">
                    <clip-loader
                      v-if="fetchCommentLoader"
                      class="d-inline-block align-middle mr-2"
                      color="#989eb5"
                      :size="'20px'"
                    />
                  </div>
                  <!--//drop down filter-->
                  <div
                    v-if="postComments.length > 1"
                    class="prevent_close_dropdown dropdown default_style_dropdown checkbox_dropdown option_dropdown"
                    style="width: 150px"
                  >
                    <div
                      class="dropdown_header d-flex align-items-center"
                      data-toggle="dropdown"
                    >
                      <span class="text"
                        ><b>Sort by:</b> {{ selectedFilter.value }}</span
                      >
                      <span class="ml-auto">
                        <i class="dropdown_arrow icon_last cs-angle-down"></i>
                      </span>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="inner">
                        <template v-for="type in filterTypes" :key="type.key">
                          <li
                            class="block text-blue-900 text-sm cursor-pointer pr-3 w-full hover:bg-gray-200"
                            @click="
                              () => {
                                page = 1
                                postComments = []
                                selectedFilter = type
                                fetchPostAndComments()
                              }
                            "
                          >
                            <div class="checkbox_input_image flex px-3 py-2 justify-between">
                              <input
                                :id="type.key"
                                :checked="type.key === selectedFilter.key"
                                type="checkbox"
                              />
                              <label
                                :for="type.key"
                                class="checkbox_right square_check_icon flex justify-between w-full"
                              >
                                {{ type.value }}
                              </label>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="comment_reply_section">
                  <!--//loop here.......-->

                  <div
                    v-for="(comment, index) in postComments"
                    :key="comment.comment_id"
                  >
                    <FacebookCommentSending
                      v-if="comment.sending"
                      :temp="temp"
                      :is-root="true"
                      :root-comment-id="comment.comment_id"
                      :post="post"
                      :comment="comment"
                      :remove-comment="removeComment"
                      :append-comment="appendComment"
                    >
                    </FacebookCommentSending>

                    <FacebookCommentBox
                      v-else
                      :root-index="index"
                      :sub-index="null"
                      :root-comment="comment"
                      :is-root="true"
                      :post="post"
                      :comment="comment"
                      :replies-count="
                        typeof comment.comment_replies !== 'undefined'
                          ? comment.comment_replies.length
                          : 0
                      "
                      :show-replies="comment.show_replies"
                      :set-private-reply-to="setPrivateReplyTo"
                      :change-status="commentStatusChanged"
                      :set-can-write="setCanWrite"
                      :set-can-show-replies="setCanShowReplies"
                    >
                    </FacebookCommentBox>

                    <transition-group name="slidedown">
                      <template
                        v-if="
                          typeof comment.comment_replies !== 'undefined' &&
                          comment.comment_replies.length &&
                          comment.show_replies
                        "
                      >
                        <div
                          v-for="(
                            comment_reply, reply_index
                          ) in comment.comment_replies"
                          :key="comment_reply.comment_id"
                        >
                          <FacebookCommentSending
                            v-if="comment_reply.sending"
                            :temp="temp"
                            :is-root="false"
                            :root-comment-id="comment.comment_id"
                            :post="post"
                            :comment="comment_reply"
                            :remove-comment="removeComment"
                            :append-comment="appendComment"
                          >
                          </FacebookCommentSending>
                          <FacebookCommentBox
                            v-else
                            :root-index="index"
                            :sub-index="reply_index"
                            :root-comment="comment"
                            :is-root="false"
                            :post="post"
                            :comment="comment_reply"
                            :replies_count="0"
                            :show_replies="false"
                            :set-private-reply-to="setPrivateReplyTo"
                            :change-status="commentStatusChanged"
                            :set-can-write="setCanWrite"
                            :set-can-show-replies="setCanShowReplies"
                          >
                          </FacebookCommentBox>
                        </div>
                      </template>
                    </transition-group>
                    <template v-if="comment.can_write">
                      <FacebookReplyBox
                        :active-inbox-detail="activeInboxDetail"
                        :is-root="false"
                        :root-comment-index="index"
                        :root-comment-id="comment.comment_id"
                        :temp="temp"
                        :post="post"
                        :append-comment="appendComment"
                        :remove-comment="removeComment"
                      >
                      </FacebookReplyBox>
                    </template>
                  </div>
                  <p
                    v-if="canLoadMore && !fetchCommentLoader"
                    class="text-center previouse_conversation pb-2"
                  >
                    <span
                      class="link pt-2 load-more-comments"
                      @click="paginateResults"
                    >
                      Load more comments
                    </span>
                  </p>
                  <FacebookReplyBox
                    :active-inbox-detail="activeInboxDetail"
                    :is-root="true"
                    :root-comment-index="null"
                    :root-comment-id="null"
                    :temp="temp"
                    :post="post"
                    :append-comment="appendComment"
                    :remove-comment="removeComment"
                  >
                  </FacebookReplyBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import InboxConversationHead from '@src/modules/inbox/components/InboxConversationHead'
import ReplyPrivate from '@src/modules/inbox/components/ReplyPrivate'
import FacebookCommentBox from '@src/modules/inbox/components/reusable/facebook-post/FacebookCommentBox'
import FacebookReplyBox from '@src/modules/inbox/components/reusable/facebook-post/FacebookReplyBox'
import FacebookCommentSending from '@src/modules/inbox/components/reusable/facebook-post/FacebookCommentSending'
import { pusherAuthSocketInbox } from '@common/lib/pusher'

export default {
  components: {
    ReplyPrivate,
    InboxConversationHead,
    FacebookCommentBox,
    FacebookReplyBox,
    FacebookCommentSending,
  },
  props: {
    activeInboxDetail: {
      type: Object,
      default: () => {},
    },
    resetView: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      temp: this,
      postComments: [],
      post: null,
      fetchCommentLoader: false,
      postLoader: true,
      commonMethods,
      activeUser: [],
      attachment: null,
      replyAttachment: [],
      privateReplyTo: null,
      indices: { parent: null, child: null },
      post_channel: '',
      selectedFilter: {
        key: 'newest',
        value: 'Newest',
      },
      filterTypes: [
        {
          key: 'all',
          value: 'Oldest',
        },
        {
          key: 'newest',
          value: 'Newest',
        },
      ],
      page: 1,
      limit: 7,
      canLoadMore: true,
      total: 0,
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  watch: {
    activeInboxDetail: async function (newVal, oldVal) {
      // watch it
      // the function is executed when the selected conversation is changed. the change could be due
      // to applied filters or user's selection'
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (
        oldVal.element_details.element_id !== newVal.element_details.element_id
      ) {
        this.canLoadMore = true
        this.page = 1
        this.post = null
        this.postComments = []
        this.selectedFilter = {
          key: 'newest',
          value: 'Newest',
        }
        this.total = 0
        if (typeof oldVal !== 'undefined') {
          // incase of invalid object remove binded sockets
          this.removeSocketListners(oldVal)
        }
        // re-bind pusher sockets
        this.bindPusherConversations()
        // reset post value
        this.post = newVal
        this.attachment = null
        if (typeof this.$refs.inputFile !== 'undefined') {
          this.$refs.inputFile.value = null
        }
        this.replyAttachment = []
        this.postLoader = false
        // fetch comments for the new post id
        await this.fetchPostAndComments()
      }
    },
  },
  created() {},
  mounted() {
    this.postLoader = true
    this.post = this.activeInboxDetail
    this.fetchPostAndComments()
    commonMethods.setActiveUser(this)
    const pusher = pusherAuthSocketInbox(
      this.getProfile._id,
      this.getProfile.full_name,
      this.getProfile.email
    )
    this.post_channel = pusher.subscribe(
      'presence-facebook-post-' + this.activeInboxDetail.workspace_id
    )
    this.bindPusherConversations()
    this.postLoader = false
  },
  beforeUnmount() {
    this.removeSocketListners(this.activeInboxDetail)
    if (this.post_channel) {
      const tail =
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForCommentUpdate = 'channel-facebook-comment-' + tail
      this.post_channel.unbind(channelForCommentUpdate)
      this.post_channel.unsubscribe(
        'presence-facebook-post-' + this.activeInboxDetail.workspace_id
      )
    }
  },
  methods: {
    ...mapActions(['fetchPostComments', 'reply']),
    bindPusherConversations() {
      // binding with pusher channel to add/append new object recieved from webhooks jobs
      console.log('binding for post ... ')
      const tail =
        this.activeInboxDetail.element_details.element_id +
        '-' +
        this.activeInboxDetail.workspace_id
      const channelForCommentUpdate = 'channel-facebook-comment-' + tail
      const temp = this
      this.post_channel.bind(channelForCommentUpdate, (data) => {
        console.log('receiving on --> ', channelForCommentUpdate)
        console.log(data.comment)
        const comment = data.comment
        if (
          comment.post_id ===
            temp.activeInboxDetail.element_details.element_id &&
          comment.workspace_id === temp.activeInboxDetail.workspace_id
        ) {
          if (comment.is_reply) {
            const index = this.findCommentIndex(comment.reply_of)
            this.appendComment('reply', comment, index)
          } else {
            this.appendComment('new', comment, -1)
          }
        }
      })
    },
    commentStatusChanged(action, rootId, subId, response) {
      // the function is called when the comment is deleted to remove itself and sub comments
      const index = this.findCommentIndex(rootId)
      console.log('commentStatusChanged .. ', {
        action,
        rootId,
        subId,
        index,
        response,
      })
      switch (action) {
        case 'delete':
          if (subId != null) {
            this.$set(this.postComments, index, response.response)
          } else {
            this.postComments.splice(index,1)
          }
          break
        default:
          this.$set(this.postComments, index, response.response)
          break
      }
    },
    removeComment(rootId, subId) {
      // function is responsible to remove comment/reply from comments list
      const rootIndex = this.findCommentIndex(rootId)
      if (subId != null) {
        const index = this.findSubCommentIndex(rootIndex, subId)
        this.postComments[rootIndex].comment_replies.splice(index,1)
      } else {
        this.postComments.splice(rootIndex,1)
      }
    },
    textAreaAutoHeight(e) {
      console.debug(
        e.target.style.height,
        ' e.target.style.height',
        e.target.scrollHeight
      )
      e.target.style.height = 'auto'
      e.target.style.height = e.target.scrollHeight + 'px'
    },
    appendComment(type, comment, index) {
      // The function is reponsible to add new comment object in the existing comment list or as a child to some
      // existing comment
      if (type === 'new') {
        const commentIndex = this.postComments.findIndex(
          (x) => x.comment_id === comment.comment_id
        )
        if (commentIndex < 0) {
          this.$set(comment, 'show_replies', false)
          this.postComments.push(comment)
        }
      } else {
        if (
          typeof this.postComments[index] === 'undefined' ||
          this.postComments[index] === null
        ) {
          this.$set(comment, 'show_replies', false)
          this.postComments.push(comment)
        } else {
          if (
            this.postComments[index].comment_replies === null ||
            !this.postComments[index].comment_replies
          ) {
            this.$set(this.postComments[index], 'comment_replies', [])
          }
          const commentIndex = this.postComments[
            index
          ].comment_replies.findIndex(
            (x) => x.comment_id === comment.comment_id
          )
          if (commentIndex < 0) {
            this.postComments[index].comment_replies.push(comment)
          }
        }
      }
    },
    checkForValidVideo(url) {
      // function checks if the video is of valid .mp4 format
      return url != null && url.includes('.mp4')
    },
    createPayloadForPM(commentId, message) {
      // function is responsible to create payload to post comment for api
      if (message === '' || message === null) {
        this.alertMessage(
          'Message and attachment both cannot be empty.',
          'error'
        )
        return null
      }
      const payload = new FormData()
      payload.append('platform', this.post.platform)
      payload.append('platform_id', this.post.platform_id)
      payload.append('comment_id', commentId)
      payload.append('workspace_id', this.post.workspace_id)
      payload.append('reply_for', 'post')
      payload.append('element_id', this.post.element_details.element_id)
      payload.append('message', message)
      payload.append('attachment_file', null)
      payload.append('attachment_type', null)
      payload.append('replied_by', this.activeUser.user_id)
      payload.append('is_private_reply', true)
      payload.append('is_for', 'reply_comment')
      return payload
    },
    setCanShowReplies(comment) {
      // function basically set the flag of the comment object that decides weather to display reply comments or not
      this.$set(comment, 'show_replies', !comment.show_replies)
    },
    setCanWrite(comment) {
      // the function is responsible to set can_write property of the comment object
      this.$set(comment, 'can_write', true)
    },
    paginateResults() {
      this.page += 1
      this.fetchPostAndComments()
    },
    async fetchPostAndComments() {
      // the function is responsible to fetch all the comments for the selected post(by post_id)
      // it fetches data via api
      if (this.fetchCommentLoader === false) {
        this.fetchCommentLoader = true
        const resp = await this.fetchPostComments({
          post_id: this.post.element_details.element_id,
          workspace_id: this.post.workspace_id,
          platform: this.post.platform,
          sort_order: this.selectedFilter.key.toLowerCase(),
          page: this.page,
          limit: this.limit,
        })
        if (resp.isValid) {
          if (resp.comments.length > 0) {
            this.canLoadMore = resp.comments.length >= this.limit
            for (const comment of resp.comments) {
              this.$set(comment, 'show_replies', false)
              this.postComments.push(comment)
            }
          }
          this.total = resp.total
          this.fetchCommentLoader = false
        } else {
          this.canLoadMore = false
          this.fetchCommentLoader = false
        }
      }
    },
    getMessageType(attachment) {
      // function returns the media type of attached file
      if (attachment) {
        const mediaTypes = ['gif', 'image', 'video']
        for (const type of mediaTypes) {
          if (attachment.type.indexOf(type) >= 0) {
            return type
          }
        }
      }
      return ''
    },
    setPrivateReplyTo(comment, index, replyIndex) {
      if (comment.can_reply_privately) {
        this.privateReplyTo = comment
        console.log('setPrivateReplyTo', {
          comment,
          index,
          replyIndex,
        })
        this.indices.parent = index
        this.indices.child = replyIndex
        this.$bvModal.show('sendDirectMessageModal')
      } else {
        if (comment.private_reply_conversation != null) {
          this.$emit('go-to', {
            element_id: comment.private_reply_conversation.id,
          })
        }
      }
    },
    async sendPrivateMessage(payload) {
      this.privateReplyTo = null
      console.log('sendPrivateMessage', payload)
      const requestPayload = this.createPayloadForPM(
        payload.comment_id,
        payload.message
      )
      const response = await this.reply(requestPayload)
      if (response.isValid) {
        console.log(response)
        console.log(this.indices)
        if (response.response.is_reply) {
          this.postComments[this.indices.parent].comment_replies[
            this.indices.child
          ].private_reply_conversation =
            response.response.private_reply_conversation
          this.postComments[this.indices.parent].comment_replies[
            this.indices.child
          ].can_reply_privately = response.response.can_reply_privately
        } else {
          this.postComments[this.indices.parent].private_reply_conversation =
            response.response.private_reply_conversation
          this.postComments[this.indices.parent].can_reply_privately =
            response.response.can_reply_privately
        }
      }
    },
    findSubCommentIndex(rootIndex, commentId) {
      // functions returns the index of the given reply(children) of a comment
      return this.postComments[rootIndex].comment_replies.findIndex(
        (x) => x.comment_id === commentId
      )
    },
    findCommentIndex(commentId) {
      // the cuntion return the index of given comment_id
      return this.postComments.findIndex((x) => x.comment_id === commentId)
    },
    removeSocketListners(value) {
      // the function removes the pusher sockets for binded channels once the seleted conversation is
      // changed within the selected filters
      const tail = value.element_details.element_id + '-' + value.workspace_id
      const channelForCommentUpdate = 'channel-facebook-comment-' + tail
      this.post_channel.unbind(channelForCommentUpdate)
    },
  },
}
</script>

<style lang="less" scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}

.img-three {
  flex: 1 1 calc(29.33% + 10px) !important;
}

.link_bg {
  background: #f7f8f9;
  border-radius: 5px;
}

.load-more-comments {
  text-decoration: underline;
  color: #385898;
  font-size: 13px;

  &:hover {
    cursor: pointer;
  }
}
</style>
