<template>
  <div class="comment_box">
    <div class="d-flex align-items-center clear">
      <h2 class="title">Comments ({{ getComments.length }})</h2>
    </div>
    <template v-if="getCommentsLoader">
      <clip-loader
        v-if="getCommentsLoader"
        color="#4165ed"
        :size="'16px'"
      ></clip-loader>
    </template>
    <div v-else class="f_account_list">
      <!-- add new comment -->
      <div v-if="!isContentEditable" class="edit_input">
        <div
          class="input_field mt-0 atwho_dropdown"
          :class="{ loader_overlay_with_loader: file_uploading }"
        >
          <template v-if="file_uploading">
            <beat-loader :size="'10px'" :color="'#4165ed'"></beat-loader>
          </template>
          <AtTa
            v-model="getNewComment.comment"
            :members="getActiveWorkspaceMembersName"
            @hoverSelect="hoverSelect = true"
          >
            <textarea
              id="comment-box-top"
              ref="ed"
              v-model="getNewComment.comment"
              placeholder="Write a comment..."
              @keyup.ctrl.enter="checkPostExistComment"
            ></textarea>
            <div
              class="attachment-icon d-flex justify-content-center align-items-center gap-1.5"
            >
              <Emoji
                dropdown-position="bottom_side"
                type="comment-top"
              />
              <label for="comment-file-input"
                ><i class="far fa-paperclip"></i
              ></label>
              <input
                id="comment-file-input"
                accept="image/*"
                type="file"
                class="d-none"
                @change.prevent="uploadCommentImage"
              />
              <i class="far fa-at" @click="triggerAt"></i>
            </div>
          </AtTa>
        </div>
        <div class="btn_block">
          <button
            :disabled="
              !getNewComment.comment || file_uploading || getCreateCommentLoader
            "
            class="btn royal_blue loader_right btn_loader add_comment_text"
            @click.prevent="checkPostExistComment"
          >
            <span v-if="getCreateCommentLoader" class="text"
              >Saving...
              <clip-loader :size="'12px'" :color="'#436aff'"></clip-loader>
            </span>
            <span v-else class="text">Add comment</span>
          </button>
          <!--TODO::ARIF-->
          <div class="attachment-files">
            <div
              v-for="(media, index) in getNewComment.media"
              :key="index"
              v-tooltip="media.name"
              class="img"
              :style="{ background: 'url(' + media.link + ')' }"
            >
              <i
                class="cs-cross cross_icon"
                @click="getNewComment.media.splice(index, 1)"
              ></i>
              <div class="image_tool">
                <i
                  class="cs-eye image_tool_item"
                  @click="previewCommentImage(media.link)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="getComments.length > 0" id="comment_list" class="comment_list">
        <div
          v-for="(comment, index) in getComments"
          :id="comment._id"
          :key="index"
          class="comment_item"
          :class="{
            editing:
              isContentEditable &&
              comment._id &&
              comment._id === getNewComment._id,
            highlight_comment: comment._id && comment._id === commentId,
          }"
        >
          <!-- comment listing -->

          <div
            class="comment_item_inner"
            :class="{ is_note_comment: comment.is_note }"
          >
            <!-- remove tooltip section-->
            <div v-if="!isContentEditable" class="top_tool">
              <i
                v-tooltip.top="'Edit'"
                class="edit_icon icon-edit-cs"
                @click.prevent="
                  UPDATE_NEW_COMMENT(comment),
                  hideDiv(true)
                "
              ></i>

              <div
                class="dropdown default_style_dropdown taskDropdown d-inline-block align-middle"
              >
                <i
                  v-tooltip.top="'Remove'"
                  class="icon-delete-cs dropdown_header remove_icon"
                  data-toggle="dropdown"
                ></i>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner">
                    <div class="head_sec">
                      <p>Confirmation</p>
                    </div>
                    <div class="body_text">
                      <p
                        >Are you sure?
                        <a
                          class="red"
                          @click.prevent="
                            callRemoveComment({
                              comment: comment,
                              index: index,
                            })
                          "
                          >Yes</a
                        >
                        -<a>No</a></p
                      >
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <!-- comment section -->

            <div class="profile_picture d-flex align-items-start">
              <div class="picture_block mt-1 picture_block_background">
                <div
                  v-if="comment.user && comment.user.image"
                  class="img"
                  :style="{ background: 'url(' + comment.user.image + ')' }"
                ></div>
                <div
                  v-else
                  class="img"
                  style="
                    background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                  "
                ></div>
              </div>
              <div class="text_block w-100">
                <p class="text"
                  >{{ comment.user.firstname }} {{ comment.user.lastname
                  }}<span class="time">{{
                    $filters.relative(comment.created_at)
                  }}</span>
                </p>

                <!-- if the div status is not editable then show the comment -->
                <template v-if="!isContentEditable">
                  <p
                    class="sub_text"
                    v-html="getMentionedUsersNames(comment.comment)"
                  ></p>
                  <!--TODO::ARIF-->
                  <div class="attachment-files">
                    <div
                      v-for="(media, index) in comment.media"
                      :key="index"
                      v-tooltip="media.name"
                      class="img"
                      :style="{ background: 'url(' + media.link + ')' }"
                    >
                      <div class="image_tool">
                        <i
                          class="cs-eye image_tool_item"
                          @click="previewCommentImage(media.link)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>

                <!-- show the edit text area-->
                <!-- if the comment id is matching then show the edit input on that region-->
                <div
                  v-if="
                    isContentEditable &&
                    comment._id &&
                    comment._id === getNewComment._id
                  "
                  class="edit_input"
                >
                  <div
                    class="input_field atwho_dropdown"
                    :class="{ loader_overlay_with_loader: file_uploading }"
                  >
                    <template v-if="file_uploading">
                      <beat-loader
                        :size="'10px'"
                        :color="'#4165ed'"
                      ></beat-loader>
                    </template>
                    <AtTa
                      v-model="getNewComment.comment"
                      :members="getActiveWorkspaceMembersName"
                    >
                      <div class="pb-6 border rounded-md">
                      <textarea
                        id="comment-box-top"
                        ref="ed"
                        v-model="getNewComment.comment"
                        class="!border-0"
                        placeholder="Write a comment..."
                        @keyup.ctrl.enter="
                          updateComment(comment._id),
                          hideDiv(false)
                        "
                      ></textarea>
                      </div>
                      <div
                        class="attachment-icon d-flex justify-content-center align-items-center gap-1.5"
                      >
                        <Emoji
                          dropdown-position="bottom_side"
                          type="comment-top"
                        />
                        <label :for="'comment-edit-file-input' + comment._id"
                          ><i class="far fa-paperclip"></i
                        ></label>
                        <input
                          :id="'comment-edit-file-input' + comment._id"
                          accept="image/*"
                          type="file"
                          class="d-none"
                          @change.prevent="uploadCommentImage"
                        />

                        <i class="far fa-at" @click="triggerAt"></i>
                      </div>
                    </AtTa>
                  </div>
                  <div class="btn_block">
                    <button
                      id="edit_cancel"
                      class="btn red border_btn"
                      @click.prevent="
                        hideDiv(false),
                        RESET_NEW_COMMENT()
                      "
                    >
                      <span>Cancel</span>
                    </button>
                    <button
                      :disabled="!getNewComment.comment || file_uploading"
                      class="btn gradient_btn"
                      @click.prevent="
                        updateComment(comment._id),
                        hideDiv(false)
                      "
                    >
                      <span style="color: #fff" class="text">Save comment</span>
                    </button>
                    <div class="attachment-files">
                      <div
                        v-for="(media, index) in getNewComment.media"
                        :key="index"
                        v-tooltip="media.name"
                        class="img"
                        :style="{ background: 'url(' + media.link + ')' }"
                      >
                        <i
                          class="cs-cross cross_icon"
                          @click="getNewComment.media.splice(index, 1)"
                        ></i>
                        <div class="image_tool">
                          <i
                            class="cs-eye image_tool_item"
                            @click="previewCommentImage(media.link)"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-3 no_blog_content">
          <div class="content-image-state">
            <img
              :src="
                require('../../../../../assets/img/states/no-comments-icon.svg')
              "
              alt=""
            />
          </div>
          <p class="bold">No comments yet</p>
          <p class="light"
            >Collaborate with your team by adding <br />comments here.</p
          >
        </div>
      </div>
    </div>

    <!-- new code ended -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import { pusherSocketPublish } from '@common/lib/pusher'
import Emoji from '../social/emoji/Emoji'
export default {
  components: {
    AtTa,
    Emoji
  },
  props: {
    commentId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isContentEditable: false,
      members: [],
      file_uploading: false,
      channel: '',
    }
  },
  computed: {
    ...mapGetters([
      'getComments',
      'getNewComment',
      'getActiveWorkspaceMembersName',
      'getCommentsLoader',
      'getPublishSelection',
      'getCreateCommentLoader',
      'getActiveWorkspace'
    ])
  },
  created () {
    const self = this
    console.debug('commentId', this.commentId)
    setTimeout(function () {
      if (self.commentId) {
        if (
          self.getComments.find((comment) => comment._id === self.commentId)
        ) {
          const elem = document.getElementById(self.commentId)
          self.scrollToDiv(
            document.getElementById('comment_list'),
            elem.offsetTop - 180,
            600
          )
        }
      }
    }, 2000)
  },
  mounted () {
    this.channel = pusherSocketPublish.subscribe(
      `comments_${this.getActiveWorkspace._id}`
    )
    this.bindPusherPostComments()
  },
  beforeUnmount () {
    if (this.channel) this.channel.unsubscribe()
    this.channel.unbind('store_comment')
    this.channel.unbind('delete_comment')
  },
  methods: {
    ...mapMutations(['UPDATE_NEW_COMMENT', 'RESET_NEW_COMMENT']),
    ...mapActions(['saveComment', 'removeComment']),
    triggerAt () {
      const { ed } = this.$refs
      ed[0].focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },
    bindPusherPostComments () {
      this.channel.bind('store_comment', (data) => {
        console.log('composer post store comment payload => ', data)
        if (
          this.$route.params.id === data.plan_id ||
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          const comment = this.getComments.find((item) => item._id === data._id)
          if (!comment) {
            this.getComments.unshift(data)
          } else {
            const commentIndex = this.getComments.findIndex(
              (item) => item._id === data._id
            )
            if (commentIndex >= 0) {
              this.getComments.splice(commentIndex, 1, data)
            }
          }
        }
      })
      this.channel.bind('delete_comment', (data) => {
        console.log('composer post delete comment payload => ', data)

        if (
          this.$route.params.id === data.plan_id ||
          this.getPublishSelection.plan_id === data.plan_id
        ) {
          const commentIndex = this.getComments.findIndex(
            (item) => item._id === data._id
          )
          if (commentIndex >= 0) {
            this.getComments.splice(commentIndex, 1)
          }
        }
      })
    },
    hideDiv (status) {
      this.isContentEditable = status
    },
    async uploadCommentImage (event) {
      this.file_uploading = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.file_uploading = false
      if (res) {
        if (res.data.status) {
          if (this.getNewComment.media) {
            this.getNewComment.media.push(res.data.media)
          } else {
            this.$set(this.getNewComment, 'media', [res.data.media])
          }
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.content-image-state {
  img {
    max-width: 140px;
  }
}
</style>
