<template>
  <div>
    <div
      v-if="isEmpty"
      class="
        w-full
        flex
        items-center
        justify-center
        border
        bg-white
        p-3
        rounded-md
        select-none
        cursor-pointer
        h-[8.5rem]
        2xl:h-[7.5rem]
        !border-2 !border-dashed
        group
        hover:!border-gray-700
      "
      @click="$emit('click')"
    >
      <i
        class="fa fa-plus text-2xl text-gray-700 group-hover:text-gray-900"
        aria-hidden="true"
      ></i>
    </div>
    <div
      v-else
      v-tooltip="{
        content:
          state === 'Added'
            ? `Data for ${slug ? slug : name} is being fetched.`
            : '',

        theme: 'light',
      }"
      class="
        bg-white
        p-3
        rounded-md
        select-none
        h-[8.5rem]
        2xl:h-[7.5rem]
        color-border
        cst-editor
        relative
        flex flex-col
        justify-between
      "
    >
      <template v-if="state === 'Added'">
        <div
          class="
            w-full
            h-full
            flex
            justify-center
            items-center
            absolute
            top-0
            bottom-0
            left-0
            right-0
            !opacity-100
          "
        >
          <clip-loader
            class="append mr-2"
            :color="'#5773fa'"
            :size="'22px'"
          ></clip-loader>
        </div>
      </template>
      <div
        class="flex items-center"
        :class="{ 'opacity-30': state === 'Added' }"
      >
        <img
          :src="image"
          class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"
          alt=""
          @error="
            $event.target.src =
              'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
          "
        />
        <span
          v-tooltip="{
            content: `Name: ${name}`,

            theme: 'light',
          }"
          class="
            text-[#000D21] text-base
            font-weight-500
            max-w-[4rem]
            md:max-w-[5.75rem]
            xl:max-w-[8rem]
            2xl:max-w-[12rem]
            truncate
          "
        >
          {{ name }}
        </span>
      </div>

      <div
        class="
          flex flex-col
          items-start
          2xl:flex-row
          2xl:justify-between
          2xl:items-center
          mb-1
        "
        :class="{ 'opacity-30': state === 'Added' }"
      >
        <div
          v-tooltip="{
            content: `Followers: ${followers}`,

            theme: 'light',
          }"
          class="flex items-baseline mb-2 2xl:mb-0"
        >
          <span class="text-[979CA0] text-sm 2xl:text-[0.9rem] mr-2"
            >Followers
          </span>
          <span
            class="font-0-87rem font-medium px-2.5 py-0.5 rounded-full"
            :class="[
              getFollowersColor().bgClass,
              getFollowersColor().textClass,
            ]"
          >
            {{
              followers.toLocaleString('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              })
            }}
          </span>
        </div>

        <div
          v-tooltip="{
            content: `Engagement rate by followers: ${engagementRate}%`,

            theme: 'light',
          }"
          class="flex justify-center items-center"
        >
          <span class="mr-2 text-sm 2xl:text-[0.9rem]">ER</span>
          <span
            class="font-0-87rem font-medium px-2.5 py-0.5 rounded-full"
            :class="[getErColor().bgClass, getErColor().textClass]"
            >{{ engagementRate }} %</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
  followers: {
    type: [String, Number],
    default: 0,
    required: true,
  },
  graph: {
    type: String,
    required: false,
    default: 'default',
    validator: (value) => {
      return ['up', 'down', 'default'].indexOf(value) !== -1
    },
  },
  change: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  engagementRate: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  isEmpty: {
    type: Boolean,
    default: false,
  },
  slug: {
    type: String,
    default: '',
  },
  state: {
    type: String,
    default: '',
  },
  minEr: {
    type: Number,
    default: 0,
  },
  maxEr: {
    type: Number,
    default: 0,
  },
  minFollowers: {
    type: Number,
    default: 0,
  },
  maxFollowers: {
    type: Number,
    default: 0,
  },
})

defineEmits(['click'])

/**
 * @description returns classes based on ER value
 * @returns {{textClass: string, bgClass: string}}
 */
const getErColor = () => {
  if (props.engagementRate === props.minEr)
    return { bgClass: 'bg-[#FFECF0]', textClass: 'text-[#FF4068]' }
  if (props.engagementRate === props.maxEr)
    return { bgClass: 'bg-[#EEF9F3]', textClass: 'text-[#56C288]' }
  return { bgClass: 'bg-[#fafafa]', textClass: 'text-[#69788E]' }
}

/**
 * @description returns classes based on ER value
 * @returns {{textClass: string, bgClass: string}}
 */
const getFollowersColor = () => {
  if (props.followers === props.minFollowers)
    return { bgClass: 'bg-[#FFECF0]', textClass: 'text-[#FF4068]' }
  if (props.followers === props.maxFollowers)
    return { bgClass: 'bg-[#EEF9F3]', textClass: 'text-[#56C288]' }
  return { bgClass: 'bg-[#fafafa]', textClass: 'text-[#69788E]' }
}
</script>
