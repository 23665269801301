<script>
export default {
  props: {
    workspace_id: {
      default: null
    },
    count_loader: {
      default: false
    },
    posting_count: {
      default: 0
    },
    id: {
      default: 'resumePosting'
    }
  },
  data () {
    return {
      processing_loader: false,
      stage: '',
      time_type: 'resume',
      recurring: {
        type: 'hour',
        value: 1
      }
    }
  },
  computed: {
    isProcessingLoader () {
      return this.processing_loader
    },
    isNormalStage () {
      return this.stage === ''
    },
    isSuccessStage () {
      return this.stage === 'success'
    },
    isFailStage () {
      return this.stage === 'fail'
    }
  },
  methods: {
    hideModal () {
      this.$bvModal.hide(this.id)
    },
    recurringTypeChange (value) {
      console.debug('Method:recurringTypeChange')
      this.recurring.value = 1
    },
    async resumePosting () {
      console.debug('Method::resumePosting', this.type, this.platform)
      const payload = { workspace_id: this.workspace_id }
      if (this.posting_count) {
        payload.time_type = this.time_type
        payload.recurring = this.recurring
      }
      this.processing_loader = true
      const response = await this.$store.dispatch('resumePosting', payload)
      if (!response) this.stage = 'fail'
      if (response) {
        this.stage = 'success'
        const items = this.$store.getters.getWorkspaces.items
        const index = items.findIndex(
          (item) => item.workspace_id === this.workspace_id
        )
        if (items[index] && items[index].workspace) { items[index].workspace.on_hold = false }
      }
      this.processing_loader = false
      const stateObject = this
      setTimeout(() => {
        setTimeout(() => {
          stateObject.stage = ''
        }, 500)
        stateObject.$bvModal.hide(this.id)
      }, 1000)
    }
  }
}
</script>

<template>
  <b-modal
    :id="id"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div v-if="isSuccessStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-
            miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>

      <div v-if="isFailStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>

      <div v-if="isNormalStage" class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Resume Posting</h2>
        </div>

        <clip-loader v-if="count_loader" :size="'16px'"></clip-loader>

        <template v-else="!count_loader">
          <template v-if="posting_count">
            <div class="input_field">
              <div class="warning_box text-center">
                <p
                  >Note: You have {{ posting_count }} paused post(s). Please
                  choose the method by which you'd like to reschedule those.</p
                >
              </div>
            </div>

            <div class="input_field resume-form">
              <div class="radio_list">
                <div class="radio_input_image">
                  <input
id="schedule_custom" v-model="time_type" type="radio"
                  value="custom" name="schedule_time">
                  <label
                    v-tooltip.top="'Manually'"
                    for="schedule_custom"
                    class="radio_left"
                    >Manually reschedule</label
                  >
                </div>
                <div class="radio_input_image">
                  <input
                    id="schedule_recurring"
                    v-model="time_type"
                    value="recurring"
                    type="radio"
                    name="schedule_time"
                  />
                  <label
                    v-tooltip.top="'Post with a fixed interval'"
                    for="schedule_recurring"
                    class="radio_left"
                    >Regular intervals
                  </label>
                </div>
                <div class="radio_input_image">
                  <input
                    id="schedule_resume"
                    v-model="time_type"
                    value="resume"
                    type="radio"
                    name="schedule_time"
                  />
                  <label
                    v-tooltip.top="'All posts set for a future scheduled time will be automatically resumed, however, posts that have past scheduled time will need to be manually scheduled.'"
                    for="schedule_resume"
                    class="radio_left"
                    >Resume from scheduled time
                  </label>
                </div>
              </div>

              <div v-if="time_type === 'recurring'" class="recurring_block">
                <div class="d-flex align-items-center">
                  <p class="text">Post Every</p>

                  <div v-if="recurring.type === 'hour'" class="with_right_icon">
                    <select v-model="recurring.value" class="inline w-auto">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                    </select>
                    <!--<i class=" right_icon fa fa-caret-down "></i>-->
                  </div>
                  <div class="with_right_icon">
                    <select
                      v-if="recurring.type === 'day'"
                      v-model="recurring.value"
                      class="inline w-auto"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                    </select>

                    <select
                      v-if="recurring.type === 'week'"
                      v-model="recurring.value"
                      class="inline w-auto"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <!--<i class=" right_icon fa fa-caret-down "></i>-->
                  </div>
                  <div class="with_right_icon">
                    <select
                      v-model="recurring.type"
                      class="ml-2 inline w-auto"
                      @change="recurringTypeChange"
                    >
                      <option value="hour">Hour(s)</option>
                      <option value="day">Day(s)</option>
                      <option value="week">Week(s)</option>
                    </select>
                    <!--<i class=" right_icon fa fa-caret-down "></i>-->
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="col-sm-12 footer_box">
            <button
              class="btn gray cancel_btn"
              :disabled="isProcessingLoader"
              @click="hideModal"
              >No</button
            >
            <button
              class="btn red confirm_btn btn_loader loader_right"
              :disabled="isProcessingLoader"
              @click.prevent="resumePosting"
            >
              <span>Yes</span>
              <clip-loader
                v-if="isProcessingLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </template>
      </div>
    </div>
  </b-modal>
</template>
