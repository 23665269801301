import { ref } from 'vue'

const selectedPlatform = ref(null)
const selectedAccount = ref(null)
const selectedPost = ref(null)
const fetchingMoreItems = ref(false)
const selectedTab = ref(null)
const noStatusSelected = ref(true)
const fetching = ref(false)
const tabs = ref([])

export default function useSocialGridView() {
  // Return properties and methods
  return {
    selectedPlatform,
    selectedAccount,
    selectedPost,
    fetchingMoreItems,
    selectedTab,
    noStatusSelected,
    fetching,
    tabs,
  }
}
