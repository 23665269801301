<template>
  <div class="min-h-full flex flex-col bg-cs-light-gray">
    <div class="flex items-center justify-between px-6 h-14">
      <span class="font-bold text-base">Members </span>
    </div>
    <hr class="m-0 hr-color" />
    <div
      class="
        search_bar
        relative
        mt-3
        mb-2
        px-3.5
        flex
        items-center
        justify-center
        w-100
        z-0
      "
    >
      <input
        id="search"
        ref="search"
        v-model="searchValue"
        type="text"
        placeholder="Search by name e.g 'John'"
        class="
          w-100
          rounded-md
          border
          py-2
          px-2
          text-grey-darkest
          focus:border-2
        "
      />
      <i
        class="
          fas
          fa-search
          cursor-pointer
          absolute
          right-4
          text-cs-primary
          px-1
          py-1
        "
      ></i>
    </div>
    <div
      class="
        py-3.5
        px-3.5
        flex-grow flex-shrink
        bg-white
        flex-basis-auto
        overflow-y-auto
        h-0
        mb-8
      "
    >
      <div class="">
        <!-- for displaying all members -->
        <template v-if="!searchVisibility">
            <template v-for="(member,index) in getWorkspaces.activeWorkspace.members">
              <div  v-if="member.user && member.status === 'joined'" :key="index">
                <label  class="relative cst-member-checkbox cursor-pointer flex flex-row-reverse justify-between items-center rounded-md px-2 py-1.5 hover:bg-cs-foggray">
                  <input
                      :id="member._id"
                      v-model="planMembers"
                      class="opacity-0 absolute h-8 w-8 cursor-pointer"
                      :value="member.user._id"
                      type="checkbox"
                  />
                  <span class="check-icon relative cursor-pointer  rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500">
                    <svg class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fill-rule="evenodd">
                        <g transform="translate(-9 -11)" fill="#0088cc" fill-rule="nonzero">
                          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span class="flex items-center relative">
                        <span class="relative mx-1">
                          <img v-if="member.user.image" class="rounded-full object-cover" width="40" height="40" :src="member.user.image"  alt="">
                          <img v-else class="rounded-full object-cover" width="40" height="40" src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"  alt="">
                        </span>
                       <span  class="flex flex-col ml-1">
                         <span :id="member.user" class="block font-medium text-sm text-gray-900">
                             {{getUserInitials(member.user)}}
                         </span>
                         <span :id="member.user" class="block font-normal text-xs text-gray-700" :style="{ 'color': getRoleClass(member.role) }">
                             {{getMutatedUserRole(member.role)}}
                         </span>
                       </span>
                  </span>
                </label>
              </div>
            </template>
        </template>
        <!-- for displaying search members -->
        <template v-for="(member, index) in getFilteredMember" :key="`member_${index}`">
          <div v-if="member.user && member.status === 'joined' && searchVisibility">
            <label  class="relative cst-member-checkbox cursor-pointer flex flex-row-reverse justify-between items-center rounded-md px-2 py-2 hover:bg-gray-400" >
              <input
                  :id="member._id"
                  v-model="planMembers"
                  class="opacity-0 absolute h-8 w-8 cursor-pointer"
                  :value="member.user._id"
                  type="checkbox"
              />
              <span class="check-icon relative cursor-pointer  rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500">
                    <svg class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none" version="1.1" viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fill-rule="evenodd">
                        <g transform="translate(-9 -11)" fill="#0088cc" fill-rule="nonzero">
                          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                        </g>
                      </g>
                    </svg>
                  </span>
              <span class="flex items-center relative">
                        <span class="relative mx-1">
                          <img v-if="member.user.image" class="rounded-full object-cover" width="40" height="40" :src="member.user.image"  alt="">
                          <img v-else class="rounded-full object-cover" width="40" height="40" src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"  alt="">
                        </span>
                       <span  class="flex flex-col ml-1">
                         <span :id="member.user" class="block font-medium text-sm text-gray-900">
                             {{getUserInitials(member.user)}}
                         </span>
                         <span :id="member.user" class="block font-normal text-xs text-gray-700" :style="{ 'color': getRoleClass(member.role) }">
                             {{getMutatedUserRole(member.role)}}
                         </span>
                       </span>
                  </span>
            </label>
          </div>
        </template>
        <div v-if="searchVisibility && getFilteredMember < 1" class="text-center">No member found.</div>
      </div>
    </div>
    <CstButton
      variant="primary"
      class="!mx-8 mb-10"
      @click="$emit('redirect-to', 'preview')"
    >
      <p class="leading-4 font-medium text-light-cs">Continue</p>
    </CstButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CstButton from '@ui/Button/CstButton.vue'
export default {
  name: "Members",
  components: {
    CstButton
  },
  props:{
    members: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  emits: ['redirect-to'],
  data() {
    return {
        planMembers: this.members,
        searchValue:'',
        searchVisibility: false,
    }
  },
  computed:{
    ...mapGetters(['getWorkspaces']),
    getFilteredMember() {
      const self = this
      const members = []
      if (this.searchValue) {
        self.searchVisibility = true
        this.getWorkspaces.activeWorkspace.members.forEach(
            (workspaceMember) => {
              if (workspaceMember.status === 'joined' && workspaceMember.user) {
                if (
                    (workspaceMember.user.firstname && workspaceMember.user.firstname.toLowerCase().indexOf(self.searchValue.toLowerCase()) >= 0) ||
                    (workspaceMember.user.lastname && workspaceMember.user.lastname.toLowerCase().indexOf(self.searchValue.toLowerCase())) >= 0
                ) {
                  members.push(workspaceMember)
                } else if (
                    workspaceMember.user.email
                        .toLowerCase()
                        .indexOf(self.searchValue.toLowerCase()) >= 0
                ) {
                  members.push(workspaceMember)
                }
              }
            }
        )
      }
      else{
        self.searchVisibility = false
        return []
      }
      return members
    }
  },
  watch:{
    planMembers(val){
      this.$emit('update-members', val)
    },
  },
  methods:{
    getUserInitials(user){
      if(user.firstname && user.lastname) return user.firstname + ' ' + user.lastname
      return user.email
    },
  }

}
</script>

<style lang="scss" scoped>
.cst-member-checkbox {
margin-bottom:0;
  &:hover{
    @apply bg-cs-foggray;
  }

input + .check-icon {
  background-color: transparent;
  border: 1.5px solid #99a3a982;
  border-radius: 0.3rem;
  width: 16px;
  height: 16px;
}
input:checked + .check-icon {
  @apply border-blue-300;
}
input:checked + .check-icon svg {
  @apply block;
}
}
</style>
