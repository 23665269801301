<template>
  <ConfirmBox
    id="removeWorkspace"
    :call-back-action="removeWorkspace"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Workspace</h2>
    </template>

    <template v-slot:description>
      <p>By removing this workspace, their:</p>
    </template>

    <template v-slot:alert_list>
      <ul class="alert_list warning">
        <li>Blogs/Accounts will be removed.</li>
        <li>Integrations will be removed.</li>
        <li>Curated Topics and Custom Topics will be removed.</li>
        <li>Favorite Folders will be removed.</li>
        <li>Your added sources will be removed from this workspace.</li>
        <li>Automation Campaigns will be removed.</li>
        <li>Plans will be removed.</li>
        <li>Publications will be removed.</li>
        <li>Comment, task and other activities will be removed.</li>
        <li>Media assets (Media library) will be removed.</li>
      </ul>
    </template>

    <div slot="footer" class="text-center">
      <p class="text-center">This action cannot be undone.</p>
    </div>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
