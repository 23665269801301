<template>
  <div class="bg-white w-full flex items-center justify-between px-7 py-5">
    <div class="flex items-center gap-2">
      <template v-if="isIndividualReport">
        <router-link :to="getBackButtonLink">
          <div
            v-tooltip="{
              content: 'Go back to all reports.',
            }"
            class="cursor-pointer w-9 h-9 flex justify-center items-center bg-gray-50 hover:bg-gray-100 active:bg-gray-300 rounded-full"
          >
            <img
              src="@src/assets/img/icons/analytic/backIcon.svg"
              alt=""
              class="w-4 h-4"
            />
          </div>
        </router-link>
        <hr class="w-6 h-full" style="transform: rotate(90deg)" />
      </template>
      <div class="text-base flex items-center gap-2">
        <p :class="{ 'font-weight-500': !isIndividualReport }">{{
          reportType
        }}</p>
        <template v-if="isIndividualReport">
          <hr class="w-6 h-full" style="transform: rotate(115deg)" />
          <p class="font-weight-500">{{ reportName }}</p>
        </template>
      </div>
    </div>
    <div v-if="showReportOptions" class="flex gap-3">
      <div class="w-56">
        <CstInputFields
          :date-options="dateOptions"
          :value="defaultDateRange"
          class="planner-date-picker cursor-pointer"
          placeholder="Select specific date range"
          size="small"
          type="date"
          :date-picker-popup-classes="['planner-date-picker-popup']"
          @change="handleDateFilterChange"
        />
      </div>
      <div
        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 py-2.5 px-3 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"
        @click="
          EventBus.$emit('show-manage-competitors-modal', reportCompetitorsInfo)
        "
        ><p>Manage Competitors</p>
      </div>

      <!--      TODO:: will be implemented in next Phase-->
      <!--      <div-->
      <!--        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 rounded-lg pl-4 pr-1.5 py-2.5 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center gap-2"-->
      <!--      >-->
      <!--        <p>Save</p>-->
      <!--        <i class="fa fa-angle-down px-2.5 rounded-lg text-lg !bg-[#549AEE]"></i>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        class="border text-gray-900 bg-cs-white hover:bg-gray-50 bg-opacity-90 hover:bg-opacity-100 cursor-pointer h-10 rounded-lg py-2.5 px-3 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"-->
      <!--      >-->
      <!--        <img-->
      <!--          src="@src/assets/img/icons/analytic/graphs_filter.svg"-->
      <!--          alt=""-->
      <!--          class="text-[#9099FF]"-->
      <!--        />-->
      <!--      </div>-->
    </div>

    <div v-if="!showReportOptions">
      <div
        class="bg-cs-primary bg-opacity-90 hover:bg-opacity-100 text-white cursor-pointer h-10 py-2.5 px-3 border-0 focus:outline-none rounded-md hover:shadow-lg font-medium flex items-center justify-center"
        @click="EventBus.$emit('show-manage-competitors-modal')"
        ><p> + Create New</p>
      </div>
    </div>
  </div>
</template>

<script setup>
// libraries
import { computed, watch } from 'vue'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'

// components
import CstInputFields from '@ui/Input/CstInputFields'

// composable
import useCompetitorReport from '@src/modules/analytics_v3/composables/useCompetitorReport'
import { useRoute } from 'vue-router'

const {
  setDateValues,
  reportCompetitorsInfo,
  dateRange,
  defaultDateRange,
  dateRangeDiff,
} = useCompetitorReport()

const $route = useRoute()

const reportType = computed(() =>
  $route.name.includes('facebook_competitor')
    ? 'Facebook Competitor Analytics'
    : 'Instagram Competitor Analytics'
)
const reportName = computed(() =>
  reportCompetitorsInfo.value ? reportCompetitorsInfo.value.name : ''
)
const isIndividualReport = computed(() => {
  return !!$route.name.includes('_competitor_v3')
})
const getBackButtonLink = computed(() => {
  if ($route.name === 'instagram_competitor_v3') {
    return { name: 'instagram_competitor_overview_v3' }
  } else if ($route.name === 'facebook_competitor_v3') {
    return { name: 'facebook_competitor_overview_v3' }
  }
  return { name: 'analytics_overview_v3' }
})

const showReportOptions = computed(() => {
  return !!$route.name.includes('_competitor_v3')
})

watch(
  () => isIndividualReport.value,
  (newVal) => {
    dateRange.value = ''
    if (newVal) {
      setDateValues()
    }
  },
    { immediate: true }
)

const dateOptions = computed(() => ({
  defaultValue: dateRange.value,
  shortcuts: [
    {
      text: 'Today',
      onClick() {
        const date = moment().toDate()
        // return a Date
        return [date, date]
      },
    },
    {
      text: 'Yesterday',
      onClick() {
        const date = moment().subtract(1, 'days').toDate()
        return [date, date]
      },
    },
    {
      text: 'Last 7 Days',
      onClick() {
        const start = moment().subtract(7, 'days').startOf('day').toDate()
        const end = moment().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 14 Days',
      onClick() {
        const start = moment().subtract(14, 'days').startOf('day').toDate()
        const end = moment().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 21 Days',
      onClick() {
        const start = moment().subtract(21, 'days').startOf('day').toDate()
        const end = moment().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 30 Days',
      onClick() {
        const start = moment().subtract(30, 'days').startOf('day').toDate()
        const end = moment().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Month to Date',
      onClick() {
        const start = moment().startOf('month').toDate()
        const end = moment().endOf('day').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last Month',
      onClick() {
        const start = moment().subtract(1, 'months').startOf('month').toDate()
        const end = moment().subtract(1, 'months').endOf('month').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 3 Months',
      onClick() {
        const start = moment().subtract(3, 'months').startOf('month').toDate()
        const end = moment().endOf('month').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 6 Months',
      onClick() {
        const start = moment().subtract(6, 'months').startOf('month').toDate()
        const end = moment().endOf('month').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 9 Months',
      onClick() {
        const start = moment().subtract(9, 'months').startOf('month').toDate()
        const end = moment().endOf('month').toDate()

        return [start, end]
      },
    },
    {
      text: 'Last 12 Months',
      onClick() {
        const start = moment().subtract(12, 'months').startOf('month').toDate()
        const end = moment().endOf('month').toDate()

        return [start, end]
      },
    },
  ],
  disabledDate: (date) => date > new Date(),
  format: 'DD MMM YY',
}))

const handleDateFilterChange = (date) => {
  if (date[0] && date[1]) {
    const startDate = moment(date[0]).format('YYYY-MM-DD')
    const endDate = moment(date[1]).format('YYYY-MM-DD')
    dateRange.value = `${startDate} - ${endDate}`

    EventBus.$emit('re-fetch-report-data')

    const [startDateStr, endDateStr] = dateRange.value.split(' - ')
    dateRangeDiff.value = moment(endDateStr).diff(moment(startDateStr), 'days')
  }
}
</script>

<style lang="scss">
.planner-date-picker-popup {
  th,
  td {
    height: 35px !important;
    //font-size: 16px !important;
  }

  .mx-calendar {
    height: 300px;
  }
}
</style>
