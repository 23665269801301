<template>
  <CstDropdown
    class="w-full min-w-[20rem]"
    size="small"
    button-classes="flex !rounded-xl w-full"
    container-classes="max-h-[30rem]"
    @on-close="resetLocalSelected"
  >
    <template v-slot:selected>
      <span class="flex flex-row w-full items-center">
        <div class=" w-[1.875rem] h-[1.875rem] bg-cs-blue rounded-full flex justify-center items-center ">
           <span class="font-bold text-white">
            {{ labelsAndCampaignsLength > 99 ? '99+' : labelsAndCampaignsLength}}
           </span>
        </div>
        <span
          class="text-left inline-flex flex-col mx-0.5 font-bold justify-start"
        >
          {{`Campaigns & Labels`}}
          <small>Selected</small>
        </span>
      </span>
    </template>
    <template v-slot>
      <!-- Campaign Collapse -->
      <div class="filter-bar">
        <div
          class="title-header"
          :class="{ collapsed: !collapsable['campaigns'] }"
          @click="(e) => handleCollapse('campaigns', e)"
        >
          <div class="flex-1 pl-5 py-3.5">
            <i class="fa fa-bullhorn text-gray-700 w-4 h-4 inline-flex justify-center items-center"></i>
            <span class="ml-2 font-weight-500">Campaigns</span>
          </div>
          <div>
            <div class="relative m-0 hover:text-black-900 flex">
              <span class="text-xs">
                <i
                  class="far fa-chevron-up text-gray-700 w-4 h-4 inline-flex justify-center items-center"
                ></i>
              </span>
              <label class="m-0 p-0 d-flex justify-between items-center">
                <input
                  class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                  :checked="
                    (LocalSelectedCampaigns.length === getCampaigns.length) && getCampaigns.length > 0
                  "
                  type="checkbox"
                  @change="(event) => handleSelectAll(event, 'campaigns')"
                />

                <div
                  class="prevent-toggle relative cursor-pointer bg-white rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center ml-2 focus-within:border-blue-500"
                >
                  <svg
                    class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
                    version="1.1"
                    viewBox="0 0 17 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-9 -11)"
                        fill="#0088cc"
                        fill-rule="nonzero"
                      >
                        <path
                          d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </label>
            </div>
          </div>
        </div>
        <b-collapse
          id="campaign-collapse"
          v-model="collapsable['campaigns']"
          visible
        >
          <div class="py-1.5">
            <p v-if="getCampaigns?.length === 0" class="text-sm text-center text-gray-800"> No Campaign is available </p>

            <template v-for="item in getCampaignsSliced" :key="item._id">
              <div class="py-2 px-5">
                <SimpleCheckbox
                  v-model="LocalSelectedCampaigns"
                  class="group"
                  :name="item._id"
                >
                  <template v-slot:label>
                    <span
                      v-tooltip="{
                        content: tooltipHtml(item?.name),
                        theme: 'light',
                        allowHTML: true,
                      }"
                      class="px-3 py-1 rounded-md  max-w-[10rem] text-white text-sm truncate inline-block"
                      :style="getCampaignColor(item.color)"
                    >{{ item.name }}</span>
                    <span
                      class="ml-2 text-xs font-normal border rounded-md bg-gray-400 hover:bg-gray-500 px-1 hidden group-hover:inline-flex absolute right-3"
                      @click.stop.prevent="
                        handleOnlyFilter('campaigns', item._id)
                      "
                      >only</span
                    >
                  </template>
                </SimpleCheckbox>
              </div>
            </template>
            <button
              v-if="getCampaigns.length > 5"
              class="border-0 bg-white my-1 w-full text-gray-700 gap-4 flex justify-center items-center"
              @click.stop.prevent="() => viewMore.campaigns = !viewMore.campaigns"
            >
            <div class="flex h-6 w-6 rounded-full bg-gray-450 justify-center items-center">
              <i
              class="text-xs text-gray-700"
              :class="{
                'fa fa-chevron-up': viewMore.campaigns,
                'fa fa-chevron-down': !viewMore.campaigns,
              }"
            ></i>

          </div>
            {{ !viewMore.campaigns ? 'View All' : 'View Less' }}
            </button>
          </div>
        </b-collapse>
      </div>
      <!-- Labels Collapse -->
      <div class="filter-bar">
        <div
          :class="collapsable['labels'] ? null : 'collapsed'"
          class="title-header"
          @click="(e) => handleCollapse('labels', e)"
        >
          <div class="flex-1 pl-5 py-3.5">
            <i class="fa fa-tag text-gray-700 w-4 h-4 inline-flex justify-center items-center"></i>
            <span class=" ml-2 font-weight-500">Labels</span>
          </div>
          <div>
            <div class="relative m-0 hover:text-black-900 flex">
              <span class="text-xs">
                <i
                  class="far fa-chevron-up text-gray-700 w-4 h-4 inline-flex justify-center items-center"
                ></i>
              </span>
              <label class="m-0 p-0 d-flex justify-between items-center">
                <input
                  class="prevent-toggle check-input opacity-0 absolute h-8 w-8"
                  :checked="(LocalSelectedLabels.length === getLabels.length) && getLabels.length > 0"
                  type="checkbox"
                  @change="(event) => handleSelectAll(event, 'labels')"
                />

                <div
                  class="prevent-toggle relative cursor-pointer bg-white rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center ml-2 focus-within:border-blue-500"
                >
                  <svg
                    class="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
                    version="1.1"
                    viewBox="0 0 17 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-9 -11)"
                        fill="#0088cc"
                        fill-rule="nonzero"
                      >
                        <path
                          d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </label>
            </div>
          </div>
        </div>
        <b-collapse
          id="labels-collapse"
          v-model="collapsable['labels']"
          visible
        >
          <div class="py-1.5">
            <p v-if="getLabels?.length === 0" class="text-sm text-center text-gray-800"> No Label is available </p>
            <template v-for="item in getLabelsSliced" v-else :key="item._id">
              <div class="py-2 px-5">
                <SimpleCheckbox
                  v-model="LocalSelectedLabels"
                  class="group"
                  :name="item._id"
                >
                  <template v-slot:label>
                    <span
                      v-tooltip="{
                        content: tooltipHtml(item?.name),
                        theme: 'light',
                        allowHTML: true,
                      }"
                      class="px-3 py-1 rounded-md max-w-[10rem] truncate inline-block "
                      :style="getLabelColor(item.color)"
                      >{{ item.name }}</span
                    >
                    <span
                      class="ml-2 text-xs font-normal border rounded-md bg-gray-400 hover:bg-gray-500 px-1 hidden group-hover:inline-flex absolute right-3"
                      @click.stop.prevent="handleOnlyFilter('labels', item._id)"
                      >only</span
                    >
                  </template>
                </SimpleCheckbox>
              </div>
            </template>
            <button
              v-if="getLabels.length > 5"
              class="border-0 bg-white my-1 w-full text-gray-800 gap-4 flex justify-center items-center"
              @click.stop.prevent="() => viewMore.labels = !viewMore.labels"
            >
            <div class="flex h-6 w-6 rounded-full bg-gray-450 justify-center items-center">
              <i
              class="text-xs text-gray-700"
              :class="{
                'fa fa-chevron-up': viewMore.labels,
                'fa fa-chevron-down': !viewMore.labels,
              }"
            ></i>

          </div>
            {{ !viewMore.labels ? 'View All' : 'View Less' }}
            </button>
          </div>
        </b-collapse>
      </div>
      <div class="p-1 sticky bottom-0 border-top bg-white z-50">
        <CstDropdownItem
          class="active:bg-transparent hover:bg-transparent !p-0.5 text-right"
          size="small"
          :close-on-click="true"
        >
          <CstButton
            size="small"
            text="Apply"
            @click="handleApplySelection"
          />
        </CstDropdownItem>
      </div>
    </template>
  </CstDropdown>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import CstButton from '@ui/Button/CstButton.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign'
import { EventBus } from '@common/lib/event-bus'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

const { tooltipHtml } = useComposerHelper()

const { PREFERNCES_TYPES, allCampaignsAndLabels, selectedCampaignsAndLabels, getAnalyticsPreference, updatePreferences, getLabelColor, getCampaignColor } =
  useLabelAndCampaign()

const LocalSelectedLabels = ref([])
const LocalSelectedCampaigns = ref([])
const viewMore = ref({
  labels: false,
  campaigns: false,
})
const collapsable = ref({
  labels: true,
  campaigns: true,
})

onMounted(() => {
  selectDefault()
})

const getLabels = computed(() => {
    return allCampaignsAndLabels.value?.labels || []
})

const getCampaigns = computed(() => {
    return allCampaignsAndLabels.value?.campaigns || []
})

const getLabelsSliced = computed(() => {
  if(viewMore.value.labels) {
    return allCampaignsAndLabels.value?.labels || []
  }
  return allCampaignsAndLabels.value?.labels.slice(0, 5) || []
})

const getCampaignsSliced = computed(() => {
  if(viewMore.value.campaigns) {
    return allCampaignsAndLabels.value?.campaigns || []
  }
  return allCampaignsAndLabels.value?.campaigns.slice(0, 5) || []
})

const labelsAndCampaignsLength = computed(() => {
  return (
    selectedCampaignsAndLabels.value.campaigns.length +
    selectedCampaignsAndLabels.value.labels.length
  )
})

watch(
  () => allCampaignsAndLabels.value,
  () => {
    selectDefault()
  },
  { deep: true }
)

watch(
  () => selectedCampaignsAndLabels.value,
  () => {
    EventBus.$emit('set-report-labels', selectedCampaignsAndLabels.value.labels)
    EventBus.$emit(
      'set-report-campaigns',
      selectedCampaignsAndLabels.value.campaigns
    )
  },
  { deep: true }
)

const selectDefault = () => {
  LocalSelectedLabels.value = selectedCampaignsAndLabels.value.labels = getAnalyticsPreference.value?.labels || []
  LocalSelectedCampaigns.value = selectedCampaignsAndLabels.value.campaigns = getAnalyticsPreference.value?.campaigns || []
}

/**
 * method to toggle collapse
 * @param key string
 * @param event Event
 */
const handleCollapse = (key, event) => {
  if (!event.target.classList.contains('prevent-toggle')) {
    collapsable.value[key] = !collapsable.value[key]
  }
}

/**
 * method to handle select all for labels and campaigns
 * @param event Event
 * @param type string
 */
const handleSelectAll = (event, type) => {
  const selectedItems = event.target.checked
    ? getAllItems(type).map((item) => item._id)
    : []

  if (type === 'labels') {
    LocalSelectedLabels.value = selectedItems
  } else {
    LocalSelectedCampaigns.value = selectedItems
  }
}
const getAllItems = (type) => {
  return type === 'labels' ? getLabels.value : getCampaigns.value
}
/**
 * method to handle only filter for labels and campaigns
 * @param type string
 * @param id string
 */
const handleOnlyFilter = (type, id) => {
  if (type === 'labels') {
    LocalSelectedLabels.value = [id]
  } else {
    LocalSelectedCampaigns.value = [id]
  }
}
/**
 * method to apply selected labels and campaigns
 * to the main selected labels and campaigns
 */
const handleApplySelection = () => {
  selectedCampaignsAndLabels.value.campaigns = LocalSelectedCampaigns.value
  selectedCampaignsAndLabels.value.labels = LocalSelectedLabels.value
  updatePreferences(PREFERNCES_TYPES.campaigns_and_labels)
}

/**
 * method to reset local selected labels and campaigns
 * to the main selected labels and campaigns
 * when the dropdown is closed
 */
const resetLocalSelected = () => {
  LocalSelectedLabels.value = selectedCampaignsAndLabels.value.labels
  LocalSelectedCampaigns.value = selectedCampaignsAndLabels.value.campaigns
}
</script>

<style scoped lang="scss">
.text-ellipsis {
  display: block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.check-input + div {
  //border-width: 1px;
  //@apply border-solid;
  //@apply border-gray-400;

  background-color: transparent;
  border: 1.5px solid #99a3a982;
  border-radius: 0.3rem;
  width: 16px;
  height: 16px;
}

input.check-input:checked + div {
  @apply border-blue-300;
}
input.check-input:checked + div svg {
  @apply block;
}

.filter-bar {
  border-right: 1px solid #dee2e6;

  i {
    transition: all 0.2s ease-in-out;
  }
  .collapsed {
    //background: #ffffff;

    .fa-chevron-up {
      transform: rotate(180deg);
    }
  }

  .title-header {
    @apply cursor-pointer flex justify-between items-center pr-5 bg-cs-metalgray hover:bg-cs-foggray;
    //background: #8cb4db14;
    transition: all 0.2s ease-in-out;
  }
}

.cst-account-checkbox__platform {
  font-size: 0.7rem;
  text-align: center;
}

.grayscale.filter.cursor-not-allowed {
  background: #e3e8eb !important;
}

.img-border {
  border: 0.25px solid #e8e8e8;
}
</style>
