<script setup>
import { computed, defineProps, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import TwitterPostModal from '@src/modules/analytics/views/twitter/components/TwitterPostModal.vue'
import useTwitterAnalytics from '@/src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

defineProps({
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
})

const route = useRoute()
const { getters } = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  postsLimit,
  postsMaxLimit,
  isPostDataLoading,
  isReportView,
  topPostsData,
  validPostsTableHeaders,
  validPostsTableApiKeys,
  nonSortableItems,

  getHeaderTitles,
  getBodyValues,
  getHeaderTooltips,
  getHeaderApiKey,
  fetchMedia,
} = useTwitterAnalytics()

const selectedSortKey = ref(validPostsTableApiKeys.engagement)

const dropDownValues = computed(() => {
  if (!topPostsData.value?.length && !postsMaxLimit.value) return []
  const range = []
  for (let i = 10; i <= postsMaxLimit.value; i += 10) {
    range.push(i)
  }
  return range
})

watch(
  () => [dateRange, route.params.accountId],
  async () => {
    if (!route.name.includes('twitter')) return

    isPostDataLoading.value = true
    selectedAccount.value = getters.getTwitterAccounts?.items?.find(
      (account) => account.twitter_id === route.params?.accountId
    )

    await fetchMedia(routes.TOP_PERFORMING_TWEETS, {
      limit: postsLimit.value,
    })
    isPostDataLoading.value = false
  },
  { deep: true }
)

/**
 * @description sets the limit of top posts to be fetched in report & fetches data
 * @param value
 * @return {Promise<void>}
 */
const handleDropDownClick = async (value) => {
  EventBus.$emit('set-top-posts-limit', value)
  postsLimit.value = value
  await fetchMedia(routes.TOP_PERFORMING_TWEETS, {
    limit: postsLimit.value,
  })
}

const handleSortClick = async (header) => {
  const apiKey = getHeaderApiKey(header)
  if (apiKey) {
    selectedSortKey.value = apiKey
    await fetchTopPerformingPosts()
  }
}

const fetchTopPerformingPosts = async () => {
  isPostDataLoading.value = true
  await fetchMedia(routes.TOP_PERFORMING_TWEETS, {
    limit: postsLimit.value,
    order_by: selectedSortKey.value,
  })
  isPostDataLoading.value = false
}
</script>

<template>
  <AnalyticsCardWrapper custom-class="overflow-x-auto">
    <template v-slot:card-header>
      <div class="flex justify-between items-center w-full">
        <div class="mx-1">
          <h2 class="text-secondary-cs text-base font-medium">
            X (Twitter) Top Posts {{ isReportView ? `(Top ${postsLimit})` : '' }}
          </h2>
          <p class="text-sm text-secondary-cs-700">
            Review your top tweets published during the selected time period,
            based on their lifetime performance.
          </p>
        </div>
        <CstDropdown
          v-if="!isReportView && !isPostDataLoading && topPostsData?.length > 0"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes= '!rounded-md !border-[#70707029]'
          button-classes="flex !px-5 !w-28 !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">
              Top {{ !isReportView && postsLimit }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem
              v-for="(value, index) in dropDownValues"
              :key="`activity_type_${index}`"
              @click="handleDropDownClick(value)"
            >
              <p class="text-gray-900 text-sm">{{ value }}</p>
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body>
      <AnalyticsPostsTable
        :selected-account="selectedAccount"
        :data-list="topPostsData"
        :valid-headers-list="validPostsTableHeaders"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-report-view="isReportView"
        :range-start-value="rangeStartValue"
        :range-end-value="rangeEndValue"
        :range-max-value="postsMaxLimit"
        @update-sort="handleSortClick"
      >
        <template v-slot:post-modal="{ selectedPost }">
          <TwitterPostModal
            v-if="selectedPost"
            :selected-account="selectedAccount"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </template>
  </AnalyticsCardWrapper>
</template>
