<template>
  <div v-if="variant === 'rollup'" class="w-100">
    <div class="summary-card">
      <div class="summary-card__title flex gap-1">
        {{ title }}
        <v-menu
          v-if="tooltipCustom !== ''"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{ tooltipCustom }}
            </p>
          </template>
        </v-menu>
      </div>
      <div
        class="summary-card__body d-flex"
        style="justify-content: space-between"
      >
        <span
          v-tooltip="{
            content: tooltipHtml(total),
            theme: 'light',
            allowHTML: true,
          }"
          class="summary-card__total">{{
            total?.toLocaleString('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            })
          }}</span>
        <span v-if="reach === '0%'" class="ml-auto summary-card__reach normal">
          <i class="fas fa-horizontal-rule"></i>
        </span>
        <span
          v-else
          class="ml-auto summary-card__reach"
          :class="{ reachup: reachUp }"
        >
          {{ reach }}
          <i
            v-show="reachUp"
            class="fas fa-arrow-up"
            style="transform: rotate(45deg)"
          ></i>
          <i
            v-show="!reachUp"
            class="fas fa-arrow-down"
            style="transform: rotate(-45deg)"
          ></i>
        </span>
      </div>
    </div>
  </div>
  <div v-else-if="variant === 'mini'" class="summary-card !w-full">
    <div class="w-100">
      <div class="summary-card__title">
        {{ title }}
      </div>
      <div
        class="summary-card__body d-flex"
        style="justify-content: space-between"
      >
        <span
          v-tooltip="{
            content: tooltipHtml(total),
            theme: 'light',
            allowHTML: true,
          }"
          class="summary-card__total"
          >{{
            total?.toLocaleString('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            })
          }}</span
        >
        <span v-if="reach === '0%'" class="ml-auto summary-card__reach normal">
          <i class="fas fa-horizontal-rule"></i>
        </span>
        <span
          v-else
          class="ml-auto summary-card__reach"
          :class="{ reachup: reachUp }"
        >
          {{ reach }}
          <i
            v-show="reachUp"
            class="fas fa-arrow-up"
            style="transform: rotate(45deg)"
          ></i>
          <i
            v-show="!reachUp"
            class="fas fa-arrow-down"
            style="transform: rotate(-45deg)"
          ></i>
        </span>
      </div>
    </div>
  </div>

  <div v-else-if="variant === 'group'">
    <div class="summary-card">
      <div class="summary-card__variant-grp">
        <div>
          <div class="summary-card__title">
            {{ title }}
          </div>
          <div class="summary-card__body">
            <span
              v-tooltip="{
                content: tooltipHtml(total),
                theme: 'light',
                allowHTML: true,
              }"
              class="summary-card__total">{{
                total?.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              }}</span>
          </div>
        </div>
        <div class="summary-card__group">
          <span
            >Positive <b>{{ positive }}</b></span
          >
          <span
            >Negative <b>{{ negative }}</b></span
          >
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="summary-card">
      <div class="summary-card__title">
        {{ title }}
      </div>
      <div class="summary-card__body">
        <span
          v-if="isReportView && total < 1000000"
          v-tooltip="{
            content: tooltipHtml(total),
            theme: 'light',
            allowHTML: true,
          }"
          class="summary-card__total"
          >{{
            roundNumber(total)
          }}</span
        >
        <span
          v-else
          v-tooltip="{
            content: tooltipHtml(total),
            theme: 'light',
            allowHTML: true,
          }"
          class="summary-card__total"
          >{{
            total?.toLocaleString('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            })
          }}</span
        >
        <span v-if="reach === '0%'" class="ml-auto summary-card__reach normal">
          <i class="fas fa-horizontal-rule"></i>
        </span>
        <span
          v-else
          class="ml-auto summary-card__reach"
          :class="{ reachup: reachUp }"
        >
          {{ reach }}
          <template>
            <i
              v-show="reachUp"
              class="fas fa-arrow-up"
              style="transform: rotate(45deg)"
            ></i>
            <i
              v-show="!reachUp"
              class="fas fa-arrow-down"
              style="transform: rotate(-45deg)"
            ></i>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { isReportView } = useAnalytics()
const { roundNumber } = useNumber()
const { tooltipHtml } = useComposerHelper()

export default {
  name: 'SummaryCard',
  props: {
    variant: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    reach: {
      type: String,
      default: '',
    },
    reachUp: {
      type: Boolean,
      default: false,
    },
    positive: {
      type: [String, Number],
      default: '',
    },
    negative: {
      type: [String, Number],
      default: '',
    },
    tooltipCustom: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      tooltipHtml,
      isReportView,
      roundNumber
    }
  },
}
</script>
