<template>
  <div>
    <svg
      class="progress_radial blue noSelect"
      data-progress="65"
      x="0px"
      y="0px"
      viewBox="0 0 80 80"
    >
      <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
      <path class="fill" :style="`stroke-dashoffset:${percentage}`" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
      <text class="value" x="50%" y="55%">%</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CircularBar",
  props:{
    bar_data:{type:Number,required:true}
  },
  data() {
    return {
      value: 0,
    };
  },
  computed:{
    percentage () {
       const max = -219.99078369140625;
       const test= ((100 - this.value) / 100) * max
      return test
    }
  },
  watch: {
  },
  mounted(){
     this.$nextTick(() => {
        this.value = this.bar_data
     })
  },
};
</script>

<style scoped>
.progress_radial {
  width: 40px;
  height: 40px;
  background-color: unset !important;
}
.progress_radial .track,
.progress_radial .fill {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 8;
  transform: rotate(90deg) translate(0px, -80px);
}
.progress_radial .track {
  stroke: #e0e2e3
}
.progress_radial .fill {
  stroke: rgb(255, 255, 255);
  stroke-dasharray: 219.99078369140625;
  stroke-dashoffset: -219.99078369140625;
  transition: stroke-dashoffset 1s;
}
.progress_radial.blue .fill {
  stroke: rgb(17 160 245);
}
.progress_radial .value,
.progress_radial text {
  fill: rgb(202 212 218);
  text-anchor: middle;
  font-size: 26px;
  font-weight: 600;
}
</style>
