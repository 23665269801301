<template>
  <div class="min-h-full flex flex-col bg-cs-light-gray">
    <div class="flex items-center px-6 h-16 max-h-16 min-h-16">
      <span class="font-bold text-base"
        >Task ({{ tasks.filter((task) => task.status).length }}/{{
          tasks.length
        }})</span
      >
      <div class="ml-4 flex items-center">
        <CircleProgress
          :percent="taskPercentage"
          unit="'%'"
          :show-percent="false"
          :size="40"
          :border-width="8"
          :border-bg-width="9"
          :transition="800"
          empty-color="rgba(184, 189, 209, 0.16)"
          fill-color="#005fd0"
        />
        <p class="ml-2">{{ taskPercentage }}%</p>
      </div>
    </div>
    <hr class="m-0 hr-color" />
    <div class="mt-2 px-3.5 atwho_dropdown">
      <AtTa
        v-model="task.text"
        :hover-select="true"
        :members="getActiveWorkspaceMembersName"
      >
        <textarea
          v-model="task.text"
          maxlength="2200"
          rows="2"
          class="rounded-md w-full p-3 border border-gray-300 text-sm min-h-[60px]"
          placeholder="Write a task..."
        ></textarea>
      </AtTa>
      <div class="py-1.5 flex items-center">
        <button
          :disabled="loader.task || task.text === ''"
          class="
            ml-auto
            text-sm
            bg-cs-primary
            focus:outline-none
            rounded-md
            text-white
            border-0
            px-3
            py-1.5
          "
          :class="{ 'cursor-not-allowed': loader.task || task.text === '' }"
          @click="() => handleTask()"
          >Add Task
        </button>
      </div>
    </div>
    <hr class="m-1 hr-color" />
    <div
      v-if="tasks && tasks.length"
      class="
        py-2
        px-3.5
        flex-grow flex-shrink flex-basis-auto
        overflow-y-auto
        h-0
        mb-24
      "
    >
      <div class="flex justify-between font-semibold">
        <div
          class="
            ml-auto
            prevent_close_dropdown
            dropdown
            default_style_dropdown
            taskDropdown
          "
        >
          <div class="dropdown_header" data-toggle="dropdown"> Delete All</div>
          <div class="dropdown-menu dropdown-menu-right">
            <ul class="inner">
              <div class="head_sec">
                <p>Confirmation</p>
              </div>
              <div class="body_text">
                <p
                  >Are you sure?
                  <a data-toggle="dropdown" class="red" @click="removeTask"
                    >Yes </a
                  >-<a data-toggle="dropdown">No </a>
                </p>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <template v-for="(singleTask, id) in tasks" :key="id">
        <div
          class="
            relative
            bg-cs-white
            px-1
            py-2
            hover:bg-cs-foggray
            mt-2
          "
        >
          <div class="flex justify-between py-1.5">
            <div class="flex items-center w-100 space-x-2">
                <CstSimpleCheckbox
                        v-model="singleTask.status"
                        reverse
                        @change="changeTaskStatus(singleTask)"
                >
                </CstSimpleCheckbox>
                <span
                        class="whitespace-pre-wrap ml-1"
                        v-html="getMentionedUsersNames(singleTask.text)"
                />
            </div>

            <div
              class="
                edit_options
                hidden
                flex
                absolute
                -top-1
                bg-white
                right-3
                border
              "
            >
              <div
                class="
                  dropdown
                  default_style_dropdown
                  taskDropdown
                  d-inline-block
                  align-middle
                "
              >
                <i
                  v-tooltip.top="'Edit'"
                  class="icon-edit-cs text-gray-700 p-1.5 cursor-pointer"
                  @click.prevent="setEditTask(singleTask)"
                ></i>
                <i
                  v-tooltip.top="'Remove'"
                  class="
                    icon-delete-cs
                    text-red-700
                    p-1.5
                    cursor-pointer
                    dropdown_header
                  "
                  data-toggle="dropdown"
                />
                <div class="dropdown-menu dropdown-menu-right font-semibold">
                  <ul class="inner">
                    <div class="head_sec">
                      <p>Confirmation</p>
                    </div>
                    <div class="body_text">
                      <p
                        >Are you sure?
                        <a class="red" @click="removeTask(singleTask)">Yes</a>
                        -<a data-toggle="dropdown">No</a></p
                      >
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--......edit  button click case ........-->
          <div
            v-if="editTask.id && editTask.id === singleTask._id"
            class="add_check_list"
          >
            <div class="mb-1.5 w-full atwho_dropdown">
              <AtTa
                v-model="editTask.text"
                :hover-select="true"
                :members="getActiveWorkspaceMembersName"
              >
                <textarea
                  v-model="editTask.text"
                  placeholder="Write a task..."
                  class="rounded-md w-full p-3 border border-gray-300 text-sm"
                  @keyup.ctrl.enter="updateTask(editTask)"
                />
              </AtTa>
            </div>
            <div class="flex justify-end items-center">
              <button
                class="
                  text-xs
                  focus:outline-none
                  rounded-md
                  text-black
                  border-0
                  px-3
                  py-1.5
                  mr-2
                "
                @click.prevent="resetFields"
              >
                <span>Cancel</span>
              </button>
              <button
                class="
                  text-xs
                  bg-cs-primary
                  focus:outline-none
                  rounded-md
                  text-white
                  border-0
                  px-3
                  py-1.5
                "
                @click.prevent="updateTask(editTask)"
              >
                <span class="text">Save</span>
              </button>

              <clip-loader
                v-if="loader.task"
                variant="info"
                class="spinner ml-2.5 mr-1"
                :color="'#9da6ac'"
                :size="'16px'"
              ></clip-loader>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import 'vue3-circle-progress/dist/circle-progress.css'
import CircleProgress from 'vue3-circle-progress'

import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import proxy from '@common/lib/http-common'
import {
  deleteTasksUrl,
  editTaskStatusUrl,
  saveTasksUrl,
} from '@src/modules/planner/config/api-utils'

import { mapGetters } from 'vuex'
import { pusherSocketPublish } from '@common/lib/pusher'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'

export default {
  name: 'Comments',
  components: {
    CircleProgress,
    CstSimpleCheckbox,
    AtTa,
  },
  mixins: [ComposerHelperMixin],
  props: {
    planId: {
      type: String,
      default: () => '',
    },
    tasksList: {
      type: Array,
      default: () => [],
    },
  },
  data: function data() {
    return {
      tasks: [...this.tasksList],
      task: {
        id: '',
        text: '',
        status: false,
        mentioned_user: [],
      },
      editTask: {
        id: '',
        text: '',
        status: false,
        mentioned_user: [],
      },
      loader: {
        task: false,
      },
      channel: '',
    }
  },
  computed: {
    ...mapGetters(['getWorkspaces', 'getActiveWorkspaceMembersName']),
    taskPercentage() {
      console.debug('Method:fetchCompletedTaskPercentage')
      let count = 0
      let percentage = 0
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].status) {
          count = count + 1
        }
      }
      if (count > 0) {
        percentage = Math.floor((count / this.tasks.length) * 100)
      } else {
        percentage = count
      }

      return percentage
    },
  },
  watch: {
    tasks: function (val) {
      this.$emit('handle-task-changes', val)
    },
  },

  mounted() {
    this.channel = pusherSocketPublish.subscribe(
      `tasks_${this.getActiveWorkspace._id}`
    )
    this.bindPusherPostTasks()
  },
  beforeUnmount() {
    if (this.channel) this.channel.unsubscribe()
    this.channel.unbind('store_task')
    this.channel.unbind('delete_task')
    this.channel.unbind('delete_all_task')
  },
  methods: {
    bindPusherPostTasks() {
      this.channel.bind('store_task', (data) => {
        console.log('composer post store tasks payload => ', data, this.$route)
        if (this.planId === data.plan_id) {
          const task = this.tasks.find((item) => item._id === data._id)
          if (!task) {
            this.tasks.unshift(data)
          } else {
            const taskIndex = this.tasks.findIndex(
              (item) => item._id === data._id
            )
            if (taskIndex >= 0) {
              this.tasks.splice(taskIndex, 1, data)
            }
          }
        }
      })
      this.channel.bind('delete_task', (data) => {
        console.log('composer post delete task payload => ', data)

        if (this.planId === data.plan_id) {
          const taskIndex = this.tasks.findIndex(
            (item) => item._id === data._id
          )
          if (taskIndex >= 0) {
            this.tasks.splice(taskIndex, 1)
          }
        }
      })
      this.channel.bind('delete_all_task', (data) => {
        console.log('composer post delete all task payload => ', data)
        if (this.planId === data.plan_id) {
          this.tasks = []
        }
      })
    },
    async changeTaskStatus(item) {
      console.log('changeTaskStatus => ', item)

      if (this.planId) {
        const payload = {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          id: item._id,
          status: item.status,
          plan_id: this.planId,
          type: 'composerSocial',
          title: item.title,
        }

        return proxy
          .post(editTaskStatusUrl, payload)
          .then((resp) => {
            return !!resp.data.status
          })
          .catch((error) => {
            console.log(error)
            return false
          })
      }
      return false
    },
    handleTask: function (taskId = null) {
      // disable button to save comment on this condition
      // that will prevent from save in case of enter + ctrl event
      if (!this.task.text) return false

      this.task.mentioned_user = this.getMentionedUsersIds(this.task.text)

      this.loader.task = true
      if (!this.planId || this.planId === '') {
        this.$emit('auto-save-plan', this.processTask, taskId)
      } else {
        this.processTask(this.planId, taskId)
      }
    },

    updateTask: function (task) {
      // disable button to save comment on this condition
      // that will prevent from save in case of enter + cntrl event
      if (!this.editTask.text) return false
      this.loader.task = true
      this.task.mentioned_user = this.getMentionedUsersIds(this.editTask.text)
      this.task.id = this.editTask.id
      this.task.text = this.editTask.text
      this.task.status = this.editTask.status
      this.processTask(this.planId, this.editTask.id)
    },

    async processTask(planId, taskId) {
      console.debug('processTask => ', planId, taskId)
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        plan_id: planId || this.planId,
        text: this.task.text.trim(),
        mentioned_users: this.task.mentioned_user,
        type: 'composerSocial',
        title: '',
        status: this.task.status,
      }

      if (taskId) {
        payload.id = taskId
      }

      return proxy
        .post(saveTasksUrl, payload)
        .then((resp) => {
          if (resp.data.status) {
            this.resetFields()
          }
          this.loader.task = false
        })
        .catch((error) => {
          console.log(error)
          this.loader.task = false
        })
    },

    async removeTask(item = null) {
      console.debug('method:remove task', item)
      if (this.planId) {
        let taskId = ''
        if (item && item._id) {
          taskId = item._id
        }
        const payload = {
          id: taskId,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          plan_id: this.planId,
        }

        return proxy
          .post(deleteTasksUrl, payload)
          .then((resp) => {
            if (resp.data.status) {
              if (taskId === '') {
                this.tasks = []
              }
              return true
            }
            return false
          })
          .catch((error) => {
            console.log(error)
            return false
          })
      }
      return false
    },
    resetFields: function () {
      this.task.id = this.editTask.id = ''
      this.task.text = this.editTask.text = ''
      this.task.status = this.editTask.status = false
      this.task.mentioned_user = []
    },

    setEditTask(task) {
      if (this.editTask.id && this.editTask.id === task._id) {
        this.resetFields()
        return
      }
      this.editTask.id = task._id
      this.editTask.text = task.text
      this.editTask.status = task.status
    },
  },
}
</script>

<style lang="scss">
.composer-comment-toolbar {
  .emoji_dropdown {
    margin-left: 0 !important;
  }

  i {
    font-size: 1rem !important;
  }
}
.task_box {
  &:hover {
    .edit_options {
      display: flex;
    }
  }
}
.mention {
  font-weight: 500;
  color: #4e92ff;
}
</style>
