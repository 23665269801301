import { ref, onMounted, onUnmounted } from 'vue'

const isMobile = ref(window.innerWidth >= 448)
const isLargeScreen = ref(window.innerWidth >= 1024)

export default function useViewPort() {
  onMounted(() => {
    const checkScreenSize = () => {
      isLargeScreen.value = window.innerWidth >= 1024
    }

    window.addEventListener('resize', checkScreenSize)

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize)
    })
  })

  return { isMobile, isLargeScreen }
}
