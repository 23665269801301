// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from '@src/modules/analytics_v3/composables/useCompetitorReport'

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'

export default function useDataTableMetrics() {
    const {
        fetchCompetitorInfo: fetchDataTableMetrics,
        apiResponse: dataTableMetricsResponse,
        loading: dataTableMetricsLoadingState,
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone, colors} = useCompetitorReport()

    const getDataTableMetrics = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonDataTableMetrics()
                break
            default:
                break
        }
    }
    const fetchCommonDataTableMetrics = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }
        try {
            await fetchDataTableMetrics(
                COMMON_API_ENDPOINTS.DATA_TABLE_METRICS,
                payload,
                COMMON_ERROR_MESSAGES.DATA_TABLE_METRICS
            )
            dataTableMetricsResponse.value = dataTableMetricsResponse.value.map(
                (item, index) => ({
                    ...item,
                    color: colors[index],
                })
            )
        } catch (error) {
        }
    }

    return {
        // data
        reportId,
        dataTableMetricsResponse,
        dataTableMetricsLoadingState,

        // methods
        getDataTableMetrics,
    }
}
