<template>
  <div
    class="social_post_side_filter"
    :class="{ open: toggleModalSidebarStatus }"
  >
    <div class="filter_inner">
      <div class="c_side_menu radiused_icon" @click="toggleModalSidebar">
        <i class="cs-angle-right"></i>
      </div>

      <template v-if="!isCSV">
        <Comments :class="{ hide: getSidebarStatus !== 'comment' }"></Comments>
        <Tasks
          v-if="getSidebarStatus === 'task'"
          :class="{ hide: getSidebarStatus !== 'task' }"
        ></Tasks>
      </template>
      <SocialPreview
        v-if="type === 'Social'"
        :class="{ hide: getSidebarStatus !== 'preview' }"
      ></SocialPreview>
      <PlanActivities
        v-if="getPublishSelection.plan_id"
        :class="{ hide: getSidebarStatus !== 'activity' }"
      ></PlanActivities>
      <seo v-if="type === 'Blog' && getSidebarStatus === 'seo'"></seo>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import seo from '@src/modules/discovery/components/share-sidebar/Seo'
import Comments from './collaboration/Comments'
import Tasks from './collaboration/Tasks'
import PlanActivities from './collaboration/Activities'
import SocialPreview from './social/previews/SocialPreview'
export default {
  components: {
    Comments,
    Tasks,
    SocialPreview,
    PlanActivities,
    seo,
  },
  /* eslint-disable vue/require-prop-types */
  props: ['type', 'isCSV'],
  data() {
    return {
      socialShareTabStatus: 'preview',
      toggleModalSidebarStatus: true,
    }
  },
  computed: {
    ...mapGetters(['getSidebarStatus', 'getPublishSelection']),
  },
  created() {
    this.setSidebarStatus('task')
  },

  methods: {
    ...mapActions(['setSidebarStatus']),
    socialShareTab(status) {
      this.socialShareTabStatus = status
    },
    toggleModalSidebar() {
      this.toggleModalSidebarStatus = !this.toggleModalSidebarStatus
    },
  },
}
</script>

<style lang="less">
.radiused_icon {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
