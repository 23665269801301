<template>
  <div
      id="help_dropdown_fixed"
      v-click-away="closeDropdownFixed"
      class=" fixed z-[1041]"
      :class="[isBlogOrSocialComposer ? 'right-0 bottom-0' : 'right-8 bottom-[1.4rem]']"
      @click.stop
  >
    <div class=" relative">

      <div
          class="flex align-items-center justify-content-center bg-black-400 hover:bg-black-500 transition-all ease-in-out text-center  border-solid border-1.5 border-white cursor-pointer shadow-sm"
          :class="[isBlogOrSocialComposer ? 'w-14 h-14' : 'rounded-full w-16 h-16']"
          @click.prevent="helpScoutChat ? closeHelpChat() : toggleDropdownFixed()"
      >
        <i v-if="helpScoutChat || toggleDropdownFixedStatus" class="cs-cross text-xl text-white"></i>
        <i v-else class="cs-question-mark text-xl text-white"></i>
      </div>

      <div
          :class="[toggleDropdownFixedStatus ? 'visible' : 'hidden', isBlogOrSocialComposer ? 'bottom-14' : 'bottom-16']"
          style="min-width: 12.625rem"
          class=" absolute right-4 bg-white rounded drop-shadow-sm border border-solid border-gray-50 p-2"
      >
        <ul class=" text-gray-900 hover:text-black-900 z-1050 ">
          <li v-if="!isLifetimePlan" class="">
            <a
                href="https://contentstudio.io/book-a-demo"
                target="_blank"
                class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out hover:bg-blue-50"
            >

              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/book-a-demo.svg" alt="book-a-demo"/>
              </div>
              <div class="text-sm">Schedule a Demo</div>
            </a>
          </li>
          <li>
            <a
                href="https://contentstudio.frill.co/b/90pqo1vj/feature-requests"
                target="_blank"
                class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/request-feature.svg" alt=""/>
              </div>
              <div class="text-sm">Request a Feature</div>
            </a>
          </li>
          <li>
            <a
                href="https://www.youtube.com/c/ContentStudioContentmarketing/videos"
                target="_blank"
                class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/video-tutorials.svg" alt="video-tutorials"/>
              </div>
              <div class="text-sm">Video Tutorials</div>
            </a>
          </li>
          <li>
            <a
                href="https://docs.contentstudio.io/"
                target="_blank"
                class="text-gray-900 hover:text-black-900 flex py-2 transition-all ease-in-out hover:bg-blue-50"
            >
              <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                <img src="@src/assets/img/icons/help-widget/help-docs.svg" alt="help-docs"/>
              </div>
              <div class="text-sm">Help Docs</div>
            </a>

          </li>
          <li
class="text-gray-900 hover:text-black-900 flex py-2 cursor-pointer hover:bg-blue-50"
              @click.prevent="openHelpChat">
            <div class="w-5 h-5 pl-2 mr-4 flex items-center">
              <img src="@src/assets/img/icons/help-widget/contact-support.svg" alt="contact-support"/>
            </div>
            <div>Contact Support</div>

          </li>

          <li>
            <a
                href="https://status.contentstudio.io/"
                target="_blank"
                class="text-gray-900 hover:text-black-900 flex flex-col py-2 transition-all ease-in-out hover:bg-blue-50"
            >
              <div class="flex">
                <div class="w-5 h-5 pl-2 mr-4 flex items-center">
                  <img src="@src/assets/img/icons/help-widget/status.svg" alt="status"/>
                </div>
                <div class="text-sm">
                  ContentStudio Status

                </div>
              </div>


              <small
                  v-if="appStatus.is_down"
                  class="small_text-variant-1 ml-8 mt-1"
                  :style="{ color: appStatus.color }"
              >
                <i class="fad fa-scrubber inner-icon"></i> Some Systems
                Degraded</small
              >
              <small
                  v-else
                  class="small_text-variant-1 ml-8 mt-1"
                  :style="{ color: appStatus.color }"
              ><i class="fad fa-scrubber inner-icon"></i> All Systems
                Operational</small
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {EventBus} from '@common/lib/event-bus'
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";

export default {
  setup(){
    const {isComposerModalOpen} = useComposerHelper()

    return {
      isComposerModalOpen
    }
  },
  data() {
    return {
      toggleDropdownFixedStatus: false,
      helpScoutChat: false,
    }
  },
  computed: {
    ...mapGetters(['getAppStatus']),
    appStatus() {
      if (this.getAppStatus.is_down !== undefined) {
        if (this.getAppStatus.is_down) return {is_down: true, color: '#e67e22'}
        return {is_down: false, color: '#6dd123'}
      } else {
        return {is_down: false, color: '#6dd123'}
      }
    },
    isBlogComposer() {
      return this.$route?.name === 'composerBlog'
    },
    isBlogOrSocialComposer() {
      return this.isBlogComposer || this.isComposerModalOpen
    },
  },
  mounted() {
    console.debug('Status', this.getAppStatus)
    EventBus.$on('open-help-widget', () => {
      Beacon('open')
      this.helpScoutChat = true
    })

    Beacon('on', 'close', () => {this.helpScoutChat = false})

  },
  beforeUnmount() {
    EventBus.$off('open-help-widget')
    Beacon('off', 'close')
  },
  methods: {
    isLifetimePlan() {
      const plan = this.$store.getters.getPlan.subscription
      if (plan.display_name || plan.fastspring_name) {
        if (plan.display_name.includes('Lifetime')) return true
        if (plan.fastspring_name.includes('lifetime')) return true
        if (
            plan.fastspring_name.includes('jvzoo') ||
            plan.fastspring_name.includes('appsumo')
        )
          return true
      }
      return false
    },
    toggleDropdownFixed() {
      this.toggleDropdownFixedStatus = !this.toggleDropdownFixedStatus
    },
    closeDropdownFixed(e) {
      const target = e.target

      // check if the target is not #help_dropdown_fixed or its child or nested child (without using the closest method)
      if (
          target &&
          target.id !== 'help_dropdown_fixed' &&
          target.parentElement?.id !== 'help_dropdown_fixed' &&
          target.parentElement?.parentElement?.id !== 'help_dropdown_fixed'
      ) {
        this.toggleDropdownFixedStatus = false
      }

    },


    // closeToggleDropdownFixed () {
    //   // this.toggleDropdownFixedStatus = false
    // },
    openHelpChat() {
      Beacon('open')
      this.helpScoutChat = true
    },
    closeHelpChat() {
      Beacon('close')
      this.helpScoutChat = false
    },
  },
}
</script>

<style scoped></style>
