<template>
  <ConfirmBox
    id="replaceCurrentTypeConfirmation"
    :call-back-action="replaceSharingImageConfirmation"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>You can only upload one type of file.</h2>
    </template>

    <template v-slot:footer>
      <p>Do you want to replace the current attachments?</p>
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
