<template>
  <div class="feeds-content-pane">
    <!--for topics -- remove d-none class .......-->
    <div class="feeds-content-pane__section" style="padding: 0; border-top: 0">
      <template v-if="load_posts">
        <div class="pt-3">
          <beat-loader :color="'#436aff'"> </beat-loader>
        </div>
      </template>
      <template v-else>
        <!-- for today and trending stories, we do not show the header. -->
        <div
          v-if="!isCollectiveCollectionType"
          class="feeds-content-pane__section__header"
        >
          <div class="feeds-content-pane__section__header__content">
            <ContentName :title="feed_type.title"></ContentName>
          </div>
          <div class="feeds-content-pane__section__header__filters">
            <ContentTotalResults
              :total="total_results"
            ></ContentTotalResults>
          </div>
        </div>
        <template v-if="isCollectiveCollectionType">
          <FeederContentViewSlot type="main">
            <template v-if="collection_results.loader">
              <div class="pt-3">
                <beat-loader :color="'#436aff'"> </beat-loader>
              </div>
            </template>
            <template v-else>
              <div class="topics-cover-stories">
                <template
                  v-if="
                    $route.params.content_type === 'today' &&
                    hasCollectionItemsWithPosts > 0
                  "
                >
                  <h2 class="topics-cover-stories__heading"
                    ><span>Cover Stories For Today</span></h2
                  >
                </template>
                <template
                  v-if="
                    $route.params.content_type === 'trending' &&
                    hasCollectionItemsWithPosts > 0
                  "
                >
                  <h2 class="topics-cover-stories__heading"
                    ><span>Trending Stories</span></h2
                  >
                </template>

                <template v-if="hasCollectionItemsWithPosts === 0">
                  <div class="no_data_found_content">
                    <content-available-state :type="'no_results'">
                      <h2 slot="headline">No results are available</h2>
                      <p slot="text"
                        >There are no stories available for your followed feeds
                        from {{ $route.params.content_type }}!</p
                      >
                    </content-available-state>
                  </div>
                </template>
                <!-- collective results , top 6 posts from the view -->
                <template
                    v-for="(collection, index) in getSortedCollectionItemsList"
                    :key="index"
                >
                  <template
                      v-if="
                    collection.posts &&
                    collection.posts.length
                  "
                  >
                    <div>
                      <div
                          class="d-flex justify_space_between align-items-center"
                      >
                        <h3 class="topics-cover-stories__subheading"
                        ><span>{{ collection.group.name }}</span></h3
                        >
                        <router-link
                            style="position: relative"
                            class="mr-4"
                            :to="{
                          name: 'feeder_collection',
                          params: { collection_id: collection.group.uuid },
                        }"
                        >
                          View more from this group
                        </router-link>
                      </div>
                      <div class="topics-cover-stories-container">
                        <template
                            v-for="(post, postIndex) in collection.posts"
                            :key="post._id"
                        >
                          <FeederCoverItem
                              v-if="collection.posts.length > 0"
                              :index="postIndex"
                              :collection_uuid="collection.group.uuid"
                              :post="post"
                          ></FeederCoverItem>
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </FeederContentViewSlot>
        </template>

        <template v-else>
          <FeederContentViewSlot>
            <!--<transition-group :name="slide_item_animation" tag="div" >-->
            <template v-for="(post, index) in posts" :key="index">
              <div
                v-if="getFeederPreferences.item_style === 'grid'"
                class="feed-grid-view-container"
              >
                <FeederGridViewItem
                  :post="post"
                  :index="index"
                  :is-archive="isArchiveView"
                  :route_name="'aggregate_feed_item'"
                >
                </FeederGridViewItem>
              </div>
              <div
                v-if="getFeederPreferences.item_style === 'list'"
                class="article_box_list"
              >
                <FeederListViewItem
                  :post="post"
                  :index="index"
                  :is-starred="isArchiveView"
                  :is-archive="isArchiveView"
                  :route_name="'aggregate_feed_item'"
                ></FeederListViewItem>
              </div>
            </template>
            <!--</transition-group>-->
            <InfiniteLoading
              ref="infiniteAggregateView"
              :identifier="infiniteId"
              @infinite="feederAggregateViewHandler"
            >

              <template slot="spinner">
                <span class="d-block pt-3 pb-4">
                <beat-loader :color="'#436aff'"></beat-loader>
              </span>
              </template>

              <template v-slot:no-more>
                <div class="not_found_text">
                  <div v-if="total_results === 0" class="no_data_found_content">
                    <content-available-state :type="'no_results'">
                      <h2 slot="headline">No Results Found</h2>
                      <p slot="text"
                      >No more results found for your query. Please try a
                        different search string or change search filters.</p
                      >
                    </content-available-state>
                  </div>
                </div>
              </template>

              <template v-slot:no-results>
                <div>
                  <div v-if="total_results === 0" class="no_data_found_content">
                    <template v-if="isFeederStarredType">
                      <content-available-state :type="'starred'">
                        <h2 slot="headline">No Starred Posts Found</h2>
                        <p slot="text">You have not starred any posts yet.</p>
                      </content-available-state>
                    </template>
                    <template v-else-if="isFeederArchivedType">
                      <content-available-state :type="'archived'">
                        <h2 slot="headline">No Archived Posts Found</h2>
                        <p slot="text">You have not archived any posts yet.</p>
                      </content-available-state>
                    </template>
                    <template v-else>
                      <content-available-state :type="'no_results'">
                        <h2 slot="headline">No Results Found</h2>
                        <p slot="text"
                        >No posts are available for your selected feed.</p
                        >
                      </content-available-state>
                    </template>
                  </div>
                </div>
              </template>

            </InfiniteLoading>
          </FeederContentViewSlot>
        </template>
      </template>
    </div>
    <!-- for modals like social share, article preview etc. -->
    <router-view></router-view>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import axios from 'axios'
import http from '@common/lib/http-common'
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import {EventBus} from "@common/lib/event-bus";
import { feederItemMixin } from '@src/modules/discovery/components/feeder/mixins/feeder-item-mixin.js'
import { feederGroupURI } from '@src/modules/discovery/config/api-utils.js'
import ContentName from '../components/content-view/ContentName'
import ContentTotalResults from '../components/content-view/ContentTotalResults'
import FeederGridViewItem from '../components/content-view/type-of-views/FeederGridViewItem'
import FeederCoverItem from '../components/content-view/type-of-views/FeederCoverItem'
import FeederContentViewSlot from '../slots/FeederContentViewSlot'
import NoResultsFound from "@src/modules/discovery/components/topics/NoResultsFound.vue";

const FeederListViewItem = () =>
  import('../components/content-view/type-of-views/FeederListViewItem')

export default {
  components: {
    NoResultsFound,
    ContentName,
    ContentTotalResults,
    FeederGridViewItem,
    FeederContentViewSlot,
    FeederCoverItem,
    InfiniteLoading,
    FeederListViewItem,
  },
  mixins: [feederItemMixin],
  data () {
    return {
      slide_item_animation: '',
      load_posts: false,
      feed_type: {},
      posts: [],
      total_results: 0,
      state: '',
      infiniteState: '',
      infiniteId: +new Date(),
      page: 1,
      collection_results: {
        items: [],
        loader: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFeederGroupList',
      'getActiveWorkspace',
      'getFeederPreferences'
    ]),
    isArchiveView () {
      return (
          this.$route.params.content_type &&
          this.$route.params.content_type === 'archived'
      )
    },
    isFeederArchivedType () {
      return this.$route.params.content_type === 'archived'
    },
    isFeederStarredType () {
      return this.$route.params.content_type === 'starred'
    },
    isCollectiveCollectionType () {
      return (
          this.$route.params.content_type === 'trending' ||
          this.$route.params.content_type === 'today'
      )
    },
    getSortedCollectionItemsList () {
      return sortBy(this.collection_results.items, function (item) {
        console.log(item)
        return [item.group.name.toLowerCase()]
      })
    },
    hasCollectionItemsWithPosts () {
      let count = 0
      this.collection_results.items.forEach((item) => {
        if (item.posts && item.posts.length) {
          count += item.posts.length
        }
      })
      return count
    }
  },
  watch: {
    '$route.params.content_type' (value) {
      this.feedAggregateContent()
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
      // only for the trending and curated topics

      if (this.isCollectiveCollectionType) {
        this.feederAggregateCollectionViewHandler()
      }
    },
    async 'getActiveWorkspace._id' (value) {
      // if changes the workspace, and on the today route. Get the data.
      if (this.$route.params.content_type === 'today') {
        // edge case, the data has not loaded yet for the feeder groups, therefore showing the loader and getting the groups.
        this.collection_results.loader = true
        const res = await this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
        await this.feederAggregateCollectionViewHandler()
      }
    }
  },
  created () {
    this.feedAggregateContent()
    if (this.isCollectiveCollectionType) {
      this.feederAggregateCollectionViewHandler()
    }
  },
  mounted () {
    EventBus.$on('feeder_archive_action', async (value) => {
      console.debug('feeder_archive_action', value)
      const res = await this.archiveAction(
        value.record_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
        if (this.isCollectiveCollectionType) {
          this.collection_results.items.forEach((item) => {
            if (item.posts && item.posts && item.posts.length) {
              const findPostIndex = item.posts.findIndex(
                (post) => post.record_id === value.record_id
              )
              item.posts.splice(findPostIndex, 1)
            }
            console.log(item)
          })
        }
      }
    })

    EventBus.$on('feeder_unarchive_action', async (value) => {
      console.debug('feeder_unarchive_action', value)
      const res = await this.archiveAction(
        value.record_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
      }
    })

    EventBus.$on('feeder_starred_action', async (value) => {
      console.log('feeder_starred_action', value)
      const res = await this.starredAction(
        value.record_id,
        value.index,
        value.state,
        this.posts
      )
      console.log('feeder aggregate view starred action', res)

      if (res) {
        // edge case, only removes if on the starred view.
        if (this.isFeederStarredType) {
          this.posts.splice(
            this.posts.findIndex((item) => value.record_id === item.record_id),
            1
          )
          this.total_results -= 1
        } else {
        }
      }

      // edge case for the cover stories for today and trending
      console.log(
        'feeder aggregate view starred action',
        value,
        this.isCollectiveCollectionType
      )

      if (this.isCollectiveCollectionType) {
        this.collection_results.items.forEach((item) => {
          if (item.posts && item.posts && item.posts.length) {
            item.posts.forEach((post) => {
              console.log(
                'feeder aggregate view starred action',
                post.record_id,
                value.record_id
              )
              if (post.record_id === value.record_id) {
                post.is_starred = value.state === 'starred'
              }
            })
          }
          console.log(item)
        })
      }
    })

    EventBus.$on('feeder_starred_is_collection', async (value) => {
      console.log('feeder_starred_is_collection', value)
      this.$store
        .dispatch('feederStarredPost', {
          record_id: value.record_id,
          state: value.state
        })
        .then((res) => {
          if (res.data.status) {
            console.log('feeder_starred searching for group')
            const group = this.collection_results.items.find(
              (item) => item.group.uuid === value.group_id
            )
            console.log('feeder_starred found the group', group)
            if (group) {
              group.posts.forEach((postItem) => {
                console.log('feeder_starred group post item', postItem)
                if (postItem.record_id === value.record_id) {
                  postItem.is_starred = value.state === 'starred'
                }
              })
            }
          }
        })
        .catch((err) => {})
    })
  },
  beforeUnmount () {
    EventBus.$off('feeder_unarchive_action')
    EventBus.$off('feeder_archive_action')
    EventBus.$off('feeder_starred_action')
    EventBus.$off('feeder_starred_is_collection')
  },
  methods: {
    feederAggregateViewHandler ($state) {
      // send an individual request in case if the content type is of starred, archived etc.
      const p = this.$store.dispatch(
        'fetchFeederAggregate',
        this.$route.params.content_type
      )
      p.then((res) => {
        if (res.data.status) {
          this.total_results = res.data.posts.length
          this.posts = res.data.posts
        }
        $state.complete()
      })
        .catch()
        .finally(() => {})
    },

    async feederAggregateCollectionViewHandler () {
      this.collection_results.loader = true
      this.collection_results.items = []
      const listOfRequests = []
      this.getFeederGroupList.forEach((item) => {
        const request = http.post(feederGroupURI + 'retrieve_by_id', {
          workspace_id: this.getActiveWorkspace._id,
          group_id: item.uuid,
          page: 1,
          limit: 4,
          state: this.$route.params.content_type
        })
        listOfRequests.push(request)
      })
      await axios
        .all(listOfRequests)
        .then((responses) => {
          console.log('list of batch requests responses =>', responses)
          responses.forEach((response) => {
            if (response.data.status) {
              this.collection_results.items.push({
                group:response.data.group,
                posts:response.data.posts
              })
            }
          })
          // use/access the results
        })
        .catch((errors) => {
          console.log('list of batch requests responses =>', errors)

          // react on errors.
        })
      this.collection_results.loader = false
    },

    feedAggregateContent () {
      this.load_posts = true
      switch (this.$route.params.content_type) {
        case 'today':
          this.feed_type.title = 'Today'
          break
        case 'starred':
          this.feed_type.title = 'Starred'
          break
        case 'unread':
          this.feed_type.title = 'Unread'
          break
        case 'saved':
          this.feed_type.title = 'Read Later'
          break
        case 'trending':
          this.feed_type.title = 'Trending'
          break
        case 'archived':
          this.feed_type.title = 'Archived'
          break
      }
      document.title = this.feed_type.title
      this.load_posts = false
    }
  },
}
</script>
