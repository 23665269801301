<template>
  <b-modal
    id="workspace-locked-billing-modal"
    size="xl"
    :modal-class="'instaConnect modal fade normal_modal'"
    dialog-class="max_width_for_modal_1000"
    hide-header
    hide-footer
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Workspace Locked</h2>
      <button
        v-tooltip.bottom="'Close'"
        type="button"
        class="close button-custom"
        data-dismiss="modal"
        @click="$bvModal.hide('workspace-locked-billing-modal')"
      >
        &times;
      </button>
    </div>
    <div class="modal-content">
      <div class="modal_body basic_form m_t_15">
        <div class="flex-row">
          <div class="col-12 text-center container_for_not_super_admin">
            <h1>{{ getWorkspaceLockMessage(workspace) }}</h1>
            <br />
            <p>
              Please notify the account owner
              <b>{{ getSuperAdminDetails().email }}</b> to settle this account
              to resume full access to the workspace.
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SubscriptionLimitContainer from './reusable/SubscriptionLimitContainer'

export default {
  components: {
    SubscriptionLimitContainer,
  },
  props: ['workspace'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getProfile', 'getWorkspaces']),
  },
  mounted() {},
  methods: {
    ...mapActions([]),
    getSuperAdminDetails() {
      const workspaceDetails = this.workspace.workspace
      const currentWorkspace = this.getWorkspaces.items.filter(
        (item) =>
          item.workspace._id.toLowerCase().indexOf(workspaceDetails._id) >= 0
      )[0]

      const teamMembers = workspaceDetails.members

      console.log('getSuperAdminDetails', {
        workspaceDetails,
        currentWorkspace,
        teamMembers,
      })
      if (teamMembers) {
        for (let i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (member.role === 'super_admin') {
            return member.user
          }
        }
      }

      return null
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.container_for_not_super_admin {
  padding: 4em 3em !important;
}
.center_both_blocks {
  display: flex !important;
}
.center_child {
  flex: 1;
}
.text_block_container {
  text-align: start;
  margin-top: 10px;
}
</style>
