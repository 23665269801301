<template>
  <div class="discovery_analytic_component">
    <div class="component_inner">
      <div class="analytic_verification">
        <!--show if review submitted-->
        <div
          v-if="review_submitted"
          class="analytic_verification_inner unlock_block"
        >
          <img
            style="width: 200px"
            src="../../../../../assets/img/common/unblock_post.svg"
          />
          <h2>Thank you for submitting the review</h2>
          <p
            >After we verify your review, this page will be automatically
            unlocked within the next 24-48 hours.</p
          >
        </div>

        <!--if no review added yet -->
        <div v-if="!review_submitted" class="analytic_verification_inner">
          <div class="head_text">
            <h2
              >Insights is a new tool that we have added recently and we are
              giving it away for FREE to our lifetime users.</h2
            >
            <p
              >But we need a small favor from you to UNLOCK this tool for
              you.</p
            >
          </div>
          <div
            class="step_box"
            :class="{ 'input-field-error': validations.service }"
          >
            <h2>Step 1</h2>
            <p
              >Please write a few words about ContentStudio on any of the below
              mentioned website.</p
            >
            <div class="web_list">
              <li :class="{ completed: review.service === 'capterra' }">
                <div
                  class="img"
                  :class="{ active_border_img: review.service === 'capterra' }"
                  @click.prevent="selectReviewWebsite('capterra')"
                >
                  <img
                    class="w-32"
                    src="../../../../../assets/img/common/capterra_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://trk.contentstudio.io/cs-app-review-capterra"
                  >Write on Capterra</a
                >
              </li>
              <li
                :class="{
                  completed: review.service === 'g2crowd',
                }"
              >
                <div
                  class="img"
                  :class="{ active_border_img: review.service === 'g2crowd' }"
                  @click.prevent="selectReviewWebsite('g2crowd')"
                >
                  <img
                    class="w-32"
                    src="../../../../../assets/img/common/crowd_logo.png"
                  />
                </div>
                <a
                  target="_blank"
                  class="d-block mt-2"
                  href="https://rplg.co/cs-app-g2crowd-review"
                  >Write on G2Crowd</a
                >
              </li>
            </div>
          </div>
          <span v-if="validations.service" class="input-error"
            >Please select the service where you have given the review.</span
          >
          <div class="step_box basic_form">
            <h2>Step 2</h2>
            <p
              >Once you have submitted your review, please send us a message
              below with a proof to verify that you’ve actually submitted the
              review.</p
            >
            <p class="sub_text"
              >Add a link to the review post that you’ve submitted and/or send
              us a screenshot or a video proof using useloom.com/screencast
              etc.</p
            >

            <div
              class="text_block input_field"
              :class="{ 'input-field-error': validations.message }"
            >
              <textarea
                id=""
                v-model="review.message"
                name=""
                cols="30"
                rows="5"
                placeholder="Type here.."
              ></textarea>
            </div>
            <span v-if="validations.message" class="input-error"
              >Please enter the review link or screenshot.</span
            >
          </div>
          <div class="btn_block text-center">
            <button
              class="btn btn_round large_btn"
              @click.prevent="submitReview"
              >Submit</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@common/lib/http-common'
import { unlockInsightsURL } from '@src/modules/discovery/config/api-utils'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      review_submitted: false,
      review: {
        service: '',
        message: '',
      },
      validations: {
        service: false,
        message: false,
      },
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
  },
  watch: {
    'review.service'(value) {
      if (value) this.validations.service = false
    },
    'review.message'(value) {
      if (value) this.validations.message = false
    },
    'getActiveWorkspace._id'(value) {
      this.$router.push({ name: 'discover_search' })
    },
  },
  methods: {
    selectReviewWebsite(service) {
      /* if (service === 'g2crowd') {
                        window.open('https://rplg.co/cs-app-g2crowd-review', '_blank')
                      } else if (service === 'capterra') {
                        window.open('https://rplg.co/cs-app-review-capterra', '_blank')
                      } */
      this.review.service = service
    },
    submitReview() {
      this.validations.service = this.requiredCheck(this.review.service)
      this.validations.message = this.requiredCheck(this.review.message)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        http
          .post(unlockInsightsURL, { review: this.review })
          .then((res) => {
            if (res.data.status) {
              this.review_submitted = true
              this.$store.dispatch('toastNotification', {
                message: res.data.message,
              })
            } else {
              this.$store.dispatch('toastNotification', {
                message: res.data.message,
                type: 'error',
              })
            }
          })
          .catch()
          .finally()
      }
    },
  },
}
</script>

<style>
.active_border_img {
  border: 1px solid #4165ed !important;
}
</style>
