<template>
    <CompetitorDummyGraphs
            v-if="isCompetitorAnalyticsEnabled !== undefined"
            :instagram-main-info="dummyData.instagramAnalyticsMainInfo"
            :all-graphs-data="dummyData.allTypesGraphData"
            :chart-media-type="dummyData.graphMediaType"
            :followers-growth-chart-data="dummyData.followersGrowthGraphData"
            :posting-types-table-data="dummyData.postingActivityTableData"
            :top-least-posts-data="dummyData.topAndLeastPostsData"
            :most-engaging-hashtags="dummyData.mostEngagedHashtags"
            :bio-analysis-table-data="dummyData.bioAnalysisData"
            :specific-type-chart-data="dummyData.specificTypeGraphData"
    />
</template>

<script>
import dummyData from '@src/modules/analytics_v3/composables/CompetitorDummyData.js'
import {inject, onBeforeMount, watch} from 'vue'
import CompetitorDummyGraphs from '@src/modules/analytics_v3/views/common/CompetitorDummyGraphs.vue'

export default {
    name: 'CompetitorAnalyticsLanding',
    components: {CompetitorDummyGraphs},
    props: {
        isCompetitorAnalyticsEnabled: {
            type: [Boolean, undefined],
            default: undefined,
        },
    },
    setup(props) {
        const root = inject('root')
        const {$router} = root
        onBeforeMount(() => {
            // router in component guard not used due to vue router migration
            if (props.isCompetitorAnalyticsEnabled) {
                $router.push({name: 'instagram_competitor_overview_v3'})
            }
        })
        watch(
            () => props.isCompetitorAnalyticsEnabled,
            (newVal) => {
              // Retrieve the value from local storage
              const storedRoute = localStorage.getItem('defaultCompetitorRoute')
              // Check if the storedRoute is not null or empty before using it
              if (newVal && storedRoute) {
                $router.push({ name: storedRoute });
              }
            }
        )
        return {
            dummyData
        }
    },
}
</script>
