<template>
  <div>
    <div class="media-tab__header">
      Type or Paste {{ title }} Image Post URL
    </div>
    <div class="media-tab__body">
      <form @submit.prevent="fetchMicroDetails">
        <div class="simple-form__text-field mt-3">
          <label for="url">
            <i class="icon-Search"></i>
          </label>
          <input
            id="url"
            v-model="link"
            type="url"
            placeholder="Type or Paste Media URL"
            :disabled="isUploading || isFetching"
          />

          <button
            v-if="link.trim().length > 0"
            class="btn btn-studio-theme-transparent-grey btn-size-small add-btn"
            type="submit"
            :disabled="isUploading || isFetching"
          >
            Fetch
          </button>
        </div>
      </form>

      <div v-if="isFetching" class="mt-3">
        <clip-loader
          class="spinner"
          :color="'#9da6ac'"
          :size="'20px'"
        ></clip-loader>
      </div>
      <template v-else>
        <div
          v-if="fetchedImage !== null && fetchedImage.length === 0"
          class="text-center"
        >
          <i>Image Not Found, Try Different Url!</i>
        </div>
        <div v-else-if="fetchedImage !== null" class="text-center">
          <img class="img-fluid" :src="fetchedImage" alt="" />
        </div>
      </template>
    </div>

    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>

          <TreeSelect
            v-model="selectedFolderValue"
            :normalizer="normalizer"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
          />
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          ml-2
        "
      >
        <button
          class="btn btn-studio-theme-space"
          :disabled="
            isUploading ||
            (fetchedImage !== null && fetchedImage.length === 0) ||
            fetchedImage === null
          "
          @click="uploadFiles"
        >
          Upload
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import { MediaHelperMixin } from '../../utils/MediaHelpers'
export default {
  name: 'FetchMediaUrlTab',
  mixins: [MediaHelperMixin],
  props: {
    type: {
      default: '',
    },
    kind: {
      default: '',
    },
    folders: {},
  },
  data() {
    return {
      selectedFolderValue: 'uncategorized',
      isUploading: false,
      isFetching: false,
      link: '',
      fetchedImage: null,
    }
  },
  computed: {
    title() {
      switch (this.kind) {
        case 'pinterest': {
          return 'Pinterest'
        }
        case 'facebook': {
          return 'Facebook'
        }
        case 'flickr': {
          return 'Flickr'
        }
        default: {
          return ''
        }
      }
    },
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id + '-root',
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
  },
  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },
  mounted() {
    if (!isEmpty(this.$route.query.type)) {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        let folderValue = this.$route.query.folderId

        if (this.$route.query.root) {
          folderValue = folderValue + '-root'
        }

        this.selectedFolderValue = folderValue
      }
    }
  },
  methods: {
    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    async uploadFiles() {
      this.isUploading = true

      let folderId = null
      let isRoot = false

      const filters = {
        link: [this.fetchedImage],
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadLinksFilesHelper(filters, (status, message) => {
        if (status) {
          this.link = ''
          this.isUploading = false
          if (this.type === 'library') {
            this.$bvModal.hide('upload-media-modal')
            EventBus.$emit('refetch-folders')
            EventBus.$emit('refetch-media', { folderId, isRoot })
            EventBus.$emit('refetch-media-limits')
          } else {
            this.$emit('changeTab', 1)
          }
        }
      })
      this.isUploading = false
    },

    async fetchMicroDetails() {
      this.isFetching = true
      await this.fetchMediaMicroLinkHelper(
        {
          link: this.link,
          type: this.kind,
        },
        (status, data) => {
          console.debug(status, data)
          this.fetchedImage = ''
          if (status && data.image) {
            this.fetchedImage = data.image.url
          }
        }
      )
      this.isFetching = false
    },
  },
}
</script>
