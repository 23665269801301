<template>
  <div
    v-click-away="closeSearchDropdown"
    class="dropdown-search-suggestions article-search-bar-pane__search__input__suggestions"
  >
    <div v-if="keywords.length > 0" class="search_dropdown_inner">
      <div class="article-search-bar-pane__search__input__suggestions__section">
        <div class="text"> Related Keywords </div>
      </div>
      <ul v-if="keywords.length > 0">
        <template v-for="(value, relatedKeywordsIndex) in keywords" :key="`relatedKeywordsIndex_${relatedKeywordsIndex}`">
          <template v-if="relatedKeywordsIndex <= 4">
            <li
                :class="{
              active:
                search_key_input.selector === 'search_autocomplete' &&
                value === search_key_input.key,
            }"
                class="item"
                @click.prevent="
              routeSearchQueryFromAutocomplete(value.toLowerCase())
            "
            ><i
                class="icon-Search article-search-bar-pane__search__input__suggestions__icon"
            ></i>
              <span v-html="getSuggestionItemValue(value)"></span>
            </li>
          </template>
        </template>
      </ul>
      <!-- showing related topics only in case of the search and insights -->
      <template v-if="getShowRelatedTopicsSuggestions">
        <div
          v-if="topics.length > 0"
          class="article-search-bar-pane__search__input__suggestions__section"
        >
          <div class="text"> Related Topics </div>
        </div>
        <ul v-if="topics.length > 0">
          <template v-for="(value, relatedTopicsIndex) in topics" :key="`relatedTopicsIndex_${relatedTopicsIndex}`">
            <li
              class="item"
              @click.prevent="routeSearchTagFromAutocomplete(value.name)"
            >
              <i
                class="far fa-folder-tree article-search-bar-pane__search__input__suggestions__icon"
              ></i>
              <span v-html="getSuggestionItemValue(value.name)"> </span>
            </li>
            <!--                                                <li @click.prevent="searchByKeyword(value)" class="item">{{value}}</li>-->
          </template>
        </ul>
      </template>

      <!--<li class="head">Related Sites</li>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'

export default {
  props: {
    topics: {
      type: Array,
      default: () => []
    },
    keywords: {
      type: Array,
      default: () => []
    },
    search_key_input: {
      type: Object,
      default: () => {}
    },
    query: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([]),
    getShowRelatedTopicsSuggestions () {
      return (
        this.$route.meta.is_active &&
        ['content', 'insights'].indexOf(this.$route.meta.is_active) >= 0
      )
    }
  },
  methods: {
    getSuggestionItemValue (value) {
      if (this.query && this.query.trim().length > 0) {
        if (value.includes(this.query)) {
          value = value.replace(this.query, `<strong>${this.query}</strong>`)
          return value
        }
      }
      return value
    },
    closeSearchDropdown () {
      EventBus.$emit('show-suggestions')
    },
    routeSearchQueryFromAutocomplete (value) {
      if (
        this.$route.name === 'discover_search' ||
        this.$route.name === 'discover_insights'
      ) {
        return this.$router.push({
          name: `${this.$route.name}_web`,
          query: { q: value }
        })
      } else {
        this.$router.replace({ query: { q: value.toLowerCase() } })
      }
      EventBus.$emit('show-suggestions')
    },
    routeSearchTagFromAutocomplete (value) {
      if (
        this.$route.name === 'discover_search' ||
        this.$route.name === 'discover_insights'
      ) {
        return this.$router.push({
          name: `${this.$route.name}_web`,
          query: { tag: value }
        })
      } else {
        this.$router.replace({ query: { tag: value } })
      }
      EventBus.$emit('show-suggestions')
    }
  }
}
</script>
