import { plannerBaseUrl } from '@src/config/api-utils'
import proxy from '@common/lib/http-common'
import {useStore} from "vuex";
export const ACTION_TYPES = {
    GET_LINK: 'GET_LINK',
    UPDATE_LINK: 'UPDATE_LINK',
    DELETE_LINK: 'DELETE_LINK',
    GET_LINK_SECURE_STATUS: 'GET_LINK_SECURE_STATUS',
}
/**
 * Service to create a share link
 * @param action  - action to be performed
 * @param {object} payload - payload to be sent
 */
