<template>
  <b-modal
    id="Card-info-dialog"
    modal-class="normal_modal bv-modal-theme-default bv-modal-theme-card-info-dialog"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <div class="modal-heading-with-text">
        <h2>Card Info</h2>
      </div>
      <b-button
        variant="studio-theme-grey-icon"
        class="mr-4"
        @click="$bvModal.hide('Card-info-dialog')"
      >
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <div class="modal_body basic_form">
      <template v-if="paymentInfo?.payment_method === 'card'">
        <div class="relative m-auto h-48 w-80 rounded-xl bg-gradient-to-r from-gray-900 to-gray-600 text-white shadow-2xl transition-transform sm:h-56 sm:w-96 sm:hover:scale-110">
        <div class="absolute top-4 w-full px-8 sm:top-8">
          <div class="flex justify-between">
            <div class="">
              <p class="font-light">Card Number</p>
              <p class="font-medium tracking-widest">{{ text + paymentInfo?.last_four_digits }}</p>
            </div>
            <div class="font-extrabold text-xl uppercase"><em>{{ paymentInfo?.card_type }}</em></div>
          </div>
          <div class="pt-4 pr-6 sm:pt-6">
            <div class="flex justify-between">
              <div class="">
                <p class="text-xs font-light">Expiry</p>
                <p class="text-base font-medium tracking-widest">{{ paymentInfo?.expiry_date && paymentInfo.expiry_date.split('/')[0] }}/{{ paymentInfo?.expiry_date && paymentInfo.expiry_date.split('/')[1] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>
      <template v-else-if="paymentInfo?.payment_method === 'paypal'">
        <img src="@assets/img/settings/cards/paypal.svg"/>
      </template>
      <template v-else-if="paymentInfo?.payment_method === 'apple-pay'">
        <img class="w-full" src="@assets/img/settings/cards/apple-pay.svg"/>
      </template>
        <!-- <increase-limits-component /> -->
    </div>
  </b-modal>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";

export default {
  name: 'CardInfoDialog',
  data () {
    return {
      paymentInfo:{},
      text:'xxxx xxxx xxxx '
      // modalId: 'Card-info-dialog'
    }
  },
  mounted() {
    EventBus.$on("Card-info-dialog", (data) => {
      this.paymentInfo = data;
      console.log('CardInfoDialog', this.paymentInfo)
      this.$bvModal.show("Card-info-dialog");
    });
  },

}
</script>

<style lang="less">
</style>
