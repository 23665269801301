import proxy, { getProxyCancelToken } from '@common/lib/http-common'
import moment from 'moment'
import {
  BULK_OPERATION_ERROR,
  BULK_OPERATION_SUCCESS,
  CHANGE_PLAN_STATUS_ERROR,
  PLAN_APPROVAL_SUCCESS,
  PLAN_REJECT_SUCCESS,
  PRIMARY_POST_REMOVAL_DUPLICATION_ERROR,
  PRIMARY_POST_REMOVAL_EDIT_ERROR,
  REMOVE_PLAN_ERROR,
  REPLACE_PLAN_ERROR,
  RESCHEDULE_PLAN_ERROR,
  RESCHEDULE_PUBLISHED_PLAN_ERROR,
  TIME_PASSED_RESCHEDULE_PLAN_ERROR,
  UNKNOWN_ERROR,
  SEND_POST_NOTIFICATION_SUCCESS,
  SEND_POST_NOTIFICATION_ERROR,
} from '@common/constants/messages'
import { getWorkspaceTimeZoneTime, timePassed } from '@common/lib/date-time'
import {
  commonTypes,
  publish,
} from '@src/modules/publish/store/states/mutation-types'
import Router from '@src/router'
import {
  changePlanStatusURL,
  fetchPlansURL,
  processPlanBulkOperationURL,
  replacePlanURL,
  removePlanURL,
  reschedulePlanURL,
  planApprovalActionURL,
  sendPostNotificationURL,
  createPlannerFilterURL,
  fetchPlannerFiltersURL,
  deletePlannerFiltersURL,
  setDefaultPlannerFiltersURL,
  shuffleQueuePostsURL,
} from '@src/modules/planner/config/api-utils'
import { fetchAllAutomationsURL } from '@src/modules/automation/config/api-utils'

import { EventBus } from '@common/lib/event-bus'
import { planner } from './mutation-types'
import usePlannerActions from '@/src/modules/planner_v2/composables/usePlannerActions'

export const getDefaultPublishTimeOptions = () => {
  return {
    time_type: 'schedule',
    repeat: '',
    repeat_type: 'Hour',
    repeat_times: 1,
    repeat_gap: 1,
    evergreen: '',
    plan_status: 'Approved',
  }
}

const defaultPublishPlanListing = {
  items: [],
  page: 1,
  limit: 20,
  check_all_statuses: true,
  date_range: '',
  total_items: 0,
  check_all: false,
  check_all_items: false,
  selected_plans: [],
  content_categories: [],
  calendar_date_range: '',
  check_all_assigned_members: true,
  check_all_created_members: true,
  check_all_labels: true,
  check_all_campaigns: true,
  check_all_categories: true,
  active_planner_filter: {
    type: '',
    statuses: [],
    members: [],
    created_by: [],
    labels: [],
    campaigns: [],
    content_category: [],
  },
}
const defaultAutomationsListing = {
  search: '',
  select_all: false,
  selected_automation: [],
  fetch_status: false,
  article: [],
  video: [],
  evergreen: [],
  rss: [],
}

export const getDefaultPublishPlanCount = () => {
  return {
    scheduled: 0,
    published: 0,
    partially_failed: 0,
    review: 0,
    rejected: 0,
    missed: 0,
    failed: 0,
    draft: 0,
    repeat: 0,
    article_automations: 0,
    video_automations: 0,
    evergreen_automations: 0,
    rss_automations: 0,
    csv_automations: 0,
    queued: 0,
    composer_folders: [],
  }
}

export const getDefaultLoadersState = () => {
  return {
    fetch_automations: false,
    fetch_plans: true,
    plans_pagination_scroll: false,
    edit_plan: false,
    change_plan_status: false,
    reschedule_plan: false,
  }
}

export default {
  state: {
    listing: JSON.parse(JSON.stringify(defaultPublishPlanListing)),
    plans_count: getDefaultPublishPlanCount(),
    automations: JSON.parse(JSON.stringify(defaultAutomationsListing)),
    confirmation_plan: '',
    loaders: getDefaultLoadersState(),
    calendarScrollPosition: 0,
  },
  getters: {
    getPlans: (state) => state.listing,
    getAllAutomations: (state) => state.automations,
    getPlansCount: (state) => state.plans_count,
    getConfirmationPlan: (state) => state.confirmation_plan,
    getPlannerLoaders: (state) => state.loaders,
    getActivePlannerFilters: (state) => state.listing.active_planner_filter,
  },
  actions: {
    changePlanProcessingStatus({ commit, getters, state, dispatch }, payload) {
      console.debug('Action::changePlanProcessingStatus', payload);

      const foundItem = getters.getPlans.items.find(plan => plan._id === payload._id);
      if (foundItem) {
        foundItem.is_processing = payload.status
      }

      // getters.getPlans.items.forEach((plan, index) => {
      //   if (plan._id === payload.id) {
      //     getters.getPlans.items[index].is_processing = payload.status
      //     return false
      //   }
      // })
    },

    resetPlansRequestFilters({ commit, getters, state, dispatch }) {
      dispatch('resetPlannerListingStates')
      commit('setPublishLabels', [])
      commit('setPublishMembers', [])
      commit('setPublishCreatedByMembers', [])
      commit(publish.SET_PLATFORMS_SELECTION, [])
    },

    async fetchWSAutomations({ commit, getters, state, dispatch }, payload) {
      console.debug('Method:fetchWSAutomations')
      return proxy
        .post(fetchAllAutomationsURL, payload)
        .then((response) => {
          const dataContainer = []
          response.data.article_automations.forEach((automation) => {
            dataContainer.push({
              type: 'Article ' + automation.type,
              name: automation.name,
              id: automation._id,
            })
          })
          response.data.evergreen_automations.forEach((automation) => {
            dataContainer.push({
              type: 'Evergreen',
              name: automation.name,
              id: automation._id,
            })
          })
          response.data.rss_automations.forEach((automation) => {
            dataContainer.push({
              type: 'RSS',
              name: automation.name,
              id: automation._id,
            })
          })
          response.data.video_automations.forEach((automation) => {
            dataContainer.push({
              type: 'Video ' + automation.type,
              name: automation.name,
              id: automation._id,
            })
          })
          return dataContainer

          // if (response.data.status === true) return response.data.article_automations.concat(response.data.video_automations, response.data.evergreen_automations, response.data.rss_automations)
          // return []
        })
        .catch((error) => {
          console.debug('Exception in fetchWSAutomations', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return true
        })
    },

    fetchAllAutomations({ commit, getters, state, dispatch }) {
      console.debug('Action:fetchAllAutomations')

      commit(planner.SET_FETCH_AUTOMATIONS_STATUS, true)
      commit(planner.SET_FETCH_AUTOMATIONS_LOADER, true)
      proxy
        .post(fetchAllAutomationsURL, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          commit(planner.SET_FETCH_AUTOMATIONS_LOADER, false)
          if (response.data.status === true) {
            commit(
              planner.SET_ALL_ARTICLE_AUTOMATIONS,
              response.data.article_automations
            )
            commit(
              planner.SET_ALL_VIDEO_AUTOMATIONS,
              response.data.video_automations
            )
            commit(
              planner.SET_ALL_EVERGREEN_AUTOMATIONS,
              response.data.evergreen_automations
            )
            commit(
              planner.SET_ALL_RSS_AUTOMATIONS,
              response.data.rss_automations
            )
            return false
          }
          commit(planner.SET_ALL_ARTICLE_AUTOMATIONS, [])
          commit(planner.SET_ALL_VIDEO_AUTOMATIONS, [])
          commit(planner.SET_ALL_EVERGREEN_AUTOMATIONS, [])
          commit(planner.SET_ALL_RSS_AUTOMATIONS, [])
        })
        .catch((response) => {
          console.debug('Exception in fetchAllAutomations', response)
          commit(planner.SET_FETCH_AUTOMATIONS_LOADER, false)
        })
    },

    async refetchPlans({ commit, getters, state, dispatch }, options) {
      console.debug('Action:refetchPlans')
      commit(planner.SET_PAGINATION_SCROLL, false)
      commit(planner.SET_ITEMS, [])
      if (getters.getPublishSelection.platforms.selection.length) {
        commit(planner.SET_FETCH_PLANS_LOADER, true)
        commit(planner.SET_PAGE, 1)
        dispatch('fetchPlans', options)
      } else commit(planner.SET_FETCH_PLANS_LOADER, false)
      if (options.name === 'calender_plans') {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          $('[data-toggle="tooltip"]').tooltip()
        }, 1000)
      }
    },

    async getPlansRequestFilters(
      { commit, getters, state, dispatch },
      options
    ) {
      console.log('METHOD::getPlansRequestFilters  ~ options -> ', options)
      const membersList = getters.getWorkspaces.activeWorkspace.members.filter(
        (workspaceMember) =>
          workspaceMember.status === 'joined' && workspaceMember.user
      )
      const payload = {
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      payload.specific_plans = options.query.plan_ids
        ? options.query.plan_ids.split(',')
        : []

      if (options && options.query.reference_id) {
        payload.reference_id = options.query.reference_id
        return payload
      }

      if (options && options.query.bulk_id) {
        payload.bulk_id = options.query.bulk_id
        return payload
      }

      // if saved filter is active we get filters value from active filters
      // else get from query parameters
      if (options.query.filter_id && getters.getActivePlannerFilters) {
        const filter = getters.getActivePlannerFilters
        payload.type = options.query.type ? options.query.type : filter.type
        payload.labels =
          options.query.labels && !getters.getPlans.check_all_labels
            ? options.query.labels.split(',')
            : filter.labels
        payload.members =
          options.query.members && !getters.getPlans.check_all_assigned_members
            ? options.query.members.split(',')
            : filter.members
        payload.created_by_members =
          options.query.created_by &&
          !getters.getPlans.check_all_created_members
            ? options.query.created_by.split(',')
            : filter.created_by
        payload.content_categories =
          options.query.content_category &&
          !getters.getPlans.check_all_categories
            ? options.query.content_category.split(',')
            : filter.content_category
        payload.campaigns =
          options.query.campaigns && !getters.getPlans.check_all_campaigns
            ? options.query.campaigns.split(',')
            : filter.campaigns
        payload.statuses = options.query.statuses
          ? options.query.statuses.split(',')
          : filter.statuses.length === 0
          ? [
              'scheduled',
              'published',
              'partial',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft',
            ]
          : filter.statuses
      } else {
        if (
          options.name === 'planner_list_v2' ||
          options.name === 'planner_list_compact_v2' ||
          options.name === 'planner_feed_v2' ||
          options.name === 'planner_calendar_v2'
        ) {
          const labels = options.query.labels?.split(',')
          if (labels?.length && labels?.length === getters.getLabels.length) {
            payload.labels = 'all'
          } else {
            payload.labels =
              labels?.length === getters.getLabels.length ? [] : labels || []
          }

          payload.content_categories =
            options.query.content_category?.split(',')?.length ===
            getters.getContentCategoryList.length
              ? []
              : options.query.content_category?.split(',')
              ? options.query.content_category?.split(',')
              : []
          payload.campaigns =
            options.query.campaigns?.split(',')?.length ===
            getters.getPublicationFolders.length
              ? []
              : options.query.campaigns?.split(',')
              ? options.query.campaigns?.split(',')
              : []
          payload.members =
            options.query.members?.split(',')?.length === membersList.length
              ? []
              : options.query.members?.split(',')
              ? options.query.members?.split(',')
              : []
          payload.created_by_members =
            options.query.created_by?.split(',')?.length === membersList.length
              ? []
              : options.query.created_by?.split(',')
              ? options.query.created_by?.split(',')
              : []
        } else {
          payload.labels =
            options.query.labels && !getters.getPlans.check_all_labels
              ? options.query.labels.split(',')
              : []
          payload.content_categories =
            options.query.content_category &&
            !getters.getPlans.check_all_categories
              ? options.query.content_category.split(',')
              : []
          payload.campaigns =
            options.query.campaigns && !getters.getPlans.check_all_campaigns
              ? options.query.campaigns.split(',')
              : []
          payload.members =
            options.query.members &&
            !getters.getPlans.check_all_assigned_members
              ? options.query.members.split(',')
              : []
          payload.created_by_members =
            options.query.created_by &&
            !getters.getPlans.check_all_created_members
              ? options.query.created_by.split(',')
              : []
        }
        payload.type = options.query.type ? options.query.type : ''
        payload.statuses = options.query.statuses
          ? options.query.statuses.split(',')
          : [
              'scheduled',
              'partial',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft',
              'in_progress',
            ]
      }
      payload.platformSelection = options.query.social_accounts
        ? options.query.social_accounts.split(',')
        : getters.getPublishSelection.platforms.selection

      payload.automations = options.query.automations
        ? options.query.automations.split(',')
        : []

      if (
        options &&
        (options.name === 'calender_plans' || options.name === 'calendar_test')
      ) {
        payload.date_range = options.query.date ? options.query.date : ''
        return payload
      }

      if (options.name !== 'planner_calendar_v2') {
        payload.order =
          options.query.order_by &&
          options.query.order_by.toLowerCase() === 'oldest'
            ? 'ascending'
            : 'descending'
        payload.limit = getters.getPlans.limit
        if ('sort_column' in options.query) {
          payload.sort_column = options.query.sort_column
        }
      }
      // payload.date_range = getters.getPlans.date_range
      payload.date_range = options.query.date ? options.query.date : ''
      payload.page = getters.getPlans.page

      return payload
    },

    async fetchPlans({ commit, getters, state, dispatch }, options) {
      // if (!getters.getPublishSelection.platforms.selection.length) {
      //   commit(planner.SET_ITEMS, [])
      //   commit(planner.SET_FETCH_PLANS_LOADER, false)
      //   return false
      // }
      const payload = await dispatch('getPlansRequestFilters', options)

      console.log('Action:fetchPlans fetchPlans', payload)

      // Format the payload date range
      if (payload?.date_range) {
        const date = payload.date_range?.split(' - ')
        const start_date = moment(date[0])?.format('YYYY-MM-DD')
        const end_date = moment(date[1])?.format('YYYY-MM-DD')
        // update the date range value
        payload.date_range = start_date + ' - ' + end_date
      }
      payload.route_name = Router.currentRoute.value.name
      const res = await proxy
        .post(fetchPlansURL, payload, getProxyCancelToken(proxy))
        .then((response) => {
          commit(planner.SET_FETCH_PLANS_LOADER, false)
          if (response.data.status) {
            if (response.data.plans_count) {
              commit(planner.SET_COUNT, response.data.plans_count)
            }
            let plans = []
            if (
              options.name === 'calender_plans' ||
              options.name === 'calendar_test'
            ) {
              console.log('calendar name...')
              // calling planner calandar
              plans = response.data.plans
              const workspaceTimezone =
                getters.getWorkspaces.activeWorkspace.timezone
              plans.map((planItem) => {
                planItem.start = getWorkspaceTimeZoneTime(
                  planItem.execution_time.date,
                  workspaceTimezone,
                  'YYYY-MM-DD'
                )
                if (planItem.post_status === 'published') {
                  planItem.editable = false
                }
              })
              console.log(plans)
              commit(planner.SET_ITEMS, plans)
              return false
            }

            plans = response.data.plans.data
              ? response.data.plans.data
              : response.data.plans
            commit(
              planner.SET_TOTAL_ITEMS,
              response.data.plans.data ? response.data.plans.total : 1
            )

            if (getters.getPlans.page === 1) {
              commit(planner.SET_SELECTED_PLANS, [])
            }

            if (plans.length && Router.currentRoute.value.query.plan_id) {
              plans[0].is_active = true
              Router.push({ name: 'list_plans' })
            }

            if (Router.currentRoute.value.query.status === 'missed_review') {
              Router.push({ name: 'list_plans' })
            }

            if (getters.getPlans.check_all) {
              plans.forEach((plan) => {
                getters.getPlans.selected_plans.push(plan._id)
              })
            }
            commit(
              planner.SET_ITEMS,
              getters.getPlans.page > 1
                ? getters.getPlans.items.concat(plans)
                : plans
            )

            try {
              if (response.data.total_plans === 20) {
                commit(planner.SET_PAGINATION_SCROLL, true)
                if (options.infinite_loading) options.infinite_loading.loaded()
                return false
              }
              commit(planner.SET_PAGINATION_SCROLL, false)
              if (options.infinite_loading) options.infinite_loading.complete()
            } catch (trace) {
              console.log('Exception in fetchPlans pagination', trace)
            }
            commit(planner.SET_FETCH_PLANS_LOADER, false)
          }
          // commit(planner.SET_FETCH_PLANS_LOADER, false)
        })
        .catch((response) => {
          console.debug('Exception in fetchPlans', response)
        })

      return res
    },

    changePlanStatus({ commit, getters, state, dispatch }, payload) {
      console.debug('Action:changePlanStatus', payload)
      const context = payload.context // eslint-disable-line
      const plannerV2 = payload.plannerV2
      delete payload.plannerV2
      delete payload.icon
      delete payload.context
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
     commit(planner.SET_CHANGE_PLAN_STATUS_LOADER, true)
     //dispatch('changePlanProcessingStatus', { id: payload.id, status: true }) cause rendering issue
      proxy
        .post(changePlanStatusURL, payload)
        .then((response) => {
          commit(planner.SET_CHANGE_PLAN_STATUS_LOADER, false)
          if (response.data.status) {
            if (plannerV2) {
              if (payload.status === 'rejected') {
                dispatch('toastNotification', {
                  message: PLAN_REJECT_SUCCESS,
                  type: 'success',
                })
              } else {
                dispatch('toastNotification', {
                  message: PLAN_APPROVAL_SUCCESS,
                  type: 'success',
                })
              }
              // to update on planner v2 frontend

              EventBus.$emit('update-plan-status', {
                id: payload.id,
                res: {
                  data: {
                    plan_status: payload.status,
                  },
                },
              })
              EventBus.$emit('update-plans-count')
              return true
            }

            const foundItem = getters.getPlans.items.find(plan => plan._id === payload._id);

            if (foundItem) {
              foundItem.status = payload.status
              foundItem.post_state = payload.status
              foundItem.can_perform.approval = false
              foundItem.render_class.icon =
                payload.status === 'rejected'
                  ? 'far fa-times-circle'
                  : 'far fa-clock'
              foundItem.render_class.tooltip =
                payload.status === 'rejected' ? 'Rejected' : 'Scheduled'
            }

            // getters.getPlans.items.forEach((plan, index) => {
            //   if (plan._id === payload.id) {
            //     getters.getPlans.items[index].status = payload.status
            //     getters.getPlans.items[index].post_state = payload.status
            //     getters.getPlans.items[index].can_perform.approval = false
            //     getters.getPlans.items[index].render_class.icon =
            //       payload.status === 'rejected'
            //         ? 'far fa-times-circle'
            //         : 'far fa-clock'
            //     getters.getPlans.items[index].render_class.tooltip =
            //       payload.status === 'rejected' ? 'Rejected' : 'Scheduled'
            //     return false
            //   }
            // })
            dispatch('changePlanProcessingStatus', {
              id: payload.id,
              status: false,
            })

            const statuses = Router.currentRoute.value.query.statuses
              ? Router.currentRoute.value.query.statuses.split(',')
              : [
                  'scheduled',
                  'published',
                  'failed',
                  'rejected',
                  'under_review',
                  'missed_review',
                  'draft',
                ]
            if (
              payload.status === 'scheduled' &&
              !statuses.includes('scheduled')
            ) {
              dispatch('toastNotification', {
                message: PLAN_APPROVAL_SUCCESS,
                type: 'success',
              })
              // eslint-disable-next-line no-undef
              $('#' + payload.id).fadeOut(250, (e) => {
                // eslint-disable-next-line no-undef
                $(this).hide()
              })
              return true
            }
            if (
              payload.status === 'rejected' &&
              !statuses.includes('rejected')
            ) {
              dispatch('toastNotification', {
                message: PLAN_REJECT_SUCCESS,
                type: 'success',
              })
              // eslint-disable-next-line no-undef
              $('#' + payload.id).fadeOut(250, (e) => {
                // eslint-disable-next-line no-undef
                $(this).hide()
              })
            }
            return true
          }
          dispatch('toastNotification', {
            message: CHANGE_PLAN_STATUS_ERROR,
            type: 'error',
          })
          dispatch('changePlanProcessingStatus', {
            id: payload.id,
            status: false,
          })
        })
        .catch((response) => {
          console.debug('Exception in changePlanStatus', response)
          dispatch('changePlanProcessingStatus', {
            id: payload.id,
            status: false,
          })
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          commit(planner.SET_CHANGE_PLAN_STATUS_LOADER, false)
        })
    },

    async removePlan({ commit, getters, state, dispatch }, payload) {
      console.debug('Action:removePlan', payload)
      payload.workspace_id = getters.getWorkspaces.activeWorkspace?._id
      return proxy
        .post(removePlanURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            const index = getters.getPlans.items.findIndex(plan => plan._id === payload.id);
            if (index !== -1) {
              getters.getPlans.items.splice(index, 1)
              commit(
                planner.SET_TOTAL_ITEMS,
                getters.getPlans.total_items - 1
              )
            }
            return response
          }
          const message = response.data.message
            ? response.data.message
            : REMOVE_PLAN_ERROR
          dispatch('toastNotification', { message, type: 'error' })
          return false
        })
        .catch((error) => {
          console.debug('Exception in removePlan', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    async replacePlan({ commit, getters, state, dispatch }, plan) {
      console.debug('Action:replacePlan', plan)

      // eslint-disable-next-line no-undef
      $(".planer_event_box_week[data-id='" + plan._id + "']")
        .find('.custom_loader')
        .removeClass('hide')
      const payload = {
        id: plan._id,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      if (plan.article_automation_id) {
        payload.type = 'Article Automation'
        payload.automation_id = plan.article_automation_id
      }
      if (plan.video_automation_id) {
        payload.type = 'Video Automation'
        payload.automation_id = plan.video_automation_id
      }

      dispatch('changePlanProcessingStatus', { id: plan._id, status: true })
      return proxy
        .post(replacePlanURL, payload)
        .then((response) => {
          dispatch('changePlanProcessingStatus', {
            id: plan._id,
            status: false,
          })
          // eslint-disable-next-line no-undef
          $(".planer_event_box_week[data-id='" + plan._id + "']")
            .find('.custom_loader')
            .addClass('hide')
          if (response.data.status === true) {

            const foundItem = getters.getPlans.items.find(item => item._id === plan._id);

            if (foundItem) {
                if (response.data.item.post) {
                    foundItem.publish_source = response.data.item.publish_source;
                    foundItem.post = response.data.item.post;
                } else {
                    for (const key in response.data.item) {
                        if (typeof response.data.item[key] !== 'function') {
                            foundItem[key] = response.data.item[key];
                        }
                    }
                }
            }


            // getters.getPlans.items.forEach((element, index) => {
            //   if (element._id === plan._id) {
            //     if (response.data.item.post) {
            //       getters.getPlans.items[index].publish_source =
            //         response.data.item.publish_source
            //       getters.getPlans.items[index].post = response.data.item.post
            //       return false
            //     }
            //     for (const key in response.data.item) {
            //       if (typeof response.data.item[key] !== 'function') {
            //         const plan = getters.getPlans.items[index]
            //         plan[key] = response.data.item[key]
            //         getters.getPlans.items[index] = plan
            //       }
            //     }
            //     return false
            //   }
            // })
            return response
          }
          const message = response.data.message
            ? response.data.message
            : REPLACE_PLAN_ERROR
          dispatch('toastNotification', { message, type: 'error' })
          return false
        })
        .catch((error) => {
          console.debug('Exception in replacePlan', error)
          dispatch('changePlanProcessingStatus', {
            id: plan._id,
            status: false,
          })
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          // eslint-disable-next-line no-undef
          $(".planer_event_box_week[data-id='" + plan._id + "']")
            .find('.custom_loader')
            .addClass('hide')
          return false
        })
    },

    clonePlan({ commit, getters, state, dispatch }, plan) {
      console.debug('Action:clonePlan', plan)
      if (plan.parent_reference && plan.blog_reference && !plan.parent) {
        dispatch('toastNotification', {
          message: PRIMARY_POST_REMOVAL_DUPLICATION_ERROR,
          type: 'error',
        })
        return false
      }

      let type = 'social'
      if (plan.blog_reference) type = 'blog'
      if (plan.status === 'draft' && plan.blog_reference) type = 'blog_draft'
      else if (plan.status === 'draft') type = 'social_draft'

      if (!plan.parent_reference || plan.queued === 1 || plan.repeat_post) {
        if(plan?.isGridView) {
          plan.action(plan._id, true, false, false, type)
        } else {
          plan.stateObject.editPublication(plan._id, true, false, false, type)
        }
        return true
      }
      plan.stateObject.editPublication(
        plan.parent_reference,
        true,
        false,
        false,
        type
      )
      return true
    },

    editPlan({ commit, getters, state, dispatch }, plan) {
      console.debug('Action:editPlan', plan)
      if (plan.parent_reference && plan.blog_reference && !plan.parent) {
        dispatch('toastNotification', {
          message: PRIMARY_POST_REMOVAL_EDIT_ERROR,
          type: 'error',
        })
        return false
      }

      let type = 'social'
      if (plan.blog_reference) type = 'blog'
      if (plan.status === 'draft' && plan.blog_reference) type = 'blog_draft'
      else if (plan.status === 'draft') type = 'social_draft'

      let reopen = false
      if (type === 'social') {
        // social reopen case
        if (plan.parent_reference && !plan.queued && !plan.repeat_post) {
          if (
            plan.parent &&
            (plan.parent.status === 'failed' ||
              plan.parent.status === 'rejected' ||
              (plan.parent.status === 'review' &&
                plan.parent.execution_time.date <
                  moment.utc().format('YYYY-MM-DD HH:mm:ss')))
          ) {
            reopen = true
          }
        } else {
          if (
            plan.status === 'failed' ||
            plan.status === 'rejected' ||
            (plan.status === 'review' &&
              plan.execution_time.date <
                moment.utc().format('YYYY-MM-DD HH:mm:ss'))
          ) {
            reopen = true
          }
        }
      } else {
        // blog and draft case we will handle this later
        if (plan.parent_reference && !plan.queued && !plan.repeat_post) {
          if (
            plan.parent &&
            (plan.parent.status === 'failed' ||
              plan.parent.status === 'rejected' ||
              (plan.parent.status === 'review' &&
                plan.parent.execution_time.date <
                  moment.utc().format('YYYY-MM-DD HH:mm:ss')))
          ) {
            commit(planner.SET_CONFIRMATION_PLAN, plan)
            plan.stateObject.$bvModal.show('reopenPlan')
            return false
          }
        } else {
          if (
            plan.status === 'failed' ||
            plan.status === 'rejected' ||
            (plan.status === 'review' &&
              plan.execution_time.date <
                moment.utc().format('YYYY-MM-DD HH:mm:ss'))
          ) {
            commit(planner.SET_CONFIRMATION_PLAN, plan)
            plan.stateObject.$bvModal.show('reopenPlan')
            return false
          }
        }
      }
      commit(commonTypes.RESET_TWITTER_VARIATIONS)
      if (!plan.parent_reference || plan.queued === 1 || plan.repeat_post) {
        if(plan?.isGridView) {
          plan.action(plan._id, false, reopen, false, type)
        } else {
          plan.stateObject.editPublication(plan._id, false, reopen, false, type)
        }
      } else {
        if(plan?.isGridView) {
          plan.action(plan.parent_reference, false, reopen, false, type)
        } else {
          plan.stateObject.editPublication(
            plan.parent_reference,
            false,
            reopen,
            false,
            type
          )
        }
      }
    },

    async processPlanBulkOperation(
      { commit, getters, state, dispatch },
      operation
    ) {
      console.debug('Method:processPlanBulkOperation', operation)

      let data = {}
      const plans = getters.getPlans
      if (plans.check_all_items) {
        data = await dispatch('getPlansRequestFilters', Router.currentRoute.value)
        delete data.order
        delete data.page
        delete data.limit
        data.operation = operation
        delete data.specific_plans
      }
      if (!plans.check_all_items) {
        data = {
          operation,
          selected_plans: plans.selected_plans,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        }
      }
      return proxy
        .post(processPlanBulkOperationURL, data)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: BULK_OPERATION_SUCCESS,
              type: 'success',
            })
            commit(planner.SET_SELECTED_PLANS, [])
            commit(planner.SET_CHECK_ALL, false)
            commit(planner.SET_CHECK_ALL_ITEMS, false)
            return response
          }
          dispatch('toastNotification', {
            message: BULK_OPERATION_ERROR,
            type: 'error',
          })
          return false
        })
        .catch((error) => {
          console.debug('Exception in processPlanBulkOperation', error)
          return false
        })
    },

    async reschedulePlan({ commit, getters, state, dispatch }, event) {
      console.debug('reschedulePlan', event)
      if (event.status === 'published') {
        dispatch('toastNotification', {
          message: RESCHEDULE_PUBLISHED_PLAN_ERROR,
          type: 'error',
        })
        return false
      }

      // pick date YYYY-MM-DD from event object startStr
      // pick time from execution time HH:mm:ss and convert it to workspace timezone
      // combine both date and time set their timezone to workspace timezone (without converting time)
      let executionTime = moment(
        event.startStr +
          ' ' +
          getWorkspaceTimeZoneTime(
            event.execution_time.date,
            getters.getActiveWorkspace.timezone,
            'HH:mm:ss'
          )
      ).tz(getters.getActiveWorkspace.timezone, true)
      // then convert it to UTC
      executionTime = executionTime.utc().format('YYYY-MM-DD HH:mm:ss')
      console.debug(
        'reschedulePlan execution time old: ',
        event.execution_time.date,
        ' new: ',
        executionTime
      )
      if (timePassed(executionTime)) {
        dispatch('toastNotification', {
          message: TIME_PASSED_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        return false
      }

      commit(planner.SET_RESCHEDULE_PLAN_LOADER, true)
      return proxy
        .post(reschedulePlanURL, {
          execution_time: executionTime,
          id: event._id,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          commit(planner.SET_RESCHEDULE_PLAN_LOADER, false)
          if (response.data.status) {
            return true
          }
          dispatch('toastNotification', {
            message: RESCHEDULE_PLAN_ERROR,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in reschedulePlan', response)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          commit(planner.SET_RESCHEDULE_PLAN_LOADER, false)
          return false
        })
    },

    resetPlannerStates({ commit, getters, state, dispatch }) {
      dispatch('resetPlannerListingStates')
      commit(planner.SET_COUNT, null)
      commit(planner.SET_ALL_AUTOMATIONS_SEARCH, '')
      commit(planner.SET_AUTOMATIONS_SELECT_ALL_STATUS, false)
      commit(planner.SET_SELECTED_AUTOMATIONS, [])
      commit(planner.SET_FETCH_AUTOMATIONS_STATUS, false)
      commit(planner.SET_ALL_ARTICLE_AUTOMATIONS, [])
      commit(planner.SET_ALL_VIDEO_AUTOMATIONS, [])
      commit(planner.SET_ALL_EVERGREEN_AUTOMATIONS, [])
      commit(planner.SET_ALL_RSS_AUTOMATIONS, [])
      commit(planner.SET_CONFIRMATION_PLAN, '')
      commit(planner.SET_DEFAULT_LOADER)
    },

    resetPlannerListingStates({ commit, getters, state, dispatch }) {
      const query = Object.assign({}, Router.currentRoute.value.query)
      delete query.type
      delete query.order_by
      delete query.labels
      delete query.campaigns
      delete query.content_category
      delete query.created_by
      delete query.members
      delete query.statuses
      delete query.plan_ids
      delete query.bulk_id
      delete query.reference_id
      delete query.date
      Router.replace({ query })

      commit(planner.SET_ITEMS, [])
      commit(planner.SET_PAGE, 1)
      commit(planner.SET_CHECK_ALL_STATUSES, true)
      commit(planner.SET_CHECK_ALL_LABELS, true)
      commit(planner.SET_CHECK_ALL_CAMPAIGNS, true)
      commit(planner.SET_CHECK_ALL_ASSIGNED_MEMBERS, true)
      commit(planner.SET_CHECK_ALL_CREATED_MEMBERS, true)
      commit(planner.SET_CHECK_ALL_CATEGORIES, true)

      // commit(planner.SET_DATE_RANGE, '')
      commit(planner.SET_TOTAL_ITEMS, 0)
      commit(planner.SET_CHECK_ALL, false)
      commit(planner.SET_CHECK_ALL_ITEMS, false)
      commit(planner.SET_SELECTED_PLANS, [])
    },

    async planApprovalAction({ commit, getters, state, dispatch }, payload) {
      console.debug('requesting:planApprovalAction', payload)
      payload.workspace_id = getters.getWorkspaces.activeWorkspace._id
      return proxy
        .post(planApprovalActionURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response
          }
          return false
        })
        .catch((error) => {
          console.debug('Exception in planApprovalAction', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    async sendPostNotification({ commit, getters, state, dispatch }, payload) {
      console.debug('Action:sendPostNotification', payload)

      dispatch('changePlanProcessingStatus', { id: payload.id, status: true })
      return proxy
        .post(sendPostNotificationURL, payload)
        .then((response) => {
          if (response.data.status) {
            dispatch('toastNotification', {
              message: SEND_POST_NOTIFICATION_SUCCESS,
              type: 'success',
            })
            return true
          }
          dispatch('toastNotification', {
            message: SEND_POST_NOTIFICATION_ERROR,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in sendPostNotification', response)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    // Planner save Filters

    async createPlannerFilter({ commit, getters, state, dispatch }, payload) {
      console.debug('Action:createPlannerFilter', payload)

      return proxy
        .post(createPlannerFilterURL, payload)
        .then((response) => {
          if (response.data.status) {
            dispatch('toastNotification', {
              message: 'Filter successfully saved.',
              type: 'success',
            })
            return response.data.filter
          }
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in createPlannerFilter', response)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          return false
        })
    },

    async fetchPlannerFilters({ commit, getters, state, dispatch }) {
      console.debug('Action:fetchPlannerFilters')

      return proxy
        .post(fetchPlannerFiltersURL, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status === true) {
            // commit(planner.SET_PLANNER_FILTERS_DATA, response.data.filters)
            return response.data.filters
          }
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in fetchPlannerFilters', response)
          return false
        })
    },

    async removePlannerFilter({ commit, getters, state, dispatch }, payload) {
      console.debug('Action:removePlannerFilter')

      return proxy
        .post(deletePlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            dispatch('toastNotification', {
              message: 'Filter successfully removed.',
              type: 'success',
            })
            return true
          }
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    async setDefaultPlannerFilter(
      { commit, getters, state, dispatch },
      payload
    ) {
      console.debug('Action:setDefaultPlannerFilter')

      return proxy
        .post(setDefaultPlannerFiltersURL, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response.data.filters
          }
          dispatch('toastNotification', {
            message: response.data.message,
            type: 'error',
          })
          return false
        })
        .catch((response) => {
          console.debug('Exception in removePlannerFilter', response)
          return false
        })
    },

    async shuffleQueuePosts({ commit, getters, dispatch }, accountId) {
      console.debug('Action:shuffleQueuePosts')
      return proxy
        .post(shuffleQueuePostsURL, {
          account_id: accountId,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((response) => {
          if (response.data.status) {
            dispatch('toastNotification', { message: response.data.message })
          }
        })
        .catch((response) => {
          console.debug('Exception in shuffleQueuePosts', response)
        })
    },
  },
  mutations: {
    [planner.SET_ITEMS](state, items) {
      state.listing.items = items
    },

    [planner.SET_PAGE](state, page) {
      state.listing.page = page
    },

    [planner.SET_CHECK_ALL_STATUSES](state, status) {
      state.listing.check_all_statuses = status
    },

    [planner.SET_CHECK_ALL_CAMPAIGNS](state, campaigns) {
      state.listing.check_all_campaigns = campaigns
    },
    [planner.SET_CHECK_ALL_CATEGORIES](state, categories) {
      state.listing.check_all_categories = categories
    },
    [planner.SET_CHECK_ALL_LABELS](state, labels) {
      state.listing.check_all_labels = labels
    },
    [planner.SET_CHECK_ALL_ASSIGNED_MEMBERS](state, status) {
      state.listing.check_all_assigned_members = status
    },
    [planner.SET_CHECK_ALL_CREATED_MEMBERS](state, status) {
      state.listing.check_all_created_members = status
    },

    [planner.SET_DATE_RANGE](state, range) {
      state.listing.date_range = range
    },

    [planner.SET_TOTAL_ITEMS](state, count) {
      state.listing.total_items = count
    },

    [planner.SET_CHECK_ALL](state, status) {
      state.listing.check_all = status
    },

    [planner.SET_CHECK_ALL_ITEMS](state, status) {
      state.listing.check_all_items = status
    },

    [planner.SET_SELECTED_PLANS](state, plans) {
      state.listing.selected_plans = plans
    },
    [planner.SET_COUNT](state, count) {
      if (count) {
        state.plans_count = count
        return false
      }
      state.plans_count = getDefaultPublishPlanCount()
    },

    [planner.SET_ALL_AUTOMATIONS_SEARCH](state, search) {
      state.automations.search = search
    },

    [planner.SET_AUTOMATIONS_SELECT_ALL_STATUS](state, status) {
      state.automations.select_all = status
    },

    [planner.SET_SELECTED_AUTOMATIONS](state, automations) {
      state.automations.selected_automation = automations
    },

    [planner.SET_FETCH_AUTOMATIONS_STATUS](state, status) {
      state.automations.fetch_status = status
    },

    [planner.SET_ALL_ARTICLE_AUTOMATIONS](state, automations) {
      state.automations.article = automations
    },

    [planner.SET_ALL_VIDEO_AUTOMATIONS](state, automations) {
      state.automations.video = automations
    },

    [planner.SET_ALL_EVERGREEN_AUTOMATIONS](state, automations) {
      state.automations.evergreen = automations
    },

    [planner.SET_ALL_RSS_AUTOMATIONS](state, automations) {
      state.automations.rss = automations
    },
    [planner.ADD_TO_ALL_RSS_AUTOMATIONS](state, automation) {
      state.automations.rss.unshift(automation)
    },
    [planner.UPDATE_TO_ALL_RSS_AUTOMATIONS](state, automation) {
      const findIndex = state.automations.rss.findIndex(
        (item) => item._id === automation._id
      )
      state.automations.rss.splice(findIndex, 1, automation)
    },

    [planner.SET_CONFIRMATION_PLAN](state, plan) {
      state.confirmation_plan = plan
    },

    [planner.SET_DEFAULT_LOADER](state) {
      state.loaders = getDefaultLoadersState()
    },

    [planner.SET_FETCH_AUTOMATIONS_LOADER](state, status) {
      state.loaders.fetch_automations = status
    },

    [planner.SET_FETCH_PLANS_LOADER](state, status) {
      state.loaders.fetch_plans = status
    },

    [planner.SET_PAGINATION_SCROLL](state, status) {
      state.loaders.plans_pagination_scroll = status
    },

    [planner.SET_EDIT_PLAN_LOADER](state, status) {
      state.loaders.edit_plan = status
    },

    [planner.SET_CHANGE_PLAN_STATUS_LOADER](state, status) {
      state.loaders.change_plan_status = status
    },

    [planner.SET_RESCHEDULE_PLAN_LOADER](state, status) {
      state.loaders.reschedule_plan = status
    },
    [planner.SET_CALENDAR_DATE_RANGE](state, value) {
      state.listing.calendar_date_range = value
    },

    [planner.SET_PLANNER_ACTIVE_FILTER](state, value) {
      if (value) state.listing.active_planner_filter = value
      else {
        state.listing.active_planner_filter = {
          type: '',
          statuses: [],
          members: [],
          created_by: [],
          labels: [],
          campaigns: [],
          content_category: [],
        }
      }
    },
    [planner.SET_CALENDAR_SCROLL_POSITION](state, position) {
      state.calendarScrollPosition = position;

    },
  },
}
