import { UNKNOWN_ERROR } from '@common/constants/messages'
import proxy from '@common/lib/http-common'
import { checkPlatformAccess, memberRole } from '@common/lib/permission'
import {
  savePlatformsURL,
  saveIGBusinessPlatformURL,
  fetchPlatformsListURL, searchSocialAccountsURL,
} from '@src/modules/integration/config/api-utils'
import {
  platforms,
  social,
} from '@src/modules/integration/store/states/mutation-types'

export default {
  state: {
    connection_state: null,
    save_platforms_loader: false,
    selected_accounts: [],
    platformsList: [],
    allPlatforms: []
  },
  getters: {
    getPlatformsConnectionState: (state) => state.connection_state,
    getSavePlatformsLoader: (state) => state.save_platforms_loader,
    getAllPlatforms: (state) => state.allPlatforms,
    getPlatformsList: (state) => state.platformsList,
    getSocialAccountsList: (state) =>
      state.platformsList.filter((item) => item.platform_type === 'social'),
    getBlogAccountsList: (state) =>
      state.platformsList.filter((item) => item.platform_type === 'blog'),
  },
  actions: {
    setPlatforms({ commit, getters, rootGetters, dispatch }, details) {
      console.debug('Action::setPlatforms', details)
      commit(details.all_item_setter, details.items)
      let items = []
      const user = getters.getProfile._id
      const workspace = getters.getWorkspaces.activeWorkspace
      if (memberRole(workspace, user) === 'Collaborator') {
        details.items.forEach(function (item) {
          if (checkPlatformAccess(workspace, user, item, details.type)) {
            items.push(item)
          }
        })
      } else {
        items = details.items
      }
      commit(details.item_setter, items)
    },
    setAllPlatforms({ commit }, items) {
      console.debug('Action::setAllPlatforms', items)

      const accounts = {}

      for (const [key, v] of Object.entries(items.items)) {
        if(key !== 'status') {
          if (key === 'instagram') {
            accounts[key] = v.accounts
          } else {
            accounts[key] = v
          }
        }
      }

      commit(platforms.SET_ALL_PLATFORMS, accounts)
    },
    async processSavePlatforms(
      { commit, getters, rootGetters, dispatch },
      payload
    ) {
      commit(platforms.SET_SAVE_PLATFORMS_LOADER, true)
      if(payload.externalLinkToken){
        return proxy
            .post(savePlatformsURL, payload.connectionState, {
              headers: {
                'Content-Type' : 'application/json',
                'EXTERNAL-LINK-TOKEN': payload.externalLinkToken
              }
            })
            .then((response) => {
              commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
              // dispatch('fetchSocialAccountsList')
              return response
            })
            .catch((error) => {
              console.debug('Exception in savePlatforms', error)
              dispatch('toastNotification', {
                message: UNKNOWN_ERROR,
                type: 'error',
              })
              commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
            })
      }
      console.debug('Method:savePlatforms')
      return proxy
        .post(savePlatformsURL, payload)
        .then((response) => {
          commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
          // dispatch('fetchSocialAccountsList')
          return response
        })
        .catch((error) => {
          console.debug('Exception in savePlatforms', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
        })
    },

    async processSaveIGBusinessProfile(
      { commit, getters, rootGetters, dispatch },
      {connectionState, externalLinkToken}
    ) {
      console.debug('Method:savePlatforms')
      commit(platforms.SET_SAVE_PLATFORMS_LOADER, true)
      return proxy
        .post(saveIGBusinessPlatformURL, connectionState, {
            headers: externalLinkToken ? {
                'Content-Type' : 'application/json',
                'EXTERNAL-LINK-TOKEN': externalLinkToken
            } : {
              'Content-Type' : 'application/json',
            }
        })
        .then((response) => {
          commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
          // dispatch('fetchSocialAccountsList')
          return response
        })
        .catch((error) => {
          console.debug('Exception in savePlatforms', error)
          dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          commit(platforms.SET_SAVE_PLATFORMS_LOADER, false)
        })
    },

    /**
     * Fetches flattened social accounts list via api call and set then in vuex
     * @param commit
     * @param getters
     * @returns {Promise<void>}
     */
    async fetchPlatformsList({ commit, getters, dispatch }) {
      console.log('ACTION::fetchPlatformsList')
      if (getters.getWorkspaces.activeWorkspace._id) {
        await proxy
          .post(fetchPlatformsListURL, {
            workspace_id: getters.getWorkspaces.activeWorkspace._id,
          })
          .then((resp) => {
            commit(social.SET_PLATFORM_LIST, resp.data)
          })
          .catch((err) => {
            dispatch('toastNotification', {
              message: "Unable to fetch social accounts list",
              type: 'error',
            })
          })
      } else {
        console.log('METHOD::fetchPlatformsList ~ WORKSPACE ID NOT FOUND')
      }
    },
    async searchPlatformsList({ commit, getters, dispatch }, data) {
      console.log('ACTION::searchPlatformsList')
      if (getters.getWorkspaces.activeWorkspace._id) {
        return await proxy
            .post(searchSocialAccountsURL, {
              workspace_id: getters.getWorkspaces.activeWorkspace._id,
              search: data.search,
              platform: data.type
            })
            .then((resp) => {
              return resp.data
            })
            .catch((err) => {
              dispatch('toastNotification', {
                message: "Unable to search social accounts list",
                type: 'error',
              })
            })
      } else {
        console.log('METHOD::searchPlatformsList ~ WORKSPACE ID NOT FOUND')
      }
    },
  },
  mutations: {
    [platforms.SET_CONNECTION_STATE](state, connectionState) {
      state.connection_state = connectionState
    },

    [platforms.SET_SAVE_PLATFORMS_LOADER](state, status) {
      state.save_platforms_loader = status
    },

    [platforms.SET_DEFAULT_STATES](state) {
      state.connection_state = null
      state.save_platforms_loader = false
    },
    [platforms.SET_ALL_PLATFORMS](state, list) {
      state.allPlatforms = list
    },
    [social.SET_PLATFORM_LIST](state, list) {
      state.platformsList = list
    },
  },
}
