<script>
import { defineComponent, ref, inject } from 'vue';
import TableData from '@src/modules/setting/components/content-categories/calendar/TableData.vue';
import TableHeader from '@src/modules/setting/components/content-categories/calendar/TableHeader.vue';
import useDateFormat from "@common/composables/useDateFormat";
export default defineComponent({
  name: 'TimeGridCalendar',
  components: { TableData, TableHeader },
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    editSlotModal: {
      type: Function,
      default: () => {},
    },
    removeSlotModal: {
      type: Function,
      default: () => {},
    },
    addSlotModal: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { getUserTimePreference } = useDateFormat();
    const days = ref(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']);

    const showHour = (index) => {
      if (getUserTimePreference.value === '24h') {
        return index < 10 ? `0${index}:00` : `${index}:00`;
      } else {
        const period = index >= 12 ? 'pm' : 'am';
        const hour = index % 12 || 12;
        return `${hour}${period}`;
      }
    };

    return {
      days,
      showHour,
      getUserTimePreference,
    };
  },
});
</script>


<template>
  <table class="table-fixed w-full">
    <thead>
    <tr>
      <th class="sticky top-0 z-10 h-[2rem] box-content border border-2 border-[#2a8bc3] w-1/5"></th>
      <TableHeader v-for="(day, i) in days" :key="`table_header_${i}`" :day="day" />
    </tr>
    </thead>
    <tbody>
    <tr v-for="index in 24" :key="`time_calendar_${index}`">
      <td class="custom-table-time-td lg:text-[0.85rem] sm:text-[0.688rem] group relative h-[2rem] box-content border border-2 border-[#2a8bc3] text-center">
        {{ showHour(index - 1) }}
      </td>
      <TableData
          v-for="(day, keyIdx) in days"
          :key="`table_data_${keyIdx}`"
          :index="index - 1"
          :day="day"
          :add-slot-modal="addSlotModal"
          :remove-slot-modal="removeSlotModal"
          :edit-slot-modal="editSlotModal"
          :slots="slots"
          :timeFormat="getUserTimePreference"
      />
    </tr>
    </tbody>
  </table>
</template>



<style lang="less">
@media screen and (min-width: 1270px) and (max-width: 1400px) {
  .edit-delete-slot {
    padding-left: 0.15rem !important;
    padding-right: 0.15rem !important;
    font-size: 0.7rem !important;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1600px) {
  .custom-table-time-td {
    font-size: 0.7rem !important;
  }
}
</style>

