<template>
  <div class="billing-upgrade-page">
    <template v-if="is_loading_addon_upgrade">
      <beat-loader :color="'#6173ff'"></beat-loader>
    </template>
    <template v-else>
      <div class="billing-close-button">
        <b-button
          variant="studio-theme-transparent-grey"
          class="mr-4 mt-3"
          @click="$router.push({ name: 'workspaces' })"
        >
          Go to workspaces
        </b-button>
      </div>
      <div class="billing-main-heading">
        <h2>Upgrade Limits</h2>
        <!--<p>Increase your account limits by looking at your future growth and needs.</p>-->
      </div>
      <div class="billing-upgrade-addons-limit-section">
        <IncreaseLimitsComponent />
      </div>
    </template>
  </div>
</template>

<script>
import IncreaseLimitsComponent from '@src/modules/setting/components/billing/dialogs/IncreaseLimitsComponent'

export default {
  components: {
    IncreaseLimitsComponent
  },
  data () {
    return {
      is_loading_addon_upgrade: true
    }
  },
  computed: {},
  created () {},
  async mounted () {
    await this.fetchWorkspaces()
    await this.Plan()
    this.is_loading_addon_upgrade = false
    if (this.isOnLifetimePlan() === false) {
      this.$router.push({ name: 'workspaces' })
    }
  },

  methods: {}
}
</script>
