<template>
  <b-modal
    id="first_comment_response"
    hide-footer
    hide-header
    modal-class="full_height first_comment_response right side_slide_right w_200"
    @hide="hideModal()"
  >
    <div class="modal_head d-flex align-items-center">
      <h3>First Comment Status</h3>
      <button type="button" class="close" @click="hideModal(false)"
        >&times;</button
      >
    </div>
    <div
      class="social_preview_block__social_preview_inner__social_post_preview"
    >
      <LinkedinPreview
        v-if="type === 'linkedin'"
        :planner="true"
        :data="obj"
        :details="obj.detail"
      ></LinkedinPreview>
      <FacebookPreview
        v-if="type === 'facebook'"
        :planner="true"
        :data="obj"
        :details="obj.detail"
      ></FacebookPreview>
      <InstagramPreview
        v-if="type === 'instagram'"
        :planner="true"
        :data="obj"
        :details="obj.detail"
      ></InstagramPreview>
      <YoutubePreview
        v-if="type === 'youtube'"
        :planner="true"
        :data="obj"
        :account="obj.account"
        :options="obj.youtubeOptions"
        :details="obj.detail"
      ></YoutubePreview>
    </div>
  </b-modal>
</template>

<script>
import LinkedinPreview from '@src/modules/publish/components/posting/social/previews/LinkedinPreview.vue'
import FacebookPreview from '@src/modules/publish/components/posting/social/previews/FacebookPreview.vue'
import InstagramPreview from '@src/modules/publish/components/posting/social/previews/InstagramPreview.vue'
import YoutubePreview from '@src/modules/publish/components/posting/social/previews/YoutubePreview.vue'
import { EventBus } from '@common/lib/event-bus'
export default {
  components: {
    LinkedinPreview,
    FacebookPreview,
    InstagramPreview,
    YoutubePreview
  },
  data () {
    return {
      type: '',
      obj: {
        detail: {},
        youtubeOptions: {},
        account: {},
        response: '',
        commentResponse: '',
        message: '',
      },
    }
  },
  created () {
    EventBus.$on(
      'first_comment_response',
      ({
        type,
        detail,
        youtubeOptions,
        account,
        response,
        commentResponse,
        message,
      }) => {
        this.type = type
        this.obj.detail = detail
        this.obj.youtubeOptions = youtubeOptions
        this.obj.account = account
        this.obj.response = response
        this.obj.commentResponse = commentResponse
        this.obj.message = message

        console.log('first_comment_response', this.obj)
        this.$bvModal.show('first_comment_response')
      }
    )
  },
  methods: {
    hideModal(hide = true) {
      this.$bvModal.hide('first_comment_response')
    },
  },
}
</script>

<style lang="less">
.comments-status-box {
  padding: 10px 0px;
  .status {
    display: inline-block;
    width: 12px;
    float: left;
    height: 12px;
    border-radius: 50%;
    color: lightgray;
    margin-right: 8px;
    margin-top: 6px;
  }
  .published {
    background: #1ecb7b;
  }
  .failed {
    background: #c00000;
  }
}
</style>
