<template>
  <div class="profile_picture" :class="[flex_class, type + '-color']">
    <div class="picture_block picture_block_background">
      <template v-if="type === 'shopify'">
        <div class="icon shopify_icon"></div>
      </template>
      <template v-else-if="type === 'webflow'">
        <div class="icon webflow_icon"></div>
      </template>
      <div v-else class="icon">
        <template v-if="type === 'tiktok'">
          <img src="@src/assets/img/integration/tiktok-icon_1.svg" alt="tiktok_logo" />
        </template>
        <i v-else :class="'cs-' + type"></i>
      </div>
      <div
        class="img"
        :style="{ 'background-image': channelBackgroundImage(account, type) }"
      ></div>
    </div>
    <div class="text_block">
      <p v-b-tooltip.top="account_name.length > 25 ? account_name : ''" class="text">{{ getModifiedChannelName(account_name) }}</p>
      <p class="sub_text">
        <template v-if="integration === 'blog'"> Blog </template>
        <template v-else>
          {{ channelType(account_type) }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {},
    type: {},
    flex_class: {
      default: 'd-flex align-items-start',
    },
    account_name: {},
    account_type: {},
    integration: {},
  },
  methods: {},
}
</script>

<style scoped></style>
