<template>
  <v-menu
    :popper-triggers="['hover']"
    placement="top"
    popper-class="first-comment__info-popover"
    popover-inner-class="!w-52 rounded-lg text-[979CA0] border p-2 !bg-white"
    class="inline-block"
    :delay="300"
  >
    <div
      v-if="data && data.id"
      class="border !border-[#E9EFF6] rounded-lg info-card hover:bg-gray-100 cursor-pointer"
      @click="$emit('preview-performance-post')"
    >
      <div class="relative rounded-lg" :class="childClasses">
        <slot name="media-content"></slot>
        <span
          class="absolute left-[0.25rem] bottom-[0.25rem] text-gray-900 text-xs py-0.5 px-2 bg-[#E3E8EB] rounded-full select-none text-center text-capitalize opacity-90"
          >{{ data.mediaType }}</span
        >
      </div>
      <slot name="card-footer">
        <div class="grid grid-cols-2 mt-1 w-full" :class="footerClasses">
          <span class="flex justify-center items-baseline w-full select-none">
            <img
              src="@src/assets/img/icons/analytic/engagement.svg"
              alt=""
              class="mr-0.5 h-[0.85rem]"
            />
            <span
              class="text-sm"
              :class="
                data[priorityMetric] < 0 ? 'text-red-500' : 'text-[#56C288]'
              "
            >
              {{ data[priorityMetric] }}
            </span>
          </span>
          <span class="flex justify-center items-center w-full select-none">
            <img
              src="@src/assets/img/icons/analytic/rate.svg"
              alt=""
              class="mr-0.5 h-[0.85rem]"
            />
            <span class="text-sm">{{ formattedEngagementRate }}</span>
          </span>
        </div>
      </slot>
    </div>

    <template v-slot:popper>
      <slot name="tooltip-content"></slot>
    </template>
  </v-menu>
</template>

<script setup>
// libraries
import { computed, defineProps } from 'vue'
import useNumber from '@common/composables/useNumber'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  childClasses: {
    type: String,
    default: 'h-[7.25rem] w-full',
  },
  footerClasses: {
    type: String,
    default: () => '',
  },
  priorityMetric: {
    type: String,
    default: 'engagement',
  },
})

defineEmits(['preview-performance-post'])

const { roundNumber } = useNumber()

// It uses a computed function to round the engagement rate to two decimal places
// and ensures precision handling using Number.EPSILON to avoid floating-point arithmetic errors.
const formattedEngagementRate = computed(() =>
  roundNumber(props.data?.engagementRate)
)
</script>

<style lang="scss" scoped>
.info-card {
  /* add a transition for a smooth effect */
  transition: box-shadow 0.2s ease-in-out;
  z-index: 1;
  position: relative;
}

.info-card:hover {
  /* set the box shadow on hover */
  box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px, rgba(0, 0, 0, 0.07) 0 2px 4px,
    rgba(0, 0, 0, 0.07) 0 4px 8px, rgba(0, 0, 0, 0.07) 0 8px 16px,
    rgba(0, 0, 0, 0.07) 0 16px 32px, rgba(0, 0, 0, 0.07) 0 32px 64px;

  /* increase the size on hover */
  transform: scale(1.1);

  /* set the z-index to a higher value */
  z-index: 2;
}
</style>
