<template>
  <div class="mx-8 ml-14 3xl:mr-40">
    <div class="my-4 flex items-center">
      <div class="w-2/12">Publish As</div>
      <div class="w-10/12 flex items-center">
        <div class="w-8/12 md:w-12/12">
          <CstRadio
            id="insta_direct_publishing"
            :disabled="disabledAPI"
            :model-value="instagramPostingMethod"
            class="mr-2 mt-2"
            name="insta_posting_options"
            value="api"
            @change="(val) => (instagramPostingMethod = val)"
          >
            <div class="flex flex-col">
              <span
                >Direct Publishing via API
                <a
                  v-tooltip.top="
                    'Learn about posting via Instagram API'
                  "
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="6017f963a4cefb30ae5c5e12"
                >
                  <i
                    class="far fa-question-circle ml-2 text-base text-blue-900"
                  ></i>
                </a>
              </span>
              <span class="text-gray-800 font-normal"
                >For Single/Multiple Images, Video/Reel and Stories Posts</span
              >
            </div>
          </CstRadio>
        </div>
        <div class="w-4/12 md:w-6/12">
          <CstRadio
            id="insta_mobile_notifications"
            :disabled="disabledMobile"
            :model-value="instagramPostingMethod"
            class="mr-2 mt-2"
            name="insta_posting_options"
            value="mobile"
            @change="(val) => (instagramPostingMethod = val)"
          >
            <div class="flex flex-col">
              <span
                >Mobile Notifications
                <a
                  v-tooltip.top="
                    'Learn about posting via Mobile Notifications'
                  "
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="5ff6b174fd168b77735325f3"
                >
                  <i
                    class="far fa-question-circle ml-2 text-base text-blue-900"
                  ></i>
                </a>
              </span>
              <span class="text-gray-800 font-normal"
                >For Stories and IGTV</span
              >
            </div>
          </CstRadio>
        </div>
      </div>
    </div>
      <div v-if="instagramPostingMethod === 'api'" class="my-5 flex items-baseline">
        <div class="w-2/12">Post Type</div>
        <div class="space-y-3 w-96">
          <CstDropdown class="w-full" size="small">
            <template v-slot:selected>
            <div class="flex flex-row space-x-2 py-1 items-center">
              <div v-if="!instagramPostType" class="flex gap-x-3 items-center">
                <img src="@src/assets/img/composer/default-option.svg" alt="Image icon" class="w-[0.9rem] h-[0.9rem]">
                <p class="text-sm leading-none">Select Post Type</p>
              </div>
              <div v-else class="flex gap-x-3 items-center">
                <img :src="instagramPostTypeObject[instagramPostType].selected_image" alt="Image icon" class="w-[0.9rem] h-[0.9rem] opacity-60">
                <p class="text-sm leading-none text-gray-800">{{ instagramPostTypeObject[instagramPostType].title }}</p>
              </div>
            </div>
          </template>

            <template v-slot>
            <CstDropdownItem v-for="(postType, key) in instagramPostTypeObject" :key="key" class="group point !pl-4 flex w-full justify-between items-center h-14" :class="{ 'opacity-50 cursor-not-allowed': isDropDownDisabled(key) }" @click="setInstagramPostType(key)">
                <div class="flex gap-x-3 items-center">
                  <img :src="isSelectedPostType(postType) ? postType?.selected_image : postType.inactive_image" alt="Image icon" class="w-[0.9rem] h-[0.9rem] opacity-60">
                  <div>
                   <p class="text-sm text-gray-900" :class="{ '!text-cs-blue': isSelectedPostType(postType) }">{{ postType.title }}</p>
                    <p class="text-xs text-gray-700">{{ postType.description }}</p>
                  </div>
                </div>
                <v-menu
                  :key="key"
                  class="mx-2 flex items-center gap-x-1 hidden group-hover:block"
                  :popper-triggers="['hover']"
                  placement="right"
                  :delay="0"
                  popper-class="first-comment__info-popover"
              >
                <!-- This will be the popover target (for the events and position) -->
                <a href="#"
                ><i class="far fa-question-circle text-base text-blue-900"></i
                ></a>
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <div v-if="instagramPostTypeObject[key]?.tooltip_content" v-html="instagramPostTypeObject[key]?.tooltip_content"></div>
                </template>
              </v-menu>
              </CstDropdownItem>
          </template>
        </CstDropdown>
          <div v-if="instagramPostType !== 'story'" class="flex w-full items-center">
            <div class="flex items-center space-x-2">
              <CstSwitch v-model="shareToStory" size="small"></CstSwitch>
              <p class="text-sm text-gray-900">Also Share to Story</p>
            </div>
            <v-menu
                class="mx-2 flex items-center gap-x-1"
                :popper-triggers="['hover']"
                placement="bottom-start"
                popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <a href="#"
              ><i class="far fa-question-circle text-base text-blue-900"></i
              ></a>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>Enable to share this post as an Instagram story alongside your feed, reel, or carousel post.
                  <a
                      v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d5a3804514782072c692"
                  >Learn more</a>
                </div>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
      <div
        v-if="instagramPostingMethod === 'api' && instagramPostType !== 'story'"
        class="my-4 flex items-baseline"
      >
        <div class="w-2/12">Collaborators</div>
        <div class="w-10/12 basic_form flex items-baseline">
          <div class="input_field !mt-1 w-full 3xl:w-[72%]">
            <div class="multi_select relative">
              <div class="multi_input">
                <input
                  v-model="collaboratorHandle"
                  class="pr-0 color-border"
                  type="text"
                  placeholder="Invite up to 3 collaborators by typing their usernames. Only public profiles can be added."
                  @keyup.enter="addCollaboratorToList($event)"
                  @keyup.188="addCollaboratorToList($event)"
                />
              </div>
              <div v-if="collaboratorHandle?.trim()" class="border mt-1 shadow-lg rounded p-4 z-10 bg-white absolute">
                  <div class="flex justify-between items-center">
                    <p class="flex items-center">
                      <i class="fab fa-instagram inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg text-cs-red"></i>
                      {{ collaboratorHandle }}
                    </p>
                    <a
                    class="text-xs text-blue-400 hover:text-blue-500 cursor-pointer inline-flex"
                    :href="`https://www.instagram.com/${collaboratorHandle}/`"
                    target="_blank"
                  >
                    Verify<span class="truncate inline-block max-w-xxs mx-1"
                      >@{{ collaboratorHandle?.trim() }}</span
                      >on Instagram
                      <i class="far fa-external-link ml-1.5"></i>
                    </a>
                  </div>
                  <p class="text-sm bg-blue-50 p-3 my-5">
                    Due to Instagram API limitations, we cannot display matching profiles. Please enter valid public profiles. Otherwise, the post will not publish successfully. <a
                v-close-popper
                href="#"
                class="beacon"
                data-beacon-article-modal="661f9d173238e52d4b87388f"
                >Learn more</a>
                  </p>
                  <div class="text-right">
                    <button
                      class="bg-cs-primary rounded-md focus:outline-none text-cs-white border-0 px-4 py-3"
                      @click="addCollaboratorToList($event)"
                      >Save Collaborator
                    </button>
                  </div>
              </div>
              <div v-if="collaboratorsList?.length" class="multi_tags !p-0">
                <ul>
                  <li
                    v-for="(item, key) in collaboratorsList"
                    :key="key"
                    class="green cursor-pointer"
                    @click="redirectToInstagram(item)"
                  >
                    <div class="py-1.5">
                      <span v-tooltip="{ content: `Click to verify ${item} account exists on Instagram.` }" class="value mr-1">{{ item }}</span>
                      <span class="remove" @click.stop="removeCollaboratorFromList(key)">&times;</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <v-menu
              class="mx-2 flex items-center gap-x-1"
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#"
            ><i class="far fa-question-circle text-base text-blue-900"></i
          ></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div>Boost engagement with Instagram Collaborator Posts! Invite collaborators to add their username to the post and share it with their followers.
              <a
                v-close-popper
                href="#"
                class="beacon"
                data-beacon-article-modal="661f9d173238e52d4b87388f"
                >Learn more</a>
            </div>
            <div class="mt-4"><strong>Note:</strong> Collaborators cannot be invited to story posts.</div>
          </template>
          </v-menu>
        </div>
      </div>
<!--      <div-->
<!--        v-if="instagramPostingMethod === 'api' && instagramPostType === 'story'"-->
<!--        class="my-2 flex items-center"-->
<!--      >-->
<!--        <div class="w-2/12"></div>-->
<!--        <div class="w-10/12 flex items-center">-->
<!--          <div class="bg-orange-100 text-orange-700 rounded-lg p-3">-->
<!--              <span class="leading-4">-->
<!--                <span class="font-semibold">Story Post Requirements:</span>-->
<!--                Only one image or video should be included in the post. The-->
<!--                duration of any video content should fall within the range of 4-->
<!--                to 60 seconds. It is recommended to maintain an aspect ratio of-->
<!--                9:16.-->
<!--              </span>-->
<!--            <template v-else>-->
<!--              <span class="leading-4">-->
<!--                <span class="font-semibold">Reels Tab Eligibility:</span>-->
<!--                Only reels with a duration between 5 seconds to 15 minutes and aspect-->
<!--                ratios of 9:16 are eligible to appear in the Reels tab.-->
<!--              </span>-->
<!--              <span class="block mt-1 leading-4">-->
<!--                Due to specific protocols set by the Instagram algorithm, the-->
<!--                video file may not appear in the Reels tab if they don't meet-->
<!--                the eligibility criteria, it will be available in the Feed-->
<!--                section only.-->
<!--                <a-->
<!--                  href="#"-->
<!--                  data-beacon-article-modal="62e10979b828a850e9e61a35"-->
<!--                  target="_blank"-->
<!--                  >Learn more.</a-->
<!--                >-->
<!--              </span>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstSwitch from "@ui/Switch/CstSwitch.vue";
export default {
  name: 'InstagramOptions',
  components: { CstRadio, CstDropdown, CstDropdownItem, CstSwitch },
  mixins: [ComposerHelperMixin],
  props: {
    instagramSelection: {
      type: Object,
      default: () => {},
    },
    instagramPostType: {
      type: String,
      default: '',
    },
    isVideoPost: {
      type: Boolean,
      default: false,
    },
    isSingleImagePost: {
      type: Boolean,
      default: false,
    },
    isCarouselPost: {
      type: Boolean,
      default: false,
    },
    instaPostingMethod: {
      type: String,
      default: null,
    },
    instagramCollaborators: {
      type: Array,
      default: [],
    },
    instagramShareToStory: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      disabledAPI: false,
      disabledMobile: false,
      collaboratorHandle: '',
      collaboratorsList: [],
      shareToStory: false,
      instagramPostTypeObject: {
        feed: {
          inactive_image: '/img/instagram-feed-icon-active.svg',
          active_image: '/img/instagram-feed-icon-active-blue.svg',
          selected_image: '/img/instagram-feed-icon-active-blue.svg',
          title: 'Feed',
          description: 'Only single image allowed',
          new: false,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Feed</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Only single image allowed</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : JPEG (JPG), PNG</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 8 MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: must be within a 4:5 to 1.91:1 range</li>
            </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d8491dd56877041fb391"
                  >Learn more</a>
          `
        },
        reel: {
          inactive_image: '/img/instagram-reels-icon-active.svg',
          active_image: '/img/instagram-reels-icon-active-blue.svg',
          selected_image: '/img/instagram-reels-icon-active-blue.svg',
          title: 'Reel',
          description: 'Only single video allowed',
          new: false,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Reel</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Only single video allowed</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 15 mins maximum, 3 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 1GB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommend 9:16 to avoid cropping or blank space</li>
            </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d8491dd56877041fb391"
                  >Learn more</a>
          `
        },
        feed_reel: {
          inactive_image: '/img/instagram-reels-plus-feed-icon-active.svg',
          active_image: '/img/instagram-feed-icon-active.svg',
          selected_image: '/img/instagram-reels-plus-feed-icon-blue.svg',
          title: 'Feed + Reel',
          description: 'Only single video allowed',
          new: false,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Feed + Reel</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Only single video allowed</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 15 mins maximum, 3 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 1GB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommend 9:16 to avoid cropping or blank space</li>
            </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d8491dd56877041fb391"
                  >Learn more</a>
          `
        },
        story: {
          inactive_image: '/img/instagram-stories-icon-active.svg',
          active_image: '/img/instagram-stories-icon-active-blue.svg',
          selected_image: '/img/instagram-stories-icon-active-blue.svg',
          title: 'Story',
          description: 'Single or up to 10 images, videos, or a mix',
          new: true,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Story</p>
            <p class="text-gray-800 my-1 text-sm">Single or up to 10 images, videos, or a mix</p>
            <p class="font-bold text-gray-900 text-sm">Image</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : JPEG (JPG), PNG</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 8 MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 9:16 to avoid cropping or blank space</li>
             </ul>
            <p class="font-bold text-gray-900 text-sm">Video</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 100MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 60 seconds maximum, 3 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 9:16 to avoid cropping or blank space</li>
             </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d5a3804514782072c692"
                  >Learn more</a>
          `
        },
        carousel: {
          inactive_image: '/img/instagram-carousel-icon-active.svg',
          active_image: '/img/instagram-feed-icon-active.svg',
          selected_image: '/img/instagram-carousel-icon-active-blue.svg',
          title: 'Carousel',
          description: 'Multiple or Up to 10 images, videos, or a mix',
          new: true,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Carousel</p>
            <p class="text-gray-800 my-1 text-sm">Multiple or Up to 10 images, videos, or a mix</p>
            <p class="font-bold text-gray-900 text-sm">Image</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : JPEG (JPG), PNG</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 8 MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 9:16 to avoid cropping or blank space</li>
             </ul>
            <p class="font-bold text-gray-900 text-sm">Video</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 100MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 60 seconds maximum, 3 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 1:1 to avoid cropping or blank space</li>
             </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6641f962c49e92656329f1e9"
                  >Learn more</a>
          `
        }
      }
    }
  },
  watch: {
    instagramSelection: {
      handler() {
        this.showInstagramOptions()
      },
      deep: true,
    },
    instagramPostingMethod: function (newVal) {
      if(newVal === 'mobile') {
        this.shareToStory = false
        // this.setInstagramPostType('feed') // no need to change post type when posting method change
      }
      this.$emit('setInstaPostingApi', newVal)
    },
    shareToStory (val) {
      this.$emit('setInstagramShareToStory', val)
    },
    instagramShareToStory (val) {
      this.shareToStory = val
    },
    instagramCollaborators: {
      handler() {
        this.collaboratorsList = this.instagramCollaborators
      },
      deep: true,
    },
  },
  created() {
    this.collaboratorsList = this.instagramCollaborators
    this.shareToStory = this.instagramShareToStory
  },
  methods: {
    /**
     * this method is responsible for disabling the dropdown items
     * @param key
     * @returns {boolean|boolean}
     */
    isDropDownDisabled(key) {
      return (this.isVideoPost && ['feed', 'carousel'].includes(key)) ||
          (this.isSingleImagePost && ['reel', 'feed_reel', 'carousel'].includes(key)) ||
          (this.isCarouselPost && ['feed', 'reel', 'feed_reel'].includes(key)) ||
          (!this.isVideoPost && !this.isSingleImagePost && !this.isCarouselPost && ['feed', 'reel', 'feed_reel', 'story', 'carousel'].includes(key))
    },
    /**
     * redirects to instagram profile
     * @param {*} instagramId
     */
    redirectToInstagram(instagramId) {
      window.open(`https://www.instagram.com/${instagramId}/`, '_blank');
    },
    /**
     * Adding collaborator to the list
     * @param {*} event
     */
    addCollaboratorToList(event) {
      let handle = this.collaboratorHandle
      // check if it is a comma, then substring last element which will be a comma
      if (event.key === ',') {
        handle = handle.substring(0, handle.length - 1)
      }

      // if it is a valid one push to the list and empty the v-model value
      if (this.collaboratorsList.includes(handle)) {
        // not a duplicate email
        this.$store.dispatch('toastNotification', {
          message: 'Instagram Collaborator already added.',
          type: 'error',
        })
        return
        }

      const MAX_COLLABORATORS = 3
      if (this.collaboratorsList.length >= MAX_COLLABORATORS) {
        this.$store.dispatch('toastNotification', {
          message: 'You can only add up to 3 Instagram Collaborators.',
          type: 'error',
        })
        return
      }

      this.collaboratorsList.push(handle)
      this.collaboratorHandle = null
      this.$emit('setInstagramCollaborators', this.collaboratorsList)
    },
    removeCollaboratorFromList(index) {
      this.collaboratorsList.splice(index, 1)
      this.$emit('setInstagramCollaborators', this.collaboratorsList)
    },
    /**
     * Checks for and assigns instagram api type
     */
    showInstagramOptions() {
      console.log('METHOD::showInstagramOptions')
      let postingMethod = this.instaPostingMethod
      this.disabledAPI =
        this.instagramSelection.no_access_accounts.length > 0 &&
        this.instagramSelection.all_access_accounts.length === 0
      this.disabledMobile = this.getMobileDevices.length === 0

      if (!this.instaPostingMethod) {
        postingMethod = !this.disabledAPI
          ? 'api'
          : !this.disabledMobile
          ? 'mobile'
          : null
      } else {
        if (
          this.disabledAPI &&
          !this.disabledMobile &&
          this.instaPostingMethod === 'api'
        ) {
          postingMethod = 'mobile'
        } else if (
          !this.disabledAPI &&
          this.disabledMobile &&
          this.instaPostingMethod === 'mobile'
        ) {
          postingMethod = 'api'
        } else if (this.disabledAPI && this.disabledMobile) {
          postingMethod = null
        }
      }

      this.instagramPostingMethod = postingMethod
      this.$emit('setInstaPostingApi', postingMethod)
    },
    setInstagramPostType(val) {
      if(this.isDropDownDisabled(val)) return
      if(val === 'story') {
        this.shareToStory = false
      }
      this.$emit('setInstagramPostType', val)
    },
    isSelectedPostType(postType) {
      if (postType?.title === 'Feed + Reel') {
        return this.instagramPostType === 'feed_reel'
      }
      return this.instagramPostType === postType?.title?.toLowerCase()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
