<template>
  <div
    v-if="getTopicSelection.sources == 'topics_and_keywords'"
    class="multi_select"
  >
    <div class="d-flex">
      <p class="sub_label"
        >Each result must contain at least <span class="bold">ONE</span> of
        these keywords</p
      >
      <button
        v-if="
          getTopicSelection.any_keywords.length > 0 ||
          (anyKeywordValue !== null &&
            anyKeywordValue.replace(/^\s+/, '') !== '')
        "
        class="reset_btn"
        @click="clearAllKeywords"
        ><i class="fa fa-times"></i> Clear</button
      >
    </div>

    <div class="multi_input">
      <input
        v-model="anyKeywordValue"
        class="prevent-listener"
        type="text"
        placeholder="Enter your main keywords or phrases, e.g. Social Media, Big Data…"
        data-cy="main-keyword"
        @keyup.enter="
          addAnyKeyword(
            getTopicSelection.any_keywords,
            anyKeywordValue,
            'any_keyword',
          )
        "
        @keyup.188="
          addAnyKeyword(
            getTopicSelection.any_keywords,
            anyKeywordValue,
            'any_keyword',
          )
        "
      />
      <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
      <!--<div class="multi_dropdown">
                <div class="multi_dropdown_inner">
                    <ul>

                        <li class="item">cnn.com </li>
                        <li class="item">bbc.com</li>
                    </ul>
                </div>
            </div>-->
    </div>
    <template v-if="getTopicSelection.any_keywords">
      <div class="multi_tags">
        <ul>
          <li
            v-for="(any_keyword, index) in getTopicSelection.any_keywords"
            :key="index"
            class="green"
          >
            <span class="value">{{ any_keyword }}</span>
            <span
              class="remove"
              @click.prevent="
                removeKeyword(getTopicSelection.any_keywords, index)
              "
              >&times;</span
            >
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      anyKeywordValue: null
    }
  },
  methods: {
    clearAllKeywords () {
      this.anyKeywordValue = ''
      this.getTopicSelection.any_keywords = []
      // document.querySelector('.multi_tags ul').innerHTML = ""
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection'])
  }
}
</script>
