<template>
  <div
    class="footer hr-color w-full z-50 px-3 py-4 flex justify-between items-center bg-cs-desertStorm"
  >
    <!-- Errors and warning box -->
    <div
      v-if="errorsBox && (errors.length || warnings.length)"
      :style="{ background: 'rgb(251,245,246)' }"
      class="absolute rounded-t-lg p-3 border border-1 h-28 left-0 bottom-16 flex flex-col w-full space-y-2 overflow-auto"
    >
      <!--remove inline style-->

      <div
        v-for="(item, index) in errors"
        :key="`error_${index}`"
        class="flex items-center"
      >
        <img
          src="@assets/img/composer/error-icon.svg"
          alt=" "
          class="w-5 h-5 mx-2"
        />
        <span v-html="item.text"></span>
      </div>

      <div
        v-for="(item, index) in warnings"
        :key="`warning_${index}`"
        class="flex items-center"
      >
        <img
          src="@assets/img/composer/warning-icon.svg"
          alt=" "
          class="w-5 h-5 mx-2"
        />
        <span v-html="item.text"></span>
      </div>
    </div>

    <div class="flex text-base" @click="errorsBox = !errorsBox">
      <div
        v-if="errors.length"
        v-tooltip.top="'Errors'"
        class="flex items-center justify-center cursor-pointer mx-6"
      >
        <img
          src="@assets/img/composer/error-icon.svg"
          alt=" "
          class="w-6 h-6"
        />
        <span class="mx-2 text-black-200 text-xs">({{ errors.length }})</span>
      </div>
      <div
        v-if="warnings.length"
        v-tooltip.top="'Warnings'"
        class="flex items-center justify-center cursor-pointer mx-6"
      >
        <img
          src="@assets/img/composer/warning-icon.svg"
          alt=" "
          class="w-6 h-6"
        />
        <span class="mx-2 text-black-200 text-xs">({{ warnings.length }})</span>
      </div>
      <div
        v-if="info.length"
        v-tooltip.top="'Info'"
        class="flex items-center justify-center cursor-pointer mx-6"
      >
        <img src="@assets/img/composer/info-icon.svg" alt=" " class="w-6 h-6" />
        <span class="mx-2 text-black-200 text-xs">({{ info.length }})</span>
      </div>
    </div>

    <div v-if="!isApprover" class="mr-5 flex flex-row space-x-4">
      <div
        v-if="
          publishTimeOptions.time_type === PUBLISH_OPTIONS_TIME.DRAFT &&
          publishTimeOptions.time_type !==
            PUBLISH_OPTIONS_TIME.CONTENT_CATEGORY &&
          !queueStatus &&
          getTeamMembership !== WORKSPACE_MEMBER_TYPES.CLIENT
        "
        class="flex space-x-2 items-center"
      >
        <CstSimpleCheckbox
          id="underReviewApproval"
          :value="publishTimeOptions.hide_client"
          reverse
          @change="onClickHideClient"
        >
          <template v-slot:label>Hide from client</template>
        </CstSimpleCheckbox>
      </div>
      <button
        v-if="!globalContentCategory && !queueStatus"
        :class="{
          'cursor-not-allowed text-blue-300 opacity-50':
            isDisabledPostingBtn ||
            isApprovalBtnDisabled ||
            isNextSlotAvailable ||
            publishTimeOptions.time_type === PUBLISH_OPTIONS_TIME.NOW ||
            disableSchedule,
          'bg-cs-primary': !(
            isDisabledPostingBtn ||
            isApprovalBtnDisabled ||
            isNextSlotAvailable
          ),
        }"
        :disabled="
          isDisabledPostingBtn ||
          isNextSlotAvailable ||
          isApprovalBtnDisabled ||
          publishTimeOptions.time_type === PUBLISH_OPTIONS_TIME.NOW ||
          disableSchedule
        "
        class="bg-cs-primary rounded-md focus:outline-none text-cs-white border-0 px-4 py-2"
        @click="onClickSendApproval"
        >Send for Approval
      </button>
      <div
        v-click-away="handleActionClickAway"
        class="flex items-center relative"
      >
        <button
          :class="buttonClasses"
          :disabled="isButtonDisabled"
          class="bg-cs-primary rounded-l-md focus:outline-none text-cs-white border-0 px-3 py-2"
          @click="onClickActionButton"
          >{{ getButtonText }}
        </button>
        <button
          :class="buttonClasses"
          :disabled="isButtonDisabled"
          class="bg-cs-primary rounded-r-md focus:outline-none text-cs-white border-0 ml-px px-2 h-full"
          @click="
            () => {
              actionOptionsToggle = !actionOptionsToggle
            }
          "
        >
          <i
            class="fa fa-chevron-up"
            :class="{ 'fa-rotate-180': actionOptionsToggle }"
          ></i>
        </button>
        <div
            v-show="actionOptionsToggle"
            class="absolute bottom-11 right-0 flex flex-col w-max max-w-sm rounded-xl bg-white p-2"
            :class="{ '-top-28': publishTimeOptions.time_type === 'queued' || (publishTimeOptions.time_type === 'schedule' && publishTimeOptions.is_custom_schedule) }"
            style="box-shadow: rgb(184 189 209 / 30%) 1px 1px 10px 0"
        >
          <div
              class="w-full p-1 text-white"
          >
            <div
                v-tooltip.top="{
                    content: `${getButtonText} and reopen blank composer`
                    }"
                class="w-full px-2 py-2.5 cursor-pointer hover:bg-cs-foggray rounded-lg"
                @click="onClickActionButton('reopen-blank')"
            >
                <span
                    class="text-sm text-gray-900 font-medium"
                >{{ getButtonText }} & compose new</span
                >
            </div>
          </div>
          <div
              v-if="publishTimeOptions.time_type !== 'queued' && !(publishTimeOptions.time_type === 'schedule' && publishTimeOptions.is_custom_schedule)"
              class="w-full p-1 text-white"
          >
            <div
                v-tooltip.top="{
                    content: `${getButtonText} and reopen duplicate composer`
                    }"
                class="px-2 py-2.5 cursor-pointer hover:bg-cs-foggray rounded-lg"
                @click="onClickActionButton('reopen-duplicate')"
            >
                <span class="text-sm text-gray-900 font-medium"
                >{{ getButtonText }} & compose new with duplicate
                  content</span
                >
            </div>
          </div>
          <div
              class="w-full p-1 text-white"
          >
            <div
                v-tooltip.top="{
                      content: `${getButtonText} and reopen blank composer keeping the selected accounts`
                      }"
                class="px-2 py-2.5 cursor-pointer hover:bg-cs-foggray rounded-lg"
                @click="onClickActionButton('reopen-with-account')"
            >
                <span class="text-sm text-gray-900 font-medium"
                >{{ getButtonText }} & compose new with same accounts</span
                >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="mr-5 flex flex-row space-x-2">
      <button
        :class="{
          'cursor-not-allowed text-blue-300 opacity-50': isDisabledPostingBtn,
          'bg-cs-primary': !isDisabledPostingBtn,
        }"
        :disabled="isDisabledPostingBtn"
        class="bg-cs-primary rounded-md focus:outline-none text-cs-white border-0 px-4 py-2"
        @click="saveApproverChanges"
        >Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import { approvalStages } from '@src/modules/publish/store/states/mutation-types'
import {
  PUBLISH_OPTIONS_TIME,
  PLAN_STATUS,
  WORKSPACE_MEMBER_TYPES,
} from '@common/constants/composer'
const clone = require('rfdc/default')
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'MainComposerFooter',
  components: {
    CstSimpleCheckbox,
  },
  props: {
    warnings: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Array,
      default: () => [],
    },
    publishTimeOptions: {
      type: Object,
      default: () => {},
    },
    globalContentCategory: {
      type: Boolean,
      default: false,
    },
    nextContentCategorySlot: {
      type: Object,
      default: () => {},
    },
    postHasErrors: {
      type: Boolean,
      default: false,
    },
    approvalData: {
      type: Object,
      default: () => {},
    },
    isApprover: {
      type: Boolean,
      default: false,
    },
    queueStatus: {
      type: [Boolean, Number],
      default: false,
    },
    postStatus: {
      type: String,
      default: 'draft',
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    processPlanLoader: {
      type: Boolean,
      default: false,
    },
    approverModalValidation: {
      type: Function,
      default: () => {},
    },
    clearState: {
      type: Function,
      default: () => {},
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    disableScheduleButton: {
      type: Boolean,
      default: false,
    },
    selectedAccountsLength: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      PUBLISH_OPTIONS_TIME,
      WORKSPACE_MEMBER_TYPES,
      PLAN_STATUS,
      errorsBox: false,
      elementsToHide: ['notificationCarousel'],
      isApprovalBtnDisabled: false,
      approvalModalVisibility: false,
      actionOptionsToggle: false,
      forceDisableButton: false,
    }
  },
  computed: {
    isButtonDisabled() {
      if (this.publishTimeOptions.time_type === 'draft') {
        return false;
      }
      return this.isDisabledPostingBtn || this.isNextSlotAvailable || this.isImageUploading || this.disableSchedule;
    },

    buttonClasses() {
      if (this.publishTimeOptions.time_type === 'draft') {
        return 'bg-cs-primary';
      }

      return {
        'cursor-not-allowed text-blue-300 opacity-50':
            this.isDisabledPostingBtn || this.isNextSlotAvailable || this.disableSchedule,
        'bg-cs-primary': !(this.isDisabledPostingBtn || this.isNextSlotAvailable || this.disableSchedule),
      };
    },
    getButtonText() {
      if (this.queueStatus) {
        return 'Update'
      }
      const timeType = this.publishTimeOptions.time_type
      if (timeType === 'now') {
        return 'Post Now'
      } else if (timeType === 'schedule') {
        return 'Schedule'
      } else if (timeType === 'queued') {
        return 'Add to Queue'
      } else if (timeType === 'content_category') {
        return 'Add to Category'
      } else if (timeType === 'draft') {
        return 'Save Draft'
      }
      return ''
    },
    isNextSlotAvailable() {
      if (
        this.publishTimeOptions.time_type ===
          this.PUBLISH_OPTIONS_TIME.CONTENT_CATEGORY &&
        !this.globalContentCategory
      ) {
        return !this.nextContentCategorySlot
      }
      return false
    },
    isDisabledPostingBtn() {
      return (
        this.postHasErrors ||
        this.processPlanLoader ||
        this.postStatus === 'published'
      )
    },
    /**
     * get state of image uploading
     * @returns {boolean}
     */
    isImageUploading() {
      return this.uploadingAiImages
    },
    /**
     * responsible for disabling schedule button
     * @returns {boolean}
     */
    disableSchedule() {
      const isScheduleType = this.publishTimeOptions.time_type === 'schedule';
      return (
          (this.forceDisableButton || this.disableScheduleButton) &&
          isScheduleType &&
          !this.publishTimeOptions.is_custom_schedule
      );
    },
  },
  mounted() {
    console.debug('model footer mounted')
    console.log('PUBLISH_OPTIONS_TIME', this.PUBLISH_OPTIONS_TIME)
    this.elementsToHide.map((elementId) => {
      const element = document.getElementById(elementId)
      if (element) element.classList.add('hidden')
    })

    EventBus.$on('approval-selected', async (approversData) => {
      this.publishTimeOptions.hide_client = false
      this.publishTimeOptions.plan_status = this.PLAN_STATUS.UNDER_REVIEW
      this.$bvModal.hide('approval-modal')
      console.log('approval-selected', approversData)
      await this.$emit('set-approval', approversData)
      if (
        this.publishTimeOptions.time_type === this.PUBLISH_OPTIONS_TIME.DRAFT
      ) {
        this.$emit('processSocialShare', true, true, false, '', true, '')
      } else {
        this.$emit('processSocialShare', false, false, false, '', true, '')
      }
    })

    EventBus.$on('force-disable-schedule-button', () => {
      this.forceDisableButton = true
    })
  },
  beforeUnmount() {
    EventBus.$off('approval-selected')
    this.elementsToHide.map((elementId) => {
      const element = document.getElementById(elementId)
      if (element) element.classList.remove('hidden')
    })
  },
  methods: {
    handleActionClickAway() {
      this.actionOptionsToggle = false
    },
    onClickActionButton(buttonType = '') {
      console.log('METHOD::onClickActionButton ~ buttonType -> ', buttonType)
      if (this.publishTimeOptions.time_type === 'draft') {
        this.$emit(
          'processSocialShare',
          true,
          false,
          false,
          '',
          false,
          buttonType
        )
      } else if (
        this.socialPostType === 'edit' &&
        this.publishTimeOptions.time_type === this.PUBLISH_OPTIONS_TIME.SCHEDULE
      ) {
        this.publishTimeOptions.plan_status = PLAN_STATUS.APPROVED
        this.$emit(
          'processSocialShare',
          false,
          false,
          false,
          '',
          false,
          buttonType
        )
      } else {
        this.$emit(
          'processSocialShare',
          false,
          false,
          false,
          '',
          false,
          buttonType
        )
      }
      // reinitialize image generation modal states
      this.clearState()
      this.actionOptionsToggle = false
    },
    saveApproverChanges() {
      console.log('METHOD::saveApproverChanges')
      this.$emit('processSocialShare')
    },
    onClickSendApproval() {
      console.log('METHOD::onClickSendApproval')
      const data = clone(this.approvalData)
      if (
        data &&
        data.status &&
        data.status !== 'completed_approval' &&
        data.approvers &&
        data.approvers.length > 0
      ) {
        this.approvalData.status = 'pending_approval'
        this.approvalData.approvers.forEach(function (element) {
          if (element.status === 'reject') {
            element.status = 'pending'
            element.last_action_note = ''
            element.last_action_time = ''
            element.notification = 'pending'
          }
        })
        console.log('METHOD::onClickSendApproval ~ data -> ', this.approvalData)
        this.$store.commit(approvalStages.SET_APPROVAL_DATA, this.approvalData)

        const checkValidation = this.approverModalValidation()
        if (checkValidation) {
          EventBus.$emit('previous-approval-data', this.approvalData)
          this.$bvModal.show('approval-modal')
        } else {
          this.$bvModal.hide('approval-modal')
        }
      } else {
        console.log('new post or no approvers')
        const checkValidation = this.approverModalValidation()
        if (checkValidation) {
          EventBus.$emit('previous-approval-data', false)
          this.$bvModal.show('approval-modal')
        } else {
          this.$bvModal.hide('approval-modal')
        }
      }
    },
    onClickHideClient(event) {
      console.log('METHOD::onclickHideClient ~ event -> ', event.target.checked)
      this.publishTimeOptions.hide_client = event.target.checked
      this.isApprovalBtnDisabled = event.target.checked
    },
  },
}
</script>

<style lang="scss">
.post-action-popover {
  .trigger {
    display: block !important;
    width: 100%;
  }
}
</style>
