<template>
  <div>
    <div class="filter grid grid-cols-2 gap-1 w-100">
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{sort}}
        </template>
        <template v-slot>
          <CstDropdownItem  @click="handleSort('Popular')">
            Popular
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSort('Latest')">
            Latest
          </CstDropdownItem>
        </template>
      </CstDropdown>
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{type}}
        </template>
        <template v-slot>
          <CstDropdownItem  @click="handleType('All')">
            All
          </CstDropdownItem>
          <CstDropdownItem  @click="handleType('Photo')">
            Photo
          </CstDropdownItem>
          <CstDropdownItem  @click="handleType('Illustration')">
            Illustration
          </CstDropdownItem>
          <CstDropdownItem  @click="handleType('Vector')">
            Vector
          </CstDropdownItem>
        </template>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length === 0" class="p-3" >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template  v-else-if="posts && posts.length > 0">
        <div class="grid grid-cols-2 gap-2 mt-2">
          <template v-for="(post, index) in posts" :key="index">
            <div
                 :draggable="true"
                 class="w-100 rounded-lg bg-cs-white border hover:bg-cs-foggray hover:shadow-sm"
                 @dragstart="dragstartHandler($event, post)">
              <div
                  class="img w-100 h-32 relative bg-cover bg-no-repeat bg-center rounded-tl-xl rounded-tr-xl bg-cs-white cursor-pointer"
                  :style="{ 'background-image': 'url(' + post.webformatURL + ')' }"
                  @click="handleImagePreview(post.webformatURL)">
                <span class="w-100 h-100 rounded-tl-xl rounded-tr-xl flex items-center justify-center opacity-0"><i class="far fa-eye text-lg text-white"></i></span>
              </div>
              <div class="grid grid-cols-1 py-2">
                <div v-tooltip.top="'Views'" class="flex items-center justify-center">
                  <p>
                    <i class="cs-eye"></i>
                  </p>
                  <h3 class="text-sm ml-1">{{ intToString(post['views']) }}</h3>
                </div>
              </div>
            </div>
          </template>
        </div>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template v-slot:no-results>
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
    </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>

  </div>
</template>

<script>
/**
 * @param post.webformatURL          Information about the object.
 *  Information about the object's members.
 */
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
export default {
  name: "Pixabay",
  components: {
    InfiniteLoading,
    CstDropdown,
    CstDropdownItem,
    NoResult
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sort: {
      type: String,
      default: ''
    },
    type:{
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch:{

  },
  methods:{
    dragstartHandler (ev, photo) {
      console.debug('dragstartHandler', ev, photo)
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'image',
            heading: '',
            media: photo.webformatURL,
            p: photo.tags || '',
            link: photo.webformatURL
          })
      )
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleSort (value) {
      this.$emit('handle-change','sort', value)
    },
    handleType (value) {
      this.$emit('handle-change','type', value)
    },
    handleImagePreview(photo){
      this.$emit('handle-change','preview',photo)
    },
  }
}
</script>

<style lang="scss" scoped>
.img:hover{
  //box-shadow: 0 2px 15px 0 #b8bdd1;
  span{
    background: #00000037;
    opacity: 1!important;
  }
}
</style>
