// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'
import {ref} from "vue";


export default function usePostingActivityTableByType () {
    const {fetchCompetitorInfo: fetchPostingActivityTableByType, apiResponse: postingActivityTableByTypeResponse, loading: postingActivityTableByTypeLoadingState} = useCompetitorsFactory()
    const sortOrder = ref(null)

    const {reportId, dateRange, dateRangeDiff, timeZone} = useCompetitorReport()

    const getPostingActivityTableByType = async (type = '', mediaType = '', mediaProductType = '') => {
        switch (type) {
            case 'instagram':
                await fetchInstagramPostingActivityTableByType(mediaType, mediaProductType)
                break
            default:
                break
        }
    }
    const fetchInstagramPostingActivityTableByType = async (mediaType, mediaProductType) => {
        if(!reportId.value) return

        const payload = {
            _id: reportId.value,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
            sort_order: sortOrder.value || 'count',
            media_type: mediaType,
            media_product_type: mediaProductType,
        }

        try{
            await fetchPostingActivityTableByType(COMMON_API_ENDPOINTS.POSTING_ACTIVITY_TABLE_BY_TYPE, payload, COMMON_ERROR_MESSAGES.POSTING_ACTIVITY_TABLE_BY_TYPE)
        }catch(error){}
    }

    return {
        // data
        dateRangeDiff,
        sortOrder,
        reportId,
        postingActivityTableByTypeResponse,
        postingActivityTableByTypeLoadingState,

        // methods
        getPostingActivityTableByType
    }
}