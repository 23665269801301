import { mapActions, mapGetters, mapMutations } from 'vuex'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { isAuthenticatedURL } from '@src/config/api-utils'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import { authenticationTypes } from '@state/mutation-types'
import proxy from '@src/modules/common/lib/http-common'

const listener = {
  data() {
    return {
      broadcastChannels: [
        'mention_in_comment:',
        'new_comment:',
        'new_post_added:',
        'new_task_assigned:',
        'mine_assigned_task_completed:',
        'post_approval:',
        'review_missed:',
        'bulk_post_approval:',
        'automation_content_planned:',
        'account_expire:',
        'account_expired:',
        'comment_in_my_post:',
      ],
      allChannels: [
        'mention_in_comment:',
        'new_comment:',
        'new_post_added:',
        'new_task_assigned:',
        'mine_assigned_task_completed:',
        'post_approval:',
        'review_missed:',
        'bulk_post_approval:',
        'automation_content_planned:',
        'account_expire:',
        'workspace_added:',
        'workspace_joined:',
        'member_removed:',
        'workspace_removed:',
        'role_changed:',
        'opml_import_completed:',
        'feedly_import_completed:',
        'logout:',
        'post_publish:',
        'missed_review:',
        'plan_approval:',
        'comment_in_my_post:',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getProfile',
      'getPublishSelection',
      'getComposerActivePlanMember',
      'getSocketId',
      'getRssAutomationListing',
      'getActivities',
      'getPlanKey',
    ]),
  },
  methods: {
    ...mapActions([
      'addToNotifications',
      'fetchOPMLHistory',
      'automationEvergreenContentPlanned',
      'composerEditingCallBack',
      'composerPlanView',
      'composerPlanClose',
      'planControlRequest',
      'fetchComments',
      'fetchTasks',
      'setPublishLabels',
      'setInboxNotificationBroadcast',
    ]),
    ...mapMutations([
      'SET_CSV_TOTAL_PROCESSING',
      'SET_CSV_PROCESSED_POSTS',
      'SET_CSV_COMPLETED_POSTS',
      'SET_CSV_VALID_POSTS',
    ]),

    /**
     * remove all the listeners (This will be precautionary called to remove duplication in notifications)
     */
    removeSocketBroadcastListeners(userId) {
      console.debug('Method:removeSocketBroadcast')
      // eslint-disable-next-line no-undef
      $(this.allChannels).each(function (index, el) {
        window.socket.removeListener(el + userId)
      })
      window.webSockets.removeListener(`logout:${userId}`)
      window.webSockets.removeListener('new-version-released')
    },
    removeSocketAllChannelListeners(userId) {
      console.debug('Method:removeSocketAllChannelListeners', userId)
      // eslint-disable-next-line no-undef
      $(this.allChannels).each(function (index, el) {
        window.socket.removeListener(el + userId)
      })
    },

    checkActiveWorkspace(data) {
      if (this.getWorkspaces.activeWorkspace._id === data.workspace_id)
        return true
      return false
    },

    removedMemberListener(data, self) {
      self.fetchWorkspaces()
      if (self.checkActiveWorkspace(data)) {
        self.$router.push({ name: 'workspaces' })
      } else {
        self.addToNotifications(data)
        self.resetDefaultStates()
        self.initializeSection()
      }
      if (data.dashboard)
        self.alertMessage('<p>' + data.description + '. </p>', 'info')
      if (data.browser) self.displayBrowserNotifications(data)
    },

    removedWorkspaceListener(data, self) {
      self.fetchWorkspaces()
      if (self.checkActiveWorkspace(data)) {
        self.$router.push({ name: 'workspaces' })
      } else {
        self.addToNotifications(data)
        self.resetDefaultStates()
        self.initializeSection()
      }

      if (data.dashboard)
        self.alertMessage('<p>' + data.description + '. </p>', 'info')
      if (data.browser) self.displayBrowserNotifications(data)
    },

    roleChangesListener(data, self) {
      self.resetDefaultStates()
      self.initializeSection()
      self.dashboardNotification(data)
      self.fetchWorkspaces()
      if (self.checkActiveWorkspace(data))
        self.$router.push({ name: 'workspaces' })
    },

    async opmlListener(data, self) {
      if (self.checkActiveWorkspace(data)) {
        self.dashboardNotification(data)
        self.fetchOPMLHistory()
        await this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
      }
    },

    /**
     * To load all the listeners for receiving notifications
     */
    loadSocketBroadcast() {
      console.debug('Method: loadSocketBroadcast')
      this.saveSocketLogs({}, 'load User socket broadcast')
      const self = this
      const userId = this.getProfile._id
      this.removeSocketBroadcastListeners(userId)

      window.webSockets.on(
        `logout:${userId}`,
        function () {
          console.debug('Logout:Socket')
          this.removeSocketAllChannelListeners(userId)
          this.checkUserLogin()
        }.bind(this)
      )

      window.webSockets.on(
        'new-version-released',
        function () {
          this.$store.commit('setIsNewVersionAvailable', true)
          this.$store.commit('setDisplayNewVersionAvailable', true)
        }.bind(this)
      )
      // eslint-disable-next-line no-undef
      socket.on('workspace_added:' + userId, function (data) {
        self.dashboardNotification(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('workspace_joined:' + userId, function (data) {
        if (self.checkActiveWorkspace(data)) self.dashboardNotification(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('member_removed:' + userId, function (data) {
        self.removedMemberListener(data, self)
      })
      // eslint-disable-next-line no-undef
      socket.on('workspace_removed:' + userId, function (data) {
        self.removedWorkspaceListener(data, self)
      })
      // eslint-disable-next-line no-undef
      socket.on('role_changed:' + userId, function (data) {
        self.roleChangesListener(data, self)
      })
      // eslint-disable-next-line no-undef
      $(this.broadcastChannels).each(function (index, el) {
        // eslint-disable-next-line no-undef
        socket.on(el + userId, function (data) {
          if (self.checkActiveWorkspace(data))
            self.onlyIncrementOrBrowserNotification(data)
        })
      })
      // eslint-disable-next-line no-undef
      socket.on('opml_import_completed:' + userId, function (data) {
        self.opmlListener(data, self)
      })
      // eslint-disable-next-line no-undef
      socket.on('feedly_import_completed:' + userId, function (data) {
        self.opmlListener(data, self)
      })
      // eslint-disable-next-line no-undef
      socket.on('post_publish:' + userId, function (data) {
        if (self.checkActiveWorkspace(data)) self.dashboardNotification(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('missed_review:' + userId, function (data) {
        console.debug('missed_review listener', data)
        self.dashboardNotification(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('plan_approval:' + userId, function (data) {
        console.debug('plan_approval listener', data)
        self.dashboardNotification(data)
      })

      socket.on('new_comment:' + userId, function (data) {
        self.dashboardNotification(data)
      })

      socket.on('comment_in_my_post:' + userId, function (data) {
        self.dashboardNotification(data)
      })

    },

    /**
     * Load the broadcast listeners for automation and set their refill time after the automation is successfully ran
     */
    workspaceBroadCastListeners() {
      const stateObject = this
      console.debug('Method: workspaceBroadCastListeners')

      const workspaceId = this.getWorkspaces.activeWorkspace._id
      // Call the method to set the inbox notification broadcast
      this.setInboxNotificationBroadcast?.();

      this.saveSocketLogs(
        { workspace_id: workspaceId },
        'load Workspace socket broadcast'
      )
      // eslint-disable-next-line no-undef
      socket.on('article_plan_job:' + workspaceId, function (data) {
        if (data.type === 'blog') {
          stateObject.automationBlogArticleContentPlanned(data)
        } else {
          stateObject.automationSocialArticleContentPlanned(data)
        }
      })
      // eslint-disable-next-line no-undef
      socket.on('video_plan_job:' + workspaceId, function (data) {
        if (data.type === 'blog') {
          stateObject.automationBlogVideoContentPlanned(data)
        } else {
          stateObject.automationSocialVideoContentPlanned(data)
        }
      })
      // eslint-disable-next-line no-undef
      socket.on(
        'rss_plan_job:' + workspaceId,
        function (data) {
          console.log('[socket] rss-plan-job listener', data)
          const item = this.getRssAutomationListing.items.find(
            (item) => item._id === data.automation._id
          )
          if (item) {
            item.lock = 0
            item.last_execution = data.automation.last_execution
          }
        }.bind(this)
      )
      // eslint-disable-next-line no-undef
      socket.on('evergreen_plan_job:' + workspaceId, function (data) {
        stateObject.$store.dispatch('automationEvergreenContentPlanned', data)
      })

      // socket.on('process_activity:' + workspaceId, function (data) {
      //   console.debug('process_activity', data)
      //   let planId = stateObject.getPublishSelection.plan_id
      //   if (planId && data && data.plan_id === planId) {
      //     let item = stateObject.getActivities.data.filter(item => item._id === data.data._id)
      //     if (!item || item.length === 0) {
      //       stateObject.getActivities.data.unshift(JSON.parse(JSON.stringify(data.data)))
      //       stateObject.$store.commit(activityTypes.SET_TOTAL_ACTIVITIES, stateObject.getActivities.totalActivities + 1)
      //     }
      //   }
      // })
      // eslint-disable-next-line no-undef
      socket.on('bulk_csv_upload:' + workspaceId, function (data) {
        console.debug('received')
        if (
          data.csv_id &&
          data.csv_id === stateObject.getCsvAutomationSelection.csvId
        ) {
          stateObject.SET_CSV_PROCESSED_POSTS(data.processed)
          stateObject.SET_CSV_TOTAL_PROCESSING(data.total)
          if (data.complete) {
            stateObject.SET_CSV_VALID_POSTS(data.any_valid_post)
            stateObject.SET_CSV_COMPLETED_POSTS(data.complete)
          }
        }
        console.debug(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('bulk_evergreen_upload:' + workspaceId, function (data) {
        console.debug('received')
        stateObject.$store.commit(
          evergreen.SET_EVERGREEN_BULK_TOTAL_PROCESS_POSTS,
          data.processed
        )
        stateObject.$store.commit(
          evergreen.SET_EVERGREEN_BULK_TOTAL_POSTS,
          data.total
        )
        console.debug(data)
      })
      // eslint-disable-next-line no-undef
      socket.on('content_rewriting_processing:' + workspaceId, function (data) {
        console.debug('received', data)
        stateObject.$store.commit('SET_REWRITING_PROCESSED', data.processed)
        stateObject.$store.commit('SET_REWRITING_TOTAL', data.total)
      })

      window.webSockets.on(
        'feed_comment:' + workspaceId,
        function (data) {
          if (
            (this.$route.name === 'feed_view' ||
              this.$route.name === 'list_plans') &&
            this.getProfile._id === data.user_id
          ) {
            console.debug('receive feed_comment', data)
            this.getPlans.items.forEach((plan, index) => {
              if (plan._id === data.planId) {
                console.debug('plan found', data.type)
                switch (data.type) {
                  case 'add':
                    if (plan.comments) {
                      if (
                        !this.getPlans.items[index].comments.find(
                          (comment) => comment._id === data.comment._id
                        )
                      ) {
                        this.getPlans.items[index].comments.unshift(
                          data.comment
                        )
                      }
                    } else this.getPlans.items[index].comments = [data.comment]
                    break
                  case 'update':
                    if (plan.comments) {
                      const item = this.getPlans.items[index].comments.find(
                        (comment) => comment._id === data.comment.id
                      )
                      if (item) item.comment = data.comment.text
                    }
                    break
                  case 'delete':
                    if (plan.comments) {
                      const commentIndex = this.getPlans.items[
                        index
                      ].comments.findIndex(
                        (comment) => comment._id === data.comment.id
                      )
                      console.debug('index', commentIndex)
                      if (commentIndex >= 0)
                        this.getPlans.items[index].comments.splice(
                          commentIndex,
                          1
                        )
                    }
                }
                return false
              }
            })
          }
        }.bind(this)
      )

      window.webSockets.on(
        'feed_approval:' + workspaceId,
        function (data) {
          if (
            (this.$route.name === 'feed_view' ||
              this.$route.name === 'list_plans') &&
            this.getProfile._id === data.user_id
          ) {
            console.debug('receive feed_approval', data)
            this.getPlans.items.forEach((plan, index) => {
              if (plan._id === data.planId) {
                this.changePlanData(data.planId, data.data)
              }
            })
          }
        }.bind(this)
      )
    },

    async callComposerEditingCallback(data) {
      console.debug('method:callComposerEditingCallback')
      const resp = await this.$store.dispatch('composerEditingCallBack', data)
      if (resp) {
        this.setCKEditorHtml(data.editor_data, true)
        this.$store.commit(
          blogPosting.SET_BLOG_POSTING_OPTIONAL_DESCRIPTION,
          data.optionalDescription
        )
        this.$store.commit(blogPosting.SET_BLOG_POSTING_TITLE, data.title)
      }
    },

    // authenticate methods

    loadUserBroadCasters() {
      console.debug('Method:: loadUserBroadCasters')
      if (this.getProfile._id)
        window.webSockets.emit('login', { user_id: this.getProfile._id })
      this.broadcastPlanEditStatus()
    },

    logoutBroadcastUser(profileId) {
      window.webSockets.emit('logout', { id: profileId })
    },

    // composer plan control methods

    broadcastPlanEditStatus() {
      const stateObject = this
      setTimeout(function () {
        const planId = stateObject.getPublishSelection.plan_id
        if (planId && stateObject.$route.name === 'composerBlog') {
          stateObject.planBroadCastListeners(planId)
          const key = stateObject.generateRandomId()
          stateObject.$store.commit(composer.SET_PLAN_KEY, key)
          const payload = {
            plan_id: planId,
            workspace_id: stateObject.getWorkspaces.activeWorkspace._id,
            user_id: stateObject.getProfile._id,
            channel: 'plan_view',
            uuid: key,
          }
          window.webSockets.emit('composer_editing', payload)
          // collaborativeSocket.emit('composer_editing', payload)
        }
      }, 2000)
    },

    closePlanEmit(planId) {
      // let planId = this.getPublishSelection.plan_id
      this.removePlanBroadCastListeners(planId)
      const payload = {
        plan_id: planId,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        user_id: this.getProfile._id,
        channel: 'plan_close',
        uuid: this.getPlanKey,
      }

      window.webSockets.emit('composer_editing', payload)
      // collaborativeSocket.emit('composer_editing', payload)
    },

    emitSocket(channel, payload) {
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          channel,
          payload,
        },
        'Emitting'
      )

      console.debug('emitting', channel)
      console.debug('emitting payload', payload)
      window.webSockets.emit(channel, payload)
    },

    planBroadCastListeners(planId) {
      console.debug('Method: planBroadCastListeners')
      this.saveSocketLogs(
        {
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
          plan_id: planId,
        },
        'load Plan socket broadcast'
      )

      window.webSockets.on(
        'composerPlanView:' + planId,
        function (data) {
          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
              data,
            },
            'Receive composerPlanView'
          )

          console.debug('composerPlanView', data)
          if (this.$route.name === 'composerBlog') {
            this.$store.commit(composer.SET_SOCKET_ID, window.webSockets.id)
            this.$store.dispatch('composerPlanView', data)
          }
        }.bind(this)
      )

      window.webSockets.on(
        'planControlRequest:' + planId,
        function (data) {
          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
              data,
            },
            'Receive planControlRequest'
          )

          if (this.$route.name === 'composerBlog') {
            this.$store.dispatch('planControlRequest', data)
          }
        }.bind(this)
      )

      window.webSockets.on(
        'closePlan:' + planId,
        function () {
          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
            },
            'Receive closePlan'
          )

          if (this.$route.name === 'composerBlog') {
            // const planControl =
            this.getComposerActivePlanMember.find((item) => {
              return item.control === true
            })
            // if (!planControl) this.$bvModal.show('lockStatusModal')
          }
        }.bind(this)
      )

      window.webSockets.on(
        'closeModal:' + planId,
        function () {
          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
            },
            'Receive closeModal'
          )

          if (this.$route.name === 'composerBlog')
            this.$bvModal.hide('lockStatusModal')
        }.bind(this)
      )

      window.webSockets.on(
        'blog_activities:' + planId,
        function (data) {
          console.debug('blog_activities', data)

          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
              data,
            },
            'Receive blog_activities'
          )

          if (
            this.$route.name === 'composerBlog' &&
            this.getSocketId !== data.socket_id
          ) {
            switch (data.type) {
              case 'comment':
                this.$store.dispatch('fetchComments')
                break
              case 'task':
                this.$store.dispatch('fetchTasks')
                break
              case 'label':
                if (data.data)
                  this.setPublishLabels(JSON.parse(JSON.stringify(data.data)))
                break
            }
          }
        }.bind(this)
      )

      window.webSockets.on(
        'composer_editing:' + planId,
        function (data) {
          this.saveSocketLogs(
            {
              workspace_id:
                this.$store.getters.getWorkspaces.activeWorkspace._id,
              plan_id: planId,
              route: this.$route.name,
              data,
            },
            'Receive composer_editing'
          )

          if (this.$route.name === 'composerBlog')
            this.$store.commit(composer.SET_SOCKET_ID, window.webSockets.id)
          this.callComposerEditingCallback(data)
        }.bind(this)
      )
    },

    removePlanBroadCastListeners(planId) {
      this.$store.commit(composer.SET_SOCKET_ID, '')
      window.webSockets.removeListener('composerPlanView:' + planId)
      window.webSockets.removeListener('planControlRequest:' + planId)
      window.webSockets.removeListener('closePlan:' + planId)
      window.webSockets.removeListener('closeModal:' + planId)
      // collaborativeSocket.removeListener('blog_activities:' + planId)
      window.webSockets.removeListener('blog_activities:' + planId)
      window.webSockets.removeListener('composer_editing:' + planId)
      // collaborativeSocket.removeListener('composer_editing:' + planId)
    },

    async checkUserLogin() {
      try {
        const resp = await proxy.post(isAuthenticatedURL, {})
        if (!resp.data.status) {
          await this.isUserLogout().then(this.$router.push({ name: 'login' }))
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          this.isLoggedOutUser()
        }
      }
    },

    async isUserLogout() {
      return new Promise((resolve, reject) => {
        this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
        this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
        resolve()
      })
    },
  },
}

export { listener }
