<template>
  <div id="tumblr_tab">
    <div class="tumblr_post_preview">
      <div class="tumblr_post_preview__head">
        <div class="tumblr_post_preview__head__profile_picture">
          <div
            class="tumblr_post_preview__head__profile_picture__picture_block"
          >
            <img
              v-if="tumblrImage(getPreviewAccount.tumblr)"
              :src="tumblrImage(getPreviewAccount.tumblr)"
              alt=""
              style="border: 1px solid #eff4f8"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <img
              v-else
              src="../../../../../../assets/img/profile_default.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="p-2">
        <div class="preview_desc">
          <p
            class="desc"
            v-html="processSharingPreviewDesciption(details.message, 'Tumblr')"
          ></p>
        </div>
      </div>

      <template v-if="details.video && details.video.link">
        <div
          class="social_preview_block__social_preview_inner__social_post_preview__preview_video"
        >
          <div
            class="img"
            :style="{
              'background-image': 'url(' + details.video.thumbnail + ')',
            }"
          >
            <div class="tumblr_vid_icon vid_icon">
              <span class="icon"></span>
            </div>
          </div>
        </div>
      </template>

      <div
        v-else-if="details.image && details.image.length > 0"
        class="tumblr_post_preview__preview_images"
      >
        <img
          v-if="details.image.constructor === Array && details.image.length"
          class="single_image"
          :src="details.image[0]"
          alt=""
        />
        <img v-else class="single_image" :src="details.image" alt="" />
      </div>

      <div v-else-if="details.link" class="prev-link">
        <h5 v-html="details.link"></h5>
      </div>

      <div class="rection_icons facebook_r">
        <span><i class="fas fa-share"></i> </span>
        <span> <i class="far fa-heart"></i> </span>
        <span><i class="fas fa-edit"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['details'],
  data () {
    return {
      PreviewMode: 'Desktop'
    }
  },
  computed: {
    ...mapGetters(['getAccountSelection', 'getPreviewAccount'])
  }
}
</script>
