<template>
  <ul
    v-if="
      (getCustomSidebar.favorites.status &&
        getTopicContentType === 'article') ||
      getSidebarCustomization.customization
    "
    :class="{ disable_click: getTopicDisabled }"
    class="topics-left-pane-items"
  >
    <li
      class="topics-left-pane-items__heading"
      :class="[sibarItemStatus('favorites') ? '' : 'opacity']"
    >
      <i class="title_icon icon-stared_icon"></i>
      <span class="topics-left-pane-items__heading__text">Favorites</span>
      <button
        v-if="!getSidebarCustomization.customization"
        v-tooltip.top="'New Folder'"
        class="topics-left-pane-items__heading__button"
        @click.prevent="addFavoriteFolder"
      >
        <i class="far fa-plus d-block"></i>
      </button>
      <div v-else class="topics-left-pane-items__heading__customization">
        <i
          class="view_icon"
          :class="[
            sibarItemStatus('favorites') ? 'cs-eye ' : 'cs-eye-close ',
          ]"
          @click.prevent="selectionSibarItem('favorites')"
        >
        </i>
      </div>
    </li>
    <!--:class="{selected_disable: !sibarItemStatus('favorites'), disable_box : getSidebarCustomization}"-->
    <div
      v-if="!getSidebarCustomization.customization"
      class="topics-left-pane-favorite-folder"
    >
      <div
        v-if="addFolder && getAddFolderBox"
        class="topics-left-pane-favorite-folder__create_input"
      >
        <input
          v-model="folderName"
          type="text"
          placeholder="Enter Folder Name"
          @keyup.enter="saveFolder(folderName)"
        />
        <div
          class="topics-left-pane-favorite-folder__create_input__action_buttons"
        >
          <img
            src="../../assets/article/check_icon.svg"
            alt="Add Folder"
            class="add_folder"
            @click.prevent="saveFolder(folderName)"
          />
          <img
            class="remove"
            src="../../assets/article/delete_icon.svg"
            alt="remove topic"
            @click.prevent="cancelFolder"
          />
        </div>
      </div>
    </div>
    <template v-if="getFolders.length > 0">
      <template v-for="(folder, index) in getFolders">
        <li
            v-if="
          getSidebarCustomization.customization ||
          !getCustomSidebar.favorites.options.includes(folder._id)
        "
            :key="`folder_${index}`"
            class="topics-left-pane-items__list"
            :class="[
          sibarItemStatus('favorites', folder._id) ? '' : 'opacity',
          getSidebarCustomization.customization
            ? 'topics-left-pane-items__list__customization'
            : '',
          folder.edit_state ? 'topics-left-pane-items__is_edit_folder' : '',
          $route.params.folder_id === folder._id ? 'active' : '',
        ]"
        >
          <!--:class="{disable_box : getSidebarCustomization,selected_disable: !sibarItemStatus('favorites',folder._id)}"-->
          <!--active:activeFolder(folder._id) -->

          <!--.........Show when click on edit icon........-->
          <template
              v-if="folder.edit_state && !getSidebarCustomization.customization"
          >
            <div
                class="topics-left-pane-items__list__update_favorite_folder topics-left-pane-favorite-folder"
            >
              <div class="topics-left-pane-favorite-folder__create_input">
                <input
                    v-model="folder.newName"
                    placeholder="Enter Folder Name"
                    type="text"
                    @keydown="updateFolderKeyDown($event, folder)"
                />
                <div
                    class="topics-left-pane-favorite-folder__create_input__action_buttons"
                >
                  <img
                      class="add_folder"
                      src="../../assets/article/check_icon.svg"
                      alt="Add Folder"
                      @click.prevent="updateFolder(folder)"
                  />
                  <img
                      class="remove"
                      src="../../assets/article/delete_icon.svg"
                      alt="remove topic"
                      @click="cancelFolderAction(folder)"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <router-link
                :to="{
              name: 'discover_topics_favorite',
              params: { folder_id: folder._id },
            }"
            >{{ folder.name }}</router-link
            >
            <div class="t_icon topics-left-pane-items__list__action_buttons">
              <template v-if="!getSidebarCustomization.customization">
                <i
                    v-tooltip.top="'Edit'"
                    class="topics-left-pane-items__list__action_buttons__edit icon-edit-cs"
                    @click.prevent="editFolderAction(folder)"
                >
                </i>

                <span class="dropdown dropdown-confirmation">
                <i
                    :id="'dropdown_' + folder._id"
                    v-tooltip.top="'Remove'"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="icon-delete-cs topics-left-pane-items__list__action_buttons__remove"
                ></i>

                <div
                    :aria-labelledby="'dropdown_' + folder._id"
                    class="dropdown-menu dropdown-confirmation__menu"
                >
                  <div class="dropdown-confirmation__menu__header">
                    Confirmation
                  </div>
                  <div class="dropdown-confirmation__menu__actions">
                    Are you sure?
                    <span
                        class="dropdown-confirmation__menu__actions__delete"
                        @click.prevent="
                        removeFolder(folder._id, folder.name, index)
                      "
                    >Yes</span
                    >
                    <span class="dropdown-confirmation__menu__actions__cancel"
                    >No</span
                    >
                  </div>
                </div>
              </span>
              </template>
              <template v-else>
                <i
                    class="view_icon"
                    :class="[
                  sibarItemStatus('favorites', folder._id)
                    ? 'cs-eye '
                    : 'cs-eye-close ',
                ]"
                    @click.prevent="selectionSibarItem('favorites', folder._id)"
                >
                </i>
              </template>
            </div>

            <!--<div class="line_animate" :class="{'active animate':getActiveTopicTab == folder._id}"></div>-->
          </template>
        </li>
      </template>
    </template>
    <template v-else>
      <p class="topics-left-pane-items__no_results"
        >You do not have any favorite folder.</p
      >
    </template>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      addFolder: false,
      folderName: null
    }
  },
  computed: {
    ...mapGetters([
      'getFolders',
      'getAddFolderBox',
      'getActiveTopicTab',
      'getTopicDisabled',
      'getSidebarCustomization',
      'getTopicContentType',
      'getWorkspaces'
    ])
  },
  created () {
    if (this.getFolders.length === 0) {
      this.getFavoriteFolders()
    }
  },
  methods: {
    ...mapActions(['setAddFolderbox', 'setTopic']),
    addFavoriteFolder () {
      this.setAddFolderBox(true)
      this.addFolder = true
    },
    cancelFolder () {
      this.addFolder = false
    },
    cancelFolderAction (folder) {
      console.log('CancelFolderAction', folder)
      this.$set(folder, 'edit_state', false)
      folder.edit_state = false
    },
    editFolderAction (folder) {
      console.log('editFolderAction', folder)
      this.$set(folder, 'edit_state', true)
      this.$set(folder, 'newName', folder.name)
    }
  }
}
</script>
