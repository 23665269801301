import proxy from '@common/lib/http-common'
import {
  cancelSubscriptionURL,
  submitSupportRequestURL,
  reopenAccountURL,
  removeAccountDataURL,
  planURL,
} from '@src/modules/setting/config/api-utils.js'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { billingBaseUrl } from '@src/config/api-utils'

const getDefaultPlanState = () => {
  return {
    plan: {
      subscription: {
        fastspring_name: null,
      },
      trial_overs_in: 14,
      type: 'paddle',
      account_id: null,
      invoices: [],
      billing_details: {},
      subscription_details: {
        default_add_ons: {},
      },
      used_limits: {
        automations: 0,
        blogs: 0,
        members: 0,
        profiles: 0,
        topics: 0,
        workspaces: 0,
        media_storage: 0,
        caption_generation_credit: 0,
      },
      upgrade_plan_name: '',
      trial_plan_name: '',
      limit_reached: false,
      loaders: {
        pause: false,
        cancel: false,
        upgrade: false,
        view: true,
        trial: false,
      },
    },
  }
}

const state = getDefaultPlanState()

// getters
const getters = {
  getPlan: (state) => {
    return state.plan
  },
  getCancelPlanLoader: (state) => {
    return state.plan.loaders.cancel
  },
  getUpgradePlanLoader: (state) => {
    return state.plan.loaders.upgrade
  },
  getUpgradePlanLoaderName: (state) => {
    return state.plan.upgrade_plan_name
  },

  getTrialChangeLoader: (state) => {
    return state.plan.loaders.trial
  },
  getTrialChangeLoaderName: (state) => {
    return state.plan.trial_plan_name
  },
  getLimitReached: (state) => {
    return state.plan.limit_reached
  },

  /**
   * Checking if the user is on the lifetime plan or on the trial/monthly plan.
   * @param state
   * @returns {boolean}
   */
  isAnalyticsPlanAllowed: (state) => {
    console.log(
      'Analytics plan allowed subscription >',
      state.plan.subscription
    )

    if (state.plan.subscription.fastspring_name) {
      const plan = state.plan.subscription.fastspring_name
      if (plan.includes('trial')) {
        return true
      } else if (
        !plan.includes('jvzoo') &&
        !plan.includes('appsumo') &&
        !plan.includes('lifetime')
      ) {
        return true
      }
    }
    return false
  },
}

// actions
const actions = {
  async fetchPlan({ commit, getters, dispatch, state }) {
    return proxy
      .post(planURL, {
        workspace_id: getters.getWorkspaces.activeWorkspace._id || '',
      })
      .then((resp) => {
        if (resp.data.status) {
          commit('SET_PLAN', resp.data.plan)
          commit('SET_PLAN_VIEW_LOADER', false)
        }
      })
      .catch()
  },
  async cancelPlan({ commit, getters, dispatch }, payload) {
    commit('SET_CANCEL_PLAN_LOADER', true)
    return proxy
      .post(cancelSubscriptionURL, {
        name: payload.name,
        cancellation_reason: payload.cancellation_reason,
        can_stay: payload.can_stay,
        type: getters.getPlan.type,
        subscription_plan: payload.subscription_plan,
        account_created_at: payload.account_created_at,
      })
      .then((resp) => {
        commit('SET_CANCEL_PLAN_LOADER', false)
        if (resp.data.status) {
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'success',
          })
          getters.getProfile.state = 'canceled'
          return true
        }
        dispatch('toastNotification', {
          message: resp.data.message,
          type: 'error',
        })
        return false
      })
      .catch()
  },

  async reopenAccount({ commit, getters, dispatch }) {
    return proxy
      .post(reopenAccountURL)
      .then((resp) => {
        if (resp.data.status) {
          dispatch('toastNotification', {
            message: 'Your account is successfully reopened.',
            type: 'success',
          })
          return true
        }
        dispatch('toastNotification', {
          message: resp.data.message,
          type: 'error',
        })
        return false
      })
      .catch((resp) => {
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },

  async removeAccountData({ commit, getters, dispatch }) {
    return proxy
      .post(removeAccountDataURL)
      .then((resp) => {
        if (resp.data.status) {
          dispatch('toastNotification', {
            message: 'Your account data is successfully removed.',
            type: 'success',
          })
          return true
        }
        dispatch('toastNotification', {
          message: resp.data.message,
          type: 'error',
        })
        return false
      })
      .catch((resp) => {
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },

  async submitSupportRequest({ commit, getters, dispatch }, payload) {
    commit('SET_CANCEL_PLAN_LOADER', true)
    return proxy
      .post(submitSupportRequestURL, {
        name: payload.name,
        cancellation_reason: payload.cancellation_reason,
        can_stay: payload.can_stay,
        question: payload.question,
        question_details: payload.question_details,
        permission_granted: payload.permission_granted,
        subscription_plan: payload.subscription_plan,
        account_created_at: payload.account_created_at,
        type: getters.getPlan.type,
      })
      .then((resp) => {
        commit('SET_CANCEL_PLAN_LOADER', false)

        if (resp.data.status) {
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'success',
          })
          return true
        }
        dispatch('toastNotification', {
          message: resp.data.message,
          type: 'error',
        })
        return false
      })
      .catch()
  },

  async pauseSubscriptionById(context) {
    // eslint-disable-next-line
    const res = await proxy
      .post(`${billingBaseUrl}paddle/user_pause_subscription`)
      .then((res) => {
        if (res.data.message) {
          context.dispatch('toastNotification', { message: res.data.message })
        }
        return true
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          context.dispatch('toastNotification', {
            message: err.response.data.message,
            type: 'error',
          })
        }
        return false
      })
  },
  async resumePaddleSubscription(context) {
    return await proxy
      .post(`${billingBaseUrl}paddle/user_resume_subscription`)
      .then((res) => {
        if (res.data.message) {
          context.dispatch('toastNotification', { message: res.data.message })
        }
        return true
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          context.dispatch('toastNotification', {
            message: err.response.data.message,
            type: 'error',
          })
        }
        return false
      })
  },

  limitReached({ commit }, value) {
    commit('SET_LIMIT_REACHED', value)
  },

  setWorkspacesLimit({ commit }, value) {
    commit('setWorkspacesLimit', value)
  },

  setMembersLimit({ commit }, value) {
    commit('setMembersLimit', value)
  },
  setMediaStorageLimit({ commit }, value) {
    commit('setMediaStorageLimit', value)
  },
}

// mutations
const mutations = {
  SET_PLAN(state, plan) {
    state.plan = Object.assign(getDefaultPlanState().plan, plan)
  },

  SET_CANCEL_PLAN_LOADER(state, value) {
    state.plan.loaders.cancel = value
  },
  SET_LIMIT_REACHED(state, value) {
    state.plan.limit_reached = value
  },

  SET_PLAN_VIEW_LOADER(state, value) {
    state.plan.loaders.view = value
  },

  setWorkspacesLimit(state, value) {
    state.plan.used_limits.workspaces = value
  },
  setMembersLimit(state, value) {
    state.plan.used_limits.members = value
  },
  setMediaStorageLimit(state, value) {
    state.plan.used_limits.media_storage = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
