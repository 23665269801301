<template>
  <div>
    <div
      v-for="(video, index) in getVideos"
      :key="index"
      class="assistant_box _video _youtube"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
    >
      <div
        class="box_inner"
        :draggable="$route.name === 'composerBlog'"
        @dragstart="dragstartHandler($event, video)"
      >
        <div class="image_block">
          <!--.......For video icon..............-->

          <div
            class="play_icon facebook_vid_icon"
            @click.prevent="
              previewVideo(
                video.snippet.title,
                video.snippet.description,
                videoEmbedUrl(video.id, 'youtube'),
                'Youtube',
              )
            "
          >
            <i class="cs-play"></i>
          </div>
          <div
            v-if="video.snippet.thumbnails.maxres"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.maxres, 'youtube')
            "
            class="img"
          ></div>
          <div
            v-else-if="video.snippet.thumbnails.high"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.high, 'youtube')
            "
            class="img"
          ></div>
          <div
            v-else-if="video.snippet.thumbnails.default"
            v-lazy:background-image="
              videoImageURL(video.snippet.thumbnails.default, 'youtube')
            "
            class="img"
          ></div>
        </div>

        <div class="content_block">
          <p class="desc">
            {{ limitTextLength(video.snippet.title, 42) }}
          </p>
          <div class="detail">
            <p class="date">
              <span>{{ videoDate('youtube', video.snippet.publishedAt) }}</span>
            </p>
          </div>
        </div>

        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div v-tooltip.top="'View'" class="stat_item">
              <div class="name">
                <p>
                  <i class="cs-eye"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.statistics.viewCount) }}</h3>
              </div>
            </div>
            <div v-tooltip.top="'Like'" class="stat_item">
              <div class="name">
                <p>
                  <i class="cs-thumbs-up"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.statistics.likeCount) }}</h3>
              </div>
            </div>
            <div v-tooltip.top="'Comment'" class="stat_item">
              <div class="name">
                <p>
                  <i class="cs-comment"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(video.statistics.commentCount) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_dragable_box editor_video_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option">
              <div class="left_block">
                <i
                  class="move_left fa fa-align-left editor_tooltip"
                  title="Align Left"
                ></i>
                <i
                  class="move_center fa fa-align-center editor_tooltip"
                  title="Align Center "
                ></i>
                <i
                  class="move_right fa fa-align-right editor_tooltip"
                  title="Align Right"
                ></i>
              </div>
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div
              class="align_box youtube_inner_embed"
              :data-link="videoEmbedUrl(video.id, 'youtube')"
              style="text-align: center; display: block"
            >
              <div class="inner_embed">
                <div class="custom_loader">
                  <div class="loader_inner"></div>
                </div>
                <iframe
                  style="width: 470px; height: 330px"
                  src=""
                  frameborder="0"
                  allowfullscreen
                ></iframe>
                <br />
              </div>
            </div>

            <p
              class="text_alignment"
              style="text-align: center"
              :data-link="'https://www.youtube.com/watch?v=' + video.id"
              :data-embed="videoEmbedUrl(video.id, 'youtube')"
              >{{ video.snippet.title }}</p
            >
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Youtube',
  components: {},
  computed: {
    ...mapGetters(['getVideos'])
  },
  created () {},
  methods: {
    ...mapActions([
      'setVideos',
      'setVideoPage',
      'setVideoScroll',
      'setDiscoveryVideoLoader',
      'setVideoPaginationLoader',
      'setVideoPageToken'
    ]),
    dragstartHandler (ev, video) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', video)
      if (video && video.id) {
        ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'embed',
            heading: '',
            media: this.videoEmbedUrl(video.id, 'youtube'),
            p: '',
            link: ''
          })
        )
      }
    }
  }
}
</script>
