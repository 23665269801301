<script setup>
import { computed } from 'vue'
import ReportHeader from '@src/modules/analytics/components/reports/ReportHeader.vue'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import PostsSection from '@src/modules/analytics/views/pinterest/components/PostsSection.vue'
import CardsComponent from '@/src/modules/analytics/views/pinterest/components/CardsComponent'
import FollowersGraph from '@/src/modules/analytics/views/pinterest/components/graphs/FollowersGraph.vue'
import ImpressionsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/EngagementsGraph.vue'
import PinsPostingAndEngagements from '@/src/modules/analytics/views/pinterest/components/graphs/PinsPostingAndEngagements.vue'
import PostingByDayGraph from '@/src/modules/analytics/views/pinterest/components/graphs/PostingByDayGraph.vue'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/pinterest/components/TopAndLeastEngagingPosts'

const { selectedAccount, topEngagingPosts, leastEngagingPosts } =
  usePinterestAnalytics()

const chunkSize = 10
/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []

  let i = 0;
  while (i < topEngagingPosts.value.engagement_rate.length) {
    startIndexes.push(i);
    i += chunkSize;
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const hardCodedSections = 7
  return hardCodedSections + topEngagingPostsChunks.value.length
})
</script>

<template>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="1"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-6 gap-4 mb-7">
          <CardsComponent />
        </div>

        <!-- Followers Graph -->
        <FollowersGraph class="!mb-7" />
        <!-- Followers Graph -->
        <FollowersGraph class="!mb-7" default-type="bar" />
      </div>
    </div>
  </div>

  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="2"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- Engagements Graph -->
      <EngagementsGraph class="!mb-7" />
      <!-- Impressions Graph -->
      <ImpressionsGraph class="!mb-7" />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="3"
        :total="totalPageSize"
      ></ReportHeader>
      <PinsPostingAndEngagements class="!mb-7" />
      <!-- Pin Posting and Engagements Graph -->
      <PinsPostingAndEngagements type="Impressions" class="!mb-7" />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="4"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- PostingByDayGraph Graph -->
      <PostingByDayGraph class="!mb-7" />

      <PostingByDayGraph type="Image" class="!mb-7" />
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="5"
        :total="totalPageSize"
      ></ReportHeader>
      <!-- PostingByDayGraph Graph -->

      <PostingByDayGraph type="Video" class="!mb-7" />

      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Impressions"
          custom-report-data-key="impressions"
          :top-posts="topEngagingPosts.impressions"
          :least-posts="leastEngagingPosts.impressions"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="6"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Engagements"
          custom-report-data-key="engagement_rate"
          :top-posts="topEngagingPosts.engagement_rate"
          :least-posts="leastEngagingPosts.engagement_rate"
          :selected-account="selectedAccount"
        />
      </div>
      <div class="!mb-7">
        <TopAndLeastEngagingPosts
          class="!mb-7"
          custom-report-label="Pin Clicks"
          custom-report-data-key="pin_clicks"
          :top-posts="topEngagingPosts.pin_clicks"
          :least-posts="leastEngagingPosts.pin_clicks"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
  </div>
  <div class="page_view">
    <div class="subpage">
      <ReportHeader
        :report-name="'Pinterest'"
        :current="7"
        :total="totalPageSize"
      ></ReportHeader>
      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Outbound Clicks"
          custom-report-data-key="outbound_clicks"
          :top-posts="topEngagingPosts.outbound_clicks"
          :least-posts="leastEngagingPosts.outbound_clicks"
          :selected-account="selectedAccount"
        />
      </div>
      <div class="!mb-7">
        <TopAndLeastEngagingPosts
          class="!mb-7"
          custom-report-label="Saves"
          custom-report-data-key="saves"
          :top-posts="topEngagingPosts.saves"
          :least-posts="leastEngagingPosts.saves"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
  </div>

  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view">
      <div class="subpage">
        <!-- Render report header -->
        <ReportHeader
          :report-name="'Pinterest'"
          :current="8 + index"
          :total="totalPageSize"
        ></ReportHeader>
        <!-- Render post table for the current chunk of top posts -->
        <PostsSection
          :range-start-value="start"
          :range-end-value="
            Math.min(start + chunkSize, topEngagingPosts.engagement_rate.length)
          "
        />
      </div>
    </div>
  </template>
</template>
