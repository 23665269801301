export default {
  allTypesGraphData: [
    {
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      avgTotalEngagements: 276299.81,
      postsPerWeek: 13.5,
      postsPerDay: 1.8,
      postsPerHour: 0.08,
      totalPosts: '54',
      weekCount: '4',
      dayCount: '30',
      hourCount: '720',
      avgEngagementRate: 0.4,
    },
    {
      mediaType: 'CAROUSEL ALBUM',
      mediaProductType: 'FEED',
      avgTotalEngagements: 31666.67,
      postsPerWeek: 0.75,
      postsPerDay: 0.1,
      postsPerHour: 0,
      totalPosts: '3',
      weekCount: '4',
      dayCount: '30',
      hourCount: '720',
      avgEngagementRate: 0.25,
    },
    {
      mediaType: 'IMAGE',
      mediaProductType: 'FEED',
      avgTotalEngagements: 16374.5,
      postsPerWeek: 2,
      postsPerDay: 0.27,
      postsPerHour: 0.01,
      totalPosts: '8',
      weekCount: '4',
      dayCount: '30',
      hourCount: '720',
      avgEngagementRate: 0.26,
    },
  ],
  instagramAnalyticsMainInfo: [
    {
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      name: 'Red Bull',
      business_account_id: '17841400849660007',
      averageEngagement: 883694,
      averagePostsPerWeek: 7.5,
      engagementRate: 0.86,
      dayOfWeek: 'Monday',
      hourOfDay: '02:00 PM',
      averagePostsPerDay: 1,
      averagePostsPerDayEngagement: 147283,
      followingCount: 1890,
      followersCount: 17124006,
      instagram_account_id: '17841400849660007',
      averagePostsPerWeekDiff: 33.93,
      engagementRateDiff: 17.31,
      followersCountDiff: 'N/A',
      color: '#61BAE4',
    },
    {
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      name: 'Coca-Cola',
      business_account_id: '17841401654983900',
      averageEngagement: 8992,
      averagePostsPerWeek: 1,
      engagementRate: 0.24,
      dayOfWeek: 'Tuesday',
      hourOfDay: '05:00 PM',
      averagePostsPerDay: 1,
      averagePostsPerDayEngagement: 6744,
      followingCount: 255,
      followersCount: 2861625,
      instagram_account_id: '17841401654983900',
      averagePostsPerWeekDiff: 150,
      engagementRateDiff: -25,
      followersCountDiff: 0.47,
      color: '#8AC1A2',
    },
    {
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      name: 'pepsi',
      business_account_id: '17841400831935560',
      averageEngagement: 26565,
      averagePostsPerWeek: 6.25,
      engagementRate: 0.3,
      dayOfWeek: 'Wednesday',
      hourOfDay: '02:00 PM',
      averagePostsPerDay: 2,
      averagePostsPerDayEngagement: 5775,
      followingCount: 15,
      followersCount: 1754981,
      instagram_account_id: '17841400831935560',
      averagePostsPerWeekDiff: 212.5,
      engagementRateDiff: -18.92,
      followersCountDiff: -0.01,
      color: '#145B9B',
    },
    {
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      name: 'Fanta',
      business_account_id: '17841402114104558',
      averageEngagement: 1011,
      averagePostsPerWeek: 1.5,
      engagementRate: 0.1,
      dayOfWeek: 'Monday',
      hourOfDay: '10:00 PM',
      averagePostsPerDay: 1,
      averagePostsPerDayEngagement: 506,
      followingCount: 20,
      followersCount: 504514,
      instagram_account_id: '17841402114104558',
      averagePostsPerWeekDiff: 150,
      engagementRateDiff: -37.5,
      followersCountDiff: 0.44,
      color: '#777777',
    },
  ],
  specificTypeGraphData: [
    {
      businessAccountId: '17841400849660007',
      name: 'Red Bull',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      avgTotalEngagements: 862394.8,
      avgCountByWeek: 6.75,
      totalPosts: '27',
      weekCount: '4',
      avgEngagementRate: 0.86,
      followingCount: '1890',
      followersCount: '17124006',
    },
    {
      businessAccountId: '17841401654983900',
      name: 'Coca-Cola',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      avgTotalEngagements: 6689.67,
      avgCountByWeek: 0.75,
      totalPosts: '3',
      weekCount: '4',
      avgEngagementRate: 0.23,
      followingCount: '255',
      followersCount: '2861625',
    },
    {
      businessAccountId: '17841400831935560',
      name: 'pepsi',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      avgTotalEngagements: 17144.2,
      avgCountByWeek: 4.5,
      totalPosts: '18',
      weekCount: '4',
      avgEngagementRate: 0.21,
      followingCount: '15',
      followersCount: '1754981',
    },
    {
      businessAccountId: '17841402114104558',
      name: 'Fanta',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      avgTotalEngagements: 1011,
      avgCountByWeek: 1.5,
      totalPosts: '6',
      weekCount: '4',
      avgEngagementRate: 0.09,
      followingCount: '20',
      followersCount: '504514',
    },
  ],
  graphMediaType: 'VIDEO REEL',
  followersGrowthGraphData: [
    {
      name: 'Red Bull',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      business_account_id: '17841400849660007',
      instagram_account_id: '17841400849660007',
      dates: [
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
      ],
      total_following_count: [
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1887',
        '1888',
        '1888',
        '1888',
        '1888',
        '1888',
        '1888',
        '1889',
        '1889',
        '1890',
      ],
      total_followed_by_count: [
        '17013054',
        '17018988',
        '17027324',
        '17033234',
        '17038465',
        '17042996',
        '17047947',
        '17049287',
        '17059191',
        '17065403',
        '17074638',
        '17079850',
        '17084299',
        '17086413',
        '17094027',
        '17096378',
        '17116310',
        '17121741',
        '17124006',
      ],
      dates_with_following_count: [
        ['2023-02-17', '1887'],
        ['2023-02-18', '1887'],
        ['2023-02-19', '1887'],
        ['2023-02-20', '1887'],
        ['2023-02-21', '1887'],
        ['2023-02-22', '1887'],
        ['2023-02-23', '1887'],
        ['2023-02-24', '1887'],
        ['2023-02-25', '1887'],
        ['2023-02-26', '1887'],
        ['2023-02-27', '1888'],
        ['2023-02-28', '1888'],
        ['2023-03-01', '1888'],
        ['2023-03-02', '1888'],
        ['2023-03-03', '1888'],
        ['2023-03-04', '1888'],
        ['2023-03-06', '1889'],
        ['2023-03-07', '1889'],
        ['2023-03-08', '1890'],
      ],
      dates_with_followed_by_count: [
        ['2023-02-17', '17013054'],
        ['2023-02-18', '17018988'],
        ['2023-02-19', '17027324'],
        ['2023-02-20', '17033234'],
        ['2023-02-21', '17038465'],
        ['2023-02-22', '17042996'],
        ['2023-02-23', '17047947'],
        ['2023-02-24', '17049287'],
        ['2023-02-25', '17059191'],
        ['2023-02-26', '17065403'],
        ['2023-02-27', '17074638'],
        ['2023-02-28', '17079850'],
        ['2023-03-01', '17084299'],
        ['2023-03-02', '17086413'],
        ['2023-03-03', '17094027'],
        ['2023-03-04', '17096378'],
        ['2023-03-06', '17116310'],
        ['2023-03-07', '17121741'],
        ['2023-03-08', '17124006'],
      ],
      color: '#61BAE4',
    },
    {
      name: 'Coca-Cola',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      business_account_id: '17841401654983900',
      instagram_account_id: '17841401654983900',
      dates: [
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
      ],
      total_following_count: [
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
        '255',
      ],
      total_followed_by_count: [
        '2848215',
        '2848218',
        '2848272',
        '2848438',
        '2848651',
        '2851001',
        '2851521',
        '2851798',
        '2851970',
        '2852208',
        '2852667',
        '2853055',
        '2853260',
        '2853505',
        '2853746',
        '2853872',
        '2854185',
        '2854270',
        '2854677',
        '2854926',
        '2855505',
        '2857634',
        '2858907',
        '2859175',
        '2859936',
        '2860112',
        '2861030',
        '2861395',
        '2861625',
      ],
      dates_with_following_count: [
        ['2023-02-06', '255'],
        ['2023-02-07', '255'],
        ['2023-02-08', '255'],
        ['2023-02-09', '255'],
        ['2023-02-10', '255'],
        ['2023-02-12', '255'],
        ['2023-02-13', '255'],
        ['2023-02-14', '255'],
        ['2023-02-15', '255'],
        ['2023-02-16', '255'],
        ['2023-02-17', '255'],
        ['2023-02-18', '255'],
        ['2023-02-19', '255'],
        ['2023-02-20', '255'],
        ['2023-02-21', '255'],
        ['2023-02-22', '255'],
        ['2023-02-23', '255'],
        ['2023-02-24', '255'],
        ['2023-02-25', '255'],
        ['2023-02-26', '255'],
        ['2023-02-27', '255'],
        ['2023-02-28', '255'],
        ['2023-03-01', '255'],
        ['2023-03-02', '255'],
        ['2023-03-03', '255'],
        ['2023-03-04', '255'],
        ['2023-03-06', '255'],
        ['2023-03-07', '255'],
        ['2023-03-08', '255'],
      ],
      dates_with_followed_by_count: [
        ['2023-02-06', '2848215'],
        ['2023-02-07', '2848218'],
        ['2023-02-08', '2848272'],
        ['2023-02-09', '2848438'],
        ['2023-02-10', '2848651'],
        ['2023-02-12', '2851001'],
        ['2023-02-13', '2851521'],
        ['2023-02-14', '2851798'],
        ['2023-02-15', '2851970'],
        ['2023-02-16', '2852208'],
        ['2023-02-17', '2852667'],
        ['2023-02-18', '2853055'],
        ['2023-02-19', '2853260'],
        ['2023-02-20', '2853505'],
        ['2023-02-21', '2853746'],
        ['2023-02-22', '2853872'],
        ['2023-02-23', '2854185'],
        ['2023-02-24', '2854270'],
        ['2023-02-25', '2854677'],
        ['2023-02-26', '2854926'],
        ['2023-02-27', '2855505'],
        ['2023-02-28', '2857634'],
        ['2023-03-01', '2858907'],
        ['2023-03-02', '2859175'],
        ['2023-03-03', '2859936'],
        ['2023-03-04', '2860112'],
        ['2023-03-06', '2861030'],
        ['2023-03-07', '2861395'],
        ['2023-03-08', '2861625'],
      ],
      color: '#8AC1A2',
    },
    {
      name: 'pepsi',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      business_account_id: '17841400831935560',
      instagram_account_id: '17841400831935560',
      dates: [
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
      ],
      total_following_count: [
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
        '15',
      ],
      total_followed_by_count: [
        '1755150',
        '1754983',
        '1754926',
        '1754881',
        '1754881',
        '1754903',
        '1755019',
        '1754974',
        '1754932',
        '1754884',
        '1754879',
        '1754948',
        '1754904',
        '1754866',
        '1754798',
        '1754783',
        '1754747',
        '1754764',
        '1754728',
        '1754742',
        '1754941',
        '1754698',
        '1754900',
        '1754875',
        '1754814',
        '1754895',
        '1754937',
        '1754970',
        '1754981',
      ],
      dates_with_following_count: [
        ['2023-02-06', '15'],
        ['2023-02-07', '15'],
        ['2023-02-08', '15'],
        ['2023-02-09', '15'],
        ['2023-02-10', '15'],
        ['2023-02-12', '15'],
        ['2023-02-13', '15'],
        ['2023-02-14', '15'],
        ['2023-02-15', '15'],
        ['2023-02-16', '15'],
        ['2023-02-17', '15'],
        ['2023-02-18', '15'],
        ['2023-02-19', '15'],
        ['2023-02-20', '15'],
        ['2023-02-21', '15'],
        ['2023-02-22', '15'],
        ['2023-02-23', '15'],
        ['2023-02-24', '15'],
        ['2023-02-25', '15'],
        ['2023-02-26', '15'],
        ['2023-02-27', '15'],
        ['2023-02-28', '15'],
        ['2023-03-01', '15'],
        ['2023-03-02', '15'],
        ['2023-03-03', '15'],
        ['2023-03-04', '15'],
        ['2023-03-06', '15'],
        ['2023-03-07', '15'],
        ['2023-03-08', '15'],
      ],
      dates_with_followed_by_count: [
        ['2023-02-06', '1755150'],
        ['2023-02-07', '1754983'],
        ['2023-02-08', '1754926'],
        ['2023-02-09', '1754881'],
        ['2023-02-10', '1754881'],
        ['2023-02-12', '1754903'],
        ['2023-02-13', '1755019'],
        ['2023-02-14', '1754974'],
        ['2023-02-15', '1754932'],
        ['2023-02-16', '1754884'],
        ['2023-02-17', '1754879'],
        ['2023-02-18', '1754948'],
        ['2023-02-19', '1754904'],
        ['2023-02-20', '1754866'],
        ['2023-02-21', '1754798'],
        ['2023-02-22', '1754783'],
        ['2023-02-23', '1754747'],
        ['2023-02-24', '1754764'],
        ['2023-02-25', '1754728'],
        ['2023-02-26', '1754742'],
        ['2023-02-27', '1754941'],
        ['2023-02-28', '1754698'],
        ['2023-03-01', '1754900'],
        ['2023-03-02', '1754875'],
        ['2023-03-03', '1754814'],
        ['2023-03-04', '1754895'],
        ['2023-03-06', '1754937'],
        ['2023-03-07', '1754970'],
        ['2023-03-08', '1754981'],
      ],
      color: '#145B9B',
    },
    {
      name: 'Fanta',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      business_account_id: '17841402114104558',
      instagram_account_id: '17841402114104558',
      dates: [
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
      ],
      total_following_count: [
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '20',
        '20',
        '20',
      ],
      total_followed_by_count: [
        '502280',
        '502325',
        '502404',
        '502471',
        '502544',
        '502850',
        '503077',
        '503136',
        '503195',
        '503301',
        '503379',
        '503449',
        '503531',
        '503653',
        '503695',
        '503771',
        '503864',
        '503850',
        '503979',
        '504039',
        '504151',
        '504209',
        '504251',
        '504284',
        '504371',
        '504401',
        '504491',
        '504514',
        '504514',
      ],
      dates_with_following_count: [
        ['2023-02-06', '21'],
        ['2023-02-07', '21'],
        ['2023-02-08', '21'],
        ['2023-02-09', '21'],
        ['2023-02-10', '21'],
        ['2023-02-12', '21'],
        ['2023-02-13', '21'],
        ['2023-02-14', '21'],
        ['2023-02-15', '21'],
        ['2023-02-16', '21'],
        ['2023-02-17', '21'],
        ['2023-02-18', '21'],
        ['2023-02-19', '21'],
        ['2023-02-20', '21'],
        ['2023-02-21', '21'],
        ['2023-02-22', '21'],
        ['2023-02-23', '21'],
        ['2023-02-24', '21'],
        ['2023-02-25', '21'],
        ['2023-02-26', '21'],
        ['2023-02-27', '21'],
        ['2023-02-28', '21'],
        ['2023-03-01', '21'],
        ['2023-03-02', '21'],
        ['2023-03-03', '21'],
        ['2023-03-04', '21'],
        ['2023-03-06', '20'],
        ['2023-03-07', '20'],
        ['2023-03-08', '20'],
      ],
      dates_with_followed_by_count: [
        ['2023-02-06', '502280'],
        ['2023-02-07', '502325'],
        ['2023-02-08', '502404'],
        ['2023-02-09', '502471'],
        ['2023-02-10', '502544'],
        ['2023-02-12', '502850'],
        ['2023-02-13', '503077'],
        ['2023-02-14', '503136'],
        ['2023-02-15', '503195'],
        ['2023-02-16', '503301'],
        ['2023-02-17', '503379'],
        ['2023-02-18', '503449'],
        ['2023-02-19', '503531'],
        ['2023-02-20', '503653'],
        ['2023-02-21', '503695'],
        ['2023-02-22', '503771'],
        ['2023-02-23', '503864'],
        ['2023-02-24', '503850'],
        ['2023-02-25', '503979'],
        ['2023-02-26', '504039'],
        ['2023-02-27', '504151'],
        ['2023-02-28', '504209'],
        ['2023-03-01', '504251'],
        ['2023-03-02', '504284'],
        ['2023-03-03', '504371'],
        ['2023-03-04', '504401'],
        ['2023-03-06', '504491'],
        ['2023-03-07', '504514'],
        ['2023-03-08', '504514'],
      ],
      color: '#777777',
    },
  ],
  bioAnalysisData: [
    {
      biography:
        'Serving stunning Pepsi visuals all day, every day. So get ready to double tap.',
      biography_length: '78',
      business_account_id: '17841400831935560',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      name: 'pepsi',
      followingCount: '15',
      followersCount: '1754981',
      instagram_account_id: '17841400831935560',
    },
    {
      biography: 'In our orange-est era, and you’re all just living in it.',
      biography_length: '58',
      business_account_id: '17841402114104558',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      name: 'Fanta',
      followingCount: '20',
      followersCount: '504514',
      instagram_account_id: '17841402114104558',
    },
    {
      biography: 'watch the World Of Red Bull👇\n#givesyouwiiings',
      biography_length: '48',
      business_account_id: '17841400849660007',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      name: 'Red Bull',
      followingCount: '1890',
      followersCount: '17124006',
      instagram_account_id: '17841400849660007',
    },
    {
      biography: 'Real Magic is only a sip away.',
      biography_length: '30',
      business_account_id: '17841401654983900',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      name: 'Coca-Cola',
      followingCount: '255',
      followersCount: '2861625',
      instagram_account_id: '17841401654983900',
    },
  ],
  postingActivityTableData: [
    {
      businessAccountId: '17841400849660007',
      name: 'Red Bull',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      count: '27',
      totalEngagement: '4311974',
      engagementRate: 0.93,
      followingCount: '1890',
      followersCount: '17124006',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      color: '#61BAE4',
    },
    {
      businessAccountId: '17841400831935560',
      name: 'pepsi',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      count: '18',
      totalEngagement: '85721',
      engagementRate: 0.27,
      followingCount: '15',
      followersCount: '1754981',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      color: '#8AC1A2',
    },
    {
      businessAccountId: '17841402114104558',
      name: 'Fanta',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      count: '6',
      totalEngagement: '3033',
      engagementRate: 0.1,
      followingCount: '20',
      followersCount: '504514',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      color: '#145B9B',
    },
    {
      businessAccountId: '17841401654983900',
      name: 'Coca-Cola',
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      count: '3',
      totalEngagement: '20069',
      engagementRate: 0.23,
      followingCount: '255',
      followersCount: '2861625',
      mediaType: 'VIDEO REEL',
      mediaProductType: 'REELS',
      color: '#777777',
    },
  ],
  topAndLeastPostsData: [
    {
      business_account_id: '17841400831935560',
      top_5_posts: [
        [
          '26374',
          '18247088224148688',
          '17841400831935560',
          "Will everyone's favorite model/actor make an appearance at the Super Bowl? #PepsiZeroSugar #SoHotRightNow",
          'IMAGE',
          'https://scontent-frt3-2.cdninstagram.com/v/t51.29350-15/329394218_197970056148856_2021691216317607263_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=2Cq2Ss1Yth4AX-QgUi2&_nc_ht=scontent-frt3-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfD6SoneBqMtDqqgUP0TlRlUYEE6yM_W1Pb6FpSmqC8nYg&oe=640D6691',
          'https://www.instagram.com/p/CoUy6mCOB7y/',
          '2023-02-06 14:50:57.000000',
          '26122',
          '252',
          '848',
          'FEED',
        ],
        [
          '15503',
          '18173546041265096',
          '17841400831935560',
          'Here is an early look at our Pepsi Zero Sugar extended Super Bowl commercial. Was benstiller’s reaction #RealOrActing? 🤔 Let us know 👇 #Zoolander #RachelDratch #SuperBowlLVII',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/6B4784596F83B702ECEB3CF82FA77D9E_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=100&vs=1399683644180210_2084237071&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC82QjQ3ODQ1OTZGODNCNzAyRUNFQjNDRjgyRkE3N0Q5RV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0JDZXBSTUpxUko5ODc4QkFLeDVhbXdrQy1vWWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm4sWPqMLn8j8VAigCQzMsF0BMFysCDEm6GBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfAwfAPU8Lhv-dJ_MpS7o3VO_h6DArlMQ6feFb1HZk4_QA&oe=640A1F69&_nc_sid=ea0b6e&_nc_rid=92989bca35',
          'https://www.instagram.com/reel/CoZ2-jKAqtE/',
          '2023-02-08 14:03:09.000000',
          '15333',
          '170',
          '848',
          'REELS',
        ],
        [
          '15219',
          '17953754318454412',
          '17841400831935560',
          'It’s time to hard launch this relationship. #PEPSIxPEEPS marshmallow cola is back for an epic reunion – this time for everyone to try 🙌\n\nAvailable in stores now for a limited time only ⏰',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/D948B8C252A26DB86970DF0D71FB7188_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=107&vs=578696840829987_300082628&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9EOTQ4QjhDMjUyQTI2REI4Njk3MERGMEQ3MUZCNzE4OF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0JBemxCT2xMSHFXNXljREFENjhSbzl2VmJOV2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm4s29mZmzlUEVAigCQzMsF0Azul41P3zuGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDAwwszl2rf1FRDDGo8ppanhGktIpd7v3uZrdbX_v0rHg&oe=6409D2C2&_nc_sid=ea0b6e&_nc_rid=8a261dbcb8',
          'https://www.instagram.com/reel/CopnFlxgydr/',
          '2023-02-14 17:00:06.000000',
          '14746',
          '473',
          '848',
          'REELS',
        ],
        [
          '12313',
          '17945032415433231',
          '17841400831935560',
          'Three-time Male Model of the Year, Derek Zoolander, is back for more Pepsi Zero Sugar. Was his reaction #RealOrActing? Try it for yourself to find out.',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/3A4A69EF224C204B2B0CB8224B26A28E_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=104&vs=746611616863720_1723605589&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8zQTRBNjlFRjIyNEMyMDRCMkIwQ0I4MjI0QjI2QTI4RV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0ItdGdoUFo3WEtaTVNFQ0FEVUhzckVZbUw5NGJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm6ozWm6m7mkAVAigCQzMsF0A%2BB64UeuFIGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfAH6V81ds-QaOparrWO-ks6gHR5rMOjsL_AM2Cmy8kssQ&oe=6409CDA0&_nc_sid=ea0b6e&_nc_rid=b9cd58da94',
          'https://www.instagram.com/reel/CpSgZrHAfHH/',
          '2023-03-02 14:02:28.000000',
          '12135',
          '178',
          '848',
          'REELS',
        ],
        [
          '8252',
          '17990429272753259',
          '17841400831935560',
          'Sneak a peek at our extended Super Bowl commercial starring stevemartinreally. Was his reaction to the new Pepsi Zero Sugar real, or was he just acting? #RealOrActing #SuperBowlLVII',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/9F4285B36F1FDEE79937FAEF2EDF93B7_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=111&vs=640446007904670_1657422884&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC85RjQyODVCMzZGMUZERUU3OTkzN0ZBRUYyRURGOTNCN192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0hHNXJoTmxWTlFNZU9FQUFPeXVVU1FzWEl3ZGJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmmpO3rdqrtT8VAigCQzMsF0BNl2yLQ5WBGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfAb86ONuB_rFNUH16WjxQIyk9HHFClb5e5E2MraJZ2ZfQ&oe=6409E224&_nc_sid=ea0b6e&_nc_rid=dfc29a5930',
          'https://www.instagram.com/reel/CoZ3NewAPVl/',
          '2023-02-08 14:05:18.000000',
          '8129',
          '123',
          '848',
          'REELS',
        ],
      ],
      least_5_posts: [
        [
          '53',
          '18073517119353442',
          '17841400831935560',
          'If this isn’t your idea of a perfect movie night idk what is',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/004B6B0C305D36EE6A3FF69A7E27C391_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=966159914373292_760358144&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8wMDRCNkIwQzMwNUQzNkVFNkEzRkY2OUE3RTI3QzM5MV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0RYWTF4T1JxaGE5dDZrQ0FMTk9ZRXN6NXBRNmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm9OSg6aL64T8VAigCQzMsF0AvxR64UeuFGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCBfgpiwkKX72fun3foWRh1lFhQo-E7HJXzb80Myd3gOA&oe=6409AE96&_nc_sid=ea0b6e&_nc_rid=8670234b0f',
          'https://www.instagram.com/reel/CpMTXwOgxyd/',
          '2023-02-28 04:13:02.000000',
          '50',
          '3',
          '848',
          'REELS',
        ],
        [
          '56',
          '17869897976828421',
          '17841400831935560',
          'We never want this spring fling to end Peepsbrand 🥰 #SpringisComing #PEPSIxPEEPS',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/474585B3DF18A7F70CB37F0608EB3FBD_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=100&vs=934925667926810_2220673826&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC80NzQ1ODVCM0RGMThBN0Y3MENCMzdGMDYwOEVCM0ZCRF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0g4MjZ4TTVvcG9WSms0REFGY3V3a3VOcnFaT2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm8Ib89PyB9z8VAigCQzMsF0ArnCj1wo9cGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBxKN8kJVcfHrieIKykjLmacAyUziP1tMtdZHYku91xZQ&oe=6409F8D3&_nc_sid=ea0b6e&_nc_rid=13096d29bf',
          'https://www.instagram.com/reel/CpdBIaWgpLQ/',
          '2023-03-06 16:00:45.000000',
          '52',
          '4',
          '848',
          'REELS',
        ],
        [
          '77',
          '18043153390379777',
          '17841400831935560',
          'A marshmallow cola flavor that will stop you in your tracks. ✋ What do you think of this limited time flavor? 👀 #PEPSIxPEEPS',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/904EA69D68A3DB39CD9F582C82D1BAB9_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=2546491868850022_974805201&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC85MDRFQTY5RDY4QTNEQjM5Q0Q5RjU4MkM4MkQxQkFCOV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR05wdDJSTURsN0JFdHA4SEFQQnpfeE1lXy1NTmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm7NWDyKHX7T8VAigCQzMsF0As3KwIMSbpGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCx37HyIDB2Lnru4PEsQojhsKIlr1CzK3umiJ6uJYdZAQ&oe=6409EBB3&_nc_sid=ea0b6e&_nc_rid=b2d97581ce',
          'https://www.instagram.com/reel/CpNkgHhgTAs/',
          '2023-02-28 16:02:10.000000',
          '71',
          '6',
          '848',
          'REELS',
        ],
        [
          '95',
          '17873135546837977',
          '17841400831935560',
          'That pillowy-soft marshmallow cola goodness 😋🐣 Let us know if you’ve found us yet ⬇️ #PEPSIxPEEPS',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/A8453A951E9AF12F1217B60DCEAD4B90_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=108&vs=1595646510911777_125282937&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9BODQ1M0E5NTFFOUFGMTJGMTIxN0I2MERDRUFENEI5MF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0xQM3hoUHlJN2RreEM0REFEZkpZUEc0VUR3dmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmhonpxrn1ikAVAigCQzMsF0Ayb1wo9cKPGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBEgsKWYgnFsT5tKAqTS83MAUZZi5R9wyByqzgnL6-czw&oe=640A03C5&_nc_sid=ea0b6e&_nc_rid=69a915cf89',
          'https://www.instagram.com/reel/CpAsK-sg5qB/',
          '2023-02-23 16:00:45.000000',
          '87',
          '8',
          '848',
          'REELS',
        ],
        [
          '112',
          '17948819522526357',
          '17841400831935560',
          'PEPSI x PEEPS marshmallow cola is back, and that means spring is coming 😎\n\nUse the #PEPSIxPEEPS Snapchat lens to scan your can or bottle for the chance to win a spring trip or exclusive #PEPSIxPEEPS spring merch. ✈️ 🌴 Scan with Snapchat or visit the link in bio to learn more!\n\nRules: No Purch Nec. Open to 50 US/DC, 18+ (19+ in AL/NE). Ends 4/17/23. See link in bio for full details.',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/A54C1EC10606E4FE73D8797A317B2AB4_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=647933203799779_2722478639&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9BNTRDMUVDMTA2MDZFNEZFNzNEODc5N0EzMTdCMkFCNF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0lQdm9ST0VYOG5EWElVQUFGMEtlYURHZ1ZkbmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm2Kzjs9K3%2BEAVAigCQzMsF0AmBaHKwIMSGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCY1rh4sUA7A6mQX_7g76YHhmkNGqls8sIkWYZwzTVfvg&oe=6409EE7E&_nc_sid=ea0b6e&_nc_rid=ad75e09c45',
          'https://www.instagram.com/reel/CoqDrCNAfWV/',
          '2023-02-14 21:01:41.000000',
          '104',
          '8',
          '848',
          'REELS',
        ],
      ],
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
      name: 'pepsi',
      followingCount: '15',
      followersCount: '1754981',
      instagram_account_id: '17841400831935560',
    },
    {
      business_account_id: '17841401654983900',
      top_5_posts: [
        [
          '9982',
          '17985394798786092',
          '17841401654983900',
          'Taking inspiration from rosalia.vt, we’ll never let them know our next move. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/034508A0DD70D116D63DF3EB06BC0D88_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=108&vs=1507082623150308_1824279916&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8wMzQ1MDhBMERENzBEMTE2RDYzREYzRUIwNkJDMEQ4OF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0QwZThoTTJmakdfUHNBQUFEdkU2WmMtNTNwQ2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmvryz79OB9j8VAigCQzMsF0AuKfvnbItEGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCCuuCvMql---Oz_BBmg6I_iXody2REH8ZPWDtUcbLQ7w&oe=64098FA6&_nc_sid=ea0b6e&_nc_rid=f8e1c95cff',
          'https://www.instagram.com/reel/CpQTatosIQA/',
          '2023-03-01 17:30:33.000000',
          '9759',
          '223',
          '312',
          'REELS',
        ],
        [
          '7651',
          '17969840633137873',
          '17841401654983900',
          'The great Coca-Cola taste with a hint of metamorphosis. Coca-Cola Move. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/5A4CE2C212F09E467858A4C743ED6AA4_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=102&vs=508347414569546_2108960864&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC81QTRDRTJDMjEyRjA5RTQ2Nzg1OEE0Qzc0M0VENkFBNF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0hDdmtCTlhTR1ZYX2VjRUFIX1JSd0FHd2NvZ2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmpPWgjJWT7D8VAigCQzMsF0AuFHrhR64UGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBRbMgXpCnwj3yzfNqUXCXN6D5A9-YqOLQ5gbJuEBG7fA&oe=640A2570&_nc_sid=ea0b6e&_nc_rid=82e784ab34',
          'https://www.instagram.com/reel/Cosnp7rP9Ij/',
          '2023-02-15 20:54:36.000000',
          '7458',
          '193',
          '312',
          'REELS',
        ],
        [
          '6905',
          '17929753232525974',
          '17841401654983900',
          'Swipe and send some love this Valentine’s! ❤️',
          'CAROUSEL_ALBUM',
          'https://video-fra5-2.cdninstagram.com/v/t50.2886-16/329638298_575410564608127_8166128325247190525_n.mp4?_nc_cat=109&vs=982073242755083_3921136550&_nc_vs=HBkcFQAYJEdKcmhwUk4tc0UwMlZRc0NBUDFGblNCeTZsTnhia1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJvjb29P61uxAFQIoAkMzLBdAGAAAAAAAABgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=YPRy3krx6mgAX_djo_l&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfBFZMfI3CY0PEzwsO0H3gfoesnLdcS_JFI81Oe95bMuVg&oe=6408AA4F&_nc_rid=0daa0126f0,https://video-fra5-2.cdninstagram.com/v/t50.2886-16/331249959_6311315382226677_1155817232892429333_n.mp4?_nc_cat=107&vs=5836141666500538_2792719280&_nc_vs=HBkcFQAYJEdDZDV2aFAxVG15Zkcyd1dBQlVFMUVlbVNRb1Fia1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJsan7IXz8d8%2FFQIoAkMzLBdAGCHKwIMSbxgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=CbjIflhkICcAX_4qnZ7&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCX_dzQ7ro2flh0Tg4Ngmq3u8LAQHAdq2SWkzYZhsvKxQ&oe=6408A99B&_nc_rid=1216063da9,https://video-fra5-2.cdninstagram.com/v/t50.2886-16/331285305_870765414039881_3340043913998383866_n.mp4?_nc_cat=109&vs=580663017285293_1515899840&_nc_vs=HBkcFQAYJEdEa0R2eE5Kd1MtZjlCY0RBUG82RkZRX09Gb3Via1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJtrvjf%2FX7u8%2FFQIoAkMzLBdAGCHKwIMSbxgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=tzoYqh1L8cwAX_2eHG9&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCRUpatF3xsIQInSB2RL3OXfXlK6BC71N4F7seKx5GL6w&oe=6408C7D5&_nc_rid=a9ad081cbb',
          'https://www.instagram.com/p/Copt8cLtNfo/',
          '2023-02-14 17:51:41.000000',
          '6803',
          '102',
          '312',
          'FEED',
        ],
        [
          '2436',
          '17997892501716173',
          '17841401654983900',
          'Explore a digital version of yourself–– Scan a Coca-Cola Move or click the link in the bio to meet your very own metaversion! Share with us when it’s ready. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/3A47DDBDB70698DD8705939D5EAA309D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=105&vs=1373278350091143_993953974&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8zQTQ3RERCREI3MDY5OEREODcwNTkzOUQ1RUFBMzA5RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0pJN3l4UEdhVGxHZHUwSEFOZkRzN0ppNjdvd2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmiuHv5srb5z8VAigCQzMsF0AuKfvnbItEGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCjmLd9h_Oq6TZFjhUl_tLD727MhJH_brlv5RhaArSwMg&oe=6409E9B7&_nc_sid=ea0b6e&_nc_rid=277844c250',
          'https://www.instagram.com/reel/Co7qT6asWuO/',
          '2023-02-21 17:06:23.000000',
          '2354',
          '82',
          '312',
          'REELS',
        ],
      ],
      least_5_posts: [
        [
          '2436',
          '17997892501716173',
          '17841401654983900',
          'Explore a digital version of yourself–– Scan a Coca-Cola Move or click the link in the bio to meet your very own metaversion! Share with us when it’s ready. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/3A47DDBDB70698DD8705939D5EAA309D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=105&vs=1373278350091143_993953974&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8zQTQ3RERCREI3MDY5OEREODcwNTkzOUQ1RUFBMzA5RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0pJN3l4UEdhVGxHZHUwSEFOZkRzN0ppNjdvd2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmiuHv5srb5z8VAigCQzMsF0AuKfvnbItEGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCjmLd9h_Oq6TZFjhUl_tLD727MhJH_brlv5RhaArSwMg&oe=6409E9B7&_nc_sid=ea0b6e&_nc_rid=277844c250',
          'https://www.instagram.com/reel/Co7qT6asWuO/',
          '2023-02-21 17:06:23.000000',
          '2354',
          '82',
          '312',
          'REELS',
        ],
        [
          '6905',
          '17929753232525974',
          '17841401654983900',
          'Swipe and send some love this Valentine’s! ❤️',
          'CAROUSEL_ALBUM',
          'https://video-fra5-2.cdninstagram.com/v/t50.2886-16/329638298_575410564608127_8166128325247190525_n.mp4?_nc_cat=109&vs=982073242755083_3921136550&_nc_vs=HBkcFQAYJEdKcmhwUk4tc0UwMlZRc0NBUDFGblNCeTZsTnhia1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJvjb29P61uxAFQIoAkMzLBdAGAAAAAAAABgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=YPRy3krx6mgAX_djo_l&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfBFZMfI3CY0PEzwsO0H3gfoesnLdcS_JFI81Oe95bMuVg&oe=6408AA4F&_nc_rid=0daa0126f0,https://video-fra5-2.cdninstagram.com/v/t50.2886-16/331249959_6311315382226677_1155817232892429333_n.mp4?_nc_cat=107&vs=5836141666500538_2792719280&_nc_vs=HBkcFQAYJEdDZDV2aFAxVG15Zkcyd1dBQlVFMUVlbVNRb1Fia1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJsan7IXz8d8%2FFQIoAkMzLBdAGCHKwIMSbxgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=CbjIflhkICcAX_4qnZ7&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCX_dzQ7ro2flh0Tg4Ngmq3u8LAQHAdq2SWkzYZhsvKxQ&oe=6408A99B&_nc_rid=1216063da9,https://video-fra5-2.cdninstagram.com/v/t50.2886-16/331285305_870765414039881_3340043913998383866_n.mp4?_nc_cat=109&vs=580663017285293_1515899840&_nc_vs=HBkcFQAYJEdEa0R2eE5Kd1MtZjlCY0RBUG82RkZRX09Gb3Via1lMQUFBRhUAAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJtrvjf%2FX7u8%2FFQIoAkMzLBdAGCHKwIMSbxgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jYXJvdXNlbF9pdGVtIn0%3D&_nc_ohc=tzoYqh1L8cwAX_2eHG9&_nc_ht=video-fra5-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCRUpatF3xsIQInSB2RL3OXfXlK6BC71N4F7seKx5GL6w&oe=6408C7D5&_nc_rid=a9ad081cbb',
          'https://www.instagram.com/p/Copt8cLtNfo/',
          '2023-02-14 17:51:41.000000',
          '6803',
          '102',
          '312',
          'FEED',
        ],
        [
          '7651',
          '17969840633137873',
          '17841401654983900',
          'The great Coca-Cola taste with a hint of metamorphosis. Coca-Cola Move. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/5A4CE2C212F09E467858A4C743ED6AA4_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=102&vs=508347414569546_2108960864&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC81QTRDRTJDMjEyRjA5RTQ2Nzg1OEE0Qzc0M0VENkFBNF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0hDdmtCTlhTR1ZYX2VjRUFIX1JSd0FHd2NvZ2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmpPWgjJWT7D8VAigCQzMsF0AuFHrhR64UGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBRbMgXpCnwj3yzfNqUXCXN6D5A9-YqOLQ5gbJuEBG7fA&oe=640A2570&_nc_sid=ea0b6e&_nc_rid=82e784ab34',
          'https://www.instagram.com/reel/Cosnp7rP9Ij/',
          '2023-02-15 20:54:36.000000',
          '7458',
          '193',
          '312',
          'REELS',
        ],
        [
          '9982',
          '17985394798786092',
          '17841401654983900',
          'Taking inspiration from rosalia.vt, we’ll never let them know our next move. #CocaColaCreations',
          'VIDEO',
          'https://video-fra5-2.cdninstagram.com/o1/v/t16/f1/m82/034508A0DD70D116D63DF3EB06BC0D88_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-fra5-2.cdninstagram.com&_nc_cat=108&vs=1507082623150308_1824279916&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8wMzQ1MDhBMERENzBEMTE2RDYzREYzRUIwNkJDMEQ4OF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0QwZThoTTJmakdfUHNBQUFEdkU2WmMtNTNwQ2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmvryz79OB9j8VAigCQzMsF0AuKfvnbItEGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCCuuCvMql---Oz_BBmg6I_iXody2REH8ZPWDtUcbLQ7w&oe=64098FA6&_nc_sid=ea0b6e&_nc_rid=f8e1c95cff',
          'https://www.instagram.com/reel/CpQTatosIQA/',
          '2023-03-01 17:30:33.000000',
          '9759',
          '223',
          '312',
          'REELS',
        ],
      ],
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/coca-cola.jpg',
      name: 'Coca-Cola',
      followingCount: '255',
      followersCount: '2861625',
      instagram_account_id: '17841401654983900',
    },
    {
      business_account_id: '17841402114104558',
      top_5_posts: [
        [
          '635',
          '17992791511744898',
          '17841402114104558',
          'Our orange-est era is so close we can almost taste it. Any guesses on what we have coming? 👀🍊',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/6248C59658FD53D923C0AB033EB8FDBC_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjQ4MC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=102&vs=1627230701121325_1201308905&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC82MjQ4QzU5NjU4RkQ1M0Q5MjNDMEFCMDMzRUI4RkRCQ192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACbgiMXJ38LuPxUCKAJDMywXQCUzMzMzMzMYEmRhc2hfYmFzZWxpbmVfMl92MREAdQAA&ccb=9-4&oh=00_AfA4zQjhlK--SVH9mAhkUXNlcSRinQDkrS4rRG6NcMb4tQ&oe=640A2349&_nc_sid=ea0b6e&_nc_rid=9b08d7eaf0',
          'https://www.instagram.com/reel/Co5E7e9jgfK/',
          '2023-02-20 17:03:40.000000',
          '617',
          '18',
          '52',
          'REELS',
        ],
        [
          '606',
          '17875757231786708',
          '17841402114104558',
          'Mirror, mirror on the wall, who’s the most Fanta-stic of them all? (It’s us.)\n\nDrop the self-love tips and tricks. 👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/7542C056C188FDCAE6D206E68F0AEF86_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=108&vs=580870103930308_2945315810&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC83NTQyQzA1NkMxODhGRENBRTZEMjA2RTY4RjBBRUY4Nl92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0VVbHVoUE94T0pqMXQ0RUFCNHlDcVlMajhCMmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm0tG6h%2FDOl0AVAigCQzMsF0AYBiTdLxqgGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDf7ubgmBxuG09YCrtIQ3px6iKbkzvZa_4Z-zHTyprNYw&oe=64098B6E&_nc_sid=ea0b6e&_nc_rid=35d7596975',
          'https://www.instagram.com/reel/Cop2kO1JYrQ/',
          '2023-02-14 19:08:19.000000',
          '591',
          '15',
          '52',
          'REELS',
        ],
        [
          '592',
          '17956284587220890',
          '17841402114104558',
          "Time traveling just to admire our eras. 🌀🕒 Wait, lol jk forgot time isn't real. 🥴 But if it was, which era would you visit?",
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/1F428EFA124BD47249B3DC80B506CD9B_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=104&vs=597114819096539_1988723893&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8xRjQyOEVGQTEyNEJENDcyNDlCM0RDODBCNTA2Q0Q5Ql92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaI2p6doOL2PxUCKAJDMywXQC4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdQAA&ccb=9-4&oh=00_AfB6g9XQ5BL8wrR3UupUyry34kMIKSfApV-IOWRbpyROkw&oe=6409895B&_nc_sid=ea0b6e&_nc_rid=ed25f493e6',
          'https://www.instagram.com/reel/Conl6DqM59O/',
          '2023-02-13 22:03:16.000000',
          '569',
          '23',
          '52',
          'REELS',
        ],
        [
          '479',
          '18205795819234673',
          '17841402114104558',
          'A new look AND new Fanta Orange taste has landed‼️ It’s our orange-est era yet. 🍊 Drop an emoji with your reaction. 👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/FB4607F05F8417AD1D513F04B9D9FFB1_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=1661135047649657_4059660211&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9GQjQ2MDdGMDVGODQxN0FEMUQ1MTNGMDRCOUQ5RkZCMV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACa89c3bzeCWQBUCKAJDMywXQCohysCDEm8YEmRhc2hfYmFzZWxpbmVfMV92MREAdQAA&ccb=9-4&oh=00_AfAPM8RPImaEXm1TbHodRwq7nGI2gl1ULv_p-lfFmowYMg&oe=640A231B&_nc_sid=ea0b6e&_nc_rid=299c80b0fd',
          'https://www.instagram.com/reel/CpA6W0OMlP_/',
          '2023-02-23 18:05:02.000000',
          '430',
          '49',
          '52',
          'REELS',
        ],
        [
          '414',
          '17913257519698033',
          '17841402114104558',
          'How deep is your love for Fanta? 🌀 What do you love about the gr🍊🍊vy-est era? 👇👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/C042FCCAC6308E1CBB77ADE5B3A305BA_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=110&vs=2060998607431785_839222816&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9DMDQyRkNDQUM2MzA4RTFDQkI3N0FERTVCM0EzMDVCQV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR1Bhd3hoTWQ5dEpXRzB3SEFGdHR2RTMwdkk0d2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm3snamJGalkEVAigCQzMsF0AyCn752yLRGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDlc6cu0er30BWx7gaodEHraDqVvtdBaK3CSZqXWOXYdQ&oe=6409899B&_nc_sid=ea0b6e&_nc_rid=8c0c552d25',
          'https://www.instagram.com/reel/Cou_YHzv4Z0/',
          '2023-02-16 19:00:32.000000',
          '398',
          '16',
          '52',
          'REELS',
        ],
      ],
      least_5_posts: [
        [
          '307',
          '18002661487564715',
          '17841402114104558',
          'Our orange-EST era cannot be contained. ✨',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/8848CA2686F269B3408C7DC3C7F15A94_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=109&vs=1892711317736874_4229507153&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC84ODQ4Q0EyNjg2RjI2OUIzNDA4QzdEQzNDN0YxNUE5NF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0RQajRCUDh2ZUdYUkJ3RUFNVnAyTUJqSjAwYmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmkpi7j9XCyj8VAigCQzMsF0Ai5mZmZmZmGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDpT_fapRCfWcs-3nsqpm9cL4DuGfmRdj0DQkVJwJvWug&oe=64099BE0&_nc_sid=ea0b6e&_nc_rid=056e7f60e5',
          'https://www.instagram.com/reel/CpS0xjbPRfe/',
          '2023-03-02 17:00:24.000000',
          '284',
          '23',
          '52',
          'REELS',
        ],
        [
          '414',
          '17913257519698033',
          '17841402114104558',
          'How deep is your love for Fanta? 🌀 What do you love about the gr🍊🍊vy-est era? 👇👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/C042FCCAC6308E1CBB77ADE5B3A305BA_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=110&vs=2060998607431785_839222816&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9DMDQyRkNDQUM2MzA4RTFDQkI3N0FERTVCM0EzMDVCQV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR1Bhd3hoTWQ5dEpXRzB3SEFGdHR2RTMwdkk0d2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm3snamJGalkEVAigCQzMsF0AyCn752yLRGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDlc6cu0er30BWx7gaodEHraDqVvtdBaK3CSZqXWOXYdQ&oe=6409899B&_nc_sid=ea0b6e&_nc_rid=8c0c552d25',
          'https://www.instagram.com/reel/Cou_YHzv4Z0/',
          '2023-02-16 19:00:32.000000',
          '398',
          '16',
          '52',
          'REELS',
        ],
        [
          '479',
          '18205795819234673',
          '17841402114104558',
          'A new look AND new Fanta Orange taste has landed‼️ It’s our orange-est era yet. 🍊 Drop an emoji with your reaction. 👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/FB4607F05F8417AD1D513F04B9D9FFB1_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=1661135047649657_4059660211&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9GQjQ2MDdGMDVGODQxN0FEMUQ1MTNGMDRCOUQ5RkZCMV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACa89c3bzeCWQBUCKAJDMywXQCohysCDEm8YEmRhc2hfYmFzZWxpbmVfMV92MREAdQAA&ccb=9-4&oh=00_AfAPM8RPImaEXm1TbHodRwq7nGI2gl1ULv_p-lfFmowYMg&oe=640A231B&_nc_sid=ea0b6e&_nc_rid=299c80b0fd',
          'https://www.instagram.com/reel/CpA6W0OMlP_/',
          '2023-02-23 18:05:02.000000',
          '430',
          '49',
          '52',
          'REELS',
        ],
        [
          '592',
          '17956284587220890',
          '17841402114104558',
          "Time traveling just to admire our eras. 🌀🕒 Wait, lol jk forgot time isn't real. 🥴 But if it was, which era would you visit?",
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/1F428EFA124BD47249B3DC80B506CD9B_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=104&vs=597114819096539_1988723893&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8xRjQyOEVGQTEyNEJENDcyNDlCM0RDODBCNTA2Q0Q5Ql92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaI2p6doOL2PxUCKAJDMywXQC4AAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdQAA&ccb=9-4&oh=00_AfB6g9XQ5BL8wrR3UupUyry34kMIKSfApV-IOWRbpyROkw&oe=6409895B&_nc_sid=ea0b6e&_nc_rid=ed25f493e6',
          'https://www.instagram.com/reel/Conl6DqM59O/',
          '2023-02-13 22:03:16.000000',
          '569',
          '23',
          '52',
          'REELS',
        ],
        [
          '606',
          '17875757231786708',
          '17841402114104558',
          'Mirror, mirror on the wall, who’s the most Fanta-stic of them all? (It’s us.)\n\nDrop the self-love tips and tricks. 👇',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/7542C056C188FDCAE6D206E68F0AEF86_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=108&vs=580870103930308_2945315810&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC83NTQyQzA1NkMxODhGRENBRTZEMjA2RTY4RjBBRUY4Nl92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0VVbHVoUE94T0pqMXQ0RUFCNHlDcVlMajhCMmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm0tG6h%2FDOl0AVAigCQzMsF0AYBiTdLxqgGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfDf7ubgmBxuG09YCrtIQ3px6iKbkzvZa_4Z-zHTyprNYw&oe=64098B6E&_nc_sid=ea0b6e&_nc_rid=35d7596975',
          'https://www.instagram.com/reel/Cop2kO1JYrQ/',
          '2023-02-14 19:08:19.000000',
          '591',
          '15',
          '52',
          'REELS',
        ],
      ],
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/fanta.jpg',
      name: 'Fanta',
      followingCount: '20',
      followersCount: '504514',
      instagram_account_id: '17841402114104558',
    },
    {
      business_account_id: '17841400849660007',
      top_5_posts: [
        [
          '1207065',
          '17974886222042877',
          '17841400849660007',
          'all we have to say is: 🛞🛞🛞 > 🛞🛞🛞🛞\n\n#redbull #givesyouwiiings #redbullsoapbox #soapbox #soapboxracing #funny #vwbus',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/214669452A68C68F386C9F79C1ACD69D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=588998065991057_2253299768&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8yMTQ2Njk0NTJBNjhDNjhGMzg2QzlGNzlDMUFDRDY5RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR05XLW9oT3dVMWxJNVFFREFMZ0J6U1NWUW9OTmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmjMik86bv1z8VAigCQzMsF0BEHrhR64UfGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCPoSIupqQ8XRxRWbt5xeUx4kpXi01ounu-1dTL95mMNQ&oe=6409C461&_nc_sid=ea0b6e&_nc_rid=a58b026f86',
          'https://www.instagram.com/reel/CoUza1YAzYX/',
          '2023-02-06 14:56:01.000000',
          '1199787',
          '7278',
          '9406',
          'REELS',
        ],
        [
          '710139',
          '17899519682749129',
          '17841400849660007',
          'here’s parxxx to show us all that it is in fact possible to waterski attached to an F1 car whilst barefoot 🦶😂🔥\n\n#redbull #givesyouwiiings #f1 #formula1 #formulaone #watersports #waterskiing #waterski',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/AD43AC7CF08CFE7AA9E64C41352BA8B2_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=107&vs=736869004821701_508562324&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9BRDQzQUM3Q0YwOENGRTdBQTlFNjRDNDEzNTJCQThCMl92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0VwVHhSTWUwUGxfZC1NQkFKYkcxNldZelAxMmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmgtbywfrJ9T8VAigCQzMsF0Afw5WBBiTdGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBtOLP3dIMLcjwFvOF3t57iaQapqRgBYzFZ3voXfr-Rxw&oe=6409B801&_nc_sid=ea0b6e&_nc_rid=83c8f4e5ad',
          'https://www.instagram.com/reel/CoxK4NSgCSj/',
          '2023-02-17 15:19:24.000000',
          '707167',
          '2972',
          '9406',
          'REELS',
        ],
        [
          '479892',
          '18025640560476418',
          '17841400849660007',
          'if this isn’t wakeboarding ASMR then we’re calling it quits because this is just too NICE 🧊🤩🔥\n\n🏂: nikguehrs \n\n#redbull #givesyouwiiings #wintersports #icy #frozen #frozenlakewake #wakeboarding #lake #watersports redbullgermany',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/924ADFB0F1B14BF1D4D780B8B0114895_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=108&vs=964102868084520_1108638190&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC85MjRBREZCMEYxQjE0QkYxRDRENzgwQjhCMDExNDg5NV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR09DSTF4TU1hZzlVdjNFQ0FJTlIyaktnZVdoOWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmvM6ksN%2BA%2Fz8VAigCQzMsF0AqZmZmZmZmGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfB5PAU4mGCf03jKdPVZkljO1QhFHNN-AlKSTGw1Om9CSw&oe=6409B0BB&_nc_sid=ea0b6e&_nc_rid=285a5c91c8',
          'https://www.instagram.com/reel/CpAmjhGAfcf/',
          '2023-02-23 15:10:08.000000',
          '477671',
          '2221',
          '9406',
          'REELS',
        ],
        [
          '437265',
          '18010487668497797',
          '17841400849660007',
          'we’ve watched this 100x and we’re still amazed by that suspension 🤯💨🤌\n\n🚘: brycemenzies7 \n\n#redbull #givesyouwiiings #motorsports #suspension #moto #offroad #bm7 #motolife',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/994D49A37107B15047BE84D2031CB38A_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=107&vs=3408919032712313_696376608&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC85OTRENDlBMzcxMDdCMTUwNDdCRTg0RDIwMzFDQjM4QV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0YyWXJ4T1RGUFYzOGVBQUFCSExxakptQWtJdGJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm8ue32M6v%2Fj8VAigCQzMsF0AiUeuFHrhSGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfD56PdcMO-zWQZA8sDDfbGq5g3u4aGTmapaCVr7O_QsXw&oe=640A1947&_nc_sid=ea0b6e&_nc_rid=96cf06da4d',
          'https://www.instagram.com/reel/CoZ9KXcAF9R/',
          '2023-02-08 14:57:15.000000',
          '435411',
          '1854',
          '9406',
          'REELS',
        ],
        [
          '340071',
          '17975107355052282',
          '17841400849660007',
          'if this #GoPro Course Preview gets your blood flowing, just wait for Red Bull PlayStreets to kick off ⛷️🔥\n\ncatch some of the most daring and creative tricks in freestyle skiing on 10 Feb at 7PM CET (10AM PST) streamed live through the ~link in bio~ 🔗📺\n\n⛷️: nickgoepper + #GoProMAX\n\ngoprode #redbull #givesyouwiiings #hypersmooth #redbullplaystreets #goprosnow #freestyle #hypersmooth #freestyleskiing #skiing #gastein #badgastein',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/BF49280C494315C6AFED74B90CC6859D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=104&vs=744071650649189_2378322280&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9CRjQ5MjgwQzQ5NDMxNUM2QUZFRDc0QjkwQ0M2ODU5RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR1AyVW9CUHp2cF9xLXRJU0FLU1BBandGTGU1cmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmsPyv5ZCFxj8VAigCQzMsF0BM2HKwIMScGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfATVOedHSbR4q73-_CcC-OTERaZgNhSZuLpxONCXFUVpQ&oe=6409E839&_nc_sid=ea0b6e&_nc_rid=6a7e6e80a7',
          'https://www.instagram.com/reel/CoeeJcIoTjB/',
          '2023-02-10 09:03:49.000000',
          '338861',
          '1210',
          '9406',
          'REELS',
        ],
      ],
      least_5_posts: [
        [
          '11646',
          '17984994013797968',
          '17841400849660007',
          'been there, done that 😏🌋\n\n#redbull #givesyouwiiings #volcano #pov #wingsuit #skydiving #skydive #snowcap',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/6F47C4644B9A592C048504678D3B419D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=103&vs=597580328619965_148976489&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC82RjQ3QzQ2NDRCOUE1OTJDMDQ4NTA0Njc4RDNCNDE5RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0hIRjh4TzEzOHFyWkJjQ0FFNGQ2REVtYlgwamJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmwoXpjN6s6z8VAigCQzMsF0AiZmZmZmZmGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfBxW0sM4iBONU1m-LO_bm4e4aBn6Ohnd0Ld4bdo-zt-RQ&oe=6409DA50&_nc_sid=ea0b6e&_nc_rid=03ec102f95',
          'https://www.instagram.com/reel/Cpfi2sXgNCd/',
          '2023-03-07 15:34:26.000000',
          '11581',
          '65',
          '9406',
          'REELS',
        ],
        [
          '17123',
          '17971505156000254',
          '17841400849660007',
          'safe to say maxverstappen1 is a man of many talents 👏👏👏\n\n#redbull #givesyouwiiings #motorsports #formula1 #f1 #formulaone',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/D241035012EA5D2B44B010EB5C47728A_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=107&vs=948721362784007_345707342&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9EMjQxMDM1MDEyRUE1RDJCNDRCMDEwRUI1QzQ3NzI4QV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR1BzeDBCTmNtN0N3YXVRQkFNOFpHNlI4TkpjOGJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmiPzorczq8z8VAigCQzMsF0AwAAAAAAAAGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfAwJzjY1KW3nxw5qnq6l2WUA4_hsiVTpEDXYyL6rKb-Fg&oe=6409C4EC&_nc_sid=ea0b6e&_nc_rid=feb945df4b',
          'https://www.instagram.com/reel/CpDKXU5gIZY/',
          '2023-02-24 15:02:13.000000',
          '17057',
          '66',
          '9406',
          'REELS',
        ],
        [
          '18398',
          '17959394573366378',
          '17841400849660007',
          'there are no right or wrong answers 💭👇\n\n#redbull #givesyouwiiings #energydrink #favedrink #drink #thoughtstarter',
          'IMAGE',
          'https://scontent-frt3-2.cdninstagram.com/v/t51.29350-15/332378879_748140196681172_345974594485693565_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=cAypLW2ekXIAX-HnO4m&_nc_ht=scontent-frt3-2.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfBVQfNz2t9EJLQaiADl-qQ9W3x4OVwKsMmUJHjw34jI3A&oe=640D196F',
          'https://www.instagram.com/p/Co-B-5jtlTN/',
          '2023-02-22 15:11:28.000000',
          '17408',
          '990',
          '9406',
          'FEED',
        ],
        [
          '20583',
          '17992497736769303',
          '17841400849660007',
          'how many POVs is too many POVs? you can never really have enough 🤷🔥😮‍💨\n\n#redbull #givesyouwiiings #formulaone #f1 #racecar #povcam #angles',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/F74DA36BAA12CBCE739072618F3550AA_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=108&vs=1171143326930493_2550863505&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9GNzREQTM2QkFBMTJDQkNFNzM5MDcyNjE4RjM1NTBBQV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0R0azFSTnp0TUZKenRJQUFDYUlYX3YzbGdzbWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmyPKNxLbGyj8VAigCQzMsF0AxZmZmZmZmGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfA92RST-CAfXW3cdXu0ybbgXkTheNcQq76vdlkjiIJmdA&oe=640A0D8F&_nc_sid=ea0b6e&_nc_rid=a348d732b2',
          'https://www.instagram.com/reel/CpXwQhMIp2N/',
          '2023-03-04 14:59:08.000000',
          '20531',
          '52',
          '9406',
          'REELS',
        ],
        [
          '24333',
          '17996867431641567',
          '17841400849660007',
          'what the drone sees vs. what the rider sees 😎✨\n\nit can only be Red Bull Valparaiso Cerro Abajo that produces these kind of views and it’s *BACK* this weekend 👏 watch it all on Red Bull TV 📺\n\n#redbull #givesyouwiiings #pov #drone #downhillmtb #bikelife',
          'VIDEO',
          'https://video-frt3-2.cdninstagram.com/o1/v/t16/f1/m82/134126BFE49E4EC6DD8E92957768C68D_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video-frt3-2.cdninstagram.com&_nc_cat=105&vs=569718961859012_1423208709&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC8xMzQxMjZCRkU0OUU0RUM2REQ4RTkyOTU3NzY4QzY4RF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR09qN214TnJpTktWZURJREFEUnFUdUxlX0FKc2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmnM6ruvWC%2Bz8VAigCQzMsF0A5zMzMzMzNGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfD86wavLWl_dRPjE62G8N5k1IHWA7HaCLtHdPFAbN4ivQ&oe=6409E997&_nc_sid=ea0b6e&_nc_rid=ea44b17897',
          'https://www.instagram.com/reel/CoXaFGxg_nX/',
          '2023-02-07 15:14:00.000000',
          '24229',
          '104',
          '9406',
          'REELS',
        ],
      ],
      image:
        'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
      name: 'Red Bull',
      followingCount: '1890',
      followersCount: '17124006',
      instagram_account_id: '17841400849660007',
    },
  ],
  mostEngagedHashtags: [
    {
      tag: '#redbull',
      count: '30',
      companies_using: [
        {
          accountId: '17841400849660007',
          name: 'Red Bull',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
          slug: 'redbull',
        },
      ],
      total_engagement: '4418467',
      engagement_per_post: 14728223.33,
      engagement_per_follower: 19.86,
    },
    {
      tag: '#givesyouwiiings',
      count: '30',
      companies_using: [
        {
          accountId: '17841400849660007',
          name: 'Red Bull',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
          slug: 'redbull',
        },
      ],
      total_engagement: '4418467',
      engagement_per_post: 14728223.33,
      engagement_per_follower: 19.86,
    },
    {
      tag: '#RealOrActing',
      count: '8',
      companies_using: [
        {
          accountId: '17841400831935560',
          name: 'pepsi',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
          slug: 'pepsi',
        },
      ],
      total_engagement: '56112',
      engagement_per_post: 701400,
      engagement_per_follower: 0.25,
    },
    {
      tag: '#PepsiZeroSugar',
      count: '8',
      companies_using: [
        {
          accountId: '17841400831935560',
          name: 'pepsi',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
          slug: 'pepsi',
        },
      ],
      total_engagement: '47618',
      engagement_per_post: 595225,
      engagement_per_follower: 0.21,
    },
    {
      tag: '#watersports',
      count: '7',
      companies_using: [
        {
          accountId: '17841400849660007',
          name: 'Red Bull',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
          slug: 'redbull',
        },
      ],
      total_engagement: '1422047',
      engagement_per_post: 20314957.14,
      engagement_per_follower: 6.39,
    },
    {
      tag: '#PEPSIxPEEPS',
      count: '6',
      companies_using: [
        {
          accountId: '17841400831935560',
          name: 'pepsi',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/pepsi.jpg',
          slug: 'pepsi',
        },
      ],
      total_engagement: '21437',
      engagement_per_post: 357283.33,
      engagement_per_follower: 0.1,
    },
    {
      tag: '#f1',
      count: '5',
      companies_using: [
        {
          accountId: '17841400849660007',
          name: 'Red Bull',
          image:
            'https://storage.googleapis.com/lumotive-web-storage/competitor-analytics/redbull.jpg',
          slug: 'redbull',
        },
      ],
      total_engagement: '839788',
      engagement_per_post: 16795760,
      engagement_per_follower: 3.77,
    },
  ],
}
