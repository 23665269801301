<template>

  <div class="post_share_option post_share_option_social basic_form_inline clear-both">
    <!--  Threaded tweets toggle-->
    <div
v-if="getAccountSelection['twitter'] && getAccountSelection['twitter'].length > 0 && getTwitterOptions.has_threaded_tweets === false && getCommonBoxOptions.status"
         class="d-flex align-items-start mb-3">

      <div class="left_input">
        <p class="label">Add threaded Tweets
          <!-- Open Article in a modal -->
          <a
v-tooltip.top="'Learn more about threaded tweets'" class="beacon ml-2"
             data-beacon-article-modal="61826fd112c07c18afde3ed5"
             href="#">
            <i class="far fa-question-circle"
            ></i>
          </a>

        </p>
      </div>
      <div class="right_input d-flex align_center">
        <label class="switch-radio">
          <input
v-model="getTwitterOptions.has_threaded_tweets"
                 type="checkbox"
                 :disabled="(!isThreadedTweetsEnabled)"
          >
          <div
class="slider round"
               :class="{ 'disabled grey': (!isThreadedTweetsEnabled) }"
          ></div>
        </label>

      </div>
    </div>


    <div
v-if="!getCarouselOptions.is_carousel_post"
         class="flex items-start mb-3">

      <div class="left_input">
        <p class="label">Create a Facebook carousel post
          <!-- Open Article in a modal -->
          <a
v-tooltip.top="'Carousal Post feature is available for Facebook Page'" class="beacon ml-2"
             href="#" data-beacon-article-modal="6221f69bab585b230a89d34b">
            <i class="far fa-question-circle"
            ></i>
          </a>

        </p>
      </div>
      <div class="right_input flex items-center">
        <label v-tooltip.top="''" class="switch-radio">
          <input
v-model="getCarouselOptions.is_carousel_post"
                 type="checkbox"
                 :disabled="!isFacebookPageSelected"
          >
          <div
class="slider round"
               :class="{ 'disabled grey': (!isFacebookPageSelected) }"
          ></div>
        </label>

      </div>
    </div>



    <!--   First Comment Toggle   -->
    <!--      {{instagramPostingMethod !== 'mobile'}}-->
    <!--      v-if="(isInstagramSelected || isFacebookSelected) && (!getPublishSelection.queueStatus) || instagramPostingMethod !== 'mobile'"-->
    <div v-if="!getSocialSharingFirstComment.has_first_comment" class="d-flex align-items-start mb-3">

      <div class="left_input">
        <p class="label">Add first comment
          <!-- Open Article in a modal -->
          <a
v-tooltip.top="'Learn more about first comment'" class="beacon ml-2"
             href="#" data-beacon-article-modal="60407b770a2dae5b58fb5bef">
            <i class="far fa-question-circle"
            ></i>
          </a>

        </p>
      </div>
      <div class="right_input d-flex align_center">
        <label class="switch-radio">
          <input
type="checkbox"
                 :checked="getSocialSharingFirstComment.has_first_comment"
                 :disabled="(!isFirstCommentEnabled) "
                 @change="HasFirstCommentChanged"
          >
          <div
class="slider round"
               :class="{ 'disabled grey': (!isFirstCommentEnabled) }"
          ></div>
        </label>

      </div>
    </div>

    <!--        for instagram posting-->
    <div v-if="isInstagramSelected" class="d-flex align-items-start">
      <div class="left_input">
        <p class="label">Instagram publishing method</p>
      </div>
      <div class="right_input">
        <div class="field_group">
          <label class="radio_container">
            <input
                id="api"
                v-model="instagramPostingMethod"
                :disabled="disableAPI"
                type="radio"
                value="api"
            >
            <div class="text-transform-none">Direct publishing via API (For single/multiple images and single video posts)<span class="check"></span></div>
          </label>
          <label class="radio_container">
            <input
                id="mobile"
                v-model="instagramPostingMethod"
                :disabled="disableMobile"
                type="radio"
                value="mobile"
            >
            <div class="text-transform-none">Mobile notification (For stories, IGTV and reels)<span class="check"></span></div>
          </label>
        </div>
      </div>
    </div>

    <template v-if="!isGlobalContentCategory && !getPublishSelection.queueStatus && getUserRole !== 'Approver'">
      <div class="d-flex align-items-start">
        <div class="left_input">
          <p class="mt-3">When to publish this?</p>
        </div>
        <div class="right_input">

          <div class="field_group">

            <label v-if="!isCSV" class="radio_container">
              <input
:id="type + '_post_now'" v-model="getPublishTimeOptions.time_type"
                     checked="checked"
                     :disabled="!hasPermission('can_schedule_plan') || isContentCategorySelected" value="now"
                     type="radio" :name="type + 'time_type'">

              <div :for="type + '_post_now'">

                <p
v-if="isContentCategorySelected"
                   v-tooltip.top="'You have selected content category, if you would like to publish content now, unselect your content category'">
                  Post Now</p>
                <p v-else>Post Now</p>

                <span class="check"></span>
                <!--<a v-if="isContentCategorySelected"-->
                <!--href="javascript:;" target="_blank"><i class="far fa-question-circle ml-1"-->
                <!--&gt;</i></a>-->
              </div>

            </label>
            <div class="flex">
            <div>
            <label class="radio_container d-flex justify-content-center align-items-center" data-cy='schedule-post'>
              <input
:id="type + '_schedule'" v-model="getPublishTimeOptions.time_type" value="schedule"
                     type="radio" :name="type + 'time_type'"
                     :disabled="isContentCategorySelected && !isCSV">

              <div :for="type + '_schedule'">
                <p
v-if="isContentCategorySelected"
                   v-tooltip.top="'You have selected content category, if you would like to schedule content, unselect your content category'">
                  Schedule </p>
                <p v-else>Schedule</p>
                <span class="check"></span>
                <!--<a-->
                <!---->
                <!--href="javascript:;" target="_blank"><i class="far fa-question-circle ml-1"-->
                <!--&gt;</i></a>-->
              </div>
            </label>
              </div>


            <div>
             <a
v-b-modal.timeRecommendationModal

                 href="javascript:;"
                 :class="!isRecommendedAccountSelection || isContentCategorySelected ? 'disabled' : ''"
                 class="ml-3 d-inline-block recommented-time-btn" @click="fetchRecommendedTimeData"><span v-tooltip.top="!isRecommendedAccountSelection ? 'Please Select a Social Account First' : ''">Recommended Best Time</span></a>
                 </div>
            </div>
            <transition name="slide-bottom">
              <div
v-if="getPublishTimeOptions.time_type === 'schedule'"
                   class=" input_field d-flex align-items-start mt-2 mb-2">

                <div class="pl-0">
                  <div class="gray_bg_field gray_bg_field--alt field_group mb-0 inner_section d-flex align-items-start mt-0">
                    <div class="w-100 pl-0 d-flex align-items-center">

                      <CustomizeDateRangePicker
class="datepicker_input"
                                                   :opens="opens"
                                                   :start-date="getStartDate"
                                                   :locale-data="locale"
                                                   :min-date="getMinDate"
                                                   :single-date-picker="singleDatePicker"
                                                   :time-picker="timePicker"
                                                   :time-picker24hour="timePicker24Hour"
                                                   :show-week-numbers="showWeekNumbers"
                                                   :show-dropdowns="showDropdowns"
                                                   :auto-apply="autoApply"
                                                   :ranges="show_ranges ? undefined : false"
                                                   :timezone="getWorkspaces.activeWorkspace.timezone"
                                                   @update="updateValues">

                        <div
slot="input" slot-scope="picker" class="datepicker_content  py-2 px-3"
                             style="min-width: 250px;">
                          <i class="icon-Calendar"></i>
                          <span>{{ $filters.date(picker.startDate) }}</span>
                        </div>
                      </CustomizeDateRangePicker>

                      <p style="font-size: 0.85rem; margin: 0.7rem 1rem;">
                        {{ getWorkspaces.activeWorkspace.timezone }} (
                        <clock />
                        )
                      </p>

                    </div>
                  </div>

                </div>

              </div>
            </transition>
            <template v-if="type === 'Social' ">

              <div class="tooltip_link_contain" data-cy='queue-post'>

                <label class="radio_container tooltip_link">
                  <input
:id="type + '_queued'" v-model="getPublishTimeOptions.time_type" value="queued"
                         :disabled="isContentCategorySelected && !isCSV"
                         type="radio" :name="type + 'time_type'"
                         data-cy="AddToQueue">

                  <div :for="type + '_queued'">
                    <p>Add to Queue</p>

                    <span class="check"></span>

                    <!--<a v-tooltip.top="'Learn more about queue slots'"-->
                    <!--href="https://docs.contentstudio.io/article/691-what-are-social-queues"-->
                    <!--target="_blank"><i class="far fa-question-circle ml-1"-->
                    <!--&gt;</i></a>-->
                  </div>

                </label>
                <div class="show_tooltip_link">
                  Learn more about Queue Slots <a
                    href="https://docs.contentstudio.io/article/691-what-are-social-queues" target="_blank">
                  Click Here
                </a>
                </div>
              </div>

              <div class="tooltip_link_contain">

                <label class="radio_container tooltip_link" data-cy='content-catagory-post'>
                  <input
:id="type + '_content_category'" v-model="getPublishTimeOptions.time_type"
                         value="content_category"
                         type="radio" :name="type + 'time_type'">

                  <div :for="type + '_content_category'">

                    <p>Add to Content Category</p>

                    <span class="check"></span>
                    <!--<a v-tooltip.top="'Learn more about Content Category'"-->
                    <!--href="https://docs.contentstudio.io/article/692-what-are-the-queues-in-content-categories"-->
                    <!--target="_blank"><i class="far fa-question-circle ml-1"-->
                    <!--&gt;</i></a>-->
                  </div>

                </label>

                <div class="show_tooltip_link">
                  Learn more about Content Category <a
                    href="https://docs.contentstudio.io/article/692-what-are-the-queues-in-content-categories"
                    target="_blank">
                  Click Here
                </a>
                </div>

              </div>

              <label v-if="!isCSV" class="radio_container" data-cy='draft-post'>
                <input
:id="type + '_draft'" v-model="getPublishTimeOptions.time_type" value="draft"
                       type="radio" :name="type + 'time_type'">

                <div :for="type + '_draft'"> Draft
                  <span class="check"></span>
                </div>

              </label>

              <template v-if="isContentCategoryType">
                <ContentCategoriesSlotTime></ContentCategoriesSlotTime>
              </template>
              <template v-if="isQueuePost && accountSelectionCount() > 1">
                <QueuePostNote></QueuePostNote>
              </template>

            </template>

          </div>

        </div>
      </div>

      <div class="postnow_option_block"></div>

      <transition-group name="slide-bottom">
        <template
v-if="!isOnlyTwitterSelected && !isCSV && (getPublishTimeOptions.time_type === 'schedule' || getPublishTimeOptions.time_type === 'now') &&
         ( !getPublishSelection.plan_id || (getPublishSelection.plan_id && (getPublishSelection.status === 'draft' || getPublishTimeOptions.repeat === 'repeat')))">

          <div key="repeat-post-option" class="d-flex align-items-start">
            <div class="left_input">
              <p class="mt-3">Would you like to repeat this post?</p>
            </div>
            <div class="right_input">

              <div class="field_group">

                <label class="radio_container">
                  <input
id="no_repeat" v-model="getPublishTimeOptions.repeat" value="" type="radio"
                         name="no_repeat">
                  <div for="no_repeat"> No
                    <span class="check"></span>
                  </div>
                </label>

                <label class="radio_container" data-cy='post-multipletimes'>
                  <input
id="repeat_post" v-model="getPublishTimeOptions.repeat" value="repeat"
                         type="radio"
                         name="repeat_post">
                  <div for="repeat_post"> Yes, multiple times
                    <span class="check"></span>
                  </div>
                </label>

              </div>

            </div>
          </div>

          <template v-if="getPublishTimeOptions.repeat === 'repeat'">

            <div key="repoeat-posts" class="d-flex align-items-start">
              <div class="left_input">
                &nbsp;
              </div>
              <div class="right_input">
                <div class="gray_bg_field field_group">
                  <div class="inner_section d-flex align-items-center ">
                    <div class="left_input">
                      <p class="label">Repeat</p>
                    </div>
                    <div class="right_input">
                      <input
v-model.number="getPublishTimeOptions.repeat_times" class="w-auto text-center moz-input" type="number" min="1"
                             max="99"
                             onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));" data-cy='repeat-time'>
                      <p class="label d-inline-block ml-3">time(s) </p>
                      <a v-tooltip.top="'Repeat times must be between 1 to 99.'" class="beacon ml-2">
                        <i class="far fa-question-circle"></i>
                      </a>
                      <!--<p v-if="repeat_Post_Limit" class="input-error">You have exceeded the Repeat Post limit which is 99</p>-->
                    </div>
                  </div>
                  <div class="inner_section mb-0 d-flex align-items-center ">
                    <div class="left_input">
                      <p class="label">With the interval of</p>
                    </div>
                    <div class="right_input">
                      <input
v-model="getPublishTimeOptions.repeat_gap" class="text-center mr-2"
                             type="number"
                             min="1"
                             onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));" style="width: 72px;" data-cy='repeat-interval'>

                      <div class="field_group d-inline-block">
                        <select
v-model="getPublishTimeOptions.repeat_type" class="select_sm"
                                data-cy='repeat-days' @change="setPublishingRepeatGap(1)">
                          <option value="Hour">Hour(s)</option>
                          <option value="Day">Day(s)</option>
                          <option value="Week">Week(s)</option>
                          <option value="Month">Month(s)</option>
                        </select>
                        <i class="icon-dropdown-cs"></i>
                      </div>

                    </div>
                  </div>

                  <div v-if="processPostTimings.length > 0" class="post_timing pt-3">
                    <ul class="post_timing_list">
                      <li v-for="(time, key) in processPostTimings" :key="key">
                        {{ getDateTimeFormat(time, 'MMMM DD,HH:mm') }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>

          </template>
        </template>
      </transition-group>

      <div v-if="getPublishTimeOptions.time_type === 'evergreen'" class="evergreen_option">
        <p class="title">Choose a campaign</p>
        <div class="schedule_timezone d-flex align-items-center">
          <p class="">Evergreen campaign</p>
          <!--<p class="text">Evergreen campaign</p>-->
          <div class="input_field mt-0 ml-3">
            <!--with_bg-->
            <select id="" name="" class="">
              <option value="">Evergreen campaign</option>
              <option value="">Evergreen campaign</option>
              <option value="">Evergreen campaign</option>
            </select>
          </div>
        </div>

        <div class="radio_list input_field  ">
          <div class="radio_input_image">
            <input id="apply_changes_next" type="radio" name="apply_changes">
            <label for="apply_changes_next" class="radio_left">Apply changes from next cycle</label>
          </div>
          <div class="radio_input_image">
            <input id="apply_changes_now" type="radio" name="apply_changes">
            <label for="apply_changes_now" class="radio_left">Apply changes now and restart cycle</label>
          </div>
        </div>

        <div class="social_accounts_list">
          <div class="profile_picture w_40 tw">
            <div class="picture_block">
              <div class="icon">
                <i class="cs-twitter"></i>
              </div>
              <img
src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                   alt="">
            </div>
          </div>
          <div class="profile_picture w_40 fb">
            <div class="picture_block">
              <div class="icon">
                <i class="cs-facebook"></i>
              </div>
              <img
src="https://pbs.twimg.com/profile_images/878937180563005440/jVOkk_OG_400x400.jpg"
                   alt="">
            </div>
          </div>
        </div>

      </div>

    </template>

    <!-- global content category text -->

    <div v-if="isGlobalContentCategory" class="input_field d-flex align-items-start">
      <div class="input_field warning_box m-auto">
        <div class="text-center"><i class="far fa-exclamation mr-2"></i>This post will be published according to
          the time slots and social channels selected for this global category in each workspace.
        </div>
      </div>
    </div>

    <div v-if="isApprovalPost && getUserRole !== 'Approver'" class="post_share_option ">
      <div class="schedule_detail_box mt-3">

        <div class="bottom_content mt-0">
          <h3>Approval Status
          </h3>
          <p
v-if="getPublishSelection.planApprovalData.approvers.length > 1" class="p-2"
             style="text-align: center">
            {{ getApprovalOptionText(getPublishSelection.planApprovalData.approve_option) }}
          </p>
          <div class="approval_list">
            <FeedViewApprovalStatus
                :approval="getPublishSelection.planApprovalData"></FeedViewApprovalStatus>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!getPublishSelection.queueStatus || checkPendingApproval && getUserRole !== 'Approver'" class="post_footer_block">
      <div v-if="!isGlobalContentCategory" class="hide_from_approver">

        <div
v-if="getPublishTimeOptions.time_type === 'draft'
                    && getTeamMembership === 'team' && getPublishTimeOptions.plan_status === 'Approved'" class="mt-0 mr-3 d-inline-block">

          <div class="field_group">
            <div class="checkbox_container">
              <label for="hideFromClient">
                Hide from client
                <input id="hideFromClient" v-model="getPublishTimeOptions.hide_client" type="checkbox">
                <span class="check"></span>
              </label>
            </div>
          </div>

        </div>

      </div>
      <div class="post_footer_actions">
        <div class="post_footer text-right">
          <template v-if="isGlobalContentCategory">
            <button
v-if="getPublishSelection.content_category_id && getPublishTimeOptions.time_type === 'content_category'"
                    class="btn btn-studio-theme-space"
                    :disabled="getPublishLoaders.processSocialShare || sharingPendingProcessStatus || getPublishLoaders.processSavePublication
                                 || (!isGlobalContentCategory && !getContentCategoryAvailableSlot.available)"
                    data-cy='add-to-category'
                    @click.prevent="processContentCategoryPost">
              <!--|| !getContentCategoryAvailableSlot.available-->
              <span>Add to Category</span>
              <clip-loader
v-if="getPublishLoaders.processSocialShare" :color="'#ffffff'"
                           :size="'16px'"></clip-loader>
            </button>
          </template>
          <template v-else-if="getUserRole !== 'Approver'">
            <template v-if="shouldLockBasedOnSubAddons('approver_workflow')">
              <div v-if="hasPermission('can_schedule_plan')" class="mt-0 mr-3 d-inline-block">

                <div class="field_group">
                  <div
v-tooltip.bottom="getLockBasedOnSubAddonsMessage('approver_workflow')"
                       class="checkbox_container"
                       @click.prevent="$bvModal.show('upgrade-plan-dialog')">
                    <label for="disabledUnderReviewApproval">
                      Needs Approval
                      <input
id="disabledUnderReviewApproval" type="checkbox" checked="checked"
                             disabled="disabled" :checked="false">
                      <span class="check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <!--getPublishTimeOptions.time_type !== 'now' &&-->
              <div v-if="hasPermission('can_schedule_plan')" class="mt-0 mr-3 d-inline-block">

                <div class="field_group">

                  <div
v-if="getPublishTimeOptions.time_type === 'now'"
                       v-tooltip="'You cannot use approval feature with \'Post now\' status.'"
                       class="checkbox_container">
                    <label for="disabledUnderReviewApproval">
                      Needs Approval
                      <input
id="disabledUnderReviewApproval" type="checkbox" checked="checked"
                             disabled="disabled" :checked="false">
                      <span class="check"></span>
                    </label>
                  </div>

                  <div
v-else-if="getPublishTimeOptions.plan_status === 'Under Review'"
                       class="checkbox_container">
                    <label for="underReviewApprovalChecked">
                      Needs Approval
                      <input
id="underReviewApprovalChecked" type="checkbox"
                             value="Under Review" :checked="true" @change="getPublishTimeOptions.plan_status = 'Approved'">
                      <span class="check"></span>
                    </label>
                  </div>

                  <div v-else class="checkbox_container">
                    <label for="underReviewApproval" data-cy='need-approval'>
                      Needs Approval
                      <input
id="underReviewApproval"
                             type="checkbox"
                             value="Approved" :checked="false" @change="getPublishTimeOptions.plan_status = 'Under Review'">
                      <span class="check"></span>
                    </label>
                  </div>

                </div>

              </div>
            </template>
            <button
v-if="(!hasPermission('can_schedule_plan') ||
                             getPublishTimeOptions.plan_status === 'Under Review') &&  getPublishTimeOptions.time_type !== 'now'" class="btn btn-studio-theme-space"
                    :disabled="socialPostContentErrors('status') || getPublishLoaders.processSocialShare || sharingPendingProcessStatus || getPublishLoaders.processSavePublication"
                    data-cy='approval'
                    @click.prevent="openApprovalModal">
              <span> Send to Approval </span>
            </button>
            <template v-else>

              <template
                  v-if=" getPublishTimeOptions.time_type === 'now' &&  getPublishTimeOptions.plan_status === 'Under Review'">
                Post status is under review, cannot post now
              </template>

              <button
v-if="getPublishSelection.content_category_id && getPublishTimeOptions.time_type === 'content_category'"
                      class="btn btn-studio-theme-space"
                      :disabled="socialPostContentErrors('status') || getPublishLoaders.processSocialShare || sharingPendingProcessStatus || getPublishLoaders.processSavePublication || (!isGlobalContentCategory && !getContentCategoryAvailableSlot.available)"
                      data-cy="add-to-category-button"
                      @click.prevent="validateAndProcess">
                <!--|| !getContentCategoryAvailableSlot.available-->
                <span>Add to Category</span>
                <clip-loader
v-if="getPublishLoaders.processSocialShare" :color="'#ffffff'"
                             :size="'16px'"></clip-loader>
              </button>

              <button
v-else-if="getPublishTimeOptions.time_type === 'draft'"
                      class="btn btn-studio-theme-space"
                      :disabled="socialPostContentErrors('status')
                                    || getPublishLoaders.processSocialShare
                                    || sharingPendingProcessStatus
                                    || getPublishLoaders.processSavePublication"
                      data-cy='save-draft' @click.prevent="validateAndProcess(true,true)">
                <span>Save as draft</span>
                <clip-loader
v-if="getPublishLoaders.processSocialShare" :color="'#ffffff'"
                             :size="'16px'"></clip-loader>
              </button>

              <button
v-else class="btn btn-studio-theme-space" data-cy="post-now"
                      :disabled="socialPostContentErrors('status')
                                    || getPublishLoaders.processSocialShare
                                    || sharingPendingProcessStatus
                                    || getPublishLoaders.processSavePublication
                                    || (getPublishTimeOptions.time_type === 'now' &&  getPublishTimeOptions.plan_status === 'Under Review')"
                      @click.prevent="validateAndProcess">
                <span> {{ getPublishButtonText }}</span>
                <clip-loader
v-if="getPublishLoaders.processSocialShare" :color="'#ffffff'"
                             :size="'16px'"></clip-loader>
              </button>

            </template>
          </template>
        </div>
      </div>

    </div>
    <div v-if="isApprovalPost && getUserRole === 'Approver'" class="post_footer_actions">
      <div class="post_footer text-right">
        <button
class="btn btn-studio-theme-space"

                :disabled="socialPostContentErrors('status') || getPublishLoaders.processSocialShare || sharingPendingProcessStatus || getPublishLoaders.processSavePublication"
                data-cy='save-draft'
                 @click.prevent="validateAndProcess(false,true)">
          <span>Save Changes</span>
          <clip-loader
v-if="getPublishLoaders.processSocialShare" :color="'#ffffff'"
                       :size="'16px'"></clip-loader>
        </button>
      </div>
    </div>
    <b-modal id="timeRecommendationModal" size="xl" centered hide-footer no-close-on-backdrop modal-class="time-recommendation-modal" header-class="cs-modal-header" title-class="cs-modal-title">
      <template v-slot:modal-title>
        Recommended Times   <a
v-tooltip.top="'Learn more about recommended times'" class="beacon ml-2 font-1rem"
                               href="#" data-beacon-article-modal="61cda7c9766387272428affa">
        <i class="far fa-question-circle"
        ></i>
      </a>
      </template>
      <TimeRecommendations
:plot-title="'Best Time For Social Posting'" :recommended-best-time="recommendedBestTime"
                           :plot-data="plotData"
                           :plot-dates="plotDates" :state="recommendedTimeResponse" :user-timezone = "getWorkspaces.activeWorkspace.timezone" @setRecommendedTime="setRecommendedTime"></TimeRecommendations>

    </b-modal>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import proxy from '@common/lib/http-common'

import FeedViewApprovalStatus from '@src/modules/planner/components/view/feed-view/FeedViewApprovalStatus'
import CustomizeDateRangePicker from '@common/components/Customize-date-range-picker/CustomizeDateRangePicker'
import { socialChannelsNameArray } from '@common/constants/common-attributes'
import {EventBus} from "@common/lib/event-bus";
import {
  approvalStages,
  instagramPostingOption
} from '../../store/states/mutation-types'
import {
  getTwitterAnalyticsV2URL,
  getTimeRecommendationUrl
} from '../../../analytics/config/api-utils'
import ContentCategoriesSlotTime from './social/ContentCategoriesSlotTime'
import QueuePostNote from './social/QueuePostNote'
import TimeRecommendations from './TimeRecommendation'
import useDateFormat from "@common/composables/useDateFormat";
const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  components: {
    DateRangePicker,
    ContentCategoriesSlotTime,
    FeedViewApprovalStatus,
    CustomizeDateRangePicker,
    QueuePostNote,
    TimeRecommendations
  },
  props: [
    'type', 'isCSV',
    'isInstagramSelected',
    'isFacebookSelected',
    'isFirstCommentEnabled',
    'isCarouselPostEnabled',
    'no_access_accounts',
    'all_access_accounts',
    'zapierErrors',
    'first_comment'
  ],
  setup(){
    const {momentWrapper} = useDateFormat()
    return{
      momentWrapper
    }
  },
  data () {
    return {
      plotData: [],
      plotDates: [],
      recommendedBestTime: [],
      recommendedTimeResponse: true,
      has_first_comment: false,
      // isThreadedTweetsEnabled: false,
      instagramPostingMethod: null,
      disableMobile: false,
      disableAPI: false,
      tooltipmsgg: 'Learn more about Content Category <a href="https://docs.contentstudio.io/article/692-what-are-the-queues-in-content-categories">Learn More<a/>',
      locale: {
        format: 'MMMM DD, YYYY HH:mm' // format of the dates displayed
      },
      opens: 'center',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: '2019-10-02',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      content_category_slot_time: {},
      content_category_slot_available: false
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.has_first_comment = this.getSocialSharingFirstComment.has_first_comment
      // this.isThreadedTweetsEnabled = this.getAccountSelection['twitter'].length > 0
    })
  },
  created () {
    if (this.getPublishSelection.plan_id) this.showPublishingPostDatePickers()
    if (this.getInstagramPostingOption) {
      this.instagramPostingMethod = this.getInstagramPostingOption
    } else {
      this.showInstagramOptions()
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategoryList',
      'getPublishTimeOptions',
      'getWorkspaces',
      'getPublishLoaders',
      'getCommonSharingDetails',
      'getPinterestSharingDetails',
      'getAccountSelection',
      'getCommonBoxOptions',
      'getSocialSharingSelection',
      'getContentCategoryAvailableSlot',
      'getApprovalStages',
      'getMobileDevices',
      'getInstagramPostingOption',
      'getSocialSharingFirstComment',
      'getCommonBoxOptions',
      'getCarouselOptions'
    ]),

    isOnlyTwitterSelected () {
      return this.getAccountSelection.twitter.length > 0 && this.getAccountSelection.facebook.length === 0 && this.getAccountSelection.linkedin.length === 0 &&
          this.getAccountSelection.pinterest.length === 0 && this.getAccountSelection.tumblr.length === 0 && this.getAccountSelection.instagram.length === 0 &&
          this.getAccountSelection.gmb.length === 0 && this.getAccountSelection.youtube.length === 0
    },
    isThreadedTweetsEnabled () {
      return this.getAccountSelection.twitter.length > 0
    },
    isFacebookPageSelected() {
      let isFBPageSelected = false
      this.getFacebookAccounts.items.forEach(account => {
        if(account.type === 'Page' &&  this.getAccountSelection.facebook.includes(account.facebook_id)){
          isFBPageSelected = true
        }
      })
      return isFBPageSelected
    },
    getMinDate () {
      const minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone).add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    getStartDate () {
      if (this.getPublishTimeOptions.post_date) {
        return this.getPublishTimeOptions.post_date
      }
      const minDate = moment().tz(this.getWorkspaces.activeWorkspace.timezone).add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },

    getPublishButtonText () {
      switch (this.getPublishTimeOptions.time_type) {
        case 'now':
          return 'Post Now'
        case 'schedule':
          return 'Schedule'
        case 'queued':
          return 'Add to Queue'
        case 'evergreen':
          return 'Add to Evergreen Queue'
        case 'content_category':
          return 'Add to Category'
      }
      if (this.getPublishSelection.content_category_id && this.getPublishSelection.content_category_id.length > 3) return 'Add to Category'
    },

    processPostTimings () {
      const times = []
      let time = ''

      const selection = this.getPublishTimeOptions

      if (selection.repeat === 'repeat') {
        if (selection.time_type === 'now') {
          time = this.getWorkspaceTimezonCurrentTime('YYYY-MM-DD HH:mm')
        } else if (selection.time_type === 'schedule') {
          time = selection.post_date
        }

        if (time) {
          times.push(time)

          if (selection.repeat_type === 'Hour') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'h')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Day') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'd')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Week') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'w')
                times.push(time)
              }
            }
          } else if (selection.repeat_type === 'Month') {
            for (let i = 0; i < selection.repeat_times; i++) {
              if (selection.repeat_gap >= 1) {
                time = moment(time).add(selection.repeat_gap, 'M')
                times.push(time)
              }
            }
          }
        }
      }

      return times
    },

    isContentCategoryType () {
      return (this.getPublishSelection.content_category_id && this.getPublishTimeOptions.time_type === 'content_category')
    },
    isQueuePost () {
      return (this.getPublishTimeOptions.time_type === 'queued')
    },
    isRecommendedAccountSelection () {
      return (this.getAccountSelection.facebook.length > 0 || this.getAccountSelection.twitter.length > 0 || this.getAccountSelection.linkedin.length > 0 || this.getAccountSelection.instagram.length > 0)
    }
  },
  methods: {
    ...mapActions([
      'setPublishingRepeatGap',
      'setPublishingPostDate',
      'setFirstCommentMessage',
      'setHasFirstComment',
      'setFirstCommentLocation',
      'setHasThreadedTweets'
    ]),
    HasFirstCommentChanged () {
      this.$emit('firstCommentChange', true)
    },
    fetchRecommendedTimeData () {
      this.plotData = []
      this.plotDates = []
      this.recommendedTimeResponse = true
      const accounts = []
      const tv = this.momentWrapper().formatTimezone()
      this.$store.getters.getAccountSelection.facebook.forEach((id) => {
        accounts.push({ account_type: 'facebook', facebook_id: id })
      })
      this.$store.getters.getAccountSelection.instagram.forEach((id) => {
        accounts.push({ account_type: 'instagram', instagram_id: id })
      })
      this.$store.getters.getAccountSelection.twitter.forEach((id) => {
        accounts.push({ account_type: 'twitter', twitter_id: id })
      })
      this.$store.getters.getAccountSelection.linkedin.forEach((id) => {
        accounts.push({ account_type: 'linkedin', linkedin_id: id })
      })
      const payload = {
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        date: tv.format('YYYY-MM-DD') + ' - ' + tv.add(6, 'd').format('YYYY-MM-DD'),
        accounts,
        timezone: this.getWorkspaces.activeWorkspace.timezone,
        state: 'merged'
      }

      proxy.post(getTimeRecommendationUrl, payload).catch(() => {

      }).then(resp => {
        if (resp.data) {
          resp = JSON.parse(JSON.stringify(resp))
          console.log('asdasdasd')
          this.plotDates = resp.data.data[0].keys
          this.plotDates = this.plotDates.map((val) => {
            const formatted = moment(val, 'YYYY-MM-DD')
            return formatted.format('DD-MMM-YYYY')
          })
          this.plotData = resp.data.data[0].plot
          this.recommendedTimeResponse = resp.data.data[0].state
          this.recommendedBestTime = resp.data.data[0].best
        }
      })
    },
    accountSelectionCount () {
      let count = 0
      const accounts = this.$store.getters.getAccountSelection
      socialChannelsNameArray.forEach((channel, index) => {
        count += accounts[channel].length
      })
      return count
    },
    validateAndProcess (draft = false, autoSaveRequest = false) {
      if (this.getTwitterOptions.has_threaded_tweets) {
        const tweets = this.getThreadedTweetsSharingDetails
        for (const i in tweets) {
          console.log('ThreadedTweetsError', tweets[i], i)
          if (tweets[i].message.trim() === '' && tweets[i].image.length === 0 && (!tweets[i].video.link || tweets[i].video.link === '')) {
            this.alertMessage('Threaded Tweet box is empty, please add something to it!', 'error')
            return false
          }
        }
      }
      if(this.getCarouselOptions && this.getCarouselOptions.is_carousel_post) {
        if(this.getCarouselOptions.accounts.length === 0) {
          this.alertMessage('Please select a social account for the carousel', 'error')
          return false
        }
        if(this.getCarouselOptions.cards.length < 2) {
          this.alertMessage('You need to have at least 2 cards in a carousel post.', 'error')
          return false
        }
      }

      if (this.has_first_comment) {
        if (this.first_comment.comment && this.first_comment.comment.trim().length) {
          this.setFirstCommentLocation(this.first_comment.location)
          this.setFirstCommentMessage(this.first_comment.comment)

          // check if first comment social selection is empty
          if (!this.getSocialSharingFirstComment.first_comment_accounts || this.getSocialSharingFirstComment.first_comment_accounts.length === 0) {
            this.alertMessage('Please select a social account in first comment section, or disable the first comment option.', 'error')

            return false
          }

          // here we check first comment text length for fb and instagram

          this.processSocialShare(draft, autoSaveRequest)
        } else {
          this.alertMessage('First comment box is empty, please add something to it!', 'error')
        }
      } else {
        this.setHasFirstComment(false)
        this.setFirstCommentLocation(null)
        this.processSocialShare(draft, autoSaveRequest)
      }
    },
    updateValues (values) {
      const myDate = moment(values.startDate)
      this.setPublishingPostDate(myDate.format('MMMM DD, YYYY HH:mm'))
    },

    openApprovalModal () {
      console.debug('method:openApprovalModal')
      const data = this.getPublishSelection.planApprovalData
      if (data && data.status && data.status !== 'completed_approval') {
        this.$store.commit(approvalStages.SET_APPROVAL_DATA, this.resetApprovalData(data))
      } else {
        this.$store.commit(approvalStages.RESET_APPROVAL_DATA)
      }
      this.$bvModal.show('socialApprovalModal')
    },

    resetApprovalData (data) {
      data = JSON.parse(JSON.stringify(data))
      data.status = 'pending_approval'
      data.approvers.forEach(function (element) {
        if (element.status === 'reject') {
          element.status = 'pending'
          element.last_action_note = ''
          element.last_action_time = ''
          element.notification = 'pending'
        }
      })
      return data
    },
    selectedInstagramOption (value) {
      this.$store.commit(instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION, value)
    },
    showInstagramOptions () {
      // check for Native API posting
      this.disableAPI = this.zapierErrors.multiple_images ||
          (this.no_access_accounts.length > 0 && this.all_access_accounts.length === 0)

      // check if mobile apps are connected AND user has selected accounts not linked with api
      this.disableMobile = this.getMobileDevices.length === 0

      // set selected option
      if (!this.disableAPI && this.disableMobile) { // api is active but mobile is deactive
        this.instagramPostingMethod = 'api'
      } else if (this.disableAPI && !this.disableMobile) { // api is deactive but mobile is active
        this.instagramPostingMethod = 'mobile'
      } else if (this.disableAPI && this.disableMobile) { // both api and mobile are deactive
        this.instagramPostingMethod = null
      } else { // both options are available
        if (this.instagramPostingMethod === null) {
          if (!this.disableAPI) {
            this.instagramPostingMethod = 'api'
          } else if (!this.disableMobile) {
            this.instagramPostingMethod = 'mobile'
          }
        }
      }

      this.selectedInstagramOption(this.instagramPostingMethod)
    },
    shouldShowFirstCommentBox () {
      // console.debug('Method::shouldShowFirstCommentBox')
      // if (this.isFirstCommentEnabled) {
      //   if (this.isFacebookSelected) { // case where FB is selected
      //     this.has_first_comment = false
      //   } else if (this.isInstagramSelected) { // case where IG is selected
      //     switch (this.instagramPostingMethod) {
      //       case 'api': // allowed only for API
      //         this.has_first_comment = false
      //         break
      //       case 'mobile': // not allowed for mobile
      //         this.has_first_comment = false
      //         break
      //       default: // not allowed for any other case
      //         this.has_first_comment = false
      //     }
      //   } else { // case where some other social is selected
      //     this.has_first_comment = false
      //   }
      // }
    },

    setRecommendedTime (recommendedTime) {
      console.log('we here one more time' + recommendedTime)
      const values = {}
      values.startDate = moment(recommendedTime, 'DD-MMM-YYYY hh:mm aa')
      console.log('we here one more time' + values)
      this.setPublishingPostDate(values.startDate.format('MMMM DD, YYYY HH:mm'))
      this.$bvModal.hide('timeRecommendationModal')
      this.getPublishTimeOptions.time_type = 'schedule'
    }
  },

  watch: {

    'getSocialSharingFirstComment.has_first_comment': function (value) {
      this.has_first_comment = value
    },

    isFirstCommentEnabled: function (val) {
      if (val === false) {
        this.has_first_comment = false
      }
    },
    'getPublishTimeOptions.repeat_times': function (newVal, oldVal) {
      if (newVal > 99) {
        console.debug('getPublishTimeOptions.repeat_times', newVal, oldVal)
        this.getPublishTimeOptions.repeat_times = oldVal
      }
    },
    has_first_comment: function (val) {
      this.$emit('firstCommentChange', val)
    },
    no_access_accounts: async function (newVal) {
      this.showInstagramOptions()
    },
    all_access_accounts: async function (newVal) {
      this.showInstagramOptions()
    },
    zapierErrors: async function (newVal) {
      this.showInstagramOptions()
    },
    'isInstagramSelected' (newVal) {
      this.shouldShowFirstCommentBox()
    },
    'isFacebookSelected' (newVal) {
      this.shouldShowFirstCommentBox()
    },
    instagramPostingMethod: async function (newVal, oldVal) {
      this.selectedInstagramOption(newVal)

      if (oldVal !== null) {
        this.shouldShowFirstCommentBox()
      }
    },
    'getPublishTimeOptions.time_type' (type) {
      switch (type) {
        case 'schedule':
          this.showPublishingPostDatePickers()
          break
        case 'content_category':
          const categoryId = this.getPublishSelection.content_category_id
          if (categoryId) this.$store.dispatch('nextAvailableSlot', categoryId)
          break
        case 'now':
          this.getPublishTimeOptions.plan_status = 'Approved'
          break
        default :
          break
      }
    },

    'getPublishSelection.content_category_id' (value) {
      if (value && value.length > 3) {
        this.$store.dispatch('nextAvailableSlot', value)
      }
    },

    'getPublishTimeOptions.plan_status' (value) {
      if (value && value === 'Approved') {
        this.getPublishTimeOptions.hide_client = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.post_footer_block {
  display: flex;

  .hide_from_approver {
    margin-top: 25px;
    flex: 2 0 0;
    display: flex;
    align-items: center;
  }

  .post_footer_actions {
  }
}
.slider.round.disabled.grey{
  background-color:#f2f2f2;
}
.moz-input{
  max-width:120px;
}
</style>
