<template>
  <div class="topics-insights-container__statistics__box__container">
    <div class="topics-insights-container__statistics__box">
      <div
        class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_first_child"
      >
        <h4>Articles Analyzed</h4>
        <h3>{{ intToString(articles_analyzed) }}</h3>
      </div>
    </div>

    <div class="topics-insights-container__statistics__box">
      <div
        class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_first_child"
      >
        <h4>Total Engagements</h4>
        <h3>{{ intToString(total_engagement) }}</h3>
      </div>
    </div>

    <div class="topics-insights-container__statistics__box">
      <div
        class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_first_child"
      >
        <h4>Avg. Engagements</h4>
        <h3>{{ $filters.floatToInt(average_engagement) }}</h3>
      </div>
    </div>

    <div class="topics-insights-container__statistics__box">
      <div
        class="topics-insights-container__statistics__box__inner topics-insights-container__statistics__box__inner_last_child"
      >
        <h4>Avg. Engagement By Channel</h4>
        <div
          class="topics-insights-container__statistics__box__inner__channels"
        >
          <div
            v-tooltip.top="'Facebook Share'"
            class="topics-insights-container__statistics__box__inner__channels__item"
          >
            <i class="fb fab fa-facebook-f"></i>
            <h3>{{ $filters.floatToInt(average_facebook_engagement) }}</h3>
          </div>
          <div
            v-tooltip.top="'Pinterest Share'"
            class="topics-insights-container__statistics__box__inner__channels__item"
          >
            <i class="pin fab fa-pinterest-p"></i>
            <h3>{{ $filters.floatToInt(average_pinterest_engagement) }}</h3>
          </div>
          <div
            v-tooltip.top="'X (Twitter) Share'"
            class="topics-insights-container__statistics__box__inner__channels__item"
          >
            <i class="tw cs-twitter"></i>
            <h3>{{ $filters.floatToInt(average_twitter_engagement) }}</h3>
          </div>
          <div
            v-tooltip.top="'Reddit Share'"
            class="topics-insights-container__statistics__box__inner__channels__item"
          >
            <i class="reddit cs-reddit"></i>
            <h3>{{ $filters.floatToInt(average_reddit_engagement) }}</h3>
          </div>

          <!--<div class="engagement_stat ml-auto two_box_social">
                         <span class="icon_item pinterest_white">
                         <span class="icon"></span>
                     </span>
                        <span class="text">{{getInsightsCount.$filters.floatToInt(averagePinterestEngagement}}</span>
                    </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles_analyzed: {
      default: 0
    },
    total_engagement: {
      default: 0
    },
    average_engagement: {
      default: 0
    },
    average_facebook_engagement: {
      default: 0
    },
    average_twitter_engagement: {
      default: 0
    },
    average_pinterest_engagement: {
      default: 0
    },
    average_reddit_engagement: {
      default: 0
    }
  }
}
</script>
