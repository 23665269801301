<script>
import {mapActions, mapGetters} from 'vuex'

import VideoModal from '@common/components/dialogs/onboarding/VideoModal'
import SocialApprovalModal from '@common/components/dialogs/approval/SocialApprovalModal'
import {commonMethods} from '@common/store/common-methods'
import ZapierWarnings from '@common/components/zapier/ZapierWarnings'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import FirstCommentSection from '@src/modules/publish/components/posting/social/sections/FirstCommentSection'
import ThreadedTweetsSection from '@src/modules/publish/components/posting/social/sections/ThreadedTweetsSection'
import {socialChannelsArray} from '@common/constants/common-attributes'
import {EventBus} from '@common/lib/event-bus'
import CarouselPostSection from "@src/modules/publish/components/posting/social/sections/CarouselPostSection";
import {planner} from '@src/modules/planner/store/mutation-types.js'
import {composer} from '@src/modules/composer/store/composer-mutation-type.js'
import {commonTypes} from '@src/modules/publish/store/states/mutation-types.js'
import LabelOptions from '../../../publish/components/options/LabelOptions'
import MemberOptions from '../../../publish/components/options/MemberOptions'
import Assistant from '../../../discovery/components/share-sidebar/Assistant'
import Comments from '../../../publish/components/posting/collaboration/Comments'
import Tasks from '../../../publish/components/posting/collaboration/Tasks'
import Activities from '../../../publish/components/posting/collaboration/Activities'
import VideoPreview from '../../../discovery/components/common/preview/VideoPreview'
import ImagePreview from '../../../discovery/components/common/preview/ImagePreview'
import ArticlePreview from '../../../discovery/components/common/preview/ArticlePreview'
import PocketPreview from '../../../discovery/components/common/preview/PocketPreview'

import AccountSelection from '../../../publish/components/posting/social/AccountSelection'
import ToggleSelection from '../../../publish/components/posting/social/ToggleSelection'
import SingleBox from '../../../publish/components/posting/social/boxes/SingleBox'
import FacebookBox from '../../../publish/components/posting/social/boxes/FacebookBox'
import TwitterBox from '../../../publish/components/posting/social/boxes/TwitterBox'
import PinterestBox from '../../../publish/components/posting/social/boxes/PinterestBox'
import LinkedinBox from '../../../publish/components/posting/social/boxes/LinkedinBox'
import TumblrBox from '../../../publish/components/posting/social/boxes/TumblrBox'
import PublishingTimeOptions from '../../../publish/components/posting/PublishingTimeOptions'
import GmbOptions from '../../../publish/components/posting/social/GmbOptions'
import YoutubeOptions from '../../../publish/components/posting/social/YoutubeOptions'
import SocialPreview from '../../../publish/components/posting/social/previews/SocialPreview'
import InstagramBox from '../../../publish/components/posting/social/boxes/InstagramBox'
import YoutubeBox from '../../../publish/components/posting/social/boxes/YoutubeBox'
import GmbBox from '../../../publish/components/posting/social/boxes/GmbBox'
import ContentCategorySelection from '../../../publish/components/posting/social/ContentCategorySelection'
import ContentCategoryNote from '../../../publish/components/posting/social/ContentCategoryNote'
import ReplaceCurrentTypeConfirmation
  from '../../../publish/components/posting/social/dialogs/ReplaceCurrentTypeConfirmation'
import ReplaceGifConfirmation from '../../../publish/components/posting/social/dialogs/ReplaceGifConfirmation'
import ReplaceImageConfirmation from '../../../publish/components/posting/social/dialogs/ReplaceImageConfirmation'

import CreatePublicationFolder from '../CreatePublicationFolder'


import UploadMediaModal from '../../../publish/components/media-library/components/UploadMediaModal'
import TiktokBox from '../../../publish/components/posting/social/boxes/TiktokBox'

const InsertFileModal = () =>
  import('../../../publish/components/widget/file/InsertFileModal')

export default {
  components: {
    TiktokBox,
    CarouselPostSection,
    FirstCommentSection,
    ErrorSection,
    GmbOptions,
    ReplaceImageConfirmation,
    ReplaceGifConfirmation,
    ReplaceCurrentTypeConfirmation,
    InstagramBox,
    GmbBox,
    SocialPreview,
    LabelOptions,
    VideoPreview,
    ArticlePreview,
    ImagePreview,
    MemberOptions,
    Assistant,
    Comments,
    Tasks,
    Activities,
    AccountSelection,
    ToggleSelection,
    SingleBox,
    FacebookBox,
    PublishingTimeOptions,
    TumblrBox,
    LinkedinBox,
    PinterestBox,
    TwitterBox,
    VideoModal,
    PocketPreview,
    ContentCategorySelection,
    SocialApprovalModal,
    ContentCategoryNote,
    CreatePublicationFolder,
    InsertFileModal,
    ZapierWarnings,
    YoutubeOptions,
    YoutubeBox,
    UploadMediaModal,
    ThreadedTweetsSection,
  },

  data() {
    return {
      teamTooltipHtml: `
             <div class="team_detail_tooltip">
                        <div class="t_inner d-flex align-items-center">
                            <div class="img" style="background: url('https://storage.googleapis.com/lumotive-web-storage/profile/582012d94255d0401200002d/mmIZthvpJWuTImEznbgw.jpg');"></div>
                            <div class="t_content">
                                <h3>ContentStudio <span class="purple role">Admin</span></h3>
                                <p>waqar@contentstudio.io</p>
                            </div>
                        </div>
                    </div>
        `,
      blogShareTabStatus: 'preview',
      toggleModalSidebarStatus: true,
      socialPublicationInterval: '',
      instagramSelection: {
        no_access_accounts: [],
        all_access_accounts: [],
      },
      previousRoute: null,
      showFirstCommentSection: false,
      first_comment: {
        comment: '',
        location: null,
        accounts: {
          facebook: [],
          instagram: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getPublishTimeOptions',
      'getContentCategoryList',
      'getBlogPostingDetails',
      'getPublicationLastUpdated',
      'getPublicationFolders',
      'getWorkspaces',
      'getPlannerLoaders',
      'getCommonBoxOptions',
      'getAccountSelection',
      'getPublishLoaders',
      'getCommentsLoader',
      'getComments',
      'getTasks',
      'getTaskscCompletedCount',
      'getApprovalStages',
      'getInstagramAccounts',
      'getCommonSharingDetails',
      'getInstagramSharingDetails',
      'getPublications',
      'getSocialSharingFirstComment',
      'getYoutubeOptions',
      'getTwitterOptions',
      'getPinterestOptions',
      'getCarouselOptions',
    ]),

    isFirstCommentEnabled() {
      const accountList = []
      if (this.firstCommentAccountSelection.length) {
        socialChannelsArray.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if (
                (channel.name === 'facebook' ||
                    channel.name === 'linkedin' ||
                    (channel.name === 'youtube' &&
                        !this.getYoutubeOptions.made_for_kids &&
                        this.getYoutubeOptions.privacy_status === 'public') ||
                    channel.name === 'instagram') &&
                this.firstCommentAccountSelection.includes(account[channel.key])
            ) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key },
              })
            }
          })
        })
      }

      return accountList.length > 0
    },
    isThreadedTweetsEnabled() {
      return this.getAccountSelection.twitter.length > 0
    },

    isCarouselPostEnabled() {
      let isFBPageSelected = false
      this.getFacebookAccounts.items.forEach((account) => {
        if (
            account.type === 'Page' &&
            this.getAccountSelection.facebook.includes(account.facebook_id)
        ) {
          isFBPageSelected = true
        }
      })
      return isFBPageSelected
    },

    firstCommentAccountSelection() {
      const facebookAccounts = this.getAccountSelection.facebook
      const instagramAccounts = this.getAccountSelection.instagram
      const youtubeAccounts = this.getAccountSelection.youtube
      const linkedinAccounts = this.getAccountSelection.linkedin

      return [
        ...facebookAccounts,
        ...instagramAccounts,
        ...youtubeAccounts,
        ...linkedinAccounts,
      ]
    },
    zapierError() {
      if (this.getCommonBoxOptions.status) {
        return this.zapierAttachmentError(this.getCommonSharingDetails)
      }
      return this.zapierAttachmentError(this.getInstagramSharingDetails)
    },
    accountSelection() {
      // to deep watch object nested property which is social account selection
      return (
          this.getAccountSelection.facebook.length === 0 &&
          this.getAccountSelection.twitter.length === 0 &&
          this.getAccountSelection.pinterest.length === 0 &&
          this.getAccountSelection.linkedin.length === 0 &&
          this.getAccountSelection.tumblr.length === 0 &&
          this.getAccountSelection.instagram.length === 0 &&
          this.getAccountSelection.gmb.length === 0 &&
          this.getAccountSelection.youtube.length === 0 &&
          this.getAccountSelection.tiktok.length === 0
      )
    },
  },
  watch: {
    first_comment: function (val) {
      this.setFirstCommentMessage(val.comment)
    },

    isFirstCommentEnabled: function (val) {
      if (val === false) {
        console.log('isFirstCommentEnabled', val)
        this.setHasFirstComment(false)
        this.showFirstCommentSection = false
      }
    },
    isThreadedTweetsEnabled: function (val) {
      if (val === false) {
        this.setHasThreadedTweets(val)
      }
    },
    isCarouselPostEnabled: function (val) {
      if (val === false) {
        this.getCarouselOptions.is_carousel_post = false
      }
    },
    'getPublishSelection.folderId'() {
      this.socialAutoPostSave()
    },
    'getAccountSelection.instagram': async function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.instagramSelection = {
          no_access_accounts: [],
          all_access_accounts: [],
        }
      } else {
        this.instagramSelection =
            commonMethods.zapierLinkedInstagramAccountExist(this)
      }
    },
    accountSelection: function (val) {
      // to toggle commonbox if no account is selected
      if (val) {
        this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true)
      }
    },
  },
  created() {
    if (
      this.getPublishSelection.status !== '' ||
      this.getPublishSelection.repost ||
      this.getPublishSelection.reopen
    ) {
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
    }

    if (this.$route.params.typeId) {
      if (this.$route.params.type && this.$route.params.type === 'comment') {
        this.blogShareTab('comment', true)
      }
      if (this.$route.params.type && this.$route.params.type === 'task') {
        this.blogShareTab('task', true)
      }
    } else {
      if (this.$route.params.type && this.$route.params.type === 'comments') {
        this.blogShareTab('comment', true)
      }
      if (this.$route.params.type && this.$route.params.type === 'tasks') {
        this.blogShareTab('task', true)
      }
    }

    this.initializeComposerSocialSection()
    if (this.getPublications.folder.id && !this.getPublishSelection.folderId) {
      this.setPublicationFolderId(this.getPublications.folder.id)
    }

    this.instagramSelection =
      commonMethods.zapierLinkedInstagramAccountExist(this)
  },
  async mounted() {
    // fetch Integration Accounts and Authorization
    this.$store.dispatch('fetchIntegrationsAccounts')
    this.$store.dispatch('fetchIntegrationAuthorizationLinks')
    this.$nextTick(function () {
      this.first_comment = {
        comment: this.getSocialSharingFirstComment.first_comment_message,
        location: this.getSocialSharingFirstComment.first_comment_location,
        accounts: this.getSocialSharingFirstComment.first_comment_accounts,
      }
    })

    EventBus.$on('fetchedFirstComment', (data) => {
      console.debug('Event:fetchedFirstComment', data)
      this.showFirstCommentSection = data.has_first_comment
      this.first_comment = {
        comment: data.first_comment_message,
        location: data.first_comment_location,
        account: data.first_comment_accounts,
      }
    })
    EventBus.$on('save_draft', () => {
      console.log('save_draft...')
      this.processSocialShare(true)
    })
  },
  methods: {
    ...mapActions([
      'setPublicationFolderId',
      'setHasFirstComment',
      'setFirstCommentMessage',
      'setHasThreadedTweets',
    ]),
    handlefirstCommentChange(val) {
      // Changing toggle state in store
      this.setHasFirstComment(val)
      this.showFirstCommentSection = val
    },
    toggleModalSidebar() {
      if (!this.toggleModalSidebarStatus && this.blogShareTabStatus === '') {
        this.blogShareTabStatus = 'assistant'
      }
      this.toggleModalSidebarStatus = !this.toggleModalSidebarStatus
    },
    blogShareTab(status, initialize = false) {
      this.toggleModalSidebarStatus = true
      this.blogShareTabStatus = status
      if (!initialize) {
        if (status === 'comment') {
          this.$router.push({
            name: 'composerSocial',
            params: { id: this.$route.params.id, type: 'comments' },
          })
        } else if (status === 'task') {
          this.$router.push({
            name: 'composerSocial',
            params: { id: this.$route.params.id, type: 'tasks' },
          })
        } else {
          this.$router.push({
            name: 'composerSocial',
            params: { id: this.$route.params.id },
          })
        }
      }
    },
    checkSelected(folderId) {
      return this.getPublishSelection.folderId === folderId
    },
    selectCampaign(folderId) {
      console.debug('Method:selectCampaign', folderId)
      if (
        this.getPublishSelection.folderId &&
        this.getPublishSelection.folderId === folderId
      ) {
        this.setPublicationFolderId(null)
      } else this.setPublicationFolderId(folderId)
    },
    openCreateFolderModal() {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
      // eslint-disable-next-line no-undef
      EventBus.$emit('createFolder')
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => (vm.previousRoute = from))
  },
  beforeUnmount () {
    // to destry interval using for saving publication
    window.clearInterval(this.socialPublicationInterval)
    EventBus.$off('save_draft')
    EventBus.$off('fetchedFirstComment')
  },
}
</script>

<template>
  <div>
    <template v-if="blogShareTabStatus === 'assistant'">
      <VideoPreview></VideoPreview>
      <ImagePreview></ImagePreview>

      <ArticlePreview></ArticlePreview>
      <PocketPreview></PocketPreview>
    </template>

    <!--        onboarding tutorial-->
    <VideoModal id="create-social-post" position="bottom_left">
      <template v-slot:heading>
        <h2>Publish Social Post</h2>
      </template>
      <template v-slot:description>
      </template>
      <template v-slot:iframe>
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/Xy7HkjOwQzI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>

    <ReplaceCurrentTypeConfirmation></ReplaceCurrentTypeConfirmation>
    <ReplaceGifConfirmation></ReplaceGifConfirmation>
    <ReplaceImageConfirmation></ReplaceImageConfirmation>
    <SocialApprovalModal></SocialApprovalModal>
    <CreatePublicationFolder></CreatePublicationFolder>

    <InsertFileModal type="social"></InsertFileModal>
    <UploadMediaModal type="social"></UploadMediaModal>

    <div
      class="composer_component composer_step_page top_left_design_box top_left_design_box_social_post"
    >
      <div class="component_inner">
        <div class="c_header d-flex align-items-center">
          <div class="head_left">
            <h2>Social Media Post Composer</h2>
          </div>
          <template v-if="!getPlannerLoaders.edit_plan">
            <div class="head_right ml-auto d-flex align-items-center">
              <p class="time_text">
                <template v-if="getPublicationLastUpdated">
                  Saved {{ $filters.relative(getPublicationLastUpdated.date) }}
                </template>
              </p>

              <div
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown label_dropdown profiles_list_header"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <!--v label length-->
                    <div
                      v-if="getPublishSelection.label.length > 0"
                      class="circle_boxes_inline circle_boxes"
                    >
                      <div
                        v-for="(labelId, index) in getPublishSelection.label"
                        :key="`labelId_${index}`"
                        :class="getLabelColorById(labelId) + ' c_box'"
                      ></div>
                    </div>
                    <!--v else -->
                    <span v-else class="icon_first">
                      <i class="icon_first icon-Label"></i>
                    </span>

                    <span class="text" data-cy="labels">Labels</span>

                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <LabelOptions></LabelOptions>
                </div>
              </div>

              <div
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown social_dropdown profiles_list_header"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <div
                      v-if="getPublishSelection.members.length > 0"
                      class="circle_boxes_inline circle_boxes"
                    >
                      <template
                        v-for="member in getWorkspaces.activeWorkspace.members"
                      >
                        <template v-if="member.user_id === selected_member">
                          <div
                              v-for="(selected_member, key) in getPublishSelection.members"
                              :key="`${member.user_id}_${key}`"
                              v-tooltip="{
                            content: teamMemberTooltipHtmlById(member),
                            classes: 'team_tooltip',
                          }"
                              class="c_box"
                          >
                            <template v-if="member.user_id === selected_member">
                              <div
                                  v-if="member.user.image"
                                  class="c_img_box"
                                  :style="{
                              background: 'url(' + member.user.image + ')',
                            }"
                              ></div>
                              <div
                                  v-else
                                  class="c_img_box"
                                  style="
                              background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                            "
                              ></div>
                            </template>
                          </div>
                        </template>
                      </template>
                    </div>
                    <span v-else class="icon_first">
                      <i class="icon-profile-cs"></i>
                    </span>
                    <span class="text" data-cy="members">Members</span>

                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <MemberOptions></MemberOptions>
                </div>
              </div>

              <div
                v-if="getBlogPostingDetails.type === 'Composer Social'"
                class="prevent_close_dropdown ml-2 dropdown default_style_dropdown checkbox_dropdown profiles_list_header campaign_dropdown"
              >
                <div class="c_header_dropdown" data-toggle="dropdown">
                  <div class="c_dropdown_box">
                    <span class="icon_first">
                      <i class="icon-Campaign"></i>
                    </span>
                    <span class="text" data-cy="campaigns">Campaigns</span>

                    <i class="dropdown_arrow icon_last cs-angle-down"></i>
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner text_center">
                    <template v-if="getPublicationFolders.length">
                      <li
                        v-for="(folder, key) in getPublicationFolders"
                        :key="key"
                        class="list_item_li"
                      >
                        <!-- v-bind="folder._id"-->
                        <template v-if="folder._id">
                          <div class="field_group">
                            <label class="radio_container radio-sm">
                              <input
                                  :id="folder._id"
                                  name="select-campaign"
                                  :checked="checkSelected(folder._id)"
                                  :value="folder._id"
                                  type="radio"
                                  data-cy="select-campaign"
                                  @change="selectCampaign(folder._id)"
                              />
                              <span class="text-truncate">
                                {{ folder.name }}
                                <span class="check"></span>
                              </span>
                            </label>
                          </div>
                        </template>
                      </li>
                    </template>
                    <hr />
                    <li
                      class="btn btn-studio-theme-space btn-size-small mb-2"
                      data-cy="create-campaign"
                      @click.prevent="openCreateFolderModal"
                    >
                      + Add New Campaign
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
              <a
                href="javascript:;"
                class="close_icon"
                @click.prevent="composerClose(previousRoute)"
              >
                &times;
              </a>
            </div>
          </template>
        </div>

        <div v-if="!getPlannerLoaders.edit_plan" class="c_sidebar">
          <ul>
            <li
              class=""
              :class="{ active: blogShareTabStatus === 'assistant' }"
              @click="blogShareTab('assistant')"
            >
              <a href="javascript:;">
                <i class="far fa-th"></i>
                <span class="text">Assistant</span>
              </a>
            </li>
            <li
              class=""
              :class="{ active: blogShareTabStatus === 'task' }"
              @click="blogShareTab('task')"
            >
              <a href="javascript:;">
                <i class="far fa-tasks"></i>
                <span class="text">Tasks</span>
                <span
                  v-if="
                    getTasks && getTasks.length - getTaskscCompletedCount > 0
                  "
                  class="count"
                  >{{ getTasks.length - getTaskscCompletedCount }}</span
                >
              </a>
            </li>
            <li
              class=""
              :class="{ active: blogShareTabStatus === 'comment' }"
              @click="blogShareTab('comment')"
            >
              <a href="javascript:;">
                <i class="far fa-comment"></i>
                <span class="text">Comments</span>
                <span
                  v-if="
                    !getCommentsLoader &&
                    getComments &&
                    getComments.length > 0
                  "
                  class="count"
                  >{{ getComments.length }}</span
                >
              </a>
            </li>
            <li
              class=""
              :class="{ active: blogShareTabStatus === 'activity' }"
              @click.prevent="blogShareTab('activity')"
            >
              <a href="javascript:;">
                <i class="far fa-list"></i>
                <span class="text">Activities</span>
              </a>
            </li>
            <li
              class=""
              :class="{ active: blogShareTabStatus === 'preview' }"
              @click.prevent="blogShareTab('preview')"
            >
              <a href="javascript:;">
                <i class="far fa-eye"></i>
                <span class="text">Preview</span>
              </a>
            </li>
          </ul>
        </div>
        <!--basic_form-->
        <div class="c_body basic_form">
          <div class="height page_content">
            <div class="height d-flex align-items-stretch">
              <div id="stopScroll" class="content">
                <div
                  class="content_container content_container_drop"
                  style="max-width: 970px"
                >
                  <template v-if="getPlannerLoaders.edit_plan">
                    <beat-loader :color="'#436aff'"></beat-loader>
                  </template>
                  <div v-else class="content_inner">
                    <div>
                      <ContentCategorySelection
                        v-if="!getPublishSelection.queueStatus"
                        :module="'publish'"
                      ></ContentCategorySelection>
                      <AccountSelection
                        v-if="!isGlobalContentCategory"
                        type="socialComposer"
                        :show-label="false"
                        :show-publish-as="true"
                      ></AccountSelection>
                      <ContentCategoryNote
                        v-if="isGlobalContentCategory"
                      ></ContentCategoryNote>

                      <template v-if="getCommonBoxOptions.globalCategoryStatus">
                        <beat-loader :color="'#436aff'"></beat-loader>
                      </template>

                      <div
                        v-if="getAccountSelection.instagram.length > 0"
                        class="input_field"
                      >
                        <div class="compose_warning_box text-center">
                          <i class="far fa-question-circle test"> </i>
                          <p>
                            Want to learn how Instagram publishing works? Learn
                            about posting via
                            <a
                              href="https://docs.contentstudio.io/article/829-how-to-post-through-instagram-api"
                              target="_blank"
                              class="insta-link"
                              >Instagram API
                            </a>
                            or
                            <a
                              href="https://docs.contentstudio.io/article/732-how-to-post-through-push-notifications"
                              target="_blank"
                              class="insta-link"
                              >Mobile Notifications
                            </a>
                          </p>
                        </div>
                      </div>
                      <div
                          v-if="getAccountSelection.tiktok.length > 0"
                          class="input_field"
                      >
                        <div class="compose_warning_box text-center">
                          <i class="far fa-question-circle test"> </i>
                          <p>
                            Captions, Links and video thumbnails cannot be
                            posted on TikTok, to learn more about posting on
                            TikTok
                            <a
                              class="ml-2"
                              href="#"
                              data-beacon-article-modal="6225cf722ce7ed0fb09127ee"
                            >
                              click here </a
                            >.
                          </p>
                        </div>
                      </div>
                      <ToggleSelection
                        style="margin-top: 15px"
                      ></ToggleSelection>

                      <SingleBox
                        :class="{
                          hide:
                            !getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus,
                        }"
                      ></SingleBox>
                      <TiktokBox
                          :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.tiktok.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .tiktok),
                        }"
                      ></TiktokBox>

                      <HashtagSuggessions
                        :class="{
                          hide:
                            !getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus,
                        }"
                      ></HashtagSuggessions>
                      <FacebookBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.facebook.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .facebook),
                        }"
                      ></FacebookBox>

                      <TwitterBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.twitter.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .twitter),
                        }"
                      ></TwitterBox>

                      <!--  Threaded tweets toggle-->
                      <div
                        v-if="
                          !getCommonBoxOptions.status &&
                          getAccountSelection['twitter'] &&
                          getAccountSelection['twitter'].length > 0 &&
                          getTwitterOptions.has_threaded_tweets === false
                        "
                        class="post_share_option post_share_option_social_threaded_tweet basic_form_inline clear-both"
                      >
                        <div class="d-flex align-items-start mb-3">
                          <div class="left_input">
                            <p class="label"
                              >Add threaded Tweets
                              <!-- Open Article in a modal -->
                              <a
                                  v-tooltip.top="
                                  'Learn more about threaded tweets'
                                "
                                  class="beacon ml-2"
                                  href="#"
                                  data-beacon-article-modal="61826fd112c07c18afde3ed5"
                              >
                                <i class="far fa-question-circle"></i>
                              </a>
                            </p>
                          </div>
                          <div class="right_input d-flex align_center">
                            <label class="switch-radio">
                              <input
                                v-model="getTwitterOptions.has_threaded_tweets"
                                type="checkbox"
                                :disabled="!isThreadedTweetsEnabled"
                              />
                              <div
                                class="slider round"
                                :class="{
                                  'disabled grey': !isThreadedTweetsEnabled,
                                }"
                              ></div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <ErrorSection
                        v-if="getCommonBoxOptions.status"
                        :errors="socialPostContentErrors()"
                        :warnings="socialPostContentWarnings()"
                      ></ErrorSection>

                      <!--  Threaded tweets toggle end-->
                      <transition name="slide-bottom">
                        <ThreadedTweetsSection
                          v-show="
                            getTwitterOptions.has_threaded_tweets &&
                            getAccountSelection['twitter'].length > 0
                          "
                        />
                      </transition>

                      <LinkedinBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.linkedin.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .linkedin),
                        }"
                      ></LinkedinBox>

                      <PinterestBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.pinterest.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .pinterest),
                        }"
                      ></PinterestBox>

                      <TumblrBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.tumblr.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .tumblr),
                        }"
                      ></TumblrBox>

                      <InstagramBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.instagram.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .instagram),
                        }"
                      ></InstagramBox>

                      <ZapierWarnings
                        v-if="
                          instagramSelection.no_access_accounts.length > 0
                        "
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.instagram.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .instagram),
                        }"
                        :aspect_ratio="false"
                        :no_access_accounts="
                          instagramSelection.no_access_accounts
                        "
                      ></ZapierWarnings>

                      <YoutubeBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.youtube.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels
                                .youtube),
                        }"
                      ></YoutubeBox>

                      <GmbBox
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus ||
                            (getAccountSelection.gmb.length === 0 &&
                              !isGlobalContentCategorySelected) ||
                            (isGlobalContentCategorySelected &&
                              !getCommonBoxOptions.globalCategoryChannels.gmb),
                        }"
                      ></GmbBox>

                      <ErrorSection
                        v-if="!getCommonBoxOptions.status"
                        :errors="socialPostContentErrors()"
                        :warnings="socialPostContentWarnings()"
                      ></ErrorSection>

                      <ZapierWarnings
                        v-if="
                          instagramSelection.no_access_accounts.length > 0
                        "
                        :class="{
                          hide:
                            !getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus,
                        }"
                        :aspect_ratio="false"
                        :no_access_accounts="
                          instagramSelection.no_access_accounts
                        "
                      ></ZapierWarnings>

                      <HashtagSuggessions
                        :class="{
                          hide:
                            getCommonBoxOptions.status ||
                            getCommonBoxOptions.globalCategoryStatus,
                        }"
                      ></HashtagSuggessions>
                      <div
                        v-if="getPublishSelection.status !== 'published'"
                        class="post_share_option"
                      >
                        <div
                          v-if="getPublishSelection.reopen"
                          class="text-center warning_box mt-3"
                        >
                          <p
                            >This post is either missed review, failed or
                            rejected thats why publishing time options have been
                            reset.</p
                          >
                        </div>
                        <GmbOptions
                          v-if="getAccountSelection.gmb.length"
                        ></GmbOptions>

                        <YoutubeOptions
                          v-if="getAccountSelection.youtube.length"
                        ></YoutubeOptions>

                        <transition name="slide-bottom">
                            <CarouselPostSection
                                v-show="getCarouselOptions.is_carousel_post"
                                :is-carousel-post-enabled ="isCarouselPostEnabled"
                            />
                        </transition>

                        <transition name="slide-bottom">
                          <FirstCommentSection
                            v-show="showFirstCommentSection"
                            v-model="first_comment"
                            :is-first-comment-enabled="isFirstCommentEnabled"
                            @firstCommentChange="handlefirstCommentChange"
                          />
                        </transition>
                        <PublishingTimeOptions
                          :first_comment="first_comment"
                          :is-facebook-selected="
                            getAccountSelection.facebook.length > 0
                          "
                          :is-first-comment-enabled="isFirstCommentEnabled"
                          :is-carousel-post-enabled="isCarouselPostEnabled"
                          :is-instagram-selected="
                            (instagramSelection &&
                              instagramSelection.no_access_accounts.length >
                                0) ||
                            instagramSelection.all_access_accounts.length > 0
                          "
                          :no_access_accounts="
                            instagramSelection.no_access_accounts
                          "
                          :all_access_accounts="
                            instagramSelection.all_access_accounts
                          "
                          :zapier-errors="zapierError"
                          type="Social"
                          @firstCommentChange="handlefirstCommentChange"
                        >
                        </PublishingTimeOptions>
                        <div
                          v-if="getPublishSelection.queueStatus"
                          class="post_footer text-right"
                        >
                          <div class="warning_box justify-content-center"
                            >This post is queued and scheduled on
                            <strong>{{
                              getWorkspaceTimeZoneTime(
                                getPublishSelection.planExecutionTime['date'],
                                'MMM DD, hh:mm a',
                              )
                            }}</strong>
                            <i
                              class="icon-edit-cs"
                              @click.prevent="editQueuePost"
                            ></i>
                          </div>

                          <button
                            v-if="!checkPendingApproval"
                            class="btn btn-studio-theme-space mt-3"
                            :disabled="
                              getPublishLoaders.processSocialShare ||
                              sharingPendingProcessStatus ||
                              getPublishLoaders.processSavePublication
                            "
                            @click.prevent="processSocialShare"
                          >
                            <span>Update</span>
                            <clip-loader
                              v-if="getPublishLoaders.processSocialShare"
                              :color="'#ffffff'"
                              :size="'16px'"
                            ></clip-loader>
                          </button>
                        </div>
                      </div>
                      <div v-else class="post_share_option">
                        <div class="post_footer">
                          <div
                            class="d-inline-block success_box warning_box_left"
                          >
                            <p
                              >Published on
                              {{
                                getWorkspaceTimeZoneTime(
                                  getPublishSelection.planExecutionTime['date'],
                                  'MMM DD, hh:mm a',
                                )
                              }}</p
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!getPlannerLoaders.edit_plan"
                id="social_filter_sidebar"
                class="social_post_side_filter"
                :class="{ open: toggleModalSidebarStatus }"
              >
                <div class="filter_inner">
                  <div
                    class="c_side_menu radiused_icon"
                    @click="toggleModalSidebar"
                  >
                    <i class="cs-angle-right"></i>
                  </div>

                  <Assistant
                    :class="{ hide: blogShareTabStatus !== 'assistant' }"
                  ></Assistant>
                  <Comments
                    :class="{ hide: blogShareTabStatus !== 'comment' }"
                    :comment-id="$route.params.typeId"
                  ></Comments>
                  <Tasks
                    :class="{ hide: blogShareTabStatus !== 'task' }"
                    :task-id="$route.params.typeId"
                  ></Tasks>
                  <Activities
                    :class="{ hide: blogShareTabStatus !== 'activity' }"
                  ></Activities>
                  <SocialPreview
                    class="social_composer_preview"
                  ></SocialPreview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import '../../../../assets/less/third-party/slick.less';
</style>

<style lang="less">
.social_composer_preview {
  .social_preview_img {
    padding: 50px !important;
  }
  .radio_tabs_buttons {
    input.radio-tab + label {
      padding: 0.5em 1.4em;
      font-weight: 400;
      @media (max-width: 1360px) {
        padding: 0.4em 1.1em;
      }
    }
    label {
      i {
        font-size: 0.8rem;
        margin-right: 0.4rem;
      }
    }
  }
}
</style>

<style lang="less">
.add-new-camp {
  text-align: center;
  font-size: 0.875rem;
  border-top: 1px solid #cecece;
  padding: 10px 0 1px 0;
  color: #484b52;
  cursor: pointer;
}
</style>
