<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.41929 11.7903C8.80639 11.4032 9.38704 11.4032 9.77413 11.7903C10.1612 12.1774 10.1612 12.7581 9.77413 13.1452L5.70962 17.2097C5.51607 17.4032 5.32252 17.5 5.0322 17.5C4.74188 17.5 4.54833 17.4032 4.35478 17.2097L0.290261 13.1452C-0.0968356 12.7581 -0.0968356 12.1774 0.290261 11.7903C0.677358 11.4032 1.258 11.4032 1.6451 11.7903L4.06464 14.2099L4.06463 1C4.06463 0.447716 4.51235 4.8538e-07 5.06463 4.37098e-07C5.61692 3.88816e-07 6.06463 0.447716 6.06463 1L6.06464 14.145L8.41929 11.7903Z"
      fill="#e02f2f"
    />
  </svg>
</template>
