<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-2">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 bg-cs-light-violet"
      >
        <img
          src="@assets/img/integration/tumblr-icon.svg"
          alt=" "
          class="w-5"
        />
      </div>
    </div>
    <div class="flex items-center px-3 py-2">
      <img
        class="object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.platform_logo"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-medium text-black">
        {{ account.platform_name }}
      </div>
      <span class="px-1 font-medium text-blue-500">Follow</span>
      <span class="ml-auto block font-bold"
        ><i class="fas fa-ellipsis-h"></i
      ></span>
    </div>
    <div class="w-full h-full">
      <div v-if="detail.image && detail.image.length > 0" class="w-100 h-100">
        <div class="flex flex-wrap relative overflow-hidden">
          <div
            class="flex-img bg-contain bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
            :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
          >
            <img
            v-tooltip="{
              content: 'Image Preview',
              placement: 'auto',
            }"
              class="max-w-full max-h-full w-full opacity-0 cursor-pointer"
              :src="detail.image[0]"
              alt=""
              @click="openLightbox(detail.image[0])"
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                  event.target.classList.remove('opacity-0')
                }
              "
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white text-center"
      >
        <img
          class="w-full min-h-[190px] max-h-[500px]"
          :src="detail.video.thumbnail"
          alt=""
        />
              <!-- playback icon -->
     <div
     class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
     @click.prevent="
     displayFile('video', detail.video.converted_video || detail.video.link, 0)
   ">
      <i
      class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"


    ></i>
     </div>
      </div>
    </div>
    <div v-if="getDescription" class="items-center px-3 py-2">
      <span class="whitespace-pre-wrap" v-html="getDescription"></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        Load More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
  </div>
  <VueEasyLightbox
  :visible="visible"
  :imgs="detail.image"
  @hide="visible = false"
></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'

export default {
  name: 'TumblrPreview',
  components: {
    VueEasyLightbox,
  },
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      loadMore: true,
      charLimit: 600,
      visible: false,
      imgs: [],
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
  },
  methods: {
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    changeLoadMore(val) {
      this.loadMore = val
    },
    openLightbox(item) {
      this.imgs = item
      this.visible = true
    },
  },
}
</script>

<style scoped>
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}
</style>
