<template>
  <ConfirmBox
    id="removeVideoAutomation"
    :call-back-action="removeVideoAutomation"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Campaign</h2>
    </template>

    <template v-slot:footer>
      <p class="text-center">This action cannot be undone.</p>
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {}
}
</script>
