<template>
  <div class="h-100 d-flex flex-column">
    <SavedReplyModal
      :active-inbox-detail="activeInboxDetail"
      :apply-saved-reply="applySavedReply"
    ></SavedReplyModal>
    <InboxConversationHead
      :active-inbox-detail="activeInboxDetail"
      :reset-view="resetView"
    ></InboxConversationHead>
    <div id="chat_conversation_block_twitter" class="conversation_messages">
      <div class="message m_in social_preview_no_tab _comment_post">
        <div class="twitter_post_preview">
          <div v-if="post !== null" class="profile_picture">
            <div class="_twitter_comment">
              <div class="twitter_reply_block">
                <p
                  v-if="rootPost !== null || parentPost !== null"
                  class="text-center previouse_conversation"
                >
                  <span
                    class="link"
                    @click="previousConversation = !previousConversation"
                  >
                    <template v-if="previousConversation"
                      >Hide previous conversation</template
                    >
                    <template v-else>Show previous conversation</template>
                  </span>
                </p>

                <div v-if="previousConversation" class="profile_picture clear">
                  <TwitterReplyTweet
                    v-if="rootPost !== null"
                    :is-parent="true"
                    :is-root="true"
                    :tweet="rootPost"
                  >
                  </TwitterReplyTweet>
                  <TwitterReplyTweet
                    v-if="parentPost !== null"
                    :is-parent="true"
                    :is-root="true"
                    :tweet="parentPost"
                  >
                  </TwitterReplyTweet>
                </div>
              </div>
            </div>

            <TwitterMainTweet :post="post"> </TwitterMainTweet>

            <div class="_twitter_comment">
              <div class="option_block d-flex align-items-center">
                <!--active-state-->
                <span class="span_item cursor_default"
                  ><strong>{{ post.retweet_count }}</strong
                  >Retweets</span
                >
                <span class="span_item cursor_default"
                  ><strong>{{ post.favorite_count }}</strong
                  >Likes</span
                >
              </div>
              <div
                class="option_block option_block_bottom d-flex align-items-center"
              >
                <span
                  class="span_item"
                  @click="
                    replyCommentTwitter(
                      post.posted_by.user_name,
                      post.mentioned_user,
                      post.tweet_id,
                      true,
                    )
                  "
                  ><i class="fas fa-reply"></i> <span>Reply</span></span
                >
              </div>
              <div v-if="postComments !== null" class="twitter_reply_block">
                <div
                  v-for="(tweet, rootIndex) in postComments"
                  :key="tweet.tweet_id"
                  class="profile_picture clear"
                >
                  <TwitterReplyTweet
                    :is-parent="false"
                    :tweet="tweet"
                    :is-root="true"
                    :root-index="rootIndex"
                    :sub-index="null"
                    :post="post"
                    :replies-count="
                      typeof postReplies[tweet.tweet_id] !== 'undefined' &&
                      postReplies[tweet.tweet_id] !== null
                        ? postReplies[tweet.tweet_id].length
                        : 0
                    "
                    :screen-name="getScreenName()"
                    :set-can-show-replies="setCanShowReplies"
                    :reply-comment-twitter="replyCommentTwitter"
                  ></TwitterReplyTweet>
                  <template v-if="tweet.replies">
                    <div
                      v-for="(tweetReply, subIndex) in tweet.replies"
                      :key="tweetReply.tweet_id"
                    >
                      <TwitterReplyTweet
                        :is-parent="false"
                        :tweet="tweetReply"
                        :is-root="false"
                        :root-index="rootIndex"
                        :sub-index="subIndex"
                        :post="post"
                        :replies-count="null"
                        :screen-name="getScreenName()"
                        :set-can-show-replies="null"
                      ></TwitterReplyTweet>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <transition name="slidedown">
              <div v-if="showWriteBlock" class="write_block">
                <div class="text_area relative">
                  <b-form-textarea
                    id="mainComment"
                    v-model="typedComment"
                    style="box-shadow: none; overflow-y: hidden"
                    placeholder="Tweet your reply..."
                    name=""
                    max-rows="6"
                    @keydown.enter.exact.prevent="replyToComments($event)"
                  >
                  </b-form-textarea>
                  <div v-if="notSent === true" class="failed_option mt-4">
                    <p
                      >Message wasn't send.
                      <span class="link" @click="replyToComments(null)"
                        >Retry</span
                      >
                      or
                      <span class="link" @click="resetMessage">Delete</span></p
                    >
                  </div>
                </div>
                <span
                  v-if="attachment !== null"
                  v-tooltip.top="attachment.name"
                  class="attachment_file"
                >
                  <i
                    class="d-flex align-items-center justify-content-center attachment-type fal fa-file"
                  ></i>
                  {{ commonMethods.limitText(attachment.name, 15) }}
                  <i
                    class="d-flex align-items-center justify-content-center cross_btn fal fa-times"
                    @click="removeMedia"
                  ></i>
                </span>

                <div
                  class="comment_tool"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 1rem;
                  "
                >
                  <div
                    class="attachment_btn"
                    style="display: flex; align-items: center"
                  >
                    <label
                      v-tooltip.bottom="'Saved Replies'"
                      class="icon-Save attachment_btn mr-3 mb-0"
                      style="cursor: pointer"
                      @click="$bvModal.show('savedReplyModal')"
                    />
                    <label
                      v-tooltip.bottom="'Attach Image'"
                      for="attachment_file"
                      class="option_icon icon-Image"
                      style="cursor: pointer; margin: 0"
                    >
                      <i></i>
                    </label>
                    <input
                      id="attachment_file"
                      ref="twitterAttachment"
                      type="file"
                      accept="*"
                      @change="attachMedia($event)"
                    />

                    <clip-loader
                      v-if="sendingPost"
                      class="d-inline-block align-middle ml-1 ml-3"
                      color="#000000"
                      :size="'14px'"
                    >
                    </clip-loader>
                  </div>

                  <div class="comment_send-btn">
                    <button
                      class="btn btn-studio-theme-space"
                      @click="replyToComments(null)"
                      >Send</button
                    >
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { commonMethods } from '@src/modules/inbox/store/common-methods'
import InboxConversationHead from '@src/modules/inbox/components/InboxConversationHead'
import TwitterMainTweet from '@src/modules/inbox/components/reusable/twitter-post/TwitterMainTweet'
import TwitterReplyTweet from '@src/modules/inbox/components/reusable/twitter-post/TwitterReplyTweet'
import SavedReplyModal from '@src/modules/inbox/components/savereplies/SavedReplyModal'
import {EventBus} from "@common/lib/event-bus";
export default {
  components: {
    InboxConversationHead,
    TwitterMainTweet,
    TwitterReplyTweet,
    SavedReplyModal,
  },
  props: { activeInboxDetail: { type: Object, default: () => {} },
    resetView: {
      type: Function,
      default: () => {},
    }},

  data() {
    return {
      sendingPost: false,
      notSent: false,
      post: null,
      parentPost: null,
      rootPost: null,
      postComments: null,
      postReplies: null,
      commonMethods,
      attachment: null,
      typedComment: '',
      mainComment: true,
      index: -1,
      activeUser: [],
      replyTo: null,
      temp: this,
      screenName: '',
      showWriteBlock: false,
      previousConversation: false,
    }
  },

  computed: {},
  watch: {
    'activeInboxDetail.element_details.element_id': async function (
      newVal,
      oldVal
    ) {
      // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.screenName = commonMethods.getSocialDetail(this, newVal).screen_name
      this.attachment = null
      this.typedComment = ''
      this.showWriteBlock = false
      this.previousConversation = false
      await this.fetchPostAndComments()
    },
  },
  mounted() {
    // this.post = this.activeInboxDetail
    this.screenName = commonMethods.getSocialDetail(
      this,
      this.activeInboxDetail
    ).screen_name
    this.fetchPostAndComments()
    commonMethods.setActiveUser(this)
  },

  created() {},

  methods: {
    ...mapActions(['fetchPostComments', 'reply']),
    applySavedReply(applyText) {
      this.typedComment = this.typedComment + applyText
    },
    getScreenName() {
      return commonMethods.getSocialDetail(this, this.activeInboxDetail)
        .screen_name
    },
    replyCommentTwitter(
      postedByUserName,
      usersMentioned,
      tweetId,
      main,
      index = -1
    ) {
      let mentionString = '@' + postedByUserName + ' '
      usersMentioned.forEach(function (userMentioned) {
        mentionString = mentionString + '@' + userMentioned.screen_name + ' '
      })
      if (this.typedComment === mentionString || this.typedComment === '') {
        this.showWriteBlock = !this.showWriteBlock
      }

      this.typedComment = mentionString
      this.notSent = false
      this.mainComment = main
      this.index = index
      this.replyTo = tweetId

      setTimeout(() => {
        document.getElementById('mainComment').focus()
      }, 300)
    },
    getImagesDist(attachmentSize) {
      switch (attachmentSize) {
        case 1:
          return 'single_image'
        case 2:
          return 'multiple_images two_images'
        case 3:
          return 'multiple_images three_images'
        case 4:
          return 'multiple_images four_images'
        default:
          return 'messed yp --> ' + attachmentSize
      }
    },
    createPayload() {
      const payload = new FormData()
      payload.append('platform', this.post.platform)
      payload.append('platform_id', this.post.platform_id)
      payload.append('workspace_id', this.post.workspace_id)
      payload.append('reply_for', 'post')
      payload.append(
        'element_id',
        this.activeInboxDetail.element_details.element_id
      )
      payload.append('tweet_id', this.replyTo)
      payload.append('root_tweet_id', this.post.root_tweet_id)
      payload.append('message', this.typedComment)
      payload.append('replied_by', this.activeUser.user_id)
      payload.append('attachment_file', this.attachment)
      return payload
    },

    async replyToComments(e) {
      // if user press enter + shift it will only add next line in textarea...
      // else case only enter key will send message
      const canSend = e === null ? true : e.keyCode === 13 && !e.shiftKey
      if (canSend) {
        if (e !== null) {
          e.preventDefault()
        }
        const payload = this.createPayload()
        this.sendingPost = true
        const response = await this.reply(payload)
        if (response.isValid) {
          EventBus.$emit('refresh-inbox-detail')
          if (this.mainComment) {
            this.postComments.push(response.response)
          } else {
            if (this.index >= 0) {
              this.postComments[this.index].replies = []
              this.postComments[this.index].replies.push(response.response)
            }
          }
          this.notSent = false
          this.typedComment = ''
          this.attachment = null
        } else {
          this.notSent = true
        }
        this.sendingPost = false
      }
    },

    resetMessage() {
      this.notSent = false
      this.typedComment = ''
      this.removeMedia()
    },
    async setCanShowReplies(show, tweet, index) {
      if (show) {
        const replies = this.postReplies[tweet.tweet_id]
        this.$set(tweet, 'replies', replies)
        this.postComments[index].replies = replies
      } else {
        this.$set(tweet, 'replies', [])
        this.postComments[index].replies = []
      }
    },

    async fetchPostAndComments() {
      const resp = await this.fetchPostComments({
        reply_to: this.activeInboxDetail.element_details.reply_to,
        post_id: this.activeInboxDetail.element_details.element_id,
        workspace_id: this.activeInboxDetail.workspace_id,
        platform: this.activeInboxDetail.platform,
      })

      if (resp.isValid) {
        this.postComments = resp.comments.tweets
        this.parentPost = resp.comments.parent_tweet
        this.rootPost = resp.comments.root_tweet
        this.post = resp.comments.post
        this.postReplies = resp.comments.replies
        if (resp.comments.post === null) {
          this.post = this.rootPost
          this.rootPost = null
        }
      } else {
        this.postComments = null
        this.parentPost = null
        this.rootPost = null
        this.post = null
        this.postReplies = null
      }
    },
    attachMedia(event) {
      console.log(event.target.files[0])
      if (event.target.files.length > 0) {
        this.attachment = event.target.files[0]
      } else {
        this.removeMedia()
      }
    },
    removeMedia() {
      this.attachment = null
      this.$refs.twitterAttachment.value = null
    },
  },
}
</script>

<style lang="less" scoped>
.like {
  color: rgb(219, 83, 82);
  cursor: pointer;
}

b-form-textarea:disabled {
  background: white;
}
</style>
