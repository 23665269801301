<script setup>
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'

const { cards, getCardData, isReportView } = useTwitterAnalytics()
</script>

<template>
  <NewStatsCard
    v-for="card in cards"
    :key="card"
    :show-info-tooltip="!isReportView"
    :data="getCardData(card)"
  />
</template>
