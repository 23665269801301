<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { isEqual } from 'lodash'
import { computed, watch, onMounted, onUnmounted } from 'vue'
import SocialMediaViewerLayout from '@src/modules/planner_v2/components/SocialMediaViewer/MainLayout'
import InstagramScreen from '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/MainScreen'
import InstagramSideBar from '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/SidebarComponent'
import phonePrototype from '@assets/img/planner/phone-prototype.svg'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import { EventBus } from '@/src/modules/common/lib/event-bus'

const store = useStore()
const route = useRoute()
const {
  DEFAULT_SELECTED_TAB,
  selectedTab,
  filteredItems,
  selectedAccount,
  selectedPost,
  fetching,
  fetchingMoreItems,
  fetchMedia,
  reset,
  showRemoteMedia,
  getPostingDetailsOnSelectedAccount,
  sortArrayByDate,
  isViaPushNotification,
  isPushAndAccepted,
} = useInstagramGridView()

const backGroundStyles = computed(() => {
  return {
    backgroundColor: selectedPost.value ? '#020b12' : '',
    borderRadius: selectedPost.value ? '28px' : '',
  }
})

onMounted(async () => {
  selectedTab.value = DEFAULT_SELECTED_TAB

  if (selectedAccount.value?.instagram_id) {
    await fetchMedia()
  }
})

onUnmounted(() => reset())

watch(
  () => selectedTab.value,
  () => {
    selectedPost.value = null
    EventBus.$emit('grid-view-tab-changed')
    EventBus.$emit('refetch-plans')
  }
)

watch(selectedAccount, async (newVal) => {
  if (newVal?.instagram_id) {
    await fetchMedia()
  } else {
    selectedAccount.value = null
  }
})

watch(
  route,
  (newVal) => {
    const newStatuses = newVal?.query?.statuses?.split(',')

    if (newStatuses === undefined) {
      showRemoteMedia.value = true

      return 1
    }

    showRemoteMedia.value = !!(
      newStatuses.includes('published') ||
      newStatuses.length === 0 ||
      newStatuses.length >= 5
    )
  },
  { immediate: true, deep: true }
)

watch(
  () => store.getters.getPlans.items,
  (newItems, oldItems) => {
    if (!isEqual(newItems, oldItems)) {
      const newPlans = newItems.map((element) => {
        // Add isDraggable property for scheduled items if needed in the future
        // Check if the post is draggable based on its status
        const isDraggable = ['draft', 'scheduled', 'reviewed'].includes(
          element.post_state
        )

        return {
          ...element,
          isDraggable,
          data_key: Math.random(),
          posting_details: getPostingDetailsOnSelectedAccount(element),
        }
      })

      filteredItems.value = sortArrayByDate(newPlans)?.filter((item) => {
        if (item.posting_details === undefined) return true

        if (!isViaPushNotification(item.posting_details)) return true

        return !isPushAndAccepted(item.posting_details)
      })
    }
  }
)
</script>

<template>
  <SocialMediaViewerLayout>
    <template v-slot:body>
      <div class="flex justify-center align-items-center h-full w-full">
        <div class="relative h-full min-w-[10rem]" :style="backGroundStyles">
          <img class="h-full min-w-[10rem]" :src="phonePrototype" alt="" />
          <div
            class="custom-padding flex justify-content-center absolute top-0 left-0 w-full h-full p-[0.4rem]"
            :class="selectedPost && 'xl-p-[0.6rem] 2xl:p-[0.7rem]'"
          >
            <div v-if="!selectedAccount" class="flex align-items-center">
              <clip-loader
                v-if="fetching && !fetchingMoreItems"
                class="spinner ml-2"
                :color="'#e0dfdf'"
                :size="'60px'"
              >
              </clip-loader>
              <div v-else>
                <img
                  class="w-full px-[3rem] mb-5"
                  src="@assets/img/planner/no-content.jpg"
                  alt="No content found"
                />
                <div class="px-10">
                  <p class="text-center font-semibold">
                    No post available yet
                  </p>
                  <p class="text-center text-muted">
                    Please compose a post for Instagram to view the grid.
                  </p>
                </div>
              </div>
            </div>
            <template v-else>
              <InstagramScreen />
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:timeline>
      <InstagramSideBar />
    </template>
  </SocialMediaViewerLayout>
</template>

<style lang="scss" scoped>
@media screen and (min-width: 2130px) {
  .custom-padding {
    padding: 0.5rem !important;
  }
}
</style>
