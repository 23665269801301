// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    FACEBOOK_API_ENDPOINTS,
    FACEBOOK_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function usePostReactsDistributionByCompany() {
    const {
        fetchCompetitorInfo: fetchPostReactsDistributionByCompany,
        apiResponse: postReactsDistributionByCompanyResponse,
        loading: postReactsDistributionByCompanyLoadingState
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone,} = useCompetitorReport()

    const getPostReactsDistributionByCompanyData = async (type = '', facebookId = '') => {
        switch (type) {
            case 'facebook':
                await fetchFbPostReactsDistributionByCompany(facebookId)
                break
            default:
                break
        }
    }

    const fetchFbPostReactsDistributionByCompany = async (facebookId) => {
        if (!reportId.value) return

        const payload = {
            facebook_id: facebookId,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }

        try {
            await fetchPostReactsDistributionByCompany(FACEBOOK_API_ENDPOINTS.POST_REACTS_DISTRIBUTION_BY_COMPANY, payload, FACEBOOK_ERROR_MESSAGES.POST_REACTS_DISTRIBUTION_BY_COMPANY)
        } catch (error) {
        }
    }


    return {
        // data
        postReactsDistributionByCompanyLoadingState,
        postReactsDistributionByCompanyResponse,

        // methods
        getPostReactsDistributionByCompanyData
    }
}