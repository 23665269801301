<template>
  <div class="feeds-content-pane">
    <!--for topics -- remove d-none class .......-->
    <div class="feeds-content-pane__section">
      <template v-if="load_posts">
        <div class="pt-3">
          <beat-loader :color="'#436aff'"> </beat-loader>
        </div>
      </template>
      <template v-else>
        <template v-if="!load_feed">
          <div class="feeds-content-pane__section__header">
            <div class="feeds-content-pane__section__header__content">
              <ContentName
                :title="feed.title"
                :image="feed.logo"
                :hashtags="feed.hashtags"
                :categories="feed.categories"
                :avg_articles_per_week="feed.avg_articles_per_week"
              ></ContentName>
            </div>
            <div class="feeds-content-pane__section__header__filters">
              <ContentTotalResults
                :total="total_results"
              ></ContentTotalResults>
              <FeedContentFilters
                :total="total_results"
                @reloadFeedDateRange="reloadFeedDateRange"
              ></FeedContentFilters>
            </div>
          </div>

          <!--<content-total-results :total="total_results"></content-total-results>-->
        </template>
        <!-- dynamic class added because there was gap coming due to infinite loader.-->
        <div class="feeds-content-pane__section__items">
          <FeederContentViewSlot>
            <template v-for="(post, index) in posts">
              <div
                v-if="getFeederPreferences.item_style === 'grid'"
                :key="index"
                class="feed-grid-view-container"
              >
                <FeederGridViewItem
                  :post="post"
                  :index="index"
                  :route_name="'individual_feed_item'"
                >
                </FeederGridViewItem>
              </div>
              <div
                v-if="getFeederPreferences.item_style === 'list'"
                :key="'list_' + post.id"
                class="article_box_list"
              >
                <FeederListViewItem
                  :post="post"
                  :index="index"
                  :route_name="'individual_feed_item'"
                ></FeederListViewItem>
              </div>
            </template>
          </FeederContentViewSlot>
          <InfiniteLoading
            ref="infiniteFeed"
            :identifier="infiniteId"
            @infinite="feederFeedSectionById"
          >
            <span slot="spinner" class="d-block pt-3 pb-4">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <div slot="no-more" class="not_found_text">
              <div v-if="total_results === 0" class="no_data_found_content">
                <div class="no_data_found_content_inner">
                  <div class="img">
                    <img
                      src="../../../../../assets/img/no_data_images/no_data_found.svg"
                      alt=""
                    />
                  </div>
                  <h2>No more results found</h2>
                  <p>No more results found for your query.</p>
                  <p
                    >Please try a different search string or change search
                    filters.</p
                  >
                </div>
              </div>
            </div>

            <div slot="no-results">
              <div class="no_data_found_content">
                <content-available-state :type="'no_results'">
                  <h2 slot="headline">No results found</h2>
                  <p slot="text"
                    >No posts are available for your selected feed</p
                  >
                </content-available-state>
              </div>
            </div>
          </InfiniteLoading>
        </div>
      </template>
    </div>
    <!-- for modals like social share, article preview etc. -->
    <router-view></router-view>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters } from 'vuex'
import moment from 'moment'
import {EventBus} from "@common/lib/event-bus";
import ContentName from '../components/content-view/ContentName'
import ContentTotalResults from '../components/content-view/ContentTotalResults'
import FeedContentFilters from '../components/content-view/FeedContentFilters'
import FeederGridViewItem from '../components/content-view/type-of-views/FeederGridViewItem'
import FeederListViewItem from '../components/content-view/type-of-views/FeederListViewItem'
import FeederContentViewSlot from '../slots/FeederContentViewSlot'
import { feederItemMixin } from '../mixins/feeder-item-mixin'

export default {
  components: {
    ContentName,
    ContentTotalResults,
    FeederGridViewItem,
    FeederListViewItem,
    FeederContentViewSlot,
    InfiniteLoading,
    FeedContentFilters
  },
  mixins: [feederItemMixin],

  data () {
    return {
      feed: {
        title: 'Test'
      },
      load_posts: false,
      posts: [],
      total_results: 0,
      state: '',
      infiniteState: '',
      infiniteId: +new Date(),
      page: 1,
      load_feed: false
    }
  },
  computed: {
    ...mapGetters(['getFeederPreferences']),
    hasFeederArchiveEvent () {
      return this.$attrs && this.$attrs.feeder_archive_action
    }
  },
  watch: {
    '$route.params.feed_id' (value) {
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    },
    '$route.query.order' (value) {
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  },
  created () {},
  mounted () {
    console.log(this.$attrs)
    console.log('created feeder feed section...')
    EventBus.$on('feeder_archive_action', async (value) => {
      console.log('feeder_archive_action', value)
      const res = await this.archiveAction(
        value.record_id,
        value.index,
        value.state,
        this.posts
      )
      if (res) {
        this.total_results -= 1
        // update the statistics for the domain id.
        this.$store.dispatch('updateFeederStatisticsForDomain', {
          domainUUID: value.domain_uuid,
          state: value.state
        })
      }
    })
    EventBus.$on('feeder_starred_action', async (value) => {
      console.log('feeder_starred_action', value)
      const res = await this.starredAction(
        value.record_id,
        value.index,
        value.state,
        this.posts
      )
    })
  },
  beforeUnmount () {
    EventBus.$off('feeder_archive_action')
    EventBus.$off('feeder_starred_action')
    console.log('unmounted feeder feed section...')
  },
  methods: {
    async feederFeedSectionById ($state) {
      this.load_feed = true
      const feed = this.$store.dispatch('fetchFeederFeed', {
        feedId: this.$route.params.feed_id,
        page: this.page,
        order: this.$route.query.order || 'Recent',
        fromDate:
          this.$route.query.from_date ||
          moment.utc().subtract(90, 'days').format('YYYY-MM-DD'),
        toDate: this.$route.query.to_date || moment.utc().format('YYYY-MM-DD')
      })
      feed
        .then((res) => {
          if (res && res.data && res.data.status) {
            if (res && res.data) {
              if (res.data.status) {
                this.state = res.data.state
                this.feed = res.data.feed
                document.title = this.limitTextLength(this.feed.title, 30)
                if (res.data.hasOwnProperty('posts')) {
                  this.total_results = res.data.total
                  if (
                    res.data.posts &&
                    res.data.posts.length
                  ) {

                    if (this.page === 1) {
                      this.posts = res.data.posts
                    } else {
                      this.posts = this.posts.concat(res.data.posts)
                    }
                    if ($state) $state.loaded()
                  } else {
                    console.log('no hits, set to complete')
                    if ($state) $state.complete()
                  }
                  this.page += 1
                } else {
                  console.log('no posts, set to complete')
                  if ($state) $state.complete()
                }
              } else {
                if ($state) $state.complete()
              }
            }
          }
          this.load_feed = false
        })
        .catch((err) => {
          this.load_feed = false
        })
    },
    reloadFeedDateRange () {
      console.log('event emitted for feed date range...')
      this.posts = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  }
}
</script>
