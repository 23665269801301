<template>
  <div
    v-if="state.loader || state.hashtags.length > 0"
    class="p-4 my-2 w-full bg-white"
  >
    <div class="flex w-full items-center justify-between mt-1">
      <div class="flex items-center">
        <div
          class="inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-ultra-blue text-cs-primary text-2xl font-bold mr-3"
        >
          #
        </div>
        <span class="font-medium text-sm text-black-300"
          >AI Generated Hashtags</span
        >
      </div>
      <div>
        <b-progress
          :variant="
            getCreditUsedLimit >= getCreditSubscribeLimit ? 'danger' : 'primary'
          "
          class="mt-4 min-w-[13rem]"
          height="8px"
          :value="getCreditUsedLimit"
          :max="getCreditSubscribeLimit"
        ></b-progress>
        <div class="flex justify-between mt-1">
          <span> AI Credits(words) </span>
          <span>
            {{ formatNumber(getCreditUsedLimit) }} of
            {{ formatNumber(getCreditSubscribeLimit) }}
          </span>
        </div>
      </div>
    </div>
    <template v-if="state.loader">
      <div class="flex flex-1 gap-x-2 w-full my-3">
        <template v-for="n in 8" :key="n" >
          <SkeletonBox height="2rem" radius=".3rem" />
        </template>
      </div>
    </template>
    <template v-else-if="state.hashtags.length > 0">
      <!-- hashtags -->
      <div
        class="flex flex-wrap border-dashed border-2 py-3 px-4 my-3 rounded-md"
      >
        <template v-for="(tag, tagIndex) in state.hashtags" :key="tagIndex">
          <span
            v-tooltip.top="{ content: 'Click to add to Editor', delay: 0 }"
            class="border !border-l-2 !border-l-[#2f8ae0] text-[#2f8ae0] hover:text-cs-primary w-max inline-block cursor-pointer rounded px-3 py-1 mr-3.5 mb-2 text-sm font-medium"
            @click="emit('addHashtag', tag)"
            >{{ tag }}</span
          >
        </template>
      </div>
      <!-- actions -->
      <div
        class="flex w-full justify-end"
        :class="{ 'pointer-events-none': state.loader }"
      >
        <span
          class="bg-[#26cb85] rounded-md focus:outline-none text-cs-white border-0 px-3 py-2 mx-1 cursor-pointer"
          @click="emit('addHashtag', state.hashtags, 0, true)"
          >Add All to Editor</span
        >
        <span
          v-if="!isEvergreen"
          class="bg-cs-primary rounded-md focus:outline-none text-cs-white border-0 px-3 py-2 mx-1 cursor-pointer"
          @click="emit('saveHashtag', state.hashtags)"
          >Save Hashtags</span
        >
        <span
          class="bg-cs-ultra-blue rounded-md focus:outline-none text-cs-primary border-0 px-3 py-2 mx-1 cursor-pointer"
          @click="emit('reGenerate')"
          >Re-Generate</span
        >
      </div>
    </template>
  </div>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */
import { computed, reactive } from 'vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import { useStore } from '@state/base'
const props = defineProps({
  isEvergreen: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['addHashtag', 'saveHashtag', 'reGenerate'])
const state = reactive({ loader: false, hashtags: [] })
const store = useStore()

const getCreditSubscribeLimit = computed(() => {
  return store.getters.getPlan?.subscription?.limits?.caption_generation_credit
})
const getCreditUsedLimit = computed(() => {
  return store.getters.getPlan?.used_limits?.caption_generation_credit
})
const setHashtags = (value = []) => {
  state.hashtags = value
}
const setLoader = (value = false) => {
  state.loader = value
}
defineExpose({ setHashtags, setLoader })
</script>
