<script setup>
import {reactive, ref, computed, onMounted, onBeforeUnmount} from "vue";

const props = defineProps({
  accountName: {
    type: String,
    default: '',
    required: true,
  },
  accountImage: {
    type: String,
    required: true,
  },
  video: {
    type: Object,
    default: () => ({}),
  },
  description: {
    type: String,
    default: '',
  },
  togglePreview: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: String,
    default: '',
  },
  storyImage: {
    type: String,
    default: '',
  },
})

const videoPlayer = ref(null)
const progressBar = ref(null)
const state = reactive({
  isVideoPlaying: false,
  shouldHideIcon: false,
  currentTime: 0,
  duration: 0,
})

// calculates the progress bar width on basis of current time and duration
const getProgressWidth = computed(() => {
  if (state.currentTime && state.duration) {
    const progress = (state.currentTime / state.duration) * 100
    return `${progress}%`
  }

  return '0%'
})

// returns the playback icon on basis of video state
const playBackIcon = computed(() =>
    state.isVideoPlaying ? 'fa-pause' : 'fa-play'
)

// mounted
onMounted(() => {
  // add event listener to video player to calculate time update
  if (videoPlayer.value) {
    videoPlayer.value.addEventListener('timeupdate', handleTimeUpdate)
  }

  // if thumbnail is not present, then show the progress bar animation
  if (!props.thumbnail && progressBar.value) {
    progressBar.value.style.width = '0%'
    progressBar.value.style.width = '100%'
  }
})

// methods

// handles the time update event of video player
const handleTimeUpdate = () => {
  const videoElement = videoPlayer.value
  if (videoElement) {
    state.currentTime = videoElement.currentTime
    state.duration = videoElement.duration
  }
}

// handles the click event of video player
const changeVideoState = () => {
  const videoElement = videoPlayer.value

  if (videoElement.paused) {
    // If video is paused, play it
    videoElement.play()
    state.isVideoPlaying = true
    setTimeout(() => (state.shouldHideIcon = true), 700)
  } else {
    // to pause
    videoElement.pause()
    state.isVideoPlaying = false
    state.shouldHideIcon = false
  }
}

// on before component gets unmounted
onBeforeUnmount(() => {
  // remove event listener from video player
  if (videoPlayer.value) {
    videoPlayer.value.removeEventListener('timeupdate', handleTimeUpdate)
  }
})
</script>

<template>
  <div
    class="
      block
      w-full
      cst-drop-shadow
      rounded-xl
      mb-3
      scroll-snap-start
      overflow-y-hidden
      bg-white
    "
  >
    <div v-if="!togglePreview" class="flex w-full items-center px-3 py-3">
      <div
        class="
          ml-auto
          inline-flex
          justify-center
          items-center
          w-8
          h-8
          rounded-lg
          bg-cs-light-blue
        "
      >
        <img
          src="@assets/img/integration/facebook-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div class="relative text-white">
      <!--   background   -->
      <div
          class="!bg-[#020B12] ratio ratio-9x16 group"
          :class="{ 'preview-background': thumbnail }"
          @click="changeVideoState"
      >
        <div v-if="storyImage" class="flex items-center justify-center">
          <img class="w-full max-h-full object-cover" :src="storyImage" alt="thumbnail" />
        </div>

        <template v-else-if="thumbnail">
            <video
                ref="videoPlayer"
                :key="video.link"
                class="cursor-pointer"
                width="100%"
                height="100%"
            >
              <source :src="video.link" type="video/mp4" />
            </video>
                <i
                    class="fas bg-[#0000007a] active:bg-gray-200/50 p-2 rounded-full playback-icon w-8 h-8 flex items-center justify-center absolute top-1/2 left-[48%] group-hover:!opacity-100"
                    :class="[playBackIcon, state.shouldHideIcon && 'opacity-0']"
                ></i>
        </template>
      </div>

      <!--   header   -->
      <div class="absolute top-px left-0 w-full text-xl text-white py-5">
        <div class="h-0.5 bg-[#3C4549] rounded-[2px] mb-[10px] mx-[7px]">
          <div
              ref="progressBar"
              class="h-0.5 bg-[#757A8A] rounded-[2px] mb-[10px]"
              :style="thumbnail ? { width: getProgressWidth } : ''"
              :class="[thumbnail ? 'progress-bar-video' : 'progress-bar-image']"
          >
          </div>
        </div>

        <div class="flex justify-between items-center px-5">
          <div class="flex items-center gap-2">
            <img
                :src="accountImage"
                alt=""
                class="rounded-full object-cover w-[34px] h-[34px]"
                @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <p class="select-none text-xs font-semibold">
              {{ accountName }}
            </p>
          </div>

          <i
              class="far fa-ellipsis-h text-xl w-6 h-6 flex justify-center items-center text-[#D2D5DF]"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.playback-icon {
  transition: opacity 0.5s ease;
}

.progress-bar-video {
  transition: width 0.3s ease;
}

.progress-bar-image {
  transition: width 5s ease;
}
</style>
