<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getEvergreenVariationData'])
  },
  methods: {
    ...mapActions(['fetchVariationUrl', 'addVariationFromPreview']),
    /*
     * remove post from URL variations Preview
     */
    removeVariationPreview (index) {
      this.getEvergreenVariationData.variationPreview.splice(index, 1)
    }
  }
}
</script>

<template>
  <div
    id="autoVariationGenerate"
    class="modal fade autoVariationGenerate normal_modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Auto Generate Variations</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form m_t_15">
          <div class="input_field mt-0">
            <label>Variations will be generated from this URL</label>
            <input
              v-model="getEvergreenVariationData.variationURL"
              class="disable_item"
              type="url"
              placeholder="Your fetch URL..."
              readonly="readonly"
              disabled="disabled"
            />
          </div>
          <div
            v-if="
              !getEvergreenVariationData.fetchLoader &&
              getEvergreenVariationData.variationPreview.length > 0
            "
            class="post_preview_main mt-4"
          >
            <div
              v-for="(
                post, index
              ) in getEvergreenVariationData.variationPreview"
              :key="index"
              class="post_preview_box"
            >
              <div class="box_inner">
                <div class="text" v-html="post"></div>
                <div class="btn_block">
                  <button
                    class="btn red_bg"
                    @click.prevent="removeVariationPreview(index)"
                    ><span>Remove</span></button
                  >
                  <span class="char_left"
                    >Characters: <span class="">{{ post.length }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="input_field d-flex align-items-center">
            <button
              v-if="getEvergreenVariationData.variationPreview.length === 0"
              class="btn blue_gradient large_btn btn_loader loader_right"
              :disabled="getEvergreenVariationData.fetchLoader"
              :class="{ disabled: getEvergreenVariationData.fetchLoader }"
              @click.prevent="fetchVariationUrl"
            >
              <span>Generate</span>
              <clip-loader
                v-if="getEvergreenVariationData.fetchLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
            <button
              v-else
              class="btn gradient_btn large_btn btn_loader loader_right"
              :disabled="getEvergreenVariationData.addLoader"
              :class="{ disabled: getEvergreenVariationData.addLoader }"
              @click.prevent="
                addVariationFromPreview(
                  getEvergreenVariationData.variationPreview,
                )
              "
            >
              <span>Add</span>
              <clip-loader
                v-if="getEvergreenVariationData.addLoader"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
            <p
              v-if="getEvergreenVariationData.variationPreview.length"
              class="ml-auto variation_count"
              >Total variation:
              <strong>{{
                getEvergreenVariationData.variationPreview.length
              }}</strong></p
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
