<template>
  <tbody>
    <template
      v-for="(data, index) in tableData"
      :key="`comparative_table_row_${index}`"
    >
      <tr
        class="hover:bg-gray-50 cursor-pointer"
        :class="{
          '!mt-2': index === 0,
          '!border-l-0 !border-t-0 !border-r-0 border !border-gray-200':
            index !== tableData.length - 1,
          'main-row relative rounded-md':
            state.expandedRowIndex.includes(index),
        }"
        @click="getFollowersGraph(data, index)"
      >
        <td class="py-2.5 pl-4 flex items-center">
          <i
            v-tooltip="{
              content: tooltipHtml(`Click to see “${getDetailedPulseChartTitle(data?.dataType)}”.`),
              theme: 'light',
              allowHTML: true,
            }"
            class="mr-3 fa text-gray-800 text-xs"
            :class="
              state.expandedRowIndex.includes(index)
                ? 'fa-chevron-up text-cs-blue'
                : 'fa-chevron-down'
            "
          >
          </i>
          <i
            class=" text-gray-700 w-4 h-4 inline-flex justify-center items-center"
            :class="data?.type === 'labels' ? 'fa fa-tag' : 'fa fa-bullhorn'"
          ></i>

          <div class="ml-2 flex items-center w-2/5">
            <span
              v-tooltip="{
                content: tooltipHtml(`${getTypeName(data?.type)}: ${data?.name}`),
                theme: 'light',
                allowHTML: true,
              }"
              class="truncate text-sm px-3 py-1 rounded-md max-w-[140px] "
              :style="data?.type === 'labels' ? getLabelColor(data?.color) : getCampaignColor(data?.color)"
              >{{ data?.name }}
            </span>
          </div>
          <PulseChart
            :key="generateChartId(data, index)"
            :data="generateChartData(data)"
            :platform-name="platformName"
            :is-loading="false"
          />
        </td>
        <td
          v-for="(dataItem, key) in headers"
          :key="`comparative_table_data_${key}`"
          class="py-2.5"
        >
          <div v-if="key === 0" class="flex items-center mr-10">
            <p class="px-2 flex items-center font-0-95rem select-none">
            <span
              v-tooltip="{
                content: tooltipHtml(`${data[headers[key]]}`),
                theme: 'light',
                allowHTML: true,
              }"
              class="px-4 py-0.5 rounded-xl min-w-[6rem] flex justify-center"
              >{{ getTableData(dataItem, data[headers[key]]) }}</span
            >
           </p>
           <!-- Progress bar start -->
           <div v-if="key === 0" id="progessBar" class="w-full">
            <div
              class="inline-block mb-2 py-0.5 px-1.5 text-xs font-medium  rounded-lg min-w-[3.5rem] text-center"
              :class="`cst-pill ${data?.color}`"
              :style="`margin-inline-start: ${data?.distributionPercentage || 0}%;`"
            >
              {{data?.distributionPercentage || 0}}%
            </div>
            <div
              class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden"
              role="progressbar"
              :aria-valuenow="data?.distributionPercentage || 0"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center rounded-full overflow-hidden text-xs text-center whitespace-nowrap transition duration-500"
                :class="`${data?.color}`"
                :style="`width: ${data?.distributionPercentage || 0}%`"
              ></div>
            </div>
          </div>
          <!-- Progress bar end -->
          </div>
          <p v-else class="px-2 flex items-center font-0-95rem select-none">
            <span
              v-tooltip="{
                content: tooltipHtml(`${data[headers[key]]}`),
                theme: 'light',
                allowHTML: true,
              }"
              class="px-4 py-0.5 rounded-xl min-w-[6rem] flex justify-center"
              >{{ getTableData(dataItem, data[headers[key]]) }}</span
            >
          </p>

        </td>
      </tr>
      <Transition name="slide">
        <tr
          v-if="
            state.expandedRowIndex.length &&
            state.expandedRowIndex.includes(index)
          "
        >
          <td colspan="100%">
            <DetailedPulseChart
              :title="data.name"
              :platform-name="platformName"
              :data="generateChartData(data)"
              :is-loading="false"
            />
          </td>
        </tr>
      </Transition>
    </template>
  </tbody>
</template>

<script setup>
// libraries
import { reactive } from 'vue'

// components
import PulseChart from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/PulseChart.vue'
import DetailedPulseChart from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/DetailedPulseChart.vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';


const { tooltipHtml } = useComposerHelper()
// composable
const {
  LABELS_COLOR_MAP,
  getDetailedPulseChartTitle,
  getLabelColor,
  getCampaignColor,
} = useLabelAndCampaign()

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  indexToSort: {
    type: Number,
    default: 0,
  },
  chartData: {
    type: Object,
    default: () => {},
  },
  platformName: {
    type: String,
    default: '',
  },
})

const state = reactive({
  isSorted: true,
  sortedHeaderKey: props.indexToSort,
  isLoading: false,
  expandedRowIndex: [],
})

const generateChartId = (data, index) => {
  const chartData = props.chartData[data?.id]

  return `pulse_chart_${index}_${data?.id}_${chartData?.buckets.length}`
}

const generateChartData = (data) => {
  const chartData = props.chartData[data?.id]

  return {
    name: data?.name,
    values: chartData?.buckets.map((value, index) => [
      value,
      chartData[data?.chartApiKey][index],
    ]),
    dataType: data?.dataType,
    color: LABELS_COLOR_MAP[data?.color],
  }
}


/**
 * @description checks key and value to return the proper values for row data
 * @param header
 * @param value
 * @returns {*|string}
 */
const getTableData = (header, value) => {
  if (value === 'N/A') return value
  if (header.includes('rate')) return `${value}%`
  return value > 1000
    ? new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
    : value
}

/**
 * @description creates the data for expanded view of followers growth graph
 * @param competitor
 * @param index
 */
const getFollowersGraph = (competitor, index) => {
  if (Object.keys(props.chartData)?.length) {
    const expandedRowIndex = state.expandedRowIndex.slice()

    const indexFound = expandedRowIndex.indexOf(index)
    if (indexFound === -1) {
      expandedRowIndex.push(index)
    } else {
      expandedRowIndex.splice(indexFound, 1)
    }
    state.expandedRowIndex = expandedRowIndex
  }
}

/**
 * @description returns the type name based on the type
 * @param type
 * @returns {string}
 */
const getTypeName = (type) => {
  return type === 'labels' ? 'Label' : 'Campaign'
}
</script>

<style lang="scss" scoped>
.main-row {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.slide-enter-active {
  animation: slide-in 0.3s;
}
.slide-leave-active {
  animation: slide-in 0.3s reverse;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
</style>
