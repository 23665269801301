import { TEXT_IMAGE_PREVIEW_URL } from '@src/config/api-utils'

export default function useString() {
  function getNumberOfWordsFromString(str, wordsCount = 2) {
    if (countWords(str) > wordsCount) {
      return str.split(' ').slice(0, wordsCount).join(' ') + '...'
    }

    return str.split(' ').slice(0, wordsCount).join(' ')
  }

  function countWords(str) {
    return str.split(' ').length
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  /**
   * @description used throughout the app in case of fallback images, takes the letter and returns a thumbnail image for that.
   */
  function fallbackImagePreview(
    text,
    bgColor = '97d56c',
    textColor = 'green',
    fontSize = 30
  ) {
    return `${TEXT_IMAGE_PREVIEW_URL}/text_preview/?bg_color=%23${bgColor}&text_color=${textColor}&font_size=${fontSize}&text=${text}`
  }

  return {
    countWords,
    getNumberOfWordsFromString,
    capitalizeFirstLetter,
    fallbackImagePreview,
  }
}
