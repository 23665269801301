<template>
  <div class="setting_team_component">
    <div class="">
      <div class="ps_box">
        <div class="flex flex-row items-center space-x-4 p-4 divide-y divide-gray-200">
          <div class="">
            <h2 class="box_heading">Emails Sent To: </h2>
          </div>
          <div v-if="getLoggedUser.role === 'super_admin'">
            <b-dropdown
                id="dropdown-right"
                variant="studio-theme"
                class="studio-theme-dropdown mr-2"
                no-caret
            >
              <template v-slot:button-content>
                <span v-if="selectedUser === undefined && !loading" class="font-semibold">Select User</span>
<!--                <span v-else-if="loading">Loading...</span>-->
                <span v-else class="">{{ selectedUser.email }}</span>
                <i class="icon-dropdown-cs"></i>
              </template>

              <b-dropdown-item v-for="(member, index) in users" :key="index" class="text-sm" @click="onClickDropDown (member)">
                <span>{{ member.user.full_name }}</span> - {{ member.user.email }}
              </b-dropdown-item>

            </b-dropdown>
          </div>
          <div v-else >
            <span class="text-lg">{{ getLoggedUser.user.full_name }}</span> - {{ getLoggedUser.user.email }}
          </div>
        </div>

        <div class="row team_member_tbl">

          <div v-if="selectedUser === undefined || loading" class="col-lg-12">
            <table>
              <thead>
              <tr>
                <th>Subject</th>
                <th>Status</th>
                <th>Received At</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td><SkeletonBox width="70%" radius=".2rem"/></td>
                  <td><SkeletonBox width="60%" radius=".2rem"/></td>
                  <td><SkeletonBox width="25%" radius=".2rem"/></td>
                </tr>
                <tr>
                  <td><SkeletonBox width="60%" radius=".2rem"/></td>
                  <td><SkeletonBox width="45%" radius=".2rem"/></td>
                  <td><SkeletonBox width="29%" radius=".2rem"/></td>
                </tr>
                <tr>
                  <td><SkeletonBox width="55%" radius=".2rem"/></td>
                  <td><SkeletonBox width="33%" radius=".2rem"/></td>
                  <td><SkeletonBox width="14%" radius=".2rem"/></td>
                </tr>
                <tr>
                  <td><SkeletonBox width="30%" radius=".2rem"/></td>
                  <td><SkeletonBox width="47%" radius=".2rem"/></td>
                  <td><SkeletonBox width="25%" radius=".2rem"/></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="col-lg-12 nopad">
            <table>
              <thead>
              <tr>
                <th>Subject</th>
                <th class="text-center">Status</th>
                <th>Sent At/Bounced At</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(email, index) in userEmails" :key="`${index}_userEmail`">
                <!--Outbound Emails-->
                <tr v-if="('Status' in email)">
                  <td class="font-normal">{{ email.Subject }}</td>
                  <td>
                    <p class="rounded-lg bg-blue-400 text-white items-center flex justify-center w-24 h-8 mx-auto">{{ email.Status }}</p>
                  </td>
                  <td>{{ momentWrapper(email.ReceivedAt).formatDateTime()}}</td>
                  <td>
                    <div class="action_icons">
                      <i v-tooltip.top="'Preview'"
                         class="action_icon sm fas fa-eye"
                         @click.prevent="previewEmailTemplate(email.MessageID)"
                      ></i>
                    </div>
                  </td>
                </tr>
                <!--Bounced Emails-->
                <tr v-else>
                  <td>{{ email.Subject }}</td>
                  <td>
                    <p class="rounded-lg bg-red-400 text-white items-center flex justify-center w-24 h-8 mx-auto">
                      {{ email.Name }}
                    </p>
                  </td>
                  <td>{{ momentWrapper(email.BouncedAt).formatDateTime() }}</td>
                  <td>
                    <div class="action_icons">
                      <i
                          v-tooltip.top="'Preview'"
                          class="action_icon sm fas fa-eye"
                          @click.prevent="previewEmailTemplate(email.MessageID)"
                      ></i>
                      <i
                          v-tooltip.top="'Resend'"
                          class="action_icon sm cs-refresh"
                          @click.prevent="resendEmail(email.MessageID)"
                      ></i>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="emailPreview" title="Email Preview" size="lg" hide-footer @hidden="emailToPreview = undefined">
      <template v-slot:modal-header>
        <div class="w-full flex flex-row items-center justify-between">
          <div class="text-lg font-semibold">
            Email Preview
          </div>
          <div>
            <button class="close" type="button" aria-label="Close" @click="closeEmailModal">x</button>
          </div>
        </div>
      </template>
      <iframe v-if="emailToPreview !== undefined" id="emailPreviewIframe" class="w-100 emailPreviewIframe">

      </iframe>
      <div v-else class="emailPreviewIframe flex flex-col space-y-2.5 items-center justify-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import proxy from '@common/lib/http-common'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import {fetchEmailStatusURL, resendEmailURL, previewEmailURL, fetchSingleEmailStatusURL} from '@src/modules/setting/config/api-utils.js'
import useDateFormat from "@common/composables/useDateFormat";
export default {
  name: 'EmailNotificationStatus',
  components: {SkeletonBox},
  setup(){
    const { momentWrapper } = useDateFormat()
    return {
      momentWrapper
    }
  },
  data() {
    return {
      users: undefined,
      selectedUser: undefined,
      emailToPreview: undefined,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getActiveWorkspaceMemberIds']),
    userEmails() {
      if (this.selectedUser !== undefined && ('postmark_outbound_resp' in this.selectedUser)) {
        return [...this.selectedUser.postmark_outbound_resp.Messages, ...this.selectedUser.postmark_bounce_resp.Bounces].sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        })
      }

      return []
    }
  },
  created() {
    // this.fetchEmailData()
    this.users = this.getActiveWorkspace.members
    this.fetchLoggedUser()
  },
  methods: {
    /**
     * Fetches outbound and bounced emails via postmark api.
     */
    fetchEmailData() {
      console.log("METHOD::fetchEmailData")
      const self = this

      // for 'n' number of team members we're chunking IDs so that we can reduce the api response time.
      const chunkedWorkspaceIDs = _.chunk(self.getActiveWorkspaceMembersIds, 2);
      for (let i = 0; i <= chunkedWorkspaceIDs.length - 1; i++) {
        proxy.post(fetchEmailStatusURL, {
          user_ids: chunkedWorkspaceIDs[i]
        })
            .then(resp => {
              // self.users = resp.data.users;
              if (self.users === undefined) self.users = []
              for (const user in resp.data.users) {
                self.users.push(resp.data.users[user])
              }
            })
            .catch(err => {
              console.log('Method::fetchEmailData ~ err -> ', err)
            })
      }
    },

    /**
     * Fetching the logged user based on which we are showing the dropdown for user selection
     */
    fetchLoggedUser() {
      this.selectedUser = this.getLoggedUser.user
      this.fetchSingleUserEmailData(this.selectedUser._id)
    },

    /**
     * Fetching single user outbound and bounced emails based on it's userId
     * @param userId
     */
    fetchSingleUserEmailData(userId) {
      const self = this
      self.loading = true
      proxy.post(fetchSingleEmailStatusURL, {
        user_id: userId
      }).then(resp => {
        // self.users = resp.data.users;
        self.selectedUser = resp.data.user
        self.loading = false
      })
          .catch(err => {
            self.loading = false
            console.log('Method::fetchEmailData ~ err -> ', err)
          })
    },

    /**
     * Resends any bounded email via it's message id. Shows an alert message in notification in case of failure
     * @param message_id
     */
    resendEmail(message_id) {
      console.log('Method::resendEmail ~ message id -> ', message_id)
      const self = this
      self.alertMessage('Re-sending Email')

      proxy.post(resendEmailURL, {message_id})
          .then(resp => {
            console.log('Method::resendEmail ~ proxy resp -> ', resp)
            const message = JSON.parse(resp.data.message);
            if (message.status === false) {
              self.alertMessage(message.error_message.Message, 'error')
            } else {
              self.alertMessage('Email sent successfully to ' + message.To, 'success')
            }
          })
          .catch(err => {
            console.log('Method::resendEmail ~ proxy err -> ', err)
          })
    },

    /**
     * Receives an HtmlBody of the preview from postmark message details api. Using that HtmlBody to show a preview in an iframe view inside a modal.
     * @param message_id
     * @returns {Promise<void>}
     */
    async previewEmailTemplate(message_id) {
      const self = this
      self.$bvModal.show('emailPreview')
      await proxy.post(previewEmailURL, {message_id})
          .then(resp => {
            console.log(resp)
            self.emailToPreview = resp.data.postmarkMessage
          })
          .catch(err => {
            console.log(err)
          })
      document.getElementById('emailPreviewIframe').src = 'data:text/html;charset=utf-8,' + escape(self.emailToPreview.HtmlBody)
    },

    /**
     * onClick method for modal
     */
    closeEmailModal() {
      this.$bvModal.hide('emailPreview')
      this.emailToPreview = undefined
    },

    /**
     * Click event for dropdown user select.
     * @param member
     */
    onClickDropDown(member) {
      console.log("Method::onClickDropDown ~ event ->  ", member)
      this.selectedUser = member.user
      this.fetchSingleUserEmailData(member.user_id)
    }
  }
}
</script>

<style scoped type="less">
.emailPreviewIframe {
  border: none;
  height: 745px;
}

.fa-spinner {
  font-size: 75px;
}
.team_member_tbl table tbody tr td {
  font-weight: 400 !important;
}

</style>
