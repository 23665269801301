<template>
  <span class="clock">
    <span class="time">
      {{ formattedTime }}
    </span>
  </span>
</template>

<script>
import useDateFormat from "@common/composables/useDateFormat";

export default {
  name: 'Clock',
  setup(){
    const { momentWrapper } = useDateFormat();
    return { momentWrapper };
  },
  data() {
    return {
      dateTime: this.momentWrapper()
    }
  },
  computed: {
    formattedTime() {
      return this.momentWrapper().formatTimezone()?.formatDateTime()
    },
  },
}
</script>
