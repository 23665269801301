<template>
  <div class="topics-filters-pane__container__right_section">
    <!--        <div class="feeds-content-pane__section__header__filters__total">-->
    <!--          <h3 class="results" v-if="total > 0">{{total | number-to-commas}}-->
    <!--            <template v-if="total === 1">-->
    <!--              Result-->
    <!--            </template>-->
    <!--            <template v-else>-->
    <!--              Results-->
    <!--            </template>-->
    <!--          </h3>-->
    <!--        </div>-->

    <div class="view_change_btn d-flex align-items-center mb-3">

      <!--v-if="total > 0"-->
      <b-dropdown
        id="dropdown-right"
        variant="studio-theme"
        class="studio-theme-dropdown article-filters-pane__right_section__dropdown mr-2"
        no-caret
      >
        <template v-slot:button-content>
          <i class="cs-trending-chart studio-theme-icon"></i>
          <span class="capitalize_text">{{ getFeedSortText }}</span>
          <i class="icon-dropdown-cs"></i>
        </template>

        <b-dropdown-item
          :active="$route.query.order === 'Recent' || !$route.query.order"
          variant="studio-theme"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, order: 'Recent' },
            })
          "
          >Recent
        </b-dropdown-item>

        <b-dropdown-item
          :active="$route.query.order === 'Oldest'"
          variant="studio-theme"
          @click.prevent="
            $router.replace({ query: { ...$route.query, order: 'Oldest' } })
          "
          >Oldest
        </b-dropdown-item>

      </b-dropdown>

      <div
        class="topic_date_picker d-inline-block align-middle topics-filters-pane__container__right_section__datepicker"
        data-cy="date-picker"
      >
        <span class="d-inline-block mr-2 topics-datepicker px-2 py-[0.45rem] border border-2 border-[#70707029] rounded-lg">
          <DateRangePicker
            ref="feedDatePicker"
            class="datepicker_input"
            :class="{ show: getDatePickerFeedFilter }"
            :opens="dateRangePickerFeed.normal.opens"
            :start-date="dateRangePickerFeed.normal.startDate"
            :end-date="dateRangePickerFeed.normal.endDate"
            :max-date="dateRangePickerFeed.normal.maxDate"
            :min-date="dateRangePickerFeed.normal.minDate"
            :locale-data="dateRangePickerFeed.normal.locale"
            :single-date-picker="dateRangePickerFeed.normal.singleDatePicker"
            :time-picker="dateRangePickerFeed.normal.timePicker"
            :time-picker24hour="dateRangePickerFeed.normal.timePicker24Hour"
            :show-week-numbers="dateRangePickerFeed.normal.showWeekNumbers"
            :show-dropdowns="dateRangePickerFeed.normal.showDropdowns"
            :auto-apply="dateRangePickerFeed.normal.autoApply"
            :ranges="dateRangePickerFeed.normal.ranges"
            @update="changeFeedDateRange"
          >
            <div
              slot="input"
              slot-scope="picker"
              class="datepicker_content discovery_white ml-2"
            >
              <i class="icon-Calendar"></i>

              <span
                v-if="getSearchDatePicker(picker.startDate, picker.endDate)"
              >
                {{ getSearchDatePicker(picker.startDate, picker.endDate) }}
              </span>
              <span
                v-else-if="
                  $route.query.from_date &&
                  getSearchDatePicker(
                    $route.query.from_date,
                    $route.query.to_date,
                  )
                "
              >
                {{
                  getSearchDatePicker(
                    $route.query.from_date,
                    $route.query.to_date,
                  )
                }}
              </span>
              <span v-else-if="picker.label">
                {{ picker.label }}
              </span>
              <span v-else> Last 3 months </span>
              <!--                <i class="icon-dropdown-cs"></i>-->
            </div>
          </DateRangePicker>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import useDateFormat from '@common/composables/useDateFormat'
import DateRangePicker from 'vue2-daterange-picker'
import ContentTotalResults from './ContentTotalResults'

export default {
  components: {
    DateRangePicker,
    ContentTotalResults
  },
  props: {
    total: 0
  },
  setup(){
    const {momentWrapper,getAccountDateTimeFormat} = useDateFormat()
    return {momentWrapper, getAccountDateTimeFormat}
  },
  data () {
    return {
      is_date_picker_loaded: false,
      total_results: 0,
      dateRangePickerFeed: {
        normal: {
          locale: {
            format: this.getAccountDateTimeFormat // fomart of the dates displayed
          },

          opens: 'left',
          startDate:
            this.$route.query?.from_date ||
              this.momentWrapper().subtract('days', 90).formatDate(),
          endDate:
            this.$route.query?.to_date || this.momentWrapper().formatDate(),
          minDate: this.momentWrapper('2023-01-01').formatDate(),
          maxDate: this.momentWrapper().formatDate(),
          dateRange: {
            endDate: this.momentWrapper().formatDate(),
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: true,
          showDropdowns: true,
          autoApply: true,
          showWeekNumbers: true,
          ranges: {
            '24 hours': [this.momentWrapper().subtract(24, 'hours'), this.momentWrapper()],
            '48 hours': [this.momentWrapper().subtract(48, 'hours'), this.momentWrapper()],
            'Last 3 days': [this.momentWrapper().subtract(3, 'day'), this.momentWrapper()],
            'Last 7 Days': [this.momentWrapper().subtract(7, 'days'), this.momentWrapper()],
            'Last 30 Days': [this.momentWrapper().subtract(30, 'days'), this.momentWrapper()],
            'Last 3 Months': [this.momentWrapper().subtract(90, 'days'), this.momentWrapper()],
            'Last 6 Months': [this.momentWrapper().subtract(180, 'days'), this.momentWrapper()],
          }
        }
      }
    }
  },

  computed: {
    getFeedSortText () {
      const feedSort = this.$route.query.order || 'Recent'
      return feedSort.replace('_', ' ')
    },
    getDatePickerFeedFilter () {
      if (this.is_date_picker_loaded) {
        return this.$refs.feedDatePicker.open
      } else {
        return false
      }
    }
  },
  watch: {},
  mounted () {
    this.is_date_picker_loaded = true
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
  },
  methods: {
    closeDatePicker () {
      if (this.$refs.feedDatePicker) {
        this.$refs.feedDatePicker.clickAway()
      }
    },
    changeFeedDateRange (values) {
      console.log('UpdateDateRange', values, this.dateRange, values.startDate)
      console.log('datepicker >', values)

      if (values.startDate && values.endDate) {
        const startDate = this.momentWrapper(values.startDate)
        const endDate = this.momentWrapper(values.endDate)
        this.$router.replace({
          query: {
            ...this.$route.query,
            from_date: startDate.formatDate(),
            to_date: endDate.formatDate(),
          }
        })
        this.$emit('reloadFeedDateRange')
      }
    },
    checkOpen (event) {
      console.log('date range picker event ', event)
    }
  }
}
</script>

<style lang="less">
.feeder-content-filters {
  display: flex;
  justify-content: flex-end;
}
</style>
