<template>
  <div class="quote-grid-view-container__inner">
    <div class="quote-grid-view-container__inner__image_block">
      <div
        :style="{
          backgroundImage: `url('${quote._source.image_url}')`,
        }"
        class="quote-grid-view-container__inner__image"
      ></div>
    </div>
    <div class="quote-grid-view-container__inner__content_block">
      <div class="quote-grid-view-container__inner__content_block__height">
        <p
          class="
            quote-grid-view-container__inner__content_block__quote_description
          "
          v-html="limitTextLength(quote._source.quote, 183)"
        ></p>
        <p
          class="quote-grid-view-container__inner__content_block__quote_author"
        >
          <template v-if="quote._source.author">
            - {{ quote._source.author }}</template
          >
          <template v-else> - Anonymous</template>
        </p>
      </div>
      <div class="quote-grid-view-container__inner__action_buttons">
        <div
          class="btn-studio-theme-white-icon"
          @click="initializeQuotesPostCreation(quote._source)"
        >
          <i class="icon-Share_icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quote: {},
    index: {},
  },
}
</script>
