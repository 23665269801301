<script setup>
import { computed, watch } from 'vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/MainGraph.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const {
  ANALYTICS_DELIMITER,
  multipleSeriesLineChartOptions: lineChartOptions,
  insightsGraphBreakDown,
  legendOptions,
  isReportView,
  dataZoomOptions,
  isNoLabelOrCampaignSelected,
  getChartData,
  tooltipFormatterScroll,
} = useLabelAndCampaign(props.label)

const isNoData = computed(() => {
  const values = Object.values(insightsGraphBreakDown.value)
  if(isNoLabelOrCampaignSelected.value) return false

  return !(
    insightsGraphBreakDown.value && (values?.[0]?.buckets?.length > 0)
  )
})

watch(
  () => [insightsGraphBreakDown, isNoLabelOrCampaignSelected],
  async () => {
    lineChartOptions.value.series = []
    const key = 'engagement'
    const options = getChartData(key)
    options[key].forEach((impression, index) => {
      lineChartOptions.value.series.push(impression)
    })
    lineChartOptions.value.legend = {
      ...legendOptions,
      data: options[key]?.map((chartType) => chartType?.name),
      type: 'scroll',
      formatter: (name) => {
        return name.split(ANALYTICS_DELIMITER)[0]
      },
      width: '80%',
    }
    lineChartOptions.value.color = undefined

    lineChartOptions.value.tooltip.formatter = tooltipFormatterScroll
    lineChartOptions.value.tooltip.enterable = true
    lineChartOptions.value.grid.top = 50

    lineChartOptions.value.xAxis.data = options?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="Engagements-Graph" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Daily Post Engagements Trend
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Analyze the trend of engagements received by posts associated with the specified campaigns and labels over the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox v-if="isLoading" height="350px" width="100%" />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="line"
          :chart-options="
            slotProps.isModal
              ? {
                  ...lineChartOptions,
                  ...dataZoomOptions,
                  grid: { ...lineChartOptions.grid, bottom: 70 },
                }
              : lineChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
