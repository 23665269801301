<script setup>
import { computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { EventBus } from '@common/lib/event-bus'
import SocialPlatform from '@/src/modules/account/views/onboarding/SocialPlatform.vue'
import useViewPort from '@/src/modules/common/composables/useViewPort'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import { useStore } from '@/src/store/base'

import RemoveIntegration from '@/src/modules/integration/components/dialogs/remove/RemoveIntegration.vue'
import useSocialMediaUtils from '@/src/modules/common/composables/useSocialMediaUtils'
import useSocialAccountsModal from '@/src/modules/account/composables/useSocialAccountsModal'
import TwitterIntegrationModal from '@/src/modules/integration/components/dialogs/TwitterIntegrationModal.vue'
import SideDetails from "@modules/account/views/onboarding/SideDetails.vue";

// declarations

const store = useStore()
const route = useRoute()
const { SUPPORTED_PLATFORMS } = useComposerHelper()
const { isLargeScreen } = useViewPort()
const { accountIdentifier } = useSocialMediaUtils()
const {
  selectedPlatform,
  selectedAccount,
  connectTwitterApp,
  processReconnectResponse,
} = useSocialAccountsModal()

// computed properties
const bodyStyles = computed(() => {
  if (isLargeScreen.value) {
    return {
      backgroundImage: `url(${require('@assets/img/onboarding/social_accounts_modal_bg.svg')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 0px',
    }
  } else {
    return {}
  }
})

onMounted(async () => {
  const response = await store.dispatch('checkConnectorState', {
    hash: route.hash,
  })

  await processReconnectResponse(response)

  await store.dispatch('fetchSocialAccounts')
  await store.dispatch('fetchSocialAuthorizationLinks')

  EventBus.$on('refetchSocialPlatforms', async () => {
    await store.dispatch('fetchSocialAccounts', true)
  })
})

onUnmounted(() => {
  EventBus.$off('showNotAllowedModal')
  EventBus.$off('refetchSocialPlatforms')
})
</script>

<template>
  <TwitterIntegrationModal @custom-app-payload="connectTwitterApp" />
  <RemoveIntegration
      :type="selectedPlatform.name"
      :platform="accountIdentifier(selectedAccount, selectedPlatform.name)"
  />

  <div class="flex items-center w-full  lg:justify-between h-full">
    <div class="w-[43.5rem] max-w-[43.5rem] 2xl:w-[57.5rem] 2xl:max-w-[57.5rem] flex flex-col gap-4 bg-gray-100 bg-opacity-80 px-10 pt-8 rounded-tl-2xl h-full">
      <img
          src="../../assets/img/logo/contentstudio-logo-original.png"
          class="w-[130px] 2xl:w-[150px]"
          alt="ContentStudio"
      />
      <SideDetails
          :sub_title="`Connect your social profiles`"
          description="To start sharing your content immediately, connect your social profiles now." />
    </div>
    <div class="w-full mt-4 2xl:mt-10 h-[28rem] 2xl:h-auto overflow-y-scroll 2xl:overflow-y-hidden lg:mt-0 px-20 2xl:px-28 flex">
      <div class="w-full">
        <div>
          <SocialPlatform
              v-for="platform in SUPPORTED_PLATFORMS"
              :key="platform.name"
              :platform="platform"
          />
        </div>
      </div>
    </div>
  </div>
</template>
