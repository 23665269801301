<script setup>
import { computed, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/instagram_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'

// state
const {
  overviewDemographicsData,
  dataZoomOptions,
  barChartOptions: chartOptions,
  isReportView,
  fanCount,

  routes,
  isLoadingStates,
} = useInstagramAnalytics('Demographics Age')

const isNoData = computed(() => {
  const data = overviewDemographicsData.value?.audience_age || {}
  const isAllZero = Object.values(data).every((value) => value === 0)
  return (Object.keys(data).length === 0) || isAllZero
})

watch(
  () => overviewDemographicsData.value,
  () => {
      const options = overviewDemographicsData.value?.audience_age || {}

      chartOptions.value.xAxis.data = Object.keys(options).slice(0, 10)
      chartOptions.value.xAxis.axisLabel.formatter = null;

      const yAxisFirstIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[0]
      : chartOptions.value.yAxis
      const yAxisSecondIndex = Array.isArray(chartOptions.value?.yAxis)
        ? chartOptions.value.yAxis[1]
        : chartOptions.value.yAxis

      chartOptions.value.grid = {
        ...chartOptions.value.grid,
        right: 30,
      }
      chartOptions.value.xAxis = {
        ...chartOptions.value.xAxis,
        type: 'value',
        axisLabel: {
          show: false,
        },
        axisPointer: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        }
      }
      chartOptions.value.yAxis = [
        {
          ...yAxisFirstIndex,
          axisLabel: {
            ...yAxisFirstIndex.axisLabel,
            formatter: (value) => value,
          },
          type: 'category',
          name: '',
          axisPointer: {
            type: 'shadow',
          },
          data: Object.keys(options).slice(0, 10)|| [],
        },
        {
          ...yAxisSecondIndex,
          name: '',
          type: 'category',
          position: 'right',
          axisLabel: {
            ...yAxisSecondIndex.axisLabel,
            formatter: (value) =>
              value !== 'null' ? value : '0',
          },
          axisPointer: {
            type: 'shadow',
            label: {
              show: false,
            },
          },
          data: Object.values(options).slice(0, 10)|| [],
        },
      ]

      chartOptions.value.series[0] = {
        ...chartOptions.value.series[0],
        itemStyle: {},
        data: Object.values(options).slice(0, 10)|| [],
      }
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(() => isLoadingStates.value?.[routes.OVERVIEW_DEMOGRAPHICS])

</script>

<template>
  <AnalyticsCardWrapper type="insta-age-demographics" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Audience by Age
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Displays the distribution of your audience based on age groups. Data shows the number of followers within each age range, providing insights into audience demographics.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="pie"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p v-if="fanCount <= 100">You'll need 100 followers (who aren't your friends) to view this demographic information.</p>
          <p v-else>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
