<script>
import { mapGetters, mapActions } from 'vuex'
import ModelLayout from '../posting/ModelLayout'
import ModelSidebar from '../posting/ModelSidebar'
// import ContentCreationSection from '../posting/blog/ContentCreationSection'
import BlogReviewPublish from '../posting/blog/BlogReviewPublish'
import ImageSection from '../posting/blog/ImageSection'
import BlogDistribute from '../posting/blog/BlogDistribute'
import ContentCreationSectionFeeder from '../posting/blog/ContentCreationSectionFeeder'

export default {
  components: {
    ContentCreationSectionFeeder,
    BlogDistribute,
    ImageSection,
    BlogReviewPublish,
    // ContentCreationSection,
    ModelLayout,
    ModelSidebar
  },
  data () {
    return {
      closeStatus: true,
      clickaway: false
    }
  },
  computed: {
    ...mapGetters([
      'getBlogSharingSelection',
      'getPublishLoaders',
      'getPublishSelection',
      'getDraftCancelAction'
    ])
  },
  created () {
    this.initializePublishSection('Blog')
  },
  mounted () {
    // body overflow issue for multiple model...
    // $('.modal').on('hidden.bs.modal', function (e) {
    //   if ($('.modal:visible').length) {
    //     $('body').addClass('modal-open')
    //   }
    // })
  },


  methods: {
    ...mapActions(['setBlogSharingStage', 'setDraftCancelAction']),
    validateProcessBlogPost (draft = false) {
      console.debug('Method:validateProcessBlogPost')
      // if (this.getBlogSharingSelection.title === '') {
      //   this.validations.postTitle = true
      //   return false
      // }
      if (draft) this.processBlogPost(true, true)
      else this.processBlogPost()
    },
    onhide (event) {
      if (
        this.$router.currentRoute.name !== 'calender_plans' &&
        this.$router.currentRoute.name !== 'list_plans' &&
        this.$router.currentRoute.name !== 'feed_view'
      ) {
        if (!this.getPublishSelection.plan_id && !this.getDraftCancelAction) {
          let validForSave = false
          if (
            this.getBlogSharingSelection.title &&
            this.getBlogSharingSelection.title.length > 0
          ) {
            validForSave = true
          } else if (
            this.blogPrimarySecondarySelectionValidation(false) === true
          ) {
            validForSave = true
          }
          // here we also check about editor value
          if (validForSave) {
            event.preventDefault()
            this.setDraftCancelAction(false)
            this.$bvModal.show('socialConfirmationDialog')
            return false
          }
        }
      }
    },
    closeShareModal () {
      this.closeStatus = false
      setTimeout(() => {
        document.querySelector('body').classList.remove('overflow_hidden')
        this.$router.go(-1)
      }, 300)
    }
  }
}
</script>

<template>
  <div
    class="modal-router social_share_modal blog_share_modal social_share_only top_left_design_box"
  >
    <div
      v-click-away="closeShareModal"
      class="modal-dialog"
      :class="[closeStatus ? 'animated fadeInDown' : 'animated fadeOutUp']"
    >
      <div class="modal-content">
        <ModelLayout type="Blog"></ModelLayout>
        <div class="modal_body" style="min-height: 300px">
          <div
            class="modal_inner_content basic_form blog_steps_common"
            style="overflow: visible"
          >
            <div class="d-flex align-items-stretch">
              <div class="content">
                <div class="steps_arrow_links">
                  <ul>
                    <li
                      :class="{
                        active: getBlogSharingSelection.stage === 'content',
                        disabled:
                          getPublishLoaders.initializeArticlePostCreation,
                      }"
                      @click.prevent="setBlogSharingStage('content')"
                      >Content</li
                    >
                    <li class="arrow_right_gray">
                      <i class="cs-angle-right"></i>
                    </li>
                    <li
                      :class="{
                        active: getBlogSharingSelection.stage === 'settings',
                        disabled:
                          getPublishLoaders.initializeArticlePostCreation,
                      }"
                      @click.prevent="setBlogSharingStage('settings')"
                      >Settings</li
                    >
                    <li class="arrow_right_gray">
                      <i class="cs-angle-right"></i>
                    </li>
                    <li
                      :class="{
                        active:
                          getBlogSharingSelection.stage === 'distribution',
                        disabled:
                          getPublishLoaders.initializeArticlePostCreation,
                      }"
                      @click.prevent="setBlogSharingStage('distribution')"
                      >Distribution</li
                    >
                    <li class="arrow_right_gray">
                      <i class="cs-angle-right"></i>
                    </li>
                    <li
                      :class="{
                        active: getBlogSharingSelection.stage === 'publish',
                        disabled:
                          getPublishLoaders.initializeArticlePostCreation,
                      }"
                      @click.prevent="setBlogSharingStage('publish')"
                      >Review & Publish</li
                    >
                  </ul>
                </div>
                <ContentCreationSectionFeeder
                  :class="{ hide: getBlogSharingSelection.stage !== 'content' }"
                ></ContentCreationSectionFeeder>
                <ImageSection
                  :class="{
                    hide: getBlogSharingSelection.stage !== 'settings',
                  }"
                ></ImageSection>
                <BlogDistribute
                  :class="{
                    hide: getBlogSharingSelection.stage !== 'distribution',
                  }"
                  :variations-options="true"
                ></BlogDistribute>
                <BlogReviewPublish
                  :class="{ hide: getBlogSharingSelection.stage !== 'publish' }"
                  type="discovery"
                ></BlogReviewPublish>
              </div>

              <ModelSidebar
                class="social_post_side_filter_model social_share_model_sidebar"
                type="Blog"
              ></ModelSidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
