import { mapActions, mapGetters } from 'vuex'
import { processContentRewritingUrl } from '@src/modules/publish/config/api-utils'
import { getArticleContentURL } from '@src/modules/discovery/config/api-utils'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import moment from 'moment'
import {
  blogPosting,
  commonTypes,
  publish,
  sharingTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { hashtagsTypes } from '@src/modules/setting/store/states/mutation-types'
import { getDefaultCurrentHashtag } from '@src/modules/setting/store/states/hashtags'
import { getDefaultPublishReplug } from '@src/modules/publish/store/states/main'
import {
  blogIntegrationsNames,
  socialIntegrations,
  socialIntegrationsConfigurations,
} from '@src/modules/integration/config/api-utils'
import {
  getPlatformIdentifierValue,
  getPlatformName,
} from '@src/modules/common/lib/integrations'
import { commonMethods } from '@src/modules/common/store/common-methods'
import {EventBus} from "@common/lib/event-bus";
import {socialChannelsArray, swalAttributes} from '../../constants/common-attributes'
import {
  GMB_INVALID_DATES_ERROR,
  GMB_NULL_END_DATE_ERROR,
  GMB_NULL_START_DATE_ERROR,
  GMB_NULL_TITLE_ERROR,
  GMB_TITLE_LENGTH_ERROR,
  INVALID_GMB_CALL_TO_ACTION_LINK,
  INVALID_GMB_REDEEM_LINK,
  NULL_BLOG_OPTION_PRIMARY_SELECTION_ERROR,
  NULL_BLOG_OPTION_SELECTION_ERROR,
  NULL_GMB_CALL_TO_ACTION,
  NULL_MEDIUM_BLOG_SELECTION_ERROR,
  NULL_REPLUG_BRAND,
  NULL_REPLUG_CAMPAIGN,
  NULL_SOCIAL_ACCOUNT_SELECTION_ERROR,
  NULL_TUMBLR_BLOG_SELECTION_ERROR,
  NULL_WORDPORESS_AUTHOR_SELECTION_ERROR,
  NULL_WORDPORESS_BLOG_SELECTION_ERROR,
  UNKNOWN_ERROR,
  WORDPORESS_BLOG_CONNECTION_ERROR,
  NULL_WEBFLOW_SITE,
  NULL_SHOPIFY_BLOG_SELECTION_ERROR,
  BLOG_CONNECTION_ERROR,
  NULL_SHOPIFY_AUTHOR_SELECTION_ERROR,
} from '../../constants/messages'

export const publishMixin = {
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getAccountSelection',
      'getWorkspaceHashtags',
      'getBlogOptions',
      'getSingleBlogSelection',
      'getAutomationScheduleOptions',
      'getPublishSelection',
      'getCommonBoxOptions',
      'getFacebookSharingDetails',
      'getTwitterSharingDetails',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTiktokSharingDetails',
      'getTumblrSharingDetails',
      'getPublishTimeOptions',
      'getCommonSharingDetails',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getTumblrAccounts',
      'getArticleAutomationDetails',
      'getVideoAutomationDetails',
      'getInstagramAccounts',
      'getInstagramSharingDetails',
      'getInstagramAccounts',
      'getBlogEditorSelector',
      'getRewritingSelection',
      'getChimpRewriterAccount',
      'getSpinRewriterAccount',
      'getQuillbotAccount',
      'getGmbSharingDetails',
      'getYoutubeSharingDetails',
      'getYoutubeOptions',
      'getInstagramPostingOption',
      'getTwitterOptions',
      'getCarouselOptions'
    ]),
    featureFlagYoutube() {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
  },
  methods: {
    ...mapActions([
      'setHashtagEditMode',
      'setInitializeArticlePostCreationLoader',
      'setUtmEditMode',
      'setCurrentUtm',
      'setTwitterRetweetSelection',
      'setPublishPlatformSelection',
      'setPublishPlatformSelectAllStatus',
      'setSidebarStatus',
      'setProcessContentRewritingLoader',
      'setDraftCancelAction',
      'refetchEvergreenAutomation',
      'setEditQueueStatus',
      'setPublishTimeSelection',
    ]),

    blogSelectionValidation() {
      const options = this.getBlogOptions
      const selection = this.getSingleBlogSelection

      console.log(options, selection)

      if (
        options.wordpress.selection === false &&
        options.tumblr === false &&
        options.medium === false &&
        options.shopify.selection === false &&
        options.webflow.selection === false
      ) {
        this.alertMessage(NULL_BLOG_OPTION_SELECTION_ERROR, 'error')
        return false
      }
      if (
        options.wordpress.selection === true &&
        selection.wordpress.website === ''
      ) {
        this.alertMessage(NULL_WORDPORESS_BLOG_SELECTION_ERROR, 'error')
        return false
      }
      if (
        options.wordpress.selection === true &&
        !options.wordpress.siteStatus
      ) {
        this.alertMessage(WORDPORESS_BLOG_CONNECTION_ERROR, 'error')
        return false
      }
      if (
        options.wordpress.selection === true &&
        selection.wordpress.author === ''
      ) {
        this.alertMessage(NULL_WORDPORESS_AUTHOR_SELECTION_ERROR, 'error')
        return false
      }
      if (options.tumblr === true && selection.tumblr.website === '') {
        this.alertMessage(NULL_TUMBLR_BLOG_SELECTION_ERROR, 'error')
        return false
      }
      if (options.medium === true && selection.medium.website === '') {
        this.alertMessage(NULL_MEDIUM_BLOG_SELECTION_ERROR, 'error')
        return false
      }
      if (
        options.shopify.selection === true &&
        selection.shopify.website === ''
      ) {
        this.alertMessage(NULL_SHOPIFY_BLOG_SELECTION_ERROR, 'error')
        return false
      }
      if (options.shopify.selection === true && !options.shopify.site_status) {
        this.alertMessage(BLOG_CONNECTION_ERROR, 'error')
        return false
      }
      if (
        options.shopify.selection === true &&
        selection.shopify.author === ''
      ) {
        this.alertMessage(NULL_SHOPIFY_AUTHOR_SELECTION_ERROR, 'error')
        return false
      }
      if (options.webflow === true && selection.webflow.website === '') {
        this.alertMessage(NULL_WEBFLOW_SITE, 'error')
        return false
      }

      return true
    },

    blogPrimarySecondarySelectionValidation(showMessage = true) {
      const primaryOptions = this.getBlogSelection.primaryBlogOption
      console.debug('primaryOptions', primaryOptions)
      let status = false
      blogIntegrationsNames.forEach((integration) => {
        console.debug('integration', integration)
        if (
          primaryOptions[integration].selection !== undefined &&
          primaryOptions[integration].selection
        ) {
          status = true
          return
        }
        if (
          primaryOptions[integration].selection === undefined &&
          primaryOptions[integration]
        ) {
          status = true
        }
      })

      if (!status && showMessage)
        this.alertMessage(NULL_BLOG_OPTION_PRIMARY_SELECTION_ERROR, 'error')
      return status
    },

    contentRewritingValidation() {
      console.debug('Method:contentRewritingValidation')

      const selection = this.getRewritingSelection

      if (selection.type === 'spin') {
        switch (selection.spinner.type) {
          case 'chimprewriter':
            if (!this.getChimpRewriterAccount) {
              this.alertMessage(
                'Please add ChimpRewriter API Credentials.',
                'error'
              )
              return false
            }
            break
          case 'spinrewriter':
            if (!this.getSpinRewriterAccount) {
              this.alertMessage(
                'Please add SpinRewriter API credentials.',
                'error'
              )
              return false
            }
            break
          case 'quillbot':
            if (!this.getQuillbotAccount) {
              this.alertMessage('Please add Quillbot API credentials.', 'error')
              return false
            }
            break
        }
      }

      return true
    },

    checkEvergreenGmbValidation() {
      if (this.getGmbOptions.topic_type !== 'STANDARD') {
        if (!this.getGmbOptions.start_date) {
          this.alertMessage(GMB_NULL_START_DATE_ERROR, 'error')
          return false
        }
        if (!this.getGmbOptions.end_date) {
          this.alertMessage(GMB_NULL_END_DATE_ERROR, 'error')
          return false
        }
        if (!this.getGmbOptions.title) {
          this.alertMessage(GMB_NULL_TITLE_ERROR, 'error')
          return false
        }
        if (this.getGmbOptions.title.length > 57) {
          this.alertMessage(GMB_TITLE_LENGTH_ERROR, 'error')
          return false
        }
        if (
          moment(this.getGmbOptions.end_date).isBefore(
            moment(this.getGmbOptions.start_date)
          )
        ) {
          this.alertMessage(GMB_INVALID_DATES_ERROR, 'error')
          return false
        }
      }
      if (
        this.getGmbOptions.topic_type !== 'OFFER' &&
        this.getGmbOptions.action_type &&
        this.getGmbOptions.action_type !== 'CALL'
      ) {
        const cta_link = this.getCommonBoxOptions.status
          ? this.getCommonSharingDetails.cta_link
          : this.getGmbSharingDetails.cta_link
        if (!cta_link) {
          this.alertMessage(NULL_GMB_CALL_TO_ACTION, 'error')
          return false
        }
        if (!this.isValidUrl(cta_link)) {
          this.alertMessage(INVALID_GMB_CALL_TO_ACTION_LINK, 'error')
          return false
        }
      }
      if (
        this.getGmbOptions.topic_type === 'OFFER' &&
        this.getGmbOptions.redeem_online_url
      ) {
        if (!this.isValidUrl(this.getGmbOptions.redeem_online_url)) {
          this.alertMessage(INVALID_GMB_REDEEM_LINK, 'error')
          return false
        }
      }
      return true
    },

    socialAccountSelectionValidation(type) {
      console.log("Method::socialAccountSelectionValidation ~ type -> ", type)
      const selection = this.getAccountSelection
      if (
        selection.facebook.length === 0 &&
        selection.twitter.length === 0 &&
        selection.pinterest.length === 0 &&
        selection.linkedin.length === 0 &&
        selection.tumblr.length === 0 &&
        selection.instagram.length === 0 &&
        selection.gmb.length === 0 &&
        selection.youtube.length === 0 &&
        selection.tiktok.length === 0 &&
        selection.threads.length === 0
      ) {
        if (type !== 'EvergreenAutomation')
          this.alertMessage(NULL_SOCIAL_ACCOUNT_SELECTION_ERROR, 'error')
        return false
      }

      if (!this.socialAccountsTokenValidation()) return false

      if (type === 'SocialShare' && selection.gmb.length) {
        if (this.getGmbOptions.topic_type !== 'STANDARD') {
          if (!this.getGmbOptions.start_date) {
            this.alertMessage(GMB_NULL_START_DATE_ERROR, 'error')
            return false
          }
          if (!this.getGmbOptions.end_date) {
            this.alertMessage(GMB_NULL_END_DATE_ERROR, 'error')
            return false
          }
          if (!this.getGmbOptions.title) {
            this.alertMessage(GMB_NULL_TITLE_ERROR, 'error')
            return false
          }
          if (this.getGmbOptions.title.length > 57) {
            this.alertMessage(GMB_TITLE_LENGTH_ERROR, 'error')
            return false
          }
          if (
            moment(this.getGmbOptions.end_date).isBefore(
              moment(this.getGmbOptions.start_date)
            )
          ) {
            this.alertMessage(GMB_INVALID_DATES_ERROR, 'error')
            return false
          }
        }
        if (
          this.getGmbOptions.topic_type !== 'OFFER' &&
          this.getGmbOptions.action_type &&
          this.getGmbOptions.action_type !== 'CALL'
        ) {
          const cta_link = this.getCommonBoxOptions.status
            ? this.getCommonSharingDetails.cta_link
            : this.getGmbSharingDetails.cta_link
          if (!cta_link) {
            this.alertMessage(NULL_GMB_CALL_TO_ACTION, 'error')
            return false
          }
          if (!this.isValidUrl(cta_link)) {
            this.alertMessage(INVALID_GMB_CALL_TO_ACTION_LINK, 'error')
            return false
          }
        }
        if (
          this.getGmbOptions.topic_type === 'OFFER' &&
          this.getGmbOptions.redeem_online_url
        ) {
          if (!this.isValidUrl(this.getGmbOptions.redeem_online_url)) {
            this.alertMessage(INVALID_GMB_REDEEM_LINK, 'error')
            return false
          }
        }
      }
      return true
    },

    /**
     * checking the access token validity for the all accounts selected for posting.
     * @returns {boolean}
     */
    socialAccountsTokenValidation() {
      console.debug('Method::socialAccountsTokenValidation')

      let status = true
      const stateObject = this
      const selection = this.getAccountSelection

      // iterating all integration
      ;[
        'facebook',
        'twitter',
        'linkedin',
        'pinterest',
        'tumblr',
        'instagram',
        'gmb',
      ].forEach((integration) => {
        // checking account selected for the specific itergation or not
        if (selection[integration] && selection[integration].length > 0) {
          // iterating all the selected accounts of a specific integration type
          selection[integration].forEach((account) => {
            // returning if status is already set to false to break the loop for integrations
            if (!status) return status
            const items =
              stateObject[
                'get' +
                  integration.charAt(0).toUpperCase() +
                  integration.slice(1) +
                  'Accounts'
              ].items
            // finding account item from the states
            account = items.find(
              (item) =>
                getPlatformIdentifierValue(item, integration) === account
            )

            // do not show token error message for facebook groups
            // as push notification method is used for fb groups
            if(account && account.facebook_id && account.type === 'Group')
                return

            // showing error message if token is invalid
            if (
              account &&
              account.validity &&
              (account.validity === 'invalid' || account.validity === 'expired')
            ) {
              const name = getPlatformName(account)
              let type = account.type ? _.startCase(account.type) : 'Profile'
              if (integration === 'gmb') type = 'Locations'
              if (account.is_inbox) type = 'Business Profile'
                this.alertMessage(
                  'Access token of the ' +
                    _.startCase(integration) +
                    ' ' +
                    type +
                    ' <b>' +
                    name +
                    ' </b> is invalid. To renew your token, please re-connect the account to ContentStudio.',
                  'error'
                )

              status = false

              // breaking loop so that only one error message visible to the user
              return false
            }
          })
        }
      })
      return status
    },

    publishTimeOptionsValidation() {
      console.debug('Method::publishTimeOptionsValidation')
      const options = this.getPublishTimeOptions

      if (options.time_type === 'schedule') {
        if (options.post_date === '') {
          this.alertMessage('Please select a date to schedule.', 'error')
          return false
        }
      }

      if (
        (options.time_type === 'now' || options.time_type === 'schedule') &&
        options.repeat === 'repeat'
      ) {
        if (options.repeat_times < 1 || options.repeat_times > 99) {
          this.alertMessage('Repeat times must be between 1 to 99.', 'error')
          return false
        }

        if (options.repeat_gap < 1) {
          this.alertMessage('Repeat gap must be greater then 0.', 'error')
          return false
        }
      } else if (options.time_type === 'evergreen') {
        if (options.evergreen === '') {
          this.alertMessage('please select evergreen.', 'error')
          return false
        }
      }

      return true
    },
    // threadedTweetsValidation (status = '', type, details) {
    //   console.debug('threadedtweetsvalidation', status, type, details)
    //   let tweets = this.getThreadedTweetsSharingDetails
    //   let errors = []
    //   if (status === 'required') {
    //     for (let i in tweets) {
    //       if (tweets[i].message.trim() === '' && tweets[i].image.length === 0 && (!tweets[i].video.link || tweets[i].video.link === '')) {
    //         errors.push('Please add something to post')
    //         return errors[0]
    //       }
    //     }
    //   } else {
    //     errors.push(this.socialPostSpecificBoxContentErrors('twitter', details))
    //   }
    //   if (errors && errors.length > 0) return errors[0]
    // },

    socialShareDetailsValidation(type, ignoreMultipleSelection = false) {
      console.debug(
        'Method:socialShareDetailsValidation',
        type,
        this.getCommonBoxOptions.status
      )
      const accountSelection = this.getAccountSelection

      if (this.getCommonBoxOptions.status || type === 'Evergreen') {
        if (type === 'SocialShare') {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.url === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box is empty, please add something to the post.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.facebook.length) {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.url === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on Facebook.',
              'error'
            )
            return false
          }
        }

        if (!ignoreMultipleSelection) {
          if (accountSelection.twitter.length > 1) {
            this.alertMessage(
              'Please select only one X (Twitter) account to continue.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.twitter.length) {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on X (Twitter).',
              'error'
            )
            return false
          }

          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.url
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on X (Twitter).',
              'error'
            )
            return false
          }
        }

        if (accountSelection.linkedin.length) {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.url === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on Linkedin.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.pinterest.length) {
          if (
            this.getCommonSharingDetails.video.link &&
            this.getCommonSharingDetails.image.length === 0
          ) {
            this.alertMessage('You cannot share videos on Pinterest', 'error')
            return false
          } else if (this.getCommonSharingDetails.image.length === 0) {
            this.alertMessage(
              'Please add an image for Pinterest post. It cannot be empty.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.pinterest.length) {
          if (this.getCommonSharingDetails.source_url) {
            if (!this.isValidUrl(this.getCommonSharingDetails.source_url)) {
              this.alertMessage('Please enter a valid source URL.', 'error')
              return false
            }
          }
        }

        if (accountSelection.tumblr.length) {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on Tumblr.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.instagram.length) {
          if (
            this.getCommonSharingDetails.image.length === 0 &&
            (!this.getCommonSharingDetails.video ||
              this.getCommonSharingDetails.video.link === '')
          ) {
            // @waqas::video
            this.alertMessage(
              'Please add an image for Instagram post. It cannot be empty.',
              'error'
            )
            return false
          }
        }

        if (accountSelection.gmb.length) {
          if (
            this.getCommonSharingDetails.message.trim() === '' &&
            this.getCommonSharingDetails.image.length === 0 &&
            this.getCommonSharingDetails.video.link === ''
          ) {
            this.alertMessage(
              'Message box is empty, please add something to post on GMB.',
              'error'
            )
            return false
          }
        }
        if (accountSelection.tiktok.length) {
          if(!this.getCommonSharingDetails.video || this.getCommonSharingDetails.video.link === '') {
            this.alertMessage(
                'TikTok posts require a video file in order to post, please make the changes in order to post successfully.',
                'error'
            )
            return false
          }
        }
        if (accountSelection.youtube.length) {
          if (
            !this.getCommonSharingDetails.video ||
            this.getCommonSharingDetails.video.link === ''
          ) {
            this.alertMessage(
              'Please add a video for Youtube post. It cannot be empty.',
              'error'
            )
            return false
          }

          if (
            !this.getYoutubeOptions.title ||
            this.getYoutubeOptions.title.trim().length === 0
          ) {
            this.alertMessage(
              'Please add Youtube video title for post. It cannot be empty.',
              'error'
            )
            return false
          }
          if (
            this.getYoutubeOptions.title &&
            this.getYoutubeOptions.title.length > 100
          ) {
            this.alertMessage(
              'You cannot add more than 100 characters for Youtube video title.',
              'error'
            )
            return false
          }
        }

        const errors = this.socialPostContentErrors('list', type)
        if (errors && errors.length > 0)
          return this.alertMessage(errors[0], 'error')
      } else {
        if (
          accountSelection.facebook.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.facebook)
        ) {
          if (
            this.getFacebookSharingDetails.message.trim() === '' &&
            this.getFacebookSharingDetails.url === '' &&
            this.getFacebookSharingDetails.image.length === 0 &&
            (!this.getFacebookSharingDetails.video ||
              this.getFacebookSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box for Facebook is empty, please add something to post.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'facebook',
            this.getFacebookSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (!ignoreMultipleSelection) {
          if (accountSelection.twitter.length > 1) {
            this.alertMessage(
              'Please select only one X (Twitter) account to continue.',
              'error'
            )
            return false
          }
        }

        if (
          accountSelection.twitter.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.twitter)
        ) {
          if (
            this.getTwitterSharingDetails.message.trim() === '' &&
            this.getTwitterSharingDetails.image.length === 0 &&
            (!this.getTwitterSharingDetails.video.link ||
              this.getTwitterSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box for X (Twitter) is empty, please add something to post.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'twitter',
            this.getTwitterSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (
          accountSelection.linkedin.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.linkedin)
        ) {
          if (
            this.getLinkedinSharingDetails.message.trim() === '' &&
            this.getLinkedinSharingDetails.url === '' &&
            this.getLinkedinSharingDetails.image === '' &&
            (!this.getLinkedinSharingDetails.video.link ||
              this.getLinkedinSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box for Linkedin is empty, please add something to post.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'linkedin',
            this.getLinkedinSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (
          accountSelection.pinterest.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.pinterest)
        ) {
          if (
            this.getPinterestSharingDetails.image === '' &&
            (!this.getPinterestSharingDetails.video.link ||
              this.getPinterestSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Please add an image for Pinterest post. It cannot be empty.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'pinterest',
            this.getPinterestSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (
          accountSelection.tumblr.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.tumblr)
        ) {
          if (
            this.getTumblrSharingDetails.message.trim() === '' &&
            this.getTumblrSharingDetails.image === '' &&
            (!this.getTumblrSharingDetails.video ||
              this.getTumblrSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Message box for Tumblr is empty, please add something to post.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'tumblr',
            this.getTumblrSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (
          accountSelection.instagram.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.instagram)
        ) {
          if (
            this.getInstagramSharingDetails.image.length === 0 &&
            (!this.getInstagramSharingDetails.video ||
              this.getInstagramSharingDetails.video.link === '')
          ) {
            this.alertMessage(
              'Please add an image/video for Instagram post. It cannot be empty.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'instagram',
            this.getInstagramSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }

        if (
          accountSelection.youtube.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.youtube)
        ) {
          if (
            !this.getYoutubeSharingDetails.video ||
            this.getYoutubeSharingDetails.video.link === ''
          ) {
            this.alertMessage(
              'Please add an video for Youtube post. It cannot be empty.',
              'error'
            )
            return false
          }
          if (
            !this.getYoutubeOptions.title ||
            this.getYoutubeOptions.title.trim().length === 0
          ) {
            this.alertMessage(
              'Please add Youtube video title for post. It cannot be empty.',
              'error'
            )
            return false
          }
          if (
            this.getYoutubeOptions.title &&
            this.getYoutubeOptions.title.length > 100
          ) {
            this.alertMessage(
              'You cannot add more than 100 characters for Youtube video title.',
              'error'
            )
            return false
          }
        }

        if (
          accountSelection.gmb.length ||
          (this.isGlobalContentCategorySelected &&
            this.getCommonBoxOptions.globalCategoryChannels.gmb)
        ) {
          if (
            this.getGmbSharingDetails.message.trim() === '' &&
            this.getGmbSharingDetails.image.length === 0 &&
            this.getCommonSharingDetails.video.link === ''
          ) {
            this.alertMessage(
              'Message box for Google my business is empty, please add something to post.',
              'error'
            )
            return false
          }

          const errors = this.socialPostSpecificBoxContentErrors(
            'gmb',
            this.getGmbSharingDetails
          )
          if (errors && errors.length > 0)
            return this.alertMessage(errors[0], 'error')
        }
      }

      if (
        type !== 'Evergreen' &&
        this.getPublishTimeOptions.time_type === 'content_category' &&
        !this.getPublishSelection.content_category_id
      ) {
        this.alertMessage(
          'Please select content category, it cannot be empty if you would like to add content to the category.',
          'error'
        )
        return false
      }

      return true
    },

    socialPostContentErrors(required, type) {
      console.debug('Method::socialPostContentErrors', required, type)
      let errors = []
      const stateObject = this
      let status = false
      if (required === 'status' && this.getTwitterOptions.has_threaded_tweets) {
        const tweets = this.getThreadedTweetsSharingDetails
        for (const i in tweets) {
          console.log('ThreadedTweetsError', tweets[i], i)
          errors = errors.concat(
            stateObject.socialPostSpecificBoxContentErrors('twitter', tweets[i])
          )
          if (errors && errors.length > 0) {
            status = true
            return status
          }
        }
      }
      // carousel errors are handled
      if(this.carouselErrors().errors.length > 0) {
        status = true
        return status
      }
      if (this.getCommonBoxOptions.status || type === 'evergreen') {
        const limits = this.remainingCharactersCommon()
        if (limits.remaining < 0)
          errors.push(
            'You have exceeded the characters limit for '+ limits.type+', please reduce your content.'
          )
        socialIntegrations.forEach((integration) => {
          errors = errors.concat(
            stateObject.socialPostSpecificBoxContentErrors(
              integration,
              stateObject.getCommonSharingDetails
            )
          )
        })
        if (errors && errors.length > 0)
          return required === 'status' ? true : errors
        return required === 'status' ? false : errors
      }
      socialIntegrations.forEach((integration) => {
        const platformName =
          integration.charAt(0).toUpperCase() + integration.slice(1)
        errors = errors.concat(
          stateObject.socialPostSpecificBoxContentErrors(
            integration,
            stateObject[`get${platformName}SharingDetails`]
          )
        )
        if (errors && errors.length > 0) {
          status = true
          return status
        }
      })
      return required === 'status' ? status : errors
    },

    carouselErrors () {
      console.debug("Method: CarouselErrors:", this.getCarouselOptions)
      const warnings = []
      const errors = []
      const errorsIndexes = []
      if(this.getCarouselOptions && this.getCarouselOptions.is_carousel_post) {
        if (this.getCarouselOptions.cards.length < 2) {
          errors.push('You need to have at least 2 cards in a carousel post excluding end card.')
        }
        if (this.getCarouselOptions.cards.length > 10) {
          errors.push('You can have a maximum of 10 cards in a carousel post.')
        }
        if (this.getCommonSharingDetails.image.length >= 10 || this.getFacebookSharingDetails.image.length >= 10) {
          warnings.push('You can have a maximum of 10 cards in a carousel post.')
        }
        if (this.getCarouselOptions.cards.length > 1) {
          this.getCarouselOptions.cards.forEach((card, index) => {
            if (!card.picture) {
              errors.push(`Card ${index + 1} has no image.`)
            }
            if (!card.url) {
              errors.push(`Card ${index + 1} has no URL (URL is required).`)
              errorsIndexes.push(index)
            } else if (!this.isValidURL(card.url)) {
              errors.push(`Card ${index + 1} has invalid URL.`)
              errorsIndexes.push(index)
            }
            if (!card.name) {
              warnings.push(`Card ${index + 1} has no title.`)
            }
            // if (card.name && card.name.length > 35) {
            //   warnings.push(`Card ${index + 1} title is too long (Title will be typically truncated after 35 characters).`)
            // }
            // if (card.description && card.description.length > 30) {
            //   warnings.push(`Card ${index + 1} description is too long (Description will be typically truncated after 30 characters).`)
            // }

          })
        }
        if (this.getCarouselOptions.end_card) {
          if (!this.getCarouselOptions.end_card_url) {
            errors.push(`End card requires a URL, this is usually the Facebook page link the post is going out to.`)
          } else if (!this.isValidURL(this.getCarouselOptions.end_card_url)) {
            errors.push(`End card has invalid URL.`)
          }
        }
      }
      return {
        warnings,
        errors,
        errorsIndexes
      }
    },

    socialPostSpecificBoxContentErrors(type, details) {
      console.debug('Method::socialPostSpecificBoxContentErrors', type, details)
      const errors = []
      const platformName = type.charAt(0).toUpperCase() + type.slice(1)
      const selectedPlatforms = this.getAccountSelection
      const mediaDetails = this.getSocialSharingMediaDetails

      // checking specific type account is selected
      if (
        selectedPlatforms &&
        selectedPlatforms[type] &&
        selectedPlatforms[type].length
      ) {
        // checking characters limit for posting
        if (
          details.facebook_url === undefined &&
          socialIntegrationsConfigurations[type] &&
          socialIntegrationsConfigurations[type].validations.characters
        ) {
          if (this.remainingCharacters(platformName) < 0) {
            errors.push(
              'You cannot add more than ' +
                socialIntegrationsConfigurations[type].validations.characters +
                ' characters for ' +
                platformName +
                ' Posting.'
            )
          }
        }

        // checking for instagram hashtag limits
        if (type === 'instagram') {
          if (this.checkInstaHash()) errors.push(this.checkInstaHash())
        }
        // checking for the image/gif validations
        if (details.image && socialIntegrationsConfigurations[type]) {
          const images =
            details.image.constructor === Array
              ? details.image
              : [details.image]

          images.every((image, index) => {
            // checking for the image validations
            if (
              mediaDetails[image] &&
              mediaDetails[image].mime_type !== 'image/gif'
            ) {
              if (socialIntegrationsConfigurations[type].validations.image) {
                if (
                  mediaDetails[image].size >
                  socialIntegrationsConfigurations[type].validations.image
                    .max_size
                ) {
                  const imageMessage =
                    details.image.constructor === Array &&
                    details.image.length > 1
                      ? socialIntegrationsConfigurations[type].validations.image
                          .max_size_multiple_images_error
                      : socialIntegrationsConfigurations[type].validations.image
                          .max_size_single_image_error
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(imageMessage)
                    }
                  } else {
                    errors.push(imageMessage)
                  }

                  return false
                }

                if (
                  mediaDetails[image].size <
                  socialIntegrationsConfigurations[type].validations.image
                    .min_size
                ) {
                  const imageMessage =
                    details.image.constructor === Array &&
                    details.image.length > 1
                      ? socialIntegrationsConfigurations[type].validations.image
                          .min_size_multiple_images_error
                      : socialIntegrationsConfigurations[type].validations.image
                          .min_size_single_image_error
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(imageMessage)
                    }
                  } else {
                    errors.push(imageMessage)
                  }
                  return false
                }

                if (
                  index === 0 &&
                  (mediaDetails[image].height <
                    socialIntegrationsConfigurations[type].validations.image
                      .min_height ||
                    mediaDetails[image].width <
                      socialIntegrationsConfigurations[type].validations.image
                        .min_width)
                ) {
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(
                        details.image.constructor === Array &&
                          details.image.length > 1
                          ? socialIntegrationsConfigurations[type].validations
                              .image.min_dimension_multiple_images_error
                          : socialIntegrationsConfigurations[type].validations
                              .image.min_dimension_single_image_error
                      )
                    }
                  } else {
                    errors.push(
                      details.image.constructor === Array &&
                        details.image.length > 1
                        ? socialIntegrationsConfigurations[type].validations
                            .image.min_dimension_multiple_images_error
                        : socialIntegrationsConfigurations[type].validations
                            .image.min_dimension_single_image_error
                    )
                  }
                  return false
                }

                if (
                  mediaDetails[image].height >
                    socialIntegrationsConfigurations[type].validations.image
                      .max_height ||
                  mediaDetails[image].width >
                    socialIntegrationsConfigurations[type].validations.image
                      .max_width
                ) {
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(
                        details.image.constructor === Array &&
                          details.image.length > 1
                          ? socialIntegrationsConfigurations[type].validations
                              .image.max_dimension_multiple_images_error
                          : socialIntegrationsConfigurations[type].validations
                              .image.max_dimension_single_image_error
                      )
                    }
                  } else {
                    errors.push(
                      details.image.constructor === Array &&
                        details.image.length > 1
                        ? socialIntegrationsConfigurations[type].validations
                            .image.max_dimension_multiple_images_error
                        : socialIntegrationsConfigurations[type].validations
                            .image.max_dimension_single_image_error
                    )
                  }
                  return false
                }
              }
            }

            // checking for the gif validations
            if (
              mediaDetails[image] &&
              mediaDetails[image].mime_type === 'image/gif'
            ) {
              if (socialIntegrationsConfigurations[type].validations.gif) {
                if (
                  mediaDetails[image].size >
                  socialIntegrationsConfigurations[type].validations.gif.size
                ) {
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(
                        socialIntegrationsConfigurations[type].validations.gif
                          .size_error
                      )
                    }
                  } else {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.gif
                        .size_error
                    )
                  }
                  return false
                }

                if (
                  mediaDetails[image].height >
                    socialIntegrationsConfigurations[type].validations.gif
                      .max_height ||
                  mediaDetails[image].width >
                    socialIntegrationsConfigurations[type].validations.gif
                      .max_width
                ) {
                  if (type === 'instagram') {
                    if (this.getInstagramPostingOption === 'api') {
                      errors.push(
                        socialIntegrationsConfigurations[type].validations.gif
                          .max_dimension_error
                      )
                    }
                  } else {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.gif
                        .max_dimension_error
                    )
                  }
                  return false
                }
              }
            }

            if (
              type === 'instagram' &&
              this.getInstagramPostingOption === 'api' &&
              mediaDetails[image] &&
              mediaDetails[image].zapier &&
              !mediaDetails[image].zapier.status
            ) {
              errors.push(
                details.image.constructor === Array && details.image.length > 1
                  ? socialIntegrationsConfigurations[type].validations.image
                      .min_dimension_multiple_images_error
                  : socialIntegrationsConfigurations[type].validations.image
                      .min_dimension_single_image_error
              )
              return false
            }

            return true
          })
        }

        // checking for the video validations
        if (
          details.video &&
          details.video.link &&
          mediaDetails[details.video.link] &&
          socialIntegrationsConfigurations[type] &&
          socialIntegrationsConfigurations[type].validations.video
        ) {
          if (
            mediaDetails[details.video.link].size >
            socialIntegrationsConfigurations[type].validations.video.size
          )
            errors.push(
              socialIntegrationsConfigurations[type].validations.video
                .size_error
            )
          if (
            mediaDetails[details.video.link].duration_seconds >
            socialIntegrationsConfigurations[type].validations.video
              .max_duration
          ) {
            const mediaSize = mediaDetails[details.video.link].duration_seconds
            const message =
              socialIntegrationsConfigurations[type].validations.video
                .max_duration_error
            const dynamicMessage =
              ' Your video is ' +
              mediaSize +
              ' seconds. Please reduce the length of this video.'
            if (type === 'instagram') {
              if (this.getInstagramPostingOption === 'api') {
                errors.push(message.concat(dynamicMessage))
              }
            } else {
              errors.push(message.concat(dynamicMessage))
            }
          }
          if (
            mediaDetails[details.video.link].duration_seconds <
            socialIntegrationsConfigurations[type].validations.video
              .min_duration
          )
            errors.push(
              socialIntegrationsConfigurations[type].validations.video
                .min_duration_error
            )

          if (
            type === 'instagram' &&
            this.getInstagramPostingOption === 'api'
          ) {
            const media = details.video
            const validations =
              socialIntegrationsConfigurations.instagram.validations.video

            // should be AAC
            if (
              media.audio_codec &&
              media.audio_codec.toLowerCase() !== validations.audio_codec
            ) {
              errors.push(
                'For Instagram API, audio codec for the video needs to be AAC, ' +
                  media.audio_codec +
                  ' provided.'
              )
            }

            // should be less than or equal to 48khz
            if (
              media.audio_hertz &&
              media.audio_hertz > validations.audio_hertz_max
            ) {
              errors.push(
                `For Instagram API, audio codec for the video needs to be max ${validations.audio_hertz_max}khz, ${media.audio_hertz}khz provided.`
              )
            }

            // can either be h264 or hecv
            if (
              media.video_codec &&
              !(
                media.video_codec.toLowerCase() ===
                  validations.video_codec_h264 ||
                media.video_codec.toLowerCase() === validations.video_codec_hecv
              )
            ) {
              errors.push(
                'For Instagram API, video codec needs to either be H264 or HECV, ' +
                  media.video_codec +
                  ' provided.'
              )
            }

            // should be between 23 to 60 fps
            if (
              media.frame_rate &&
              (media.frame_rate < validations.frame_rate_min ||
                media.frame_rate > validations.frame_rate_max)
            ) {
              errors.push(
                'For Instagram API, video framerate needs to be between 23fps and 60fps, ' +
                  media.frame_rate +
                  'fps provided.'
              )
            }
          }
        }

        // // checking in case of indirect publishing
        // if (socialIntegrationsConfigurations[type] && socialIntegrationsConfigurations[type].validations.indirect_publishing && socialIntegrationsConfigurations[type].validations.indirect_publishing.status) {
        //   if (this.hasIndirectIntegrationError(type)) {
        //     errors.push(selectedPlatforms[type].length > 1 ? socialIntegrationsConfigurations[type].validations.indirect_publishing.integration_multiple_accounts_error : socialIntegrationsConfigurations[type].validations.indirect_publishing.integration_single_account_error)
        //   }
        // }
      }
      return errors
    },

    hasIndirectIntegrationError(type) {
      console.debug('Method::hasIndirectIntegrationError', type)
      switch (type) {
        case 'instagram':
          if (this.$store.getters.getMobileDevices.length > 0) return false
          let status = true
          this.getAccountSelection[type].forEach((account) => {
            account = this.$store.getters.getInstagramAccounts.items.find(
              (item) => {
                return item.instagram_id === account
              }
            )
            status = !(account && account.linked_to && account.linked_to.zapier)
            if (status) return status
          })
          return status
      }
      return false
    },

    socialPostContentWarnings(required, type) {
      console.debug('Method::socialPostContentWarnings', required, type)
      let warnings = []
      const stateObject = this
      let status = false
      const selectedPlatforms = this.getAccountSelection
      console.log("Method::socialPostContentWarnings ~ selectedPlatforms", selectedPlatforms)

      if (this.getCommonBoxOptions.status || type === 'evergreen') {
        const details = stateObject.getCommonSharingDetails
        if (details.video && details.video.link ) {
          warnings.push(
            'You can add video only on Facebook, X (Twitter), Linkedin, Tumblr, Youtube, GMB, TikTok and Instagram.'
          )
        }
        if (selectedPlatforms.tiktok.length) {
          warnings.push(
              'Captions and Links added will not be posted on TikTok'
          )
        }
        if (details.mentions.length > 0)
          warnings.push('You can only tag Facebook pages and X (Twitter) handles.')

        if (
          (selectedPlatforms.twitter.length ||
            selectedPlatforms.linkedin.length ||
            selectedPlatforms.gmb.length ||
            selectedPlatforms.tumblr.length) &&
          details.video.link !== ''
        ) {
          warnings.push(
            'You can add custom video thumbnail only on Facebook, LinkedIn and Youtube.'
          )
        }

        socialIntegrations.forEach((integration) => {
          warnings = warnings.concat(
            stateObject.socialPostSpecificBoxContentWarnings(
              integration,
              details
            )
          )
        })
        if (warnings && warnings.length > 0)
          return required === 'status' ? true : warnings
        return required === 'status' ? false : warnings
      }

      socialIntegrations.forEach((integration) => {
        const platformName =
          integration.charAt(0).toUpperCase() + integration.slice(1)
        warnings = warnings.concat(
          stateObject.socialPostSpecificBoxContentWarnings(
            integration,
            stateObject[`get${platformName}SharingDetails`]
          )
        )
        if (warnings && warnings.length > 0) {
          status = true
          return status
        }
      })

      return required === 'status' ? status : warnings
    },

    socialPostSpecificBoxContentWarnings(type, details) {
      console.debug(
        'Method::socialPostSpecificBoxContentWarnings',
        type,
        details
      )
      const warnings = []
      let platformName = type.charAt(0).toUpperCase() + type.slice(1)
      platformName =
        platformName === 'Gmb' ? 'Google my business' : platformName
      const selectedPlatforms = this.getAccountSelection
      const mediaDetails = this.getSocialSharingMediaDetails

      // checking specific type account is selected
      if (
        selectedPlatforms &&
        selectedPlatforms[type] &&
        selectedPlatforms[type].length &&
        socialIntegrationsConfigurations[type].warnings
      ) {
        if (
          socialIntegrationsConfigurations[type].warnings.repeat_warning &&
          this.getPublishTimeOptions.time_type !== 'queued' &&
          this.getPublishTimeOptions.time_type !== 'evergreen' &&
          this.getPublishTimeOptions.repeat === 'repeat'
        ) {
          warnings.push(
            'Repeat posting are not allowed on ' + platformName + '.'
          )
        }

        // checking for the image/gif warnings
        if (details.image && socialIntegrationsConfigurations[type]) {
          const images =
            details.image.constructor === Array
              ? details.image
              : [details.image]
          if (
              socialIntegrationsConfigurations[type].warnings &&
              socialIntegrationsConfigurations[type].warnings.image &&
              socialIntegrationsConfigurations[type].warnings.image.max_images &&
              images.length > socialIntegrationsConfigurations[type].warnings.image.max_images
          ){
            warnings.push(
                socialIntegrationsConfigurations[type].warnings.image
                    .max_images_warning
            )}
          images.every((image, index) => {
            if (
              mediaDetails[image] &&
              mediaDetails[image].mime_type !== 'image/gif'
            ) {
              if (socialIntegrationsConfigurations[type].warnings.image) {
                if (
                  mediaDetails[image].height >
                    socialIntegrationsConfigurations[type].warnings.image
                      .max_height ||
                  mediaDetails[image].width >
                    socialIntegrationsConfigurations[type].warnings.image
                      .max_width
                ) {
                  warnings.push(
                    details.image.constructor === Array &&
                      details.image.length > 1
                      ? socialIntegrationsConfigurations[type].warnings.image
                          .max_dimension_multiple_images_warning
                      : socialIntegrationsConfigurations[type].warnings.image
                          .max_dimension_single_image_warning
                  )
                  return false
                }
              }
            }

            if (
              mediaDetails[image] &&
              mediaDetails[image].mime_type === 'image/gif'
            ) {
              if (socialIntegrationsConfigurations[type].warnings.gif) {
                if (
                  mediaDetails[image].height >
                    socialIntegrationsConfigurations[type].warnings.gif
                      .max_height ||
                  mediaDetails[image].width >
                    socialIntegrationsConfigurations[type].warnings.gif
                      .max_width
                ) {
                  warnings.push(
                    socialIntegrationsConfigurations[type].warnings.gif
                      .max_dimension_warning
                  )
                  return false
                }
              }
            }

            return true
          })
        }
      }
      return warnings
    },

    socialShareDraftValidation(showMessage = true) {
      const accountSelection = this.getAccountSelection

      if (this.getCommonBoxOptions.status) {
        if (
          this.getCommonSharingDetails.message.trim() === '' &&
          this.getCommonSharingDetails.url === '' &&
          this.getCommonSharingDetails.image.length === 0 &&
          (!this.getCommonSharingDetails.video ||
            this.getCommonSharingDetails.video.link === '')
        ) {
          if (showMessage)
            this.alertMessage(
              'Message box is empty, please add something to save.',
              'error'
            )
          return false
        }
      } else {
        let hasContent = false
        if (accountSelection.facebook.length) {
          if (
            this.getFacebookSharingDetails.message.trim() ||
            this.getFacebookSharingDetails.url ||
            this.getFacebookSharingDetails.image.length > 0 ||
            (this.getFacebookSharingDetails.video &&
              this.getFacebookSharingDetails.video.link)
          ) {
            hasContent = true
          }
        }

        if (accountSelection.twitter.length) {
          if (
            !hasContent &&
            (this.getTwitterSharingDetails.message.trim() ||
              this.getTwitterSharingDetails.image.length > 0 ||
              (this.getTwitterSharingDetails.video &&
                this.getTwitterSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (accountSelection.linkedin.length) {
          if (
            !hasContent &&
            (this.getLinkedinSharingDetails.message.trim() ||
              this.getLinkedinSharingDetails.url ||
              this.getLinkedinSharingDetails.image.length > 0 ||
              (this.getLinkedinSharingDetails.video &&
                this.getLinkedinSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (accountSelection.pinterest.length) {
          if (
            !hasContent &&
            (this.getPinterestSharingDetails.image.length > 0 ||
              this.getPinterestSharingDetails.message.trim() ||
              (this.getPinterestSharingDetails.video &&
                this.getPinterestSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (accountSelection.tumblr.length) {
          if (
            !hasContent &&
            (this.getTumblrSharingDetails.message.trim() ||
              this.getTumblrSharingDetails.image.length > 0 ||
              (this.getTumblrSharingDetails.video &&
                this.getTumblrSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (accountSelection.instagram.length) {
          if (
            !hasContent &&
            (this.getInstagramSharingDetails.image.length === 0 ||
              this.getInstagramSharingDetails.message.trim() ||
              (this.getInstagramSharingDetails.video &&
                this.getInstagramSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (accountSelection.youtube.length) {
          if (
            !hasContent &&
            this.getYoutubeSharingDetails.video &&
            this.getYoutubeSharingDetails.video.link
          ) {
            hasContent = true
          }
        }

        if (accountSelection.tiktok.length) {
          if (
              !hasContent &&
              this.getTiktokSharingDetails.video &&
              this.getTiktokSharingDetails.video.link
          ) {
            hasContent = true
          }
        }

        if (accountSelection.gmb.length) {
          if (
            !hasContent &&
            (this.getGmbSharingDetails.message.trim() ||
              this.getGmbSharingDetails.image.length > 0 ||
                (this.getGmbSharingDetails.video &&
                    this.getGmbSharingDetails.video.link))
          ) {
            hasContent = true
          }
        }

        if (!hasContent) {
          if (showMessage)
            this.alertMessage(
              'Message boxs are empty, please add something to save.',
              'error'
            )
          return false
        }
      }

      return true
    },

    automationScheduleOptionsValidation(type) {
      const selection = this.getAutomationScheduleOptions
      if (selection.time_type === 'Custom') {
        const stateObject = this
        if (
          selection.custom.days[0].status === false &&
          selection.custom.days[1].status === false &&
          selection.custom.days[2].status === false &&
          selection.custom.days[3].status === false &&
          selection.custom.days[4].status === false &&
          selection.custom.days[5].status === false &&
          selection.custom.days[6].status === false
        ) {
          this.alertMessage('Please select some time day(s).', 'error')
          return false
        }

        if (selection.custom.days[0].status === true) {
          if (selection.custom.days[0].times.length === 0) {
            this.alertMessage('Please select some time(s) for sunday.', 'error')
            return false
          }

          // loop on times for validation
          let status = true

          $(selection.custom.days[0].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for sunday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[1].status === true) {
          if (selection.custom.days[1].times.length === 0) {
            this.alertMessage('Please select some time(s) for monday.', 'error')
            return false
          }

          // loop on times for validation
          let status = true

          $(selection.custom.days[1].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for monday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[2].status === true) {
          if (selection.custom.days[2].times.length === 0) {
            this.alertMessage(
              'Please select some time(s) for tuesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          $(selection.custom.days[2].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for tuesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[3].status === true) {
          if (selection.custom.days[3].times.length === 0) {
            this.alertMessage(
              'Please select some time(s) for wednesday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          $(selection.custom.days[3].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for wednesday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[4].status === true) {
          if (selection.custom.days[4].times.length === 0) {
            this.alertMessage(
              'Please select some time(s) for thursday.',
              'error'
            )
            return false
          }

          // loop on times for validation
          let status = true

          $(selection.custom.days[4].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for thursday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[5].status === true) {
          if (selection.custom.days[5].times.length === 0) {
            this.alertMessage('Please select some time(s) for friday.', 'error')
            return false
          }
          // loop on times for validation
          let status = true

          $(selection.custom.days[5].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for friday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
        if (selection.custom.days[6].status === true) {
          if (selection.custom.days[6].times.length === 0) {
            this.alertMessage(
              'Please select some time(s) for saturday.',
              'error'
            )
            return false
          }
          // loop on times for validation
          let status = true

          $(selection.custom.days[6].times).each(function (index, el) {
            if (!stateObject.maskingValidationStatus(el)) {
              stateObject.alertMessage(
                'Please Enter valid time value for saturday time box ' +
                  (index + 1) +
                  '.',
                'error'
              )
              status = false
              return false
            }
          })

          if (!status) {
            return false
          }
        }
      }

      if (selection.schedule_type === 'fix') {
        if (selection.start_time === '') {
          this.alertMessage('Please select start date.', 'error')
          return false
        }

        let automation = null
        if (type === 'ArticleAutomation') {
          automation = this.getArticleAutomationDetails
        } else {
          automation = this.getVideoAutomationDetails
        }

        if (
          (automation.id === '' ||
            automation.remove_posts === true ||
            type === 'evergreen') &&
          this.timePassingCheck(selection.start_time)
        ) {
          this.alertMessage(
            'Starting time has passed.Please change start time.',
            'error'
          )
          return false
        }
      }

      return true
    },

    changeUtmSelectionById(utm) {
      console.debug('Method::changeUtmSelectionById', utm)
      const stateObject = this
      if (utm) {
        this.getUtms.items.forEach(function (item) {
          if (item._id === utm) {
            if (item.auto_add_social_channel) {
              // @jalib:explain this to me @waqas -> Here of item.auto_add_social_channel is true then item.source = name of the social channel
              item.source = ''
            }
            stateObject.setCurrentUtm(JSON.parse(JSON.stringify(item)))
            return false
          }
        })
      } else {
        stateObject.setCurrentUtm([])
      }
      this.setUtmEditMode(false)
    },

    changeHashtagSelectionById(hashtag) {
      console.debug('Method::changeHashtagSelectionById', hashtag)
      const hashtagPayload = getDefaultCurrentHashtag()
      if (hashtag) {
        const hashtagItem = this.getWorkspaceHashtags.items.find(
          (item) => item._id === hashtag
        )
        if (hashtagItem) Object.assign(hashtagPayload, hashtagItem)
      }
      this.$store.commit(
        hashtagsTypes.SET_CURRENT_HASHTAG_VALUE,
        JSON.parse(JSON.stringify(hashtagPayload))
      )
      this.setHashtagEditMode(false)
    },

    replugValidation() {
      console.debug('Method:replugValidation')

      if (this.getReplug.item._id) {
        const details = this.getPublishSelection.replug
        if (!details.brand) {
          this.alertMessage(NULL_REPLUG_BRAND, 'error')
          return false
        } else if (!details.campaign) {
          this.alertMessage(NULL_REPLUG_CAMPAIGN, 'error')
          return false
        }
      } else {
        this.alertMessage('You have not connected replug yet.', 'error')
        return false
      }

      return true
    },

    labelFilterChange() {
      console.debug('Method:labelFilterChange')

      const stateObject = this

      setTimeout(function () {
        const name = stateObject.$route.name
        if (
          name === 'calender_plans' ||
          name === 'list_plans' ||
          name === 'feed_view'
        ) {
          stateObject.$store.dispatch('refetchPlans', stateObject.$route)
        } else if (name === 'composer') {
          stateObject.$store.commit(
            composer.SET_PUBLICATIONS_LABELS,
            JSON.parse(JSON.stringify(stateObject.getPublishSelection.label))
          )
          stateObject.refetchPublications()
        }
      }, 200)
    },

    platformFilterChange(filterType, selectionType) {
      console.debug('Method:platformChange', filterType, selectionType)

      if (selectionType === 'All') {
        const platforms = []
        if (this.getPublishSelection.platforms.check_all) {
          // blog channel filter

          if (filterType === 'Blog') {
            blogIntegrationsNames.forEach((integration) => {
              const accounts = this.$store.getters.getBlogs[integration].items
              platforms.push(
                ...accounts.map((item) => item.platform_identifier)
              )
            })
          }
          // channel contains three values, channel name, property and getter.
          socialChannelsArray.forEach((channel) => {
            console.log('[select all] social channels', channel)
            const accounts = this.$store.getters[channel.getter]
            const accountsList = accounts.hasOwnProperty('items')
              ? accounts.items
              : accounts
            platforms.push(...accountsList.map((item) => item[channel.key]))
          })
          console.log(platforms)
        }
        this.$store.commit(publish.SET_PLATFORMS_SELECTION, platforms)

        if (platforms.length === 0) {
          this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, false)
        }
      } else {
        let totalPlatforms = 0
        // social channel total count

        socialChannelsArray.forEach((channel) => {
          console.log('[platform count] social channel', channel)
          const accounts = this.$store.getters[channel.getter]
          const accountsList = accounts.hasOwnProperty('items')
            ? accounts.items
            : accounts
          totalPlatforms += accountsList.length
        })

        // blog channels total count

        if (filterType === 'Blog') {
          blogIntegrationsNames.forEach((integration) => {
            totalPlatforms +=
              this.$store.getters.getBlogs[integration].items.length
          })
        }
        console.log('[platform count] total > ', totalPlatforms)

        // select the value

        const allSelected =
          this.getPublishSelection.platforms.selection.length === totalPlatforms
        this.$store.commit(publish.SET_PLATFORMS_CHECK_ALL_STATUS, allSelected)
      }

      const stateObject = this
      setTimeout(function () {
        const name = stateObject.$route.name
        if (name === 'articleBlogAutomationListing') {
          stateObject.$store.dispatch('fetchArticleToBlogAutomations')
        } else if (name === 'articleSocialAutomationListing') {
          stateObject.$store.dispatch('fetchArticleToSocialAutomations')
        } else if (name === 'videoBlogAutomationListing') {
          stateObject.refetchVideoBlogAutomations()
        } else if (name === 'videoSocialAutomationListing') {
          stateObject.refetchVideoSocialAutomations()
        } else if (name === 'rssAutomationListing') {
          stateObject.$store.dispatch('fetchRSSAutomationsList')
        } else if (name === 'evergreenAutomationListing') {
          stateObject.$store.dispatch('refetchEvergreenAutomation')
        } else if (
          name === 'calender_plans' ||
          name === 'list_plans' ||
          name === 'feed_view'
        ) {
          stateObject.$store.dispatch('refetchPlans', stateObject.$route)
        }
      }, 200)
      if (this.$route.path.includes('/planner/calender')) {
        setTimeout(function () {
          $('[data-toggle="tooltip"]').tooltip()
        }, 1000)
      }
    },

    moveToReplugConnection() {
      console.debug('Method::moveToReplugConnection')
      this.setDraftCancelAction(true)
      this.$bvModal.hide('social_share_modal')
      this.$router.push({
        name: 'integrations',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    },

    processReplugSelection(replug) {
      if (replug.brand && replug.campaign) {
        const selection = getDefaultPublishReplug()
        const brand = this.getReplug.brands.find(
          (brand) => brand._id === replug.brand
        )
        if (brand) {
          const campaign = brand.campaign.find(
            (campaign) => campaign._id === replug.campaign
          )
          if (campaign) {
            selection.status = true
            selection.brand = brand
            selection.campaign = campaign
          }
        }
        this.$store.commit(publish.SET_PUBLISH_REPLUG_VALUE, selection)
      }
    },

    async initializeArticlePostCreation(id, modal, isDraftPost = false, planId = '') {
      console.debug('Method:initializeArticlePostCreation')

      this.resetBlogSocialDetail()
      this.setInitializeArticlePostCreationLoader(true)
      this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'content')

      const payload = {
        id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }

      if (modal === 'social_share_modal') {
        let res = false
        if(isDraftPost) {
          const options = {...swalAttributes(), modalClass: 'sweet-alert-confirmation fade sweet-alert-minimize-composer', okVariant: 'primary', hideHeader: false}
          res = await this.$bvModal.msgBoxConfirm(
              'You have a post currently in the composer that is minimized. What would you like to do?',
              {
                title: '⚠️ Unfinished Post is in the Composer!',
                ...options,
                cancelTitle: 'Save & Create New',
                okTitle: 'Return to Composer',
              }
          )
        }
        if(res === null) {
          return
        } else if(!res) {
          payload.variations = true
          EventBus.$emit('reset-composer-data')
          EventBus.$emit('publication-composer-post-loader', true)
          this.$bvModal.show('composer-modal')
        } else {
          const workspace = this.$route.params.workspace
          history.pushState({}, null, `/${workspace}/composer/${planId}`)
          this.$bvModal.show('composer-modal')
          return
        }
      } else {
        this.$bvModal.show(modal)
      }

      // if (modal === 'social_share_modal') {
      //   this.setSidebarStatus('preview')
      // }

      // reset variation index and values
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)

      this.postRequest(
          getArticleContentURL,
          payload,
          (response) => {
            if (response.data.status === true) {
              if (modal === 'blog_share_modal') {
                this.initializeBlogPost(response.data.post, 'Discovery Article')
              } else {
                if (response.data.variations_status) {
                  const stateObject = this
                  const allVariation = [response.data.post._source.title]
                  $(response.data.twitter_variations).each(function (index, el) {
                    const variation = stateObject.removeHtmlTags(response.data.twitter_variations[index])
                    if (variation && variation.length <= 250 &&
                        allVariation.indexOf(variation) < 0)
                      allVariation.push(variation)
                  })
                  this.$store.commit(commonTypes.SET_TWITTER_VARIATION, allVariation)
                }
                EventBus.$emit('publication-composer-post', {
                  mode: 'discovery-post',
                  id,
                  options: response.data.post._source,
                })
                // this.initializeSocialShare(response.data.post._source, id) // old code
              }
            } else {
              this.alertMessage(
                  'Unable to get Post details. Please try again.',
                  'error'
              )
              this.$bvModal.hide(modal)
            }
            this.setInitializeArticlePostCreationLoader(false)
          },
          (response) => {
            console.debug('Exception in initializeArticlePostCreation ', response)
            this.setInitializeArticlePostCreationLoader(false)
            this.alertMessage(UNKNOWN_ERROR, 'error')
            this.$bvModal.hide(modal)
          }
      )
    },

    initializeVideoPostCreation(post, modal) {
      console.debug('Method:initializeVideoPostCreation', post, modal)

      // reset variation index and values
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)

      this.resetBlogSocialDetail()

      if (modal === 'blog_share_modal') {
        this.$bvModal.show(modal)
        this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'content')

        const stateObject = this
        setTimeout(function () {
          stateObject.initializeBlogPost(post, 'Discovery Video')
        }, 200)
      } else {
        EventBus.$emit('publication-composer-post-loader', true)
        this.$bvModal.show('composer-modal')

        EventBus.$emit('publication-composer-post', {
          mode: 'discovery-post',
          options: {
            _id: post.id,
            title: post.snippet.title,
            url: 'https://www.youtube.com/watch?v=' + post.id,
          },
        })

      }
    },

    checkFacebookPostType(post) {
      if (
        post.link &&
        post.link.includes('https://www.facebook.com/') &&
        post.link.includes('/videos/')
      ) {
        return 'Video'
      } else if (
        post.name === 'Timeline Photos' ||
        (post.link &&
          post.link.includes('https://www.facebook.com/') &&
          post.link.includes('/photos/'))
      ) {
        return 'Photo'
      } else if (post.caption !== undefined && post.caption.length !== 0) {
        return 'Link'
      } else if (post.name === undefined) {
        if (post.link === undefined || post.link.length <= 0) {
          return 'Status'
        } else {
          if (
            post.link &&
            post.link.includes('https://www.facebook.com/') &&
            post.link.includes('/photos/')
          ) {
            return 'Photo'
          } else if (post.caption !== undefined && post.caption.length !== 0) {
            return 'Link'
          }
        }
      }
    },

    initializeFacebookPostCreation(post, modal) {
      console.debug('Method:initializeFacebookPostCreation', post)
      // reset variation index and values

      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)

      const type = this.checkFacebookPostType(post)
      let message = ''
      if (this.limitTextLength(post.message, 0)) {
        message = this.limitTextLength(post.message, 0)
      }

      this.setSidebarStatus('preview')

      if (type === 'Video' && modal === 'blog_share_modal') {
        this.$bvModal.show(modal)
        this.$store.commit(blogPosting.SET_BLOG_POSTING_STAGE, 'content')
        this.initializeBlogPost(post, 'Discovery Facebook')
      } else {
        EventBus.$emit('publication-composer-post-loader', true)
        this.$bvModal.show('composer-modal')
        let options = {}
        if (type === 'Photo') {
          options = {
            title: message,
            image: post.full_picture,
          }
        }
        if (type === 'Status') {
          options = {title: message}
        }
        if (type === 'Video' || type === 'Link') {
          options = {title: message, url: post.link}
        }
        EventBus.$emit('publication-composer-post', {
          mode: 'discovery-post',
          options,
        })
      }
    },

    initializeQuotesPostCreation(post) {
      console.debug('Method:initializeQuotesPostCreation', post)
      // reset variation index and values
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)

      let message = this.getDecodedHtml(post.quote)
      let image = ''
      if (post && post.image_url) {
        image = post.image_url
      }

      if (post.author) {
        message += ' ' + post.author
      }
      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')
      EventBus.$emit('publication-composer-post', {
        mode: 'discovery-post',
        options: {title: message, image},
      })
      // this.$bvModal.show('social_share_modal')
      // this.initializeSocialShare({ title: message, image: image })
    },

    initializeTwitterPostCreation(post) {
      console.debug('Method:initializeTwitterPostCreation', post)
      // reset variation index and values
      this.$store.commit(commonTypes.RESET_TWITTER_VARIATIONS)

      this.setSidebarStatus('preview')
      EventBus.$emit('publication-composer-post-loader', true)
      this.$bvModal.show('composer-modal')
      let options = {}
      if (post.entities.media && post.entities.media[0]) {
        options = {
          title: post.text,
          image: post.entities.media[0].media_url,
        }
      } else {
        options = {title: post.text, image: ''}
      }

      EventBus.$emit('publication-composer-post', {
        mode: 'discovery-post',
        options,
      })

    },

    initializeTwitterRetweet(post) {
      console.debug('Method:initializeTwitterRetweet')

      let username = ''
      if (post.user.screen_name) {
        username = post.user.screen_name
      }

      this.setTwitterRetweetSelection({
        retweetId: post.id_str,
        message: post.text,
        username,
        comment: '',
      })
    },

    /**
     * this method will process content rewriting.
     */
    processContentRewriting() {
      console.debug('Method:processContentRewriting')

      const description = this.getBlogPostingHTML
      if (!this.removeHtmlTags(description)) {
        this.alertMessage('Please add some description first.', 'error')
        return false
      }

      if (!this.contentRewritingValidation()) {
        return false
      }

      this.$store.commit('SET_REWRITING_PROCESSED', 0)
      this.$store.commit('SET_REWRITING_TOTAL', 0)
      this.setProcessContentRewritingLoader(true)
      this.postRequest(
        processContentRewritingUrl,
        {
          description,
          rewriting_selection: this.getRewritingSelection,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          if (response.data.status === true) {
            this.setCKEditorHtml(response.data.description, true)
            $('#contentRewritingModal').modal('hide')
          } else {
            this.alertMessage(response.data.error_message, 'error')
          }
          this.setProcessContentRewritingLoader(false)
          this.$store.commit('SET_REWRITING_PROCESSED', 0)
          this.$store.commit('SET_REWRITING_TOTAL', 0)
        },
        (error) => {
          console.debug('Exception in processContentRewriting', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setProcessContentRewritingLoader(false)
          this.$store.commit('SET_REWRITING_PROCESSED', 0)
          this.$store.commit('SET_REWRITING_TOTAL', 0)
        }
      )
    },

    editQueuePost() {
      this.$store.commit('setEditQueueStatus', false)
      this.setPublishTimeSelection(null)
    },
  },
}
