<template>
  <div class="flex flex-col h-full items-center">
    <div v-if="showLogo" class="mx-auto mb-4">
      <img
        src="../assets/img/logo/contentstudio-logo-original.png"
        width="210"
        alt="ContentStudio"
      />
    </div>

    <Carousel
      class="featureSlider__carousel w-full"
      :per-page="1"
      :autoplay="true"
      :autoplay-timeout="5000"
      :loop="true"
      pagination-active-color="#005fd0"
      pagination-color="rgba(58, 69, 87, 0.2)"
      :pagination-padding="4"
    >
      <slot name="slides" />
    </Carousel>
  </div>
</template>

<script>
import { Carousel } from '@jambonn/vue-concise-carousel'

export default {
  name: 'FeaturesSlider',
  components: {
    Carousel,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .feature-img-container {
  max-width: 56rem;
  max-height: 570px;
  overflow: hidden;
  @media (max-height: 848px) {
    @apply max-w-screen-md;
  }
  @media (max-height: 768px) {
    @apply max-w-screen-sm;
  }
  img {
    max-height: 570px;
    object-fit: contain;
  }
}
</style>
