<template>
  <div class="calendar-time">
    <select v-model="hour" class="hourselect">
      <option
        v-for="h in hours"
        :key="h"
        :disabled="checkTimeDisable(h)"
        :value="h"
        >{{ $filters.formatNumber(h) }}</option
      >
    </select>
    :
    <select v-model="minute" class="minuteselect">
      <option
        v-for="m in minutes"
        :key="m"
        :disabled="checkTimeMintDisable(m)"
        :value="m"
        >{{ $filters.formatNumber(m) }}</option
      >
    </select>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  filters: {
    formatNumber: (value) => {
      if (value < 10) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
  props: {
    minuteIncrement: {
      type: Number,
      default: 5,
    },
    hour24: {
      type: Boolean,
      default: true,
    },
    secondPicker: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      default() {
        return moment()
      },
    },
    timezone: {
      default() {
        return 'UTC'
      },
    },
    minDate: {
      default() {
        return moment()
      },
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const hours = this.currentTime.hours()
    return {
      hour: this.hour24 ? hours : hours % 12 || 12,
      minute:
        this.currentTime.minutes() -
        (this.currentTime.minutes() % this.minuteIncrement),
      second: this.currentTime.seconds(),
      ampm: hours < 12 ? 'AM' : 'PM',
    }
  },
  computed: {
    hours() {
      const values = []
      const max = this.hour24 ? 24 : 12
      for (let i = 0; i < max; i++) {
        values.push(this.hour24 ? i : i + 1)
      }
      return values
    },
    minutes() {
      const values = []
      const max = 60
      for (let i = 0; i < max; i = i + this.minuteIncrement) {
        values.push(i)
      }
      return values
    },
  },
  watch: {
    hour() {
      this.onChange()
    },
    minute() {
      this.onChange()
    },
    second() {
      this.onChange()
    },
    ampm() {
      this.onChange()
    },
  },
  methods: {
    getHour() {
      if (this.hour24) {
        return this.hour
      } else {
        if (this.hour === 12) {
          return this.ampm === 'AM' ? 0 : 12
        } else {
          return this.hour + (this.ampm === 'PM' ? 12 : 0)
        }
      }
    },
    onChange() {
      this.$emit('update', {
        hours: this.getHour(),
        minutes: this.minute,
        seconds: this.second,
      })
    },
    checkTimeDisable(hour) {
      const todaysDate = moment.utc().tz(this.timezone)
      if (
        todaysDate.date() === this.currentTime.date() &&
        todaysDate.month() === this.currentTime.month() &&
        todaysDate.year() === this.currentTime.year()
      ) {
        if (todaysDate.hour() > hour) {
          return true
        }
      }

      if (this.secondary) {
        const min = moment(this.minDate)
        if (
          min.date() === this.currentTime.date() &&
          min.month() === this.currentTime.month() &&
          min.year() === this.currentTime.year()
        ) {
          if (min.hour() > hour) {
            return true
          }
        }
      }
      return false
    },
    checkTimeMintDisable(mint) {
      const todaysDate = moment.utc().tz(this.timezone)
      if (
        todaysDate.date() === this.currentTime.date() &&
        todaysDate.month() === this.currentTime.month() &&
        todaysDate.year() === this.currentTime.year()
      ) {
        if (todaysDate.minutes() + 5 > mint && this.hour <= todaysDate.hour()) {
          return true
        }
      }
      if (this.secondary) {
        const min = moment(this.minDate)
        if (
          min.date() === this.currentTime.date() &&
          min.month() === this.currentTime.month() &&
          min.year() === this.currentTime.year()
        ) {
          if (min.minutes() + 5 > mint && this.hour <= min.hour()) {
            return true
          }
        }
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped></style>
