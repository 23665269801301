<template>
  <div>
    <div class="filter grid grid-cols-2 gap-1 w-100">
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{sort}}
        </template>
        <template v-slot>
          <CstDropdownItem  @click="handleSort('Viral')">
            Viral
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSort('Top')">
            Top
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSort('Time')">
            Time
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSort('Rising')">
            Rising
          </CstDropdownItem>
        </template>
      </CstDropdown>
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{section}}
        </template>
        <template v-slot>
          <CstDropdownItem  @click="handleSection('Hot')">
            Hot
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSection('Top')">
            Top
          </CstDropdownItem>
          <CstDropdownItem  @click="handleSection('User')">
            User
          </CstDropdownItem>
        </template>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length === 0" class="p-3" >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template  v-else-if="posts && posts.length > 0">
        <div class="grid grid-cols-2 gap-2 mt-2">
          <template v-for="(post, index) in posts" >
            <div
v-if="post.url"
                 :key="index"
                 :draggable="true"
                  class="w-100 rounded-lg bg-cs-white border hover:bg-cs-foggray hover:shadow-sm"
                 @dragstart="dragstartHandler($event, post)">
                    <div
                        class="img w-100 h-32 relative bg-cover bg-no-repeat bg-center rounded-tl-xl rounded-tr-xl bg-cs-white  cursor-pointer"
                        :style="{ 'background-image': 'url(' + post.url + ')' }"
                        @click="handleImagePreview(post.url)">
                      <span class="w-100 h-100 rounded-tl-xl rounded-tr-xl flex items-center justify-center opacity-0"><i class="far fa-eye text-lg text-white"></i></span>
                    </div>
                      <p  class="text-sm mt-0 ml-1 py-1">
                        {{ limitTextLength(post.title, 20) }}
                      </p>
                    <hr class="m-1">
                    <div class="grid grid-cols-2 py-2">
                      <div v-tooltip.top="'Views'" class="flex items-center justify-center">
                        <p>
                          <i class="cs-eye"></i>
                        </p>
                        <h3 class="text-sm ml-1">{{ intToString(post['views']) }}</h3>
                      </div>
                      <div v-tooltip.top="'Score'" class="flex items-center justify-center">
                        <p>
                          <i class="cs-star"></i>
                        </p>
                        <h3 class="text-sm ml-1">{{ intToString(post['score']) }}</h3>
                      </div>
                    </div>
            </div>
          </template>
        </div>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template v-slot:no-results>
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
      </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
export default {
  name: "Imgur",
  components: {
    InfiniteLoading,
    CstDropdown,
    CstDropdownItem,
    NoResult
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sort: {
      type: String,
      default: ''
    },
    section:{
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch:{

  },
  methods:{
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'image',
            heading: '',
            media: photo.url,
            p: photo.title || '',
            link: photo.url
          })
      )
    },
    // photoImgurImageURL (photo) {
    //   if (photo.url && photo.url.indexOf('http://i.imgur.com/') == 0) {
    //     return photo.url
    //   }
    //   return false
    // },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleSort (value) {
      this.$emit('handle-change','sort', value)
    },
    handleSection (value) {
      this.$emit('handle-change','section', value)
    },
    handleImagePreview(photo){
      this.$emit('handle-change','preview',photo)
    },
  }
}
</script>

<style lang="scss" scoped>
.img:hover{
  span{
    background: #00000037;
    opacity: 1!important;
  }
}
</style>
