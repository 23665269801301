<template>
  <div ref="spline-chart-container" class="h-chart" style="height: 100%">
    <highcharts :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
import useDateFormat from "@common/composables/useDateFormat";
export default {
  name: 'SplineBasicChart',
  components: {
    highcharts
  },
  props: {
    categories: {},
    series: {}
  },
  setup(){
    const { momentWrapper } = useDateFormat()
    return {
      momentWrapper
    }
  },
  data () {
    return {
      dataOptions: {}
    }
  },
  computed: {
    formattedCategories () {
      return this.categories.map((item) => {
        return this.momentWrapper(item).formatDate()
      })
    }
  },
  mounted () {
    this.initializeDataOptions()
    this.$nextTick(function () {
      this.dataOptions.chart.height =
        this.$refs['spline-chart-container'].offsetHeight - 32
    })
  },
  methods: {
    initializeDataOptions () {
      this.dataOptions = {
        title: {
          text: ' '
        },
        chart: {
          type: 'spline',
          height: this.$refs['spline-chart-container'].offsetHeight - 32
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        xAxis: {
          categories: this.formattedCategories,
          crosshair: true
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false
            }
          },
          series: {
            marker: {
              radius: 6,
              symbol: 'circle',
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            },
            shadow: true,
            lineWidth: 4
          }
        },
        series: this.series
      }
    }
  }
}
</script>
