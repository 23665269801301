<template>
  <div class="cs-limits-upsell-container">
    <div class="row-close-modal">
      <button type="button" class="close" @click="$router.go(-1)"
        >&times;</button
      >
    </div>
    <addons></addons>
    <h3 class="tagline">Choose an option to upgrade your limits!</h3>
    <template v-if="starterDeadlineTimeOffer && loaded">
      <p class="tagline-text"
        ><span class="upsell-off-bold-text"
          >50% OFF Special Offer - Expires in</span
        ></p
      >
      <div class="cs-limits-upsell-countdown-timer">
        <VueCountdown
          v-slot="{ days, hours, minutes, seconds }"
          :time="new Date(starterDeadlineTimeOffer)"
        >
          Time Remaining：{{ days }} days, {{ hours }} hours,
          {{ minutes }} minutes, {{ seconds }} seconds.
        </VueCountdown>
      </div>
    </template>
    <div class="cs-limits-upsell-container-box">
      <div class="cs-limits-box">
        <h4>Increase individual limits via add-ons</h4>
        <p>
          Using this option, you can increase your
          <span class="font-weight-500"
            >workspaces, social accounts, blogs and team members</span
          >
          without moving to a higher plan.
        </p>
        <div class="upgrade-btn-row">
          <button
            class="btn large_btn gradient_btn btn_loader loader_right"
            @click.prevent="showIndividualLimitsModal"
            ><span>Select</span></button
          >
        </div>
      </div>
      <div class="cs-limits-box">
        <h4>Upgrade to a higher plan</h4>
        <p
          >Using this option, you can upgrade to a higher plan and get
          <span class="font-weight-500">all the benefits and features</span>
          available in the higher plans.</p
        >
        <div class="upgrade-btn-row">
          <button
            class="btn large_btn gradient_btn btn_loader loader_right"
            @click.prevent="showUpgradeHigherPlanModal"
            ><span>Select</span></button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpgradePlanDialog from '@src/modules/setting/components/billing/dialogs/UpgradePlanDialog'
import moment from 'moment'
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'LimitsUpsell',
  components: {
    VueCountdown,
    UpgradePlanDialog,
  },
  data() {
    return {
      // added for the countdown timer, as they were showing 00:00:00, now after 0.5 second reload, they show
      // the proper date.
      loaded: false,
    }
  },

  computed: {
    starterDeadlineTimeOffer() {
      const profile = this.$store.getters.getProfile
      console.log('starter deadline', profile.created_at)
      const now = moment.utc().format('YYYY-MM-DD HH:mm')
      const days = this.checkTimeDifference(profile.created_at, now, 'days')
      console.log('starter deadline', days)
      console.log(
        'starter deadline',
        moment(profile.created_at)
          .add(14, 'days')
          .add(Math.random() * 11, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss')
      )
      return days < 14
        ? moment(profile.created_at)
            .add(14, 'days')
            .add(Math.random(), 'seconds')
            .format('YYYY-MM-DD HH:mm:ss')
        : false
    },
  },
  created() {
    this.loaded = false
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 500)
  },
  methods: {
    showIndividualLimitsModal() {
      this.$bvModal.show('increase-limits-dialog')
      this.$bvModal.hide('upgrade-plan-dialog')
    },
    showUpgradeHigherPlanModal() {
      this.$bvModal.hide('increase-limits-dialog')
      this.$bvModal.show('upgrade-plan-dialog')
    },
  },
}
</script>

<style lang="less">
@import (reference) '../../../../../../assets/less/basic/_mixins';
@import (reference) '../../../../../../assets/less/basic/_colors';
@import (reference) '../../../../../../assets/less/basic/_classes';

.row-close-modal {
  position: absolute;
  top: 0;
  right: 0;

  button {
    margin-top: 5px;
    margin-right: 0.625rem;
    padding: 10px 20px;
    font-size: 36px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    -webkit-box-shadow: 0 2px 6px 0 @ds-lightGray_7;
    -moz-box-shadow: 0 2px 6px 0 @ds-lightGray_7;
    box-shadow: 0 2px 6px 0 @ds-lightGray_7;
  }
}

.cs-limits-upsell-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  align-items: center;

  .tagline {
    width: 100%;
    padding: 20px 30px;
    text-align: center;
  }

  .cs-limits-upsell-container-box {
    width: 1040px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    .cs-limits-box {
      /*height: 200px;*/
      background: #fff;
      width: 50%;
      margin: 0 15px;
      .white_box_with_shadow_radius;

      h4 {
        text-align: center;
        padding: 30px 0 15px 0;
        color: @ds-darkGray_2;

        i {
          padding-right: 0.625rem;
        }

        .space-shuttle-rotate {
        }
      }

      p {
        text-align: center;
        padding: 10px 30px;
        line-height: 20px;
      }

      .upgrade-btn-row {
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 10px 0;
        margin-bottom: 20px;
      }
    }
  }

  .tagline-text {
    padding: 15px 0;
    font-size: 1.125rem;
  }

  .cs-limits-upsell-countdown-timer {
    margin-bottom: 15px;
  }

  .upsell-off-bold-text {
    padding: 5px 6px;
    border-radius: 4px;
    background: @ds-yellow;
    color: #fff;
    font-weight: 600;
  }
}
</style>
