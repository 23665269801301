<template>
  <div class="article-list-view-container__inner">
    <div class="article-list-view-container__inner__image_section">
      <div
        v-if="post.categories"
        class="article-list-view-container__inner__categories"
      >
        <span
          v-for="(category, index) in post.categories"
          :key="index"
          class="tag capitalize"
          >{{ category }}</span
        >
      </div>
      <div
        :style="{ backgroundImage: `url(${backgroundImageURL(post)})` }"
        class="article-list-view-container__inner__image"
      ></div>

      <div
        v-if="post.shared && post.shared_count"
        class="article-list-view-container__inner__shared"
      >
        <span>
          Shared to {{ post.shared_count }}
          <template v-if="post.shared_count === 1">channel</template>
          <template v-else>channels</template>
        </span>
      </div>
    </div>

    <div class="article-list-view-container__inner__content_section">
      <div
        class="article-list-view-container__inner__content_section__left_section"
      >
        <h2
          :title="limitTextLength(post.title, 0)"
          @click.prevent="routeToPreview"
          v-html="limitTextLength(post.title, 100)"
        ></h2>
        <div
          class="clear article-list-view-container__inner__content_section__left_section__details"
        >
          <p class="text_row">
            <a href="javascript:;">{{ trimURL(post.url_netloc) }}</a>

            <!--<a class="left_link" href="">Insight</a>
                                <a class="left_link" href="">All Articles</a>
                                <a class="left_link" href="">Block Globally</a>-->
          </p>
          <p class="text_row">{{ $filters.relative(post.post_date) }}</p>
          <p
            class="article-list-view-container__inner__content_section__left_section__author_information"
          >
            <template v-if="post.author">
              By <span style="color: #4e92ff" v-html="post.author"></span>
              <a
                v-if="post.twitter_handler"
                target="_blank"
                :href="getTwitterLink(post.twitter_handler)"
              >
                {{ post.twitter_handler }}</a
              >
              <a
                v-for="(account, index) in post.twitter_accounts"
                v-else-if="post.twitter_accounts && index < 1"
                target="_blank"
                :href="getTwitterLink(account)"
              >
                {{ account }}</a
              >
            </template>
            <template v-else-if="post.twitter_handler">
              By
              <a target="_blank" :href="getTwitterLink(post.twitter_handler)">{{
                post.twitter_handler
              }}</a>
            </template>
            <template
              v-else-if="
                post.twitter_accounts && post.twitter_accounts.length > 0
              "
            >
              By
              <a
                v-for="(account, twitterAccountsIndex) in post.twitter_accounts"
                :key="`twitterAccountsIndex_${twitterAccountsIndex}`"
                target="_blank"
                :href="getTwitterLink(account)"
                ><template v-if="post.twitter_accounts && twitterAccountsIndex < 1">{{ account }}</template></a
              >
            </template>
          </p>
          <div
            class="article-list-view-container__inner__content_section__left_section__topics"
          >
            <template v-for="item in post.topics">
              <span
                v-for="(finalized_topic, index) in checkIfMultipleTopics(item)"
                :key="index"
                class="topic_tag"
              >
                {{ finalized_topic }}
              </span>
            </template>
          </div>

          <div
            v-if="post.top_twitter_influencers"
            class="article-list-view-top-influencers"
          >
            <div
              v-if="post.total_influencers_shares"
              class="article-list-view-top-influencers__left"
            >
              <p
                ><i class="icon-profile-cs mr-2"></i> Shared by
                <strong>{{
                  $filters.numberToCommas(post.total_influencers_shares)
                }}</strong>
                influencers</p
              >
            </div>
            <div v-if="post.total_influencers_shares > 0" class="ml-3">
              <template
                v-for="(influencer, index) in post.top_twitter_influencers"
              >
                <a
                  v-tooltip="{
                    content: accountTooltipHtml('influencer', influencer),
                    classes: 'team_tooltip',
                  }"
                  target="_blank"
                  :href="'https://twitter.com/' + influencer.screen_name"
                >
                  <div
                    v-if="influencer.profile_image"
                    class="img"
                    :style="{
                      background: 'url(' + influencer.profile_image + ')',
                    }"
                  ></div>
                  <div
                    v-else
                    class="img"
                    style="
                      background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                    "
                  ></div>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="article-list-view-container__inner__content_section__right_section"
      >
        <div
          class="article-list-view-container__inner__content_section__right_section__action_buttons"
        >
          <!-- share buttons -->
          <b-dropdown
            id="dropdown-share-articles"
            variant="studio-icon-theme"
            class="studio-icon-theme-dropdown mr-3"
            dropleft
            no-caret
          >
            <template v-slot:button-content>
              <i
                v-tooltip="'Share this post'"
                class="far fa-share-alt icon"
              ></i>
              <b-badge
                v-if="post.shared_count"
                pill
                variant="light"
                class="ml-2"
                >{{ post.shared_count }}</b-badge
              >
            </template>
            <b-dropdown-item
                variant="studio-icon-theme"
                @click.prevent="shareToSocial(post.record_id)"
              ><i class="far fa-share"></i> Social Media
            </b-dropdown-item>
            <b-dropdown-item
              v-show="false"
              variant="studio-icon-theme"
              @click.prevent="routeToBlogPost(post.record_id)"
              ><i class="far fa-rss"></i> Blog Post
            </b-dropdown-item>
          </b-dropdown>

          <!-- favorite article button -->
          <div
            class="dropdown b-dropdown studio-icon-theme-dropdown mr-3 btn-group"
          >
            <template v-if="post.is_starred">
              <button
                class="btn btn-studio-icon-theme"
                style="margin-bottom: 0.5rem"
                @click.prevent="
                  EventBus.$emit('feeder_starred_action', {
                    record_id: post.record_id,
                    index: index,
                    state: 'unstarred',
                  })
                "
              >
                <i
                  v-tooltip="'Unfavorite'"
                  class="far fa-heart icon is-favorite"
                ></i>
                <span
                  :class="{ ' active': post.is_starred }"
                  class="icon_animate"
                ></span>
              </button>
            </template>
            <template v-else>
              <button
                class="btn btn-studio-icon-theme"
                style="margin-bottom: 0.5rem"
                @click.prevent="
                  EventBus.$emit('feeder_starred_action', {
                    record_id: post.record_id,
                    index: index,
                    state: 'starred',
                  })
                "
              >
                <i v-tooltip="'Favorite'" class="far fa-heart icon"></i>
              </button>
            </template>
          </div>

          <!-- archive article button -->
          <button
            id="dropdown-archive-articles"
            class="btn btn-studio-icon-theme post_archi_btn"
            style=""
          >
            <template v-if="isArchive">
              <i
                v-tooltip.top="'Unarchive Post'"
                class="far fa-archive icon is-archived"
                @click="
                  EventBus.$emit('feeder_unarchive_action', {
                    record_id: post.record_id,
                    index: index,
                    domain_uuid: post.domain_uuid,
                    state: 'unarchive',
                  })
                "
              ></i>
            </template>
            <template v-else>
              <i
                v-tooltip.top="'Archive Post'"
                class="far fa-archive icon"
                @click="
                  EventBus.$emit('feeder_archive_action', {
                    record_id: post.record_id,
                    index: index,
                    domain_uuid: post.domain_uuid,
                    state: 'archive',
                  })
                "
              ></i>
            </template>
          </button>

          <!-- more actions button-->
          <b-dropdown
            id="dropdown-actions-articles"
            variant="studio-icon-theme"
            dropleft
            class="studio-icon-theme-dropdown"
            no-caret
          >
            <template v-slot:button-content>
              <i
                v-tooltip.top="'More options'"
                class="cs-dots-h icon"
              ></i>
            </template>

            <b-dropdown-item
              variant="studio-icon-theme"
              target="_blank"
              :href="savePocketContent(post.url)"
              ><i class="fab fa-get-pocket"></i> Add to Pocket
            </b-dropdown-item>

            <b-dropdown-item
              v-show="false"
              v-if="getGridViewTotalInfluencers"
              variant="studio-icon-theme"
              @click.prevent="showSharers(post.id)"
              ><i class="fa fa-users-crown"></i> View Influencers
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from "@common/lib/event-bus";
import { feederItemMixin } from '../../../mixins/feeder-item-mixin'
import FeederSentimentIcon from '../feed-item/FeederSentimentIcon'
import FeederSharedCountTooltip from '../feed-item/FeederSharedCountTooltip'

export default {
  components: {
    FeederSentimentIcon,
    FeederSharedCountTooltip
  },
  mixins: [feederItemMixin],
  props: {
    post: {},
    index: {},
    isArchive: { default: false },
    route_name: {}
  },
  computed: {
    ...mapGetters([]),
    EventBus() {
      return EventBus
    }
  },
  methods: {
    ...mapActions([]),
    routeToShareSocial () {
      const payload = {
        name: `${this.route_name}_social_share`,
        params: { post_id: this.post.id },
        query: { ...this.$route.query, module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },
    routeToBlogPost (postId) {
      const payload = {
        name: 'aggregate_feed_item_blog_post',
        params: { post_id: postId },
        query: { module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    },
    routeToPreview () {
      const payload = {
        name: `${this.route_name}_preview`,
        params: { post_id: this.post.record_id },
        query: { ...this.$route.query, module: 'feeds' }
      }
      console.log(payload)
      this.$router.push(payload)
    }
  }
}
</script>

<style>
.listview-items {
  transition: all 600ms ease-in-out 50ms;
}

.listview-items-enter-active,
.listview-items-leave-active {
  transition: all 0.6s;
}

.listview-items-enter, .listview-items-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(45px);
}
</style>
