<template>
  <div>
    <header class="h-[60px] bg-[#005fd0] flex items-center px-4 py-2">
      <a href="https://app.contentstudio.io/" target="_blank">
        <img
            src="https://contentstudio.io/images/Logo%20White.svg"
            alt="ContentStudio"
            width="130"
        />
      </a>
    </header>

    <div
        v-if="state.loading && !state.loadingMore"
        class="p-12 my-20 w-128 mx-auto text-center text-lg"
    >
      Loading...
    </div>
    <template v-else-if="!state.isError && state.token">
      <main class="px-4 py-6">
        <div
            class="flex flex-row items-center justify-between border-0 h-11 mb-4 relative"
        >
          <div>
            <p class="capitalize cs-modal-title">{{ plans.title }}</p>
          </div>
          <!-- Bulk Actions/Dropdown-->
          <!--          <CstDropdown-->
          <!--            button-classes="flex justify-between bg-main-gray"-->
          <!--            size="small"-->
          <!--            :disabled="state.selectedPlans.length < 2"-->
          <!--          >-->
          <!--            <template v-slot:selected>-->
          <!--              <span><i class="far fa-layer-group mr-2"></i>Bulk Actions</span>-->
          <!--            </template>-->

          <!--            <template v-slot>-->
          <!--              <CstDropdownItem @click="handleBulkAction('approve')">-->
          <!--                Approve-->
          <!--              </CstDropdownItem>-->
          <!--              <CstDropdownItem @click="handleBulkAction('reject')">-->
          <!--                Reject-->
          <!--              </CstDropdownItem>-->
          <!--              <CstDropdownItem @click="handleBulkAction('comment')">-->
          <!--                Comment-->
          <!--              </CstDropdownItem>-->
          <!--            </template>-->
          <!--          </CstDropdown>-->

          <!-- Count -->
          <div class="flex justify-end space-x-3 items-center">
            <span class="font-weight-500 opacity-70">
              Showing {{ plans.data.length }} of {{ plans.total }} post(s)
            </span>
          </div>
        </div>
        <div
            v-dragscroll
            class="f-table bg-white w-full shadow-sm select-none text-sm text-left text-gray-900 overflow-auto"
            @scroll="handleScroll"
        >
          <!--   Header   -->
          <div
              class="flex flex-nowrap flex-row sticky top-0 w-full bg-[#f3f4f4] z-20"
          >
            <div
                class="f-table-cell bg-[#f3f4f4] !w-[400px] px-6 py-3 cursor-pointer capitalize font-weight-500 sticky left-0 flex items-center"
            >
              Post
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Accounts
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Status
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Post Type
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Created By
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Created At
            </div>
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500"
            >
              Last Updated At
            </div>

            <!--    Actions      -->
            <div
                class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500 !w-[120px] flex-1"
            ></div>
          </div>

          <!--   Body   -->
          <div>
            <!--    Row      -->
            <DataRow
                v-for="(plan, i) in plans.data"
                :key="i"
                :plan="plan"
                :timezone="plans.timezone"
              :selected="state.selectedPlans.includes(plan._id)"
                @on-plan-select="onPlanSelect"
                @on-plan-status-click="onPlanStatusClick"
                @on-lightbox="onLightbox"
                @preview-plan="previewPlan"
            />

            <!--    Load More      -->
            <div
                v-if="state.loadingMore"
                class="flex flex-nowrap flex-row w-full f-table-row"
            >
              <div class="f-table-cell px-6 py-4 sticky left-0">
                <div class="flex items-center text-base">
                  <i class="fad fa-spinner-third fa-spin"></i>
                  <span class="ml-3">Loading more...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    <template v-else>
      <div
          class="rounded-xl bg-white shadow-sm p-12 my-20 w-128 mx-auto text-center text-lg"
      >
        The link is invalid or disabled by the user.
      </div>
    </template>

    <PublicPasswordProtectedModal
        :link-id="state.linkId"
        @on-verify="onVerify"
    />

    <ExternalActionsModal :token="state.token" />

    <VueEasyLightbox
        :visible="lightBox.imageVisible"
        :imgs="lightBox.media"
        @hide="handleHide"
    ></VueEasyLightbox>

    <DisplayFile></DisplayFile>

    <b-modal
        id="post-status-modal"
        size="lg"
        centered
        :no-close-on-backdrop="true"
        hide-header
        hide-footer
        dialog-class="max-w-7xl"
    >
      <PlannerPostStatus
          v-if="state.focusedPlan"
          :item="state.focusedPlan"
          modal-id="post-status-modal"
      />
    </b-modal>

    <PlannerPostPreview
        :plan="state.currentPlanInPreview"
        :token="state.token"
        :disable-left="planPreviewDisableLeft()"
        :disable-right="planPreviewDisableRight()"
        :external-post-preview="true"
        @next-plan="previewNextPlan"
        @previous-plan="previewPreviousPlan"
        @approve-with-comment="approveWithComment"
        @reject-with-comment="rejectWithComment"
    />
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import { defineComponent, inject, onMounted, reactive } from 'vue'
import PublicPasswordProtectedModal from '@src/modules/planner_v2/components/PublicPasswordProtectedModal'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import DataRow from '@src/modules/planner_v2/components/DataRow'
import ExternalActionsModal from '@src/modules/planner_v2/components/ExternalActionsModal'
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import DisplayFile from '@src/modules/publish/components/dialogs/DisplayFile'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
import PlannerPostPreview from '@src/modules/planner_v2/components/PlannerPostPreview_v2.vue'
import proxy from '@common/lib/http-common'
import { fetchPlatformsListURL } from '@src/modules/integration/config/api-utils'
import { social } from '@src/modules/integration/store/states/mutation-types'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'SharePlans',
  components: {
    PlannerPostPreview,
    PublicPasswordProtectedModal,
    DataRow,
    ExternalActionsModal,
    VueEasyLightbox,
    DisplayFile,
    PlannerPostStatus,
  },
  directives: {
    dragscroll,
  },
  setup() {
    const root = inject('root')
    const {$bvModal, $route} = root
    const {dispatch, commit} = useStore()

    const state = reactive({
      linkId: '',
      isPasswordProtected: false,
      isError: false,
      loading: false,
      loadingMore: false,
      token: '',
      selectedAll: false,
      selectedPlans: [],
      focusedPlan: {},
      currentPlanIndex: -1,
      currentPlanInPreview: {},
    })

    const plans = reactive({
      title: '',
      data: [],
      page: 1,
      total: 0,
      isNextPageExists: false,
    })

    const lightBox = reactive({
      imageVisible: false,
      videoVisible: false,
      media: [],
    })

    /**
     * Returns true/false if the previous button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableLeft = () => {
      return state.currentPlanIndex === 0
    }

    /**
     * Returns true/false if the next button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableRight = () => {
      return state.currentPlanIndex === plans.data.length - 1
    }

    onMounted(async () => {
      state.linkId = $route.params.id

      if (!state.linkId) {
        state.isError = true
        dispatch('toastNotification', {
          message: 'The provided link is invalid',
          type: 'error',
        })
      }

      await getShareLink(state.linkId)

      EventBus.$on('post-status-modal', (post) => {
        onPlanStatusClick(post)
      })
    })

    /**
     * Method to handle scroll event
     */
    const handleScroll = () => {
      const element = document.querySelector('.f-table')
      if (element) {
        const {scrollTop, scrollHeight, clientHeight} = element
        const buffer = 10 // Adjust the buffer value as needed

        if (scrollTop + clientHeight >= scrollHeight - buffer) {
          fetchNextPage()
        }
        console.log('At Bottom')
      }
    }

    // Attach the event listener to

    /**
     * Method to get share link
     */
    const getShareLink = async (id) => {
      state.loading = true
      try {
        const response = await serviceShareLink(ACTION_TYPES.GET_LINK, {
          link_id: id,
        })
        plans.title = response?.name
        if (response.is_password_protected) {
          state.isPasswordProtected = true
          $bvModal.show('public-password-protected')
        } else {
          state.token = response.token
        }
      } catch (error) {
        state.isError = true
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false

        if (state.token) {
          await fetchPlans()
        }
      }
    }

    /**
     * Method to verify password
     */
    const onVerify = (token) => {
      state.token = token
      $bvModal.hide('public-password-protected')

      fetchPlans()
    }

    /**
     * Method to fetch plans
     */
    const fetchPlans = async (page = 1) => {
      if (state.loading) return
      state.loading = true
      state.loadingMore = page > 1

      try {
        const response = await serviceShareLink(ACTION_TYPES.FETCH_PLANS, {
          token: state.token,
          page,
          limit: 10,
        })
        if (plans.data.length) {
          plans.data = [...plans.data, ...response.plans.data]
          state.selectedAll = false
        } else {
          plans.data = response.plans.data
        }

        plans.page = response.plans.current_page
        plans.total = response.plans.total
        plans.isNextPageExists = response.plans.next_page_url !== null
        plans.timezone = response.timezone
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false
        state.loadingMore = false
      }
    }

    /**
     * Method to fetch next page
     */
    const fetchNextPage = () => {
      if (plans.isNextPageExists) {
        fetchPlans(plans.page + 1)
      }
    }

    /**
     * Method to select all plans
     */
    const selectAll = () => {
      if (state.selectedAll) {
        state.selectedPlans = plans.data.map((plan) => plan._id)
      } else {
        state.selectedPlans = []
      }
    }

    /**
     * Method to select plan
     */
    const onPlanSelect = (planId) => {
      if (state.selectedPlans.includes(planId)) {
        state.selectedPlans = state.selectedPlans
            .filter((id) => id !== planId)
            .map((id) => id)
      } else {
        state.selectedPlans.push(planId)
      }

      state.selectedAll = state.selectedPlans.length === plans.data.length
    }

    const handleBulkAction = (action) => {
      EventBus.$emit('external-actions::show', {
        type: action,
        plans: [...state.selectedPlans],
      })
    }

    const handleHide = () => {
      lightBox.imageVisible = false
    }

    /**
     * Method to show lightbox
     * @param type - type of media, can be image or video
     * @param media Array of urls with type image and single url with type video
     */
    const onLightbox = (type = 'image', media) => {
      if (type === 'image') {
        lightBox.imageVisible = true
        lightBox.media = media
      } else {
        lightBox.videoVisible = true
        EventBus.$emit('displayFile', {
          type,
          media,
          index: 0,
        })
        $bvModal.show('display-file-modal')
      }
    }

    /**
     * Method to show plan status
     * @param plan
     */
    const onPlanStatusClick = (plan) => {
      state.focusedPlan = plan
      $bvModal.show('post-status-modal')
    }

    const previewPlan = (plan) => {
      state.focusedPlan = plan
      const plantIndex = plans.data.findIndex(
          (item) => item._id === state.focusedPlan._id
      )
      state.currentPlanIndex = plantIndex
      state.currentPlanInPreview = plans.data[plantIndex]
      $bvModal.show('planner-post-preview')
    }

    /**
     * onClick event for previewing next plan
     */
    const previewNextPlan = () => {
      if (state.currentPlanIndex === plans.data.length - 1) return
      state.currentPlanInPreview = plans.data[++state.currentPlanIndex]
    }

    /**
     * onClick event for previewing previous plan
     */
    const previewPreviousPlan = () => {
      if (state.currentPlanIndex === 0) return
      state.currentPlanInPreview = plans.data[--state.currentPlanIndex]
    }

    /**
     * Event for displaying the approve modal
     * @param item
     */
    const approveWithComment = (item) => {
      console.log('METHOD::approveWithComment ~ item -> ', item)
      state.planForApproval = item
      EventBus.$emit('external-actions::show', {
        type: 'approve',
        plans: state.planForApproval,
      })
    }

    /**
     * @description in order to fetch the platform list (required in post preview)
     */
    const fetchPlatformsList = async () => {
      const workspaceId = plans?.data[0]?.workspace_id
      await proxy
          .post(fetchPlatformsListURL, {
            workspace_id: workspaceId,
          })
          .then((resp) => {
            commit(social.SET_PLATFORM_LIST, resp.data)
          })
          .catch((err) => {
            console.error(err)
          })
    }

    /**
     * Event for displaying the reject modal
     * @param item
     */
    const rejectWithComment = (item) => {
      console.log('METHOD::rejectWithComment ~ item -> ', item)
      state.planForReject = item
      EventBus.$emit('external-actions::show', {
        type: 'reject',
        plans: state.planForReject,
      })
    }

    return {
      plans,
      state,
      lightBox,
      onVerify,
      fetchNextPage,
      selectAll,
      onPlanSelect,
      handleScroll,
      handleBulkAction,
      handleHide,
      onLightbox,
      onPlanStatusClick,
      previewPlan,
      previewNextPlan,
      previewPreviousPlan,
      approveWithComment,
      rejectWithComment,
      planPreviewDisableRight,
      planPreviewDisableLeft,
    }
  },
})
</script>

<style lang="scss" scoped>
.f-table {
  border: 1px solid #eee;
  border-radius: 0.375rem;

  // 100vh - 60px (header) - 3rem (top + bottom paddings) - 2.75rem (Bulk Actions) - 1rem (Bulk Action top + bottom margins)
  height: calc(100vh - 60px - 3rem - 2.75rem - 1rem);
}

.f-table-cell {
  @apply w-[245px] flex-shrink-0;

  @media (max-width: 1280px) {
    @apply w-[200px];
  }
}

.f-table-cell-center-y {
  @apply flex items-center;
}
</style>