<script>
import { mapGetters } from 'vuex'
import { integrations } from '@src/modules/integration/store/states/mutation-types'
import CustomAppsSection from '@src/modules/integration/components/sections/CustomAppsSection.vue'
import ImportFeedly from './dialogs/ImportFeedly.vue'
import ShortenerSection from './sections/ShortenerSection'
import IntegrationSection from './sections/IntegrationSection'
import YoutubeDataApiSection from './sections/YoutubeDataApiSection'
import RemoveIntegration from './dialogs/remove/RemoveIntegration'
import RemoveYoutubeApiKey from './dialogs/remove/RemoveYoutubeApiKey'

export default {
  components: {
    CustomAppsSection,
    RemoveIntegration,
    IntegrationSection,
    ShortenerSection,
    ImportFeedly,
    YoutubeDataApiSection,
    RemoveYoutubeApiKey,
  },
  data() {
    return {
      type: '',
      platform: '',
      integrations_authorization_interval: '',
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getFetchIntegrationsAccountsLoader',
      'getActiveWorkspace',
    ]),
    isAccountLoading() {
      return this.getFetchIntegrationsAccountsLoader
    },
  },
  watch: {
    'getActiveWorkspace._id'(val) {
      console.debug('Watch::Integration-getActiveWorkspace._id', val.length)
      if (val.length > 0) {
        this.initializeSection()
      }
    },
  },
  mounted() {
    setTimeout(() => {
      let ele = this.$route.hash ? this.$route.hash : '#link-Shortener'
      if (ele) {
        ele = ele + '-section'
        const topPos =
          document.querySelector(ele).getBoundingClientRect().top - 100
        window.scrollTo({
          top: topPos,
          behavior: 'smooth',
        })
      }
    }, 500)
  },
  created() {
    this.initializeSection()
  },
  beforeUnmount() {
    console.debug('beforeUnmount integration')
    window.clearInterval(this.integrations_authorization_interval)
  },
  methods: {
    async initializeSection() {
      console.debug('Integrations::initializeSection')
      this.$store.dispatch('fetchIntegrationsAccounts')
      this.$store.dispatch('fetchIntegrationAuthorizationLinks')
      this.integrations_authorization_interval = window.setInterval(
        function () {
          this.$store.dispatch('fetchIntegrationAuthorizationLinks')
        }.bind(this),
        120000
      )
      const response = await this.$store.dispatch(
        'checkConnectorState',
          {hash : this.$route.hash}
      )
      if (response && response.data && response.data.status === true) {
        if (response.data.process && response.data.process === 'feedly') {
          const topics =
            response.data.topics && response.data.topics.length > 0
              ? response.data.topics
              : []
          this.$store.commit(integrations.SET_FEEDLY_TOPICS, topics)
          if (topics) this.$bvModal.show('import_feedly')
          return false
        }
        this.$store.dispatch('toastNotification', {
          message: response.data.message,
          type: 'success',
        })
      }
    },
  },
}
</script>

<template>
  <div class="setting_miscellaneous_component setting_integration_component">
    <div class="component_inner">
      <RemoveIntegration :type="type" :platform="platform"></RemoveIntegration>
      <RemoveYoutubeApiKey></RemoveYoutubeApiKey>
      <ImportFeedly></ImportFeedly>
      <div v-if="isAccountLoading" class="ps_box p-5">
        <div class="box_content p-5">
          <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
        </div>
      </div>
      <ShortenerSection v-show="!isAccountLoading"></ShortenerSection>
      <!--<rewriting-section v-show="!isAccountLoading"></rewriting-section>-->
      <IntegrationSection
        v-show="!isAccountLoading"
        type="pocket"
      ></IntegrationSection>
      <IntegrationSection
        v-show="!isAccountLoading"
        type="replug"
      ></IntegrationSection>
      <IntegrationSection
        v-show="!isAccountLoading"
        type="feedly"
      ></IntegrationSection>
      <YoutubeDataApiSection
        v-show="!isAccountLoading && $route.name === 'integrations'"
      ></YoutubeDataApiSection>
      <CustomAppsSection type="twitter" title="X (Twitter) Custom Apps" />
    </div>
  </div>
</template>
