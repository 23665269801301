<script>
import { mapGetters } from 'vuex'
import {
  shortener,
  rewriter,
  integrations,
  social,
  pinterest,
} from '@src/modules/integration/store/states/mutation-types'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import {EventBus} from "@common/lib/event-bus";

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    platform: {
      type: [Object, String],
      default: () => {},
    },
    workspaceId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      removeLoader: false,
      stage: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPocket',
      'getFeedly',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getThreadsAccounts',
      'getLinkedinAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getPinterestAccounts',
      'getYoutubeAccounts',
      'getTiktokAccounts',
      'getPlatformsList',
    ]),
    isRemoveLoader() {
      return this.removeLoader
    },
    isNormalStage() {
      return this.stage === ''
    },
    isSuccessStage() {
      return this.stage === 'success'
    },
    isFailStage() {
      return this.stage === 'fail'
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('removeIntegration')
    },
    /**
     * This method is used to remove the item which is existing in the state after the removal.
     * @param getter
     * @param key
     */
    spliceStateItem(getter, key) {
      console.debug('Method::spliceStateItem', key)
      const items = this[getter].items ? this[getter].items : this[getter]
      const index = items.findIndex((item) => {
        return item[key] === this.platform
      })
      if (index > -1) items.splice(index, 1)

      this.$store.commit(
        social.SET_PLATFORM_LIST,
        this.getPlatformsList.filter(
          (item) => item[item.channel_identifier] !== this.platform
        )
      )
    },

    async removeIntegration() {
      console.debug('Method::removeIntegration', this.type, this.platform, this.$route.name)
      const payload = { type: this.type }
      if (this.platform) {
        payload.platform = this.platform
      }
      this.removeLoader = true
      const response = await this.$store.dispatch('removeIntegration', payload)
      if (!response) {
        this.stage = 'fail'
      }
      if (response) {
        this.stage = 'success'
        EventBus.$emit('refetchSocialPlatforms')
        if(this.$route.name === 'easy-connect'){
          EventBus.$emit('update-accounts-listing')
        }

        if (blogIntegrationsNames.includes(this.type)) {
          // TODO::we won't be calling plan service call each time if something removed.
          await this.Plan()

          if (
            this.type === 'tumblr' &&
            this.platform.indexOf('profile_') >= 0
          ) {
            this.spliceStateItem('getTumblrAccounts', 'platform_identifier')
          } else {
            const items = this.$store.getters.getBlogs[this.type].items
            const index = items.findIndex((item) => {
              return item.platform_identifier === this.platform
            })
            items.splice(index, 1)
          }
        } else {
          switch (this.type) {
            case 'facebook':
              await this.Plan()
              this.spliceStateItem('getFacebookAccounts', 'facebook_id')
              break
            case 'twitter':
              await this.Plan()
              this.spliceStateItem('getTwitterAccounts', 'twitter_id')
              break
            case 'threads':
            await this.Plan()
            this.spliceStateItem('getThreadsAccounts', 'platform_identifier')
            break
            case 'linkedin':
              await this.Plan()
              this.spliceStateItem('getLinkedinAccounts', 'linkedin_id')
              break
            case 'pinterest':
              await this.Plan()
              this.$store.commit(
                pinterest.SET_ACCOUNTS,
                this.getPinterestAccounts.items.filter((acc) => {
                  return !(
                    acc.type.toLowerCase() === 'board' &&
                    acc?.profile_id === this.platform
                  )
                })
              )

              this.spliceStateItem('getPinterestAccounts', 'board_id')
              this.spliceStateItem('getPinterestAccounts', 'pinterest_id')
              this.spliceStateItem('getPinterestAccounts', 'profile_id')
              break
            case 'instagram':
              await this.Plan()
              this.spliceStateItem('getInstagramAccounts', 'instagram_id')
              break
            case 'gmb':
              await this.Plan()
              this.spliceStateItem('getGmbAccounts', 'name')
              break
            case 'tiktok':
              await this.Plan()
              this.spliceStateItem('getTiktokAccounts', 'platform_identifier')
              break
            case 'youtube':
              await this.Plan()
              this.spliceStateItem('getYoutubeAccounts', 'platform_identifier')
              break
            case 'bitly':
              this.$store.commit(shortener.SET_BITLY_ACCOUNT, null)
              if (this.getWorkspaces.activeWorkspace.shortener === 'bitly') {
                this.getWorkspaces.activeWorkspace.shortener = '' // TODO::call mutation of this
              }
              break
            case 'chimp_rewriter':
              this.$store.commit(rewriter.SET_CHIMP_REWRITER_ACCOUNT, null)
              break
            case 'spin_rewriter':
              this.$store.commit(rewriter.SET_SPIN_REWRITER_ACCOUNT, null)
              break
            case 'quillbot':
              this.$store.commit(rewriter.SET_QUILLBOT_ACCOUNT, null)
              break
            case 'yandex_translate':
              this.$store.commit(rewriter.SET_QUILLBOT_ACCOUNT, null)
              break
            case 'pocket':
              this.spliceStateItem('getPocket', '_id')
              break
            case 'replug':
              this.$store.commit(integrations.SET_REPLUG_ACCOUNT, {
                domain_options: ['rplg.co', 'replug.link'],
              })
              if (this.getWorkspaces.activeWorkspace.shortener === 'replug') {
                this.getWorkspaces.activeWorkspace.shortener = '' // TODO::call mutation of this
              }
              break
            case 'feedly':
              this.spliceStateItem('getFeedly', '_id')
              break
          }
        }
      }
      this.removeLoader = false
      const stateObject = this
      setTimeout(function () {
        setTimeout(function () {
          stateObject.stage = ''
        }, 500)
        stateObject.$bvModal.hide('removeIntegration')
        stateObject.$emit('remove-integration', stateObject.type)
      }, 1000)
    },
  },
}
</script>

<template>
  <b-modal
    id="removeIntegration"
    hide-footer
    hide-header
    modal-class="alertBox"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal_body">
      <div v-if="isSuccessStage" class="svg_animation_success">
        <svg
          version="1.1"
          viewBox="0 0 130.2 130.2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path circle"
            cx="65.1"
            cy="65.1"
            fill="none"
            miterlimit="10"
            r="62.1"
            stroke="#73AF55"
            stroke-
            stroke-width="6"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>

      <div v-if="isFailStage" class="svg_animation_success">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class="path line"
            fill="none"
            stroke="#D06079"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>

      <div v-if="isNormalStage" class="col-sm-12 alert_content">
        <div class="col-sm-12 alert_content">
          <h2>Remove</h2>
          <p class="text-center">This action cannot be undone.</p>
        </div>
        <div class="col-sm-12 footer_box">
          <button
            :disabled="isRemoveLoader"
            class="btn gray cancel_btn"
            @click="hideModal"
            >No
          </button>
          <button
            :disabled="isRemoveLoader"
            class="btn red confirm_btn btn_loader loader_right"
            @click.prevent="removeIntegration"
          >
            <span>Yes</span>
            <clip-loader
              v-if="isRemoveLoader"
              :color="'#ffffff'"
              :size="'16px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
