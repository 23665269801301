import { mapGetters, mapActions, mapMutations } from 'vuex'
import http from '@src/modules/common/lib/http-common'
import { fetchArticleAutomationUrl } from '@src/modules/automation/config/api-utils'
import {
  article,
  recipeScheduleTypes,
} from '@src/modules/automation/store/recipes/mutation-types'
import {
  swalAttributes,
  getDuplicateCampaignName,
} from '@src/modules/common/constants/common-attributes'
import {
  publish,
  rewritingTypes,
  social,
  blogPosting,
} from '@src/modules/publish/store/states/mutation-types'
import integrations from '@common/lib/integrations'
import {
  AUTOMATION_NO_RESULT_ERROR,
  AUTOMATION_NULL_TOPIC_SELCTION_ERROR,
  AUTOMATION_PROCESSING_RESULTS_INFO,
  FETCH_AUTOMATION_ERROR,
  NULL_ENGAGEMENT_VALUE_ERROR,
  NULL_POST_AGE_ERROR,
  TOPIC_VALIDATION,
} from '@common/constants/messages'

export const articleAutomationMixin = {
  computed: {
    ...mapGetters([
      'getArticleAutomationValidations',
      'getFetchIntegrationsAccountsLoader',
      'getArticleAutomationDetails',
      'getWorkspaces',
      'getArticleBlogAutomationListing',
      'getArticleSocialAutomationListing',
      'getAutomationScheduleOptions',
      'getTopics',
      'getReplug',
      'getTopicSelection',
      'getTopicsType',
      'getTopicsRegion',
      'getTopicsLanguage',
      'getRewritingSelection',
      'getPublishSelection',
      'getTopicsFilters',
      'getArticleAutomationListing',
      'getPlans',
      'getNewWorkspaceHashtag',
      'getAllAutomations',
      'getPreviewSuggestionLoader',
      'getPreviewSuggestion',
      'getArticleAutomationTabStatus',
    ]),
    /**
     * Returns bool true/false if the api call for fetching the article content is in progress
     * @returns {boolean}
     */
    isProcessingResults() {
      return this.getPreviewSuggestionLoader
    },
  },
  methods: {
    ...mapMutations([
      'SET_TOPICS_LANGUAGE',
      'SET_TOPICS_REGION',
      'SET_TOPICS_TYPE',
      'SET_TOPIC_SELECTION',
    ]),
    ...mapActions([
      'setFetchArticleBlogAutomationsLoader',

      'setFetchArticleAutomationLoader',
      'setChangeArticleAutomationStatusLoader',
      'setAutomationScheduleOptions',
      'setSelectedHashtagSelection',
      'setSelectedReplugSelection',
      'setSelectedReplugSelectedItem',
      'setPreviewSuggestionPostAge',
      'setPreviewSuggestionEngagement',
      'setArticleAutomationListingSelectedAll',
      'validateHashtagInput',
      'saveWorkspaceHashtag',
      'articlesPreviewSuggestion',
      'setEditQueueStatus',
    ]),

    createArticleAutomationProcess(routeName) {
      const result = this.checkSubscriptionLimits('automations')
      if (result) {
        return
      }
      console.debug('Method::createArticleAutomationProcess', routeName)
      this.resetArticleAutomationSection()
      this.$router.push({
        name: routeName,
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    },

    async validateArticleAutomation(tab) {
      console.debug('Method::processArticleAutomationValidation', tab)
      if (
        this.isProcessingResults &&
        !this.getArticleAutomationTabStatus?.first
      ) {
        this.alertMessage(AUTOMATION_PROCESSING_RESULTS_INFO, 'info')
        return false
      }
      const automation = this.getArticleAutomationDetails
      const validations = this.getArticleAutomationValidations.validations
      if (tab === 'first' || tab === 'All') {
        // validating name and social accounts selection
        validations.name = this.requiredCheck(automation.name)

        if (this.$route.name === 'saveArticleBlogAutomation') {
          if (!this.blogPrimarySecondarySelectionValidation()) return false

          if (!this.socialAccountsTokenValidation()) return false

          if (this.getPublishSelection.replug.status) {
            if (this.replugValidation() === false) return false
          }

          if (this.getPublishSelection.hashtag.status) {
            await this.validateHashtagInput('ArticleAutomation')

            if (this.getWorkspaceHashtagValidation === false) return false
          }
        } else {
          validations.social_accounts =
            !this.socialAccountSelectionValidation('ArticleAutomation')
          if (validations.name || validations.social_accounts) return false
        }
        this.$store.commit(
          article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS,
          'second'
        )
      }
      if (tab === 'second' || tab === 'All') {
        if (automation.article.build_type === 'topics') {
          if (automation.article.topics.length === 0) {
            this.alertMessage(AUTOMATION_NULL_TOPIC_SELCTION_ERROR, 'error')
            return false
          }
        } else if (!this.showPreviewSnippet()) {
          this.alertMessage(TOPIC_VALIDATION.TOPIC_KEYWORDS, 'error')
          return
        } else if (!this.getPreviewSuggestion?.posts?.length) {
          this.alertMessage(AUTOMATION_NO_RESULT_ERROR, 'error')
          return false
        }

        if (
          automation.article.post_age === 2 &&
          automation.article.post_date === ''
        ) {
          this.alertMessage(NULL_POST_AGE_ERROR, 'error')
          return false
        } else if (
          automation.article.engagement_type !== 'None' &&
          (automation.article.engagement_count === '' ||
            automation.article.engagement_count < 0)
        ) {
          this.alertMessage(NULL_ENGAGEMENT_VALUE_ERROR, 'error')
          return false
        }
        // else if (!this.contentRewritingValidation()) {
        //   return false
        // }

        this.$store.commit(
          article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS,
          'third'
        )
      }
      if (tab === 'third' || tab === 'All') {
        if (automation.article.build_type === 'topics') {
          if (automation.article.topics.length === 0) {
            this.alertMessage(AUTOMATION_NULL_TOPIC_SELCTION_ERROR, 'error')
            return false
          }
        } else {
          if (!this.showPreviewSnippet()) {
            this.alertMessage(TOPIC_VALIDATION.TOPIC_KEYWORDS, 'error')
            return
          }
        }

        validations.name = this.requiredCheck(automation.name)

        if (this.$route.name === 'saveArticleBlogAutomation') {
          if (!this.blogPrimarySecondarySelectionValidation()) return false

          if (!this.socialAccountsTokenValidation()) return false

          if (this.getPublishSelection.replug.status) {
            if (this.replugValidation() === false) return false
          }

          if (this.getPublishSelection.hashtag.status) {
            await this.validateHashtagInput('ArticleAutomation')

            if (this.getWorkspaceHashtagValidation === false) return false
          }

          if (!this.finalTabValidation(automation)) return false
        } else {
          if (this.getPublishSelection.replug.status) {
            if (this.replugValidation() === false) return false
          }

          if (this.getPublishSelection.hashtag.status) {
            await this.validateHashtagInput('ArticleAutomation')

            if (this.getWorkspaceHashtagValidation === false) return false
          }
          if (validations.name || validations.social_accounts) return false
          this.$store.commit(
            article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS,
            'fourth'
          )
        }
      }
      if (tab === 'fourth' || tab === 'All') {
        if (!this.finalTabValidation(automation)) {
          return false
        }
      }

      return true
    },

    resetArticleAutomationSection() {
      console.debug('Method::resetArticleAutomationSection')
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
      this.$store.commit(blogPosting.RESET_BLOG_POSTING)
      this.$store.commit(social.RESET_ACCOUNT_SELECTION)
      this.$store.commit(article.RESET_ARTICLE_AUTOMATION)
      this.$store.commit(rewritingTypes.RESET_REWRITING_SELECTION)
      this.$store.commit(publish.RESET_PUBLISH_HASHTAG_VALUE)
      this.$store.commit(publish.RESET_PUBLISH_REPLUG_VALUE)
      this.$store.commit(recipeScheduleTypes.RESET_AUTOMATION_SCHEDULE_OPTIONS)
      this.setEditQueueStatus(false)
      this.SET_TOPIC_SELECTION(null)
      this.SET_TOPICS_TYPE('Any')
      this.SET_TOPICS_REGION({
        code: 'world',
        native_language: {
          code: 'en',
          languageName: 'English',
        },
        name: '' + 'Worldwide',
        flag_position: '0 0',
      })
      this.SET_TOPICS_LANGUAGE({
        languageName: 'English',
        code: 'en',
      })
    },

    // social sections end
    async fetchArticleAutomation(automation, clone = false) {
      console.debug('Method:fetchArticleAutomation')

      this.setFetchArticleAutomationLoader(true)
      await http
        .post(fetchArticleAutomationUrl, {
          id: automation,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          copy: clone,
        })
        .then((res) => {
          let count = -1

          if (res.data.status) {
            automation = res.data.automation
            if (clone) {
              automation.name = getDuplicateCampaignName(automation.name)
              automation.id = ''
            } else {
              automation.id = automation._id
            }

            delete automation._id
            this.$store.commit(article.SET_ARTICLE_AUTOMATION, automation)
            this.$store.commit(
              social.SET_ACCOUNT_SELECTION,
              automation.account_selection
            )
            this.$store.commit(social.SET_PINTEREST_ACCOUNT_SELECTION, [])
            this.SET_TOPIC_SELECTION(automation.article.topic_selection)
            this.SET_TOPICS_TYPE(automation.article.type)
            this.SET_TOPICS_REGION(automation.article.region)
            this.SET_TOPICS_LANGUAGE(automation.article.language)
            this.processAutomationScheduleOptions(automation.schedule_options)
            this.processHashtagSelection(automation.hashtag)
            if (automation.replug) {
              if (!this.getFetchIntegrationsAccountsLoader) {
                this.processReplugSelection(automation.replug)
              } else {
                this.$store.commit(
                  publish.SET_PUBLISH_REPLUG_SELECTED_ITEM_VALUE,
                  automation.replug
                )
              }
            }
            if (this.$route.name === 'articleBlogAutomationListing') {
              this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
              this.$store.commit(blogPosting.RESET_BLOG_POSTING)
              this.$store.dispatch(
                'processPrimarySecondaryBlogSelection',
                automation.blog_selection
              )
              this.$store.commit(
                rewritingTypes.SET_REWRITING_SELECTION,
                automation.rewriting_selection
              )
              this.$router.push({ name: 'saveArticleBlogAutomation' })
            } else {
              this.$router.push({ name: 'saveArticleSocialAutomation' })
            }

            if (this.getAutomationScheduleOptions.time_type !== 'Queue') {
              count = res.data.next_posting.length
            }
            if (this.getAutomationScheduleOptions.time_type === 'Queue') {
              count = 0
              const names = integrations.socialPlatformNames()
              names.forEach(function (integration) {
                if (Object.keys(res.data.next_posting).includes(integration)) {
                  res.data.next_posting[integration].forEach(function (
                    element,
                    index
                  ) {
                    count += element.length
                  })
                }
              })
            }

            this.articlesPreviewSuggestion({ automation: true })

            const stateObject = this
            setTimeout(function () {
              stateObject.showAutomationPostAgeDatePickers()
            }, 200)
          }
          if (!res.data.status) {
            this.alertMessage(FETCH_AUTOMATION_ERROR, 'error')
          }

          this.$store.commit(article.SET_AUTOMATION_NEXT_POSTING, count)
          return res
        })
        .catch(() => {
          this.$store.commit(article.SET_AUTOMATION_NEXT_POSTING, -1)
          return null
        })
      this.setFetchArticleAutomationLoader(false)
    },
    // creation methods start

    changeArticleAutomationPostAge(postAge) {
      this.$store.commit(article.SET_ARTICLE_AUTOMATION_POST_AGE, postAge)
      this.setPreviewSuggestionPostAge(postAge)
      this.articlesPreviewSuggestion({ automation: true })
    },

    changeArticleAutomationEngagementType(engagement = null) {
      if (engagement == null) {
        engagement = this.getTopicsFilters.topics.engagement.type
      }
      this.$store.commit(
        article.SET_ARTICLE_AUTOMATION_ENGAGEMENT_TYPE,
        engagement
      )
      const count =
        engagement === 'None'
          ? 0
          : this.getArticleAutomationDetails.article.engagement_count
      this.setPreviewSuggestionEngagement({
        type: engagement,
        count,
      })
      this.articlesPreviewSuggestion({ automation: true })
    },

    finalTabValidation(automation) {
      console.debug('Method::finalTabValidation')
      if (!this.automationScheduleOptionsValidation('ArticleAutomation')) {
        return false
      }

      if (
        !automation.prefetch_days_limit ||
        (automation.prefetch_days_limit && automation.prefetch_days_limit < 2)
      ) {
        this.alertMessage(
          'Please enter prefetch days limit greater than 1.',
          'error'
        )
        return false
      }

      if (
        automation.prefetch_days_limit &&
        automation.prefetch_days_limit > 100
      ) {
        this.alertMessage(
          'Please enter prefetch days limit less than 100.',
          'error'
        )
        return false
      }

      return true
    },

    /**
     * Validate on the final step once again, so that there is no validation missing.
     *
     * Once validated, store the campaign
     * @returns {Promise<void>}
     */
    async validateAndStoreArticleAutomation() {
      console.debug('Method::validateAndStoreArticleAutomation')
      if (await this.validateArticleAutomation('All')) {
        if (
          this.getPublishSelection.hashtag.status &&
          this.getPublishSelection.hashtag.selected === ''
        ) {
          this.saveWorkspaceHashtag('ArticleAutomation', this).then((resp) => {
            if (this.getPublishSelection.hashtag.selected) {
              this.isActiveConfirmation()
            }
          })
        } else {
          this.isActiveConfirmation()
        }
      }
    },

    /**
     * Checking if the user would like to activate their campaign while saving it. If they do that, we are passing the activate value
     * @returns {Promise<void>}
     */
    async isActiveConfirmation() {
      const type =
        this.$route.name === 'saveArticleBlogAutomation' ? 'blog' : 'social'
      let resp
      console.debug('Method::isActiveConfirmation')
      const automation = this.getArticleAutomationDetails

      // is the automation status paused.

      if (automation.id && automation.status === 0) {
        const ok = await this.$bvModal
          .msgBoxConfirm(
            'Your campaign is not active, would you like to activate?',
            {
              title: 'Activate Campaign',
              ...swalAttributes(),
            }
          )
          .then((res) => {
            return res
          })
          .catch(() => {
            return null
          })

        // the user given the permission to activate it.
        if (ok) {
          resp = await this.$store.dispatch('storeArticleAutomation', {
            type,
            activate: true,
          })
        }
      } else {
        resp = await this.$store.dispatch('storeArticleAutomation', {
          type,
          activate: false,
        })
      }
      // check the response, if received, return to the listing page
      if (resp && resp.data && resp.data.status) {
        this.$router.push({
          name:
            type === 'blog'
              ? 'articleBlogAutomationListing'
              : 'articleSocialAutomationListing',
        })
      }
    },
  },
}
