import { ref } from 'vue'
import axios from 'axios'

const ipv4 = ref(null)

export default function useIp() {
  axios.get('https://api.ipify.org?format=json').then((response) => {
    ipv4.value = response.data.ip
  })

  return {
    ipv4,
  }
}
