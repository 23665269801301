<template>
  <div>
    <div
      class="dropdown_header d-flex align-items-center"
      data-toggle="dropdown"
    >
      <i class="trending_icon icon_first cs-trending-chart"></i>
      <span class="text" data-cy="influencer_sort_dropdown_filter_text">
        {{ getSortByDisplay() }}
      </span>
      <span class="ml-auto">
        <i class="dropdown_arrow icon_last cs-angle-down"></i>
      </span>
    </div>
    <div class="dropdown-menu dropdown-menu-left">
      <ul class="inner">
        <template v-if="type === 'youtube'">
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_relevancy"
              @click.prevent="callInfluencerSort(null)"
              >Relevancy</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_subscribers"
              @click.prevent="callInfluencerSort('total_subscribers')"
              >Total Subscribers</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_views"
              @click.prevent="callInfluencerSort('total_views')"
              >Total Views</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_views"
              @click.prevent="callInfluencerSort('total_runtime')"
              >Total Runtime</a
            >
          </li>
        </template>
        <template v-else>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_relevancy"
              @click.prevent="callInfluencerSort(null)"
              >Relevancy</a
            >
          </li>
          <li class="list_item">
            <a
              v-if="type === 'twitter'"
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_total_tweets_published"
              @click.prevent="callInfluencerSort('total_tweets_published')"
              >Total Tweets</a
            >
            <a
              v-if="type === 'instagram'"
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_total_posts_published"
              @click.prevent="callInfluencerSort('total_posts_published')"
              >Total Posts</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_follower_count"
              @click.prevent="callInfluencerSort('follower_count')"
              >Followers</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              href="javascript:;"
              data-cy="influencer_sort_dropdown_filter_following"
              @click.prevent="callInfluencerSort('following')"
              >Following</a
            >
          </li>
        </template>

        <li class="list_item">
          <a
            v-if="type === 'twitter'"
            class="dropdown-item"
            href="javascript:;"
            data-cy="influencer_sort_dropdown_filter_average_interaction"
            @click.prevent="callInfluencerSort('average_interaction_per_tweet')"
            >Average Interaction</a
          >

          <a
            v-else
            class="dropdown-item"
            href="javascript:;"
            data-cy="influencer_sort_dropdown_filter_average_interaction"
            @click.prevent="callInfluencerSort('average_interaction')"
            >Average Interaction</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    type: ''
  },
  methods: {
    getSortByDisplay () {
      const sort = this.$route.query.sort ? this.$route.query.sort : null
      switch (sort) {
        case 'total_subscribers':
          return 'Total Subscribers'
        case 'total_views':
          return 'Total Views'
        case 'total_runtime':
          return 'Total Runtime'
        case 'total_tweets_published':
          return 'Total Tweets'
        case 'total_posts_published':
          return 'Total Posts'
        case 'follower_count':
          return 'Followers'
        case 'following':
          return 'Following'
        case 'average_interaction_per_tweet':
          return 'Average Interaction'
        case 'average_interaction':
          return 'Average Interaction'
        default:
          return 'Relevancy'
      }
    },
    callInfluencerSort (attr) {
      this.$parent.changeInfluencerSortOrder(attr, this.type)
    }
  },
  computed: {
    ...mapGetters([
      'getInfluencerFilters',
      'getInstagramInfluencerFilters',
      'getYoutubeInfluencerFilters'
    ])
  }
}
</script>
