<template>
  <div class="px-2">
    <!--  View Limits</h2>-->
    <div class="grid grid-cols-4 justify-center gap-6 mt-8">
      <!--workspaces-->
      <div class="item-check-box">
        <span class="name">Workspaces</span>
        <span class="quantity">{{
          addOnsData.workspaces ? addOnsData.workspaces.quantity : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.workspaces
              ? addOnsData.workspaces.price * addOnsData.workspaces.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--team members-->
      <div class="item-check-box">
        <span class="name">Team Members</span>
        <span class="quantity">{{
          addOnsData.members ? addOnsData.members.quantity : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.members
              ? addOnsData.members.price * addOnsData.members.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--Social Accounts-->
      <div class="item-check-box">
        <span class="name">Social Accounts</span>
        <span class="quantity">{{
          addOnsData.profiles ? addOnsData.profiles.quantity : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.profiles
              ? addOnsData.profiles.price * addOnsData.profiles.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--Blogs (Publishing)-->
      <div class="item-check-box">
        <span class="name">Blogs (Publishing)</span>
        <span class="quantity">{{
          addOnsData.blogs ? addOnsData.blogs.quantity : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.blogs
              ? addOnsData.blogs.price * addOnsData.blogs.quantity
              : 0
          }}/month</span
        >
      </div>
    </div>
    <div class="grid grid-cols-4 justify-center gap-6 mt-8">
      <!--Automation Campaigns-->
      <div class="item-check-box">
        <span class="name">Automation Campaigns</span>
        <span class="quantity">{{
          addOnsData.automation_campaigns
            ? addOnsData.automation_campaigns.quantity
            : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.automation_campaigns
              ? addOnsData.automation_campaigns.price *
                addOnsData.automation_campaigns.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--Media Storage (GBs)-->
      <div class="item-check-box">
        <span class="name">Media Storage (GBs)</span>
        <span class="quantity">{{
          addOnsData.media_storage ? addOnsData.media_storage.quantity : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.media_storage
              ? addOnsData.media_storage.price *
                addOnsData.media_storage.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--AI Text Credits(words)-->
      <div class="item-check-box">
        <span class="name">AI Text Credits(words)</span>
        <span class="quantity">{{
          addOnsData.caption_generation_credit
            ? addOnsData.caption_generation_credit.quantity
            : 0
        }}</span>
        <span class="price"
          >${{
            addOnsData.caption_generation_credit
              ? addOnsData.caption_generation_credit.price *
                addOnsData.caption_generation_credit.quantity
              : 0
          }}/month</span
        >
      </div>
      <!--AI Image Credits(words)-->
      <div class="item-check-box">
        <span class="name">AI Image Credits</span>
        <span class="quantity">{{
            addOnsData.image_generation_credit
                ? addOnsData.image_generation_credit.quantity
                : 0
          }}</span>
        <span class="price"
        >${{
            addOnsData.image_generation_credit
                ? addOnsData.image_generation_credit.price *
                addOnsData.image_generation_credit.quantity
                : 0
          }}/month</span
        >
      </div>
    </div>
    <!--      total bill         -->
    <div
      class="flex justify-center items-center my-8 border-solid border-gray-300 border-2 rounded-md px-3 py-4"
    >
      <div class="text-base font-semibold"> Grand Total </div>
      <div class="ml-auto text-xl font-bold">
        ${{ parseFloat(getAddonsPrice).toFixed(2) + ' /' }}
        <span class="text-base font-normal">
          <template v-if="bill_type === 'monthly'"> Monthly </template>
          <template v-else> Annually </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddOnsLimitsComponent',
  props: {
    addOnsData: { type: Object, default: () => ({}), required: false },
    bill_type: { type: String, default: 'monthly', required: false}
  },
  data() {
    return {
      loaders: {
        increase_limits: false,
      },
      modalId: 'increase-limits-dialog',
    }
  },

  computed: {
    getAddonsPrice() {
      const sumAmount =
        (this.addOnsData.workspaces
          ? this.addOnsData.workspaces.price *
            this.addOnsData.workspaces.quantity
          : 0) +
        (this.addOnsData.profiles
          ? this.addOnsData.profiles.price * this.addOnsData.profiles.quantity
          : 0) +
        (this.addOnsData.members
          ? this.addOnsData.members.price * this.addOnsData.members.quantity
          : 0) +
        (this.addOnsData.blogs
          ? this.addOnsData.blogs.price * this.addOnsData.blogs.quantity
          : 0) +
        (this.addOnsData.automation_campaigns
          ? this.addOnsData.automation_campaigns.price *
            this.addOnsData.automation_campaigns.quantity
          : 0) +
        (this.addOnsData.media_storage
          ? this.addOnsData.media_storage.price *
            this.addOnsData.media_storage.quantity
          : 0) +
        (this.addOnsData.caption_generation_credit
          ? this.addOnsData.caption_generation_credit.price *
            this.addOnsData.caption_generation_credit.quantity
          : 0) +
          (this.addOnsData.image_generation_credit
          ? this.addOnsData.image_generation_credit.price *
            this.addOnsData.image_generation_credit.quantity
          : 0)

      console.log('sumAmount', sumAmount)
      if (this.bill_type === 'annually') return sumAmount * 12
      return sumAmount
    },
  },
}
</script>

<style lang="less" scoped>
.item-check-box {
  height: 130px;
  @apply flex flex-col items-center justify-center w-full text-center rounded-lg border-2 border-solid hover:border-blue-300 border-gray-300 border-opacity-50;
  .quantity {
    @apply text-cs-primary font-extrabold  text-center text-4xl my-3;
  }
  .name {
    @apply block text-base;
  }
  .price {
    @apply text-sm font-semibold;
  }
}
</style>
