import * as _ from 'underscore'
const commonMethods = {
  /**
   * Checks for instagram API permissions on selected instagram accounts.
   * Replacement for deprecated method "zapierLinkedInstagramAccountExist"
   * @param accountSelection
   * @param accountsList
   * @returns {{no_access_accounts: *[], all_access_accounts: *[]}}
   */
  linkedInstagramAccountExist(accountSelection, accountsList) {
    const allAccessAccounts = [] // will contain profiles with IG publishing permissions
    const noAccessAccounts = [] // will contain profiles which do not have IG publishing permissions

    accountSelection.instagram.forEach((item) => {
      accountsList.forEach((channel) => {
        if (channel[channel.channel_identifier] === item) {
          if (!this.hasInstaDirectAPIPermission(channel)) {
            noAccessAccounts.push(channel)
          } else {
            allAccessAccounts.push(channel)
          }
        }
      })
    })

    // update the instagramSelection variable from data to the new selected data
    return {
      no_access_accounts: noAccessAccounts,
      all_access_accounts: allAccessAccounts,
    }
  },

  /**
   * @Deprecated
   * @param temp
   * @returns {{no_access_accounts: *[], all_access_accounts: *[]}}
   */
  zapierLinkedInstagramAccountExist(temp) {
    const selectedAccounts = [] // will contain all the details for the IG accounts selected
    for (let x = 0; x < temp.getAccountSelection.instagram.length; x++) {
      // loop over all selected IG ids
      const selected = temp.getAccountSelection.instagram[x] // selected ig profile
      const selectedItem = temp.getInstagramAccounts.all_items.filter(
        (item) => {
          // loop and check for IG details for selected ids
          return item.instagram_id === selected
        }
      )

      // if selected acccount is found append to selected accounts array
      if (selectedItem.length > 0) {
        selectedAccounts.push(selectedItem[0])
      }
    }

    const allAccessAccounts = [] // will contain profiles with IG publishing permissions
    const noAccessAccounts = [] // will contain profiles which do not have IG publishing permissions

    // if selected accounts are found ...
    if (selectedAccounts.length > 0) {
      for (let x = 0; x < selectedAccounts.length; x++) {
        // loop over each account and segregate into seperate arrays accordingly
        const item = selectedAccounts[x]
        if (!this.hasInstaDirectAPIPermission(item)) {
          noAccessAccounts.push(item)
        } else {
          allAccessAccounts.push(item)
        }
      }
    }

    // update the instagramSelection variable from data to the new selected data
    return {
      no_access_accounts: noAccessAccounts,
      all_access_accounts: allAccessAccounts,
    }
  },
  isPinterestAccountIsManuallyConnected(account) {
    return (
      account &&
      (account.pinterest_id || account.board_id) &&
      account.manually_added
    )
  },
  showZapierIntegration(account) {
    return account && account.linked_to && account.linked_to.zapier
  },
  isPinterestBoard(account) {
    return account && account.board_id
  },
  hasInstaDirectAPIPermission(channel) {
    const publishAPIPermission = _.findWhere(channel.permissions, {
      permission: 'instagram_content_publish',
    })
    return publishAPIPermission
      ? publishAPIPermission.status === 'granted'
      : false
  },
  showInstagramWarning(mobileDevices, account) {
    // check if account is instagram
    if (account.instagram_id) {
      // check if mobile device is connected OR // check if account is connected to zapier
      return !(mobileDevices.length > 0)
    }

    return false
  },

  isFacebookGroup(account) {
    return account && account.facebook_id && account.type === 'Group'
  },
  isFacebookGroupWithPostedAs(account) {
    return (
      account &&
      account.facebook_id &&
      account.type === 'Group' &&
      account.posted_as &&
      Array.isArray(account.posted_as)
    )
  },
  isFbPageTokenIsSelectedForPosting(fbGroup, postedAs) {
    if (fbGroup && fbGroup.posted_as && Array.isArray(fbGroup.posted_as)) {
      for (const i in fbGroup.posted_as) {
        if (
          postedAs &&
          fbGroup.posted_as[i].id === postedAs[fbGroup.facebook_id] &&
          fbGroup.posted_as[i].type === 'Page'
        ) {
          return true
        }
      }
    }
    return false
  },
  getFBGroupPostedAsAccount(fbAccount, postedAs) {
    let defaultPostedAs = ''
    if (
      fbAccount?.type === 'Group' &&
      fbAccount?.posted_as &&
      Array.isArray(fbAccount.posted_as)
    ) {
      for (const i in fbAccount.posted_as) {
        if (
          postedAs &&
          fbAccount.posted_as[i].id === postedAs[fbAccount.facebook_id] &&
          fbAccount.posted_as[i].name
        ) {
          return fbAccount.posted_as[i]
        } else if (
          fbAccount.posted_as[i].default === true &&
          fbAccount.posted_as[i].name
        ) {
          defaultPostedAs = fbAccount.posted_as[i]
        }
      }
    }
    return defaultPostedAs
  },

  /**
   * toggles the visibility of help dropdown and GoSquared Chat plugin, hides on toggle = true
   */
  toggleHelpDropdown(toggle = false) {
    const ele = document.getElementById('help_dropdown_fixed')
    if (ele) {
      toggle ? ele.classList.add('hidden') : ele.classList.remove('hidden')
    }
    const element = document.getElementById('gs')
    if (element)
      toggle
        ? element.classList.add('hidden')
        : element.classList.remove('hidden')
  },
  toggleAiChat(toggle = false) {
    const ele = document.getElementById('AI_chat_widget')
    if (ele) {
      toggle ? ele.classList.add('hidden') : ele.classList.remove('hidden')
    }
  },
  toggleWidgets(toggle = false) {
    this.toggleHelpDropdown(toggle)
    this.toggleAiChat(toggle)
  }
}

export { commonMethods }
