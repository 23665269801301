import proxy from '@common/lib/http-common'
import {
  FETCH_PAUSE_POSTING_COUNT_ERROR,
  PAUSE_WORKSPACE_POSTING_ERROR,
  UNKNOWN_ERROR,
  UNPAUSE_WORKSPACE_POSTING_ERROR,
} from '@common/constants/messages'
import {
  fetchPausePostingCountURL,
  pausePostingURL,
  resumePostingURL,
  fetchYoutubeApikeyURL,
  updateYoutubeApikeyURL,
  removeYoutubeApikeyURL,
} from '@src/modules/setting/config/api-utils'
import { workspaceTypes } from './mutation-types'

const getDefaultWorkspaceDetails = () => {
  return {
    id: '',
    name: '',
    slug: '',
    logo: '',
    logoId: '',
    note: '',
    timezone: 'UTC',
    first_day: {
      day: 'Monday',
      key: 1,
    },
    logoSuggestions: [],
    superAdmin: '',
    instagram_posting_method: 'api',
    onboarding_steps: {
      watch_video: {
        status: false,
      },
      connect_social_account: {
        status: false,
      },
      create_first_post: {
        status: false,
      },
      content_category: {
        status: false,
      },
      discover_content: {
        status: false,
      },
      invite_team: {
        status: false,
      },
    },
    onboarding: false,
    last_youtube_consent_time: ''
  }
}
const getDefaultActiveWorkspace = () => {
  return {
    _id: '',
    name: '',
    timezone: '',
    logo: '',
    first_day: {
      day: 'Monday',
      key: 1,
    },
    onboarding_steps: {
      watch_video: {
        status: false,
      },
      connect_social_account: {
        status: false,
      },
      create_first_post: {
        status: false,
      },
      content_category: {
        status: false,
      },
      discover_content: {
        status: false,
      },
      invite_team: {
        status: false,
      },
      accounts_connection_modal_closed: {
        status: false,
      }
    },
    onboarding: false,
  }
}
const getDefaultAddonsLifetime = () => {
  return {
    analytics: false,
    influencers: false,
  }
}

const state = {
  workspaces: {
    items: [],
    search: '',
    activeWorkspace: getDefaultActiveWorkspace(),
    activeWorkspaceMembersName: [], // used in tasks, comments and mentions etc
    activeWorkspaceMembersIds: [], // used in tasks, comments and mentions etc
    activeWorkspaceMemberDetails: [], // used in tasks, comments and mentions etc
  },
  workspaceDetails: getDefaultWorkspaceDetails(),
  workspaceLoaded: false,
  workspaceLogoSuggestionLoader: false,
  workspaceSlugValidation: '', // following states will be used: invalid, already_used
  workspaceSlugAvailableLoader: false,
}

// getters
const getters = {
  getWorkspaces: (state) => {
    return state.workspaces
  },

  getActiveWorkspace: (state) => {
    return state.workspaces.activeWorkspace
  },

  getActiveWorkspaceMembersName: (state) => {
    return state.workspaces.activeWorkspaceMembersName
  },
  getActiveWorkspaceMembersIds: (state) => {
    return state.workspaces.activeWorkspaceMembersIds
  },
  getActiveWorkspaceMembersDetails: (state) => {
    return state.workspaces.activeworkspaceMembersDetails
  },

  getWorkspaceDetails: (state) => {
    return state.workspaceDetails
  },
  getWorkspaceLoaded: (state) => {
    return state.workspaceLoaded
  },
  getWorkspaceLogoSuggestionsLoader: (state) => {
    return state.workspaceLogoSuggestionLoader
  },
  getWorkspaceSlugValidation: (state) => {
    return state.workspaceSlugValidation
  },
  getSlugAvailableLoader: (state) => {
    return state.workspaceSlugAvailableLoader
  },
  getActiveWorkspaceAddonsLifetime: (state) => {
    if (state && Object.hasOwn(state, 'workspaces')) {
      if (
        state.workspaces &&
        state.workspaces.activeWorkspace &&
        state.workspaces.activeWorkspace.members
      ) {
        const item = state.workspaces.activeWorkspace.members.find(
          (item) => item.role === 'super_admin'
        )
        if (item) return item.user.addons_lifetime
      }
    }
    return getDefaultAddonsLifetime()
  },
  /**
   *
   * @param state
   * @returns {object}
   */
  getActiveWorkspaceTriggers: (state) => {
    const item = state.workspaces.activeWorkspace.members.find(
      (item) => item.role === 'super_admin'
    )
    return item.user.triggers
  },
  // get team members for inbox.

  getWorkspaceInboxTeamMembers: (state) => {
    return state.workspaces.activeWorkspace.members.filter((item) => {
      return item.role !== 'approver' && item.status === 'joined'
    })
  },
}

// actions
const actions = {
  async pausePosting({ commit, getters, rootGetters, dispatch }, payload) {
    console.debug('Action:pausePosting', payload)
    return proxy
      .post(pausePostingURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        const message = response.data.message
          ? response.data.message
          : PAUSE_WORKSPACE_POSTING_ERROR
        dispatch('toastNotification', { message, type: 'error' })
        return false
      })
      .catch((error) => {
        console.debug('Exception in pausePosting', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },

  async fetchPausePostingCount(
    { commit, getters, rootGetters, dispatch },
    payload
  ) {
    console.debug('Action:fetchPausePostingCount', payload)
    return proxy
      .post(fetchPausePostingCountURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        const message = response.data.message
          ? response.data.message
          : FETCH_PAUSE_POSTING_COUNT_ERROR
        dispatch('toastNotification', { message, type: 'error' })
        return false
      })
      .catch((error) => {
        console.debug('Exception in fetchPausePostingCount', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },

  async resumePosting({ commit, getters, rootGetters, dispatch }, payload) {
    console.debug('Action:resumePosting', payload)
    return proxy
      .post(resumePostingURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        const message = response.data.message
          ? response.data.message
          : UNPAUSE_WORKSPACE_POSTING_ERROR
        dispatch('toastNotification', { message, type: 'error' })
        return false
      })
      .catch((error) => {
        console.debug('Exception in resumePosting', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },

  async fetchWorkspaceYoutubeKey(
    { commit, getters, rootGetters, dispatch },
    payload
  ) {
    console.debug('Action:fetchWorkspaceYoutubeKey', payload)
    return proxy
      .post(fetchYoutubeApikeyURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        return false
      })
      .catch((error) => {
        console.debug('Exception in fetchWorkspaceYoutubeKey', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },
  async updateWorkspaceYoutubeKey(
    { commit, getters, rootGetters, dispatch },
    payload
  ) {
    console.debug('Action:updateWorkspaceYoutubeKey', payload)
    return proxy
      .post(updateYoutubeApikeyURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        let message = response.data.message
          ? response.data.message
          : UNKNOWN_ERROR

        // remove html tags
        const txt = document.createElement('textarea')
        txt.innerHTML = message
        message = txt.value

        message = message.replace(/(<([^>]+)>)/gi, '')
        message = message.trim()

        dispatch('toastNotification', { message, type: 'error' })
        return false
      })
      .catch((error) => {
        console.debug('Exception in fetchWorkspaceYoutubeKey', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },
  async removeWorkspaceYoutubeKey({ dispatch }, payload) {
    console.debug('Action:removeWorkspaceYoutubeKey', payload)
    return proxy
      .post(removeYoutubeApikeyURL, payload)
      .then((response) => {
        if (response.data.status === true) return response
        const message = response.data.message
          ? response.data.message
          : UNKNOWN_ERROR
        dispatch('toastNotification', { message, type: 'error' })
        return false
      })
      .catch((error) => {
        console.debug('Exception in removeWorkspaceYoutubeKey', error)
        dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
        return false
      })
  },
  // removeHtmlTag (text) {
  //   // for decoding html entities
  //   let txt = document.createElement('textarea')
  //   txt.innerHTML = text
  //   text = txt.value
  //
  //   text = text.replace(/(<([^>]+)>)/ig, '')
  //   text = text.trim()
  //
  //   return text
  // }
}

// mutations
const mutations = {
  [workspaceTypes.SET_ACTIVE_WORKSPACE](state, workspace) {
    if (!workspace) {
        workspace = getDefaultActiveWorkspace()
    }
    state.workspaces.activeWorkspace = workspace

    // get the members name and update those

    const members = []
    const membersIds = []
    const memberDetails = []
    if (workspace?.members) {
      workspace.members.filter((member) => {
        if (member.status === 'joined') {
          if (member.user) {
            if (member.user.firstname && member.user.lastname) {
              // space remove , if firstname or lastname contain space it will cause mention issue
              members.push(
                member.user.firstname.replaceAll(' ', '') +
                  member.user.lastname.replaceAll(' ', '')
              )
              membersIds.push(member.user._id)
              console.log(member)
              memberDetails.push({
                name:
                  member.user.firstname.replaceAll(' ', '') +
                  member.user.lastname.replaceAll(' ', ''),
                display_name:
                  member.user.firstname + ' ' + member.user.lastname,
                image: member.user.image || null,
                role: member.role || null,
                membership: member.membership || null,
              })
            }
          }
        }
      })
    }
    state.workspaces.activeWorkspaceMembersName = members
    state.workspaces.activeWorkspaceMembersIds = membersIds
    state.workspaces.activeworkspaceMembersDetails = memberDetails

    if (!state.workspaces.activeWorkspace.onboarding_steps) {
      state.workspaces.activeWorkspace.onboarding_steps = {
        watch_video: {
          status: true,
        },
        connect_social_account: {
          status: true,
        },
        create_first_post: {
          status: true,
        },
        content_category: {
          status: true,
        },
        discover_content: {
          status: true,
        },
        invite_team: {
          status: true,
        },
      }

      state.workspaces.activeWorkspace.onboarding = true
    }
  },

  [workspaceTypes.SET_WORKSPACE](state, value) {
    if (value) {
      value.id = value._id
      value.logoSuggestions = []
      state.workspaceDetails = value

      console.log('SET_WORKSPACE SET_WORKSPACE ', value)

      if (!state.workspaceDetails.onboarding_steps) {
        state.workspaceDetails.onboarding_steps = {
          watch_video: {
            status: true,
          },
          connect_social_account: {
            status: true,
          },
          create_first_post: {
            status: true,
          },
          content_category: {
            status: true,
          },
          discover_content: {
            status: true,
          },
          invite_team: {
            status: true,
          },
        }

        state.workspaceDetails.onboarding = true
      }
    } else {
      state.workspaceDetails = getDefaultWorkspaceDetails()
    }
  },

  [workspaceTypes.SET_WORKSPACE_LOGO](state, value) {
    state.workspaceDetails.logo = value
  },

  [workspaceTypes.SET_WORKSPACE_SLUG_AVAILABLE_LOADER](state, value) {
    state.workspaceSlugAvailableLoader = value
  },
  [workspaceTypes.SET_WORKSPACE_TIMEZONE](state, value) {
    state.workspaceDetails.timezone = value
  },
  [workspaceTypes.SET_WORKSPACE_FIRST_DAY](state, value) {
    state.workspaceDetails.firstDay = value
  },
  [workspaceTypes.SET_WORKSPACE_SLUG](state, value) {
    state.workspaceDetails.slug = value
  },
  [workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS](state, value) {
    state.workspaceDetails.logoSuggestions = value
  },
  [workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER](state, value) {
    state.workspaceLogoSuggestionLoader = value
  },
  [workspaceTypes.SET_WORKSPACE_ITEMS](state, value) {
    state.workspaces.items = value
  },

  [workspaceTypes.SET_WORKSPACE_SEARCH](state, search) {
    state.workspaces.search = search
  },

  [workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION](state, value) {
    state.workspaceSlugValidation = value
  },
  [workspaceTypes.SET_WORKSPACE_LOADED](state, value) {
    state.workspaceLoaded = value
  },
  [workspaceTypes.SET_WORKSPACE_SUPER_ADMIN](state, value) {
    state.workspaceDetails.superAdmin = value
  },
  [workspaceTypes.SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS](state, value) {
    state.workspaces.activeWorkspace.onboarding_steps = { ...value }

    // state.workspaces.activeWorkspace.onboarding_steps = {
    //   watch_video: value.watch_video.status,
    //   connect_social_account: value.connect_social_account.status,
    //   create_first_post: value.create_first_post.status,
    //   content_category: value.content_category.status,
    //   discover_content: value.discover_content.status,
    //   invite_team: value.invite_team.status,
    // }
  },
  [workspaceTypes.SET_WORKSPACE_PREFERENCES](state, value) {
    const activeWorkspaceIndex = state.workspaces.items.findIndex(
      (item) => item.workspace_id === state.workspaces.activeWorkspace._id
    )
    if (activeWorkspaceIndex !== -1) state.workspaces.items[activeWorkspaceIndex].preferences = value
  },
  [workspaceTypes.SET_WORKSPACE_USERMAVEN_ANALYTICS_URL](state, value) {
    state.workspaces.activeWorkspace.usermaven_link = value
  },
  [workspaceTypes.SET_LAST_YOUTUBE_CONSENT_TIME](state, value) {
    state.workspaces.activeWorkspace.last_youtube_consent_time = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
