<template>
  <div
    ref="customTooltip"
    v-click-away="closeTooltip"
    :class="{ hoverEffect: isHover, show: toggleStatus }"
    class="custom_tooltip_click"
  >
    <slot name="iconHtml"></slot>
    <div :style="{ width: width + 'px' }" :class="'tool_tip_box ' + position">
      <div class="tool_tip_inner">
        <span
          v-if="isClick"
          class="close_tooltip"
          @click.self="toggleTooltip($event)"
          >&times;</span
        >
        <slot name="htmlContent"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    width: {
      type: Number,
      default: 180,
    },
    position: {
      type: String,
      default: 'top_center',
    },
    isHover: {
      type: Boolean,
      default: false,
    },
    isClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleStatus: false,
    }
  },
  mounted() {
    if (this.isClick) {
      this.$refs.customTooltip.addEventListener(
        'click',
        this.toggleTooltip,
        false
      )
    }
  },
  methods: {
    preventClose(e) {
      return false
    },
    closeTooltip(e) {
      e.stopPropagation()
      this.toggleStatus = false
    },
    toggleTooltip(e) {
      e.stopPropagation()
      this.toggleStatus = !this.toggleStatus
    },
  },
  computed: {
    ...mapGetters([]),
  },
}
</script>

<style lang="less" scoped>
@import '~@assets/less/basic/_mixins';
@import '~@assets/less/basic/_colors';
.custom_tooltip_click {
  position: relative;
  cursor: pointer;
  .tool_tip_box {
    position: absolute;
    z-index: 999;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    .border-radius(5px);
    -webkit-box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.67);
    -moz-box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.67);
    box-shadow: 0 0 15px 0 rgba(184, 189, 209, 0.67);
    background-color: @white;
    border: 1px solid fade(@ds-lightGray, 13%);
    .tool_tip_inner {
      padding: 20px;
      position: relative;
      text-align: left;
      .close_tooltip {
        position: absolute;
        right: 5px;
        top: 2px;
        cursor: pointer;
        font-size: 22px;
        color: @ds-lightGray;
      }
      h3 {
        font-size: 1.125rem;
        margin-bottom: 0.625rem;
      }
      p {
        font-size: 0.875rem;
        margin-bottom: 8px;
        line-height: 21px;
      }
      a {
        color: @ds-royalBlueMedium;
      }
      strong {
        font-weight: 600;
        color: @c-darkBlack;
      }
      &:after,
      &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 8px solid @white;
      }
      &:before {
        border-top: 8px solid @c-darkBlack;
      }
    }
  }
  .tool_tip_box.top_center {
    bottom: 118%;
    transform: translateX(-50%);
    left: 50%;
    right: 0;
    .tool_tip_inner {
      &:after,
      &:before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .tool_tip_box.bottom_center {
    top: 118%;
    transform: translateX(-50%);
    left: 50%;
    right: 0;
    .tool_tip_inner {
      &:after,
      &:before {
        bottom: 100%;
        left: ~'calc(50% - 1.125rem)';
        transform: rotate(-180deg) translateX(-50%);
      }
    }
  }

  .tool_tip_box.top_right {
    bottom: 118%;
    left: 15px;
    .tool_tip_inner {
      &:after,
      &:before {
        top: 100%;
        left: 20px;
      }
    }
  }

  .tool_tip_box.top_left {
    bottom: 118%;
    right: 15px;
    .tool_tip_inner {
      &:after,
      &:before {
        top: 100%;
        right: 20px;
      }
    }
  }

  .tool_tip_box.left_middle {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    .tool_tip_inner {
      &:after,
      &:before {
        top: 50%;
        left: 97%;
        transform: rotate(-90deg);
      }
    }
  }
}
.custom_tooltip_click.hoverEffect {
  &:hover {
    .tool_tip_box {
      opacity: 1;
      visibility: visible;
    }
  }
}
.custom_tooltip_click.show {
  .tool_tip_box {
    opacity: 1;
    visibility: visible;
  }
}
</style>
