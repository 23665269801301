<template>
  <div
    :class="{
      visible: parentState.modalToggler,
      hidden: !parentState.modalToggler,
    }"
  >
    <!-- Modal -->
    <div
      id="wrapper"
      class="z-1050 fixed flex items-center justify-end p-3 pr-[10px] h-screen min-h-[100vh] top-0 right-1"
    >
      <transition name="slide">
        <div
          v-show="parentState.savedPromptsToggler || parentState.historyToggler"
          class="right-[100%] !h-full flex"
        >
          <!--saved Prompts dialog -->
          <SavedPrompts
            v-show="
              parentState.savedPromptsToggler && !parentState.historyToggler
            "
            :history-toggler="parentState.historyToggler"
            :type="parentState.type"
            :toggle-chat-dialogs="parentState.toggleChatDialogs"
            :handle-custom-prompts="parentState.handleCustomPrompts"
          />
          <!--Chat History dialog -->
          <ChatHistory
            v-show="
              parentState.historyToggler && !parentState.savedPromptsToggler
            "
            :chat-history="parentState.chats"
            :active-chat-id="parentState.activeChat.id"
            :toggle-chat-dialogs="parentState.toggleChatDialogs"
            :fetch-chat-by-id="parentState.fetchChatById"
          />
        </div>
      </transition>
      <!--chat dialog -->
      <div
        ref="chatDialogRef"
        class="z-1050 flex flex-col !w-full min-w-[480px] max-w-[480px] h-full bg-[#FBFCFC] p-0 !rounded-lg transition-all"
        :style="{
          boxShadow: '0 2px 50px 0 rgba(0, 39, 114, 0.15)',
          border: '1px solid rgba(210, 213, 223, 0.50)',
        }"
      >
        <!-- head -->
        <ChatHeader
          :type="parentState.type"
          :history-toggler="parentState.historyToggler"
          :toggle-chat-dialogs="handleClose"
          :handle-new-chat="parentState.handleNewChat"
          @toggle-dialog-size="toggleModalSize"
        >
        </ChatHeader>
        <ChatBox
          v-show="parentState.modalToggler"
          :type="parentState.type"
          :modal-toggler="parentState.modalToggler"
          :is-credit-visible="state.isCreditVisible"
          :active-chat="parentState.activeChat"
          :active-chat-loader="parentState.activeChatLoader"
          :fetch-active-chat="parentState.fetchActiveChat"
          :message-loader="parentState.messageLoader"
          :toggle-chat-dialogs="parentState.toggleChatDialogs"
          :send-message="parentState.sendChatMessage"
          :delete-message="parentState.deleteChatMessage"
          :prompt-input="parentState.promptInput"
          :handle-custom-prompts="parentState.handleCustomPrompts"
          :prompts="prompts"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { reactive, ref, onMounted, onUnmounted } from 'vue'
import ChatBox from '@src/modules/AI-tools/ChatBox.vue'
import SavedPrompts from '@src/modules/AI-tools/SavedPrompts.vue'
import { useStore } from '@state/base'
import ChatHistory from '@src/modules/AI-tools/ChatHistory.vue'
import ChatHeader from '@src/modules/AI-tools/ChatHeader.vue'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { EventBus } from '@common/lib/event-bus'
import { getFavoritePrompts } from '@common/lib/helper'
const store = useStore()
const chatDialogRef = ref(null)
const state = reactive({
  isModalExpanded: false,
  isCreditVisible: false,
})
const props = defineProps({
  backdropClose: {
    type: Boolean,
    default: false,
  },
  parentState: {
    type: Object,
    default: () => {},
  },
})

const prompts = ref([])

const { isComposerModalOpen } = useComposerHelper()

onMounted(() => {
  EventBus.$on('close-chat-modal', () => {
    minimized('closeChatModal')
  })

  EventBus.$on('modal-update-prompts', (data) => {
    prompts.value =  getFavoritePrompts(data.default_prompts, data.custom_prompts) ?? [];
  })
})
onUnmounted(() => {
  EventBus.$off('close-chat-modal')
  EventBus.$off('modal-update-prompts')
})

const toggleModalSize = (value = false) => {
  // state.isModalExpanded = !state.isModalExpanded
  chatDialogRef.value.classList.toggle('!max-w-[720px]', value)
}

const handleClose = (value) => {
  switch (value) {
    case 'closeChatModal':
      minimized(value)
      break
    case 'openHistory':
      props.parentState.toggleChatDialogs(value)
      break
  }
}

const handleBackdropClose = (e) => {
  if (e.target.id === 'wrapper' && !props.backdropClose) {
    minimized('closeChatModal')
  }
}
const minimized = (value) => {
  props.parentState.toggleChatDialogs('closeHelper')
  const minimizedClass = isComposerModalOpen.value
    ? 'minimized-composer'
    : 'minimized'
  chatDialogRef.value.classList.add(minimizedClass)
  setTimeout(() => {
    props.parentState.toggleChatDialogs(value)
    chatDialogRef.value.classList.remove(minimizedClass)
  }, 350)
}
</script>

<style lang="scss" scoped>
.minimized {
  transform: translate(0px, 80%) scale(0.1);
  transition-duration: 400ms;
  opacity: 0.5;
}

.minimized-composer {
  opacity: 0.5;
  transition-duration: 400ms;
  -webkit-transform: translate(110%, 0);
  -ms-transform: translate(110%, 0);
  -o-transform: translate(110%, 0);
  transform: translate(110%, 0);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 500ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
