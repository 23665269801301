<template>
  <div v-if="isLoading">
    <SkeletonBox class="!w-full !h-[400px]"></SkeletonBox>
  </div>
  <div v-else-if="isAllDataFetched" class="analytics-no-data-found">
    <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
    <p>Data is being fetched.</p>
  </div>
  <div v-else-if="!data.length" class="analytics-no-data-found">
    <img
        src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
        alt=""
    />
    <p>No data found.</p>
  </div>
  <div
      v-else
      class="flex border !border-[#E9EFF6]"
      :class="
        type === 'performanceReviewTable'
          ? 'tooltip-handler'
          : 'overflow-x-auto'
      "
  >
    <table class="w-full text-sm text-justify text-gray-900 rounded-lg">
      <thead class="bg-gray-200 rounded-lg text-sm text-gray-900">
      <tr>
        <th v-if="showInfoCell" class="p-3 select-none group"
        ><p class="font-weight-500 font-0-95rem ml-1">Competitors</p></th
        >
        <th
            v-for="(listItem, key) in props.headers"
            :key="`${key}_header_${type}`"
            class="p-3 select-none group"
            :class="{
                'cursor-pointer hover:bg-gray-300 min-w-[9rem]':
                  listItem !== 'image',
              }"
            @click="sortColumn(listItem, key)"
        >
          <v-menu
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
          >
            <div class="w-full flex justify-between items-center">
              <p
                  class="font-weight-500 text-left font-0-95rem"
                  v-html="getHeaderTitles(listItem)"
              ></p>
              <i
                  v-if="isSortable(key, listItem)"
                  class="fa text-gray-700 group-hover:text-gray-900"
                  :class="state.isSorted ? 'fa-sort-desc' : 'fa-sort-asc'"
              ></i>
            </div>
            <template v-slot:popper>
              <p
                  class="text-gray-900"
                  v-html="getTableHeaderTooltips(listItem, platformName, type, selectedType)"
              ></p>
            </template>
          </v-menu>
        </th>
      </tr>
      </thead>

      <component
          :is="typeToComponent[type]"
          :key="getTableKey(type)"
          :is-sorted="state.isSorted"
          :table-data="state.tableData"
          :headers="props.headers"
          :platform-name="platformName"
          :followers-growth-data="
            type === 'performanceReviewTable' ? followersGrowthData : null
          "
          :single-hashtag-data="
            type === 'mostEngagedHashtagsTable' ? singleHashtagData : null
          "
          :single-hashtag-header="
            type === 'mostEngagedHashtagsTable' ? singleHashtagHeader : null
          "
          v-on="$listeners"
      />
    </table>
  </div>
</template>

<script setup>
// libraries
import { reactive, computed, watch } from 'vue'

// components
import PostingActivityTableHelper from '@src/modules/analytics_v3/components/PostingActivityTableHelper.vue'
import HashtagTableHelper from '@src/modules/analytics_v3/components/HashtagTableHelper.vue'
import ComparativeTableHelper from '@src/modules/analytics_v3/components/ComparativeTableHelper.vue'
import BioAnalysisTableHelper from '@src/modules/analytics_v3/components/BioAnalysisTableHelper.vue'

// composable
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useCompetitorHelper from "@src/modules/analytics_v3/composables/useCompetitorHelper";

const props = defineProps({
  showInfoCell: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "Competitors' Performance Review",
  },
  data: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: 'review',
  },
  maxValues: {
    type: Object,
    default: () => {},
  },
  minValues: {
    type: Object,
    default: () => {},
  },
  indexToSort: {
    type: Number,
    default: 0,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  followersGrowthData: {
    type: Object,
    default: () => {},
  },
  selectedType: {
    type: String,
    default: '',
  },
  allAvailableTypes: {
    type: Array,
    default: () => [],
  },
  singleHashtagData: {
    type: Object,
    default: () => {},
  },
  singleHashtagHeader: {
    type: Array,
    default: () => [],
  },
  colors: {
    type: Array,
    default: () => [],
  },
  isAllDataFetched: {
    type: Boolean,
    default: false,
  },
  platformName: {
    type: String,
    default: ''
  }
})

const { getHeaderTitles, getTableHeaderTooltips } = useCompetitorHelper()

const state = reactive({
  tableData: computed(() => props.data),
  singleHashDataArray: [],
  isSorted: true,
  sortedHeaderKey: props.indexToSort,
  isLoading: false,
  expandedRowIndex: [],
})

// dynamic component handling
const typeToComponent = {
  performanceReviewTable: ComparativeTableHelper,
  postingActivityTableByType: PostingActivityTableHelper,
  mostEngagedHashtagsTable: HashtagTableHelper,
  bioAnalysisTableData: BioAnalysisTableHelper,
}

watch(
    () => props.singleHashtagData,
    (newData) => {
      state.isLoading = false
      state.singleHashDataArray.push(newData)
    }
)

/**
 * @description checks whether to show the sorting icon or not.
 * @param key
 * @param headerName
 * @returns {boolean}
 */
const isSortable = (key, headerName) => {
  const nonSortableItems = ['tag', 'companies_using', 'biography']
  return !nonSortableItems.includes(headerName) && key === state.sortedHeaderKey
}

/**
 * @description called when any thead is clicked in order to sort the respective column
 * @param header
 * @param index
 */
const sortColumn = (header, index) => {
  if (state.sortedHeaderKey === index && state.isSorted) {
    state.tableData?.sort((a, b) => b[header] - a[header])
    state.isSorted = false
    return
  }
  state.sortedHeaderKey = index
  state.isSorted = true
  state.tableData?.sort((a, b) => a[header] - b[header])
}

const getTableKey = (type) => {
  switch (type) {
    case 'performanceReviewTable':
      return `${state.tableData[0].business_account_id}_comparativeTable`
    case 'postingActivityTableByType':
      return `${state.tableData[0].businessAccountId}_postingActivityTableByType`
    case 'mostEngagedHashtagsTable':
      return `${state.tableData[0].tag}_mostEngagedHashtagsTable`
    case 'bioAnalysisTableData':
      return `${state.tableData[0].business_account_id}_bioAnalysis`
    default:
      return ''
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1417px) {
  .tooltip-handler {
    overflow-x: auto;
  }
}
</style>
