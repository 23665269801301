<script>
import { mapGetters } from 'vuex'
// import { itemCalanderPlatformsCount } from '@common/lib/planner'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils.js'
import PlatformItem from './PlatformItem'
import usePlannerHelper from '@/src/modules/planner_v2/composables/usePlannerHelper'

const { itemCalanderPlatformsCount } = usePlannerHelper()

export default {
  components: { PlatformItem },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      integrations: blogIntegrationsNames,
    }
  },
  computed: {
    ...mapGetters(['getSocialIntegrationsNames']),
    hasBlogSelection() {
      return this.item.blog_selection
    },
    hasSocialSelection() {
      return (
        this.item.account_selection &&
        Object.keys(this.item.account_selection)?.length > 0
      )
    },
    morePlatformSectionStatus() {
      return itemCalanderPlatformsCount(this.item) > 3
    },
    morePlatformCount() {
      return itemCalanderPlatformsCount(this.item) - 3
    },
    isCalenderView() {
      return (
        this.$route.name === 'calender_plans' ||
        this.$route.name === 'planner_calendar_v2'
      )
    },
  },
  methods: {
    // ...importedMethods,
    hasSpecificBlogSelection(type) {
      return (
        this.item.blog_selection[type] && this.item.blog_selection[type].website
      )
    },
    hasSpecificSocialSelection(type) {
      return (
        this.item.account_selection[type] &&
        this.item.account_selection[type].length
      )
    },
  },
}
</script>

<template>
  <div class="users">
    <template v-if="hasBlogSelection">
      <template
        v-for="(integration, index) in integrations"
        :key="`integration_${index}`"
      >
        <template v-if="hasSpecificBlogSelection(integration)">
          <PlatformItem :item="item" :type="integration"></PlatformItem>
        </template>
      </template>
    </template>

    <template v-if="isCalenderView && hasSocialSelection">
      <template v-for="(platforms, type) in item.account_selection" :key="type">
        <PlatformItem
          v-for="(platform, platformIndex) in platforms"
          :key="`${platformIndex}_platform`"
          :item="item"
          :type="type"
          :platform="getFreshAccount(type, platform)"
          :is-calender-view="true"
        />
      </template>

      <div
        v-if="morePlatformSectionStatus"
        class="profile_picture d-flex align-items-start"
      >
        <div class="picture_block picture_block_background">
          <div class="img text count-number">+{{ morePlatformCount }}</div>
        </div>
      </div>
    </template>

    <template v-if="!isCalenderView && hasSocialSelection">
      <template
        v-for="(type, index) in getSocialIntegrationsNames"
        :key="`type_${index}`"
      >
        <template v-if="hasSpecificSocialSelection(type)">
          <template
            v-for="(platform, platformIndex) in item.account_selection[type]"
            :key="`${platformIndex}_platform`"
          >
            <PlatformItem
              :item="item"
              :type="type"
              :platform="getFreshAccount(type, platform)"
            ></PlatformItem>
          </template>
        </template>
      </template>

      <div
        v-if="morePlatformSectionStatus"
        class="d-inline-block align-middle ml-3 circle_boxes_inline align-middle"
      >
        <div
          class="circle_box d-flex align-items-center justify-content-center"
        >
          <span class="text">+{{ morePlatformCount }}</span>
          <!--          <v-menu-->
          <!--              :popper-triggers="['hover']"-->
          <!--              placement="bottom-center"-->
          <!--              class="costum_pop_over"-->
          <!--              popper-class="tooltip-full more_acounts_pop_over"-->
          <!--          >-->
          <!--             <span class="text">+{{ morePlatformCount }}</span>-->
          <!--          <template v-slot:popper>-->
          <!--           <template v-if="hasSocialSelection">-->
          <!--             <template v-for="type in getSocialIntegrationsNames">-->
          <!--                <template v-if="hasSpecificSocialSelection(type)">-->
          <!--                 <template v-for="platform in item.account_selection[type]">-->
          <!--                <platform-item-pop-over-->
          <!--                 :item="item"-->
          <!--                 :type="type"-->
          <!--                 :platform="getFreshAccount(type, platform)"-->
          <!--                ></platform-item-pop-over>-->
          <!--           </template>-->
          <!--          </template>-->
          <!--        </template>-->
          <!--       </template>-->
          <!--      </template>-->
          <!--        </v-menu>-->
        </div>
      </div>
    </template>
    <!--<div  class="profile_picture d-flex align-items-start">-->
    <!--<div class="picture_block picture_block_background ">-->
    <!--<div  class="img text count-number">+34</div>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>
<!--<style>-->

<!--</style>-->
