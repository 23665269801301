<template>
  <div
    class="inline-block text-left w-full"
    :class="{ relative: isOpen }"
    style=""
  >
    <div
      class="inline-flex pl-2 pr-3 py-3 items-center w-full bg-cs-metalgray hover:bg-cs-foggray focus:outline-none"
      aria-expanded="true"
      aria-haspopup="true"
      @click="handleOpen"
    >
      <slot name="channel"> Channel Name </slot>
      <div class="flex items-center ml-auto">
        <i
          class="fas fa-chevron-down block transition mr-2 text-gray-800 p-1 cursor-pointer text-xs"
          :class="{ 'fa-rotate-180': isOpen }"
        ></i>
        <CstSimpleCheckbox
          :value="checked"
          :disabled="disabledSelectAll"
          @input="selectAllChannelAccounts($event)"
        />
      </div>
    </div>
    <div
      v-show="isOpen"
      class="origin-top-right relative w-100 rounded-xl focus:outline-none z-1"
    >
      <slot name="accounts"> </slot>
    </div>
  </div>
</template>

<script>
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
export default {
  name: 'AccountDropdown',
  components: {
    CstSimpleCheckbox,
  },
  mixins: [ComposerHelper],
  provide() {
    return {
      ctx: this,
    }
  },
  props: {
    selectAll: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: '',
    },
    disabledSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.open,
      checked: false,
    }
  },
  computed: {
    // getBackground: function () {
    //   return this.getSocialBackground(this.channel)
    // },
  },
  watch: {
    open: function (val) {
      this.isOpen = val
    },
    selectAll: function (val) {
      this.checked = val
      this.selectAllChannelAccounts(val)
    },
  },
  mounted() {
    this.checked = this.selectAll
  },
  methods: {
    // handleClickAway () {
    //   this.isOpen = false
    // },
    handleOpen: function (event) {
      console.log('handleOpen', event.target)
      if (
        !event.target.classList.contains('check-icon') &&
        event.target.tagName !== 'INPUT'
      ) {
        this.isOpen = !this.isOpen
      }
    },

    selectAllChannelAccounts: function (value) {
      console.debug('selectAllChannelAccounts', this.channel, value)
      this.$emit('selectChannelAccounts', this.channel, value)
    },
  },
}
</script>

<style scoped></style>
