import proxy from '@common/lib/http-common'
import { LUMOTIVE_INBOX, LUMOTIVE_INBOX_SAVED_REPLIES } from './constants'
import { commonMethods } from './common-methods'

export default {
  state: {
    status: true,
    filter: null
  },
  getters: {
    getInboxFilter (state) {
      return state.filter
    }
  },
  mutations: {
    setInboxFilter(state, payload) {
      if (payload) {
        state.filter = { ...state.filter, ...payload }
      } else {
        state.filter = null
      }
      if(state.filter?.all_channels){
        state.filter.count = 0
        state.filter.count += state.filter.all_channels?.facebook ? state.filter.all_channels.facebook.length : 0
        state.filter.count += state.filter.all_channels?.instagram ? state.filter.all_channels.instagram.length : 0
        state.filter.count += state.filter.all_channels?.linkedin ? state.filter.all_channels.linkedin.length : 0
        state.filter.count += state.filter.all_channels?.gmb ? state.filter.all_channels.gmb.length : 0
      }
    }
  },
  actions: {
    markAsRead ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        inboxDetail: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/markAsRead', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.inboxDetail = response.data.inbox_detail
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    syncAllData ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/syncAllDetails', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    fetchSideBarDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        channels: null,
        tags: null,
        conversation_counts: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/fetchSideBarDetails', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.channels = response.data.channels
            responseObj.tags = response.data.tags
            responseObj.conversation_counts = response.data.conversation_counts
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    fetchFilteredInboxDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        conversations: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/fetchAllInboxDetails', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.conversations = response.data.conversations
            return responseObj
          }
          return response
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    fetchPostComments ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        total: null,
        conversations: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/comments/fetch', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.total = response.data.total
            responseObj.comments =
              payload.platform === 'twitter'
                ? response.data.tweets
                : response.data.comments
            return responseObj
          }
          return response
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    // fetch all messages against a conversation
    fetchMessages ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        messages: null,
        next: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/messages/fetch', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.messages = response.data.messages
            responseObj.next = response.data.next
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    // fetch all messages against a conversation
    inboxStatusChangeAction ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/status/update', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    // reply to conversation against a conversation
    reply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/reply', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    likeFacebookComment ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/likeFacebookComment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    hideUnhideComment ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/hideUnhideComment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return error.response.data
        })
    },
    deleteComment ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/deleteComment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return error.response.data
        })
    },

    addNoteToInboxDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        note: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/addNote', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.note = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    // set inbox usage to workspace
    setInboxUsage ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        resp: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/set/usage', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.resp = response.data.resp
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    // assign a new tag to conversation
    updateInboxTags ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        inbox_details: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/updateInboxTags', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.inbox_details = response.data.inbox_details
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    deleteTagsFromWorkspace ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        counts: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/deleteTagsFromWorkspace', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.counts = response.data.count
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    fetchWorkspaceTags ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tags: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/fetchWorkspaceTags', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.tags = response.data.tags_list
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    createTagDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tagDetails: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/createTags', payload)
        .then((response) => {
          // service call successful
          const resp = response.data
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = resp.message
            responseObj.tagDetails = resp.tag

            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    updateBookmarkedMessages ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/updateBookmark', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    fetchBookmarkedMessages ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/messages/fetchAllStarredMessages', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    fetchFilteredBookmarkMessages ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(
          LUMOTIVE_INBOX + '/messages/fetchFilteredBookmarkMessages',
          payload
        )
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    updateUserDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/updateUserDetails', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    bulkUpdate ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/status/update/bulk', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },

    // saved replies start here
    fetchSavedReplyVariables ({ commit, getters, dispatch }) {
      const responseObj = {
        isValid: null,
        message: null,
        variables: null
      }
      return proxy
        .get(LUMOTIVE_INBOX_SAVED_REPLIES + '/variable/fetch')
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.variables = response.data.variables
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    fetchSavedReplies ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReplies: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/fetch', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReplies = response.data.saved_replies
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    createSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/create', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    updateSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/update', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    deleteSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/delete', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    },
    selectSavedReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/select', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response.data.message
          )
        })
    }
  }
}
