<template>
  <div class="auth_parent_container">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Not an approver?<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Approver login</h2>
              <p class="text-md text-[#757A8A] mt-5">Enter email address below and we’ll get you a login access.</p>
            </div>

            <div class="flex flex-col gap-2 mt-12">

              <!-- Email -->
              <CstFloatingLabelInput
                  id="email"
                  v-model="email"
                  type="email"
                  label="Email Address"
                  show-icon-left
                  class="!h-[3.3rem]"
              >
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  :disabled="loginLoader"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  type="submit"
                  @click.prevent="sendMagicLink"
              >
                <span class="w-full">Send login email
                  <img
                      v-if="loginLoader"
                      style="width: 20px; margin-left: 8px"
                      src="../assets/img/common/gif_loader_white.gif"
                      alt=""
                  />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginWithMagicLinkURL } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");
export default {
  name: 'MagicSignIn',
  components: {
    LoginSideComponent,
    CstFloatingLabelInput,
  },
  data() {
    return {
      email: '',
      loginLoader: false,
    }
  },
  methods: {
    async sendMagicLink() {
      console.debug(this.email)
      const parameters = { email: this.email }
      this.loginLoader = true
      const resp = await this.$http
        .post(loginWithMagicLinkURL, parameters)
        .catch((err) => {
          if (err.response.status === 500) {
            this.alertMessage(UNKNOWN_ERROR, 'error')
            return false
          }
        })
        .finally(() => {
          this.loginLoader = false
        })
      // this.loginLoader = false

      if (resp.data.status === false) {
        this.alertMessage(resp.data.message, 'error')
      } else {
        this.alertMessage(
          'We sent you an email. Click the link in the email to log in instantly.',
          'success'
        )
      }
    },
  },
}
</script>
