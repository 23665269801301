<template>
  <div class="assistant_main_block">
    <div class="d-flex align-items-center clear">
      <h2 class="title">Assistant</h2>
    </div>
    <div class="assistant_content">
      <div class="icon_tabs">
        <ul class="tab_ul">
          <li v-tooltip.top="'Articles'" class="tab_li">
            <!--:class="{active:getSearchContentType == 'article'}"-->
            <a
              :class="{ active: getAssistantContentType == 'article' }"
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                globe_gray_icon
              "
              href=""
              @click.prevent="changeAssistantType('article')"
            >
              <i class="cs-world-o"></i>
            </a>
          </li>
          <li v-tooltip.top="'Youtube'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'youtube' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                youtube_gray_icon
              "
              @click.prevent="changeAssistantType('youtube')"
            >
              <i class="cs-youtube"></i>
            </a>
          </li>
          <li v-tooltip.top="'Dailymotion'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'dailymotion' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                dailymotion_gray_icon
              "
              @click.prevent="changeAssistantType('dailymotion')"
            >
              <i class="cs-dailymotion"></i>
            </a>
          </li>

          <li v-tooltip.top="'Flickr'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'flickr' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                flickr_gray_icon
              "
              @click.prevent="changeAssistantType('flickr')"
            >
              <i class="cs-flickr"></i>
            </a>
          </li>

          <li v-tooltip.top="'Imgur'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'imgur' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                imgur_gray_icon
              "
              @click.prevent="changeAssistantType('imgur')"
            >
              <i class="cs-imgur"></i>
            </a>
          </li>

          <li v-tooltip.top="'pixabay'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'pixabay' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                pixabay_gray_icon
              "
              @click.prevent="changeAssistantType('pixabay')"
            >
              <i class="cs-pixabay"></i>
            </a>
          </li>

          <li v-tooltip.top="'giphy'" class="tab_li">
            <a
              :class="{ active: getAssistantContentType == 'giphy' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                giphy_gray_icon
              "
              @click.prevent="changeAssistantType('giphy')"
            >
              <i class="cs-giphy"></i>
            </a>
          </li>

          <li v-tooltip.top="'pocket'" class="tab_li last_li">
            <a
              :class="{ active: getAssistantContentType == 'pocket' }"
              href=""
              class="
                tab_a
                d-flex
                align-items-center
                justify-content-center
                pocket_gray_icon
              "
              @click.prevent="changeAssistantType('pocket')"
            >
              <i class="cs-pocket"></i>
            </a>
          </li>

          <li
            v-tooltip.top="capitalizeFirstLetter(getAssistantContentType)"
            class="last_li tab_li"
            :class="{
              hide: !(
                getAssistantContentType == 'quotes' ||
                getAssistantContentType == 'favorite'
              ),
            }"
          >
            <!--@click.prevent="changeAssistantType(getAssistantContentType)"-->
            <a
              href="javascript:;"
              class="
                active
                tab_a
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <i
                :class="{
                  'cs-left-quote ': getAssistantContentType == 'quotes',
                  'cs-star ': getAssistantContentType == 'favorite',
                }"
              ></i>
            </a>
          </li>

          <li class="tab_li">
            <div class="dropdown default_style_dropdown assistant_dropdown">
              <div class="dropdown_header dots_icon_v" data-toggle="dropdown">
                <i class="cs-dots-v"></i>
              </div>

              <div class="dropdown-menu dropdown-menu-right">
                <ul class="inner">
                  <li
                    class="list_item"
                    :class="{ 'd-none': getAssistantContentType == 'quotes' }"
                  >
                    <a
                      href=""
                      class="d-flex align-items-center quotes_gray_icon"
                      @click.prevent="changeAssistantType('quotes')"
                    >
                      <i class="cs-left-quote"></i>
                      <span class="icon_text">Quotes</span>
                    </a>
                  </li>

                  <li
                    class="list_item"
                    :class="{ 'd-none': getAssistantContentType == 'favorite' }"
                  >
                    <a
                      href=""
                      class="d-flex align-items-center favorite_gray_icon"
                      @click.prevent="changeAssistantType('favorite')"
                    >
                      <i class="cs-star"></i>
                      <span class="icon_text">Favorite</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="assistant_filters">
        <div
          v-if="getAssistantContentType !== 'favorite'"
          class="search-bar-input"
        >
          <div class="search-bar-inner">
            <input
              v-model="search"
              type="text"
              placeholder="Search by  keyword..."
              @keyup.enter="searchByKeywordAssistant(search)"
            />
            <button
              class="search_btn"
              @click.prevent="searchByKeywordAssistant(search)"
            >
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
        <div class="bottom_filters m-1">
          <template
            v-if="
              getAssistantContentType == 'youtube' &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <VideoSortFilter></VideoSortFilter>
            </div>
          </template>
          <template
            v-if="
              getAssistantContentType == 'facebook' &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <FacebookDate class="assistant_datepicker ml-0"></FacebookDate>
            <!--ml-2-->
            <FacebookSort class="with_background_open_blue"></FacebookSort>
            <FacebookType class="text-left"></FacebookType>
          </template>
          <template
            v-if="
              getAssistantContentType == 'twitter' &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <TwitterLanguage></TwitterLanguage>
            </div>
          </template>
          <template
            v-if="
              getAssistantContentType == 'dailymotion' &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <DailymotionFilter></DailymotionFilter>
            </div>
          </template>
          <template
            v-if="getAssistantContentType == 'favorite'"
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <div
                v-tooltip.top="'Sort By'"
                class="
                  dropdown
                  with_background_open_blue
                  option_dropdown
                  default_style_dropdown
                "
              >
                <FilterSort :is-topic="true"></FilterSort>
              </div>
              <FavoriteFilter></FavoriteFilter>
            </div>
          </template>
          <template
            v-if="getAssistantContentType == 'pocket'"
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <FilterPocket></FilterPocket>
            </div>
          </template>
          <template
            v-if="
              (getAssistantContentType == 'flickr' ||
                getAssistantContentType == 'imgur' ||
                getAssistantContentType == 'giphy' ||
                getAssistantContentType == 'pixabay') &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <div class="composer_dropdown_list">
              <ImagesFilter></ImagesFilter>
            </div>
          </template>
          <template
            v-if="
              getAssistantContentType == 'article' &&
              getFilters.search.trim().length > 0
            "
            :class="{ disable_click: getTopicDisabled }"
          >
            <!--d-flex align-items-center-->
            <div class="filter_row text-left">
              <FilterDate class="assistant_datepicker ml-0"></FilterDate>
              <div
                v-tooltip.top="'Sort By'"
                class="
                  dropdown
                  with_background_open_blue
                  option_dropdown
                  default_style_dropdown
                "
              >
                <FilterSort></FilterSort>
              </div>
            </div>

            <div class="filter_row d-flex align-items-center mb-0">
              <div
                v-tooltip.top="'Region'"
                class="
                  dropdown
                  with_background_open_blue
                  option_dropdown
                  default_style_dropdown
                "
              >
                <FilterRegion></FilterRegion>
              </div>
              <div
                v-tooltip.top="'Language'"
                class="
                  dropdown
                  with_background_open_blue
                  option_dropdown
                  default_style_dropdown
                  ml-2
                "
              >
                <FilterLanguage></FilterLanguage>
              </div>
              <div
                v-tooltip.top="'Length'"
                class="
                  dropdown
                  option_dropdown
                  with_background_open_blue
                  default_style_dropdown
                  hide
                  ml-2
                "
              >
                <FilterType></FilterType>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="
          (getFilters.search.trim().length == 0 &&
            getAssistantContentType !== 'favorite' &&
            getAssistantContentType != 'pocket') ||
          (getAssistantContentType == 'pocket' &&
            getPocketFilter.account == '' &&
            getFilters.search.trim().length == 0)
        "
        class="initial_view_block"
      >
        <div class="assistant_step">
          <div class="left_section">
            <i class="fas fa-search"></i>
          </div>
          <div class="right_section">
            <h4>Step 1</h4>
            <span class="text">Search</span>
            <p
              >Select a channel and find content suggestion with your own
              keywords.</p
            >
          </div>
        </div>

        <div class="assistant_step">
          <div class="left_section">
            <i class="fas fa-hand-point-up"></i>
          </div>
          <div class="right_section">
            <h4>Step 2</h4>
            <span class="text">Select and add to editor</span>
            <p
              >Pick the best items your audience will like, drag and drop into
              the editor.</p
            >
            <img
              src="../../../../assets/img/common/drag_and_drop_icon.png"
              alt=""
              class="drag_drop_icon"
            />
          </div>
        </div>
      </div>

      <div
        v-if="
          getAssistantContentType == 'article' &&
          getFilters.search.trim().length > 0
        "
        class="article_list"
      >
        <div v-if="getSearchLoader.content.discoverySearch" class="p-3">
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
        <template v-else>
          <ArticlePosts></ArticlePosts>
          <template v-if="getArticleLoader && getPosts.length == 0">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <h2>No results found</h2>
                <p>No data found for your search.</p>
              </div>
            </div>
          </template>
          <template v-else-if="getArticleLoader && getPosts.length <= 19">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <h2>No More results found</h2>
              </div>
            </div>
          </template>
          <InfiniteLoading v-else ref="infiniteLoading" @infinite="onInfinite">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <span slot="no-more" class="not_found_text">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
            <NoResultsFound :nore="true"></NoResultsFound>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'favorite' &&
          getCurationFolderText !== 'Select Folder'
        "
        class="article_list"
      >
        <div v-if="getTopicLoader" class="p-3">
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
        <template v-else>
          <ArticlePosts></ArticlePosts>
          <template v-if="getTopicPaginationLoader && getPosts.length == 0">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <h2>No results found</h2>
                <p>No data found for your search.</p>
              </div>
            </div>
          </template>
          <template
            v-else-if="getTopicPaginationLoader && getPosts.length <= 19"
          >
            <!-- FIXME: add a better image for this case -->
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfiniteFavorite"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <span slot="no-more" class="not_found_text">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
            <NoResultsFound :nore="true"></NoResultsFound>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'youtube' &&
          getFilters.search.trim().length > 0
        "
        class="youtube_video_list"
      >
        <template v-if="getDiscoveryVideoLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <youtube></youtube>
          <template v-if="getVideoPaginationLoader && getVideos.length == 0">
            <NoResultsFound></NoResultsFound>
          </template>
          <template
            v-else-if="getVideoPaginationLoader && getVideos.length <= 19"
          >
            <!-- FIXME: add a better image for this case -->
            <!--<span >No more results found for your query.</span>-->
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfiniteVideo"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <NoResultsFound :more="true"></NoResultsFound>
            <span slot="no-results">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'dailymotion' &&
          getFilters.search.trim().length > 0
        "
        class="youtube_video_list"
      >
        <template v-if="getDiscoveryVideoLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <Dailymotion></Dailymotion>
          <template v-if="getVideoPaginationLoader && getVideos.length == 0">
            <NoResultsFound></NoResultsFound>
          </template>
          <template
            v-else-if="getVideoPaginationLoader && getVideos.length <= 19"
          >
            <!-- FIXME: add a better image for this case -->
            <!--<span >No more results found for your query.</span>-->
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfiniteDailymotion"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <NoResultsFound :more="true"></NoResultsFound>
            <span slot="no-results">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'twitter' &&
          getFilters.search.trim().length > 0
        "
        class="twitter_list"
      >
        <template v-if="getDiscoveryTwitterLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <Twitter></Twitter>

          <template
            v-if="getTwitterPaginationLoader && getTweetsContent.length == 0"
          >
            <NoResultsFound></NoResultsFound>
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfiniteTwitter"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>

            <span slot="no-more" class="not_found_text">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
            <span slot="no-results">
              <span class="not_found_text">
                <i class="fa fa-frown-o" aria-hidden="true"></i>
                <span v-if="getTweetsContent.length == 0"
                  >No results found for your query.</span
                >
                <span v-else>
                  <NoResultsFound :more="true"></NoResultsFound>
                </span>
              </span>
            </span>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          (getAssistantContentType == 'flickr' ||
            getAssistantContentType == 'imgur' ||
            getAssistantContentType == 'giphy' ||
            getAssistantContentType == 'pixabay') &&
          getFilters.search.trim().length > 0
        "
        class="flickr_list"
      >
        <template v-if="getImagesLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <FlickrPosts v-if="getAssistantContentType == 'flickr'"></FlickrPosts>
          <ImgurPosts v-if="getAssistantContentType == 'imgur'"></ImgurPosts>
          <GiphyPosts v-if="getAssistantContentType == 'giphy'"></GiphyPosts>
          <PixabayPosts
            v-if="getAssistantContentType == 'pixabay'"
          ></PixabayPosts>

          <template
            v-if="getImagesPaginationLoader && getImagesPosts.length == 0"
          >
            <NoResultsFound></NoResultsFound>
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfiniteImages"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>

            <span slot="no-more" class="not_found_text">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
            <span slot="no-results">
              <span class="not_found_text">
                <i class="fa fa-frown-o" aria-hidden="true"></i>
                <span v-if="getImagesPosts.length == 0"
                  >No results found for your query.</span
                >
                <span v-else>
                  <NoResultsFound :more="true"></NoResultsFound>
                </span>
              </span>
            </span>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'pocket' && getPocketFilter.account !== ''
        "
        class="pocket_list"
      >
        <template v-if="getPocketLoader">
          <beat-loader :color="'#436aff'"></beat-loader>
        </template>
        <template v-else>
          <PocketPosts></PocketPosts>

          <template
            v-if="getPocketPaginationLoader && getPocketPosts.length == 0"
          >
            <NoResultsFound></NoResultsFound>
          </template>
          <InfiniteLoading
            v-else
            ref="infiniteLoading"
            @infinite="onInfinitePocket"
          >
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
            <span slot="no-more" class="not_found_text">
              <NoResultsFound :more="true"></NoResultsFound>
            </span>
            <NoResultsFound :nore="true"></NoResultsFound>
          </InfiniteLoading>
        </template>
      </div>

      <div
        v-if="
          getAssistantContentType == 'quotes' &&
          getFilters.search.trim().length > 0
        "
        class="quotes_list"
      >
        <WebQuotes></WebQuotes>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import youtube from '../../../composer/components/assistant/Youtube'
import NoResultsFound from '../topics/NoResultsFound'
import VideoSortFilter from '../filters/video/VideoSort'

import Twitter from '../../../composer/components/assistant/Twitter'
import TwitterLanguage from '../filters/twitter/FilterTwitterLanguage'

import Dailymotion from '../../../composer/components/assistant/Dailymotion'
import DailymotionFilter from '../filters/video/DailymotionSort'

import FilterType from '../filters/FilterType'
import FilterSort from '../filters/FilterSort'
import FilterRegion from '../filters/FilterRegion'
import FilterLanguage from '../filters/FilterLanguage'
import FilterDate from '../filters/FilterDate'

import ArticlePosts from '../../../composer/components/assistant/Article'

import FlickrPosts from '../../../composer/components/assistant/Flickr'
import GiphyPosts from '../../../composer/components/assistant/Giphy'
import ImgurPosts from '../../../composer/components/assistant/Imgur'
import PixabayPosts from '../../../composer/components/assistant/Pixabay'
import ImagesFilter from '../filters/images/PhotoFilters'

import FavoriteFilter from '../filters/FilterFavorite'

import WebQuotes from '../../../composer/components/assistant/Quotes'

import PocketPosts from '../../../composer/components/assistant/Pocket'
import FilterPocket from '../filters/pocket/FilterPocketAccount'

import { articlesTypes as articleTypes } from '../../store/mutation-types'

export default {
  components: {
    youtube,
    InfiniteLoading,
    NoResultsFound,
    VideoSortFilter,
    TwitterLanguage,
    Twitter,
    Dailymotion,
    DailymotionFilter,
    FilterType,
    FilterSort,
    FilterRegion,
    FilterLanguage,
    FilterDate,
    ArticlePosts,
    FlickrPosts,
    GiphyPosts,
    ImgurPosts,
    PixabayPosts,
    ImagesFilter,
    WebQuotes,
    FavoriteFilter,
    PocketPosts,
    FilterPocket,
  },
  data() {
    return {
      search: '',
    }
  },
  created() {
    this.search = this.getFilters.search
  },

  computed: {
    ...mapGetters([
      'getAssistantContentType',
      'getFilters',
      'getVideoPaginationLoader',
      'getDiscoveryVideoLoader',
      'getVideoPaginationLoader',
      'getVideos',
      'getVideoScroll',
      'getVideoPage',
      'getTopicDisabled',
      'getDiscoveryTwitterLoader',
      'getTweetsContent',
      'getTwitterPaginationLoader',
      'getTwitterScroll',
      'getTwitterContentPage',
      'getTopicLoader',
      'getPosts',
      'getTopicPaginationLoader',
      'getScroll',
      'getArticlesPage',
      'getSearchLoader',
      'getImagesPosts',
      'getImagesPaginationLoader',
      'getImagesLoader',
      'getImagesPage',
      'getImagesScroll',
      'getQuotes',
      'getQuoteLoader',
      'getQuoteScroll',
      'getQuotePage',
      'getCurationFolderText',
      'getArticleLoader',
      'getTopicsScroll',
      'getTopicsPage',
      'getPocketLoader',
      'getPocketPaginationLoader',
      'getPocketPosts',
      'getPocketPage',
      'getPocketScroll',
    ]),
  },
  methods: {
    ...mapActions([
      'setFacebookPage',
      'setVideoPage',
      'setTwitterContentPage',
      'setArticlesPage',
      'setQuotesPage',
      'setPocketPage',
      'topicsContent',
    ]),
    ...mapMutations(['SET_TOPICS_PAGE', articleTypes.SET_ARTICLES_PAGE]),
    onInfiniteFacebook($state) {
      console.log(
        'On facebook infinite',
        this.getFacebookScroll,
        this.getFacebookPage
      )
      if (this.getFacebookScroll) {
        this.setFacebookPage(this.getFacebookPage + 1)
        this.getFacebookData($state)
      }
    },
    onInfiniteVideo($state) {
      console.log('On video infinite', this.getVideoScroll, this.getVideoPage)
      if (this.getVideoScroll) {
        this.setVideoPage(this.getVideoPage + 1)
        this.getYoutubeContentByTopic($state)
      }
    },
    onInfiniteDailymotion($state) {
      console.log(
        'On dailymotion video infinite',
        this.getVideoScroll,
        this.getVideoPage
      )
      if (this.getVideoScroll) {
        this.setVideoPage(this.getVideoPage + 1)
        this.getDailymotionVideos($state)
      }
    },
    onInfiniteTwitter($state) {
      console.log(
        'On twitter infinite',
        this.getTwitterScroll,
        this.getTwitterContentPage
      )
      if (this.getTwitterScroll) {
        this.setTwitterContentPage(this.getTwitterContentPage + 1)
        this.getTwitterData($state)
      }
    },
    async onInfinite($state) {
      console.log('On infinite article', this.getScroll, this.getArticlesPage)
      if (this.getScroll) {
        this[articleTypes.SET_ARTICLES_PAGE](this.getArticlesPage + 1)
        await this.articles($state)
        this.checkComposerDragDrop()
        // this.setArticlesPage(this.getArticlesPage + 1)
        // this.getArticlesContent($state)
      }
    },
    onInfiniteImages($state) {
      console.log(
        'On infinite images',
        this.getImagesScroll,
        this.getImagesPage
      )
      if (this.getImagesScroll) {
        this.setImagesPage(this.getImagesPage + 1)
        this.getImagesContent($state)
      }
    },
    onInfinitePocket($state) {
      console.log(
        'On infinite pocket',
        this.getPocketScroll,
        this.getPocketPage
      )
      if (this.getPocketScroll) {
        this.setPocketPage(this.getPocketPage + 1)
        this.getPocketContent($state)
      }
    },
    onInfiniteQuotes($state) {
      console.log('On infinite quotes', this.getQuoteScroll, this.getQuotePage)
      if (this.getQuoteScroll) {
        // if (this.getCurationTab == 'Article') {
        this.setQuotesPage(this.getQuotePage + 1)
        this.getWebQuotes($state)
        // }
      }
    },
    async onInfiniteFavorite($state) {
      console.log(
        'On article infinite favorite',
        this.getTopicsScroll,
        this.getTopicsPage
      )
      if (this.getTopicsScroll) {
        console.log('Calling...')
        this.SET_TOPICS_PAGE(this.getTopicsPage + 1)
        await this.topicsContent($state)
        this.checkComposerDragDrop()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.assistant_step {
  margin-bottom: 15px;
  padding: 20px 15px;
  border: 1px solid rgba(184, 189, 209, 0.2);
  border-radius: 8px;
  display: flex;
  .left_section {
    i {
      font-size: 1.125rem;
      padding-top: 5px;
      color: #436aff !important;
    }
  }
  .right_section {
    margin-left: 0px !important;
    padding-left: 0px !important;
    p,
    h4,
    .text {
      padding-left: 0.625rem;
    }
    p {
      margin-bottom: 0px;
    }
    .text {
      font-weight: 500;
    }

    h4 {
      font-size: 12px;
      text-transform: uppercase;
      color: #436aff;
    }
  }
}
</style>
