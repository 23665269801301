<script>
import { mapGetters, mapActions } from 'vuex'
import {EventBus} from "@common/lib/event-bus";
export default {
  components: {},
  data() {
    return {
      updateYoutubeTokenLoader: false,
      youtubeKey: '',
      fetchedYoutubeKey: '',
      fetchYotubeApiKeyLoader: false,
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),
  },
  watch: {
    'getActiveWorkspace._id'() {
      this.fetchYoutubeApiKey()
    },
  },
  mounted() {
    EventBus.$on('remove_youtube_key', () => {
      this.youtubeKey = this.fetchedyoutubeKey = ''
    })
    this.fetchYoutubeApiKey()
  },
  beforeUnmount() {
    EventBus.$off('remove_youtube_key')
  },
  methods: {
    ...mapActions(['fetchWorkspaceYoutubeKey', 'updateWorkspaceYoutubeKey']),
    async fetchYoutubeApiKey() {
      this.fetchYotubeApiKeyLoader = true
      const response = await this.fetchWorkspaceYoutubeKey({
        workspace_id: this.getActiveWorkspace._id,
      })
      console.log(response)
      if (response.data && response.data.key) {
        this.youtubeKey = response.data.key
        this.fetchedyoutubeKey = this.youtubeKey
      } else {
        this.youtubeKey = ''
      }
      this.fetchYotubeApiKeyLoader = false
    },

    async updateYoutubeApiKey() {
      if (!this.youtubeKey || this.youtubeKey.length <= 5) {
        this.alertMessage('Please enter a valid Youtube Api Key.', 'error')
        return false
      }
      this.updateYoutubeTokenLoader = true
      const response = await this.updateWorkspaceYoutubeKey({
        workspace_id: this.getActiveWorkspace._id,
        token: this.youtubeKey,
      })
      if (response.data && response.data.status) {
        this.alertMessage('YouTube Data API key has been saved.', 'success')
        this.fetchedyoutubeKey = this.youtubeKey
      }
      this.updateYoutubeTokenLoader = false
    },

    removeAPI() {
      console.debug('Method::removeAccount', this.getActiveWorkspace._id)
      this.$root.$bvModal.show('removeYoutubeApiKey')
    },
  },
}
</script>

<template>
  <!--  id="youtube"-->
  <div id="youtube" class="ps_box">
    <div class="box_heading">
      <h2>YouTube Data API Key</h2>
    </div>
    <div class="box_content">
      <clip-loader
        v-if="fetchYotubeApiKeyLoader"
        class="spinner mt-5 mb-3"
        :color="'#6d76ff'"
        :size="'20px'"
      ></clip-loader>

      <div v-else class="col-7 integration_box_list">
        <div class="field_group">
          <p
            >In order to use YouTube videos in automation recipes, you will need
            to add your YouTube Data API key due to recent quota change.
            <a
              href="https://docs.contentstudio.io/article/715-how-to-get-youtube-data-api-key"
              target="_blank"
              >Please follow this article</a
            >
            to create YouTube Data API Key.
          </p>
        </div>

        <div class="field_group d-flex">
          <input
            id="youtubeToken"
            v-model="youtubeKey"
            type="text"
            placeholder="Type here..."
            style="width: 400px"
          />
          <label for="youtubeToken" class="label-animated"
            >YouTube Data API Key</label
          >
          <i
            v-show="fetchedYoutubeKey"
            v-tooltip.top="'Remove'"
            class="action_icon icon-delete-cs"
            @click.prevent="removeAPI"
          ></i>
        </div>

        <div class="field_group">
          <button
            class="btn btn-studio-theme-space"
            @click.prevent="updateYoutubeApiKey"
          >
            <span
              >Update
              <clip-loader
                v-if="updateYoutubeTokenLoader"
                class="spinner"
                :color="'#ffffff'"
                :size="'12px'"
              ></clip-loader>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action_icon.icon-delete-cs {
  line-height: 1.5;
}
</style>
