<template>
  <div class="social_accounts_tooltip" :class="channels_class">
    <template v-if="has_ids"> </template>
    <template v-else>
      <template v-for="(items, channel_name) in accounts">
        <template v-for="account in items">
          <div class="radio_input_image radio_social_profile">
            <input
              :id="'select' + account._id"
              type="radio"
              name="testprofile"
            />
            <label
              v-tooltip="{
                content: channelTooltipHTML(channel_name, account),
                classes: 'team_tooltip',
                allowHTML: true,
                theme: 'light',
              }"
              :for="'select' + account._id"
              class="radio_right"
            >
              <div :class="['profile_picture ' + channel_name + '-color']">
                <div
                  class="picture_block picture_block_background"
                  :class="{
                    icons_height_md: channels_size === '36px',
                    icons_height_lg: channels_size === '42px',
                  }"
                >
                  <div class="icon">
                    <template v-if="channel_name === 'tiktok'">
                      <img src="@src/assets/img/integration/tiktok-icon_1.svg" alt="tiktok_logo" />
                    </template>
                    <i v-else :class="'cs-' + channel_name"></i>
                  </div>
                  <div
                    class="img"
                    :style="{
                      'background-image': channelBackgroundImage(
                        account,
                        channel_name,
                      ),
                    }"
                  ></div>
                </div>
              </div>
            </label>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    has_ids: {
      default: false,
    },
    accounts: {},
    channels_class: {
      default: 'd-flex align-items-start justify-content-center',
    },
    channels_size: {
      default: '35px',
    },
  },
  computed: {},
  methods: {},
}
</script>

<style lang="less" scoped>
.icons_height_md {
  width: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
}
.icons_height_lg {
  width: 34px !important;
  height: 34px !important;
  min-width: 34px !important;
}
.text-is-left-only {
  text-align: left !important;
}
</style>
