<script setup>
import OverviewSection from '@src/modules/analytics/views/youtube/components/OverviewSection'
import PostsSection from '@src/modules/analytics/views/youtube/components/PostsSection'
import CstAlert from '@ui/Alert/CstAlert'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import { ref } from 'vue'
import { useStore } from '@state/base'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import FilterBar from '@src/modules/analytics/views/youtube/components/FilterBar.vue'
import CstButton from '@ui/Button/CstButton.vue'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'

const { getters } = useStore()

const fetchingData = ref(false)
const mainComponentRef = ref(null)
const {
  selectedAccount,
  isReconnectRequired,
  showConsentPopup,
  isLoading,
  getUserConsent,
  toggleYoutubeAccountData,
} = useYoutubeAnalytics()

const scrollToTop = () => {
  setTimeout(() => {
    mainComponentRef.value?.scrollIntoView({ behavior: 'smooth' })
  }, 0)
}
</script>

<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <FilterBar @scroll-to-top="scrollToTop" />
    <div
      v-if="getters.getYoutubeAccounts?.items?.length <= 0 && !fetchingData"
      class="analytics-main-errors"
    >
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p v-if="getters.getFetchSocialStatus">
        Retrieving Data! Please Wait...
      </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          :to="{ name: 'social', params: { id: 'youtube' } }"
          class="btn btn-studio-theme-space btn-size-small"
          >Connect Youtube Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getters?.getYoutubeAccounts?.items?.length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selectedAccount?.state === 'Added'">
        <AnalyticsLoading :name="selectedAccount.name" />
      </template>
      <template v-else>
        <TabsComponent
          :show-backdrop="
            showConsentPopup ||
            selectedAccount?.preferences?.analytics_data_deleted
          "
          :tabs="['#overview', '#posts']"
          type="youtube"
        >
          <template v-slot:backdrop-content>
            <div
              v-if="showConsentPopup"
              class="mt-20 mx-auto w-[35rem] rounded-xl bg-white py-4"
            >
              <div class="mx-5 font-medium">
                <h4 class="text-xl">Permission Required</h4>
              </div>
              <hr />
              <CstAlert class="mx-5 mb-4 !p-1.5" type="info">
                <b>Note:</b> We need your consent to access and store your
                social media data for accurate analytics and reports.
              </CstAlert>
              <div class="mx-5 px-5">
                <ul class="text-gray-900">
                  <li class="list-disc">
                    We will store authorized data (e.g., analytics, post metrics
                    for as long as you permit us to.
                  </li>
                  <li class="list-disc">
                    Every 30 days, we'll verify your authorization and update
                    the stored data.
                  </li>
                  <li class="list-disc">
                    Public data (e.g., follower counts) will only be stored for
                    30 days and refreshed automatically.
                  </li>
                </ul>
              </div>
              <hr />
              <div class="flex justify-between items-center w-full px-5">
                <a
                  class="text-blue-400 text-sm"
                  href="https://developers.google.com/youtube/terms/developer-policies#e.-handling-youtube-data-and-content"
                  target="_blank"
                >
                  Learn More
                </a>
                <CstButton
                  :disabled="isLoading"
                  :loading="isLoading"
                  class="!bg-green-600 !shadow-none !text-sm"
                  size="small"
                  variant="primary"
                  @click="getUserConsent"
                >
                  Yes, I Agree
                </CstButton>
              </div>
            </div>
            <CstConfirmationPopup
              v-else-if="selectedAccount?.preferences?.analytics_data_deleted"
              class="mt-20 mx-auto"
              display-type="card"
            >
              <template v-slot:head>
                <h4 class="text-xl"> Restore Data </h4>
              </template>
              <template v-slot:body>
                <p
                  >In accordance with your request, your analytical data has
                  been deleted. To restore your analytical data, please grant us
                  access to retrieve it again.</p
                >
              </template>
              <template v-slot:footer>
                <div class="flex justify-between items-center w-full">
                  <a
                    class="text-blue-400 text-sm"
                    href="https://developers.google.com/youtube/terms/developer-policies#e.-handling-youtube-data-and-content"
                    target="_blank"
                  >
                    Learn More
                  </a>
                  <CstButton
                    class="!bg-green-600 !shadow-none !text-sm"
                    size="small"
                    variant="primary"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click="toggleYoutubeAccountData"
                  >
                    Grant Access
                  </CstButton>
                </div>
              </template>
            </CstConfirmationPopup>
          </template>
          <template v-if="selectedAccount && isReconnectRequired" v-slot:alert>
            <CstAlert class="text-left mb-5" type="warn">
              To view your updated data, you must reconnect your YouTube
              account.
              <router-link
                :to="{ name: 'social', params: { id: 'youtube' } }"
                class="text-yellow-600 font-semibold hover:text-yellow-700"
                >Reconnect Now
              </router-link>
            </CstAlert>
          </template>
          <!-- overview tab -->
          <template v-if="selectedAccount" v-slot:overview>
            <OverviewSection />
          </template>

          <!-- posts tab -->
          <template v-slot:posts>
            <PostsSection />
          </template>
        </TabsComponent>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>
