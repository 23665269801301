<template>
  <div class="pagination_style_inner">
    <template v-if="showAllPages">
      <a
        v-for="index in renderedPages"
        :key="index"
        href="#"
        :class="{ active: page === index }"
        @click.prevent="$parent.callInfluencerPagination(type, index)"
      >{{ index }}</a>
    </template>

    <template v-if="!showAllPages">
      <a
        v-if="showFirstPage"
        href="#"
        :class="{ active: page === 1 }"
        @click.prevent="$parent.callInfluencerPagination(type, 1)"
      >1</a>
      <a
        v-if="showDotsAfterFirstPage"
        href="#"
        class="dotted"
        @click.prevent="$parent.callInfluencerPagination(type, page - 2)"
      >...</a>

      <a
        v-for="item in getVisiblePages"
        :key="item"
        href="#"
        :class="{ active: page === item }"
        @click.prevent="$parent.callInfluencerPagination(type, item)"
      >{{ item }}</a>

      <a
        v-if="showDotsBeforeLastPage"
        href="#"
        class="dotted"
        @click.prevent="$parent.callInfluencerPagination(type, page + 2)"
      >...</a>
      <a
        v-if="showLastPage"
        href="#"
        :class="{ active: page === total }"
        @click.prevent="$parent.callInfluencerPagination(type, total)"
      >{{ total }}</a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      renderedPages: [],
      renderTimer: null,
      chunkSize: 100,
      renderDelay: 10
    };
  },
  computed: {
    showAllPages() {
      return this.total < 6;
    },
    showFirstPage() {
      return this.page > 2;
    },
    showDotsAfterFirstPage() {
      return this.page > 3;
    },
    showLastPage() {
      return this.page < this.total - 1;
    },
    showDotsBeforeLastPage() {
      return this.showLastPage && this.page < this.total - 2;
    },
    getVisiblePages() {
      let pageCutLow = Math.max(1, this.page - 1);
      let pageCutHigh = Math.min(this.total, this.page + 1);

      if (this.page === 1) {
        pageCutHigh = Math.min(this.total, 3);
      } else if (this.page === 2) {
        pageCutHigh = Math.min(this.total, 4);
      } else if (this.page === this.total) {
        pageCutLow = Math.max(1, this.total - 2);
      } else if (this.page === this.total - 1) {
        pageCutLow = Math.max(1, this.total - 3);
      }

      return Array.from({ length: pageCutHigh - pageCutLow + 1 }, (_, i) => pageCutLow + i);
    }
  },
  watch: {
    total: {
      immediate: true,
      handler() {
        this.renderPages();
      }
    },
    page: {
      immediate: true,
      handler() {
        this.ensureCurrentPageRendered();
      }
    }
  },
  methods: {
    renderPages() {
      this.renderedPages = [];
      if (this.renderTimer) {
        clearTimeout(this.renderTimer);
      }
      this.renderNextChunk(1);
    },
    renderNextChunk(start) {
      const end = Math.min(start + this.chunkSize - 1, this.total);
      for (let i = start; i <= end; i++) {
        this.renderedPages.push(i);
      }
      if (end < this.total) {
        this.renderTimer = setTimeout(() => {
          this.renderNextChunk(end + 1);
        }, this.renderDelay);
      }
      this.ensureCurrentPageRendered();
    },
    ensureCurrentPageRendered() {
      if (this.showAllPages && !this.renderedPages.includes(this.page)) {
        this.renderedPages.push(this.page);
        this.renderedPages.sort((a, b) => a - b);
      }
    }
  },
  beforeUnmount() {
    if (this.renderTimer) {
      clearTimeout(this.renderTimer);
    }
  }
};
</script>
