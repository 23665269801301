// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    FACEBOOK_API_ENDPOINTS,
    FACEBOOK_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function usePostEngagementOverTime() {
    const {
        fetchCompetitorInfo: fetchPostEngagementOverTime,
        apiResponse: postEngagementOverTimeResponse,
        loading: postEngagementOverTimeLoadingState
    } = useCompetitorsFactory()
    const {reportId, dateRange, timeZone,} = useCompetitorReport()

    const getPostEngagementOverTimeData = async (type = '', facebookId = '') => {
        switch (type) {
            case 'facebook':
                await fetchFbPostEngagementOverTime(facebookId)
                break
            default:
                break
        }
    }

    const fetchFbPostEngagementOverTime = async (facebookId) => {
        if (!reportId.value) return

        const payload = {
            facebook_id: facebookId,
            time_zone: timeZone.value,
            date_filter: dateRange.value,
        }

        try {
            await fetchPostEngagementOverTime(FACEBOOK_API_ENDPOINTS.POST_ENGAGEMENT_OVER_TIME, payload, FACEBOOK_ERROR_MESSAGES.POST_ENGAGEMENT_OVER_TIME)
        } catch (error) {
        }
    }


    return {
        // data
        postEngagementOverTimeLoadingState,
        postEngagementOverTimeResponse,

        // methods
        getPostEngagementOverTimeData
    }
}