import { swalAttributes } from '@common/constants/common-attributes';

export default {
  methods: {
    async exitConfirmation(to = 'automation', title, slug = '', byPass = false) {
      try {
        if (byPass) {
          await this.$router.push({name: to, params: {workspace: slug}});
          return;
        }

        const res = await this.$bvModal.msgBoxConfirm(`You are about to exit ${title} builder, you have unsaved changes that will be lost. Are you sure you want to exit?`, {
          title: 'Please Confirm',
          ...swalAttributes()
        });

        if (res) {
          await this.$router.push({name: to, params: {workspace: slug}});
        }
      } catch (error) {
        console.error('Error during exit confirmation:', error)
      }
    }
  }
}
