export const workspaceTypes = {
  SET_WORKSPACE: 'SET_WORKSPACE',
  SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_WORKSPACE',
  SET_WORKSPACE_TIMEZONE: 'SET_WORKSPACE_TIMEZONE',
  SET_WORKSPACE_SLUG: 'SET_WORKSPACE_SLUG',
  SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER:
    'SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER',
  SET_WORKSPACE_LOGO_SUGGESTIONS: 'SET_WORKSPACE_LOGO_SUGGESTIONS',
  SET_WORKSPACE_LOADED: 'SET_WORKSPACE_LOADED',
  SET_WORKSPACE_SLUG_AVAILABLE_LOADER: 'SET_WORKSPACE_SLUG_AVAILABLE_LOADER',
  SET_WORKSPACE_ITEMS: 'SET_WORKSPACE_ITEMS',
  SET_WORKSPACE_SEARCH: 'SET_WORKSPACE_SEARCH',
  SET_WORKSPACE_LOGO: 'SET_WORKSPACE_LOGO',
  SET_WORKSPACE_SLUG_VALIDATION: 'SET_WORKSPACE_SLUG_VALIDATION',
  SET_WORKSPACE_SUPER_ADMIN: 'SET_WORKSPACE_SUPER_ADMIN',
  SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS:
    'SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS',
  SET_WORKSPACE_FIRST_DAY: 'SET_WORKSPACE_FIRST_DAY',
  SET_WORKSPACE_PREFERENCES: 'SET_WORKSPACE_PREFERENCES',
  SET_WORKSPACE_USERMAVEN_ANALYTICS_URL:
    'SET_WORKSPACE_USERMAVEN_ANALYTICS_URL',
  SET_LAST_YOUTUBE_CONSENT_TIME: 'SET_LAST_YOUTUBE_CONSENT_TIME',
}

export const setting = {
  SET_MOBILE_DEVICES: 'SET_MOBILE_DEVICES',
  SET_FETCH_DEVICES_LOADER: 'SET_FETCH_DEVICES_LOADER',
}
export const notificationTypes = {
  SET_IMPORTANT_NOTIFICATIONS_LIST: 'SET_IMPORTANT_NOTIFICATIONS_LIST',
  SET_IMPORTANT_NOTIFICATIONS_VISIBILITY:
    'SET_IMPORTANT_NOTIFICATIONS_VISIBILITY',
}

export const sourceTypes = {
  RESET_SOURCE_STATES: 'RESET_SOURCE_STATES',
}

export const contentCategoriesTypes = {
  RESET_CONTENT_CATEGORY_ADD: 'RESET_CONTENT_CATEGORY_ADD',
  RESET_CONTENT_CATEGORY_ADD_SLOT: 'RESET_CONTENT_CATEGORY_ADD_SLOT',
  SET_CONTENT_CATEGORY_ADD_SLOT: 'SET_CONTENT_CATEGORY_ADD_SLOT',
  SET_CONTENT_CATEGORY_ADD_VALUE: 'SET_CONTENT_CATEGORY_ADD_VALUE',
  SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE:
    'SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE',
  SET_CONTENT_CATEGORIES_LIST: 'SET_CONTENT_CATEGORIES_LIST',
  SET_CONTENT_CATEGORIES_SLOTS: 'SET_CONTENT_CATEGORIES_SLOTS',
  ADD_TO_CONTENT_CATEGORIES_LIST: 'ADD_TO_CONTENT_CATEGORIES_LIST',
  ADD_TO_CONTENT_CATEGORIES_SLOTS: 'ADD_TO_CONTENT_CATEGORIES_SLOTS',
  SET_CONTENT_CATEGORIES_RETRIEVE_LOADER:
    'SET_CONTENT_CATEGORIES_RETRIEVE_LOADER',
  SET_CONTENT_CATEGORIES_STORE_LOADER: 'SET_CONTENT_CATEGORIES_STORE_LOADER',
  SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER:
    'SET_CONTENT_CATEGORIES_STORE_SLOTS_LOADER',
  SET_CONTENT_CATEGORIES_RETRIEVE_SLOTS_LOADER:
    'SET_CONTENT_CATEGORIES_RETRIEVE_SLOTS_LOADER',
  SET_CONTENT_CATEGORY_AVAILABLE_SLOT: 'SET_CONTENT_CATEGORY_AVAILABLE_SLOT',
  RESET_CONTENT_CATEGORY_AVAILABLE_SLOT:
    'RESET_CONTENT_CATEGORY_AVAILABLE_SLOT',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
}

export const hashtagsTypes = {
  SET_CURRENT_HASHTAG_VALUE: 'SET_CURRENT_HASHTAG_VALUE',
}

export const organizationTypes = {
  SET_ORGANIZATION_MEMBERS_LOADER_RETRIEVE:
    'SET_ORGANIZATION_MEMBERS_LOADER_RETRIEVE',
  SET_ORGANIZATION_MEMBERS_LOADER_STORE_MEMBER:
    'SET_ORGANIZATION_MEMBERS_LOADER_STORE_MEMBER',
  SET_ORGANIZATION_MEMBERS: 'SET_ORGANIZATION_MEMBERS',
  RESET_ORGANIZATION_MEMBER_ADD: 'RESET_ORGANIZATION_MEMBER_ADD',
  SET_ORGANIZATION_MEMBERS_VALIDATIONS: 'SET_ORGANIZATION_MEMBERS_VALIDATIONS',
}
