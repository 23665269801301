<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@state/base'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import PostEngagementAndImpression from '@src/modules/analytics/views/twitter/components/graphs/PostEngagementAndImpression.vue'
import debounce from 'lodash.debounce'
import CardsComponent from '@/src/modules/analytics/views/twitter/components/CardsComponent'
import FollowersGraph from '@/src/modules/analytics/views/twitter/components/graphs/FollowersGraph.vue'
import FollowingGraph from '@/src/modules/analytics/views/twitter/components/graphs/FollowingGraph.vue'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/twitter/components/graphs/TopAndLeastEngagingPosts.vue'

// state
const route = useRoute()
const store = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  topPostsData,
  leastPostsData,
  fetchMedia,
} = useTwitterAnalytics()

watch(
  () => [dateRange, route.params.accountId],
  debounce(async () => {
    if (!route.name.includes('twitter')) return

    selectedAccount.value = store.getters.getTwitterAccounts.items?.find(
      (i) => i.twitter_id === route.params.accountId
    )

    fetchMedia(routes.PAGE_AND_POST_INSIGHTS)
    fetchMedia(routes.ENGAGEMENTS_AND_IMPRESSIONS)
    fetchMedia(routes.FOLLOWERS_TREND)
    fetchMedia(routes.TOP_PERFORMING_TWEETS)
    fetchMedia(routes.LEAST_PERFORMING_TWEETS)
    fetchMedia(routes.CREDITS_USED_COUNT)
  }, 300),
  { deep: true, immediate: true }
)
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-5 gap-4 mb-7">
      <CardsComponent />
    </div>

    <div class="flex flex-col gap-y-[1.75rem]">
      <!-- Graphs Here -->
      <FollowersGraph />
      <FollowingGraph />
      <PostEngagementAndImpression
        :chart-type="'total_engagement'"
        :title="'Engagement'"
      />
      <PostEngagementAndImpression
        :chart-type="'impression_count'"
        :title="'Impression'"
      />
      <TopAndLeastEngagingPosts
        :top-posts="topPostsData"
        :least-posts="leastPostsData"
        :selected-account="selectedAccount"
      />
    </div>
  </div>
</template>
