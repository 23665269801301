<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <div class="my-4 flex items-center">
      <div class="w-2/12"> Post Type</div>
      <div class="w-10/12 flex items-center">
        <CstRadio
          id="fb-feed"
          class="w-max mr-4"
          name="facebook_post_type"
          :model-value="facebookPostType"
          value="feed"
          @change="setFacebookPostType"
        >
          Feed
        </CstRadio>
        <CstRadio
          id="fb-reel"
          v-tooltip="{ content: getReelDisableTooltip }"
          class="w-max mr-4"
          name="facebook_post_type"
          :model-value="facebookPostType"
          value="reel"
          :disabled="
            (isFacebookGroupsSelected && !isFacebookPageSelected) ||
            facebookSharingDetails?.image?.length ||
            !!facebookBackgroundId
          "
          @change="setFacebookPostType"
        >
          <div class="flex justify-center">
            Reel

            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <i
                class="far fa-question-circle text-base text-blue-900 ml-1.5"
              ></i>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>Reel's option is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                </ul>
              </template>
            </v-menu>
          </div>
        </CstRadio>
        <CstRadio
          id="fb-story"
          v-tooltip="{ content: getStoryDisableTooltip }"
          class="w-max mr-4"
          name="facebook_post_type"
          :model-value="facebookPostType"
          value="story"
          :disabled="
            (isFacebookGroupsSelected && !isFacebookPageSelected) ||
            facebookSharingDetails?.image?.length > 1 ||
            isFacebookCarousel ||
            !!facebookBackgroundId
          "
          @change="setFacebookPostType"
        >
          <div class="flex justify-center">
            Story
            <v-menu
              :popper-triggers="['hover']"
              placement="bottom-end"
              popper-class="first-comment__info-popover"
            >
              <!-- This will be the popover target (for the events and position) -->
              <i
                class="far fa-question-circle text-base text-blue-900 ml-1.5"
              ></i>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>Story's option is available for:</div>
                <ul>
                  <li><i class="fab fa-facebook-f"></i> Pages</li>
                </ul>
              </template>
            </v-menu>
          </div>
        </CstRadio>
      </div>
    </div>

    <div
      v-if="facebookSharingDetails?.video?.link && isFacebookPageSelected"
      class="my-4 flex flex-row items-center"
    >
      <div class="w-2/12">Video Title</div>
      <div class="w-10/12">
        <CstInputFields
          :value="facebookVideoTitle"
          maxlength="100"
          class="w-full"
          type="text"
          size="small"
          @value="setFacebookVideoTitle"
        />
      </div>
    </div>

    <div class="my-2 flex items-center">
      <div class="w-2/12"></div>
      <div v-if="isReelOrStory" class="w-10/12 flex items-center">
        <div class="bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
            <template v-if="facebookPostType === 'reel'">
              <span class="font-semibold">Reels Eligibility:</span>
              Reels option is currently only available for Pages. When a Group
              is selected, the system will publish it as a feed post.
              <span class="block mt-1 leading-4">
                Only video file with a duration between 4 and 60 seconds and
                aspect ratios of 9:16 are eligible to be posted as a Reel post.
                <a
                  href="#"
                  data-beacon-article-modal="632bf6d1172d934fa05937d1"
                  target="_blank"
                  >Learn more.</a
                >
              </span>
            </template>
            <template v-else-if="facebookPostType === 'story'">
              <span class="font-semibold">Story Eligibility:</span>
              Story option is currently only available for Pages. When a Group
              is selected, the system will publish it as a feed post.
              <span class="block mt-1 leading-4">
                Only video file with a duration between 4 and 60 seconds and
                aspect ratios of 9:16 are eligible to be posted as a Story post.
              </span>
            </template>
          </span>
        </div>
      </div>
    </div>

    <div class="my-2 flex items-center">
      <div class="w-2/12"></div>
        <div v-if="isFacebookGroupsSelected" class="w-10/12 flex items-center bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
              Due to API limitations, posting to Facebook groups uses mobile app push notification method.
              <a
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="661cac3f9d250919f9e26672">Learn more</a>
          </span>
        </div>
      </div>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstInputFields from '@ui/Input/CstInputFields'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'FacebookOptions',
  components: {
    CstRadio,
    CstInputFields,
  },
  props: {
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    facebookSharingDetails: {
      type: Object,
      default: () => {},
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    facebookVideoTitle: {
      type: String,
      default: '',
    },
    isFacebookGroupsSelected: {
      type: Boolean,
      default: false,
    },
    isFacebookPageSelected: {
      type: Boolean,
      default: false,
    },
    isFacebookCarousel: {
      type: Boolean,
      default: false,
  },
  },
  emits: [
    'setFacebookPostType',
    'setFacebookVideoTitle',
  ],
  data() {
    return {}
  },
  computed: {
    getReelDisableTooltip() {
      if(this.facebookBackgroundId) {
        return 'Facebook background option is not available to Reel post type.'
      }
      if (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) {
        return 'Reels can only be published on Facebook pages.'
      }
      if (this.facebookSharingDetails?.image?.length) {
        return 'Reel option is not available when an image is selected.'
      }
      return ''
    },
    getStoryDisableTooltip() {
      if(this.facebookBackgroundId) {
        return 'Facebook background option is not available to Story post type.'
      }
      if (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) {
        return 'Stories can only be published on Facebook pages.'
      }
      if (this.facebookSharingDetails?.image?.length > 1) {
        return 'Story option is only available when one image is selected.'
      }
      if (this.isFacebookCarousel) {
        return 'Story option is not available when carousel is selected.'
      }
      return ''
    },
    /**
     * Check if the post type is reel or story
     * @returns {boolean}
     */
    isReelOrStory() {
      return ['reel', 'story'].includes(this.facebookPostType)
    },
  },
  watch: {
    facebookSharingDetails(val) {
      if (val?.image.length && this.facebookPostType === 'reel') {
        this.setFacebookPostType('feed')
      }
    },
  },
  methods: {
    setFacebookPostType(value) {
      this.$emit('setFacebookPostType', value)
    },
    setFacebookVideoTitle(value) {
      this.$emit('setFacebookVideoTitle', value)
    },
  },
}
</script>
