<template>
  <div>
      <div class="filter grid grid-cols-1 gap-1 w-full">
        <CstDropdown class="w-100" size="small" button-classes="flex justify-between bg-white">
          <template v-slot:selected>{{sortType}}</template>
            <CstDropdownItem @click="handleSortType('Relevance')">
              Relevance
            </CstDropdownItem>
            <CstDropdownItem @click="handleSortType('Most Viewed')">
              Most Viewed
            </CstDropdownItem>
            <CstDropdownItem @click="handleSortType('Trending')">
              Trending
            </CstDropdownItem>
        </CstDropdown>
      </div>
      <div v-if="loader && posts.length ===  0" class="p-3" >
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
      <template  v-else-if="posts && posts.length > 0">
          <div class="grid grid-cols-2 gap-2 mt-2 w-full">
            <template v-for="(post, index) in posts" :key="index">
              <div
                   :draggable="true"
                   class="w-100 rounded-lg bg-cs-white border hover:bg-cs-foggray my-2  hover:shadow-sm" @dragstart="dragstartHandler($event, post)">
                <div class="m-0  image relative w-100">
                  <div
                      class="text-white border rounded-full p-2.5 flex items-center justify-center bg-[#0000007a] cursor-pointer absolute" style="top:45%;left:48%;"
                      @click.prevent="
                            handleVideoPreview(
                              post.title,
                              '',
                               videoEmbedUrl(post, 'dailymotion'),
                               'Dailymotion',
                            )">
                    <i class="cs-play"></i>
                  </div>
                  <div
                      v-if="post"
                      :style="{ 'background-image': 'url(' + videoImageURL(post, 'dailymotion') + ')' }"
                      class="w-100 h-40 bg-center bg-cover bg-no-repeat rounded-tl-md rounded-tr-md">
                  </div>
                </div>
                <div class="p-2">
                  <p class="text-sm font-bold">
                    {{ limitTextLength(post.title, 42) }}
                  </p>
                  <p class="text-xs text-gray-800">
                    <span>{{ videoDate('dailymotion', post) }}</span>
                  </p>
                  <hr class="m-2">
                  <div class="flex justify-between">
                    <div v-tooltip.top="'View'" class="flex items-center">
                      <i class="cs-eye text-lg"></i><span class="mx-0.5">{{ intToString(post.views_total) }}</span>
                    </div>
                    <div v-tooltip.top="'Likes'" class="flex items-center">
                      <i class="cs-thumbs-up"></i><span class="mx-0.5">{{ intToString(post.likes_total) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
          <template v-slot:no-results>
            <NoResult msg="No More Result Found"></NoResult>
          </template>
        </InfiniteLoading>
        </template>
      <template v-else-if="posts.length ===  0">
        <NoResult msg="No Result Found"></NoResult>
      </template>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
export default {
  name: "Dailymotion",
  components: {
    InfiniteLoading,
    NoResult,
    CstDropdown,
    CstDropdownItem
  },
  props:{
    loader:{
      type:Boolean,
      default:false
    },
    posts: {
      type: Array,
      default: () => []
    },
    sortType: {
      type: String,
      default: '',
    },
  },
  data () {
    return {

    }
  },
  watch:{
  },
  methods:{
    dragstartHandler (ev, video) {
      // Add the target element's id to the data transfer object
      if (video && video.url) {
        ev.dataTransfer.setData(
            'myData',
            JSON.stringify({
              id: ev.target.id,
              type: 'embed',
              heading:  video.title || '',
              media: video.url,
              p: '',
              link: ''
            })
        )
      }
    },
    handleSortType(value) {
      this.$emit('handle-change','sort', value)
    },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleVideoPreview(title, description, url, source){
      const data = {
        title,
        description,
        url,
        source,
      }
      this.$emit('handle-change','preview',data)
    },
  }
}
</script>

<style scoped>
input[type="checkbox"]:checked + label {
  color: #3a4557;
}
</style>
