<script setup>
import { ref, computed } from 'vue'
import { useElementSize } from '@vueuse/core'
import useSocialGridView from "@src/modules/planner_v2/composables/useSocialGridView";
import {useComposerHelper} from "@src/modules/composer_v2/composables/useComposerHelper";
import { vInfiniteScroll } from '@vueuse/components'
import useSocialMediaFetcher from '@/src/modules/common/composables/useSocialMediaUtils'
import InstagramPreview from '@/src/modules/composer_v2/components/SocialPreviews/InstagramPreview'
import { EventBus } from '@/src/modules/common/lib/event-bus';
import { useStore } from '@/src/store/base';

const { getSocialImageRounded, channelImage, getPlatformName } = useComposerHelper()
const store = useStore()

const onLoadMore = () => {

  if (
    !fetching.value &&
    store.getters.getPlans.items.length < store.getters.getPlans.total_items
  ) {
    fetchingMoreItems.value = true
    EventBus.$emit('refetch-more')
  }
}
const {selectedPost, selectedPlatform, selectedTab, fetchingMoreItems, selectedAccount, fetching, tabs } = useSocialGridView()
const { onError } = useSocialMediaFetcher()

const elementWidth = ref(null)
const { width } = useElementSize(elementWidth)

const selectedTabStyles = computed(() => {
  return {
    borderBottom: '1px solid black',
  }
})
</script>

<template>
  <div class="flex flex-col w-full">
    <template v-if="selectedPost && selectedAccount">
      <!-- Preview Section -->
      <InstagramPreview
        :key="selectedPost._id"
        class="w-full h-full mb-3 scroll-snap-start"
        :account="selectedAccount"
        :detail="selectedPost?.common_box_status ? selectedPost?.common_sharing_details : selectedPost.instagram_sharing_details"
        :instagram-post-type="'story'"
        :toggle-preview="true"
        :white-bg="false"
        :show-overlay="true"
        :instagram-share-to-story="selectedPost?.instagram_share_story || false"
       common-box-status/>
    </template>

    <div v-show="!selectedPost" class="contents h-full">
      <!-- User Avatar Section -->
      <div
        class="
          flex
          w-full
          gap-2
          justify-content-center
          align-items-center
        "
        :class="selectedPlatform.route_suffix === 'tiktok' ? 'py-4' : 'pt-[2rem] pb-[1.5rem]'"
      >
        <span class="relative">
          <img
            :src="channelImage(selectedAccount, selectedPlatform.route_suffix === 'tiktok' ? 'tiktok' : 'instagram')"
            alt=""
            class="
              rounded-full
              object-cover
              border
              avatar-image
            "
            data-fallback="0"
            @error="onError($event)"
          />

          <img
              :src="getSocialImageRounded(selectedPlatform.route_suffix === 'tiktok' ? 'tiktok' : 'instagram')"
            alt=""
            class="absolute right-0 bottom-0 !w-5 !h-5"
          />
        </span>
        <div ref="elementWidth" class="max-w-[100px]">
          <p
            v-tooltip="{
              content: width >= 100 ? getPlatformName(selectedAccount) : '',
            }"
            class="text-[0.875rem] font-semibold truncate"
          >
            {{ getPlatformName(selectedAccount) }}
          </p>
        </div>
      </div>

      <!-- Tabs -->
      <div class="grid grid-cols-12 w-full">
        <div
          v-for="(tab, index) in tabs"
          :key="tab.name"
          v-tooltip.top="tab.name === 'Self' ? 'Self Only' : tab.name"
          class="
            relative
            p-2
            col-span-4
            items-center
            cursor-pointer
            justify-content-center
          "
          :class="selectedPlatform.route_suffix === 'tiktok' ? 'col-span-6' : 'col-span-4'"
          :style="selectedTab.name === tab.name && selectedTabStyles"
          @click="selectedTab = tab"
        >
          <div
            v-if="index !== tabs.length - 1"
            class="h-[10px] top-[6px] 2xl:top-[10px] 2xl:h-[14px] right-border"
          ></div>

          <div class="flex justify-center items-center">
            <ImageIcon
              :path="tab.icon"
              :active="selectedTab.name === tab.name"
            />
          </div>
        </div>
      </div>

      <div
        v-infinite-scroll="[onLoadMore, { distance: 100 }]"
        class="
          !pt-0
          rounded-b-[1rem]
          2xl:rounded-b-[2rem]
          overflow-auto
          no-scrollbar
          h-full
        "
      >
        <clip-loader
          v-if="fetching && !fetchingMoreItems"
          class="flex items-center spinner mt-2 h-full"
          color="#e0dfdf"
          size="1.875rem"
        />

        <template v-else>
          <KeepAlive>
            <component :is="selectedTab.components.main" class="w-full" />
          </KeepAlive>

          <clip-loader
              v-if="fetchingMoreItems"
              class="flex justify-center spinner mt-2"
              color="#e0dfdf"
              size="1.875rem"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid {
  .right-border {
    position: absolute;
    right: -1px;
    color: #757a8a;
    border-right: 1px solid;
  }

  h3 {
    margin-top: 2px;
  }
}


.avatar-image {
  width: 2.5rem;
  aspect-ratio: 1;
}

@media (width >= 1560px) {
  .avatar-image {
    width: 3.5rem;
  }
}

@media (width >= 2000px) {
  .avatar-image {
    width: 5.5rem;
  }
}
</style>
