<script setup>
import { watch } from 'vue'
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import debounce from 'lodash.debounce'

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'line',
  },
  chartOptions: {
    type: Object,
    required: true,
  },
})

const { setup, chartRef } = useEcharts(props.chartOptions, props.type)

watch(
  () => [chartRef.value, props.chartOptions],
  debounce(() => {
    setup(props.chartOptions)
  }, 100)
)
</script>

<template>
  <div ref="chartRef" :style="{ height: isModal ? '85vh' : '350px' }" />
</template>
