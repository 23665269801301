<script setup>
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import { computed, watch } from 'vue'

const props = defineProps({
  publishedPostsValue: {
    type: Number,
    required: true,
  },
  scheduledPostsValue: {
    type: Number,
    required: true,
  },
  partiallyFailedPostsValue: {
    type: Number,
    required: true,
  },
  failedPostsValue: {
    type: Number,
    required: true,
  },
})

const chartOptions = computed(() => ({
  tooltip: {
    show: true,
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '55%'],
      label: {
        show: false,
      },
      itemStyle: {
        borderRadius: 4,
        borderColor: '#fff',
        borderWidth: 2,
      },
      data: [
        {
          value: props.publishedPostsValue,
          name: 'Published',
          itemStyle: { color: props.publishedPostsValue ? '#56C288' : '#D2D5DF' },
        },
        {
          value: props.scheduledPostsValue,
          name: 'Scheduled',
          itemStyle: { color: props.scheduledPostsValue ? '#FFC555' : '#D2D5DF' },
        },
        {
          value: props.partiallyFailedPostsValue,
          name: 'Partially Failed',
          itemStyle: { color: props.partiallyFailedPostsValue ? '#B52D4A' : '#D2D5DF' },
        },
        {
          value: props.failedPostsValue,
          name: 'Failed',
          itemStyle: { color: props.failedPostsValue ? '#D72C5C' : '#D2D5DF' },
        },
      ],
    },
  ],
}))

// setting up the eChart
const { setup, chartRef } = useEcharts(chartOptions.value, ['pie'])

watch(
  () => chartRef.value,
  () => {
    setup(chartOptions.value)
  }
)
</script>

<template>
  <div ref="chartRef" :style="{ height: '21.875rem' }"></div>
</template>