<template>
  <aside class="min-h-100 relative">
    <div class="flex flex-row h-screen relative">
      <!-- modal view -->
      <div class="h-screen bg-white">
        <div
          class="
            social_post_side_filter
            basic_form
            h-full
            !min-w-[26rem]
            !w-[26rem]
          "
          :class="{
            open: toggleModalSidebarStatus,
          }"
        >
          <div class="filter_inner"
               :style="{
                borderLeft: toggleModalSidebarStatus ? '1px solid #DDE3E7 !important' : 'none !important',
                }"
          >
            <span
              v-if="toggleModalSidebarStatus"
              v-tooltip="'Minimize Sidebar'"
              class="
                absolute
                left-[-11px]
                top-[1rem]
                cursor-pointer
                text-gray-700
                hover:text-gray-900
                bg-cs-light-gray
                color-border
                flex
                items-center
                justify-center
                w-6
                h-6
                rounded-full
                shadow-sm
                z-10
              "
              @click="toggleModalSidebar"
            >
              <i class="fas fa-angle-right text-lg font-normal"></i>
            </span>
            <Helper v-if="composerSidebarStatus === 'helper'" />
            <Campaigns
              v-if="composerSidebarStatus === 'campaigns'"
              :campaigns="data.campaigns"
              :folder-id="data.folderId"
              @redirect-to="handleSidebarRedirect"
              v-bind="$attrs"
              v-on="$listeners"
            />
            <AssistantMain v-if="composerSidebarStatus === 'assistant'" />
            <Comments
              v-if="composerSidebarStatus === 'comment'"
              :plan-id="data.planId"
              :comments-list="data.comments"
              v-bind="$attrs"
              v-on="$listeners"
            ></Comments>
            <Tasks
              v-if="composerSidebarStatus === 'task'"
              :plan-id="data.planId"
              :tasks-list="data.tasks"
              v-bind="$attrs"
              v-on="$listeners"
            ></Tasks>
            <Labels
              v-if="composerSidebarStatus === 'labels'"
              :labels="data.labels"
              @redirect-to="handleSidebarRedirect"
              v-bind="$attrs"
              v-on="$listeners"
            />
            <Members
              v-if="composerSidebarStatus === 'members'"
              :members="data.members"
              @redirect-to="handleSidebarRedirect"
              v-bind="$attrs"
              v-on="$listeners"
            />
            <Activities
              v-if="composerSidebarStatus === 'activity'"
              :plan-id="data.planId"
              :activities="data.activities"
            />
            <PostPreview
              v-if="composerSidebarStatus === 'preview'"
              :preview-accounts="getAccountData"
              :publish-as="data.publishAs"
              :details="data.details"
              :status="data.status"
              :carousel="data.carousel"
              :carousel-accounts="data.carouselAccounts"
              :first-comment="data.firstComment"
              :youtube-title="data.youtubeTitle"
              :youtube-post-type="data.youtubePostType"
              :gmb-options="data.gmbOptions"
              :twitter-options="data.twitterOptions"
              :threads-options="data.threadsOptions"
              :instagram-post-type="data.instagramPostType"
              :facebook-post-type="data.facebookPostType"
              :linkedin-options="data.linkedinOptions"
              :tiktok-options="data.tiktokOptions"
              :active-tab="data.activeTab"
              :instagram-collaborators="data.instagramCollaborators"
              :facebook-background-id="data.facebookBackgroundId"
              :facebook-group-selected="data.facebookGroupSelected"
              :facebook-page-selected="data.facebookPageSelected"
              :instagram-share-to-story="data.instagramShareToStory"
            />
          </div>
        </div>
      </div>
      <!-- sidebar -->
      <div class="z-99 !pt-0 bg-white">
        <div class="h-full" :style="{
          borderLeft: '1px solid #DDE3E7'
        }">
          <span
              v-if="!toggleModalSidebarStatus"
              v-tooltip="'Maximize Sidebar'"
              class="
                absolute
                left-[-1.788rem]
                top-[1.1rem]
                cursor-pointer
                text-gray-700
                hover:text-gray-900
                bg-cs-light-gray
                color-border
                flex
                items-center
                justify-center
                w-6
                h-6
                rounded-full
                shadow-sm
                z-10
              "
              @click="toggleModalSidebar"
          >
          <i class="fas fa-angle-left text-lg font-normal"></i>
        </span>
          <ul>
            <li
                class="
              h-14
              group
              mt-[3.3rem]
            "
            >
              <img
                  src="@src/assets/img/chat_bot/ai_toolkit.svg"
                  alt="ai-chat-widget"
                  class="h-14 cursor-pointer"
              />

              <img
                  src="@src/assets/img/common/new_tag.svg"
                  alt=""
                  class="absolute top-[3.3rem] right-[2.15rem]"
              />
              <div
                  class="
                transition
                delay-150
                duration-300
                ease-out
                absolute
                right-[3.8rem]
                top-[6rem]
                z-10
                border
                w-[16rem]
                shadow-lg
                h-max
                rounded-l-lg
                bg-white
                scale-0
                group-hover:scale-100
                origin-top-right
              "
              >
                <p class="p-2 border !border-t-0 !border-r-0 !border-l-0 font-semibold">AI Toolkit</p>
                <ul class="p-2 cursor-pointer">
                  <li
                      class="
                    p-2 hover:bg-cs-secondary
                    rounded-sm
                    cursor-pointer
                    flex
                    items-center
                    gap-2
                  "
                      @click="handleAIClick('writing-assistant')"
                  >
                    <img
                        src="@assets/img/chat_bot/ai_writer.svg"
                        alt="AI Writing Assistant"
                        class="h-5 w-5"
                    />
                    <span>AI Writing Assistant</span>
                  </li>
                  <li
                      class="
                    p-2 hover:bg-cs-secondary
                    rounded-sm
                    cursor-pointer
                    flex
                    items-center
                    gap-2
                  "
                      @click="handleAIClick('caption')"
                  >
                    <img
                        src="@assets/img/chat_bot/ai_caption.svg"
                        alt="AI Caption Generator"
                        class="h-5 w-5"
                    />
                    <span>AI Caption Generator</span>
                  </li>
                  <li
                      v-if="data.activeTab !== 'youtube'"
                      class="
                    p-2 hover:bg-cs-secondary
                    rounded-sm
                    cursor-pointer
                    flex
                    items-center
                    gap-2
                  "
                      @click="handleAIClick('image')"
                  >
                    <img
                        src="@assets/img/chat_bot/ai_image.svg"
                        alt="AI Image Generator"
                        class="h-5 w-5"
                    />
                    <span>AI Image Generator</span>
                  </li>
                  <li
                      class="
                    p-2 hover:bg-cs-secondary
                    rounded-sm
                    flex
                    items-center
                    gap-1.5
                    cursor-pointer
                  "
                  >
                    <div
                        class="flex items-center gap-2"
                        :class="{
                      'text-gray-700 pointer-events-none': !aiHashtagEnabled,
                    }"
                        @click="handleAIClick('hashtags')"
                    >
                      <img
                          src="@assets/img/chat_bot/ai_hashtag.svg"
                          alt="AI Hashtag Generator"
                          class="h-5 w-5"
                      />
                      <span>AI Hashtag Generator</span>
                    </div>
                    <img
                        v-if="!aiHashtagEnabled"
                        v-tooltip="
                      'To generate hashtags, please enter at least 100 characters in the post composer.'
                    "
                        src="@assets/img/composer/help-icon.svg"
                        alt=""
                        class="w-4 h-4"
                    />
                  </li>
                </ul>
              </div>
            </li>
            <li
                v-tooltip="'Preview'"
                class="
              hover:bg-[#E3F2FF]
              flex
              justify-center
              items-center
              h-14
              cursor-pointer
            "
                :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'preview' }"
                @click.prevent="socialShareTab('preview')"
            >
              <img
                  src="@src/assets/img/chat_bot/preview.svg"
                  alt="ai-chat-widget"
                  class="w-5 h-5"
              />
            </li>
            <li
                v-tooltip="'Tasks'"
                class="
              hover:bg-[#E3F2FF]
              flex
              justify-center
              items-center
              h-14
              cursor-pointer
              relative
            "
                :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'task' }"
                @click="socialShareTab('task')"
            >
              <img
                  src="@src/assets/img/chat_bot/tasks.svg"
                  alt="ai-chat-widget"
                  class="w-5 h-5"
              />
              <div
                  v-if="getUncompletedTasks(data.tasks)?.length"
                  class="
                absolute
                top-2
                w-4
                text-xs
                right-2
                bg-red-500
                text-white
                rounded-full
                flex
                items-center
                justify-center
              "
              >
                {{ getUncompletedTasks(data.tasks).length }}
              </div>
            </li>
            <li
                v-tooltip="'Comments'"
                class="
              hover:bg-[#E3F2FF]
              flex
              justify-center
              items-center
              h-14
              cursor-pointer
              relative
            "
                :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'comment' }"
                @click="socialShareTab('comment')"
            >
              <img
                  src="@src/assets/img/chat_bot/comments-new.svg"
                  alt="ai-chat-widget"
                  class="w-5 h-5"
              />
              <div
                  v-if="data?.comments?.length"
                  class="
                absolute
                top-2
                w-4
                text-xs
                right-2
                bg-red-500
                text-white
                rounded-full
                flex
                items-center
                justify-center
              "
              >
                {{ data.comments.length }}
              </div>
            </li>
            <li
                v-tooltip="'Assistant'"
                class="
              hover:bg-[#E3F2FF]
              flex
              justify-center
              items-center
              h-14
              cursor-pointer
            "
                :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'assistant' }"
                @click="socialShareTab('assistant')"
            >
              <img
                  src="@src/assets/img/chat_bot/Union.svg"
                  alt="ai-chat-widget"
                  class="w-[1.688rem] h-[1.688rem]"
              />
            </li>
            <li
                v-tooltip="'Activities'"
                class="
              hover:bg-[#E3F2FF]
              flex
              justify-center
              items-center
              h-14
              cursor-pointer
            "
                :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'activity' }"
                @click.prevent="socialShareTab('activity')"
            >
              <img
                  src="@src/assets/img/chat_bot/activities.svg"
                  alt="ai-chat-widget"
                  class="w-5 h-5 m-3"
              />
            </li>
            <!-- Members  -->
              <li
                  v-tooltip="'Members'"
                  class="
                   hover:bg-[#E3F2FF]
                    flex
                    justify-center
                    items-center
                    h-14
                    cursor-pointer
                    "
                    :class="{ 'bg-[#E3F2FF]': composerSidebarStatus === 'members' }"
                    @click.prevent="openMembers"
                    >
                    <img
                      src="@src/assets/img/chat_bot/members.svg"
                      alt="ai-chat-widget"
                      class="w-6 h-6"
              />
            </li>
          </ul>
          <div
              v-if="getToolkitViewStatus"
              class="
            absolute
            right-[4.25rem]
            top-[0.45rem]
            text-white
            z-10
            bg-[#404040]
            w-[32rem]
            rounded-lg
            shadow-lg
          "
          >
            <div class="relative px-2.5 py-2">
              <h5 class="font-semibold leading-normal mb-1">Introducing AI Toolkit!</h5>
              <p class="text-sm leading-normal">
                Generate AI-powered content, including captions, product
                descriptions, quotes, and more, in just a few clicks. Boost your
                social media presence instantly!
              </p>
              <i
                  v-tooltip="'Close'"
                  class="
                fas
                fa-times
                absolute
                top-1
                right-1
                text-white
                font-thin
                rounded-full
                bg-gray-cs-700
                hover:bg-gray-cs-800
                active:bg-gray-cs-900
                px-1
                py-0.5
                cursor-pointer
              "
                  @click="handleToolkitClose"
              ></i>
              <span
                  class="
                text-[#404040]
                text-xl
                absolute
                bottom-[-0.1rem]
                right-[-0.7rem]"
              >&#9658;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import Comments from '@src/modules/composer_v2/components/Comments'
import Tasks from '@src/modules/composer_v2/components/Tasks'
import Campaigns from '@src/modules/composer_v2/components/Campaigns'
import Members from '@src/modules/composer_v2/components/Members'
import Labels from '@src/modules/composer_v2/components/Labels'
import Activities from '@src/modules/composer_v2/components/Activities'
import { EventBus } from '@common/lib/event-bus'
import Helper from '@modules/discovery/components/share-sidebar/Helper.vue'
import { mapGetters } from 'vuex'
import PostPreview from '../components/PostPreview'
import AssistantMain from './SidebarAssistant/AssistantMain'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

export default {
  name: 'ComposerSidebar',
  components: {
    Helper,
    Activities,
    Labels,
    Members,
    Campaigns,
    PostPreview,
    Comments,
    Tasks,
    AssistantMain,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isApprover: {
      type: Boolean,
    },
  },
  setup() {
    const { setComposerToolkitInfoViewStatus, isComposerModalOpen, isDraftComposer } = useComposerHelper()

    return {
      isDraftComposer,
      isComposerModalOpen,
      setComposerToolkitInfoViewStatus,
    }
  },
  data() {
    return {
      isTabHidden: false,
      width: '20',
      height: '20',
      stroke: '#979CA0',
      activeStroke: '#0068E5',
      toggleModalSidebarStatus: true,
      composerSidebarStatus: 'preview',
      aiHashtagEnabled: false,
      showToolkitInfo: true,
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    getAccountData() {
      const acc = {}
      const { accounts, accountList } = this.data
      Object.entries(accounts).forEach(([key, value]) => {
        let pageAdded = false // Flag to track if a page account is added
        if (value.length > 0) {
          accountList.forEach((channel) => {
            if (
              (this.data.facebookPostType === 'story' ||
                this.data.facebookPostType === 'reel') &&
              channel.channel_type === 'facebook'
            ) {
              if (
                channel.type === 'Page' &&
                value.find((val) => val === channel[channel.channel_identifier])
              ) {
                // Add the first page account if it's a story or reel and a page account exists
                if (!pageAdded) {
                  acc[key] = channel
                  pageAdded = true
                }
              } else if (
                channel.type === 'Group' &&
                value.find((val) => val === channel[channel.channel_identifier])
              ) {
                // If it's a story or reel and no page account found, add the first group account
                if (!pageAdded) {
                  acc[key] = channel
                }
              }
            } else if (channel[channel.channel_identifier] === value[0]) {
              acc[key] = channel
            }
          })
        }
      })
      return acc
    },
    getToolkitViewStatus() {
      return (
        this.showToolkitInfo &&
        this.getProfile?.preferences?.composer_toolkit_view
      )
    },
  },
  watch: {},
  mounted() {
    EventBus.$on('open-composer-sidebar', (status) => {
      this.socialShareTab(status, true)
    })
    EventBus.$on('editor-character-count', (data = {}) => {
      this.$nextTick(() => {
        const activeTab = this.data.status ? 'common' : this.data.activeTab
        if (data.editorType !== activeTab) return
        this.aiHashtagEnabled = data.characterCount >= 100
      })
    })
  },
  beforeUnmount() {
    EventBus.$off('open-composer-sidebar')
    EventBus.$off('editor-character-count')
  },
  methods: {
    getUncompletedTasks(tasks) {
      return tasks.filter((task) => task.status === false)
    },
    handleAIClick(requestType = '') {
      this.showToolkitInfo = false
      switch (requestType) {
        case 'writing-assistant':
          EventBus.$emit('open-chat-modal')
          break
        case 'caption':
          EventBus.$emit('AiCaptionModal_show', {
            isOldComposer: false,
            source: this.data.activeTab,
          })
          break
        case 'image':
          EventBus.$emit('imageGeneratorModal', { isOldComposer: false })
          break
        case 'hashtags':
          EventBus.$emit(
            `generate-hashtags-${
              this.data.status ? 'common' : this.data.activeTab
            }`,
            true
          )
          break
      }
    },

    toggleModalSidebar() {
      if (!this.toggleModalSidebarStatus && this.composerSidebarStatus === '') {
        this.composerSidebarStatus = 'assistant'
      }
      this.toggleModalSidebarStatus = !this.toggleModalSidebarStatus
    },

    socialShareTab(status, initialize = false) {
      if(status !== 'campaigns' && status !== 'members' && status !== 'labels'){
        EventBus.$emit('sidebar-tab-change', status)
      }
      this.toggleModalSidebarStatus = true
      this.composerSidebarStatus = status

      if (!initialize) {
        switch (status) {
          case 'comment':
            break
          case 'task':
          default:
        }
      }
      this.checkComposerDragDrop()
    },
    handleToolkitClose() {
      this.$store.dispatch('setComposerToolkitInfoStatus', false)
      this.setComposerToolkitInfoViewStatus()
    },
    handleSidebarRedirect(tabName){
      this.socialShareTab(tabName)
    },
    openMembers() {
      EventBus.$emit('open-composer-sidebar', 'members')
    },
  },
}
</script>

<style lang="scss">
.hide {
  display: none;
}

.stroke-icon {
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #979ca0;

  &:hover {
    stroke: #0068e5;
  }
}

.stroke-active {
  stroke: #0068e5;
}

.fill-icon {
  fill: #979ca0;

  &:hover {
    fill: #0068e5;
  }
}

.fill-active {
  fill: #0068e5;
}

.composer-aside-tabs {
  min-height: 100%;
  @apply bg-cs-light-gray;
  .tab-content {
    width: 26rem;
    border-left: 1px solid #eff0f1;
  }

  .tab-pane {
    height: 100%;
  }

  .col-auto {
    border-left: 1px solid #eff0f1;
    padding: 0.625rem 0.75rem !important;
    @apply bg-cs-light-gray;
    .nav {
      .nav-pills {
        border: 0 !important;
      }
    }
  }

  .nav-link {
    @apply mb-5 h-8 w-8 flex justify-center items-center text-black-700;
  }

  .nav-link:hover {
    @apply bg-cs-secondary;
  }

  .nav-link.active {
    color: #005fd0 !important;
    background-color: #e6f0fc !important;
    //@apply bg-cs-secondary text-cs-primary
  }
}
</style>
