<template>
  <div ref="wordCloud-chart-container" class="h-chart">
    <highcharts :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import highcharts from "@ui/Highcharts";
export default {
  name: 'WordCloud',
  components: {
    highcharts
  },
  props: {
    series: {
      required: true
    }
  },
  data () {
    return {
      dataOptions: {
        title: {
          text: ' '
        },

        chart: {
          type: 'wordcloud',
          marginTop: 20
        },
        credits: {
          enabled: false
        },

        series: []
      }
    }
  },
  watch: {
    series (newValue, oldValue) {
      console.debug('series watch', newValue, oldValue)
      if (newValue.length > 0 && newValue !== oldValue) {
        this.$set(this.dataOptions, 'series', this.series)
      }
    }
  },
  mounted () {
    // console.debug('series watch', this.series)
    this.$nextTick(() => {
      this.$set(this.dataOptions, 'series', this.series)
      // this.dataOptions.charkt.height = this.$refs['wordCloud-chart-container'].offsetHeight - 32
    })
  },
  methods: {}
}
</script>
