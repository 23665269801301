<template>
  <div class="box_inner">
    <b-modal
      ref="view_notes"
      modal-class=" add_slot normal_modal"
      hide-footer
      hide-header
    >
      <div
        class="modal_head d-flex align-items-center"
        style="border-bottom: 2px solid #f5f5f8"
      >
        <h2>Your workspace internal notes</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="hideModal"
          >&times;
        </button>
      </div>
      <div class="basic_form">
        <div class="modal_body basic_form m_t_15 notes_data">
          <span class="display_notes" v-html="note"> </span>
        </div>
      </div>
    </b-modal>

    <PausePosting
      :id="'pausePosting_' + workspace_id"
      :workspace_id="workspace_id"
    ></PausePosting>
    <ResumePosting
      :id="'resumePosting_' + workspace_id"
      :workspace_id="workspace_id"
      :count_loader="posting_count_loader"
      :posting_count="pause_posting_count"
    ></ResumePosting>

    <div class="component_inner__workspace_list__w_box__actions">
      <div class="action_btn">
        <template
          v-if="item.workspace.policy && item.workspace.policy.can_edit"
        >
          <i
            v-if="isOnHold(item)"
            v-tooltip.left="'Resume Posting'"
            class="fa fa-play-circle action_icon sm"
            @click.prevent="changeHoldStatus(item.workspace._id, false)"
          ></i>

          <i
            v-if="!isOnHold(item)"
            v-tooltip.left="'Pause posting'"
            class="fa fa-pause-circle action_icon sm"
            @click.prevent="changeHoldStatus(item.workspace._id, true)"
          ></i>

          <i
            v-tooltip.left="'Settings'"
            class="icon-settings-cs action_icon sm"
            @click.prevent="changeWorkspace(item.workspace, 'basicSetting')"
          >
          </i>
        </template>
        <i
          v-if="
            item.workspace.policy &&
            item.workspace.policy.can_remove &&
            !item.default
          "
          v-tooltip.left="'Remove'"
          class="icon-delete-cs action_icon sm"
          data-cy="remove-hover"
          @click.prevent="checkWorkspaceRemovalAction(item)"
        >
        </i>
      </div>

      <div v-if="item.default" data-cy="default_workspace_button" class="tag">
        Default
      </div>

      <div
        v-else
        data-cy="default_workspace_set_button"
        class="tag set_default"
        @click.prevent="$bvModal.show(item.workspace._id)"
      >
        Set as default
      </div>

        <b-modal
          :id="item.workspace._id"
          ref="defaultWorkspaceModal"
          no-close-on-backdrop
          modal-class="alertBox defaultWorkspaceModal"
          hide-footer
          hide-header
        >
          <div class="modal_body">
            <p
              >Are you sure you want to make
              <b>{{ item.workspace.name }}</b> workspace as default?
            </p>
            <div class="col-sm-12 footer_box">
              <button
                class="btn gray cancel_btn"
                @click="$bvModal.hide(item.workspace._id)"
                >No
              </button>

              <button
                class="btn red confirm_btn btn_loader lsoader_right"
                @click.prevent="
                  changeDefaultWorkspace(item.workspace, $bvModal)
                "
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </b-modal>
    </div>

    <div class="" @click.prevent="changeWorkspace(item.workspace, 'dashboard')">
      <div class="profile_logo">
        <div
          class="img"
          :style="{ background: 'url(' + item.workspace.logo + ')' }"
        ></div>
      </div>
      <h3 data-cy="workspace_listing_name">{{ item.workspace.name }}</h3>

      <div
        v-if="item.workspace.members && item.workspace.members.length"
        class="users"
      >
        <template v-for="(member, index) in item.workspace.members">
          <div
            v-if="index < 3"
            :key="index"
            class="circle_box d-flex align-items-center justify-content-center"
          >
            <img
              v-if="member.user && member.user.image"
              v-tooltip="{ content: member.user.full_name }"
              :src="member.user.image"
               class="w-[1.875rem] h-[1.875rem] rounded-circle"
              alt=""
            />
            <img
              v-else
              src="../../../../../assets/img/profile_default.svg"
               class="w-[1.875rem] h-[1.875rem] rounded-circle"
              alt=""
            />
          </div>
        </template>

        <div
            v-if="item.workspace.members.length > 3"
            v-tooltip="{ content: userList }"
            class="circle_box count_box d-flex align-items-center justify-content-center"
        >
          <p class="text">+{{ item.workspace.members.length - 3 }}</p>
        </div>
      </div>

      <div v-else class="pages">
        <p>No profile connected yet</p>
      </div>

      <div class="date">
        <p
          >Created:
          {{ momentWrapper(item.workspace.created_at).formatDate()  }}</p
        >
      </div>
    </div>
    <div class="notes mt-2">
      <div class="status-show">
        <div v-if="isOnHold(item)" class="mb-2">
          <span class="paused-case"
            >Publishing paused,
            <a
              class="text-decoration-none"
              href="javascript:;"
              @click.prevent="changeHoldStatus(item.workspace._id, false)"
              >resume</a
            >?</span
          >
        </div>
      </div>
      <a
        v-if="item.workspace.note"
        href="javascript:;"
        @click.stop.prevent="openNotesForWorkspace(item.workspace.note)"
        >View Notes</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PausePosting from '../../dialogs/hold/PausePosting'
import ResumePosting from '../../dialogs/hold/ResumePosting'
import useDateFormat from "@common/composables/useDateFormat";
import moment from "moment";
export default {
  components: {
    PausePosting,
    ResumePosting
  },
  props: ['item'],
  setup(){
    const { momentWrapper } = useDateFormat()
    return { momentWrapper }
  },
  data () {
    return {
      workspace_id: null,
      posting_count_loader: false,
      pause_posting_count: 0,
      show_notes_more: true,
      note: '',
      showMore: 'Show More'
    }
  },
  computed: {
    moment() {
      return moment
    },
    ...mapGetters([]),
    userList() {
      let name = ''
      this.item.workspace?.members?.length > 3 && this.item.workspace.members.map((member, idx) => (idx > 2 && member.user?.full_name !== '') ? name = name+', '+ member.user?.full_name : name + ', ')
      return !name.replace(/\s/g, '').length ? '' : name
    }
  },
  mounted () {
    this.workspace_id = this.item.workspace._id
  },
  methods: {
    ...mapActions([]),
    toggleShowMore () {
      if (this.showMore === 'Show More') {
        this.showMore = 'Show Less'
      } else {
        this.showMore = 'Show More'
      }
    },
    isOnHold (item) {
      return !!(item.workspace && item.workspace.on_hold)
    },
    async changeHoldStatus (workspace, status) {
      console.debug('Method::changeHoldStatus2', workspace)
      this.workspace_id = workspace

      setTimeout(async () => {
        if (status) return this.$bvModal.show('pausePosting_' + workspace)

        this.posting_count_loader = true
        this.$bvModal.show('resumePosting_' + workspace)
        const response = await this.$store.dispatch('fetchPausePostingCount', {
          workspace_id: this.workspace_id
        })
        if (response) {
          this.pause_posting_count = response.data.plans
          this.posting_count_loader = false
          return
        }
        this.$bvModal.hide('resumePosting_' + workspace)
      }, 100)
    },
    openNotesForWorkspace (note) {
      this.note = note
      this.$refs.view_notes.show()
    },
    hideModal () {
      this.$refs.view_notes.hide()
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.display_notes {
  white-space: break-spaces;
}
.display_show_more {
}
.notes_data {
  overflow: auto;
  margin-bottom: 23px;
  max-height: 276px;
}
</style>
