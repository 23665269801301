<script setup>
import { onMounted, ref } from 'vue'

const currentTestimonial = ref(null)
const testimonials = ref([
  {
    content: "ContentStudio.io is a powerful content curation tool that ensures you can become part of, influence, and even generate thought leadership around a topic that is of interest to you or your business. The high level of customization helps you publish the right message to the right social network at the right time for maximum engagement.",
    name: "Neal Schaffer",
    position: "Author, Global Speaker, Marketing Influencer",
    image: require("@assets/img/onboarding/neal-schaffer.png")
  },
  {
    content: "Content allows us to have a variable team where we can have all players on one platform. It aggregates all elements of social media management into one app; social media content scheduling and publishing, customer service, and analytics. It is quite astounding how much value this platform packs. ContentStudio really rocks it for us.",
    name: "Anthony",
    position: "CEO at Lake Placid Film Festival",
    image: require("@assets/img/onboarding/anthony.png")
  },
  {
    content: "I've been a big fan of Content Studio from the first time I tried it. Not only is it super easy to find amazing relevant content to share with your audience and grow your influence, but I absolutely love how it helps you create better-optimised content for social media and your blog, easier than ever.",
    name: "Lilach Bullock",
    position: "Content Creation Expert, Speaker, Author, Trainer & Consultant",
    image: require("@assets/img/onboarding/lilach.png")
  },
  {
    content: "ContentStudio is a time and lifesaver. We love the automation recipes, especially \"articles to social media.\" You put your social on autopilot, sit back and reap the rewards.",
    name: "Agata Pawlak",
    position: "Founder at CruzCreative.Agency",
    image: require("@assets/img/onboarding/agata.png")
  },
  {
    content: "ContentStudio helps me and my team to instantly approve posts and gives me control over the content that goes on my social channels.",
    name: "James Pemberton",
    position: "Proactive Healthcare Recruiters",
    image: require("@assets/img/onboarding/james.png")
  },
])

const getRandomTestimonial = () => {
  const randomIndex = Math.floor(Math.random() * testimonials.value.length)
  return testimonials.value[randomIndex]
}

onMounted(() => {
  currentTestimonial.value = getRandomTestimonial()
})
</script>

<template>
  <div v-if="currentTestimonial" class="w-full">
    <div class="w-full p-1 flex flex-col justify-between">
      <div class="flex flex-grow mb-8">
        <p class="pt-6 text-md 2xl:text-lg text-[#0D2859]">"{{ currentTestimonial.content }}"</p>
      </div>
      <div class="w-full flex flex-nowrap gap-x-4 items-center">
        <img
            :src="currentTestimonial.image"
            class="w-10 2xl:w-14 h-10 2xl:h-14 rounded-full"
            alt="ContentStudio"
        />
        <div>
          <h2 class="text-sm 2xl:text-lg font-semibold text-[#0D2859]">{{ currentTestimonial.name }}</h2>
          <p class="text-[#0D2859] text-sm 2xl:text-lg">{{ currentTestimonial.position }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
