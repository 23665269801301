<script>
import { mapActions, mapGetters } from 'vuex'
import ErrorSection from '@src/modules/publish/components/posting/social/sections/ErrorSection'
import GmbOptions from '@src/modules/publish/components/posting/social/GmbOptions'
import { social } from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type'
import SingleBox from '@src/modules/publish/components/posting/social/boxes/SingleBox'
import FirstCommentSection from '@src/modules/publish/components/posting/social/sections/FirstCommentSection.vue'
import { socialChannelsArray } from '@common/constants/common-attributes'
export default {
  components: {
    FirstCommentSection,
    ErrorSection,
    SingleBox,
    GmbOptions,
  },
  data() {
    return {
      imageUploading: false,
      showFirstCommentSection: false,
      first_comment: {
        comment: '',
        accounts: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getEvergreenSelection',
      'getEvergreenVariationData',
      'getAccountSelection',
      'getSocialSharingFirstComment',
    ]),

    firstCommentAccountSelection() {
      const facebookAccounts = this.getAccountSelection.facebook
      const instagramAccounts = this.getAccountSelection.instagram
      const youtubeAccounts = this.getAccountSelection.youtube
      const linkedinAccounts = this.getAccountSelection.linkedin

      return [
        ...facebookAccounts,
        ...instagramAccounts,
        ...youtubeAccounts,
        ...linkedinAccounts,
      ]
    },

    isFirstCommentEnabled() {
      const accountList = []
      if (this.firstCommentAccountSelection.length) {
        socialChannelsArray.forEach((channel) => {
          this.getChannelItems(channel).forEach((account) => {
            if (
              (channel.name === 'facebook' ||
                channel.name === 'linkedin' ||
                (channel.name === 'youtube' &&
                  !this.getYoutubeOptions.made_for_kids &&
                  this.getYoutubeOptions.privacy_status === 'public') ||
                channel.name === 'instagram') &&
              this.firstCommentAccountSelection.includes(account[channel.key])
            ) {
              accountList.push({
                ...account,
                channel: { name: channel.name, key: channel.key },
              })
            }
          })
        })
      }

      return accountList.length > 0
    },
  },
  watch: {
    first_comment: function (val) {
      this.setFirstCommentMessage(val.comment)
    },
    isFirstCommentEnabled: function (val) {
      if (val === false) {
        this.setHasFirstComment(false)
        this.showFirstCommentSection = false
      }
    },
  },
  mounted() {
    EventBus.$on('image-uploading', (value) => {
      this.imageUploading = value
    })

    EventBus.$on('evergreenFetchedFirstComment', (data) => {
      this.getSocialSharingFirstComment.has_first_comment =
        data.has_first_comment
      this.getSocialSharingFirstComment.first_comment_message =
        data.first_comment_message
      this.getSocialSharingFirstComment.first_comment_accounts =
        data.first_comment_accounts

      this.first_comment = {
        comment: data.first_comment_message,
        account: data.first_comment_accounts,
      }
    })
  },
  methods: {
    ...mapActions(['setHasFirstComment', 'setFirstCommentMessage']),
    /*
     * method will add new post and add/update variation
     */
    addEvergreenPost() {
      // check social account validation before adding variation
      if (!this.socialShareDetailsValidation('Evergreen')) return false
      if (this.getAccountSelection.gmb.length) {
        const validationStatus = this.checkEvergreenGmbValidation()
        if (!validationStatus) return false
      }

      const post = this.getCommonSharingDetails
      if (this.getAccountSelection.gmb.length) {
        post.gmb_options = JSON.parse(JSON.stringify(this.getGmbOptions))
      }

      post.has_first_comment =
        this.getSocialSharingFirstComment?.has_first_comment || false
      post.first_comment_message =
        this.getSocialSharingFirstComment?.first_comment_message || ''
      post.first_comment_accounts =
        this.getSocialSharingFirstComment?.first_comment_accounts || []

      console.debug('addEvergreenPost', this.getCommonSharingDetails)
      const posts = this.getEvergreenSelection.posts
      if (this.getEvergreenVariationData.addState === 'variation') {
        // section called in case of add/update variation
        // check variation is new or edit case
        if (this.getEvergreenSelection.active_post) {
          // in case of edit update specific variation through index
          posts[this.getEvergreenVariationData.postIndex][
            this.getEvergreenVariationData.variationIndex
          ] = post
        } else {
          // in case of new variation generate unique id and append in posts
          post.id = this.generateRandomId()
          posts[this.getEvergreenVariationData.postIndex].push(post)
        }
      } else {
        // section called in case of add new post
        post.id = this.generateRandomId()
        posts.push([post])
      }

      // reset evergreen stats
      this.$store.commit(
        evergreen.SET_EVERGREEN_POSTS,
        JSON.parse(JSON.stringify(posts))
      )
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'post')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, 0)
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      EventBus.$emit('AiCaptionModal_reset')

      // reset first comment
      this.getSocialSharingFirstComment.has_first_comment = false
      this.getSocialSharingFirstComment.first_comment_message = ''
      this.getSocialSharingFirstComment.first_comment_accounts = []

      this.clearModalState()
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('hide')
    },
    /**
     * this method will clear image generator modal state
     */
    clearModalState() {
      EventBus.$emit('clear-modal-state')
    },

    handleFirstCommentChange(val) {
      // Changing toggle state in store
      this.setHasFirstComment(val)
      this.showFirstCommentSection = val
    },
  },
}
</script>

<template>
  <div id="addEvergreenPost" class="modal fade addEvergreenPost normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-start">
          <div class="left_block">
            <h2 v-if="getEvergreenVariationData.addState === 'variation'">
              <template v-if="getEvergreenSelection.active_post"
                >Edit Evergreen Variation</template
              >
              <template v-else>Add Evergreen Variation</template>
            </h2>
            <h2 v-else>Add Evergreen Post</h2>
          </div>
          <button
            type="button"
            class="modal_head__close"
            data-dismiss="modal"
            @click="clearModalState"
            >&times;</button
          >
        </div>
        <div class="modal_body modal-body basic_form m_t_15">
          <SingleBox
            type="Common"
            evergreen-box="true"
            :image-uploading="imageUploading"
          ></SingleBox>

          <transition v-if="isFirstCommentEnabled" name="slide-bottom">
            <FirstCommentSection
              v-show="true"
              v-model="first_comment"
              :is-first-comment-enabled="isFirstCommentEnabled"
              @firstCommentChange="handleFirstCommentChange"
            />
          </transition>
          <ErrorSection
            :errors="socialPostContentErrors('list', 'evergreen')"
            :warnings="socialPostContentWarnings('list', 'evergreen')"
          ></ErrorSection>
          <GmbOptions v-if="getAccountSelection.gmb.length"></GmbOptions>
          <div class="post_footer text-right">
            <button
              :disabled="
                sharingPendingProcessStatus ||
                socialPostContentErrors('status') ||
                imageUploading
              "
              class="btn btn-studio-theme-space"
              data-cy="add-post"
              @click.prevent="addEvergreenPost"
            >
              <span
                v-if="
                  getEvergreenSelection.active_post &&
                  getEvergreenSelection.active_post.length
                "
              >
                Update</span
              >
              <span v-else>Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
