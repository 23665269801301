<template>
  <div class="onboarding-workspaces">
    <removeConfirmation />
    <div class="col-md-12 onboarding-workspaces__wrapper">
      <div>
        <h2
          >Create Your First Workspace

          <v-menu
              :popper-triggers="['hover']"
              placement="bottom-center"
              class="d-inline-flex "
              popper-class="tooltip-full "
          >
            <a
class="beacon ml-2 text-base"
               href="#">
              <i class="far fa-question-circle p-0" style="background: transparent;padding: 0;"></i>
            </a>
            <template v-slot:popper>
              <div>
                  <span>
                   A workspace is a collection of content calendar, posting plans, accounts, users, and assets for one brand in one single place. We recommend having a separate workspace for each one of your brands or clients you are working with. Let's set up your first workspace now and you can create more later from the dashboard.
                  </span>
              </div>
            </template>
          </v-menu>
        </h2>

        <div class="onboarding-workspaces__hint d-inline-block">
          <p
            ><b>Hint: </b>If you will be managing your personal accounts in
            ContentStudio then create a workspace with your own brand name.
          </p>
        </div>

        <template v-if="getWorkspaces.items.length">
          <div class="onboarding-workspaces__list">
            <div
              v-for="(item, i) in getWorkspacesFilter"
              :key="i"
              class="mx-auto pt-4"
            >
              <div
                v-if="item"
                class="onboarding-workspaces__workspace"
                :class="{
                  'onboarding-workspaces__workspace--active':
                    getActiveWorkspace._id === item.workspace._id &&
                    getWorkspacesFilter.length > 1,
                }"
              >
                <span>
                  <img :src="item.workspace.logo" alt="logo" />
                </span>
                <h3>{{ item.workspace.name }}</h3>
                <p>{{ item.workspace.slug }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <CreateWorkspaceForm />
        </template>
      </div>
      <div class="onboarding-workspaces__footer">
        <button
          class="btn btn-studio-theme-transparent-grey btn-size-large"
          data-cy="save-workspace"
          :disabled=" getSettingLoaders.saveWorkspace || getWorkspaces.items.length > 0"
          @click.prevent="saveWorkspace"
          >Create Workspace</button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import removeConfirmation from '@src/modules/setting/components/workspace/RemoveConfirmation'
import CreateWorkspaceForm from '@src/modules/account/components/CreateWorkspaceForm'

import WorkspaceFields from '@src/modules/setting/components/workspace/WorkspaceFields'

export default {
  name: 'CreateOrganization',
  components: {
    removeConfirmation,
    CreateWorkspaceForm,
    WorkspaceFields
  },
  methods: {
    createOnboardingWorkspace () {
      this.$store.commit(workspaceTypes.SET_WORKSPACE, null)
      this.$bvModal.show('create-workspace-modal')
    },
    async routeDiscovery () {
      // console.log(this.getWorkspacesFilter)
      // if (this.getWorkspaces.items.length > 0) {
      //   this.$router.push({ name: 'socialaccounts' })
      // } else {
      //   this.alertMessage('Please add your first workspace', 'error')
      // }

      if (this.getWorkspaces.items.length > 0) {
        await this.fetchWorkspaces(true)

        // if (this.getUserRole === 'Collaborator') {
        //   this.$router.push({ name: 'contentcategories' })
        // } else {
        //   this.$router.push({ name: 'socialaccounts' })
        // }
      } else {
        this.alertMessage('Please add your first workspace', 'error')
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters([
      'getOnboardingWorkspaces',
      'getWorkspaces',
      'getProfile',
      'getSettingLoaders',
      'getActiveWorkspace'
    ]),
    getWorkspacesFilter () {
      if (this.getProfile.onBoarding && this.getProfile.migration) {
        return this.getOnboardingWorkspaces
      }
      return this.getWorkspaces.items
    },
    hasWorkspace () {
      return this.getWorkspaces.items.length > 0
    }
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    hasWorkspace (value) {
      if (value) {
        this.trackUserMaven('onboarding_created_workspace')
        this.routeDiscovery()
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    /**
     * Redirect user to dashboard if user have at least one workspace
     */
    if (this.getWorkspaces.items.length > 0) {
      this.$router.push({
        name: 'dashboard',
        params: {workspace: this.getWorkspaces.activeWorkspace.slug}
      })
      return
    }
    // google ads conversion event
    gtag('event', 'conversion', {'send_to': process.env.VUE_APP_GTAG_SEND_TO});
  },
  mounted () {

  }
}
</script>
