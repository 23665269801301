<template>
  <div
    class="z-[1050] flex flex-col flex-1 w-full min-w-[30rem] max-w-[30rem] h-full bg-[#FFFFFF] p-0 rounded-lg relative right-[0]"
    style="border: 1px solid rgba(210, 213, 223, 0.5);border-right:0; box-shadow: 0 2px 50px 0 rgba(0, 39, 114, 0.15)"
  >
    <!-- header -->
    <div
        v-tooltip="{ content: 'Collapse' }"
        class="absolute -left-3 top-[1rem] rounded-full w-[1.875rem] h-[1.875rem] flex justify-center items-center cursor-pointer bg-white z-[20000] border"
        @click="props.toggleChatDialogs('openHistory')"
    >
      <i class="cs-angle-right w-[0.688rem] text-[#979CA0]"></i>
    </div>
    <div
      class="w-full h-[60px] bg-gray-300 flex items-center justify-between px-5 bg-opacity-30"
    >
      <span class="ml-3 text-lg font-semibold text-black-900 mt-0.5"
        >History</span
      >
    </div>
    <div
      v-if="haveHistory"
      class="overflow-y-scroll h-full scrollable-area p-4"
    >
      <!--          {{JSON.stringify(props.chatHistory)}} &lt;!&ndash; this is just for testing &ndash;&gt;-->
      <template v-for="item in chatHistory" :key="item._id">
        <div
          v-if="item._id !== props.activeChatId"
          class="p-3 bg-[#F2F3F8] rounded-e-2xl self-end w-100 mb-4 relative rounded-lg cursor-pointer hover:scale-[1.02] transition-all"
          @click="props.fetchChatById(item._id)"
        >
          <span class="text-[#3C4549] text-right block two-line-clamp">{{item.first_message.user ? item.first_message.user.content : '' }}</span>
          <div class="mt-3 w-full flex items-center justify-between">
            <span class="text-[#757A8A] text-left block two-line-clamp">{{item.first_message.assistant ? item.first_message.assistant.content : '' }}</span>
            <span class="min-w-max ml-12 bg-[#3C4549] bg-opacity-10 rounded text-[0.75rem] text-[#3C4549] py-0.5 px-1">
              {{getRelativeTime(item.created_at)}}
            </span>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="flex h-full items-center justify-center w-full">
      <span class="whitespace-nowrap font-medium text-lg">No History Yet!</span>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { defineProps, computed } from 'vue'
import useDateFormat from "@common/composables/useDateFormat";
const {momentWrapper} = useDateFormat();
// components
const props = defineProps({
  chatHistory: {
    type: Array,
    default: () => [],
  },
  activeChatId: {
    type: String,
    default: '',
  },
  fetchChatById: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
})

const haveHistory = computed(() => {
  const hasSingleItem = props.chatHistory?.length === 1;
  const isFirstItemActive = hasSingleItem && props.chatHistory[0]?._id === props.activeChatId;

  return !isFirstItemActive && props.chatHistory?.length > 0;
})

function getRelativeTime(created_at) {
  const now = momentWrapper();
  const createdAtDate = momentWrapper(created_at);
  const diffDays = now.diff(createdAtDate, 'days');

  if (diffDays === 0) {
    return 'Today';
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else {
    return createdAtDate.formatDate()
  }
}
</script>

<style lang="scss" scoped>
.scrollable-area::-webkit-scrollbar-track {
  background-color: white;
}
.two-line-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow:hidden;
}
</style>
