const COMMON_API_ENDPOINTS = {
    SEARCH_COMPETITOR: 'search',
    SAVE_REPORT: 'addUpdateCompetitorReport',
    DELETE_REPORT: 'deleteCompetitorReport',
    FETCH_ALL_REPORTS: 'getCompetitorReportsByWorkspace',
    DATA_TABLE_METRICS: 'dataTableMetrics',
    GET_REPORT_INFO: 'getCompetitorReport',
    POSTING_ACTIVITY_GRAPH_BY_TYPES: 'postingActivityGraphByTypes',
    POSTING_BY_SPECIFIC_TYPE: 'postingActivityBySpecificType',
    POSTING_ACTIVITY_TABLE_BY_TYPE: 'postingActivityTableByType',
    FOLLOWERS_GROWTH_COMPARISON: 'followersGrowthComparison',
    TOP_HASHTAGS: 'topHashtags',
    INDIVIDUAL_HASHTAG_DATA: 'individualHashtagData',
    BIOGRAPHY_DATA: 'biographyData',
    TOP_AND_LEAST_PERFORMING_POSTS: 'topAndLeastPerformingPosts',
}

const COMMON_ERROR_MESSAGES = {
    // reports
    FETCH_ALL_REPORTS: 'Could not fetch analytics reports.',

    // single report
    GET_REPORT_INFO: 'Could not fetch info for competitors.',
    SAVE_REPORT: 'Could not add/edit the report.',
    DATA_TABLE_METRICS: 'Could not fetch data for report analytics.',
    POSTING_ACTIVITY_GRAPH_BY_TYPES: 'Could not fetch data for Posting By Type Graph.',
    POSTING_BY_SPECIFIC_TYPE: 'Could not fetch data for Specific Type Graph.',
    FOLLOWERS_GROWTH_COMPARISON: 'Could not fetch data for Followers Growth Comparison Graph.',
    POSTING_ACTIVITY_TABLE_BY_TYPE: 'Could not fetch data for Specific Type Table.',
    TOP_HASHTAGS: 'Could not fetch data for most engaged hashtags.',
    INDIVIDUAL_HASHTAG_DATA: 'Could not fetch data for individual hashtag.',
    BIOGRAPHY_DATA: 'Could not fetch data for bio analysis.',
    TOP_AND_LEAST_PERFORMING_POSTS: 'Could not fetch data for top and least performing posts.',
    DELETE_REPORT: (reportName) => `Could not delete ${reportName}.`,


    DEFAULT: 'Something went wrong.',
}

const FACEBOOK_API_ENDPOINTS = {
    POST_REACTS_DISTRIBUTION_BY_COMPANY: 'postReactDistributionByCompany',
    POST_ENGAGEMENT_OVER_TIME: 'postEngagementOverTime',
    POST_ENGAGEMENT_BY_COMPETITORS: 'postEngagementByCompetitor',
}

const FACEBOOK_ERROR_MESSAGES = {
    POST_REACTS_DISTRIBUTION_BY_COMPANY: 'Could not fetch data for Post React Distribution By Company Graph.',
    POST_ENGAGEMENT_OVER_TIME: 'Could not fetch data for Post Engagement Over Time Graph.',
    POST_ENGAGEMENT_BY_COMPETITORS: 'Could not fetch data for Post Engagement By Company Graph.',
}

const PLATFORMS = {
    INSTAGRAM: 'instagram',
    FACEBOOK: 'facebook',
    LABELSANDCAMPAIGNS: 'labelsAndCampaigns',
}

export {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
    FACEBOOK_API_ENDPOINTS,
    FACEBOOK_ERROR_MESSAGES,
    PLATFORMS,
}
