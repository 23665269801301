<template>
  <div class="flex items-center w-full">
    <span class="relative rounded-full" :class="{ 'selected_border': selected }">
      <img
          :src="platformLogo"
          width="28"
          height="28"
          alt="platform"
          class="rounded-full object-cover border"
          @error="handleError"
      />
      <img
          :src="require(`./../../../assets/img/integration/${badgeSrc}`)"
          alt="badge"
          class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
      />
    </span>
  </div>
</template>

<script setup>

// Props
defineProps({
  platformLogo: {
    type: String,
    required: true,
  },
  badgeSrc: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

// Error handler
const handleError = (event) => {
  event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg';
};
</script>

<style scoped>
.selected_border {
  border: 1px solid #00c489;
  padding: 2px;
}
</style>
