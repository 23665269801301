<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, watch, onMounted, onUnmounted, nextTick } from 'vue'
import SocialMediaViewerLayout from '@src/modules/planner_v2/components/SocialMediaViewer/MainLayout'
import TiktokScreen from '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/MainScreen'
import TiktokSidebar from '@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/SidebarComponent.vue'
import useTiktokGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Tiktok/useTiktokGridView'

const store = useStore()
const route = useRoute()
const {
  DEFAULT_SELECTED_TAB,
  selectedTab,
  filteredItems,
  selectedAccount,
  selectedPost,
  fetching,
  fetchingMoreItems,
  showRemoteMedia,
  remoteMedia,
  hasMoreRemoteMedia,
  fetchedMediaCursor,
  filteredItemsAndRemoteMedia,
  selfOnlyPosts,
  reset,
  fetchMedia,
  sortArrayByDate,
  getPostingDetailsOnSelectedAccount,
} = useTiktokGridView()

const backGroundStyles = computed(() => {
  return {
    backgroundColor: selectedPost?.value ? '#020b12' : '',
    borderRadius: selectedPost?.value ? '28px' : '',
  }
})

onMounted(() => {
  selectedTab.value = DEFAULT_SELECTED_TAB
})

onUnmounted(() => reset())

watch(selectedAccount, async (newVal) => {
  if (newVal?.platform_identifier) {
    hasMoreRemoteMedia.value = true
    filteredItems.value = []
    remoteMedia.value = []
    filteredItemsAndRemoteMedia.value = []
    fetchedMediaCursor.value = null
    selfOnlyPosts.value = []
    await nextTick()
    await fetchMedia()
  } else {
    selectedAccount.value = null
  }
})

watch(
  route,
  (newVal) => {
    const newStatuses = newVal?.query?.statuses?.split(',')

    if (newStatuses === undefined) {
      showRemoteMedia.value = true

      return 1
    }

    showRemoteMedia.value = !!(
      newStatuses.includes('published') || newStatuses.length >= 5
    )
  },
  { immediate: true, deep: true }
)

watch(
  () => store.getters.getPlans.items,
  (newVal) => {
    const privatePlans = []
    const filteredPlans = []
    // Iterate through the new value and separate the posts based on their privacy level
    newVal?.forEach((item) => {
      // Check if the post is draggable based on its status
      const isDraggable = ['draft', 'scheduled', 'reviewed'].includes(
        item.post_state
      )
      const postingDetails = getPostingDetailsOnSelectedAccount(item)

      if (item.tiktok_options?.privacy_level === 'SELF_ONLY') {
        privatePlans.push({
          ...item,
          isDraggable,
          posting_details: postingDetails,
        })
      } else {
        filteredPlans.push({
          ...item,
          isDraggable,
          posting_details: postingDetails,
        })
      }
    })

    selfOnlyPosts.value = sortArrayByDate(privatePlans)
    filteredItems.value = sortArrayByDate(filteredPlans)
  }
)
</script>

<template>
  <SocialMediaViewerLayout>
    <template v-slot:body>
      <div class="flex justify-center align-items-center h-full w-full">
        <div class="relative h-full min-w-[10rem]" :style="backGroundStyles">
          <img
            class="h-full min-w-[10rem]"
            :src="require('@assets/img/planner/phone-prototype.svg')"
            alt="Phone prototype"
          />
          <div
            class="custom-padding flex justify-content-center absolute top-0 left-0 w-full h-full p-[0.4rem]"
            :class="selectedPost && 'xl-p-[0.6rem] 2xl:p-[0.7rem]'"
          >
            <div v-if="!selectedAccount" class="flex align-items-center">
              <clip-loader
                v-if="fetching && !fetchingMoreItems"
                class="spinner ml-2"
                :color="'#e0dfdf'"
                :size="'60px'"
              >
              </clip-loader>
              <div v-else>
                <img
                  class="w-full px-[3rem] mb-5"
                  src="@assets/img/planner/no-content.jpg"
                  alt="No content found"
                />
                <div class="px-10">
                  <p class="text-center font-semibold">
                    No post available yet
                  </p>
                  <p class="text-center text-muted">
                    Please compose a post for Tiktok to view the grid.
                  </p>
                </div>
              </div>
            </div>
            <template v-else>
              <TiktokScreen />
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:timeline>
      <TiktokSidebar />
    </template>
  </SocialMediaViewerLayout>
</template>

<style lang="scss" scoped>
@media screen and (min-width: 2130px) {
  .custom-padding {
    padding: 0.5rem !important;
  }
}
</style>
