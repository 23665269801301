<template>
  <div
    class="grid !gap-2"
    :class="state.loopLimit === 5 ? 'grid-cols-5' : 'grid-cols-4'"
  >
    <div v-for="(post, index) in displayedPosts" :key="`${postsType}_${index}`">
      <PerformingPostsCard
          :data="getPostData(post)"
          @preview-performance-post="$emit('preview-performance-post', index)"
      >
        <template v-slot:media-content>
          <template v-if="platformName === 'instagram'">
            <template v-if="post[4] === 'VIDEO' && post[5]">
              <video id="video" :src="post[5]" type="video/mp4" class="rounded-lg h-[7.25rem] w-[6rem] xl:w-[7rem] 2xl:w-[8rem]"/>
            </template>
            <template v-else-if="post[4] === 'CAROUSEL_ALBUM' || post[4] === 'IMAGE' || post[4] === 'VIDEO'">
              <img
                  :src="post[4] === 'VIDEO' && !post[5] ? getResizedImageURL(getMediaLink(post[6])) : getImageLink(post[4], post[5])"
                  loading="lazy"
                  alt=""
                  class="rounded-lg h-[7.25rem] w-[6rem] xl:w-[7rem] 2xl:w-[8rem]"
                  @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'"
              />
            </template>
          </template>
          <template v-else-if="platformName === 'facebook'">
            <template v-if="(post.media_type === 'videos' || post.status_type === 'videos') || (post.media_type === 'image' || post.media_type === 'carousel' || post.media_type === 'link' || post.status_type === 'images') && post.media[0].link">
              <img
                  :src="getImageLink(post.media_type, post.media[0].link)"
                  loading="lazy"
                  alt=""
                  class="rounded-lg h-[7.25rem] w-[6rem] xl:w-[7rem] 2xl:w-[8rem]"
                  @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'"
              />
            </template>
            <template v-else-if="post.media_type === 'text' || post.status_type === 'text'">
              <span class="line-clamp-5 !text-xs px-1.5 pt-1.5">{{post.caption}}</span>
            </template>
            <template v-else>
              <img
                  src="https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png"
                  loading="lazy"
                  alt=""
                  class="rounded-lg h-[7.25rem] w-[6rem] xl:w-[7rem] 2xl:w-[8rem]"
              />
            </template>
          </template>
        </template>
        <template v-slot:tooltip-content>
          <template v-if="platformName === 'instagram'">
            <p class="text-sm flex justify-between text-gray-900"
            >Engagement
              <span class="ml-3 font-bold">{{
                  `${parseInt(post[0]).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Eng. Rate
              <span class="ml-3 font-bold"
              >{{ getEngagementRate(followers, post[0]) }}%</span
              ></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Likes
              <span class="ml-3 font-bold">{{
                  `${parseInt(post[8]).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Comments
              <span class="ml-3 font-bold">{{
                  `${parseInt(post[9]).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Post Type <span class="ml-3 font-bold">{{ `${getMediaTypeName(platformName, post[4], post[11])}` }}</span></p
            >
          </template>
          <template v-else-if="platformName === 'facebook'">
            <p class="text-sm flex justify-between text-gray-900"
            >Engagement
              <span class="ml-3 font-bold">{{
                  `${parseInt(post.post_engagement).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Eng. Rate
              <span class="ml-3 font-bold"
              >{{ getEngagementRate(followers, post.post_engagement) }}%</span
              ></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Reacts
              <span class="ml-3 font-bold">{{
                  `${parseInt(post.total_post_reactions).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Comments
              <span class="ml-3 font-bold">{{
                  `${parseInt(post.comments).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Shares
              <span class="ml-3 font-bold">{{
                  `${parseInt(post.shares).toLocaleString()}`
                }}</span></p
            >
            <p class="text-sm flex justify-between text-gray-900"
            >Post Type <span class="ml-3 font-bold">{{ `${getMediaTypeName(platformName, post.media_type)}` }}</span></p
            >
          </template>
          <hr class="mx-1 my-2 text-gray-800" />
          <p class="text-xs w-full flex justify-center text-gray-900">
            Click for full preview</p
          >
        </template>
      </PerformingPostsCard>
    </div>
  </div>
</template>

<script setup>
// libraries
import { computed, onMounted, onUnmounted, reactive } from 'vue'

// components
import PerformingPostsCard from '@src/modules/analytics_v3/components/PerformingPostsCard.vue'
import useCompetitorHelper from "@src/modules/analytics_v3/composables/useCompetitorHelper";

const props = defineProps({
  postsData: {
    type: Array,
    default: () => [],
  },
  postsType: {
    type: String,
    default: '',
  },
  followers: {
    type: String,
    default: '',
  },
  platformName: {
    type: String,
    default: '',
  }
})
defineEmits(['preview-performance-post'])

const {getMediaTypeName, getEngagementRate, getMediaLink} = useCompetitorHelper()
const state = reactive({
  loopLimit: 5,
  screenWidth: window.innerWidth,
})

const displayedPosts = computed(() => {
  state.loopLimit = state.screenWidth >= 1510 ? 5 : 4
  return props.postsData?.slice(0, state.loopLimit)
})

const updateScreenWidth = () => {
  state.screenWidth = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenWidth)
})

const getPostData = (post) => {
  switch(props.platformName){
    case 'instagram':
      return {
        engagement: parseInt(post[0])?.toLocaleString('en-US', {
          notation: 'compact',
          compactDisplay: 'short',
        }),
        id: post[1],
        mediaType: getMediaTypeName(props.platformName, post[4], post[11]),
        engagementRate: getEngagementRate(props.followers, post[0]),
      }
    case 'facebook':
      return {
        engagement: parseInt(post.post_engagement)?.toLocaleString('en-US', {
          notation: 'compact',
          compactDisplay: 'short',
        }),
        id: post.id,
        mediaType: getMediaTypeName(props.platformName, post.media_type),
        engagementRate: getEngagementRate(props.followers, post.post_engagement),
      }
    default:
      return {}
  }
}

const getImageLink = (type, link) => {
  return type === 'CAROUSEL_ALBUM' ? link.split(',')[0] : link
}
</script>