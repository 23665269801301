<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.64516 5.70968C1.25806 6.09678 0.677416 6.09678 0.290319 5.70968C-0.096777 5.32258 -0.0967771 4.74194 0.290319 4.35484L4.35484 0.290323C4.54838 0.0967751 4.74193 0 5.03225 0C5.32258 0 5.51613 0.096775 5.70967 0.290323L9.77419 4.35484C10.1613 4.74194 10.1613 5.32258 9.77419 5.70968C9.38709 6.09677 8.80645 6.09677 8.41935 5.70968L5.99982 3.29014V16.5C5.99982 17.0523 5.5521 17.5 4.99982 17.5C4.44753 17.5 3.99982 17.0523 3.99982 16.5V3.35502L1.64516 5.70968Z"
      fill="#3D8A61"
    />
  </svg>
</template>
