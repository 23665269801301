<template>
  <b-modal
      id="instagram-connect-modal"
    size="xl"
    :modal-class="
      showMobile
        ? 'create_board normal_modal connectingInstaAccount'
        : 'instaConnect modal fade normal_modal'
    "
    hide-header
    hide-footer
  >
    <div class="modal-dialog" style="margin-top: 0; pointer-events: auto">
      <div class="modal_head d-flex align-items-center">
        <button
          v-if="showMobile"
          v-tooltip.bottom="'Back'"
          type="button"
          class="close ml-0 button-custom"
          data-dismiss="modal"
          style="padding: 0"
          @click="showDefaultModal"
        >
          <i class="far fa-angle-left"></i>
        </button>
        <h2 v-if="!showMobile">Next Steps for Instagram Integration</h2>
        <h2 v-if="showMobile">Instagram Integration via Mobile Application</h2>
        <button
          v-tooltip.bottom="'Close'"
          type="button"
          class="close button-custom"
          data-dismiss="modal"
          @click="$bvModal.hide('instagram-connect-modal')"
        >
          &times;
        </button>
      </div>
      <div class="modal-content">
        <InstagramConnect
          v-if="!showMobile"
          :show-mobile="showMobileModal"
        ></InstagramConnect>

        <ConnectInstagramAccount
          v-if="showMobile"
        ></ConnectInstagramAccount>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'

import InstagramConnect from '@common/components/zapier/InstagramConnect'
import ConnectInstagramAccount from '../dialogs/instagram/ConnectInstagramAccount'

export default {
  props: [],
  components: {
    InstagramConnect,
    ConnectInstagramAccount,
  },
  data() {
    return {
      showMobile: false,
    }
  },
  computed: {
    ...mapGetters(['getMobileDevices']),
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    hideModal() {
      this.$bvModal.hide('instagram-connect-modal')
    },
    showDefaultModal() {
      this.showMobile = false
    },
    showMobileModal() {
      this.showMobile = true
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.button-custom {
  height: 35px !important;
  width: 35px !important;
  font-size: 25px !important;
  background: #f6f6f6 !important;
}
</style>
