<template>
  <div class="discovery_article_component">
    <div v-if="false" class="component_inner pt-5">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div class="component_inner">
      <div class="article-search-bar-pane">
        <!-- go back -->
        <template v-if="$route.query.tag || $route.query.q">
          <button
            class="btn article-search-bar-pane__back_button"
            @click.prevent="$router.go(-1)"
          >
            <i class="cs-angle-left"></i> Back
          </button>
        </template>
        <div class="article-search-bar-pane__search">
          <ArticleSearchInput :search_module="'article'" />
          <ArticleSearchContentType />
        </div>
        <!-- related topics suggestions -->
        <ArticleSearchRelatedTopicsSuggestions
          v-if="$route.meta.is_active && $route.meta.is_active === 'insights'"
        />
      </div>
      <template v-if="$route.meta.show_default_view">
        <ArticleSearchCuratedTopicsView
          v-if="$route.meta.show_default_view"
          :is_type_of="'insights'"
        />
      </template>
      <template v-else-if="!$route.path.includes('review_required')">
        <div class="flex-insights-container">
          <div class="left_side">
            <ArticleFollowCuratedTopicBlock />
          </div>
          <div class="right_side w-max">
            <InsightsArticleFilters />
          </div>
        </div>
      </template>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InsightsArticleFilters from './components/filters/InsightsArticleFilters'
import ArticleSearchInput from './components/ArticleSearchInput'
import ArticleSearchContentType from './components/ArticleSearchContentType'
import ArticleSearchCuratedTopicsView from './components/ArticleSearchCuratedTopicsView'
import ArticleFollowCuratedTopicBlock from './components/ArticleFollowCuratedTopicBlock'
import ArticleSearchRelatedTopicsSuggestions from './components/suggestions/content/ArticleSearchRelatedTopicsSuggestions'

export default {
  components: {
    ArticleSearchInput,
    ArticleSearchContentType,
    ArticleSearchCuratedTopicsView,
    InsightsArticleFilters,
    ArticleFollowCuratedTopicBlock,
    ArticleSearchRelatedTopicsSuggestions,
  },
  computed: {
    ...mapGetters(['getDiscoverCuratedTopic', 'getActiveWorkspace']),
  },
  watch: {
    async 'getActiveWorkspace._id'(value) {
      await this.$store.dispatch('fetchDiscoverTopCuratedTopics')
    },
  },
  async created() {
    await this.$store.dispatch('fetchDiscoverTopCuratedTopics')
  },
}
</script>

<style lang="less">
.flex-insights-container {
  padding: 20px 0 20px 0;
  border-top: 1px solid rgba(195, 203, 235, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;

  .left_side {
    flex: 1 0 0;
  }
}
</style>
