<template>
  <div v-if="isLoading">
    <SkeletonBox class="!w-full" :style="{ height: chartHeight }"></SkeletonBox>
  </div>
  <div
    v-else-if="isAllDataFetched"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
    <p>Data is being fetched.</p>
  </div>
  <div
    v-else-if="!data.length"
    class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
  >
    <img
      src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
      alt=""
    />
    <p>No Engagement Data Found.</p>
  </div>
  <div v-else class="flex w-full h-full">
    <div class="flex-1">
      <div
        ref="chartRef"
        :style="{ height: isModal ? '85vh' : chartHeight }"
      ></div>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent, computed, watch, onMounted } from 'vue'

// components
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import { commonMethods } from '@common/store/common-methods'

// composable
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'

export default defineComponent({
  name: 'PostEngagementChart',
  components: { SkeletonBox },
  props: {
    title: {
      type: String,
      default: "Competitors' Analytics",
    },
    chartHeight: {
      type: [Number, String],
      default: '400px',
    },
    xAxisLabel: {
      type: String,
      default: 'Engagement Rate',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    yAxisSecondLabel: {
      type: String,
      default: 'Followers %',
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    selectedType: {
      type: String,
      default: '',
    },
    allAvailableTypes: {
      type: Array,
      default: () => [],
    },
    chartTypes: {
      type: Array,
      default: () => ['bar'],
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    isAllDataFetched: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dateRangeDiff: {
      type: String,
      default: '30',
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['chart-type'],
  setup(props, { emit }) {
    onMounted(() => {
      if (props.isModal && chartRef.value) {
        setup(chartOptions.value)
      }
    })


    /**
     * @description emits the selected type to change chart.
     * @param value
     */
    const changeChartType = (value) => {
      let type = ''
      switch (value) {
        case 'Image':
          type = 'IMAGE'
          break
        case 'Video':
          type = 'VIDEO'
          break
        case 'Reel':
          type = 'VIDEO REEL'
          break
        case 'Carousel':
          type = 'CAROUSEL ALBUM'
          break
      }
      emit('chart-type', type)
    }

    /**
     * @description method to check and create labels(having slug) or label objects having images as background colors to be plotted on axisLabels
     */
    const getLabels = () => {
      const labels = {}
      props.data.forEach((item) => {
        labels[
          item.facebook_id
        ] = `https://microlinks.cstuinternal.com/format_image/?link=${encodeURIComponent(
          item.image
        )}`
      })
      for (const item in labels) {
        labels[item] = {
          height: 35,
          width: 35,
          align: 'center',
          backgroundColor: {
            image: labels[item],
          },
        }
      }
      return labels
    }

    /*
     * Tooltip formatter
     */
    const tooltipFormatter = (params) => {
      const image = params[0]?.value[1]
      const name = params[0]?.value[4]
      const engagement = params[0]?.value[2]
      const posts = params[0]?.value[3]
      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <img src="${image}" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
            />
            <span class="text-[#000D21] text-base font-weight-500 w-[10rem] 2xl:w-[14rem] truncate">${name}</span>
          </div>
            <div class="flex items-end justify-between mt-3">
              <span class="text-[979CA0] text-sm mr-1.5">Engagement:</span>
              <span class="text-[#69788E] text-sm font-bold">${engagement}</span>
            </div>
            <div class="flex items-end justify-between mt-1">
              <span class="text-[979CA0] text-sm mr-1.5">Posts:</span>
              <span class="text-[#69788E] text-sm font-bold">${posts}</span>
            </div>
        </div>
        `
    }

    /**
     * @description creates data for bar chart
     */
    const getBarChartData = () => {
      return {
        data: [
          ...props.data.map((item) => [
            item.facebook_id,
            item.image,
            item.total_engagements,
            item.total_posts,
            item.page_name,
          ]),
        ],
      }
    }

    /**
     * @description sets the background styling for xAxis label
     */
    const getXAxisBackgroundStyle = () => {
      return {
        borderRadius: 20,
        borderColor: 'gray',
        borderWidth: 0.5,
      }
    }

    /**
     * @description creates proper labels for posting types on xAxis
     * @param value
     * @returns {*|string}
     */
    const getXAxisLabelValue = (value) => {
      switch (value) {
        case 'VIDEO REEL':
          return 'Reel'
        case 'CAROUSEL ALBUM':
          return 'Carousel'
        case 'IMAGE':
          return 'Image'
        case 'VIDEO':
          return 'Video'
        default:
          return value
      }
    }

    const chartOptions = computed(() => ({
      legend: props.legend,
      color: props.colors,
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            color: '#61BAE4',
            backgroundColor: '#E6F7FF',
          },
          crossStyle: {
            color: '#61BAE4',
            width: 1,
          },
        },
        formatter: tooltipFormatter,
      },
      grid: {
        left: 50,
        top: 30,
        right: 60,
        bottom: 50,
        show: true,
        width: 'auto',
        borderColor: '#E9EFF6',
        containLabel: true,
      },
      xAxis: {
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        type: 'category',
        name: props.xAxisLabel,
        nameLocation: 'center',
        nameTextStyle: {
          color: '#979CA0',
        },
        nameGap: 60,
        axisLabel: {
          color: '#979CA0',
          interval: 0,
          align: 'center',
          ...getXAxisBackgroundStyle(),
          margin: 14,
          formatter: (value) => {
            return props.xAxisLabel === 'Post Type'
              ? getXAxisLabelValue(value)
              : '{' + value + '| }'
          },
          rich: {
            ...getLabels(),
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'white',
          },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          name: `{a|} {b|${props.yAxisLabel}}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ED6AF',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'left',
          nameLocation: 'center',
          nameGap: 50,
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value?.toLocaleString('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              })
            },
          },
          axisPointer: {
            label: {
              backgroundColor: '#8ED6AF',
              color: 'white',
              formatter: (dataPoint) => {
                return dataPoint.value?.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
        },
        {
          type: 'value',
          name: `{a|} {b|${props.yAxisSecondLabel}}`,
          nameTextStyle: {
            rich: {
              a: {
                backgroundColor: '#8ABAF3',
                width: 11,
                height: 11,
                borderRadius: 2,
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: 14,
                fontSize: 12,
              },
              b: {
                color: '#979CA0',
                fontSize: 12,
                padding: [0, 0, 0, 6],
              },
            },
          },
          position: 'right',
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
          },
          nameLocation: 'center',
          nameGap: 45,
          splitLine: {
            show: false,
          },
          axisPointer: {
            label: {
              color: 'white',
              backgroundColor: '#8ABAF3',
              precision: '2',
            },
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#333',
              type: 'dashed',
              width: 5,
            },
          },
        },
      ],
      series: [
        {
          name: props.yAxisLabel,
          id: 'y-PostEngagementChart',
          type: 'bar',
          color: '#8ED6AF',
          colorBy: 'series',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          yAxisIndex: 0,
          encode: {
            x: 0,
            y: 2,
          },
          ...getBarChartData(),
        },
        {
          name: props.yAxisSecondLabel,
          id: 'y2-PostEngagementChart',
          type: 'bar',
          colorBy: 'series',
          color: '#8ABAF3',
          yAxisIndex: 1,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          barCategoryGap: '70%',
          barMaxWidth: 30,
          encode: {
            x: 0,
            y: 3,
          },
          ...getBarChartData(),
        },
      ],
    }))

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => chartRef.value,
      () => {
        if (!chartRef.value) return
        setup(chartOptions.value)
      }
    )

    return {
      chartRef,

      commonMethods,
      changeChartType,
    }
  },
})
</script>
