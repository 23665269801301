import { composer } from './composer-mutation-type'

const state = {
  folderStatus: false,
  fetchFolderLoader: false,
  createFolderLoader: false,
  folders: [],
  totalMyPublications: 0,
  newfolder: {
    name: '',
    color: '',
  },

  editCampaign: {
    editId: '',
    name: '',
    color: '',
  },
  id: '',
  lastUpdated: '',

  fetchPublicationLoader: false,
  fetchPlansPaginationScroll: false,

  moveCampaign: {
    id: '',
    loader: false,
    planId: '',
    campaignCreate: false
  },
  publications: {
    publications: [],
    type: 'All Posts',
    labels: [],
    members: [],
    status: [],
    category: [],
    search: '',
    folder: {
      id: '',
      name: ''
    },
    page: 1,
    limit: 12
  },
  selection: {
    ids: [],
    selectAll: false
  }
}

// getters
const getters = {
  getPublicationFolderStatus: state => {
    return state.folderStatus
  },
  getPublicationFolderEdit: state => {
    return state.editCampaign
  },
  getPublicationFolderFetchLoader: state => {
    return state.fetchFolderLoader
  },
  getPublicationCreateFolderLoader: state => {
    return state.createFolderLoader
  },
  getPublicationFolders: state => {
    return state.folders
  },
  getTotalMyPublications: state => {
    return state.totalMyPublications
  },
  getPublicationNewFolder: state => {
    return state.newfolder
  },

  getMoveCampaign: state => {
    return state.moveCampaign
  },
  getPublicationLastUpdated: state => {
    return state.lastUpdated
  },
  getPublications: state => {
    return state.publications
  },
  getPublicationFetchLoader: state => {
    return state.fetchPublicationLoader
  },
  getPublicationFetchScrollLoader: state => {
    return state.fetchPlansPaginationScroll
  },

  getPublicationBulkSelection: state => {
    return state.selection
  }

}

// actions
const actions = {}

// mutations
const mutations = {

  [composer.SET_PUBLICATION_FOLDER_STATUS] (state, status) {
    state.folderStatus = status
  },
  [composer.SET_FETCH_PUBLICATION_FOLDERS_LOADER] (state, status) {
    state.fetchFolderLoader = status
  },
  [composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER] (state, status) {
    state.createFolderLoader = status
  },

  [composer.SET_PUBLICATION_FOLDERS]  (state, folders) {
    state.folders = folders
  },
  [composer.SET_PUBLICATION_FOLDERS_TOTAL] (state, total) {
    state.totalMyPublications = total
  },
  [composer.SET_PUBLICATION_LAST_UPDATED] (state, status) {
    state.lastUpdated = status
  },

  [composer.SET_MOVE_CAMPAIGN_ID] (state, id) {
    state.moveCampaign.id = id
  },
  [composer.SET_MOVE_CAMPAIGN_PLAN_ID] (state, id) {
    state.moveCampaign.planId = id
  },
  [composer.SET_MOVE_CAMPAIGN_LOADER] (state, status) {
    state.moveCampaign.loader = status
  },
  [composer.SET_MOVE_CAMPAIGN_CREATE_STATUS] (state, status) {
    state.moveCampaign.campaignCreate = status
  },
  [composer.SET_MOVE_CAMPAIGN_DEFAULT] (state) {
    state.moveCampaign.loader = false
    state.moveCampaign.id = ''
    state.moveCampaign.planId = ''
    state.moveCampaign.campaignCreate = true
  },
  [composer.SET_FETCH_PUBLICATION_CONTENT_LOADER] (state, status) {
    state.fetchPublicationLoader = status
  },
  [composer.SET_FETCH_PUBLICATION_PAGINATION_LOADER] (state, status) {
    state.fetchPlansPaginationScroll = status
  },

  [composer.SET_PUBLICATIONS] (state, publications) {
    state.publications.publications = publications
  },
  [composer.SET_PUBLICATIONS_PAGE] (state, page) {
    state.publications.page = page
  },
  [composer.SET_PUBLICATIONS_LABELS] (state, labels) {
    state.publications.labels = labels
  },
  [composer.SET_PUBLICATIONS_MEMBERS] (state, members) {
    state.publications.members = members
  },
  [composer.SET_PUBLICATIONS_SEARCH] (state, search) {
    state.publications.search = search
  },
  [composer.SET_PUBLICATIONS_STATUS] (state, status) {
    state.publications.status = status
  },
  [composer.SET_CHECK_ALL_STATUSES] (state, status) {
    state.publications.check_all_statuses = status
  },
  [composer.SET_CHECK_ALL_CATEGORIES] (state, status) {
    state.publications.check_all_categories = status
  },
  [composer.SET_CHECK_ALL_MEMBERS] (state, status) {
    state.publications.check_all_members = status
  },
  [composer.SET_PUBLICATIONS_CATEGORY] (state, category) {
    state.publications.category = category
  },
  [composer.SET_PUBLICATIONS_FOLDER] (state, folder) {
    if (folder) {
      state.publications.folder = {
        id: folder._id,
        name: folder.name
      }
    } else {
      state.publications.folder = {
        id: '',
        name: ''
      }
    }
  },
  [composer.SET_PUBLICATIONS_DEFAULT] (state, setFolder) {
    state.folderStatus = false
    state.fetchFolderLoader = false
    state.createFolderLoader = false
    state.folders = []
    state.totalMyPublications = 0
    state.newfolder = {
      name: '',
      color: '',
    }
    state.id = ''
    state.lastUpdated = ''

    state.fetchPublicationLoader = false
    state.fetchPlansPaginationScroll = false

    state.moveCampaign = {
      id: '',
      loader: false,
      planId: '',
      campaignCreate: false
    }

    if (!setFolder) {
      setFolder = {
        id: '',
        name: ''
      }
    }
    state.publications = {
      check_all_statuses: true,
      check_all_categories: true,
      check_all_members: true,
      publications: [],
      type: 'All Posts',
      labels: [],
      members: [],
      status: ['Scheduled', 'Partial', 'Published', 'Draft', 'Failed', 'Rejected', 'Under Review', 'Missed Review', 'In Progress'],
      category: [],
      search: '',
      folder: setFolder,
      page: 1,
      limit: 12
    }
  },

  [composer.SET_PUBLICATIONS_BULK_IDS] (state, ids) {
    state.selection.ids = ids
  },

  [composer.SET_PUBLICATIONS_SELECT_ALL] (state, all) {
    state.selection.selectAll = all
  },

  [composer.SET_PUBLICATIONS_TYPE] (state, type) {
    state.publications.type = type
  },

  [composer.SET_PUBLICATIONS_EDIT_ID] (state, id) {
    state.editCampaign.editId = id
  },
  [composer.SET_PUBLICATIONS_EDIT_NAME] (state, name) {
    state.editCampaign.name = name
  },
  [composer.SET_PUBLICATIONS_EDIT_COLOR] (state, color) {
    state.editCampaign.color = color
  },
  // Add new Campaign
  [composer.SET_PUBLICATIONS_NEW_FOLDER] (state, data) {
    state.newfolder.name = data.name
    state.newfolder.color = data.color
  },


}

export default {
  state,
  getters,
  actions,
  mutations
}
