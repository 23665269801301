<template>
  <div class="blog_share_editor">
    <clip-loader
      v-if="getPublishLoaders.initializeArticlePostCreation"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>

    <div
      class="input_field editor_title"
      :class="{ hide: getPublishLoaders.initializeArticlePostCreation }"
    >
      <textarea
        id=""
        v-model="getBlogPostingDetails.title"
        name=""
        cols="30"
        rows="2"
        placeholder="Add your title here..."
      ></textarea>
    </div>

    <BlogPostEditor
      :class="{ hide: getPublishLoaders.initializeArticlePostCreation }"
    />
    <!--        <blog-editor :class="{hide:getPublishLoaders.initializeArticlePostCreation}" ></blog-editor>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BlogEditor from '../../../../composer/components/blog/BlogEditorFeeder'

import BlogPostEditor from '../../../../composer/components/blog/BlogPostEditor'
export default {
  components: {
    BlogPostEditor
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  computed: {
    ...mapGetters(['getBlogPostingDetails', 'getPublishLoaders'])
  },
  methods: {},

  watch: {}
}
</script>
