import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  articlesTypes,
  topicTypes,
} from '@src/modules/discovery/store/mutation-types'
import {EventBus} from "@common/lib/event-bus";

export const searchMixin = {
  computed: {
    ...mapGetters(['getFilters', 'getWorkspaces', 'getTopicModule']),
  },
  methods: {
    ...mapMutations([
      'SET_TOPIC_MODULE',
      'SET_POSTS',
      topicTypes.SET_POSTS_TOTAL,
      articlesTypes.SET_SEARCH_DISPLAY_TOPICS,
      articlesTypes.SET_SEARCH_ARTICLES_TOPIC,
      articlesTypes.SET_SEARCH_CONTENT_TYPE,
      articlesTypes.SET_SEARCH_KEYWORD,
      articlesTypes.SET_SEARCH_TEXT,
      articlesTypes.SET_ARTICLE_SHOW_SUGGESTIONS,
    ]),
    ...mapActions([
      'getContent',
      'articles',
      'articlesInsights',
      'fetchInfluencers',
    ]),

    async searchByKeyword(keyword) {
      console.log('searchByKeyword > ', this.getTopicModule, keyword)

      // setting the values
      this.SET_SEARCH_KEYWORD(keyword)
      this.SET_SEARCH_TEXT(keyword)
      this.SET_ARTICLE_SHOW_SUGGESTIONS(false)
      // this.$store.dispatch('trackEvent', { event: 'query_searched' })
      switch (this.getTopicModule) {
        case 'insights':
          console.log('searchByKeyword > insights')

          this.SET_SEARCH_DISPLAY_TOPICS(false)
          this.articlesInsights()
          break
        case 'influencers':
          console.log('searchByKeyword > influencers')
          EventBus.$emit('influencer-search-by-keyword')
          return
        default:
          await this.getContent({ type: 'search', keyword })
          this.articles()
          this.SET_SEARCH_CONTENT_TYPE('article')
      }
      this.$router.push({
        name: 'search_web',
        query: { q: this.getFilters.search },
      })
    },
    async searchByCuratedTopic(topic) {
      await this.getContent({ type: 'curated', topic })
      if (this.getTopicModule === 'insights') {
        await this.articlesInsights()
        this.$store.commit(articlesTypes.SET_CONTENT_LOADER, false)
      } else {
        this.articles()
      }
      // this.SET_SEARCH_DISPLAY_TOPICS(false)

      this.$router.push({ name: 'search_web' })
    },
    /**
     * Used for showing the display topics page.
     */
    viewDisplayTopics(isBack = false) {
      console.log('method:ViewDisplayTopics')
      // User selected some curated topic, and he clicks back to the listing of topics section.
      this.SET_SEARCH_DISPLAY_TOPICS(true)
      this.SET_SEARCH_ARTICLES_TOPIC(null)
      this.SET_SEARCH_CONTENT_TYPE('article')
      this.SET_SEARCH_KEYWORD('')
      this.SET_TOPIC_MODULE('content')
      this.SET_POSTS([])
      if (isBack) {
        this.$router.push({ name: 'articles' })
        return
      }
      if (this.$route.query.q) {
        this.searchByKeyword(this.$route.query.q)
      } else {
        if (this.$route.name !== 'articles') {
          this.$router.push({ name: 'articles' })
        }
      }
    },
  },
}
