<script>
import { mapGetters } from 'vuex'
import removeTeamConfirmation from './RemoveTeamConfirmation.vue'
import useDateFormat from "@common/composables/useDateFormat";
import AddTeamMember from './AddTeamMember'

export default {
  components: {
    AddTeamMember,
    removeTeamConfirmation,
  },
  setup() {
    const { momentWrapper } = useDateFormat()
    return { momentWrapper }
  },
  computed: {
    ...mapGetters(['getPlan', 'getWorkspaces', 'getProfile']),
  },
  created() {
    if (!this.hasPermission('can_view_team')) {
      this.$router.push({ name: 'profile' })
    }
  },
  methods: {
    getMemberName(member) {
      if(member?.user?.full_name) {
        return member?.user?.full_name
      }
      return this.getProfile._id === member.user_id ? (this.getProfile.firstname + ' ' + this.getProfile.lastname) : ''
    },
    isActionsAllowed(member) {
      return !!(
        this.hasPermission('can_manage_member') &&
        member.role !== 'super_admin' &&
        this.getProfile.username !== member.user.email
      )
    },
    getUsertype(member) {
      return member.membership && member.membership === 'client'
        ? 'Client'
        : 'Team'
    },
    async changePlanAction(existingPlan) {
      this.addTeamMemberModal()
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave Team Member:')
    this.$bvModal.hide('addTeamMember')
    this.$bvModal.hide('upgrade-plan-dialog')
    next()
  },
}
</script>

<template>
  <div class="setting_team_component">
    <div class="component_inner clear">
      <AddTeamMember ref="mySubComponent"></AddTeamMember>

      <remove-team-confirmation></remove-team-confirmation>

      <div class="bg-white rounded-xl mb-7 overflow-hidden">
        <div class="flex px-4 py-4 items-center justify-between">
          <div class="mx-2 font-medium text-lg">Team Members</div>
          <div class="text-right">
            <button
              v-if="hasPermission('can_manage_member')"
              class="btn btn-studio-theme-space"
              @click.prevent="
                changePlanAction(getPlan.subscription.fastspring_name)
              "
            >
              <i class="icon-add-new-member-cs"></i> &nbsp;<span
                data-cy="add-new-team-member"
              >
                Add New Team Member</span
              >
            </button>
          </div>
        </div>
        <div class="w-full rounded-xl px-4 pb-2">
          <table class="w-full p-2">
            <thead class="focus:outline-none h-16 bg-gray-50">
              <tr>
                <th class="pl-3">Name</th>
                <th class="text-center">Role</th>
                <th class="text-center">Joined as</th>
                <th class="text-center">Status</th>
                <th class="text-right pr-5">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-if="
                  getWorkspaces.activeWorkspace &&
                  getWorkspaces.activeWorkspace.members
                "
              >
                <template
                  v-for="(member, index) in getWorkspaces.activeWorkspace
                    .members"
                >
                  <tr
                    v-if="member.user"
                    :key="index"
                    style="border-top: 1px solid #f1f1f1"
                    class="focus:outline-none h-16"
                  >
                    <td class="pl-3">
                      <p class="font-medium text-sm">
                        {{getMemberName(member)}}
                      </p>
                      <p class="text-sm text-gray-800">{{
                        member.user.email
                      }}</p>
                    </td>

                    <td>
                      <p
                        class="font-medium text-sm text-center bg-gray-50 py-2 px-3 w-max rounded-md mx-auto"
                        :class="getRoleClass(member.role)"
                      >
                        {{ getMutatedUserRole(member.role) }}
                      </p>
                    </td>

                    <td class="text-center">
                      <span class="text-sm">
                        {{ getUsertype(member) }}
                      </span>
                    </td>

                    <td class="text-center">
                      <template v-if="member.status === 'invited'">
                        <p class="text-[#ff9300] text-sm font-medium"
                          >Pending invitation</p
                        >
                      </template>

                      <template v-if="member.status === 'joined'">
                        <p class="text-[#1ecb7b] text-sm font-medium">Joined</p>
                        <span class="text-xs text-gray-800">
                          {{
                            momentWrapper(
                              member.user.created_at,
                            ).formatDateTime()
                          }}
                        </span>
                      </template>
                    </td>

                    <td class="text-right pr-4 w-52">
                      <template v-if="member.status === 'invited'">
                        <span v-if="hasPermission('can_manage_member')">
                          <i
                            v-tooltip.top="'Copy Link'"
                            v-clipboard:copy="invitationLink(member)"
                            v-clipboard:success="copyInvitationLinkSuccess"
                            v-clipboard:error="copyInvitationLinkError"
                            class="icon-duplicate-cs text-[#3a4557] text-sm cursor-pointer bg-[#3a45570d] hover:bg-gray-500 p-2 mx-1 rounded-md"
                          >
                          </i>

                          <i
                            v-tooltip.top="'Resend Invitation'"
                            class="icon-resend-cs text-[#3a4557] text-sm cursor-pointer bg-[#3a45570d] hover:bg-gray-500 p-2 mx-1 rounded-md"
                            @click.prevent="resendInvitation(member)"
                          >
                          </i>
                        </span>
                      </template>

                      <template v-if="isActionsAllowed(member)">
                        <i
                          v-tooltip.top="'Edit'"
                          class="icon-edit-cs text-[#3a4557] text-xs cursor-pointer bg-[#3a45570d] hover:bg-gray-500 p-2 mx-1 rounded-md"
                          @click.prevent="editMember(member._id)"
                        ></i>
                        <i
                          v-tooltip.top="'Remove'"
                          class="icon-delete-cs text-[#3a4557] text-xs cursor-pointer bg-[#3a45570d] hover:bg-gray-500 p-2 mx-1 rounded-md"
                          data-cy="remove-member"
                          @click.prevent="
                            confirmAction('removeTeam', member._id)
                          "
                        ></i>
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
