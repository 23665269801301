<template>
  <div class="fileUploadModel__upload_file_wrapper__right_block">
    <div class="flex-row text-right">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('insertFileModel')"
        >&times;</button
      >
    </div>
    <div class="fileUploadModel__upload_file_wrapper__right_block__right_inner">
      <div
        class="fileUploadModel__upload_file_wrapper__right_block__right_inner__media_preview_block"
      >
        <div class="input_field mt-0">
          <label for="">Find an image on Pixabay</label>
          <div class="search_input with_icon_left">
            <div class="search_inner cs-input-grey">
              <!--                             @keyup.enter="searchImage('pixabay',keyword)"-->
              <input
                ref="searchArea"
                v-model="keyword"
                type="text"
                class=""
                placeholder="Search for something"
                data-cy="pixabay-search"
                @keyup.enter="searchPixabayImage"
              />
              <button class="search_btn">
                <i class="icon-Search"></i>
              </button>
              <i
                v-tooltip.top="'Clear'"
                class="clearsearch"
                @click="clearSearch"
                >×</i
              >
            </div>
          </div>
        </div>
      </div>

      <!--          v-if="getImagesPosts.length > 0"-->
      <div
        class="fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_upload"
      >
        <ul>
          <clip-loader
            v-if="getImagesLoader"
            color="#4165ed"
            :size="'16px'"
          ></clip-loader>

            <template v-if="getImagesPosts.length > 0">
              <!--                          @click="insertImageFromLink(source,photo.webformatURL)"-->
              <li
                v-for="photo in getImagesPosts"
                class="col-3"
                @click="processFileSelectionPixabay(photo)"
              >
                <div
                  v-if="photo.webformatURL"
                  class="image"
                  :class="{
                    active: selectedItems.includes(photo.webformatURL),
                  }"
                >
                  <img ref="pixaImage" :src="photo.webformatURL" alt="" />
                  <i
                    v-if="selectedItems.includes(photo.webformatURL)"
                    class="fa fa-check"
                  ></i>
                </div>
              </li>
            </template>

            <template v-if="!getImagesScroll && getImagesPosts.length === 0">
              <NoResultsFound></NoResultsFound>
            </template>

            <InfiniteLoading
              v-else-if="getImagesPosts.length > 0 && getImagesScroll"
              ref="infiniteLoading"
              @infinite="onInfiniteImages"
            >
              <span slot="spinner">
                <beat-loader :color="'#436aff'"></beat-loader>
              </span>
            </InfiniteLoading>
        </ul>
      </div>
      <div v-if="selectedItems.length" class="bottom_btn_section">
        <button
          class="btn blue loader royal_blue mt-3"
          data-cy="submit-button"
          @click.prevent="insertPixabayImages(selectedItems)"
        >
          <span>Insert </span>
          <span>{{ selectedItems.length }}</span>
          <span> image(s)</span>
        </button>
      </div>

      <NoResultsFound v-if="!getImagesPosts"></NoResultsFound>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../../../../discovery/components/topics/NoResultsFound'

export default {
  components: {
    InfiniteLoading,
    NoResultsFound
  },
  props: ['source', 'type'],
  data () {
    return {
      size: '28px',
      color: '#0095f3',
      keyword: '',
      selectedItems: []
    }
  },

  created () {
    this.resetImagesStates()
  },

  computed: {
    ...mapGetters([
      'getFileWidget',
      'getImagesLoader',
      'getImagesPosts',
      'getImagesScroll',
      'getImagesPage',
      'getBlogEditorSelector'
    ])
  },
  methods: {
    ...mapActions(['setImagesPage', 'resetImagesStates']),

    onInfiniteImages ($state) {
      console.log(
        'On infinite images',
        this.getImagesScroll,
        this.getImagesPage
      )
      if (this.getImagesScroll) {
        this.setImagesPage(this.getImagesPage + 1)
        this.getMediaImages(this.getFileWidget.activeTab, this.keyword, $state)
      }
    },
    searchPixabayImage () {
      // clear selected images here
      this.selectedItems = []
      this.searchImage('pixabay', this.keyword)
    },
    clearSearch () {
      this.selectedItems = []
      this.keyword = ''
      this.resetImagesStates()
    },
    insertPixabayImages (images) {
      console.debug('method:insertPixabayImages', images)
      this.insertImageFromLink(this.source, images)
    },

    processFileSelectionPixabay (image) {
      console.debug('Method::processFileSelectionPixabay', image)
      if (image && image.webformatURL) {
        if (this.selectedItems.includes(image.webformatURL) === false) {
          this.selectedItems.push(image.webformatURL)
        } else {
          const index = this.selectedItems.indexOf(image.webformatURL)
          if (index > -1) {
            this.selectedItems.splice(index, 1)
          }
        }
      }
    }
  }
}
</script>
