<template>
  <div
    id="linkedin_sharing_box"
    class="input_field social_content_box social_content_box_multi"
    :class="{
      in_active: getSocialSharingSelection.activeTab !== 'linkedin_tab',
      editor_hover: isFileHover,
    }"
    @@dragleave.self="
      $store.dispatch('leaveFileDragOver', { event: $event, type: 'Linkedin' })
    "
    @dragover="
      $store.dispatch('onFileDragOver', { event: $event, type: 'Linkedin' })
    "
    @drop="$store.dispatch('onFileDrop', { event: $event, type: 'Linkedin' })"
  >
    <div class="box_social_tag lin_bg">
      <i class="cs-linkedin"></i>
    </div>

    <div v-if="isFileHover" class="drag_content">
      <p>Drag files here</p>
    </div>

    <div class="text_content text_content_main">
      <resize-textarea
        id="linkedin_sharing_message"
        ref="linkedin_sharing_message"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }"
        class="content_textarea"
        spellcheck="false"
        :min-height="55"
        @input="handleInput"
        @keydown="handleKeysOnPopup"
        @paste="sharingMessageChanges($event, 'Linkedin')"
        @keyup.space="sharingMessageChanges($event, 'Linkedin')"
        @focus="focusSharingBox('Linkedin')"
        @click="focusSharingBox('Linkedin')"
        @keydown.ctrl.b="sharingMessageFormatting('Linkedin', 'bs')"
        @keydown.ctrl.i="sharingMessageFormatting('Linkedin', 'is')"
      ></resize-textarea>
      <div id="clone_txt_linkedin_tab" class="clone_txt">
        <span
          class="desc"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
          }"
        ></span>
        <span
          class="in_active desc"
          :class="{
            hide: getSocialSharingSelection.activeTab === 'linkedin_tab',
          }"
          @click="
            focusSharingBox('Linkedin'),
              textareaFocus($refs.linkedin_sharing_message)
          "
        ></span>
        <span
          class="exceed"
          :class="{
            hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
          }"
        ></span>
      </div>
      <HashtagSuggestions
        v-click-away="hideHashtag"
        type="Linkedin"
      ></HashtagSuggestions>

      <div class="text_content_bottom">
        <template v-if="shouldLockBasedOnSubAddons('caption_generation')">
          <button
              v-if="getSocialSharingSelection.activeTab == 'linkedin_tab'"
              class="btn btn-studio-theme-space caption-btn"
              @click.prevent="$bvModal.show('upgrade-plan-dialog')">
            <i class="fas fa-lock mr-2" style="font-size: 0.8rem"></i>
            <span
                v-tooltip="{
                  content: getLockBasedOnSubAddonsMessage('caption_generation'),
                  placement: 'top-center',
                }"
            >
              Generate Caption
            </span>
          </button>
        </template>
        <template v-else>
          <button
              v-if="getSocialSharingSelection.activeTab == 'linkedin_tab'"
              class="btn btn-studio-theme-space caption-btn"
              :disabled="
            getPublishLoaders.fetchLinkedinShortLinks ||
            !getLinkedinSharingDetails.url
          "
              @click="fetchAiCaption(getLinkedinSharingDetails.url, 'Linkedin')"
          >
          <span
              v-tooltip="{
              content: getLinkedinSharingDetails.url
                ? null
                : 'Caption generation button is disabled until a valid URL is put in the composer box',
              placement: 'top-center',
            }"
          >
            Generate Caption
          </span>
          </button>
        </template>

      </div>
    </div>

    <Options
      type="Linkedin"
      :class="{ hide: getSocialSharingSelection.activeTab !== 'linkedin_tab' }"
    ></Options>
    <clip-loader
      v-if="
        getPublishLoaders.fetchLinkedinShortLinks ||
        getPublishLoaders.fetchLinkedinSharingPreview
      "
      :class="{ hide: getSocialSharingSelection.activeTab !== 'linkedin_tab' }"
      class="link_loader"
      color="#4165ed"
      :size="'16px'"
    ></clip-loader>
    <template v-else>
      <LinkSection
        v-if="getLinkedinSharingDetails.url"
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }"
        type="Linkedin"
        :details="getLinkedinSharingDetails"
      ></LinkSection>
      <VideoSection
        v-else-if="
          (getLinkedinSharingDetails.video &&
            getLinkedinSharingDetails.video.link) ||
          getPublishLoaders.upload_linkedin_sharing_video
        "
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }"
        type="Linkedin"
        :details="getLinkedinSharingDetails"
      ></VideoSection>
      <ImageSection
        v-else-if="
          getInstagramSharingLoaders.upload_sharing_image.status ||
          getLinkedinSharingDetails.image && getLinkedinSharingDetails.image.length > 0
        "
        :class="{
          hide: getSocialSharingSelection.activeTab !== 'linkedin_tab',
        }"
        type="Linkedin"
        :details="getLinkedinSharingDetails"
      ></ImageSection>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Options from '../options/Options'
import LinkSection from '../sections/LinkSection'
import ImageSection from '../sections/ImageSection'
import VideoSection from '../sections/VideoSection'
import HashtagSuggestions from '../options/HashtagSuggestion'

export default {
  components: {
    Options,
    ImageSection,
    LinkSection,
    VideoSection,
    HashtagSuggestions
  },

  data () {
    return {}
  },

  created () {},
  mounted () {
    this.setLinkedinboxRef(this.$refs.linkedin_sharing_message)
  },

  computed: {
    ...mapGetters([
      'getLinkedinSharingDetails',
      'getPublishLoaders',
      'getSocialSharingSelection',
      'getInstagramSharingLoaders'
    ]),
    isFileHover () {
      return (
        this.getSocialSharingSelection.drag_over_status.linkedin &&
        this.getSocialSharingSelection.activeTab === 'linkedin_tab'
      )
    }
  },
  methods: {
    ...mapActions(['setLinkedinboxRef']),
    handleKeysOnPopup (e) {
      if (
        this.getSocialSharingSelection.hashTagsSuggestions.dropdown.status &&
        (e.keyCode === 38 || e.keyCode === 40)
      ) {
        e.preventDefault()
      }
    },
    handleRerender () {
      console.debug('Method::handleRerender')
      const text = document.getElementById('linkedin_sharing_message').value
      if (!text) {
        const message = this.getLinkedinSharingDetails.message
        document.getElementById('linkedin_sharing_message').value = message
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(message, 'Linkedin'),
          'Linkedin'
        )
      }
    },
    handleInput(event) {
      this.handleRerender()
      this.sharingMessageChanges(event, 'Linkedin')
    }
  },

  watch: {
    'getLinkedinSharingDetails.message' (value) {
      this.setSharingBoxHtml(
        this.processSharingBoxHtml(value, 'Linkedin'),
        'Linkedin'
      )
    }
  }
}
</script>
