<script>
export default {
  props: ['errors', 'warnings'],
  computed: {
    hasSingleItem () {
      let itemsCount = 0
      if (this.errors) itemsCount += this.errors.length
      if (this.warnings) itemsCount += this.warnings.length
      return itemsCount === 1
    },
    showSingleItem () {
      if (this.errors && this.errors.length > 0) {
        return (
          '<p><i style="color: #ec4134" class="far fa-exclamation-circle mr-2"></i> Note: ' +
          this.errors[0] +
          '</p>'
        )
      }
      if (this.warnings && this.warnings.length > 0) {
        return (
          '<p><i style="color: #fea500" class="far fa-exclamation-triangle mr-2"></i> Note: ' +
          this.warnings[0] +
          '</p>'
        )
      }
      return ''
    },
    hasMultipleItems () {
      let itemsCount = 0
      if (this.errors) itemsCount += this.errors.length
      if (this.warnings) itemsCount += this.warnings.length
      return itemsCount > 1
    },
    totalErrorsCount () {
      return this.errors ? this.errors.length : 0
    },
    totalWarningsCount () {
      return this.warnings ? this.warnings.length : 0
    }
  },
  methods: {}
}
</script>

<template>
  <div>
    <!-- single item -->
    <div v-if="hasSingleItem" class="error_contain mb-2 mt-3 mx-0">
      <div class="error_single" v-html="showSingleItem"></div>
    </div>

    <!-- multiple items -->
    <div v-if="hasMultipleItems" class="error_contain mb-2 mt-3 mx-0">
      <b-button v-b-toggle.collapse-social-errors class="cl_main_btn">
        <span v-if="totalWarningsCount" class="mr-3"
          ><i
            style="color: #fea500"
            class="far fa-exclamation-triangle mr-2"
          ></i
          >{{ totalWarningsCount }}
          <span v-if="totalWarningsCount === 1">Warning</span>
          <span v-else> Warnings</span></span
        >
        <span v-if="totalErrorsCount"
          ><i style="color: #ec4134" class="far fa-exclamation-circle mr-2"></i
          >{{ totalErrorsCount }}
          <span v-if="totalErrorsCount === 1">Error</span>
          <span v-else>Errors</span>
        </span>
        <i class="icon-dropdown-cs"></i>
      </b-button>

      <b-collapse id="collapse-social-errors" class="cl_body">
        <div
          v-for="(warning, i) in warnings"
          :key="'warnings' + i"
          class="cl_list"
        >
          <p
            ><i
              style="color: #fea500"
              class="far fa-exclamation-triangle mr-2"
            ></i
            ><span v-html="warning"></span>
          </p>
        </div>

        <div v-for="(error, i) in errors" :key="'errors' + i" class="cl_list">
          <p
            ><i
              style="color: #ec4134"
              class="far fa-exclamation-circle mr-2"
            ></i
            ><span v-html="error"></span
          ></p>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
