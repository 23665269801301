<script setup>
import { ref, computed } from 'vue'
import ConnectedAccountsDropdown from '@/src/modules/account/views/onboarding/ConnectedAccountsDropdown.vue'
import TwitterDropdown from '@/src/modules/account/views/onboarding/TwitterDropdown.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import useSocialAccountsModal from '@/src/modules/account/composables/useSocialAccountsModal'
import { useStore } from '@/src/store/base'


const props = defineProps({
  platform: {
    type: Object,
    required: true,
  },
})

// declarations
const store = useStore()
const isActive = ref(false)
const isActiveTwitter = ref(false)
const { getSocialImageRounded, showTypes } = useComposerHelper()
const { selectedPlatform, connectAccount, connectTwitterCustomApp } =
    useSocialAccountsModal()

// methods
const onDropdownStateChange = (state) => {
  if (state) {
    isActive.value = true
  }

  if (!state) {
    setTimeout(() => (isActive.value = false), 150)
  }
}

const onDropdownStateChangeTwitter = (state) => {
  if (state) {
    isActiveTwitter.value = true
  }

  if (!state) {
    setTimeout(() => (isActiveTwitter.value = false), 150)
  }
}

const handleConnect = () => {
  selectedPlatform.value = props.platform.name

  connectAccount()
}

const handleTwitterCustomApp = (_id) => {
  selectedPlatform.value = props.platform.name

  connectTwitterCustomApp(_id)
}

// computed properties
const accounts = computed(() => {
  const items = store.getters.getAllPlatforms[props.platform.name]

  return items?.map((item) => ({
    ...item,
    channel_type: props.platform.name,
  })) ?? []
})

</script>

<template>
  <div
      class="
      flex
      relative
      w-full
      h-[3.25rem]
      mb-[0.5rem]
      rounded-[0.5rem]
      justify-between
      align-items-center
      p-[1rem]
      bg-white
      hover:!border-[#409aff]
      border
    "
      :class="(isActive && 'z-10') || (isActiveTwitter && 'z-10')"
  >
    <div class="flex align-items-center gap-2">
      <!-- Platform name & Logo -->
      <img
          :src="getSocialImageRounded(platform.name)"
          :class="[platform.name === 'twitter' ? 'h-[1.6rem] w-[1.6rem]' : 'h-[1.8rem] w-[1.8rem]']"
          alt="Social Image"
      />
      <p class="text-[0.675rem] xl:text-[0.875rem] font-normal">
        {{ platform.label }}
        <span
            v-if="showTypes(platform.types)"
            class="text-[#757A8A] text-[0.675rem] xl:text-xs"
        >
          ({{ platform.types.join(' & ') }})
        </span>
      </p>
    </div>

    <div class="flex justify-between align-items-center gap-14">


      <!-- Connect -->
      <div v-if="platform.name === 'twitter'" class="flex items-center flex-row-reverse gap-x-4">
        <TwitterDropdown
            :total-accounts="accounts?.length"
            @on-dropdown-state-change="onDropdownStateChangeTwitter"
            @on-connect="handleConnect"
            @on-connect-custom-app="handleTwitterCustomApp"
        />
        <ConnectedAccountsDropdown
            :platform="platform"
            class="cursor-pointer"
            @on-dropdown-state-change="onDropdownStateChange"
        />
      </div>

      <span
          v-else
          class="
          cursor-pointer
          rounded-lg
          px-2
          flex
          items-center
          gap-x-4
        "
      >
        <ConnectedAccountsDropdown
            :platform="platform"
            class="cursor-pointer"
            @on-dropdown-state-change="onDropdownStateChange"
        />
        <p class="group flex items-center">
          <img src="@assets/img/icons/onboarding/add.svg" alt="add" class="w-5 h-5 block group-hover:hidden">
          <span @click="handleConnect" class="text-[0.75rem] xl:text-[0.875rem] text-[#2A70FC] font-medium hidden group-hover:block"> Connect </span>
        </p>
      </span>
    </div>
  </div>
</template>

<style scoped>
.parent:hover {
  border: 0.5px solid #2973e5;
  transform: scaleX(1.04);
  -webkit-transform: scaleX(1.04);
  -ms-transform: scaleX(1.04);
  box-shadow: inset 0px 20px 40px 0px rgba(0, 0, 0, 0.75);
}

.parent:hover :deep(.child),
.parent:hover p {
  color: #2973e5;
}

.parent:hover + div,
:has(+ .parent:hover) {
  transform: scaleX(1.02);
  -webkit-transform: scaleX(1.02);
  -ms-transform: scaleX(1.02);
  box-shadow: inset 0px 20px 40px 0px rgba(0, 0, 0, 0.75);
}

.parent {
  box-shadow: 0px 2px 4px 0px rgba(15, 0, 111, 0.08) !important;
}
</style>
