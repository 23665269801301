import { tiktok } from '@src/modules/integration/store/states/mutation-types'
import {
  tiktokTypes,
  tiktokSharingTypes,
} from '@src/modules/publish/store/states/mutation-types'

const getDefaultTiktokAccountsState = () => {
  return {
    items: [],
  }
}
const getDefaultTiktokSharingVideo = () => {
  return {
    name: '',
    title: '',
    mime_type: '',
    link: '',
    duration: '00:00:00.00',
    size: 0,
    thumbnail: '',
    thumbnails_suggestions: [],
    converted_video: '',
    converted_link: '',
    converted_mime_type: '',
    converted_size: 0,
  }
}

const getDefaultTiktokSharingDetails = () => {
  return {
    message: '',
    video: getDefaultTiktokSharingVideo(),
  }
}
export default {
  state: {
    accounts: getDefaultTiktokAccountsState(),
    selection: {
      message: '',
      video: getDefaultTiktokSharingVideo(),
    },
    loaders: {
      upload_sharing_image: { status: false, position: -1 },
    },
    progressBar: {
      image: 0,
      numberOfImages: 0,
      video: 0,
    },
  },
  getters: {
    getTiktokAccounts: (state) => state.accounts,
    getTiktokSharingDetails: (state) => state.selection,
    getTiktokSharingLoaders: (state) => state.loaders,
    getTiktokSharingProgressBar: (state) => state.progressBar,
  },
  actions: {},
  mutations: {
    [tiktok.SET_ACCOUNTS](state, account) {
      state.accounts.items = account.sort((a, b) =>
        a.platform_name
          .toLowerCase()
          .localeCompare(b.platform_name.toLowerCase())
      )
    },
    [tiktok.SET_ALL_ACCOUNTS](state, account) {
      // state.accounts.items = account;
    },
    [tiktokTypes.SET_SHARING_VIDEO_INITIALIZATION](state) {
      state.selection.image = ''
      state.selection.url = ''
      state.selection.video = getDefaultTiktokSharingVideo()
      state.progressBar.video = 0
    },
    [tiktokTypes.SET_SHARING_VIDEO_PERCENTAGE](state, percent) {
      state.progressBar.video = percent
    },
    [tiktokTypes.SET_SHARING_VIDEO](state, video) {
      if (video) {
        state.selection.video = video
      } else {
        state.selection.video = getDefaultTiktokSharingVideo()
      }
    },
    [tiktokSharingTypes.RESET_TIKTOK_SHARING_DETAILS](state) {
      state.selection = getDefaultTiktokSharingDetails()
      state.cancelledLink = ''
      state.progressBar = {
        image: 0,
        numberOfImages: 0,
        video: 0,
      }
    },
    [tiktokTypes.SET_SHARING_DETAILS](state, details) {
      state.cancelledLink = ''
      if (details == null) {
        state.selection = getDefaultTiktokSharingDetails()
        return false
      }
      // if (details.image.constructor !== Array) details.image = details.image.length ? [details.image] : []
      state.selection = details
    },
  },
}
