<template>
  <b-modal
    id="add_slot"
    dialog-class="flex justify-center items-center "
    body-class="min-h-[567px]"
    centered
    size="lg"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    @show="handleModalShow"
    @hide="handleModalHide"
  >
    <div class="modal_head">
      <h4>
        <template v-if="!getContentCategorySlotAdd._id">
          Add New Slot
        </template>
        <template v-else> Edit Slot </template>
      </h4>
      <button
        type="button"
        class="modal_head__close"
        @click="handleCloseSlotWithConfirmation"
        >&times;
      </button>
    </div>

    <div class="modal_body w-[75%] mx-auto py-16">
      <div class="field_group my-2">
        <label for="">Category Name</label>
        <div class="category_dropdown dropdown default_style_dropdown">
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="text">
              <template v-if="!getContentCategorySlotAdd.category_id">
                Select category from list
              </template>
              <template v-else>
                <span class="text">
                  <template
                    v-for="(category, index) in getContentCategoryList"
                    :key="`add_slot_${index}`"
                  >
                    <template
                      v-if="
                        category._id === getContentCategorySlotAdd.category_id
                      "
                    >
                      <span class="ct_color" :class="category.color"></span>
                      <span class="ct_text" data-cy="existing-categories">{{
                        category.name
                      }}</span>
                    </template>
                  </template>
                </span>
              </template>
            </span>

            <span class="ml-auto">
              <i class="icon-dropdown-cs"></i>
            </span>
          </div>
          <div
            class="dropdown-menu dropdown-menu-right"
            data-cy="select-category"
          >
            <ul class="inner">
              <template v-if="getContentCategoryList.length > 0">
                <template
                  v-for="(category, index) in getContentCategoryList"
                  :key="`select_category_${index}`"
                >
                  <li
                    :value="category._id"
                    class="list_item_li"
                    data-cy="existing-slots"
                    @click="selectSlotCategory(category)"
                  >
                    <span class="ct_color" :class="category.color"></span>
                    <span class="ct_text"
                      ><template v-if="category.category_state === 'global'"
                        ><i
                          v-tooltip="'Global Category'"
                          style="font-size: 13px; padding-right: 5px"
                          class="far fa-crown"
                        ></i
                      ></template>
                      {{ category.name }}
                      <template v-if="category.category_state === 'global'"
                        >(Global)</template
                      ></span
                    >
                  </li>
                </template>
              </template>

              <li v-else class="no_text_li"> No category added yet. </li>
            </ul>
          </div>
          <span v-if="validations.category" class="input-error">
            {{ messages.category }}
          </span>
        </div>
      </div>

      <div class="field_group pt-4">
        <label for="">Choose a time</label>
      </div>

      <div class="d-flex mt-2">
        <div class="field_group w-100 m-1">
          <select
            v-model="localHour"
            name=""
            class="ml-0"
            data-cy="hours-in-slots"
          >
            <template v-for="hour in hourOptions" :key="hour">
              <option :value="hour" data-cy="hours">
                <template v-if="hour <= 9"> 0{{ hour }} </template>
                <template v-else>{{ hour }}</template>
              </option>
            </template>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>

        <div class="field_group w-100 m-1">
          <select v-model="localMinute" name="">
            <option value="0"> 00 </option>
            <template v-for="minutes in 59" :key="minutes">
              <option :value="minutes">
                <template v-if="minutes <= 9"> 0{{ minutes }} </template>
                <template v-else>{{ minutes }}</template>
              </option>
            </template>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>

        <div
          v-if="getUserTimePreference === '12h'"
          class="field_group w-100 m-1"
        >
          <select v-model="localPeriod" name="">
            <option value="AM"> AM </option>
            <option value="PM"> PM </option>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>

      <div
        v-if="!getContentCategorySlotAdd._id"
        class="field_group input_validation"
      >
        <div class="time_table_inline d-flex align-items-start">
          <template v-for="day in getWeekdays" :key="day">
            <div class="time_box_main">
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="day"
                    v-model="getContentCategorySlotAdd.weekdays"
                    :value="day"
                    type="checkbox"
                  />
                  <label :for="day" data-cy="days-slot">{{
                    day.substr(0, 3)
                  }}</label>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span v-if="validations.weekdays" class="input-error">
          {{ messages.weekdays }}
        </span>
      </div>

      <!-- edit case where we give the option to update the day. -->

      <div
        v-if="getContentCategorySlotAdd._id"
        class="field_group input_validation my-1"
      >
        <div class="time_table_inline d-flex align-items-start">
          <template v-for="day in getWeekdays" :key="day">
            <div class="time_box_main">
              <div class="box_inner">
                <div class="day_box">
                  <input
                    :id="day"
                    v-model="getContentCategorySlotAdd.day"
                    :value="day"
                    type="radio"
                  />
                  <label :for="day" data-cy="days-slot">{{
                    day.substr(0, 3)
                  }}</label>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span v-if="validations.weekdays" class="input-error">
          {{ messages.weekdays }}
        </span>
      </div>

      <div
        v-if="
          !getContentCategorySlotAdd._id &&
          getContentCategorySlotAdd.category_id &&
          selected_category &&
          selected_category.category_state === 'global'
        "
        class="field_group"
      >
        <div class="radio_input_image">
          <input
            id="local"
            v-model="getContentCategorySlotAdd.slots_state"
            value="local"
            type="radio"
            name="category_type"
          />
          <label for="local" class="radio_left"
            >Add slots within this workspace
            <i
              v-tooltip="
                'Your category slots will be created only within this workspace.'
              "
              class="far fa-question-circle ml-1"
            ></i
          ></label>
        </div>
        <div class="radio_input_image ml-4">
          <input
            id="global"
            v-model="getContentCategorySlotAdd.slots_state"
            value="global"
            type="radio"
            name="category_type"
          />
          <label for="global" class="radio_left"
            >Add slots in all workspaces
            <i
              v-tooltip="
                'Your category slots will be created in all workspaces where your global category is present.'
              "
              class="far fa-question-circle ml-1"
            ></i
          ></label>
        </div>
      </div>

      <p
        v-if="selected_category.posts_count > 0"
        class="compose_warning_box pl-3 m-0 mb-3 mt-4"
      >
        Already scheduled posts will not be moved to the new slots. However,
        posts created in the future will use the new slots.
      </p>

      <span v-else></span>
    </div>

    <div class="w-[95%] px-2 mx-auto my-8 flex justify-between">
      <!-- Go back to edit category -->
      <div v-if="categoryRecentlyAdded" class="field_group">
        <CstButton
          id="backToCategory"
          variant="secondary"
          data-cy="open-edit-category-modal"
          @click="openEditCategoryModal"
        >
          <span>Back</span>
        </CstButton>
      </div>

      <!-- create the new slots -->
      <div v-if="!getContentCategorySlotAdd._id" class="field_group ml-auto">
        <CstButton
          id="createSlotButtn"
          variant="primary"
          data-cy="create-slot"
          :loading="getContentCategoryLoaders.store_slots"
          @click="validateAndStoreSlot"
        >
          <span>Create</span>
        </CstButton>
      </div>

      <!-- update scenario for the slot case -->
      <div
        v-else-if="getContentCategorySlotAdd._id"
        class="field_group ml-auto"
      >
        <CstButton
          variant="primary"
          :loading="getContentCategoryLoaders.store_slots"
          @click="validateAndUpdateSlot"
        >
          <span>Update</span>
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'
import CstButton from '@ui/Button/CstButton.vue'
import useDateFormat from '@common/composables/useDateFormat'
import { EventBus } from '@/src/modules/common/lib/event-bus'
import { swalAttributes } from '@/src/modules/common/constants/common-attributes'
export default {
  components: {
    CstButton,
  },
  setup() {
    const { getUserTimePreference } = useDateFormat()
    return {
      getUserTimePreference,
    }
  },

  data() {
    return {
      validations: {
        category: false,
        weekdays: false,
      },
      messages: {
        category: 'Please choose a category.',
        weekdays: 'Please choose a day for the slot.',
      },
      selected_category: {},
      categoryRecentlyAdded: false,
      localHour: '',
      localMinute: '',
      localPeriod: 'AM',
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategorySlotAdd',
      'getContentCategoryList',
      'getContentCategoryLoaders',
    ]),
    getWeekdays() {
      return [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ]
    },
    getComputedToastMessage() {
      return this.categoryRecentlyAdded
        ? 'New Category created with some slots'
        : 'Slot has been added successfully.'
    },
    hourOptions() {
      return this.getUserTimePreference === '24h'
        ? [...Array(24).keys()]
        : [...Array(12).keys()].map((h) => h + 1)
    },
  },
  watch: {
    'getContentCategorySlotAdd.weekdays.length'(value) {
      if (value > 0) this.validations.weekdays = false
    },
    'getContentCategorySlotAdd.category_id'(value) {
      if (value && value.length > 2) this.validations.category = false
      if (!('name' in this.selected_category) || !this.selected_category.name) {
        this.selected_category =
          this.getContentCategoryList.find((item) => item._id === value) || {}
      }
    },
  },
  unmounted() {
    EventBus.$off('category-added')
  },
  methods: {
    initializeLocalTime() {
      const { hour, minute, period } = this.getContentCategorySlotAdd
      // convert the 12h format to 24h format
      this.localHour =
        this.getUserTimePreference === '24h'
          ? period === 'PM'
            ? (hour % 12) + 12
            : hour === 12
            ? 0
            : hour
          : hour
      this.localMinute = minute
      this.localPeriod = period
    },
    async validateAndStoreSlot() {
      document.getElementById('createSlotButtn').disabled = true
      const slot = this.getContentCategorySlotAdd
      this.validations.category = !slot.category_id
      this.validations.weekdays = !(slot.weekdays.length > 0)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        // Convert local time to 12-hour format to update the store
        let hour =
          this.getUserTimePreference === '24h' && this.localPeriod === 'PM'
            ? parseInt(this.localHour, 10) % 12
            : parseInt(this.localHour, 10)

        // if hour is greater than 12 and period is AM
        if (
          this.getUserTimePreference === '24h' &&
          hour >= 12 &&
          this.localPeriod === 'AM'
        ) {
          this.localPeriod = 'PM'
          hour = hour % 12 || 12
        }

        slot.hour = hour
        slot.minute = parseInt(this.localMinute, 10)
        slot.period = this.localPeriod
        let res
        if (this.getContentCategorySlotAdd.slots_state === 'global') {
          res = await this.$store.dispatch('storeGlobalCategorySlot')
        } else {
          res = await this.$store.dispatch('storeCategorySlot')
        }

        if (res) {
          await this.$store.dispatch('toastNotification', {
            message: this.getComputedToastMessage,
            type: 'success',
          })
          this.categoryRecentlyAdded = false
          this.getContentCategorySlotAdd = {}
          this.selected_category = {}
          this.$bvModal.hide('add_slot')
        }
      }
      setTimeout(function () {
        if (document.getElementById('createSlotButtn'))
          document.getElementById('createSlotButtn').disabled = false
      }, 2000)
    },
    async validateAndUpdateSlot() {
      const slot = this.getContentCategorySlotAdd
      if (slot.category_id) {
        const hour = parseInt(this.localHour, 10)
        const is24HourFormat = this.getUserTimePreference === '24h'

        const { convertedHour, period } = is24HourFormat
          ? this.convert24HourTo12Hour(hour)
          : this.normalize12HourFormat(hour, this.localPeriod)

        Object.assign(slot, {
          hour: convertedHour,
          minute: parseInt(this.localMinute, 10),
          period,
        })

        if (await this.$store.dispatch('updateCategorySlot')) {
          this.$store.commit(
            contentCategoriesTypes.RESET_CONTENT_CATEGORY_ADD_SLOT
          )
          this.categoryRecentlyAdded = ''
          this.$bvModal.hide('add_slot')
        }
      } else {
        this.validations.category = true
      }
    },

    convert24HourTo12Hour(hour) {
      if (hour === 0) return { convertedHour: 12, period: 'AM' }
      if (hour === 12) return { convertedHour: 12, period: 'PM' }
      if (hour > 12) return { convertedHour: hour - 12, period: 'PM' }
      return { convertedHour: hour, period: 'AM' }
    },

    normalize12HourFormat(hour, period) {
      return { convertedHour: hour % 12 || 12, period }
    },

    async handleCloseSlotWithConfirmation() {
      if (this.categoryRecentlyAdded) {
        // Show a confirmation box to the user before closing the modal.
        this.$root.$bvModal
          .msgBoxConfirm(
            'Content category has been created successfully. Do you want to add the slots later?',
            {
              title: 'Add Slot Confirmation',
              ...swalAttributes(),
              cancelTitle: 'Cancel',
              okTitle: 'Add Slots Later',
            }
          )
          .then((res) => {
            if (res) {
              this.$bvModal.hide('add_slot')
              this.selected_category = {}
              this.categoryRecentlyAdded = false
            }
          })
      } else {
        this.$bvModal.hide('add_slot')
        this.selected_category = {}
        this.categoryRecentlyAdded = false
      }
    },
    selectSlotCategory(category) {
      this.selected_category = category
      this.$store.commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_CATEGORY_VALUE,
        category._id
      )
    },
    openEditCategoryModal() {
      const category = this.selected_category
      this.$store.commit(
        contentCategoriesTypes.SET_CONTENT_CATEGORY_ADD_VALUE,
        category
      )
      this.$bvModal.hide('add_slot')
      this.$bvModal.show('add_category')
    },
    handleModalHide() {
      EventBus.$off('category-added')
      this.categoryRecentlyAdded = false
      // reset the days and weekdays
      this.$store.commit(contentCategoriesTypes.RESET_CONTENT_CATEGORY_ADD_SLOT)
      // reset validations
      this.validations = {
        category: false,
        weekdays: false,
      }
      // Reset local state
      this.localHour = ''
      this.localMinute = ''
      this.localPeriod = 'AM'
    },
    handleModalShow() {
      this.initializeLocalTime()
      // on event category-added set the new added property to true
      EventBus.$on('category-added', (category) => {
        if (category) {
          this.categoryRecentlyAdded = true
          const categoryObj = this.getContentCategoryList.find(
            (item) => item._id === category?.id
          )
          this.selected_category = categoryObj || {}
        }
      })
    },
  },
}
</script>
