<template>
  <b-modal
    id="add_utm_modal"
    modal-class="add_utm_modal"
    hide-header
    hide-footer
  >
    <div class="modal_head d-flex align-items-center">
      <h4 v-if="getCurrentUtms._id">Edit your UTM parameters</h4>
      <h4 v-else>Add your UTM parameters</h4>
      <button
        id="close"
        type="button"
        class="modal_head__close"
        @click="$bvModal.hide('add_utm_modal')"
        >&times;</button
      >
    </div>
    <div class="modal_body m_t_15">
      <UtmOptions type="miscellaneous"></UtmOptions>
      <div class="field_group">
        <button
          :disabled="getSaveUtmLoader"
          :class="{ disabled: getSaveUtmLoader }"
          class="btn btn-studio-theme-space"
          data-cy="save-utm"
          @click.prevent="saveUtms"
        >
          <span>Save</span>
          <clip-loader
            v-if="getSaveUtmLoader"
            :color="'#ffffff'"
            :size="'12px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const UtmOptions = () =>
  import('../../publish/components/posting/options/UtmOptions.vue')
export default {
  components: {
    UtmOptions
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getUtmsStatus',
      'getUtms',
      'getCurrentUtms',
      'getSaveUtmLoader'
    ])
  },

  data () {
    return {}
  },

  created () {},
  mounted () {},

  methods: {},
  ...mapActions(['setCurrentUtm', 'setUtms', 'setUtmsStatus'])
}
</script>
