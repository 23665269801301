const InboxMain = () =>
  import(/* webpackChunkName: "inbox" */ '../../components/InboxMain.vue')
const Conversation = () =>
  import(/* webpackChunkName: "inbox" */ '../../components/Conversation.vue')
const Post = () =>
  import(/* webpackChunkName: "inbox" */ '../../components/Post.vue')
const InboxLinkSidebar = () =>
  import(
    /* webpackChunkName: "inbox" */ '../../components/InboxLinkSidebar.vue'
  )

export const inboxRoutes = {
  inbox: {
    path: '/:workspace/inbox',
    name: 'inbox',
    redirect: '/:workspace/inbox/unassigned/conversation',
    component: InboxMain,
    meta: {
      title: 'Inbox | ContentStudio'
    },
    children: [
      {
        path: '/:workspace/inbox/:filter/:conversation',
        name: 'inboxConversation',
        component: InboxLinkSidebar,
        meta: {
          title: 'Inbox | ContentStudio'
        }
      },
      {
        path: '/:workspace/inbox/post',
        name: 'inboxPost',
        component: Post,
        meta: {
          title: 'Inbox Post'
        }
      }
    ]
  }
}
