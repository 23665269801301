<template>
  <div class="table_box">
    <table>
      <thead>
        <th
          >Author Name
          <i
            class="sorting_icon cs-arrow-up"
            :class="{ active: sortByRow === 'key' && sortByOrder === 'asc' }"
            @click.prevent="changeSortOrder('key', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{ active: sortByRow === 'key' && sortByOrder === 'desc' }"
            @click.prevent="changeSortOrder('key', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center"
          >Total Articles
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active: sortByRow === 'doc_count' && sortByOrder === 'asc',
            }"
            @click.prevent="changeSortOrder('doc_count', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active: sortByRow === 'doc_count' && sortByOrder === 'desc',
            }"
            @click.prevent="changeSortOrder('doc_count', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center"
          >Total Engagement
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active:
                sortByRow === 'total_interactions.value' &&
                sortByOrder === 'asc',
            }"
            @click.prevent="changeSortOrder('total_interactions.value', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active:
                sortByRow === 'total_interactions.value' &&
                sortByOrder === 'desc',
            }"
            @click.prevent="changeSortOrder('total_interactions.value', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center"
          >Avg. Engagement
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active:
                sortByRow === 'average_interactions.value' &&
                sortByOrder === 'asc',
            }"
            @click.prevent="
              changeSortOrder('average_interactions.value', 'asc')
            "
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active:
                sortByRow === 'average_interactions.value' &&
                sortByOrder === 'desc',
            }"
            @click.prevent="
              changeSortOrder('average_interactions.value', 'desc')
            "
          >
          </i>
        </th>
      </thead>
      <tbody>
        <template
          v-for="(item, index) in orderedList()"
          :key="index"
        >
          <tr
v-if="
            articles_popular_authors &&
            articles_popular_authors.length > 0 &&
            item.total_interactions.value >= 0
          ">
            <td>
              {{ item.key }}
            </td>
            <td class="make-text-center">
              {{ item.doc_count }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.total_interactions.value) || 0 }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.average_interactions.value) || 0 }}
            </td>
          </tr>
        </template>
        <tr
          v-if="
            articles_popular_authors && articles_popular_authors.length === 0
          "
        >
          <td> No data found for the authors. </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--    <div class="ds_card_container  ds_domain_table">-->

  <!--        <div class="card_v1">-->
  <!--            <div class="card_inner">-->
  <!--                <div class="card_head">-->
  <!--                    <h2>Top Authors</h2>-->
  <!--                </div>-->
  <!--                <div class="card_content">-->

  <!--                    <div class="ds_analytic_table">-->
  <!--                        <div class="t_head d-flex align-items-center">-->
  <!--                            <div class="col">-->
  <!--                                <i class="sorting_icon cs-arrow-up"-->
  <!--                                   :class="{'active': sortByRow==='key' && sortByOrder==='asc'}"-->
  <!--                                   @click.prevent="changeSortOrder('key', 'asc')">-->
  <!--                                </i>-->
  <!--                                <i class="sorting_icon cs-arrow-down"-->
  <!--                                   :class="{'active': sortByRow==='key' && sortByOrder==='desc'}"-->
  <!--                                   @click.prevent="changeSortOrder('key', 'desc')">-->
  <!--                                </i>-->
  <!--                                <span class="text">Author Name</span>-->
  <!--                            </div>-->
  <!--                            <div class="col">-->
  <!--                                <i class="sorting_icon cs-arrow-up"-->
  <!--                                   :class="{'active': sortByRow==='doc_count' && sortByOrder==='asc'}"-->
  <!--                                   @click.prevent="changeSortOrder('doc_count', 'asc')">-->
  <!--                                </i>-->
  <!--                                <i class="sorting_icon cs-arrow-down"-->
  <!--                                   :class="{'active': sortByRow==='doc_count' && sortByOrder==='desc'}"-->
  <!--                                   @click.prevent="changeSortOrder('doc_count', 'desc')">-->
  <!--                                </i>-->
  <!--                                <span class="text">Total Articles</span>-->
  <!--                            </div>-->
  <!--                            <div class="col">-->
  <!--                                <i class="sorting_icon cs-arrow-up"-->
  <!--                                   :class="{'active': sortByRow==='total_interactions.value' && sortByOrder==='asc'}"-->
  <!--                                   @click.prevent="changeSortOrder('total_interactions.value', 'asc')">-->
  <!--                                </i>-->
  <!--                                <i class="sorting_icon cs-arrow-down"-->
  <!--                                   :class="{'active': sortByRow==='total_interactions.value' && sortByOrder==='desc'}"-->
  <!--                                   @click.prevent="changeSortOrder('total_interactions.value', 'desc')">-->
  <!--                                </i>-->
  <!--                                <span class="text">Total Engagement</span>-->
  <!--                            </div>-->
  <!--                            <div class="col">-->
  <!--                                <i class="sorting_icon cs-arrow-up"-->
  <!--                                   :class="{'active': sortByRow==='average_interactions.value' && sortByOrder==='asc'}"-->
  <!--                                   @click.prevent="changeSortOrder('average_interactions.value', 'asc')">-->
  <!--                                </i>-->
  <!--                                <i class="sorting_icon cs-arrow-down"-->
  <!--                                   :class="{'active': sortByRow==='average_interactions.value' && sortByOrder==='desc'}"-->
  <!--                                   @click.prevent="changeSortOrder('average_interactions.value', 'desc')">-->
  <!--                                </i>-->
  <!--                                <span class="text">Avg Engagement</span>-->
  <!--                            </div>-->
  <!--                        </div>-->

  <!--                        <div class="t_body">-->

  <!--                            <div class="t_row d-flex align-items-center"-->
  <!--                                 v-if="articles_popular_authors && articles_popular_authors.length > 0 && author.total_interactions.value >= 0"-->
  <!--                                 v-for="author in orderedList()">-->
  <!--                                <div class="col">-->
  <!--                                    <div class="profile_picture">-->
  <!--                                        <div class="picture_block">-->
  <!--                                            <div :class="getRandomColor()">-->
  <!--                                                <span>{{capitalizeName(author.key)}}</span>-->
  <!--                                            </div>-->

  <!--                                        </div>-->
  <!--                                        <div class="text_block">-->
  <!--                                            <p class="text">{{author.key}}</p>-->
  <!--                                        </div>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                                <div class="col">{{author.doc_count}}</div>-->
  <!--                                <div class="col">{{author.total_interactions.value | float-to-int}}</div>-->
  <!--                                <div class="col">{{author.average_interactions.value | float-to-int}}</div>-->
  <!--                            </div>-->

  <!--                            <div class="t_row d-flex align-items-center"-->
  <!--                                 v-if="articles_popular_authors && articles_popular_authors.length === 0">-->
  <!--                                <div class="col col-full-width">-->
  <!--                                    No data found for the authors.-->
  <!--                                </div>-->
  <!--                            </div>-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  props: {
    articles_popular_authors: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sortByRow: 'doc_count',
      sortByOrder: 'desc'
    }
  },
  computed: {},
  methods: {
    getRandomColor () {
      const colorsList = [
        'label_green',
        'label_blue',
        'label_purple',
        'label_pink',
        'label_red2',
        'label_light_red',
        'color_11',
        'color_12',
        'color_13',
        'color_14',
        'color_15',
        'color_16',
        'color_17',
        'color_18',
        'color_19',
        'color_20'
      ]
      return (
        'round d-flex align-items-center justify-content-center ' +
        colorsList[Math.floor(Math.random() * colorsList.length)]
      )
    },
    changeSortOrder (row, order) {
      this.sortByRow = row
      this.sortByOrder = order
    },

    orderedList () {
      return orderBy(
        this.articles_popular_authors,
        [this.sortByRow],
        [this.sortByOrder]
      )
    }
  }
}
</script>
