<template>
  <!-- The Modal -->

  <b-modal
    id="influencer_detail_modal"
    modal-class="full_height influencer_detail_modal right side_slide_right w_900"
    hide-footer
    hide-header
    @hide="hideModal"
  >
    <template v-if="getInfluencerLoaders.preview">
      <beat-loader class="pt-4" :color="'#436aff'"></beat-loader>
    </template>
    <template v-else-if="preview">
      <div>
        <div v-if="$parent.checkInfluencerType !== 'twitter'" class="link_section fixed_links d-flex">
          <ul class="links">
            <li>
              <!--:class="{'animate' : Router.currentRoute.value.name === 'overview' }"-->
              <a
                href="javascript:"
                data-cy="preview_overview_tab"
                :class="{ active: active_tab === 'overview' }"
                @click.prevent="changeTab('overview')"
                >Overview <span class="line_animate_h"></span
              ></a>
            </li>
            <li v-if="$parent.checkInfluencerType !== 'youtube' && $parent.checkInfluencerType !== 'twitter'">
              <a
                href="javascript:"
                data-cy="preview_mention_tab"
                :class="{ active: active_tab === 'top_mentioned' }"
                @click.prevent="changeTab('top_mentioned')"
                >Top Mentioned <span class="line_animate_h"></span
              ></a>
            </li>

            <li v-if="$parent.checkInfluencerType === 'instagram'">
              <a
                href="javascript:"
                data-cy="preview_posts_tab"
                :class="{ active: active_tab === 'posts' }"
                @click.prevent="changeTab('posts')"
                >Posts <span class="line_animate_h"></span
              ></a>
            </li>
<!--            <li v-if="$parent.checkInfluencerType === 'twitter'">-->
<!--              <a-->
<!--                href="javascript:"-->
<!--                data-cy="preview_twitter_tab"-->
<!--                :class="{ active: active_tab === 'tweets' }"-->
<!--                @click.prevent="changeTab('tweets')"-->
<!--                >Tweets <span class="line_animate_h"></span-->
<!--              ></a>-->
<!--            </li>-->
<!--            <li v-if="$parent.checkInfluencerType === 'twitter'">-->
<!--              <a-->
<!--                href="javascript:"-->
<!--                data-cy="preview_shared_articles_tab"-->
<!--                :class="{ active: active_tab === 'shared_articles' }"-->
<!--                @click.prevent="changeTab('shared_articles')"-->
<!--                >Shared Articles <span class="line_animate_h"></span-->
<!--              ></a>-->
<!--            </li>-->

            <li v-if="$parent.checkInfluencerType === 'youtube'">
              <a
                href="javascript:"
                data-cy="preview_shared_articles_tab"
                :class="{ active: active_tab === 'videos' }"
                @click.prevent="changeTab('videos')"
                >Videos <span class="line_animate_h"></span
              ></a>
            </li>
          </ul>
          <!--<div class="dropdown_section ml-auto">
                        <div class=" dropdown option_dropdown default_style_dropdown">

                            <div class="dropdown_header d-flex align-items-center" data-toggle="dropdown">
                                <span class="text"><span class="gray_light">Date: </span>Last 30 days</span>
                                <span class=" ml-auto">
                                   <i class="dropdown_arrow icon_last cs-angle-down "></i>
                                 </span>
                            </div>

                            <div class="dropdown-menu dropdown-menu-right">
                                <ul class="inner">
                                    <li class="list_item_li">
                                        Move
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>-->
        </div>
      </div>

      <div class="modal_head d-flex align-items-center">
        <h2>Influencer Detail</h2>
        <button type="button" class="close" @click="hideModal(false)"
          >&times;</button
        >
      </div>
      <div
        v-if="Object.keys(preview) && Object.keys(preview).length > 0"
        class="modal_body height d-flex flex-column"
      >
        <div class="top_section">
          <div class="d-flex align-items-start">
            <div class="top_left">
              <div class="influencer_img">
                <span v-if="preview.verified" class="fa fa-stack fa-lg">
                  <i class="fa fa-certificate fa-stack-2x"></i>
                  <i class="fa fa-check fa-stack-1x fa-inverse"></i>
                </span>
                <div
                  v-if="$parent.checkInfluencerType === 'youtube'"
                  class="img"
                  :style="backgroundImageURL(preview.influencer_image)"
                >
                </div>
                <div
                  v-else
                  class="img"
                  :style="backgroundImageURL(preview.profile_image)"
                ></div>
              </div>
            </div>
            <div class="top_right">
              <div class="right_col d-flex align-items-start">
                <div class="detail">
                  <template v-if="$parent.checkInfluencerType === 'twitter'">
                    <h3
                      class="name"
                      data-cy="influencer_preview_name"
                      v-html="preview.display_name"
                    ></h3>
                    <a
                      v-if="preview.screen_name"
                      target="_blank"
                      data-cy="influencer_preview_username"
                      class="text"
                      :href="'https://twitter.com/' + preview.screen_name"
                      >@{{ preview.screen_name }}</a
                    >
                  </template>
                  <template
                    v-else-if="$parent.checkInfluencerType === 'instagram'"
                  >
                    <h3
                      class="name"
                      data-cy="influencer_preview_name"
                      v-html="preview.full_name"
                    ></h3>
                    <a
                      v-if="preview.username"
                      target="_blank"
                      data-cy="influencer_preview_username"
                      class="text"
                      :href="'https://instagram.com/' + preview.username"
                      >@{{ preview.username }}</a
                    >
                  </template>
                  <template
                    v-else-if="$parent.checkInfluencerType === 'youtube'"
                  >
                    <h3
                      class="name"
                      data-cy="influencer_preview_name"
                      v-html="preview.channel_title"
                    ></h3>
                    <a
                      target="_blank"
                      data-cy="influencer_preview_username"
                      class="text"
                      :href="preview.profile_link"
                      >@{{ preview.channel_title }}</a
                    >
                  </template>
                </div>

                <button
                  v-if="$parent.checkInfluencerType === 'twitter'"
                  class="btn gradient_btn"
                  data-cy="preview_follow"
                  @click.prevent="$parent.openFollowModal(preview)"
                >
                  <span>Follow/Unfollow</span>
                </button>
              </div>
              <div class="right_col d-flex align-items-center">
                <p
                  v-if="$parent.checkInfluencerType === 'twitter'"
                  class="value"
                  data-cy="preview_posts_count"
                  >{{
                    $filters.numberToCommas(preview.total_tweets_published)
                  }}
                  <span>Tweets</span></p
                >
                <p
                  v-else-if="$parent.checkInfluencerType === 'instagram'"
                  class="value"
                  data-cy="preview_posts_count"
                  >{{ $filters.numberToCommas(preview.total_posts_published) }}
                  <span>Posts</span></p
                >
                <p
                  v-else-if="$parent.checkInfluencerType === 'youtube'"
                  class="value"
                  data-cy="preview_posts_count"
                  >{{ $filters.numberToCommas(preview.total_videos) }}
                  <span>Videos</span></p
                >

                <p
                  v-if="$parent.checkInfluencerType === 'youtube'"
                  v-tooltip="preview.total_subscribers"
                  class="value"
                  data-cy="preview_follower_count"
                  >{{ $filters.numberToCommas(preview.total_subscribers)
                  }}<span>Subscribers</span></p
                >

                <p
                  v-else
                  v-tooltip="preview.follower_count"
                  class="value"
                  data-cy="preview_follower_count"
                  >{{ $filters.numberToCommas(preview.follower_count)
                  }}<span>Followers</span></p
                >

                <p
                  v-if="$parent.checkInfluencerType === 'youtube'"
                  class="value"
                  data-cy="preview_views_count"
                  >{{ $filters.numberToCommas(preview.total_views) }}
                  <span>Views</span></p
                >

                <p v-else class="value" data-cy="preview_following_count"
                  >{{ $filters.numberToCommas(preview.following) }}
                  <span>Following</span></p
                >

                <!--location-->
                <template v-if="$parent.checkInfluencerType === 'youtube'">
                  <p
                    v-if="
                      preview.country_location &&
                      getFullCountryText(preview.country_location)
                    "
                    data-cy="preview_location"
                    class="value border-right-0 pr-0 mr-0"
                    ><span>
                      <i class="mr-1 fa fa-map-marker"></i>
                      {{ getFullCountryText(preview.country_location) }}</span
                    >
                  </p>
                </template>

                <p
                  v-else-if="
                    preview.parsed_location &&
                    preview.parsed_location.length > 0
                  "
                  data-cy="preview_location"
                  class="value border-right-0 pr-0 mr-0"
                  ><span>
                    <i class="mr-1 fa fa-map-marker"></i>
                    {{ preview.parsed_location[0] }}</span
                  >
                </p>
              </div>
              <div class="right_col">
                <p
                  class="gray_light"
                  data-cy="preview_bio"
                  v-html="preview.bio"
                ></p>
              </div>
            </div>
          </div> </div
        ><!-- overview section for influencer detail -->
        <template v-if="active_tab === 'overview'">
          <InfluencerOverview
            :preview="preview"
            :type="$parent.checkInfluencerType"
            :score="score"
          ></InfluencerOverview>
        </template>
        <template
          v-if="
            active_tab === 'top_mentioned' &&
            $parent.checkInfluencerType === 'twitter'
          "
        >
          <InfluencerTopMentioned
            :top_mentioned="top_mentioned"
          ></InfluencerTopMentioned>
        </template>
        <template
          v-if="
            active_tab === 'top_mentioned' &&
            $parent.checkInfluencerType === 'instagram'
          "
        >
          <InfluencerInstagramTopMentioned
            :top_mentioned="top_mentioned"
          ></InfluencerInstagramTopMentioned>
        </template>
        <template v-if="active_tab === 'tweets'">
          <InfluencerSharedTweets :tweets="tweets"></InfluencerSharedTweets>
        </template>
        <template v-if="active_tab === 'posts'">
          <InfluencerSharedPosts
            :posts="posts"
            :preview="preview"
            :platform="$parent.checkInfluencerType"
          ></InfluencerSharedPosts>
        </template>
        <template v-if="active_tab === 'shared_articles'">
          <InfluencerSharedArticles
            :shared_articles="shared_articles"
          ></InfluencerSharedArticles>
        </template>

        <template v-if="active_tab === 'videos'">
          <InfluencerSharedVideos
            :posts="posts"
            :preview="preview"
          ></InfluencerSharedVideos>
        </template>
      </div>
      <span v-else class="text-center">No Data Found</span>
    </template>
  </b-modal>
</template>

<script>
import { useRoute } from 'vue-router'

import { mapGetters, mapActions } from 'vuex'
import { isoCountries } from '@common/constants/iso-countries'
import { influencerTypes } from '@src/modules/discovery/store/mutation-types.js'
import InfluencerOverview from './sub-components/InfluencerOverview'
import InfluencerTopMentioned from './sub-components/InfluencerTopMentioned'
import InfluencerInstagramTopMentioned from './sub-components/InfluencerInstagramTopMentioned'
import InfluencerSharedTweets from './sub-components/InfluencerSharedTweets'
import InfluencerSharedPosts from './sub-components/InfluencerSharedPosts'
import InfluencerSharedArticles from './sub-components/InfluencerSharedArticles'
import InfluencerSharedVideos from './sub-components/InfluencerSharedVideos'
import Router from '../../../../../router'
export default {
  components: {
    InfluencerOverview,
    InfluencerTopMentioned,
    InfluencerSharedArticles,
    InfluencerSharedTweets,
    InfluencerSharedPosts,
    InfluencerInstagramTopMentioned,
    InfluencerSharedVideos,
  },
  props: {},
  data() {
    return {
      active_tab: 'overview',
      preview: {},
      top_mentioned: {},
      tweets: {},
      shared_articles: {},
      posts: {},
    }
  },
  computed: {
    ...mapGetters(['getInfluencerLoaders']),
    score() {
      return this.preview.score ? this.preview.score : 0
    },
  },
  watch: {
    '$route.params.influencer_id'(value) {
      if (value) {
        this.initModal()
        this.$bvModal.show('influencer_detail_modal')
      }
    },
    'getInfluencerLoaders.preview'(value) {
      if (!value) {
        this.active_tab = 'overview'
        this.$parent.preview = {}
      }
    },
  },
  created() {
    this.initModal()
    this.$bvModal.show('influencer_detail_modal')
  },
  mounted() {
    // this.$bvModal.show('influencer_detail_modal')
  },
  methods: {
    ...mapActions([
      'fetchInfluencerPreview',
      'fetchInfluencerTopMentioned',
      'fetchInfluencerPosts',
      'fetchInfluencerSharedArticles',
    ]),
    initModal() {
      console.debug(
        'initModal',

        this.$parent.checkInfluencerType,
        Router.currentRoute.value,
        Router.currentRoute.value.params
      )
      if (this.$parent.checkInfluencerType === 'twitter') {
        if (Router.currentRoute.value.name === 'twitter_influencer_preview') {
          this.getInfluencerPreview({
            user_id: Router.currentRoute.value.params.influencer_id,
            type: 'twitter',
          })
        } else {
          this.getInfluencerPreview({
            screen_name: Router.currentRoute.value.params.influencer_id,
            type: 'twitter_screen_name',
          })
        }
      } else if (this.$parent.checkInfluencerType === 'instagram') {
        this.getInstagramInfluencerPreview(
          Router.currentRoute.value.params.influencer_id
        )
      } else if (this.$parent.checkInfluencerType === 'youtube') {
        this.getYoutubeInfluencerPreview(
          Router.currentRoute.value.params.influencer_id
        )
      }
    },
    changeTab(value) {
      this.active_tab = value
    },
    hideModal(hide = true) {
      console.debug('Method:hideModal influencer_detail_modal', hide)
      this.$bvModal.hide('influencer_detail_modal')
      this.$parent.$parent.showPreview = false
      if (hide) {
        // Router.currentRoute.valuer.go(-1)
        const query = Router.currentRoute.value.query
        if (Router.currentRoute.value.name === 'twitter_influencer_preview') {
          return this.$router.push({
            name: 'twitter_influencers',
            query,
          })
        }
        if (Router.currentRoute.value.name === 'instagram_influencer_preview') {
          return this.$router.push({
            name: 'instagram_influencers',
            query,
          })
        }
        if (Router.currentRoute.value.name === 'youtube_influencer_preview') {
          return this.$router.push({
            name: 'youtube_influencers',
            query,
          })
        }
        if (
          Router.currentRoute.value.name ===
          'twitter_discovery_influencer_preview'
        ) {
          return this.$router.push({
            name: 'discover_search_web',
            query,
          })
        }
      }
    },
    backgroundImageURL(url) {
      return `background:url('${url}')`
    },

    async getInfluencerPreview(payload) {
      this.setSocialSharingTagsSuggestionsItems([])

      this.preview = await this.fetchInfluencerPreview(payload)
      if (this.preview?.screen_name) {
        if (this.preview.top_hashtags.length > 0) {
          this.fetchHashTagsVelocity(this.preview.top_hashtags)
        }
        this.$store.commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, true)
        this.$store.commit(
          influencerTypes.SET_INFLUENCER_LOADER_SHARED_ARTICLES,
          true
        )

        if (this.preview.top_user_mentions) {
          this.top_mentioned = await this.fetchInfluencerTopMentioned({
            top_user_mentions: this.preview.top_user_mentions,
            type: 'twitter',
          })
        }
        if (this.preview.screen_name) {
          this.tweets = await this.fetchInfluencerPosts({
            screenName: this.preview.screen_name,
            type: 'twitter',
          })
          this.shared_articles = await this.fetchInfluencerSharedArticles(
            this.preview.screen_name
          )
        }
      }
    },

    async getInstagramInfluencerPreview(influencerId) {
      console.debug('getInstagramInfluencerPreview')
      this.setSocialSharingTagsSuggestionsItems([])

      this.preview = await this.fetchInfluencerPreview({
        user_id: influencerId,
        type: 'instagram',
      })

      console.debug('getInstagramInfluencerPreview', this.preview, influencerId)

      if (this.preview && this.preview.username) {
        if (this.preview.top_hashtags.length > 0) {
          this.setSharingTagsReplaceStatus(true)
          this.setSocialSharingTagsSuggestionsItems(this.preview.top_hashtags)
        }
        if (this.preview.top_user_mentions) {
          this.$store.commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, true)
          this.top_mentioned = await this.fetchInfluencerTopMentioned({
            top_user_mentions: this.getTopMentionedUsername(
              this.preview.top_user_mentions
            ),
            type: 'instagram',
          })
        }
        if (this.preview.username) {
          this.posts = await this.fetchInfluencerPosts({
            screenName: this.preview.username,
            type: 'instagram',
          })
        }
      }
    },

    async getYoutubeInfluencerPreview(influencerChannelId) {
      this.preview = await this.fetchInfluencerPreview({
        channel_id: influencerChannelId,
        type: 'youtube',
      })
      this.$store.commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, true)
      this.posts = await this.fetchInfluencerPosts({
        channel_id: influencerChannelId,
        type: 'youtube',
      })
    },

    getTopMentionedUsername(data) {
      const topMentioned = []
      data.forEach(function (element) {
        topMentioned.push(element.name)
      }, this)
      return topMentioned
    },

    getFullCountryText(code) {
      return isoCountries[code]
    },
  },
}
</script>

<style lang="less">
.fixed_links {
  position: absolute;
  left: -200px;
  vertical-align: top;
  top: 0;
  margin: 0;
  height: 100%;
  width: 200px;
  background: #ececec;
  ul {
    width: 100%;
    margin-top: 15%;
    li {
      margin-left: 0.625rem;
      &:hover {
        a {
          color: white;
        }
      }
      a {
        padding: 11px 30px;
        font-size: 15px;
        margin-left: 0.625rem;
        display: block;
        color: black;
        &:hover {
          cursor: pointer;
          background: #7a79ff;
          border-top-left-radius: 20px;
          color: white;
          border-bottom-left-radius: 20px;
          margin-left: 0.625rem;
        }
        &:active {
          margin-left: 0.625rem;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }
      }
      .active {
        background: white;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        margin-left: 0.625rem;
      }
    }
  }
}
</style>
