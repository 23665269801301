<template>
  <div class="ml-0 reply_items d-flex changeBackgroundOnMouseOver">
    <div class="content" @click="selectedSavedReply(savedReply._id)">
      <h4 style="font-size: 1rem">{{ savedReply.title }}</h4>
      <p
        style="
          max-width: 358px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          width: 100%;
        "
        >{{ savedReply.description }}</p
      >
    </div>
    <div class="d-flex">
      <div
        class="dropdown default_style_dropdown taskDropdown d-inline-block deleteItem"
      >
        <i
          v-tooltip.bottom="'Delete'"
          data-toggle="dropdown"
          class="far fa-trash-alt"
          style="color: red; margin-right: 0.625rem"
        ></i>
        <div class="dropdown-menu dropdown-menu-right">
          <ul class="inner">
            <div class="body_text">
              <p
                >Are you sure?
                <span
                  class="red"
                  style="
                    margin-left: 8px;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  @click="deleteReply(savedReply._id)"
                  >Yes</span
                >
                -<span
                  style="
                    margin-left: 8px;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  >No</span
                >
              </p>
            </div>
          </ul>
        </div>
      </div>
      <i
        v-tooltip.bottom="'Edit'"
        class="icon-edit-cs"
        @click.prevent="editExistingReply(savedReply)"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: [
    'savedReply',
    'editExistingReply',
    'selectedSavedReply',
    'deleteReply'
  ],
  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  mounted () {},
  methods: {
    ...mapActions([])
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.changeBackgroundOnMouseOver {
  &:hover {
    background: #eaeaea;
    cursor: pointer;
  }
}
.addCursor {
  cursor: pointer;
}

.filledReply .dropdown-menu {
  left: 0 !important;
}

.filledReply .reply_items {
  line-height: 24px;
}
</style>
