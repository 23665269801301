<template>
  <div id="ai-chat-header" class="relative">
    <!-- head -->
    <div
        class="w-full min-h-[60px] h-[60px] bg-white flex items-center justify-between px-[16px]"
        style="border-bottom: 1px solid #eceef5"
    >
      <div class="flex items-center">
        <img
            class="mb-1"
            src="@src/assets/img/chat_bot/cyrus-icon-blue.svg"
            alt="AI Chat Bot"
        />
        <span class="ml-3 text-base font-semibold text-[#26303E] mt-0.5"
        >AI Writing Assistant</span
        >
      </div>
      <div class="flex items-center">
        <button
            v-if="type !== 'blog'"
            v-tooltip="{
              content: state.isModalExpanded ?  'Collapse' : 'Expand',
            }"
            class="border-none bg-transparent p-1.5 rounded"
            :class="{ '!bg-[#E3F2FF]': state.isModalExpanded }"
            @click="toggleModalSize"
        >
          <img
              v-show="state.isModalExpanded"
              src="@src/assets/img/chat_bot/minimize.svg"
              alt="minimize"
          />
          <img
              v-show="!state.isModalExpanded"
              src="@src/assets/img/chat_bot/expand.svg"
              alt="expand"
          />
        </button>
        <button
            v-tooltip="'Chat Credit'"
            class="ml-2 border-none bg-transparent p-1.5 rounded"
            :class="{ '!bg-[#E3F2FF]': state.isCreditVisible }"
            @click="() => (state.isCreditVisible = !state.isCreditVisible)"
        >
          <img
              src="@src/assets/img/chat_bot/credit-icon.svg"
              alt="chat-credit"
          />
        </button>
        <button
            v-tooltip="'Chat History'"
            class="ml-2 border-none bg-transparent p-1.5 rounded"
            :class="{ '!bg-[#E3F2FF]': props.historyToggler }"
            @click="props.toggleChatDialogs('openHistory')"
        >
          <img
              src="@src/assets/img/chat_bot/chat-history.svg"
              alt="chat-history"
          />
        </button>
        <button
            class="ml-2 bg-[#2961D2] text-[#fff] font-normal text-sm rounded border-none px-[8px] py-[8px] flex items-center justify-center w-[92px]"
            @click="props.handleNewChat"
        >
          <img
              src="@src/assets/img/chat_bot/add-white.svg"
              alt="open"
              class="mr-1 w-2.5 h-2.5"
          />
          <span class="text-sm leading-[14px]">New Chat</span>
        </button>
        <div
            v-if="props.type === 'modal'"
            v-tooltip="'Close'"
            class="flex items-center justify-center hover:bg-gray-100 active:bg-gray-300 rounded ml-[12px] w-[32px] h-[32px] cursor-pointer p-[4px]"
            @click="props.toggleChatDialogs('closeChatModal')"
        >
          <img
              src="@src/assets/img/chat_bot/Vector.svg"
              alt="close_icon"
          />
        </div>

      </div>
    </div>
    <!-- chat credit -->
    <div
        v-show="state.isCreditVisible"
        id="credits-banner"
        v-tooltip="{
                content: creditsLimitsTooltip,
                allowHTML: true,
            }"
        class="w-full h-[40px] bg-[#FFF9EE] flex items-center px-4  z-[1500]"
    >
      <span class="whitespace-nowrap font-light text-sm"
      >AI Credit (Words)</span
      >
      <!-- replaced with b-progress -->
      <progress
          class="credit-bar mx-2"
          :class="{
          'credit-bar-danger': getCreditUsedLimit >= getCreditSubscribeLimit || getCreditUsedLimit > getCreditSubscribeLimit - 1000,
        }"
          :value="getCreditUsedLimit"
          :max="getCreditSubscribeLimit"
      ></progress>
      <span class="w-max whitespace-nowrap font-medium text-sm">
        {{ formatNumber(getCreditUsedLimit) }} of
        {{ formatNumber(getCreditSubscribeLimit) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import {reactive, defineProps, computed, watch} from "vue";
import {useStore} from "@state/base";

const state = reactive({
  isModalExpanded: false,
  isCreditVisible: false,
})
const store = useStore()
const emit = defineEmits(['toggleDialogSize'])
const props = defineProps({
  type:{
    type:String,
    default:'modal'
  },
  historyToggler:{
    type:Boolean,
    default:false
  },
  toggleChatDialogs:{
    type:Function,
    default:()=>{}
  },
  handleNewChat:{
    type:Function,
    default:()=>{}
  }
})

const toggleModalSize = () => {
  state.isModalExpanded = !state.isModalExpanded
  emit('toggleDialogSize', state.isModalExpanded)
}

const getCreditSubscribeLimit = computed(() => {
  return store.getters.getPlan?.subscription?.limits?.caption_generation_credit
})

const getCreditUsedLimit = computed(() => {
  return store.getters.getPlan?.used_limits?.caption_generation_credit
})
const getSuperAdminDetails = computed(() => {
  const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
  if (teamMembers)
    return teamMembers.find((member) => member.role === 'super_admin').user
  return { full_name: '' }
})
const creditsLimitsTooltip = () => {

  return `
              <div class="text-left">
      <span class="font-medium block">AI text Credits (words)</span>
      <span class="block my-2">${getCreditUsedLimit.value} of ${getCreditSubscribeLimit.value} used</span>
      <span class="block">
        This limit is subscribed by ${getSuperAdminDetails.value?.full_name} and being consumed by all team members.
        And the credits get reset on the start of the month.
      </span>
    </div>
                `;
};

watch(
    () => getCreditUsedLimit,
    (val) => {
      if(val > getCreditSubscribeLimit.value - 1000 || val >= getCreditSubscribeLimit.value)
           state.isCreditVisible = true
    }
)
</script>

<style lang="scss">
.credit-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 8px;
  width: 100%;
  &::-webkit-progress-bar {
    border-radius: 6px;
    background: rgba(107, 83, 36, 0.1);
  }
  &::-webkit-progress-value {
    background: #0068e5;
    border-radius: 6px;
  }
}
.credit-bar-danger::-webkit-progress-value {
  background: #dc3545;
  border-radius: 6px;
}
</style>
