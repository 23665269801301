<template>
  <b-modal
      id="add-twitter-account-modal"
      centered
      no-close-on-backdrop
      hide-header
      hide-footer
      body-class="!px-0"
      dialog-class="!w-[484px]"
      @hide="onModalHide"
  >
    <div class="relative">
      <!--      modal close     -->
      <div class="w-full flex justify-end mt-2 pt-[5px] px-[5px]">
        <img
            src="@src/assets/img/integration/cross_dark_gray.svg"
            class="w-6 h-6 mr-[12px] cursor-pointer"
            alt=""
            @click="$bvModal.hide('add-twitter-account-modal')"
        />
      </div>

      <!--      main modal body     -->
      <div class="px-10 mb-12 w-full flex flex-col justify-center items-center">
        <!--        modal title     -->
        <div class="w-full flex justify-center items-center gap-x-[8px]">
          <img
              src="@src/assets/img/integration/twitter-x-rounded.svg"
              alt=""
              class="w-12 h-12"
          />
          <p class ="font-medium text-lg text-[#202324] select-none"
          >Connect X (Twitter) Account</p
          >
        </div>
        <p class="text-base text-[#757A8A] mt-[24px] text-center"
        >Create your app from
          <a
              href="https://developer.twitter.com/en/portal/dashboard"
              target="_blank"
              class="font-medium text-[#2961D2] cursor-pointer">X (Twitter)'s Developer's portal</a>,
          and provide the
          <span class="font-medium">consumer keys</span> to authenticate.
          <a href="https://docs.contentstudio.io/article/966-how-to-connect-with-a-twitter-custom-app" target="_blank">
            <img
                v-tooltip="{
                  content: 'Learn more',
                  placement: 'bottom',
                }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="inline-block align-middle"
            >
          </a>
        </p>
        <hr class="text-[#ECEEF5] w-full mb-0 mr-0 ml-0 mt-[30px]"/>

        <!--        modal inputs      -->
        <div class="mt-6 w-full flex flex-col justify-center items-center">
          <form>
            <label for="app-name" class="flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none"
              >App Name<span class="ml-1">*</span>
              </span>
              <input
                  id="app-name"
                  v-model="state.appName"
                  type="text"
                  placeholder="Add name here"
                  class="color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 text-[#3a4557] rounded-[8px] bg-[#F2F3F8] cst-editor"
                  autocomplete="off"
              />
            </label>
            <label for="app-key" class="mt-[12px] flex flex-col mb-0 space-y-2">
              <span class="text-[14px] font-medium select-none"
              >API Key<span class="ml-1">*</span>
              </span>
              <span class="relative">
                <input
                    id="app-key"
                    v-model="state.apiKey"
                    :type="state.keyType"
                    placeholder="Add key here"
                    class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 rounded-[8px] bg-[#F2F3F8] cst-editor"
                    autocomplete="off"
                />
                <img
                    v-tooltip="state.keyType === 'password' ? 'Show' : 'Hide'"
                    :src="keyEyeIconSrc"
                    class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    alt=""
                    @click="toggleInputType('keyType')"
                />
              </span>
            </label>
            <label
                for="app-secret"
                class="mt-[12px] flex flex-col mb-0 space-y-2 select-none"
            >
              <span class="text-[14px] font-medium"
              >API Secret<span class="ml-1">*</span>
              </span>
              <span class="relative">
                <input
                    id="app-secret"
                    v-model="state.apiSecret"
                    :type="state.secretType"
                    placeholder="Add secret here"
                    class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 rounded-[8px] bg-[#F2F3F8] cst-editor"
                    autocomplete="off"
                />
                <img
                    v-tooltip="state.secretType === 'password' ? 'Show' : 'Hide'"
                    :src="secretEyeIconSrc"
                    class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    alt=""
                    @click="toggleInputType('secretType')"
                />
              </span>
            </label>
            <label
                for="app-callback-url"
                class="mt-[12px] flex flex-col mb-0 space-y-2"
            >
              <span class="text-[14px] font-medium select-none">Callback URL</span>
              <span class="relative">
                <input
                    id="app-callback-url"
                    ref="callbackUrl"
                    :value="`${integrationsBaseURL}twitter/connect`"
                    readonly
                    class="text-[#202324] color-border w-[404px] h-[42px] pt-4 pl-4 pb-4 pr-9 rounded-[8px] bg-[#F2F3F8] cst-editor"
                    autocomplete="off"
                />
                <img
                    v-tooltip="'Copy'"
                    src="@src/assets/img/integration/copy_url.svg"
                    class="w-5 h-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    alt=""
                    @click="copyToClipboard"
                />
              </span>
            </label>

            <!--          checkbox      -->
            <div class="mt-7 gap-x-[8px] flex items-center w-full">
              <input
                  v-model="state.isChecked"
                  type="checkbox"
                  class="rounded w-4 h-4 cursor-pointer"
              />
              <span
                  class="cursor-pointer text-[#202324] text-[14px] leading-4 select-none"
                  @click="state.isChecked = !state.isChecked"
              >
                I’ve added the call back URL.
              </span>
            </div>
          </form>
        </div>

        <!--        proceed button      -->
        <CstButton
            class="mt-10 w-[404px] h-[44px] !rounded-[8px]"
            :disabled="!getProceedStatus"
            @click="connectCustomApp"
        >
          <div class="flex items-center justify-center w-full">
            <p class="text-[16px] font-medium w-full">Proceed</p>
            <clip-loader
                v-if="state.showLoader"
                class="ml-2 mb-1"
                :color="'white'"
                :size="'16px'"
            ></clip-loader>
          </div>
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// libraries
import {computed, inject, reactive, ref} from 'vue'

// Components
import CstButton from '@ui/Button/CstButton.vue'
import {integrationsBaseURL} from '@src/config/api-utils'
import {useStore} from '@state/base'
import proxy from '@common/lib/http-common'
import {UNKNOWN_ERROR} from '@common/constants/messages'

const root = inject('root')
const {$bvModal} = root
const {getters, dispatch} = useStore()

const emit = defineEmits(['custom-app-payload'])
const props = defineProps({
  externalLink: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  keyType: 'password',
  keyToggleIcon: 'eye_disabled.svg',
  secretType: 'password',
  secretToggleIcon: 'eye_disabled.svg',
  isChecked: false,
  appName: '',
  apiKey: '',
  apiSecret: '',
  isDisabled: false,
  showLoader: false,
})

const callbackUrl = ref(null)

/**
 * @description Computed property that returns the path of the eye icon based on the current value of state.secretType.
 * If state.secretType is 'password', the path of the allowed eye icon is returned; otherwise, the path of the disabled eye icon is returned.
 * @returns {String} - The path of the eye icon
 */
const secretEyeIconSrc = computed(() =>
    state.secretType === 'password'
        ? '/img/eye_allowed.svg'
        : '/img/eye_disabled.svg'
)

/**
 * @description Computed property that returns the path of the eye icon based on the current value of state.keyType.
 * If state.keyType is 'password', the path of the allowed eye icon is returned; otherwise, the path of the disabled eye icon is returned.
 * @returns {String} - The path of the eye icon
 */
const keyEyeIconSrc = computed(() =>
    state.keyType === 'password'
        ? '/img/eye_allowed.svg'
        : '/img/eye_disabled.svg'
)

/**
 * @description Toggles input type between "password" and "text".
 * @param {string} type - Type of input field to toggle.
 */
const toggleInputType = (type = '') => {
  state[type] === 'password'
      ? (state[type] = 'text')
      : (state[type] = 'password')
}

/**
 * @description Function to copy the callback URL to clipboard
 */
const copyToClipboard = () => {
  callbackUrl.value.select()
  navigator.clipboard.writeText(callbackUrl.value.value)
}

/**
 * @description Computed property that returns a boolean value indicating whether all required form fields have been filled out
 * @returns {Boolean} - true if all required form fields have been filled out, false otherwise
 */
const getProceedStatus = computed(
    () =>
        !!(
            state.appName &&
            state.apiKey &&
            state.apiSecret &&
            state.isChecked &&
            !state.isDisabled
        )
)

const connectCustomApp = async () => {
  state.showLoader = true
  state.isDisabled = true
  const external_link_token = JSON.parse(sessionStorage.getItem('external_link_token'))?.token
  const workspaceId = JSON.parse(sessionStorage.getItem('external_link_token'))?.workspaceId
  const payload = {
    workspace_id: getters.getActiveWorkspace._id || workspaceId,
    api_key: state.apiKey,
    api_secret: state.apiSecret,
    app_name: state.appName,
    platform_type: 'twitter',
  }

  try {
    const {data} = props.externalLink ? await proxy.post(
        `${integrationsBaseURL}developerApp/addApp`,
        payload, {
          headers: {
            'EXTERNAL-LINK-TOKEN': external_link_token
          }
        }
    ) : await proxy.post(
        `${integrationsBaseURL}developerApp/addApp`,
        payload
    )
    const {status, message, url, data: successData} = data
    if (!status) {
      throw new Error(message || UNKNOWN_ERROR)
    }

    const successPayload = {
      url,
      ...successData,
    }

    emit('custom-app-payload', successPayload)
    $bvModal.hide('add-twitter-account-modal')

  } catch (error) {
    const errorMessage =
        error?.response?.data?.message || error?.message || UNKNOWN_ERROR
    await dispatch('toastNotification', {
      message: errorMessage,
      type: 'error',
    })
  } finally {
    state.showLoader = false
    state.isDisabled = false
  }
}

const onModalHide = () => {
  state.apiSecret = ''
  state.apiKey = ''
  state.appName = ''
  state.isChecked = false
  state.isDisabled = false
  state.keyType = 'password'
  state.keyToggleIcon = 'eye_disabled.svg'
  state.secretType = 'password'
  state.secretToggleIcon = 'eye_disabled.svg'
  state.showLoader = false
}
</script>
