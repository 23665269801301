<template>
  <div class="topics-insights-container">
    <template v-if="!is_insights_retrieved">
      <beat-loader class="mt-5"></beat-loader>
    </template>
    <template v-else>
      <div class="topics-insights-container__tabs">
        <a
          href="javascript:;"
          :class="{
            active:
              !$route.query.section || $route.query.section === 'overview',
          }"
          @click.prevent="
            $router.replace({ query: { ...$route.query, section: 'overview' } })
          "
        >
          Overview
        </a>
        <a
          href="javascript:;"
          :class="{ active: $route.query.section === 'top_domains' }"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, section: 'top_domains' },
            })
          "
        >
          Top Domains
        </a>
        <a
          href="javascript:;"
          :class="{ active: $route.query.section === 'top_authors' }"
          @click.prevent="
            $router.replace({
              query: { ...$route.query, section: 'top_authors' },
            })
          "
        >
          Top Authors
        </a>
      </div>
      <template
        v-if="!$route.query.section || $route.query.section === 'overview'"
      >
        <ContentInsightsSummary
          :total_articles="insights.hits.total || 0"
          :total_engagements="insights.aggregations.total_stats.value || 0"
          :avg_engagements="insights.aggregations.average_stats.value || 0"
          :average_facebook_engagement="
            insights.aggregations.average_facebook.value || 0
          "
          :average_pinterest_engagement="
            insights.aggregations.average_pins.value || 0
          "
          :average_reddit_engagement="
            insights.aggregations.average_reddit.value || 0
          "
        />
        <ContentInsightsArticlesPublishedOverTime
          :articles_published_per_day="
            insights.aggregations.articles_published_per_day
          "
        />
        <div class="topics-insights-container__row">
          <ContentInsightsAverageEngagementByNetwork
            :average_channel_engagements="
              insightsEngagementMetrics(insights.aggregations)
            "
          />
          <ContentInsightsTotalEngagementByNetwork
            :total_channel_engagements="
              insightsEngagementMetrics(insights.aggregations)
            "
          />
        </div>
        <ContentInsightsPopularContentTypes
          :popular_content_types="
            popularContentTypesList(insights.aggregations.categories_list)
          "
        />
        <ContentInsightsPopularDays
          :popular_days="insights.aggregations.popular_days"
        />
        <div class="topics-insights-container__row">
          <ContentInsightsWordCloud
            :wordcloud_list="
              wordCloudListItems(
                insights.aggregations.top_popular_hashtags.buckets,
              )
            "
          />
          <ContentInsightsWordCount
            :wordcount="
              wordCountLevelList(
                insights.aggregations.temporarily_popular_word_count.buckets,
              )
            "
          />
        </div>
        <div class="topics-insights-container__row">
          <!--          <content-insights-popular-sentiments :sentiments="sentimentsMetrics(insights.aggregations.average_sentiment_statistics)"/>-->
          <ContentInsightsPopularReadingLevels
            :reading_levels="
              popularReadingLevels(
                insights.aggregations.popular_reading_levels.buckets,
              )
            "
          />
        </div>
        <ContentInsightsTopDomains
          :top_domains="
            topPopularDomains(insights.aggregations.top_domains.buckets)
          "
        />
      </template>
      <template v-else-if="$route.query.section === 'top_domains'">
        <ContentInsightsTopDomainsTable
          :top_domains="
            topPopularDomains(insights.aggregations.top_domains.buckets)
          "
        />
      </template>
      <template v-else-if="$route.query.section === 'top_authors'">
        <ContentInsightsTopAuthorsTable
          :top_authors="insights.aggregations.top_authors.buckets"
        />
      </template>
    </template>
  </div>
</template>

<script>
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import useDateFormat from "@common/composables/useDateFormat";
import ContentInsightsSummary from '../../../content-insights/components/ContentInsightsSummary'
import discover from '../../../../store/discover'
import ContentInsightsArticlesPublishedOverTime from '../../../content-insights/components/ContentInsightsArticlesPublishedOverTime'
import ContentInsightsAverageEngagementByNetwork from '../../../content-insights/components/ContentInsightsAverageEngagementByNetwork'
import ContentInsightsTotalEngagementByNetwork from '../../../content-insights/components/ContentInsightsTotalEngagementByNetwork'
import insightsResponseMixin from '../../../../mixins/insights/insights-response-mixin'
import ContentInsightsPopularContentTypes from '../../../content-insights/components/ContentInsightsPopularContentTypes'
import ContentInsightsPopularDays from '../../../content-insights/components/ContentInsightsPopularDays'
import ContentInsightsWordCloud from '../../../content-insights/components/ContentInsightsWordCloud'
import ContentInsightsWordCount from '../../../content-insights/components/ContentInsightsWordCount'
import ContentInsightsPopularSentiments from '../../../content-insights/components/ContentInsightsPopularSentiments'
import ContentInsightsPopularReadingLevels from '../../../content-insights/components/ContentInsightsPopularReadingLevels'
import ContentInsightsTopDomains from '../../../content-insights/components/ContentInsightsTopDomains'
import ContentInsightsTopDomainsTable from '../../../content-insights/components/ContentInsightsTopDomainsTable'
import ContentInsightsTopAuthorsTable from '../../../content-insights/components/ContentInsightsTopAuthorsTable'

export default {
  components: {
    ContentInsightsSummary,
    ContentInsightsArticlesPublishedOverTime,
    ContentInsightsAverageEngagementByNetwork,
    ContentInsightsTotalEngagementByNetwork,
    ContentInsightsPopularContentTypes,
    ContentInsightsPopularDays,
    ContentInsightsWordCloud,
    ContentInsightsWordCount,
    ContentInsightsPopularSentiments,
    ContentInsightsPopularReadingLevels,
    ContentInsightsTopDomains,
    ContentInsightsTopDomainsTable,
    ContentInsightsTopAuthorsTable
  },
  mixins: [insightsResponseMixin],
  props: {},
  setup(){
    const { momentWrapper, getAccountDateTimeFormat} = useDateFormat()
    return {
      momentWrapper,
      getAccountDateTimeFormat
    }
  },
  data () {
    return {
      is_insights_retrieved: false,
      insights: {
        hits: {
          total: 0
        },
        aggregations: {
          total_stats: {
            value: 0
          },
          average_stats: {
            value: 0
          },
          average_facebook: {
            value: 0
          },
          average_pins: {
            value: 0
          },
          average_reddit: {
            value: 0
          },
          articles_published_per_day: {
            buckets: []
          },
          popular_days: {}
        }
      }
    }
  },
  watch: {
    '$route.query.from_date' (value) {
      this.actionFetchCuratedTopicInsights()
    },
    '$route.query.to_date' (value) {
      this.actionFetchCuratedTopicInsights()
    }
  },
  created () {
    this.actionFetchCuratedTopicInsights()
  },
  methods: {
    async actionFetchCuratedTopicInsights () {
      console.log('actionFetchCuratedTopicInsights')
      this.is_insights_retrieved = false

      let curatedTopic = {}
      if (this.$route.query.topic && this.$route.query.topic.length > 1) {
        await proxy
          .post(`${discoveryBaseUrl}v1/topics/find`, {
            tag: this.$route.query.topic,
            workspace_id: this.$store.getters.getActiveWorkspace._id
          })
          .then((res) => {
            if (res.data.status) {
              curatedTopic = res.data.data
            }
            return res
          })
          .catch(() => null)
          .finally()
      }
      const payload = {
        workspace_id: this.$store.getters.getActiveWorkspace._id,
        topic: curatedTopic,
        from_date:
            this.momentWrapper(this.$route.query.from_date).format('YYYY-MM-DD') ||
            this.momentWrapper().subtract(90, 'day').format('YYYY-MM-DD'),
        to_date: this.momentWrapper(this.$route.query.to_date).format('YYYY-MM-DD') || this.momentWrapper().format('YYYY-MM-DD')
      };
      if(this.$route.params.topic_id !== ''){
        payload.topic_id = this.$route.params.topic_id
      }
      await proxy
        .post(`${discoveryBaseUrl}v2/retrieve_curated_topic_insights`, payload)
        .then((res) => {
          if (res.data.status) {
            this.insights = res.data.insights
          }
        })
        .catch((err) => {})
      this.is_insights_retrieved = true
    }
  }
}
</script>
