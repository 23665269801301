<template>
  <div v-if="type === 'main'" class="articles_list grey_inner">
    <slot></slot>
  </div>

  <div v-else class="topics-content-container">
    <div class="topics-content-container__box">
      <div class="topics-content-container__box__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: ''
  }
}
</script>
