<script>
import { mapActions, mapGetters } from 'vuex'

import { approvalStages } from '@src/modules/publish/store/states/mutation-types.js'
import { EventBus } from '@common/lib/event-bus'
import CustomizeDateRangePicker from '@common/components/Customize-date-range-picker/CustomizeDateRangePicker'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  components: { CustomizeDateRangePicker },

  props: {
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      locale: {
        format: 'MMMM DD, YYYY HH:mm', // format of the dates displayed
      },

      opens: 'center',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: '2019-10-02',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: false,
      showWeekNumbers: false,
      content_category_slot_time: {},
      content_category_slot_available: false,
    }
  },
  computed: {
    ...mapGetters([
      'getContentCategoryList',
      'getPublishTimeOptions',
      'getWorkspaces',
      'getPublishLoaders',
      'getCommonSharingDetails',
      'getPinterestSharingDetails',
      'getAccountSelection',
      'getCommonBoxOptions',
      'getSocialSharingSelection',
      'getContentCategoryAvailableSlot',
      'getApprovalData',
      'getProfile',
    ]),
    getMinDate() {
      const minDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(10, 'm')
      return minDate.format('MMMM DD, YYYY HH:mm')
    },
    getStartDate() {
      if (this.getPublishTimeOptions.post_date) {
        return this.getPublishTimeOptions.post_date
      }
      const minDate = moment()
        .tz(this.getWorkspaces?.activeWorkspace?.timezone)
        .add(60, 'm');

      const formattedDate = minDate.format('MMMM DD, YYYY HH:mm');
      this.setPublishingPostDate(formattedDate)
      return formattedDate
    },
    isApproverPost() {
      return (
        this.getApprovalData.plan &&
        this.getApprovalData.plan.status === 'review' &&
        !this.getApprovalData.plan.approval
      )
    },
    isValidForDraft() {
      return (
        this.getApprovalData.plan &&
        this.getApprovalData.plan.status === 'draft' &&
        this.getApprovalData.plan.approval &&
        this.getApprovalData.plan.approval.status === 'completed_approval'
      )
    },
    getPublishButtonText() {
      let textType = ''
      if (this.isApproverPost) {
        textType = 'approve'
      }
      switch (this.getPublishTimeOptions.time_type) {
        case 'now':
          if (textType === 'approve') return 'Approve & Post Now'
          return 'Post Now'
        case 'schedule':
          if (textType === 'approve') return 'Approve & Schedule'
          return 'Schedule'
        case 'queued':
          if (textType === 'approve') return 'Approve & Add to Queue'
          return 'Add to Queue'
        case 'content_category':
          if (textType === 'approve') return 'Approve & Add to Category'
          return 'Add to Category'
      }
      if (
        this.getPublishSelection.content_category_id &&
        this.getPublishSelection.content_category_id.length > 3
      ) {
        if (textType === 'approve') return 'Approve & Add to Category'
        return 'Add to Category'
      }
      return ''
    },
    isContentCategoryType() {
      return (
        this.getPublishSelection.content_category_id &&
        this.getPublishTimeOptions.time_type === 'content_category'
      )
    },
  },
  watch: {
    'getPublishTimeOptions.time_type'(type) {
      let categoryId
      switch (type) {
        case 'schedule':
          this.showPublishingPostDatePickers()
          break
        case 'content_category':
          categoryId = this.getPublishSelection.content_category_id
          if (categoryId) {
            this.$store.dispatch('nextAvailableSlot', categoryId)
          }

          break
        default:
          break
      }
    },

    'getPublishSelection.content_category_id'(value) {
      if (value && value.length > 3) {
        this.$store.dispatch('nextAvailableSlot', value)
      }
    },
  },
  created() {
    console.debug('publish feed createsd')
    if (this.getApprovalData.plan) {
      this.showPublishingPostDatePickers()
    }
  },
  methods: {
    ...mapActions([
      'setPublishingRepeatGap',
      'setPublishingPostDate',
      'planApprovalAction',
    ]),
    hideModal() {
      // no need to change status and count when skip button press

      // const res = {
      //   data: {
      //     plan_status: this.getApprovalData.plan.status,
      //     execution_time: this.getApprovalData.plan.execution_time,
      //   },
      // }
      // EventBus.$emit('update-plan-status', {
      //   id: this.getApprovalData.plan._id,
      //   res,
      // })
      // EventBus.$emit('update-plans-count')
      this.$bvModal.hide('publishingTimeFeedView')
    },
    updateValues(values) {
      const myDate = moment(values.startDate)
      this.setPublishingPostDate(myDate.format('MMMM DD, YYYY HH:mm'))
    },

    openApprovalModal() {
      console.debug('method:openApprovalModal')
      this.$store.commit(approvalStages.RESET_APPROVAL_DATA)
      this.$bvModal.show('socialApprovalModal')
    },

    async approvePlan() {
      this.getApprovalData.plan.is_processing = true
      const payload = {
        planId: this.getApprovalData.plan._id,
        status: 'approve',
        publish_time_options: this.getPublishTimeOptions,
        approvalUserid: this.getProfile._id,
        type: this.getApprovalData.type,
      }
      const res = await this.$store.dispatch('planApprovalAction', payload)
      if (res && res.data && res.data.status) {
        EventBus.$emit('update-plan-status', {
          id: this.getApprovalData.plan._id,
          res,
        })
        EventBus.$emit('update-plans-count')
        // for old planner
        this.changePlanData(this.getApprovalData.plan._id, res)
        this.changeApprovalPlanData(res)
        this.dispatchApprovalSocket(res, this.getApprovalData.plan._id)
        EventBus.$emit('toggleStatus:' + this.getApprovalData.plan._id, false)
        this.$bvModal.hide('publishingTimeFeedView')
      } else {
        this.$store.dispatch('toastNotification', {
          message: 'Unable to perform this action.',
          type: 'error',
        })
      }
      this.getApprovalData.plan.is_processing = false
    },
  },
}
</script>

<template>
  <b-modal
    id="publishingTimeFeedView"
    modal-class="normal_modal publishingTimeFeedView"
    hide-footer
    hide-header
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Select date & time</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('publishingTimeFeedView')"
        >&times;</button
      >
    </div>

    <div class="modal_body">
      <!--      <p class="center_txt"-->
      <!--        >This post doesn't have a publishing time in future.</p-->
      <!--      >-->

      <div
        class="post_share_option post_share_option_social basic_form_inline mt-4"
      >
        <template v-if="!isGlobalContentCategory">
          <div class="input_field d-flex align-items-start">
            <div class="left_input">
              <p class="label">When would you like to publish this?</p>
            </div>
            <div class="right_input">
              <div class="_radio_list">
                <div
                  v-if="isApproverPost || isValidForDraft"
                  class="radio_input_image"
                >
                  <input
                    :id="type + '_post_now'"
                    v-model="getPublishTimeOptions.time_type"
                    :disabled="
                      !hasPermission('can_schedule_plan') ||
                      isContentCategorySelected
                    "
                    value="now"
                    type="radio"
                    :name="type + 'time_type'"
                  />
                  <label :for="type + '_post_now'" class="radio_left"
                    >Post Now</label
                  >
                </div>

                <div class="radio_input_image">
                  <input
                    :id="type + '_schedule'"
                    v-model="getPublishTimeOptions.time_type"
                    value="schedule"
                    :disabled="isContentCategorySelected"
                    type="radio"
                    :name="type + 'time_type'"
                  />
                  <label :for="type + '_schedule'" class="radio_left"
                    >Schedule</label
                  >
                </div>

                <div
                  v-if="getApprovalData.plan && getApprovalData.plan.queued"
                  class="radio_input_image"
                >
                  <input
                    :id="type + '_queued'"
                    v-model="getPublishTimeOptions.time_type"
                    value="queued"
                    :disabled="isContentCategorySelected"
                    type="radio"
                    :name="type + 'time_type'"
                  />
                  <label :for="type + '_queued'" class="radio_left"
                    >Add to Queue</label
                  >
                </div>

                <div
                  v-if="
                    getPublishSelection.content_category_id &&
                    getPublishSelection.content_category_id.length > 3
                  "
                  class="radio_input_image"
                >
                  <input
                    :id="type + '_content_category'"
                    v-model="getPublishTimeOptions.time_type"
                    value="content_category"
                    type="radio"
                    :name="type + 'time_type'"
                  />
                  <label :for="type + '_content_category'" class="radio_left"
                    >Add to Content Category</label
                  >
                </div>

                <template v-if="isContentCategoryType">
                  <div class="input_field">
                    <div
                      class="text-center"
                      :class="{
                        warning_box: getContentCategoryAvailableSlot.available,
                        error_box: !getContentCategoryAvailableSlot.available,
                      }"
                    >
                      <template
                        v-if="getContentCategoryAvailableSlot.available"
                      >
                        <p
                          ><strong>Note:</strong>
                          <template
                            v-if="getContentCategoryAvailableSlot.scheduled"
                          >
                            Your post scheduled for
                            {{
                              $filters.convertToWeekdayWithTime(
                                getContentCategoryAvailableSlot.slot_time.date,
                              )
                            }}
                            ({{
                              $filters.relative(
                                getContentCategoryAvailableSlot.slot_time.date,
                              )
                            }}) from now.
                          </template>
                          <template v-else>
                            Your post will be published at
                            {{
                              $filters.convertToWeekdayWithTime(
                                getContentCategoryAvailableSlot.slot_time.date,
                              )
                            }}
                            ({{
                              getUtcFromWorkspaceTimeZoneFromNow(
                                getContentCategoryAvailableSlot.slot_time.date,
                              )
                            }}) from now according to your slot.
                          </template>
                        </p>
                      </template>

                      <template v-else>
                        You have not created any slots for your category. You
                        need to add slots before you can add content to the
                        category.
                        <router-link :to="{ name: 'content_categories' }"
                          >Click here to create slots.</router-link
                        >
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="postnow_option_block"></div>
          <transition name="slide-bottom">
            <div
              v-if="getPublishTimeOptions.time_type === 'schedule'"
              class="input_field d-flex align-items-start"
            >
              <div class="left_input"> &nbsp; </div>

              <div class="right_input">
                <div
                  class="gray_bg_field field_group mb-0 inner_section d-flex align-items-start"
                >
                  <div class="left_input">
                    <p class="label">Select date and time</p>
                  </div>
                  <div class="right_input">
                    <!--                                        <date-range-picker class="datepicker_input"-->
                    <!--                                                           :opens="opens"-->
                    <!--                                                           :startDate="getStartDate"-->
                    <!--                                                           @update="updateValues"-->
                    <!--                                                           :locale-data="locale"-->
                    <!--                                                           :minDate="getMinDate"-->
                    <!--                                                           :singleDatePicker="singleDatePicker"-->
                    <!--                                                           :timePicker="timePicker"-->
                    <!--                                                           :timePicker24Hour="timePicker24Hour"-->
                    <!--                                                           :showWeekNumbers="showWeekNumbers"-->
                    <!--                                                           :showDropdowns="showDropdowns"-->
                    <!--                                                           :autoApply="autoApply"-->
                    <!--                                                           :ranges="show_ranges ? undefined : false">-->
                    <!--                                            <div slot="input" class="datepicker_content  " slot-scope="picker"-->
                    <!--                                                 style="min-width: 250px;">-->
                    <!--                                                <i class="icon_first  cs-calendar-fill"></i>-->
                    <!--                                                <span>{{ picker.startDate | date}}</span>-->
                    <!--                                            </div>-->
                    <!--                                        </date-range-picker>-->

                    <CustomizeDateRangePicker
                      class="datepicker_input"
                      :opens="opens"
                      :start-date="getStartDate"
                      :locale-data="locale"
                      :min-date="getMinDate"
                      :single-date-picker="singleDatePicker"
                      :time-picker="timePicker"
                      :time-picker-24-hour="timePicker24Hour"
                      :show-week-numbers="showWeekNumbers"
                      :show-dropdowns="showDropdowns"
                      :auto-apply="autoApply"
                      :ranges="show_ranges ? undefined : false"
                      :time-zone="getWorkspaces.activeWorkspace.timezone"
                      @update="updateValues"
                    >
                      <div
                        slot="input"
                        slot-scope="picker"
                        class="datepicker_content"
                        style="min-width: 250px"
                      >
                        <i class="icon-Calendar"></i>
                        <span>{{ $filters.date(picker.startDate) }}</span>
                      </div>
                    </CustomizeDateRangePicker>

                    <p style="font-size: 0.85rem; margin: 0.7rem 1rem"
                      >Timezone: {{ getWorkspaces.activeWorkspace.timezone }} ( <clock />)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>

        <!-- global content category text -->
        <div class="post_footer text-right mt-4">
          <button
            class="btn btn-studio-theme-grey-space mr-1"
            @click="hideModal"
          >
            Skip, someone else will do this!
          </button>
          <button
            class="btn btn-studio-theme-space"
            @click.prevent="approvePlan"
          >
            <span> {{ getPublishButtonText }}</span>
            <!--<clip-loader :color="'#ffffff'" :size="'16px'" v-if="getPublishLoaders.processSocialShare"></clip-loader>-->
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped lang="scss">
#publishingTimeFeedView {
  &___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
}
</style>
