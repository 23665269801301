<template>
    <div class="relative">
        <div
                class="z-100 fixed top-[50%] left-[50%]"
                style="transform: translate(-35%, -45%)"
        >
            <CompetitorUpgradeModal/>
        </div>
        <!-- overlay -->
        <div
                class="bg-[#595c5f40] pointers-events-none z-50 h-full w-full absolute"
        ></div>
        <div v-if="state.loading" class="h-screen">
            <div class="p-6 grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
                <SkeletonBox class="!h-[10rem] !w-80"/>
            </div>
        </div>
        <!-- graph with dummy data -->
        <div class="pt-5 pr-5 pl-5 pb-0">
            <div ref="competitorTiles" class="mb-10 2xl:mb-7 h-[8rem]">
                <transition-group
                        tag="div"
                        class="grid grid-cols-5 gap-7"
                        name="slide-fade"
                >
                    <template v-for="(competitor, index) in instagramMainInfo">
                        <template v-if="instagramMainInfo">
                            <CompetitorTile
                                    :id="competitor.business_account_id"
                                    :key="`competitor_tile_${index}`"
                                    :name="competitor.name ? competitor.name : competitor.slug"
                                    :slug="competitor.slug"
                                    :image="competitor.image"
                                    :color="competitor.color"
                                    :followers="competitor.followersCount"
                                    :graph="getTileGraphValue(index)"
                                    :change="competitor.followersCountDiff"
                                    :engagement-rate="competitor.engagementRateDiff"
                            />
                        </template>
                        <CompetitorTile
                                v-if="
                instagramMainInfo.length < 5 &&
                index + 1 === instagramMainInfo.length
              "
                                :key="`empty_tile${index}`"
                                :is-empty="true"
                                name=""
                                image=""
                                color=""
                                followers=""
                                @show-manage-competitors-modal="
                $emit('show-manage-competitors-modal', singleReportData)
              "
                        />
                    </template>
                </transition-group>
            </div>

            <div class="mb-7 relative">
                <PerformanceComparison
                        :title="performanceComparisonData.title"
                        :data="instagramMainInfo"
                        :legend="{
            show: true,
            data: instagramMainInfo.length
              ? instagramMainInfo.map((item) => item.name)
              : [],
          }"
                        :x-axis-label="performanceComparisonData.xAxisLabel"
                        :y-axis-label="performanceComparisonData.yAxisLabel"
                        type="instagramMainInfo"
                        @get-graph-data="fetchGraphData"
                />
            </div>

            <div class="mb-7">
                <CompetitorsTable
                        :title="performanceReviewTableData.title"
                        :data="instagramMainInfo"
                        :headers="performanceReviewTableData.headers"
                        :data-found-check="true"
                        :followers-data-check="true"
                        :followers-growth-data="followersGrowthData"
                        :type="performanceReviewTableData.type"
                        @get-table-data="fetchTableData"
                />
            </div>

            <div class="mb-7 grid grid-cols-2 gap-4">
                <FollowersComparisonBarChart
                        :title="performanceReviewGraphData.title"
                        :data="instagramMainInfo"
                        :x-axis-label="performanceReviewGraphData.xAxisLabel"
                        :y-axis-label="performanceReviewGraphData.yAxisLabel"
                        type="followersComparisonGraph"
                        @get-graph-data="fetchGraphData"
                />

                <DoubleBarChart
                        :date-range-diff="allGraphsData.dateRangeDiff"
                        :title="postingByTypeData.title"
                        :data="allGraphsData"
                        :colors="postingByTypeData.colors"
                        :x-axis-label="postingByTypeData.xAxisLabel"
                        :y-axis-label="postingByTypeData.yAxisLabel"
                        :y-axis-second-label="postingByTypeData.yAxisSecondLabel"
                        type="allTypesGraph"
                        :data-found-check="true"
                        @data-found="graphDataCheck"
                        @chart-type="(value) => changeChartType(value, 'allTypes')"
                        @get-graph-data="fetchGraphData"
                />
            </div>

            <div
                    ref="specificPostingContent"
                    class="mb-7 grid grid-cols-2 w-full gap-4"
            >
                <DoubleBarChart
                        :title="postingBySpecificType.title"
                        :date-range-diff="postingBySpecificType.dateRangeDiff"
                        :data="postingBySpecificType.data"
                        :x-axis-label="postingBySpecificType.xAxisLabel"
                        :y-axis-label="postingBySpecificType.yAxisLabel"
                        :y-axis-second-label="postingBySpecificType.yAxisSecondLabel"
                        :data-found-check="true"
                        :selected-type="getFormattedMediaType(graphMediaType)"
                        :all-available-types="['VIDEO REEL', 'CAROUSEL ALBUM', 'IMAGE']"
                        type="postingActivityBySpecificType"
                        @chart-type="changeChartType"
                        @get-graph-data="fetchGraphData"
                />

                <CompetitorsTable
                        :title="activityTypeTableData.title"
                        :data="postingTypesTableData"
                        :headers="activityTypeTableData.headers"
                        :data-found-check="true"
                        :type="activityTypeTableData.type"
                        :selected-type="getFormattedMediaType(graphMediaType)"
                        :all-available-types="['VIDEO REEL', 'CAROUSEL ALBUM', 'IMAGE']"
                        @chart-type="changeChartType"
                        @get-table-data="fetchTableData"
                />
            </div>

            <div class="mb-7">
                <CompetitorsTable
                        :title="getHashtagsTableData.title"
                        :show-info-cell="false"
                        :data="mostEngagingHashtags"
                        :headers="getHashtagsTableData.headers"
                        :data-found-check="true"
                        :type="getHashtagsTableData.type"
                        :single-hashtag-data="singleHashtagData"
                        :single-hashtag-header="getSingleHashtagData.headers"
                        :index-to-sort="2"
                        @get-table-data="fetchTableData"
                        @single-hashtag="fetchSingleHashtagData"
                />
            </div>

            <div class="mb-7">
                <CompetitorsTable
                        :title="getBioAnalysisTableData.title"
                        :data="bioAnalysisTableData"
                        :headers="getBioAnalysisTableData.headers"
                        :data-found-check="true"
                        :type="getBioAnalysisTableData.type"
                        @get-table-data="fetchTableData"
                />
            </div>

            <div>
                <TopAndLeastPerformingPosts :data="topLeastPostsData"/>
            </div>
        </div>
    </div>
</template>

<script>
// libraries
import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    ref,
    onUnmounted,
} from 'vue'

// components
import CompetitorTile from '@src/modules/analytics_v3/components/CompetitorTile.vue'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison.vue'
import CompetitorsTable from '@src/modules/analytics_v3/components/CompetitorsTable.vue'
import FollowersGrowthComparison from '@src/modules/analytics_v3/components/FollowersGrowthComparison.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import TopAndLeastPerformingPosts from '@src/modules/analytics_v3/components/TopAndLeastPerformingPosts.vue'
import CompetitorUpgradeModal from '@src/modules/analytics_v3/views/common/CompetitorUpgradeModal.vue'
import FollowersComparisonBarChart from "@src/modules/analytics_v3/components/FollowersComparisonBarChart.vue";
import DoubleBarChart from "@src/modules/analytics_v3/components/DoubleBarChart.vue";

export default defineComponent({
    name: 'CompetitorDummy',
    components: {
        DoubleBarChart,
        FollowersComparisonBarChart,
        TopAndLeastPerformingPosts,
        SkeletonBox,
        FollowersGrowthComparison,
        CompetitorsTable,
        CompetitorTile,
        PerformanceComparison,
        CompetitorUpgradeModal,
    },
    props: {
        dummyData: {
            type: Array,
            default: () => [],
        },
        singleReportData: {
            type: Object,
            default: () => {
            },
        },
        singleErrorData: {
            type: Object,
            default: () => {
            },
        },
        allGraphsData: {
            type: Array,
            default: () => [],
        },
        specificTypeChartData: {
            type: Array,
            default: () => [],
        },
        chartMediaType: {
            type: String,
            default: '',
        },
        followersGrowthChartData: {
            type: Array,
            default: () => [],
        },
        postingTypesTableData: {
            type: Array,
            default: () => [],
        },
        instagramMainInfo: {
            type: Array,
            default: () => [],
        },
        topLeastPostsData: {
            type: Array,
            default: () => [],
        },
        mostEngagingHashtags: {
            type: Array,
            default: () => [],
        },
        singleHashtagData: {
            type: Object,
            default: () => {
            },
        },
        bioAnalysisTableData: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, {emit}) {
        /**
         * @description returns formatted type name
         * @param value
         * @returns {*|string}
         */
        const getFormattedMediaType = (value) => {
            switch (value) {
                case 'VIDEO REEL':
                    return 'Reel'
                case 'CAROUSEL ALBUM':
                    return 'Carousel'
                case 'IMAGE':
                    return 'Image'
                case 'VIDEO':
                    return 'Video'
                default:
                    return value
            }
        }

        const state = reactive({
            loading: true,
            graphDataFound: false,
            reportID: '640823eebceb24ccf9024854',
            chartMediaType: computed(() =>
                getFormattedMediaType(props.chartMediaType)
            ),
            labelBgColor: '#ffff',
        })
        const specificPostingContent = ref(null)
        const competitorTiles = ref(null)

        const colors = [
            '#339ca8',
            '#cda819',
            '#37A2DA',
            '#8081B9',
            '#FFE27D',
            '#FFAE8B',
            '#71F6F9',
        ]

        onMounted(() => {
            console.log("DUMMYGRAPH::dummyData -> ", props.dummyData)
            // emits an event to main analytic to fetch the specific competitor report.
            //  emit('get-report-data', state.reportID)
            setTimeout(() => {
                state.loading = false
            }, 600)
        })

        onUnmounted(() => {
            state.loading = false
        })

        // data for the performance comparison graph
        const performanceComparisonData = {
            title: "Competitors' Performance Comparison",
            xAxisLabel: 'Engagement Rate',
            yAxisLabel: 'Followers',
            yAxisSecondLabel: 'Followers %',
        }

        // data for performance review table
        const performanceReviewTableData = {
            title: 'Competitors’ Comparative Table',
            type: 'performanceReviewTable',
            headers: [
                'followersCount',
                'followersCountDiff',
                'averagePostsPerWeek',
                'averagePostsPerWeekDiff',
                'engagementRate',
                'engagementRateDiff',
            ],
        }

        // data for performance review graph
        const performanceReviewGraphData = {
            title: 'Competitors by Total Followers vs Net Change in Followers',
            xAxisLabel: 'Engagement Rate',
            yAxisLabel: 'Followers',
            yAxisSecondLabel: 'Followers % chg.',
        }

        // data for general posting activity by type graph
        const postingByTypeData = {
            xAxisLabel: 'Type',
            title: 'Posting Activity by Post Type',
            yAxisLabel: 'Engagement',
            yAxisSecondLabel: 'Posts/ Week',
            xAxisSecondLabel: 'Engagement',
            colors,
        }

        // data for specific activity graph
        const postingBySpecificType = computed(() => {
            const instagramMainInfo = props.instagramMainInfo
            const specificTypeChartData = props.specificTypeChartData

            const data = specificTypeChartData?.length
                ? specificTypeChartData.map((item) => {
                    const competitorData = instagramMainInfo.find(
                        (competitor) => competitor.name === item.name
                    )
                    return {...item, color: competitorData?.color}
                })
                : []

            return {
                xAxisLabel: 'Posts/ Week',
                yAxisLabel: 'Accounts',
                yAxisSecondLabel: '',
                xAxisSecondLabel: 'Engagement Rate',
                title: 'Activity by Competitors',
                data,
            }
        })

        // data for specific activity type table
        const activityTypeTableData = {
            type: 'postingActivityTableByType',
            headers: ['count', 'totalEngagement', 'engagementRate'],
            title: 'Activity by Competitors',
        }

        // data for followers growth comparison graph
        const followersGrowthData = computed(() => ({
            data: props.followersGrowthChartData,
            xAxisLabel: 'Timeline',
            yAxisLabel: 'Followers',
            title: 'Followers Growth Comparison',
        }))

        // data for most engaged hashtags (table)
        const getHashtagsTableData = {
            headers: [
                'tag',
                'companies_using',
                'count',
                'engagement_per_post',
                'engagement_per_follower',
            ],
            type: 'mostEngagedHashtagsTable',
            title: 'Most Engaged Hashtags',
        }

        // data for single hashtag data w.r.t competitors
        const getSingleHashtagData = {
            headers: [
                'name',
                'count',
                'engagement_per_post',
                'engagement_per_follower',
            ],
            type: 'singleHashtagTableData',
        }

        // data for bio analysis (table)
        const getBioAnalysisTableData = {
            headers: ['biography', 'biography_length'],
            type: 'bioAnalysisTableData',
            colors,
            title: 'Bio Analysis',
        }

        /**
         * @description method called upon clicking a label/bar/scatter point of a graph. Changes the chart and table data of specific type chart and table.
         * @param value
         * @param changeOriginator
         */
        const changeChartType = (value, changeOriginator = '') => {
            const mediaProductType = value === 'VIDEO REEL' ? 'REELS' : 'FEED'
            emit('chart-type', value, mediaProductType)

            if (changeOriginator === 'allTypes')
                specificPostingContent.value.scrollIntoView({behavior: 'smooth'})
        }

        /**
         * @description emits type of all the graphs to fetch their specific data
         * @param type
         */
        const fetchGraphData = (type) => {
            emit('get-graph-data', type, state.reportID)
        }

        /**
         * @description emits type of all the graphs to fetch their specific data
         * @param type
         */
        const fetchTableData = (type) => {
            emit('get-table-data', type, state.reportID)
        }

        /**
         * @description emits the hashtag whose date is to be fetched
         * @param hashtag
         */
        const fetchSingleHashtagData = (hashtag) => {
            emit('single-hashtag', hashtag)
        }

        /**
         *
         * @description called when the data is changed in posting activity by type graph, used in posting by specific type table and graph for conditional rendering.
         * @param value
         */
        const graphDataCheck = (value) => {
            state.graphDataFound = value
        }

        /**
         * @description depending on the ER, sets the status of competitor tile graph.
         * @param index
         * @returns {string}
         */
        const getTileGraphValue = (index) => {
            const engagementRate =
                props.instagramMainInfo[index].engagementRate
            let status
            if (engagementRate > 0) {
                status = 'up'
            } else if (engagementRate < 0) {
                status = 'down'
            } else {
                status = 'default'
            }
            return status
        }

        return {
            state,
            performanceComparisonData,
            performanceReviewTableData,
            postingByTypeData,
            activityTypeTableData,
            postingBySpecificType,
            followersGrowthData,
            performanceReviewGraphData,
            specificPostingContent,
            getHashtagsTableData,
            getBioAnalysisTableData,
            getSingleHashtagData,
            competitorTiles,

            changeChartType,
            fetchGraphData,
            fetchTableData,
            graphDataCheck,
            getTileGraphValue,
            getFormattedMediaType,
            fetchSingleHashtagData,
        }
    },
})
</script>

<style scoped></style>
