<template>
  <ConfirmBox
    id="removeAllEvergreenPost"
    :call-back-action="removeAllEvergreenPost"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Posts</h2>
    </template>

    <div slot="footer" class="text-center">
      <p>You won't be able to undo.</p>
    </div>
  </ConfirmBox>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
import { evergreen } from '../../../store/automation-mutation-type'

export default {
  components: {
    ConfirmBox
  },

  methods: {
    ...mapActions(['setConfirmActionStage', 'setCommonSharingDetails']),
    /*
     ** remove all evergreen posts callback method
     */
    removeAllEvergreenPost (confirmation) {
      console.debug('Method:removeAllEvergreenPost', confirmation)
      if (confirmation.status) {
        // close confirmation modal
        this.setConfirmActionStage('success')
        this.closeConfirmAction(confirmation.type)

        // reset state for evergreen posts and social box
        this.$store.commit(evergreen.SET_EVERGREEN_POSTS, [])
        this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')
        this.setCommonSharingDetails(null)
        document.getElementById('common_sharing_message').value = ''
      } else {
        // eslint-disable-next-line no-undef
        $('#' + confirmation.type).modal('hide')
      }
    }
  }
}
</script>
