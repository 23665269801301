<template>
  <div>
    <div class="py-4 px-2 flex flex-col space-y-4">
      <h2 class="font-semibold"> {{ computeModalTitle }} </h2>
      <div>
        <p>{{ computeModalDescription }}</p>
      </div>
    </div>
    <div class="py-4">
      <div v-if="!isBulkReject">
        <AtTa
          v-model="comment.rejectPostComment"
          :hover-select="true"
          :members="getActiveWorkspaceMembersName"
        >
          <textarea
            id="reject-comment-box-top"
            ref="ed"
            v-model="comment.rejectPostComment"
            class="rounded-md w-full p-3 border border-gray-300 text-sm"
            maxlength="2200"
            placeholder="Add your comment here"
            rows="2"
          ></textarea>
        </AtTa>
        <div class="flex flex-row items-center space-x-2">
          <div v-if="comment.media.length" class="flex flex-wrap space-x-1.5">
            <div
              v-for="(media, key) in comment.media"
              :key="key"
              v-tooltip="media.name"
              class="flex flex-col group relative"
            >
              <img
                :key="key + 'img'"
                :src="media.link"
                alt=""
                class="w-20 h-20 rounded cursor-pointer"
              />
              <i
                class="cs-cross text-xxs absolute hidden group-hover:block top-0.5 right-0.5 text-center w-4 h-4 bg-red-800 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer rounded-full"
                @click="
                  () => {
                    comment.media.splice(key, 1)
                  }
                "
              ></i>
              <i
                class="absolute hidden group-hover:block w-full bottom-0 cs-eye text-center p-1.5 bg-black-900 text-white bg-opacity-75 hover:bg-opacity-90 cursor-pointer"
                @click="previewMediaImage(media.link)"
              ></i>
            </div>
          </div>
          <div v-if="loader.file">
            <div
              class="w-20 h-20 rounded border flex justify-center items-center"
            >
              <clip-loader
                :color="'#000000'"
                :size="'13px'"
                class="spinner"
                variant="info"
              ></clip-loader>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <!-- Emoji attachments and hashtags-->
          <div class="composer-comment-toolbar flex space-x-1.5 justify-end items-center mt-1.5">
            <div class="relative">
              <div @click="emojiPickerStates = !emojiPickerStates">
                <div
                  v-b-tooltip.hover.top="'Add an emoji'"
                  class="w-8 h-8 flex items-center justify-center bg-gray-400 rounded-md cursor-pointer"
                >
                  <i class="fal fa-smile-beam text-blue-700 text-base"> </i>
                </div>
              </div>
              <CstEmojiPicker
                v-if="emojiPickerStates"
                position="bottom-end"
                :is-open="emojiPickerStates"
                @on-select="onEmojiSelect"
                @handle-click="emojiPickerStates = false"
              >
              </CstEmojiPicker>
            </div>

            <label
              v-b-tooltip.hover.top="'Attach Media'"
              class="cursor-pointer w-8 h-8 mb-0 flex items-center justify-center bg-gray-400 rounded-md"
              for="reject-note-file-input"
            >
              <img
                class="w-[1rem] h-[1rem]"
                :src="require('@assets/img/icons/planner/add_image.svg')"
                alt="add_media"
              />
            </label>
            <input
              id="reject-note-file-input"
              accept="image/*"
              class="d-none"
              type="file"
              @change.prevent="uploadMedia"
            />
            <i
              v-b-tooltip.hover.top="'Tag People'"
              class="far fa-at cursor-pointer flex w-8 h-8 items-center justify-center bg-gray-400 rounded-md text-blue-700 font-1rem"
              @click="triggerAt"
            ></i>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center pt-2 space-x-2">
        <button
            class="border-0 focus:outline-none rounded-md hover:shadow-lg font-semibold text-sm pb-2.5 pt-3 px-5 text-white bg-red-500"
            @click="$emit('onClickCancelReject')"
        >
          Cancel
        </button>
        <CstButton
            text="Yes, Reject"
            :loading="btnLoading"
            :disabled="btnLoading"
            @click="
                $emit(
                  'callProcessPlanApproval',
                  planForReject,
                  'reject',
                  true,
                  comment,
                )
              "
        ></CstButton>
      </div>
      <VueEasyLightbox
        :imgs="imgs"
        :visible="visible"
        @hide="handleHide"
      ></VueEasyLightbox>
    </div>
  </div>
</template>

<script>
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import VueEasyLightbox from 'vue-easy-lightbox'
import { mapGetters } from 'vuex'
import CstButton from '@ui/Button/CstButton'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'

export default {
  name: 'RejectionConfirmation',
  components: {
    CstButton,
    AtTa,
    CstEmojiPicker,
    VueEasyLightbox,
  },
  props: {
    btnLoading: {
      type: Boolean,
      default: false,
    },
    planForReject: {
      type: Object,
      default: null,
    },
    isBulkReject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emojiPickerStates: false,
      loader: {
        file: false,
        comments: false,
      },
      comment: {
        rejectPostComment: '',
        mentioned_user: [],
        media: [],
      },
      imgs: '',
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspaceMembersName']),
    computeModalTitle() {
      return this.isBulkReject ? 'Bulk Reject Posts' : 'Reject Post'
    },
    computeModalDescription() {
      return this.isBulkReject
        ? 'Are you sure you want to reject these posts?'
        : 'Are you sure you want to reject this post?'
    },
  },
  methods: {
    onEmojiSelect(emoji) {
      this.comment.rejectPostComment += emoji
    },
    async uploadMedia() {
      this.loader.file = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.loader.file = false
      console.log('upload-image', res)
      if (res) {
        if (res.data.status) {
          if (this.comment.media !== undefined) {
            this.comment.media.push(res.data.media)
          } else {
            this.$set(this.comment, 'media', [res.data.media])
          }
        } else {
          await this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    triggerAt() {
      const { ed } = this.$refs
      ed.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      ed.dispatchEvent(e)
    },
    previewMediaImage(image) {
      console.log('openImageLightBox', image)
      if (image) {
        this.imgs = image
        this.visible = true
      }
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

<style scoped></style>
