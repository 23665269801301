<template>
  <b-modal
    id="commentImagePreviewModal"
    modal-class="video_preview commentImagePreviewModal normal_modal"
    hide-footer
    hide-header
  >
    <div class="basic_form">
      <div class="modal_head d-flex align-items-center">
        <h2>Image Preview</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          @click="$bvModal.hide('commentImagePreviewModal')"
          >&times;</button
        >
      </div>
      <div class="modal_body">
        <div class="video_box_iframe">
          <div class="modal_inner clear text-center">
            <img
              v-if="getCommentPreviewLink"
              class="lazy modal_image"
              :src="getCommentPreviewLink"
              alt=""
            />
            <img
              v-else
              class="lazy modal_image"
              src="https://storage.googleapis.com/lumotive-web-storage/comments/5c57ceb4d6c2b4000721e383/cQwFE1SOuMxAFAuSMWkN.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},

  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getCommentPreviewLink'])
  },

  created () {},
  mounted () {},

  methods: {
    ...mapActions([])
  }
}
</script>
