<script>
import { mapGetters, mapActions } from 'vuex'
import { AUTOMATION_YOUTUBE_API_VALIDATION } from '@common/constants/messages'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import VideoPreview from '../../../../discovery/components/common/preview/VideoPreview'

import AccountSelection from '../../../../publish/components/posting/social/AccountSelection'
import ScheduleOptions from '../../ScheduleOptions'
import ReplugSelection from '../../Selection/ReplugSelection'
import HashtagSelection from '../../Selection/HashtagSelection'
import VideosFiltersSelection from './FiltersSelection'

export default {
  components: {
    HashtagSelection,
    ReplugSelection,
    AccountSelection,
    VideosFiltersSelection,
    ScheduleOptions,
    VideoPreview
  },
  mixins: [AutoMixin],

  data () {
    return {
      youtubeKey: ''
    }
  },

  created () {
    // if (!this.getVideoAutomationDetails.id) {
    //   this.$router.push({
    //     name: 'automation',
    //     params: { workspace: this.getWorkspaces.activeWorkspace.slug }
    //   })
    //   return false
    // }
    this.initializeSaveVideoSocialAutomationSection()
    this.setPreviewSuggestionPosts([])
  },
  mounted () {
    document.addEventListener('keyup', this.keyPressListener)
    this.fetchYoutubeApiKey()
  },
  unmounted () {
    document.removeEventListener('keyup', this.keyPressListener)
  },

  computed: {
    ...mapGetters([
      'getVideoAutomationTabStatus',
      'getVideoAutomationDetails',
      'getAutomationLoaders',
      'getWorkspaces',
      'getFetchNextPostingLoader'
    ]),
    showNextScheduledPostsCount () {
      return (
        !this.getFetchNextPostingLoader &&
        this.getVideoAutomationDetails.next_posting > -1
      )
    },
    nextScheduledPostsMessage () {
      return this.getVideoAutomationDetails.id &&
        !this.getVideoAutomationDetails.remove_posts
        ? 'Note: ' +
            this.getVideoAutomationDetails.next_posting +
            ' new posts will be scheduled for your accounts on the next refill.'
        : 'Note: ' +
            this.getVideoAutomationDetails.next_posting +
            ' new posts will be scheduled for your accounts.'
    }
  },
  methods: {
    ...mapActions(['fetchWorkspaceYoutubeKey', 'setPreviewSuggestionPosts']),
    async fetchYoutubeApiKey () {
      const response = await this.fetchWorkspaceYoutubeKey({
        workspace_id: this.getActiveWorkspace._id
      })
      if (response.data && response.data.key) {
        this.youtubeKey = response.data.key
      } else this.youtubeKey = ''
    },
    keyPressListener (event) {
      console.debug('Method::keyPressListener', event.keyCode)
      if (this.$route.name === 'saveVideoSocialAutomation') {
        let tab = 1
        if (event.keyCode === 27) {
          if (this.getVideoAutomationTabStatus.first === true) {
            this.$router.push({
              name: 'automation',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug }
            })
            return false
          }

          if (this.getVideoAutomationTabStatus.second === true) {
            tab = 1
          }
          if (this.getVideoAutomationTabStatus.third === true) {
            tab = 2
          }
          if (this.getVideoAutomationTabStatus.fourth === true) {
            tab = 3
          }
          this.changeActiveVideoAutomationTab(tab)
        }
        if (
          event.keyCode === 13 &&
          !event.target.classList.contains('prevent-listener')
        ) {
          if (this.getVideoAutomationTabStatus.fourth === true) {
            this.validateYoutubeTokenAutomation()
            return false
          }
          if (this.getVideoAutomationTabStatus.first === true) {
            tab = 1
          }
          if (this.getVideoAutomationTabStatus.second === true) {
            tab = 2
          }
          if (this.getVideoAutomationTabStatus.third === true) {
            tab = 3
          }
          if (tab === 2) this.validateYoutubeToken(tab)
          else this.processVideoAutomationValidation(tab)
        }
      }
    },
    validateYoutubeTokenAutomation () {
      if (
        this.getVideoAutomationDetails.video.source === 'Youtube' &&
        this.youtubeKey === ''
      ) {
        this.alertMessage(AUTOMATION_YOUTUBE_API_VALIDATION, 'error')
        return false
      }
      this.initializeSavingVideoAutomationProcess()
    },
    validateYoutubeToken (tab) {
      if (
        this.getVideoAutomationDetails.video.source === 'Youtube' &&
        this.youtubeKey === ''
      ) {
        this.alertMessage(AUTOMATION_YOUTUBE_API_VALIDATION, 'error')
        return false
      }
      this.processVideoAutomationValidation(tab)
    },

    scheduleOptionsChanges () {
      console.debug('Method::scheduleOptionsChanges')
      this.$store.dispatch('fetchAutomationNextPosting', this.$route.name)
    }
  },

  watch: {
    'getVideoAutomationTabStatus.second' (value) {
      if (value) {
        this.previewVideoSuggestion(false, null, this.youtubeKey)
      }
    }
  }
}
</script>

<template>
  <div>
    <VideoPreview class="automation_video_modal"></VideoPreview>
    <div class="automation_main_component create_automation_main_component">
      <div class="component_inner">
        <button
          class="close_icon float-right"
          @click="
            exitConfirmation('videoSocialAutomationListing','Videos to Social Media', getWorkspaces.activeWorkspace.slug)
          "
        >
          &times;
        </button>

        <div class="top_content">
          <h2>New Campaign</h2>
          <img
            src="../../../../../assets/img/automation/video_social.svg"
            alt=""
          />
          <h3>Videos to Social Media</h3>
          <p
            >Share videos on your social media profiles, pages and groups with
            smart rules and filters.</p
          >
        </div>

        <!--active - past - d-none -->
        <div class="steps_count">
          <div class="count_inner">
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getVideoAutomationTabStatus.first === true,
                past: getVideoAutomationTabStatus.first === false,
              }"
              @click="changeActiveVideoAutomationTab(1)"
            >
              <span>1</span>
            </a>
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getVideoAutomationTabStatus.second === true,
                past:
                  getVideoAutomationTabStatus.first === false &&
                  getVideoAutomationTabStatus.second === false,
              }"
              @click="processVideoAutomationValidation(1)"
            >
              <span>2</span>
            </a>
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getVideoAutomationTabStatus.third === true,
                past:
                  getVideoAutomationTabStatus.first === false &&
                  getVideoAutomationTabStatus.second === false &&
                  getVideoAutomationTabStatus.third === false,
              }"
              @click="validateYoutubeToken(2)"
            >
              <span>3</span>
            </a>

            <a
              class="d-flex align-items-center justify-content-center"
              :class="{ active: getVideoAutomationTabStatus.fourth === true }"
              @click="validateYoutubeToken(3)"
            >
              <span>4</span>
            </a>
          </div>
        </div>

        <div
          class="page_1 connect_social_accounts"
          :class="{ 'd-none': getVideoAutomationTabStatus.first === false }"
        >
          <div class="automation_page width_medium">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 1 - Campaign Name and Channels</h3>
              </div>
              <div class="input_field">
                <label>Campaign Name</label>
                <input
                  v-model="getVideoAutomationDetails.name"
                  type="text"
                  placeholder="Campaign name e.g marketing posts"
                  maxlength="70"
                  data-cy="campaign-name"
                />
              </div>

              <AccountSelection :module="'automation'" :show-label="true"></AccountSelection>

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  disabled="disabled"
                >
                  <i class="icon_left cs-angle-left"></i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  data-cy="step1-button"
                  @click="processVideoAutomationValidation(1)"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="page_2 content_rules_filter"
          :class="{ 'd-none': getVideoAutomationTabStatus.second === false }"
        >
          <VideosFiltersSelection></VideosFiltersSelection>
        </div>

        <div
          class="page_3 schedule_campaign"
          :class="{ 'd-none': getVideoAutomationTabStatus.third === false }"
        >
          <div class="automation_page width_medium">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 3 - Optimize your posts</h3>
              </div>

              <ReplugSelection></ReplugSelection>
              <HashtagSelection></HashtagSelection>

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click="changeActiveVideoAutomationTab(2)"
                >
                  <i class="icon_left cs-angle-left"></i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  @click="processVideoAutomationValidation(3)"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"> </i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="page_3 schedule_campaign"
          :class="{ 'd-none': getVideoAutomationTabStatus.fourth === false }"
        >
          <div class="automation_page width_medium">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 3 - Schedule and Finalize</h3>
              </div>

              <ScheduleOptions type="VideoSocialAutomation"></ScheduleOptions>

              <div class="input_field">
                <div class="recurring_block extra_options">
                  <div class="d-flex align-items-center">
                    <p class="text">Fetch posts for the next</p>
                    <input
                      v-model="getVideoAutomationDetails.prefetch_days_limit"
                      style="min-width: 200px"
                      type="number"
                      placeholder="Number of prefetched post"
                      min="2"
                      max="100"
                      @change="scheduleOptionsChanges"
                    />
                    <p class="last_text">days in advance</p>
                  </div>
                </div>
              </div>

              <div class="input_field">
                <div class="checkbox_input_image">
                  <input
                    id="reviewContent"
                    v-model="getVideoAutomationDetails.review_content"
                    type="checkbox"
                  />
                  <label for="reviewContent" class="checkbox_left"
                    >I’d like to review content before it is published</label
                  >
                </div>
              </div>

              <div
                v-if="showNextScheduledPostsCount"
                class="input_field d-inline-block"
              >
                <div class="warning_box">
                  <p>{{ nextScheduledPostsMessage }}</p>
                </div>
              </div>

              <div v-if="getVideoAutomationDetails.id" class="input_field">
                <div class="checkbox_input_image">
                  <input
                    id="removePosts"
                    v-model="getVideoAutomationDetails.remove_posts"
                    type="checkbox"
                    @change="scheduleOptionsChanges"
                  />
                  <label for="removePosts" class="checkbox_left"
                    >Remove existing scheduled posts</label
                  >
                </div>
              </div>

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click="changeActiveVideoAutomationTab(3)"
                >
                  <i class="icon_left cs-angle-left"></i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  :class="{
                    btn_disable: getAutomationLoaders.saveVideoAutomation,
                  }"
                  @click.prevent="validateYoutubeTokenAutomation"
                >
                  <span>Done</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                  <img
                    v-if="getAutomationLoaders.saveVideoAutomation"
                    style="width: 20px"
                    src="../../../../../assets/img/common/gif_loader_white.gif"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
