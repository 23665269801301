<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-2">
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
      >
        <img
          src="@assets/img/integration/pinterest-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="detail.image && detail.image.length > 0" class="w-100 h-100">
      <div class="flex flex-wrap relative rounded-t-3xl overflow-hidden">
        <div
          class="bg-contain bg-no-repeat bg-center w-full max-h-[500px] min-h-[300px]"
          :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
        >
          <img
          v-tooltip="{
            content: 'Image Preview',
            placement: 'auto',
           }"
            class="max-w-full max-h-full w-full opacity-0 cursor-pointer"
            :src="detail.image[0]"
            alt=""
            @click="openLightbox(detail.image[0])"
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
      </div>
    </div>
    <div v-else-if="detail.video.link">
      <video
        class="w-full rounded-lg preview__tiktok-video"
        :poster="
          detail.video.thumbnail
            ? detail.video.thumbnail
            : '@src/assets/img/no_data_images/no_media_found.svg'
        "
        controls
      >
        <source
          :src="detail.video.converted_video || detail.video.link"
          type="video/mp4"
        />
        <source
          :src="detail.video.converted_video || detail.video.link"
          type="video/ogg"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    <img
      v-else
      class="w-100 min-h-48 max-h-[33rem]"
      src="@src/assets/img/no_data_images/no_media_found.svg"
      alt=""
    />
    <div class="flex items-center px-3 py-2">
      <img
        class="rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="account.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <div class="px-2 font-bold">
        {{ account.name }}
        <span
          v-if="followersCount"
          class="block font-normal text-xs text-gray-800"
          >{{ followersCount }} followers</span
        >
      </div>
      <span class="ml-auto block font-bold bg-gray-200 py-2 px-3 rounded-full"
        >Follow</span
      >
    </div>
    <div
      v-if="detail.pinterest_title || detail.message"
      class="flex items-center px-4 py-2"
    >
      <div class="text-center text-black w-100">
        <span
          v-if="detail.pinterest_title"
          class="block font-bold text-lg py-1"
          >{{ detail.pinterest_title }}</span
        >
        <div class="block font-normal text-sm whitespace-pre-wrap">
          <span
              v-html="getDescription"
          ></span>
          <span
              v-if="getDescription.length > charLimit && loadMore"
              class="text-blue-300 cursor-pointer"
              @click="loadMore = !loadMore"
          >
            Load More</span
          >
          <span
              v-if="getDescription.length > charLimit && !loadMore"
              class="text-blue-300 cursor-pointer"
              @click="loadMore = !loadMore"
          >
            Show Less</span
          >
        </div>
      </div>
    </div>

  </div>
  <VueEasyLightbox
  :visible="visible"
  :imgs="detail.image"
  @hide="visible = false"
></VueEasyLightbox>
</template>

<script>
import { parseDescriptionHtml } from '@common/lib/helper'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  name: 'PinterestPreview',
  components: {
    VueEasyLightbox,
  },
  props: {
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    followersCount: {
      type: Number,
      default: 0,
      required: false,
    },
    sourceUrl: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
  },
  data() {
    return {
      charLimit: 500,
      loadMore: true,
      visible: false,
      imgs: [],
    }
  },
  computed: {
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg && msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    getThumbnail() {
      return this.detail?.video?.thumbnail
    },
  },
  watch: {
    detail: {
      handler(oldVal, newVal) {
        if (
          newVal.video?.link !== oldVal.video?.link &&
          this.$refs.pinterestPreview
        ) {
          this.$refs.pinterestPreview.load()
        }
      },
      deep: true,
    },
  },
  methods: {
    openLightbox(item) {
      this.imgs = item
      this.visible = true
    },
  },
}
</script>

<style scoped></style>
