<template>
  <div class="auth_parent_container">
    <div v-if="loader" class="flex flex-col h-full items-center justify-center">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div v-else class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <div>
            <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Link invalid or expired</h2>
              <p class="text-md text-[#757A8A] text-center mt-5">Your token is either invalid or has been expired. Please
                request for the new Magic link from here.</p>
            </div>
            <div class="auth-form__button-field mt-7">
              <router-link
                  :to="{ name: 'magicLogin' }"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
              >
                <span class="w-full">Request New Link</span>
              </router-link>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");
import { authenticationTypes } from '@state/mutation-types'
import { checkValidMagicTokenURL } from '@src/config/api-utils.js'
import AuthenticationLeft from './AuthenticationLeft.vue'

export default {
  components: {
    AuthenticationLeft,
    LoginSideComponent
  },
  data() {
    return {
      is_valid_token: false,
      password: '',
      confirm_password: '',
      loader: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getJWTToken']),
  },
  created() {
    this.verifyToken()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    async verifyToken() {
      this.loader = true
      let magicToken = ''
      if (this.$route.query && this.$route.query.magic_token) {
        magicToken = this.$route.query.magic_token
      }
      const resp = await this.$http
        .post(checkValidMagicTokenURL, { token: magicToken })
        .then(
          async (response) => {
            if (response.data.status) {
              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                  },
                })
                return
              }

              // set token of logged user
              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
            } else {
              this.loader = false
              return false
            }
            return response
          },
          (response) => {
            this.loader = false
            return false
          }
        )
      if (
        (resp && resp.data.status) ||
        (this.getJWTToken && this.getJWTToken.length > 5)
      ) {
        if (this.isDeletedAccount() === true) return
        if (this.isEmailVerified() === false) return
        if (this.isSubscriptionExpired() === true) return
        if (this.isTrialExpired() === true) return

        // fetch profile and workspace data
        await this.fetchProfile()

        if (this.getProfile && this.getProfile.need_to_reset_password) {
          this.$router.push({ name: 'setPassword' })
          return
        }

        if (this.$route.query.url) {
          window.location.href = this.$route.query.url
        } else {
          this.$router.push({
            name: resp.data?.user?.preferences?.default_landing_page || 'dashboard',
            params: {workspace: resp.data.activeWorkspace.workspace.slug},
          })
        }
      }
    },
  },
}
</script>
