<script setup>
import { inject, ref } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
import PlannerAside from '@src/modules/planner_v2/components/PlannerAside'
import usePlannerActions from '@/src/modules/planner_v2/composables/usePlannerActions'

const root = inject('root')
const { $bvModal } = root
const selectedPost = ref()

const {
  commentsAccordion,
  internalNotesAccordion,
  externalAction,
  selectedItem: composableSelectedItem,
} = usePlannerActions()

EventBus.$on('post-status-modal', (post) => {
  selectedPost.value = post
  $bvModal.show('post-status-modal')
})
</script>

<template>
  <b-modal
    id="post-details-modal"
    body-class="p-0 rounded-md shadow-sm overflow-hidden padding-bottom-0"
    size="md"
    centered
    :no-close-on-backdrop="true"
    hide-header
    hide-footer
    dialog-class="max-w-3xl"
    modal-class="full_height first_comment_response right side_slide_right"
  >
    <PlannerAside
      :comments-accordion="commentsAccordion"
      :internal-notes-accordion="internalNotesAccordion"
      :external-action="externalAction"
      :item="composableSelectedItem"
      modal-id="post-details-modal"
    />
  </b-modal>

  <b-modal
    id="post-status-modal"
    size="lg"
    centered
    :no-close-on-backdrop="true"
    hide-header
    hide-footer
    dialog-class="max-w-7xl"
  >
    <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
  </b-modal>
</template>
