<template>
  <div>
    <!--.........if folder already exist...........-->
    <div v-if="getFolders.length > 0" class="dropdown-menu dropdown-menu-right">
      <li class="heading">Select Folder</li>
      <ul class="inner">
        <div
          v-if="getFolders.length > 4"
          class="search_input with_icon_right search_input_discover_filters"
        >
          <div class="search_inner w-100">
            <input
              v-model="search_folder"
              type="text"
              placeholder="Search folder name"
              class=""
            />
          </div>
        </div>
        <template v-for="(folder, index) in getSortedFolders">
          <li class="list_item">
            <span
              @click.prevent="savePostAsFavoriteAction(folder._id, post_id)"
              >{{ folder.name }}</span
            >
          </li>
        </template>
      </ul>
      <div class="dd_footer close_this text-center">
        <button
          v-if="addFolderStatus"
          class="btn gradient_btn large_btn blue_hover"
          @click.prevent="addFolder"
        >
          <i class="far fa-plus mr-2"></i>
          <span>Create New Folder</span>
        </button>
        <div v-else class="create_input">
          <input
            v-model="folderName"
            type="text"
            placeholder="Enter Folder Name"
            @keyup.enter="saveFolderAction(folderName)"
          />
          <div class="input_icon">
            <img
              class="add_folder"
              src="../../assets/article/check_icon.svg"
              alt="Add Folder"
              @click.prevent="saveFolderAction(folderName)"
            />
            <img
              class="remove"
              src="../../assets/article/delete_icon.svg"
              alt="remove topic"
              @click="cancelFolder"
            />
          </div>
        </div>
      </div>
    </div>

    <!--.........if No  Folder exist...........-->
    <div v-else class="dropdown-menu dropdown-menu-right">
      <li class="heading new_folder">Create New Folder</li>
      <div class="dd_footer close_this">
        <div class="create_input">
          <input
            v-model="folderName"
            type="text"
            placeholder="Enter Folder Name"
            @keyup.enter="saveFolderAction(folderName)"
          />
          <div class="input_icon">
            <img
              class="add_folder"
              src="../../assets/article/check_icon.svg"
              alt="Add Folder"
              @click.prevent="saveFolderAction(folderName)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import { EventBus } from '@common/lib/event-bus'

export default {
  props: ['post_id', 'postIndex'],
  data () {
    return {
      addFolderStatus: true,
      folderName: null,
      search_folder: ''
    }
  },
  computed: {
    ...mapGetters(['getFolderText', 'getFolders']),
    getSortedFolders () {
      if (this.search_folder.trim().length > 0) {
        const folders = this.getFolders.filter((item) =>
          item.name.toLowerCase().includes(this.search_folder.toLowerCase())
        )
        return sortBy(folders, ['name'], ['asc'])
      }
      return sortBy(this.getFolders, ['name'], ['asc'])
    }
  },
  methods: {
    // ...mapActions([''])
    addFolder () {
      this.addFolderStatus = false
    },
    cancelFolder () {
      this.addFolderStatus = true
    },
    savePostAsFavoriteAction (folderId, postId) {
      console.log('savePostAsFavoriteAction: ', folderId, postId)
      EventBus.$emit('favorite-post-item', {
        folderId,
        postId
      })
      this.search_folder = ''
    },
    async saveFolderAction (folderName) {
      console.log('saveFolderAction: ', folderName)
      const res = await this.$store.dispatch('storeFolder', {
        name: folderName
      })
      if (res) {
        EventBus.$emit('favorite-post-item', {
          folderId: res.data.added_folder,
          postId: this.post_id
        })
        this.addFolderStatus = true
        this.folderName = ''
      }
    }
  }
}
</script>
