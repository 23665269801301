<template>
  <div
      class="px-5 pt-[5.6rem] pb-5 relative pointer-events-none cst-editor mt-[-4.25rem] mb-1.5 rounded-md border !border-t-0 !border-r-0 !border-l-0"
  >
    <div
        class="mb-6 w-full flex gap-3 justify-start items-center !pointer-events-auto"
    >
      <h2 class="pl-8 text-base font-weight-500">{{
          title + "'s Followers Growth Trend"
        }}</h2>
      <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
          class="inline-block"
      >
        <i class="far fa-question-circle p-0 cursor-pointer"></i>
        <template v-slot:popper>
          <p class="text-sm text-gray-900">
            {{getComponentInfoTooltip(type, platformName)}}
          </p>
        </template>
      </v-menu>
    </div>
<!--    <div v-if="isLoading">-->
<!--      <SkeletonBox class="!w-full !h-[30rem]"></SkeletonBox>-->
<!--    </div>-->
    <div v-if="noDataFound" class="!pointer-events-auto flex flex-col justify-center items-center">
      <img src="@src/assets/img/analytics/no_post.svg" alt="" class="w-40"/>
      <p>No Followers Growth Data Found for {{ data.name }}.</p>
    </div>

    <div v-else class="flex !pointer-events-auto">
      <div class="flex-1">
        <div ref="chartRef" :style="{ height: chartHeight }"></div>
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import {
  computed,
  defineComponent,
  watch,
} from 'vue'

// composable
import useEcharts from "@src/modules/analytics_v3/composables/useEcharts"
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

export default defineComponent({
  name: 'FollowersGrowthComparison',
  props: {
    title: {
      type: String,
      default: "Competitors' Performance",
    },
    chartHeight: {
      type: [Number, String],
      default: '350px',
    },
    xAxisLabel: {
      type: String,
      default: 'Timeline',
    },
    yAxisLabel: {
      type: String,
      default: 'Followers',
    },
    yAxisSecondLabel: {
      type: String,
      default: 'Followers %',
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    type: {
      type: String,
      default: 'followersGrowthComparison',
    },
    legend: {
      type: Object,
      default: () => ({
        show: false,
        data: [],
      }),
    },
    chartTypes: {
      type: Array,
      default: () => ['line']
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    platformName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {getComponentInfoTooltip} = useCompetitorHelper()

    /**
     * @description condition if there's no data available for plotting chart.
     * @returns {boolean}
     */
    const noDataFound = computed(() => !props.data[props.platformName === 'facebook' ? 'dates_with_followers_count' : 'dates_with_followed_by_count']?.length)

    /**
     * @description creates and sorts(w.r.t. date) the data for series
     * @returns {*}
     */
    const createSeriesData = () => {
      if(!props.data) return []
      return {
        symbolSize: 8,
        colorBy: 'series',
        name: props.data?.name,
        type: 'line',
        areaStyle: {opacity: 0.4, cursor: 'auto'},
        color: props.data?.color,
        data: props.data[props.platformName === 'facebook' ? 'dates_with_followers_count' : 'dates_with_followed_by_count'],
        cursor: 'auto',
      }
    }

    /**
     * tooltip for pulse graph
     */
    const tooltipFormatter = (params) => {
      const {data} = params[0]
      const followersCount = parseInt(data[1])
      const date = new Date(data[0])
      const month = date.toLocaleString('default', {month: 'short'})
      const year = date.getFullYear()

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            <span class="text-[#000D21] font-weight-300">${month} ${date.getDate()}, ${year}</span>
          </div>
            <div class="flex items-end justify-between mt-2.5">
              <span class="text-[979CA0] text-sm mr-1.5">Followers: </span>
              <span class="text-[#69788E] text-sm font-bold">${followersCount.toLocaleString()}</span>
            </div>
        </div>
        `
    }

    const chartOptions = computed(() => ({
      tooltip: {
        borderColor: props.data?.color,
        show: true,
        trigger: 'axis',
        axisPointer: {
          crossStyle: {
            color: '#595c5f',
            width: 1,
          },
        },
        formatter: tooltipFormatter,
      },
      legend: props.legend,
      grid: {
        left: 30,
        top: 30,
        right: 40,
        bottom: 30,
        show: true,
        width: 'auto',
        borderColor: '#E9EFF6',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        name: props.xAxisLabel,
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          margin: 10,
          formatter: (value) => {
            const date = new Date(value)
            const month = date.toLocaleString('default', {month: 'short'})
            const year = date.getFullYear()
            return `${month} ${date.getDate()}, ${year}`
          },
        },
        nameLocation: 'end',
        nameTextStyle: {
          align: 'right',
          verticalAlign: 'top',
          lineHeight: 70,
          color: 'gray',
          fontWeight: 'bold',
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      },
      yAxis: {
        type: 'value',
        sort: 'ascending',
        name: props.yAxisLabel,
        min: 'dataMin',
        nameTextStyle: {
          align: 'center',
          verticalAlign: 'bottom',
          color: 'gray',
          fontWeight: 'bold',
        },
        axisLabel: {
          color: '#979CA0',
          fontSize: 12,
          fontWeight: 400,
          formatter: (value) => {
            return value.toLocaleString('en-US', {
              notation: 'compact',
              compactDisplay: 'short',
            })
          },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            color: '#E9EFF6',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#F2F4F6',
          },
        },
      },
      series: createSeriesData(),
    }))

    // setting up the eChart
    const {setup, chartRef} = useEcharts(
        chartOptions.value,
        props.chartTypes,
    )

    watch(
        () => chartRef.value,
        () => {
          setup(chartOptions.value)
        }
    )

    return {
      chartRef,
      chartOptions,
      noDataFound,
      getComponentInfoTooltip,
    }
  },
})
</script>
