<template>
  <b-modal
    :id="modalId"
    centered
    modal-class="upgrade-limits-modal"
    header-class="cs-modal-header"
    title-class="cs-modal-title"
    no-close-on-backdrop
    size="xl"
    hide-footer
    @hide="hideModal"
    @show="onShow"
  >
    <template v-slot:modal-title>
      <span v-if="addOnsData.type">Subscriptions Limits</span>
      <span v-else>Upgrade Limits</span>
    </template>
    <!-- view limits -->
    <template v-if="addOnsData.type">
      <AddOnsLimitsComponent :add-ons-data="addOnsData.data" :bill_type="addOnsData.type" />
    </template>
    <!-- increase limits -->
    <template v-else>
      <IncreaseLimitsComponent />
    </template>
  </b-modal>
</template>

<script>
import IncreaseLimitsComponent from '@src/modules/setting/components/billing/dialogs/IncreaseLimitsComponent'
import AddOnsLimitsComponent from '@src/modules/setting/components/billing/dialogs/AddOnsLimitsComponent'
import { EventBus } from '@common/lib/event-bus'

export default {
  name: 'IncreaseLimitsDialog',
  components: {
    IncreaseLimitsComponent,
    AddOnsLimitsComponent,
  },
  data() {
    return {
      modalId: 'increase-limits-dialog',
      addOnsData: {
        type: false,
        data: null,
      },
    }
  },
  mounted() {
    EventBus.$on('show-addons-limit', (data) => {
      this.addOnsData.type = data.type
      this.addOnsData.data = data.subscriptionLimits
      this.$bvModal.show('increase-limits-dialog')
    })
  },
  methods: {
    hideModal() {
      this.addOnsData.type = false
      this.addOnsData.data = null
      this.$bvModal.hide('increase-limits-dialog')
    },
    onShow(event) {
      // if user is on trail plan then show change trail plan dialog
      if (this.isUserTrialPlan()) {
        event.preventDefault()
        this.$bvModal.show('change-trial-plan-dialog')
      }
    },
  },
}
</script>

<style lang="less"></style>
