import { mapGetters } from 'vuex'
import textHelper from '@common/lib/text-helper'
import useString from '@/src/modules/common/composables/useString.js'
import useDateFormat from "@common/composables/useDateFormat";
const { momentWrapper } = useDateFormat()
const { capitalizeFirstLetter } = useString()

export default {
  data() {
    return {
      accountSelectFlag: {
        facebook: false,
        instagram: false,
        linkedin: false,
        tiktok: false,
        twitter: false,
        pinterest: false,
        youtube: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getPinterestBoards',
      'getPinterestAccounts',
      'getLinkedinCompanyPages',
      'getLinkedinAccounts',
      'getInstagramAccounts',
      'getFacebookPagesList',
      'getTiktokAccounts',
      'getTwitterAccounts',
      'getYoutubeAccounts',
    ]),
  },
  methods: {
    /**
     * Reseting the date time to the default 30 days.
     *
     * @param analytics
     */
    reloadDatePicker(analytics) {
      analytics.startDate = analytics.ranges['Last 30 Days'][0]
      analytics.endDate = analytics.ranges['Last 30 Days'][1]
      this.analytics.isChanged = false
    },
    getActiveBoardImage(boardId) {
      if (this.getPinterestBoards.length > 0) {
        const boards = this.getPinterestBoards.filter(
          (item) => item.board_id === boardId
        )
        if (boards.length > 0) {
          return boards[0].image
        }
      }
      return null
    },
    getActiveCompanyPage(pageId) {
      if (this.getLinkedinAccounts.items.length > 0) {
        const boards = this.getLinkedinAccounts.items.filter(
          (item) => item.linkedin_id.toString() === pageId.toString()
        )
        if (boards.length > 0) {
          return boards[0].image
        }
      }
      return null
    },
    isLink(text) {
      if (!text) return text
      return textHelper.convertToLinkClass(text)
      // return text
    },
    isHashtag(text) {
      if (!text) return text
      return textHelper.convertToHashtagsClass(text)
      // return text
    },
    nextLineReplacement(text) {
      if (!text) return text
      return textHelper.replaceByHtmlNextLine(text)
    },
    findInstaAccount(instaId) {
      return this.getInstagramAccounts.items.find(
        (x) => x.instagram_id === instaId
      )
    },
    findFacebookAccount(facebookId) {
      return this.getFacebookPagesList.find((x) => x.facebook_id === facebookId)
    },
    findLinkedinAccount(linkedinId) {
      return this.getLinkedinAccounts.items.find(
        (x) => x.linkedin_id === linkedinId
      )
    },
    findTwitterAccount(twitterId) {
      return this.getTwitterAccounts.items.find(
        (x) => x.twitter_id === twitterId
      )
    },
    findPinterestBoard(boardId) {
      return this.getPinterestBoards.find((x) => x.board_id === boardId)
    },
    findTiktokAccount(tiktokId) {
      return this.getTiktokAccounts.items.find(
        (x) => x.platform_identifier === tiktokId
      )
    },
    findYoutubeAccount(youtubeId) {
      return this.getYoutubeAccounts?.items?.find(
        (x) => x.platform_identifier === youtubeId
      )
    },
    getProfileType(accountType, account = null) {
      switch (accountType) {
        case 'instagram':
          return this.isIGPersonalAccount(account) ? 'Personal' : 'Business'
        case 'facebook':
          return 'Page'
        case 'pinterest':
          return account?.type || 'Unknown Type'
        case 'linkedin':
          return account ? account.type : 'Page'
        case 'tiktok':
          return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
        case 'youtube':
          return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
        default:
          return 'Page'
      }
    },
    getImage(network, post) {
      switch (network) {
        case 'instagram':
          return post.profile_picture_url
        case 'facebook':
          return (
            'https://graph.facebook.com/' +
            post.page_id +
            '/picture?type=square'
          )
        case 'linkedin':
          console.log('Linkedin ids are: ', post)
          if (this.findLinkedinAccount(post.linkedin_id)) {
            return this.findLinkedinAccount(post.linkedin_id).image
          } else if (post?.image) return post.image
          return ''
        case 'tiktok':
          return this.findTiktokAccount(post.tiktok_id)?.platform_logo
        case 'youtube':
          return this.findYoutubeAccount(post.channel_id)?.platform_logo
        case 'twitter':
          return post.profile_image_url
        case 'pinterest':
          if (this.findPinterestBoard(post.board_id)) {
            return this.findPinterestBoard(post.board_id).image
          }
          return ''
      }
    },

    /**
     *
     * @param account object
     * @param type string
     */
    channelImage(account, type) {
      const image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      const gmbImage =
        'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      if (!account) return image

      if (account && account.platform_logo) return account.platform_logo

      switch (type) {
        case 'facebook':
          return account.image
            ? account.image
            : account.picture && account.picture.url
            ? account.picture.url
            : image
        case 'twitter':
        case 'linkedin':
        case 'pinterest':
          return account.image && account.image.large && account.image.large.url
            ? account.image.large.url
            : account.image
            ? account.image
            : image
        case 'instagram':
          return account.image
            ? account.image
            : account.profile_picture
            ? account.profile_picture
            : image
        case 'medium':
          return account.imageUrl ? account.imageUrl : image
        case 'gmb':
          return account.location_name ? gmbImage : image
      }
    },
    isPostImageAvailable(network, post) {
      switch (network) {
        case 'instagram':
          return 'media_url' in post
        case 'facebook':
          return 'full_picture' in post
        case 'linkedin':
          return 'image' in post
        case 'twitter':
          return 'media_url' in post
        case 'pinterest':
          return 'image' in post
        case 'tiktok':
          return 'cover_image_url' in post
        case 'youtube':
          return 'thumbnail_url' in post
      }
    },
    getPostImage(network, post) {
      switch (network) {
        case 'instagram':
          if (!post.media_url?.length && post.permalink) {
            return this.getResizedImageURL(
              this.getMediaLink(post.permalink),
              570,
              0
            )
          }
          return post.media_url[post.media_url.length - 1]
        case 'facebook':
          return post.full_picture
        case 'linkedin':
          return post.image
        case 'twitter':
          return post.media_url
        case 'pinterest':
          return post.image
        case 'tiktok':
          return post.cover_image_url
        case 'youtube':
          return post.thumbnail_url
      }
    },
    getPostName(network, post) {
      switch (network) {
        case 'instagram':
          return post.name
        case 'facebook':
          return post.page_name
        case 'linkedin':
          if (this.findLinkedinAccount(post.linkedin_id)) {
            return this.findLinkedinAccount(post.linkedin_id).name
          } else if (post?.name) return post.name
          return ''
        case 'twitter':
          return post.name
        case 'tiktok':
          return post.page_name
        case 'youtube':
          if (this.findYoutubeAccount(post.channel_id)) {
            return this.findYoutubeAccount(post.channel_id).platform_name
          } else if (post?.name) return post.name
          return ''
        case 'pinterest':
          if (this.findPinterestBoard(post.board_id)) {
            return this.findPinterestBoard(post.board_id).name
          }
      }
    },
    getPostUsername(network, post) {
      switch (network) {
        case 'instagram':
          return '@' + post.username
        case 'facebook':
          return post.name
        case 'linkedin':
          return post.user_name
        case 'twitter':
          return '@' + post.screen_name
        case 'pinterest':
          return '@' + post.screen_name
        case 'tiktok':
          return '@' + post.page_name
        case 'youtube':
          if (this.findYoutubeAccount(post.channel_id)) {
            return (
              '@' + this.findYoutubeinAccount(post.channel_id).platform_name
            )
          }
      }
    },
    isPostLikesAvailable(network) {
      switch (network) {
        case 'twitter':
        case 'instagram':
        case 'facebook':
        case 'linkedin':
          return true
        case 'pinterest':
          return false
        case 'tiktok':
          return true
        case 'youtube':
          return true
      }
    },
    getPostLikes(network, post) {
      switch (network) {
        case 'instagram':
          return post.like_count ? post.like_count : 0
        case 'facebook':
          return post.like ? post.like : 0
        case 'linkedin':
          return post.favorites ? post.favorites : 0
        case 'twitter':
          return post.favorite_count ? post.favorite_count : 0
        case 'tiktok':
          return post.likes_count
            ? post.likes_count
            : post.total_likes
            ? post.total_likes
            : 0
        case 'youtube':
          return post.like ? post.like : 0
      }
    },
    getPostLikesText(network) {
      switch (network) {
        case 'instagram':
          return 'Likes'
        case 'facebook':
          return 'Likes'
        case 'linkedin':
          return 'Favorites'
        case 'twitter':
          return 'Favorites'
        case 'tiktok':
          return 'Likes'
        case 'youtube':
          return 'Likes'
      }
    },
    getPostEngagement(network, post) {
      switch (network) {
        case 'instagram':
        case 'pinterest':
          return post.engagement ? post.engagement : 0
        case 'facebook':
          return post.total_engagement ? post.total_engagement : 0
        case 'linkedin':
          return post.total_engagement ? post.total_engagement : 0
        case 'twitter':
          return post.total_engagement ? post.total_engagement : 0
        case 'tiktok':
          return post.total_engagement ? post.total_engagement : 0
        case 'youtube':
          return post.total_engagement ? post.total_engagement : 0
      }
    },
    isPostCommentsAvailable(network) {
      switch (network) {
        case 'twitter':
        case 'pinterest':
          return false
        case 'instagram':
        case 'facebook':
        case 'linkedin':
          return true
        case 'youtube':
          return true
      }
      return network !== 'twitter'
    },
    getPostComments(network, post) {
      switch (network) {
        case 'instagram':
          return post.comments_count ? post.comments_count : 0
        case 'facebook':
          return post.comments ? post.comments : 0
        case 'linkedin':
          return post.comments ? post.comments : 0
        case 'tiktok':
          return post.comments_count
            ? post.comments_count
            : post.total_comments
            ? post.total_comments
            : 0
        case 'twitter':
          return 0
        case 'youtube':
          return post.comment ? post.comment : 0
      }
    },
    isPostSharesAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'twitter':
        case 'pinterest':
          return true
        case 'instagram':
        case 'linkedin':
          return false
        case 'youtube':
          return true
      }
    },
    getPostShares(network, post) {
      switch (network) {
        case 'instagram':
          return post.saved ? post.saved : 0
        case 'facebook':
          return post.shares ? post.shares : 0
        case 'linkedin':
          return post.shares ? post.shares : 0
        case 'twitter':
          return post.retweet_count ? post.retweet_count : 0
        case 'pinterest':
          return post.repins_count ? post.repins_count : 0
        case 'tiktok':
          return post.shares_count
            ? post.shares_count
            : post.total_shares
            ? post.total_shares
            : 0
        case 'youtube':
          return post.share ? post.share : 0
      }
    },
    getPostSharesText(network) {
      switch (network) {
        case 'facebook':
          return 'Shares'
        case 'instagram':
          return 'Saved'
        case 'linkedin':
          return 'Shares'
        case 'twitter':
          return 'Retweet Count'
        case 'pinterest':
          return 'Repins Count'
        case 'tiktok':
          return 'Shares'
        case 'youtube':
          return 'Shares'
      }
    },
    isPostTextAvailable(network, post) {
      switch (network) {
        case 'instagram':
          return 'caption' in post
        case 'facebook':
          return 'caption' in post
        case 'linkedin':
          return 'title' in post
        case 'twitter':
          return 'tweet_text' in post
        case 'pinterest':
          return 'pin_text' in post
        case 'tiktok':
          return 'post_description' in post
        case 'youtube':
          return 'title' in post
      }
    },
    getPostText(network, post) {
      switch (network) {
        case 'instagram':
          return post.caption
        case 'facebook':
          return post.caption
        case 'linkedin':
          return post.title
        case 'pinterest':
          return post.pin_text
        case 'twitter':
          return post.tweet_text
        case 'tiktok':
          return post.post_description
        case 'youtube':
          return post.title
      }
    },
    isPostImpressionsAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPostImpressions(network, post) {
      switch (network) {
        case 'facebook':
          return 'post_impressions' in post ? post.post_impressions : 0
        case 'pinterest':
          return 'impressions' in post ? post.impressions : 0
      }
    },
    isPinClickAvailable(network) {
      switch (network) {
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPinClick(network, post) {
      switch (network) {
        case 'pinterest':
          return 'pin_click' in post ? post.pin_click : 0
      }
    },
    isPostClicksAvailable(network) {
      switch (network) {
        case 'facebook':
        case 'pinterest':
          return true
        default:
          return false
      }
    },
    getPostClicks(network, post) {
      switch (network) {
        case 'facebook':
          return 'post_clicks' in post ? post.post_clicks : 0
        case 'pinterest':
          return 'pin_click' in post ? post.pin_click : 0
      }
    },
    getPostDate(network, post) {
      switch (network) {
        case 'instagram':
          return momentWrapper(post.post_created_at).formatDateTime()
        case 'facebook':
          return momentWrapper(post.created_time).formatDateTime()
        case 'linkedin':
          return momentWrapper(post.created_at).formatDateTime()
        case 'twitter':
          return momentWrapper(post.created_at).formatDateTime()
        case 'pinterest':
          return momentWrapper(post.created_at).formatDateTime()
        case 'tiktok':
          return momentWrapper(post.created_time).formatDateTime()
        case 'youtube':
          return momentWrapper(post.published_at).formatDateTime()
      }
    },
    formatDate(date) {
      return momentWrapper(date).formatTimezone().formatDateTime()
    },
    getIconClass(accountType) {
      switch (accountType) {
        case 'instagram':
          return 'fab fa-instagram ins ins_bg'
        case 'facebook':
          return 'fab fa-facebook-f fb fb_bg'
        case 'twitter':
          return 'fab fa-twitter tw tw_bg'
        case 'linkedin':
          return 'fab fa-linkedin-in lin lin_bg'
        case 'pinterest':
          return 'fab fa-pinterest-in pin pin_bg'
        case 'tiktok':
          return 'fab fa-tiktok'
        case 'youtube':
          return 'fab fa-youtube'
      }
    },
    getEngagementField(network) {
      switch (network) {
        case 'instagram':
          return 'engagement'
        case 'facebook':
          return 'total_engagement'
        case 'linkedin':
          return 'total_engagement'
        case 'twitter':
          return 'total_engagement'
        case 'pinterest':
          return 'engagement'
        case 'tiktok':
          return 'total_engagement'
        case 'youtube':
          return 'total_engagement'
      }
    },
    getLikeField(network) {
      switch (network) {
        case 'instagram':
          return 'like_count'
        case 'facebook':
          return 'total'
        case 'linkedin':
          return 'favorites'
        case 'twitter':
          return 'favorite_count'
        case 'tiktok':
          return 'likes_count'
        case 'youtube':
          return 'like'
      }
    },
    isVideo(network, post) {
      switch (network) {
        case 'instagram':
          return post.media_type === 'VIDEO'
        case 'facebook':
          return false
        case 'linkedin':
          return false
        case 'twitter':
          return false
        case 'tiktok':
          return false
        case 'youtube':
          return false
      }
    },
    getCommentField(network) {
      switch (network) {
        case 'instagram':
          return 'comments_count'
        case 'facebook':
          return 'comments'
        case 'linkedin':
          return 'comments'
        case 'twitter':
          return false
        case 'tiktok':
          return 'comments_count'
        case 'youtube':
          return 'comment'
      }
    },
    getSharesField(network) {
      switch (network) {
        case 'instagram':
          return false
        case 'facebook':
          return 'shares'
        case 'linkedin':
          return 'shares'
        case 'twitter':
          return 'shares'
        case 'pinterest':
          return 'repins_count'
        case 'tiktok':
          return 'shares_count'
        case 'youtube':
          return 'share'
      }
    },

    selectAllAccounts() {
      const platforms = ['facebook', 'instagram', 'linkedin', 'tiktok', 'youtube']
      let accountsList = []
      for (const platform in platforms) {
        accountsList = accountsList.concat(
          this.getPlatformAccounts(platforms[platform])
        )
      }
      return accountsList
    },
    getPlatformAccounts(platform) {
      const accountsList = []
      switch (platform) {
        case 'facebook':
          this.getFacebookPagesList.forEach((item) => {
            item.account_type = 'facebook'
            accountsList.push(item)
          })
          break
        case 'twitter':
          this.getTwitterAccounts.items.forEach((item) => {
            if (item?.developer_app?.analytics_enabled) {
              item.account_type = 'twitter'
              accountsList.push(item)
            }
          })
          break
        case 'linkedin':
          this.getLinkedinAccounts.items.forEach((item) => {
            item.account_type = 'linkedin'
            if (typeof item.linkedin_id !== 'string') {
              accountsList.push(item)
            }
          })
          break
        case 'instagram':
          this.getInstagramAccounts.items.forEach((item) => {
            item.account_type = 'instagram'
            accountsList.push(item)
          })
          break
        case 'pinterest':
          this.getPinterestBoards.forEach((item) => {
            item.account_type = 'pinterest'
            accountsList.push(item)
          })
          break
        case 'tiktok':
          this.getTiktokAccounts.items.forEach((item) => {
            item.account_type = 'tiktok'
            accountsList.push(item)
          })
          break
        case 'youtube':
          this.getYoutubeAccounts?.items?.forEach((item) => {
            item.account_type = 'youtube'
            accountsList.push(item)
          })
          break
      }

      return accountsList
    },
    getPreview(network) {
      switch (network) {
        case 'instagram':
          return 'instagram-posts-analytics-preview'
        case 'facebook':
          return 'facebook-analytics-preview'
        case 'linkedin':
          return 'linkedin-analytics-preview'
        case 'twitter':
          return 'twitter-analytics-preview'
        case 'pinterest':
          return 'pinterest-analytics-preview'
        case 'tiktok':
          return 'tiktok-analytics-preview'
        case 'youtube':
          return 'youtube-analytics-preview'
      }
    },
    getPreviewLink(network, post) {
      switch (network) {
        case 'instagram':
          return post.permalink
        case 'facebook':
          return post.permalink
        case 'linkedin':
          return 'https://www.linkedin.com/feed/update/' + post.activity
        case 'pinterest':
          return 'https://www.pinterest.com/pin/' + post.pin_id
        case 'twitter':
          return (
            'https://twitter.com/' +
            post.screen_name +
            '/status/' +
            post.tweet_id
          )
        case 'tiktok':
          return post.embed_link
        case 'youtube':
          return post.share_url
        default:
          return ''
      }
    },
    timesAgo(date) {
      return moment(date).tz(this.getActiveWorkspace.timezone).fromNow()
    },
    hourDifference(date) {
      return Math.round(moment().diff(date) / 36e5)
    },
    getReachCurrentPrevious(currentVal, prevVal) {
      let current = currentVal
      if (currentVal) {
        current = currentVal
      }

      let previousValue = 0
      if (prevVal) {
        previousValue = prevVal
      }

      const previous =
        previousValue === 0 || !previousValue ? current : previousValue
      const value = (((current - previous) / previous) * 100).toFixed(2)
      return value === 'NaN' || value === '0.00' ? 0 : value
    },
    getReach(type, section) {
      let current = 0
      if (section.current && section.current[type]) {
        current = isNaN(section.current[type])
          ? section.current[type].value
          : section.current[type]
      }

      let previousValue = 0
      if (section.previous && section.previous[type]) {
        previousValue = isNaN(section.previous[type])
          ? section.previous[type].value
          : section.previous[type]
      }

      const previous =
        previousValue === 0 || !previousValue ? current : previousValue
      const value = (((current - previous) / previous) * 100).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReachUp(type, section) {
      return this.getReach(type, section) > 0
    },
    getReels(type, section) {
      let current = 0
      if (section.current && section.current[type]) {
        current = isNaN(section.current[type])
          ? section.current[type].value
          : section.current[type]
      }

      let previousValue = 0
      if (section.previous && section.previous[type]) {
        previousValue = isNaN(section.previous[type])
          ? section.previous[type].value
          : section.previous[type]
      }

      const previous =
        previousValue === 0 || !previousValue ? current : previousValue
      const value = (((current - previous) / previous) * 100).toFixed(2)
      return value === 'NaN' ? 0 : value
    },
    getReelsUp(type, section) {
      return this.getReels(type, section) > 0
    },
    sortList(targetObject) {
      if (targetObject) {
        return Object.entries(targetObject)
          .sort(([, v1], [, v2]) => +v2 - +v1)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      }
    },
    /**
     * @description creates a thumbnail for every media link. (was required in case of videos and reels.)
     * @param url
     * @returns {string}
     */
    getMediaLink(url) {
      switch (true) {
        case url.includes('/tv/'):
          return `${url.replace('/tv/', '/p/')}media?size=l`
        case url.includes('/reel/'):
          return `${url.replace('/reel/', '/p/')}media?size=l`
        default:
          return `${url}media?size=l`
      }
    },
  },
}
