import http from '@common/lib/http-common'
import {
  feederAggregateURI,
  feederFeedSuggestionURI,
  feederFeedURI,
  feederGroupURI,
  feederOPMLURI,
  feederPostURI,
  feederUserFeedURI,
} from '@src/modules/discovery/config/api-utils'
import { feederTypes } from '@src/modules/discovery/store/mutation-types'
import moment from 'moment'

const getDefaultFeederGroup = () => {
  return {
    _id: null,
    name: null,
  }
}
const getDefaultFeederValidations = () => {
  return {
    name: false,
  }
}
const getDefaultFeederMessages = () => {
  return {
    name: 'Feed group name cannot be empty.',
  }
}
export default {
  state: {
    group: {
      create: getDefaultFeederGroup(),
      list: [],
      validations: {
        fields: getDefaultFeederValidations(),
        messages: getDefaultFeederMessages(),
      },
    },
    preferences: {
      item_style: 'grid',
    },
  },
  getters: {
    getFeederGroupStore: (state) => state.group.create,
    getFeederGroupValidations: (state) => state.group.validations,
    getFeederGroupList: (state) => state.group.list,
    getFeederPreferences: (state) => state.preferences,
  },
  actions: {
    fetchFeederGroup(
      { commit, getters, dispatch },
      { groupId, page, order, fromDate, toDate }
    ) {
      return http.post(feederGroupURI + 'retrieve_by_id', {
        workspace_id: getters.getActiveWorkspace._id,
        group_id: groupId,
        page,
        order,
        from_date: moment(fromDate).format('YYYY-MM-DD'),
        to_date: moment(toDate).format('YYYY-MM-DD'),
      })
    },
    fetchFeederFeed(
      { commit, getters, dispatch },
      { feedId, page, order, fromDate, toDate }
    ) {
      return http.post(feederUserFeedURI + 'retrieve_by_id', {
        workspace_id: getters.getActiveWorkspace._id,
        feed_id: feedId,
        page,
        order,
        from_date: fromDate,
        to_date: toDate,
      })
    },
    feederArchivePost({ commit, getters, dispatch }, { record_id, state }) {
      return http.post(feederPostURI + 'archived/store', {
        workspace_id: getters.getActiveWorkspace._id,
        record_id: record_id,
        state,
      })
    },
    feederStarredPost({ commit, getters, dispatch }, { record_id, state }) {
      return http.post(feederPostURI + 'starred/store', {
        workspace_id: getters.getActiveWorkspace._id,
        record_id: record_id,
        state,
      })
    },

    fetchFeederAggregate({ commit, getters, dispatch }, state) {
      return http.post(feederAggregateURI + '/view', {
        workspace_id: getters.getActiveWorkspace._id,
        state,
      })
    },

    storeFeederGroup({ commit, getters, dispatch }, payload) {
      const groupStore = getters.getFeederGroupStore
      const groupPayload = {
        _id: groupStore._id || null,
        name: groupStore.name,
      }
      return http
        .post(feederGroupURI + 'store', {
          workspace_id: getters.getActiveWorkspace._id,
          ...groupPayload,
        })
        .then((res) => {
          if (res.data.status) {
            if (getters.getFeederGroupStore._id) {
              commit(feederTypes.UPDATE_ITEM_TO_GROUP, res.data.data)
            } else {
              commit(feederTypes.ADD_ITEM_TO_GROUP, res.data.data)
            }
            dispatch('toastNotification', { message: res.data.message })
            payload.appContainer.$bvModal.hide('add_feeder_group')
          }
          return res
        })
    },
    fetchFeederGroups({ commit, getters, dispatch }) {
      return http
        .post(feederGroupURI + 'show', {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            commit(feederTypes.SET_GROUP_ITEMS, res.data.data)
          }
          return res
        })
        .catch((err) => err)
    },
    fetchFeederStatistics({ commit, getters, dispatch }) {
      return http
        .post(feederGroupURI + 'statistics', {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            const groupItemList = getters.getFeederGroupList
            const statistics = res.data.data
            console.log('feeder statistics [before]', groupItemList)
            groupItemList.forEach((groupItem) => {
              let totalGroupCount = 0
              groupItem.user_feed_details.forEach((userFeedItem) => {
                if (Object.hasOwn(statistics, userFeedItem.feed_id)) {
                  console.log(
                    'feeder feed item statistics present',
                    statistics[userFeedItem.feed_id]
                  )
                  totalGroupCount += statistics[userFeedItem.feed_id]
                  userFeedItem.total_posts = statistics[userFeedItem.feed_id]
                  // Vue.$set(userFeedItem, 'total_posts', statistics[userFeedItem.feed_id])
                }
              })
              groupItem.total_posts = totalGroupCount
              // Vue.$set(groupItem, 'total_posts', totalGroupCount)
              console.log('feeder statistics group item', groupItem)
            })
            console.log('feeder statistics [after]', groupItemList)
          }
          return res
        })
        .catch((err) => err)
    },
    updateFeederStatisticsForDomain({ getters }, { domainUUID, state }) {
      const groupItemList = getters.getFeederGroupList
      groupItemList.forEach((groupItem) => {
        let totalGroupCount = groupItem.total_posts
        groupItem.user_feed_details.forEach((userFeedItem) => {
          if (userFeedItem.feed_id === domainUUID) {
            if (state === 'archive') {
              userFeedItem.total_posts -= 1
              totalGroupCount -= 1
            } else {
              userFeedItem.total_posts += 1
              totalGroupCount += 1
            }
          }
        })
        groupItem.total_posts = totalGroupCount
        console.log('feeder statistics group item', groupItem)
      })
    },
    deleteFeederGroup({ commit, getters, dispatch }, feederGroupId) {
      return http
        .post(feederGroupURI + 'delete', {
          workspace_id: getters.getActiveWorkspace._id,
          group_id: feederGroupId,
        })
        .then((res) => {
          if (res.data.status) {
            commit(feederTypes.REMOVE_ITEM_FROM_GROUP, feederGroupId)
            dispatch('toastNotification', { message: res.data.message })
          }
          return res
        })
    },
    storeUserFeed({ commit, getters, dispatch }, { feedId, groupId }) {
      return http
        .post(feederUserFeedURI + 'store', {
          workspace_id: getters.getActiveWorkspace._id,
          group_id: groupId,
          feed_id: feedId,
        })
        .then((res) => {
          if (res.data.status) {
          }
          return res
        })
    },
    deleteUserFeed({ commit, getters, dispatch }, { feedId, groupId }) {
      return http
        .post(feederUserFeedURI + 'delete', {
          workspace_id: getters.getActiveWorkspace._id,
          group_id: groupId,
          feed_id: feedId,
        })
        .then((res) => {
          if (res.data.status) {
          }
          return res
        })
    },
    addUserFeedToGroup({ commit, getters }, payload) {
      console.log('[add user feed to group] -> payload ', payload)
      const group = getters.getFeederGroupList.find(
        (item) => item.uuid === payload.group_id
      )
      console.log('[add user feed to group] -> group found', group)
      if (group) {
        console.log(
          '[add user feed to group] -> add feed to the group',
          payload
        )
        group.user_feed_details.push(payload)
        console.log(
          '[add user feed to group] -> add feed to the group',
          payload.uuid
        )
        group.user_feed_ids.push(payload.uuid)
      }
    },
    removeUserFeedFromGroup({ commit, getters }, payload) {
      console.log('[add user feed to group] -> payload ', payload)
      const group = getters.getFeederGroupList.find(
        (item) => item.uuid === payload.group_id
      )
      console.log('[add user feed to group] -> group found', group)
      if (group) {
        const feedItemIndex = group.user_feed_details.findIndex(
          (feed) => feed.feed_id === payload.feed_id
        )
        if (feedItemIndex >= 0) group.user_feed_details.splice(feedItemIndex, 1)
        const feedIdIndex = group.user_feed_ids.indexOf(payload.feed_id)
        if (feedIdIndex >= 0) group.user_feed_ids.splice(feedIdIndex, 1)
      }
    },
    shareFeederPost({ commit, getters }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return http.post(feederPostURI + 'preview/share', payload)
    },
    feederOPML({ commit, getters }, payload) {
      return http.post(feederFeedURI + 'opml', payload)
    },
    feederManageSources({ commit, getters }, payload) {
      const requestPayload = {
        group_id: (payload && Object.hasOwn(payload, 'group_id')) || null,
        workspace_id: getters.getActiveWorkspace._id,
      }
      return http.post(feederGroupURI + 'manage/sources', requestPayload)
    },
    feederUnfollowIndividualFeed({ commit, getters }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return http.post(
        feederUserFeedURI + 'manage/unfollow/individual',
        payload
      )
    },
    feederUnfollowBatchFeeds({ commit, getters }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return http.post(feederUserFeedURI + 'manage/unfollow/batch', payload)
    },
    feederStoreUserFeedName({ commit, getters, dispatch }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return http.post(feederUserFeedURI + 'name/store', payload)
    },
    feederOPMLHistories({ commit, getters, dispatch }) {
      return http.post(feederOPMLURI + 'history', {
        workspace_id: getters.getActiveWorkspace._id,
      })
    },
    feederOPMLHistoryLogs({ commit, getters, dispatch }, historyId) {
      return http.post(feederOPMLURI + 'history/logs', {
        workspace_id: getters.getActiveWorkspace._id,
        history_id: historyId,
      })
    },
    feederSearchHistory({ commit, getters, dispatch }) {
      return http.post(feederFeedSuggestionURI + 'search_history', {
        workspace_id: getters.getActiveWorkspace._id,
      })
    },
  },
  mutations: {
    [feederTypes.SET_GROUP_STORE_ITEM](state, value) {
      state.group.create = JSON.parse(JSON.stringify(value))
    },
    [feederTypes.ADD_ITEM_TO_GROUP](state, value) {
      state.group.list.push(value)
    },
    [feederTypes.UPDATE_ITEM_TO_GROUP](state, payload) {
      console.log('feederTypes.UPDATE_ITEM_TO_GROUP payload', payload)
      const itemIndex = state.group.list.findIndex(
        (item) => item._id === payload._id
      )
      console.log('feederTypes.UPDATE_ITEM_TO_GROUP item index', itemIndex)
      const item = state.group.list.find((item) => item._id === payload._id)
      item.name = payload.name
      // console.log('feederTypes.UPDATE_ITEM_TO_GROUP', item, itemTarget, payload)
      state.group.list.splice(itemIndex, 1, item)
    },
    [feederTypes.SET_GROUP_ITEMS](state, value) {
      state.group.list = value
    },
    [feederTypes.REMOVE_ITEM_FROM_GROUP](state, value) {
      const itemIndex = state.group.list.findIndex((item) => item._id === value)
      state.group.list.splice(itemIndex, 1)
    },
    [feederTypes.CHANGE_ITEM_VIEW_PREFERENCE](state, value) {
      state.preferences.item_style = value
    },
    [feederTypes.RESET_CREATE_GROUP_STATE](state) {
      state.group.create = getDefaultFeederGroup()
    },
  },
}
