<template>
  <div>
    <div class="filter grid grid-cols-2 gap-1 w-100">
      <CstDropdown class="col-span-2" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-if="account" v-slot:selected>
          {{account}}
        </template>
        <template v-else v-slot:selected>
          Select Account
        </template>
        <template v-if="accountsList.length > 0">
          <CstDropdownItem v-for="(item,index) in accountsList" :key="index" @click="handleAccount(item)" >
            {{item.username}}
          </CstDropdownItem>
        </template>
        <template v-else>
          <CstDropdownItem>
            No Account Found
          </CstDropdownItem>
        </template>
      </CstDropdown>
      <CstDropdown class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>{{type}}</template>
          <CstDropdownItem @click="handleType('All')">
            All
          </CstDropdownItem>
          <CstDropdownItem @click="handleType('Article')">
            Article
          </CstDropdownItem>
          <CstDropdownItem @click="handleType('Video')">
            Video
          </CstDropdownItem>
          <CstDropdownItem @click="handleType('Image')">
            Image
          </CstDropdownItem>
      </CstDropdown>
      <CstDropdown  class="" size="small" button-classes="w-100 flex justify-between bg-white">
        <template v-slot:selected>
          {{sort}}
        </template>
          <CstDropdownItem @click="handleSort('Newest')">
            Newest
          </CstDropdownItem>
          <CstDropdownItem @click="handleSort('Oldest')">
            Oldest
          </CstDropdownItem>
          <CstDropdownItem @click="handleSort('Title')">
            Title
          </CstDropdownItem>
          <CstDropdownItem @click="handleSort('Site')">
            Site
          </CstDropdownItem>
      </CstDropdown>
    </div>
    <div v-if="loader && posts.length === 0" class="p-3">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else-if="posts && posts.length > 0">
        <template v-for="(post, index) in posts" :key="index">
          <div
               :draggable="true"
                class="flex my-2 w-100 p-2 rounded-lg  bg-cs-white border hover:bg-cs-foggray hover:shadow-sm overflow-hidden max-h-38" @dragstart="dragstartHandler($event, post)">
            <div class="image w-28 min-h-full">
              <div
v-if="post.image" class="w-full h-full bg-cover bg-no-repeat bg-center rounded-md"
                   :style="{ 'background-image': 'url(' + post.image + ')' }">
                <span class="w-100 flex bg-cs-primary text-white text-xs px-2">{{getPocketContentType(post)}}</span>
                <img v-if="post.image" class="max-w-full max-h-full opacity-0"  :src="post.image" alt="">
              </div>
              <div
v-else class="w-full h-full bg-cover bg-no-repeat bg-center rounded-md"
                   :style="{ 'background-image': 'url(@assets/img/composer/no-preview.png)' }">
                <span class="w-100 flex bg-cs-primary text-white text-xs px-2">{{getPocketContentType(post)}}</span>
                <img v-if="post.image" class="max-w-full max-h-full opacity-0"  :src="post.image" alt="">
              </div>
            </div>
            <div class="content w-full px-2">
              <div v-if="getPocketContentType(post) !== 'Image'" class="font-bold" :title="post.title">{{ limitTextLength(post.title, 55) }}</div>
              <div class="my-1 font-normal text-xs text-gray-800">{{trimURL(post.domain) }}</div>
              <div class="my-1 font-normal text-xs text-gray-800">{{ unixFromNow(post.time_added) }}</div>
              <p v-if="post.authors" class="font-normal text-xs text-gray-800">By {{ getAuthorNames(post.authors) }} </p>
<!--              <hr class="m-2">-->
<!--              <div class="flex justify-between">-->
<!--                  <span class="text-cs-primary cursor-pointer ml-auto">Preview <i class="fas fa-arrow-right"></i></span>-->
<!--              </div>-->
            </div>
          </div>
        </template>
      <InfiniteLoading class="mt-4" @infinite="handleScroll">
            <span slot="spinner">
              <beat-loader :color="'#436aff'"></beat-loader>
            </span>
        <template v-slot:no-results>
          <NoResult msg="No More Result Found"></NoResult>
        </template>
      </InfiniteLoading>
      </template>
    <template v-else-if="accountsList.length ===  0">
      <NoResult msg="No Pocket Account Found"></NoResult>
    </template>
    <template v-else-if="posts.length ===  0">
      <NoResult msg="No Result Found"></NoResult>
    </template>


  </div>
</template>

<script>
/**
 * @param post.time_added          Information about the object.
 * @param post.is_article   Information about the object's members.
 */
import InfiniteLoading from 'vue-infinite-loading'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import NoResult from './NoResult'
export default {
  name: "Pocket",
  components: {
    InfiniteLoading,
    CstDropdown,
    CstDropdownItem,
    NoResult
  },
  props:{
    account: {
      type: String,
      default: '',
      required: true
    },
    accountsList: {
      type: Array,
      default: () => [],
      required: true,
    },
    posts: {
      type: Array,
      default: () => []
    },
    loader: {
      type: Boolean,
      default: false
    },
    sort: {
      type: String,
      default: 'Newest'
    },
    type: {
      type: String,
      default: 'All'
    },
  },
  methods:{
    dragstartHandler (ev, post) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData(
          'myData',
          JSON.stringify({
            id: ev.target.id,
            type: 'article',
            heading: post.title,
            media: post.image,
            p: post.title || '',
            link: post.given_url || ''
          })
      )
    },
    getPocketContentType (value) {
      if (value.is_article === '1') {
        return 'Article'
      } else if (value.videos) {
        return 'Video'
      } else {
        if (value.description) {
          return 'Article'
        }
        return 'Image'
      }
  },
    handleScroll ($state) {
      this.$emit('handle-change','scroll', $state)
    },
    handleAccount (value) {
      this.$emit('handle-change','account', value.username)
    },
    handleSort(value) {
      this.$emit('handle-change','sort', value)
    },
    handleType(value) {
      this.$emit('handle-change','type', value)
    },
  }
}
</script>

<style scoped>

</style>
