import InstagramMainLayout from '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/GridViewMainLayout.vue'
import TiktokMainLayout from '@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/GridViewMainLayout.vue'
import TiktokFeedsSidebarComponent
  from "@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/FeedsSidebarComponent.vue";
import TiktokFeedsComponent from "@src/modules/planner_v2/components/SocialMediaViewer/Tiktok/FeedsComponent.vue";
import FeedsSidebarComponent
  from "@src/modules/planner_v2/components/SocialMediaViewer/Instagram/FeedsSidebarComponent.vue";
import FeedsComponent from "@src/modules/planner_v2/components/SocialMediaViewer/Instagram/FeedsComponent.vue";
import {defineAsyncComponent, shallowRef} from "vue";
import ClipLoader from "@common/components/spinner/ClipLoader.vue";

const ReelsComponent = shallowRef(
    defineAsyncComponent({
      loader: () =>
          import(
              '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/ReelsComponent.vue'
              ),
      loadingComponent: ClipLoader,
    })
)

const ReelsSidebarComponent = shallowRef(
    defineAsyncComponent({
      loader: () =>
          import(
              '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/ReelsSidebarComponent.vue'
              ),
      loadingComponent: ClipLoader,
    })
)

const StoriesComponent = shallowRef(
    defineAsyncComponent({
      loader: () =>
          import(
              '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/StoriesComponent.vue'
              ),
      loadingComponent: ClipLoader,
    })
)

const StoriesSidebarComponent = shallowRef(
    defineAsyncComponent({
      loader: () =>
          import(
              '@src/modules/planner_v2/components/SocialMediaViewer/Instagram/StoriesSidebarComponent.vue'
              ),
      loadingComponent: ClipLoader,
    })
)

export const SUPPORTED_PLATFORMS = {
  INSTAGRAM: {
    route_suffix: 'instagram',
    component: InstagramMainLayout,
  },
  TIKTOK: {
    route_suffix: 'tiktok',
    component: TiktokMainLayout,
  },
}

/* INSTAGRAM GRID VUE */
export const REEL = 'Reel'
export const IMAGE = 'Image'
export const VIDEO = 'Video'
export const STORY = 'Story'

export const INSTAGRAM_TAB_NAMES = {
    FEED: 'Feed',
    REEL: 'Reels',
    STORY: 'Story',
}

export const INSTAGRAM_TABS = [
    {
        name: INSTAGRAM_TAB_NAMES.FEED,
        icon: 'instagram-feed-icon.svg',
        components: {
            main: FeedsComponent,
            sidebar: FeedsSidebarComponent,
        },
    },
    {
        name: INSTAGRAM_TAB_NAMES.REEL,
        icon: 'instagram-reels-icon.svg',
        components: {
            main: ReelsComponent,
            sidebar: ReelsSidebarComponent,
        },
    },
    {
        name: INSTAGRAM_TAB_NAMES.STORY,
        icon: 'instagram-stories-icon.svg',
        components: {
            main: StoriesComponent,
            sidebar: StoriesSidebarComponent,
        },
    },
]

/* TIKTOK GRID VIEW */
export const TIKTOK_TAB_NAMES = {
    FEED: 'Feed',
    SELF_ONLY: 'Self',
}

export const TIKTOK_TABS = [
  {
    name: 'Feed',
    icon: 'tiktok-feed-icon.svg',
    components: {
      main: TiktokFeedsComponent,
      sidebar: TiktokFeedsSidebarComponent,
    },
  },
  {
    name: 'Self',
    icon: 'tiktok-self-icon.svg',
    components: {
      main: TiktokFeedsComponent,
      sidebar: TiktokFeedsSidebarComponent,
    },
  },
]
