<template>
  <div class="fileUploadModel__upload_file_wrapper__right_block">
    <div class="flex-row text-right">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('insertFileModel')"
        >&times;</button
      >
    </div>
    <div class="fileUploadModel__upload_file_wrapper__right_block__right_inner">
      <div
        class="fileUploadModel__upload_file_wrapper__right_block__right_inner__media_preview_block"
      >
        <div class="input_field mt-0">
          <label for="">Find an image on Giphy</label>
          <div class="search_input with_icon_left">
            <div class="search_inner w-100 cs-input-grey">
              <!--                        searchImage('giphy',keyword)-->
              <input
                ref="searchArea"
                v-model="keyword"
                type="text"
                class=""
                placeholder="Search for something"
                @keyup.enter="searchGiphyImage"
              />
              <div class="bottom_btn_section mt-3">
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
                <i
                  v-tooltip.top="'Clear'"
                  class="clearsearch"
                  @click="clearSearch"
                  >×</i
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--          v-if="getImagesPosts.length > 0 || getImagesLoader"-->
      <div
        class="fileUploadModel__upload_file_wrapper__right_block__right_inner__recent_upload"
      >
        <ul>
          <clip-loader
            v-if="getImagesLoader"
            color="#4165ed"
            :size="'16px'"
          ></clip-loader>
          <template v-else>
            <template v-if="getImagesPosts.length">
              <!--                          insertImageFromLink(source,photo.images.original.url)-->
              <li
                v-for="(photo, photoIndex) in getImagesPosts"
                :key="`photo_${photoIndex}`"
                class="col-3"
                @click="processFileSelectionGiphy(photo.images.original.url)"
              >
                <div
                    v-if="
                  photo.images &&
                  photo.images.original &&
                  photo.images.original.url
                "
                  class="image"
                  :class="{
                    active: selectedItems.includes(photo.images.original.url),
                  }"
                >
                  <img :src="photo.images.original.url" alt="" />
                  <i
                    v-if="selectedItems.includes(photo.images.original.url)"
                    class="fa fa-check"
                  ></i>
                </div>
              </li>
            </template>

            <template v-if="!getImagesScroll && getImagesPosts.length === 0">
              <NoResultsFound></NoResultsFound>
            </template>

            <InfiniteLoading
              v-else-if="getImagesPosts.length > 0 && getImagesScroll"
              ref="infiniteLoading"
              @infinite="onInfiniteImagesGiphy"
            >
              <span slot="spinner">
                <beat-loader :color="'#436aff'"></beat-loader>
              </span>
            </InfiniteLoading>
          </template>
        </ul>

        <div v-if="selectedItems.length" class="bottom_btn_section">
          <button
            class="btn blue loader royal_blue mt-3"
            @click.prevent="insertGiphyImages(selectedItems)"
          >
            <span>Insert </span>
            <span>{{ selectedItems.length }}</span>
            <span> image(s)</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import NoResultsFound from '../../../../discovery/components/topics/NoResultsFound'

export default {
  components: {
    InfiniteLoading,
    NoResultsFound
  },
  props: ['type', 'source'],
  data () {
    return {
      size: '28px',
      color: '#0095f3',
      keyword: '',
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters([
      'getFileWidget',
      'getImagesLoader',
      'getImagesPosts',
      'getImagesScroll',
      'getImagesPage',
      'getBlogEditorSelector'
    ])
  },

  created () {
    this.resetImagesStates()
  },

  methods: {
    ...mapActions(['setImagesPage', 'resetImagesStates']),

    onInfiniteImagesGiphy ($state) {
      console.log(
        'On infinite images',
        this.getImagesScroll,
        this.getImagesPage
      )
      if (this.getImagesScroll) {
        this.setImagesPage(this.getImagesPage + 1)
        this.getMediaImages(this.getFileWidget.activeTab, this.keyword, $state)
      }
    },
    searchGiphyImage () {
      // clear selected images here
      this.selectedItems = []
      this.searchImage('giphy', this.keyword)
    },

    clearSearch () {
      this.selectedItems = []
      this.keyword = ''
      this.resetImagesStates()
    },

    insertGiphyImages (images) {
      console.debug('method:insertGiphyImages', images)
      this.insertImageFromLink(this.source, images)
    },
    processFileSelectionGiphy (image) {
      console.debug('Method::processFileSelectionGiphy', image)
      if (this.selectedItems.includes(image) === false) {
        this.selectedItems.push(image)
      } else {
        const index = this.selectedItems.indexOf(image)
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }
    }
  }
}
</script>
