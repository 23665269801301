<template>
  <div>
    <div class="filter_row d-flex align-items-center">
      <div
        v-tooltip.top="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="trending_icon icon_first cs-trending-chart"></i>
          <span
            v-if="getPocketFilter.account == ''"
            class="text filter_pocket_truncate"
            >select account</span
          >
          <span v-else class="text">{{ getPocketFilter.account }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <ul v-if="getPocket.length > 0" class="inner">
            <li v-for="account in getPocket" class="list_item">
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectPocketAccount(account.username)"
                >{{ account.username }}</a
              >
            </li>
          </ul>
          <ul v-else class="inner">
            <li class="no_text_li">
              <p>No Account found</p>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-tooltip.top="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="trending_icon icon_first cs-trending-chart"></i>
          <span class="text">{{ getPocketFilter.sort.name }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-right">
          <ul class="inner">
            <li
              class="list_item"
              @click.prevent="changePocketSort('Newest', 'newest')"
            >
              <a href="javascript:void(0)">Newest</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketSort('Oldest', 'oldest')"
            >
              <a href="javascript:void(0)">Oldest</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketSort('Title', 'title')"
            >
              <a href="javascript:void(0)">Title</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketSort('Site', 'site')"
            >
              <a href="javascript:void(0)">Site</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="filter_row d-flex align-items-center mb-0">
      <div
        v-tooltip.top="'Sort By'"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i class="trending_icon icon_first cs-trending-chart"></i>
          <span class="text">{{ getPocketFilter.type.name }}</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <ul class="inner">
            <li
              class="list_item"
              @click.prevent="changePocketType('All', 'all')"
            >
              <a href="javascript:void(0)">All</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketType('Article', 'article')"
            >
              <a href="javascript:void(0)">Article</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketType('Video', 'video')"
            >
              <a href="javascript:void(0)">Video</a>
            </li>

            <li
              class="list_item"
              @click.prevent="changePocketType('Image', 'image')"
            >
              <a href="javascript:void(0)">Image</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {},
  computed: {
    ...mapGetters(['getPocket', 'getPocketFilter'])
  }
}
</script>
