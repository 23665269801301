<template>
  <div class="analytics-main-container">
    <AnalyticsFilterBar
      type="linkedin"
      :style="{
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
      :updating="fetching_data"
      :get-accounts="getAccounts"
      :get-date="getDate"
      :get-previous-date="getPreviousDate"
    ></AnalyticsFilterBar>
    <div
      v-if="getPlatformAccounts('linkedin')?.length <= 0 && !fetching_data"
      class="analytics-main-errors"
    >
      <img
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
        alt=""
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect Linkedin Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getPlatformAccounts('linkedin')?.length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selected_account?.state === 'Added'">
        <AnalyticsLoading :name="selected_account.name"></AnalyticsLoading>
      </template>
      <template v-else>
        <LinkedInTabs v-if="!getFetchSocialStatus">
          <!-- overview tab -->
          <template v-if="selected_account" v-slot:overview>
            <OverviewTab
              :account="selected_account"
              :date="date"
              :previous_date="previous_date"
              :get-overview-loader="getOverviewLoader"
              :profile="selected_account?.type"
            ></OverviewTab>
          </template>

          <!-- posts tab -->
          <template v-slot:posts>
            <PostsTab
              :account="selected_account"
              :date="date"
              :previous_date="previous_date"
              :get-posts-loader="getPostsLoader"
            ></PostsTab>
          </template>
        </LinkedInTabs>
      </template>

      <div class="analytics-content-sync-box">
        <!--        <template v-if="selected_account.last_analytics_updated_at">-->
        <!--          <p class="mr-4">Updated {{ timesAgo(selected_account.last_analytics_updated_at) }}</p>-->
        <!--        </template>-->
        <!--        <div class="update_box d-flex ml-2" style="cursor: pointer"-->
        <!--             :class="{'disable_click':selected_account.state === 'Syncing'}" @click="reFetchData()">-->
        <!--          <template-->
        <!--              v-if="getSyncServiceStatus===true || selected_account.state === 'Syncing'">-->
        <!--            <i class="fas fa-sync"></i> <span>  Syncing...</span>-->
        <!--          </template>-->
        <!--          <template v-else>-->
        <!--              <i class="fas fa-sync"></i> <span>  Sync</span>-->
        <!--          </template>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import LinkedInTabs from '@src/modules/analytics/views/linkedin/components/LinkedInTabs'

// Tabs
import OverviewTab from '@src/modules/analytics/views/linkedin/tabs/OverviewTab'
import PostsTab from '@src/modules/analytics/views/linkedin/tabs/PostsTab'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { mapActions, mapGetters } from 'vuex'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { linkedin } from '@src/modules/integration/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import AnalyticsLoading from '../../components/common/AnalyticsLoading'

export default {
  name: 'AnalyticsOverview',

  components: {
    AnalyticsFilterBar,
    LinkedInTabs,
    OverviewTab,
    PostsTab,
    AnalyticsLoading,
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return {
      selected_account: [],
      date: '',
      previous_date: '',
      fetching_data: false,
    }
  },
  computed: {
    ...mapGetters(['getFetchSocialStatus', 'getSyncServiceStatus']),
  },
  watch: {
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
      }
    },
  },
  created() {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      this.resetState()

      const pusher = pusherAuthSocketAnalytics()
      this.analytics_channel = pusher.subscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
      if (this.selected_account?.linkedin_id) {
        this.bindSocketListeners()
      }
    })
  },
  mounted() {
    const pusher = pusherAuthSocketAnalytics()
    this.analytics_channel = pusher.subscribe(
      'analytics-channel-' + this.getActiveWorkspace._id
    )
    if (this.selected_account?.linkedin_id) {
      this.bindSocketListeners()
    }
  },
  beforeUnmount() {
    this.removeSocketListners(this.selected_account?.linkedin_id)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
        'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account?.linkedin_id
      )
      this.analytics_channel.unsubscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  methods: {
    ...mapActions(['syncUpdateService']),
    resetState() {
      this.fetching_data = false
      this.selected_account = []
      this.date = ''
      this.previous_date = ''
    },
    getAccounts(account) {
      this.selected_account = account
    },
    getDate(date) {
      this.date = date
    },
    getPreviousDate(date) {
      this.previous_date = date
    },
    getOverviewLoader(value) {
      this.fetching_data = value
    },
    getPostsLoader(value) {
      this.fetching_data = value
    },
    setLIState(state, checkFor, lastAnalyticsUpdatedAt) {
      const updatedItems = []
      for (const item in this.getLinkedinAccounts.items) {
        const update = this.getLinkedinAccounts.items[item]
        if (update.linkedin_id === checkFor) {
          update.state = state
          // eslint-disable-next-line camelcase
          update.last_analytics_updated_at = lastAnalyticsUpdatedAt
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'linkedin',
        all_item_setter: linkedin.SET_ALL_ACCOUNTS,
        item_setter: linkedin.SET_ACCOUNTS,
      })
    },
    removeSocketListners(value) {
      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners() {
      const event =
        'syncing-' +
        this.getActiveWorkspace._id +
        '-' +
        this.selected_account?.linkedin_id
      this.analytics_channel.bind(event, (data) => {
        this.setLIState(
          data.state,
          data.account,
          data.last_analytics_updated_at
        )
      })
    },
  },
}
</script>
