<script setup>
import OverviewSection from '@src/modules/analytics/views/twitter/components/OverviewSection'
import PostsSection from '@src/modules/analytics/views/twitter/components/PostsSection'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import {EventBus} from '@common/lib/event-bus'
import {pusherAuthSocketAnalytics} from '@common/lib/pusher'
import {onBeforeUnmount, ref, watch, onMounted, onBeforeMount} from 'vue'
import {useStore} from '@state/base'
import {twitter} from '@src/modules/integration/store/states/mutation-types'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import {onBeforeRouteUpdate, useRouter} from 'vue-router'
import {useComposerHelper} from '@modules/composer_v2/composables/useComposerHelper'

const {getters, dispatch} = useStore()
const router = useRouter()
const {analyticsEnabledTwitterAccounts} = useComposerHelper()

const twitterAccounts = ref([])
const selectedAccount = ref(null)
const dateFilter = ref('')
const previousDate = ref('')
const fetchingData = ref(false)
const analyticsChannel = ref('')
const mainComponentRef = ref(null)
const {postsLimit, fetchTwitterJobs} = useTwitterAnalytics()

watch(selectedAccount, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    setTimeout(() => {
      mainComponentRef.value?.scrollIntoView({behavior: 'smooth'})
    }, 0)
    removeSocketListeners(oldVal)
    bindSocketListeners()
  }
})

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.debug('workspace-changed')
    resetState()

    const pusher = pusherAuthSocketAnalytics()
    analyticsChannel.value = pusher.subscribe(
        'analytics-channel-' + getters.getActiveWorkspace._id
    )
    if (selectedAccount.value?.platform_identifier) {
      bindSocketListeners()
    }
  })
})

onMounted(async () => {
  const pusher = pusherAuthSocketAnalytics()
  analyticsChannel.value = pusher.subscribe(
      'analytics-channel-' + getters.getActiveWorkspace._id
  )
  if (selectedAccount.value?.platform_identifier) {
    bindSocketListeners()
  }
  await fetchTwitterJobs()
  EventBus.$emit('set-top-posts-limit', postsLimit.value)
})

onBeforeUnmount(() => {
  removeSocketListeners(selectedAccount.value?.platform_identifier)
  if (analyticsChannel.value) {
    analyticsChannel.value.unbind(
        'syncing-' +
        getters.getActiveWorkspace._id +
        '-' +
        selectedAccount.value?.platform_identifier
    )
    analyticsChannel.value.unsubscribe(
        'analytics-channel-' + getters.getActiveWorkspace._id
    )
  }
})

onBeforeRouteUpdate(async (to, from) => {
  // only fetch the user if the id changed as maybe only the query or the hash changed
  if (!analyticsEnabledTwitterAccounts.value?.length) {
    await router.push({name: 'analytics_overview_v3'})
  }
})

const resetState = () => {
  fetchingData.value = false
  twitterAccounts.value = []
  selectedAccount.value = []
  dateFilter.value = ''
  previousDate.value = ''
  analyticsChannel.value = ''
}

const getAccounts = (account) => {
  selectedAccount.value = account
}

const getDate = (date) => {
  dateFilter.value = date
}

const getPreviousDate = (date) => {
  previousDate.value = date
}

const removeSocketListeners = (value) => {
  const event = 'syncing-' + getters.getActiveWorkspace._id + '-' + value
  if (analyticsChannel.value) {
    analyticsChannel.value?.unbind(event)
  }
}

const bindSocketListeners = () => {
  const event =
      'syncing-' +
      getters.getActiveWorkspace._id +
      '-' +
      selectedAccount.value?.platform_identifier
  console.log(event)
  analyticsChannel.value.bind(event, (data) => {
    console.log('message data --> ', data)
    setTwitterState(data.state, data.account, data.last_analytics_updated_at)
  })
}

const setTwitterState = (state, checkFor, lastAnalyticsUpdatedAt) => {
  const updatedItems = []
  for (const item in getters.getTwitterAccounts) {
    const update = getters.getTwitterAccounts[item]
    if (update.platform_identifier === checkFor) {
      update.state = state
      // eslint-disable-next-line camelcase
      update.last_analytics_updated_at = lastAnalyticsUpdatedAt
    }
    updatedItems.push(update)
  }
  dispatch('setPlatforms', {
    items: updatedItems,
    type: 'twitter',
    all_item_setter: twitter.SET_ALL_ACCOUNTS,
    item_setter: twitter.SET_ACCOUNTS,
  })
}
</script>

<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <AnalyticsFilterBar
        :get-accounts="getAccounts"
        :get-date="getDate"
        :get-previous-date="getPreviousDate"
        :style="{
        // eslint-disable-next-line vue/camelcase
        cursor: fetchingData ? 'progress' : '',
        pointerEvents: fetchingData ? 'none' : '',
      }"
        :updating="fetchingData"
        type="twitter"
    />
    <div
        v-if="getters.getTwitterAccounts?.items?.length <= 0 && !fetchingData"
        class="analytics-main-errors"
    >
      <img
          alt=""
          draggable="false"
          src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p v-if="getters.getFetchSocialStatus">
        Retrieving Data! Please Wait...
      </p>
      <p v-else>
        No Account(s) Connected
        <small>Connect a social account </small>
        <router-link
            class="btn btn-studio-theme-space btn-size-small"
            :to="{ name: 'social' }"
        >Connect X (Twitter) Account
        </router-link>
      </p>
    </div>

    <div
        v-else-if="getters?.getTwitterAccounts?.items?.length > 0"
        class="analytics-content-wrapper"
    >
      <template v-if="selectedAccount?.state === 'Added'">
        <AnalyticsLoading :name="selectedAccount.name"/>
      </template>
      <template v-else>
        <TabsComponent
            v-if="!getters.getFetchSocialStatus"
            :tabs="['#overview', '#posts']"
            type="twitter"
        >
          <!-- overview tab -->
          <template v-if="selectedAccount" v-slot:overview>
            <OverviewSection/>
          </template>

          <!-- posts tab -->
          <template v-slot:posts>
            <PostsSection/>
          </template>
        </TabsComponent>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
          alt=""
          draggable="false"
          src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>
