<template>
  <div
    class="bg-white p-5 rounded-md"
    :class="{
      'color-border': !isModal,
      'cst-editor': type !== 'performanceReviewTable' && !isModal,
    }"
  >
    <!--    component header-->
    <div class="mb-6 w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <CstDropdown
          v-if="selectedType"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          button-classes="flex !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !w-[7.5rem] !px-0"
          :disabled="isLoading"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">{{
              selectedType + ' Post'
            }}</p>
          </template>
          <template v-slot>
            <template
              v-for="(postingType, index) in allAvailableTypes"
              :key="`activity_type_${index}`"
            >
              <CstDropdownItem @click="changeChartType(postingType)">
                <p class="text-gray-900 text-sm">{{ postingType + ' Post' }}</p>
              </CstDropdownItem>
            </template>
          </template>
        </CstDropdown>
        <h2 class="text-base font-weight-500 select-none capitalize">{{
          title
        }}</h2>
        <v-menu
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{getComponentInfoTooltip(type, platformName, selectedType)}}
            </p>
          </template>
        </v-menu>
      </div>

      <div class="flex justify-center items-center">
        <div
          class="flex items-center gap-2"
          :class="type === 'performanceReviewTable' ? 'mr-20' : 'mr-6'"
        >
          <span
            class="
              rounded-full
              py-0.5
              px-2
              select-none
              bg-[#EEF9F3]
              w-[4.25rem]
            "
          >
            Highest
          </span>
          <span
            class="rounded-full py-0.5 px-2 select-none bg-[#FFECF0] w-[4rem]"
          >
            Lowest
          </span>
          <span
            v-if="type === 'performanceReviewTable'"
            class="rounded-full py-0.5 select-none bg-transparent"
          >
            Gain
            <i class="fa fa-arrow-up text-[#56C288] ml-0.5"></i>
          </span>
          <span
            v-if="type === 'performanceReviewTable'"
            class="rounded-full py-0.5 select-none bg-transparent"
          >
            Loss
            <i class="fa fa-arrow-down text-red-500 ml-0.5"></i>
          </span>
        </div>
        <div
          v-if="!isModal && data.length"
          v-tooltip="{
            content: 'Maximize',
            theme: 'light',
          }"
          class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
          @click="$bvModal.show(`competitors-table-${type}-modal`)"
        >
          <img
            src="@src/assets/img/full_screen.svg"
            alt="fullScreen"
            class="w-5 h-5"
          />
        </div>
        <div
          v-if="isModal"
          v-tooltip="{
            content: 'Minimize',
            theme: 'light',
          }"
          class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
          @click="$bvModal.hide(`competitors-table-${type}-modal`)"
        >
          <i class="fa fa-times fa-lg"></i>
        </div>
      </div>
    </div>

<!--    body -->
    <div v-if="isLoading">
      <SkeletonBox class="!w-full !h-[400px]"></SkeletonBox>
    </div>
    <div v-else-if="isAllDataFetched" class="analytics-no-data-found">
      <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
      <p>Data is being fetched.</p>
    </div>
    <div v-else-if="!data.length" class="analytics-no-data-found">
      <img
        src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
        alt=""
      />
      <p>No data found.</p>
    </div>
    <div
      v-else
      class="flex border !border-[#E9EFF6]"
      :class="
        type === 'performanceReviewTable'
          ? 'tooltip-handler'
          : 'overflow-x-auto'
      "
    >
      <table class="w-full text-sm text-justify text-gray-900 rounded-lg">
        <thead class="bg-gray-200 rounded-lg text-sm text-gray-900">
          <tr>
            <th v-if="showInfoCell" class="p-3 select-none group"
              ><p class="font-weight-500 font-0-95rem ml-1">Competitors</p></th
            >
            <th
              v-for="(listItem, key) in props.headers"
              :key="`${key}_header_${type}`"
              class="p-3 select-none group"
              :class="{
                'cursor-pointer hover:bg-gray-300 min-w-[9rem]':
                  listItem !== 'image',
              }"
              @click="sortColumn(listItem, key)"
            >
              <v-menu
                :popper-triggers="['hover']"
                placement="top"
                popper-class="first-comment__info-popover"
                class="first-comment__base-width"
                :delay="300"
              >
                <div class="w-full flex justify-between items-center">
                  <p
                    class="font-weight-500 text-left font-0-95rem"
                    v-html="getHeaderTitles(listItem)"
                  ></p>
                  <i
                    v-if="isSortable(key, listItem)"
                    class="fa text-gray-700 group-hover:text-gray-900"
                    :class="state.isSorted ? 'fa-sort-desc' : 'fa-sort-asc'"
                  ></i>
                </div>
                <template v-slot:popper>
                  <p
                    class="text-gray-900"
                    v-html="getTableHeaderTooltips(listItem, platformName, type, selectedType)"
                  ></p>
                </template>
              </v-menu>
            </th>
          </tr>
        </thead>

        <component
          :is="typeToComponent[type]"
          :key="getTableKey(type)"
          :is-sorted="state.isSorted"
          :table-data="state.tableData"
          :headers="props.headers"
          :platform-name="platformName"
          :followers-growth-data="
            type === 'performanceReviewTable' ? followersGrowthData : null
          "
          :single-hashtag-data="
            type === 'mostEngagedHashtagsTable' ? singleHashtagData : null
          "
          :single-hashtag-header="
            type === 'mostEngagedHashtagsTable' ? singleHashtagHeader : null
          "
          v-on="$listeners"
        />
      </table>
    </div>
    <b-modal
      :id="`competitors-table-${type}-modal`"
      centered
      hide-footer
      hide-header
      body-class="!overflow-y-auto h-screen"
      content-class="!mt-2 !rounded-none"
      dialog-class="cst-modal"
      @hide="commonMethods.toggleWidgets(false)"
      @shown="commonMethods.toggleWidgets(true)"
    >
      <CompetitorsTable
        :title="title"
        :data="data"
        :type="type"
        :headers="headers"
        :is-modal="true"
        :is-all-data-fetched="isAllDataFetched"
        :is-loading="isLoading"
        :max-values="extremeValues ? extremeValues.maxValues : []"
        :min-values="extremeValues ? extremeValues.minValues : []"
        :followers-growth-data="followersGrowthData"
        :selected-type="selectedType"
        :all-available-types="allAvailableTypes"
        :show-info-cell="showInfoCell"
        :index-to-sort="indexToSort"
        :single-hashtag-data="singleHashtagData"
        :single-hashtag-header="singleHashtagHeader"
        :platform-name="platformName"
        v-on="$listeners"
      />
    </b-modal>
  </div>
</template>

<script setup>
// libraries
import { reactive, computed, watch } from 'vue'

// components
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import { commonMethods } from '@common/store/common-methods'
import PostingActivityTableHelper from '@src/modules/analytics_v3/components/PostingActivityTableHelper.vue'
import HashtagTableHelper from '@src/modules/analytics_v3/components/HashtagTableHelper.vue'
import ComparativeTableHelper from '@src/modules/analytics_v3/components/ComparativeTableHelper.vue'
import BioAnalysisTableHelper from '@src/modules/analytics_v3/components/BioAnalysisTableHelper.vue'

// composable
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

const props = defineProps({
  showInfoCell: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "Competitors' Performance Review",
  },
  data: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: 'review',
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  maxValues: {
    type: Object,
    default: () => {},
  },
  minValues: {
    type: Object,
    default: () => {},
  },
  indexToSort: {
    type: Number,
    default: 0,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  followersGrowthData: {
    type: Object,
    default: () => {},
  },
  selectedType: {
    type: String,
    default: '',
  },
  allAvailableTypes: {
    type: Array,
    default: () => [],
  },
  singleHashtagData: {
    type: Object,
    default: () => {},
  },
  singleHashtagHeader: {
    type: Array,
    default: () => [],
  },
  colors: {
    type: Array,
    default: () => [],
  },
  isAllDataFetched: {
    type: Boolean,
    default: false,
  },
  platformName: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['chart-type'])

const { getMinMaxValues, getHeaderTitles, getComponentInfoTooltip, getTableHeaderTooltips } = useCompetitorHelper()

const state = reactive({
  tableData: computed(() => props.data),
  singleHashDataArray: [],
  isSorted: true,
  sortedHeaderKey: props.indexToSort,
  isLoading: false,
  expandedRowIndex: [],
})

// dynamic component handling
const typeToComponent = {
  performanceReviewTable: ComparativeTableHelper,
  postingActivityTableByType: PostingActivityTableHelper,
  mostEngagedHashtagsTable: HashtagTableHelper,
  bioAnalysisTableData: BioAnalysisTableHelper,
}

watch(
  () => props.singleHashtagData,
  (newData) => {
    state.isLoading = false
    state.singleHashDataArray.push(newData)
  }
)

/**
 * @description checks whether to show the sorting icon or not.
 * @param key
 * @param headerName
 * @returns {boolean}
 */
const isSortable = (key, headerName) => {
  const nonSortableItems = ['tag', 'companies_using', 'biography']
  return !nonSortableItems.includes(headerName) && key === state.sortedHeaderKey
}

/**
 * @description checks if the min and max values are passed through props, if not, calls getMinMaxValues method to get min and max values of all columns.
 * @type {ComputedRef<unknown>}
 */
const extremeValues = computed(() => {
  if (props.minValues?.engagementRate && props.maxValues?.engagementRate) {
    const minValues = props.minValues
    const maxValues = props.maxValues
    return { minValues, maxValues }
  } else {
    return getMinMaxValues(props.data, props.headers)
  }
})

/**
 * @description called when any thead is clicked in order to sort the respective column
 * @param header
 * @param index
 */
const sortColumn = (header, index) => {
  if (state.sortedHeaderKey === index && state.isSorted) {
    state.tableData?.sort((a, b) => b[header] - a[header])
    state.isSorted = false
    return
  }
  state.sortedHeaderKey = index
  state.isSorted = true
  state.tableData?.sort((a, b) => a[header] - b[header])
}

/**
 * @description emits the selected type to change chart.
 * @param value
 */
const changeChartType = (value) => {
  let type = ''
  switch (value) {
    case 'Image':
      type = 'IMAGE'
      break
    case 'Video':
      type = 'VIDEO'
      break
    case 'Reel':
      type = 'VIDEO REEL'
      break
    case 'Carousel':
      type = 'CAROUSEL ALBUM'
      break
  }
  emit('chart-type', type)
}

const getTableKey = (type) => {
  switch (type) {
    case 'performanceReviewTable':
      return `${state.tableData[0].business_account_id}_comparativeTable`
    case 'postingActivityTableByType':
      return `${state.tableData[0].businessAccountId}_postingActivityTableByType`
    case 'mostEngagedHashtagsTable':
      return `${state.tableData[0].tag}_mostEngagedHashtagsTable`
    case 'bioAnalysisTableData':
      return `${state.tableData[0].business_account_id}_bioAnalysis`
    default:
      return ''
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1417px) {
  .tooltip-handler {
    overflow-x: auto;
  }
}
</style>
