<template>
  <ConfirmBox
    id="removeCsvPlan"
    :call-back-action="removeCsvPlan"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Plan</h2>
    </template>

    <template v-slot:footer>
      <p class="text-center">This action cannot be undone.</p>
    </template>
  </ConfirmBox>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'
export default {
  components: {
    ConfirmBox
  },

  data () {
    return {}
  },
  computed: {
    ...mapGetters([])
  },
  created () {},
  mounted () {},

  methods: {
    ...mapActions(['cvsPlanOperations']),
    removeCsvPlan (confirmation, data) {
      console.debug('Method:removeCsvPlan', confirmation, data)
      if (confirmation.status) {
        this.cvsPlanOperations(data)
      } else {
        // eslint-disable-next-line no-undef
        $('#' + confirmation.type).modal('hide')
      }
    }
  }
}
</script>
