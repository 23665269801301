<template>
  <div>
    <b-modal
      :id="modalId"
      modal-class="normal_modal bv-modal-upgrade-plan !overflow-hidden"
      body-class="max-w-[98vw] overflow-auto max-h-[98vh]"
      centered
      hide-footer
      hide-header
    >
      <div class="modal_head d-flex align-items-center">
        <h2>Upgrade your subscription plan to get more features.</h2>
        <button
          type="button"
          class="modal_head__close"
          data-dismiss="modal"
          @click="$bvModal.hide(modalId)"
          >&times;
        </button>
      </div>
      <div class="modal_body basic_form">
        <UpgradePlanComponent @showPlanConfirmation="onShowPlanConfirmation" />
      </div>
    </b-modal>

    <UpgradePlanConfirmation
      :subscription="subscription"
      :billing_period="billing_period"
      :is-downgrade="isDowngrade"
    ></UpgradePlanConfirmation>
  </div>
</template>

<script>
import UpgradePlanComponent from './UpgradePlanComponent'
import UpgradePlanConfirmation from './UpgradePlanConfirmation'

export default {
  name: 'UpgradePlanDialog',
  components: {
    UpgradePlanComponent,
    UpgradePlanConfirmation,
  },
  data() {
    return {
      modalId: 'upgrade-plan-dialog',
      subscription: '',
      billing_period: '',
      isDowngrade: false,
    }
  },
  mounted() {
    // EventBus.$on('limited-upgrade-plan-dialog',(level = 0)=>{
    //        console.log('reached->level',level)
    //       this.subscription_level = level
    //        this.$bvModal.show('upgrade-plan-dialog')
    // })
  },
  methods: {
    // eslint-disable-next-line camelcase
    onShowPlanConfirmation(subscription, billing_period, isDowngrade = false) {
      console.debug('Debuged', subscription, billing_period)
      this.isDowngrade = isDowngrade
      this.subscription = subscription
      // eslint-disable-next-line camelcase
      this.billing_period = billing_period
    },
  },
}
</script>
