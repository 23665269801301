<template>
  <div class="min-h-full flex flex-col bg-cs-light-gray">
    <div class="flex items-center px-6 h-14">
      <span class="font-bold text-base">Labels </span>
      <div v-if="!isOpen" v-tooltip.right="{ content: 'Add New Label',distance: 14 }" class="mx-4 cursor-pointer w-7 h-7 bg-cs-primary text-white rounded-lg font-bold flex items-center justify-center" @click="isOpen = !isOpen"><i class="fal fa-plus"></i></div>
    </div>
    <hr class="m-0 hr-color">
    <div class="py-3.5 px-3.5 flex-grow flex-shrink flex-basis-auto overflow-y-auto h-0 mb-24">

           <div v-if="isOpen" id="editor" class="my-2">
          <input
v-model="newLabelName"
                 type="text"
                 placeholder="Enter label name..."
                 class="w-full my-1 mx-0 px-3 py-2.5 text-sm rounded-lg border border-gray-300 shadow-xs font-normal text-gray-800 hover:bg-gray-50 focus:outline-none"/>
          <span v-if="validations.name" class="input-error">{{
              validations.nameError
            }}</span>

          <div class="cst_label_box p-2 border rounded-md mt-1">
            <span class="text-sm font-bold text-gray-900 block py-2 ml-2">Choose Color</span>
            <template v-for="(value, key) in fetchColor(null, null)">
              <div class="box_item relative w-10 h-10 float-left p-1 rounded-md">
                <input
                    :id="key"
                    v-model="newLabelColor"
                    :value="key"
                    type="radio"
                    class="w-8 h-8 cursor-pointer opacity-0 absolute"
                    name="color_select"
                />
                <label :for="key" :class="value + ' item_inner'" class="rounded-md">
                  <i class="cs-checked check_icon_planner d-flex height align-items-center justify-content-center"></i>
                </label>
              </div>
            </template>
          </div>
          <span v-if="validations.color" class="input-error">{{
              validations.colorError
            }}</span>
             <div class="flex items-center justify-end">
               <button
class="block mt-3 text-sm  focus:outline-none rounded-md text-black  border-0 px-3 py-2 mr-2"
                       @click="resetFields">Cancel
               </button>
               <button
                   class="block mt-3 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2"
                   @click.prevent="createLabel">
                 <span v-if="getNewLabel._id">Save</span>
                 <span v-else>Create New Label</span>
                 <clip-loader
                     v-if="getSaveLabelLoader"
                     :color="'#ffffff'"
                     :size="'16px'"
                 ></clip-loader>
               </button>
             </div>

        </div>
      <template v-if="getOrderedLabels && !isOpen">
        <template v-for="(label,index) in getOrderedLabels" :key="index">
          <div class="label_box relative flex items-center rounded-md px-2 py-2 hover:bg-cs-foggray cursor-pointer">
            <label class="cst-label-checkbox flex flex-row-reverse w-full justify-between items-center">
              <input
                  :id="'composer' + label._id"
                  v-model="planLabels"
                  class="opacity-0 absolute h-8 w-8 cursor-pointer"
                  :value="label._id"
                  type="checkbox"
              />
              <span  class="check-icon relative cursor-pointer bg-white rounded-md w-5 h-5 mt-1 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500">
                <svg class="fill-current hidden w-3 h-3 text-blue-500 pointer-events-none"  viewBox="0 0 17 12" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(-9 -11)" fill="#0088cc" fill-rule="nonzero">
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </span>
              <span class="flex w-full items-center relative">
                 <span class="w-7 h-7 rounded-lg mr-3" :class="fetchColor(label.color)"></span>
                 <span :id="label._id" class="block font-normal text-sm text-gray-900">
                         {{label.name}}
                 </span>
            </span>
            </label>
            <div class="edit_options absolute right-8 hidden flex items-center">
              <div :id="label._id" class="block  cursor-pointer p-1 mx-0.5 font-semibold text-sm text-[#2f8ae0]" @click.prevent="editLabel(label)">
                <i v-tooltip.top="'Edit'" class="edit_icon icon-edit-cs"></i>
              </div>
              <div :id="label._id" class="block  cursor-pointer p-1 mx-0.5 font-semibold text-sm text-[#db5353]" style="color:#db5353" @click.prevent="confirmAndDeleteLabel(label)">
                <i v-tooltip.top="'Remove'" class="remove_icon icon-delete-cs"></i>
              </div>
            </div>
          </div>
          <div v-if="getOrderedLabels.length < 1" class="text-center">Create a label and it will be displayed here.</div>
        </template>
      </template>


    </div>
    <CstButton
        variant="primary"
        class="!mx-8 mb-10 mt-auto"
        @click="$emit('redirect-to', 'preview')"
    >
      <p class="leading-4 font-medium text-light-cs">Continue</p>
    </CstButton>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstInputFields from '@ui/Input/CstInputFields'
import orderBy from 'lodash.orderby'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {swalAttributes} from "@common/constants/common-attributes";
import CstButton from '@ui/Button/CstButton'
export default {
  name: "Labels",
  components: {
    CstDropdown,
    CstDropdownItem,
    CstInputFields,
    CstButton
  },
  props:{
    labels: {
      type: Array,
      default:()=>[],
      required: false
    },
  },
  emits: ['redirect-to'],
  data() {
    return {
      isOpen:false,
      newLabelName:'',
      newLabelColor:'',
      editorVisibility:false,
      planLabels:this.labels,
      validations: {
        name: false,
        nameError:'Please enter a label name',
        color:false,
        colorError:'Please select a label color'
      },
    }
  },
  watch:{
    newLabelName() {
      this.validations.name = false
    },
    newLabelColor() {
      this.validations.color = false
    },
    planLabels(val){
      this.$emit('update-labels', val)
    },
  },
  computed:{
    ...mapGetters([
      'getLabels',
      'getNewLabel',
      'getSaveLabelLoader'
    ]),
    getOrderedLabels () {
      return this.getLabels.length > 0
          ? orderBy(this.getLabels, function (item) {
            return [item.name.toLowerCase()]
          })
          : []
    },
  },
  methods:{
    ...mapActions([
      'saveLabel',
      'deleteLabel',
    ]),
    ...mapMutations(['SET_NEW_LABEL']),
    createLabel() {
       if (!this.newLabelName || this.newLabelName.trim().length === 0) {
         this.validations.name = true
         return
       }
       if (!this.newLabelColor || this.newLabelColor.trim().length === 0) {
         this.validations.color = true
         return
       }
       if (this.newLabelName && this.newLabelColor) {
         // using states here to set the new label
         this.getNewLabel.name = this.newLabelName
         this.getNewLabel.color = this.newLabelColor
         this.saveLabel()
         this.isOpen = false
         this.newLabelName = this.newLabelColor = ''
       }
     },
    editLabel (label) {
      this.SET_NEW_LABEL(JSON.parse(JSON.stringify(label)))
      // setting local values
      this.getNewLabel._id = label._id
      this.newLabelName = this.getNewLabel.name
      this.newLabelColor = this.getNewLabel.color
      this.editorVisibility = true
      this.isOpen = true
    },
    async confirmAndDeleteLabel(label) {
      // asking for confirmation
      const res = await this.$bvModal
          .msgBoxConfirm('Are you sure you want to delete this label?', {
            title: 'Delete Label',
            ...swalAttributes()
          })
          .then((res) => {
            return res
          })
          .catch((err) => {
            console.error('Delete label error', err)
            return false
          })

      if (res)
        this.deleteLabel(label)
    },

    resetFields: function () {
      this.isOpen = false
      this.newLabelName = ''
      this.newLabelColor = ''
      this.SET_NEW_LABEL(null)
    },
  }
}
</script>

<style lang="scss" scoped>
.cst_label_box {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  input[type='radio']:checked + .item_inner {
    .check_icon_planner {
      display: flex !important;
    }
  }
  .box_item {
    .item_inner {
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: #000;
      .check_icon_planner {
        display: none!important;
        color: #fff;
      }
    }
  }
}
.cst-label-checkbox {
  &
  input + .check-icon {
    background-color: transparent;
    border: 1.5px solid #99a3a982;
    border-radius: 0.3rem;
    width: 16px;
    height: 16px;
  }
  input:checked + .check-icon {
    @apply border-blue-300;
  }
  input:checked + .check-icon svg {
    @apply block;
  }
}
.label_box{
  &:hover{
    .edit_options{
      display:flex;
    }
  }
}
.label_list {
  .list_item {
    position: relative;
    margin-bottom: 0.625rem;
    &:last-child {
      margin-bottom: 0;
    }

      -webkit-transition: all 0.2s linear !important;
      -moz-transition: all 0.2s linear !important;
      -o-transition: all 0.2s linear !important;
      transition: all 0.2s linear !important;

    &:hover {
      padding-right: 70px;
      .label_icon {
        visibility: visible;
        opacity: 1;
      }
    }
    .label {
      position: relative;
      display: block;
      padding: 8px 15px;
      padding-right: 30px;
      margin-bottom: 0;
      cursor: pointer;
      border-radius:20px;
      span {
        display: block;
        font-size: 0.875rem;
        color: #fff;
      }
      .check_icon_planner {
        position: absolute;
        top: 50%;
        right: 15px;
        display: none;
        color: #fff;
        transform: translateY(-50%);
      }
    }
    .label_icon {
      -webkit-transition: all 0.2s linear !important;
      -moz-transition: all 0.2s linear !important;
      -o-transition: all 0.2s linear !important;
      transition: all 0.2s linear !important;

      position: absolute;
      top: 50%;
      right: 15px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-50%);
      i {
        font-size: 0.875rem;
        color: #2f8ae0;
        cursor: pointer;
      }

      .remove_icon {
        margin-left: 0.625rem;
        color: #db5353;
      }
    }
  }

  .list_item.green {
    .label {
      background-color: #69c366;
    }
  }
  .list_item.blue {
    .label {
      background-color: #5cc6ff;
    }
  }
  .list_item.red {
    .label {
      background-color: #ff6462;
    }
  }
  .list_item.red2 {
    .label {
      background-color: #ff5f31;
    }
  }
  .list_item.purple {
    .label {
      background-color: #864de9;
    }
  }

  .list_item.orange {
    .label {
      background-color: #e7af4d;
    }
  }
  .list_item.light_red {
    .label {
      background-color: #fea28b;
    }
  }
  .list_item.pink {
    .label {
      background-color: #fa6ab6;
    }
  }
  .list_item.dark_blue {
    .label {
      background-color: #0095f3;
    }
  }
  .list_item.dark_pink {
    .label {
      background-color: #dc70ea;
    }
  }
}
</style>
