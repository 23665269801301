<template>
  <div>
    <!-- Summary Section -->
    <AnalyticsSummaryBlock
      heading="Instagram Page Performance Summary"
      subtitle="View your key profile performance metrics from the reporting period."
    >
      <template v-if="summary_loader">
        <div class="row">
          <div
            v-for="i in 8"
            :key="i"
            class="col-6 col-sm-3"
            :class="{ 'mb-4': i < 4 }"
          >
            <SkeletonBox :width="'100%'" height="92px" radius="10px" />
          </div>
        </div>
      </template>
      <div v-else class="row">
        <template v-for="(value, key, i) in summary_values" :key="key">
          <div
            class="col-6 col-sm-3 summary-card-wrapper"
            :class="{ 'mb-4': i < 4 }"
          >
              <SummaryCard
                :title="value"
                :total="summary.current[key] ? summary.current[key] : 0"
                :reach="Math.abs(getReach(key, summary)) + '%'"
                :reach-up="getReachUp(key, summary)"
              ></SummaryCard>
          </div>
        </template>
      </div>
    </AnalyticsSummaryBlock>

    <!-- Audience Growth Section -->
    <div class="d-flex !mb-7">
      <div class="col-9 p-0">
        <section
          class="mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Instagram Audience Growth </h2>
            <p>See how your audience grew during the reporting period.</p>
          </div>
          <template v-if="audience_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="audience && audience.audience_growth && audience.audience_growth.show_data > 0"
              :categories="audience.audience_growth.buckets"
              :series="getAudienceGrowthSeries(audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="@modules/analytics/assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Audience Growth Rollup</h2>
          <template v-if="audience_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-for="(value, key) in audience_rollup_values" :key="key">
              <SummaryCard
                :title="value"
                :total="audience.audience_growth_rollup.current[key]"
                :reach="
                  Math.abs(getReach(key, audience.audience_growth_rollup)) + '%'
                "
                :reach-up="getReachUp(key, audience.audience_growth_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!-- Publishing Behavior Section -->
    <div class="d-flex">
      <div class="col-9 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Instagram Publishing Behavior </h2>
            <p
              >View the different types of posts you published during the
              selected time period.</p
            >
          </div>
          <template v-if="publishing_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <ColumnCrossHairChart
              v-if="
                publishing_behaviour && publishing_behaviour.publishing_behaviour &&
                publishing_behaviour.publishing_behaviour.show_data > 0
              "
              :series="getPublishingBehaviourSeries(publishing_behaviour)"
              :categories="publishing_behaviour.publishing_behaviour.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section>
          <h2>Publishing Behavior by Content Type</h2>
          <template v-if="publishing_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template
              v-for="(value, key) in publishing_behaviour_rollup_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="
                  publishing_behaviour.publishing_behaviour_rollup.current[key]
                "
                :reach="
                  Math.abs(
                    getReach(
                      key,
                      publishing_behaviour.publishing_behaviour_rollup,
                    ),
                  ) + '%'
                "
                :reach-up="
                  getReachUp(
                    key,
                    publishing_behaviour.publishing_behaviour_rollup,
                  )
                "
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!-- instagram Top Posts -->
    <section style="margin-top: 3.2rem">
      <h2>Instagram Top Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post’s lifetime performance.</p
      >
      <template v-if="top_post_loader">
        <div class="row">
          <template v-for="i in 3" :key="i">
            <div class="col-4">
              <div class="analytics-post-card">
                <div class="analytics-post-card__header">
                  <div class="analytics-post-card__header-left">
                    <div class="analytics-post-card__header-img">
                      <SkeletonBox
                        height="2.8rem"
                        width="2.8rem"
                        radius="50%"
                      />
                    </div>
                    <div class="analytics-post-card__header-detail">
                      <div
                        class="d-flex"
                        style="flex-direction: column; margin-left: 10px"
                      >
                        <div class="mb-1">
                          <SkeletonBox width="100px" radius=".2rem" />
                        </div>
                        <SkeletonBox radius=".2rem" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="analytics-post-card__header-right"
                    style="filter: grayscale(100%); opacity: 0.6"
                  >
                    <!-- Dynamic Icon bases on props -->
                    <i class="icon-instagram_icon"></i>
                  </div>
                </div>

                <div class="analytics-post-card__content">
                  <div class="mb-1">
                    <SkeletonBox width="100%" radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox width="100%" height="150px" radius=".2rem" />
                  </div>
                </div>

                <ul class="analytics-post-card__content-ele">
                  <li v-for="i in 4" :key="i">
                    <SkeletonBox width="40%" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify_center analytics-post-card-row">
          <template v-if="top_posts.length <= 0">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p
                >You do not have any posts published in the selected time
                period.</p
              >
            </div>
          </template>
          <template v-for="i in 3" v-else :key="i">
            <div :class="top_posts.length >= 3 ? 'col-4' : 'col-6'">
              <template v-if="top_posts.length >= i">
                <PostCard :post="top_posts[i - 1]" platform_type="instagram" />
              </template>
              <template v-else>
                <div class="analytics-post-card">
                  <div class="analytics-post-card__header">
                    <div class="analytics-post-card__header-left">
                      <div class="analytics-post-card__header-img">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          height="2.8rem"
                          width="2.8rem"
                          radius="50%"
                        />
                      </div>
                      <div class="analytics-post-card__header-detail">
                        <div
                          class="d-flex"
                          style="flex-direction: column; margin-left: 10px"
                        >
                          <div class="mb-1"> No More Post </div>
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            radius=".2rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="analytics-post-card__header-right"
                      style="filter: grayscale(100%); opacity: 0.6"
                    >
                      <!-- Dynamic Icon bases on props -->
                      <i class="far fa-external-link-square-alt"></i>
                    </div>
                  </div>

                  <div class="analytics-post-card__content">
                    <div class="mb-1">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        height="260px"
                        radius=".2rem"
                      />
                    </div>
                  </div>

                  <ul class="analytics-post-card__content-ele">
                    <li v-for="i in 6" :key="i">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="40%"
                        radius=".2rem"
                      />
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="1.1rem"
                        radius=".2rem"
                      />
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </section>

    <section content="analytics-users-status">
      <h2>Instagram Active Users</h2>
      <p
        >Review your active users as of the last day of the reporting period.</p
      >

      <div class="row">
        <div class="col-6">
          <div class="analytics-users-status__block">
            <h3>Active Users by Hours</h3>

            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <template v-if="active_users_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <LineBasicChart
                  v-if="active_users.active_users_hours.highest_value > 0"
                  :categories="active_users.active_users_hours.buckets"
                  :series="getActiveUsersSeries(active_users, 'hours')"
                />
                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p>
                      No Data Found! You have less than or equal to 30 users.</p
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="active_users.active_users_hours.highest_value !== 0"
            class="analytics-users-status__block"
          >
            <p
              >Best hour to post is
              <b>{{ active_users.active_users_hours.highest_hour }}:00</b> with
              highest value
              <b>{{ active_users.active_users_hours.highest_value }}</b></p
            >
          </div>
        </div>
        <div class="col-6">
          <div class="analytics-users-status__block">
            <h3>Active Users by Days</h3>

            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <!-- LOADER -->
              <template v-if="active_users_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <LineBasicChart
                  v-if="active_users.active_users_days.highest_value > 0"
                  :categories="active_users.active_users_days.buckets"
                  :series="getActiveUsersSeries(active_users, 'days')"
                />
                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div
            v-if="active_users.active_users_days.highest_value !== 0"
            class="analytics-users-status__block"
          >
            <p
              >Best day to post is
              <b>{{ active_users.active_users_days.highest_day }}</b> with
              highest value
              <b>{{ active_users.active_users_days.highest_value }}</b></p
            >
          </div>
        </div>
      </div>
    </section>

    <section content="analytics-users-status">
      <h2>Instagram Hashtags</h2>
      <p>Review your hashtag performance.</p>

      <div class="row">
        <div class="col-6">
          <div class="analytics-users-status__block">
            <h3>Most used hashtags</h3>
            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <template v-if="hashtag_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <WordCloud
                  v-if="hashtags.top_hashtags.length"
                  :series="getHashtagsSeries(hashtags.top_hashtags)"
                ></WordCloud>

                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="analytics-users-status__block">
            <h3>Top Hashtags by Lifetime Engagements</h3>

            <!-- Chart -->
            <div
              class="h-chart h-chart-section-wrapper mt-4 flex-center-center"
              style="height: 90%"
            >
              <!-- LOADER -->
              <template v-if="hashtag_loader">
                <div class="h-chart flex-center-center" style="height: 90%">
                  <SkeletonBox
                    height="250px"
                    width="300px"
                    type="chart"
                    radius="4px"
                  />
                </div>
              </template>
              <template v-else>
                <WordCloud
                  v-if="hashtags.top_engagement_hashtags.length"
                  :series="getHashtagsSeries(hashtags.top_engagement_hashtags)"
                ></WordCloud>

                <div v-else class="flex-center-center" style="height: 90%">
                  <div class="analytics-no-data-found">
                    <img
                      src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                      alt=""
                    />
                    <p> No Data Found!</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--impressions-->
    <div class="d-flex">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Instagram Impressions</h2>
            <p
              >Review how your content was seen by the Instagram community
              during the reporting period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="impressions_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="impressions && impressions.impressions &&  impressions.impressions.show_data > 0"
              :series="getImpressionsSeries(impressions)"
              :categories="impressions.impressions.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Impressions Rollup</h2>
          <template v-if="impressions_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-for="(value, key) in impressions_rollup_values" :key="key">
              <SummaryCard
                :title="value"
                :total="impressions.impressions_rollup.current[key]"
                :reach="
                  Math.abs(getReach(key, impressions.impressions_rollup)) + '%'
                "
                :reach-up="getReachUp(key, impressions.impressions_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-9 p-0 mt-4">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Instagram Engagement</h2>
            <p
              >See how people are engaging with your posts during the reporting
              period.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="engagement_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="engagement && engagement.engagements && engagement.engagements.show_data > 0"
              :series="getEngagementSeries(engagement)"
              :categories="engagement.engagements.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 mt-4 analytics-slide-summary">
        <section>
          <h2>Engagements Rollup</h2>
          <template v-if="engagement_loader">
            <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-for="(value, key) in engagement_rollup_values" :key="key">
              <SummaryCard
                :title="value"
                :total="engagement.engagements_rollup.current[key]"
                :reach="
                  Math.abs(getReach(key, engagement.engagements_rollup)) + '%'
                "
                :reach-up="getReachUp(key, engagement.engagements_rollup)"
                variant="rollup"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

     <div class="d-flex">
      <div class="col-9 p-0 mt-4">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <div class="flex items-center gap-2 mb-2">
            <h2>Instagram Reels Performance By</h2>
            <CstDropdown
                dropdown-placement="bottom"
                container-classes="!max-h-96"
                button-classes="flex !w-40 !h-[30px] !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !px-0"
              >
                <template v-slot:arrow>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </template>
                <template v-slot:selected>
                  <h2 class="!text-dark-cs-800">
                    {{ selectedReelMetric }}
                  </h2>
                </template>
                <template v-slot>
                  <template
                    v-for="(item, index) in getReelDropdownOptions()"
                    :key="`activity_type_${index}`"
                  >
                  <div class="max-h-14">
                    <CstDropdownItem @click="selectedReelMetric = item">
                      <p class="text-gray-900 text-sm !mb-0 !py-0.5">{{ item }}</p>
                    </CstDropdownItem>
                  </div>
                  </template>
                </template>
              </CstDropdown>
            </div>
            <p
              >See how your reels posted during the selected time period are performing in terms of
              watch time, shares, and engagement.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="reels_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="reels_performance && reels_performance.reels && reels_performance.reels?.show_data > 0"
              :key="selectedReelMetric"
              :series="getReelsSeries(reels_performance, selectedReelMetric)"
              :categories="reels_performance.reels.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 mt-4 analytics-slide-summary">
        <section>
          <h2>Reels Rollup</h2>
          <template v-if="reels_loader">
            <div v-for="i in 5" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-for="(value, key) in reels_rollup_values" :key="key">
              <SummaryCard
                :title="value"
                :total="reels_performance.reels_rollup.current[key]"
                :reach="
                  Math.abs(getReach(key, reels_performance.reels_rollup)) + '%'
                "
                :reach-up="getReachUp(key, reels_performance.reels_rollup)"
                variant="rollup"
                :tooltip-custom="getReelsRollupTooltips(value)"
              />
            </template>
          </template>
        </section>
      </div>
    </div>

    <!--    stories performance-->
    <div class="d-flex pt-12">
      <div class="col-9 p-0">
        <section
          class="mr-0 mt-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Instagram Stories Performance</h2>
            <p
              >Review how people are interacting with stories that you published
              during selected date range.</p
            >
          </div>
          <!--    LOADER      -->
          <template v-if="story_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="story_performance && story_performance.stories_performance && story_performance.stories_performance.show_data > 0"
              :series="getStoriesPerformanceSeries(story_performance)"
              :categories="story_performance.stories_performance.buckets"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
            <SplineBasicChart
              v-if="story_performance?.stories_performance?.show_data > 0"
              :series="getStoriesPublishedSeries(story_performance)"
              :categories="story_performance?.stories_performance?.buckets || []"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Stories Rollup</h2>
          <template v-if="story_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <template v-if="story_rollup_values && story_performance && story_performance.stories_rollup">
              <template v-for="(value, key) in story_rollup_values" :key="key">
                <SummaryCard
                    :title="value"
                    :total="story_performance.stories_rollup.current[key]"
                    :reach="
                  Math.abs(getReach(key, story_performance.stories_rollup)) +
                  '%'
                "
                    :reach-up="getReachUp(key, story_performance.stories_rollup)"
                    variant="rollup"
                />
              </template>
            </template>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import PostCard from '@src/modules/analytics/views/common/PostCard'

// Charts
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnCrossHairChart from '@src/modules/analytics/views/common/Infographics/ColumnCrossHairChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'

// SkeletonBox
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { mapActions } from 'vuex'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import instagramMixin from '@src/modules/analytics/components/common/series/instagramMixin'
import WordCloud from '@src/modules/analytics/views/common/Infographics/WordCloud'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'

export default {
  name: 'OverviewTab',
  components: {
    AnalyticsSummaryBlock,
    SummaryCard,
    PostCard,
    SkeletonBox,
    SplineBasicChart,
    LineBasicChart,
    ColumnCrossHairChart,
    WordCloud,
    CstDropdown,
    CstDropdownItem
  },
  mixins: [analyticsUtilsMixin, instagramMixin],
  props: {
    account: {
      type: Object,
      default: () => {
      }
    },
    date: {
      type: String,
      default: ''
    },
    previous_date: {
      type: String,
      default: ''
    },
    getOverviewLoader: {
      type: Function,
      default: () => {
      }
    }
  },
  data () {
    return dataValues().instagram.overview
  },
  computed: {
    getFetchingData () {
      return (
        this.summary_loader ||
        this.audience_loader ||
        this.publishing_loader ||
        this.top_post_loader ||
        this.active_users_loader ||
        this.impressions_loader ||
        this.engagement_loader ||
        this.video_loader ||
        this.reels_loader
      )
    }
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    getFetchingData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOverviewLoader(newVal)
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    legendClick (point, flag) {
      if (point.visible && flag) {
        point.setVisible(false)
      } else {
        point.setVisible(true)
      }
    },
    getPieRef (chart, name) {
      const tempObj = { ...this.legends }
      tempObj[name] = chart.series[0].data
      this.legends = tempObj
    },
    initialize () {
      this.getSummary()
      this.getAudienceGrowth()
      this.getPublishingBehaviour()
      this.getTopPosts()
      this.getActiveUsers()
      this.getImpressions()
      this.getEngagement()
      this.getHashtags()
      this.getStoryPerformance()
      this.getReelsPerformance()
    },
    async getSummary () {
      if (this.summary_loader === true) {
        return
      }
      this.summary_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'summary',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
        this.summary = response.overview.summary
      }
      this.summary_loader = false
    },
    async getAudienceGrowth () {
      if (this.audience_loader === true) {
        return
      }
      this.audience_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'audience_growth',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.audience.audience_growth = response.overview.audience_growth
          this.audience.audience_growth_rollup =
            response.overview.audience_growth_rollup
      }
      this.audience_loader = false
    },

    async getPublishingBehaviour () {
      if (this.publishing_loader === true) {
        return
      }
      this.publishing_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'publishing_behaviour',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.publishing_behaviour.publishing_behaviour =
            response.overview.publishing_behaviour
          this.publishing_behaviour.publishing_behaviour_rollup =
            response.overview.publishing_behaviour_rollup
      }
      this.publishing_loader = false
    },

    async getTopPosts () {
      if (this.top_post_loader === true) {
        return
      }
      this.top_post_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'top_posts',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.top_posts = response.top_posts
      }
      this.top_post_loader = false
    },
    async getActiveUsers () {
      if (this.active_users_loader === true) {
        return
      }
      this.active_users_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'active_users',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.active_users = response.overview
      }
      this.active_users_loader = false
    },
    async getImpressions () {
      if (this.impressions_loader === true) {
        return
      }
      this.impressions_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'impressions',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.impressions = response.overview
      }
      this.impressions_loader = false
    },
    async getEngagement () {
      if (this.engagement_loader === true) {
        return
      }
      this.engagement_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'engagement',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.engagement = response.overview
      }
      this.engagement_loader = false
    },
    async getHashtags () {
      if (this.hashtag_loader === true) {
        return
      }
      this.hashtag_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'hashtags',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.hashtags = response
      }
      this.hashtag_loader = false
    },
    async getStoryPerformance () {
      if (this.story_loader === true) {
        return
      }
      this.story_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'stories_performance',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.story_performance = response.overview
      }
      this.story_loader = false
    },
    async getReelsPerformance () {
      if (this.reels_loader === true) {
        return
      }
      this.reels_loader = true
      if ('instagram_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.instagram_id ? this.account.instagram_id : '',
          date: this.date,
          section: 'reels_performance',
          tab: 'overview',
          type: 'instagram',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.reels_performance = response.overview
      }
      this.reels_loader = false
    }

  },
}
</script>
