<template>
  <div class="blog_boxes">
    <div v-if="getBlogOptions.wordpress.selection" class="box_item">
      <div class="box_inner">
        <div class="head wp_bg">
          <i class="cs-wordpress"></i>
          <span class="text">WordPress</span>
        </div>
        <div class="box_body">
          <div class="my-4">
            <div class="flex items-center">
              <div class="text-sm w-25">Select WP Blog</div>
              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template
                    v-if="getSingleBlogSelection.wordpress.website"
                    v-slot:selected
                  >
                    {{ getSingleBlogSelectionName('wordpress') }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your WordPress blog
                  </template>
                  <template v-if="getBlogItems('wordpress').length" v-slot>
                    <CstDropdownItem
                      v-for="(item, index) in getBlogItems('wordpress')"
                      :key="index"
                      :disabled="checkBlogDisable(item.platform_identifier)"
                      @click="changeSelection('wordpress', item)"
                    >
                      {{ trimURL(item.platform_url) }}
                    </CstDropdownItem>
                  </template>
                  <template v-else v-slot>
                    <div>No Blog Found</div>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>

          <template v-if="getSingleBlogSelection.wordpress.website">
            <clip-loader
              v-if="$parent.fetch_blog_options_loader"
              style="padding: 20px 15px"
              color="#4165ed"
              :size="'16px'"
            ></clip-loader>
            <template v-else>
              <div class="my-4">
                <div class="flex items-center">
                  <div class="text-sm w-25">Select Author</div>
                  <div class="with_right_icon w-50">
                    <CstDropdown class="w-full" size="default">
                      <template
                        v-if="getSingleBlogSelection.wordpress.author"
                        v-slot:selected
                      >
                        {{ getBlogAuthorName() }}
                      </template>
                      <template v-else v-slot:selected>
                        Select your WordPress author
                      </template>
                      <template
                        v-if="getBlogOptions.wordpress.authors.length"
                        v-slot
                      >
                        <CstDropdownItem
                          v-for="author in getBlogOptions.wordpress.authors"
                          :key="author.user_id"
                          @click="
                            () => {
                              getSingleBlogSelection.wordpress.author =
                                author.user_id
                            }
                          "
                        >
                          {{ author.display_name }}
                        </CstDropdownItem>
                      </template>
                      <template v-else v-slot>
                        <div class="text-center">No Authors Found</div>
                      </template>
                    </CstDropdown>
                  </div>
                </div>
              </div>

              <div class="my-4">
                <div class="flex items-center">
                  <div class="text-sm w-25">Select Category(s)</div>
                  <div class="w-50">
                    <div class="with_right_icon">
                      <CstDropdown class="w-full" size="default">
                        <template v-if="category.name" v-slot:selected>
                          {{ category.name }}
                        </template>
                        <template v-else v-slot:selected>
                          Select your WordPress category
                        </template>
                        <template
                          v-if="getBlogOptions.wordpress.categories.length"
                          v-slot
                        >
                          <template
                            v-for="(item, categoryIndex) in getBlogOptions
                              .wordpress.categories"
                          >
                            <CstDropdownItem
                              v-if="!selectedCategoriesItems.includes(item)"
                              :key="categoryIndex"
                              @click="
                                () => {
                                  category = item
                                  wordpressCategoriesSelection(item)
                                }
                              "
                            >
                              {{ item.name }}
                            </CstDropdownItem>
                          </template>
                        </template>
                        <template v-else v-slot>
                          <div class="text-center">No Categories Found</div>
                        </template>
                      </CstDropdown>
                    </div>

                    <div
                      v-if="selectedCategoriesItems.length"
                      class="multi_select author_category"
                    >
                      <ul class="multi_tags">
                        <li
                          v-for="(
                            categoryItem, index
                          ) in selectedCategoriesItems"
                          :key="index"
                        >
                          <span class="value">{{ categoryItem.name }}</span>
                          <span
                            class="remove"
                            @click="wordpressCategoriesRemoval(index)"
                            >&times;</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!--    TODO: Uncomment when jobs implementation completes    -->
              <div class="my-4">
                <div class="flex items-center">
                  <div class="text-sm w-25">Add Tag(s)</div>
                  <div class="with_right_icon w-50">

                    <input
                        v-model="wordpress_tag"
                        type="text"
                        class="my-1 w-full px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none bg-cs-light-gray"
                        placeholder="Enter new tags separated by space"
                        @keyup.enter="addWordpressTag"
                        @keyup.space="addWordpressTag"
                    />
                    <div
                        v-if="getSingleBlogSelection.wordpress.post_tags"
                        class=" "
                    >
                      <ul class="">
                        <li
                            v-for="(tag, index) in getSingleBlogSelection.wordpress.post_tags"
                            :key="index"
                            class="rounded-lg bg-green-200 bg-clip-padding inline-block px-2 py-1 mr-2 mb-2 align-middle bg-opacity-50 border border-gray-700"
                        >
                          <span class="value">{{ tag }}</span>
                          <span class="remove text-base ml-1 cursor-pointer" @click="removeWordpressTag(index)">&times;</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>

          <div class="my-6">
            <div class="flex items-center">
              <div class="text-sm w-25">Publish Status</div>
              <div class="flex w-50">
                <CstRadio
                  id="status_draft"
                  class="mr-6"
                  name="post_status"
                  value="draft"
                  @change="
                    (value) => {
                      getSingleBlogSelection.wordpress.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Draft</span>
                  </div>
                </CstRadio>
                <CstRadio
                  id="status_post"
                  name="post_status"
                  model-value="publish"
                  value="publish"
                  @change="
                    (value) => {
                      getSingleBlogSelection.wordpress.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Publish</span>
                  </div>
                </CstRadio>
              </div>
            </div>
          </div>
          <div
            v-if="
              getSingleBlogSelection.wordpress.status === 'draft' &&
              (getBlogSelectionEdit.primary ||
                !blogPrimarySecondarySelectionValidation(false))
            "
            class="input_field"
          >
            <div class="warning_box text-center">
              <p
                >Note: Draft WordPress posts will not be shared on social media
                channels.</p
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getBlogOptions.tumblr" class="box_item">
      <div class="box_inner">
        <div class="head tum_bg">
          <i class="cs-tumblr"></i>
          <span class="text">Tumblr</span>
        </div>
        <div class="box_body">
          <div class="my-4">
            <div class="flex items-center">
              <div class="text-sm w-25">Select your Tumblr blog</div>
              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template
                    v-if="getSingleBlogSelection.tumblr.website"
                    v-slot:selected
                  >
                    {{ getSingleBlogSelectionName('tumblr') }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your Tumblr blog
                  </template>
                  <template v-if="getBlogItems('tumblr').length" v-slot>
                    <CstDropdownItem
                      v-for="(item, index) in getBlogItems('tumblr')"
                      :key="index"
                      :disabled="checkBlogDisable(item.platform_identifier)"
                      @click="changeSelection('tumblr', item)"
                    >
                      {{ trimURL(item.platform_url) }}
                    </CstDropdownItem>
                  </template>
                  <template v-else v-slot>
                    <div class="text-center">No Blog Found</div>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getBlogOptions.medium" class="box_item">
      <div class="box_inner">
        <div class="head med">
          <i class="cs-medium"></i>
          <span class="text">Medium</span>
        </div>
        <div class="box_body">
          <div class="my-4">
            <div class="flex items-center">
              <div class="text-sm w-25">Select your Medium blog</div>
              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template
                    v-if="getSingleBlogSelection.medium.website"
                    v-slot:selected
                  >
                    {{ getSingleBlogSelectionName('medium') }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your Medium blog
                  </template>
                  <template v-if="getBlogItems('medium').length" v-slot>
                    <CstDropdownItem
                      v-for="(item, index) in getBlogItems('medium')"
                      :key="index"
                      :disabled="checkBlogDisable(item.platform_identifier)"
                      @click="changeSelection('medium', item)"
                    >
                      {{ trimURL(item.platform_url) }}
                    </CstDropdownItem>
                  </template>
                  <template v-else v-slot>
                    <div class="text-center">No Blog Found</div>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>
          <div class="my-6">
            <div class="flex item-center">
              <div class="text-sm w-25">Publish status</div>
              <div class="flex w-50">
                <CstRadio
                  id="medium_public"
                  class="mr-6"
                  model-value="public"
                  name="medium_status"
                  value="public"
                  @change="
                    (value) => {
                      getSingleBlogSelection.medium.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Public</span>
                  </div>
                </CstRadio>
                <CstRadio
                  id="medium_draft"
                  class="mr-6"
                  name="medium_status"
                  value="draft"
                  @change="
                    (value) => {
                      getSingleBlogSelection.medium.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Draft</span>
                  </div>
                </CstRadio>
                <CstRadio
                  id="medium_unlisted"
                  name="medium_status"
                  value="unlisted"
                  @change="
                    (value) => {
                      getSingleBlogSelection.medium.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Unlisted</span>
                  </div>
                </CstRadio>
              </div>
            </div>
          </div>
          <div
            v-if="
              getSingleBlogSelection.medium.status === 'draft' &&
              (getBlogSelectionEdit.primary ||
                !blogPrimarySecondarySelectionValidation(false))
            "
            class="input_field"
          >
            <div class="warning_box text-center">
              <p
                >Note: Draft Medium posts will not be shared on social media
                channels.</p
              >
            </div>
          </div>
          <div
            v-if="
              (getBlogOptions.wordpress.selection &&
                getSingleBlogSelection.wordpress.status !== 'draft') ||
              getBlogOptions.tumblr
            "
            class="input_field"
          >
            <div class="checkbox_input_image">
              <input
                id="medium_repurpose_check"
                v-model="getSingleBlogSelection.medium.repurpose"
                type="checkbox"
              />

              <label
                v-if="
                  getBlogOptions.wordpress.selection &&
                  getSingleBlogSelection.wordpress.status !== 'draft'
                "
                for="medium_repurpose_check"
                class="checkbox_left"
                >Repurpose content of wordpress</label
              >
              <label v-else for="medium_repurpose_check" class="checkbox_left"
                >Repurpose content of tumblr</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getBlogOptions.shopify.selection" class="box_item">
      <div class="box_inner">
        <div class="head shopify">
          <i class="shopify_icon small_icon"></i>
          <span class="text">Shopify</span>
        </div>
        <div class="box_body">
          <div class="my-4">
            <div class="flex items-center">
              <div class="text-sm w-25">Select your Shopify blog</div>
              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template
                    v-if="getSingleBlogSelection.shopify.website"
                    v-slot:selected
                  >
                    {{ getSingleBlogSelectionName('shopify') }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your Shopify blog
                  </template>
                  <template v-if="getBlogItems('shopify').length" v-slot>
                    <CstDropdownItem
                      v-for="(item, index) in getBlogItems('shopify')"
                      :key="index"
                      :disabled="checkBlogDisable(item.platform_identifier)"
                      @click="changeSelection('shopify', item)"
                    >
                      {{ trimURL(item.platform_url) }}
                    </CstDropdownItem>
                  </template>
                  <template v-else v-slot>
                    <div class="text-center">No Blog Found</div>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>

          <template v-if="getSingleBlogSelection.shopify.website">
            <clip-loader
              v-if="$parent.fetch_blog_options_loader"
              style="padding: 20px 15px"
              color="#4165ed"
              :size="'16px'"
            ></clip-loader>
            <template v-else>
              <div class="my-4">
                <div class="flex items-center">
                  <div class="text-sm w-25">Select Author</div>
                  <div class="with_right_icon w-50">
                    <CstDropdown class="w-full" size="default">
                      <template
                        v-if="getSingleBlogSelection.shopify.author"
                        v-slot:selected
                      >
                        {{ getSingleBlogSelection.shopify.author }}
                      </template>
                      <template v-else v-slot:selected>
                        Select your Shopify author
                      </template>
                      <template v-if="getBlogOptions.shopify.authors" v-slot>
                        <CstDropdownItem
                          v-for="author in getBlogOptions.shopify.authors"
                          :key="author"
                          @click="
                            () => {
                              getSingleBlogSelection.shopify.author = author
                            }
                          "
                        >
                          {{ author }}
                        </CstDropdownItem>
                      </template>
                    </CstDropdown>
                  </div>
                </div>
              </div>

              <div class="my-4">
                <div class="flex items-start">
                  <div class="text-sm w-25">Select tag(s)</div>
                  <div class="w-50 multi_select">
                    <input
                      v-model="shopify_tag"
                      class="prevent-listener"
                      type="text"
                      placeholder="Enter tag that you think are relevant..."
                      @keyup.enter="addShopifyTag(shopify_tag)"
                    />

                    <div class="multi_input">
                      <div
                        v-if="shopify_tag.length"
                        v-click-away="closeDropdown"
                        class="multi_dropdown"
                      >
                        <div class="multi_dropdown_inner">
                          <ul>
                            <li
                              v-for="(tag, index) in getShopifyTagsOptions"
                              :key="index"
                              class="item d-flex align-items-center"
                              @click.prevent="addShopifyTag(tag)"
                            >
                              {{ tag }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="getSingleBlogSelection.shopify.tags"
                      class="multi_select author_tag"
                    >
                      <ul class="multi_tags">
                        <li
                          v-for="(tag, index) in getSingleBlogSelection.shopify
                            .tags"
                          :key="index"
                        >
                          <span class="value">{{ tag }}</span>
                          <span class="remove" @click="removeShopifyTag(index)"
                            >&times;</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div class="my-6">
            <div class="flex items-center">
              <div class="text-sm w-25">Publish status</div>
              <div class="flex w-50">
                <CstRadio
                  id="shopify_visible"
                  class="mr-6"
                  model-value="Visible"
                  name="shopify_status"
                  value="Visible"
                  @change="
                    (value) => {
                      getSingleBlogSelection.shopify.published = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Visible</span>
                  </div>
                </CstRadio>
                <CstRadio
                  id="shopify_hidden"
                  name="shopify_status"
                  value="Hidden"
                  @change="
                    (value) => {
                      getSingleBlogSelection.shopify.published = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Hidden</span>
                  </div>
                </CstRadio>
              </div>
            </div>
          </div>
          <div
            v-if="
              getSingleBlogSelection.shopify.published === 'Hidden' &&
              (getBlogSelectionEdit.primary ||
                !blogPrimarySecondarySelectionValidation(false))
            "
            class="input_field"
          >
            <div class="warning_box text-center">
              <p
                >Note: Hidden Shopify posts will not be shared on social media
                channels.</p
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getBlogOptions.webflow" class="box_item">
      <div class="box_inner">
        <div class="head tum_bg">
          <i class="cs-webflow"></i>
          <span class="text">Webflow</span>
        </div>
        <div class="box_body">
          <div class="my-4">
            <div class="flex items-center">
              <div class="text-sm w-25">Select your Webflow site</div>

              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template
                    v-if="getSingleBlogSelection.webflow.website"
                    v-slot:selected
                  >
                    {{ getSingleBlogSelectionName('webflow') }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your Webflow site
                  </template>
                  <template v-if="getBlogItems('webflow').length" v-slot>
                    <CstDropdownItem
                      v-for="(item, index) in getBlogItems('webflow')"
                      :key="index"
                      @click="changeSelection('webflow', item)"
                    >
                      {{ trimURL(item.platform_url) }}
                    </CstDropdownItem>
                  </template>
                  <template v-else v-slot>
                    <div class="text-center">No Blog Found</div>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>

          <clip-loader
            v-if="getWebflowLoading"
            style="padding: 20px 15px"
            color="#4165ed"
            :size="'16px'"
          ></clip-loader>

          <div
            v-if="
              getSingleBlogSelection.webflow.website.length &&
              !getWebflowLoading
            "
            class="my-4"
          >
            <div class="flex items-center">
              <label class="text-sm w-25">Select Collection</label>
              <div class="with_right_icon w-50">
                <CstDropdown class="w-full" size="default">
                  <template v-if="collection" v-slot:selected>
                    {{ collection }}
                  </template>
                  <template v-else v-slot:selected>
                    Select your Collection
                  </template>
                  <template v-slot>
                    <template v-if="getWebflowCollections?.length > 0">
                      <CstDropdownItem
                        v-for="(webflowCollection, index) in getWebflowCollections"
                        :key="index"
                        @click="setWebflowCollection(index)"
                      >
                        {{ webflowCollection.name }}
                      </CstDropdownItem>
                    </template>
                    <template v-else>
                      <CstDropdownItem disabled>
                        No collection available
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </div>
          <div class="my-6">
            <div class="flex items-center">
              <div class="text-sm w-25">Publish status</div>
              <div class="flex w-50">
                <CstRadio
                  class="mr-6"
                  name="webflow_post_status"
                  value="draft"
                  @change="
                    (value) => {
                      getSingleBlogSelection.webflow.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Draft</span>
                  </div>
                </CstRadio>
                <CstRadio
                  name="webflow_post_status"
                  value="publish"
                  @change="
                    (value) => {
                      getSingleBlogSelection.webflow.status = value
                    }
                  "
                >
                  <div class="flex flex-col">
                    <span class="">Publish</span>
                  </div>
                </CstRadio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstRadio from '@ui/Radio/CstRadio'
import {
  fetchBlogSiteDomainsURL,
  fetchBlogFullCollectionURL,
} from '@src/modules/publish/config/api-utils'
import proxy from '@common/lib/http-common'

export default {
  components: {
    CstDropdown,
    CstDropdownItem,
    CstRadio,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      category: '',
      shopify_tag: '',
      wordpress_tag: '',
      selectedCategoriesItems: [],
      selected_shopify_tags: [],
      fetch_blog_options_loader: false,
      collection: '',
      blog: undefined,
      webflow_sites: [
        {
          _id: '589a331aa51e760df7ccb89d',
          name: 'test-api-domain.com',
        },
        {
          _id: '589a331aa51e760df7ccb89e',
          name: 'www.test-api-domain.com',
        },
      ],
      selected_site: '',
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getBlogOptions',
      'getSingleBlogSelection',
      'getWebflowCollections',
      'getWebflowLoading',
    ]),
    getShopifyTagsOptions() {
      const tags = []
      if (!this.getBlogOptions.shopify.tags.includes(this.shopify_tag)) {
        tags.push(this.shopify_tag)
      }
      this.getBlogOptions.shopify.tags.forEach((tag, index) => {
        if (
          !this.getSingleBlogSelection.shopify.tags.includes(tag) &&
          tag.indexOf(this.shopify_tag) >= 0
        ) {
          tags.push(tag)
        }
      })
      return tags
    },
  },

  watch: {},
  created() {
    console.debug('created')
    if (
      this.getSingleBlogSelection.wordpress.categories &&
      this.getSingleBlogSelection.wordpress.categories.length > 0
    ) {
      this.selectedCategoriesItems = []
      const selfObject = this
      // eslint-disable-next-line no-undef
      $(this.getBlogOptions.wordpress.categories).each(function (index, el) {
        if (
          selfObject.getSingleBlogSelection.wordpress.categories.indexOf(
            el.term_id
          ) !== -1
        ) {
          selfObject.selectedCategoriesItems.push(el)
        }
      })
    }

    if (this.getSingleBlogSelection.webflow.collection) {
      this.collection = this.getSingleBlogSelection.webflow.collection.name
    }
  },
  methods: {
    getSingleBlogSelectionName(type) {
      if (this.getSingleBlogSelection[type].website) {
        const item = this.getBlogItems(type).find(
          (item) =>
            item.platform_identifier ===
            this.getSingleBlogSelection[type].website
        )
        return item?.platform_name
      }
      return ''
    },
    getBlogAuthorName() {
      const id = this.getSingleBlogSelection.wordpress.author
      if (id)
        return this.getBlogOptions.wordpress.authors.find(
          (author) => author.user_id === id
        ).display_name
    },
    changeSelection(type, value) {
      this.getSingleBlogSelection[type].website = value.platform_identifier
      if (type === 'wordpress' || type === 'shopify')
        this.fetchBlogOptions(type, this.getSingleBlogSelection[type].website)
      if (type === 'webflow') {
        this.onSelectingWebflowSite(
          'webflow',
          this.getSingleBlogSelection.webflow.website
        )
        this.collection = ''
      }
    },
    setWebflowCollection(collection) {
      console.log('Method::setWebflowCollection ~ collection -> ', collection)
      // get full collection
      this.fetchFullCollection(
        'webflow',
        this.getWebflowCollections[collection]
      )

      /* console.log("Collection is: ", this.getWebflowCollections[collection - 1]);
                this.getSingleBlogSelection.webflow.collection = this.getWebflowCollections[collection - 1]
                this.collection = collection.name */
    },
    getBlogItems(type) {
      return this.$store.getters.getBlogs[type].items
    },

    wordpressCategoriesSelection(category) {
      if (category) {
        this.selectedCategoriesItems.push(category)
        this.getSingleBlogSelection.wordpress.categories.push(category.term_id)
        this.category = ''
      }
    },

    wordpressCategoriesRemoval(index) {
      this.selectedCategoriesItems.splice(index, 1)
      this.getSingleBlogSelection.wordpress.categories.splice(index, 1)
    },

    async fetchBlogOptions(type, identifier) {
      if (identifier) {
        this.$parent.fetch_blog_options_loader = true
        await this.$store.dispatch('fetchBlogOptions', {
          type,
          platform_identifier: identifier,
        })
        this.$parent.fetch_blog_options_loader = false
      }
    },

    onSelectingWebflowSite(type, identifier) {
      console.log(
        'Method::onSelectingWebflowSite ~ type, identifier --> ',
        type,
        identifier
      )
      const blog = this.getBlogItems('webflow').filter(
        (blog) => blog.platform_identifier === identifier
      )
      if (blog.length > 0) {
        this.fetchBlogDomains(blog[0])
        this.blog = blog[0]
      }
      console.log('Method::onSelectingWebflowSite ~ blog -> ', blog)
      this.fetchBlogOptions(type, identifier)
    },
    fetchBlogDomains(blog) {
      const self = this
      console.log('Method::fetchBlogDomains ~ Blog -> ', blog)
      // eslint-disable-next-line camelcase
      const { site_id, access_token } = blog
      console.log(
        'Method::fetchBlogDomains ~ site_id, access_token -> ',
        site_id,
        access_token
      )

      proxy
        .post(fetchBlogSiteDomainsURL, {
          site_id,
          access_token,
          workspace_id: self.getWorkspaces.activeWorkspace._id,
          type: 'webflow',
        })
        .then((resp) => {
          console.log(resp)
          self.webflow_sites = resp.data.sites
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchFullCollection(type, collection) {
      console.log(
        'Method::fetchFullCollection ~ type, collection -> ',
        type,
        collection
      )
      const self = this

      let respCollection = null
      await proxy
        .post(fetchBlogFullCollectionURL, {
          type,
          workspace_id: self.getWorkspaces.activeWorkspace._id,
          access_token: self.blog.access_token,
          collection,
        })
        .then((resp) => {
          console.log('Method::fetchFullCollection ~ response -> ', resp)
          respCollection = resp.data.collection

          self.getSingleBlogSelection.webflow.collection = respCollection
          self.collection = collection.name
        })
        .catch((err) => {
          console.log('Method::fetchFullCollection ~ error -> ', err)
        })
      return respCollection
    },

    addShopifyTag(tag) {
      if (tag) {
        if (!this.getSingleBlogSelection.shopify.tags.includes(tag)) {
          this.getSingleBlogSelection.shopify.tags.push(tag)
        }
        this.shopify_tag = ''
      }
    },

    addWordpressTag(tag) {
      if (this.wordpress_tag) {
        if (!this.getSingleBlogSelection.wordpress.post_tags.includes(this.wordpress_tag)) {
          this.getSingleBlogSelection.wordpress.post_tags.push(this.wordpress_tag)
        }
        this.wordpress_tag = ''
      }
    },

    removeWordpressTag(index) {
      this.getSingleBlogSelection.wordpress.post_tags.splice(index, 1)
    },

    removeShopifyTag(index) {
      this.getSingleBlogSelection.shopify.tags.splice(index, 1)
    },

    closeDropdown() {
      this.shopify_tag = ''
    },

    checkBlogDisable(platformIdentifier) {
      // console.debug('Method:checkBlogDisable', platformIdentifier)
      if (
        this.getBlogSelection.platform_name &&
        this.getBlogSelection.primaryBlog[
          this.getBlogSelection.platform_name
        ] &&
        this.getBlogSelection.primaryBlog[this.getBlogSelection.platform_name]
          .website &&
        platformIdentifier ===
          this.getBlogSelection.primaryBlog[this.getBlogSelection.platform_name]
            .website &&
        !this.getBlogSelectionEdit.primary
      ) {
        return true
      }
      if (
        this.getBlogSelection.secondaryAccountsId.includes(
          platformIdentifier
        ) &&
        !this.getBlogSelectionEdit.secondary
      ) {
        return true
      }
      return false

      // {{ getBlogSelection.secondaryAccountsId}}
      // {{getBlogSelection.primaryBlog[getBlogSelection.platform_name].website}}
    },
  },
}
</script>
