<template>
  <div>
    <div
      v-for="(item, index) in getFilteredCommentsList"
      :key="index"
      class="comment_item"
    >
      <div v-if="!item?.share_link"
        class="comment_item_inner"
        :class="{ is_note_comment: item.is_note }"
      >
        <div class="top_tool !top-[12px] !right-[8px]">
          <i
            v-tooltip.top="'Edit'"
            class="edit_icon icon-edit-cs"
            @click="allowToUpdateComment(item)"
          ></i>
          <i
            v-tooltip.top="'Remove'"
            class="d-inline-block icon-delete-cs remove_icon"
            @click="deleteCommentAction(item, index)"
          ></i>
        </div>

        <div class="profile_picture d-flex align-items-start">
          <div class="picture_block mt-1 picture_block_background">
            <div
              v-if="item.user && item.user.image"
              class="img"
              :style="{ background: 'url(' + item.user.image + ')' }"
            ></div>
            <div
              v-else
              class="img"
              style="
                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
              "
            ></div>
          </div>
          <div class="text_block w-100">
            <div class="text">
              <template v-if="item.user">
                <div v-tooltip="item.user.full_name" class="comment_truncate max-w-[7rem]">
                  {{ item.user.full_name }}
                </div>
              </template>
              <template v-else>
                <template v-if="item.user_id === getProfile._id">
                  <div
                    v-tooltip="getProfile.full_name"
                    class="comment_truncate max-w-[8rem]"
                  >
                    {{ getProfile.full_name }}
                  </div>
                </template>
                <template v-else> Team Member </template>
              </template>
              <span v-tooltip="item.created_at" class="time w-full inline-block !ml-0">{{
                $filters.relative(item.created_at)
              }}</span></div
            >
            <p v-if="!item.can_update_comment" class="sub_text whitespace-pre-wrap">
              {{ item.comment }}</p
            >

            <!-- update existing comment block -->
            <div
              v-if="item.can_update_comment"
              class="comment_block basic_form p-0 updating-comment"
            >
              <div class="edit_input">
                <div class="profile_picture">
                  <div class="text_block ml-0">
                    <div class="atwho_dropdown mention-feed-dropdown">
                      <AtTa
                        :members="getActiveWorkspaceMembersDetails"
                        name-key="name"
                      >
                        <template v-slot:item="member">
                          <div class="mention-dropdown-li profile_picture">
                            <div class="picture_block picture_block_background">
                              <div
                                v-if="member.item.image"
                                class="img border-0"
                                :style="{
                                  background: 'url(' + member.item.image + ')',
                                }"
                              ></div>
                              <div
                                v-else
                                class="img"
                                style="
                                  background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                "
                              ></div>
                            </div>
                            <div class="text_block ml-2 w-auto">
                              <span class="text">{{ member.item.name }}</span>
                              <span
                                class="userrole"
                                :class="getRoleClass(member.item.role)"
                                >{{
                                  getMutatedUserRole(member.item.role)
                                }}</span
                              >
                            </div>
                          </div>
                        </template>
                        <!--Added CTRL+Enter-->
                        <div class="pb-6 border rounded-md hover:!bg-white">
                          <textarea
                              id="comment-box-edit"
                              ref="editAt"
                              v-model="item.new_comment"
                              placeholder="Write a comment..."
                              spellcheck="false"
                              class="!pr-[0.625rem] !border-0 mt-1"
                              @keyup.ctrl.enter="updateCommentAction(item)"
                          ></textarea>
                        </div>
                        <div
                            class="attachment-icon d-flex justify-content-center align-items-center"
                        >
                          <Emoji type="comment-edit" dropdown-position="dropdown-menu-bottom"/>
                          <label
                              :for="'feed-file-input' + item._id"
                              class="mb-0"
                          ><i class="far fa-paperclip mb-0"></i
                          ></label>
                          <input
                              :id="'feed-file-input' + item._id"
                              accept="image/*"
                              type="file"
                              class="d-none"
                              @change.prevent="
                              uploadExistingCommentImage($event, item)
                            "
                          />
                          <i
                              class="far fa-at"
                              @click="triggerAtExistingComment"
                          ></i>
                        </div>
                      </AtTa>
                    </div>
                    <!--</div>-->
                    <div class="btn_block">
                      <button
                        class="btn gradient_btn"
                        @click="updateCommentAction(item)"
                      >
                        <span class=" ">Update</span>
                      </button>
                      <button
                        class="btn dark_gray ml-2"
                        @click="disallowToUpdateComment(item)"
                      >
                        <span class=" ">Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- attached media files -->

            <div class="attachment-files">
              <template v-if="file_uploading">
                <beat-loader :size="'10px'" :color="'#4165ed'"></beat-loader>
              </template>
              <div
                v-for="(media, index) in item.media"
                v-tooltip="media.name"
                class="img"
                :style="{ background: 'url(' + media.link + ')' }"
              >
                <i
                  v-if="item.can_update_comment"
                  class="fal fa-times cross_icon"
                  @click="item.media.splice(index, 1)"
                ></i>
                <div class="image_tool">
                  <i
                    class="cs-eye image_tool_item"
                    @click="previewCommentImage(media.link)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- external Comments Display -->
      <div v-else class="p-3 mb-2 flex flex-row bg-[#FBFCE1] rounded-md">
              <div class="ml-2 flex-1">
                <div>
                  <span
                    v-tooltip="item.email"
                    class="font-semibold mr-1"
                    >{{ item.name }}</span
                  >
                  <p class="whitespace-pre-wrap text-sm">{{ item.comment }}</p>
                </div>
                <div class="flex justify-between mt-1.5">
                  <div class="text-xs text-gray-700 my-auto">{{
                    moment.parseZone(item.created_at).utc(true).fromNow()
                  }}</div>
                  <v-menu
                    :popper-triggers="['hover']"
                    placement="bottom-end"
                    popper-class="threaded-tweets__info-popover"
                  >
                    <!-- This will be the popover target (for the events and position) -->
                    <img
                      src="@assets/img/composer/help-icon.svg"
                      alt=""
                      class="w-4 h-4"
                    />
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <p class="text-sm">External Comment Added by Shareable link</p>
                    </template>
                  </v-menu>
                </div>
              </div>
            </div>
    </div>

    <div
      v-if="comments && comments.length > 1 && !show_all_comments"
      class="more-comment"
    >
      <p @click="show_all_comments = true"
        >({{ comments.length - 1 }}) Show More
        <template v-if="comments.length - 1 === 1">comment</template>
        <template v-else>comments</template>
      </p>
    </div>

    <div
      v-if="comments && comments.length > 1 && show_all_comments"
      class="more-comment hide-comment"
    >
      <p @click="show_all_comments = false"> Show Less Comments </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import Emoji from '@src/modules/publish/components/posting/social/emoji/Emoji.vue'

export default {
  components: {
    AtTa,
    Emoji
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    planId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show_all_comments: false,
      file_uploading: false,
      moment: require('moment-timezone/builds/moment-timezone-with-data.min')
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getActiveWorkspace',
      'getActiveWorkspaceMembersDetails'
    ]),
    getFilteredCommentsList () {
      if (this.comments && this.comments.length > 0) {
        return this.show_all_comments ? this.comments : [this.comments[0]]
      }
    }
  },
  methods: {
    /**
     * Allow to update the comment.
     */
    allowToUpdateComment (item) {
      this.$emit('commentAction', {
        comment_id: item._id,
        new_comment: item.comment,
        can_update_comment: true,
        action: 'set_comment_fields'
      })
    },
    /**
     * Disallow to update the comment.
     */
    disallowToUpdateComment (item) {
      this.$emit('commentAction', {
        comment_id: item._id,
        can_update_comment: false,
        action: 'set_comment_fields'
      })
    },
    triggerAtExistingComment () {
      const { editAt } = this.$refs
      if (editAt && editAt[0]?.focus) {
        editAt[0].focus()
        document.execCommand('insertText', 0, ' @')
        const e = document.createEvent('HTMLEvents')
        e.initEvent('input', true, true)
        editAt.dispatchEvent(e)
      }
    },
    /**
     * Preview the comment image.
     * @param link {String} - Image link.
     */
    async uploadExistingCommentImage (event, item) {
      this.file_uploading = true
      const res = await this.$store.dispatch('uploadImageForComment', event)
      this.file_uploading = false
      if (res) {
        if (res.data.status) {
          this.$emit('commentAction', {
            comment_id: item._id,
            media: res.data.media,
            action: 'update_media'
          })
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      }
    },
    /**
     * update the comment.
     */
    async updateCommentAction (item) {
      const payload = {
        workspace_id: item.workspace_id,
        plan_id: this.planId,
        comment: item.new_comment,
        mentioned_user: this.$parent.mentionedUserIdsList(item.new_comment),
        type: this.$route.name,
        title: item.title || null,
        is_note: item.is_note ? item.is_note : false,
        media: item.media ? item.media : [],
        id: item._id
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)

      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          this.$emit('commentAction', {
            comment_id: item._id,
            updated_comment: res.data.comment.comment,
            action: 'update_comment'
          })
          // this.dispatchCommentSocket({ 'text': res.data.comment.comment, 'id': item._id }, this.plan_id, 'update')
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      } else {
          this.$store.dispatch('toastNotification', {
            type: 'error',
            message: 'Failed to update the comment. Please try again.',
        })
      }
    },
    /**
     *
     * @param comment {Object} - Comment object.
     * @param index {Integer} - Index no used for removing the item from the list.
     * @returns {Promise<void>}
     */
    async deleteCommentAction (comment) {
      const res = await this.$store.dispatch('deleteFeedViewComment', {
        id: comment._id,
        workspace_id: comment.workspace_id,
        plan_id: comment.plan_id
      })
      // once removed, we get the response
      if (res && res.data.status) {
        // remove the comment from the list
        this.$emit('commentAction', {comment_id: comment._id, action: 'delete'})

        // this.dispatchCommentSocket({ 'id': comment._id }, comment.plan_id, 'delete')
        return
      }
      // show the toast message or notification

      const message = res && !res.data.status ? res.data.message : UNKNOWN_ERROR
      this.$store.dispatch('toastNotification', {
        message,
        type: 'error'
      })
    },
  }
}
</script>

<style lang="less">
.is_note_comment {
  background: rgba(255, 175, 46, 0.15) !important;
}
.comment_truncate {
  max-width: 10.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
</style>
