<template>
  <div class="feeds-left-pane__fixed-block">
    <div class="feeds-left-pane__fixed-block__content">
      <ul class="mb-0 feeds-left-pane__fixed-block__content__ul_header">
        <li>
          <router-link
            :to="{
              name: 'feeder_aggregate_view',
              params: { content_type: 'today' },
            }"
          >
            <i class="title_icon gray_dark_2 icon-today_icon"></i>
            <span>Today</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'feeder_aggregate_view',
              params: { content_type: 'trending' },
            }"
          >
            <i class="title_icon gray_dark_2 icon-trending_icon"></i>
            <span>Trending</span>
          </router-link>
        </li>
        <!--<li>
            <router-link
                    :to="{name:'feeder_aggregate_view', 'params':{'content_type': 'unread'}}">

                <i class="title_icon  gray_dark_2 fas fa-inbox">
                </i>
                <span>Unread</span>
                <div class="line_animate"></div>
            </router-link>
        </li> -->
        <li>
          <router-link
            :to="{
              name: 'feeder_aggregate_view',
              params: { content_type: 'starred' },
            }"
          >
            <i class="title_icon gray_dark_2 icon-stared_icon"> </i>
            <span>Starred</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'feeder_aggregate_view',
              params: { content_type: 'archived' },
            }"
          >
            <i class="title_icon gray_dark_2 icon-Archive_icon"> </i>
            <span>Archived</span>
          </router-link>
        </li>
      </ul>
      <hr />
      <FeederGroups></FeederGroups>
      <div class="hr_line"></div>
      <!--<ul class="footer_links mb-0">
        &lt;!&ndash;<li @click="$router.push({name: 'feeder_manage_sources'})" class="heading heading_gray d-flex align-items-center manage-sources-btn" :class="{'active': $route.name === 'feeder_manage_sources'}">&ndash;&gt;
        &lt;!&ndash;<div class="manage-btn">&ndash;&gt;
        &lt;!&ndash;<i class="title_icon title_icon_gray icon-settings-cs"></i>&ndash;&gt;
        &lt;!&ndash;<span class="text">Manage Sources </span>&ndash;&gt;
        &lt;!&ndash;</div>&ndash;&gt;
        &lt;!&ndash;</li>&ndash;&gt;
      </ul>

      &lt;!&ndash;<div class="hr_line"></div>&ndash;&gt;-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeederGroups from '../components/FeederGroups'

export default {
  components: {
    FeederGroups
  },

  computed: {
    ...mapGetters([])
  },
  methods: {}
}
</script>
<!--
<style lang="less">

.footer_feeder_links {
  padding-left: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  i {
    margin-right: 0.625rem;
  }
}
</style>
-->
