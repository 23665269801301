<script setup>
import { defineProps } from 'vue'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import NewsCardHelper from '@src/modules/dashboard/components/NewsCardHelper.vue'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'

defineProps({
  newsData: {
    type: Array,
    default: () => [],
  },
})

const handleLinkClick = (url = '') => {
  window.open(url, '_blank')
}

const { navigationNext, navigationPrev, sortOnDateBasis } = useDashboard()
</script>

<template>
  <Carousel
    navigation-enabled
    pagination-color="#D2D5DF"
    pagination-active-color="#2973E5"
    :per-page="1"
    :navigation-next-label="navigationNext"
    :navigation-prev-label="navigationPrev"
    class="min-h-[14.375rem] h-full w-full dashboardCarousel__carousel"
  >
    <template
      v-for="(news, newsIndex) in sortOnDateBasis(
        newsData,
        (item) => item.updatedAt
      )"
      :key="`news-key-${newsIndex}`"
    >
      <Slide>
        <!-- center aligned text card > type = text -->
        <NewsCardHelper v-if="news?.type === 'text'">
          <template v-slot>
            <div
              class="flex flex-col justify-center items-center text-center h-[8.75rem] w-full gap-y-3"
            >
              <p
                class="text-primary-cs text-[1.375rem] font-semibold truncate max-w-[20rem] flex items-center justify-center gap-2"
                >{{ news.title }}
                <span
                  v-if="news.isNew"
                  class="text-[#014B75] bg-[#FFC555] font-medium text-xs px-1.5 py-0.5 rounded-pill"
                  >NEW</span
                ></p
              >
              <p class="text-secondary-cs text-base line-clamp-3">{{
                news.message
              }}</p>
              <p
                v-if="news.linkUrl"
                class="text-secondary-cs-700 text-sm font-normal cursor-pointer truncate hover:text-primary-cs italic"
                @click="handleLinkClick(news.linkUrl)"
              >
                {{ news.linkText }}
              </p>
            </div>
          </template>
        </NewsCardHelper>
        <!-- left aligned title with html based content from api > type = html -->
        <NewsCardHelper v-if="news?.type === 'html'">
          <template v-slot>
            <div class="flex flex-col h-[8.75rem] gap-y-3 text-left w-full">
              <p
                class="text-primary-cs text-[1.375rem] font-semibold truncate max-w-[50rem]"
                >{{ news.title }}</p
              >
              <div class="w-full" v-html="news.htmlContent"></div>
              <p
                v-if="news.linkUrl"
                class="text-secondary-cs-700 text-sm font-normal cursor-pointer truncate w-max hover:text-primary-cs italic"
                @click="handleLinkClick(news.linkUrl)"
              >
                {{ news.linkText }}
              </p>
            </div>
          </template>
        </NewsCardHelper>
        <!-- text left, full height image right > type = media-1  -->
        <NewsCardHelper v-else-if="news?.type === 'media-1'">
          <template v-slot>
            <div class="flex h-full w-full gap-x-6 justify-center">
              <div
                class="flex flex-col h-[8.75rem] gap-y-3 text-left max-w-[55%]"
              >
                <p
                  class="text-primary-cs text-[1.375rem] font-semibold truncate max-w-[20rem]"
                  >{{ news.title }}</p
                >
                <p class="text-secondary-cs text-base line-clamp-3">{{
                  news.message
                }}</p>
                <p
                  v-if="news.linkUrl"
                  class="text-secondary-cs-700 text-sm font-normal cursor-pointer truncate w-max hover:text-primary-cs italic"
                  @click="handleLinkClick(news.linkUrl)"
                >
                  {{ news.linkText }}
                </p>
              </div>
              <img
                class="h-[9.563rem] max-w-[30%] object-contain"
                :src="news.imageUrl"
                alt=""
              />
            </div>
          </template>
        </NewsCardHelper>
        <!-- centered (text on left and image on right) > type = media-2 -->
        <NewsCardHelper v-else-if="news?.type === 'media-2'">
          <template v-slot>
            <div class="flex flex-col h-[8.75rem] items-center gap-y-3">
              <p
                class="text-primary-cs text-[1.375rem] font-semibold truncate max-w-[20rem]"
                >{{ news.title }}</p
              >
              <div class="flex gap-x-3 text-right w-[80%]">
                <p class="text-secondary-cs text-base line-clamp-3">{{
                  news.message
                }}</p>
                <img
                  class="h-[3.75rem] w-[3.75rem] mr-4 mt-auto mb-auto object-contain"
                  :src="news.imageUrl"
                  alt=""
                />
              </div>
              <p
                v-if="news.linkUrl"
                class="text-secondary-cs-700 text-sm font-normal cursor-pointer truncate hover:text-primary-cs italic"
                @click="handleLinkClick(news.linkUrl)"
              >
                {{ news.linkText }}
              </p>
            </div>
          </template>
        </NewsCardHelper>
      </Slide>
    </template>
  </Carousel>
</template>
