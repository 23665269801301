<script>
// libraries
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TableData',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    day: {
      type: String,
      default: '',
    },
    addSlotModal: {
      type: Function,
      default: () => {},
    },
    slots: {
      type: Array,
      default: () => [],
    },
    removeSlotModal: {
      type: Function,
      default: () => {},
    },
    editSlotModal: {
      type: Function,
      default: () => {},
    },
    timeFormat: {
      type: String,
      default: '12h',
    },
  },
  setup(props) {
    const root = inject('root')

    const convertTo12Hour = (hour24) => {
      const period = hour24 >= 12 ? 'PM' : 'AM';
      const hour12 = hour24 % 12 || 12;
      return { hour: hour12, period };
    }

    const convertTo24Hour = (hour, period) => {
      if (period === 'AM' && hour === 12) return 0;
      if (period === 'PM' && hour !== 12) return hour + 12;
      return hour;
    }


    const formatHour = (hour) => {
      return hour?.toString()?.padStart(2, '0')
    }

    const dataDateTime = computed(() => {
      return `${props.day}-${
          props.index > 12 ? props.index % 12 : props.index === 12 ? 0 : props.index
      }-${props.index >= 12 ? 'PM' : 'AM'}`
    })
    const findSlot = computed(() => {
      return props.slots.filter((item) => {
        const itemDateTime = `${item.day?.toString()}-${item.hour}-${item.period}`;
        return dataDateTime.value === itemDateTime;
      });
    });


    const editSlot = (item) => {
      // Always convert to 12-hour format
      const { hour: hour12, period } = convertTo12Hour(item.hour)
      const slotData = { ...item, hour: hour12 }
      props.editSlotModal(slotData)
    }

    const removeSlot = (item) => {
      props.removeSlotModal({
        slotId: item._id,
        weekday: item.day,
        index: item.idx,
        categoryId: item.category._id,
      })
    }

    const formatDisplayTime = (hour, minute, period) => {
      if (props.timeFormat === '24h') {
        const hour24 = convertTo24Hour(hour, period)
        return `${formatHour(hour24)}:${root.$filters.addZeroToStart(minute)}`
      } else {
        return `${formatHour(hour)}:${root.$filters.addZeroToStart(
          minute
        )} ${period}`
      }
    }

    const addSlot = (hour, minutes, day) => {
      // Always convert to 12-hour format
      const { hour: hour12, period } = convertTo12Hour(hour)
      const slotData = { hour: hour12, minutes, day, period }
      props.addSlotModal(slotData)
    }

    return {
      dataDateTime,
      root,
      findSlot,
      removeSlot,
      editSlot,
      formatDisplayTime,
      addSlot,
    }
  },
})
</script>

<template>
  <td
    class="
      relative
      h-[2rem]
      box-content
      border border-2 border-[#2a8bc3]
      align-top
      lg:px-2
      sm:px-1
    "
    :data-date-time="dataDateTime"
  >
    <div class="all-slots relative z-10">
      <template
        v-for="(slot, slotIndex) in findSlot"
        :key="`slot_${slotIndex}`"
      >
        <div
          class="
            group
            custom-slots
            w-full
            rounded-t-lg rounded-b-lg
            border border-solid border-[#70707045]
            mt-2
            last:mb-[38px]
          "
        >
          <div
            class="
              edit-delete-slot
              lg:flex
              lg:justify-between
              lg:flex-row
              sm:flex-col
              sm:justify-center
              sm:align-center
              lg:px-2
              lg:py-2.5
              sm:px-1
              sm:py-1.5
              lg:h-[1.875rem]
              sm:h-[3.5rem]
              border
              border-solid
              border-top-0
              border-left-0
              border-right-0
              border-bottom-2
            "
          >
            <span class="text-[#3a4557ba] text-[0.7rem]">
              {{ formatDisplayTime(slot.hour, slot.minute, slot.period) }}
            </span>
            <div class="hidden group-hover:block">
              <span
                title="Edit Slot"
                class="
                  edit-slot-button
                  cursor-pointer
                  icon-edit-cs
                  text-xs
                  remove_icon
                  ml-auto
                  text-[#3a4557ba]
                  hover:text-[#ff4500]
                "
                @click="() => editSlot(slot)"
              ></span>
              <span
                title="Remove Slot"
                class="
                  remove-slot-button
                  cursor-pointer
                  icon-delete-cs
                  text-xs
                  remove_icon
                  ml-1
                  text-[#3a4557ba]
                  hover:text-[#ff4500]
                "
                @click="() => removeSlot(slot)"
              ></span>
            </div>
          </div>
          <div
            class="
              w-full
              px-2
              py-2.5
              text-center
              cursor-pointer
              edit-slot-bar
              rounded-b-lg
            "
            :class="[slot.category.color]"
            @click="() => editSlot(slot)"
          >
            <span
              class="
                text-white
                font-medium
                text-[0.688rem]
                capitalize
                overflow-ellipsis
              "
              :title="slot.category.color"
              >{{ slot.category.name || '--' }}</span
            >
          </div>
        </div>
      </template>
    </div>
    <div
      class="
        group
        custom-slot-btn
        cursor-pointer
        absolute
        w-full
        bottom-0
        h-full
        left-1.5
      "
      @click="addSlot(index, 0, day)"
    >
      <button
        class="
          h-[2rem]
          absolute
          bottom-0
          hidden
          group-hover:block
          duration-300
          bg-[#0068e5]
          text-white
          w-11/12
          rounded-md
          border-0
        "
      >
        Add a slot
      </button>
    </div>
  </td>
</template>
