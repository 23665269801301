<template>
  <div class="feeds-manage-sources">
    <!-- show the logs modal only on the route where we are showing the history of the imports-->
    <FeederGroupLogsModal :logs="logs_list"></FeederGroupLogsModal>

    <div class="feeds-manage-sources__left_section">
      <h3 class="section_heading feeds-manage-sources__left_section__heading"
        >OPML Import History</h3
      >
      <p class="feeds-manage-sources__left_section__heading_text">
        Your previously imported feeds.
      </p>
    </div>
    <div class="feeds-manage-sources__right_section">
      <div class="buttons-content">
        <router-link
          :to="{ name: 'feeder_opml_import' }"
          class="btn btn-studio-theme-space"
          ><span>Import OPML</span></router-link
        >
      </div>
    </div>

    <div class="social_table feeds-manage-sources-table mt-3">
      <div class="table_wrapper">
        <table class="table">
          <thead>
            <tr>
              <th> File name </th>
              <th class="text-center"> Feeds Statistics </th>
              <th> Created at </th>
              <th> Actions </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loaders.retrieve">
              <tr>
                <td class="pt-3 pb-3" colspan="4">
                  <clip-loader :color="'#436aff'" :size="'14px'"></clip-loader>
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-if="histories && histories.length === 0">
                <td colspan="4" class="text-center">
                  You have not imported any OPML file yet.
                </td>
              </template>
              <template
                v-for="(history, historyIndex) in histories"
                :key="`history_${historyIndexhistory}`"
              >
                <tr v-if="histories && histories.length > 0">
                  <td>
                    <span v-tooltip="history.filename">
                      {{ limitTextLength(history.filename, 30) }}
                    </span>
                  </td>
                  <td class="feeds_table_statistics text-center">
                    <span class="total_feeds mr-2">
                      {{ history.total }} in total
                    </span>
                    <span class="total_added mr-2">
                      {{ history.added }} added
                    </span>
                    <span class="total_failed">
                      {{ history.failed }} failed
                    </span>
                  </td>
                  <td>
                    {{ $filters.relative(history.created_at_timestamp.$date) }}
                  </td>
                  <td class="actions">
                    <template v-if="history.state === 'processing'">
                      <span class="actions_processing">
                        Processing
                        <clip-loader
                          color="#4165ed"
                          :size="'12px'"
                        ></clip-loader>
                      </span>
                    </template>
                    <template v-else>
                      <a
                        href="javascript:;"
                        @click.prevent="viewOPMLHistoryLogs(history._id)"
                        >View Logs</a
                      >
                    </template>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { pusherSocket } from '@common/lib/pusher'

const FeederGroupLogsModal = () => import('../dialogs/FeederGroupLogsModal')

export default {
  components: {
    FeederGroupLogsModal
  },
  data () {
    return {
      histories: [],
      loaders: {
        retrieve: false
      },
      channel: null,
      logs_list: []
    }
  },
  computed: {},
  watch: {
    'getActiveWorkspace._id' (value) {
      this.histories = []
      this.fetchFeedHistories()
      this.channel = pusherSocket.subscribe(
        `opml_${this.getActiveWorkspace._id}`
      )
      this.bindFeederFeedLogs()
    }
  },
  created () {
    this.fetchFeedHistories()
  },
  mounted () {
    this.channel = pusherSocket.subscribe(`opml_${this.getActiveWorkspace._id}`)
    this.bindFeederFeedLogs()

    this.channel.bind('opml_state', (data) => {
      console.log(data)
      if (data.state === 'completed') {
        this.fetchFeedHistories()
      }
    })
  },
  beforeUnmount () {
    this.channel.unbind('opml_state')
    this.channel.unbind('opml_history_feed')
  },
  methods: {
    bindFeederFeedLogs () {
      this.channel.bind('opml_history_feed', (data) => {
        console.log(data)
        console.log(this.histories)
        const historyItem = this.histories.find(
          (item) => item._id === data.opml_history_id
        )
        if (data.state === 'added') {
          historyItem.added += 1
        } else {
          historyItem.failed += 1
        }
      })
    },
    async fetchFeedHistories () {
      this.loaders.retrieve = true
      const res = await this.$store.dispatch('feederOPMLHistories')
      if (res && res.data.status) {
        this.histories = res.data.data
      }
      this.loaders.retrieve = false
    },
    viewOPMLHistoryLogs (historyId) {
      this.logs_list = []
      this.$store
        .dispatch('feederOPMLHistoryLogs', historyId)
        .then((res) => {
          if (res.data.status) {
            this.logs_list = res.data.data
          }
        })
        .catch((err) => {})
        .finally()
      this.$bvModal.show('feeder_logs_opml_import')
    }
  }
}
</script>
