// import {analyticsFeatureLockCheck} from "@src/router";
import DownloadReports from '@src/modules/analytics/components/reports/DownloadReports'
import ReportsSettings from '@src/modules/setting/components/ReportSettings'

const AnalyticsOverview = () =>
  import(
    /* webpackChunkName: "AnalyticsOverview" */ '@src/modules/analytics/views/overview/AnalyticsOverview'
  )

const AnalyticsMain = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/AnalyticsMain.vue'
  )

const InstagramOverview = () =>
  import(
    /* webpackChunkName: "InstagramAnalyticsOverview" */ '@src/modules/analytics/views/instagram/InstagramMain'
  )
const TwitterOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '@src/modules/analytics/views/twitter/MainComponent.vue'
  )
const PDFReports = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/PDFReports.vue'
  )

const PinterestOverview = () =>
  import(
    /* webpackChunkName: "PinterestAnalyticsOverview" */ '@src/modules/analytics/views/pinterest/MainComponent'
  )
const LinkedinOverview = () =>
  import(
    /* webpackChunkName: "LinkedinAnalyticsOverview" */ '@src/modules/analytics/views/linkedin/LinkedInMain'
  )
const MyReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/reports/MyReport.vue'
  )

export const analyticsRoutes = {
  path: '/:workspace/analytics',
  component: AnalyticsMain,
  name: 'analytics',
  redirect: '/:workspace/analytics/main',
  meta: {
    title: 'Analytics',
  },
  children: [
    {
      path: 'twitter/:accountId?',
      component: TwitterOverview,
      name: 'twitter_analytics',
      meta: {
        title: 'X (Twitter) | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports/',
      component: MyReport,
      name: 'my_report',
      meta: {
        title: 'My Report | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'download_reports/',
      component: DownloadReports,
      name: 'download_reports',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports_setting/',
      component: ReportsSettings,
      name: 'reports_setting',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'overview/',
      component: AnalyticsOverview,
      name: 'group_analytics',
      meta: {
        title: 'Overview | Analytics',
      },
    },
    {
      path: 'reports/:reportId?',
      component: PDFReports,
      name: 'analytics_pdf_report',
      meta: {
        title: 'Print Report | Analytics',
        guest: true,
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'pinterest/:accountId?',
      component: PinterestOverview,
      name: 'pinterest_analytics',
      meta: {
        title: 'Pinterest | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'linkedin/:accountId?',
      component: LinkedinOverview,
      name: 'linkedin_analytics',
      meta: {
        title: 'Linkedin | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?',
      component: InstagramOverview,
      name: 'instagram_analytics',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?section',
      component: InstagramOverview,
      name: 'instagram_overview',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
  ],
}
