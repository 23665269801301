/**
 * @description Composable to manage competitors
 * includes: search/add/remove competitors, save/delete report
 */


// libs
import {computed, inject, ref} from 'vue'
import {EventBus} from "@common/lib/event-bus";
import {useRoute} from "vue-router"
import {useStore} from '@state/base'

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport"

// helpers
import {notificationHandler} from '@src/modules/analytics_v3/helper/services'

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


const {getters} = useStore()

export default function useCompetitorsManagement () {
    const root = inject('root')
    const {$bvModal} = root
    const route = useRoute()

    const {reportCompetitorsInfo} = useCompetitorReport()

    // responsible for searching competitors in manage competitors modal
    const {
        fetchCompetitorInfo: searchCompetitorData,
        apiResponse: searchResponse,
        loading: searchLoadingState,
        allReports,
        platform,
    } = useCompetitorsFactory()

    // responsible for saving a report
    const {fetchCompetitorInfo: saveReportData, apiResponse: saveReportResponse, loading: saveReportLoadingState} =
        useCompetitorsFactory()

    // responsible for deleting a report
    const {
        fetchCompetitorInfo: deleteReportData,
        apiResponse: deleteReportResponse,
        loading: deleteReportLoadingState,
    } = useCompetitorsFactory()


    const competitors = ref([]) // array of competitors added/ to be added to the report
    const reportData = ref({}) // report data if the report is being edited or if created
    const searchedCompetitor = ref(null) // competitor searched in the manage competitors modal
    const reportTitle = ref('Untitled')
    const searchedText = ref('')


    const reportId = computed(() => route?.params?.reportId)

    const searchCompetitor = async () => {
        if (!searchedText.value) return
        const data = new FormData()
        data.append('search', searchedText.value)
        try {
            searchedCompetitor.value = await searchCompetitorData(COMMON_API_ENDPOINTS.SEARCH_COMPETITOR, data)
        } catch (error) {
        }
    }

    const addCompetitor = async (index = '') => {
        if (
            !competitors.value?.find(
                (competitor) =>
                    competitor.competitor_id === searchedCompetitor.value[index]?.competitor_id
            )
        ) {
            competitors.value.push(searchedCompetitor.value[index])
            searchedCompetitor.value = null
            searchedText.value = ''
        } else {
            await notificationHandler('Competitor already added.', 'info')
        }
    }

    const removeCompetitor = (index) => {
        competitors.value.splice(index, 1)
    }

    const saveReport = async () => {
        if (!reportTitle.value || !competitors.value?.length) return

        const competitorsData = competitors.value?.map((competitor) => {
            return {
                platform_type: platform.value,
                type: 'Page',
                competitor_id: competitor.competitor_id,
                name: competitor.name || '',
                slug: platform.value === 'instagram' ? competitor.slug : competitor.name,
                image: competitor.image,
                state: competitor.state || 'Added',
                is_active: true,
            };
        });

        const payload = {
            name:
                reportTitle.value === 'Untitled' && allReports.value?.length
                    ? `Untitled-${allReports.value.length + 1}${Math.floor(
                        Math.random() * 100
                    )}`
                    : reportTitle.value,
            platform_type: platform.value,
            workspace_id: getters.getActiveWorkspace._id,
            created_by_user_id: getters.getActiveWorkspace.user_id,
            updated_by_user_id: getters.getActiveWorkspace.user_id,
            competitors: competitorsData,
        }
        if (reportData.value?._id) payload._id = reportData.value._id

        try {
            reportCompetitorsInfo.value = saveReportResponse.value = await saveReportData(
                COMMON_API_ENDPOINTS.SAVE_REPORT,
                payload,
                COMMON_ERROR_MESSAGES
            )
        } catch (error) {
        }

        const reportIndex = allReports.value?.findIndex(report => report._id === saveReportResponse.value?._id);

        if (reportIndex > -1) {
            allReports.value[reportIndex] = saveReportResponse.value;
        } else {
            allReports.value.unshift(saveReportResponse.value);
        }

        $bvModal.hide('manage-competitors-modal')

        if(reportId.value && platform.value) EventBus.$emit('re-fetch-report-data')
    }

    const deleteReport = async (reportId, reportName) => {
        const payload = {
            _id: reportId,
        }
        try {
            deleteReportResponse.value = await deleteReportData(
                COMMON_API_ENDPOINTS.DELETE_REPORT,
                payload,
                COMMON_ERROR_MESSAGES.DELETE_REPORT(reportName)
            )

            if (deleteReportResponse.value) {
                // Find and remove the deleted report from allReports
                const deletedReportIndex = allReports.value.findIndex(
                    (report) => report._id === reportId
                )
                if (deletedReportIndex > -1) {
                    allReports.value.splice(deletedReportIndex, 1)
                }
            }

        } catch (error) {
        }
    }

    return {
        // data
        searchLoadingState,
        searchResponse,
        saveReportLoadingState,
        saveReportResponse,
        reportData,
        competitors,
        reportTitle,
        deleteReportResponse,
        deleteReportLoadingState,
        searchedCompetitor,
        searchedText,
        platform,

        // methods
        searchCompetitor,
        addCompetitor,
        removeCompetitor,
        saveReport,
        deleteReport,
    }
}
