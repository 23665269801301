import unescape from 'lodash/unescape'

const he = require('he')
export const renderUtils = {
  methods: {
    backgroundImageURL(post, width = 360) {
      if (post) {
        if (post.image) {
          return this.getResizedImageURL(post.image, width, 0)
        } else {
          return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        }

        // we are using google container engine for this purpose.
        if (post.resized_url) {
          return post.resized_url
        } else if (post.image) {
          return post.image
        } else {
          return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        }
      }

      return ''
    },
    backgroundLogoFaviconURL(url) {
      if (url) {
        return `https://favicon.cstuinternal.com/?url=${url}&size=16`
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
    },

    backgroundArticlePreviewImageURL(post, width = 360) {
      if (post) {
        if (post.image) {
          return this.getResizedImageURL(post.image, width, 0)
        } else {
          return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-400.png'
        }

        // we are using google container engine for this purpose.
        if (post.resized_url) {
          return post.resized_url
        } else if (post.image) {
          return post.image
        } else {
          return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-400.png'
        }
      }
      return ''
    },
    backgroundArticlePreviewImageDefaultURL() {
      return 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-400.png'
    },

    limitTextLength(text, lengthLimit) {
      // console.debug('limitTextLength', text);
      // console.debug('lenghtLimit', lenghtLimit);
      if (text) {
        // console.log('Before text', text.length, lengthLimit)
        text = unescape(text)
        // console.log('After text', text.length, lengthLimit)
        // console.debug('text length', text.length);

        if (lengthLimit && text.length > lengthLimit) {
          text = text.substring(0, lengthLimit) + '...'
        }
        text = he.decode(text)
      }

      return text
    },
    calculateEngagements(source) {
      let FacebookShares = 0
      let pins = 0
      let reddit = 0
      let twitterShares = 0
      if (source.facebook_shares) {
        FacebookShares = source.facebook_shares
      }
      if (source.pins) {
        pins = source.pins
      }
      if (source.reddit) {
        reddit = source.reddit
      }
      if (source.twitter_shares) {
        twitterShares = source.twitter_shares
      }

      return FacebookShares + pins + reddit + twitterShares
    },
    checkSentiment(values) {},
    checkIfMultipleTopics(value) {
      if (!value) return ''
      const items = value.toString().split('/')
      value = items[items.length - 1]
      const valueItem = value.toString()
      if (valueItem.includes('Reptiles & Amphibians')) {
        return ''
      }
      /* if (valueItem.includes('?')) {
        valueItem = value.split('?')
        return valueItem
      } */
      return [valueItem]
    },
    savePocketContent(url) {
      return 'https://getpocket.com/edit?url=' + url
    },
  },
}
