<template>
  <b-modal
    id="mediaRemovalConfirmationDialog"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="alertBox socialConfirmationDialog"
    hide-footer
    hide-header
  >
    <div class="modal_body">
      <div class="col-sm-12 alert_content">
        <h2>Are you sure?</h2>
        <p class="text-center"
          >In case of permanently deleting the media asset which is used in any
          of the scheduled posts, the post will fail to publish.</p
        >

        <div class="compose_warning_box text-center">
          <p style="color: #444343"
            >Note: Archived assets are shown in the Archive folder and will not
            affect the scheduled posts.</p
          >
        </div>
      </div>

      <div class="col-sm-12 footer_box">
        <button class="btn gray cancel_btn" @click="closeThisModal"
          >Cancel</button
        >
        <button
          class="btn gray_color_btn gray cancel_btn"
          @click="archiveMedia"
          >Archive</button
        >
        <button class="btn confirm_btn" @click="deleteMedia"
          >Delete Permanently
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { useComposerHelper } from "@/src/modules/composer_v2/composables/useComposerHelper";
import {EventBus} from "@common/lib/event-bus";

export default {
  setup() {
    const {isComposerModalOpen} = useComposerHelper()

    return {
      isComposerModalOpen,
    }
  },
  computed: {
    EventBus() {
      return EventBus
    }
  },
  methods: {
    archiveMedia(){
      if(this.$route.name === 'media-library' && !this.isComposerModalOpen){
        EventBus.$emit('archive-media-main')
      }else{
        EventBus.$emit('archive-media')
      }
    },
    deleteMedia(){
      if(this.$route.name === 'media-library' && !this.isComposerModalOpen){
        EventBus.$emit('delete-media-main')
      } else{
        EventBus.$emit('delete-media')
      }
    },
    closeThisModal() {
      this.$bvModal.hide('mediaRemovalConfirmationDialog')
    },
  },
}
</script>

<style lang="scss"></style>
