<template>
  <div class="topics-pane">
    <TopicsSidebar />
    <div class="topics-filters-pane force-white-bg">
      <div class="topics-filters-pane__container">
        <div class="topics-filters-pane-curated-quotes-search">
          <div
            class="search-bar-input search-bar-input-discovery with-icon-left"
          >
            <div class="search-bar-inner">
              <input
                id
                v-model="search_quote_keyword"
                class="discoverySearchInput"
                type="text"
                placeholder="Search for a keyword e.g motivation, fitness etc."
                @keyup.enter="searchQuoteByKeyword"
              />
              <button
                class="search_btn input_icon search_icon gradient_color"
                data-cy="discovery_search_button"
                @click.prevent="searchQuoteByKeyword"
              >
                <i class="icon-Search"></i>
                <!--<beat-loader :color="'#ffffff'" :size="'18px'" v-if="getSearchLoader.button.search"></beat-loader>-->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="topics-content-container">
      <div class="topics-content-container__box">
        <div class="topics-content-container__box__inner">
          <div
            v-for="(quote, index) in quotes_list"
            class="quote-grid-view-container"
          >
            <QuoteGridViewItem :quote="quote" :index="index" />
          </div>
        </div>
        <InfiniteLoading
          ref="infiniteCuratedQuotes"
          :identifier="infiniteId"
          @infinite="quotesViewHandler"
        >
          <span slot="spinner" class="d-block pt-3 pb-4">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>
          <div slot="no-more" class="not_found_text">
            <div v-if="total_results === 0" class="no_data_found_content">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No Results Found</h2>
                <p slot="text"
                  >No more results found for your query. Please try a different
                  search keyword.</p
                >
              </content-available-state>
            </div>
          </div>

          <div slot="no-results">
            <div v-if="total_results === 0" class="no_data_found_content">
              <content-available-state :type="'no_results'">
                <h2 slot="headline">No Results Found</h2>
                <p slot="text"
                  >No quotes are available for your searched query.</p
                >
              </content-available-state>
            </div>
          </div>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script>
import proxy from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import InfiniteLoading from 'vue-infinite-loading'
import QuoteGridViewItem from '../../../common/components/QuoteGridViewItem'
import TopicsSidebar from '../../sub-components/TopicsSidebar'

export default {
  components: {
    TopicsSidebar,
    QuoteGridViewItem,
    InfiniteLoading
  },
  data () {
    return {
      is_curated_quotes_loading: false,
      infiniteId: +new Date(),
      infiniteState: '',
      page: 1,
      limit: 20,
      search_quote_keyword: '',
      quotes_list: [],
      total_results: 0
    }
  },
  watch: {
    '$route.query.q' (value) {
      this.quotes_list = []
      this.total_results = 0
      this.state = ''
      this.page = 1
      this.infiniteState = ''
      this.infiniteId += 1
    }
  },
  created () {
    if (this.$route.query.q) {
      this.search_quote_keyword = this.$route.query.q
    }
  },
  methods: {
    quotesViewHandler ($state) {
      this.actionFetchQuotes($state)
    },
    searchQuoteByKeyword () {
      this.$router.replace({
        query: { ...this.$route.query, q: this.search_quote_keyword }
      })
    },
    actionFetchQuotes ($state) {
      proxy
        .post(`${discoveryBaseUrl}search/quotes`, {
          page: this.page,
          limit: this.limit,
          search: this.search_quote_keyword
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.quotes.hits && res.data.quotes.hits.length > 0) {
              this.quotes_list.push(...res.data.quotes.hits)
              if ($state) $state.loaded()
              this.total_results = res.data.quotes.total
              this.page += 1
            } else {
              if ($state) $state.complete()
            }
          } else {
            if ($state) $state.complete()
          }
        })
        .catch((err) => {})
    }
  }
}
</script>
