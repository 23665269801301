<template>
  <div>
    <FeederGroupAddModal></FeederGroupAddModal>

    <template v-if="!hasFeederGroupsLoaded">
      <div class="mt-4">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <div v-else class="">
        <!-- groups/sources are already followed, we will show the already followed collection data -->
        <div class="discovery_article_component">
          <div class="component_inner">
            <div class="feeds-left-pane">
              <FeederSidebarView></FeederSidebarView>
              <router-view></router-view>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ExtensionWidget from '@src/modules/discovery/components/common/components/ExtensionWidget'
import FeederSidebarView from './FeederSidebarView'
const FeederGroupAddModal = () => import('../dialogs/FeederGroupAddModal')
export default {
  components: {
    FeederGroupAddModal,
    FeederSidebarView,
    ExtensionWidget
  },
  data () {
    return {
      service_calls: {
        groups: false
      }
    }
  },
  async created () {
    await this.$store.dispatch('fetchFeederGroups')
    this.$store.dispatch('fetchFeederStatistics')
    this.service_calls.groups = true
    // if coming from different routes and click on the Feeds tab.
    if (!this.feedViewGuard()) {
      if (this.$route.name === 'feeder') {
        this.$router.push({
          name: 'feeder_aggregate_view',
          params: { content_type: 'today' }
        })
      }
    }
  },
  methods: {
    /**
     * Deciding to which route should be redirected to depending on the no. of groups and their sources followed.
     */
    feedViewGuard () {
      const groupLength = this.getFeederGroupList.length
      console.log(
        '[feeder view] -> guard validation checking length ',
        groupLength
      )
      // if no groups created yet, redirect to the follow sources route.

      if (groupLength === 0) {
        this.$router.push({ name: 'feeder_follow_sources' })
        return true
      }
      // edge case, if not added any feeds to their group, redirect them to the feeder follow sources.

      if (this.hasGroupUserFeedsAvailable === 0) {
        this.$router.push({ name: 'feeder_follow_sources' })
        return true
      }
      return false
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getFeederGroupList']),

    hasFeederGroupsLoaded () {
      return this.service_calls.groups
    },
    hasGroupUserFeedsAvailable () {
      let totalAddedFeeds = 0
      this.getFeederGroupList.forEach((feed) => {
        totalAddedFeeds += feed.user_feed_details.length
      })
      console.log(
        '[feeder view] -> guard validation total added feeds',
        totalAddedFeeds
      )
      return totalAddedFeeds
    }
  },
  watch: {
    '$route.name' (value) {
      console.log(value)
      if (value === 'feeder') {
        this.feedViewGuard()
      }
    },
    async 'getActiveWorkspace._id' (value) {
      if (value) {
        const res = await this.$store.dispatch('fetchFeederGroups')
        this.$store.dispatch('fetchFeederStatistics')
        console.log('[feeder view] -> active workspace change ', res)
        // if workspace changes and feed view guard failed, pass to the today page
        if (!this.feedViewGuard()) {
          this.$router.push({
            name: 'feeder_aggregate_view',
            params: { content_type: 'today' }
          })
        }
      }
    }
  }
}
</script>
