<script setup>
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import { computed, watch } from 'vue'

const props = defineProps({
  approvalPostsValue: {
    type: Number,
    required: true,
  },
  missedApprovalPostsValue: {
    type: Number,
    required: true,
  },
  rejectedPostsValue: {
    type: Number,
    required: true,
  },
})

const chartOptions = computed(() => ({
  grid: {
    top: 20,
    left: 40,
    right: 50,
    bottom: 20,
  },
  tooltip: {
    show: true,
  },
  xAxis: {
    axisLabel: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: '#F2F3F8',
      },
    },
    type: 'category',
    data: ['Needs Approval', 'Missed Approval', 'Rejected Posts'],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#757A8A',
      fontSize: 12,
      fontWeight: 400,
      formatter: (value) => value?.toFixed(0),
    },
  },
  series: [
    {
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
      },
      barMaxWidth: 12,
      data: [
        {
          value: props.approvalPostsValue,
          name: 'Needs Approval',
          itemStyle: { color: '#90CAFF' },
        },
        {
          value: props.missedApprovalPostsValue,
          name: 'Missed Approval',
          itemStyle: { color: '#FBC956' },
        },
        {
          value: props.rejectedPostsValue,
          name: 'Rejected Posts',
          itemStyle: { color: '#D72C5C' },
        },
      ],
      type: 'bar',
    },
  ],
}))

// setting up the eChart
const { setup, chartRef } = useEcharts(chartOptions.value, ['bar'])

watch(
  () => chartRef.value,
  () => {
    setup(chartOptions.value)
  }
)
</script>

<template>
  <div class="flex-1">
    <div ref="chartRef" :style="{ height: '130px' }"></div>
  </div>
</template>