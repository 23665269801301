<script setup>
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import { watch, computed } from 'vue'
import useNumber from '@common/composables/useNumber'

const { formatNumber } = useNumber()

const {
  barChartOptions,
  channelFindVideoData,
  isReportView,
  SINGLE_GRAPH_TOOLTIPS,
} = useYoutubeAnalytics()

const noDataFound = computed(() => {
  return !channelFindVideoData.value?.length
})

watch(
  () => channelFindVideoData,
  (newValue, oldValue) => {
    if (newValue.value === oldValue) return

    const yAxisFirstIndex = Array.isArray(barChartOptions.value?.yAxis)
      ? barChartOptions.value.yAxis[0]
      : barChartOptions.value.yAxis
    const yAxisSecondIndex = Array.isArray(barChartOptions.value?.yAxis)
      ? barChartOptions.value.yAxis[1]
      : barChartOptions.value.yAxis

    barChartOptions.value.grid = {
      ...barChartOptions.value.grid,
      right: 30,
    }
    barChartOptions.value.tooltip = {
      ...barChartOptions.value.tooltip,
      formatter: tooltipFormatter,
    }
    barChartOptions.value.xAxis = {
      ...barChartOptions.value.xAxis,
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisPointer: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
      inverse: true,
    }

    barChartOptions.value.yAxis = [
      {
        ...yAxisFirstIndex,
        axisLabel: {
          ...yAxisFirstIndex.axisLabel,
          formatter: (value) => value,
        },
        type: 'category',
        name: '',
        axisPointer: {
          type: 'shadow',
        },
        data: newValue.value?.map((item) => item.name) || [],
      },
      {
        ...yAxisSecondIndex,
        name: '',
        type: 'category',
        position: 'right',
        axisLabel: {
          ...yAxisSecondIndex.axisLabel,
          formatter: (value) =>
            value !== 'null' ? `${formatNumber(value)}%` : '0%',
        },
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        data: newValue.value?.map((item) => item.perc_value) || [],
      },
    ]

    barChartOptions.value.series[0] = {
      ...barChartOptions.value.series[0],
      itemStyle: {},
      data: newValue.value?.map((item) => item.perc_value) || [],
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

const tooltipFormatter = (params) => {
  const { axisValue, value } = params[0]
  return `
           <div class="flex justify-between items-center">
            <span class="text-[979CA0] text-sm mr-1.5">${axisValue}</span>
            <span class="text-[#69788E] text-sm font-bold">${
              value ? `${formatNumber(value)}%` : '0%'
            }</span>
          </div>
`
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h2 class="text-base font-weight-500 select-none">
            How Viewers Find Your Videos
          </h2>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{ SINGLE_GRAPH_TOOLTIPS.VIDEO_DISCOVERY }}
              </p>
            </template>
          </v-menu>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? barChartOptions
              : {
                  ...barChartOptions,
                  yAxis: [
                    {
                      ...barChartOptions?.yAxis[0],
                      data: barChartOptions?.yAxis[0]?.data?.slice(-6),
                    },
                    {
                      ...barChartOptions?.yAxis[1],
                      data: barChartOptions?.yAxis[1]?.data?.slice(-6),
                    },
                  ],
                  series: [
                    {
                      ...barChartOptions?.series[0],
                      data: barChartOptions?.series[0]?.data?.slice(-6),
                    },
                  ],
                }
          "
          :is-modal="slotProps.isModal"
        />
        <p
          v-if="!slotProps.isModal && !isReportView"
          class="text-sm font-medium text-[#69788E] hover:text-[#2961D2] cursor-pointer text-center"
          @click="slotProps.showModal"
        >
          View More
        </p>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
