<script>
import { mapGetters, mapActions } from 'vuex'
import {
  TWITTER_INFLUENCER_LIST_DESCRIPTION,
  TWITTER_INFLUENCER_LIST_NAME
} from '@common/constants/messages'
import {EventBus} from "@common/lib/event-bus";

export default {
  components: {},

  data () {
    return {
      loaders: {
        save: false
      },
      influencer_list: {
        _id: null,
        name: null
        // description: null,
        // type: 'public'
      },
      validations: {
        name: false
        // description: false,
        // type: false
      },
      messages: {
        name: TWITTER_INFLUENCER_LIST_NAME,
        description: TWITTER_INFLUENCER_LIST_DESCRIPTION
      }
    }
  },

  created () {
    EventBus.$on('influencer-folder-reset', () => {
      this.influencer_list = {
        _id: null,
        name: null
        // description: null,
        // type: 'public'
      }
    })
  },
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getCreateListInfluencer'])
  },
  mounted () {},

  methods: {
    ...mapActions(['saveInfluencerFolder', 'saveShortlistInfluencer']),
    async storeList () {
      this.validations.name = this.requiredCheck(this.influencer_list.name)
      // this.validations.description = this.requiredCheck(this.influencer_list.description)
      const result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      )
      if (result) {
        this.loaders.save = true

        const folderId = await this.saveInfluencerFolder(this.influencer_list)
        if (folderId) {
          this.$parent.influencers.data[
            this.getCreateListInfluencer.index
          ].influencer_folders.push(folderId)
          const payload = {
            action: 'add',
            influencer_folder_id: folderId,
            workspace_id: this.getActiveWorkspace._id,
            screen_name: this.getCreateListInfluencer.screen_name,
            type: this.getCreateListInfluencer.type
          }
          const res = await this.saveShortlistInfluencer(payload)
          if (res && res.status) {
            this.alertMessage('Successfully added to the list.', 'success')
          }
        }

        this.loaders.save = false
      }
    }
  },
  watch: {
    'influencer_list.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
      }
    }
  }
}
</script>

<template>
  <div id="createTwitterList" class="modal fade normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>Create a new list</h2>
          <button type="button" class="close" data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body basic_form m_t_15">
          <div class="input_field mt-0">
            <label for="">Name</label>
            <div class="input_validation">
              <input
                v-model="influencer_list.name"
                :class="{ 'input-field-error': validations.name }"
                data-cy="influencer_list_name"
                name="influencer_name"
                type="text"
                placeholder="Enter list name..."
              />
              <span v-if="validations.name" class="input-error">
                {{ messages.name }}
              </span>
            </div>
          </div>
          <!--<div class="input_field">-->
          <!--<label for="">Description</label>-->
          <!--<div class="input_validation">-->
          <!--<textarea :class="{'input-field-error' : validations.description}" v-model="influencer_list.description" style="resize: none;" name=""-->
          <!--data-cy="influencer_list_desc" placeholder="Enter description..." id="" cols="30"-->
          <!--rows="4"></textarea>-->
          <!--<span class="input-error" v-if="validations.description">-->
          <!--{{messages.description}}-->
          <!--</span>-->
          <!--</div>-->

          <!--</div>-->
          <!--<div class="mt-4 mb-4 radio_list">-->
          <!--<div class="radio_input_image mr-2">-->
          <!--<input v-model="influencer_list.type" id="public_list" type="radio" name="automation_type"-->
          <!--value="public" data-cy="influencer_list_public">-->
          <!--<label for="public_list" class="radio_left">Public <span class="gray_light">Anyone can follow this list</span></label>-->
          <!--</div>-->
          <!--<div class="radio_input_image mr-2">-->
          <!--<input v-model="influencer_list.type" id="private_list" checked type="radio"-->
          <!--name="automation_type" value="private" data-cy="influencer_list_private">-->
          <!--<label for="private_list" class="radio_left">Private <span class="gray_light">Only you can access this list</span></label>-->
          <!--</div>-->
          <!--</div>-->

          <div class="input_field">
            <button
              :disabled="loaders.save"
              class="btn blue_gradient large_btn btn_loader loader_right"
              data-cy="influencer_list_create_button"
              @click="storeList"
            >
              <span>Create</span>
              <clip-loader
                v-if="loaders.save"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
