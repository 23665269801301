<script setup>
import { computed, defineProps } from 'vue'
import PerformancePostPreviewModal from '@src/modules/analytics_v3/components/PerformancePostPreviewModal.vue'
import TwitterPublishedPostPreview from '@src/modules/analytics/views/twitter/components/TwitterPublishedPostPreview.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

const props = defineProps({
  selectedPost: {
    type: Object,
    default: () => ({}),
  },
  selectedAccount: {
    type: Object,
    default: () => ({}),
  },
})

const { getSocialImageRounded, tooltipHtml } = useComposerHelper()
const { postModalFields } = useTwitterAnalytics()

const tweetTypeMap = {
  '': 'Text',
  replied_to: 'Reply',
  tweet: 'Tweet',
  quoted: 'Quoted',
}

const formattedTweetLink = computed(() => {
  try {
    return props?.selectedPost?.link
      ? new URL(props?.selectedPost.link).href
      : ''
  } catch (error) {
    console.error('Invalid URL:', props?.selectedPost?.link)
    return ''
  }
})

const getDisplayValue = (key) => {
  const selectedPost = props?.selectedPost

  if (!selectedPost) return 'Invalid Post'

  switch (key) {
    case 'tweetType':
      return tweetTypeMap[selectedPost.tweetType] ?? selectedPost.tweetType
    default:
      return selectedPost[key] ?? 'Invalid Key'
  }
}
</script>

<template>
  <PerformancePostPreviewModal
    :preview-post-data="selectedPost"
    :is-modal="false"
    :type="'post-details-modal'"
  >
    <template v-slot:modal-title>
      <h4>Post Preview</h4>
    </template>
    <template v-slot:sidebar-title>
      <p class="font-weight-500 mb-[1rem]">Statistics</p>
    </template>
    <template v-slot:sidebar-content>
      <div class="grid grid-cols-3 gap-x-2 xl:grid-cols-1">
        <template
          v-for="{ iconSrc, label, key, tooltip } in postModalFields"
          :key="key"
        >
          <div
            class="border border-cs-gray-200 rounded-xl mb-4 px-4 py-0.5 xl:px-4 xl:py-2 flex statistics-hover group bg-[#F4F6FA80]"
          >
            <img
              :src="iconSrc"
              :alt="`${label} Icon`"
              class="w-6 h-6 my-auto"
            />
            <div class="flex flex-col ml-5">
              <span
                v-tooltip="{
                  content: tooltipHtml(tooltip),
                  theme: 'light',
                  allowHTML: true,
                }"
                class="text-gray-800 group-hover:text-black-900"
              >
                {{ label }}
              </span>
              <span
                class="text-black-900 font-weight-500 text-lg mt-1 group-hover:text-blue-500 capitalize"
              >
                {{ getDisplayValue(key) }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-slot:post-preview>
      <div class="flex justify-between items-center pb-3">
        <div class="flex justify-start items-center">
          <img
            :src="selectedAccount.image"
            alt="Platform Logo"
            class="w-10 h-10 rounded-full"
            @error="
              $event.target.src =
                'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
            "
          />
          <p class="ml-3 font-weight-500 text-black-900 select-none">{{
            selectedAccount.name
          }}</p>
        </div>
        <a
          v-tooltip="{
            content:
              selectedPost.link &&
              `View post on ${selectedAccount.account_type}`,
          }"
          :href="selectedPost.link"
          target="_blank"
        >
          <img
            :src="getSocialImageRounded(selectedAccount.account_type)"
            alt="Platform Logo"
            class="h-8 w-8"
          />
        </a>
      </div>
      <TwitterPublishedPostPreview :tweet-link="formattedTweetLink" />
    </template>
  </PerformancePostPreviewModal>
</template>
