<template>
  <div class="topics-content-container">
    <div class="topics-content-container__box">
      <div class="topics-content-container__box__inner">
          <template v-if="posts_list.length">
              <template v-for="(post, index) in posts_list">
                  <div
                          v-if="$route.query.view === 'list'"
                          class="article-list-view-container"
                  >
                      <TopicsListViewItem :post="post" :index="index"/>
                  </div>
                  <div
                          v-else
                          :key="'grid_' + post._source.id"
                          class="article-grid-view-container"
                  >
                      <TopicsGridViewItem :post="post" :index="index"/>
                  </div>
              </template>
          </template>

        <InfiniteLoading
          ref="infiniteFavoritePosts"
          :identifier="infiniteId"
          @infinite="curatedPostsHandler"
        >
          <span slot="spinner" class="d-block pt-3 pb-4">
            <beat-loader :color="'#436aff'"></beat-loader>
          </span>

          <span slot="no-more" class="not_found_text">
            <NoResultsFound v-if="total_results > 20" :more="true" />
          </span>
          <span slot="no-results" class="not_found_text">
            <!-- once the request completes, we show it based on the slot content. -->
            <NoResultsFound v-if="is_request_completed_for_favorite_posts" />
          </span>
          <NoResultsFound :nore="true" />
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import proxy, { getProxyCancelToken } from '@common/lib/http-common'
import { discoveryBaseUrl } from '@src/config/api-utils'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'
import NoResultsFound from '../../NoResultsFound'
import TopicsGridViewItem from '../../../common/components/TopicsGridViewItem'
const TopicsListViewItem = () =>
  import('../../../common/components/TopicsListViewItem')

export default {
  components: {
    TopicsGridViewItem,
    TopicsListViewItem,
    InfiniteLoading,
    NoResultsFound
  },
  data () {
    return {
      limit: 20,
      page: 1,
      infiniteId: +new Date(),
      infiniteState: '',
      total_results: 0,
      posts_list: [],
      folder: { name: '' },
      is_request_completed_for_favorite_posts: false
    }
  },
  watch: {
    '$route.params.topic_id' (value) {
      this.resetCuratedWebViewFilters()
    },
    '$route.query.sort' (value) {
      this.resetCuratedWebViewFilters()
    },
    async '$route.query.to_date' (value) {
      this.resetCuratedWebViewFilters()
    },
    async '$route.query.from_date' (value) {
      this.resetCuratedWebViewFilters()
    },
    async '$route.query.topic' (value) {
      this.resetCuratedWebViewFilters()
    }
  },
  mounted () {
    EventBus.$on('archive-post-item', async (res) => {
      console.log('archive-post-item', res)
      const resArchive = await this.$store.dispatch(
        'archivePostItem',
        res.postId
      )
      if (resArchive) {
        this.posts_list.splice(
          this.posts_list.findIndex((item) => item._source.id === res.postId),
          1
        )
      }
    })
    EventBus.$on('favorite-post-item', async (payload) => {
      console.log('EventBus:favorite-post-item ', payload)
      const resFavorite = this.$store.dispatch('favoritePostItem', {
        folder_id: payload.folderId,
        post_id: payload.postId
      })
      resFavorite.then((res) => {
        if (res.data.status) {
          const post = this.posts_list.find(
            (item) => item._source.id === payload.postId
          )
          post._source.is_favorite = true
          post._source.is_active = true
          EventBus.$emit('favorite-post-item-preview')
        }
      })
    })

    EventBus.$on('unfavorite-post-item', async (payload) => {
      console.log('EventBus:unfavorite-post-item ', payload)
      const unfavorite = this.$store.dispatch('unfavoritePostItem', {
        id: payload.postId
      })
      unfavorite.then((res) => {
        if (res.data.status) {
          const post = this.posts_list.find(
            (item) => item._source.id === payload.postId
          )
          post._source.is_favorite = false
          post._source.is_active = false
        }
      })
    })
  },
  beforeUnmount () {
    EventBus.$off('archive-post-item')
    EventBus.$off('favorite-post-item')
    EventBus.$off('unfavorite-post-item')
  },
  methods: {
    curatedPostsHandler ($state) {
      this.actionFetchCuratedTopicPosts($state)
    },
    async actionFetchCuratedTopicPosts ($state) {
      this.is_request_completed_for_favorite_posts = false

      // check if the topic_id is available or not.
      // if it is not available, we get the topic data by its tag.

      let curatedTopic = {}
      if (this.$route.query.topic) {
        await proxy
          .post(`${discoveryBaseUrl}v1/topics/find`, {
            tag: this.$route.query.topic,
            workspace_id: this.$store.getters.getActiveWorkspace._id
          })
          .then((res) => {
            if (res.data.status) {
              curatedTopic = res.data.data
            }
            return res
          })
          .catch(() => null)
          .finally()
      }

      await proxy
        .post(
          `${discoveryBaseUrl}v2/topics_curated_articles`,
          {
            page: this.page,
            limit: this.limit,
            topic_id: this.$route.params.topic_id,
            topic: curatedTopic,
            workspace_id: this.$store.getters.getActiveWorkspace._id,
            is_followed: this.$route.query.is_followed || false,
            sort: this.$route.query.sort || 'most_shared',
            from_date:
              this.$route.query.from_date ||
              moment.utc().subtract(30, 'day').format('YYYY-MM-DD'),
            to_date:
              this.$route.query.to_date || moment.utc().format('YYYY-MM-DD')
          },
          getProxyCancelToken(proxy)
        )
        .then((res) => {
          if (res.data.status) {
            if (res.data.posts && res.data.posts.length > 0) {
              this.posts_list.push(...res.data.posts)
              this.$parent.$parent.curated_topic = res.data.topic
              if ($state) $state.loaded()
              this.$parent.$parent.total_results = res.data.total
              this.page += 1
            } else {
              if ($state) $state.complete()
            }
          } else {
            if ($state) $state.complete()
          }
        })
        .catch((err) => {})
      this.is_request_completed_for_favorite_posts = true
    },
    resetCuratedWebViewFilters () {
      this.page = 1
      this.posts_list = []
      this.$parent.$parent.total_results = 0
      this.state = ''
      this.infiniteState = ''
      this.infiniteId += 1
    }
  }
}
</script>
