<template>
  <div
      class="planner-calender-main"
      :class="{
      loader_overlay_with_loader: isFetchingPlans,
    }"
  >
    <beat-loader v-if="isFetchingPlans" :color="'005fd0'"></beat-loader>

    <transition name="fade-list-item">
      <template v-if="showNullPlansSection">
        <div class="warning_box text-center mx-0">
          <p>No posts found for the selected filters and date-range.</p>
        </div>
      </template>
    </transition>

    <FullCalendar
        class="planner-app-calendar"
        ref="calendar"
        :options="{
        ...calendarOptions,
        timeZone: getActiveWorkspace?.timezone,
        events: plans,
        initialView: getCalendarViewPreference,
        buttonHints: {
          today: todayToolTipText,
        },
      }">
      <template v-slot:eventContent="arg">
        <CalendarEvent
            :key="arg.event.extendedProps?._id"
            :item="arg.event.extendedProps"
            :plan="plans.filter((plan) => plan._id === arg.event.extendedProps?._id)?.[0]"
        ></CalendarEvent>
      </template>
    </FullCalendar>
  </div>

  <b-modal
      id="post-status-modal"
      size="lg"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      dialog-class="max-w-7xl"
  >
    <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
  </b-modal>
</template>

<script>
// Core Imports
import { mapGetters } from 'vuex'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
// Full Calendar Imports
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import useDateFormat from "@common/composables/useDateFormat";
import { CC_POST_RESCHEDULE_PLAN_ERROR } from '@common/constants/messages'
import { getPlanMedia } from '@common/lib/planner'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import { pusherSocketPublish } from '@common/lib/pusher'
import debounce from 'lodash/debounce'
import { planner } from '@src/modules/planner/store/mutation-types'
import proxy from '@common/lib/http-common'
import { plannerDefaultCalendarView } from '@src/modules/publish/config/api-utils'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import CalendarEvent from '../components/CalendarEvent'
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
FullCalendar.compatConfig = {
  MODE: 3,
}
CalendarEvent.compatConfig = {
  MODE: 3,
}

const clone = require('rfdc/default')

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEvent,
    PlannerPostStatus,
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    isPlatformSelected: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'calendar-date-change',
    'handle-query-change',
    'replace-plan',
    'reject-with-comment',
    'preview-plan',
    'approve-with-comment',
  ],
  setup() {
    const { isEditablePost, canEditThisPost } = usePlannerHelper()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    const { momentWrapper} = useDateFormat()
    return {
      isEditablePost,
      canEditThisPost,
      openDraftComposer,
      draftPlanId,
      momentWrapper
    }
  },
  data() {
    return {
      todayToolTipText: 'Current Week',
      selectedPost: null,
      isInitialDatesSet: true,
      calendarDefaultView: this.getCalendarViewPreference,
      calendarOptions: {
        // Plugins
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
          momentTimezonePlugin,
        ],
        // Components Options
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },

        // Custom
        customButtons: {
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('clicked the custom button!')
            },
          },
        },

        // Views Options
        editable: true,
        // selectable: true,
        // selectMirror: true,
        dayMaxEvents: 10,
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: {weekday: 'long'},
        dayCellContent: this.handleDayCellContent,
        views: {
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        datesSet: this.handleDatesSet,
        viewDidMount: this.handleViewDidMount,
        // dayCellContent: this.handleDayCellContent,
        // dayCellClassNames: 'h-100 fc-options-hover',
        dayCellClassNames: 'fc-cell',
        dayHeaders: true,

        // Events Options
        initialEvents: [],
        events: [],
        eventColor: 'transparent',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDisplay: 'block',
        eventDrop: this.reschedulePlan,
        firstDay: null,
        // eventContent: this.handleEventContent
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
      },
      currentEvents: [],
      labels_channel: '',
      previousTopOffset: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getPlannerLoaders',
      'getPublishSelection',
      'getWorkspaceDetails',
      'getWorkspaces',
      'getCalendarViewPreference',
      'getActiveWorkspace'
    ]),
    isFetchingPlans() {
      return this.loader
    },
    planItemsCount() {
      return this.plans.length
    },
    /* isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    }, */
    showNullPlansSection() {
      return !this.isFetchingPlans && !this.planItemsCount
    },
  },
  watch: {
    showNullPlansSection(newValue) {
      if (newValue) {
        this.$set(this.calendarOptions, 'events', this.plans)
      }
    },
    plans(newValue) {
      this.$nextTick(() => {
        const scrollerElements = document.querySelectorAll('.fc-scroller')
        if (scrollerElements.length > 1) {
          const scrollerElement = scrollerElements[1]

          scrollerElement.scrollTo({
            top: this.$store.state.planner.calendarScrollPosition,
            behavior: 'smooth',
          })
        }
      })
    },
    scroll(val) {
      debounce(() => {
        this.handleTableHeight()
      }, 650)()
    },
    'getWorkspaces.activeWorkspace.first_day'(firstDay) {
      this.calendarOptions.firstDay = firstDay?.day ? firstDay.key : 1
    },
  },
  created() {
    const query = Object.assign({}, this.$route.query)
    delete query.order_by
    this.$router.push({query})
  },
  mounted() {
    this.calendarOptions.firstDay = this.getWorkspaces.activeWorkspace.first_day
        ? this.getWorkspaces.activeWorkspace.first_day.key
        : 1
    EventBus.$on('post-status-modal', (post) => {
      this.selectedPost = post
      this.$bvModal.show('post-status-modal')
    })

    this.$nextTick(() => {
      this.labels_channel = pusherSocketPublish.subscribe(
          `labels_${this.getActiveWorkspace._id}`
      )
      this.bindPusherLabels(this.labels_channel)
    })

    this.$nextTick(() => {
      this.handleTableHeight()
    })
    window.addEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.addEventListener('scroll', this.handleScroll)
    }

    EventBus.$on('empty-calendar-events', () => {
      this.calendarOptions.events = [];
      this.removeEventCalendar();
    })
  },

  beforeUnmount() {
    delete this.$route.query.date
    window.removeEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.removeEventListener('scroll', this.handleScroll)
    }
    this.$store.commit(planner.SET_CALENDAR_SCROLL_POSITION, 0)

    EventBus.$off('post-status-modal')

    EventBus.$off('empty-calendar-events')

    // setting user default calendar view ['month','week'] based on user Selection
    if (this.calendarDefaultView !== this.getCalendarViewPreference) {
      this.setPlannerDefaultCalendarView(this.calendarDefaultView)
    }
  },
  methods: {
    async removeEventCalendar() {
      if (this.$refs?.calendar) {
        console.log('removeEventCalendar');
        const calendarApi = this.$refs.calendar?.getApi();
        if (calendarApi) {
          console.log('removeEventCalendar: removeAllEvents');
          await calendarApi.removeAllEvents();
        }
      }
    },
    handleTableHeight() {
      if (this.$el && this.$el instanceof HTMLElement) {
        const offset = this.$el.getBoundingClientRect()
        this.previousTopOffset = offset
        const scroll =
            window.scrollY ||
            window.scrollTop ||
            document.getElementsByTagName('html')[0].scrollTop
        this.$el.style.height = `calc(100vh - ${
            scroll ? this.previousTopOffset.top : offset.top
        }px)`
      }
    },
    // https://preactjs.com/guide/v8/api-reference/ - createElement Guide
    handleDayCellContent(arg, createElement) {
      const day = this.momentWrapper(arg.date).formatTimezone().format('D')
      const wrapper = document.createElement('div')
      const dayContent = document.createElement('span')
      dayContent.className = arg.isToday
          ? 'fc-daygrid-day-no active'
          : 'fc-daygrid-day-no'
      dayContent.textContent = day
      if (
          (arg.isFuture || arg.isToday) &&
          !arg.isOther &&
          this.hasPermission('can_access_top_header')
      ) {
        const post = document.createElement('i')
        post.className = 'fas fa-plus'
        post.title = 'New Social Post'
        post.addEventListener('click', (e) =>
            this.createContent(e, 'post', arg.isToday, arg.date)
        )
        const blog = document.createElement('i')
        blog.className = 'fas fa-file'
        blog.title = 'New Blog Post'
        blog.addEventListener('click', (e) =>
            this.createContent(e, 'blog', arg.isToday, arg.date)
        )
        const icons = document.createElement('span')
        icons.className = 'fc-daygrid-top-icon'
        icons.appendChild(post)
        icons.appendChild(blog)

        wrapper.appendChild(icons)
        wrapper.appendChild(dayContent)
        return {domNodes: [wrapper]}
      }
      wrapper.appendChild(dayContent)
      return {domNodes: [wrapper]}
    },
    async createContent(e, type, isToday, date) {
      if(type === 'post') {
        const res = await this.openDraftComposer(
            '⚠️ Unfinished Post is in the Composer!',
            'You have a post currently in the composer that is minimized. What would you like to do?',
            'Save & Create New',
            'Return to Composer'
        )
        if(res === null) {
         return
        } else if(!res) {
          this.changePublishTypeFromCalender(type, isToday, date)
        } else {
          const workspace = this.$route.params.workspace
          history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
          this.$bvModal.show('composer-modal')
        }
        return
      }
      this.changePublishTypeFromCalender(type, isToday, date)
    },
    handleDayClassNames(arg) {
      console.log('handleDayClassNames')
    },
    handleDateClick(arg) {
      alert('date click! ' + arg.dateStr)
    },
    handleViewDidMount(event) {
      this.fetchPlansWithCalendarDate(event)
    },

    async handleDatesSet(event) {
      await this.fetchPlansWithCalendarDate(event)
      if (this.isInitialDatesSet) {
        this.isInitialDatesSet = false
        return
      }
      this.$emit('handle-query-change')
    },
    async fetchPlansWithCalendarDate(event) {
      this.calendarDefaultView =
          event?.view?.type || this.getCalendarViewPreference

      this.todayToolTipText =
          event?.view?.type === 'dayGridWeek' ? 'Current Week' : 'Current Month'

      const start = this.momentWrapper(event.view.activeStart).format('MMMM DD, YYYY')
      const end =  this.momentWrapper(event.view.activeEnd).format('MMMM DD, YYYY')

      // remove all events from calendar before fetching new events
      this.calendarOptions.events = [];
      this.removeEventCalendar();

      const date = clone(start + ' - ' + end)
      this.$emit('calendar-date-change', date)

      await this.$router.push({
        query: {...this.$route.query, date},
      })
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
    },
    async handleEventClick(info) {
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      if (event) {
        item.stateObject = this
        switch (event) {
          case 'viewItemAttachment':
            // eslint-disable-next-line no-case-declarations
            let plan = target.getAttribute('data-plan')
            if (plan) {
              // finding plan from states
              plan = item.stateObject.plans.find((item) => item._id === plan)
              if (plan) {
                // checking for plan media
                const attachment = getPlanMedia(plan)
                if (attachment) {
                  EventBus.$emit('displayFile', {
                    type: attachment.type,
                    media: attachment.media,
                    index: 0,
                  })
                  item.stateObject.$bvModal.show('display-file-modal')
                }
              }
            }
            break
          case 'editCalendarItem':
            const resp = await this.openDraftComposer(
                '⚠️ Unfinished Post is in the Composer!',
                'You have a post currently in the composer that is minimized. What would you like to do?',
                'Save & Edit Selected',
                'Return to Composer'
            )
            if(resp === null) {
              return
            } else if(!resp) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('editPlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
              this.$bvModal.show('composer-modal')
            }
            break
          case 'deleteCalendarItem':
            if (item?.post_state === 'published' && !item?.blog_reference) {
              await this.fetchPlanAccounts(item?._id)
              this.$bvModal.show('delete-post-modal')
              return
            }
            // eslint-disable-next-line no-case-declarations
            const res = await this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this post?', {
                  title: 'Remove Post',
                  ...swalAttributes(),
                })
                .then((res) => res)
                .catch(() => null)

            if (res) {
              await this.$store.dispatch('removePlan', {
                id: item._id,
              })
            }
            break
          case 'replaceCalendarItem':
            this.$emit('replace-plan', item)
            break
          case 'approveCalendarItem':
            this.changePlanStatusMethod('scheduled', item, true)
            break
          case 'rejectCalendarItem':
            this.changePlanStatusMethod('rejected', item, true)
            break
          case 'duplicateCalendarItem':
            const response = await this.openDraftComposer(
                '⚠️ Unfinished Post is in the Composer!',
                'You have a post currently in the composer that is minimized. What would you like to do?',
                'Save & Create Duplicate',
                'Return to Composer'
            )
            if(response === null) {
              return
            } else if(!response) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('clonePlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
              this.$bvModal.show('composer-modal')
            }
            break
          case 'calendarItemCheckbox':
            // eslint-disable-next-line no-case-declarations
            const planId = target.getAttribute('value')
            if (target.checked) {
              this.getPlans.selected_plans.push(planId)
            } else {
              this.getPlans.selected_plans.splice(
                  this.getPlans.selected_plans.indexOf(planId),
                  1
              )
            }
            break
          case 'approvalCalendarItemReject':
            this.$emit('reject-with-comment', item)
            break
          case 'approvalCalendarItemApprove':
            this.$emit('approve-with-comment', item)
            break
        }
      } else {
        this.$emit('preview-plan', item._id)
      }
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    async reschedulePlan(info) {
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      item.stateObject = this
      if (!this.canEditPlan(item)) {
        info.revert()
        return false
      }
      item.start = this.momentWrapper(info.event.start)
      item.startStr = info.event.startStr // startStr YYYY-MM-DD
      if (item.content_category_id && item.content_category_id.length > 0) {
        this.$store.dispatch('toastNotification', {
          message: CC_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        info.revert()
        return false
      }
      // Allow only draft or scheduled or reviewed post to reschedule
      if (
          item.post_state !== 'draft' &&
          item.post_state !== 'scheduled' &&
          item.post_state !== 'reviewed'
      ) {
        this.$store.dispatch('toastNotification', {
          message:
              'You can only reschedule a draft or scheduled or in review post.',
          type: 'error',
        })
        info.revert()
        return false
      }

      const status = await this.$store.dispatch('reschedulePlan', item)
      if (!status) info.revert()
    },
    async handleScroll(event) {
      debounce(() => {
        const scrollPosition = event.target.scrollTop
        if (!this.isFetchingPlans) {
          this.$store.commit(
              planner.SET_CALENDAR_SCROLL_POSITION,
              scrollPosition
          )
        }
      }, 500)()
    },
    /**
     * Sets user default calendar View ['month','week'] based on user Selection
     * @param defaultView
     */
    setPlannerDefaultCalendarView(defaultView) {
      const self = this
      proxy
          .post(plannerDefaultCalendarView, {
            planner_default_calendar_view: defaultView,
          })
          .then(() => {
            self.$store.dispatch('setDefaultCalendarView', defaultView)
          })
          .catch((err) => {
            console.log('PROXY::plannerDefaultView ~ err -> ', err)
          })
    },
    canEditPlan(item) {
      return this.isEditablePost(item) && this.canEditThisPost(item)
    },
  },
  beforeRouteLeave(to, from, next) {
      if (this.$refs?.calendar) {
        const calendarApi = this.$refs.calendar?.getApi()
        if (calendarApi) {
          calendarApi.removeAllEvents()
          calendarApi.destroy()
        }
      }
    this.$nextTick(() => {
      next()
    })
  },
}
</script>

<style lang="scss">
// @import '~@fullcalendar/common/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
</style>
