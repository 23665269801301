import { mapActions, mapGetters, mapMutations } from 'vuex'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { getPocketContentURL } from '../../config/api-utils'
const pocketMixin = {
  methods: {
    ...mapMutations(['SET_TOPIC_DISABLED']),
    ...mapActions([
      'setPocketPosts',
      'setPocketLoader',
      'setPocketScroll',
      'setPocketPaginationLoader',
      'setPocketPage',
      'setPocketContentAccount',
      'setPocketSort',
      'setPocketType'
    ]),
    getPocketContent ($state = null) {
      this.SET_TOPIC_DISABLED(true)
      const payload = {
        filters: this.getPocketFilter,
        search: this.getFilters.search,
        workspace_id: this.getWorkspaces.activeWorkspace._id
      }
      if (payload) {
        this.postRequest(
          getPocketContentURL,
          payload,
          (response) => {
            if (response.data.status) {
              this.SET_TOPIC_DISABLED(false)

              if (this.getPocketPage === 0) {
                this.setPocketPosts([])
              }

              if (!response.data.pocket || response.data.pocket.length === 0) {
                response.data.pocket = []
              }

              if (this.getPocketPage >= 1) {
                this.setPocketPosts(
                  this.getPocketPosts.concat(response.data.pocket)
                )
              } else {
                this.setPocketPosts(response.data.pocket)
              }

              this.setPocketPaginationLoader(true)
              if (response.data.pocket.length > 0) {
                this.setPocketScroll(true)
                if ($state) {
                  $state.loaded()
                }
              } else {
                this.setPocketScroll(false)
                try {
                  if ($state) {
                    $state.complete()
                  }
                } catch (ex) {
                  console.log(ex)
                }
              }

              this.setPocketLoader(false)
              this.checkComposerDragDrop()
            }
          },
          (response) => {
            this.alertMessage(UNKNOWN_ERROR, 'error')
          }
        )
      }
    },
    resetPocketFilter () {
      this.setPocketPage(0)
      this.setPocketScroll(true)
      this.setPocketPosts([])
      this.setPocketPaginationLoader(false)
      this.setPocketLoader(true)
      this.getPocketContent()
    },
    selectPocketAccount (account) {
      console.debug('Method:selectPocketAccount')
      this.setPocketContentAccount(account)
      this.resetPocketFilter()
    },

    changePocketSort (name, code) {
      console.debug('Method:changePocketSort')
      this.setPocketSort({ code, name })
      this.resetPocketFilter()
    },
    changePocketType (name, code) {
      console.debug('Method:changePocketType')
      this.setPocketType({ code, name })
      this.resetPocketFilter()
    },
    getAuthorNames (authors) {
      let authorsName = ''

      for (const prop in authors) {
        if (authorsName === '') {
          authorsName = authors[prop].name
        } else {
          authorsName += ',' + authors[prop].name
        }
      }

      return authorsName
    },
    pocketContentType (content) {
      if (content.is_article == '1') {
        return 'Article'
      } else if (content.videos) {
        return 'Video'
      } else {
        // custom filter for articles
        if (content.description) {
          return 'Article'
        }

        return 'Image'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPocketFilter',
      'getFilters',
      'getWorkspaces',
      'getPocketPage'
    ])
  }
}
export { pocketMixin }
