<template>
  <div>
    <div
      v-for="(photo, key) in getImagesPosts"
      :key="key"
      class="assistant_box _pixabay _photo"
      :class="{ dragable_box: !($route.name === 'composerBlog') }"
    >
      <div
        class="box_inner"
        :draggable="$route.name === 'composerBlog'"
        @dragstart="dragstartHandler($event, photo)"
      >
        <div
          class="image_block"
          @click="previewImage(backgroundPixabayImageURL(photo))"
        >
          <div class="play_icon">
            <i class="cs-eye"></i>
          </div>
          <div
            v-lazy:background-image="backgroundPixabayImageURL(photo)"
            class="img"
          ></div>
        </div>

        <div class="social_stats">
          <div class="social_stats_inner d-flex">
            <div v-tooltip.top="'View'" class="stat_item">
              <div class="name">
                <p>
                  <i class="cs-eye"></i>
                </p>
              </div>
              <div class="value">
                <h3>{{ intToString(photo['views']) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!--Editor Html DON'T REMOVE-->
        <div
          class="hide d-none editor_pixabay_block editor_dragable_box editor_image_block"
          contenteditable="false"
        >
          <div class="editor_dragable_box_inner clear">
            <div class="top_option no_flickr">
              <div class="right_block">
                <i
                  class="handler fa fa-hand-paper-o editor_tooltip"
                  title="Move"
                ></i>
                <i
                  class="edit_box fa fa-check-circle editor_tooltip green"
                  title="Merge"
                ></i>
                <i
                  class="remove_box fa fa-times-circle editor_tooltip red"
                  title="Remove"
                ></i>
              </div>
            </div>

            <div id="" class="align_box" style="text-align: center">
              <div class="inner_embed">
                <div class="custom_loader">
                  <div class="loader_inner"></div>
                </div>
                <br />
                <img
                  style="max-width: 100%"
                  class="hide"
                  :src="backgroundPixabayImageURL(photo)"
                  alt=""
                />
              </div>
            </div>
            <div class="clearfix"><br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getImagesPosts'])
  },
  created () {},
  methods: {
    ...mapActions([]),
    backgroundPixabayImageURL (photo) {
      return photo.webformatURL
    },
    dragstartHandler (ev, photo) {
      // Add the target element's id to the data transfer object

      console.log('dragstartHandler', photo)

      ev.dataTransfer.setData(
        'myData',
        JSON.stringify({
          id: ev.target.id,
          type: 'image',
          heading: '',
          media: this.backgroundPixabayImageURL(photo),
          p: photo.tags || '',
          link: this.backgroundPixabayImageURL(photo)
        })
      )
    }
  }
}
</script>
