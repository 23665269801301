<template>
  <div class="table_box">
    <table>
      <thead>
        <th>
          Website
          <i
            class="sorting_icon cs-arrow-up"
            :class="{ active: sortByRow === 'key' && sortByOrder === 'asc' }"
            @click.prevent="changeSortOrder('key', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{ active: sortByRow === 'key' && sortByOrder === 'desc' }"
            @click.prevent="changeSortOrder('key', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center">
          Total Articles
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active: sortByRow === 'doc_count' && sortByOrder === 'asc',
            }"
            @click.prevent="changeSortOrder('doc_count', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active: sortByRow === 'doc_count' && sortByOrder === 'desc',
            }"
            @click.prevent="changeSortOrder('doc_count', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center">
          Total Engagement
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active:
                sortByRow === 'total_interactions.value' &&
                sortByOrder === 'asc',
            }"
            @click.prevent="changeSortOrder('total_interactions.value', 'asc')"
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active:
                sortByRow === 'total_interactions.value' &&
                sortByOrder === 'desc',
            }"
            @click.prevent="changeSortOrder('total_interactions.value', 'desc')"
          >
          </i>
        </th>
        <th class="make-text-center">
          Avg. Engagement
          <i
            class="sorting_icon cs-arrow-up"
            :class="{
              active:
                sortByRow === 'average_interactions.value' &&
                sortByOrder === 'asc',
            }"
            @click.prevent="
              changeSortOrder('average_interactions.value', 'asc')
            "
          >
          </i>
          <i
            class="sorting_icon cs-arrow-down"
            :class="{
              active:
                sortByRow === 'average_interactions.value' &&
                sortByOrder === 'desc',
            }"
            @click.prevent="
              changeSortOrder('average_interactions.value', 'desc')
            "
          >
          </i>
        </th>
      </thead>
      <tbody>
        <template
          v-for="(item, index) in orderedList()"
          :key="index"
        >
          <tr
v-if="
            articles_popular_domains.domains_list &&
            articles_popular_domains.domains_list.length > 0 &&
            item.total_interactions.value >= 0
          " >
            <td>
              {{ item.key }}
            </td>
            <td class="make-text-center">
              {{ item.doc_count }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.total_interactions.value) || 0 }}
            </td>
            <td class="make-text-center">
              {{ intToString(item.average_interactions.value) || 0 }}
            </td>
          </tr>
        </template>

        <tr
          v-if="
            articles_popular_domains.domains_list &&
            articles_popular_domains.domains_list.length === 0
          "
        >
          <td> No data found for the top domains. </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  props: {
    articles_popular_domains: {}
  },
  data () {
    return {
      sortByRow: 'doc_count',
      sortByOrder: 'desc'
    }
  },
  methods: {
    changeSortOrder (row, order) {
      this.sortByRow = row
      this.sortByOrder = order
    },

    orderedList () {
      return orderBy(
        this.articles_popular_domains.domains_list,
        [this.sortByRow],
        [this.sortByOrder]
      )
    }
  }
}
</script>
