<template>
  <div
    v-if="getTopicSelection.sources == 'domains_and_sources'"
    class="multi_select"
  >
    <div class="d-flex align-items-center">
      <p class="sub_label"
        ><span class="bold">INCLUDE</span> results from these domains
      </p>
      <div class="ml-auto d-flex">
        <button
          v-if="
            getTopicSelection.include_domains.length > 0 ||
            (includeDomainsValue !== '' &&
              includeDomainsValue.replace(/^\s+/, '') !== '')
          "
          class="reset_btn mt-2"
          @click="clearAllKeywords"
          ><i class="fa fa-times"></i> Clear</button
        >
        <i
          v-tooltip="'Find a domain name and preview content on the right side'"
          class="mb-0 icon-help-cs float-right help_label"
        ></i>
      </div>
    </div>

    <div class="multi_input">
      <input
        v-model="includeDomainsValue"
        class="prevent-listener"
        type="text"
        placeholder="Enter your main domains such as cnn.com, bbc.com"
        @focus="asyncIncludeDomainResults(includeDomainsValue)"
        @input="asyncIncludeDomainResults(includeDomainsValue)"
        @keyup.enter="handleKeyEnter($event)"
        @keyup.188="
          addAnyKeyword(
            getTopicSelection.include_domains,
            includeDomainsValue,
            'include_domains',
          )
        "
      />
      <p class="placeholder">Press ‘ , ’ or ‘Enter’ to add</p>
      <div
        v-if="isIncludeDomainsLoading && includeDomainsValue.length >= 3"
        v-click-away="closeDropdown"
        class="multi_dropdown"
      >
        <div class="multi_dropdown_inner">
          <ul>
            <template
              v-for="(
                suggested_domain, index
              ) in include_domains_suggestion_list"
              :key="index"
            >
              <li
                class="item d-flex align-items-center"
                @click.prevent="addIncludeDomain(suggested_domain, index)"
              >
                <span
                  v-if="!suggested_domain.is_parent"
                  class="icon world_icon_size"
                >
                  <i class="cs-rss"></i>
                </span>
                <span v-else-if="suggested_domain.favicon_16" class="icon">
                  <img :src="suggested_domain.favicon_16" alt="" />
                </span>
                <span v-else class="icon world_icon_size">
                  <i class="cs-world-o"></i>
                </span>
                <span class="text">
                  <!--<span class="heading">{{suggested_domain.name}}</span>-->
                  <span
                    class="url"
                    v-html="
                      highlightText(suggested_domain.url, includeDomainsValue)
                    "
                  ></span>
                </span>
                <span class="view">
                  <i v-tooltip="'View'" class="fa fa-eye"></i>
                </span>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div
        v-else-if="includeDomainsLoader"
        v-click-away="closeDropdown"
        class="multi_dropdown"
      >
        <div
          class="multi_dropdown_inner min_height d-flex align-items-center justify-content-center"
        >
          <beat-loader :color="'#436aff'"></beat-loader>
        </div>
      </div>
    </div>
    <template v-if="getTopicSelection.include_domains">
      <div class="multi_tags">
        <ul>
          <li
            v-for="(
              include_keyword, index
            ) in getTopicSelection.include_domains"
            :key="index"
            class="green"
          >
            <span class="value">{{ include_keyword }}</span>
            <span
              class="remove"
              @click.prevent="
                removeKeyword(getTopicSelection.include_domains, index)
              "
              >&times;</span
            >
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      includeDomainsValue: '',
      isIncludeDomainsLoading: false,
      include_domains_suggestion_list: [],
      includeDomainsLoader: false
    }
  },
  methods: {
    closeDropdown () {
      this.isIncludeDomainsLoading = false
    },
    clearAllKeywords () {
      this.includeDomainsValue = ''
      this.getTopicSelection.include_domains = []
      this.includeDomainsLoader = false
    },
    handleKeyEnter(event) {
      this.asyncIncludeDomainResults(this.includeDomainsValue)
      this.addAnyKeyword(
          this.getTopicSelection.include_domains,
          this.includeDomainsValue,
          'include_domains',
          event,
      )
    }
  },
  computed: {
    ...mapGetters(['getTopicSelection'])
  },
  watch: {
    includeDomainsValue (value) {
      if (value.length == 0) {
        this.include_domains_suggestion_list = []
      }
    }
  }
}
</script>
