<template>
  <ConfirmBox
    id="removeEvergreenVariation"
    :call-back-action="removeEvergreenVariation"
    confirm-text="Yes"
    cancel-text="No"
  >
    <template v-slot:header>
      <h2>Remove Variation</h2>
    </template>

    <div slot="footer" class="text-center">
      <p>You won't be able to undo.</p>
    </div>
  </ConfirmBox>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmBox from '@common/components/dialogs/ConfirmBox'

export default {
  components: {
    ConfirmBox
  },

  computed: {
    ...mapGetters(['getEvergreenSelection'])
  },

  methods: {
    ...mapActions(['setConfirmActionStage']),
    removeEvergreenVariation (confirmation, variation) {
      console.debug('Method:removeEvergreenVariation', confirmation, variation)

      if (confirmation.status) {
        this.getEvergreenSelection.posts[variation.postIndex].splice(
          variation.variationIndex,
          1
        )
        if (
          this.getEvergreenSelection.posts[variation.postIndex].length === 0
        ) {
          this.getEvergreenSelection.posts.splice(variation.postIndex, 1)
        }
        this.setConfirmActionStage('success')
        this.closeConfirmAction(confirmation.type)
      } else {
        // eslint-disable-next-line no-undef
        $('#' + confirmation.type).modal('hide')
      }
    }
  }
}
</script>
