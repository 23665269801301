<script>
import { mapGetters, mapActions } from 'vuex'
import CanvaTypes from '@src/modules/publish/components/posting/CanvaTypes'
import CrelloTypes from '@src/modules/publish/components/posting/CrelloTypes'
import { EventBus } from '@common/lib/event-bus'
import usePermissions from "@common/composables/usePermissions";
import Hashtag from './Hashtag'
import ReplugDropdown from './ReplugDropdown'
import Utm from './Utm'
const LocationDropdown = () =>
  import(
    '@src/modules/publish/components/posting/social/options/LocationDropdown'
  )

export default {
  components: {
    CanvaTypes,
    LocationDropdown,
    Utm,
    Hashtag,
    ReplugDropdown,
    CrelloTypes,
  },
  setup(){
    const {hideVista} = usePermissions()
    return {
      hideVista
    }
  },
  props: {
    type: {
      type: String,
      default: 'Common',
    },
    evergreenBox: {
      type: String,
      default: 'true',
    },
  },

  computed: {
    ...mapGetters([
      'getPublishSelection',
      'getAccountSelection',
      'getCommonSharingDetails',
      'getPinterestSharingDetails',
      'getPublishLoaders',
      'getEvergreenSelection',
    ]),
    emojiComponent() {
      return () =>
        import('@src/modules/publish/components/posting/social/emoji/Emoji.vue')
    },

    getSharingImageUploadId() {
      return `${this.type.toLowerCase()}SharingImageUpload`
    },

    getSharingImageUpdateId() {
      return `${this.type.toLowerCase()}SharingImageUpdate`
    },

    getSharingVideoUploadId() {
      return `${this.type.toLowerCase()}SharingVideoUpload`
    },

    getSharingSourceUrl() {
      if (this.type === 'Pinterest') {
        return this.getPinterestSharingDetails.source_url
      }
      return this.getCommonSharingDetails.source_url
    },

    getSharingSourceLoader() {
      if (this.type === 'Pinterest') {
        return this.getPublishLoaders.shrinkPinterestSharingSourceLink
      }
      return this.getPublishLoaders.shrinkCommonSharingSourceLink
    },

    locationVisibilityStatus() {
      if (this.type === 'Common') {
        return !!(
          this.getAccountSelection.twitter.length ||
          this.getAccountSelection.facebook.length ||
          this.getAccountSelection.instagram.length
        )
      }
      return !!['Twitter', 'Facebook', 'Instagram'].includes(this.type)
      // if (this.type === 'Common') return !!(this.getAccountSelection.facebook.length > 0 || this.getAccountSelection.twitter.length)
      // return !!['Facebook', 'Twitter'].includes(this.type)
    },

    getCharCountForHashtags() {
      return this.getCommonSharingDetails.message.length
    },
  },
  methods: {
    ...mapActions(['uploadSharingVideo', 'setCommonSharingDetails']),

    triggerSharingVideoUpload(selector) {
      console.debug('Method:triggerSharingVideoUpload', selector)
      document.getElementById(selector).click()
    },

    showMediaModal(source) {
      console.debug('method:showMediaModal', source)

      EventBus.$emit('show-media-library-modal', { source, details: {},  sideTabIndex: 1 })

      // EventBus.$emit('mediaLibrary', { source: source })
      // this.$bvModal.show('insertFileModel')

      // $('#insertFileModel').modal('show')
    },
    /**
     * Open save caption modal
     */
    openSaveCaptionModal() {
      EventBus.$emit('save-caption-modal', {
        caption: this.getCommonSharingDetails.message,
        isOldComposer: true,
        source: 'Common',
      })
    },
  },
}
</script>

<template>
  <div class="bottom_option">
    <div
      v-if="
        type === 'Pinterest' ||
        (type === 'Common' && getAccountSelection.pinterest.length > 0)
      "
      class="shrink_link d-flex align-items-center"
    >
      <label for="">Source URL for Pinterest</label>
      <div class="search_input with_icon_right d-flex align-items-center">
        <div class="search_inner w-100">
          <input
            v-if="type === 'Pinterest'"
            v-model="getPinterestSharingDetails.source_url"
            type="text"
            required=""
            complete="off"
            placeholder="Add Pinterest source link..."
          />
          <input
            v-else
            v-model="getCommonSharingDetails.source_url"
            type="text"
            required=""
            complete="off"
            placeholder="Add Pinterest source link..."
          />

          <button
            v-if="getSharingSourceUrl && !isShortLink(getSharingSourceUrl)"
            v-tooltip.top="'Shrink'"
            class="search_btn input_icon search_icon gradient_color loader_right btn_loader btn"
            :disabled="getSharingSourceLoader"
            :class="{ disabled: getSharingSourceLoader }"
            @click.prevent="shrinkSharingSourceLink(type, getSharingSourceUrl)"
          >
            <span>Shrink</span>
            <clip-loader
              v-if="getSharingSourceLoader"
              :color="'#ffffff'"
              :size="'12px'"
            ></clip-loader>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <form method="post" enctype="multipart/form-data" class="d-none">
        <input
          :id="getSharingImageUploadId"
          type="file"
          name="socialFile"
          accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
          :multiple="
            [
              'Common',
              'Facebook',
              'Twitter',
              'Linkedin',
              'Instagram',
              'Tiktok',
            ].indexOf(type) >= 0
          "
          data-index=""
          @change="uploadSharingImage($event, type)"
        />
      </form>

      <form method="post" enctype="multipart/form-data" class="d-none">
        <input
          :id="getSharingImageUpdateId"
          type="file"
          name="socialFileUpdate"
          accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
          data-index=""
          @change="processsUpdateSharingImage($event, type)"
        />
      </form>

      <form method="post" enctype="multipart/form-data" class="d-none">
        <input
          :id="getSharingVideoUploadId"
          type="file"
          name="socialFile"
          accept=".avi,.mov,.m4v,.mp4"
          data-index=""
          @change="uploadSharingVideo({ event: $event, type: type })"
        />
      </form>

      <div
        class="o_left d-flex align-items-center"
        :class="{ disable_compose_icon: sharingBoxPendingProcessStatus(type) }"
      >
        <i
          v-if="type !== 'Youtube' && type !== 'Tiktok'"
          v-tooltip.top="'Add Image'"
          class="min_width far fa-image"
          @click.prevent="triggerSharingImageUpload(getSharingImageUploadId)"
        >
        </i>
        <i
          v-if="
            type === 'Common' ||
            type === 'Facebook' ||
            type === 'Twitter' ||
            type === 'Linkedin' ||
            type === 'Tumblr' ||
            type === 'Instagram' ||
            type === 'Youtube' ||
            type === 'Gmb' ||
            type === 'Tiktok'
          "
          v-tooltip.top="'Add Video'"
          class="min_width far fa-video"
          @click.prevent="triggerSharingVideoUpload(getSharingVideoUploadId)"
        >
        </i>

        <i
          v-tooltip.top="'Add via Media Library/Other Sources'"
          class="min_width far fa-folder-open"
          data-cy="image-folder"
          @click.prevent="showMediaModal(type)"
        >
        </i>

        <Hashtag v-if="type !== 'Tiktok'" :type="type"></Hashtag>
        <ReplugDropdown v-if="type !== 'Tiktok'" :type="type"></ReplugDropdown>
        <Utm v-if="type !== 'Tiktok'" :type="type"></Utm>
        <div
          v-if="type !== 'Youtube' && type !== 'Tiktok'"
          data-cy="platform-selection"
          class="dropdown default_style_dropdown"
        >
          <CanvaTypes
            :dropdown-position="'dropdown-menu-left'"
            :type="type"
          ></CanvaTypes>
        </div>

        <div
          v-if="!hideVista() && type !== 'Youtube' && type !== 'Tiktok'"
          data-cy="platform-selection"
          class="dropdown default_style_dropdown"
        >
          <CrelloTypes
            :dropdown-position="'dropdown-menu-left'"
            :type="type"
          ></CrelloTypes>
        </div>

        <LocationDropdown
          v-if="locationVisibilityStatus"
          :type="type"
        ></LocationDropdown>
        <div
          v-tooltip="'Saved Captions'"
          class="cst-editor__toolbar-btn relative mr-4 cursor-pointer w-7 h-7 flex items-center justify-center rounded-md transition-all ease-in-out"
          @click="openSaveCaptionModal"
        >
          <i class="icon-Save pointer" />
        </div>
        <div
          v-tooltip="'Generate AI Content'"
          class="cst-editor__toolbar-btn cursor-pointer mx-1 pr-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        >
          <img
            src="@assets/img/common/cyrus-bot.svg"
            alt=""
            class="w-6 mb-[0.25rem]"
          />
          <div
            class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-48 z-50"
          >
            <div
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click="fetchAiCaption(true, 'Common')"
            >
              <span class="pt-0.5"> AI Generated Captions </span>
            </div>
            <div
              v-if="type !== 'youtube'"
              class="p-2 hover:bg-cs-secondary flex items-center"
              @click="openAiImageModal"
            >
              <span class="pt-0.5"> AI Generated Images </span>
            </div>
            <div
              class="hover:bg-cs-secondary flex items-center justify-between"
            >
              <div
                class="p-2"
                :class="{
                  'text-gray-700 pointer-events-none':
                    getCharCountForHashtags < 100,
                }"
                @click.prevent="$emit('fetchAIHashtags')"
              >
                <span class="pt-0.5"> AI Generated Hashtags </span>
              </div>
              <v-menu
                v-if="getCharCountForHashtags < 100"
                :popper-triggers="['hover']"
                placement="right"
                popper-class="first-comment__info-popover"
                class="inline-block mx-1"
              >
                <a href="#"
                  ><img
                    src="@assets/img/composer/help-icon.svg"
                    alt=""
                    class="w-4 h-4"
                /></a>
                <template v-slot:popper>
                  To generate hashtags, the content needs to be at least 100
                  characters long.
                </template>
              </v-menu>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="type !== 'Tiktok'"
        class="o_right ml-auto d-flex align-items-center gap-x-2"
      >
        <p
          v-if="
            (getAccountSelection.facebook.length > 0 ||
            getAccountSelection.threads.length > 0 ||
              getAccountSelection.twitter.length > 0 ||
              getAccountSelection.linkedin.length > 0 ||
              getAccountSelection.pinterest.length > 0 ||
              getAccountSelection.gmb.length > 0 ||
              getAccountSelection.youtube.length > 0 ||
              getAccountSelection.instagram.length > 0) &&
            type !== 'Tumblr'
          "
          class="mr-2 chart_limit font-0-87rem"
        >
          Characters Left:
          <span :class="{ char_exceed: remainingCharacters(type) < 0 }">{{
            remainingCharacters(type)
          }}</span>
        </p>
        <button
          v-tooltip.bottom="'Make selected text Bold'"
          style="
            height: 35px;
            width: 35px;
            align-items: center;
            display: flex;
            padding: 0;
            justify-content: center;
            font-size: 0.875rem;
            margin: 0 0.4rem;
          "
          class="btn btn-studio-theme-grey-space"
          @click="sharingMessageFormatting(type, 'bs')"
        >
          <i class="far fa-bold"></i>
        </button>

        <button
          v-tooltip.bottom="'Make selected text Italic'"
          style="
            height: 35px;
            width: 35px;
            align-items: center;
            display: flex;
            padding: 0;
            justify-content: center;
            font-size: 0.875rem;
          "
          class="btn btn-studio-theme-grey-space"
          @click="sharingMessageFormatting(type, 'is')"
        >
          <i class="far fa-italic"></i>
        </button>

        <emoji :is="emojiComponent" :type="type"></emoji>

        <!--<button class="btn emoji_btn ml-3">Add</button>-->
      </div>
    </div>
  </div>
</template>
