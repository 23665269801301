<template>
  <div
    id="component-dropdown"
    class="components-uitkit right-side-uitkit-container"
  >
    <h3> Forms </h3>
    <p
      >For the Forms, we have different variations running. But, for the future,
      we will be using the following variation for the form fields.</p
    >

    <div class="row">
      <div class="col-6">
        <h5><u>Field With Icon</u></h5>
        <div class="uikit-codemirror">
          <Codemirror v-model="inputTextwithIcon" :options="cmOptions" />
        </div>

        <div class="field_group">
          <div class="field_group__with_icon">
            <input id="fname" type="text" placeholder="First name" />
            <label for="fname" class="label-animated">First name:</label>
            <span class="field_group__with_icon__input_icon">
              <i class="icon-User"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <h5><u>Field Without Icon</u></h5>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputText" :options="cmOptions" />
        </div>

        <div class="field_group">
          <input id="fname2" type="text" placeholder="Last name" />
          <label for="fname2" class="label-animated">First name:</label>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-6">
        <h5><u>Textarea</u></h5>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputTextarea" :options="cmOptions" />
        </div>

        <div class="field_group">
          <textarea
            id="internalNotes"
            placeholder="Add notes, instructions or SOPs for your team here..."
          ></textarea>
          <label class="label-animated" for="internalNotes"
            >Internal Notes</label
          >
        </div>
      </div>

      <div class="col-6">
        <h5><u>Simple Dropdown</u></h5>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputSelect" :options="cmOptions" />
        </div>

        <div class="field_group">
          <Select>
            <option>test 1</option>
            <option>test 2</option>
            <option>test 3</option>
          </Select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>
    </div>

    <hr />

    <div class="row container-fluid">
      <div class="col-3">
        <div class="row">
          <h3>Radio</h3>
        </div>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputRedio" :options="cmOptions" />
        </div>

        <div class="field_group">
          <label class="radio_container">
            <input checked="checked" type="radio" name="field1" />

            <div for="field1">
              Post Now
              <span class="check"></span>
            </div>
          </label>

          <label class="radio_container">
            <input type="radio" name="field2" />

            <div for="field2">
              Post Now
              <span class="check"></span>
            </div>
          </label>
        </div>
      </div>

      <div class="col-3">
        <div class="row">
          <h3>Radio Small</h3>
        </div>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputRediosm" :options="cmOptions" />
        </div>

        <div class="field_group">
          <label class="radio_container radio-sm">
            <input id="field1" type="radio" />
            <div for="field1">
              Post Now
              <span class="check"></span>
            </div>
          </label>

          <label class="radio_container radio-sm">
            <input id="field2" type="radio" />
            <div for="field2">
              Post Now
              <span class="check"></span>
            </div>
          </label>
        </div>
      </div>

      <div class="col-3">
        <div class="row">
          <h3>Checkbox</h3>
        </div>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputCheckbox" :options="cmOptions" />
        </div>

        <div class="field_group">
          <div class="checkbox_container">
            <label>
              Needs Approval
              <input type="checkbox" checked="checked" />
              <span class="check"></span>
            </label>
          </div>

          <div class="checkbox_container">
            <label>
              Needs Approval
              <input type="checkbox" checked="checked" />
              <span class="check"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="row">
          <h3>Checkbox Small</h3>
        </div>

        <div class="uikit-codemirror">
          <Codemirror v-model="inputCheckboxsm" :options="cmOptions" />
        </div>

        <div class="field_group">
          <div class="checkbox_container">
            <label>
              Needs Approval
              <input type="checkbox" checked="checked" />
              <span class="check check-sm"></span>
            </label>
          </div>

          <div class="checkbox_container">
            <label>
              Can add blogs
              <input type="checkbox" checked="checked" />
              <span class="check check-sm"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row container-fluid">
      <div class="col-6">
        <h5><u>Search Bar</u></h5>
        <br />

        <div class="uikit-codemirror">
          <Codemirror v-model="searchField" :options="cmOptions" />
        </div>

        <div class="search-bar-input">
          <div class="search-bar-inner">
            <input type="text" placeholder="Search by campaign..." />
            <button class="search_btn">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-6">
        <h5><u>Search Bar field</u></h5>
        <br />

        <div class="uikit-codemirror">
          <Codemirror v-model="searchField" :options="cmOptions" />
        </div>

        <div class="search-bar-input">
          <div class="search-bar-inner">
            <input type="text" placeholder="Search by campaign..." />
            <button class="search_btn">
              <i class="icon-Search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Codemirror } from 'vue-codemirror'

export default {
  components: {
    Codemirror,
  },
  data() {
    return {
      cmOptions: {
        tabSize: 4,
        mode: 'text/html',
        lineNumbers: true,
        line: true,
      },
      inputText: `<div class="field_group">
                                    <input type="text" id="fname2" placeholder="Last name">
                                    <label for="fname2" class="label-animated">First name</label>
                                </div>`,
      inputTextwithIcon: `<div class="field_group">
                                                <div class="field_group__with_icon">
                                                    <input type="text" id="fname" placeholder="First name">
                                                    <label for="fname" class="label-animated">First name</label>
                                                    <span class="field_group__with_icon__input_icon">
                                                        <i class="far fa-user"></i>
                                                     </span>
                                                </div>
                                            </div>`,

      inputSelect: `<div class="field_group">
                                <select>
                                  <option>test 1</option>
                                  <option>test 2</option>
                                </select>
                                <i class="icon-dropdown-cs"></i>
                             </div>`,

      inputRedio: `<div class="field_group">
             <label class="radio_container">
                <input type="radio" id="field1">
                  <div for="field1"> Post Now
                    <span class="check"></span>
                </div>
              </label>

               <label class="radio_container">
                 <input type="radio" id="field2">
                    <div for="field2"> Post Now
                       <span class="check"></span>
                    </div>
                 </label>
         </div>`,
      inputRediosm: `<div class="field_group">

         <label class="radio_container radio-sm">
            <input type="radio" id="field1">
              <div for="field1"> Post Now
                <span class="check"></span>
              </div>
         </label>

          <label class="radio_container radio-sm">
             <input type="radio" id="field2">
               <div for="field2"> Post Now
                <span class="check"></span>
              </div>
           </label>
          </div>`,

      inputCheckbox: `<div class="field_group">

     <div class="checkbox_container">
       <label>
        Needs Approval
       <input type="checkbox" checked="checked">
       <span class="check"></span>
      </label>
     </div>
     <div class="checkbox_container">
         <label>
           Needs Approval
           <input type="checkbox" checked="checked">
             <span class="check"></span>
         </label>
     </div>
     </div>`,
      inputCheckboxsm: `<div class="field_group">

     <div class="checkbox_container">
       <label>
        Needs Approval
       <input type="checkbox" checked="checked">
       <span class="check check-sm"></span>
      </label>
     </div>
     <div class="checkbox_container">
         <label>
           Needs Approval
           <input type="checkbox" checked="checked">
             <span class="check check-sm"></span>
         </label>
     </div>
     </div>`,

      inputTextarea: `<div class="field_group">
      <textarea placeholder="Add notes, instructions or SOPs for your team here..." id="internalNotes"></textarea>
        <label class="label-animated" for="internalNotes">Internal Notes</label>
    </div>`,

      searchField: `<div class="search-bar-input">
  <div class="search-bar-inner">
     <input type="text" placeholder="Search by campaign...">
     <button class="search_btn">
        <i class="icon-Search"></i>
     </button>
  </div>
</div>`,
    }
  },
}
</script>
