<template>
  <div>
    <template v-if="isTopic || automation">
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="sorting_icon icon_first cs-trending-chart"> </i>
        <span class="text">{{ getTopicsType }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('topics', 'Any')"
              >Any</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('topics', 'Full')"
              >Full Content</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('topics', 'Abstract')"
              >Abstract Content</a
            >
          </li>
        </ul>
      </div>
    </template>

    <template v-else>
      <div
        class="dropdown_header d-flex align-items-center"
        data-toggle="dropdown"
      >
        <i class="sorting_icon icon_first cs-filter"></i>
        <span class="text">{{ getArticlesType }}</span>
        <span class="ml-auto">
          <i class="dropdown_arrow icon_last cs-angle-down"></i>
        </span>
      </div>

      <div class="dropdown-menu dropdown-menu-right">
        <ul class="inner">
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('articles', 'Any')"
              >Any</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('articles', 'Full')"
              >Full Content</a
            >
          </li>
          <li class="list_item">
            <a
              class="dropdown-item"
              @click.prevent="changeType('articles', 'Abstract')"
              >Abstract Content</a
            >
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    automation: {
      type: Boolean,
      Default: false
    },
    isTopic: {
      type: Boolean,
      Default: false
    },
    isCreateTopic: {
      type: Boolean,
      Default: false
    }
  },
  computed: {
    ...mapGetters(['getArticlesType', 'getTopicsType'])
  },
  methods: {
    ...mapMutations(['SET_TOPICS_TYPE']),
    ...mapActions(['topicsContent', 'articles', 'articlesPreviewSuggestion']),
    async changeType (type, value) {
      switch (type) {
        case 'topics':
          this.SET_TOPICS_TYPE(value)
          if (this.isCreateTopic || this.automation) {
            this.articlesPreviewSuggestion()
            return
          }
          this.topicsContent()
          break
        case 'articles':
          await this.getContent({ filter: { key: 'type', value } })
          this.articles()
          break
        default:
          break
      }
    }
  }
}
</script>
