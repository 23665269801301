<template>
  <div ref="pie-chart-container" class="h-chart">
    <highcharts :ref="chartRef" :options="dataOptions"></highcharts>
  </div>
</template>

<script>
import highcharts from '@ui/Highcharts'
export default {
  name: 'PieInnerChart',
  components: {
    highcharts,
  },
  props: {
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 250,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    thickness: {
      type: String,
      default: '70%',
    },
    titleFontSize: {
      type: String,
      default: '1.2rem',
    },
    title: {
      type: [String, Number],
      default: '',
    },
    chartRef: {
      type: String,
      default: null,
    },
    seriesName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataOptions: {},
    }
  },
  watch: {
    width(val) {
      this.dataOptions = {
        ...this.dataOptions,
        chart: {
          ...this.dataOptions.chart,
          width: val,
        },
      }
    },
  },
  created() {
    this.initializeDataOptions()
  },
  methods: {
    initializeDataOptions() {
      const self = this
      this.dataOptions = {
        chart: {
          type: 'pie',
          width: this.width,
          height: this.height,
          events: {
            redraw: function () {
              console.log('Test me')
            },
            load: function (chart) {
              self.$emit('mounted', chart.target, self.chartRef)
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          verticalAlign: 'middle',
          floating: true,
          text: this.title,
          style: {
            color: '#26282c',
            fontWeight: 'bold',
            fontSize: this.titleFontSize,
          },
        },
        plotOptions: {
          pie: {
            innerSize: this.thickness,
            shadow: false,
            center: ['50%', '50%'],
            dataLabels: false,
            cursor: 'pointer',
            // showInLegend: true
          },
        },
        legend: {
          // useHTML: true,
          // verticalAlign: 'bottom',
          // floating: true,
          // labelFormatter: function () {
          //   return `
          //         <span class="pie-chart-legend" style="display: flex;justify-content: center;flex-direction: column;text-align: center;" >
          //           <span class="pie-chart-legend__label ">
          //            ${this.name}
          //           </span>
          //           <span class="pie-chart-legend__value">
          //            ${this.options.y}
          //           </span>
          //         </span>
          //         `
          // }
        },
        colors: this.isChartEmpty(this.data) ? ['#b1afaf'] : this.colors,
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>',
          enabled: !this.isChartEmpty(this.data),
        },
        series: [
          {
            name: this.seriesName,
            colorByPoint: true,
            data: this.isChartEmpty(this.data)
              ? [['No Data Found', 100]]
              : this.data,
            color: '#b1afaf',
            borderWidth: 2,
          },
        ],
      }
    },

    isChartEmpty(arr) {
      let flag = true
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] > 0) {
          flag = false
          break
        } else {
          flag = true
        }
      }

      return flag
    },
  },
}
</script>

<style scoped>
.h-chart {
  min-height: 0;
}
</style>
