import { mapGetters, mapActions } from 'vuex'
import { processBlogPostUrl } from '@src/modules/publish/config/api-utils'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { planner } from '@src/modules/planner/store/mutation-types'
import { blogPosting } from '@src/modules/publish/store/states/mutation-types'
import { UNKNOWN_ERROR } from '../../constants/messages'

const he = require('he')
export const blogPostRequest = {
  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getBlogSelection',
      'getPublishSelection',
      'getTasks',
      'getDraftCancelAction',
      'getWorkspaceHashtagValidation',
      'getSocketId',
      'getPlanControl',
      'getBlogSubmissionSelection'
    ]),
  },
  methods: {
    ...mapActions([
      'setProcessBlogPostLoader',
      'setProcessSavePublicationLoader',
      'setPublishPlanId',
      'setPublishPlanSecondaryIds',
      'setSocialPlanDetail',
      'saveTask',
      'saveComment',
      'setDraftCancelAction',
      'validateHashtagInput',
    ]),

    /**
     * prepare draft post payload
     * set status as draft, draft loader processing and check post is able to visible in planner
     * @param payload
     * @return payload
     */
    prepareDraftPayload(payload) {
      payload.status = 'draft'
      payload.visible = !(
        this.getBlogSelection.platform_name === '' ||
        !this.getBlogPostingDetails.title ||
        this.getBlogPostingDetails.title.trim() === ''
      )
      this.setProcessSavePublicationLoader(true)
      return payload
    },

    /**
     * prepare noraml post payload
     * @param payload
     * @return payload
     */
    prepareNormalPayload(payload) {
      if (this.getPublishSelection.status === 'draft') {
        payload.status = 'scheduled'
        if (
          payload.socialDetail &&
          payload.socialDetail.length > 0 &&
          payload.socialDetail[0].status
        ) {
          payload.socialDetail[0].status = 'scheduled'
        }
      }
      // else {
      //   payload.status = 'published'
      // }
      payload.visible = true
      payload.post.description = this.fetchCKEditorPreviewHtml()
      this.setProcessBlogPostLoader(true)
      return payload
    },

    /**
     * prepare blog post payload
     * @param description
     * @param draft
     * @return payload
     */
    blogPostPayload(description, draft) {
      let payload = {
        type: this.getBlogPostingDetails.type,
        publish_source: this.getPublishSelection.publish_source,
        blog_selection: this.getPrimarySecondaryBlogSelection('BlogPost'),
        account_selection: this.getAccountSelection,
        post: {
          id: this.getBlogPostingDetails.id,
          title: this.getBlogPostingDetails.title,
          image: this.getBlogPostingDetails.image,
          link: this.getBlogPostingDetails.link, // null in case of composer
          description,
          customizeSeoPreview: this.getBlogPostingDetails.customizeSeoPreview,
          metaTitle: this.getBlogPostingDetails.metaTitle,
          metaUrl: this.getBlogPostingDetails.metaUrl,
          metaDescription: this.getBlogPostingDetails.metaDescription,
          optionalDescription: this.getBlogPostingDetails.optionalDescription,
          seoKeyword: this.getBlogSubmissionSelection.keyword,
          seoUrl: this.getBlogSubmissionSelection.url
        },
        labels: this.getPublishSelection.label,
        members: this.getPublishSelection.members,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
        socialDetail: this.getPublishSelection.socialPlansDetail,
        reopen: this.getPublishSelection.reopen,
        planId: this.getPublishSelection.plan_id,
        secondaryIds: this.getPublishSelection.secondaryPlansIds,
        status: null,
        // is_edit: false, // commented for the time being as code is not deployed to live version yet.
        folderId: this.getPublishSelection.folderId,
        post_variations: this.getBlogPostingDetails.post_variations,
      }

      if (this.getBlogPostingDetails.type === 'Discovery Article') {
        payload.post.original_source =
          this.getBlogPostingDetails.original_source
        payload.post.remove_anchors_links =
          this.getBlogPostingDetails.remove_anchors_links
      } else if (this.getBlogPostingDetails.type === 'Composer Article') {
        if (!payload.post.link) {
          payload.post.link = null
        }
      }

      if (draft) {
        payload = this.prepareDraftPayload(payload)
      } else {
        payload = this.prepareNormalPayload(payload)
      }

      // in case of discovery social post draft
      if (payload.status === 'draft') {
        this.$store.commit(
          blogPosting.SET_BLOG_POSTING_TYPE,
          'Composer Article'
        )
        payload.type = 'Composer Article'
        payload.folderId = this.getPublishSelection.folderId
      }

      // hashtags
      const hashtag = this.getNewWorkspaceHashtag
      payload.hashtag = {}
      payload.hashtag.status = this.getPublishSelection.hashtag.status
      if (this.getPublishSelection.hashtag.status) {
        payload.hashtag.id = this.getPublishSelection.hashtag.selected
        payload.hashtag.name = hashtag.name
        payload.hashtag.items = hashtag.items
        payload.hashtag.count = hashtag.count
        payload.hashtag.position = hashtag.position
      }

      // replug
      payload.replug = {}
      payload.replug.status = this.getPublishSelection.replug.status
      if (this.getPublishSelection.replug.status) {
        payload.replug.brand = this.getPublishSelection.replug.brand._id
        payload.replug.campaign = this.getPublishSelection.replug.campaign._id
        if (this.getPublishSelection.replug.campaign.domainId) {
          payload.replug.custom_domain = true
        } else {
          payload.replug.custom_domain = false
        }
      }

      return payload
    },

    /**
     * Draft blog post success method
     * @param response
     * @param redirect
     * @param taskCommentSave
     * @param isSaveDraftClicked
     */
    draftSuccessResponse(response, redirect, taskCommentSave, isSaveDraftClicked) {
      if (response.data.has_text) {
        this.$store.commit(
          composer.SET_PUBLICATION_LAST_UPDATED,
          response.data.last_updated
        )
      }
      this.setPublishPlanId(response.data.ids.primary)
      this.$store.commit(
        blogPosting.SET_BLOG_POSTING_ID,
        response.data.ids.blogPostId
      )

      // this is the case when user duplicate a post from composer
      if (
        this.$router.currentRoute.name === 'composerBlog' &&
        this.$route.params.id !== response.data.ids.primary
      ) {
        this.$router.push({
          name: 'composerBlog',
          params: { id: response.data.ids.primary },
        })
      }

      // if (this.$router.currentRoute.name === 'composerBlog') {
      //   if (response.data.versionHistory) this.$store.commit(composer.SET_COMPOSER_VERSION_HISTORY, response.data.versionHistory)
      //   else this.$store.commit(composer.SET_COMPOSER_VERSION_HISTORY, [])
      // }

      this.setSecondaryIdsSelection(response.data.ids.secondary)
      this.setPublishPlanSecondaryIds(response.data.ids.secondary)
      this.setSocialPlanDetail([response.data.ids.social])
      this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)

      if (
        this.$route.name === 'list_plans' ||
        this.$route.name === 'calender_plans' ||
        this.$route.name === 'feed_view'
      ) {
        this.$store.dispatch('refetchPlans', this.$route)
      }

      // confirmation box close for discovery blog post
      if (this.getDraftCancelAction) {
        this.setDraftCancelAction(false)
        this.$bvModal.hide('socialConfirmationDialog')
        this.$bvModal.hide('blog_share_modal')
      }

      // discovery task /comment save request after save in case of discovery post
      if (taskCommentSave === 'task') {
        this.saveTask(null)
      } else if (taskCommentSave === 'comment') {
        this.saveComment()
      }

      if (redirect) {
        const stateObject = this
        // setTimeout(function () {
        //   stateObject.loadBlogEditor()
        // }, 2000)
        setTimeout(function () {
          if (document.getElementById('c_title'))
            document.getElementById('c_title').focus()
        }, 600)
        this.$router.push({
          name: 'composerBlog',
          params: { id: response.data.ids.primary },
        })
      }

        if(isSaveDraftClicked){
          this.alertMessage('Your post has been saved as draft.', 'success')
        }
    },

    /**
     * Blog post success method
     */
    blogSuccessResponse(response = true, previousRoute = null) {
      this.alertMessage('Your post has been saved.', 'success')
      this.composerClose(previousRoute)
      this.setDraftCancelAction(true)
      if (
        this.$route.name === 'list_plans' ||
        this.$route.name === 'calender_plans' ||
        this.$route.name === 'feed_view'
      ) {
        this.$store.dispatch('refetchPlans', this.$route)
      }
      this.$bvModal.hide('blog_share_modal')
    },

    /**
     * Blog post error method
     * @param error
     */
    blogErrorResponse(error) {
      console.debug('Exception in processBlogPost ', error)
      this.alertMessage(UNKNOWN_ERROR, 'error')
      this.setProcessBlogPostLoader(false)
      this.setProcessSavePublicationLoader(false)
    },

    /**
     * Blog post response processing
     * @param response
     * @param draft
     * @param redirect
     * @param taskCommentSave
     * @param isSaveDraftClicked
     */
    blogResponse(response, draft, redirect, taskCommentSave, isSaveDraftClicked, previousRoute = '') {
      if (response.data.status === true) {
        if (!draft) {
          this.blogSuccessResponse(response , previousRoute)
        } else {
          // this.emitBlogActivities('label', this.getPublishSelection.label)
          this.draftSuccessResponse(response, redirect, taskCommentSave, isSaveDraftClicked)
        }
      } else if (response.data.message) {
        this.alertMessage(response.data.message, 'error')
      } else {
        this.alertMessage('Unable to save post.Please try again.', 'error')
      }

      this.setProcessBlogPostLoader(false)
      this.setProcessSavePublicationLoader(false)
    },

    /**
     * Blog post draft and normal post request
     * @param draft
     * @param autoSaveRequest
     * @param redirect
     * @param taskCommentSave
     * @param isSaveDraftClicked
     */
    async processBlogPost(
      draft = false,
      autoSaveRequest = false,
      redirect = false,
      taskCommentSave = '',
      isSaveDraftClicked = false,
      previousRoute = ''
    ) {
      console.debug('Method:processBlogPost')

      this.$store.commit(composer.SET_PUBLICATION_LAST_UPDATED, '')

      if (draft || autoSaveRequest) {
        if (this.getSocketId && !this.getPlanControl) {
          console.debug('invalid control')
          return false
        }
      }

      if (
        this.getBlogPostingDetails.title === '' &&
        (!draft || autoSaveRequest)
      ) {
        this.alertMessage('Please enter post title', 'error')
        return false
      }

      if (!draft || autoSaveRequest) {
        if (this.getPublishSelection.replug.status) {
          if (this.replugValidation() === false) {
            return false
          }
        }

        if (this.getPublishSelection.hashtag.status) {
          await this.validateHashtagInput('rss')

          if (this.getWorkspaceHashtagValidation === false) {
            return false
          }
        }
      }

      if (!draft && this.blogPrimarySecondarySelectionValidation() === false)
        return false
      if (!draft && this.socialAccountsTokenValidation() === false) return false
      const description = this.fetchDescription(redirect)

      console.debug('Method:processBlogPost', description)

      if (!autoSaveRequest && draft && this.getPublishSelection.plan_id) {
        const text = this.stripHtml(description)
        if (!text || (text && text.trim().length === 0)) {
          return false
        }
      }
      const payload = this.blogPostPayload(description, draft)

      this.postRequest(
        processBlogPostUrl,
        payload,
        (response) => {
          this.blogResponse(response, draft, redirect, taskCommentSave, isSaveDraftClicked, previousRoute)
        },
        (error) => {
          this.blogErrorResponse(error)
        }
      )
    },

    /**
     * fetch editor description
     * @param redirect
     */
    fetchDescription(redirect) {
      let description = ''
      if (!redirect) description = this.fetchCKEditorHtml(false).html()
      return description
    },

    stripHtml(html) {
      // Create a new div element
      const temporalDivElement = document.createElement('div')
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html
      // Retrieve the text property of the element (cross-browser support)
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ''
      )
    },
  },
}
