<template>
  <div class="social_accounts_tooltip" :class="channels_class">
    <template v-if="has_ids"> </template>
    <template v-else>
      <template v-for="(items, channel_name) in accounts" :key="`channel_${channel_name}`">
        <template v-for="(account, index) in items" :key="`${index}_account`">
          <div class="radio_input_image radio_social_profile">
            <input
              :id="item._id + '_' + account._id"
              v-model="item.feed_preview.account_id"
              type="radio"
              :name="'testprofile' + item._id"
              :value="account._id"
              @change="feedAccountSelectionChanges(account, channel_name)"
            />
            <label
              v-tooltip="{
                content: channelTooltipHTML(channel_name, account),
                classes: 'team_tooltip',
                allowHTML: true,
                theme: 'light',
              }"
              :for="item._id + '_' + account._id"
              class="radio_right"
            >
              <div :class="['profile_picture ' + channel_name + '-color']">
                <div
                  class="picture_block picture_block_background"
                  :class="{
                    icons_height_md: channels_size === '36px',
                    icons_height_lg: channels_size === '42px',
                  }"
                >
                  <div class="icon">
                    <template v-if="channel_name === 'tiktok'">
                      <img src="@src/assets/img/integration/tiktok-icon_1.svg" alt="tiktok_logo" />
<!--                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 450 730" style="width: 10px;">
                        <path fill="#ffffff" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" id="svg_1" class="selected" fill-opacity="1"/></g>
                      </svg>-->
                    </template>
                    <template v-else-if="channel_name === 'twitter'">
                      <i class="fa fa-twitter"></i>
                    </template>
                    <i v-else :class="'cs-' + channel_name"></i>

                  </div>
                  <div
                    class="img"
                    :style="{
                      'background-image': channelBackgroundImage(
                        account,
                        channel_name,
                      ),
                    }"
                  ></div>
                </div>
              </div>
            </label>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    has_ids: {
      type: Boolean,
      default: false
    },
    accounts: {
      type: Array,
      default: () => []
    },
    channels_class: {
      type: String,
      default: 'd-flex align-items-start justify-content-center'
    },
    channels_size: {
      type: String,
      default: '35px'
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {},
  methods: {
    feedAccountSelectionChanges (account, channelName) {
      console.debug('Method:feedAccountSelectionChanges', account)
      this.item.feed_preview.channel = channelName
      this.item.feed_preview.account = account
    }
  }
}
</script>

<style lang="less" scoped>
.icons_height_md {
  width: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
}
.icons_height_lg {
  width: 34px !important;
  height: 34px !important;
  min-width: 34px !important;
}
.text-is-left-only {
  text-align: left !important;
}
</style>
