<template>
  <div class="auth_parent_container">
    <div v-if="getSettingLoaders.joinTeamStatusLoader" class="flex flex-col h-full items-center justify-center">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div v-else class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Already have an account?<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'login' }">Sign in</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Join ContentStudio</h2>
              <p class="text-md text-[#757A8A] mt-2">Please enter your basic details.</p>
            </div>

            <div class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 pt-12">
                <!-- Email -->
                <CstFloatingLabelInput
                    id="memberfirstName"
                    v-model="first_name"
                    class="!h-[3.3rem] mb-2.5"
                    type="text"
                    label="First Name"
                    :maxlength="18"
                    show-icon-left
                >
                  <template v-slot:icon>
                    <i class="icon-User"></i>
                  </template>
                </CstFloatingLabelInput>

                <CstFloatingLabelInput
                    id="memberlastName"
                    v-model="last_name"
                    class="!h-[3.3rem] mb-2.5"
                    type="text"
                    label="Last Name"
                    :maxlength="18"
                    :readonly="getTeam.id"
                    show-icon-left
                >
                  <template v-slot:icon>
                    <i class="icon-User"></i>
                  </template>
                </CstFloatingLabelInput>

                <div>
                  <CstFloatingLabelInput
                    id="password"
                    v-model="password"
                    class="!h-[3.3rem] mb-2.5"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    show-icon-left
                    show-icon-right
                >
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="togglePassword('password')"
                    ></i>
                  </template>
                </CstFloatingLabelInput>
                  <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                    <p
                        class="text-xs"
                    >
                      {{ password_strength_message }}
                    </p>
                    <div class="flex gap-1">
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                      <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                    </div>
                  </div>
                </div>

                <CstFloatingLabelInput
                    id="confirm_password"
                    v-model="confirmPassword"
                    class="!h-[3.3rem] mb-2.5"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    label="Confirm Password"
                    show-icon-left
                    show-icon-right
                >
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="togglePassword('confirmPassword')"
                    ></i>
                  </template>
                </CstFloatingLabelInput>

              </div>

            </div>
            <div class="auth-form__button-field mt-7">
              <button
                  :class="{ disabled: getSettingLoaders.joinTeamLoader }"
                  :disabled="getSettingLoaders.joinTeamLoader"
                  data-cy="sign_up_button"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="
                  joinTeam(
                    first_name,
                    last_name,
                    password,
                    confirmPassword,
                    $route.params.reff,
                    $route.params.token,
                  )
                "
              >
                <span class="w-full">Join Now <img
                    v-if="getSettingLoaders.joinTeamLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CstFloatingLabelInput from "@ui/Input/CstFloatingLabelInput";
import FeaturesSlider from '../components/FeaturesSlider'
import SignupSideComponent from "@src/components/authentication/SignupSideComponent.vue";

export default {
  name: 'JoinTeamMember',
  components: {
    FeaturesSlider,
    CstFloatingLabelInput,
    SignupSideComponent
  },
  data() {
    return {
      size: '14px',
      loaderColor: '#ffffff',
      first_name: '',
      last_name: '',
      confirmPassword: '',
      password: '',
      showPassword: false,
      showConfirmPassword: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getSettingLoaders']),
  },
  created() {
    this.getJoinTokenStatus()
  },

  methods: {
    ...mapActions([]),
    /**
     * this method is responsible for show or hide password
     * @param field
     */
    togglePassword(field) {
      if (field === 'password') {
        this.showPassword = !this.showPassword;
      } else if (field === 'confirmPassword') {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
  watch: {
    'password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is satisfactory';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.setting_profile_component {
  text-align: -webkit-center;
  padding: 0;
}
.setting_profile_component .ps_box:last-child {
  margin-bottom: 0;
}
.setting_profile_component .ps_box .profile_image_upload {
  padding: 8px 0;
  min-width: 150px;
  width: 130px;
  border: 1px solid rgba(58, 69, 87, 0.1);
}
</style>
