<template>
  <!-- The Modal -->
  <div
    id="blog_article_preview"
    class="full_height modal fade twitter_influencer_modal right side_slide_right w_1050 blog_article_preview"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          style="background: #ffffff"
          class="modal_head d-flex align-items-center"
        >
          <h2>Article Preview</h2>
          <button
            style="background: #ffffff"
            type="button"
            class="close"
            data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body height">
          <!--<div class=" d-flex height align-items-center justify-content-center" >-->
          <!--<beat-loader></beat-loader>-->
          <!--</div>-->
          <div class="blog_preview_block">
            <div class="blog_preview_inner">
              <template
                v-if="
                  blogPreviewHTML.length > 35 ||
                  getBlogPostingDetails.image.link ||
                  getBlogTitle(false)
                "
              >
                <div
                  v-if="getBlogPostingDetails.image.link || getBlogTitle(false)"
                  class="post_head"
                >
                  <img
                    v-if="getBlogPostingDetails.image.link"
                    class="featured_img"
                    :src="getBlogPostingDetails.image.link"
                    alt=""
                  />
                  <h1
                    v-if="getBlogTitle(false)"
                    v-html="getBlogTitle(false)"
                  ></h1>
                  <div class="post_head_bottom hide">
                    <p class="author"
                      >By <span class="bold">Natasha Bertrand</span> on
                      <a href="">theatlantic.com</a></p
                    >
                    <p class="date">Oct 30 2018</p>
                  </div>
                </div>
                <div class="post_description">
                  <div
                    id="previewCuration"
                    class="curationCuratedDescription"
                    v-html="blogPreviewHTML"
                  ></div>
                </div>
              </template>
              <template v-else>
                <div class="no_blog_content">
                  <img src="/img/no_block_content.png" alt="" />
                  <p class="bold">No content added yet.</p>
                  <p class="light">Please write some content to get preview.</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'getBlogPostingDetails',
      'getBlogPostingContentExistenceStatus',
      'getBlogPostingHTML'
    ]),
    blogPreviewHTML () {
      const regex = /<oembed.+?url="(.*?)"><\/oembed>/g

      const newHTML = this.getBlogPostingHTML.replaceAll(
        regex,
        '<a data-iframely-url href="$1"></a>'
      )

      console.log(newHTML)

      return newHTML
    }
  }
}
</script>
