<template>
  <div class="auth_left">
    <div class="auth_left_inner height d-flex align-items-center">
      <img
        class="shape_1"
        src="../../assets/img/authentication/left_shape.svg"
        alt=""
      />
      <img
        class="shape_2"
        src="../../assets/img/authentication/right_shape.svg"
        alt=""
      />
      <div class="inner_content">
        <div class="top_logo">
          <img src="../../assets/img/logo/logo_white.svg" alt="" />
          <span>ContentStudio</span>
        </div>

        <div class="left_side_slider">
          <li>
            <div class="slider_img">
              <img src="../../assets/img/authentication/slide2.png" />
            </div>

            <h3>Composer</h3>
            <p
              >Annotate, publish or schedule content to multiple social and
              blogging channels at once from one single platform.</p
            >
          </li>
          <li>
            <div class="slider_img">
              <img src="../../assets/img/authentication/slide3.png" />
            </div>

            <h3>Planner</h3>
            <p
              >Streamline your content workflow by collaborating with your team
              members and planning month-long campaigns from an interactive
              calendar.</p
            >
          </li>
          <li>
            <div class="slider_img">
              <img src="../../assets/img/authentication/slide4.png" />
            </div>

            <h3>Publish</h3>
            <p
              >Scheduled content to all of your channels and networks from a
              single place</p
            >
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'slick-carousel'
export default {
  components: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {
    $('.left_side_slider').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    })
  },
  methods: {},
}
</script>

<style lang="scss">
@import '~slick-carousel/slick/slick.scss';
</style>
