<script setup>
import { defineProps, reactive } from 'vue';
import CstSwitch from '@ui/Switch/CstSwitch'
import AccountSelectionDropdown from '@src/modules/composer_v2/components/AccountSelectionDropdown'

const props = defineProps({
  isLinkedinCarouselDisabled:{
    type: Boolean,
    required: true,
    default: false
  },
  sharingDetails: {
    type: Object,
    required: true,
    default: () => {}
  },
  linkedinAccountSelection: {
    type: Array,
    required: true,
    default: () => []
  },
  commonBoxStatus: {
    type: Boolean,
    required: true,
    default: false
  },
  linkedinOptions: {
    type: Object,
    required: true,
    default: () => {}
  }
});
const emit = defineEmits(['close-linkedin-carousel']);

const state = reactive({
  linkedinCarouselTitle: '',
});

</script>

<template>
  <div class="cst-carousel-post color-border rounded-xl mt-5 p-relative cst-editor flex flex-col">
    <div
        class="grid grid-flow-col my-5 mx-4"
    >
      <div class="flex items-center">
        <div
            class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg bg-cs-secondary"
        >
          <img
              src="@assets/img/composer/linkedin_block.svg"
              alt=""
              class="w-5 h-5"
          />
        </div>
        <span class="mx-2 font-normal text-sm text-black-300"
        >Linkedin Carousel</span
        >
        <v-menu
            :popper-triggers="['hover']"
            placement="bottom-end"
            popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
          ><img
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Carousel Post is available for:</div>
            <ul>
              <li><i class="fab fa-linkedin-in"></i>Pages and Profiles</li>
            </ul>

            <p class="first-comment__message-box"
            >How to add carousel post?
              <a
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="64c8899af3abbf47e505323d"
              >Click here</a
              ></p
            >
          </template>
        </v-menu>
        <CstSwitch
            v-model="linkedinOptions.is_carousel"
            class="mx-2"
            size="small"
            :disabled="isLinkedinCarouselDisabled"
        />
        <div class="ml-auto">
          <AccountSelectionDropdown
              type="carousel"
              :carousel="linkedinOptions"
              :accounts-list="linkedinAccountSelection"
              v-bind="$attrs"
              v-on="$listeners"
          />
        </div>
      </div>
    </div>
    <div class="px-4 pb-3 flex items-center">
      <label for="linkedinCarouselTitle" class="block mb-1 text-gray-800 w-2/12">Carousel Post Title:</label>
      <input
          id="linkedinCarouselTitle"
          ref="linkedinCarouselTitle"
          v-model="linkedinOptions.title"
          type="text"
          class="w-10/12 px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 focus:outline-none bg-cs-light-gray"
          placeholder="Title"
          maxlength="50"
      />
    </div>
  </div>
</template>
