<script setup>
import CstButton from '@ui/Button/CstButton.vue'
import { EventBus } from '@common/lib/event-bus'

const redirectToDemo = () => {
  window.open('https://contentstudio.io/book-a-demo/', '_blank')
}

const redirectToArticles = () => {
  window.open('https://docs.contentstudio.io/', '_blank')
}

const openHelpWidget = () => {
  EventBus.$emit('open-help-widget')
}

const handleWatchVideoTutorial = () => {
  EventBus.$emit('show-yt-video-modal', 'uAuiDkRGYhI')
}
</script>

<template>
  <div
    class="bg-no-repeat bg-right-bottom w-full h-full"
    :style="{
      backgroundImage: `url(${require('@assets/img/dashboard/images/support.svg')})`,
    }"
  >
    <div class="px-5 py-6 flex justify-between flex-col h-full">
      <p
        class="text-secondary-cs-700 text-lg font-medium leading-0 ml-1 leading-none"
        >Support Center</p
      >
      <ul>
        <li
          class="text-primary-cs text-sm font-normal hover:bg-gray-cs-200 py-1 px-2 w-max rounded cursor-pointer"
          @click="redirectToArticles"
          ><span class="mr-2 mb-0.5">&#9679;</span>Detailed Articles
        </li>
        <li
          class="text-primary-cs text-sm font-normal hover:bg-gray-cs-200 py-1 px-2 w-max rounded cursor-pointer"
          @click="handleWatchVideoTutorial"
          ><span class="mr-2 mb-0.5">&#9679;</span>Watch Tutorial
        </li>
        <li
          class="text-primary-cs text-sm font-normal hover:bg-gray-cs-200 py-1 px-2 w-max rounded cursor-pointer"
          @click="openHelpWidget"
          ><span class="mr-2 mb-0.5">&#9679;</span>Contact Support
        </li>
      </ul>
      <CstButton
        variant="secondary"
        class="!px-4 !py-2 !rounded w-max ml-1"
        @click="redirectToDemo"
        ><p class="text-light-cs text-sm font-normal">Book a Demo</p>
      </CstButton>
    </div>
  </div>
</template>
