<template>
  <div
    v-click-away="handleClickAway"
    :class="{ 'relative z-100': isOpen }"
    class="block text-left"
  >
    <div>
      <span
        aria-expanded="true"
        aria-haspopup="true"
        class="flex justify-center items-center font-semibold text-primary-variant-1"
      >
        <span
          class="truncate inline-block w-28 rounded-lg color-border shadow-xs bg-white font-normal text-gray-800 hover:bg-gray-50 focus:outline-none px-3 py-1.5 text-sm bg-main-gray"
          @click="handleOpen"
        >
          <slot name="selected">
            {{ currentFilterName }}
          </slot>
        </span>

        <div
          :class="{
            'cursor-not-allowed bg-gray-500 bg-opacity-100 hover:shadow-none':
              !currentFilterModified,
            'bg-primary-variant-1 bg-opacity-90 hover:bg-opacity-100':
              currentFilterModified,
          }"
          :disabled="!currentFilterModified"
          class="border-0 ml-2 flex justify-center items-center text-xs inline-block w-10 h-7 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded appearance-none"
          @click="handleSave"
        >
          <i class="fa fa-save"></i>
        </div>
      </span>
    </div>
    <div
      v-show="isOpen"
      class="filter-dropdown max-h-72 overflow-y-auto origin-top-right absolute mt-2 rounded shadow-lg bg-white focus:outline-none z-50"
    >
      <div
        ref="dropdownList"
        :data-active="selected"
        aria-labelledby="menu-button"
        aria-orientation="vertical"
        class="py-1"
        role="menu"
        tabindex="-1"
        @keydown="keyHandler"
      >
        <template v-if="!isSave && filters.length">
          <div
            v-for="(filter, index) in filters"
            :key="`filters_list_${filter._id}_${index}`"
            :class="classObject"
            class="text-gray-800 py-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 text-ellipsis"
            @click="onClickFilter(filter)"
          >
            <span class="truncate">{{ filter.name }}</span>
            <span v-if="filter._id === currentFilter"
              ><i class="far fa-check text-green-600"></i
            ></span>
            <span class="flex flex-row items-center">
              <span
                v-if="filter.default"
                class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 text-gray-800 hover:bg-blue-200 hover:bg-opacity-50 rounded mr-1.5 hover:text-white"
                @click.prevent="$emit('set-default-filter-status', filter._id, 'remove', index)">
                Remove as default
              </span>
              <span
                v-else
                class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 text-gray-800 hover:bg-blue-200 rounded mr-1.5 hover:text-white"
                @click.prevent="$emit('set-default-filter-status', filter._id, 'add', index)">
                Set as default
              </span>
              <i
                class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 hover:bg-red-500 text-gray-800 rounded fa fa-trash hover:text-white"
                @click.prevent="$emit('remove-saved-filter', filter._id, index)"
              >
              </i>
            </span>
          </div>
        </template>
        <template v-else>
          <div
            class="text-gray-800 cursor-pointer hover:bg-gray-50 active:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-500 px-3 py-1.5 text-xs"
          >
            <div>Save Filters</div>

            <div class="mt-3">
              <CstRadio
                v-for="(item, index) in filters"
                :id="`save-radio-existing-id-${index}`"
                :key="`save_radio_existing_${index}`"
                v-model="saveOption"
                :disabled="filters.length === 0"
                name="save_filter"
                :value="`existing ${item._id} ${item.name}`"
              >
                Update '{{ item.name }}'
              </CstRadio>
              <CstRadio
                id="save-radio-new"
                v-model="saveOption"
                name="save_filter"
                value="new"
              >
                Save as a new Filter
              </CstRadio>
            </div>

            <div class="mt-2 flex justify-center">
              <input
                v-if="saveOption === 'new'"
                v-model="filterName"
                class="bg-white rounded-md border border-gray-300 shadow-xs w-full pb-2 pt-2 px-4 text-xs"
                placeholder="Name"
                type="text"
              />
              <CstButton
                :class="{ 'cursor-not-allowed': disableSaveFilterButton }"
                :disabled="disableSaveFilterButton"
                class="ml-1.5 flex-shrink-0"
                size="small"
                text="Save"
                @click="onClickSaveFilter"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CstButton from '@ui/Button/CstButton'
import CstRadio from '@ui/Radio/CstRadio'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import {EventBus} from "@common/lib/event-bus";
export default {
  name: 'FilterDropdown',
  components: {
    CstRadio,
    CstButton,
  },
  mixins: [Helpers],
  provide() {
    return {
      ctx: this,
    }
  },
  props: {
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    currentFilter: {
      type: String,
      default: '',
    },
    currentFilterModified: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
emits: ['set-default-filter-status', 'remove-saved-filter', 'implement-filter', 'save-filter'],
  data() {
    return {
      filterName: '',
      isOpen: this.open,
      isSave: false,
      selected: 0,
      saveOption: 'new',
      activePlannerFilters: {
        _id: '',
        type: '',
        statuses: [],
        members: [],
        created_by: [],
        labels: [],
        campaigns: [],
        content_category: [],
        platformSelection: [],
        no_social_account: false,
      },
    }
  },
  computed: {
    currentFilterName: function () {
      if (this.currentFilter && this.filters.length) {
        const filterArr = this.filters.filter(
          (filter) => filter._id === this.currentFilter
        )

        if (filterArr.length) {
          return filterArr[0].name
        }
      }

      return 'Saved Filters'
    },
    classObject: function () {
      return {
        'px-3 py-1.5 text-xs': this.size === 'small',
        'px-3 py-3 text-sm': this.size === 'default',
        'px-3 py-3 text-base': this.size === 'large',
        [this.buttonClasses]: true,
      }
    },
    disableSaveFilterButton() {
      return this.filterName.length === 0 && this.saveOption === 'new'
    },
  },
  async mounted() {

  },
  methods: {
    handleClickAway() {
      this.isOpen = false
      this.isSave = false
    },
    handleSave() {
      if (this.isSave) {
        this.isSave = false
        this.isOpen = false
      } else {
        this.isSave = true
        this.isOpen = true
      }
    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
        if (this.isSave) {
          this.isOpen = true
          this.isSave = false
        }
      } else {
        this.isOpen = true
        setTimeout(() => {
          this.$refs.dropdownList.focus()
        }, 100)
      }
    },
    keyHandler(e) {
      console.log(e)
      if (e.keyCode === 38 && this.selected > 0) {
        e.preventDefault()
        this.selected--
      } else if (
        e.keyCode === 40 &&
        this.selected < this.$refs.dropdownList.children.length - 1
      ) {
        e.preventDefault()
        this.selected++
      }
    },
    /**
     * onClick save filter button event handler (emits the saved filter name). saveOption will contain the id and name of the filter if update is selected
     */
    onClickSaveFilter() {
      const [optionType, id, ...name] = this.saveOption.split(' ')
      this.$emit('save-filter', this.filterName, { optionType, id, name })
      this.handleClickAway()
    },

    /**
     * onClick filter button event handler (emits the filter clicked)
     * @param filter
     */
    onClickFilter(filter) {
      console.log('METHOD::onClickFilter ~ filter -> ', filter)
      this.$emit('implement-filter', filter)
      this.handleClickAway()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-xxs {
  font-size: 0.6rem;
}

.filter-dropdown {
  width: 15rem;
}
</style>
