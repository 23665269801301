<template>
  <div
    class="article-grid-view-container__inner__content_block__social_statistics__inner__item"
  >
    <div class="name">
      <p>Sentiment</p>
    </div>
    <div v-if="sentiment && sentiment.length > 0" class="value">
      <i
        v-if="sentiment[0].label == 'negative'"
        v-tooltip="'Negative'"
        class="far fa-frown sad"
      >
      </i>
      <i
        v-if="
          sentiment[0].label == 'positive' && sentiment[0].probability >= 0.65
        "
        v-tooltip="'Positive'"
        class="far fa-smile smile"
      >
      </i>
      <i
        v-else-if="
          sentiment[0].label == 'positive' && sentiment[0].probability < 0.65
        "
        v-tooltip="'Neutral'"
        class="far fa-meh normal"
      >
      </i>
      <i
        v-if="sentiment[0].label == 'neutral'"
        v-tooltip="'Neutral'"
        class="far fa-meh normal"
      >
      </i>
    </div>
    <div v-else class="value">
      <i v-tooltip="'Neutral'" class="far fa-meh normal"> </i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sentiment: {}
  }
}
</script>
