<template>
  <b-modal
      id="add-fb-group-modal"
      body-class="!px-0"
      centered
      dialog-class="!w-[484px]"
      hide-footer
      hide-header
      no-close-on-backdrop
      @hide="onModalHide"
  >
    <div class="relative">
      <!-- modal close -->
      <div class="w-full flex justify-end mt-2 pt-[5px] px-[5px]">
        <img
            alt=""
            class="w-6 h-6 mr-[12px] cursor-pointer"
            src="@src/assets/img/integration/cross_dark_gray.svg"
            @click="$bvModal.hide('add-fb-group-modal')"
        />
      </div>

      <!-- main modal body -->
      <div class="px-10 mb-4 w-full flex flex-col justify-center items-center">
        <!-- modal title -->
        <div class="w-full flex justify-center items-center gap-x-[8px]">
          <img
              alt=""
              class="w-12 h-12"
              src="@src/assets/img/integration/facebook-rounded.svg"
          />
          <p class="font-medium text-lg text-[#202324] select-none">{{action}} Facebook Group</p>
        </div>
        <hr class="text-[#ECEEF5] w-full mb-0 mr-0 ml-0 mt-[30px]"/>

        <!-- modal inputs -->
        <div class="w-full flex flex-col justify-center items-center">
          <form @submit.prevent="">
            <!-- logo upload -->
            <div class="my-4 flex flex-col align-items-center">
              <span class="text-[14px] font-medium select-none mb-2">
                Group Image
              </span>
              <div class="upload_logo">
                <div class="image_upload">
                  <div
                      :class="{
                      'loader_overlay_with_loader': state.showLogoLoader,
                    }"
                      class="upload_section"
                  >
                    <!-- for real dummy image -->
                    <div v-if="!state.groupLogo" class="img">
                      <img
                          alt=""
                          class="picture"
                          src="https://storage.googleapis.com/contentstudio-media-library-nearline/social_profiles_image/facebook/group-default-cover.png"
                      />
                    </div>
                    <!-- for real image -->
                    <div v-else class="img">
                      <img :src="state.groupLogo" alt="logo" class="picture w-full"/>
                    </div>

                    <clip-loader
                        v-if="state.showLogoLoader"
                        :size="'16px'"
                        class="center_loader"
                        color="#436aff"
                    ></clip-loader>
                    <div
                        v-else
                        class="upload_btn_block"
                        @click="triggerLogoUpload('group_logo_image_new')"
                    >
                      <input
                          id="group_logo_image_new"
                          accept="image/*"
                          autocomplete="off"
                          type="file"
                          @change.prevent="handleLogoUpload($event)"
                      />
                      <i class="icon-Camera"></i>
                      <p v-if="!state.groupLogo">Upload</p>
                      <p v-else>Change</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <label class="flex flex-col mb-0 space-y-2" for="app-name">
              <span class="text-[14px] font-medium select-none">
                Group Name<span class="ml-1">*</span>
              </span>
              <input
                  id="fb-group-name"
                  v-model="state.groupName"
                  min="3"
                  autocomplete="off"
                  class="color-border h-[42px] pt-4 pl-4 pb-4 pr-9 text-[#3a4557] rounded-[8px] bg-[#F2F3F8] cst-editor"
                  placeholder="Your Facebook group name here"
                  type="text"
              />
            </label>

            <!-- mobile app required info -->
            <div class="bg-[#F2F3F8] rounded-[8px] mt-4 px-5 py-4 w-full">
              <p class="text-[#202324] font-medium text-[14px]">Mobile App Required</p>
              <p class="text-[#3a4557] text-[14px]">Due to API limitations, posting to Facebook groups uses mobile app push notification method. Download the app to receive reminders for your scheduled Facebook group posts on your phone.
                <a
                  href="https://docs.contentstudio.io/article/1025-publishing-to-facebook-group-via-mobile-app-notification-method"
                  target="_blank">Learn more</a>
              </p>
            </div>

            <div class="flex justify-center mt-4 space-x-4">
                <img
                    :draggable="false"
                    src="@assets/img/dashboard/icons/google-play.svg" alt="Google Play" class="h-10 cursor-pointer"
                    @click="redirectToPlayStore"
                />
                <img
                    :draggable="false"
                    src="@assets/img/dashboard/icons/apple-store.svg" alt="App Store" class="h-10 cursor-pointer"
                    @click="redirectToAppStore"
                />
            </div>

          </form>
        </div>

        <!-- proceed button -->
        <CstButton
            :disabled="!getProceedStatus"
            class="mt-4 w-[404px] h-[44px] !rounded-[8px]"
            @click="addGroup"
        >
          <div class="flex items-center justify-center w-full">
            <p class="text-[16px] font-medium w-full">{{action}}</p>
            <clip-loader
                v-if="state.showLoader"
                :color="'white'"
                :size="'16px'"
                class="ml-2 mb-1"
            ></clip-loader>
          </div>
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */

// libraries
import {computed, inject, reactive, watch} from 'vue'

// Components
import CstButton from '@ui/Button/CstButton.vue'
import {integrationsBaseURL} from '@src/config/api-utils'
import {useStore} from '@state/base'
import proxy from '@common/lib/http-common'
import {UNKNOWN_ERROR} from '@common/constants/messages'
import {facebook} from "@modules/integration/store/states/mutation-types";
import {EventBus} from "@common/lib/event-bus";
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";

const root = inject('root')
const {$bvModal} = root
const {getters, dispatch} = useStore()

const { uploadIntegrationImage } = useIntegrationComposable()

const emit = defineEmits(['close'])

const props = defineProps({
  group: {
    type: Object,
    default: null,
  },
  action: {
    type: String,
    default: 'Add',
  },
})

const state = reactive({
  groupLogo: '',
  groupName: '',
  groupId: '',
  isDisabled: false,
  showLoader: false,
  showLogoLoader: false,
})

// watch for changes in the group prop
watch(
    () => props.group,
    (newValue) => {
      if (newValue) {
        state.groupLogo = newValue.image || ''
        state.groupName = newValue.name || ''
        state.groupId = newValue._id || ''
      }
    }
)

/**
 * @description Computed property that returns a boolean value indicating whether all required form fields have been filled out
 * @returns {Boolean} - true if all required form fields have been filled out, false otherwise
 */
const getProceedStatus = computed(
    () =>
        !!(
            state.groupName &&
            state.groupName.length >= 3 &&
            !state.isDisabled
        )
)

const redirectToAppStore = () => {
  window.open(
      'https://apps.apple.com/us/app/contentstudio-smm-tool/id1439314125',
      '_blank'
  )
}

const redirectToPlayStore = () => {
  window.open(
      'https://play.google.com/store/apps/details?id=com.muneeb.contentstudio',
      '_blank'
  )
}

const addGroup = async () => {
  state.showLoader = true
  state.isDisabled = true
  const payload = {
    id: state.groupId,
    image: state.groupLogo,
    name: state.groupName,
    workspace_id: getters.getActiveWorkspace._id,
  }

  await proxy.post(
      `${integrationsBaseURL}facebook/addGroup`,
      payload)
      .then((response) => {
        handleResponse(response.data)
      })
      .catch((error) => {
        console.error('Error while adding facebook group', error)
        dispatch('toastNotification', {
          message: error.response?.data?.message || error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      })

  state.showLoader = false
  state.isDisabled = false
}

const handleResponse = (response) => {
  if (response.status && response.items) {
    $bvModal.hide('add-fb-group-modal')

    EventBus.$emit('refetchSocialPlatforms')

    dispatch('toastNotification', {
      message: "Facebook group added successfully.",
      type: 'success',
    })

    dispatch('setPlatforms', {
      items: response.items,
      type: 'facebook',
      all_item_setter: facebook.SET_ALL_ACCOUNTS,
      item_setter: facebook.SET_ACCOUNTS,
    })

    // fetch the platforms list for composer
    dispatch('fetchPlatformsList')

  } else {
    dispatch('toastNotification', {
      message: response.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
}

const onModalHide = () => {
  state.groupLogo = ''
  state.groupName = ''
  state.groupId = ''
  state.isDisabled = false
  state.showLoader = false
  emit('close')
}

const triggerLogoUpload = (id) => {
  document.getElementById(id).click();
};

const handleLogoUpload = async (event) => {
  state.showLogoLoader = true
  const file = event.target.files[0]
  state.groupLogo = await uploadIntegrationImage(file)
  state.showLogoLoader = false
}

</script>
