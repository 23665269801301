<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@state/base'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import PostTable from '@/src/modules/analytics/views/tiktok/components/PostTable.vue'

const route = useRoute()
const store = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  postsLimit,
  sortingFields,
  postTableCurrentOffset,
  selectedTableSort,
  isPostDataLoading,

  fetchMedia,
  setPostTableDefaultValues,
} = useTiktokAnalytics()

watch(
  () => [dateRange, route.params.accountId],
  async () => {
    isPostDataLoading.value = true
    setPostTableDefaultValues()
    selectedTableSort.value = sortingFields.ENGAGEMENT
    selectedAccount.value = store.getters.getTiktokAccounts.items.find(
      (i) => i.platform_identifier === route.params.accountId
    )
    await fetchMedia(routes.POSTS_DATA, {
      limit: postsLimit.value,
      offset: postTableCurrentOffset.value * postsLimit.value,
      sort_order: selectedTableSort.value,
    })
    isPostDataLoading.value = false
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <PostTable />
</template>
