<template>
  <div class="discovery_main_component fixed_section_top_bar">
    <SocialConfirmBox
      class="discovery_socialConfirm_modal"
    ></SocialConfirmBox>
    <CreatePublicationFolder></CreatePublicationFolder>
    <template
      v-if="
        !$route.name.includes('feeder') &&
        !$route.name.includes('feed_item_social_share')
      "
    >
      <SocialShare></SocialShare>
      <BlogShare></BlogShare>
      <UploadMediaModal type="BlogEditorFile"></UploadMediaModal>
      <InsertFileModal
        class="discovery_insertFile_modal"
        type="BlogEditorFile"
      ></InsertFileModal>
      <PasteFromWord class="discovery_pasteWord_modal"></PasteFromWord>
      <!--<content-rewriting-section-modal class="discovery_content_rewrite_modal"></content-rewriting-section-modal>-->
      <ReplaceGifConfirmation></ReplaceGifConfirmation>
      <ReplaceCurrentTypeConfirmation></ReplaceCurrentTypeConfirmation>
      <ReplaceImageConfirmation></ReplaceImageConfirmation>
      <SocialApprovalModal></SocialApprovalModal>
    </template>
    <template v-else-if="$route.name.includes('feed_item_social_share')">
      <SocialApprovalModal></SocialApprovalModal>
    </template>
    <!--        onboarding tutorial-->
    <VideoModal id="create-custom-topic" position="bottom_right">
      <template v-slot:heading>
        <h2>Get Started With Custom Topic Creation</h2>
      </template>
      <template v-slot:description>
        <!--<h2>To begin using the discovery, you can use either a search section or create your tailored topics according to your needs.</h2>-->
        <!-- , connect a social
                    network <span class="bold">(Facebook, Twitter, LinkedIn, Pinterest, Tumblr)</span> or
                    a blog channel <span class="bold">(WordPress, Medium, Tumblr)</span>.-->
      </template>
      <template v-slot:iframe>
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/NwQR4LrmAY4"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>
    <div class="component_inner">
      <div
        v-if="!$route.path.includes('/discovery/influencers/')"
        class="section_top_bar"
      >
        <div class="d-flex align-items-center">
          <div class="left">
            <ul class="links">
              <li>
                <router-link
                  class="route-btn"
                  tag="button"
                  :disabled="
                    $route.matched.some(
                      ({ name }) => name === 'discover_topics',
                    )
                  "
                  :to="{ name: 'discover_topics' }"
                  data-cy="discover-topics"
                  >Topics
                </router-link>
              </li>
              <li>
                <router-link
                  class="route-btn"
                  tag="button"
                  :class="{
                    'active animate':
                      $route.meta.is_route &&
                      $route.meta.is_route === 'discover_search',
                  }"
                  :to="{ name: 'discover_search' }"
                  data-cy="discover-search"
                  @click="navigateToSearch"
                >
                  Search
                </router-link>
              </li>
              <li class="beta-li-tag">
                <!--<span class="nav-beta">NEW</span>-->
                <router-link
                  class="route-btn"
                  tag="button"
                  :disabled="
                    $route.matched.some(({ name }) => name === 'feeder')
                  "
                  :to="{ name: 'feeder' }"
                  data-cy="discover-feeds"
                  >Feeds
                </router-link>
              </li>
            </ul>
          </div>
          <div class="right ml-auto d-flex">
            <div
              v-if="
                !$route.path.includes('/discovery/content/discover_search') &&
                !$route.path.includes('/discovery/content/discover_insights')
              "
              class="manage-sources-btn"
            >
              <div class="manage-btn" @click.prevent="goToSources">
                <i class="title_icon title_icon_gray icon-settings-cs"></i>
                <span class="text">Manage Sources </span>
              </div>
            </div>

            <ComposerDropdown></ComposerDropdown>
            <!--<trial-ending class=" d-inline-block align-middle"></trial-ending>-->
          </div>
        </div>
      </div>
      <!--<transition name="slide" appear>-->
      <router-view></router-view>
      <!--</transition>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VideoModal from '@common/components/dialogs/onboarding/VideoModal'

import SocialConfirmBox from '@common/components/dialogs/SocialConfirmBox'
import ComposerDropdown from '@common/components/dropdowns/ComposerDropdown'
import SocialApprovalModal from '@common/components/dialogs/approval/SocialApprovalModal'
import CreatePublicationFolder from '@src/modules/composer/components/CreatePublicationFolder'
import InsertFileModal from '../../publish/components/widget/file/InsertFileModal'
import PasteFromWord from '../../publish/components/widget/file/PasteFromWord'
import ReplaceGifConfirmation from '../../publish/components/posting/social/dialogs/ReplaceGifConfirmation'
import ReplaceCurrentTypeConfirmation from '../../publish/components/posting/social/dialogs/ReplaceCurrentTypeConfirmation'
import ReplaceImageConfirmation from '../../publish/components/posting/social/dialogs/ReplaceImageConfirmation'

import { articlesTypes as articleTypes } from '../store/mutation-types'

import SocialShare from '../../publish/components/dialogs/SocialShare'
import BlogShare from '../../publish/components/dialogs/BlogPost'


// import FeedViewApprovalStatus from '@lumotive/planner/components/view/feed-view/FeedViewApprovalStatus'

import UploadMediaModal from '../../publish/components/media-library/components/UploadMediaModal'
export default {
  components: {
    UploadMediaModal,
    ReplaceImageConfirmation,
    VideoModal,
    ReplaceCurrentTypeConfirmation,
    ReplaceGifConfirmation,
    InsertFileModal,
    PasteFromWord,
    SocialShare,
    BlogShare,
    SocialConfirmBox,
    ComposerDropdown,
    SocialApprovalModal,
    CreatePublicationFolder
  },
  data () {
    return {}
  },
  created () {
    // // redirect user ro planner if user role is approver in that workspace
    // let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
    // if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
    //     this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    // }
  },
  async mounted() {
    console.debug('Routeeee', this.$route)

    // if (!this.getActiveWorkspace.onboarding_steps.discover_content.status) {
    //   // await this.onboardingStepsCompleted('discover_content')
    // }



    // When bootstrap modal overlap with another modal then body overflow remove automatically. For this we add overflow class manually
    // $('.discovery_image_modal,.discovery_content_rewrite_modal,.discovery_insertFile_modal,.discovery_pasteWord_modal').on('hide.bs.modal', function () {
    //     setTimeout(function () {
    //         $('body').addClass('modal-open')
    //     },500)
    // })
  },
  // beforeCreate () {
  //   // // redirect user ro planner if user role is approver in that workspace
  //   let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
  //   if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
  //     this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
  //   }
  // },
  computed: {
    ...mapGetters(['getWorkspaces', 'getTopicModule'])
  },
  watch: {},
  methods: {
    ...mapMutations([
      'SET_TOPIC_MODULE',
      'SET_POSTS',
      articleTypes.SET_SEARCH_DISPLAY_TOPICS,
      articleTypes.SET_SEARCH_CONTENT_TYPE,
      articleTypes.SET_SEARCH_ARTICLES_TOPIC
    ]),
    ...mapActions(['setSearch', 'setSearchContentType']),
    navigateToTopics () {
      this.$router.push({ name: 'discover_topics' })
      console.log('method:NavigateToTopics')
      // this.initializeTopics()

      // }
    },
    navigateToSearch () {
      window.scrollTo(0, 0)
      this.setSearch('')

      // if the posts length is greater than 15 and the user clicks on a page to the 15.
      if (this.getPosts && this.getPosts.length > 15) {
        // set the default curated topics view.
        this[articleTypes.SET_SEARCH_DISPLAY_TOPICS](true)
        // set the search content to article
        this[articleTypes.SET_SEARCH_CONTENT_TYPE]('article')
        this[articleTypes.SET_SEARCH_ARTICLES_TOPIC](null)
        // topic module to content.
        this.SET_TOPIC_MODULE('content')
        this.SET_POSTS([])
      }
    },
    goToSources () {
      this.$router.push({
        name: 'feeder_manage_sources',
        params: { workspace: this.getWorkspaces.activeWorkspace.slug }
      })
    }
  }
}
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s;
}

.slide-enter, .slide-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-45px);
}
</style>
