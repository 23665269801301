<template>
  <div class="discovery_article_component">
    <div v-if="false" class="component_inner pt-5">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div class="component_inner">
      <div class="article-search-bar-pane">
        <!-- go back -->
        <template v-if="$route.query.tag || $route.query.q">
          <button
            class="btn article-search-bar-pane__back_button"
            @click.prevent="$router.go(-1)"
          >
            <i class="cs-angle-left"></i> Back
          </button>
        </template>
        <!-- article search input file -->
        <div
          class="filter_section_inner width_800 article-search-bar-pane__search"
        >
          <!-- search input -->
          <ArticleSearchInput :search_module="'article'"></ArticleSearchInput>
          <!-- toggle option such as content feed or insights -->
          <ArticleSearchContentType
            v-if="getIsSearchContentAndInsightsType"
          ></ArticleSearchContentType>
        </div>
        <!-- related topics suggestions -->
        <ArticleSearchRelatedTopicsSuggestions></ArticleSearchRelatedTopicsSuggestions>
      </div>
      <!-- list of manual curated topics that we are showing to the end users. -->
      <template v-if="$route.meta.show_default_view">
        <ArticleSearchCuratedTopicsView
          :is_type_of="'content'"
        ></ArticleSearchCuratedTopicsView>
      </template>
      <template v-else>
        <!-- content filters section such as channel, article filters etc -->

        <!--                <filters-section-slot>-->
        <div class="article-filters-pane">
          <!-- search content and insights filters -->
          <ContentChannelFilters></ContentChannelFilters>
          <template v-if="getIsSearchContentAndInsightsType">
            <ContentArticleFilters></ContentArticleFilters>
          </template>

          <!-- video youtube filters -->

          <template v-if="$route.name === 'discover_search_youtube'">
            <VideoYoutubeFilters></VideoYoutubeFilters>
          </template>
          <!-- twitter channel filters -->

          <template v-if="$route.name === 'discover_search_twitter'">
            <TwitterSearchFilters></TwitterSearchFilters>
          </template>
        </div>
        <!--                </filters-section-slot>-->
      </template>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ArticleSearchInput from './components/ArticleSearchInput'
import ArticleSearchContentType from './components/ArticleSearchContentType'
import ArticleFollowCuratedTopicBlock from './components/ArticleFollowCuratedTopicBlock'
import ArticleSearchCuratedTopicsView from './components/ArticleSearchCuratedTopicsView'
import ArticleSearchRelatedTopicsSuggestions from './components/suggestions/content/ArticleSearchRelatedTopicsSuggestions'
import ContentArticleFilters from './components/filters/ContentArticleFilters'
import ContentChannelFilters from './components/filters/ContentChannelFilters'
import VideoYoutubeFilters from './components/filters/VideoYoutubeFilters'
import TwitterSearchFilters from './components/filters/TwitterSearchFilters'
import FiltersSectionSlot from './slots/FiltersSectionSlot'

export default {
  components: {
    ArticleSearchInput,
    ArticleFollowCuratedTopicBlock,
    ArticleSearchContentType,
    ArticleSearchCuratedTopicsView,
    FiltersSectionSlot,
    ContentArticleFilters,
    ContentChannelFilters,
    ArticleSearchRelatedTopicsSuggestions,
    VideoYoutubeFilters,
    TwitterSearchFilters,
  },
  async created() {
    await this.$store.dispatch('fetchDiscoverTopCuratedTopics')
    if (this.getFolders.length === 0) {
      this.getFavoriteFolders()
    }
  },
  methods: {},
  computed: {
    ...mapGetters([
      'getDiscoverCuratedTopic',
      'getActiveWorkspace',
      'getFolders',
    ]),
    getIsSearchContentAndInsightsType() {
      return (
        this.$route.meta.is_active &&
        ['content', 'insights'].indexOf(this.$route.meta.is_active) >= 0
      )
    },
  },
  watch: {
    async 'getActiveWorkspace._id'(value) {
      await this.$store.dispatch('fetchDiscoverTopCuratedTopics')
    },
  },
}
</script>

<style lang="less">
.is_following {
  button {
    background: #1ecb7b !important;

    span {
      color: #fff !important;

      i {
        color: #fff !important;
        font-weight: bold;
      }
    }
  }
}
</style>
