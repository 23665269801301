<template>
  <div>
    <div class="input_field">
      <div class="radio_input_image">
        <input
          id="build_type_topics"
          v-model="getArticleAutomationDetails.article.build_type"
          value="topics"
          type="radio"
          name="build_type"
        />
        <label for="build_type_topics" class="radio_left"
          >Load keywords and domains from a custom topic
          <span
            v-if="getArticleAutomationDetails.article.build_type === 'topics'"
            >:</span
          >
        </label>
      </div>
      <div
        v-if="getArticleAutomationDetails.article.build_type === 'topics'"
        id="topic_dropdown_id"
        class="relative prevent_close_dropdown with_background inline_block dropdown default_style_dropdown ml-2"
      >
        <div
          data-display="static"
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <span class="text">Select Topic</span>
          <span class="ml-auto">
            <i class="dropdown_arrow icon_last cs-angle-down"></i>
          </span>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <ul class="inner">
            <template v-if="getTopics.length">
              <li
                v-for="(item, key) in getTopics"
                :key="key"
                class="list_item_li"
              >
                <div class="checkbox_input_image d-block">
                  <input
                    :id="'topic_selection_' + item._id"
                    v-model="getArticleAutomationDetails.article.topics"
                    :value="item._id"
                    type="checkbox"
                    @click="platformFilterChange"
                  />
                  <label
                    :for="'topic_selection_' + item._id"
                    class="d-block checkbox_right"
                  >
                    <div class="d-flex align-items-start med">
                      <div class="text_block">
                        <p class="text">{{ item.filters.topics.name }}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </li>
            </template>

            <p v-else class="text-center"
              >You have not created any topic yet.</p
            >
          </ul>
        </div>
      </div>
    </div>

    <div class="input_field mt-0">
      <div class="radio_input_image">
        <input
          id="build_type_new"
          v-model="getArticleAutomationDetails.article.build_type"
          value="new"
          type="radio"
          name="build_type"
        />
        <label for="build_type_new" class="radio_left">Build a new query</label>
      </div>
    </div>

    <div
      v-if="getArticleAutomationDetails.article.build_type === 'new'"
      class="topic_selection_block topic_common_style"
    >
      <div class="radio_block">
        <div class="radio_input_image block">
          <input
            id="t1"
            v-model="getTopicSelection.match_type"
            value="title"
            checked
            type="radio"
            name="m1"
            @change.prevent="articlesPreviewSuggestion"
          />
          <label for="t1" class="radio_left">Match query in titles only</label>
        </div>
        <div class="radio_input_image block">
          <input
            id="t2"
            v-model="getTopicSelection.match_type"
            value="title_body"
            type="radio"
            name="m1"
            @change.prevent="articlesPreviewSuggestion"
          />
          <label for="t2" class="radio_left"
            >Match query in titles and body content.</label
          >
        </div>
      </div>
      <SourceFrom></SourceFrom>
      <div class="input_field">
        <AnyKeywords></AnyKeywords>
        <IncludeDomains></IncludeDomains>
      </div>

      <MustAlsoContainsKeywords></MustAlsoContainsKeywords>
      <MustNotContainsKeywords></MustNotContainsKeywords>

      <ExcludeDomains></ExcludeDomains>
      <LimitDomainsResults></LimitDomainsResults>
    </div>

    <div class="dropdown_section">
      <!-- do not show post length in article to social scenario -->

      <div
        v-if="$route.name !== 'saveArticleSocialAutomation'"
        class="d-flex align-items-center dropdown_list_item"
      >
        <p class="text_left">Post Length</p>

        <div
          v-tooltip.top="'Length'"
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <FilterType :automation="true"></FilterType>
        </div>
      </div>

      <div class="d-flex align-items-center dropdown_list_item">
        <div class="text_left"
          >Post Region

          <!--isHover=true if to show on hover-->
          <!--<custom-tooltip :width="350" :isClick="true" >-->
          <!--<div class="guide_tooltip" slot="htmlContent">-->
          <!--<h3>What is my Loom basic video limits?</h3>-->
          <!--<p>Lorem ipsum dolor sit amet, consectetur-->
          <!--adipisicing elit. Officiis, quis?</p>-->
          <!--<p>consectetur dolor sit amet, <strong>consectetur</strong> adipisicing elit-->
          <!--. Aliquid deserunt exercitationeamet, consectetur adipisicing elit-->
          <!--. Aliquid deserunt <a href="">exercitationem</a> totam.</p>-->
          <!--</div>-->
          <!--</custom-tooltip>-->
        </div>
        <div
          v-tooltip.top="'Region'"
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <FilterRegion :automation="true"></FilterRegion>
        </div>
      </div>

      <div class="d-flex slign-items-center dropdown_list_item">
        <div class="text_left">Post Language </div>
        <div
          v-tooltip.top="'Language'"
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <FilterLanguage :automation="true"></FilterLanguage>
        </div>
      </div>

      <div class="d-flex align-items-center dropdown_list_item">
        <p class="text_left">Post Age</p>
        <div
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="text">{{
              getArticleAutomationDetails.article.post_age.text
            }}</span>
            <span class="ml-auto">
              <i class="dropdown_arrow icon_last cs-angle-down"></i>
            </span>
          </div>
          <div class="dropdown-menu dropdown-menu-right">
            <ul class="inner">
              <li
                v-for="(item, index) in postAge"
                :key="`post_age_list_${index}`"
                class="list_item_li"
                @click="changeArticleAutomationPostAge(item)"
              >
                {{ item.text }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="getArticleAutomationDetails.article.post_age.value === -1"
        class="d-flex align-items-center dropdown_list_item"
      >
        <p class="text_left">Specific date</p>
        <div
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <div class="input_field specific_daterange_picker">
            <!--<input type="text" id="specific_date" v-model="getArticleAutomationDetails.article.post_date"-->
            <!--min="0" placeholder="Select specific date...">-->
            <DateRangePicker
              class="datepicker_input"
              :opens="opens"
              :start-date="getPostAgeStartDate"
              :locale-data="locale"
              :max-date="getPostAgeMaxDate"
              :single-date-picker="singleDatePicker"
              :time-picker="timePicker"
              :time-picker24hour="timePicker24Hour"
              :show-week-numbers="showWeekNumbers"
              :show-dropdowns="showDropdowns"
              :auto-apply="autoApply"
              :ranges="show_ranges ? undefined : false"
              @update="updatePostAgeValues"
            >
              <div
                slot="input"
                slot-scope="picker"
                class="datepicker_content with_bg"
                style="min-width: 250px"
              >
                <i class="icon-Calendar"></i>
                <span>{{ $filters.onlyDate(picker.startDate) }}</span>
              </div>
            </DateRangePicker>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center dropdown_list_item">
        <p class="text_left">Min Social Engagement</p>
        <div
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <div
            class="dropdown_header d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span class="text">{{
              getArticleAutomationDetails.article.engagement_type
            }}</span>
            <span class="ml-auto">
              <i class="dropdown_arrow icon_last cs-angle-down"></i>
            </span>
          </div>
          <div class="dropdown-menu dropdown-menu-right">
            <ul class="inner">
              <li
                class="list_item_li"
                @click="changeArticleAutomationEngagementType('None')"
              >
                None
              </li>

              <li
                class="list_item_li"
                @click="changeArticleAutomationEngagementType('Facebook')"
              >
                Facebook
              </li>

              <li
                class="list_item_li"
                @click="changeArticleAutomationEngagementType('Pinterest')"
              >
                Pinterest
              </li>

              <li
                class="list_item_li"
                @click="changeArticleAutomationEngagementType('Reddit')"
              >
                Reddit
              </li>

              <li
                class="list_item_li"
                @click="changeArticleAutomationEngagementType('Twitter')"
              >
                X (Twitter)
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="getArticleAutomationDetails.article.engagement_type !== 'None'"
        class="d-flex align-items-center dropdown_list_item"
      >
        <p class="text_left">Engagement value</p>
        <div
          class="with_background inline_block dropdown default_style_dropdown"
        >
          <div class="input_field mt-0">
            <input
              v-model="getArticleAutomationDetails.article.engagement_count"
              class="prevent-listener"
              type="number"
              min="0"
              placeholder="Enter engagement value..."
              @change.prevent="changeArticleAutomationEngagementType"
            />
          </div>
        </div>
      </div>
    </div>

    <!--<content-rewriting-section v-if="type === 'Blog'" showLabel="true"-->
    <!--showDefault="true"></content-rewriting-section>-->

    <div class="checkbox_list">
      <template v-if="type === 'Blog'">
        <div class="checkbox_input_image block">
          <input
            id="original_source"
            v-model="getArticleAutomationDetails.original_source"
            type="checkbox"
          />
          <label for="original_source" class="checkbox_left"
            >Add original source
          </label>
        </div>

        <div class="checkbox_input_image block">
          <input
            id="remove_anchors_links"
            v-model="getArticleAutomationDetails.remove_anchors_links"
            type="checkbox"
          />
          <label for="remove_anchors_links" class="checkbox_left"
            >Remove anchor links</label
          >
        </div>
      </template>

      <div class="checkbox_input_image block">
        <input
          id="skip_without_image"
          v-model="getArticleAutomationDetails.article.skip_without_image"
          type="checkbox"
        />
        <label for="skip_without_image" class="checkbox_left"
          >Do not include posts without featured image</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import { article } from '@src/modules/automation/store/recipes/mutation-types'
import FilterRegion from '../../../../discovery/components/filters/FilterRegion'
import FilterType from '../../../../discovery/components/filters/FilterType'
import FilterLanguage from '../../../../discovery/components/filters/FilterLanguage'
import SourceFrom from '../../../../discovery/components/topics/selection/SourceFrom'
import AnyKeywords from '../../../../discovery/components/topics/selection/AnyKeywords'
import IncludeDomains from '../../../../discovery/components/topics/selection/IncludeDomains'
import MustNotContainsKeywords from '../../../../discovery/components/topics/selection/MustNotContainsKeywords'
import MustAlsoContainsKeywords from '../../../../discovery/components/topics/selection/MustAlsoContainsKeywords'
import ExcludeDomains from '../../../../discovery/components/topics/selection/ExcludeDomains'
import LimitDomainsResults from '../../../../discovery/components/topics/selection/LimitDomainsResults'

const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export default {
  components: {
    ExcludeDomains,
    MustNotContainsKeywords,
    IncludeDomains,
    AnyKeywords,
    SourceFrom,
    FilterLanguage,
    FilterType,
    FilterRegion,
    MustAlsoContainsKeywords,
    LimitDomainsResults,
    DateRangePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],
  data() {
    return {
      engagementType: 'None',
      locale: {
        format: 'YYYY-MM-DD', // fomart of the dates displayed
      },
      opens: 'center',
      startDate: '2017-09-19',
      endDate: '2017-10-09',
      minDate: '2016-09-02',
      maxDate: '2019-10-02',
      show_ranges: false,
      singleDatePicker: true,
      timePicker: false,
      timePicker24Hour: true,
      showDropdowns: false,
      autoApply: true,
      showWeekNumbers: false,
      postAge: [
        { text: 'Most Recent Only', value: 3 },
        { text: 'Last 24 hours', value: 1 },
        { text: 'Last 3 days', value: 3 },
        { text: 'Last 7 days', value: 7 },
        { text: 'Last 14 days', value: 14 },
        { text: 'Last 30 days', value: 30 },
        { text: 'Last 2 months', value: 60 },
        { text: 'Last 3 months', value: 90 },
        { text: 'Last 4 months', value: 120 },
        { text: 'Last 5 months', value: 150 },
        { text: 'Last 6 months', value: 180 },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getArticleAutomationDetails',
      'getTopics',
      'getTopicSelection',
    ]),
    getPostAgeMaxDate() {
      const maxDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      if (this.getArticleAutomationDetails.article.post_date === '') {
        this.$store.commit(
          article.SET_ARTICLE_AUTOMATION_POST_DATE,
          maxDate.format('YYYY-MM-DD')
        )
      }
      return maxDate.format('YYYY-MM-DD')
    },
    getPostAgeStartDate() {
      if (this.getArticleAutomationDetails.article.post_date) {
        return this.getArticleAutomationDetails.article.post_date
      }
      const startDate = moment()
        .tz(this.getWorkspaces.activeWorkspace.timezone)
        .add(1, 'hours')
      return startDate.format('YYYY-MM-DD')
    },
  },
  watch: {
    'getArticleAutomationDetails.article.post_age'(postAge) {
      if (postAge.value === 2) {
        this.showAutomationPostAgeDatePickers()
      }
      this.articlesPreviewSuggestion()
    },

    'getArticleAutomationDetails.article.topics'(val) {
      this.articlesPreviewSuggestion()
    },
    'getArticleAutomationDetails.article.build_type'(val) {
      this.getArticleAutomationDetails.article.topics = []
      this.articlesPreviewSuggestion()
    },
    'getArticleAutomationDetails.article.skip_without_image'(val) {
      this.articlesPreviewSuggestion()
    },
  },
  methods: {
    ...mapActions(['articlesPreviewSuggestion']),
    updatePostAgeValues(values) {
      const myDate = moment(values.startDate)
      this.$store.commit(
        article.SET_ARTICLE_AUTOMATION_POST_DATE,
        myDate.format('YYYY-MM-DD')
      )
      this.articlesPreviewSuggestion()
    },
  },
}
</script>
