import { plannerBaseUrl } from '@src/config/api-utils.js'

export const fetchDevicesUrl = plannerBaseUrl + 'fetchDevices'
export const removeDeviceUrl = plannerBaseUrl + 'removeDevice'

export const fetchPlansURL = plannerBaseUrl + 'fetchPlans'

export const fetchPlanPreviewURL = plannerBaseUrl + 'plan/preview'
export const fetchPlansCountURL = plannerBaseUrl + 'fetchPlansCount'
export const removePlanURL = plannerBaseUrl + 'removePlan'
export const changePlanStatusURL = plannerBaseUrl + 'changePlanStatus'
export const sendPostNotificationURL = plannerBaseUrl + 'sendPostNotification'
export const replacePlanURL = plannerBaseUrl + 'replacePlan'
export const processPlanBulkOperationURL =
  plannerBaseUrl + 'processPlanBulkOperation'
export const reschedulePlanURL = plannerBaseUrl + 'reschedulePlan'
export const getPlanVersionHistoryURL = plannerBaseUrl + 'fetchVersionHistory'

// save Filters
export const createPlannerFilterURL = plannerBaseUrl + 'createPlannerFilter'
export const fetchPlannerFiltersURL = plannerBaseUrl + 'fetchPlannerFilter'
export const deletePlannerFiltersURL = plannerBaseUrl + 'deletePlannerFilters'
export const setDefaultPlannerFiltersURL =
  plannerBaseUrl + 'setDefaultPlannerFilters'

export const shuffleQueuePostsURL = plannerBaseUrl + 'shuffleQueuePosts'

// approval

export const planApprovalActionURL = plannerBaseUrl + 'planApprovalAction'

// Labels

export const fetchLabelsUrl = plannerBaseUrl + 'getLabels'
export const removeLabelUrl = plannerBaseUrl + 'deleteLabel'
export const saveLabelUrl = plannerBaseUrl + 'saveLabel'

// comments
export const getCommentsUrl = plannerBaseUrl + 'getComments'
export const deleteCommentsUrl = plannerBaseUrl + 'deleteComment'
export const uploadCommentsURL = plannerBaseUrl + 'image/upload'
export const saveCommentsUrl = plannerBaseUrl + 'saveComment'

// Tasks
export const getTasksUrl = plannerBaseUrl + 'getTasks'
export const saveTasksUrl = plannerBaseUrl + 'saveTask'
export const deleteTasksUrl = plannerBaseUrl + 'deleteTask'
export const editTaskStatusUrl = plannerBaseUrl + 'editTaskStatus'

// Activities
export const getActivitiesURL = plannerBaseUrl + 'getActivity'

export const retryPostPublishURL = plannerBaseUrl + 'retrySocialShare'

export const SOCIAL_PLATFORMS_MEDIA_ENDPOINT = plannerBaseUrl + 'media'

// Swap Posts

export const swapPlanDatesUrl = plannerBaseUrl + 'plans/swapExecutionTime'
