<template>
  <div>
    <!-- Summary Section -->
    <AnalyticsSummaryBlock
      :heading="'Linkedin ' + profile + ' Performance Summary'"
      subtitle="View your key profile performance metrics from the reporting period."
    >
      <div v-if="summary_loader">
        <div class="row">
          <div v-for="i in 4" :key="i" class="col-6 col-sm-3">
            <SkeletonBox :width="'100%'" height="92px" radius="10px" />
          </div>
        </div>
      </div>
      <div v-else class="row">
        <template
          v-for="(value, key) in profile === 'Page'
            ? summary_values
            : summary_values_profile"
          :key="key"
        >
          <div class="col-6 col-sm-3" >
            <SummaryCard
              :title="value"
              :total="summary.current[key]"
              :reach="Math.abs(getReach(key, summary)) + '%'"
              :reach-up="getReachUp(key, summary)"
            ></SummaryCard>
          </div>
        </template>
      </div>
    </AnalyticsSummaryBlock>

    <!-- Audience Growth Section -->
    <div v-if="profile === 'Page'" class="d-flex">
      <div class="col-9 p-0">
        <section
          class="mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Linkedin Audience Growth</h2>
            <p>See how your audience grew during the reporting period.</p>
          </div>

          <template v-if="audience_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="audience.audience_growth.show_data > 0"
              :categories="audience.audience_growth.buckets"
              :series="getAudienceGrowthSeries(audience)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Audience Growth Rollup</h2>
          <template v-if="audience_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <div
              v-for="(value, key) in audience.audience_growth_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="audience.audience_growth_rollup['current'][key]"
                :reach="
                  Math.abs(getReach(key, audience.audience_growth_rollup)) + '%'
                "
                :reach-up="getReachUp(key, audience.audience_growth_rollup)"
                variant="rollup"
              />
            </div>
          </template>
        </section>
      </div>
    </div>

    <!-- Engagement  Section -->
    <div class="d-flex" style="margin-top: 1.6rem">
      <div class="col-9 p-0">
        <section
          class="mt-0 mr-0 analytics-fb-audience h-chart-section-wrapper"
        >
          <div class="analytics-section-header">
            <h2>Linkedin Engagement</h2>
            <p
              >See how your engagement grew on the posts during the reporting
              period.</p
            >
          </div>

          <template v-if="engagement_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <SplineBasicChart
              v-if="engagement.engagement.show_data > 0"
              :categories="engagement.engagement.buckets"
              :series="getEngagementSeries(engagement)"
            />
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-3 p-0 m-0 analytics-slide-summary">
        <section class="mt-0">
          <h2>Engagement Rollup</h2>
          <template v-if="engagement_loader">
            <div v-for="i in 4" :key="i" style="margin-top: 1.3rem">
              <SkeletonBox :width="'100%'" height="92px" radius="10px" />
            </div>
          </template>
          <template v-else>
            <div
              v-for="(value, key) in engagement.engagement_values"
              :key="key"
            >
              <SummaryCard
                :title="value"
                :total="engagement.engagement_rollup['current'][key]"
                :reach="
                  Math.abs(getReach(key, engagement.engagement_rollup)) + '%'
                "
                :reach-up="getReachUp(key, engagement.engagement_rollup)"
                variant="rollup"
              />
            </div>
          </template>
        </section>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-6 p-0">
        <section class="mr-0 analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Post Density - Daily</h2>
          </div>

          <template v-if="posts_density_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <ColumnChart
              v-if="posts_per_days.data.show_data"
              :series="getPostsDensitySeries(posts_per_days)"
              :categories="getDaysBuckets(posts_per_days)"
            ></ColumnChart>
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
        </section>
      </div>
      <div class="col-6 p-0">
        <section class="analytics-fb-audience h-chart-section-wrapper">
          <div class="analytics-section-header">
            <h2>Top Hashtags</h2>
          </div>
          <template v-if="hashtag_loader">
            <div class="h-chart flex-center-center" style="height: 90%">
              <SkeletonBox
                height="250px"
                width="300px"
                type="chart"
                radius="4px"
              />
            </div>
          </template>
          <template v-else>
            <WordCloud
              v-if="top_hashtags.length"
              :series="getHashtagsSeries(top_hashtags)"
            ></WordCloud>
            <div v-else class="flex-center-center" style="height: 90%">
              <div class="analytics-no-data-found">
                <img
                  src="../../../assets/imgs/no_data_images/no-analytical-data-available.svg"
                  alt=""
                />
                <p> No Data Found!</p>
              </div>
            </div>
          </template>
          <!--    LOADER      -->
          <!--          <div class="h-chart" style="display: flex;justify-content: center;align-items: center;height: 90%;">-->
          <!--          <SkeletonBox height="250px" width="300px" type="chart" radius="4px"/>-->
          <!--          </div>-->
        </section>
      </div>
    </div>

    <!-- linkedin Top Posts -->
    <section style="margin-top: 3.2rem">
      <h2>LinkedIn Top Posts</h2>
      <p
        >Review your top posts published during the selected time period, based
        on the post’s lifetime performance.</p
      >
      <template v-if="top_post_loader">
        <div class="row">
          <template v-for="i in 3" :key="i" >
            <div class="col-4">
              <div class="analytics-post-card">
                <div class="analytics-post-card__header">
                  <div class="analytics-post-card__header-left">
                    <div class="analytics-post-card__header-img">
                      <SkeletonBox
                        height="2.8rem"
                        width="2.8rem"
                        radius="50%"
                      />
                    </div>
                    <div class="analytics-post-card__header-detail">
                      <div
                        class="d-flex"
                        style="flex-direction: column; margin-left: 10px"
                      >
                        <div class="mb-1">
                          <SkeletonBox width="100px" radius=".2rem" />
                        </div>
                        <SkeletonBox radius=".2rem" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="analytics-post-card__header-right"
                    style="filter: grayscale(100%); opacity: 0.6"
                  >
                    <!-- Dynamic Icon bases on props -->
                    <i class="icon-facebook_icon"></i>
                  </div>
                </div>

                <div class="analytics-post-card__content">
                  <div class="mb-1">
                    <SkeletonBox width="100%" radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox radius=".2rem" />
                  </div>
                  <div class="mb-2">
                    <SkeletonBox width="100%" height="150px" radius=".2rem" />
                  </div>
                </div>

                <ul class="analytics-post-card__content-ele">
                  <li v-for="i in 4" :key="i">
                    <SkeletonBox width="40%" radius=".2rem" />
                    <SkeletonBox width="1.1rem" radius=".2rem" />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify_center analytics-post-card-row">
          <template v-if="top_posts.length <= 0">
            <div class="analytics-no-data-found">
              <img
                src="../../../assets/imgs/no_data_images/no_post_found.svg"
                alt=""
              />
              <p
                >You do not have any posts published in the selected time
                period.</p
              >
            </div>
          </template>
          <template v-for="i in 3" v-else :key="i">
            <div  :class="top_posts.length >= 3 ? 'col-4' : 'col-6'">
              <template v-if="top_posts.length >= i">
                <PostCard :accounts="account" :post="top_posts[i - 1]" platform_type="linkedin" />
              </template>
              <template v-else>
                <div class="analytics-post-card">
                  <div class="analytics-post-card__header">
                    <div class="analytics-post-card__header-left">
                      <div class="analytics-post-card__header-img">
                        <SkeletonBox
                          class="skeletonBox--noanimcation"
                          height="2.8rem"
                          width="2.8rem"
                          radius="50%"
                        />
                      </div>
                      <div class="analytics-post-card__header-detail">
                        <div
                          class="d-flex"
                          style="flex-direction: column; margin-left: 10px"
                        >
                          <div class="mb-1"> No More Post </div>
                          <SkeletonBox
                            class="skeletonBox--noanimcation"
                            radius=".2rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="analytics-post-card__header-right"
                      style="filter: grayscale(100%); opacity: 0.6"
                    >
                      <!-- Dynamic Icon bases on props -->
                      <i class="far fa-external-link-square-alt"></i>
                    </div>
                  </div>

                  <div class="analytics-post-card__content">
                    <div class="mb-1">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        radius=".2rem"
                      />
                    </div>
                    <div class="mb-2">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="100%"
                        height="260px"
                        radius=".2rem"
                      />
                    </div>
                  </div>

                  <ul class="analytics-post-card__content-ele">
                    <li v-for="i in 6" :key="i">
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="40%"
                        radius=".2rem"
                      />
                      <SkeletonBox
                        class="skeletonBox--noanimcation"
                        width="1.1rem"
                        radius=".2rem"
                      />
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import AnalyticsSummaryBlock from '@src/modules/analytics/views/overview/components/AnalyticsSummaryBlock'
import SummaryCard from '@src/modules/analytics/views/overview/components/SummaryCard'
import PostCard from '@src/modules/analytics/views/common/PostCard'

// Charts
import SplineBasicChart from '@src/modules/analytics/views/common/Infographics/SplineBasicChart'
import ColumnChart from '@src/modules/analytics/views/common/Infographics/ColumnChart'
import LineBasicChart from '@src/modules/analytics/views/common/Infographics/LineBasicChart'
import WordCloud from '@src/modules/analytics/views/common/Infographics/WordCloud'

// SkeletonBox
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { dataValues } from '@src/modules/analytics/components/common/helper'
import { mapActions } from 'vuex'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import linkedinMixin from '@src/modules/analytics/components/common/series/linkedinMixin'

export default {
  name: 'OverviewTab',
  components: {
    AnalyticsSummaryBlock,
    SummaryCard,
    PostCard,
    SkeletonBox,
    SplineBasicChart,
    LineBasicChart,
    ColumnChart,
    WordCloud
  },
  mixins: [analyticsUtilsMixin, linkedinMixin],
  props: ['account', 'date', 'previous_date', 'getOverviewLoader', 'profile'],
  data () {
    return dataValues().linkedin.overview
  },
  computed: {
    getFetchingData () {
      return (
        this.summary_loader ||
        this.audience_loader ||
        this.posts_density_loader ||
        this.top_post_loader ||
        this.engagement_loader
      )
    }
  },
  mounted () {
    console.log('Linkedin Account')
    console.log(this.account)
    this.initialize()
  },
  methods: {
    ...mapActions(['getAnalyzeService']),
    initialize () {
      this.getSummary()
      this.getAudienceGrowth()
      this.getEngagement()
      this.getTopPosts()
      this.getPostsDensity()
      this.getHashtags()
    },
    async getSummary () {
      if (this.summary_loader === true) {
        return
      }
      this.summary_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'summary',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          if ('current' in response.overview) {
            this.summary = response.overview
          }
      }
      this.summary_loader = false
    },
    async getEngagement () {
      if (this.engagement_loader === true) {
        return
      }
      this.engagement_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'engagement',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.engagement.engagement = response.engagement
          this.engagement.engagement_rollup = response.engagement_rollup
      }
      this.engagement_loader = false
    },
    async getAudienceGrowth () {
      if (this.audience_loader === true) {
        return
      }
      this.audience_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'audience_growth',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.audience.audience_growth = response.audience_growth
          this.audience.audience_growth_rollup = response.audience_growth_rollup
      }
      this.audience_loader = false
    },
    async getPostsDensity () {
      if (this.posts_density_loader === true) {
        return
      }
      this.posts_density_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'posts_per_days',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          console.log("Response for posts per days is: ", response)
          this.posts_per_days = response.posts_per_days
      }
      this.posts_density_loader = false
    },
    async getTopPosts () {
      if (this.top_post_loader === true) {
        return
      }
      this.top_post_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'top_posts',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.top_posts = response.top_posts
      }
      this.top_post_loader = false
    },
    async getHashtags () {
      if (this.hashtag_loader === true) {
        return
      }
      this.hashtag_loader = true
      if ('linkedin_id' in this.account) {
        const response = await this.getAnalyzeService({
          accounts: this.account.linkedin_id,
          date: this.date,
          section: 'hashtags',
          tab: 'overview',
          type: 'linkedin',
          timezone: this.getActiveWorkspace.timezone,
          previous_date: this.previous_date,
          workspace_id: this.getActiveWorkspace._id
        })
          this.top_hashtags = response.top_hashtags
      }
      this.hashtag_loader = false
    }
  },
  watch: {
    account (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    getFetchingData (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOverviewLoader(newVal)
      }
    },
    date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    },
    previous_date (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialize()
      }
    }
  }
}
</script>
