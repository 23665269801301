<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import { commonMethods } from '@common/store/common-methods'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel';
import {
  socialIntegrations,
  socialIntegrationsConfigurations
} from '@src/modules/integration/config/api-utils'
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    details: {
      type: Object,
      default: () => {},
    },
    threadedTweetIndex: {
      type: Number,
      default: 0,
    },
    imageUploading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alt_text_modal: false,
      alt_text_obj: {
        image: null,
        alt_text: '',
      },
      selected_image: {
        image: undefined,
        index: -1,
      },
      alt_text_twitter_limit: 1000,
      image_pintura_loader: true,
    }
  },
  computed: {
    ...mapGetters([
      'getPublishLoaders',
      'getCommonSharingProgressBar',
      'getFacebookSharingProgressBar',
      'getTwitterSharingProgressBar',
      'getThreadedTweetsSharingDetails',
      'getLinkedinSharingProgressBar',
      'getPinterestSharingProgressBar',
      'getTumblrSharingProgressBar',
      'getInstagramSharingProgressBar',
      'getGmbSharingProgressBar',
      'getGmbSharingLoaders',
      'getInstagramSharingLoaders',
      'getLinkedinSharingLoaders',
    ]),
    getUnselectedImages() {
      const stateObject = this
      return this.details.image_suggestions.filter(
        (el) => !stateObject.details.image.includes(el)
      )
    },

    newImageLoader() {
      switch (this.type) {
        case 'Common':
          if (this.getPublishLoaders.uploadCommonSharingImage.position === -1) {
            return this.getPublishLoaders.uploadCommonSharingImage.status
          }
          break
        case 'Facebook':
          if (
            this.getPublishLoaders.uploadFacebookSharingImage.position === -1
          ) {
            return this.getPublishLoaders.uploadFacebookSharingImage.status
          }
          break
        case 'Twitter':
          if (
            this.getPublishLoaders.uploadTwitterSharingImage.position === -1
          ) {
            return this.getPublishLoaders.uploadTwitterSharingImage.status
          }
          break
        case 'ThreadedTweets':
          if (
            this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
              .uploadThreadedTweetsSharingImage.position === -1
          ) {
            return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
              .uploadThreadedTweetsSharingImage.status
          }
          break
        case 'Linkedin':
          // checking the loader status of image uploading
          if (
            this.getLinkedinSharingLoaders.upload_sharing_image.position === -1
          )
            return this.getLinkedinSharingLoaders.upload_sharing_image.status
          break
        case 'Pinterest':
          return this.getPublishLoaders.uploadPinterestSharingImage
        case 'Tumblr':
          return this.getPublishLoaders.uploadTumblrSharingImage
        case 'Instagram':
          if (
            this.getInstagramSharingLoaders.upload_sharing_image.position === -1
          )
            return this.getInstagramSharingLoaders.upload_sharing_image.status
          break
        case 'Gmb':
          return this.getGmbSharingLoaders.upload_sharing_image
        default:
          return false
      }
      return false
    },

    getSharingLoader() {
      switch (this.type) {
        case 'Common':
          return this.getPublishLoaders.uploadCommonSharingImage
        case 'Facebook':
          return this.getPublishLoaders.uploadFacebookSharingImage
        case 'Twitter':
          return this.getPublishLoaders.uploadTwitterSharingImage
        case 'ThreadedTweets':
          return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
            .uploadThreadedTweetsSharingImage
        case 'Linkedin':
          return this.getLinkedinSharingLoaders.upload_sharing_image
        case 'Pinterest':
          return this.getPublishLoaders.uploadPinterestSharingImage
        case 'Tumblr':
          return this.getPublishLoaders.uploadTumblrSharingImage
        case 'Instagram':
          return this.getInstagramSharingLoaders.upload_sharing_image
        case 'Gmb':
          return this.getGmbSharingLoaders.upload_sharing_image
        default:
          return false
      }
    },

    getSharingImagePercentage() {
      switch (this.type) {
        case 'Common':
          return this.getCommonSharingProgressBar.image
        case 'Facebook':
          return this.getFacebookSharingProgressBar.image
        case 'Twitter':
          return this.getTwitterSharingProgressBar.image
        case 'ThreadedTweets':
          return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
            .image
        case 'Linkedin':
          return this.getLinkedinSharingProgressBar.image
        case 'Pinterest':
          return this.getPinterestSharingProgressBar.image
        case 'Tumblr':
          return this.getTumblrSharingProgressBar.image
        case 'Instagram':
          return this.getInstagramSharingProgressBar.image
        case 'Gmb':
          return this.getGmbSharingProgressBar.image
        default:
          return null
      }
    },

    getUploadSharingImagesCount() {
      switch (this.type) {
        case 'Common':
          return this.getCommonSharingProgressBar.numberOfImages
        case 'Facebook':
          return this.getFacebookSharingProgressBar.numberOfImages
        case 'Twitter':
          return this.getTwitterSharingProgressBar.numberOfImages
        case 'ThreadedTweets':
          return this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
            .numberOfImages
        case 'Linkedin':
          return this.getLinkedinSharingProgressBar.numberOfImages
        case 'Instagram':
          return this.getInstagramSharingProgressBar.numberOfImages
      }
      return 1
    },

    getSharingImageUpdateId() {
      switch (this.type) {
        case 'Common':
          return 'commonSharingImageUpdate'
        case 'Facebook':
          return 'facebookSharingImageUpdate'
        case 'Twitter':
          return 'twitterSharingImageUpdate'
        case 'ThreadedTweets':
          return 'threadedTweetsSharingImageUpdate_' + this.threadedTweetIndex
        case 'Linkedin':
          return 'linkedinSharingImageUpdate'
        case 'Pinterest':
          return 'pinterestSharingImageUpdate'
        case 'Tumblr':
          return 'tumblrSharingImageUpdate'
        case 'Instagram':
          return 'instagramSharingImageUpdate'
        case 'Gmb':
          return 'gmbSharingImageUpdate'
        default:
          return ''
      }
    },

    getSharingEnhanceId() {
      switch (this.type) {
        case 'Common':
          return 'enhanceCommonSharingImage'
        case 'Facebook':
          return 'enhanceFacebookSharingImage'
        case 'Twitter':
          return 'enhanceTwitterSharingImage'
        case 'ThreadedTweets':
          return 'enhanceThreadedTweetsSharingImage_' + this.threadedTweetIndex
        case 'Linkedin':
          return 'enhanceLinkedinSharingImage'
        case 'Pinterest':
          return 'enhancePinterestSharingImage'
        case 'Tumblr':
          return 'enhanceTumblrSharingImage'
        case 'Instagram':
          return 'enhanceInstagramSharingImage'
        case 'Gmb':
          return 'enhanceGmbSharingImage'
        default:
          return null
      }
    },

    /**
     *  Contextual validation for the alt-text text area.
     */
    textAreaValidation() {
      let resp = null
      if (this.alt_text_obj.alt_text !== undefined) {
        resp = this.alt_text_obj.alt_text.length <= 1000 ? null : false
      }
      return resp
    },

    altTextButtonStatus() {
      return this.altTextAreaCounter() > this.alt_text_twitter_limit
    },
  },

  created() {
    console.debug(
      'Created:ImageSection',
      this.type,
      this.details,
      this.threadedTweetIndex,
      this.getThreadedTweetsSharingDetails[this.threadedTweetIndex]
        .uploadThreadedTweetsSharingImage.position
    )
  },

  mounted() {
    if (this.details.alt_texts === undefined) {
      this.details.alt_texts = []
    }
  },
  methods: {
    isGif(url) {
      console.debug('ISGif', url, this.getSocialSharingMediaDetails)
      if (
        this.getSocialSharingMediaDetails[url] &&
        this.getSocialSharingMediaDetails[url].mime_type
      ) {
        return this.getSocialSharingMediaDetails[url].mime_type === 'image/gif'
      } else {
        return false
      }
    },

    displayFile(media, index) {
      console.debug('Method::displayFile')
      EventBus.$emit('displayFile', {
        type: 'image',
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },

    hasImageError(index) {
      const errors = this.postImageErrors(index)
      return !!(errors && errors.length > 0)
    },

    errorsContent(index) {
      let content = '<ul>'
      const errors = this.postImageErrors(index)
      errors.forEach((error) => {
        content += '<li><span>' + error + '</span></li>'
      })
      content += '</ul>'

      return content
    },

    gifContent(index) {
      return (
        '<span>' + 'Editing a Gif will convert it into a PNG image.' + '</span>'
      )
    },

    postImageErrors(index) {
      console.debug('Method::postImageErrors', index)
      let errors = []
      const stateObject = this

      if (this.getCommonBoxOptions.status && this.type !== 'ThreadedTweets') {
        socialIntegrations.forEach((integration) => {
          errors = errors.concat(
            stateObject.specificImageErrors(integration, index)
          )
        })
        return errors
      }

      errors = stateObject.specificImageErrors(this.type.toLowerCase(), index)

      return errors
    },

    specificImageErrors(type, index) {
      console.debug('Method::specificImageErrors', type, index)
      if (type === 'threadedtweets') {
        type = 'twitter'
      }
      const errors = []
      const selectedPlatforms = this.getAccountSelection
      const mediaDetails = this.getSocialSharingMediaDetails

      // checking specific type account is selected
      if (
        selectedPlatforms &&
        selectedPlatforms[type] &&
        selectedPlatforms[type].length
      ) {
        // checking for the image/gif validations
        if (
          this.details.image &&
          socialIntegrationsConfigurations[type] &&
          socialIntegrationsConfigurations[type].validations.image
        ) {
          const images =
            this.details.image.constructor === Array
              ? this.details.image
              : [this.details.image]
          if (images[index]) {
            // checking for the image validations
            if (
              mediaDetails[images[index]] &&
              mediaDetails[images[index]].mime_type !== 'image/gif'
            ) {
              if (
                mediaDetails[images[index]].size >
                socialIntegrationsConfigurations[type].validations.image
                  .max_size
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.image
                        .max_size_single_image_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.image
                      .max_size_single_image_error
                  )
                }
              }

              if (
                mediaDetails[images[index]].size <
                socialIntegrationsConfigurations[type].validations.image
                  .min_size
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.image
                        .min_size_single_image_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.image
                      .min_size_single_image_error
                  )
                }
              }

              if (
                index === 0 &&
                (mediaDetails[images[index]].height <
                  socialIntegrationsConfigurations[type].validations.image
                    .min_height ||
                  mediaDetails[images[index]].width <
                    socialIntegrationsConfigurations[type].validations.image
                      .min_width)
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.image
                        .min_dimension_single_image_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.image
                      .min_dimension_single_image_error
                  )
                }
              }

              if (
                mediaDetails[images[index]].height >
                  socialIntegrationsConfigurations[type].validations.image
                    .max_height ||
                mediaDetails[images[index]].width >
                  socialIntegrationsConfigurations[type].validations.image
                    .max_width
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.image
                        .max_dimension_single_image_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.image
                      .max_dimension_single_image_error
                  )
                }
              }
            }

            // checking for the gif validations
            if (
              mediaDetails[images[index]] &&
              mediaDetails[images[index]].mime_type === 'image/gif'
            ) {
              if (
                mediaDetails[images[index]].size >
                socialIntegrationsConfigurations[type].validations.gif.size
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.gif
                        .size_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.gif
                      .size_error
                  )
                }
              }

              if (
                mediaDetails[images[index]].height >
                  socialIntegrationsConfigurations[type].validations.gif
                    .max_height ||
                mediaDetails[images[index]].width >
                  socialIntegrationsConfigurations[type].validations.gif
                    .max_width
              ) {
                if (type === 'instagram') {
                  if (this.getInstagramPostingOption === 'api') {
                    errors.push(
                      socialIntegrationsConfigurations[type].validations.gif
                        .max_dimension_error
                    )
                  }
                } else {
                  errors.push(
                    socialIntegrationsConfigurations[type].validations.gif
                      .max_dimension_error
                  )
                }
              }
            }

            if (
              type === 'instagram' &&
              this.getInstagramPostingOption === 'api' &&
              mediaDetails[images[index]] &&
              mediaDetails[images[index]].zapier &&
              !mediaDetails[images[index]].zapier.status
            ) {
              errors.push(
                socialIntegrationsConfigurations[type].validations.image
                  .min_dimension_single_image_error
              )
            }
          }
        }
      }
      return errors
    },

    getFileTitle(file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let title = ''
      if (mediaDetails[file]) {
        if (mediaDetails[file].name) title = mediaDetails[file].name + ' '
      }
      return title
    },

    getFileTooltip (file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let tooltip = '<div class="d-flex justify-content-between">'
      if (mediaDetails[file]) {
        tooltip = '<div class="d-flex justify-content-between">'
        if (mediaDetails[file].name) tooltip += mediaDetails[file].name + ' '
        if (mediaDetails[file].size)
          tooltip +=
            "<span class='ml-1'><b>" +
            this.bytesToSize(mediaDetails[file].size) +
            '</b></span>'
        tooltip += '</div>'

        const altText = this.altTextToolTip(file, true)

        if (altText)
          tooltip +=
            "<div class='mt-2 w-100 text-left'><b>Alt Text: </b>" +
            altText +
            '</div>'
      }
      return tooltip
    },

    getFileExtention (file) {
      const mediaDetails = this.getSocialSharingMediaDetails
      let extention = ''
      if (mediaDetails[file] && mediaDetails[file].mime_type) {
        extention = mediaDetails[file].mime_type.replace('image/', '.')
      }
      return extention
    },

    /**
     * when the Add Description button is triggered.
     * @param image
     * @param index
     */
    triggerAltTextModal(image, index) {
      this.selected_image.image = image
      this.selected_image.index = index

      // eslint-disable-next-line camelcase
      let temp_selected = this.details.alt_texts.filter(
        (altText) => altText.image === image
      )
      if (temp_selected.length > 0) {
        this.selected_image.alt_text =
          temp_selected[temp_selected.length - 1].alt_text
      } else {
        this.selected_image.alt_text = ''
      }

      // eslint-disable-next-line camelcase
      temp_selected = null
      this.alt_text_obj = {
        image,
        alt_text: this.selected_image.alt_text,
      }
      this.alt_text_modal = true
    },

    /**
     * Callback for When the Alt-Text modal is closed
     * @param event
     * @param image_index
     */
    // eslint-disable-next-line camelcase
    close_alt_text_modal(event, image_index) {
      // check for necessary validation.
      if (this.altTextAreaCounter() > 1000) {
        console.log('Twitter alt-text char limit exceeded.')
        return
      }

      this.alt_text_modal = false
      //   push alt_text_obj in the details.
      //   this.details.alt_texts = [];

      this.details.alt_texts.push(this.alt_text_obj)

      //   clear the alt_text_obj
      this.alt_text_obj = {
        image: null,
        alt_text: '',
      }
    },

    /**
     * Because we can't use v-model for the tooltip due to the dynamic structure of the alt-text injection in this.details we have to use a method to return a proposed tooltip text.
     * @param image
     * @param flag
     * @returns {string|null}
     */
    altTextToolTip: function (image, flag = false) {
      // Old Requirement.
      /* var resp = 'Add Media Description/ALT Text';
                var temp_selected = [];
                if(this.details.alt_texts){
                    temp_selected = this.details.alt_texts.filter(alt_text => alt_text.image == image);
                }
                if(temp_selected.length > 0){
                    resp = temp_selected[temp_selected.length - 1].alt_text.length > 0 ? 'Update Media Description/ALT Text' : resp;

                }

                return resp; */

      let tooltip = 'Add Media Description/ALT Text'
      if (this.details.alt_texts !== undefined) {
        // eslint-disable-next-line camelcase
        const temp_selected = this.details.alt_texts.filter(
          (altText) => altText.image === image
        )
        if (temp_selected.length > 0) {
          tooltip =
            temp_selected[temp_selected.length - 1].alt_text === ''
              ? tooltip
              : temp_selected[temp_selected.length - 1].alt_text
          tooltip =
            tooltip.length > 100 ? tooltip.substr(0, 100) + ' ...' : tooltip
        }
      }
      if (flag) {
        if (tooltip !== 'Add Media Description/ALT Text') return tooltip
        else return null
      }
      return tooltip
    },

    /**
     * Counter for alt-texts
     * @returns {number}
     */
    altTextAreaCounter() {
      let counter = 0
      if (this.alt_text_obj.alt_text) {
        counter = this.alt_text_obj.alt_text.length
      }
      return counter
    },
  },
}
</script>

<template>
  <div class="bottom_image_selection">
    <p
      v-if="
        (type === 'Common' ||
          type === 'Facebook' ||
          type === 'Twitter' ||
          type === 'ThreadedTweets') &&
        getUnselectedImages.length > 0 &&
        !sharingPendingProcessStatus
      "
      class="top_text"
      >Suggested Images ({{ getUnselectedImages.length }})</p
    >

    <div class="left_img">
      <template v-if="details.image.constructor === Array">
        <div
          v-for="(image, index) in details.image"
          :key="index"
          v-tooltip="{
            placement: 'top-start',
            content: getFileTooltip(image),
             delay: { show: 400 },
          allowHTML: true,
          }"
          class="img composer_selected_item"
          :style="{ 'background-image': 'url(' + image + ')' }"
        >
          <div
            v-if="
              getSharingLoader.status && getSharingLoader.position === index
            "
            class="list_plan_bars uploading_bar d-flex justify-content-center flex-column"
          >
            <template v-if="getSharingImagePercentage">
              <p class="desc_text">Uploading...</p>
              <div class="bar_item">
                <div class="item_inner">
                  <div
                    class="limit_add"
                    :style="{ width: getSharingImagePercentage + '%' }"
                  ></div>
                  <div class="limit_exceed"></div>
                </div>
              </div>
            </template>

            <clip-loader v-else color="#4165ed" :size="'16px'"></clip-loader>
          </div>
          <!-- {{this.getThreadedTweetsSharingDetails[this.threadedTweetIndex].uploadThreadedTweetsSharingImage}} -->
          <div
            class="display_layer"
            @click.prevent="displayFile(details.image, index)"
          ></div>
          <i
            class="cs-cross cross_icon"
            @click.prevent="removeSharingImage(type, image, threadedTweetIndex)"
          ></i>

          <!--                    <div class="composer-file-format" v-if="getFileExtention(image)">{{getFileExtention(image)}}</div>-->
          <div class="image-footer">
            <div class="image-footer__title">
              {{ getFileTitle(image) }}
            </div>

            <div class="image-footer__buttons">
              <i
                v-tooltip.top="'Add Media Description/ALT Text'"
                class="image_tool_item"
                :disabled="sharingBoxPendingProcessStatus(type)"
                @click.prevent="triggerAltTextModal(image, index)"
              >
                ALT
              </i>

              <i
                v-tooltip.top="'Edit'"
                class="icon-edit-cs image_tool_item"
                :disabled="
                  sharingBoxPendingProcessStatus(type, threadedTweetIndex)
                "
                @click.prevent="
                  triggerSharingImageEnhance(type, index, threadedTweetIndex)
                "
              >
              </i>
            </div>

            <!--<i v-tooltip.top="'Edit'" class="cs-paint-brush image_tool_item " :disabled="sharingBoxPendingProcessStatus(type)" @click.prevent="triggerSharingImageEnhance(type,index)">
            </i>-->

            <img :id="getSharingEnhanceId" class="hide" src="" data-index="" />
          </div>

          <v-menu
            v-if="hasImageError(index)"
            offset="10"
            placement="bottom-start"
            popper-class="tooltip-danger tooltip-danger--clickable"
            :popper-triggers="['hover']"
          >
            <!-- This will be the popover target (for the events and position) -->
            <div class="error-message-icon" @click.prevent="false">
              <i class="far fa-exclamation-circle"></i>
            </div>

            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div v-html="errorsContent(index)"></div>
            </template>
          </v-menu>

          <v-menu
            v-else-if="isGif(image)"
            offset="10"
            placement="bottom-start"
            popper-class="tooltip-danger tooltip-danger--clickable"
            :popper-triggers="['hover']"
          >
            <!-- This will be the popover target (for the events and position) -->
            <div
              class="error-message-icon error-message-icon--info"
              @click.prevent="false"
            >
              <i class="far fa-exclamation-circle"></i>
            </div>

            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div v-html="gifContent(index)"></div>
            </template>
          </v-menu>

          <!--                  <div class="error-message-icon" v-if="hasImageError(index)">-->
          <!--                    <i v-tooltip.bottom-start="{-->
          <!--                      content:errorsContent(index),-->
          <!--                      // trigger: ,'manual',-->
          <!--                      // show: true,-->
          <!--                      classes: 'tooltip-danger'}" class="far fa-exclamation-circle">-->

          <!--                    </i>-->
          <!--                  </div>-->
        </div>
      </template>

      <div
          v-else-if="details.image && !newImageLoader"
          v-tooltip="{
    placement: 'top-start',
    content: getFileTooltip(details.image),
    delay: { show: 400 },
   allowHTML: true,
  }"
          class="img composer_selected_item"
          :style="{ 'background-image': 'url(' + details.image + ')' }"
      >
        <div
          class="display_layer"
          @click.prevent="displayFile([details.image], 0)"
        ></div>
        <i
          class="cs-cross cross_icon"
          @click.prevent="
            removeSharingImage(type, details.image, threadedTweetIndex)
          "
        ></i>
        <!--                <div class="composer-file-format" v-if="getFileExtention(details.image)">-->
        <!--                    {{getFileExtention(details.image)}}-->
        <!--                </div>-->
        <div class="image-footer">
          <div class="image-footer__buttons">
            <i
              v-tooltip.top="'Upload'"
              class="cs-upload-arrow image_tool_item"
              :disabled="
                sharingBoxPendingProcessStatus(type, threadedTweetIndex)
              "
              @click.prevent="
                triggerSharingImageUpdate(getSharingImageUpdateId)
              "
            ></i>

            <i
              v-tooltip.top="'Edit'"
              class="cs-paint-brush image_tool_item"
              :disabled="
                sharingBoxPendingProcessStatus(type, threadedTweetIndex)
              "
              @click.prevent="
                triggerSharingImageEnhance(type, -1, threadedTweetIndex)
              "
            ></i>
          </div>
          <img :id="getSharingEnhanceId" class="hide" src="" />
        </div>

        <div v-if="hasImageError(0)" class="error-message-icon">
          <i
            v-tooltip.bottom-start="{
              content: errorsContent(0),
              classes: 'tooltip-danger',
            }"
            class="far fa-exclamation-circle"
          ></i>
        </div>
      </div>

      <div
        v-if="newImageLoader || imageUploading"
        class="img composer_selected_item"
      >
        <div
          class="list_plan_bars uploading_bar d-flex justify-content-center flex-column"
        >
          <p v-if="getUploadSharingImagesCount > 1" class="desc_text">
            Uploading...({{ getUploadSharingImagesCount }})</p
          >
          <div v-if="getSharingImagePercentage" class="bar_item">
            <div class="item_inner">
              <div
                class="limit_add"
                :style="{ width: getSharingImagePercentage + '%' }"
              ></div>
              <div class="limit_exceed"></div>
            </div>
          </div>
          <clip-loader v-else color="#4165ed" :size="'16px'"></clip-loader>
        </div>
      </div>
    </div>

    <template
      v-if="
        (type === 'Common' ||
          type === 'Facebook' ||
          type === 'Twitter' ||
          type === 'ThreadedTweets') &&
        getUnselectedImages.length > 0 &&
        !sharingPendingProcessStatus
      "
    >
      <div class="right_img">
        <div class="scroll_area">
          <div
            v-for="(image, index) in getUnselectedImages"
            :key="index"
            class="img"
            :style="{ 'background-image': 'url(' + image + ')' }"
            @click="addSharingImage(image, type, threadedTweetIndex)"
          ></div>
        </div>
      </div>
    </template>

    <b-modal
      id="alt-text-modal"
      ref="alt-text-modal"
      v-model="alt_text_modal"
      no-close-on-backdrop
      centered
      hide-header
      size="lg"
    >
      <header class="modal-header media-library-modal-header">
        <h3 class="font-weight-bold my-auto">
          Add Media Description/ALT Text
          <!--<a class="beacon ml-2" v-tooltip.top="'Learn more about Alt-Texts'"
             href="#">
              <i class="far fa-question-circle"
              ></i>
          </a>-->
        </h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="alt_text_modal = false"
          >×</button
        >
      </header>

      <b-container fluid class="pt-4 px-4 pb-2">
        <b-row>
          <b-col sm="12"> </b-col>
          <b-col sm="3" class="my-auto">
            <b-img
              center
              width="150"
              height="150"
              :src="selected_image.image"
              alt="Image 1"
            ></b-img>
          </b-col>
          <b-col sm="9">
            <b-textarea
              id="textarea"
              v-model="alt_text_obj.alt_text"
              placeholder="Enter Description/ALT-Text"
              rows="6"
              :state="textAreaValidation"
              no-resize
              max-rows="6"
            ></b-textarea>
          </b-col>
          <b-col offset-sm="10" sm="2" class="d-flex justify-content-end">
            <span>{{ altTextAreaCounter() }}/{{ alt_text_twitter_limit }}</span>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer="">
        <div class="px-3">
          <p
            >Available for:
            <span style="color: #00acee"
              ><i size="24" class="fab fa-twitter-square fa-lg px-1"></i></span
          ></p>
        </div>
        <b-button
          size="lg"
          variant="primary"
          :disabled="altTextButtonStatus"
          @click="close_alt_text_modal($event, selected_image.index)"
        >
          Save
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
      </template>
    </b-modal>
  </div>
</template>

<style>
.modal-footer {
  justify-content: space-between;
}
</style>
