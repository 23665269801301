// libs

// composable
import useCompetitorsFactory from '@src/modules/analytics_v3/composables/useCompetitorsFactory'
import useCompetitorReport from "@src/modules/analytics_v3/composables/useCompetitorReport";

// constants
import {
    COMMON_API_ENDPOINTS,
    COMMON_ERROR_MESSAGES,
} from '@src/modules/analytics_v3/constants/constants'


export default function useBiographyData() {
    const {
        fetchCompetitorInfo: fetchBiographyData,
        apiResponse: biographyDataResponse,
        loading: biographyDataLoadingState
    } = useCompetitorsFactory()
    const {reportId} = useCompetitorReport()

    const getBiographyData = async (type = '') => {
        switch (type) {
            case 'instagram':
            case 'facebook':
                await fetchCommonBiographyData()
                break
            default:
                break
        }
    }

    const fetchCommonBiographyData = async () => {
        if (!reportId.value) return

        const payload = {
            _id: reportId.value,
        }

        try {
            await fetchBiographyData(COMMON_API_ENDPOINTS.BIOGRAPHY_DATA, payload, COMMON_ERROR_MESSAGES.BIOGRAPHY_DATA)
        } catch (error) {
        }
    }


    return {
        // data
        biographyDataLoadingState,
        biographyDataResponse,

        // methods
        getBiographyData
    }
}