<template>
  <div
    id="movePublicationModal"
    class="modal fade add_utm_modal normal_modal movePublicationModal z-[1060]"
  >
    <div class="modal-dialog w-1/3">
      <div class="modal-content">
        <div class="modal_head">
          <h4>Move to other Campaign Folder</h4>
          <button
            id="close"
            type="button"
            class="modal_head__close"
            data-dismiss="modal"
            >&times;</button
          >
        </div>
        <div class="modal_body">
          <div class="field_group">
            <label>Select Campaign Folder</label>
            <CstDropdown class="w-full">
              <template v-slot:selected>
                <div
                  v-if="getSelectedFolder"
                  class="px-2 py-0.5 rounded-full cst-pill"
                  :class="getSelectedFolder?.color || 'border'"
                >
                  {{ getSelectedFolder.name }}
                </div>
              </template>
              <CstDropdownItem
                v-for="(folder, index) in publicationFolders"
                :key="`campaign_folder_${folder._id}`"
                :close-on-click="true"
              >
                <CstRadio
                  :id="`publication_folders_${index}`"
                  v-model="selectedFolder"
                  name="publication_folders"
                  :value="folder._id"
                  reverse
                  @change="onClickFolder(folder._id)"
                >
                  <span class="px-2 py-0.5 rounded-full cst-pill" :class="folder?.color" >
                    {{ truncateString(folder.name, 40) }} ({{ folder.counts || 0 }})
                  </span>
                </CstRadio>
              </CstDropdownItem>
            </CstDropdown>
          </div>

          <div class="field_group">
            <button
              class="btn btn-studio-theme-space"
              :disabled="getMoveCampaign.loader"
              :class="{ disabled: getMoveCampaign.loader }"
              @click.prevent="movePostsToFolder"
            >
              Move
              <clip-loader
                v-if="getMoveCampaign.loader"
                :color="'#ffffff'"
                :size="'12px'"
              ></clip-loader>
            </button>

            <a
              class="pull-right create_campaign_link"
              href=""
              @click.prevent="openCreateModal"
              >Create New Campaign Folder</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {EventBus} from "@common/lib/event-bus";
import { composer } from '@src/modules/composer/store/composer-mutation-type.js'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem'
import CstRadio from '@ui/Radio/CstRadio'

export default {
  name: 'MovePublicationFolder',
  components: {
    CstRadio,
    CstDropdownItem,
    CstDropdown,
  },
  props: {
    publicationFolders: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      selectedFolder: this.getMoveCampaign?.id || 'edit_default_folder'
    }
  },
  computed: {
    ...mapGetters([
      'getPublicationFolders',
      'getMoveCampaign',
      'getTotalMyPublications'
    ]),
    getSelectedFolder () {
      return this.publicationFolders.find(folder => folder._id === this.selectedFolder)
    },
  },
  created () {},
  watch: {
    getMoveCampaign: {
      handler: function (val) {
        this.selectedFolder = val?.id
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([]),
    openCreateModal () {
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, true)
      $('#movePublicationModal').modal('hide')
      EventBus.$emit('createFolder')
    },
    movePostsToFolder() {
      this.getMoveCampaign.id = this.selectedFolder
      this.movePostCampaign()
    },
    onClickFolder (id) {
      this.selectedFolder = id
    }
  }
}
</script>
