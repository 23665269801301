<template>
  <div
    v-if="$parent.toggleViewStatus === 'list'"
    class="social_table influencer-radius-table"
  >
    <div class="table_wrapper">
      <div class="table_scroll">
        <table class="table">
          <thead>
            <tr>
              <th style="width: 450px"></th>
              <th style="width: 300px"></th>
              <th style="width: 200px; text-align: center">Views per Video</th>
              <th style="width: 200px; text-align: center">Subscribers </th>
              <th style="width: 200px; text-align: center"> Total Views </th>
              <th style="width: 200px; text-align: center"
                >Avg. Interaction
                <i
                  v-tooltip="'Average Interaction per 1000 followers'"
                  class="far fa-question-circle cursor_pointer"
                  aria-hidden="true"
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="$parent.loaders.search">
              <tr>
                <td
                  colspan="6"
                  style="border-radius: 0"
                  class="border-right-0 border-bottom-0 border-left-0"
                >
                  <div class="p-3">
                    <beat-loader :color="'#436aff'"></beat-loader>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-if="$parent.influencers.data.length === 0">
                <td
                  colspan="6"
                  style="border-radius: 0"
                  class="border-right-0 border-bottom-0 border-left-0"
                >
                  <div class="p-3 text-center">
                    <p>No influencers available for your filters.</p>
                  </div>
                </td>
              </tr>
              <template
                v-for="(influencer, index) in $parent.influencers.data"
                v-else
              >
                <tr>
                  <td style="width: 39%">
                    <div
                      class="profile_picture table_profile d-flex align-items-start"
                    >
                      <!--..///for if text with image -->

                      <div
                        class="picture_block picture_block_background influencer_detail"
                        @click="$parent.redirectYoutubePreview(influencer)"
                      >
                        <div
                          class="img"
                          :style="
                            $parent.influencerBackgroundImageURL(
                              influencer.influencer_image,
                            )
                          "
                        ></div>
                      </div>
                      <div class="text_block">
                        <p class="text influencer_detail"
                          ><span
                            @click="$parent.redirectYoutubePreview(influencer)"
                            v-html="influencer.channel_title"
                          ></span>

                          <a
                            target="_blank"
                            data-cy="influencer_preview_username"
                            class="text username"
                            :href="
                              'https://www.youtube.com/channel/' +
                              influencer.channel_id
                            "
                            >@{{ influencer.channel_title }}</a
                          >

                          <!--<template v-if="influencer.is_verified"><i-->
                          <!--class="is_verified fa fa-check-circle"-->
                          <!--v-tooltip="'Verified Account'">-->
                          <!--</i></template>-->
                        </p>
                        <div
                          class="sub_text influencer_detail"
                          @click="$parent.redirectYoutubePreview(influencer)"
                        >
                          <p v-html="influencer.bio"> </p>
                          <p
                            v-if="
                              influencer.country_location &&
                              getFullCountryText(influencer.country_location)
                            "
                            style="opacity: 0.6"
                          >
                            <i
                              class="location-detail mr-1 fa fa-map-marker"
                            ></i>
                            {{
                              getFullCountryText(influencer.country_location)
                            }}</p
                          >
                        </div>

                        <div class="simple_tag">
                          <template
                            v-for="(
                              interest, interestIndex
                            ) in $parent.sortInterest(influencer.interests)"
                          >
                            <template v-if="interestIndex < 2">
                              <span
                                v-tooltip.top="
                                  'View more influencers from this topic'
                                "
                                class="tag_item c-pointer"
                                :data-cy="
                                  'youtube_influencer_topic_' + interestIndex
                                "
                                @click.prevent="
                                  $parent.changeInfluencerTopic(
                                    interest,
                                    'youtube',
                                  )
                                "
                              >
                                <span class="tag_text tag_text_truncate">{{
                                  interest.label
                                }}</span>
                                <span class="text_detail">{{
                                  interest.count
                                }}</span>
                              </span>
                            </template>
                          </template>
                          <template v-if="influencer.interests.length > 2">
                            <div class="custom_tooltip d-inline-block">
                              <span class="tag_item c-pointer">
                                <span class="tag_text"
                                  >+{{ influencer.interests.length - 2 }}</span
                                >
                              </span>

                              <div
                                class="tool_tip_box top_right"
                                style="left: 0; width: 280px"
                              >
                                <div class="tool_tip_inner p-0">
                                  <div
                                    slot="htmlContent"
                                    style="max-height: 200px; overflow: auto"
                                    class="guide_tooltip p-2"
                                  >
                                    <template
                                      v-for="(
                                        interest, interestIndex
                                      ) in $parent.sortInterest(
                                        influencer.interests,
                                      )"
                                    >
                                      <template v-if="interestIndex >= 2">
                                        <span
                                          class="tag_item c-pointer mb-2"
                                          :data-cy="
                                            'youtube_influencer_topic_' +
                                            interestIndex
                                          "
                                          @click.prevent="
                                            $parent.changeInfluencerTopic(
                                              interest,
                                              'youtube',
                                            )
                                          "
                                        >
                                          <span class="tag_text">{{
                                            interest.label
                                          }}</span>
                                          <span class="text_detail">{{
                                            interest.count
                                          }}</span>
                                        </span>
                                      </template>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="list_item">
                      <div
                        class="twitter_list_dropdown prevent_close_dropdown checkbox_dropdown dropdown option_dropdown default_style_dropdown"
                      >
                        <div
                          class="d-flex align-items-center"
                          data-toggle="dropdown"
                        >
                          <div class="list_item_div">
                            <i class="fa fa-list"></i><span>Add To List</span>
                          </div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="inner">
                            <template
                              v-if="getInfluencerFolders.data.length > 0"
                            >
                              <li
                                v-for="folder in getInfluencerFolders.data"
                                class="list_item_li d-block"
                                @click="
                                  $parent.shortlistInfluencer({
                                    influencer,
                                    influencer_folder_id: folder._id,
                                    screen_name: influencer.username,
                                    action: $parent.isShortlisted(
                                      influencer.influencer_folders,
                                      folder._id,
                                    )
                                      ? 'remove'
                                      : 'add',
                                    type: 'instagram',
                                  })
                                "
                              >
                                <span> {{ folder.name }} </span>
                                <span
                                  v-if="
                                    $parent.isShortlisted(
                                      influencer.influencer_folders,
                                      folder._id,
                                    )
                                  "
                                  class="added-text"
                                  ><i class="fa fa-check-circle"></i
                                ></span>
                              </li>

                              <li
                                class="no_text_li"
                                @click.prevent="
                                  $parent.createInfluencerList({
                                    screen_name: influencer.username,
                                    index: index,
                                    type: 'instagram',
                                  })
                                "
                              >
                                <i class="cs-plus"></i
                                ><span>Create a new list</span>
                              </li>
                            </template>

                            <li
                              v-else
                              class="no_account_li"
                              @click.prevent="
                                $parent.createInfluencerList({
                                  screen_name: influencer.username,
                                  index: index,
                                  type: 'instagram',
                                })
                              "
                            >
                              <p>You have not created any list.</p>
                              <span class="link">Create list</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="cleafix"></div>
                    </div>
                  </td>
                  <td class="text-center">
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.average_view)
                      "
                      class="number"
                      >{{ intToString(influencer.average_view) }}</span
                    >
                  </td>
                  <td class="text-center">
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.total_subscribers)
                      "
                      class="number"
                      >{{ intToString(influencer.total_subscribers) }}</span
                    >
                  </td>
                  <td class="text-center">
                    <span
                      v-tooltip.top="stringToInt(influencer.total_views)"
                      class="number"
                      >{{ intToString(influencer.total_views) }}</span
                    >
                  </td>
                  <td class="text-center">
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.average_interaction, true)
                      "
                      class="number"
                      >{{ intToString(influencer.average_interaction) }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="space-row" colspan="6"></td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div class="clearfix"></div>
      <div v-if="!$parent.loaders.search" class="pagination_style">
        <InfluencerPagination
          :total="$parent.influencers.total_pages"
          :page="$parent.influencers.current_page"
          :type="$parent.checkInfluencerType"
        ></InfluencerPagination>
      </div>
    </div>
  </div>

  <div
    v-else-if="$parent.toggleViewStatus === 'grid'"
    class="influencer-grid-view-list"
  >
    <template v-if="$parent.loaders.search">
      <div class="p-3">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <template v-else>
      <div
        v-if="$parent.influencers.data.length === 0"
        slot="no-more"
        class="not_found_text"
      >
        <div class="no_data_found_content">
          <div class="no_data_found_content_inner">
            <div class="img">
              <img
                src="../../../../../assets/img/no_data_images/no_data_found.svg"
                alt=""
              />
            </div>
            <h2>No results found</h2>
            <p>No influencer available found for your query.</p>
            <p
              >Please try a different search string or change search filters.</p
            >
          </div>
        </div>
      </div>

      <div
        v-for="(influencer, index) in $parent.influencers.data"
        v-else
        class="grid-view-item"
      >
        <div class="item-inner">
          <div class="profile_picture table_profile">
            <!--..///for if text with image -->

            <div
              class="picture_block picture_block_background influencer_detail mb-2"
              @click="$parent.redirectYoutubePreview(influencer)"
            >
              <div
                class="img"
                :style="
                  $parent.influencerBackgroundImageURL(
                    influencer.influencer_image,
                  )
                "
              ></div>
            </div>
            <div class="text_block">
              <p
                data-cy="youtube_influencer_username"
                class="text influencer_detail mb-2"
              >
                <span
                  @click="$parent.redirectYoutubePreview(influencer)"
                  v-html="influencer.channel_title"
                ></span>
                <!--<template v-if="influencer.is_verified"><i-->
                <!--class="is_verified fa fa-check-circle"-->
                <!--v-tooltip="'Verified Account'">-->
                <!--</i></template>-->
              </p>

              <p
                v-if="influencer.channel_id"
                class="sub-text mb-3 influencer_detail"
              >
                <a
                  style=""
                  target="_blank"
                  data-cy="influencer_preview_username"
                  class="text username ml-0"
                  :href="
                    'https://www.youtube.com/channel/' + influencer.channel_id
                  "
                  >@{{ influencer.channel_title }}</a
                >
              </p>

              <div
                class="sub_text influencer_detail"
                @click="$parent.redirectYoutubePreview(influencer)"
              >
                <p
                  style="height: 24px"
                  class="location-txt mb-3"
                  :data-cy="
                    'youtube_influencer_location_' + influencer.channel_id
                  "
                >
                  <template
                    v-if="
                      influencer.country_location &&
                      getFullCountryText(influencer.country_location)
                    "
                  >
                    <i class="location-detail mr-1 far fa-map-marker-alt"></i>
                    {{ getFullCountryText(influencer.country_location) }}
                  </template>
                </p>
                <p
                  style="height: 48px"
                  class="desc-text mb-3"
                  :data-cy="'youtube_influencer_bio_' + influencer.channel_id"
                  @click="$parent.redirectYoutubePreview(influencer)"
                  v-html="influencer.bio"
                ></p>
              </div>

              <div class="simple_tag mb-3">
                <template
                  v-for="(interest, interestIndex) in $parent.sortInterest(
                    influencer.interests,
                  )"
                >
                  <template v-if="interestIndex < 2">
                    <span
                      v-tooltip.top="
                        'View more influencers from this topic'
                      "
                      class="tag_item c-pointer"
                      :data-cy="'youtube_influencer_topic_' + interestIndex"
                      @click.prevent="
                        $parent.changeInfluencerTopic(interest, 'youtube')
                      "
                    >
                      <span class="tag_text tag_text_truncate">{{
                        interest.label
                      }}</span>
                      <span class="text_detail">{{ interest.count }}</span>
                    </span>
                  </template>
                </template>
                <template v-if="influencer.interests.length > 2">
                  <div class="custom_tooltip d-inline-block">
                    <span class="tag_item c-pointer">
                      <span class="tag_text"
                        >+{{ influencer.interests.length - 2 }}</span
                      >
                    </span>

                    <div
                      class="tool_tip_box top_left"
                      style="right: 0; width: 280px"
                    >
                      <div class="tool_tip_inner p-0">
                        <div
                          slot="htmlContent"
                          style="max-height: 200px; overflow: auto"
                          class="guide_tooltip p-2"
                        >
                          <template
                            v-for="(
                              interest, interestIndex
                            ) in $parent.sortInterest(influencer.interests)"
                          >
                            <template v-if="interestIndex >= 2">
                              <span
                                class="tag_item c-pointer mb-2"
                                :data-cy="
                                  'youtube_influencer_topic_' + interestIndex
                                "
                                @click.prevent="
                                  $parent.changeInfluencerTopic(
                                    interest,
                                    'youtube',
                                  )
                                "
                              >
                                <span class="tag_text">{{
                                  interest.label
                                }}</span>
                                <span class="text_detail">{{
                                  interest.count
                                }}</span>
                              </span>
                            </template>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="social_stats">
            <div class="social_stats_inner d-flex">
              <div class="stat_item">
                <div class="name">
                  <p>Views per Video</p>
                </div>
                <div class="value">
                  <h3>
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.average_view)
                      "
                      class="number"
                      data-cy="youtube_influencers_listing_published"
                      >{{ intToString(influencer.average_view) }}</span
                    >
                  </h3>
                </div>
              </div>

              <div class="stat_item">
                <div class="name">
                  <p>Subscribers</p>
                </div>
                <div class="value">
                  <h3>
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.total_subscribers)
                      "
                      class="number"
                      data-cy="youtube_influencers_listing_follower"
                      >{{ intToString(influencer.total_subscribers) }}</span
                    >
                  </h3>
                </div>
              </div>
              <div class="stat_item">
                <div class="name">
                  <p>Total Views</p>
                </div>
                <div class="value">
                  <h3>
                    <span
                      v-tooltip.top="stringToInt(influencer.total_views)"
                      data-cy="twitter_influencers_listing_views"
                      class="number"
                      >{{ intToString(influencer.total_views) }}</span
                    >
                  </h3>
                </div>
              </div>

              <div class="stat_item">
                <div
                  v-tooltip="'Average Interaction per 1000 followers'"
                  class="name"
                >
                  <p>Avg. Interaction</p>
                </div>
                <div class="value">
                  <h3>
                    <span
                      v-tooltip.top="
                        stringToInt(influencer.average_interaction, true)
                      "
                      class="number"
                      data-cy="youtube_influencers_listing_interaction"
                      >{{ intToString(influencer.average_interaction) }}</span
                    >
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div class="btn_block d-flex">
            <div class="btn_block_inner text-center">
              <div
                class="mr-2 twitter_list_dropdown prevent_close_dropdown checkbox_dropdown dropdown option_dropdown default_style_dropdown"
              >
                <div
                  v-tooltip.top="'Add To List'"
                  class="btn d-flex align-items-center"
                  data-toggle="dropdown"
                >
                  <div
                    class="list_item_div"
                    data-cy="twitter_influencer_list_button"
                  >
                    <i class="mr-0 far fa-bookmark"></i>
                    <!--<span>Add To List</span>-->
                  </div>
                </div>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="inner" data-cy="twitter_influencer_list">
                    <template v-if="getInfluencerFolders.data.length > 0">
                      <li
                        v-for="folder in getInfluencerFolders.data"
                        class="list_item_li d-block"
                        :data-cy="
                          'youtube_influencer_list_dropdown' + folder._id
                        "
                        @click="
                          $parent.shortlistInfluencer({
                            influencer,
                            influencer_folder_id: folder._id,
                            screen_name: influencer.channel_id,
                            action: $parent.isShortlisted(
                              influencer.influencer_folders,
                              folder._id,
                            )
                              ? 'remove'
                              : 'add',
                            type: 'youtube',
                          })
                        "
                      >
                        <span> {{ folder.name }} </span>
                        <span
                          v-if="
                            $parent.isShortlisted(
                              influencer.influencer_folders,
                              folder._id,
                            )
                          "
                          class="added-text"
                        >
                          <i
                            class="fa fa-check-circle"
                            :data-cy="
                              'youtube_influencer_list_dropdown_icon_' +
                              folder._id
                            "
                          ></i>
                        </span>
                      </li>

                      <li
                        class="no_text_li"
                        data-cy="twitter_influencer_list_create"
                        @click.prevent="
                          $parent.createInfluencerList({
                            screen_name: influencer.channel_id,
                            index: index,
                            type: 'youtube',
                          })
                        "
                      >
                        <i class="cs-plus"></i><span>Create a new list</span>
                      </li>
                    </template>

                    <li
                      v-else
                      class="no_account_li"
                      data-cy="twitter_influencer_list_create"
                      @click.prevent="
                        $parent.createInfluencerList({
                          screen_name: influencer.channel_id,
                          index: index,
                          type: 'youtube',
                        })
                      "
                    >
                      <p>You have not created any list.</p>
                      <span class="link">Create list</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div v-if="!$parent.loaders.search" class="pagination_style">
        <InfluencerPagination
          :total="$parent.influencers.total_pages"
          :page="$parent.influencers.current_page"
          :type="$parent.checkInfluencerType"
        ></InfluencerPagination>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isoCountries } from '@common/constants/iso-countries'
import { influencerTypes } from '../../../store/mutation-types'
import InfluencerPagination from '../InfluencerPagination'

export default {
  components: {
    InfluencerPagination
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getInfluencerFolders'])
  },

  watch: {},
  created () {
    let page = this.$route.query.page ? this.$route.query.page : 1
    page = Number.parseInt(page)
    if (Number.isNaN(page)) page = 1
    this.$parent.getInfluencers('youtube', page)
    this.$parent.getInfluencersFilterStats('youtube')
  },
  mounted () {},

  methods: {
    getFullCountryText (code) {
      return isoCountries[code]
    },
    callInfluencerPagination (type, page) {
      this.$parent.getInfluencers(type, page)
    }
  }
}
</script>

<style lang="less" scoped>
.discovery_influencer_component
  .component_inner
  .influencer-grid-view-list
  .grid-view-item
  .item-inner
  .simple_tag
  .tag_item
  .tag_text_truncate {
  max-width: 8.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  float: left;
}
@media (max-width: 1350px) {
  .discovery_influencer_component
    .component_inner
    .influencer-grid-view-list
    .grid-view-item
    .item-inner
    .simple_tag
    .tag_item
    .tag_text_truncate {
    max-width: 5.2rem;
  }
}
</style>
