// NOTE: extra routes below, we can remove them after validating if they are no longer required.

const Groups = () =>
  import(
    /* webpackChunkName: "extra-settings" */ '@src/modules/setting/components/Groups.vue'
  )
const Sources = () =>
  import(
    /* webpackChunkName: "extra-settings" */ '@src/modules/setting/components/sources/Sources.vue'
  )
const WebSources = () =>
  import(
    /* webpackChunkName: "extra-settings" */ '@src/modules/setting/components/sources/WebSources.vue'
  )
const TwitterSources = () =>
  import(
    /* webpackChunkName: "extra-settings" */ '@src/modules/setting/components/sources/TwitterSources.vue'
  )
const FacebookSources = () =>
  import(
    /* webpackChunkName: "extra-settings" */ '@src/modules/setting/components/sources/FacebookSources.vue'
  )

// NOTE: used routes below.

const SettingMain = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/SettingMain.vue'
  )
const Profile = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/Profile.vue'
  )
const AffiliateProgramView = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/AffiliateProgram.vue'
  )
const PublishingQueue = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/content-categories/ContentCategories.vue'
  )
const Social = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/integration/components/platforms/social/Social.vue'
  )
const Miscellaneous = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/Miscellaneous.vue'
  )
const ReportSettings = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/ReportSettings.vue'
  )
const EmailNotificationStatus = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/EmailNotificationStatus.vue'
  )
const Integrations = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/integration/components/Integrations.vue'
  )
const BasicSetting = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/workspace/BasicSetting.vue'
  )
const Notifications = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/notifications/Notifications.vue'
  )
const referEarn = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/refer&earn.vue'
  )
const Team = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/workspace/team/Team.vue'
  )
const Blog = () =>
  import('@src/modules/integration/components/platforms/blog/Blog.vue')
const EnrolledPlanView = () =>
  import('@src/modules/setting/components/billing/EnrolledPlanView.vue')
const createWorkspace = () =>
  import('@src/modules/setting/components/workspace/Create.vue')
const WorkspaceListing = () =>
  import(
    /* webpackChunkName: "workspace-related" */ '@src/modules/setting/components/workspace/Listing.vue'
  )
const ManageTeamMember = () =>
  import('@src/modules/setting/components/organization/ManageTeamMember.vue')
const StartTrial = () =>
  import(
    /* webpackChunkName: "workspace-related" */ '@src/modules/setting/components/workspace/StartTrial.vue'
  )
const ManageLimits = () =>
  import(
    /* webpackChunkName: "workspace-related" */ '@src/modules/setting/components/manage-limits/ManageLimitsMain.vue'
  )

export const settingRoutes = {
  settings: {
    name: 'profile_settings',
    path: '/:workspace/settings/',
    redirect: '/:workspace/settings/profile',
    component: SettingMain,
    meta: {
      title: 'Settings',
    },
    children: [
      {
        path: 'profile/',
        name: 'profile',
        component: Profile,
        meta: {
          title: 'User Profile | Settings',
        },
      },
      {
        path: 'plan/',
        name: 'plan',
        component: EnrolledPlanView,
        meta: {
          title: 'Billing & Plan | Settings',
        },
      },
      {
        path: 'affiliate/',
        name: 'affiliate',
        component: AffiliateProgramView,
        meta: {
          title: 'Affiliate Program | Settings',
        },
      },
      {
        path: 'subscription/',
        name: 'subscription',
        component: EnrolledPlanView,
        meta: {
          title: 'Subscription Plan | Settings',
        },
      },
      /* {
        path: 'developer_api_credentials/',
        name: 'developers',
        component: Developers,
        meta: {
          'title': 'API Credentials'
        }
      }, */

      {
        path: 'groups/',
        name: 'groups',
        component: Groups,
        meta: {
          title: 'Groups Settings | Settings',
        },
      },
      {
        path: 'social/:id?',
        name: 'social',
        component: Social,
        props: true,
        meta: {
          title: 'Social Accounts | Settings',
        },
      },
      {
        path: 'blog/',
        name: 'blogs',
        component: Blog,
        meta: {
          title: 'Blogs & Websites | Settings',
        },
      },
      {
        path: 'miscellaneous/',
        name: 'miscellaneous',
        component: Miscellaneous,
        meta: {
          title: 'Miscellaneous | Settings',
        },
      },
      {
        path: 'reportsettings/',
        name: 'reportsettings',
        component: ReportSettings,
        meta: {
          title: 'Analytics Reports | Settings',
        },
      },
      {
        path: 'emailNotificationStatus/',
        name: 'emailNotificationStatus',
        component: EmailNotificationStatus,
        meta: {
          title: 'Email Notification Status | Settings',
        },
      },
      {
        path: 'integrations/',
        name: 'integrations',
        component: Integrations,
        meta: {
          title: 'Other Integrations | Settings',
        },
      },

      {
        path: 'sources/',
        name: 'sources',
        component: Sources,
        redirect: 'sources/web',
        meta: {
          title: 'Sources | Settings',
        },
        children: [
          {
            path: 'web/',
            name: 'web_sources',
            component: WebSources,
            meta: {
              title: 'Web Sources | Settings',
            },
          },
          {
            path: 'facebook/',
            name: 'facebook',
            component: FacebookSources,
            meta: {
              title: 'Facebook Sources | Settings',
            },
          },
          {
            path: 'twitter/',
            name: 'twitter',
            component: TwitterSources,
            meta: {
              title: 'X (Twitter) Sources | Settings',
            },
          },
        ],
      },
      {
        path: 'basicSetting/',
        name: 'basicSetting',
        component: BasicSetting,
        meta: {
          title: 'Basic Setting | Settings',
        },
      },
      {
        path: 'notifications/',
        name: 'notifications',
        component: Notifications,
        meta: {
          title: 'Notifications | Settings',
        },
      },
      {
        path: 'refer&earn/',
        name: 'refer&earn',
        component: referEarn,
        meta: {
          title: 'Refer & Earn | Settings',
        },
      },
      {
        path: 'team/',
        name: 'team',
        component: Team,
        meta: {
          title: 'Team Members | Settings',
        },
      },
      {
        path: 'content_categories/',
        name: 'content_categories',
        component: PublishingQueue,
        meta: {
          title: 'Content Categories | Settings',
        },
      },
    ],
  },
  workspace: {
    workspaces: {
      path: '/workspaces',
      name: 'workspaces',
      component: WorkspaceListing,
      meta: {
        title: 'Listing | Workspaces',
      },
    },
    manageTeam: {
      path: '/manage_team',
      name: 'manageTeam',
      component: ManageTeamMember,
      meta: {
        title: 'Manage Team | Workspaces',
      },
    },
    StartTrial: {
      path: '/start_trial',
      name: 'start_trial',
      component: StartTrial,
      meta: {
        title: 'Start Trial | Workspaces',
        top_header: false,
      },
    },

    createWorkspace: {
      path: '/createWorkspace',
      name: 'createWorkspace',
      component: createWorkspace,
      meta: {
        title: 'Create Workspace | Workspaces',
      },
    },
    manageLimits: {
      path: '/manage-limits',
      name: 'manageLimits',
      component: ManageLimits,
      meta: {
        title: 'Manage Limits | Settings',
      },
    },
  },
}
